import React from "react";
import { Card } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";
import SupportEmailLink from "components/SupportEmailLink";

// TODO: add the real messaging once we finalize it
const IndividualDenied = () => {
  window.analytics.page("Individual Denied");
  return (
    <Card className="text-center attention-card">
      <div className="alert-icon-container">
        <FaExclamationTriangle color="#DB4163" size={42} />
      </div>
      <Card.Body>
        <Card.Title>Application Denied</Card.Title>
        <Card.Text>
          Unfortunately your application to use Icon was denied. If you believe
          this to be an error or have any questions please feel free to reach
          out to <SupportEmailLink />.
        </Card.Text>
      </Card.Body>
    </Card>
  );
};
export default IndividualDenied;
