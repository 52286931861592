import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card } from "react-bootstrap";
import { push } from "connected-react-router";
import { each, size } from "lodash";
import { getPendingApprovals } from "actions/adminActions";
import { createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";

import Button from "components/Button";
import IconSpinner from "components/IconSpinner";
import IconTable from "components/IconTable";
import IconTableHeader from "components/IconTableHeader";

import "./Approval.scss";

class ApprovalTable extends React.PureComponent {
  static propTypes = {
    getPendingApprovals: PropTypes.func,
    client: PropTypes.object,
    push: PropTypes.func,
    pendingApprovals: PropTypes.arrayOf(PropTypes.object),
    isFetching: PropTypes.bool,
  };

  constructor() {
    super();
  }

  _promises = [];

  componentDidMount() {
    this._promises.push(this.props.getPendingApprovals(this.props.client));
  }

  componentWillUnmount() {
    each(this._promises, (promise) => promise.cancel());
  }

  _pendingApprovalColumns = () => {
    const columns = [
      {
        label: "Approval Type",
        key: "type.label",
      },
      {
        label: "Type",
        key: "entityIdentifier",
        format: (entity) => {
          if (entity.type === "Employer") {
            return "Company";
          }
          return entity.type;
        },
      },
      {
        label: "Id",
        key: "entityIdentifier.id",
      },
      {
        label: "Name",
        key: "correlationName",
      },
      {
        label: "Action",
        customComponent: (props) => {
          return (
            <Button
              name="action"
              color="action"
              size="sm"
              type="button"
              btnLabel="View"
              onClick={() =>
                this.props.push(`/approval/${props.type.urlPath}/${props.id}`)
              }
            />
          );
        },
      },
    ];
    return columns;
  };

  render() {
    const columns = this._pendingApprovalColumns();
    const data = this.props.pendingApprovals;

    if (this.props.isFetching) {
      return <IconSpinner />;
    }
    return (
      <>
        <h2 style={{ color: "#0a2540" }}>Pending Approvals</h2>
        <Card>
          <IconTableHeader
            tableHeader="Approvals"
            tableCountLabel="Pending"
            tableCount={size(this.props.pendingApprovals)}
          />
          <IconTable columns={columns} data={data} />
        </Card>
      </>
    );
  }
}

const isLoadingSelector = createLoadingSelector(
  adminConstants.GET_PENDING_APPROVALS
);

const mapStateToProps = (state) => {
  return {
    isFetching: isLoadingSelector(state) || !state.admin.approvalsById,
    pendingApprovals: Object.values(state.admin.approvalsById).filter(
      (approval) => approval.status === "PENDING"
    ),
  };
};

const mapDispatchToProps = {
  push,
  getPendingApprovals,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ApprovalTable));
