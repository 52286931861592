import Cookies from "js-cookie";

// Removes user identifiers and cookies related to the logged in user
export function clearSession() {
  localStorage.clear();
  Cookies.remove("ajs_user_id", { path: "", domain: window.location.hostname });
}

export default {
  clearSession,
};
