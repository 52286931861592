import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { Form } from "react-bootstrap";

const reasons = [
  { value: "NORMAL", description: "Normal Withdrawal (age 59 1/2 or older)" },
  { value: "PREMATURE", description: "Early Withdrawal (under age 59 1/2)" },
  { value: "DISABILITY", description: "Disability" },
  { value: "DEATH", description: "Death Withdrawal by a Beneficiary" },
  {
    value: "EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE",
    description:
      "Excess Contribution Removed Before the Excess Removal Deadline",
  },
  {
    value: "EXCESS_CONTRIBUTION_REMOVAL_AFTER_TAX_DEADLINE",
    description:
      "Excess Contribution Removed After the Excess Removal Deadline",
  },
  {
    value: "ROLLOVER_TO_QUALIFIED_PLAN",
    description:
      "Direct Rollover to an Eligible Employer-Sponsored Retirement Plan",
  },
  {
    value: "ROLLOVER_TO_IRA",
    description:
      "Direct Rollover to IRA from a Qualified Plan (PSP, MPP, 401k)",
  },
  {
    value: "TRANSFER",
    description:
      "Funds are being transferred by trustee to an identical retirement account",
  },
  {
    value: "RECHARACTERIZATION_PRIOR_YEAR",
    description: "Recharacterization: prior year",
  },
  {
    value: "RECHARACTERIZATION_CURRENT_YEAR",
    description: "Recharacterization: current year",
  },
  {
    value: "PREMATURE_SIMPLE_IRA_LESS_THAN_2_YEARS",
    description: "SIMPLE IRA Withdrawal in the first two years",
  },
  {
    value: "NORMAL_ROTH_IRA_GREATER_THAN_5_YEARS",
    description: "Roth IRA Withdrawal after first five Years",
  },
];

const WithdrawalReasonSelect = ({
  value,
  handleBlur,
  handleChange,
  touched,
  errors,
}) => {
  return (
    <Form.Control
      as="select"
      name="reason"
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      isInvalid={touched.reason && !!errors.reason}
      isValid={touched.reason && !errors.reason}
    >
      <option value="" disabled>
        Select Withdrawal Reason
      </option>
      {map(reasons, (reason) => (
        <option key={reason.value} value={reason.value}>
          {reason.description}
        </option>
      ))}
    </Form.Control>
  );
};

WithdrawalReasonSelect.propTypes = {
  value: PropTypes.string,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
};

export default WithdrawalReasonSelect;
