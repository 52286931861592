import React from "react";
import PropTypes from "prop-types";
import "./CircularProgressBar.css";

class CircularProgressBar extends React.Component {
  static propTypes = {
    percentage: PropTypes.string,
    sqSize: PropTypes.string,
    strokeWidth: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = {
      percentage: this.props.percentage,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.percentage !== this.props.percentage) {
      this.setState({
        percentage: this.props.percentage,
      });
    }
  }

  render() {
    // Size of the enclosing square
    const sqSize = this.props.sqSize;
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - (dashArray * this.state.percentage) / 100;

    return (
      <svg
        width={this.props.sqSize}
        height={this.props.sqSize}
        viewBox={viewBox}
      >
        <defs>
          <pattern
            id="img2"
            patternUnits="userSpaceOnUse"
            width={this.props.sqSize}
            height={this.props.sqSize}
          >
            <image
              href="/assets/gradient-bg.png"
              x={viewBox.x}
              y={viewBox.y}
              width={this.props.sqSize}
              height={this.props.sqSize}
            />
          </pattern>
        </defs>
        <circle
          className="circle-background"
          cx={this.props.sqSize / 2}
          cy={this.props.sqSize / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
        />
        <circle
          className="circle-progress"
          fill="none"
          stroke="url(#img2)"
          cx={this.props.sqSize / 2}
          cy={this.props.sqSize / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${this.props.sqSize / 2} ${
            this.props.sqSize / 2
          })`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
        <text
          className="circle-text"
          x="50%"
          y="50%"
          dy=".3em"
          textAnchor="middle"
        >
          {`${this.state.percentage}%`}
        </text>
      </svg>
    );
  }
}

export default CircularProgressBar;
