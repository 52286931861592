import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import Button from "components/Button";
import Alert from "components/Alert";

import "./SimpleModal.scss";

const SimpleModal = ({
  onClose,
  show,
  title,
  children,
  buttonText,
  onSubmit,
  btnDisabled,
  isXhrRequest,
  isFetching,
  closeButtonText = "Cancel",
  error,
  showCloseButton,
  hideActions,
  size,
  subtext,
  hideHeader,
}) => {
  return (
    <Modal
      centered
      scrollable
      size={size}
      show={show}
      onHide={() => {
        const clickingOutsideModal = true;
        onClose(clickingOutsideModal);
      }}
      className="icon-modal icon-modal"
    >
      {!hideHeader && (
        <Modal.Header className="modal-header" closeButton={showCloseButton}>
          <IconHeader
            id="contained-modal-title-vcenter"
            variant="cardHeader"
            headerText={title}
          />
          <IconSubheader subheader={subtext} />
        </Modal.Header>
      )}
      <Modal.Body className="modal-body">
        {children}
        {error && <Alert type="error" msg={error} />}
      </Modal.Body>
      {!hideActions && (
        <Modal.Footer>
          {showCloseButton && (
            <Button
              name="cancel"
              btnLabel={closeButtonText}
              onClick={() => onClose()}
              color="cancel"
            />
          )}
          <Button
            name="submit"
            btnLabel={buttonText}
            disabled={btnDisabled}
            withArrow={isXhrRequest}
            loading={isFetching}
            onClick={() => (onSubmit ? onSubmit() : onClose())}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
};

SimpleModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  btnDisabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  isXhrRequest: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  hideActions: PropTypes.bool,
  hideHeader: PropTypes.bool,
  closeButtonText: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.string,
  subtext: PropTypes.string,
};

SimpleModal.defaultProps = {
  closeButtonText: "Cancel",
};

export default SimpleModal;
