import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { getContributionAbility } from "actions/contributionActions";
import { Row, Col } from "react-bootstrap";
import { filter } from "lodash";
import { getAllAccounts } from "actions/accountActions";
import { activeAccountType } from "store/selectors/user";
import { accountTypesToEnglishMapping } from "statics/accountTypes";
import {
  getLinkedEmployers,
  getEmployerLinkRequests,
} from "actions/userActions";

import AnnualContributionsWidget from "./AnnualContributionsWidget";
import IconSpinner from "components/IconSpinner";
import Employers from "./Employers";
import Banks from "./Banks";

import "./IndividualContributions.scss";

class IndividualContributions extends React.PureComponent {
  static propTypes = {
    getLinkedEmployers: PropTypes.func,
    getAllAccounts: PropTypes.func,
    getContributionAbility: PropTypes.func,
    client: PropTypes.object,
    getEmployerLinkRequests: PropTypes.func,
    accountType: PropTypes.string,
    linkedEmployers: PropTypes.arrayOf(PropTypes.shape({})),
    pendingEmployerLinks: PropTypes.arrayOf(PropTypes.shape({})),
    currentContributionAbility: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      initialFetching: true,
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.getLinkedEmployers(this.props.client),
      this.props.getEmployerLinkRequests(this.props.client),
      this.props.getContributionAbility(this.props.client),

      this.props.getAllAccounts(this.props.client),
    ]).then(() => this.setState({ initialFetching: false }));
  }

  _getContent() {
    return (
      <>
        <Employers
          linkedEmployers={this.props.linkedEmployers}
          pendingEmployerLinks={this.props.pendingEmployerLinks}
        />
        <Banks />
        <Row>
          <Col md={12}>
            <AnnualContributionsWidget
              currentContributionAbility={this.props.currentContributionAbility}
            />
          </Col>
        </Row>
      </>
    );
    // todo add bank section
  }

  render() {
    let content;
    if (this.state.initialFetching) {
      content = <IconSpinner centered />;
    } else {
      content = this._getContent();
    }

    return (
      <div className="mega-container" id="user-contributions">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">
              {accountTypesToEnglishMapping[this.props.accountType]}{" "}
              Contributions
            </h1>
            <p className="page-subtext">
              Contributions overview will help you better manage and grow your
              wealth over time.
            </p>
            <div className="main-content">{content}</div>
          </article>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    accountType: activeAccountType(state),
    linkedEmployers: state.user.linkedEmployers,
    pendingEmployerLinks: filter(
      state.user.employerLinkRequests,
      (linkRequest) => linkRequest.status !== "ACTIVE"
    ),
  };
};

const mapDispatchToProps = {
  getLinkedEmployers,
  getEmployerLinkRequests,
  getAllAccounts,
  getContributionAbility,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualContributions));
