import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Col, Row } from "react-bootstrap";
import { push } from "connected-react-router";
import { FiGlobe } from "react-icons/fi";
import { toast } from "react-toastify";
import { formatCurrency } from "utils/number";
import { createErrorSelector, createLoadingSelector } from "store/selectors";

import moment from "moment";
import Button from "components/Button";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import withSessionToken from "components/withSessionToken";
import { payrollConstants } from "actions/types";
import { approveSessionTokenPayroll } from "actions/payrollActions";

class AuthlessPayrollApproval extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    push: PropTypes.func,
    approveSessionTokenPayroll: PropTypes.func,
    isApproving: PropTypes.bool,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    sessionParams: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSuccessPage: false,
    };
  }

  _goToDashboard = () => {
    this.props.push("/dashboard");
  };

  _submitPayroll = () => {
    const { sessionToken, identityHash } = this.props.sessionParams;
    this.props
      .approveSessionTokenPayroll(this.props.client, {
        sessionToken,
        identityHash,
      })
      .then(() => {
        if (!this.props.error) {
          toast.success("Successfully processed the payroll.");
          this.setState({ showSuccessPage: true });
        } else {
          toast.error("There was a problem processing the payroll."),
            this.props.push("/dashboard/contributions");
        }
      });
  };

  render() {
    const {
      companyName,
      groupName,
      processingDate,
      accountNumber,
      payrollAmount,
      start,
      end,
    } = this.props.sessionParams.payload;

    const commonDateFormat = "MMM Do YYYY";

    const payPeriod = `Pay Period: ${moment(start).format(
      commonDateFormat
    )} -  ${moment(end).format(commonDateFormat)} `;

    if (this.state.showSuccessPage) {
      return (
        <>
          <div className="authlessPayrollApproval">
            <div className="authlessPayrollApproval-intro">
              <IconHeader
                variant="cardHeader"
                headerText="Successfully Approved Payroll"
              />
              <IconSubheader subheader={payPeriod} />
            </div>
            <div className="authlessPayrollApproval-body">
              <IconHeader variant="referralHeader" headerText="You did it!" />
              <IconSubheader
                subheader={`Time to relax, we will take it from here. Icon will debit ${formatCurrency(
                  payrollAmount
                )} from your account ending in ${accountNumber} on ${moment(
                  processingDate
                ).format(
                  commonDateFormat
                )}. Please make sure to have funds available.`}
              />

              <div className="authlessPayrollApproval">
                <Row>
                  <Col>
                    <div className="employerInfo">
                      <div className="employerInfo-body">
                        <div>
                          <div className="circle">
                            <span className="icon">
                              <FiGlobe
                                color="white"
                                stroke="#60A4BF"
                                size={16}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="employerInfo-intro">
                          <IconHeader
                            variant="labelHeader"
                            headerText="Company"
                          />
                          <IconSubheader subheader={companyName} />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="approval-box">
                      <div className="employerInfo">
                        <div className="employerInfo-body">
                          <div className="employerInfo-intro">
                            <IconHeader
                              variant="labelHeader"
                              headerText="Group"
                            />
                            <IconSubheader subheader={groupName} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="approval-box">
                      <div className="employerInfo">
                        <div className="employerInfo-body">
                          <div className="employerInfo-intro">
                            <IconHeader
                              variant="labelHeader"
                              headerText="Processing Date"
                            />
                            <IconSubheader
                              subheader={moment(processingDate).format(
                                commonDateFormat
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="approval-box">
                      <div className="employerInfo">
                        <div className="employerInfo-body">
                          <div className="employerInfo-intro">
                            <IconHeader
                              variant="labelHeader"
                              headerText="Total Contribution"
                            />
                            <IconSubheader
                              subheader={formatCurrency(payrollAmount)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="authlessPayrollApproval">
          <div className="authlessPayrollApproval-intro">
            <IconHeader variant="cardHeader" headerText="Process Payroll" />
            <IconSubheader subheader={payPeriod} />
          </div>
          <div className="authlessPayrollApproval-body">
            <IconHeader
              variant="referralHeader"
              headerText="Review and Submit"
            />
            <IconSubheader
              subheader={`By clicking submit, you authorize Icon Financial Services to debit your account ending in ${accountNumber} the amount of ${formatCurrency(
                payrollAmount
              )} on ${moment(processingDate).format(
                commonDateFormat
              )}. If the processing date falls on a weekend, US federal holidays, or after 5:30 PM Pacific, requests will be processed on the next business day.`}
            />

            <div>
              <Row>
                <Col sm={3}>
                  <div className="employerInfo">
                    <div className="employerInfo-body">
                      <div>
                        <div className="circle">
                          <span className="icon">
                            <FiGlobe color="white" stroke="#60A4BF" size={16} />
                          </span>
                        </div>
                      </div>
                      <div className="employerInfo-intro">
                        <IconHeader
                          variant="labelHeader"
                          headerText="Company"
                        />
                        <IconSubheader subheader={companyName} />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="approval-box">
                    <div className="employerInfo">
                      <div className="employerInfo-body">
                        <div className="employerInfo-intro">
                          <IconHeader
                            variant="labelHeader"
                            headerText="Group"
                          />
                          <IconSubheader subheader={groupName} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="approval-box">
                    <div className="employerInfo">
                      <div className="employerInfo-body">
                        <div className="employerInfo-intro">
                          <IconHeader
                            variant="labelHeader"
                            headerText="Processing Date"
                          />
                          <IconSubheader
                            subheader={moment(processingDate).format(
                              commonDateFormat
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="approval-box">
                    <div className="employerInfo">
                      <div className="employerInfo-body">
                        <div className="employerInfo-intro">
                          <IconHeader
                            variant="labelHeader"
                            headerText="Bank Account"
                          />
                          <IconSubheader subheader={accountNumber} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="approval-box">
                    <div className="employerInfo">
                      <div className="employerInfo-body">
                        <div className="employerInfo-intro">
                          <IconHeader
                            variant="labelHeader"
                            headerText="Total Contribution"
                          />
                          <IconSubheader
                            subheader={formatCurrency(payrollAmount)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="submit-row">
              <Button
                btnLabel="Approve Payroll"
                name="submit"
                withArrow={true}
                loading={this.props.isFetching}
                onClick={this._submitPayroll}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const actions = [payrollConstants.APPROVE_SESSION_TOKEN_PAYROLL];

const isLoadingSelector = createLoadingSelector(actions);
const isErrorSelector = createErrorSelector(actions);

const updatingSelector = createLoadingSelector(actions);

const mapStateToProps = (state) => {
  return {
    isFetching: isLoadingSelector(state),
    error: isErrorSelector(state),
    isUpdating: updatingSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  approveSessionTokenPayroll,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withSessionToken(AuthlessPayrollApproval)));
