import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Form } from "react-bootstrap";
import { chain, filter, find, isEmpty, map, get, size } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { clearUploadedDocuments } from "actions/userActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { toast } from "react-toastify";
import { getKybDocumentTypes } from "services/adminService";
import {
  getKybStatusAndInfo,
  submitBusinessDocuments,
  submitBusinessMemberDocuments,
} from "actions/adminActions";

import moment from "moment";
import FileUploader from "components/FileUploader";
import IconSpinner from "components/IconSpinner";
import Button from "components/Button";
import SimpleModal from "components/SimpleModal";
import IconTableHeader from "components/IconTableHeader";
import IconTable from "components/IconTable";

class MemberDocs extends React.PureComponent {
  static propTypes = {
    memberDocuments: PropTypes.array,
    allMemberDocuments: PropTypes.array,
    allBusinessDocuments: PropTypes.array,
    companyId: PropTypes.string,
    error: PropTypes.string,
    client: PropTypes.object,
    isSubmitting: PropTypes.bool,
    submitBusinessDocuments: PropTypes.func,
    submitBusinessMemberDocuments: PropTypes.func,
    getKybStatusAndInfo: PropTypes.func,
    clearUploadedDocuments: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      selectedDocTypeByMemberId: {},
      kybStatus: {},
      selectedMember: "",
      initialFetching: true,
      businessMembers: [],
      businessMemberDocuments: [],
      documentTypes: [],
      isFetching: false,
      isMounted: false,
      showMetadataModal: false,
      verificationDetails: [],
    };
  }

  async componentDidMount() {
    this.setState({ isMounted: true });
    await this._getKybMemberData();
    this.setState({ initialFetching: false });
  }

  async componentWillUnmount() {
    this.setState({
      isMounted: false,
    });
  }

  async _getKybMemberData() {
    this.setState({ isFetching: true });
    const {
      data: { getCompanyKybData: kybData, getKybStatusForCompany: kybStatus },
    } = await this.props.getKybStatusAndInfo(
      this.props.client,
      this.props.companyId
    );

    const {
      businessMembers,
      businessMemberDocuments,
      businessDocuments,
      employerProfile,
      silaWallet,
    } = kybData;

    const documentTypes = await getKybDocumentTypes(
      this.props.client,
      this.props.companyId
    );

    const formattedBusinessDocuments = chain(businessDocuments)
      .filter((doc) => {
        return doc.type === "OtherDocument";
      })
      .map((doc) => {
        return {
          ...doc,
          isBusinessDocument: true,
        };
      })
      .value();

    this.setState({
      businessMembers,
      businessMemberDocuments,
      businessDocuments: formattedBusinessDocuments,
      documentTypes,
      employerProfile: {
        ...employerProfile,
        silaWalletId: silaWallet.accountNumber,
      },
      kycResponses: kybStatus.kycResponses,
      isFetching: false,
    });
  }

  _submitDocuments = async () => {
    const hasMemberDocs = !isEmpty(this._getMemberDocuments());
    const hasBusinessDocs = !isEmpty(this._getBusinessDocuments());
    if (hasMemberDocs) {
      const formattedDocuments = map(
        this._getMemberDocuments(),
        ({
          docUrl: url,
          name,
          contentType,
          docType: type,
          metadata: { businessMemberId },
        }) => {
          return {
            url,
            name,
            contentType,
            type,
            metadata: {
              employerBusinessMemberId: businessMemberId,
            },
          };
        }
      );

      await this.props.submitBusinessMemberDocuments(this.props.client, {
        companyId: this.props.companyId,
        documents: formattedDocuments,
      });
    }

    if (hasBusinessDocs) {
      const formattedDocuments = map(
        this._getBusinessDocuments(),
        ({ docUrl: url, name, contentType, docType: type }) => {
          return {
            url,
            name,
            contentType,
            type,
            metadata: {},
          };
        }
      );

      await this.props.submitBusinessDocuments(this.props.client, {
        companyId: this.props.companyId,
        documents: formattedDocuments,
      });
    }

    if (!this.props.error) {
      toast.success("Successfully uploaded documents.");
      this.props.clearUploadedDocuments();

      setTimeout(() => {
        if (this.state.isMounted) {
          this._getKybMemberData();
        }
      }, 5000);
    } else {
      toast.error("Something went wrong with uploading documents.");
    }
  };

  // Status Tables
  _getStatusData = () => {
    return this.state.kycResponses.map((response) => {
      const entityId = response.message.split(" ")[0];

      return {
        referance: response.reference,
        entityType: response.entityType,
        status: response.status,
        message: response.message,
        verificationHistory: response.verificationHistory,
        entityId,
      };
    });
  };

  _buildStatusColumns = () => {
    const columns = [
      {
        label: "Entity ID",
        key: "entityId",
      },

      {
        label: "Entity Type",
        key: "entityType",
      },
      {
        label: "Status",
        key: "status",
      },
      {
        label: "Message",
        key: "message",
      },
      {
        label: "Metadata",
        customComponent: (props) => {
          return (
            <div>
              <a
                className={"link"}
                onClick={() => {
                  this.setState({
                    showMetadataModal: true,
                    verificationDetails: props.verificationHistory,
                  });
                }}
              >
                View Details
              </a>
            </div>
          );
        },
      },
    ];
    return columns;
  };

  _buildStatusSection = () => {
    const columns = this._buildStatusColumns();
    const data = this._getStatusData();
    return (
      <>
        <IconTableHeader tableHeader="Status" />
        <IconTable columns={columns} data={data} />
      </>
    );
  };

  _buildMembersSection = () => {
    return map(this.state.businessMembers, (member) => {
      const { firstName, lastName } = member.memberProfile;
      return this._buildUploaderSection({
        entityName: `${firstName} ${lastName}`,
        entityId: member.id,
        entityHandle: member.businessMemberHandle,
      });
    });
  };

  _buildBusinessUploadSection = () => {
    return this._buildUploaderSection({
      entityName: this.state.employerProfile.businessName,
      entityId: this.props.companyId,
      entityHandle: "",
      isCompany: true,
    });
  };

  _buildUploaderSection({ entityName, entityHandle, entityId, isCompany }) {
    const showUploader =
      isCompany || !!this.state.selectedDocTypeByMemberId[entityId];

    const docType = isCompany
      ? "other_doc"
      : this.state.selectedDocTypeByMemberId[entityId];

    const fileMetadata = isCompany
      ? { companyId: entityId }
      : { businessMemberId: entityId };

    const successMessage =
      "Your file was successfully uploaded. You can now add more files.";

    return (
      <div style={{ marginBottom: 16 }}>
        <h5 style={{ marginBottom: 4 }}>
          Upload docs for {entityName} {!isCompany && `- ${entityHandle}`}
        </h5>

        <article className="col-form" style={{ paddingBottom: 0 }}>
          {!isCompany && (
            <Form.Group
              as={Col}
              sm={6}
              controlId="formBasicSize"
              style={{ paddingLeft: 0 }}
            >
              <Form.Control
                as="select"
                name="documentType"
                defaultValue={""}
                value={this.state.selectedDocTypeByMemberId[entityId]}
                onChange={(e) =>
                  this.setState({
                    selectedDocTypeByMemberId: {
                      ...this.state.selectedDocTypeByMemberId,
                      [entityId]: e.target.value,
                    },
                  })
                }
              >
                <option value="">Select Document Type</option>
                {map(this.state.documentTypes, (doc) => {
                  return <option value={doc.id}>{doc.value}</option>;
                })}
              </Form.Control>
            </Form.Group>
          )}
          {showUploader && (
            <Form.Row>
              <FileUploader
                fileLimit={8}
                docType={docType}
                metadata={fileMetadata}
                fileUploadedMsg={successMessage}
              />
            </Form.Row>
          )}
        </article>
      </div>
    );
  }

  _calcNotEnoughDocs = () => {
    const noDocs =
      isEmpty(this._getMemberDocuments()) &&
      isEmpty(this._getBusinessDocuments());

    return noDocs;
  };

  //Member Docs Table
  _getMemeberDocsData = () => {
    return map(
      [...this.state.businessMemberDocuments, ...this.state.businessDocuments],
      ({ name, createdAt, contentType, memberId, id, isBusinessDocument }) => {
        const uploadDate = moment(createdAt).format("YYYY-MM-DD");
        let entityDetails;

        if (isBusinessDocument) {
          entityDetails = `${this.state.employerProfile.businessName} - ${this.state.employerProfile.silaWalletId}`;
        } else {
          const member = find(this.state.businessMembers, { id: memberId });
          const { firstName, lastName } = member.memberProfile;
          entityDetails = `${firstName} ${lastName} - ${member.businessMemberHandle}`;
        }

        return {
          id,
          name,
          contentType,
          uploadDate,
          entityDetails,
        };
      }
    );
  };

  _buildMemberDocsColumns = () => {
    const columns = [
      {
        label: "Document Type",
        key: "name",
      },

      {
        label: "File Type",
        key: "contentType",
      },
      {
        label: "Upload Date",
        key: "uploadDate",
      },
      {
        label: "Member",
        key: "entityDetails",
      },
    ];
    return columns;
  };

  _getMemberDocsTable = () => {
    const columns = this._buildMemberDocsColumns();
    const data = this._getMemeberDocsData();

    const noDocs =
      isEmpty(this.state.businessMemberDocuments) &&
      isEmpty(this.state.businessDocuments);
    if (noDocs) {
      return null;
    }

    return (
      <div className="business-member-documents">
        <IconTableHeader
          tableHeader="Submitted Documents"
          tableCount={size(data)}
        />
        <IconTable columns={columns} data={data} />
      </div>
    );
  };

  //Verification Table
  _getVerificationData = () => {
    return this.state.verificationDetails.map((verification) => {
      const tags = verification.tags.join(", ");
      const reasons = verification.reasons.join(", ");
      return {
        id: verification.verificationId,
        status: verification.verificationStatus,
        kyc: verification.kycLevel,
        tags,
        reasons,
      };
    });
  };

  _buildVerificationColumns = () => {
    const columns = [
      {
        label: "Verification Status",
        key: "status",
      },

      {
        label: "KYC Level",
        key: "kyc",
      },
      {
        label: "Tags",
        key: "tags",
      },
      {
        label: "Reasons",
        key: "reasons",
      },
    ];
    return columns;
  };

  _buildVerificationDetailsTable = () => {
    const columns = this._buildVerificationColumns();
    const data = this._getVerificationData();

    return (
      <>
        <IconTableHeader
          tableHeader="Verification Details"
          tableCount={size(data)}
        />
        <IconTable columns={columns} data={data} />
      </>
    );
  };

  _getMemberDocuments = () => {
    return filter(this.props.allMemberDocuments, (document) => {
      return get(this.state, "businessMembers", [])
        .map((m) => m.id)
        .includes(document.metadata.businessMemberId);
    });
  };

  _getBusinessDocuments = () => {
    return filter(this.props.allBusinessDocuments, (document) => {
      return this.props.companyId === document.metadata.companyId;
    });
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    const notEnoughDocuments = this._calcNotEnoughDocs();

    return (
      <div className="member-docs">
        {this.state.isFetching && <IconSpinner centered />}
        {this.state.showMetadataModal && (
          <SimpleModal
            show={true}
            size={"lg"}
            onClose={() =>
              this.setState({
                showMetadataModal: false,
                verificationDetails: {},
              })
            }
            title="Verification Details"
            buttonText="close"
          >
            {this._buildVerificationDetailsTable()}
          </SimpleModal>
        )}

        <h3>KYB Details:</h3>
        {this._buildStatusSection()}
        <h3>Upload Member Documents:</h3>
        {this._buildMembersSection()}
        <h3 style={{ marginTop: 8 }}>Upload Business Documents:</h3>
        {this._buildBusinessUploadSection()}
        <Button
          disabled={notEnoughDocuments}
          onClick={this._submitDocuments}
          withArrow
          name="submit"
          btnLabel={"Submit Documents"}
          loading={this.props.isSubmitting}
        />
        {this._getMemberDocsTable()}
      </div>
    );
  }
}

const isSubmittingSelector = createLoadingSelector([
  adminConstants.SUBMIT_BUSINESS_KYB_DOCUMENTS,
  adminConstants.SUBMIT_BUSINESS_MEMBERS_KYB_DOCUMENTS,
]);

const errorSelector = createErrorSelector(
  adminConstants.SUBMIT_BUSINESS_KYB_DOCUMENTS,
  adminConstants.SUBMIT_BUSINESS_MEMBERS_KYB_DOCUMENTS,
  adminConstants.GET_KYB_STATUS
);

const mapStateToProps = (state) => {
  const memberDocuments = filter(state.userDocuments.documents, (document) => {
    return document.metadata && document.metadata.businessMemberId;
  });
  const businessDocuments = filter(
    state.userDocuments.documents,
    (document) => {
      return document.metadata && document.metadata.companyId;
    }
  );
  return {
    allMemberDocuments: memberDocuments,
    allBusinessDocuments: businessDocuments,
    isSubmitting: isSubmittingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  submitBusinessDocuments,
  submitBusinessMemberDocuments,
  getKybStatusAndInfo,
  clearUploadedDocuments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(MemberDocs));
