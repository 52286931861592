import React from "react";
import PropTypes from "prop-types";
import Alert from "components/Alert";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";

import { FiAlertTriangle } from "react-icons/fi";

const IconBanner = ({ title, subheader, onClick, errorMsg, error }) => (
  <div className="rollover-banner">
    <div className="rollover-box">
      <div className="employerInfo">
        <div className="employerInfo-body">
          <div>
            <div className="re-auth-circle">
              <span className="icon">
                <FiAlertTriangle color="white" stroke="#B12121" size={16} />
              </span>
            </div>
          </div>
          <div className="employerInfo-intro">
            <IconHeader variant="labelHeader" headerText={title} />
            <IconSubheader subheader={subheader} />
          </div>
        </div>
        <div className="employerInfo-action"> {onClick}</div>
      </div>
    </div>
    {error && <Alert msg={errorMsg} type="error" />}
  </div>
);

IconBanner.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  onClick: PropTypes.func,
  errorMsg: PropTypes.string,
  error: PropTypes.bool,
};

export default IconBanner;
