import React from "react";
import PropTypes from "prop-types";
import IconSpinner from "components/IconSpinner";
import Alert from "components/Alert";
import { get, includes } from "lodash";
import { push } from "connected-react-router";
import { setTab } from "actions/navigationActions";
import {
  getProgressiveOnboarding,
  getSetupIntent,
  hydrateDashboard,
} from "actions/employerActions";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { employerConstants } from "actions/types";
import { createErrorSelector } from "store/selectors";
import {
  hasFullyOnboarded,
  requiresBillingMicroDepositVerification,
  requiresBillingPaymentUpdate,
} from "store/selectors/employer";
import {
  EmployerApplicationAdditionalDocumentationSubmitted,
  EmployerApplicationRequiresAdditionalDocumentation,
  EmployerApplicationSubmittedKYB,
  EmployerBillingVerificationPending,
  EmployerPendingKYBSubmission,
  EmployerRegistrationComplete,
  EmployerSelfServeRegistrationComplete,
  EmployerTermsComplete,
} from "statics/states";
import KybDashboard from "pages/dashboards/employerDashboard/dashboard/KybDashboard";
import EmployerDashboard from "pages/dashboards/employerDashboard/dashboard/EmployerDashboard";
import ProgressiveOnboardingDashboard from "pages/dashboards/employerDashboard/dashboard/ProgressiveOnboardingDashboard";

const stillKybStates = [
  EmployerApplicationAdditionalDocumentationSubmitted,
  EmployerApplicationRequiresAdditionalDocumentation,
  EmployerApplicationSubmittedKYB,
  EmployerRegistrationComplete,
  EmployerTermsComplete,
  EmployerPendingKYBSubmission,
  EmployerSelfServeRegistrationComplete,
  EmployerBillingVerificationPending,
];

/*
 * Dashboard container is merely a wrapper for whatever subdashboard we are rendering
 * Its only purpose is to determine what subdashboard to render based on a defined rule set
 * This will allow us to concentrate each dashboards rendering/display logic in
 * */

class DashboardContainer extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    hydrateDashboard: PropTypes.func.isRequired,
    getSetupIntent: PropTypes.func.isRequired,
    getProgressiveOnboarding: PropTypes.func.isRequired,
    error: PropTypes.string,
    companyState: PropTypes.string,
    hasFullyOnboarded: PropTypes.bool,
    requiresBillingMicroDepositVerification: PropTypes.bool,
    requiresPaymentMethodUpdate: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      initialFetching: true,
    };
  }

  componentDidMount() {
    window.analytics.page("Employer Dashboard");

    const promises = [
      this.props.getProgressiveOnboarding(this.props.client),
      this.props.getSetupIntent(this.props.client),
    ];

    Promise.all(promises).then(() => {
      this.setState({
        initialFetching: false,
      });
    });
  }

  _getActiveDashboard = () => {
    const fullyOnboarded = this.props.hasFullyOnboarded;

    if (
      includes(stillKybStates, this.props.companyState) ||
      this.props.requiresBillingMicroDepositVerification ||
      this.props.requiresPaymentMethodUpdate
    ) {
      return <KybDashboard />;
    } else if (!fullyOnboarded) {
      return <ProgressiveOnboardingDashboard />;
    } else {
      return <EmployerDashboard />;
    }
  };

  render() {
    const activeDashboard = this.state.initialFetching
      ? null
      : this._getActiveDashboard();
    return (
      <div className="dashboard-content">
        {this.state.initialFetching && <IconSpinner centered />}
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        {!this.state.initialFetching && (
          <div className="mega-container" id="employer-dashboard">
            {activeDashboard}
          </div>
        )}
      </div>
    );
  }
}

const errorSelector = createErrorSelector(employerConstants.HYDRATE_DASHBOARD);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    hasFullyOnboarded: hasFullyOnboarded(state),
    companyState: get(state.user.companyState, "state", ""),
    requiresBillingMicroDepositVerification:
      requiresBillingMicroDepositVerification(state),
    requiresPaymentMethodUpdate: requiresBillingPaymentUpdate(state),
  };
};

const mapDispatchToProps = {
  push,
  setTab,
  hydrateDashboard,
  getProgressiveOnboarding,
  getSetupIntent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(DashboardContainer));
