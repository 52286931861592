import { bankConstants } from "./types";
import bankService from "services/bankService";
import ActionCreator from "utils/actionHandler";
import { pick } from "lodash";

function getRequiredPlaidAccountFields(account) {
  return pick(account, [
    "id",
    "name",
    "type",
    "subtype",
    "mask",
    "verification_status",
  ]);
}

function formatPlaidMetadata(metadata) {
  const formattedMetadata = pick(metadata, [
    "public_token",
    "account_id",
    "link_session_id",
    "accounts",
    "account",
    "institution",
  ]);

  return {
    ...formattedMetadata,
    institution: pick(formattedMetadata.institution, [
      "name",
      "institution_id",
    ]),
    account: getRequiredPlaidAccountFields(formattedMetadata.account),
    accounts: formattedMetadata.accounts.map(getRequiredPlaidAccountFields),
  };
}

export const linkBank = (client, public_token, metadata) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(bankConstants.LINK_BANK_WITH_ICON);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      bankService.linkBank(client, public_token, formatPlaidMetadata(metadata))
    );
  };
};

export const linkBankIndividual = (
  client,
  public_token,
  metadata,
  ownerName
) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(bankConstants.LINK_BANK_WITH_ICON);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      bankService.linkBankIndividual(
        client,
        public_token,
        formatPlaidMetadata(metadata),
        ownerName
      )
    );
  };
};

export const deleteBank = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(bankConstants.DELETE_BANK);
    dispatch(actionCreator.request());
    return bankService
      .deleteBankIndividual(client, accountId)
      .then(() => dispatch(actionCreator.success(accountId)))
      .catch((error) => dispatch(actionCreator.failure(error)));
  };
};

export const deleteEmployerBank = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(bankConstants.DELETE_BANK);
    dispatch(actionCreator.request());
    return bankService
      .deleteBankEmployer(client, accountId)
      .then(() => dispatch(actionCreator.success(accountId)))
      .catch((error) => dispatch(actionCreator.failure(error)));
  };
};

export const verifyBankAccount = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(bankConstants.VERIFY_BANK);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      bankService.verifyBankAccountRequest(client, accountId)
    );
  };
};

export const getPlaidLinkToken = (client, bankAccountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(bankConstants.GET_PLAID_LINK_TOKEN);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      bankService.getPlaidLinkToken(client, bankAccountId)
    );
  };
};

export const oAuthPlaidLinkError = (error) => {
  const actionCreator = new ActionCreator(bankConstants.OAUTH_PLAID_LINK_TOKEN);
  return actionCreator.failure(error);
};

export const getReauthorizationLinkToken = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      bankConstants.GET_REAUTHORIZATION_LINK_TOKEN
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      bankService.getReauthorizationLinkToken(client, accountId)
    );
  };
};

export const getExpiringEmployerBanks = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      bankConstants.GET_EXPIRING_EMPLOYER_BANKS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      bankService.getExpiringEmployerBanks(client)
    );
  };
};

export const updateLinkToken = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(bankConstants.UPDATE_LINK_TOKEN);
    dispatch(actionCreator.request());
    return bankService
      .updateLinkToken(client, accountId)
      .then(() => dispatch(actionCreator.success(accountId)))
      .catch((error) => dispatch(actionCreator.failure(error)));
  };
};

export const getIndividualReauthorizationLinkToken = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      bankConstants.GET_INDIVIDUAL_REAUTHORIZATION_LINK_TOKEN
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      bankService.getIndividualReauthorizationLinkToken(client, accountId)
    );
  };
};

export const getExpiringIndividualBanks = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      bankConstants.GET_EXPIRING_INDIVIDUAL_BANKS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      bankService.getExpiringIndividualBanks(client)
    );
  };
};
