import React from "react";
import PropTypes from "prop-types";
import { get, map } from "lodash";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Row, Col, Tab, Tabs, Card } from "react-bootstrap";

import { setTab } from "actions/navigationActions";
import ProfileInfo from "./ProfileInfo";
import CloseAccount from "./CloseAccount";
import LoginInfo from "./LoginInfo";
import SuitabilityProfile from "./SuitabilityProfile";
const tabs = [
  {
    label: "Info",
    id: "info",
  },
  {
    label: "Investment Profile",
    id: "investment-profile",
  },
  {
    label: "Login Info",
    id: "login-info",
  },
  {
    label: "Close Account",
    id: "close-account",
  },
];

function calcActiveTab(routeName) {
  switch (routeName) {
    case "/dashboard/profile/info":
      return "info";
    case "/dashboard/profile/close-account":
      return "close-account";
    case "/dashboard/profile/login-info":
      return "login-info";
    case "/dashboard/profile/investment-profile":
      return "investment-profile";
    default:
      return "info";
  }
}

import "./IndividualProfile.scss";
class IndividualProfile extends React.PureComponent {
  static propTypes = {
    routeName: PropTypes.string,
    push: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      tab: calcActiveTab(props.routeName),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.routeName !== this.props.routeName) {
      this.setState({ tab: calcActiveTab() });
    }
  }

  tabUpdate(value) {
    this.setState({ tab: value.k });
    this.props.push("/dashboard/profile/" + value.k);
  }

  _controlledTabs() {
    return (
      <Tabs
        activeKey={this.state.tab}
        id="tab-company"
        onSelect={(k) => this.tabUpdate({ k })}
      >
        {map(tabs, (tab) => {
          return <Tab key={tab.id} eventKey={tab.id} title={tab.label} />;
        })}
      </Tabs>
    );
  }

  _getCardContent = () => {
    switch (this.state.tab) {
      case "info":
        return <ProfileInfo />;
      case "close-account":
        return <CloseAccount />;
      case "login-info":
        return <LoginInfo />;
      case "investment-profile":
        return <SuitabilityProfile />;
    }
  };

  render() {
    const content = this._getCardContent();
    return (
      <div className="mega-container" id="user-profile">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">User Profile</h1>
            <p className="page-subtext">
              Manage your user information and linked bank accounts.
            </p>
            <div className="main-content">
              <Row>
                <Col>
                  <div className="card-tab">{this._controlledTabs()}</div>
                  <Card className="dashboard-widget">
                    <Card.Body>{content}</Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const routeName = get(state.router, "location.pathname");
  return { routeName };
};

const mapDispatchToProps = { push, setTab };

export default connect(mapStateToProps, mapDispatchToProps)(IndividualProfile);
