import gql from "graphql-tag";

function register(client, user) {
  return new Promise((resolve, reject) => {
    // The operation name of Signup is very important for the backend to skip JWT auth so do not change
    const ADD_USER = gql`
      mutation Signup(
        $email: String!
        $password: String!
        $username: String!
        $userRole: String!
        $firstName: String
        $lastName: String
        $invitationCode: String
        $inviteId: String
        $referralCode: String
        $planCode: String
      ) {
        signUp(
          email: $email
          password: $password
          username: $username
          userRole: $userRole
          invitationCode: $invitationCode
          inviteId: $inviteId
          referralCode: $referralCode
          planCode: $planCode
          firstName: $firstName
          lastName: $lastName
        ) {
          id
          error
        }
      }
    `;
    const result = client.mutate({
      mutation: ADD_USER,
      variables: {
        email: user.email,
        password: user.password,
        username: user.username,
        userRole: user.role,
        invitationCode: user.invitationCode,
        inviteId: user.inviteId,
        referralCode: user.referralCode,
        planCode: user.planCode,
        firstName: user.firstName,
        lastName: user.lastName,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function registerAdmin(client, user) {
  return new Promise((resolve, reject) => {
    const ADD_USER = gql`
      mutation Register(
        $email: String!
        $username: String!
        $providerId: String!
      ) {
        addAdminAccount(
          email: $email
          username: $username
          providerId: $providerId
        ) {
          id
          error
        }
      }
    `;
    const result = client.mutate({
      mutation: ADD_USER,
      variables: {
        email: user.email,
        username: user.username,
        providerId: user.providerId,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

// user login by email and password
function getState(client) {
  return new Promise((resolve, reject) => {
    const USER_LOGIN = gql`
      query GetState($rid: String!) {
        getState(rid: $rid) {
          user {
            id
            userRoleId
            username
            emailConfirmed
          }
          userState {
            version
            state
            causedByEvent
            newestVersion
          }
          companyState {
            version
            state
            causedByEvent
            newestVersion
          }
          company {
            id
            name
            dba
            ein
            type
            naicsCode
            size
            url
            planId
            planCode
            enabledProductFeatures
            hasRunFirstPayroll
            referralId
            billingType
            referralPlans {
              min
              max
              planId
              referralCredit
              refereeCredit
            }
            address {
              id
              address1
              address2
              city
              state
              postalCode
              phone
              phoneExt
              fax
            }
            planTypes {
              id
              description
              name
            }
            progressiveOnboardingState
            payrollProvider
            limits {
              key
              value
            }
          }
          featureFlags {
            key
            value
          }
        }
      }
    `;
    const result = client.query({
      query: USER_LOGIN,
      variables: { rid: "id-" + Math.random() },
    });
    result.then(
      (data) => {
        resolve(data.data.getState);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getIraAccountForm(client) {
  return new Promise((resolve, reject) => {
    const IRA_ACCOUNT_FORM = gql`
      query GetIraAccountForm {
        getIraAccountForm {
          account {
            type
          }
          identity {
            firstName
            lastName
            legalName
            address1
            address2
            city
            state
            postalCode
            phone
            dob
            ssn
            citizenshipCountry
            permanentResident
            birthCountry
          }
          disclosure {
            tradeAuthorization
            controlPerson
            stockSymbols
            exchangeOrFINRA
            firmName
            politicallyExposed
            organizationName
            familyNames
            docUrl
            isCustodianEmployee
          }
          investmentProfile {
            objective
            experience
            annualIncome {
              min
              max
            }
            liquidNetWorth {
              min
              max
            }
            totalNetWorth {
              min
              max
            }
            riskTolerance
            riskToleranceRawValue
          }
          suitability {
            timeHorizon
            liquidityNeeds
            timeHorizonLiquidity
          }
          employmentStatus {
            status
            employer
            position
          }
          trustedContact {
            trustedContact
            firstName
            lastName
            email
          }
          terms {
            accountAgreement
            termsOfService
          }
        }
      }
    `;
    const result = client.query({
      query: IRA_ACCOUNT_FORM,
    });
    result.then(
      (data) => {
        resolve(data.data.getIraAccountForm);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateIraAccountIdentity(client, iraAccountData) {
  return new Promise((resolve, reject) => {
    const UPDATE_ACCOUNT_IDENTITY = gql`
      mutation UpdateIraAccountIdentity(
        $firstName: String!
        $lastName: String!
        $legalName: String
        $address1: String!
        $address2: String
        $city: String!
        $state: String!
        $postalCode: String!
        $phone: String!
        $dob: String!
        $ssn: String!
      ) {
        updateIraAccountIdentity(
          firstName: $firstName
          lastName: $lastName
          legalName: $legalName
          address1: $address1
          address2: $address2
          city: $city
          state: $state
          postalCode: $postalCode
          phone: $phone
          dob: $dob
          ssn: $ssn
        ) {
          identity {
            firstName
            lastName
            legalName
            address1
            address2
            city
            state
            postalCode
            phone
            dob
            ssn
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_ACCOUNT_IDENTITY,
      variables: {
        firstName: iraAccountData.firstName,
        lastName: iraAccountData.lastName,
        legalName: iraAccountData.legalName,
        address1: iraAccountData.address1,
        address2: iraAccountData.address2,
        city: iraAccountData.city,
        state: iraAccountData.state,
        postalCode: iraAccountData.postalCode,
        phone: iraAccountData.phone,
        dob: iraAccountData.dob,
        ssn: iraAccountData.ssn,
      },
    });
    result.then(
      (identityResponse) => {
        if (identityResponse.data) {
          resolve(identityResponse.data);
        } else {
          reject(identityResponse);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function updateIraAccountUserCitizenship(client, userCitizenship) {
  return new Promise((resolve, reject) => {
    const UPDATE_USERCITIZENSHIP = gql`
      mutation updateIraAccountUserCitizenship(
        $citizenshipCountry: String!
        $permanentResident: Boolean!
        $birthCountry: String
      ) {
        updateIraAccountUserCitizenship(
          citizenshipCountry: $citizenshipCountry
          permanentResident: $permanentResident
          birthCountry: $birthCountry
        ) {
          identity {
            citizenshipCountry
            permanentResident
            birthCountry
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_USERCITIZENSHIP,
      variables: {
        citizenshipCountry: userCitizenship.citizenshipCountry,
        permanentResident: userCitizenship.permanentResident,
        birthCountry: userCitizenship.birthCountry,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function updateIraAccountDisclosure(client, disclosure) {
  return new Promise((resolve, reject) => {
    const UPDATE_ACCOUNT_DISCLOSURE = gql`
      mutation UpdateIraAccountDisclosure(
        $tradeAuthorization: Boolean!
        $controlPerson: Boolean!
        $stockSymbols: [String]
        $exchangeOrFINRA: Boolean!
        $firmName: String
        $politicallyExposed: Boolean!
        $organizationName: String
        $familyNames: [String]
        $docUrl: String
        $isCustodianEmployee: Boolean!
      ) {
        updateIraAccountDisclosure(
          tradeAuthorization: $tradeAuthorization
          controlPerson: $controlPerson
          stockSymbols: $stockSymbols
          exchangeOrFINRA: $exchangeOrFINRA
          firmName: $firmName
          politicallyExposed: $politicallyExposed
          organizationName: $organizationName
          familyNames: $familyNames
          docUrl: $docUrl
          isCustodianEmployee: $isCustodianEmployee
        ) {
          disclosure {
            tradeAuthorization
            controlPerson
            stockSymbols
            exchangeOrFINRA
            firmName
            politicallyExposed
            organizationName
            familyNames
            docUrl
            isCustodianEmployee
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_ACCOUNT_DISCLOSURE,
      variables: {
        tradeAuthorization: disclosure.tradeAuthorization,
        controlPerson: disclosure.controlPerson,
        stockSymbols: disclosure.stockSymbols,
        exchangeOrFINRA: disclosure.exchangeOrFINRA,
        firmName: disclosure.firmName,
        politicallyExposed: disclosure.politicallyExposed,
        organizationName: disclosure.organizationName,
        familyNames: disclosure.familyNames,
        docUrl: disclosure.docUrl,
        isCustodianEmployee: disclosure.isCustodianEmployee,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function updateIraAccountInvestmentProfile(client, investmentProfile) {
  return new Promise((resolve, reject) => {
    const UPDATE_ACCOUNT_INVESTMENT_PROFILE = gql`
      mutation UpdateIraAccountInvestmentProfile(
        $objective: InvestmentObjective!
        $experience: InvestmentExperience!
        $annualIncome: MonetaryRangeInput!
        $liquidNetWorth: MonetaryRangeInput!
        $totalNetWorth: MonetaryRangeInput!
        $riskTolerance: InvestmentRisk!
        $riskToleranceRawValue: String!
        $formMetadata: String!
      ) {
        updateIraAccountInvestmentProfile(
          objective: $objective
          experience: $experience
          annualIncome: $annualIncome
          liquidNetWorth: $liquidNetWorth
          totalNetWorth: $totalNetWorth
          riskTolerance: $riskTolerance
          riskToleranceRawValue: $riskToleranceRawValue
          formMetadata: $formMetadata
        ) {
          investmentProfile {
            objective
            experience
            annualIncome {
              min
              max
              rawValue
            }
            liquidNetWorth {
              min
              max
            }
            totalNetWorth {
              min
              max
            }
            riskTolerance
            formMetadata
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_ACCOUNT_INVESTMENT_PROFILE,
      variables: {
        objective: investmentProfile.objective,
        experience: investmentProfile.experience,
        annualIncome: investmentProfile.annualIncome,
        liquidNetWorth: investmentProfile.liquidNetWorth,
        totalNetWorth: investmentProfile.totalNetWorth,
        riskTolerance: investmentProfile.riskTolerance,
        riskToleranceRawValue: investmentProfile.riskToleranceRawValue,
        formMetadata: investmentProfile.formMetadata,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function updateIraAccountSuitability(client, suitability) {
  return new Promise((resolve, reject) => {
    const UPDATE_ACCOUNT_SUITABILITY = gql`
      mutation UpdateIraAccountSuitability(
        $timeHorizon: TimeHorizon!
        $liquidityNeeds: LiquidityNeeds!
        $timeHorizonLiquidity: String!
      ) {
        updateIraAccountSuitability(
          timeHorizon: $timeHorizon
          liquidityNeeds: $liquidityNeeds
          timeHorizonLiquidity: $timeHorizonLiquidity
        ) {
          suitability {
            timeHorizon
            liquidityNeeds
            timeHorizonLiquidity
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_ACCOUNT_SUITABILITY,
      variables: {
        timeHorizon: suitability.timeHorizon,
        liquidityNeeds: suitability.liquidityNeeds,
        timeHorizonLiquidity: suitability.timeHorizonLiquidity,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function updateIraAccountEmploymentStatus(client, employmentStatus) {
  return new Promise((resolve, reject) => {
    const UPDATE_ACCOUNT_EMPLOYMENTSTATUS = gql`
      mutation UpdateIraAccountEmploymentStatus(
        $status: String!
        $employer: String!
        $position: String!
      ) {
        updateIraAccountEmploymentStatus(
          status: $status
          employer: $employer
          position: $position
        ) {
          employmentStatus {
            status
            employer
            position
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_ACCOUNT_EMPLOYMENTSTATUS,
      variables: {
        status: employmentStatus.status,
        employer: employmentStatus.employer,
        position: employmentStatus.position,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function updateIraAccountEmploymentPosition(client, employmentPosition) {
  return new Promise((resolve, reject) => {
    const UPDATE_ACCOUNT_EMPLOYMENTPOSITION = gql`
      mutation updateIraAccountEmploymentPosition($position: String!) {
        updateIraAccountEmploymentPosition(position: $position) {
          employmentStatus {
            position
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_ACCOUNT_EMPLOYMENTPOSITION,
      variables: {
        position: employmentPosition.position,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function updateIraAccountTrustedContact(client, trustedContact) {
  return new Promise((resolve, reject) => {
    const UPDATE_ACCOUNT_TRUSTEDCONTACT = gql`
      mutation UpdateIraAccountTrustedContact(
        $trustedContact: Boolean!
        $firstName: String
        $lastName: String
        $email: String
      ) {
        updateIraAccountTrustedContact(
          trustedContact: $trustedContact
          firstName: $firstName
          lastName: $lastName
          email: $email
        ) {
          trustedContact {
            trustedContact
            firstName
            lastName
            email
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_ACCOUNT_TRUSTEDCONTACT,
      variables: {
        trustedContact: trustedContact.trustedContact,
        firstName: trustedContact.firstName,
        lastName: trustedContact.lastName,
        email: trustedContact.email,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function booleanValue(val) {
  if (val) {
    return val === true || val.toString() === "true";
  }
  return false;
}

function updateIraAccountTermsAndSubmit(client, terms) {
  return new Promise((resolve, reject) => {
    const UPDATE_ACCOUNT_TERMS_SUBMIT = gql`
      mutation UpdateIraAccountTermsAndSubmit(
        $termsOfService: Boolean!
        $accountAgreement: Boolean!
      ) {
        updateIraAccountTermsAndSubmit(
          termsOfService: $termsOfService
          accountAgreement: $accountAgreement
        ) {
          terms {
            termsOfService
            accountAgreement
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_ACCOUNT_TERMS_SUBMIT,
      variables: {
        termsOfService: booleanValue(terms.termsOfService),
        accountAgreement: booleanValue(terms.accountAgreement),
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function updateEmployerAccountTermsAndSubmit(client, terms) {
  return new Promise((resolve, reject) => {
    const UPDATE_EMPLOYER_ACCOUNT_TERMS_SUBMIT = gql`
      mutation UpdateEmployerAccountTermsAndSubmit($termsOfService: Boolean!) {
        updateEmployerAccountTermsAndSubmit(termsOfService: $termsOfService)
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_EMPLOYER_ACCOUNT_TERMS_SUBMIT,
      variables: {
        termsOfService: terms.termsOfService,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

// user logout
function logout(userId) {
  // Any cleanup needed to remove local users or on the server
  return new Promise((resolve) => {
    resolve(userId);
  });
}

// confirm email address
function confirmEmail(client) {
  return new Promise((resolve, reject) => {
    const SEND_CONFIRM_EMAIL = gql`
      mutation userEmailConfirmed {
        userEmailConfirmed
      }
    `;
    const result = client.mutate({
      mutation: SEND_CONFIRM_EMAIL,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.userEmailConfirmed);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getValidatedAddress(client, address) {
  return new Promise((resolve, reject) => {
    const GET_VALIDATED_ADDRESS = gql`
      query GetValidatedAddress(
        $address1: String!
        $address2: String
        $city: String!
        $state: String!
        $postalCode: String!
      ) {
        getValidatedAddress(
          address1: $address1
          address2: $address2
          city: $city
          state: $state
          postalCode: $postalCode
        ) {
          address1
          address2
          city
          state
          postalCode
        }
      }
    `;

    const result = client.query({
      query: GET_VALIDATED_ADDRESS,
      variables: address,
    });
    result.then(
      (data) => {
        resolve(data.data.getValidatedAddress);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function userCompletedKyc(client) {
  return new Promise((resolve, reject) => {
    const USER_COMPLETED_KYC = gql`
      mutation userCompletedKyc {
        userCompletedKyc {
          version
          state
          causedByEvent
          newestVersion
        }
      }
    `;
    const result = client.mutate({
      mutation: USER_COMPLETED_KYC,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.userCompletedKyc);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function userCompletedOnboarding(client) {
  return new Promise((resolve, reject) => {
    const USER_COMPLETED_SIGNUP = gql`
      mutation userCompletedOnboarding {
        userCompletedOnboarding {
          version
          state
          causedByEvent
          newestVersion
        }
      }
    `;
    const result = client.mutate({
      mutation: USER_COMPLETED_SIGNUP,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.userCompletedOnboarding);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateIraYellowPathDocuments(client, documents) {
  return new Promise((resolve, reject) => {
    const UPDATE_IRA_YELLOWPATH_DOCS = gql`
      mutation updateIraYellowPathDocuments($documents: [DocumentInput!]) {
        updateIraYellowPathDocuments(documents: $documents) {
          identity {
            firstName
            lastName
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_IRA_YELLOWPATH_DOCS,
      variables: { documents },
    });
    result.then(
      (data) => {
        resolve(data.data.updateIraYellowPathDocuments);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getNeededYellowPathDocs(client) {
  return new Promise((resolve, reject) => {
    const GET_NEEDED_DOCUMENTS = gql`
      query getNeededDocumentation {
        getNeededDocumentation {
          primaryNeededDocuments
          secondaryNeededDocuments
          tags
        }
      }
    `;
    const result = client.query({
      query: GET_NEEDED_DOCUMENTS,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.getNeededDocumentation);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getUserProfile(client) {
  return new Promise((resolve, reject) => {
    const USER_PROFILE = gql`
      query getUserProfile {
        getUserProfile {
          legalName
          firstName
          lastName
          address1
          address2
          city
          state
          postalCode
          phoneNumber
          email
          dob
        }
      }
    `;
    const result = client.query({
      query: USER_PROFILE,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.getUserProfile);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateUserProfile(client, profileParams) {
  return new Promise((resolve, reject) => {
    const UPDATE_USER_PROFILE = gql`
      mutation updateUserProfile($profileParams: UserProfileInput) {
        updateUserProfile(profileParams: $profileParams) {
          legalName
          firstName
          lastName
          address1
          address2
          city
          state
          postalCode
          phoneNumber
          email
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_USER_PROFILE,
      variables: {
        profileParams,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.updateUserProfile);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function closeUserAccount(client, accountId, reason) {
  return new Promise((resolve, reject) => {
    const CLOSE_ACCOUNT = gql`
      mutation closeAccount($accountId: ID!, $reason: String!) {
        closeAccount(accountId: $accountId, reason: $reason)
      }
    `;
    const result = client.mutate({
      mutation: CLOSE_ACCOUNT,
      variables: {
        accountId,
        reason,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function deleteEmailChangeRequest(client, emailChangeRequestId) {
  return new Promise((resolve, reject) => {
    const DELETE_EMAIL_CHANGE = gql`
      mutation deleteEmailChangeRequest($emailChangeRequestId: ID!) {
        deleteEmailChangeRequest(emailChangeRequestId: $emailChangeRequestId)
      }
    `;
    const result = client.mutate({
      mutation: DELETE_EMAIL_CHANGE,
      variables: {
        emailChangeRequestId,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function requestEmailChange(client, email) {
  return new Promise((resolve, reject) => {
    const EMAIL_CHANGE = gql`
      mutation requestEmailChange($email: String!) {
        requestEmailChange(email: $email) {
          id
          createdAt
          fromEmail
          toEmail
          userId
        }
      }
    `;
    const result = client.mutate({
      mutation: EMAIL_CHANGE,
      variables: {
        email,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.requestEmailChange);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function verifyEmailChangeRequest(client, emailChangeRequestId) {
  return new Promise((resolve, reject) => {
    const VERIFY_EMAIL_CHANGE = gql`
      mutation verifyEmailChange($emailChangeRequestId: ID!) {
        verifyEmailChange(emailChangeRequestId: $emailChangeRequestId)
      }
    `;
    const result = client.mutate({
      mutation: VERIFY_EMAIL_CHANGE,
      variables: {
        emailChangeRequestId,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getEmailChangeRequest(client, email) {
  return new Promise((resolve, reject) => {
    const EMAIL_CHANGE = gql`
      query getEmailChangeRequest($email: String!) {
        getEmailChangeRequest(email: $email) {
          id
          createdAt
          fromEmail
          toEmail
          userId
        }
      }
    `;
    const result = client.query({
      query: EMAIL_CHANGE,
      variables: {
        email,
      },
    });

    result.then(
      (data) => {
        resolve(data.data.getEmailChangeRequest);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getInvestmentProfileAndSuitability(client) {
  return new Promise((resolve, reject) => {
    const GET_INVESTMENT_PROFILE_AND_SUITABILITY = gql`
      query getInvestmentProfileAndSuitability($accountId: ID) {
        getInvestmentProfile(accountId: $accountId) {
          objective
          experience
          annualIncome {
            min
            max
            rawValue
          }
          liquidNetWorth {
            min
            max
          }
          totalNetWorth {
            min
            max
          }
          riskTolerance
          riskToleranceRawValue
          formMetadata
        }
        getSuitability(accountId: $accountId) {
          timeHorizon
          liquidityNeeds
          timeHorizonLiquidity
        }
      }
    `;
    const result = client.query({
      query: GET_INVESTMENT_PROFILE_AND_SUITABILITY,
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getInvestmentProfile(client) {
  return new Promise((resolve, reject) => {
    const GET_INVESTMENT_PROFILE = gql`
      query getInvestmentProfile {
        getInvestmentProfile {
          objective
          experience
          annualIncome {
            min
            max
            rawValue
          }
          liquidNetWorth {
            min
            max
          }
          totalNetWorth {
            min
            max
          }
          riskTolerance
          riskToleranceRawValue
          formMetadata
        }
      }
    `;
    const result = client.query({
      query: GET_INVESTMENT_PROFILE,
      variables: {},
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.getInvestmentProfile);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateInvestmentProfileAndSuitability(
  client,
  accountId,
  investmentProfile,
  suitability,
  isAccountScoped
) {
  return new Promise((resolve, reject) => {
    const UPDATE_INVESTMENT_PROFILE_AND_SUITABILITY = gql`
      mutation updateInvestmentProfileAndSuitability(
        $accountId: ID
        $investmentProfile: IraAccountInvestmentProfileInput!
        $suitability: IraAccountSuitabilityInput!
        $isAccountScoped: Boolean!
      ) {
        updateInvestmentProfileAndSuitability(
          accountId: $accountId
          input: {
            investmentProfile: $investmentProfile
            suitability: $suitability
          }
          isAccountScoped: $isAccountScoped
        ) {
          investmentProfile {
            objective
            experience
            annualIncome {
              min
              max
            }
            liquidNetWorth {
              min
              max
            }
            totalNetWorth {
              min
              max
            }
            riskTolerance
            riskToleranceRawValue
            formMetadata
          }
          suitability {
            timeHorizon
            liquidityNeeds
            timeHorizonLiquidity
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_INVESTMENT_PROFILE_AND_SUITABILITY,
      variables: {
        investmentProfile,
        suitability,
        accountId,
        isAccountScoped,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function acceptInvitation(client, invitationCode) {
  return new Promise((resolve, reject) => {
    const ACCEPT_INVITATION = gql`
      mutation acceptInvitation($invitationCode: String!) {
        acceptInvitation(invitationCode: $invitationCode) {
          id
          status
          company {
            id
            name
            planId
            enabledProductFeatures
          }
          group {
            id
            payrollFrequency
            companyGroupIndex
            useUniqueInviteCodes
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: ACCEPT_INVITATION,
      variables: {
        invitationCode,
      },
    });
    result.then(
      (response) => {
        if (response.data.acceptInvitation) {
          resolve(response.data.acceptInvitation);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function optOut(client, inviteId, optedOut) {
  return new Promise((resolve, reject) => {
    const OPT_OUT = gql`
      mutation optOut($inviteId: String!, $optedOut: Boolean!) {
        optOut(inviteId: $inviteId, optedOut: $optedOut) {
          optedOut
        }
      }
    `;
    const result = client.mutate({
      mutation: OPT_OUT,
      variables: {
        inviteId,
        optedOut,
      },
    });
    result.then(
      (response) => {
        if (response.data.optOut) {
          resolve(response.data.optOut);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getEmployerLinkRequests(client) {
  return new Promise((resolve, reject) => {
    const GET_LINK_REQUESTS = gql`
      query getEmployerLinkRequestsForUser {
        getEmployerLinkRequestsForUser {
          id
          status
          company {
            id
            name
            planId
            enabledProductFeatures
          }
          group {
            id
            payrollFrequency
            companyGroupIndex
            useUniqueInviteCodes
          }
          schedule {
            start
            end
            payrollProcessingDate
            onOrAfterPayrollProcessingDate
            status
          }
          contribution {
            amount
            id
            contributionChangeRequests {
              userCurrentContributionAmount
              userNewContributionAmount
              status
              id
            }
          }
        }
      }
    `;
    const result = client.query({
      query: GET_LINK_REQUESTS,
      variables: {},
      fetchPolicy: "network-only",
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.getEmployerLinkRequestsForUser);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getLinkedEmployers(client) {
  return new Promise((resolve, reject) => {
    const GET_LINKS = gql`
      query listIndividualLinkedEmployers {
        listIndividualLinkedEmployers {
          id
          company {
            id
            name
            planId
          }
          groupWithSchedule {
            group {
              id
              name
              payrollFrequency
              companyGroupIndex
            }
          }
          group {
            id
            name
            payrollFrequency
            companyGroupIndex
          }

          schedule {
            start
            end
            payrollProcessingDate
            onOrAfterPayrollProcessingDate
            status
          }
          contribution {
            amount
            id
            contributionChangeRequests {
              status
              id
              userCurrentContributionAmount
              userNewContributionAmount
            }
          }
        }
      }
    `;
    const result = client.query({
      query: GET_LINKS,
      variables: {},
      fetchPolicy: "network-only",
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.listIndividualLinkedEmployers);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function deleteEmployerLink(client, linkId) {
  return new Promise((resolve, reject) => {
    const DELETE_LINK = gql`
      mutation deleteEmployerLink($linkId: ID!) {
        deleteEmployerLink(linkId: $linkId)
      }
    `;
    const result = client.mutate({
      mutation: DELETE_LINK,
      variables: {
        linkId,
      },
    });
    result.then(
      (response) => {
        if (response.data.deleteEmployerLink) {
          resolve(response.data.deleteEmployerLink);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function deleteEmployerLinkRequest(client, linkRequestId) {
  return new Promise((resolve, reject) => {
    const DELETE_LINK = gql`
      mutation cancelUserLinkRequest($linkRequestId: ID!) {
        cancelUserLinkRequest(linkRequestId: $linkRequestId) {
          id
          status
          company {
            id
            name
            planId
          }
          group {
            id
            payrollFrequency
            companyGroupIndex
          }
          schedule {
            start
            end
            payrollProcessingDate
            onOrAfterPayrollProcessingDate
            status
          }
          contribution {
            amount
            id
            contributionChangeRequests {
              userCurrentContributionAmount
              userNewContributionAmount
              status
              id
            }
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: DELETE_LINK,
      variables: {
        linkRequestId,
      },
    });
    result.then(
      (response) => {
        if (response.data.cancelUserLinkRequest) {
          resolve(response.data.cancelUserLinkRequest);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function startInvestmentProfileUpdateRequest(client) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation startInvestmentProfileUpdate {
        startInvestmentProfileUpdate
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
    });
    result.then(
      (response) => {
        if (response.data.startInvestmentProfileUpdate) {
          resolve(response.data.startInvestmentProfileUpdate);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function startPortfolioUpdateRequest(client) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation startPortfolioUpdate {
        startPortfolioUpdate
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
    });
    result.then(
      (response) => {
        if (response.data.startPortfolioUpdate) {
          resolve(response.data.startPortfolioUpdate);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function exitInvestmentProfileUpdateRequest(client) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation exitInvestmentProfileUpdate {
        exitInvestmentProfileUpdate
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
    });
    result.then(
      (response) => {
        if (response.data.exitInvestmentProfileUpdate) {
          resolve(response.data.exitInvestmentProfileUpdate);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function submitSsn(client, ssn) {
  return new Promise((resolve, reject) => {
    const SUBMIT_SSN = gql`
      mutation submitSsn($ssn: String!) {
        submitSsn(ssn: $ssn)
      }
    `;
    const result = client.mutate({
      mutation: SUBMIT_SSN,
      variables: {
        ssn,
      },
    });
    result.then(
      (response) => {
        if (response.data.submitSsn) {
          resolve(response.data.submitSsn);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function submitEin(client, { ein, companyId }) {
  return new Promise((resolve, reject) => {
    const SUBMIT_EIN = gql`
      mutation submitEin($ein: String!, $companyId: ID!) {
        submitEin(ein: $ein, companyId: $companyId)
      }
    `;
    const result = client.mutate({
      mutation: SUBMIT_EIN,
      variables: {
        ein,
        companyId,
      },
    });
    result.then(
      (response) => {
        if (response.data.submitEin) {
          resolve(response.data.submitEin);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function validateEin(client, { ein, companyId }) {
  return new Promise((resolve, reject) => {
    const VALIDATE_EIN = gql`
      mutation validateEin($ein: String!, $companyId: ID!) {
        validateEin(ein: $ein, companyId: $companyId)
      }
    `;
    const result = client.mutate({
      mutation: VALIDATE_EIN,
      variables: {
        ein,
        companyId,
      },
    });
    result.then(
      (response) => {
        if (response.data.validateEin) {
          resolve(response.data.validateEin);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function createSupportRequest(client, { comment, subject, email, name }) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation createSupportRequest(
        $comment: String!
        $subject: String!
        $email: String!
        $name: String!
      ) {
        createSupportRequest(
          comment: $comment
          subject: $subject
          email: $email
          name: $name
        )
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        comment,
        subject,
        email,
        name,
      },
    });
    result.then(
      (response) => {
        if (response.data.createSupportRequest) {
          resolve(response.data.createSupportRequest);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getRequiredStepsRequest(client) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getRequiredUpdates {
        getRequiredUpdates {
          updates {
            id
            entityIdentifier {
              type
              id
            }
            allSteps
            pendingSteps
            isCompleted
            accountType
          }
        }
      }
    `;

    client
      .query({
        query: REQUEST,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (response.data && response.data.getRequiredUpdates) {
          resolve(response.data.getRequiredUpdates);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateRequiredDisclosuresRequest(client, { isCustodianEmployee }) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation updateRequiredDisclosures($isCustodianEmployee: Boolean!) {
        updateRequiredDisclosures(isCustodianEmployee: $isCustodianEmployee) {
          id
          entityIdentifier {
            type
            id
          }
          isCompleted
          allSteps
          pendingSteps
        }
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        isCustodianEmployee,
      },
    });
    result.then(
      (response) => {
        if (response.data.updateRequiredDisclosures) {
          resolve(response.data.updateRequiredDisclosures);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateSelectedAccountType(client, accountType) {
  return new Promise((resolve, reject) => {
    const UPDATE_SELECTED_ACCOUNT_TYPE = gql`
      mutation updateSelectedAccountType($accountType: AccountType!) {
        updateSelectedAccountType(accountType: $accountType)
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_SELECTED_ACCOUNT_TYPE,
      variables: {
        accountType,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function initializeRollover(client, accountType) {
  return new Promise((resolve, reject) => {
    const INITIALIZE_ROLLOVER = gql`
      mutation initializeRollover($accountType: RolloverAccountType!) {
        initializeRollover(accountType: $accountType) {
          id
          routingNumber
          accountNumber
          name
          accountType
          isRollover
          isProcessingRollover
          state
          createdAt
          active
          lifecycleState
          balances {
            withdrawalBalance
            totalAccountBalance
            cashBalance
            principal
            totalPendingContributions
            totalPendingDistributions
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: INITIALIZE_ROLLOVER,
      variables: {
        accountType,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function submitRolloverAccountInfo(client, input) {
  return new Promise((resolve, reject) => {
    const SUBMIT_ROLLOVER_INFO = gql`
      mutation submitRolloverAccountInfo($input: [RolloverAccountInput!]!) {
        submitRolloverAccountInfo(input: $input) {
          id
          routingNumber
          accountNumber
          name
          accountType
          isRollover
          isProcessingRollover
          state
          createdAt
          active
          lifecycleState
          balances {
            withdrawalBalance
            totalAccountBalance
            cashBalance
            principal
            totalPendingContributions
            totalPendingDistributions
          }
        }
      }
    `;

    const result = client.mutate({
      mutation: SUBMIT_ROLLOVER_INFO,
      variables: { input },
    });

    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function submitUserRolloverInfo(client, input) {
  return new Promise((resolve, reject) => {
    const SUBMIT_USER_ROLLOVER_INFO = gql`
      mutation submitUserRolloverInfo($input: [RolloverAccountInput!]!) {
        submitUserRolloverInfo(input: $input) {
          id
          routingNumber
          accountNumber
          name
          accountType
          isRollover
          isProcessingRollover
          state
          createdAt
          active
          lifecycleState
          balances {
            withdrawalBalance
            totalAccountBalance
            cashBalance
            principal
            totalPendingContributions
            totalPendingDistributions
          }
        }
      }
    `;

    const result = client.mutate({
      mutation: SUBMIT_USER_ROLLOVER_INFO,
      variables: { input },
    });

    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function addRolloverAccountDisclosure(client, disclosure) {
  return new Promise((resolve, reject) => {
    const ADD_ROLLOVER_ACCOUNT_DISCLOSURE = gql`
      mutation addRolloverAccountDisclosure(
        $tradeAuthorization: Boolean!
        $controlPerson: Boolean!
        $stockSymbols: [String]
        $exchangeOrFINRA: Boolean!
        $firmName: String
        $politicallyExposed: Boolean!
        $organizationName: String
        $familyNames: [String]
        $docUrl: String
        $isCustodianEmployee: Boolean!
      ) {
        addRolloverAccountDisclosure(
          tradeAuthorization: $tradeAuthorization
          controlPerson: $controlPerson
          stockSymbols: $stockSymbols
          exchangeOrFINRA: $exchangeOrFINRA
          firmName: $firmName
          politicallyExposed: $politicallyExposed
          organizationName: $organizationName
          familyNames: $familyNames
          docUrl: $docUrl
          isCustodianEmployee: $isCustodianEmployee
        ) {
          id
          routingNumber
          accountNumber
          name
          accountType
          isRollover
          isProcessingRollover
          state
          createdAt
          active
          lifecycleState
          balances {
            withdrawalBalance
            totalAccountBalance
            cashBalance
            principal
            totalPendingContributions
            totalPendingDistributions
          }
        }
      }
    `;

    const result = client.mutate({
      mutation: ADD_ROLLOVER_ACCOUNT_DISCLOSURE,
      variables: {
        tradeAuthorization: disclosure.tradeAuthorization,
        controlPerson: disclosure.controlPerson,
        stockSymbols: disclosure.stockSymbols,
        exchangeOrFINRA: disclosure.exchangeOrFINRA,
        firmName: disclosure.firmName,
        politicallyExposed: disclosure.politicallyExposed,
        organizationName: disclosure.organizationName,
        familyNames: disclosure.familyNames,
        docUrl: disclosure.docUrl,
        isCustodianEmployee: disclosure.isCustodianEmployee,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function updateRolloverInvestmentProfileAndSuitability(
  client,
  investmentProfile,
  suitability
) {
  return new Promise((resolve, reject) => {
    const USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE = gql`
      mutation UpdateRolloverInvestmentProfileAndSuitability(
        $investmentProfile: IraAccountInvestmentProfileInput!
        $suitability: IraAccountSuitabilityInput!
      ) {
        updateRolloverInvestmentProfileAndSuitability(
          input: {
            investmentProfile: $investmentProfile
            suitability: $suitability
          }
        ) {
          id
          routingNumber
          accountNumber
          name
          accountType
          isRollover
          isProcessingRollover
          state
          createdAt
          active
          lifecycleState
          balances {
            withdrawalBalance
            totalAccountBalance
            cashBalance
            principal
            totalPendingContributions
            totalPendingDistributions
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE,
      variables: {
        investmentProfile,
        suitability,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateRolloverAccountTermsAndSubmit(client, terms) {
  return new Promise((resolve, reject) => {
    const UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT = gql`
      mutation updateRolloverAccountTermsAndSubmit(
        $termsOfService: Boolean!
        $accountAgreement: Boolean!
        $rolloverTerms: Boolean!
      ) {
        updateRolloverAccountTermsAndSubmit(
          termsOfService: $termsOfService
          accountAgreement: $accountAgreement
          rolloverTerms: $rolloverTerms
        ) {
          id
          routingNumber
          accountNumber
          name
          accountType
          isRollover
          isProcessingRollover
          state
          createdAt
          active
          lifecycleState
          balances {
            withdrawalBalance
            totalAccountBalance
            cashBalance
            principal
            totalPendingContributions
            totalPendingDistributions
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_ROLLOVER_ACCOUNT_TERMS_SUBMIT,
      variables: {
        termsOfService: booleanValue(terms.termsOfService),
        accountAgreement: booleanValue(terms.accountAgreement),
        rolloverTerms: booleanValue(terms.rolloverTerms),
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function userTermsDate(client) {
  return new Promise((resolve, reject) => {
    const QUERY = gql`
      query userTermsDate {
        userTermsDate {
          createdAt
        }
      }
    `;
    const result = client.query({
      query: QUERY,
      variables: {},
    });
    result.then(
      (response) => {
        if (response.data.userTermsDate) {
          resolve(response.data.userTermsDate);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function getRolloverInfo(client, accountId) {
  return new Promise((resolve, reject) => {
    const GET_ROLLOVER_INFO = gql`
      query getRolloverInfo($accountId: ID!) {
        getRolloverInfo(accountId: $accountId) {
          amount
          status
          accountId
          docId
          accountNumber
          financialInstitution
        }
      }
    `;
    const result = client.query({
      query: GET_ROLLOVER_INFO,
      variables: {
        accountId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.getRolloverInfo);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function cancelRollover(client, accountId) {
  return new Promise((resolve, reject) => {
    const CANCEL_ROLLOVER = gql`
      mutation cancelRollover($accountId: ID!) {
        cancelRollover(accountId: $accountId)
      }
    `;

    const result = client.mutate({
      mutation: CANCEL_ROLLOVER,
      variables: { accountId },
    });

    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

export const userService = {
  register,
  registerAdmin,
  getUserProfile,
  updateUserProfile,
  validateEin,
  getState,
  logout,
  confirmEmail,
  getIraAccountForm,
  updateIraAccountIdentity,
  updateIraAccountUserCitizenship,
  updateIraAccountTrustedContact,
  updateIraAccountEmploymentStatus,
  updateIraAccountEmploymentPosition,
  updateIraAccountDisclosure,
  updateIraAccountInvestmentProfile,
  updateIraAccountSuitability,
  updateIraAccountTermsAndSubmit,
  updateEmployerAccountTermsAndSubmit,
  getValidatedAddress,
  userCompletedKyc,
  userCompletedOnboarding,
  getNeededYellowPathDocs,
  updateIraYellowPathDocuments,
  closeUserAccount,
  requestEmailChange,
  getEmailChangeRequest,
  deleteEmailChangeRequest,
  verifyEmailChangeRequest,
  updateInvestmentProfileAndSuitability,
  getInvestmentProfileAndSuitability,
  getInvestmentProfile,
  acceptInvitation,
  getEmployerLinkRequests,
  getLinkedEmployers,
  deleteEmployerLink,
  startInvestmentProfileUpdateRequest,
  startPortfolioUpdateRequest,
  exitInvestmentProfileUpdateRequest,
  deleteEmployerLinkRequest,
  submitSsn,
  submitEin,
  createSupportRequest,
  optOut,
  getRequiredStepsRequest,
  updateRequiredDisclosuresRequest,
  updateSelectedAccountType,
  initializeRollover,
  submitRolloverAccountInfo,
  submitUserRolloverInfo,
  addRolloverAccountDisclosure,
  updateRolloverInvestmentProfileAndSuitability,
  updateRolloverAccountTermsAndSubmit,
  userTermsDate,
  getRolloverInfo,
  cancelRollover,
};
