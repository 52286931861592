/* eslint-disable react/no-unescaped-entities */

import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

export const EvolveTerms = ({
  info = {
    effectiveDate: moment().format("MMMM D, YYYY"),
  },
}) => {
  return (
    <>
      <div className="terms-of-service">
        <div>
          <ol type="I">
            <li>
              <strong>INTRODUCTION</strong>.
              <div>
                <p>
                  This document, as amended or supplemented by the attached
                  disclosures (collectively, this “Agreement”), contains the
                  general terms, conditions and disclosures for the Evolve Bank
                  &amp; Trust transaction account (the “Account”) and
                  constitutes an agreement between the Bank (as defined below)
                  and you. For purpose of this Agreement, the term “Account” may
                  also collectively include Sub-Deposit Accounts of such
                  Account.
                </p>
              </div>
              <div>
                <p>
                  References to the “Bank,” “we,” “us,” or “our” means Evolve
                  Bank &amp; Trust, our successors, affiliates, and assignees.
                  References to “you” and “your” means you and any person that
                  holds, whether individually, jointly or on behalf of another
                  person, the Account. This Agreement may be supplemented or
                  amended as set forth in Section VI (D) (Changes in Terms).
                  Bank services and access and use of your Account may be
                  provided to you through the website and smart phone
                  application of our service provider, The Icon Savings
                  Corporation (dba Icon Savings Plan), at{" "}
                  <a href="http://iconsavingsplan.com">iconsavingsplan.com</a>{" "}
                  (the “Service Provider,” and its website and smart phone
                  application, the “Service Provider Platform”). The Service
                  Provider or its affiliates may offer you additional services
                  through its Service Provider Platform, pursuant and subject to
                  the terms and conditions between you and the Service Provider
                  (the “Services” and such terms and conditions, the “Service
                  Provider Terms”), and such Services are not governed by this
                  Agreement and not offered by Bank. Please see Section II (The
                  Account) for additional information.
                </p>
              </div>
              <div>
                <p>
                  By opening or continuing to maintain an Account with us, you
                  agree to be bound by this Agreement, and all applicable
                  agreements, disclosures, and other documents, as well as by
                  all applicable federal or state laws, statutes and
                  regulations. Please keep a copy of this Agreement.
                </p>
              </div>
              <div>
                <p>
                  We may decline to open an Account for any reason or for no
                  reason. We are not liable for any damages or liabilities
                  resulting from refusal of an Account relationship.
                </p>
              </div>
              <div>
                <p>
                  <strong>
                    THIS AGREEMENT IS SUBJECT TO MANDATORY ARBITRATION PURSUANT
                    TO THE FEDERAL ARBITRATION ACT AND A WAIVER OF CLASS ACTION
                    AND JURY PROVISION. PLEASE CAREFULLY READ SECTION V
                    (ARBITRATION AND WAIVERS).
                  </strong>
                </p>
              </div>
            </li>
            <li>
              <strong>THE ACCOUNT</strong>.
              <ol type={"A"}>
                <li>
                  <strong>Availability</strong>. The Account is available to
                  United States citizens or lawful permanent residents with a
                  U.S. physical address or with military addresses (APO or FPO)
                  who are at least 18 years old with a valid Social Security
                  Number or Tax Identification Number. To open an Account, you
                  must provide any information we may request from time to time.
                  Each owner of an Account shall have a continuing obligation to
                  provide any other documentation we may request from time to
                  time, including any information required under our customer
                  identification program to establish your identity.
                  <p>
                    You must also agree to go paperless. This means that: (1)
                    you must keep us supplied with your valid email address and
                    telephone number; and (2) you must agree to accept
                    electronic delivery of all communications that we need or
                    decide to send you in accordance with Section VI (O)
                    (Electronic Communications and Electronic Signatures;
                    Electronic Storage of Data and Records).
                  </p>
                </li>
                <li>
                  <strong>Account Type and Ownership</strong>. The Account is a
                  transaction account that allows you to make certain purchases
                  and payments and transfers to third parties. The Account may
                  not be opened as a joint account. You cannot give a security
                  interest or pledge your Account to someone other than us
                  without first getting our express written consent. We are not
                  required to give consent to a security interest or pledge to
                  someone else. Unless we agree in a separate writing otherwise
                  a security interest or pledge to someone else must be
                  satisfied or released before any right to withdraw from the
                  account can be exercised. You must immediately report to us
                  any changes in Account ownership information that you
                  originally provided to us in connection with the submission of
                  your application for an Account.
                </li>
                <li>
                  <strong>Opening an Account</strong>. To open an Account, you
                  must submit to us an application and all information that we
                  may require. To help the government fight the funding of
                  terrorism and money laundering activities, Federal law
                  requires us to obtain, verify and record information that
                  identifies each person who opens an Account. This means that
                  when you open an Account, we will ask for your name, address,
                  date of birth, social security number, and other information
                  that will allow us to identify you. We may also ask to see
                  other identifying documents, such as a driver’s license,
                  taxpayer identification number; passport number and country of
                  issuance; alien identification card number; or number and
                  country of issuance of any other government-issued document
                  evidencing nationality or residence and bearing a photograph
                  or similar safeguard. If, for any reason, you are unable to
                  provide the information necessary to verify your identity, the
                  Account may be blocked or closed, which may result in
                  additional fees assessed to the Account.
                  <p>
                    By opening an Account, you agree that we can gather your
                    personal information from one or more consumer reporting
                    agencies, governmental entities and/or other third parties,
                    including, but not limited to, the Service Provider. You
                    also agree that we have the right to access follow-up
                    consumer reports while your Account is open for maintenance,
                    review or collection purposes.
                  </p>
                  <p>
                    By applying for an Account, you certify that all information
                    you have provided to us, including, but not limited to, your
                    name, mailing address and residential address (if
                    different), social security number (or passport number and
                    country of issuance) or other identification documentation,
                    date of birth, telephone number and other information
                    provided at our request, is true, accurate and complete. If
                    you falsify, misrepresent, or fail to provide requested
                    information, we may cancel your Account. In addition, funds
                    tied to suspected illicit or illegal activity may be subject
                    to in ternal holds and investigation and potentially federal
                    investigation. We reserve the right to restrict or delay
                    your access to any such funds, subject to applicable law. We
                    may share any of the information collected and any results
                    of any investigation with the Service Provider. Please see
                    our Privacy Policy for additional details and accessible by
                    visiting{" "}
                    <a href="https://www.getevolved.com/privacy-policy/">
                      https://www.getevolved.com/privacy-policy/
                    </a>{" "}
                    (“Privacy Policy”).
                  </p>
                  <p>
                    There is no minimum balance required and no maximum balance
                    limit. However, we may, at our sole discretion and at any
                    time, require a minimum balance and set a maximum balance
                    limit. The minimum balance is required to be held in account
                    at all times. If you fail to fund your Account within sixty
                    (60) days of Account opening or fail to maintain a positive
                    balance in your Account for sixty (60) days, we may close
                    your Account.
                  </p>
                </li>
                <li>
                  <strong>Service Providers</strong>. You understand that we
                  have engaged the Service Provider to perform a number of
                  services for you under this Agreement, including, but not
                  limited to, identity verification, compliance monitoring, risk
                  mitigation, managing your Account and other services we may
                  require. The Service Provider, along with its partners and
                  agents, is the servicer of your Account. In that capacity,
                  Service Provider may act on our behalf, perform our
                  obligations and/or enforce our rights under this Agreement.
                  You agree that you may only access the Account through the
                  Service Provider. You may provide us with payment instructions
                  and view certain Account information by using the Service
                  Provider Platform.
                  <p>
                    Separate and apart from its role as our servicer, the
                    Service Provider may also offer Services to you. We are
                    neither responsible for the provisioning of such Services
                    nor do we make any recommendations, representations,
                    warranties or suggestions regarding such Services or Service
                    Provider Platform. To the fullest extent permitted by law,
                    we will not have any liability in connection with the
                    Services and disclaim all warranties, either express,
                    implied, or statutory, including, but not limited to, any
                    implied warranties of merchantability, fitness for a
                    particular purpose and lack of viruses related to the
                    Services or Service Provider Platform. Subject to applicable
                    law, in no event shall we be liable for any losses or
                    damages related to your use of Services or Service Provider
                    Platform.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <strong>USING YOUR ACCOUNT</strong>
              <ol type={"A"}>
                <li>
                  <strong>Linking Accounts</strong>. You may link your bank
                  account at another financial institution (“Linked Account”) to
                  your Account in order to transfer funds between your Linked
                  Account and your Account. You represent and warrant: (1) that
                  you have the right to authorize any and all charges and debits
                  to the Linked Account; (2) the Linked Account is held at a
                  depository institution located in the U.S.; and (3) you are
                  individually or jointly the owner of the Linked Account. You
                  will indemnify and hold us harmless from any claims by any
                  person related to the Linked Account, including any other
                  owner of the Linked Account. You are not permitted to link
                  your Account with a credit, debit, gift, or other type of card
                  issued by a third party or another bank. You may deposit funds
                  into or withdraw funds from your Account by using the Service
                  Provider Platform to initiate an Automated Clearing House
                  transfer (“ACH Transfer”) from the Linked Account. You may
                  withdraw funds from your Account by using the Service Provider
                  Platform to initiate an ACH Transfer from your Account and
                  depositing such funds into your Linked Account. The external
                  account that you are depositing funds into or withdrawing
                  funds from must be at an external bank located in the U.S. We
                  do not accept requests made otherwise to initiate ACH
                  Transfers. Requests for ACH Transfers to debit the Linked
                  Account that are made before 5:00 PM Pacific on a business
                  day, shall be considered received on the business day the
                  request is made. Requests for ACH Transfers to debit the
                  Linked Account that are made after 5:00 PM Pacific on a
                  business day or on a non-business day, shall be considered
                  received the next business day.
                </li>
                <li>
                  <strong>
                    Internal Transfer Between Accounts at the Bank
                  </strong>
                  . We may (but are not obligated to) permit you to initiate a
                  transfer of funds between your accounts at the Bank through a
                  Service Provider Platform. We do not accept requests made
                  otherwise to initiate internal transfers.
                </li>
                <li>
                  <strong>Deposits</strong>. When you make a deposit, we will
                  act only as your collection agent and will not be responsible
                  beyond the exercise of good faith and ordinary care. All
                  deposits are provisionally credited subject to our receipt of
                  final payment. If a deposit or transfer of funds into your
                  Account is returned or rejected by the paying financial
                  institution for any reason, you agree that we may deduct the
                  amount of the deposit or transfer against your Account,
                  without prior written notice to you.
                  <ol type={"i"}>
                    <li>
                      We do not accept deposits by mail, cash, paper check or
                      foreign currency deposits. We are not liable for wire
                      transfers that you may attempt into your Account or
                      deposits of any kind that you mail to us, including if it
                      is lost in transit, lost in the mail, or otherwise not
                      received by us. If you attempt to make a deposit through a
                      prohibited means, the transaction will be rejected. If you
                      mail us a cash deposit, we will send the cash back to you.
                      We may but are not obligated to accept checks. If you mail
                      a paper check to us, including personal checks, money
                      orders or cashier’s checks, we may either apply the check
                      to any negative balance you may have on your Account or
                      send the check back to you. We do not accept any deposits
                      in foreign currency. Any deposits received in foreign
                      currency, whether in the form of cash or check, will be
                      sent back to you. We will send all items back to the
                      address we have for you on file and we are not liable if
                      you do not receive the return mail or cash or items.
                    </li>
                    <li>
                      Subject to the limitations set forth herein, you may only
                      deposit with us funds that are immediately available,
                      which under applicable law are irreversible and are not
                      subject to any lien, claim or encumbrance.
                    </li>
                    <li>
                      You may make a deposit into your Account by ACH Transfers
                      or any other method we make available to you. You may also
                      arrange for recurring deposits, such as direct deposits,
                      to be made electronically to your Account.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Withdrawals</strong>. Except as provided herein and
                  subject to Exhibit 2 (Transaction Limits) and Section VIII (B)
                  (Transfer Limitations), you may make withdrawals from your
                  Account up to the available balance in your Account by any
                  means that we make available to you. We may refuse a
                  withdrawal request exceeding the available funds in your
                  Account even if you make a deposit later in the business day
                  after we refuse your withdrawal request. We may allow
                  withdrawals at any time, but reserve the right to require
                  seven (7) days written notice of intention to withdraw funds
                  from your Account if your Account is a negotiable order of
                  withdrawal (NOW) account.
                  <p>
                    If your available balance is not enough to pay a transaction
                    you make, we may assess an insufficient or unavailable funds
                    fee, regardless of whether we pay the transaction.
                  </p>
                  <p>
                    You are not permitted to make withdrawals from your Account
                    by creating a check drawn on your Account. If you attempt to
                    make any transactions with your Account using a credit,
                    debit, gift or other type of card issued by a third party or
                    another bank or by creating a check drawn on your Account,
                    we may reject and/or return the transaction without notice
                    to you and may consider such action to be a violation by you
                    of this Agreement.
                  </p>
                  <p>
                    Before allowing a transaction, we may request you to provide
                    us with identification, authorization, documentation or
                    other information that we deem necessary. We may refuse a
                    withdrawal if you fail to comply with our request. We may
                    tell you in advance when we are going to refuse a withdrawal
                    request but will not be required to do so.
                  </p>
                  <p>
                    All withdrawals are subject to limitations set forth in
                    Exhibit 2 (Transaction Limits) and VIII (B) (Transfer
                    Limitations).
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <strong>ACCOUNT DISCLOSURES</strong>
              <ol type={"A"}>
                <li>
                  <strong>Payment Instructions</strong>. You agree that any
                  payment instructions received by us (such as requests to
                  withdraw or transfer funds from your Account) through the
                  Service Provider Platform or in connection with your username,
                  passphrase, PIN or mobile device used to access the Service
                  Provider Platform (collectively “Credentials”) will be deemed
                  to be authorized by you, subject to applicable law. The use of
                  any of your Credentials by another person will be as effective
                  as your use of the Credentials, regardless of whether the
                  person affixing the Credential was authorized by you and
                  regardless of the means by which the Credential was affixed,
                  subject to applicable law. For example, if you provide your
                  username and password used to access the Service Provider
                  Platform to another person and that person uses that
                  information to withdraw funds from your Account, we will deem
                  the withdrawal to be authorized by you, subject to applicable
                  law. Unless prohibited by applicable law, you agree to hold us
                  harmless and indemnify us for any liability incurred for
                  reasonably acting upon such instructions which bear any of
                  your Credentials. You agree to keep confidential and to take
                  all reasonable precautions and make all reasonable efforts to
                  protect the secrecy of all Credentials issued to you, selected
                  by you, or utilized by you. If any of your Credentials become
                  lost or known to another person, you agree to notify us
                  immediately so that a replacement may be issued. If you
                  believe your Credentials are no longer secure or confidential,
                  or that someone has used your Credentials without your
                  permission, please contact us immediately by calling
                  503-917-0590 or notifying us through the Service Provider
                  Platform.
                  <p>
                    We may refuse to follow any of your payment instructions if
                    we think they are illegal or potentially harmful to us or
                    any other person. If we follow your payment instructions, we
                    may require other security measures to protect us from any
                    losses we may suffer. To the greatest extent permitted by
                    law, you agree to pay us back for any damages or losses
                    (including, but not limited to, the reasonable costs of an
                    attorney) that we suffer if we take an action based on your
                    (or what appears to be your) oral, written or electronic
                    instructions.
                  </p>
                </li>
                <li>
                  <strong>Posting Order of Credits and Debits</strong>.
                  <ol type={"i"}>
                    <li>
                      Posting Orders. This section summarizes how we generally
                      post some common transactions to your Account. Posting
                      transactions to your Account impact your Account balance.
                      Posting a credit increases your balance, while posting a
                      debit or hold reduces your balance. Credits include
                      deposits and credits we make. Holds include deposit holds,
                      authorizations and holds related to withdrawals and EFTs
                      (as defined below). Debits include withdrawals, transfers,
                      payments from your Account, transactions and fees.
                      <p>
                        We use automated systems to process transactions and
                        then to post transactions to your Account. When we
                        process multiple transactions for your Account on the
                        same day, you agree that we may in our discretion
                        determine our posting orders for the transactions and
                        that we may credit, authorize, accept, pay, decline or
                        return credits, debits and holds in any order at our
                        option, subject to applicable law. We generally post
                        common transactions as set forth herein. We group the
                        different types of transactions into categories. We use
                        several different categories for holds, credits, and
                        debits. Most categories include more than one
                        transaction type. After the end of the business day, our
                        automated systems assign each transaction received for
                        that day to a category. We generally post all
                        transactions within a category, using the posting order
                        or orders that apply to that category before we post any
                        transactions assigned to the next category.
                      </p>
                      <p>
                        First, we start with the balance in your Account at the
                        beginning of the business day, subtract holds from your
                        balance, and make any adjustments from prior days. Next,
                        we generally add available and settled deposits and
                        credits to your balance and then subtract debits from
                        your balance based on the debit category. We subtract
                        debits in a category from your balance in order from the
                        highest to lowest dollar amount, unless specified
                        otherwise herein. We then subtract from your balance any
                        other types of electronic debits in order from the
                        highest to lowest dollar amount. These debits generally
                        include Transfers. Finally, we subtract from your
                        balance most fees (such as monthly maintenance fees,
                        overdraft fees, returned item fees, and ATM fees) in
                        order from highest to lowest dollar amount. Some fees
                        may show as “processing” until the next day. The first
                        category of debits to be applied against your balance
                        are certain ATM transactions (e.g., transactions and ATM
                        withdrawals and transfers) and associated fees. The
                        debits within this category are subtracted from your
                        balance in date and time order our systems receive date
                        and time information.
                      </p>
                    </li>
                    <li>
                      Changing Posting Orders. You agree that we may determine
                      in our discretion the orders in which we post transactions
                      to your Account. You agree that we may determine in our
                      discretion the categories, the transactions within a
                      category, the order among categories and the posting
                      orders within a category. We sometimes add or delete
                      categories, change posting orders within categories and
                      move transaction types among categories. You agree that we
                      may in our discretion make these changes at any time
                      without notice to you.
                    </li>
                    <li>
                      Posting Orders Determined at End of Day. We receive
                      credits, debits and holds throughout the day. Regardless
                      of when during the day we receive transactions for your
                      Account, you agree that we may treat them as if we
                      received all transactions at the same time at the end of
                      the business day. During the day, we show some
                      transactions as processing. Please note that transactions
                      shown as processing have not been posted yet. The posting
                      order for these transactions is determined at the end of
                      the day, with the other transactions we receive for that
                      day and based on the category. We do not always receive
                      debits on the same day that you conduct them. We generally
                      post credits and debits to your Account, and report them
                      on your statement, in a different order than the order in
                      which you conduct them or we receive them.
                    </li>
                    <li>
                      Overdraft Fees. We do not permit you to overdraw your
                      Account and we do not charge you insufficient funds fees
                      or overdraft fees. If the available balance in your
                      Account is not sufficient to cover any transaction you
                      have authorized, we can refuse to process the transaction.
                      You must make a deposit immediately to cover any negative
                      balance. If your Account has a negative balance for sixty
                      (60) calendar days or more, we may close your Account.
                      However, there may be instances where your Account can
                      still become overdrawn. For example, if a deposit is
                      returned or if a merchant settles a transaction for an
                      amount greater than what was authorized.
                    </li>
                    <li>
                      Certain Transactions Made After Business Day Ends. During
                      processing, we may include in your Account balance some
                      transactions that you make after the business day cut-off,
                      but before the end of the calendar day. Deposits are made
                      available based on Section IX (Deposit Availability
                      Disclosure). We generally subtract from your Account
                      balance the following debits, when the transaction occurs
                      after the cutoff time for the business day, but during the
                      same calendar day: cash withdrawals made at an ATM(s) and
                      any credit-push ACH Transfers or EFTs.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Insurance Coverage</strong>. The Federal Deposit
                  Insurance Corporation (“FDIC”) insures deposits according to
                  the ownership category in which the funds are insured. Not all
                  accounts may be insured. Unless otherwise stated by us or the
                  FDIC, the Account is insured by the FDIC up to the standard
                  maximum deposit insurance amount per depositor, per
                  FDIC-insured bank, and per ownership category (the “Standard
                  Insurance Amount” or “SIA”), and, with respect to Sub-Deposit
                  Accounts, please see Section F (iii) (d) (FDIC Deposit
                  Insurance) and Section F (vii) (Information about the FDIC).
                  For purposes of determining the SIA applicable to your Account
                  at Bank, you need to consider all accounts that you also hold
                  at the Bank. To determine SIA applicable to your Account
                  maintained at Bank and for any other FDIC insurance
                  requirements that may apply, please visit the FDIC’s website
                  at{" "}
                  <a href="http://www.fdic.gov/deposit/deposits">
                    www.fdic.gov/deposit/deposits
                  </a>{" "}
                  or call the FDIC directly at 1-877-ASKFDIC (1-877-275-3342).
                  You can also use the FDIC’s Electronic Deposit Insurance
                  Estimator (EDIE) at{" "}
                  <a href="http://www.fdic.gov/edie.">www.fdic.gov/edie.</a> For
                  insurance coverage on Sub-Deposit Account(s), please refer to
                  Section F (iii) (d) (FDIC Deposit Insurance) and Section F
                  (vii) (Information about the FDIC).
                </li>
                <li>
                  <strong>
                    Legal Processes and Claims Against you or your Account.
                  </strong>{" "}
                  If we receive a levy, attachment, execution, garnishment, tax
                  withholding order, restraining order, subpoena, warrant,
                  injunction, government agency request for information, search
                  warrant, forfeiture or other similar order (“Legal Process”)
                  against you, any party to your Account, or against your
                  Account, we may accept and comply with such Legal Process
                  whether it was served to us in person, by mail, by facsimile,
                  by email, or at one of our locations (other than where your
                  Account, property or records are held). You direct us not to
                  contest the Legal Process; provided, however, we are under no
                  obligation to contest any Legal Process. Subject to any law or
                  government authority prohibiting such disclosure, we may, but
                  are not required to send notice to you of the Legal Process.
                  We may refuse to permit withdrawals or transfers from your
                  Account until the Legal Process is dismissed or satisfied. We
                  may hold and turn over funds or other property to the court or
                  creditor as directed by the Legal Process, subject to our
                  right of set-off and security interest in the funds or other
                  property. You are responsible for any losses, costs, or
                  expenses we incur as a result of any Legal Process involving
                  you, any party to your Account or your Account.
                  <p>
                    If we receive a claim against the funds in your Account, or
                    if we know of or believe that there‘s a dispute as to the
                    ownership or control of funds in your Account, we may, in
                    our discretion: (1) place a hold on your Account and refuse
                    to pay out any funds until we‘re satisfied that the dispute
                    is settled; (2) close your Account and send the balance to
                    the named account holder(s); (3) require a court order to
                    act; or (4) take any other action we feel is necessary to
                    protect us. We will not be liable to you for taking any such
                    action.
                  </p>
                  <p>
                    We may charge your Account a fee for each Legal Process or
                    claim involving you, any party to your Account or your
                    Account. You agree to pay us for fees and expenses
                    (including administrative expenses) that we incur in
                    responding to any Legal Process or claim related to your
                    Account, such as expenses for research and copying of
                    documents. The fees and expenses may include attorneys’
                    fees. We may deduct these fees and expenses from your
                    Account or any account you have with us without prior notice
                    to you.
                  </p>
                  <p>
                    We may produce or provide access to property, including
                    documents, that are located in or at any of our locations or
                    facilities or at any facility of a third party holding such
                    property or documentation on our behalf, even if the
                    facility or location is not subject to being searched per
                    the Legal Process.
                  </p>
                  <p>
                    We have no liability to you if we accept and comply with a
                    Legal Process as provided in this section or by law.
                  </p>
                </li>
                <li>
                  <strong>Statements and Reproduction of Bank Records</strong>.
                  We will deliver or make available to you periodic statements
                  for your Account as required by law. You will receive a
                  statement as long as you have a transaction on your Account
                  during the Statement period, and if there were no transactions
                  on your Account, we will provide you a statement at least
                  quarterly. The Account statement will describe payments or
                  transfers made in connection with your Account. You agree to
                  notify us promptly if you do not receive your statement by the
                  date you normally would expect to receive it. You will receive
                  an electronic statement (not paper), and you agree that the
                  statement has been delivered or made available to you in a
                  reasonable manner.
                  <p>
                    If you request us to research and/or reproduce any of your
                    records (statements, checks, deposits, withdrawals, etc.) we
                    may charge a fee, and you agree to pay this fee. If the
                    expected fee is large, you may be asked to pay the fee in
                    advance. We reserve the right to provide you with an imaged
                    item in lieu of the original item.
                  </p>
                </li>
                <li>
                  <strong>Bank Deposit Custodial Program</strong>.
                  <ol type={"i"}>
                    <li>
                      <strong>Introduction</strong>. By accepting the terms of
                      this Agreement or holding a deposit account with us
                      (including, but not limited to your Account), you agree to
                      participate in the Bank deposit custodial program, as
                      further described in this Section F (the “Program”) and
                      agree to be bound by the following terms and conditions
                      (“Program Agreement”). The Program is offered by us in
                      connection with your Account. If you have any questions
                      about any provisions of this Program Agreement, please
                      contact us at{" "}
                      <a href="mailto:support@iconsavingsplan.com">
                        support@iconsavingsplan.com
                      </a>
                      .
                      <p>
                        Pursuant to the terms of this Program Agreement, you
                        agree to appoint Bank, as agent for you and as
                        custodian, to open and maintain with one or more insured
                        depository institutions (each, a “Program Bank” and
                        collectively, “Program Banks”) a deposit account,
                        transaction account, money marketing account, or omnibus
                        custody account (individually and collectively,
                        “Sub-Deposit Account”) in the name of Bank (for your
                        benefit), and to deposit your funds into Sub-Deposit
                        Accounts at one or more Program Banks as set forth on a
                        list provided to you (“Bank List”). You agree to open a
                        custodial account at Bank (“Custodial Account”), which
                        may be used to facilitate deposits to and withdrawals
                        from the Sub-Deposit Account.
                      </p>
                      <p>
                        When you deposit funds with Bank under this Agreement,
                        your funds will be deposited into your Account and may
                        be deposited by Bank, as your agent and custodian, into
                        Sub-Deposit Accounts at one or more Program Banks via
                        your Account or Custodial Account. As your custodian,
                        Bank will maintain records of your Sub-Deposit Accounts
                        at each Program Bank.
                      </p>
                      <p>
                        Your funds at Bank and at each Program Bank identified
                        as a “Priority Bank” on the Bank List are eligible for
                        FDIC insurance coverage up to $250,000 for each
                        insurable capacity in which your Sub-Deposit Account is
                        held (<em>e.g.</em>, individual account, joint account,
                        etc.) (the “Deposit Limit”).
                      </p>
                      <p>
                        <strong>
                          Funds in your Account or Sub-Deposit Account may
                          exceed the Deposit Limit in any Priority Bank. Amounts
                          in excess of the Deposit Limit but not greater than
                          the Program Insurance Eligibility Limit (as defined
                          herein) will be deposited at one or more Priority
                          Banks. Any amount in excess of the Program Insurance
                          Eligibility Limit may not be covered by FDIC
                          insurance; such amount may be deposited at a Priority
                          Bank acting as an “Excess Bank” (as set forth on the
                          Bank List) for such amount.
                        </strong>
                      </p>
                      <p>
                        <strong>
                          Any deposits (including certificates of deposit) that
                          you maintain in the same insurable capacity directly
                          with Bank, a Program Bank or through an intermediary
                          (such as a broker), regardless of the number of
                          accounts, will be aggregated with funds in your
                          Account, Sub-Deposit Account, Custodial Account or a
                          Program Bank for purposes of the Deposit Limit. You
                          are responsible for monitoring the total amount of
                          deposits that you have with Bank and each Program Bank
                          in order to determine the extent of FDIC insurance
                          coverage available to you.
                        </strong>
                      </p>
                      <p>
                        The Sub-Deposit Accounts are solely the obligations of
                        the Program Banks, and neither directly nor indirectly
                        obligations of Bank nor Service Provider. Bank is agent
                        and custodian for you with respect to Sub-Deposit
                        Accounts and ownership will be evidenced by a book entry
                        in records maintained by us. You will not have a direct
                        relationship with the Program Banks. No evidence of
                        ownership related to the Sub-Deposit Account will be
                        issued to you, and you will not receive any written
                        confirmation of the establishment of the Sub-Deposit
                        Account or transfer of funds to or from the Sub-Deposit
                        Account. All deposits and withdrawals will be made by
                        Bank on your behalf, and all deposits to your
                        Sub-Deposit Account and withdrawals from the Sub-Deposit
                        Account necessary to satisfy any debits to or
                        withdrawals from your Account will be made by Bank, as
                        your agent. Information about your Sub-Deposit Accounts
                        may be obtained from Service Provider or Bank, not the
                        Program Banks.
                      </p>
                      <p>
                        All communications regarding the Program, including
                        periodic statements and notices regarding changes to the
                        Program, are the responsibility of Service Provider and
                        Bank, not the Program Banks. If you have any questions
                        regarding your Account or Sub-Deposit Accounts, please
                        contact Service Provider at{" "}
                        <a href="mailto:support@iconsavingsplan.com">
                          support@iconsavingsplan.com
                        </a>
                        .
                      </p>
                      <p>
                        The terms and conditions of the Program, and any feature
                        of the Program, may be varied at Bank’s sole discretion.
                        Bank, in its sole discretion, may make exceptions to the
                        terms and conditions of the Program.
                      </p>
                    </li>
                    <li>
                      <strong>
                        Appointment of Bank as Your Agent and Custodian.
                      </strong>{" "}
                      You appoint Bank, and Bank agrees to be appointed, as your
                      agent and custodian to hold in safekeeping all Sub-Deposit
                      Accounts at Program Banks. You authorize Bank to execute
                      and deliver or file on your behalf all appropriate
                      receipts, agreements, releases and other instruments,
                      including whatever agreements may be required to establish
                      and maintain the Sub-Deposit Accounts or to establish your
                      ownership interest in the Sub-Deposit Account. You appoint
                      Bank to act as your agent with respect to establishing,
                      maintaining and administering the Sub-Deposit Account, and
                      you authorize us to take any action necessary to
                      establish, maintain and administer the Sub-Deposit Account
                      and to initiate transfers to and from your Sub-Deposit
                      Account, Custodial Account and Account as we may determine
                      in Bank’s sole discretion.
                      <p>
                        You agree that Bank, as your agent, may place any or all
                        of your funds on deposit with Bank at one or more
                        Program Banks in an amount determined in Bank’s sole
                        discretion based on available capacity at the Program
                        Bank or other criteria, including rates or fees paid on
                        deposits by the Program Bank to Bank. Bank may place
                        your funds without regard to whether such funds may
                        exceed the Deposit Limit at one or more Program Banks,
                        even if your funds could be placed in one or more
                        Program Banks in an amount less than the Deposit Limit.
                      </p>
                      <p>
                        Bank may financially benefit if it places your funds
                        into a Program Bank in excess of the Deposit Limit when
                        that Program Bank pays a greater amount of fees to Bank
                        than other Program Banks that could accept your deposits
                        in amounts under the FDIC insurance limit. Please
                        carefully read the section entitled “Waiver of
                        Conflicts” below.
                      </p>
                      <p>
                        You authorize Bank to collect and retain all payments of
                        income pertaining to the Sub-Deposit Accounts (if any)
                        and to deliver or transfer the funds on deposit in the
                        Sub-Deposit Accounts to another account with Bank,
                        including your Account, to process any transactions or
                        transfers from your Account. As your agent, you
                        authorize Bank to determine the amount of funds to
                        deposit in and withdraw from each Sub-Deposit Account.
                      </p>
                      <p>
                        You agree that Bank, in its sole discretion, may divide
                        the funds deposited into your Account into one or more
                        separate Sub-Deposit Account to be managed by Bank.
                      </p>
                      <p>
                        You agree that Bank may comply with any writ of
                        attachment, execution, garnishment, tax levy,
                        restraining order, subpoena, warrant or other legal
                        process that Bank believes (correctly or otherwise) to
                        be valid. We may notify you of such process by
                        telephone, electronically or in writing. If Bank is not
                        fully reimbursed for its record research, photocopying
                        and handling costs by the party that served the process,
                        Bank may charge such costs to your Sub-Deposit Account,
                        in addition to any minimum fee Bank may charge for
                        complying with legal processes.
                      </p>
                      <p>
                        You agree that Bank may honor any legal process that is
                        served personally, by mail, or by facsimile transmission
                        at any of Bank’s offices or an office of Bank’s agent
                        (including locations other than where the funds, records
                        or property sought is held), even if the law requires
                        personal delivery at the office where your Account or
                        records are maintained.
                      </p>
                      <p>
                        We may provide your name, tax identification number and
                        other pertinent identifying information to Program
                        Banks, and other parties providing services in
                        connection with the placement of your funds and the
                        establishing and holding of the Sub-Deposit Account.
                      </p>
                      <p>
                        You agree that we shall have no liability to you for any
                        action taken or omitted by us hereunder in good faith.
                      </p>
                      <p>
                        You agree to indemnify us and our nominees against, and
                        to hold us and our nominees harmless from, all expenses
                        (including counsel fees), liabilities and claims arising
                        out of the holding, delivery or transfer of the
                        Sub-Deposit Accounts and compliance with any legal
                        process that we believe (correctly or otherwise) to be
                        valid.
                      </p>
                    </li>
                    <li>
                      <strong>Operation of the Program.</strong>
                      <ol type={"a"}>
                        <li>
                          <strong>Accounts at Bank.</strong> You agree to
                          establish an Account and Custodial Account with us and
                          that we may establish a Sub-Deposit Account on your
                          behalf at Bank. Please read the section titled “Your
                          Relationship with Bank and the Program Banks” below.
                        </li>
                        <li>
                          <strong>Accounts at Program Banks.</strong> As your
                          agent, we will open one or more Sub-Deposit Accounts
                          on your behalf at the Program Banks on the
                          then-current Bank List and act as your custodian for
                          the Sub-Deposit Accounts. At some Program Banks, we
                          may open a money market deposit account (“MMDA”) and a
                          transaction account (“TA”); at other Program Banks, we
                          may open a TA only. Each MMDA and TA are considered a
                          Sub-Deposit Account. The Sub-Deposit Account may not
                          be transferred to another institution, except by us or
                          the Program Bank.
                        </li>
                        <li>
                          <strong>Program Bank List.</strong> The Bank List of
                          available Program Banks into which your funds may be
                          deposited is available on our website at{" "}
                          <a href="https://www.getevolved.com/openbanking/fdic-insured-sweep-program/">
                            https://www.getevolved.com/openbanking/fdic-insured-sweep-program/
                          </a>{" "}
                          or by contacting us. The Bank List may include one or
                          more Program Banks that constitute Excess Banks; we
                          may deposit your funds at an Excess Bank without limit
                          and without regard to Deposit Limit if funds
                          sufficient to meet the Program Insurance Eligibility
                          Limit have been placed at one or more Priority Banks.
                          If all of your funds are withdrawn from a Program
                          Bank, the next time your funds are available for
                          deposit in a Program Bank, your funds may be deposited
                          in a different Program Bank.
                          <p>
                            You may at any time, with reasonable advance notice
                            to us at{" "}
                            <a href="mailto:support@iconsavingsplan.com">
                              support@iconsavingsplan.com
                            </a>
                            , designate a Program Bank as ineligible to receive
                            your funds. In addition, you may at any time
                            instruct Bank to remove your funds from a Program
                            Bank, close your Sub-Deposit Accounts with the
                            Program Bank and designate the Program Bank as
                            ineligible to receive future deposits. Any funds
                            from a closed Sub-Deposit Account will be deposited
                            into Sub-Deposit Accounts at another Program Bank
                            set forth on the Bank List, as amended by you, or if
                            no other Program Bank is eligible, the funds will be
                            deposited into your Account. For example, if you
                            maintain other accounts at a Program Bank on the
                            list separate from the Program, you may wish to
                            designate this Program Bank as ineligible to avoid
                            the aggregation of funds in your account(s) held
                            directly with funds deposited through the Program as
                            the combination of funds may exceed the amount of
                            available FDIC insurance coverage at that Program
                            Bank. If you designate all Program Banks as
                            ineligible, we will return funds to your Account and
                            may, in our discretion and in accordance with law,
                            close your Account and any Sub-Deposit Account
                            opened specifically for you.
                          </p>
                        </li>
                        <li>
                          <strong>FDIC Deposit Insurance.</strong> We will place
                          your funds at Priority Banks as your agent in a manner
                          that enables up to $250,000 of your deposits or such
                          other greater amount as may be disclosed to you by us
                          from time to time to be eligible for FDIC insurance
                          through the Program (such amount, the “Program
                          Insurance Eligibility Limit”). From time to time, we
                          may increase the amount of available FDIC insurance
                          through the Program, and you will be notified of such
                          an increase. Any other deposits you have at the
                          Priority Banks outside of the Program may reduce FDIC
                          insurance coverage of your deposits through the
                          Program. Moreover, if you have designated one or more
                          Priority Banks as ineligible to receive your funds,
                          the amount of FDIC insurance for which your funds are
                          eligible may be limited. The depositing of your funds
                          into the Sub-Deposit Account may not increase your
                          FDIC deposit insurance coverage, and may in fact
                          reduce insurance coverage depending on any other
                          deposits you may maintain at a Program Bank.
                        </li>
                        <li>
                          <strong>Changes to Bank List.</strong> One or more of
                          the Program Banks included on the Bank List may be
                          replaced with a Program Bank not previously included
                          on the Bank List or a Program Bank may be deleted from
                          the Bank List. In addition, Program Banks designated
                          as Priority Banks may be replaced with a Program Bank
                          not previously designated as a Priority Bank or a
                          Program Bank may be removed from the list of
                          designated Priority Banks. We will notify you if the
                          Bank List has changed in accordance with the section
                          titled “Changes to the Program.” Continued use of your
                          Account and/or the Program after receipt of the
                          initial Bank List or notice of subsequent changes to
                          such list will constitute your consent to such list.
                          <p>
                            An increase in the number of Priority Banks in the
                            future may not increase the potential FDIC coverage
                            available in the Program, and there can be no
                            assurance that additional Priority Banks will be
                            added or your FDIC insurance coverage will increase.
                            A decrease in the number of Priority Banks in the
                            future could decrease the potential FDIC coverage
                            available in the Program.
                          </p>
                          <p>
                            You agree that deposits and withdrawals of your
                            funds made after a change to the Bank List will
                            occur as described below under “Deposit Procedures”
                            and “Withdrawal Procedures,” respectively.
                          </p>
                          <p>
                            If a Program Bank at which you have funds in a
                            Sub-Deposit Account(s) no longer makes the
                            Sub-Deposit Account(s) available through the
                            Program, we may move funds to another Program Bank
                            on the Bank List on or about the day that the
                            Program Bank leaves the Program or return funds to
                            your Account.
                          </p>
                        </li>
                        <li>
                          <strong>Deposit Procedures.</strong> You agree that,
                          as your agent, we may open one or more Sub-Deposit
                          Accounts on your behalf at one or more of the Program
                          Banks on the then current Bank List. If we open both
                          an MMDA and a TA at a Program Bank, all deposits will
                          be made into your MMDA and/or TA, subject to six (6)
                          monthly withdrawal limits applicable to MMDA.
                          <p>
                            You agree that, as your agent, we may place your
                            funds at one or more Program Banks without limit and
                            without regard to the Deposit Limit. You agree that
                            we will place your funds based on each Program
                            Bank’s available capacity or other criteria,
                            including rates paid by Program Banks on Sub-Deposit
                            Accounts or fees paid to us. Please see the section
                            titled “Waiver of Conflicts” for additional
                            information.{" "}
                            <strong>
                              You understand and agree that deposit amounts in
                              excess of the Deposit Limit at a Priority Bank and
                              deposit amounts at an Excess Bank will not be
                              insured by the FDIC.
                            </strong>
                          </p>
                          <p>
                            Periodically, your previously deposited funds may be
                            reallocated among the Program Banks on a revised
                            Bank List based on each Program Bank’s available
                            capacity or other criteria, including rates paid by
                            Program Banks to us.
                          </p>
                          <p>
                            <strong>
                              You understand and agree that it is your
                              obligation to monitor your funds deposited at all
                              Program Banks. You may email Service Provider at
                              any time at{" "}
                              <a href="mailto:support@iconsavingsplan.com">
                                support@iconsavingsplan.com
                              </a>{" "}
                              to determine your Sub-Deposit Account balances at
                              each Program Bank.
                            </strong>
                          </p>
                        </li>
                        <li>
                          <strong>Withdrawal Procedures.</strong> You agree that
                          all withdrawals will be made by us as your agent.
                          Funds may be withdrawn from Program Banks based on a
                          Program Bank’s available capacity or other criteria,
                          including, but not limited to, rates or fees paid to
                          Bank. Funds may also be withdrawn from Sub-Deposit
                          Account to fund transactions associated with your
                          Account.
                          <p>
                            If you have an MMDA and a TA at a given Program
                            Bank, all withdrawals will be made from the TA. If
                            funds in the TA at a Program Bank from which funds
                            are being withdrawn are insufficient, funds in the
                            related MMDA at that Program Bank will be
                            transferred to the TA, plus funds to maintain any TA
                            minimum, or “threshold,” amount.
                          </p>
                          <p>
                            At any point during a month in which transfers from
                            an MMDA at a Program Bank have reached a total of
                            six (6), all funds will be transferred from that
                            MMDA to the linked TA at the Program Bank until the
                            end of the month. Deposits for the remainder of the
                            month into this Program Bank will be made to the TA.
                            At the beginning of the next month, funds on deposit
                            in the TA will be transferred to the MMDA, minus any
                            threshold amount to be maintained in the TA. The
                            limits on MMDA transfers will not limit the number
                            of withdrawals you can make from funds on deposit at
                            a Program Bank or the amount of FDIC insurance
                            coverage for which you are eligible.
                          </p>
                          <p>
                            Pursuant to federal banking regulations, the Program
                            Banks may reserve the right to require seven (7)
                            days’ prior written notice before you withdraw or
                            transfer funds from an MMDA. The Program Banks have
                            indicated that they have no present plans to
                            exercise this right. TAs are not subject to this
                            restriction.
                          </p>
                        </li>
                        <li>
                          <strong>
                            Interest Rates Paid on Deposit Balances.
                          </strong>{" "}
                          You will not be paid interest on your Sub-Deposit
                          Accounts. Your Account is not an interest-bearing
                          account and you will not be paid interest or any other
                          form of income on your funds held in any Account or
                          Sub-Deposit Account.
                        </li>
                        <li>
                          <strong>Waiver of Conflicts.</strong> You understand
                          and agree that, as your agent, we may place any or all
                          of your funds on deposit with us at one or more
                          Program Banks in an amount determined in Bank’s sole
                          discretion based on available capacity at the Program
                          Bank or other criteria, including rates paid on
                          deposits by the Program Bank or fees paid to Bank.
                          <p>
                            You understand and agree that Bank has no obligation
                            to place funds into Program Banks to maximize the
                            amount of deposit insurance available on your funds
                            or to maximize any interest rates that your funds
                            may earn. Bank may place your funds without regard
                            to whether such funds may exceed the Deposit Limit
                            at one or more Program Banks, even if your funds
                            could be placed in one or more Program Banks in an
                            amount less than the Deposit Limit.
                          </p>
                          <p>
                            You understand and agree that the Program Banks may
                            pay us fees to Bank and that we have no obligation
                            to share these fees with you. You understand and
                            agree that we may financially benefit if we place
                            your funds into a Program Bank in excess of the
                            Deposit Limit when that Program Bank pays a greater
                            amount of fees to us than other Program Banks that
                            could increase the deposit insurance available to
                            you. You further acknowledge and agree that each
                            Program Bank may pay us a fee in connection with the
                            placement of funds with the Program Bank, including
                            for each Sub-Deposit Account. The fee may vary
                            between banks. Your Account is not an
                            interest-bearing account and you will not be paid
                            interest or any other form of income on your funds
                            held in any Account or Sub-Deposit Account. Even if
                            we receive a fee from a Program Bank, you are not
                            entitled to interest on your Sub-Deposit Account.
                            For the avoidance of doubt, you authorize us to
                            collect and retain all payments of income pertaining
                            to the placement of your deposits at other Program
                            Banks, including the Sub-Deposit Accounts. You
                            further acknowledge that we and Program Banks may
                            also receive other financial benefits in connection
                            with the funds in your Sub-Deposit Account.
                          </p>
                          <p>
                            Our placement of funds in the Sub-Deposit Account
                            may reflect considerations of federal and state law,
                            our funding needs and funding needs of Program
                            Banks, general economic conditions or other factors
                            determined by us in our sole discretion. We may
                            place funds to enhance our business objectives and
                            for balance sheet management purposes without any
                            benefit to you. Subject to applicable law, your only
                            rights with respect to the Sub-Deposit Account are
                            to demand that we repay you all amounts in your
                            Account that were deposited with Bank and those
                            transferred to the Sub-Deposit Account from your
                            Account.
                          </p>
                        </li>
                        <li>
                          <strong>Information About Your Accounts.</strong>{" "}
                          Although there are two or more accounts associated
                          with your funds (the Account and the Sub-Deposit
                          Account), your Account is treated as a single account
                          for our reporting deposits and withdrawals, as well as
                          for balance requirement, service charge, and monthly
                          statement (which will reflect the total balance in
                          your Account and each Sub-Deposit Account, excluding
                          any payments or amounts owed or belonging to us or any
                          Program Bank). The funds you deposit with us in your
                          Account (whether or not transferred to a Sub-Deposit
                          Account) will be made available to you in accordance
                          with this Agreement (and regardless of the performance
                          of any of our loans or investments, subject to FDIC
                          insurance limitations). The existence of the
                          Sub-Deposit Account will not change the manner in
                          which you use or obtain information about your
                          Account. Transfers to and from your Sub-Deposit
                          Account will not appear on your monthly statement. We
                          are responsible for the accuracy of your Account
                          statements, not the Program Banks.
                          <p>
                            You will receive, from time to time, periodic
                            information which will reflect the balances of your
                            Sub-Deposit Accounts at each Program Bank for the
                            applicable period.
                          </p>
                        </li>
                      </ol>
                      <li>
                        <strong>
                          Your Relationship with Bank and the Program Banks.
                        </strong>{" "}
                        We will act as your agent and custodian in establishing
                        the Sub-Deposit Accounts at each Program Bank,
                        depositing funds into the Sub-Deposit Accounts,
                        withdrawing funds from the Sub-Deposit Accounts and
                        transferring funds among the Sub-Deposit Accounts.
                        <p>
                          Sub-Deposit Account ownership will be evidenced by a
                          book entry on the account records of each Program Bank
                          (or by us on behalf of Program Bank) and by records
                          maintained by us as your custodian. No evidence of
                          ownership, such as a passbook or certificate will be
                          issued to you. You may at any time obtain information
                          about your Sub-Deposit Accounts by contacting Service
                          Provider.
                        </p>
                        <p>
                          You agree that all transactions with respect to your
                          Sub-Deposit Accounts must be directed by us and all
                          information concerning your Sub-Deposit Accounts must
                          be obtained from Bank through Service Provider. You
                          agree that the Program Banks have no obligation to
                          accept instructions from you with respect to your
                          Sub-Deposit Accounts or to provide you with
                          information concerning your Sub-Deposit Accounts.
                        </p>
                        <p>
                          If you decide to terminate your participation in the
                          Program, your termination will result in a return of
                          funds in accordance with law and closing of your
                          Account and any Sub-Deposit Account opened
                          specifically for you.
                        </p>
                        <p>
                          Each Sub-Deposit Account constitutes an obligation of
                          a Program Bank and is not directly or indirectly our
                          obligation. You can obtain publicly available
                          financial information concerning each Program Bank at
                          &lt;
                          <a href="http://www.ffiec.gov/nicpubweb/nicweb/nichome.aspx">
                            www.ffiec.gov/nicpubweb/nicweb/nichome.aspx
                          </a>
                          &gt;, by contacting the FDIC Public Information Center
                          by mail at L. William Seidman Center, Virginia Square,
                          3501 North Fairfax Drive, Arlington, Virginia 22226,
                          or by phone at 703-562-2200.
                        </p>
                        <p>
                          We do not guaranty in any way the financial condition
                          of any Program Bank or the accuracy of any publicly
                          available financial information concerning any Program
                          Bank.
                        </p>
                      </li>
                      <li>
                        <strong>Fees.</strong> You understand and agree that
                        each Program Bank will pay us a fee in connection with
                        the Program. The fee may vary between banks. Even if
                        Bank receives a fee from a Program Bank, you may not be
                        entitled to interest on your Sub-Deposit Account. Other
                        than applicable fees deducted by us, you will not be
                        charged fees or commissions in connection with your
                        participation in the Program.
                        <p>
                          In addition, other service providers may receive fees
                          from each Program Bank or Bank and may receive a
                          portion of the fee paid to us by the Program Banks.
                        </p>
                      </li>
                      <li>
                        <strong>Changes to the Program.</strong> You agree that
                        we may modify the terms and conditions of the Program,
                        including changing, removing or adding Program Banks
                        offered therein. All notices of material changes to the
                        Program may be communicated by a posting on our website,
                        letter, a separate electronic or written communication,
                        a note on a periodic statement or other means.
                      </li>
                      <li>
                        <strong>Information about the FDIC.</strong>
                        <p>
                          <strong>Deposit Insurance: General.</strong> Your
                          Sub-Deposit Accounts are insured by the FDIC, an
                          independent agency of the U.S. Government, up to
                          $250,000 for all deposits held in the same insurable
                          capacity as recognized by the FDIC at any one bank.
                          According to the FDIC, no depositor has ever lost a
                          penny of FDIC-insured funds.
                        </p>
                        <p>
                          Your funds become eligible for FDIC insurance
                          immediately upon placement into your Sub-Deposit
                          Account at a Priority Bank. Generally, any accounts or
                          deposits that you maintain directly with a particular
                          bank, or through any other intermediary, in the same
                          insurable capacity in which the accounts or deposits
                          are maintained would be aggregated with the accounts
                          or deposits for purposes of the $250,000 Deposit
                          Limit.
                        </p>
                        <p>
                          <strong>
                            You are responsible for monitoring the total amount
                            of deposits that you hold with any one bank,
                            directly or through an intermediary, in order to
                            determine the extent of FDIC insurance coverage
                            available to you on your deposits. We are not
                            responsible for any insured or uninsured portion of
                            any deposits.
                          </strong>
                        </p>
                        <p>
                          In the event a Priority Bank fails, your Sub-Deposit
                          Accounts at that Priority Bank is insured, up to
                          $250,000, for amounts accrued to the day the Priority
                          Bank is closed.
                        </p>
                        <p>
                          Under certain circumstances, if you become the owner
                          of deposits at a Program Bank because another
                          depositor dies, beginning six (6) months after the
                          death of the depositor the FDIC will aggregate those
                          deposits for purposes of the $250,000 Deposit Limit
                          with any other deposits that you own in the same
                          insurable capacity at the bank. Examples of deposit
                          accounts that may be subject to this FDIC policy
                          include joint accounts, “payable on death” accounts
                          and certain trust accounts. The FDIC provides the
                          six-month “grace period” to permit you to restructure
                          your deposits to obtain the maximum amount of deposit
                          insurance for which you are eligible.
                        </p>
                        <p>
                          In the event that FDIC deposit insurance payments
                          become necessary, payments of deposits will be made to
                          us as your custodian. There is no specific time period
                          during which the FDIC must make insurance payments
                          available. We will not be obligated to you for amounts
                          not covered by deposit insurance nor will we be
                          obligated to make any payments to you in satisfaction
                          of a loss you might incur as a result of a delay in
                          insurance payouts. We will not be obligated to credit
                          your account with funds in advance of payments
                          received from the FDIC.
                        </p>
                        <p>
                          Furthermore, you may be required to provide certain
                          documentation to the FDIC before insurance payments
                          are made. For example, if you hold deposits as trustee
                          for the benefit of trust participants, you may be
                          required to furnish affidavits and provide indemnities
                          regarding an insurance payment.
                        </p>
                        <p>
                          If your balance in your Sub-Deposit Accounts are
                          assumed by another depository institution pursuant to
                          a merger or consolidation, such deposits will continue
                          to be insured separately, up to the FDIC insurance
                          coverage limits and subject to the terms hereunder,
                          from the deposits that you might have established with
                          the acquiring institution until (i) the maturity date
                          of any time deposits (including certificates of
                          deposit) that were assumed, or (ii) with respect to
                          deposits that are not time deposits, the expiration of
                          a six (6) month period from the date of acquisition.
                          Thereafter, any assumed deposits will be aggregated
                          with your existing deposits with the acquiring
                          institution held in the same capacity for purposes of
                          FDIC insurance coverage. Any deposit opened at the
                          acquiring institution after the acquisition will be
                          aggregated with deposits established with the
                          acquiring institution for purposes of FDIC insurance
                          coverage.
                        </p>
                        <p>
                          If you have questions about basic FDIC insurance
                          coverage, please contact us. You may wish to seek
                          advice from your own attorney concerning FDIC
                          insurance coverage of deposits held in more than one
                          insurable capacity. You may also obtain information by
                          contacting the FDIC, Deposit Insurance Outreach,
                          Division of Depositor and Consumer Protection, by
                          letter (550 17<sup>th</sup> Street, N.W., Washington,
                          D.C. 20429), by phone (877-275-3342 or 800-925-4618
                          (TDD)), by visiting the FDIC website at:{" "}
                          <a href="https://www.fdic.gov/deposit/index.html">
                            https://www.fdic.gov/deposit/index.html
                          </a>
                          , or by email using the FDIC’s Online Customer
                          Assistance Form available on its website.
                        </p>
                      </li>
                      <li>
                        <strong>Termination.</strong> Your participation in the
                        Program may be terminated without penalty at any time at
                        the option of either you or us. You may also opt-out of
                        the Program at any time. Following opt-out or
                        termination of your participation in the Program, we
                        may, if applicable, transfer your funds in the
                        Sub-Deposit Accounts at Program Banks to your Account
                        or, at our sole discretion, terminate your Account and
                        return your funds in accordance with applicable laws and
                        regulations.
                      </li>
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Interest and Interest Rate</strong>. Your Account is
                  not an interest-bearing account.
                </li>
                <li>
                  <strong>Prohibitions</strong>. The Account is only available
                  for personal, family or household purposes and not for
                  business purposes. You are not entitled to open an Account
                  under this Agreement if you are a corporation, unincorporated
                  business association, partnership, limited liability company,
                  incorporated nonprofit organization, sole proprietorship or
                  any other entity. We reserve the right to close your Account
                  if we determine that it is used for any impermissible purpose,
                  as we may determine from time to time. You may not use your
                  Account for illegal gambling or any other illegal transaction
                  or purpose. This prohibition includes any transaction that is
                  illegal in the jurisdiction where you live, in the
                  jurisdiction where the transaction is consummated, or in any
                  other jurisdiction affected by the transaction. You are
                  responsible for determining the legality of each of your
                  transactions in all applicable jurisdictions before entering
                  into the transaction. We have no obligation to monitor, review
                  or evaluate the legality of any transaction. Nevertheless, we
                  may deny transactions or authorizations if we believe the
                  transaction is or may be illegal. We reserve the right to
                  refuse or return any item that we believe is related to an
                  illegal transaction, an Internet or online gambling
                  transaction or a high-risk transaction. To the fullest extent
                  permitted by law, you agree to pay for any transaction that
                  you authorized, even if the transaction is determined to be
                  illegal or associated with an illegal activity. We reserve the
                  right to cancel, close or restrict use of the Account,
                  including by refusing the processing of any transaction, if we
                  believe you have violated this Agreement or any applicable
                  law.
                </li>
                <li>
                  <strong>Account Fees; Fee Schedule</strong>. You agree to pay
                  all fees and charges applicable to your Account, which will be
                  automatically deducted from your Account and may result in
                  your Account being overdrawn. The amounts of these fees and
                  charges are listed in our Fee Schedule, attached as Exhibit 1
                  (“Fee Schedule”). Fees and our Fee Schedule are subject to
                  change at any time and without notice to you, unless required
                  by law. Fees set forth in the Fee Schedule are charged by Bank
                  for its services. Service Provider may charge you fees
                  separate and apart from the Fees set forth in the Fee Schedule
                  for its Services, pursuant to the Service Provider Terms. Bank
                  is not responsible for any fees charged by Service Provider
                  for its Services. If you request a service that is not
                  included in the Fee Schedule and there is a fee for such
                  service, such fee will be disclosed at the time you request
                  the service and you agree that any such fee may be deducted
                  from your Account.
                </li>
                <li>
                  <strong>Negative Balance.</strong> Each time you initiate a
                  transaction using your Account, you authorize the Bank to
                  reduce the funds available in your Account by the amount of
                  the transaction (and any reversals) and all associated fees.
                  You are not allowed to exceed the available amount in your
                  Account through an individual transaction or a series of
                  transactions (creating a “negative balance”). Nevertheless, if
                  any transaction causes the balance in your Account to go
                  negative, including, but not limited to, any purchase
                  transactions where the retailer or merchant does not request
                  authorization, you shall remain fully liable to us for the
                  amount of any negative balance and any corresponding
                  transaction fees. We reserve the right to bill you for any
                  negative balance or to recoup such negative balance from your
                  Account or any other account you have at the Bank. You agree
                  to pay us promptly for the negative balance and any related
                  fees. We also reserve the right to terminate your Account if
                  you create one or more negative balances. If you fail to pay
                  us amounts owed under this Agreement, we may refer your
                  Account, and collection of amounts owed, to a collection
                  agency. You may also be liable for any related Insufficient
                  Funds/NSF Fee(s) as set forth in Exhibit 1.
                </li>
                <li>
                  <strong>Power of Attorney/Agents</strong>. Subject to
                  applicable law, we may allow you to give another person (known
                  as an “attorney-in-fact”) power of attorney to act on your
                  behalf for your Account; provided, we are not obligated to
                  honor such power of attorney until you first obtain our
                  written approval. Email us at{" "}
                  <a href="mailto:support@iconsavingsplan.com">
                    support@iconsavingsplan.com
                  </a>{" "}
                  for approval if you plan to create a power of attorney. Unless
                  we approve of your power of attorney in writing, we are not
                  required to honor orders and instructions concerning your
                  Account by an attorney in fact for any Account owner, or by a
                  personal representative, guardian, conservator, or custodian
                  of an account owner. If we approve your appointment of an
                  attorney in fact, we will honor orders and instructions from
                  your attorney-in-fact until the earlier of (1) our receipt
                  from you revoking such power of attorney and our written
                  acknowledgement; (2) our receipt of notice that you or your
                  attorney-in-fact have died or become incapacitated; or (3) our
                  termination of the acceptance of the power of attorney. We
                  have no duty to monitor or ensure that the acts of your
                  attorney in fact are for your use or benefit or are otherwise
                  permissible under applicable law. We will not be liable if
                  your attorney in fact exceeds his or her powers or does not
                  comply with your instructions or applicable law. We may
                  terminate acceptance of a power of attorney at any time and
                  for any reason and without notice to you. You agree to hold us
                  harmless from and against any actions we take based upon the
                  instructions of your attorney in fact or that your attorney in
                  fact takes regarding your Account.
                </li>
                <li>
                  <strong>Death, Incapacity or Termination</strong>. You agree
                  to notify us immediately if an Account owner on your Account
                  or if you, through a representative, dies or is declared
                  legally incompetent. In the case of an individual Account, if
                  we have reason to believe the Account owner has died or has
                  been declared legally incompetent, we may place a hold on the
                  Account to retain funds and refuse all transactions until we
                  know and have verified the identity of the successor. Until we
                  receive notice and any required proof of death or
                  incapacitation, we may continue to accept deposits and process
                  transactions to your Account. If you die while residing
                  outside the United States, we may require a personal
                  representative to be appointed by a court in a United States
                  jurisdiction. Until we receive notice and any required proof
                  of death or incompetence, we may act as if all owners are
                  alive and competent. In the event we receive written notice
                  from a personal representative, executor, administrator,
                  conservator or guardian purporting to represent you or your
                  estate, we shall be entitled to rely on all information
                  supplied and representations made in such written notice to
                  the full extent permitted by applicable law. If certain
                  payments originating from government entities are deposited
                  into your Account after your death, we may be required to
                  return those payments to the originator upon notice. If we
                  have any tax liability because of paying your balance to your
                  estate, the estate will be responsible for repaying us the
                  amount of that tax. If you owe us a debt at the time of your
                  death, we are authorized to exercise our right of setoff (our
                  right to apply funds in one account to the debt associated
                  with another account) or security interest rights against the
                  funds credited to your balance after your death. You agree to
                  hold us harmless for any actions we take based on our belief
                  that you have died or become incapacitated, or any notices of
                  death or incapacitation that we receive.
                </li>
                <li>
                  <strong>Monitoring and Closing Your Account</strong>. Subject
                  to the terms herein, you can close your Account at any time
                  and for any reason by contacting us or terminating the Account
                  through the Service Provider Platform, if applicable. We
                  reserve the right to refuse your request to close your Account
                  if your Account is not in good standing or if you have a
                  negative balance on your Account. If you intend to close your
                  Account, you should notify us through the Service Provider
                  Platform. Simply reducing your Account balance to $0.00 is
                  insufficient notice. If you close your Account you are
                  responsible for transactions you initiated or authorized,
                  including those that we receive after the Account is closed,
                  subject to applicable law. Withdrawals from your Account may
                  be restricted prior to Account closing.
                  <p>
                    We reserve the right to monitor all Account activity for
                    inappropriate use. We may also suspend or close your Account
                    at our discretion and for any reason with or without notice.
                    This includes if we believe you are using your Account for
                    fraudulent or illegal purposes or in violation of law or
                    regulation, this Agreement, any other agreement you may have
                    with us or Service Provider, or if you otherwise present
                    undue risk to us or Service Provider. We are not responsible
                    to you for any damages you may suffer as a result of the
                    closure or suspension of your Account. Once we have closed
                    your Account, you agree that we can assess any service
                    charge otherwise applicable against any remaining balance in
                    your Account. We are not responsible to you for any damages
                    you may suffer as a result of your Account being closed. If
                    you attempt to make a deposit to an Account we closed, we
                    may collect the deposit and set-off your indebtedness to us.
                    The closure of your Account or termination of this Agreement
                    does not impact any right or obligation that arose prior to
                    closure or termination, or any right or obligation that, by
                    its nature, should survive termination (including, but not
                    limited to, any indemnification obligation by you, our
                    limitations of liability, and any terms governing
                    arbitration). If we close your Account, all collected funds
                    (less any amounts due to us or for debits in process) will
                    be transferred to your Linked Account. Alternatively, we
                    may, at our discretion, mail you a check for the available
                    balance in your Account. Written notice that the Account has
                    been closed, to the extent required by law, and a check, if
                    any, will be sent to any email address, in the case of
                    notice, and address, in the case of a check delivery, shown
                    on our records for you or the email address we have on file
                    for the Account. We reserve the right not to return funds to
                    you if your account balance is less than $1.00.
                  </p>
                </li>
                <li>
                  <strong>Dormancy and Unclaimed Property</strong>.
                  <p>
                    State laws (called “escheat” or unclaimed property laws)
                    require us to close your Account and transfer your money to
                    the state if your Account is dormant for a period of time as
                    defined by your state of residence. State and federal law
                    and our policy govern when your Account is considered
                    dormant. Your Account is usually considered dormant if you
                    have not accessed your Account, communicated to us about
                    your Account or otherwise shown an interest in your Account
                    within the period of time specified under applicable law.
                    Each state has varying laws as to when an account is subject
                    to escheatment and we may be required to send the balance in
                    your Account to the state of your last known address or, if
                    not in the U.S., the state in which we reside. We will make
                    all reasonable efforts to contact you if required by
                    applicable law before transferring the remaining balance of
                    your Account to the applicable state. After we surrender the
                    funds to the state, we have no further liability to you for
                    those funds and you must apply to the appropriate state
                    agency to reclaim your funds. You can avoid the transfer of
                    your money to the state simply by signing into your Account,
                    transacting periodically, or contacting us, or replying to
                    any abandoned property correspondence. If your Account
                    becomes dormant, you may no longer receive statements, but
                    you can still view your accounts online.
                  </p>
                </li>
              </ol>
              <li>
                <strong>ARBITRATION AND WAIVERS</strong>.
                <p>
                  <strong>
                    THIS ARBITRATION PROVISION WILL HAVE A SUBSTANTIAL IMPACT ON
                    HOW LEGAL DISPUTES BETWEEN YOU AND US ARE RESOLVED. PLEASE
                    READ THIS SECTION V (ARBITRATION AND WAIVERS) CAREFULLY.
                  </strong>{" "}
                  For a dispute subject to arbitration, neither you nor we will
                  have the right to: (A) have a court or a jury decide the
                  dispute; (B) engage in information-gathering (discovery) to
                  the same extent as in court; © participate in a class action
                  in court or in class arbitration; or (D) join or consolidate a
                  claim with claims of any other persons. Arbitration procedures
                  are simpler and more limited than rules applicable in court.
                </p>
                <ol type={"A"}>
                  <li>
                    <strong>Definitions</strong>. If you have a dispute with us
                    or our Service Provider, and we are not able to resolve the
                    dispute informally, you and we agree that upon demand by
                    either you or us, the dispute will be resolved through the
                    arbitration process as set forth in this section. A “claim”
                    or “dispute,” as used in this Section V (Arbitration and
                    Waivers), is any unresolved disagreement between you, us
                    and/or our Service Provider, arising from or relating in any
                    way to the Account, or this Agreement. The terms “claim” and
                    “dispute” are to be given the broadest possible meaning that
                    will be enforced and includes, by way of example and without
                    limitation, any disagreement relating in any way to your
                    Account or any additional account, joint accountholder;
                    services offered in connection with an Account or any other
                    services offered pursuant to this Agreement; to your use of
                    any of our banking products or services; to any means you
                    may use to access your Account(s); to any advertisements,
                    promotions or oral or written statements related to the
                    Account; to the benefits and services related to an Account;
                    or your approval, establishment or enrollment for an
                    Account. Claims also include disagreements about the
                    meaning, application or enforceability of this arbitration
                    agreement. As solely used in this Section V (Arbitration and
                    Waivers), “we” or “us” shall include the Bank, its
                    affiliates and their successors, employees, directors,
                    officers and agents. In addition, “we” or “us” shall include
                    Service Provider, any third party, their respective
                    subsidiaries, affiliates, licensees, predecessors,
                    successors, and assigns using or providing any product,
                    service or benefit in connection with this Agreement or any
                    Account if, and only if, such third party is named as a
                    co-party with us (or files a claim with or against us) in
                    connection with a claim asserted by you. The terms “you” or
                    “yours” shall mean each Account owner and all persons or
                    entities approved to have, approved to use and/or given
                    access to an Account, including but not limited to all
                    persons or entities contractually obligated under this
                    Agreement and all joint accountholder and any additional
                    accountholders. The term “Account,” for purposes of
                    arbitration, includes any updated or substitute account for
                    you related to the Account or any other account contemplated
                    by this Agreement.
                  </li>
                  <li>
                    <strong>Binding Arbitration and Appeals</strong>. If either
                    party elects arbitration, that claim shall be arbitrated on
                    an individual basis. Arbitration applies whenever there is a
                    claim between you and us. The arbitrator’s authority to
                    resolve claims or make awards is limited to claims between
                    you and us alone. If a third party is also involved in a
                    claim between you and us, then the claim will be decided
                    with respect to the third party in arbitration as well, and
                    it must be named as a party in accordance with the rules of
                    procedure governing the arbitration. No award or relief will
                    be granted by the arbitrator except on behalf of, or
                    against, a named party. Furthermore, claims brought by you
                    against us or by us against you may not be joined or
                    consolidated with claims brought by or against someone other
                    than you, unless otherwise agreed to in writing by all
                    parties. Claims are subject to arbitration, regardless of on
                    what theory they are based, whether they seek legal or
                    equitable remedies, or whether they are common law or
                    statutory (Federal or state) claims. Arbitration applies to
                    any and all such claims or disputes, whether they arose in
                    the past, may currently exist, or may arise in the future.
                    <p>
                      Judgment upon the arbitrator’s award may be entered by any
                      court having jurisdiction. The arbitrator’s decision is
                      final and binding, except for any right of appeal provided
                      by the Federal Arbitration Act (Title 9 of the United
                      States Code) (“FAA”). However, if the amount of the claim
                      exceeds $25,000 or involves a request for injunctive or
                      declaratory relief that could foreseeably involve a cost
                      or benefit to either party exceeding $25,000, any party
                      can, within thirty (30) days after the entry of the award
                      by the arbitrator, appeal the award to a three-arbitrator
                      panel administered by the forum. The panel shall
                      reconsider anew all factual and legal issues, following
                      the same rules of procedure and decide by majority vote.
                      Reference in this Section V (Arbitration and Waivers) to
                      “the arbitrator” shall mean the panel if an appeal of the
                      arbitrator’s decision has been taken.
                    </p>
                  </li>
                  <li>
                    <strong>Initiation of Arbitration</strong>. Any claim must
                    be resolved, upon the election by you or us, by arbitration
                    pursuant to this Section V (Arbitration and Waivers) and the
                    code of procedures of the national arbitration organization
                    to which the claim is referred in effect at the time the
                    claim is filed. Claims shall be referred to either Judicial
                    Arbitration and Mediation Services (“JAMS”) or the American
                    Arbitration Association (“AAA”), as selected by the party
                    electing to use arbitration. For a copy of the procedures,
                    to file a claim or for other information about these
                    organizations, contact them as follows: (i) JAMS at 1920
                    Main Street, Suite 300, Los Angeles, CA 92614; website at{" "}
                    <a href="http://www.jamsadr.com">www.jamsadr.com</a>; and
                    (ii) AAA at 335 Madison Avenue, New York, NY 10017; website
                    at <a href="http://www.adr.org/">www.adr.org.</a>
                    <p>
                      In the event that JAMS or the AAA is unable to handle the
                      dispute for any reason, then the matter shall be
                      arbitrated instead by a neutral arbitrator selected by
                      agreement of the parties pursuant to the AAA rules of
                      procedure; or, if the parties cannot agree, selected by a
                      court in accordance with the FAA. To the extent that there
                      is any variance between the selected forum’s rules and
                      this Section V (Arbitration and Waivers), this Section V
                      (Arbitration and Waivers) shall control.
                    </p>
                    <p>
                      If you initiate the arbitration, you must notify us in
                      writing at 1631 NE Broadway St. #815, Portland OR 97232.
                      If we initiate the arbitration, we will notify you in
                      writing at your last known address in our file.
                    </p>
                  </li>
                  <li>
                    <strong>Class Action and Jury Waiver</strong>. NEITHER YOU
                    NOR WE (I) HAVE THE RIGHT TO LITIGATE THAT CLAIM IN COURT OR
                    HAVE A JURY TRIAL ON THAT CLAIM, (II) SHALL BE ENTITLED TO
                    JOIN OR CONSOLIDATE DISPUTES BY OR AGAINST OTHERS IN ANY
                    COURT ACTION OR ARBITRATION, (III) HAVE THE RIGHT TO INCLUDE
                    IN ANY COURT ACTION OR ARBITRATION ANY DISPUTE AS A
                    REPRESENTATIVE OR MEMBER OF ANY CLASS OF CLAIMANTS
                    PERTAINING TO ANY CLAIM SUBJECT TO ARBITRATION, (IV) HAVE
                    THE RIGHT TO ACT IN ANY ARBITRATION IN THE INTEREST OF THE
                    GENERAL PUBLIC OR IN A PRIVATE ATTORNEY GENERAL CAPACITY,
                    UNLESS THOSE PERSONS ARE BENEFICIARIES ON YOUR ACCOUNT; OR
                    (V) ENGAGE IN DISCOVERY EXCEPT AS PROVIDED FOR IN THE CODE
                    OF PROCEDURES OF JAMS OR AAA, AS APPLICABLE (THE “CODE”).
                    THIS IS SO WHETHER OR NOT THE CLAIM HAS BEEN ASSIGNED. NOTE
                    THAT OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT
                    MAY NOT BE AVAILABLE IN ARBITRATION.
                  </li>
                  <li>
                    <strong>Location of Arbitration and Cost</strong>. Any
                    arbitration shall take place in Memphis, Tennessee, unless
                    the parties agree to a different location in writing. The
                    party initiating the arbitration (or appeal of the first
                    arbitration award) shall pay the initial filing fee. If you
                    file the arbitration and an award is rendered in your favor,
                    we will reimburse you for your filing fee. All fees and
                    costs will be allocated in accordance with the rules of the
                    arbitration forum. Each party shall bear the expense of
                    their respective attorneys, experts, and witnesses and other
                    expenses, regardless of who prevails, but the arbitrator
                    will have the authority to award attorneys and expert
                    witness fees and costs to the extent permitted by this
                    Agreement, the forum’s rules, or applicable law.
                  </li>
                  <li>
                    <strong>Arbitration Procedures</strong>: This Section V
                    (Arbitration and Waivers) is made pursuant to a transaction
                    involving interstate commerce, and shall be governed by the
                    Federal Arbitration Act, 9 U.S.C. Sections 1-16, as it may
                    be amended (the “FAA”). The arbitration shall be governed by
                    the applicable Code, except that (to the extent enforceable
                    under the FAA) this Arbitration Section shall control if it
                    is inconsistent with the applicable Code. The arbitrator
                    shall apply applicable substantive law consistent with the
                    FAA and applicable statutes of limitations and shall honor
                    claims of privilege recognized at law and, at the timely
                    request of either party, shall provide a brief written
                    explanation of the basis for the decision. In conducting the
                    arbitration proceeding, the arbitrator shall not apply the
                    Federal or any state rules of civil procedure or rules of
                    evidence. Either party may submit a request to the
                    arbitrator to expand the scope of discovery allowable under
                    the applicable Code. The party submitting such a request
                    must provide a copy to the other party, who may submit
                    objections to the arbitrator with a copy of the objections
                    provided to the request party, within fifteen (15) days of
                    receiving the requesting party’s notice. The granting or
                    denial of such request will be in the sole discretion of the
                    arbitrator who shall notify the parties of his/her decision
                    within twenty (20) days of the objecting party’s submission.
                    The arbitrator shall take reasonable steps to preserve the
                    privacy of individuals, and of business matters. Judgment
                    upon the award rendered by the arbitrator may be entered in
                    any court having jurisdiction. The arbitrator’s decision
                    will be final and binding, except for any right of appeal
                    provided by the FAA. However, any party can appeal that
                    award to a three-arbitrator panel administered by the same
                    arbitration organization, as set forth in Section V(B)
                    (Arbitration and Waivers).
                  </li>
                  <li>
                    <strong>Governing Law</strong>. You and we agree that in our
                    relationship arising from this Agreement: (1) the parties
                    are participating in transactions involving interstate
                    commerce; (2) the arbitrator shall decide any dispute
                    regarding the enforceability of this arbitration agreement;
                    and (3) this arbitration agreement and any resulting
                    arbitration are governed by the provisions of the FAA, and,
                    to the extent any provision of that act is inapplicable, the
                    laws of the State of Tennessee. The arbitrator must apply
                    applicable substantive law consistent with the FAA and
                    applicable statutes of limitations and claims of privilege
                    recognized at law. At the timely request of either party,
                    the arbitrator must provide a brief written explanation of
                    the basis for the award.
                  </li>
                  <li>
                    <strong>Continuation and Severability.</strong> This Section
                    V (Arbitration and Waivers) shall survive termination of
                    your Account, any voluntary payment of any debt owed to us
                    and paid in full by you, any legal proceeding by us to
                    collect a debt owed by you, and/or any bankruptcy by you or
                    us. If any portion of this Section V (Arbitration and
                    Waivers) of this Arbitration Section is deemed invalid or
                    unenforceable under any principle or provision of law or
                    equity, consistent with the FAA, it shall not invalidate the
                    remaining portions of this Section V (Arbitration and
                    Waivers), this Agreement or any prior agreement you may have
                    had with us, each of which shall be enforceable regardless
                    of such invalidity.
                  </li>
                </ol>
              </li>
            </li>
            <li>
              <strong>MISCELLANEOUS TERMS</strong>.
              <ol type={"A"}>
                <li>
                  <strong>Ordinary Care; No Fiduciary Obligation</strong>. You
                  agree that any act or omission made by us in reliance upon or
                  in accordance with any provision of the Uniform Commercial
                  Code as adopted in the State of Tennessee, or any rule or
                  regulation of the State of Tennessee or a federal agency
                  having jurisdiction over the Bank, shall constitute ordinary
                  care. Unless otherwise specified or required by law, we do not
                  assume any fiduciary obligation on your behalf. Unless
                  required by law, this means that we do not act as your trustee
                  or financial advisor, and we do not assume any responsibility
                  for your account beyond reasonable care.
                </li>
                <li>
                  <strong>Governing Law, Forum and Time Limits</strong>.
                  <ol type={"i"}>
                    <li>
                      All Accounts are opened at the Tennessee branch office of
                      the Bank. All actions relating to your Account, including
                      this Agreement , will be governed by the laws and
                      regulations of the United States and the State of
                      Tennessee (to the extent that laws of the State of
                      Tennessee are not preempted by Federal law). Federal and
                      the laws of the State of Tennessee shall be applied
                      without giving effect to principles of conflicts of law.
                    </li>
                    <li>
                      To the extent any dispute arising under this Agreement or
                      relating in any way to your Account or your relationship
                      with us is not arbitrated, you consent to the jurisdiction
                      of, and agree that such dispute will be resolved by the
                      Federal or state court located in Shelby County,
                      Tennessee.
                    </li>
                    <li>
                      Subject to applicable law, you must file any lawsuit (to
                      the extent permitted by this Agreement) or arbitration
                      against us within one (1) year after the claim arises,
                      unless Federal law or the laws of the State of Tennessee
                      or an applicable agreement requires a longer time. This
                      limit is in addition to limits on notice as a condition to
                      making a claim. If applicable law does not permit
                      contractual shortening of the time during which a lawsuit
                      must be filed to a period as short as one (1) year, you
                      agree to the shortest permitted time under Tennessee law.
                      Any action against us must be brought within the period
                      that the applicable law requires us to preserve records
                      unless applicable law or this agreement provides a shorter
                      limitation period.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Reporting Information</strong>. We may report
                  information about your Account to consumer reporting agencies,
                  governmental agencies, and/or third-party data services.
                  Defaults on your Account may be reflected in your consumer
                  report.
                </li>
                <li>
                  <strong>Changes in Terms</strong>. The terms and conditions of
                  this Agreement, including any fees, and features of your
                  Account may be amended or revised at any time by posting a
                  revised version at{" "}
                  <a href="https://employer.iconsavingsplan.com/dashboard/documents">
                    https://employer.iconsavingsplan.com/dashboard/documents
                  </a>{" "}
                  or{" "}
                  <a href="https://my.iconsavingsplan.com/dashboard/documents">
                    https://my.iconsavingsplan.com/dashboard/documents
                  </a>{" "}
                  depending on your account type, and any such revisions or
                  changes shall be effective upon posting. We will give you
                  advance notice if we are required by applicable law. We may
                  provide such notice to you with your statement,
                  electronically, or by mail. Any notice we provide to you will
                  be binding and sent to the last (postal or electronic) address
                  in our records. We may change your address if we receive an
                  address change notice from the U.S. Postal Service. We may
                  change or terminate this Agreement without notice at our
                  discretion or to comply with any appropriate federal or state
                  law or regulation. Subject to the terms of this Agreement, you
                  may determine to cease use of or close your Account if you do
                  not agree with any change or revision to this Agreement prior
                  to the effective date of such revision. Any continued use of
                  your Account is presumed your acceptance of and agreement to
                  be bound by revisions to this Agreement.
                </li>
                <li>
                  <strong>Security Interest</strong>. You grant us a security
                  interest in your Account to secure payment of any money that
                  you owe to us arising under this Agreement or any other
                  agreements with us. You acknowledge and grant us the right to
                  use any of the funds in your Account to cover any money you
                  owe to us (a “right of set-off”). We have the right to set-off
                  any liability, direct or contingent, past, present or future
                  that you owe against any account you have with us. This means
                  that we can take any funds in your Account or any other
                  account you have with us to pay any debt or liability you owe
                  us. We may exercise our security interest or right of set-off
                  without prior recourse to other sources of repayment or
                  collateral, if any, and even if such action causes you to lose
                  interest, incur any penalty or suffer any other consequence.
                  If we exercise our security interest or right to set-off, we
                  will notify you to the extent required by applicable law. If
                  the law imposes conditions or limits on our ability to take or
                  set off funds in your Account, to the extent that you may do
                  so by contract, you waive those conditions and limits, and you
                  authorize us to apply funds as we deem applicable. We may also
                  exercise these rights against any Account beneficiary. These
                  rights exist no matter who contributed the funds to the
                  Account. We will consider your consent to this Agreement as
                  your consent for us asserting our security interest or
                  exercising our right of set-off should any laws governing your
                  Account require your consent.
                </li>
                <li>
                  <strong>No Waiver of Rights</strong>. If we fail to exercise
                  or waive a right with respect to your Account on one or more
                  occasions, it does not mean we have waived, or are obligated
                  to waive, the same right on any other occasion. We may release
                  any other person obligated under this Agreement without
                  affecting your responsibilities under this Agreement. In any
                  event, no such waiver or delay by us is effective unless it is
                  in writing and approved by us.
                </li>
                <li>
                  <strong>Assignment</strong>. Your Account is not transferable
                  and is not assignable as collateral for a loan or for any
                  other purpose.
                  <p>
                    The Bank may transfer or assign its rights under this
                    Agreement, including transferring or assigning your Account
                    without your consent and without notice, unless required by
                    applicable law.
                  </p>
                </li>
                <li>
                  <strong>Other Services and Conflicts</strong>. If you and Bank
                  have entered into any other agreement related to additional
                  bank services offered in connection with your Account (“Other
                  Agreement”), this Agreement and the Other Agreement shall be
                  read together as if one agreement. If any term of such Other
                  Agreement conflicts with any term of this Agreement, this
                  Agreement shall control. If any statement that is made by one
                  of our employees, our affiliates’ employees, or by Servicer or
                  Servicer’s employees or affiliates, conflicts with this
                  Agreement, the terms of this Agreement shall govern.
                </li>
                <li>
                  <strong>Privacy Policy</strong>. You authorize us to collect,
                  transmit, store, use or share technical, location, login or
                  other information about you and your Account or use of your
                  Account, with our affiliates, the Service Provider, payment
                  network and third parties, unless applicable law or{" "}
                  <a href="https://nam05.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.getevolved.com%2Fprivacy-policy%2F&amp;data=02%7C01%7Csarah.erwin%40getevolved.com%7C615d3db9d5ff44b8a7ff08d81846098d%7Ce27426a1cf5f454c9939221f9145deab%7C0%7C0%7C637286036385695899&amp;sdata=Qs%2FZCxYxB%2FmV%2FBg%2F8vafSDJhjMLdix6YcyCvy%2F%2BRXyI%3D&amp;reserved=0">
                    our Privacy Policy
                  </a>{" "}
                  prohibits us from doing so. Please see our Privacy Policy, as
                  it may be amended from time to time, for your choices about
                  information sharing, accessible at{" "}
                  <a href="https://www.getevolved.com/privacy-policy/">
                    https://www.getevolved.com/privacy-policy/
                  </a>
                  .
                  <p>
                    You understand, acknowledge and agree that the use and
                    disclosure of any information provided by you directly to
                    the applicable payment network, the Service Provider or
                    other third parties (including their respective agents,
                    representatives, affiliates and service providers) will
                    permit such parties to have access to certain details of
                    your transactions made with your Account and such use,
                    accesses and disclosure will be governed by such party’s
                    privacy policy and not our privacy policy, and, to the
                    greatest extent permitted by law, we are not liable for the
                    use of your information by any such parties. You understand
                    that the information that is provided to or held by third
                    parties in relation to your Account is outside of our
                    control. Any information you provide to any third party
                    through your use of your Account, is subject to the third
                    party’s separate agreement with you, including that party’s
                    privacy policy.
                  </p>
                  <p>
                    We periodically collect and use technical data and related
                    information (for example, we may collect technical
                    information about any device used by you in connection with
                    the services and products provided to you under this
                    Agreement and do so to facilitate the updates to our
                    services).
                  </p>
                  <p>
                    Push notifications can be displayed on a locked or inactive
                    device screen. Since it is possible to view this information
                    even when your device is locked, you must maintain physical
                    control over your device and/or turn off push notifications
                    to prevent potential unauthorized use.
                  </p>
                </li>
                <li>
                  <strong>Severability</strong>. In the event that any court or
                  tribunal of competent jurisdiction determines that any
                  provision of this Agreement is illegal, invalid, or
                  unenforceable, the remainder of this Agreement shall not be
                  affected thereby. To the extent permitted by applicable law,
                  the parties hereto waive any provision of law which prohibits
                  or renders unenforceable any provision hereof, and to the
                  extent that such waiver is not permitted by applicable law,
                  the parties intend that such provision be interpreted as
                  modified to the minimum extent necessary to render such
                  provision enforceable.
                </li>
                <li>
                  <strong>
                    Limitation of Liability, Disclaimer of Warranty,
                    Indemnification and Reimbursements
                  </strong>
                  .
                  <ol type={"i"}>
                    <li>
                      Limitation of Liability. EXCEPT AS REQUIRED BY LAW, WE ARE
                      NOT LIABLE FOR ANY CLAIMS, COSTS, LOSSES, OR DAMAGES
                      RESULTING DIRECTLY OR INDIRECTLY FROM OUR FAILURE TO ACT,
                      OR ANY DELAY BEYOND TIME LIMITS PRESCRIBED BY LAW OR
                      PERMITTED BY THIS AGREEMENT IF SUCH FAILURE OR DELAY IS
                      CAUSED BY MAINTENANCE OR INTERRUPTION OR MALFUNCTION OF
                      EQUIPMENT OR COMMUNICATION FACILITIES, UNUSUAL TRANSACTION
                      VOLUME, SUSPENSION OF PAYMENTS BY ANOTHER FINANCIAL
                      INSTITUTION, FIRE, NATURAL DISASTERS, ELEMENTS OF NATURE,
                      GOVERNMENT ACTION, ACTS OF WAR, TERRORISM OR CIVIL STRIFE,
                      EMERGENCY CONDITIONS, OR OTHER CIRCUMSTANCES BEYOND THE
                      REASONABLE CONTROL OF BANK. EXCEPT AS REQUIRED BY LAW, OUR
                      LIABILITY TO YOU FOR A CLAIM IS LIMITED TO THE FACE VALUE
                      OF THE ITEM OR TRANSACTION, OR THE ACTUAL VALUE OF ANY
                      FUNDS NOT PROPERLY CREDITED OR DEBITED. IN NO EVENT WILL
                      WE BE LIABLE FOR ANY CONSEQUENTIAL, INDIRECT, SPECIAL,
                      INCIDENTAL, EXEMPLARY, PUNITIVE DAMAGES OR DAMAGES OF ANY
                      KIND EVEN IF YOU ADVISE US OF THE POSSIBILITY OF SUCH
                      DAMAGES. EXCEPT AS REQUIRED BY LAW AND NOTWITHSTANDING
                      ANYTHING TO THE CONTRARY, WE SHALL ONLY BE RESPONSIBLE AND
                      LIABLE FOR OUR OWN GROSS NEGLIGENCE OR WILLFUL MISCONDUCT
                      IN PERFORMING OUR OBLIGATIONS UNDER THIS AGREEMENT. WE
                      SHALL NOT BE LIABLE TO ANY THIRD PARTY OR FOR ANY ACT OR
                      OMISSION OF YOURS OR ANY THIRD PARTY, INCLUDING, BUT NOT
                      LIMITED TO, THIRD PARTIES USED BY US IN EXECUTING ANY
                      TRANSACTION CONTEMPLATED BY THIS AGREEMENT OR PERFORMING A
                      RELATED ACT AND NO SUCH THIRD PARTY SHALL BE DEEMED TO BE
                      OUR AGENT. ALL BANK SERVICES AND ACCOUNT FEATURES ARE
                      PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF
                      ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                      LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                      FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THE
                      BANK’S SERVICES OR ACCOUNT FEATURES WILL MEET YOUR
                      REQUIREMENTS, BE CONTINUOUS, UNINTERRUPTED, SECURE,
                      TIMELY, OR ERROR-FREE, OR THAT DEFECTS WILL BE CORRECTED.
                      IN ADDITION, EXCEPT AS REQUIRED BY LAW, WE ARE NOT LIABLE
                      OR RESPONSIBLE FOR ANY SERVICES OR FEATURES OF THE SERVICE
                      PROVIDER PLATFORM OR SERVICES ANY OTHER PRODUCTS PROVIDED
                      TO YOU BY SERVICE PROVIDER. WE ARE ALSO NOT LIABLE FOR ANY
                      UNAUTHORIZED ACCESS OF YOUR INFORMATION OR DATA BY A THIRD
                      PARTY DUE TO YOUR USE OF THIRD-PARTY COMMUNICATION
                      CHANNELS NOT OFFERED BY US. TO THE FULLEST EXTENT
                      PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EITHER
                      EXPRESS OR IMPLIED OR STATUTORY, INCLUDING, BUT NOT
                      LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                      FITNESS FOR A PARTICULAR PURPOSE AND LACK OF VIRUSES
                      RELATED TO THE SERVICES AND/OR PRODUCTS OF SERVICE
                      PROVIDER AND/OR THE SERVICE PROVIDER PLATFORM.
                      <p>
                        YOU AGREE THAT THE AMOUNT OF ANY CLAIM YOU HAVE AGAINST
                        US IN CONNECTION WITH ANY ACCOUNT OR TRANSACTION WITH
                        US, WHETHER BROUGHT AS A WARRANTY, NEGLIGENCE, WRONGFUL
                        DISHONOR OR OTHER ACTION, IS SUBJECT TO REDUCTION TO THE
                        EXTENT THAT: 1) NEGLIGENCE OR FAILURE TO USE REASONABLE
                        CARE ON YOUR PART, OR ON THE PARTY OF ANY OF YOUR AGENTS
                        OR EMPLOYEES, CONTRIBUTED TO THE LOSS WHICH IS THE BASIS
                        OF YOUR CLAIM AND 2) DAMAGES COULD NOT BE AVOIDED BY OUR
                        USE OF ORDINARY CARE.
                      </p>
                      <p>
                        ANY LOSS RECOVERY YOU OBTAIN FROM THIRD PARTIES ON A
                        PARTICULAR CLAIM WILL REDUCE THE AMOUNT OF ANY
                        OBLIGATIONS WE MAY HAVE TO YOU ON THAT CLAIM AND YOU
                        WILL IMMEDIATELY NOTIFY US OF ANY SUCH RECOVERY. YOU
                        AGREE TO PURSUE ALL RIGHTS YOU MAY HAVE UNDER ANY
                        INSURANCE POLICY YOU MAINTAIN IN CONNECTION WTH ANY LOSS
                        AND TO PROVIDE US INFORMATION REGARDING COVERAGE. OUR
                        LIABILITY WILL BE REDUCED BY THE AMOUNT OF ANY INSURANCE
                        PROCEEDS YOU RECEIVE OR ARE ENTITLED TO RECEIVE IN
                        CONNECTION WITH THE LOSS. IF WE REIMBURSE YOU FOR A LOSS
                        COVERED BY INSURANCE, YOU AGREE TO ASSIGN US YOUR RIGHTS
                        UNDER THE INSURANCE TO THE EXTENT OF YOUR REIMBURSEMENT.
                      </p>
                    </li>
                    <li>
                      Indemnification. To the greatest extent permitted by law,
                      you agree to indemnify and hold us and our officers,
                      directors, shareholders, employees, successors,
                      predecessors, representatives, principals, agents,
                      assigns, parents, subsidiaries and/or insurers harmless
                      from any losses, damages, suits and expenses, including
                      reasonable attorneys’ fees, that we may incur, without
                      regard to the merit or lack thereof, arising out of, or
                      related in any way to: (i) taking any action or not taking
                      any action that we are entitled to take pursuant to this
                      Agreement; (ii) any action or omission by you; (iii) the
                      matters set forth herein; or (iv) our action or inaction
                      in reliance upon oral, written or electronic instructions
                      or information from you.
                    </li>
                    <li>
                      Reimbursement for Losses. If we take any action to collect
                      debt incurred by you or other amounts you owe us under
                      this Agreement or defend ourselves in a lawsuit brought by
                      you where we are the prevailing party, you agree to
                      reimburse us for our losses, including, without
                      limitation, reasonable attorneys’ fees, to the extent
                      permitted by applicable law. We may charge your Account
                      for our losses without prior notice to you. This section
                      does not, in any case, reduce our liability to you as
                      described in Section VIII (Electronic Fund Transfers
                      Disclosure).
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Liability for Service Interruptions</strong>. From
                  time to time, due to maintenance, malfunctions or failures of
                  software, equipment, or telecommunications devices, as well as
                  unusual transaction volume or similar reasons, access to your
                  Account may not be available to you. Access to your Account
                  may also be interrupted as a result of things beyond our
                  reasonable control, computer failures, loss of power, failure
                  or interruption of communication or transportation facilities,
                  riots, strikes, or civil unrest. You agree that we shall not
                  be responsible for any loss, damages, costs or expenses that
                  you may suffer or incur, directly or indirectly, as a result
                  of the unavailability of access to your Account, regardless of
                  whether it could be shown that we could have prevented or
                  reduced the duration of such unavailability by taking any
                  action within our reasonable control. We shall also not be
                  responsible for any loss, damages, costs or expenses which you
                  may suffer or incur, directly or indirectly, as a result of
                  your inability to access your Account caused directly or
                  indirectly, in whole or in part, by your computer or mobile
                  device, your inability to establish a connection to or to
                  access the Internet, or other limitations or constraints of
                  the Internet. The provisions set forth in this section shall
                  apply in addition to any other provisions in this Agreement
                  but as applied to service interruptions for access to your
                  Account, this section shall control.
                </li>
                <li>
                  <strong>Section Headings and Successors</strong>. The headings
                  in this Agreement are only for convenience and do not in any
                  way limit or define your or our rights or obligations under
                  this Agreement. This Agreement will be binding on your
                  personal representatives, executors, administrators, and
                  successors.
                </li>
                <li>
                  <strong>
                    Change of Address or any Application Information
                  </strong>
                  . You must notify us through the Service Provider Platform of
                  any change of address, email address or phone number or any
                  material change to information you provided us in your
                  application to open an Account. Any notice we provide to you
                  will be binding and sent to the last (postal or electronic)
                  address in our records. We may change your address if we
                  receive an address change notice from the U.S. Postal Service
                  or if a company in the business of providing correct addresses
                  informs us that the address in our records no longer matches
                  your address.
                </li>
                <li>
                  <strong>
                    Electronic Communications and Electronic Signatures;
                    Electronic Storage of Data and Records
                  </strong>
                  .
                  <ol type={"i"}>
                    <li>
                      Electronic Communications. When you applied you agreed and
                      each time you use your Account or any other service or
                      product that we provide to you under this Agreement you
                      agree, to the terms and conditions of our Electronic
                      Communications Consent Agreement accessible at at{" "}
                      <a href="https://employer.iconsavingsplan.com/dashboard/documents">
                        https://employer.iconsavingsplan.com/dashboard/documents
                      </a>{" "}
                      or{" "}
                      <a href="https://my.iconsavingsplan.com/dashboard/documents">
                        https://my.iconsavingsplan.com/dashboard/documents
                      </a>{" "}
                      depending on your account type.
                    </li>
                    <li>
                      Electronic Signatures. You understand, acknowledge and
                      agree that we may use your electronic signature in lieu of
                      or in addition to your handwritten signature and that your
                      electronic signature will have the same legal and binding
                      effect as your handwritten signature.
                    </li>
                    <li>
                      Electronic Storage of Data and Records. You agree and
                      consent that we may record or otherwise document and store
                      information electronically transmitted, entered or
                      received by us or through any mobile application or
                      website provided to you to access and/or use your Account
                      for our record-keeping purposes. You further acknowledge
                      and agree that such records, documents and other stored
                      information shall be presumed to be accurate
                      representation of your requests, communications and
                      instructions to us.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Calls and Messages.</strong> You agree that we or our
                  agents or service providers may contact you regarding any
                  matter for any non- telemarketing reason using any kind of
                  telecommunications technology at any email and telephone
                  number you provide to us, including the phone number for your
                  mobile device. You agree to receive these calls and messages,
                  including pre-recorded or auto-dialed calls. You also agree
                  that we may send text messages to any phone number for your
                  mobile device you provide to us. You understand and accept
                  that your service provider may charge you for these calls and
                  messages consistent with applicable law. For quality control
                  purposes and for other reasons, you permit us to record and
                  monitor your telephone conversations and electronic
                  communications with us (including email). Unless the law
                  requires otherwise, you consent in advance to such recording
                  and monitoring and we do not need to remind you of these
                  activities at the time of the call or communication. To revoke
                  the consent provided pursuant to this subsection, you must
                  send ten (10) days’ prior written notice of such revocation to
                  1631 NE Broadway St. #815, Portland OR 97232.
                </li>
                <li>
                  <strong>Survival</strong>. Termination of this Agreement shall
                  not impact any right or obligation arising prior to
                  termination, and in any event, the parties agree that any
                  right or obligation which, by its nature, should survive
                  termination of this Agreement will survive any such
                  termination (including, but not limited to Sections V
                  (Arbitration and Waivers) and V (Miscellaneous Terms) of this
                  Agreement.
                </li>
              </ol>
            </li>
            <li>
              <strong>TAX REPORTING AND BACKUP WITHHOLDING</strong>.
              <p>
                Federal law requires us to have a valid Social Security Number
                or Individual Taxpayer Identification Number for each Account
                owner so that we can correctly report the interest you have
                earned to the relevant tax authorities (if your Account is an
                interest-bearing account). Accordingly, you must certify your
                Social Security Number or Individual Taxpayer Identification
                Number as part of opening an Account. If you do not provide us
                with and certify a valid Social Security Number or Individual
                Taxpayer Identification Number within the time period specified,
                we are required to withhold a percentage of the taxable interest
                that we pay to you. This withholding is commonly referred to as
                “Backup Withholding.” In some states, we may also be required to
                withhold taxable interest for state taxes.
              </p>
            </li>
            <li>
              <strong>ELECTRONIC FUND TRANSFERS DISCLOSURE</strong>.
              <p>
                Your Account allows you to withdraw funds up to the available
                balance or make deposits through electronic fund transfers
                (“EFTs”). EFTs are transactions that are processed by electronic
                means. This Section VIII (Electronic Fund Transfers Disclosure)
                provides you with information and important disclosures and
                terms about the EFTs that are permitted on your Account You may
                also receive additional services from Service Provider through
                the Service Provider Platform that allow you to initiate EFTs to
                and from your Account that are not described in this Agreement.
                You will be provided separate agreements and disclosures
                applicable to those services from Service Provider.
              </p>
              <ol type={"A"}>
                <li>
                  <strong>Supported EFTs</strong>. The EFTs that we may support
                  are listed below:
                  <ol type={"i"}>
                    <li>
                      Direct deposits of federal government payroll, disability,
                      veteran’s benefits, Social Security, and other federal
                      government payments that you may receive and that may be
                      deposited to your Account and of your paycheck to your
                      Account, provided that your employer offers direct deposit
                      and you complete any forms required by your employer.
                    </li>
                    <li>
                      Any deposit or withdrawal to your Account that is handled
                      electronically as an ACH Transfer.
                    </li>
                    <li>
                      Transactions at ATMs to withdraw cash, transfer funds and
                      find out balances.
                    </li>
                    <li>
                      Transactions at participating merchants to purchase goods
                      and services. Some merchants may also allow you to
                      withdraw cash from your Account while making a purchase.
                    </li>
                    <li>
                      Online fund transfers using your Account routing and
                      account number, if available.
                      <p>
                        We do not charge for EFTs, but we may add charges at any
                        time by providing you with prior notice if required by
                        law. Transfer limitations under Section B, include
                        limits on ATM withdrawals, cash advances and PIN or
                        signature-based purchases. ACH Transfers to external
                        accounts are also subject to limits on the dollar amount
                        of electronic transfers between your Account and
                        external accounts at other financial institutions,
                        including Linked Accounts. When you use an ATM not owned
                        by us, you may be charged a fee by the ATM operator or
                        any network used (and you may be charged a fee for a
                        balance inquiry even if you do not complete a fund
                        transfer).
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Transfer Limitations</strong>. All EFTs are subject to
                  limits. The transfer limit depends on the EFT type. The
                  frequency and dollar amount of Account transactions may be
                  limited based on fraud risk and security of the Account. All
                  limits described here are designed to be flexible in order to
                  protect the security and integrity of the service and
                  accounts, including the Account , as well as you and all other
                  users of the service. These limitations may be based on
                  confidential fraud and risk criteria that are essential to our
                  management of risk and the protection of you and the integrity
                  of the service and all Accounts and may be modified at our
                  sole discretion without advance notice. For a description of
                  certain limits, please see Section VIII (B) (Transfer
                  Limitations) and Exhibit 2 (Transaction Limits), which is
                  incorporated herein by reference. These limits are examples
                  and subject to change based on fraud risk and security of the
                  Account.
                </li>
                <li>
                  <strong>Preauthorized Credits</strong>. If you have arranged
                  to have direct deposits made to your Account at least once
                  every 60 days from the same person or company including your
                  employer, you can call us at 503-917-0590 to find out whether
                  or not the deposit has been made or you can check if the
                  deposit has been made by reviewing your transaction history in
                  the Service Provider Platform.
                </li>
                <li>
                  <strong>Liability for Failure to Make EFTs</strong>. If we do
                  not complete a transfer to or from your Account on time or in
                  the correct Amount according to our agreement with you, we
                  will be liable for damages or losses proximately caused by our
                  failure. All such damages and losses for a failure to make a
                  transfer will have to be actual proven damages. However, there
                  are some exceptions, and we will not be liable for any damages
                  or losses if, for example: (1) there are insufficient funds in
                  your Account to complete the transaction through no fault of
                  ours; (2) the funds in your Account are not yet available for
                  use or withdrawal; (3) the ATM where you are making the
                  transfer does not have enough cash; (4) the ATM, system,
                  equipment or terminal involved in the transfer is not working
                  properly and you know about the improper functioning at the
                  time you started the transaction; (5) the funds in your
                  Account are subject to a Legal Process or other encumbrance
                  restricting the transfer; (6) the circumstances preventing the
                  transfer or the failure of the transfer is beyond our control
                  (e.g., power outage, an Act of God, fire, natural disaster)
                  even after we have taken reasonable precautions; or (7) the
                  Account is inactive or dormant or has been revoked due to
                  inactivity or at our discretion. There may be additional
                  exceptions stated in our agreement with you or permitted by
                  law.
                </li>
                <li>
                  <strong>Liability for Unauthorized Transfers</strong>.
                  <p>
                    Tell us AT ONCE if you believe your Credentials have been
                    lost or stolen, or if you believe that an electronic fund
                    transfer has been made without your permission using
                    information from your Account. You could lose all the money
                    in your Account. If you tell us within two (2) business days
                    after you learn of the loss or theft of your Credentials,
                    you can lose no more than $50 if someone used your
                    Credentials without your permission. If you do NOT tell us
                    within two (2) business days after you learn of the loss or
                    theft of your Credentials, and we can prove we could have
                    stopped someone from using your Credentials without your
                    permission if you had told us, you could lose as much as
                    $500.
                  </p>
                  <p>
                    Also, if your statement shows transfers that you did not
                    make, tell us at once. If you do not tell us within sixty
                    (60) days after the statement was mailed to you or first
                    became available via electronic means, you may not get back
                    any money you lost after the sixty (60) days if we can prove
                    that we could have stopped someone from taking the money if
                    you had told us in time. We can extend these time periods if
                    extenuating circumstances (such as a long trip or hospital
                    stay) kept you from notifying us.
                  </p>
                </li>
                <li>
                  <strong>Business Days</strong>. For purposes of these
                  disclosures, our business days are (Monday through Friday)
                  (Monday through Saturday) (any day including Saturdays and
                  Sundays). Federal holidays are (not) included.
                </li>
                <li>
                  <strong>Confidentiality</strong>. We will disclose information
                  to third parties about your Account, the transfers you make:
                  (1) where it is necessary for completing transfers or
                  transactions; (2) in order to verify the existence and
                  condition of your Account for a third party, such as a credit
                  bureau or merchant; (3) in order to comply with government
                  agency or court orders; (4) if you give us your written
                  permission; (5) to our and the Bank’s employees, auditors,
                  affiliates, service providers, or attorneys as need; (6) in
                  order to collect Fees or funds you may owe us; (7) as
                  otherwise provided by the Privacy Policy; and (8) to verify
                  your identity and determine if we should open an Account for
                  you or using services provided to us by third parties.
                  <p>
                    Please refer to Section VI (I) (Privacy Policy) for more
                    information about our commitment to you, your privacy rights
                    and who we share data and information with. Services offered
                    by Service Provider, including the Service Provider
                    Platform, are also subject to Service Provider’s privacy
                    policy{" "}
                    <a href="https://iconsavingsplan.com/privacy/">
                      https://iconsavingsplan.com/privacy/
                    </a>
                    . Please carefully review Service Provider’s privacy policy
                    for more information about Service Provider’s commitment to
                    you, your privacy rights and who Service Provider shares
                    data and information with.
                  </p>
                </li>
                <li>
                  <strong>Preauthorized Payments</strong>.
                  <ol type={"i"}>
                    <li>
                      Right to stop payment and procedure for doing so. If you
                      have told us in advance to make regular payments out of
                      your account, you can stop any of these payments. Here’s
                      how: Call us at 503-917-0590, in time for us to receive
                      your request 3 business days or more before the payment is
                      scheduled to be made. If you call, we may also require you
                      to put your request in writing and get it to us within 14
                      days after you call. We may charge you for each
                      stop-payment order you give, in accordance with our Fee
                      Schedule.
                    </li>
                    <li>
                      Notice of varying amounts. If these regular payments vary
                      in amount, the person you are going to pay will tell you,
                      ten (10) days before each payment, when it will be made
                      and how much it will be. (You may choose instead to get
                      this notice only when the payment would differ by more
                      than a certain amount from the previous payment, or when
                      the amount would fall outside certain limits that you
                      set.).
                    </li>
                    <li>
                      Liability for failure to stop payment of preauthorized
                      transfer. If you order us to stop one of these payments
                      three (3) Business Days or more before the transfer is
                      scheduled, and we do not do so, we will be liable for your
                      losses or damages. To the extent permitted by law, our
                      liability for losses or damages may be limited to actual
                      damages that you have sustained if the failure to stop
                      payment was due to a bona fide error.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Error Resolution</strong>. In Case of Errors or
                  Questions About Your Electronic Transfers Telephone us at
                  503-917-0590, write us at 1631 NE Broadway St. #815, Portland
                  OR 97232; or email us at{" "}
                  <a href="mailto:support@iconsavingsplan.com">
                    support@iconsavingsplan.com
                  </a>{" "}
                  as soon as you can, if you think your statement or receipt is
                  wrong or if you need more information about a transfer listed
                  on the statement or receipt. We must hear from you no later
                  than sixty (60) days after we sent the FIRST statement on
                  which the problem or error appeared.
                  <p>
                    You must provide us with the following information: (1) tell
                    us your name and account number (if any); (2) describe the
                    error or the transfer you are unsure about, and explain as
                    clearly as you can why you believe it is an error or why you
                    need more information; and (3) tell us the dollar amount of
                    the suspected error.
                  </p>
                  <p>
                    If you tell us orally, we may require that you send us your
                    complaint or question in writing within ten (10) business
                    days. We will determine whether an error occurred within ten
                    (10) business days after we hear from you and will correct
                    any error promptly. If we need more time, however, we may
                    take up to forty-five (45) days to investigate your
                    complaint or question. If we decide to do this, we will
                    credit your Account within ten (10) business days for the
                    amount you think is in error, so that you will have the use
                    of the money during the time it takes us to complete our
                    investigation. If we ask you to put your complaint or
                    question in writing and we do not receive it within ten (10)
                    business days, we may not credit your Account. For errors
                    involving new Accounts, point-of-sale, or foreign-initiated
                    transactions, we may take up to ninety (90) days to
                    investigate your complaint or question. For new Accounts, we
                    may take up to twenty (20) business days to credit your
                    Account for the amount you think is in error.
                  </p>
                  <p>
                    We will tell you the results within three business days
                    after completing our investigation. If we decide that there
                    was no error, we will send you a written explanation. You
                    may ask for copies of the documents that we used in our
                    investigation. If we credit your Account with funds while
                    investigating an error, you must repay those funds to us if
                    we conclude that no error has occurred.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <strong>DEPOSIT AVAILABILITY DISCLOSURE</strong>.
              <p>
                When a deposit is made to your Account, the funds may not be
                available immediately. This Section IX (Deposit Availability
                Disclosure) describes when funds will be made available to you.
              </p>
              <ol type={"A"}>
                <li>
                  <strong>Delays</strong>. We may delay the availability of
                  funds from certain types of deposits to your Account. There
                  are exceptions that may apply in certain circumstances (e.g.,
                  if you have overdrawn your Account repeatedly in the last six
                  (6) months or there is an emergency). During the delay, you
                  may not withdraw funds and may not use the funds. We will
                  notify you if we delay the availability of your funds if
                  required by law, and we will tell you when you can expect your
                  funds to be available if required by law.
                </li>
                <li>
                  <strong>Deposits</strong>. Any transaction that you conduct on
                  Saturday, Sunday, a federal holiday, or other days on which we
                  are closed will be handled the following business day. If we
                  receive a deposit before 5:00 PM Pacific on a Business Day, we
                  will consider that business day to be the day of your deposit.
                  However, if we receive a deposit after 5:00 PM Pacific or on a
                  non -Business Day, we will consider the deposit as having been
                  made the next business day. Deposits may only be made by EFT,
                  which includes direct deposits and ATM Transfers.
                </li>
                <li>
                  <strong>Availability of ACH Transfer and EFT Deposits</strong>
                  . Funds from ACH Transfers or EFTs that you initiate through
                  another bank will <strong>generally</strong> be available on
                  the next business day after the day the ACH Transfer or EFT is
                  deemed to be received by us. Once they are available, you can
                  us e the funds for EFTs and ACH Transfers. Even after we have
                  made funds available to you and you have withdrawn the funds,
                  you remain responsible if any deposit to your Account is
                  returned, rejected, or otherwise uncollected by the Bank. An
                  ACH Transfer or EFT debit to a Linked Account or any other
                  external account that we originate on your behalf will
                  generally be made available on the third business day after
                  the date the ACH Transfer or EFT is deemed to be received and
                  processed by us.
                </li>
              </ol>
            </li>
            <li>
              <strong>
                EVOLVE BANK &amp; TRUST ELECTRONIC COMMUNICATION CONSENT
              </strong>
              <p>Effective Date: {info.effectiveDate}</p>
              <p>
                We want to provide you with communications electronically.
                Certain laws and regulations require us to provide
                communications to you “in writing,” which means you may be
                entitled to receive the information on paper. The E-SIGN Act
                allows us to provide you communications electronically and to
                conduct transactions with you electronically, with your consent.
              </p>
              <p>
                Please read this Evolve Bank &amp; Trust Electronic
                Communication Consent (the “Consent”) carefully prior to
                providing us with your consent. This Consent describes how we
                deliver and receive communications to and from you
                electronically and asks you to consent to use electronic records
                and signatures in our relationship with you. If you do not agree
                to this Consent or you later withdraw your consent provided
                herein, you may not be able to continue to use our services.
              </p>
              <p>
                <strong>Definitions</strong>
              </p>
              <p>
                “<strong>We</strong>”, “<strong>us</strong>”, and “
                <strong>our</strong>” means Evolve Bank &amp; Trust, its current
                or future affiliated companies, agents, assignees and service
                providers.
              </p>
              <p>
                “<strong>You</strong>” and “<strong>your</strong>” mean each
                applicant, account owner and anyone else with access to the
                account. If there is more than one owner, then these words mean
                each account owner separately, and all account owners jointly.
              </p>
              <p>
                “<strong>Communication</strong>” means each application,
                agreement, disclosure, notice, fee schedule, response to claims,
                statement, privacy policy, record, document and other
                information related to your account or to any Product, or that
                you sign, submit or agree to at our request.
              </p>
              <p>
                “<strong>Product</strong>” means each and every account, product
                or service we offer or will offer.
              </p>
              <p>
                The words “include” and “including,” when used at the beginning
                of a list of one or more items, indicates that the list contains
                examples and is not exclusive or exhaustive, and the items in
                the list are only illustrations.
              </p>
              <p>
                <strong>
                  Scope of Communications to be Provided in Electronic Form.
                </strong>
              </p>
              <p>
                This Consent applies to all Communications and Products. By
                applying for or using a Product, you agree that any
                Communications will be provided in electronic format, to the
                extent allowed by law, and that paper Communications will not be
                sent. Your consent to receive electronic Communications and
                transactions includes, but is not limited to:
              </p>
              <ul>
                <li>
                  All legal and regulatory disclosures and communications
                  associated with the Product;
                </li>
                <li>
                  The Account Terms, including any amendments thereto, and any
                  and all agreements by and between you and us that relate to a
                  Product;
                </li>
                <li> Privacy policies and notices;</li>
                <li>Responses to claims filed in connection with a Product;</li>
                <li> Statements; and</li>
                <li>
                  All other communications between us and your concerning the
                  Product and any related transactions, products or services.
                </li>
              </ul>
              <p>
                Sometimes the law, or our agreement with you, requires you to
                give us a written notice. You must still provide these notices
                to us on paper, unless we specifically tell you in another
                Communication how you may deliver that notice to us
                electronically.
              </p>
              <p>
                We reserve the right, at our sole discretion, to discontinue the
                provision of electronic Communications, or to terminate or
                change the terms and conditions upon which electronic
                Communications are provided. We will provide you with notice of
                any such termination or change as required by law.
              </p>
              <p>
                <strong>
                  Method of Providing Communications in Electronic Form.
                </strong>
              </p>
              <p>
                All Communications that we provide in electronic form will be
                provided either (1) by e-mail or (2) by access to a website
                designated in an email notice from us, (3) through the any
                mobile application we may make available, (4) to the extent
                permitted by law, by access to a website generally designated in
                advance for such purpose, or (5) in the manner specified in any
                other agreement we or our affiliates have with you.
              </p>
              <p>
                If you seek to obtain a new product, service or account with us,
                we may remind you that you have already consented to receiving
                electronic Communications and using electronic signatures in
                your relationship with us.
              </p>
              <p>
                Continuing to use our Products after receiving updates to our
                system requirements signifies your acceptance of the change and
                reaffirmation of your consent.
              </p>
              <p>
                <strong>Keeping your Records Current.</strong>
              </p>
              <p>
                It is your responsibility to provide us with a true, accurate
                and complete e-mail address, street address, and other
                information related to this Consent and a Product, and to
                maintain and update promptly any changes in this information.
                You can update information (such as your e-mail address) by
                contacting us at{" "}
                <a href="mailto:support@iconsavingsplan.com">
                  support@iconsavingsplan.com
                </a>
                . We are not responsible for any delay or failure in the receipt
                of the Communications if we send the Communications to the last
                e-mail address you provided to us.
              </p>
              <p>
                <strong>
                  System Requirements for Accessing Communications.
                </strong>
              </p>
              <p>
                In order to access, view, and retain electronic Communications
                that we make available, you must have:
              </p>
              <ul>
                <li>
                  computers capable of running one of these compatible browsers:
                </li>
                <li>Internet Explorer version 9.0 or higher.</li>
                <li>Firefox version 35 or higher.</li>
                <li>Safari version 6.1 or higher.</li>
                <li>Chrome version 38 or higher.</li>
              </ul>
              <p>OR</p>
              <ul>
                <li>
                  an Apple iPhone or iPad running iOS version 9.0 or higher.
                </li>
                <li>
                  access to an active e-mail account with an email service
                  provider.
                </li>
              </ul>
              <p>
                We may update these requirements as necessary to preserve the
                ability to receive electronic Communications. If there is a
                substantial change in these requirements, you will be notified
                as required by law.
              </p>

              <p>
                <strong>Requesting Paper Copies.</strong>
              </p>
              <p>
                We will not send paper copies of any Communication; however, we
                reserve the right, but assume no obligation, to provide a paper
                (instead of electronic) copy of any Communication that you have
                authorized us to provide electronically. You can obtain a paper
                copy of an electronic Communication by printing it or by
                requesting that we mail a paper copy. To request a paper copy,
                call us at 503-971-0590 during normal business hours, except for
                national holidays. There may be a fee associated with the
                request for the delivery of paper copies of any Communication
                provided electronically pursuant to this Consent.
              </p>
              <p>
                <strong>Communications in Writing.</strong>
              </p>
              <p>
                All Communications in either electronic or paper format from us
                to you will be considered “in writing.” You should print or
                download a copy of this Consent and any other Communications for
                your records.
              </p>
              <p>
                <strong>Withdrawing Your Consent</strong>
              </p>
              <p>
                You can withdraw your consent to receive Communications
                electronically at any time. Your withdrawal of consent will
                become effective after we have had a reasonable opportunity to
                act upon it. To withdraw your consent to receive Communications
                electronically, you must contact us by e-mailing at{" "}
                <a href="mailto:support@iconsavingsplan.com">
                  support@iconsavingsplan.com
                </a>
                .
              </p>
              <p>
                If you withdraw consent, your access to all Products will be
                terminated and any account you have with us will be closed and
                funds will be returned to you in accordance with the account
                agreement. If you withdraw consent, the legal validity and
                enforceability of prior Communications delivered in electronic
                form will not be affected.
              </p>
              <p>
                <strong>Consent</strong>
              </p>
              <p>
                By applying for or using a Product or by checking any call to
                action (including “I Agree” or similar language), you give us
                affirmative consent to receive electronic Communications as
                described herein.
              </p>
              <p>
                By providing your consent, you are also confirming that you have
                the hardware and software described above, that you are able to
                receive and review electronic Communications, and that you have
                an active email account. You are also confirming that you are
                authorized to, and do, consent on behalf of all the other
                account owners, authorized signers, authorized representatives,
                delegates, product owners and/or service users identified with
                your Products.
              </p>
            </li>
          </ol>
        </div>
      </div>

      <div>
        <p>
          <strong>Exhibit 1</strong>
        </p>
      </div>
      <div>
        <p>
          <strong>FEE SCHEDULE</strong>
        </p>
      </div>
      <div>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>
                  <strong>Fee Description</strong>
                </th>
                <th>
                  <strong>Fee Amount and Frequency</strong>
                </th>
                <th>
                  <strong>Additional Details</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Insufficient Funds</td>
                <td>$75</td>
                <td>Assessed for recurring failures.</td>
              </tr>
              <tr>
                <td>ACH Return</td>
                <td>$75</td>
                <td>Assessed for recurring failures.</td>
              </tr>
              <tr>
                <td>Stop Payment</td>
                <td>$75</td>
                <td>Assessed for recurring failures.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div>
        <p>
          <strong>Exhibit 2</strong>
        </p>
      </div>
      <div>
        <p>
          <strong>TRANSACTION LIMITS</strong>
        </p>
      </div>
      <div>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>
                  <strong>Withdrawal Method</strong>
                </th>
                <th>
                  <strong>Limitations for New Accounts</strong>
                </th>
                <th>
                  <strong>Limitations for Your Account</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ACH</td>
                <td>Number of concurrent payrolls and total payroll amount</td>
                <td>Number of concurrent payrolls and total payroll amount</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

EvolveTerms.propTypes = {
  info: PropTypes.shape({
    effectiveDate: PropTypes.string,
  }),
};
export default EvolveTerms;
