import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card } from "react-bootstrap";
import { get, isEmpty, size } from "lodash";
import { getPayrollHistory } from "actions/payrollActions";
import { wrapError } from "utils/errorHelper";
import { FiDownload, FiList } from "react-icons/fi";
import { simulateLinkClickNewTab } from "utils/dom";
import { getEmployerDownloadUrl } from "services/documentService";
import { formatCurrency } from "utils/number";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { payrollConstants } from "actions/types";
import {
  payrollStatusToEnglish,
  payrollStatusToType,
} from "statics/statusToEnglishMapping";

import moment from "moment";
import StatusBox from "components/StatusBox";
import IconTable from "../../../../components/IconTable";
import IconEmptyState from "components/IconEmptyState";
import Alert from "components/Alert";
import IconSpinner from "components/IconSpinner";

import "./ProcessGroupsTable.scss";

const PENDING_DOCUMENT_ID = "-1";

class ProcessContributions extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    client: PropTypes.object.isRequired,
    getPayrollHistory: PropTypes.func.isRequired,
    error: PropTypes.object,
    loading: PropTypes.bool,
    groupId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: "",
      payrolls: [],
    };
  }

  componentDidMount() {
    this.props.getPayrollHistory(this.props.client).then((response) => {
      const payrolls = get(response, "data.payrollHistory", []);
      return this.setState({
        payrolls,
      });
    });
  }

  _renderError = () => {
    if (!isEmpty(this.props.error) || !isEmpty(this.state.error)) {
      const message = isEmpty(this.props.error)
        ? this.state.error
        : this.props.error;
      return <Alert type="error" msg={message} />;
    }
  };

  _renderContent = () => {
    const columns = this._buildColumns();
    const numberOfGroups = size(this.state.payrolls);
    const payrollEmpty = isEmpty(this.state.payrolls);

    if (this.props.loading) {
      return <IconSpinner centered />;
    }

    return (
      <Card className="process-contributions">
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        <div className="widget-header">
          <Card.Title>
            Payroll History:
            <p className="invite-number">
              Payrolls (<p className="number">{numberOfGroups}</p>)
            </p>
          </Card.Title>
        </div>
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        {!payrollEmpty && (
          <IconTable columns={columns} data={this.state.payrolls} />
        )}
        {payrollEmpty && (
          <div style={{ borderTop: "1px solid #f2f4f7" }}>
            <IconEmptyState
              header={"No processed payrolls"}
              subheader="Please process a payroll to view payroll history"
              icon={<FiList color="white" stroke="#60A4BF" size={16} />}
            />
          </div>
        )}
      </Card>
    );
  };

  _buildColumns = () => {
    const commonDateFormat = "MMM Do YYYY";

    const columns = [
      {
        label: "Group Name",
        key: "groupName",
      },

      {
        label: "Payroll Period",
        key: "error",
        customComponent: (props) => {
          const start = props.period.displayStart;
          const end = props.period.displayEnd;
          const period = `${moment(start).format(commonDateFormat)} - ${moment(
            end
          ).format(commonDateFormat)}`;
          return period;
        },
      },
      {
        label: "Amount",
        customComponent: (props) => <> {formatCurrency(props.totalAmount)}</>,
      },
      {
        label: "Status",
        customComponent: (props) => (
          <StatusBox
            status={payrollStatusToEnglish[props.status]}
            type={payrollStatusToType[props.status]}
          />
        ),
      },
      {
        label: "Action",

        customComponent: (props) => (
          <>
            {props.payrollDocumentId === PENDING_DOCUMENT_ID ? (
              <StatusBox type="light" status="Processing" />
            ) : (
              <a
                className="link"
                target="_blank"
                onClick={() => this._downloadFile(props.payrollDocumentId)}
              >
                <FiDownload
                  fill={"#FFFFFF"}
                  color={"#FFFFFF"}
                  stroke={"#29b0c2"}
                  size={"20px"}
                />
              </a>
            )}
          </>
        ),
      },
    ];
    return columns;
  };

  _downloadFile(fileId) {
    getEmployerDownloadUrl(this.props.client, { documentId: fileId })
      .then((response) => {
        simulateLinkClickNewTab(response.url);
      })
      .catch((error) => {
        this.setState({ error: wrapError(error) });
      });
  }

  render() {
    return (
      <div>
        <div className="mega-container">
          <section className="page-title-wrap">
            <article className="text-cell">
              <h1 className="page-title">Payroll History</h1>
              {this._renderError()}
            </article>
          </section>
          <div className="main-content">{this._renderContent()}</div>
        </div>
      </div>
    );
  }
}

const actions = [payrollConstants.GET_PAYROLL_HISTORY];

const errorSelector = createErrorSelector(actions);
const loadingSelector = createLoadingSelector(actions);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    loading: loadingSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  getPayrollHistory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ProcessContributions));
