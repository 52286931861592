import React from "react";
import PropTypes from "prop-types";
import { Alert as BsAlert } from "react-bootstrap";

const typeToVariantMapping = {
  error: "danger",
};

const Alert = ({ type, msg, dismissible = false, onClose }) => {
  return (
    <BsAlert
      dismissible={dismissible}
      onClose={onClose}
      variant={typeToVariantMapping[type] || type}
    >
      {msg}
    </BsAlert>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(["warning", "success", "error"]).isRequired,
  msg: PropTypes.string.isRequired,
  dismissible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Alert;
