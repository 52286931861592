import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { connect } from "react-redux";
import {
  filter,
  find,
  get,
  groupBy,
  isEmpty,
  keyBy,
  size,
  some,
  transform,
} from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { BsTrash } from "react-icons/bs";
import { Card } from "react-bootstrap";
import { push } from "connected-react-router";
import { groupType } from "statics/propTypes";
import { setTab } from "actions/navigationActions";
import {
  hasAutoApprovalLinkRequestsFeature,
  hasExpiredInvites,
} from "store/selectors/employer";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { toast } from "react-toastify";
import { payrollPeriodsToEnglishMapping } from "utils/timeHelper";
import {
  groupStatusToEnglish,
  groupStatusType,
} from "statics/statusToEnglishMapping";
import {
  getAllEmployerGroups,
  getLinkedEmployees,
  getPendingLinkedEmployees,
  updateEmployerGroup,
} from "actions/employerActions";
import {
  activeBanksSelector,
  banksSelector,
  isBankVerifiedSelector,
} from "store/selectors/bank";
import {
  FiChevronDown,
  FiEdit3,
  FiGrid,
  FiPlus,
  FiUserPlus,
  FiUsers,
} from "react-icons/fi";

import AddGroup from "pages/dashboards/employerDashboard/dashboard/AddGroup";
import IconEmptyState from "components/IconEmptyState";
import EditGroupForm from "components/EditGroupForm";
import IconTable from "components/IconTable";
import IconSpinner from "components/IconSpinner";
import RemoveGroup from "components/RemoveGroup";
import GroupWithEmployees from "./GroupWithEmployees";
import Button from "components/Button";
import StatusBox from "components/StatusBox";

import "./EmployerGroups.scss";
import ActionsMenu from "components/ActionsMenu";

const EMPLOYER_GROUP_LIMIT = 10;

class EmployerGroups extends React.Component {
  static propTypes = {
    setTab: PropTypes.func,
    getPendingLinkedEmployees: PropTypes.func,
    getAllEmployerGroups: PropTypes.func,
    getLinkedEmployees: PropTypes.func,
    deleteEmployerGroup: PropTypes.func,
    updateEmployerGroup: PropTypes.func,
    client: PropTypes.shape({}),
    push: PropTypes.func,
    loading: PropTypes.bool,
    isDeleting: PropTypes.bool,
    groupUpdating: PropTypes.bool,
    hasExpiredInvites: PropTypes.bool,
    groups: PropTypes.array,
    error: PropTypes.string,
    groupUpdateError: PropTypes.string,
    hasBank: PropTypes.bool,
    accounts: PropTypes.array,
    isFetching: PropTypes.bool,
    planId: PropTypes.string,
    hasGroup: PropTypes.bool,

    bankAccounts: PropTypes.arrayOf(PropTypes.object),
    groupsById: PropTypes.objectOf(groupType),
    groupWithEmployees: PropTypes.shape({}),
    employerLinkRequests: PropTypes.object,
    hasPayrollIntegration: PropTypes.bool,
    linkedEmployees: PropTypes.array,

    flushErrors: PropTypes.func,
    onClose: PropTypes.func,
  };
  _isMounted = false;

  constructor(props) {
    super(props);

    const groupId = queryString.parse(window.location.search).groupId;

    this.state = {
      initialFetching: true,
      showGroupForm: false,
      showEditmodal: false,
      addGroupActive: false,
      showRemoveModal: false,
      showGroupWithEmployees: false,
      groupData: null,
      groupWithEmployees: null,
      groupForEdit: {},
      groupId: groupId || "",
    };
  }

  async componentDidMount() {
    window.analytics.page("Employer Approvals");

    await Promise.all([
      this.props.getPendingLinkedEmployees(this.props.client),
      this.props.getLinkedEmployees(this.props.client),
      this.props.getAllEmployerGroups(this.props.client),
    ]);

    this.setState({ initialFetching: false });
  }

  _submitGroupForEdit = (group) => {
    this.props.updateEmployerGroup(this.props.client, group).then(() => {
      if (!this.props.error) {
        toast.success("Successfully updated group.");
        this.setState({ showEditmodal: false, groupForEdit: {} });
      }
    });
  };

  _actionBtn = () => {
    if (this.props.hasBank) {
      return (
        <Button
          icon={{
            icon: <FiPlus size={14} color={"#fffff"} />,
            position: "left",
          }}
          size="sm"
          name="action"
          onClick={() => this.setState({ showGroupForm: true })}
          btnLabel="Add Group"
        />
      );
    } else {
      return (
        <Button
          icon={{
            icon: <FiPlus size={14} color={"#fffff"} />,
            position: "left",
          }}
          size="sm"
          name="action"
          onClick={() => {
            document.body.click();
            this.props.push("/dashboard");
          }}
          btnLabel="Add Bank"
        />
      );
    }
  };

  _addGroup() {
    const numberOfGroups = size(this.props.groups);
    return (
      <div>
        <div className="widget-header">
          <span>
            <Card.Title>
              Groups:
              <p className="invite-number">
                Active groups (<p className="number">{numberOfGroups}</p>)
              </p>
            </Card.Title>
          </span>
          <div>{this._actionBtn()}</div>
        </div>
      </div>
    );
  }

  _blankSlate = () => {
    let header;

    if (!this.props.hasBank) {
      header = "Please add a bank account before you configure your groups.";
    } else if (this.props.hasBank) {
      header = "Add a group to get started.";
    }
    return (
      <>
        {this._addGroup()}
        <IconEmptyState
          header={header}
          icon={<FiUsers color="white" stroke="#60A4BF" size={16} />}
        />
      </>
    );
  };

  getPopoverActions = (groupIsDeletable, group) => {
    const actions = [
      {
        label: "Edit Group",
        icon: <FiEdit3 size={14} color={"#60A4BF"} />,
        onClick: () => {
          this.setState({
            showEditmodal: true,
            groupForEdit: group,
          });
        },
      },
    ];

    if (!groupIsDeletable) {
      actions.push({
        label: "View Group",
        icon: <FiGrid color="white" stroke="#60A4BF" size={14} />,
        onClick: () => {
          this.setState({
            showGroupWithEmployees: true,
            groupWithEmployees: get(
              this.props.groupWithEmployees,
              group.id,
              []
            ),
          });
        },
      });
    } else {
      actions.push({
        label: "Add Employee",
        icon: <FiUserPlus size={14} color={"#60A4BF"} />,
        onClick: () => {
          this.props.push("/dashboard/users/employees");
        },
      });

      actions.push({
        label: "Remove",
        icon: <BsTrash size={14} color="#B12121" />,
        onClick: () => {
          this.setState({
            showRemoveModal: true,
            groupData: group,
          });
        },
      });
    }

    return actions;
  };

  render() {
    let content;

    if (this.props.isFetching) {
      return <IconSpinner centered />;
    }

    if (isEmpty(this.props.groups)) {
      content = this._blankSlate();
    } else {
      const columns = [
        {
          key: "name",
          label: "Group",
        },
        {
          label: "Plan Id",
          customComponent: (props) => {
            const planId = `${this.props.planId}-${props.companyGroupIndex}`;
            return planId;
          },
        },

        {
          label: "Bank Account",
          customComponent: (props) => {
            const bankAccounts = this.props.bankAccounts;
            const associatedBankAccountId = get(
              props.associatedBankAccount,
              "id",
              null
            );

            const associatedBankAccount = find(bankAccounts, {
              id: associatedBankAccountId,
            });
            const accountId = get(associatedBankAccount, "accountId");
            if (!accountId) return "";

            const planId = `${props.associatedBankAccount.bankName} ${accountId}`;
            return planId;
          },
        },
        {
          key: "payrollFrequency",
          label: "Payroll Frequency",
          customComponent: (props) => (
            <>
              {payrollPeriodsToEnglishMapping[props.payrollFrequency] ||
                props.payrollFrequency}
            </>
          ),
        },

        {
          key: "associatedLinkRequests",
          label: "Participants",
          customComponent: (props) => {
            const linkedEmployees = filter(props.associatedLinkRequests, {
              status: "ACTIVE",
            });
            return <>{size(linkedEmployees)}</>;
          },
        },
        {
          label: "Status",
          customComponent: (props) => {
            const linkRequests = props.associatedLinkRequests;
            const requestAccess = "REQUEST_ACCESS";
            const pendingUsers = some(linkRequests, {
              status: requestAccess,
            });
            const active = !isEmpty(linkRequests) && this.props.hasBank;

            const notActive = isEmpty(linkRequests) && this.props.hasBank;

            const linkBank = !this.props.hasBank;

            let status;

            if (pendingUsers) {
              status = "REQUEST_ACCESS";
            } else if (active) {
              status = "ACTIVE";
            } else if (notActive) {
              status = "NOT_ACTIVE";
            } else if (linkBank) {
              status = "LINK_BANK";
            }
            return (
              <>
                <StatusBox
                  status={groupStatusToEnglish[status]}
                  type={groupStatusType[status]}
                />
              </>
            );
          },
        },

        {
          label: "Actions",
          customComponent: (props) => {
            const groupIsDeletable = isEmpty(props.associatedLinkRequests);
            const groupId = props.id;

            const linkRequests = props.associatedLinkRequests;
            const requestAccess = "REQUEST_ACCESS";
            const pendingUsers = some(linkRequests, {
              status: requestAccess,
            });

            const bankAccountId = props.associatedBankAccount.id;
            const groupHasActiveBankAccount = some(this.props.accounts, {
              id: bankAccountId,
            });

            const updateGroupBank =
              !groupHasActiveBankAccount && this.props.hasBank;

            const showPendingUserReview =
              pendingUsers && groupHasActiveBankAccount;

            const removedAllBankAccounts =
              !this.props.hasBank && this.props.hasGroup;

            if (this.props.isFetching) {
              return <IconSpinner centered />;
            }

            if (showPendingUserReview) {
              return (
                <>
                  <Button
                    className="table-btn"
                    color="action"
                    name="action"
                    size="sm"
                    btnLabel="Review"
                    onClick={() => {
                      this.props.push({
                        pathname: `/dashboard/company/group/${groupId}/review-employees`,
                      });
                    }}
                  />
                </>
              );
            } else if (updateGroupBank) {
              return (
                <>
                  <Button
                    className="table-btn"
                    color="action"
                    name="action"
                    size="sm"
                    btnLabel="Update Group Bank"
                    onClick={() => {
                      this.setState({
                        showEditmodal: true,
                        groupForEdit: props,
                      });
                    }}
                  />
                </>
              );
            } else if (removedAllBankAccounts) {
              return (
                <>
                  <Button
                    className="table-btn"
                    color="action"
                    name="action"
                    size="sm"
                    btnLabel="Link New Bank"
                    onClick={() => {
                      this.props.push({
                        pathname: `/dashboard/company/bank`,
                      });
                    }}
                  />
                </>
              );
            } else {
              return (
                <>
                  <div className="groups-table-actions">
                    <ActionsMenu
                      actions={this.getPopoverActions(groupIsDeletable, props)}
                    >
                      <Button color={"action"}>
                        <div className="group-actions-dropdown">
                          <p className="group-actions-label">Actions</p>
                          <span>
                            <FiChevronDown
                              color="white"
                              stroke={"#1b9aa9"}
                              size={10}
                            />
                          </span>
                        </div>
                      </Button>
                    </ActionsMenu>
                  </div>
                </>
              );
            }
          },
        },
      ];

      content = (
        <>
          {this.props.groups.length < EMPLOYER_GROUP_LIMIT && this._addGroup()}
          <IconTable columns={columns} data={this.props.groups} />
        </>
      );
    }

    if (this.state.showGroupForm) {
      return (
        <AddGroup onClose={() => this.setState({ showGroupForm: false })} />
      );
    } else if (this.state.showEditmodal) {
      return (
        <div className="download-employee-template">
          <div className="main-content">
            <Card>
              <Card.Title>
                <p className="page-header">Edit Group</p>
              </Card.Title>
              <Card.Body>
                <EditGroupForm
                  group={this.state.groupForEdit}
                  onClose={() => {
                    this.setState({ showEditmodal: false, groupForEdit: {} });
                  }}
                  onSubmit={this._submitGroupForEdit}
                  error={this.props.groupUpdateError}
                  accounts={this.props.accounts}
                  isLoading={this.props.groupUpdating}
                />
              </Card.Body>
            </Card>
          </div>
        </div>
      );
    } else if (this.state.showGroupWithEmployees) {
      return (
        <>
          <Card className="groups-table">
            <GroupWithEmployees
              groupWithEmployees={this.state.groupWithEmployees}
              planId={this.props.planId}
              groupId={this.state.groupId}
              hasPayrollIntegration={this.props.hasPayrollIntegration}
              onClose={() => {
                this.setState({
                  showGroupWithEmployees: false,
                  groupWithEmployees: null,
                });
              }}
            />
          </Card>
        </>
      );
    }

    return (
      <>
        {this.state.showRemoveModal && (
          <>
            <RemoveGroup
              groupData={this.state.groupData}
              onClose={() =>
                this.setState({
                  showRemoveModal: false,
                  groupData: null,
                })
              }
              onSuccess={() => {
                this.props.getAllEmployerGroups(this.props.client);
                this.setState({ showRemoveModal: false });
              }}
            />
          </>
        )}

        <div>
          <Card className="groups-table">{content}</Card>
        </div>
      </>
    );
  }
}

const isDeleting = createLoadingSelector(
  employerConstants.DELETE_EMPLOYER_GROUPS
);

const initialLoadingSelector = createLoadingSelector([
  employerConstants.GET_EMPLOYER_ACCOUNTS,
  employerConstants.GET_EMPLOYER_GROUPS,
]);

const groupUpdateActions = [employerConstants.UPDATE_EMPLOYER_GROUPS];
const groupUpdateErrorSelector = createErrorSelector(groupUpdateActions);
const groupUpdatingSelector = createLoadingSelector(groupUpdateActions);
const errorSelector = createErrorSelector(
  employerConstants.DELETE_EMPLOYER_GROUPS
);

const mapStateToProps = (state) => {
  const groups = state.employer.groups;
  const groupsById = keyBy(state.employer.groups, "id");

  let groupWithEmployees = groupBy(
    state.employer.linkedEmployees,
    "groupWithSchedule.group.id"
  );

  groupWithEmployees = transform(state.employer.groups, (acc, group) => {
    acc[group.id] = {
      ...group,
      employees: groupWithEmployees[group.id] || [],
    };

    return acc;
  });
  const hasAutoApprovalFeature = hasAutoApprovalLinkRequestsFeature(state);

  const pendingLinks = hasAutoApprovalFeature
    ? []
    : filter(
        state.employer.employerLinkRequests,
        (request) => request.status === "REQUEST_ACCESS"
      );

  const employerLinkRequests = groupBy(pendingLinks, "group.id");

  return {
    groups,
    isDeleting: isDeleting(state),
    error: errorSelector(state),
    groupUpdateError: groupUpdateErrorSelector(state),
    groupUpdating: groupUpdatingSelector(state),
    hasBank: isBankVerifiedSelector(state),
    accounts: activeBanksSelector(state),
    hasExpiredInvites: hasExpiredInvites(state),
    isFetching: initialLoadingSelector(state),
    bankAccounts: banksSelector(state),
    hasGroup: !isEmpty(state.employer.groups),
    planId: state.employer.company.planId,
    groupsById,
    groupWithEmployees,
    employerLinkRequests,
  };
};

const mapDispatchToProps = {
  push,
  setTab,
  getAllEmployerGroups,
  updateEmployerGroup,
  getLinkedEmployees,
  getPendingLinkedEmployees,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerGroups));
