import { documentConstants } from "./types";
import { getDocuments } from "services/documentService";
import ActionCreator from "utils/actionHandler";

export const getAllDocuments = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(documentConstants.GET_DOCUMENTS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getDocuments(client, accountId)
    );
  };
};

export default {
  getAllDocuments,
};
