import React from "react";
import { Card } from "react-bootstrap";
import SupportEmailLink from "components/SupportEmailLink";

// TODO: add the real messaging once we finalize it
const PendingClosure = () => {
  return (
    <Card className="text-center attention-card">
      <Card.Body>
        <Card.Title>Account pending closure</Card.Title>
        <Card.Text>
          We are working to close your account. If you have any questions please
          feel free to reach out to <SupportEmailLink />
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default PendingClosure;
