import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { push } from "connected-react-router";
import { get, map } from "lodash";

import IconSpinner from "components/IconSpinner";
import EmployerInfo from "./EmployerInfo";
import CrmLink from "./CrmLink";
import Groups from "./Groups";
import RosterUploads from "./RosterUploads";
import Company from "../KYB/Company";
import Controller from "../KYB/Controller";
import Summary from "../KYB/Summary";
import Payrolls from "./Payrolls";
import { getCompany } from "services/adminService";
import {
  EmployerApplicationRequiresAdditionalDocumentation,
  EmployerApplicationSubmittedKYB,
  employerOnboardingStates,
  EmployerPendingKYBSubmission,
  employerYellowPathStates,
} from "statics/states";
import MemberDocs from "./MemberDocs";
import Billing from "./Billing";
import Organization from "pages/dashboards/adminDashboard/employers/Organization";
import EntityLimits from "../EntityLimits";
import { isSuperIconAdmin } from "store/selectors/admin";
import MissedPayrolls from "./MissedPayrolls";

class Employer extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    push: PropTypes.func,
    companyId: PropTypes.string,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
    routeName: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      tab: this._calcActiveTab(props.routeName),
      initialFetching: true,
      companyState: "",
    };
  }

  async componentDidMount() {
    const company = await getCompany(this.props.client, this.props.companyId);
    const {
      currentState,
      user: { email },
    } = company;

    this.setState({
      initialFetching: false,
      companyState: currentState,
      companyAdminEmail: email,
      company,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.routeName !== this.props.routeName) {
      this.setState({ tab: this._calcActiveTab() });
    }
  }

  _onCompanyUpdate = (params = {}) => {
    this.setState({ company: { ...this.state.company, ...params } });
  };

  _getTabs = () => {
    let allTabs = [
      {
        label: "Info",
        id: "info",
      },
      {
        label: "Groups/Banks",
        id: "groups",
      },
      {
        label: "Payrolls",
        id: "payrolls",
      },
      {
        label: "Missed Payrolls",
        id: "missed_payrolls",
      },
      {
        label: "Organization",
        id: "organization",
      },
      {
        label: "Roster Uploads",
        id: "roster",
      },
      {
        label: "Crm",
        id: "crm",
      },
      {
        label: "KYB",
        id: "kyb/summary",
      },
    ];

    if (
      this.state.companyState &&
      !employerOnboardingStates.includes(this.state.companyState)
    ) {
      allTabs.push({
        label: "Billing",
        id: "billing",
      });
    }

    if (this.props.isSuperAdmin) {
      allTabs.push({
        label: "Limits",
        id: "limits",
      });
    }

    if (employerYellowPathStates.includes(this.state.companyState)) {
      allTabs.push({
        label: "Yellowpath",
        id: "documents",
      });
    }

    return allTabs;
  };

  _tabUpdate(value) {
    this.setState({ tab: value.k });
    this.props.push(`/employers/${this.props.companyId}/${value.k}`);
  }

  _controlledTabs() {
    const tabs = this._getTabs();
    return (
      <Tabs
        activeKey={this.state.tab}
        id="tab-company"
        onSelect={(k) => this._tabUpdate({ k })}
      >
        {map(tabs, (tab) => {
          return <Tab key={tab.id} eventKey={tab.id} title={tab.label} />;
        })}
      </Tabs>
    );
  }

  _calcActiveTab = (routeName) => {
    switch (routeName) {
      case `/employers/${this.props.companyId}/info`:
        return "info";
      case `/employers/${this.props.companyId}/kyb/info`:
      case `/employers/${this.props.companyId}/kyb/owners`:
      case `/employers/${this.props.companyId}/kyb/summary`:
        return "kyb/summary";
      case `/employers/${this.props.companyId}/documents`:
        return "documents";
      case `/employers/${this.props.companyId}/groups`:
        return "groups";
      case `/employers/${this.props.companyId}/payrolls`:
        return "payrolls";
      case `/employers/${this.props.companyId}/roster`:
        return "roster";
      case `/employers/${this.props.companyId}/billing`:
        return "billing";
      case `/employers/${this.props.companyId}/organization`:
        return "organization";
      case `/employers/${this.props.companyId}/limits`:
        return "limits";
      case `/employers/${this.props.companyId}/crm`:
        return "crm";
      case `/employers/${this.props.companyId}/missed_payrolls`:
        return "missed_payrolls";

      default:
        return "info";
    }
  };

  _calcKybContent = () => {
    const allowKybEdit =
      employerOnboardingStates.includes(this.state.companyState) &&
      this.state.companyState !== EmployerApplicationSubmittedKYB;

    const allowKybSubmission =
      this.state.companyState === EmployerPendingKYBSubmission ||
      this.state.companyState ===
        EmployerApplicationRequiresAdditionalDocumentation;

    switch (this.props.routeName) {
      case `/employers/${this.props.companyId}/kyb/info`:
        return (
          <Company
            canEdit={allowKybEdit}
            companyId={this.props.companyId}
            onBack={() => {
              this.props.push(`/employers/${this.props.companyId}/kyb/summary`);
            }}
          />
        );
      case `/employers/${this.props.companyId}/kyb/owners`:
        return (
          <Controller canEdit={allowKybEdit} companyId={this.props.companyId} />
        );
      case `/employers/${this.props.companyId}/kyb/summary`:
        return (
          <Summary
            canEdit={allowKybEdit}
            canSubmit={allowKybSubmission}
            companyId={this.props.companyId}
          />
        );
      case `/employers/${this.props.companyId}/limits`:
        return (
          <Summary
            canEdit={allowKybEdit}
            canSubmit={allowKybSubmission}
            companyId={this.props.companyId}
          />
        );
    }
  };

  _getCardContent = () => {
    switch (this.state.tab) {
      case "info":
        return <EmployerInfo companyId={this.props.companyId} />;
      case "kyb/summary":
        return this._calcKybContent();
      case "documents":
        return <MemberDocs companyId={this.props.companyId} />;
      case "groups":
        return (
          <Groups
            companyId={this.props.companyId}
            company={this.state.company}
          />
        );
      case "payrolls":
        return <Payrolls companyId={this.props.companyId} />;
      case "missed_payrolls":
        return <MissedPayrolls companyId={this.props.companyId} />;
      case "roster":
        return <RosterUploads companyId={this.props.companyId} />;
      case "organization":
        return <Organization companyId={this.props.companyId} />;
      case "billing":
        return (
          <Billing
            companyId={this.props.companyId}
            billingType={this.state.company.billingType}
            companyAdminEmail={this.state.companyAdminEmail}
            updateCompany={this._onCompanyUpdate}
          />
        );
      case "limits":
        return (
          <EntityLimits entityType="EMPLOYER" entityId={this.props.companyId} />
        );
      case "crm":
        return <CrmLink companyId={this.props.companyId} />;
    }
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }
    return (
      <div className="mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Employer Info</h1>

            <div className="main-content">
              {this.state.fetchingAdmins && <IconSpinner centered />}
              <Row>
                <Col>
                  <div className="card-tab">{this._controlledTabs()}</div>
                  <Card>
                    <Card.Body>{this._getCardContent()}</Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const companyId = get(ownProps.match, "params.id");
  const routeName = get(state.router, "location.pathname");

  return {
    companyId,
    routeName,
    isSuperAdmin: isSuperIconAdmin(state),
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Employer));
