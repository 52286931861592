import { beneficiaryConstants } from "actions/types";
import { map } from "lodash";
const initialState = {
  beneficiaries: [],
  succesfullyUpdated: false,
  successfullyDeleted: false,
};

function formatBeneficiaries(beneficiaries) {
  return map(beneficiaries, (beneficiary) => {
    // graphql adds __typename to objects and can cause unintended side effects
    const { __typename, ...restOfBeneficiary } = beneficiary; //eslint-disable-line no-unused-vars
    return {
      ...restOfBeneficiary,
      address1: beneficiary.streetAddress[0],
      address2: beneficiary.streetAddress[1],
    };
  });
}

export function beneficiary(state = initialState, action) {
  switch (action.type) {
    case beneficiaryConstants.GET_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        beneficiaries: formatBeneficiaries(action.data),
      };

    case beneficiaryConstants.UPDATE_BENEFICIARIES_REQUEST:
      return { ...state, succesfullyUpdated: false };
    case beneficiaryConstants.UPDATE_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        succesfullyUpdated: true,
        beneficiaries: formatBeneficiaries(action.data),
      };

    case beneficiaryConstants.DELETE_BENEFICIARIES_REQUEST:
      return { ...state, successfullyDeleted: false };

    case beneficiaryConstants.DELETE_BENEFICIARIES_SUCCESS:
      return {
        ...state,
        successfullyDeleted: true,
        beneficiaries: [],
      };

    default:
      return state;
  }
}
