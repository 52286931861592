import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { Card, Row, Col } from "react-bootstrap";
import { push } from "connected-react-router";
import { createErrorSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { FiUsers } from "react-icons/fi";

import Alert from "components/Alert";
import IconEmptyState from "components/IconEmptyState";

import "./Employees.scss";

const MissingEmployerGroup = (props) => {
  return (
    <div className="download-employee-template">
      <div className="main-content">
        <Row>
          <Col>
            <Card>
              <IconEmptyState
                icon={<FiUsers color="white" stroke="#60A4BF" size={16} />}
                header={"Looks like you do not have any groups linked."}
                subheader="You must first add a group before you can add employees."
                actionText={"Add group"}
                onClick={() => {
                  props.push("/dashboard/company/group");
                }}
              />
              {props.error && <Alert type="error" msg={props.error} />}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

MissingEmployerGroup.propTypes = {
  error: PropTypes.string,
  push: PropTypes.func,
};

const actions = [employerConstants.GET_EMPLOYER_GROUPS];
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(MissingEmployerGroup));
