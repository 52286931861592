import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { employeeInvitationType } from "statics/propTypes";
import {
  hasEnabledPayrollIntegration,
  hasStatePlanSelector,
} from "store/selectors/employer";

import EmployeeInvitationsTable from "./EmployeeInvitationsTable";
import AddEmployeeForm from "./AddEmployeeForm";
import IconSpinner from "components/IconSpinner";
import MissingEmployerGroup from "./MissingEmployerGroup";

import "./Employees.scss";

class Employees extends React.Component {
  static propTypes = {
    client: PropTypes.object,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    hasPayrollIntegration: PropTypes.bool,
    employeeInvitations: PropTypes.objectOf(employeeInvitationType),
    groups: PropTypes.arrayOf(PropTypes.shape({})),
    hasStatePlan: PropTypes.bool,
  };

  componentDidMount() {
    window.analytics.page("Employer User Management");
  }

  render() {
    if (this.props.isFetching) {
      return <IconSpinner centered />;
    } else if (isEmpty(this.props.groups)) {
      return <MissingEmployerGroup />;
    } else if (
      isEmpty(this.props.employeeInvitations) &&
      !this.props.hasStatePlan &&
      !this.props.hasPayrollIntegration
    ) {
      return (
        <>
          <AddEmployeeForm />
        </>
      );
    }

    return (
      <div
        className="tab-pane"
        id="account employees-page"
        role="tabpanel"
        aria-labelledby="account_tab"
      >
        <EmployeeInvitationsTable
          hasPayrollIntegration={this.props.hasPayrollIntegration}
        />
      </div>
    );
  }
}

const actions = [
  employerConstants.GET_EMPLOYER_GROUPS,
  employerConstants.GET_EMPLOYEE_INVITES,
];
const isFetchingSelector = createLoadingSelector(actions);

const errorSelector = createErrorSelector(actions);
const mapStateToProps = (state) => {
  return {
    isFetching: isFetchingSelector(state),
    groups: state.employer.groups,
    employeeInvitations: state.employer.employeeInvitations,
    error: errorSelector(state),
    hasStatePlan: hasStatePlanSelector(state),
    hasPayrollIntegration: hasEnabledPayrollIntegration(state),
  };
};

export default connect(mapStateToProps)(withApollo(Employees));
