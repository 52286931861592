import { some, get, isArray, endsWith, map, isEmpty, filter } from "lodash";

// given x actions return if any are in a loading state
export const createLoadingSelector = (actions) => (state) => {
  // allow passing in single instance vs array
  let actionsToIterate = !isArray(actions) ? [actions] : actions;

  // returns true only when all actions is not loading
  return some(actionsToIterate, (action) => get(state, `loading.${action}`));
};

// given x actions return if any are in an errored state
export const createErrorSelector = (actions) => (state) => {
  // allow passing in single instance vs array
  let actionsToIterate = !isArray(actions) ? [actions] : actions;
  const actionsWithErrors = filter(actionsToIterate, (action) =>
    get(state, `error.${action}`)
  );
  if (isEmpty(actionsWithErrors)) {
    return "";
  }

  return map(actionsWithErrors, (action) => {
    let errorMsg = get(state, `error.${action}`);
    errorMsg = endsWith(errorMsg, ".") ? errorMsg : errorMsg + ".";
    return errorMsg;
  }).join(" ");
};
