import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { connect } from "react-redux";
import classnames from "classnames";
import { push } from "connected-react-router";
import { FaBusinessTime } from "react-icons/fa";
import { withApollo } from "@apollo/client/react/hoc";
import { Card } from "react-bootstrap";
import {
  EmployerApplicationAdditionalDocumentationSubmitted,
  EmployerApplicationRequiresAdditionalDocumentation,
  EmployerApplicationSubmittedKYB,
  EmployerBillingVerificationPending,
  EmployerPendingKYBSubmission,
  EmployerRegistrationComplete,
  EmployerSelfServeRegistrationComplete,
  EmployerTermsComplete,
} from "statics/states";

const applicationProcessing = "Application Processing";
const applicationProcessingSubtext =
  "We are processing your application to join Icon.";

const stateToTitleMap = {
  [EmployerTermsComplete]: "Company Verification",
  [EmployerRegistrationComplete]: "Company Verification",
  [EmployerApplicationSubmittedKYB]: applicationProcessing,
  [EmployerPendingKYBSubmission]: applicationProcessing,
  [EmployerSelfServeRegistrationComplete]: applicationProcessing,
  [EmployerApplicationRequiresAdditionalDocumentation]:
    "Need Additional Documentation",
  [EmployerApplicationAdditionalDocumentationSubmitted]:
    "Received Documentation",
  [EmployerBillingVerificationPending]: "Verifying Billing Information",
};

const stateToSubTitleMap = {
  [EmployerTermsComplete]:
    "Thank you. We're setting up your account. You will receive an email from Icon with the next steps.",
  [EmployerRegistrationComplete]:
    "Thank you for signing up to Icon! An Icon representative will be in touch shortly to ensure we verify your business successfully. Look for an email with next steps.",
  [EmployerPendingKYBSubmission]: applicationProcessingSubtext,
  [EmployerSelfServeRegistrationComplete]: applicationProcessingSubtext,
  [EmployerApplicationSubmittedKYB]: applicationProcessingSubtext,
  [EmployerApplicationRequiresAdditionalDocumentation]:
    "We need additional information about your business to continue processing your application. Our Customer Success team will reach out with next steps.",
  [EmployerApplicationAdditionalDocumentationSubmitted]:
    "We received the needed documentation and are processing your application.",
  [EmployerBillingVerificationPending]:
    "We are awaiting verification of your billing details.",
};

class KybCard extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    client: PropTypes.object,
    companyState: PropTypes.string,
  };

  render() {
    return (
      <>
        <div className={classnames("kyb-card")}>
          <Card className="text-center">
            <div className="alert-icon-container">
              <FaBusinessTime size={42} color="#009baa" />
            </div>
            <Card.Body>
              <Card.Title style={{ color: "#0a2540" }}>
                {stateToTitleMap[this.props.companyState] ||
                  applicationProcessing}
              </Card.Title>
              <Card.Text style={{ color: "#858c9c", fontSize: "14px" }}>
                {stateToSubTitleMap[this.props.companyState] ||
                  applicationProcessingSubtext}
              </Card.Text>
              {/* doc uploader can go here  */}
            </Card.Body>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyState: get(state.user.companyState, "state", ""),
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(KybCard));
