import React from "react";
import { PropTypes } from "prop-types";
import cn from "classnames";
import "./Typography.scss";

const variantsMapping = {
  subheading: "subheading",
  centered: "centered",
};

const IconSubheader = ({ variant, children, subheader, ...props }) => {
  const Component = variant ? variantsMapping[variant] : "p";

  return (
    <div>
      <Component
        className={cn("default-variant", {
          [`typography--variant-${variant}`]: variant,
        })}
        {...props}
      >
        {children}
        {subheader}
      </Component>
    </div>
  );
};

IconSubheader.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  subheader: PropTypes.string,
};

export default IconSubheader;
