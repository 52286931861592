import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { Form } from "react-bootstrap";

import { states } from "statics/countriesAndStates";

const StateSelect = ({ value, handleBlur, handleChange, touched, errors }) => {
  return (
    <Form.Control
      as="select"
      name="state"
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      isInvalid={touched.state && !!errors.state}
      isValid={touched.state && !errors.state}
    >
      <option value="" disabled>
        Select State
      </option>
      {map(states, (stateLabel, stateAbbrv) => (
        <option key={stateLabel} value={stateAbbrv}>
          {stateLabel}
        </option>
      ))}
    </Form.Control>
  );
};

StateSelect.propTypes = {
  value: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
};

export default StateSelect;
