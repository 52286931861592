import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { get } from "lodash";
import { FiFile, FiCode } from "react-icons/fi";

import IconHeader from "components/IconHeader";

class AddDeductionCodeInstructions extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    client: PropTypes.object,
    payrollProvider: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const isGusto = this.props.payrollProvider === "gusto";
    const instructions = isGusto
      ? "https://support.iconsavingsplan.com/en/articles/8562334-how-to-setup-payroll-deductions-in-gusto"
      : "https://support.iconsavingsplan.com/en/articles/8562244-how-to-set-up-payroll-deductions";

    return (
      <>
        <div className="employerInfo">
          <div className="employerInfo-body" style={{ padding: 0 }}>
            <div>
              <div className="circle">
                <span className="icon">
                  <FiCode color="white" stroke="#60A4BF" size={16} />
                </span>
              </div>
            </div>
            <div className="employerInfo-intro">
              <IconHeader
                variant="labelHeader"
                headerText="Setup Deduction Code"
              />
            </div>
          </div>
          <div className="employerInfo-action">
            <span
              style={{
                display: "flex",
                color: "#01a3b0",
                cursor: "pointer",
              }}
            >
              <FiFile color="white" stroke="#60A4BF" size={16} />

              <a
                href={instructions}
                className="modal-link"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: "flex",
                  color: "#01a3b0",
                  cursor: "pointer",
                  fontSize: 14,
                  paddingLeft: 5,
                }}
              >
                View Instructions
              </a>
            </span>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const payrollProvider = get(state.employer.company, "payrollProvider");
  return {
    payrollProvider,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddDeductionCodeInstructions));
