import React from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { toast } from "react-toastify";

import SchedulePicker from "pages/signUp/individual/setupContribution/SchedulePicker";
import IconSpinner from "components/IconSpinner";
import ContributionSelector from "components/ContributionSelector";

import { END_OF_MONTH } from "utils/timeHelper";
import { recommendedContributionType } from "statics/propTypes";
import { getIraAccountIdSelector } from "store/selectors/user";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { contributionConstants, accountConstants } from "actions/types";
import { getAllAccounts } from "actions/accountActions";

import {
  getContributionRecommendations,
  createRecurringContribution,
  getContributionAbilityAndEstimates,
} from "actions/contributionActions";

class RecurringContributionModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    createRecurringContribution: PropTypes.func,
    getContributionRecommendations: PropTypes.func,
    getAllAccounts: PropTypes.func,
    getContributionAbilityAndEstimates: PropTypes.func,
    show: PropTypes.bool,
    isFetching: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    recurringContribution: PropTypes.object,
    dailyMax: PropTypes.number,
    weeklyMax: PropTypes.number,
    monthlyMax: PropTypes.number,
    currentContributionAmount: PropTypes.number,
    everyOtherWeekMax: PropTypes.number,
    dailyRecommendations: recommendedContributionType,
    weeklyRecommendations: recommendedContributionType,
    everyOtherWeekRecommendations: recommendedContributionType,
    monthlyRecommendations: recommendedContributionType,
    bankId: PropTypes.string,
    scheduleId: PropTypes.string,
    accountId: PropTypes.string,
    bankAccountNumber: PropTypes.string,
    error: PropTypes.string,
    client: PropTypes.object,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};

    if (
      isEmpty(prevState.recurringContribution) &&
      !isEmpty(nextProps.recurringContribution)
    ) {
      newState.recurringContribution = nextProps.recurringContribution;
    }

    return newState;
  }
  constructor() {
    super();

    this.state = {
      activeStep: 0,
      recurringContribution: {},
      showContributionSelector: false,
    };
  }
  componentDidMount() {
    this.props.getContributionRecommendations(this.props.client, {
      isSelfEmployed: true,
    });
  }

  _submitContribution = async (amount, isDailyCustom) => {
    try {
      await this.props.createRecurringContribution(this.props.client, {
        accountId: this.props.accountId,
        scheduleId: this.props.scheduleId,
        dayOne:
          this.state.recurringContribution.dayOne === END_OF_MONTH
            ? 31
            : +this.state.recurringContribution.dayOne,
        frequency: this.state.recurringContribution.frequency,
        anchorPayDate: this.state.recurringContribution.anchorPayDate,
        bankId: this.props.bankId,
        isDailyCustom,
        amount: +amount,
      });
      if (!this.props.error) {
        this.props.getContributionAbilityAndEstimates(this.props.client);

        this.props.onClose();
        toast.success("Successfully updated contribution");
      }
    } catch (error) {
      return error;
    }
  };

  _getModalHeading = () => {
    const title = this.state.showContributionSelector
      ? "How much do you want to invest to your IRA from your bank account?"
      : "Schedule";
    const subtitle = this.state.showContributionSelector
      ? "This is a long-term investment – you can put a little bit in each time you’re paid so that your money can start working for you. You can always change this any time"
      : "Select a preferred schedule that works best for you  ";

    return (
      <Modal.Header closeButton>
        <h3 className="modal-title">{title}</h3>
        <p className="modal-subtitle">{subtitle}</p>
      </Modal.Header>
    );
  };

  _setSchedule = (schedule) => {
    this.setState({
      recurringContribution: schedule,
      showContributionSelector: true,
    });
  };

  _getModalBody = () => {
    if (this.props.isFetching) {
      return <IconSpinner centered />;
    }

    if (this.state.showContributionSelector) {
      return (
        <div className="contribution-selection">
          <ContributionSelector
            dailyMax={this.props.dailyMax}
            weeklyMax={this.props.weeklyMax}
            monthlyMax={this.props.monthlyMax}
            everyOtherWeekMax={this.props.everyOtherWeekMax}
            dailyRecommendations={this.props.dailyRecommendations}
            weeklyRecommendations={this.props.weeklyRecommendations}
            everyOtherWeekRecommendations={
              this.props.everyOtherWeekRecommendations
            }
            monthlyRecommendations={this.props.monthlyRecommendations}
            error={this.props.error}
            onSubmit={this._submitContribution}
            isSubmitting={this.props.isSubmitting}
            currentContributionAmount={this.props.currentContributionAmount}
            schedule={this.state.recurringContribution}
            bankAccountNumber={this.props.bankAccountNumber}
          />
        </div>
      );
    }

    return (
      <SchedulePicker
        schedule={this.state.recurringContribution}
        setSchedule={this._setSchedule}
      />
    );
  };

  render() {
    return (
      <Modal
        centered
        scrollable
        show={this.props.show}
        onHide={this.props.onClose}
        className="icon-modal"
      >
        {this._getModalHeading()}
        <Modal.Body>{this._getModalBody()}</Modal.Body>
      </Modal>
    );
  }
}

const actions = [
  contributionConstants.ADD_RECURRING_CONTRIBUTION,
  contributionConstants.GET_CONTRIBUTION_RECOMMENDATIONS,
  accountConstants.GET_ACCOUNTS,
];

const errorSelector = createErrorSelector(actions);
const loadingSelector = createLoadingSelector(
  contributionConstants.GET_CONTRIBUTION_RECOMMENDATIONS
);
const isSubmitting = createLoadingSelector(actions);

const mapStateToProps = (state) => {
  const {
    dailyMax,
    weeklyMax,
    monthlyMax,
    everyOtherWeekMax,
    recommendations,
  } = state.contribution;

  return {
    dailyMax,
    weeklyMax,
    monthlyMax,
    everyOtherWeekMax,
    dailyRecommendations: recommendations.daily,
    weeklyRecommendations: recommendations.weekly,
    everyOtherWeekRecommendations: recommendations.everyOtherWeek,
    monthlyRecommendations: recommendations.monthly,
    error: errorSelector(state),
    isFetching: loadingSelector(state),
    isSubmitting: isSubmitting(state),
    accountId: getIraAccountIdSelector(state),
  };
};

const mapDispatchToProps = {
  getContributionRecommendations,
  createRecurringContribution,
  getAllAccounts,

  getContributionAbilityAndEstimates,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(RecurringContributionModal));
