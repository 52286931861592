import { withdrawalConstants } from "./types";
import { makeWithdrawal } from "services/withdrawalService";
import ActionCreator from "utils/actionHandler";

export const makeIraWithdrawal = (
  client,
  { iraAccountId, amount, reason, isFullDisbursement }
) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      withdrawalConstants.WITHDRAWAL_REQUEST
    );
    dispatch(actionCreator.request());

    return makeWithdrawal({
      client,
      iraAccountId,
      amount,
      reason,
      isFullDisbursement,
    }).then(
      (data) => {
        if (data) {
          dispatch(actionCreator.success());
        }
      },
      (error) => {
        console.log("makeIraWithdrawal Error: " + error);
        dispatch(actionCreator.failure(error));
      }
    );
  };
};
