import React from "react";
import { PendingAdminApproval } from "statics/payrollIntegrationStates";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Button from "components/Button";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { approvePayrollIntegration } from "actions/adminActions";

export const PayrollIntegrationStatusField = ({
  companyId,
  status,
  approvePayrollIntegration,
  client,
  isActivating,
  error,
  onSuccess,
}) => {
  if (status !== PendingAdminApproval) return <span>{status}</span>;
  return (
    <>
      {status} -{" "}
      <OverlayTrigger
        rootClose
        trigger="click"
        placement="bottom"
        overlay={
          <Popover className="action-popover">
            <Popover.Content>
              <p>Are you sure you want to activate this integration?</p>
              <div>
                <Button
                  type="button"
                  color="cancel"
                  name="cancel"
                  btnLabel="Cancel"
                  onClick={() => document.body.click()}
                  size="sm"
                />
                <Button
                  type="button"
                  color="primary"
                  name="submit"
                  btnLabel="Activate"
                  withArrow={true}
                  loading={isActivating}
                  onClick={async () => {
                    const { data: state } = await approvePayrollIntegration(
                      client,
                      {
                        companyId,
                      }
                    );
                    if (error) {
                      toast.error(error);
                      document.body.click();
                      return;
                    }

                    toast.success("Integration activated successfully");
                    onSuccess(state);
                    document.body.click();
                  }}
                  size="sm"
                />
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        <span
          style={{
            fontSize: 12,
            color: "#0cb7c4",
          }}
        >
          Activate Integration
        </span>
      </OverlayTrigger>
    </>
  );
};

PayrollIntegrationStatusField.propTypes = {
  companyId: PropTypes.string,
  status: PropTypes.string,
  approvePayrollIntegration: PropTypes.func,
  client: PropTypes.shape({}),
  isActivating: PropTypes.bool,
  error: PropTypes.string,
  onSuccess: PropTypes.func,
};

const loadingSelector = createLoadingSelector(
  adminConstants.APPROVE_PAYROLL_INTEGRATION
);

const errorSelector = createErrorSelector(
  adminConstants.APPROVE_PAYROLL_INTEGRATION
);

const mapStateToProps = (state) => ({
  isActivating: loadingSelector(state),
  error: errorSelector(state),
});

const mapDispatchToProps = {
  approvePayrollIntegration,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(PayrollIntegrationStatusField));
