import React from "react";
import PropTypes from "prop-types";
import IconTable from "components/IconTable";
import IconEmptyState from "components/IconEmptyState";
import Button from "components/Button";

import { Card } from "react-bootstrap";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { capitalize, isEmpty, map, uniqBy, size } from "lodash";
import { FiUserPlus } from "react-icons/fi";
import { FiCheckCircle } from "react-icons/fi";

class GroupsWithEmployees extends React.PureComponent {
  static propTypes = {
    groupWithEmployees: PropTypes.object,
    planId: PropTypes.string,
    hasPayrollIntegration: PropTypes.bool,
    push: PropTypes.func,
    onClose: PropTypes.func,
  };

  _getPlanData = () => {
    const employees = uniqBy(
      this.props.groupWithEmployees.employees,
      "employeeId"
    );
    return map(employees, (employee) => {
      const userProfile = employee.userProfile ? employee.userProfile : {};
      const firstName = userProfile.firstName;
      const lastName = userProfile.lastName;
      const fullName = `${firstName} ${lastName}`;

      return {
        id: employee.id,
        employeeName: fullName,
        lastFourOfSsn: userProfile.lastFourOfSsn,
        email: userProfile.email,
      };
    });
  };

  _buildPlanColumns = () => {
    const columns = [
      {
        label: "Employee Name",
        key: "employeeName",
      },

      {
        label: "Employee Email",
        key: "email",
      },
      {
        label: "Last 4 SSN",
        key: "lastFourOfSsn",
      },
    ];
    return columns;
  };

  _blankSlate = () => {
    return (
      <div style={{ borderTop: "1px solid #f2f4f7" }}>
        <IconEmptyState
          header={"Plan contains 0 users."}
          icon={<FiCheckCircle color="white" stroke="#60A4BF" size={16} />}
        />
      </div>
    );
  };

  render() {
    const columns = this._buildPlanColumns();
    const data = this._getPlanData();
    let content;

    if (isEmpty(data)) {
      content = this._blankSlate();
    } else {
      content = (
        <>
          <IconTable columns={columns} data={data} />
          <div className="close-btn">
            <Button
              btnLabel="Cancel"
              color="cancel"
              name="cancel"
              type="button"
              onClick={this.props.onClose}
            />
          </div>
        </>
      );
    }

    const numberOfEmplyees = size(this._getPlanData());

    const groupDetails = this.props.groupWithEmployees || {};
    const planType = groupDetails.planType;
    const groupName = groupDetails.name;

    return (
      <>
        <div className="plan-table">
          <div className="widget-header">
            <span>
              <Card.Title>
                {groupName}:
                <p className="invite-number">
                  Active Employees (<p className="number">{numberOfEmplyees}</p>
                  )
                </p>
              </Card.Title>
              <p className="detail">
                <span className="badge">
                  <span className="badge-style-plan-type">
                    <p className="type"> {capitalize(planType)}</p>
                  </span>
                </span>
              </p>
            </span>
            <div className="button-row">
              {!this.props.hasPayrollIntegration && (
                <Button
                  icon={{
                    icon: <FiUserPlus size={14} color={"#fffff"} />,
                    position: "left",
                  }}
                  size="sm"
                  name="action"
                  onClick={() => {
                    this.props.push("/dashboard/users/employees");
                  }}
                  btnLabel="Add Employee"
                />
              )}
            </div>
          </div>
        </div>

        <div className="plan-approval-table"> {content}</div>
      </>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(GroupsWithEmployees));
