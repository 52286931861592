import React from "react";
import { get, includes, isNil, pick, size } from "lodash";
import PropTypes from "prop-types";
import { Col, Form } from "react-bootstrap";
import Button from "components/Button";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { capitalCase } from "change-case";
import { FiCalendar } from "react-icons/fi";

import InfoTooltip from "components/InfoTooltip";
import Alert from "components/Alert";
import { groupType } from "statics/propTypes";
import { daysOfMonthOptions, END_OF_MONTH } from "utils/timeHelper";

import "./GroupForm.scss";
import { ScrollToFieldError } from "utils/form";

const defaultVals = [
  "name",
  "payrollFrequency",
  "associatedBankAccount",
  "anchorPayDate",
  "payrollProcessingOffset",
  "planType",
  "useUniqueInviteCodes",
];

const twicePerMonthVals = [...defaultVals, "dayOne", "dayTwo"];
const monthlyVals = [...defaultVals, "dayOne"];
const frequencyToNeededValMap = {
  WEEKLY: defaultVals,
  EVERY_OTHER_WEEK: defaultVals,
  MONTHLY: monthlyVals,
  TWICE_PER_MONTH: twicePerMonthVals,
};
const payrollProcessingOffset = [
  {
    value: 0,
    description: "Zero, Debit on the payroll date",
    key: "0",
  },
  {
    value: 1,
    description: "1 Day",
    key: "1",
  },
  {
    value: 2,
    description: "2 Days",
    key: "2",
  },
  {
    value: 3,
    description: "3 Days",
    key: "3",
  },
  {
    value: 4,
    description: "4 Days",
    key: "4",
  },
];

const yup = require("yup");

const TWICE_PER_MONTH = "TWICE_PER_MONTH";
const EVERY_OTHER_WEEK = "EVERY_OTHER_WEEK";
const WEEKLY = "WEEKLY";
const MONTHLY = "MONTHLY";

function showDayOne(frequency) {
  return includes([MONTHLY, TWICE_PER_MONTH], frequency);
}

function showDayTwo(frequency) {
  return frequency === TWICE_PER_MONTH;
}

function requireDisplayAnchorPayDate(frequency) {
  return includes([EVERY_OTHER_WEEK, WEEKLY], frequency);
}

export const schema = yup.object({
  name: yup
    .string()
    .label("Group Name")
    .required()
    .min(2, "Must be at least two characters.")
    .max(100),
  payrollFrequency: yup.string().label("Payroll Frequency").required(),
  dayOne: yup.string().nullable().label("First Payday"),
  dayTwo: yup.string().nullable().label("Second Payday"),
  displayDayOne: yup
    .string()
    .nullable()
    .label("Alternate First Payday")
    .when(["useDisplayDates", "payrollFrequency"], {
      is: (useDisplayDates, payrollFrequency) =>
        useDisplayDates && showDayOne(payrollFrequency),
      then: yup.string().label("Alternate First Payday").required(),
    }),
  displayDayTwo: yup
    .string()
    .nullable()
    .label("Alternate Second Payday")
    .when(["useDisplayDates", "payrollFrequency"], {
      is: (useDisplayDates, payrollFrequency) =>
        useDisplayDates && showDayTwo(payrollFrequency),
      then: yup.string().label("Alternate Second Payday").required(),
    }),
  associatedBankAccount: yup
    .string()
    .label("Associated Bank Account")
    .required(),
  anchorPayDate: yup.date().label("First Pay Date").required(),
  payrollProcessingOffset: yup.number().label("Processing Offset").required(),
  planType: yup.string().label("Plan Type").required(),
  useUniqueInviteCodes: yup.boolean(),
  useDisplayDates: yup.boolean(),
  displayAnchorPayDate: yup
    .date()
    .when(["useDisplayDates", "payrollFrequency"], {
      is: (useDisplayDates, payrollFrequency) =>
        useDisplayDates && requireDisplayAnchorPayDate(payrollFrequency),
      then: yup.date().label("End Date").required(),
    }),
});

const planTypeMapper = {
  statePlan: "STATE",
  primaryPlan: "PRIMARY",
  companionPlan: "COMPANION",
};

const parseDate = (val) => {
  if (val) {
    return Date.parse(val);
  }
  return val;
};

export default class GroupForm extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    error: PropTypes.string,
    accounts: PropTypes.array,
    isLoading: PropTypes.bool,
    group: groupType,
    planTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    hasExpiredInvites: PropTypes.bool,
    enabledDaysBeforeDebit: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const offset = this.props.enabledDaysBeforeDebit ? undefined : 0;
    const uniqueCodes = this.props.hasExpiredInvites ? undefined : false;

    // We need to have this plan type here so it will submit both values correctly.
    const planTypeSize = size(this.props.planTypes) > 1;
    const planName = get(this.props.planTypes, "[0].name");
    const type = planTypeSize ? undefined : planTypeMapper[planName];
    const defaultBankId = get(props.accounts, "0.id", undefined);

    this.state = {
      id: "",
      name: undefined,
      payrollFrequency: undefined,
      planType: type,
      associatedBankAccount: defaultBankId,
      dayOne: 15,
      dayTwo: END_OF_MONTH,
      anchorPayDate: undefined,
      payrollProcessingOffset: offset,
      displayAnchorPayDate: undefined,
      displayDayOne: "",
      displayDayTwo: "",
      useUniqueInviteCodes: uniqueCodes,
    };
  }

  submitGroup = (values) => {
    const groupVals = this.getGroupValues(values);
    let group = {
      ...groupVals,
      anchorPayDate: moment(groupVals.anchorPayDate).format("YYYY-MM-DD"),
    };
    if (values.useDisplayDates) {
      group = this.addDisplayValues(group, values);
    }

    this.props.onSubmit(group);
  };

  getGroupValues = (values) => {
    const groupVals = pick(
      values,
      frequencyToNeededValMap[values.payrollFrequency]
    );
    if (values.payrollFrequency === TWICE_PER_MONTH) {
      return {
        ...groupVals,
        dayOne: this.parseDayString(values.dayOne),
        dayTwo: this.parseDayString(values.dayTwo),
      };
    } else if (values.payrollFrequency === MONTHLY) {
      return {
        ...groupVals,
        dayOne: this.parseDayString(values.dayOne),
      };
    }
    return groupVals;
  };

  addDisplayValues = (groupVals, values) => {
    if (values.payrollFrequency === TWICE_PER_MONTH) {
      return {
        ...groupVals,
        displayDayOne: this.parseDayString(values.displayDayOne),
        displayDayTwo: this.parseDayString(values.displayDayTwo),
      };
    } else if (values.payrollFrequency === MONTHLY) {
      return {
        ...groupVals,
        displayDayOne: this.parseDayString(values.displayDayOne),
      };
    }
    return {
      ...groupVals,
      displayAnchorPayDate: moment(values.displayAnchorPayDate).format(
        "YYYY-MM-DD"
      ),
    };
  };

  parseDayString = (day) => {
    return day === END_OF_MONTH ? 31 : parseInt(day);
  };

  render() {
    const planTypeSize = size(this.props.planTypes) > 1;

    const accounts = this.props.accounts.map((account, index) => {
      const label = !account.bankAlias
        ? account.bankName
        : `${account.bankName} - ${account.bankAlias} ${account.accountId} `;
      return (
        <option value={account.id} key={index}>
          {label}
        </option>
      );
    });

    return (
      <Formik
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={(values) => {
          this.submitGroup(values);
        }}
        enableReinitialize={true}
        initialValues={{
          ...this.state,
          useDisplayDates: false,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="group-creation">
            <ScrollToFieldError />
            <Form.Row>
              <Form.Group as={Col} controlId="formGroupName">
                <Form.Label>
                  What name do you want to give the group?
                </Form.Label>
                <Form.Control
                  name="name"
                  placeholder="Group Name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.name && !!errors.name}
                  isValid={touched.name && !errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGroupBank">
                <Form.Label>
                  Which bank account will this group use to process payroll?
                </Form.Label>
                <Form.Control
                  as="select"
                  name="associatedBankAccount"
                  value={values.associatedBankAccount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.associatedBankAccount &&
                    !!errors.associatedBankAccount
                  }
                  isValid={
                    touched.associatedBankAccount &&
                    !errors.associatedBankAccount
                  }
                >
                  <option value="" disabled>
                    Associated Bank Account
                  </option>
                  {accounts}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.associatedBankAccount}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formPlanType">
                <Form.Label>What is your plan type?</Form.Label>
                {planTypeSize && (
                  <Form.Control
                    className="planTypes"
                    as="select"
                    name="planType"
                    value={values.planType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.planType && !!errors.planType}
                    isValid={touched.planType && !errors.planType}
                  >
                    <option value="">Select Plan Type</option>

                    {this.props.planTypes.map((item) => (
                      <option value={planTypeMapper[item.name]} key={item.name}>
                        {capitalCase(planTypeMapper[item.name])}
                      </option>
                    ))}
                  </Form.Control>
                )}
                {!planTypeSize && (
                  <>
                    {this.props.planTypes.map((item) => (
                      <div
                        className="group-select"
                        value={planTypeMapper[item.name]}
                        key={item.name}
                      >
                        <Form.Control
                          name="planType"
                          value={capitalCase(planTypeMapper[item.name])}
                          onChange={handleChange}
                          isInvalid={touched.planType && !!errors.planType}
                          isValid={touched.planType && !errors.planType}
                          placeholder={capitalCase(planTypeMapper[item.name])}
                          disabled
                        />
                      </div>
                    ))}
                  </>
                )}

                <Form.Control.Feedback type="invalid">
                  {errors.planType}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGroupFrequency">
                <Form.Label>How often do you run payroll?</Form.Label>

                <Form.Control
                  as="select"
                  name="payrollFrequency"
                  value={values.payrollFrequency}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.payrollFrequency && !!errors.payrollFrequency
                  }
                  isValid={touched.payrollFrequency && !errors.payrollFrequency}
                >
                  <option value="">Select Frequency</option>
                  <option value={WEEKLY}>Every Week</option>
                  <option value={EVERY_OTHER_WEEK}>Every Two Weeks</option>
                  <option value={TWICE_PER_MONTH}>Twice a Month</option>
                  <option value={MONTHLY}>Every Month</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.payrollFrequency}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            {showDayOne(values.payrollFrequency) && (
              <Form.Row>
                <Form.Group as={Col} md={6} controlId="formGroupDayone">
                  <Form.Label>
                    {values.payrollFrequency === MONTHLY
                      ? "What day of the month is the payday?"
                      : "What day of the month is the first payday?"}
                  </Form.Label>
                  <Form.Control
                    sm={6}
                    as="select"
                    name="dayOne"
                    value={values.dayOne}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.dayOne && !!errors.dayOne}
                    isValid={touched.dayOne && !errors.dayOne}
                  >
                    <option value="" disabled>
                      Day of Month
                    </option>
                    {daysOfMonthOptions.map((item) => (
                      <option value={item.value} key={item.key}>
                        {item.value}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            )}
            {showDayTwo(values.payrollFrequency) && (
              <Form.Row>
                <Form.Group as={Col} md={6} controlId="formGroupDayTwo">
                  <Form.Label>
                    What day of the month is the second payday?
                  </Form.Label>
                  <Form.Control
                    sm={6}
                    as="select"
                    name="dayTwo"
                    value={values.dayTwo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.dayTwo && !!errors.dayTwo}
                    isValid={touched.dayTwo && !errors.dayTwo}
                  >
                    <option value="" disabled>
                      Day of Month
                    </option>
                    {daysOfMonthOptions.map((item) => (
                      <option value={item.value} key={item.key}>
                        {item.value}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            )}
            <Form.Row>
              <Form.Group as={Col} controlId="formBasicDateOfBirth">
                <Form.Label>When is your next pay day?</Form.Label>
                <div>
                  <p className="start-date">Start Date:</p>
                </div>
                <div className="date-picker">
                  <span className="calendar">
                    <FiCalendar
                      size={"18px"}
                      color={"#FFFFFF"}
                      fill={"#FFFFFF"}
                      stroke={"#AAB1BF"}
                      strokeWidth={"2"}
                    />
                  </span>
                  <DatePicker
                    className="form-control"
                    name="anchorPayDate"
                    value={parseDate(values.anchorPayDate)}
                    dateFormat="MM/dd/yyyy"
                    selected={
                      (values.anchorPayDate &&
                        new Date(values.anchorPayDate)) ||
                      null
                    }
                    onChange={(val) => {
                      setFieldValue("anchorPayDate", new Date(val));
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.anchorPayDate && !!errors.anchorPayDate}
                    isValid={touched.anchorPayDate && !errors.anchorPayDate}
                  />
                </div>
                {errors.anchorPayDate && touched.anchorPayDate && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {errors.anchorPayDate}
                  </div>
                )}
              </Form.Group>
            </Form.Row>
            {this.props.enabledDaysBeforeDebit && (
              <Form.Row>
                <Form.Group as={Col} controlId="formGroupProcessingOffset">
                  <Form.Label>
                    How many days prior to the payroll date should approved
                    contributions be debited from the linked bank account?
                  </Form.Label>
                  <Form.Control
                    sm={6}
                    as="select"
                    name="payrollProcessingOffset"
                    value={values.payrollProcessingOffset}
                    onChange={(e) =>
                      setFieldValue("payrollProcessingOffset", +e.target.value)
                    }
                    onBlur={handleBlur}
                    isInvalid={
                      touched.payrollProcessingOffset &&
                      !!errors.payrollProcessingOffset
                    }
                    isValid={
                      touched.payrollProcessingOffset &&
                      !errors.payrollProcessingOffset
                    }
                  >
                    <option value="">Select Number of Days</option>
                    {payrollProcessingOffset.map((item) => (
                      <option value={item.value} key={item.key}>
                        {item.description}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.payrollProcessingOffset}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
            )}
            <Form.Row>
              <Form.Group as={Col} sm={12} controlId="useDisplayDates">
                <Form.Row className="align-items-center">
                  <Form.Check
                    className="useDisplayDates"
                    type="checkbox"
                    name="useDisplayDates"
                    checked={values.useDisplayDates}
                    value={values.useDisplayDates}
                    label="Pay period ends before payday"
                    onChange={(e) => {
                      setFieldValue("useDisplayDates", e.target.checked);
                    }}
                  />
                  <InfoTooltip
                    tooltipBody="If checked, you can configure the pay period end date."
                    placement="right"
                  />
                </Form.Row>
              </Form.Group>
            </Form.Row>
            {values.useDisplayDates && showDayOne(values.payrollFrequency) && (
              <Form.Row>
                <Form.Group as={Col} md={6} controlId="formGroupDisplayDayOne">
                  <Form.Label>
                    {values.payrollFrequency === MONTHLY
                      ? "What day of the month is the end of the pay period?"
                      : "What day of the month is the first end of the pay period?"}
                  </Form.Label>
                  <Form.Control
                    sm={6}
                    as="select"
                    name="displayDayOne"
                    value={values.displayDayOne}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.displayDayOne && !!errors.displayDayOne}
                    isValid={touched.displayDayOne && !errors.displayDayOne}
                  >
                    <option value="" disabled>
                      Day of Month
                    </option>
                    {daysOfMonthOptions.map((item) => (
                      <option value={item.value} key={item.key}>
                        {item.value}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.displayDayOne}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
            )}
            {values.useDisplayDates && showDayTwo(values.payrollFrequency) && (
              <Form.Row>
                <Form.Group as={Col} md={6} controlId="formGroupDayTwo">
                  <Form.Label>
                    What day of the month is the second end of the pay period?
                  </Form.Label>
                  <Form.Control
                    sm={6}
                    as="select"
                    name="displayDayTwo"
                    value={values.displayDayTwo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.displayDayTwo && !!errors.displayDayTwo}
                    isValid={touched.displayDayTwo && !errors.displayDayTwo}
                  >
                    <option value="" disabled>
                      Day of Month
                    </option>
                    {daysOfMonthOptions.map((item) => (
                      <option value={item.value} key={item.key}>
                        {item.value}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.displayDayTwo}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
            )}
            {values.useDisplayDates &&
              !showDayTwo(values.payrollFrequency) &&
              !showDayOne(values.payrollFrequency) && (
                <Form.Row>
                  <Form.Group as={Col} controlId="displayAnchorPayDate">
                    <Form.Label>
                      What is the last day of your current pay period?
                    </Form.Label>

                    <div>
                      <div>
                        <p className="start-date">End Date:</p>
                      </div>
                      <div className="date-picker">
                        <span className="calendar">
                          <FiCalendar
                            size={"18px"}
                            color={"#FFFFFF"}
                            fill={"#FFFFFF"}
                            stroke={"#AAB1BF"}
                            strokeWidth={"2"}
                          />
                        </span>
                        <DatePicker
                          className="form-control"
                          name="displayAnchorPayDate"
                          value={
                            isNil(values.displayAnchorPayDate)
                              ? parseDate(values.anchorPayDate)
                              : parseDate(values.displayAnchorPayDate)
                          }
                          dateFormat="MM/dd/yyyy"
                          selected={
                            (values.displayAnchorPayDate &&
                              new Date(values.displayAnchorPayDate)) ||
                            null
                          }
                          onChange={(val) => {
                            setFieldValue(
                              "displayAnchorPayDate",
                              new Date(val)
                            );
                          }}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.displayAnchorPayDate &&
                            !!errors.displayAnchorPayDate
                          }
                          isValid={
                            touched.displayAnchorPayDate &&
                            !errors.displayAnchorPayDate
                          }
                          maxDate={
                            isNil(values.anchorPayDate)
                              ? values.anchorPayDate
                              : parseDate(values.anchorPayDate)
                          }
                        />
                      </div>
                    </div>
                    {errors.displayAnchorPayDate &&
                      touched.displayAnchorPayDate && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.displayAnchorPayDate}
                        </div>
                      )}
                  </Form.Group>
                </Form.Row>
              )}
            {this.props.hasExpiredInvites && (
              <Form.Row>
                <Form.Group as={Col} sm={12} controlId="formBasicUniqueCode">
                  <Form.Row className="align-items-center">
                    <Form.Check
                      className="useUniqueInviteCodes"
                      type="checkbox"
                      name="useUniqueInviteCodes"
                      checked={values.useUniqueInviteCodes}
                      value={values.useUniqueInviteCodes}
                      label="Employee invites should expire"
                      onChange={(e) => {
                        setFieldValue("useUniqueInviteCodes", e.target.checked);
                      }}
                    />
                    <InfoTooltip
                      tooltipBody="If checked, we will generate a unique invite code for each employee that will expire after 60 days."
                      placement="right"
                    />
                  </Form.Row>
                </Form.Group>
              </Form.Row>
            )}
            {this.props.error && <Alert type="error" msg={this.props.error} />}
            <div className="submit-row">
              <span className="cancel-btn">
                <Button
                  btnLabel="Cancel"
                  name="cancel"
                  color="cancel"
                  type="button"
                  onClick={this.props.onClose}
                />
              </span>
              <Button
                btnLabel="Add Group"
                name="submit"
                withArrow={true}
                loading={this.props.isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
