import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card } from "react-bootstrap";
import { push } from "connected-react-router";
import { FiUsers } from "react-icons/fi";
import { isEmpty } from "lodash";
import { getOriginations } from "actions/adminActions";
import { createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { Link } from "react-router-dom";

import moment from "moment";
import IconSpinner from "components/IconSpinner";
import IconTable from "components/IconTable";
import IconTableHeader from "components/IconTableHeader";
import IconEmptyState from "components/IconEmptyState";
import Paginator from "components/Paginator";

class Originations extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    originations: PropTypes.arrayOf(PropTypes.shape({})),
    getOriginations: PropTypes.func,
    client: PropTypes.object,
    isFetching: PropTypes.bool,
    originationsCount: PropTypes.number,
  };

  constructor() {
    super();

    this.state = {
      limit: 25,
      offset: 0,
      initialFetching: true,
      page: 0,
    };
  }

  componentDidMount() {
    this._getPaginatedOriginations().then(() => {
      this.setState({ initialFetching: false });
    });
  }

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () =>
      this._getPaginatedOriginations()
    );
  };

  _getPaginatedOriginations = () => {
    return this.props.getOriginations(this.props.client, {
      limit: this.state.limit,
      offset: this.state.offset,
    });
  };

  _getOriginationsData = () => {
    return this.props.originations.map((origination) => {
      const userInfo = origination.userInfo;
      const fullName = `${userInfo.firstName} ${userInfo.lastName}`;
      const commonDateFormat = "MMM Do YYYY";

      return {
        id: userInfo.id,
        fullName,
        email: userInfo.email,
        ssn: userInfo.lastFourOfSsn,
        apexAccountId: origination.apexAccountId,
        apexAccountStatus: origination.apexAccountStatus,
        updatedAt: moment(origination.updatedAt).format(commonDateFormat),
        iconAccountId: origination.iconAccountId,
      };
    });
  };

  _buildOriginationsColumns = () => {
    const columns = [
      {
        label: "Name",
        key: "fullName",
      },

      {
        label: "Email",
        key: "email",
      },
      {
        label: "Last 4 SSN",
        key: "ssn",
      },
      {
        label: "Apex #",
        key: "apexAccountId",
      },
      {
        label: "Status",
        key: "apexAccountStatus",
      },
      {
        label: "Updated Date",
        key: "updatedAt",
      },
      {
        customComponent: (props) => {
          return (
            <div style={{ paddingRight: 15 }}>
              <Link
                to={`/originations/${props.iconAccountId}`}
                className="icon-link"
              >
                View Info
              </Link>
            </div>
          );
        },
      },
    ];
    return columns;
  };

  _clearFilters = () => {
    this._textInput.value = "";
    this.setState(
      {
        search: "",
        stateFilter: "",
        offset: 0,
      },
      () => this._getPaginatedOriginations()
    );
  };

  render() {
    const columns = this._buildOriginationsColumns();
    const data = this._getOriginationsData();

    if (this.state.initialFetching) {
      return <IconSpinner />;
    }

    const showBlankSlate = isEmpty(this.props.originations);
    return (
      <div className="mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Originations</h1>

            <div className="main-content">
              <Card>
                {this.props.isFetching && <IconSpinner centered />}

                {showBlankSlate && (
                  <div style={{ marginTop: 12 }}>
                    <IconEmptyState
                      header={
                        "There were no originations found matching your search or filters"
                      }
                      subheader="Would you like to clear your search?"
                      icon={
                        <FiUsers color="white" stroke="#60A4BF" size={16} />
                      }
                      actionText={"Clear Search"}
                      onClick={this._clearFilters}
                    />
                  </div>
                )}

                {!showBlankSlate && (
                  <>
                    <IconTableHeader
                      tableHeader="Originations"
                      tableCount={this.props.originationsCount}
                    />
                    <IconTable columns={columns} data={data} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: 15,
                      }}
                    >
                      <Paginator
                        onChange={this._onPageChange}
                        pageCount={
                          this.props.originationsCount / this.state.limit
                        }
                        page={this.state.page}
                      />
                    </div>
                  </>
                )}
              </Card>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const isLoadingSelector = createLoadingSelector(
  adminConstants.GET_ORIGINATIONS
);

const mapStateToProps = (state) => {
  return {
    isFetching: isLoadingSelector(state),
    originations: state.admin.originations,
    originationsCount: state.admin.originationsCount,
  };
};

const mapDispatchToProps = {
  push,
  getOriginations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Originations));
