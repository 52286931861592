import React from "react";
import PropTypes from "prop-types";
import { isEmpty, keyBy } from "lodash";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { Card, Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { FiFolder } from "react-icons/fi";
import { push } from "connected-react-router";

import Button from "components/Button";
import IconSpinner from "components/IconSpinner";
import IconTable from "components/IconTable";
import IconTableHeader from "components/IconTableHeader";
import Paginator from "components/Paginator";
import IconEmptyState from "components/IconEmptyState";
import {
  deleteSalesQuote,
  getPaginatedSalesQuotes,
  getPricingData,
} from "actions/adminActions";
import { formatCurrency } from "utils/number";
import moment from "moment";
import { copyTextToClipboard } from "utils/dom";
import {
  isProduction,
  productionEmployerOrigin,
  stagingEmployerOrigin,
} from "utils/determineSite";
import { BsTrash } from "react-icons/bs";
import { toast } from "react-toastify";

class SalesQuotes extends React.PureComponent {
  static propTypes = {
    getPaginatedSalesQuotes: PropTypes.func,
    getPricingData: PropTypes.func,
    push: PropTypes.func,
    isFetching: PropTypes.bool,
    isDeleting: PropTypes.bool,
    deleteSalesQuote: PropTypes.func,
    client: PropTypes.object,
    salesQuotes: PropTypes.array,
    totalRows: PropTypes.number,
    error: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      limit: 50,
      offset: 0,
      initialFetching: true,
      page: 0,
    };
  }

  async componentDidMount() {
    await this._getPaginatedSalesQuotes();
    const {
      data: { setupFees, subscriptions },
    } = await this.props.getPricingData(this.props.client);

    this.setState({
      setupFeesById: keyBy(setupFees, "id"),
      subscriptionsById: keyBy(subscriptions, "id"),
      initialFetching: false,
    });
  }

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () =>
      this._getPaginatedSalesQuotes()
    );
  };

  _getPaginatedSalesQuotes = () => {
    return this.props.getPaginatedSalesQuotes(this.props.client, {
      limit: this.state.limit,
      offset: this.state.offset,
    });
  };

  salesQuoteColumns = () => {
    const columns = [
      {
        label: "Company Name",
        key: "companyName",
      },
      {
        label: "Company Size",
        key: "companySize",
      },
      {
        label: "Company Contact",
        key: "companyContact",
      },
      {
        label: "Quote Code",
        key: "quoteCode",
      },
      {
        label: "Annual Subscription Fee",
        key: "metadata.subscriptionId",
        customComponent: (props) => {
          const subscription =
            this.state.subscriptionsById[props.metadata.subscriptionId];

          const price = subscription
            ? formatCurrency(subscription.unitAmount)
            : "N/A";
          return <span>{price}</span>;
        },
      },
      {
        label: "One-Time Setup Fee",
        key: "metadata.setupFeeId",
        customComponent: (props) => {
          const setupFee = this.state.setupFeesById[props.metadata.setupFeeId];
          const price = setupFee ? formatCurrency(setupFee.unitAmount) : "N/A";
          return <span>{price}</span>;
        },
      },
      {
        label: "Discount Percentage",
        key: "metadata.discountPercent",
      },
      {
        label: "Discount Length",
        key: "metadata.discountLength",
      },
      {
        label: "Expiration Date",
        key: "expiresAt",
        customComponent: (props) => {
          return <span>{moment(props.expiresAt).format("YYYY-MM-DD")}</span>;
        },
      },
      {
        label: "Redeemed",
        key: "redeemed",
        customComponent: (props) => {
          return <span>{props.redeemed ? "Yes" : "No"}</span>;
        },
      },
      {
        label: "Sign-Up Link",
        customComponent: (props) => {
          const { quoteCode } = props;
          let signupUrl = isProduction()
            ? productionEmployerOrigin
            : stagingEmployerOrigin;

          signupUrl = signupUrl + "?planCode=" + quoteCode;
          return (
            <span>
              <a
                className="icon-link"
                onClick={() => copyTextToClipboard(signupUrl)}
              >
                Copy Link
              </a>
            </span>
          );
        },
      },
      {
        customComponent: (props) => {
          return (
            <span className="action-icons">
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="bottom"
                overlay={
                  <Popover className="action-popover">
                    <Popover.Content>
                      {!props.groupHasApprovedPayrolls &&
                        !props.groupHasProcessingPayrolls && (
                          <p>Are you sure you want to delete this quote?</p>
                        )}
                      <div>
                        <Button
                          type="button"
                          action="cancel"
                          color="cancel"
                          btnLabel="Cancel"
                          onClick={() => document.body.click()}
                          size="sm"
                        />
                        <Button
                          type="button"
                          name="submit"
                          color="red"
                          btnLabel="Remove"
                          withArrow={true}
                          loading={this.props.isDeleting}
                          onClick={() => this.removeSalesQuote(props.id)}
                          size="sm"
                        />
                      </div>
                    </Popover.Content>
                  </Popover>
                }
              >
                <span className="action-icon">
                  <BsTrash size={18} color="#B12121" />
                </span>
              </OverlayTrigger>
            </span>
          );
        },
      },
    ];
    return columns;
  };

  async removeSalesQuote(quoteId) {
    await this.props.deleteSalesQuote(this.props.client, quoteId);

    if (!this.props.error) {
      toast.success("Sales quote removed.");
    } else {
      toast.error(
        `Something went wrong deleting sales quote: ${this.props.error}`
      );
    }
  }

  _getBlankSlate = () => {
    return (
      <Row>
        <Col>
          <IconEmptyState
            header="No Quotes"
            subheader="It looks like we have not generated any quotes yet"
            icon={<FiFolder color="white" stroke="#60A4BF" size={16} />}
          />
        </Col>
      </Row>
    );
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    const columns = this.salesQuoteColumns();
    const data = this.props.salesQuotes;
    const noQuotes = isEmpty(data);

    return (
      <div id="sales-quotes">
        <h2 style={{ color: "#0a2540" }}>Sales Quotes</h2>
        <Card>
          <Row>
            <Col>
              <IconTableHeader
                tableHeader={"Sales Quotes"}
                tableCountLabel="Quotes"
                tableCount={this.props.totalRows}
              />
              <div
                style={{
                  float: "right",
                  margin: 8,
                }}
              >
                <Button
                  size="sm"
                  name="action"
                  onClick={() => this.props.push("/dashboard/sales_quotes/new")}
                  btnLabel="New Quote"
                />
              </div>
              {!noQuotes && (
                <>
                  <IconTable columns={columns} data={data} />
                  <Paginator
                    onChange={this._onPageChange}
                    pageCount={this.props.totalRows / this.state.limit}
                    page={this.state.page}
                  />
                </>
              )}
              {noQuotes && this._getBlankSlate()}
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const isFetching = createLoadingSelector(adminConstants.GET_SALES_QUOTES);
const errorSelector = createErrorSelector(adminConstants.DELETE_SALES_QUOTE);
const isDeleting = createLoadingSelector(adminConstants.DELETE_SALES_QUOTE);

const mapStateToProps = (state) => {
  const { results, totalRows } = state.admin.salesQuotes;

  return {
    salesQuotes: results,
    totalRows,
    error: errorSelector(state),
    isDeleting: isDeleting(state),
    isFetching: isFetching(state),
  };
};

const mapDispatchToProps = {
  getPaginatedSalesQuotes,
  push,
  deleteSalesQuote,
  getPricingData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(SalesQuotes));
