import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { get, isEmpty } from "lodash";
import { FiRepeat } from "react-icons/fi";
import { getBillingDetails } from "actions/adminActions";
import { adminConstants } from "actions/types";
import { createErrorSelector } from "store/selectors";

import IconEmptyState from "components/IconEmptyState";
import IconSpinner from "components/IconSpinner";
import BillingForm from "./BillingForm";
import Alert from "components/Alert";
import Button from "components/Button";
import { ISOLVED_WHOLESALE, LEGACY_NOBILL } from "statics/billingTypes";
import ChangeBillingTypeForm from "pages/dashboards/adminDashboard/employers/ChangeBillingTypeForm";
import UpdateBillingContactForm from "pages/dashboards/adminDashboard/employers/UpdateBillingContactForm";
import InfoTable from "components/InfoTable";

const metadata = [
  {
    key: "billingContactEmail",
    label: "Billing Contact",
  },
  {
    key: "monthlyFee",
    label: "Subscription Fee",
    format: "currency",
  },
  {
    key: "billingFrequency",
    label: "Billing Frequency",
    format: (val) => {
      if (val === "year") return "Annually";
      if (val === "month") return "Monthly";
    },
  },
  {
    key: "perUserFee",
    label: "Per User Fee",
    format: "currency",
  },
  {
    key: "nextBillingDate",
    label: "Next Billing Date",
  },
  {
    key: "stripeCustomerId",
    label: "Stripe ID",
  },
  {
    key: "metadata.billedInArrears",
    label: "Billed In Arrears",
    format: (isBilledInArrears) => (isBilledInArrears ? "Yes" : "No"),
  },
];

class Billing extends React.PureComponent {
  static propTypes = {
    companyId: PropTypes.string,
    error: PropTypes.string,
    companyAdminEmail: PropTypes.string,
    getBillingDetails: PropTypes.func,
    updateCompany: PropTypes.func,
    billingType: PropTypes.string,
    client: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      showBillingForm: false,
      initialFetching: true,
      billingDetails: {},
      showBillingContactForm: false,
    };
  }

  async componentDidMount() {
    const { data } = await this.props.getBillingDetails(
      this.props.client,
      this.props.companyId
    );

    this.setState({
      initialFetching: false,
      billingDetails: data,
    });
  }

  _getBillingForm = () => {
    const billingContactEmail = get(
      this.state.billingDetails,
      "billingContactEmail",
      ""
    );
    return (
      <BillingForm
        onClose={() => this.setState({ showBillingForm: false })}
        onSuccess={(billingDetails) =>
          this.setState({ showBillingForm: false, billingDetails })
        }
        companyId={this.props.companyId}
        billingContactEmail={
          billingContactEmail || this.props.companyAdminEmail
        }
      />
    );
  };

  _getBillingContactForm = () => {
    return (
      <UpdateBillingContactForm
        companyId={this.props.companyId}
        billingContactEmail={get(
          this.state.billingDetails,
          "billingContactEmail",
          ""
        )}
        onSuccess={(billingDetails) =>
          this.setState({ billingDetails, showBillingContactForm: false })
        }
        onClose={() => this.setState({ showBillingContactForm: false })}
      />
    );
  };

  _getContent() {
    if (this.state.showBillingForm) {
      return this._getBillingForm();
    }

    if (this.state.showBillingContactForm) {
      return this._getBillingContactForm();
    }

    if (this.state.showChangeBillingForm) {
      return (
        <ChangeBillingTypeForm
          companyId={this.props.companyId}
          billingType={this.props.billingType}
          onCancel={() => this.setState({ showChangeBillingForm: false })}
          onSuccess={(billingType) => {
            this.props.updateCompany({ billingType });
            this.setState({
              showChangeBillingForm: false,
              billingDetails: {},
            });
          }}
        />
      );
    }

    switch (this.props.billingType) {
      case ISOLVED_WHOLESALE:
        return (
          <p className={"page-title centered"}>
            Employer is billed through Isolved Wholesale
          </p>
        );
      case LEGACY_NOBILL:
        return (
          <p className={"page-title centered"}>
            Employer is on a grandfathered non-billable plan
          </p>
        );
    }

    const hasBillingSubscription =
      !isEmpty(this.state.billingDetails) &&
      this.state.billingDetails.subscriptionId;

    if (hasBillingSubscription) {
      return <InfoTable metadata={metadata} data={this.state.billingDetails} />;
    }

    return (
      <div className="blank-billing-slate">
        <div>
          <IconEmptyState
            header={"Setup Billing"}
            subheader="It looks like billing needs to be setup for this customer."
            icon={<FiRepeat color="white" stroke="#60A4BF" size={16} />}
            actionText={"Setup Billing"}
            onClick={() => this.setState({ showBillingForm: true })}
          />
        </div>
      </div>
    );
  }

  render() {
    if (this.state.initialFetching) return <IconSpinner centered />;
    const showChangeBillingBtn =
      !this.state.showBillingForm &&
      !this.state.showChangeBillingForm &&
      !this.state.showBillingContactForm;
    const content = this._getContent();
    const hasBillingSubscription =
      !isEmpty(this.state.billingDetails) &&
      this.state.billingDetails.subscriptionId;

    return (
      <div id="admin-billing">
        {content}
        {showChangeBillingBtn && (
          <div className="submit-row btn-row" style={{ float: "right" }}>
            {hasBillingSubscription && (
              <Button
                name="action"
                btnLabel={"Change Billing Contact"}
                color={"primary"}
                size={"sm"}
                onClick={() => this.setState({ showBillingContactForm: true })}
              />
            )}
            <Button
              name="action"
              btnLabel={"Change Billing Type"}
              color={"action"}
              size={"sm"}
              onClick={() => this.setState({ showChangeBillingForm: true })}
            />
          </div>
        )}
        {this.props.error && <Alert type="error" msg={this.props.error} />}
      </div>
    );
  }
}

const errorSelector = createErrorSelector(adminConstants.GET_BILLING_DETAILS);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  getBillingDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Billing));
