import { employerConstants, payrollConstants } from "./types";
import {
  employerService,
  getPaginatedInvites as getInviteSet,
  submitEmployerAgents,
} from "services/employerService";
import ActionCreator from "utils/actionHandler";
import { map } from "lodash";

export const registerEmployer = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.REGISTER_EMPLOYER
    );
    dispatch(actionCreator.request());

    return employerService.register(client, params).then(
      (data) => {
        if (data && data.company.id) {
          try {
            dispatch(actionCreator.success(data.company));
            // Success, navigate to the next page

            return data;
          } catch (error) {
            dispatch(actionCreator.failure(error));
          }
        }
      },
      (error) => {
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

//Add Controller + Benificial Owner
export const postEmployerAgent = (client, employer) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.REGISTER_AGENT);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      submitEmployerAgents(client, employer)
    );
  };
};

// Get company details
export const getCompanyInfo = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.GET_COMPANY);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getCompany(client)
    );
  };
};

// Get employer accounts
export const getAllEmployerAccounts = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_EMPLOYER_ACCOUNTS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getAllEmployerAccounts(client)
    );
  };
};

// Add employer groups
export const addEmployerGroup = (client, id, group) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.ADD_EMPLOYER_GROUPS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.addEmployerGroup(client, id, group),
      { id }
    );
  };
};

// Get employer groups
export const getAllEmployerGroups = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_EMPLOYER_GROUPS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getAllEmployerGroups(client)
    );
  };
};
export const getEmployerGroup = (client, groupId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_EMPLOYER_GROUP
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getEmployerGroup(client, groupId)
    );
  };
};

// Delete employer Group
export const deleteEmployerGroup = (client, id, companyId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.DELETE_EMPLOYER_GROUPS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.deleteEmployerGroup(client, id, companyId),
      { id }
    );
  };
};

// Update employer groups
export const updateEmployerGroup = (client, group, companyId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.UPDATE_EMPLOYER_GROUPS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.updateEmployerGroup(client, group, companyId)
    );
  };
};

export const inviteEmployees = (client, { userEmails, groupId }) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.INVITE_EMPLOYEE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.inviteEmployees(client, userEmails, groupId)
    );
  };
};

export const stageEmployeeInvites = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.STAGE_EMPLOYEE_INVITE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.stageEmployeeInvites(client, params)
    );
  };
};

export const resendInvitation = (client, invitation) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.RESEND_INVITE_EMPLOYEE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.resendInvitation(client, invitation)
    );
  };
};

export const getEmployeeInvitations = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_EMPLOYEE_INVITES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getEmployeeInvites(client)
    );
  };
};

export const getTakeRateEmployeesForCompany = (client, groupId, timeframe) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_TAKE_RATE_EMPLOYEES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getTakeRateEmployeesForCompany(client, groupId, timeframe)
    );
  };
};

export const getLinkedEmployees = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_LINKED_EMPLOYEES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getLinkedEmployees(client)
    );
  };
};

export const getPendingLinkedEmployees = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_PENDING_LINKED_EMPLOYEES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getPendingLinkedEmployees(client)
    );
  };
};

export const approveEmployeeLinks = (client, linkIds) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.APPROVE_PENDING_LINKED_EMPLOYEE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.approvePendingLinkedEmployees(client, linkIds)
    );
  };
};

export const rejectEmployeeLinks = (client, linkIds) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.REJECT_PENDING_LINKED_EMPLOYEE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.rejectPendingLinkedEmployees(client, linkIds)
    );
  };
};

export const processPayrollForGroup = (client, payrollInput) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.PROCESS_PAYROLL);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.processPayroll(client, payrollInput)
    );
  };
};

export const getUsersForGroup = (client, groupId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_USERS_FOR_GROUP
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getUserProfilesForGroup(client, groupId)
    );
  };
};

export const approveContributionChanges = (
  client,
  contributionChangeIds,
  groupId
) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.APPROVE_CONTRIBUTION_CHANGES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.approveContributionChanges(
        client,
        contributionChangeIds,
        groupId
      )
    );
  };
};

export const rejectContributionChanges = (
  client,
  contributionChangeIds,
  groupId
) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.REJECT_CONTRIBUTION_CHANGES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.rejectContributionChanges(
        client,
        contributionChangeIds,
        groupId
      )
    );
  };
};

export const deleteEmployerLinks = (client, contributionChangeIds) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.DELETE_EMPLOYER_LINKS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.deleteEmployerLinks(client, contributionChangeIds)
    );
  };
};

export const getAdministrators = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_ADMINISTRATORS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getAdministrators(client)
    );
  };
};

export const resendAdministratorInvitation = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.RESEND_INVITE_ADMINISTRATOR
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.resendAdministratorInvite(client, params)
    );
  };
};

export const inviteAdministrator = (client, administrator) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.INVITE_ADMINISTRATOR
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.inviteAdministrator(client, administrator)
    );
  };
};

export const lockAdministrator = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.LOCK_ADMINISTRATOR
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.lockAdministrator(client, params)
    );
  };
};

export const unlockAdministrator = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.UNLOCK_ADMINISTRATOR
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.unlockAdministrator(client, params)
    );
  };
};

export const revokeInvitation = (client, invitationId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.REVOKE_INVITE_EMPLOYEE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.revokeInvitation(client, invitationId)
    );
  };
};

export const uploadEmployeesDocument = (client, groupId, file) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.UPLOAD_EMPLOYEES_DOCUMENT
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.uploadEmployeesDocumentRequest(client, groupId, file)
    );
  };
};

export const getEmployeeDocumentUploadJobs = (client, companyId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_EMPLOYEE_DOCUMENT_UPLOAD_JOBS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getEmployeeDocumentUploadJobsRequest(client, companyId)
    );
  };
};

export const resendInvitations = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.RESEND_INVITES_EMPLOYEE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.resendInvitations(client, params)
    );
  };
};

export const getPaginatedEmployees = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_PAGINATED_EMPLOYEES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getPaginatedEmployees(client, params)
    );
  };
};

export const setEmployerSession = (client, companyId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.SET_EMPLOYER_SESSION
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.setEmployerSession(client, companyId)
    );
  };
};
export const getLinkedCompanies = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_LINKED_COMPANIES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getLinkedCompanies(client)
    );
  };
};

export const getPaginatedInvites = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_PAGINATED_INVITES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getInviteSet(client, params)
    );
  };
};

export const changeAdministratorRole = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.CHANGE_ADMIN_ROLE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.changeAdministratorRole(client, params)
    );
  };
};

export const fetchOwners = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.GET_OWNERS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getBeneficialOwners(client)
    );
  };
};

export const validateSelfServe = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.VALIDATE_SELF_SERVE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.validateSelfServe(client)
    );
  };
};

export const getSetupIntent = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.GET_SETUP_INTENT);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getSetupIntent(client)
    );
  };
};

export const createSetupIntent = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.CREATE_SETUP_INTENT
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.createSetupIntent(client)
    );
  };
};

export const setInvoicePaymentMethod = (client, paymentMethod) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.SET_INVOICE_PAYMENT_METHOD
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.setInvoicePaymentMethod(client, paymentMethod)
    );
  };
};

export const getBillingPortalURL = (client, returnUrl) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_BILLING_PORTAL_URL
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getBillingPortalURL(client, returnUrl)
    );
  };
};

export const getProgressiveOnboarding = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_PROGRESSIVE_ONBOARDING
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getProgressiveOnboarding(client)
    );
  };
};

export const updateProgressiveOnboarding = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.UPDATE_PROGRESSIVE_ONBOARDING
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.updateProgressiveOnboarding(client, params)
    );
  };
};

export const uploadRoster = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.UPLOAD_ROSTER);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.uploadRoster(client, params)
    );
  };
};

export const linkFinch = (client, token) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.CONNECT_FINCH);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.linkFinch(client, token)
    );
  };
};

export const getFinchStatus = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.GET_FINCH_STATUS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getFinchStatus(client)
    );
  };
};

export const getEmployeeRoster = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_EMPLOYEE_ROSTER
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getEmployeeRoster(client, params)
    );
  };
};

export const resyncEmployeeRoster = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.RESYNC_ROSTER);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.resyncEmployeeRoster(client)
    );
  };
};

export const processRoster = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.PROCESS_ROSTER);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.processRoster(client)
    );
  };
};

export const approveRoster = (client, rejectedEmployeeIds) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.APPROVE_ROSTER);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.approveRoster(client, rejectedEmployeeIds)
    );
  };
};

export const reconnectFinch = (client, token) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.RECONNECT_FINCH);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.reconnectFinch(client, token)
    );
  };
};

// Refered Company
export const referCompany = (client, email) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.REFER_COMPANY);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.referCompany(client, email)
    );
  };
};

export const getPayrollGroupContributionAbility = (client, groupId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_PAYROLL_GROUP_CONTRIBUTION_ABILITY
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getPayrollGroupContributionAbilityRequest(
        client,
        groupId
      ),
      { id: groupId }
    );
  };
};

export const currentPayrolls = (client, companyId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.CURRENT_PAYROLLS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.currentPayrolls(client, companyId)
    );
  };
};

export const skipBilling = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.SKIP_BILLING);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.skipBilling(client)
    );
  };
};

export const hydrateDashboard = (client) => {
  const queryNameToReducerAction = {
    employerAccountList: employerConstants.GET_EMPLOYER_ACCOUNTS_SUCCESS,
    getEmployerGroups: employerConstants.GET_EMPLOYER_GROUPS_SUCCESS,
    getAllEmployeeInvitesForCompany:
      employerConstants.GET_EMPLOYEE_INVITES_SUCCESS,
    getEmployerLinkRequests:
      employerConstants.GET_PENDING_LINKED_EMPLOYEES_SUCCESS,
    getTotalAmountSaved: payrollConstants.GET_TOTAL_AMOUNT_SAVED_SUCCESS,
    getLinkedEmployees: employerConstants.GET_LINKED_EMPLOYEES_SUCCESS,
  };
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.HYDRATE_DASHBOARD
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.hydrateDashboard(client),
      undefined,
      (data) => {
        map(data, (d, key) => {
          if (queryNameToReducerAction[key]) {
            const action = {
              type: queryNameToReducerAction[key],
              data: d,
            };
            dispatch(action);
          }
        });
      }
    );
  };
};

export const getEmployerRequiredUpdates = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_EMPLOYER_REQUIRED_STEPS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getEmployerRequiredStepsRequest(client)
    );
  };
};

export const updateRequiredOrderForm = (client, completedOrderForm) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.UPDATE_EMPLOYER_REQUIRED_ORDER_FORM
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.updateRequiredOrderFormsRequest(
        client,
        completedOrderForm
      )
    );
  };
};

export const getPayrollIntegrationStatus = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_PAYROLL_INTEGRATION_STATUS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getPayrollIntegrationStatus(client)
    );
  };
};

export const getCompanyTermsSubmissionDate = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      employerConstants.GET_EMPLOYER_TERMS_DATE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.companyTermsDate(client)
    );
  };
};

export const getSalesQuote = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(employerConstants.GET_SALES_QUOTE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      employerService.getSalesQuote(client, params)
    );
  };
};
