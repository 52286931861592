import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { FiRefreshCw } from "react-icons/fi";
import { activeRolloverIsMixed } from "store/selectors/user";
import { setAccountSession } from "actions/accountActions";

import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import Button from "components/Button";
import RolloverInstructionsModal from "./RolloverInstructionsModal";

import "./PriorYearContributionCard.scss";

class IndividualRolloverCard extends React.Component {
  static propTypes = {
    client: PropTypes.object,
    setAccountSession: PropTypes.func,
    title: PropTypes.string,
    subtext: PropTypes.string,
    onClick: PropTypes.bool,
    accountId: PropTypes.string,
  };
  constructor() {
    super();

    this.state = {
      showRolloverInstructionsModal: false,
    };
  }

  render() {
    return (
      <>
        <div className="rollover-banner">
          <RolloverInstructionsModal
            show={this.state.showRolloverInstructionsModal}
            onSuccess={() => {
              this.setState({ showRolloverInstructionsModal: false });
            }}
            onClose={() => {
              this.setState({ showRolloverInstructionsModal: false });
            }}
          />

          <div className="rollover-box">
            <div className="employerInfo">
              <div className="employerInfo-body">
                <div>
                  <div className="circle">
                    <span className="icon">
                      <FiRefreshCw color="white" stroke="#60A4BF" size={16} />
                    </span>
                  </div>
                </div>
                <div className="employerInfo-intro">
                  <IconHeader
                    variant="labelHeader"
                    headerText={this.props.title}
                  />
                  <IconSubheader subheader={this.props.subtext} />
                </div>
              </div>
              <div className="employerInfo-action">
                <Button
                  size="sm"
                  color="action"
                  name="action"
                  btnLabel="View"
                  onClick={() => {
                    this.setState({ showRolloverInstructionsModal: true });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;
  const accounts = state.accounts.iraAccounts;
  const account = find(accounts, {
    isProcessingRollover: false,
    state: "AccountActive",
    isRollover: true,
  });
  const accountId = account ? account.id : null;

  return {
    userState,
    accounts,
    accountId,
    isRolloverIsMixed: activeRolloverIsMixed(state),
  };
};

const mapDispatchToProps = {
  setAccountSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualRolloverCard));
