import React from "react";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import EllipsisLoader from "components/EllipsisLoader";

const VerificationCheckMark = () => {
  return (
    <svg
      className="checkmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <circle
        className="checkmark__circle"
        cx="26"
        cy="26"
        r="25"
        fill="none"
      />
      <path
        className="checkmark__check"
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      />
    </svg>
  );
};

const Step = ({ isActive, label, subtext, index, completedStep, isAsync }) => {
  return (
    <div
      className={classnames("step", {
        ["is-active"]: isActive,
        ["is-completed"]: completedStep,
      })}
    >
      <div className="step-box">
        <div className="step-box-details">
          <p className="step-label">
            {index}. {label}
            {isAsync && isActive ? <EllipsisLoader /> : null}
          </p>

          {completedStep && (
            <span className="verification">
              <VerificationCheckMark />
            </span>
          )}
        </div>
        {subtext && <p className="step-subtext">{subtext}</p>}
      </div>
    </div>
  );
};

Step.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string,
  index: PropTypes.number,
  completedStep: PropTypes.bool,
  isAsync: PropTypes.bool,
  disabled: PropTypes.bool,
  subtext: PropTypes.string,
};

export default Step;
