import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card } from "react-bootstrap";
import { every, isEmpty, size, transform } from "lodash";
import { FaUndo } from "react-icons/fa";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { push } from "connected-react-router";

import Button from "components/Button";
import StatusBox from "components/StatusBox";
import IconTable from "components/IconTable";

export class ReviewEmployeeLinkRequest extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    push: PropTypes.func,
    setApproved: PropTypes.func,
    setRejected: PropTypes.func,
    goToNamedStep: PropTypes.func,
    isFetching: PropTypes.bool,
    pendingLinkRequests: PropTypes.array,
    client: PropTypes.object,
    approvedPlans: PropTypes.object,
    rejectedPlans: PropTypes.object,
    group: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedLinkRequests: {},
      selectAll: false,
      selectedLinks: {},
    };
  }

  _removeFromApproved = (id) => {
    const newApproved = { ...this.props.approvedPlans };
    delete newApproved[id];
    this.props.setApproved(newApproved);
  };

  _removeFromRejected = (id) => {
    const newRejected = { ...this.props.rejectedPlans };
    delete newRejected[id];
    this.props.setRejected(newRejected);
  };

  _addToApproved = () => {
    const newApproved = {
      ...this.props.approvedPlans,
      ...this.state.selectedLinkRequests,
    };
    this.props.setApproved(newApproved);

    this.setState({
      selectAll: false,
      selectedLinkRequests: {},
    });
  };

  _addToRejected = () => {
    const newRejected = {
      ...this.props.rejectedPlans,
      ...this.state.selectedLinkRequests,
    };
    this.props.setRejected(newRejected);

    this.setState({ selectAll: false, selectedLinkRequests: {} });
  };

  _getEmployeeStatus = (id) => {
    const statusAndUndo = {
      status: null,
      undoButton: null,
    };

    if (this.props.approvedPlans[id]) {
      statusAndUndo.status = <StatusBox status="Approved" type="success" />;
      statusAndUndo.undoButton = (
        <span className="undo">
          <FaUndo
            size={14}
            color={"#858c9c"}
            onClick={() => this._removeFromApproved(id)}
          />
        </span>
      );
    } else if (this.props.rejectedPlans[id]) {
      statusAndUndo.status = <StatusBox status="Rejected" type="danger" />;
      statusAndUndo.undoButton = (
        <span className="undo">
          <FaUndo
            size={14}
            color={"#858c9c"}
            onClick={() => this._removeFromRejected(id)}
          />
        </span>
      );
    } else {
      statusAndUndo.status = <StatusBox status="Pending" type="pending" />;
    }

    return statusAndUndo;
  };

  _getReviewExistingData = () => {
    return this.props.pendingLinkRequests.map((emp) => {
      const {
        userProfile: { firstName, lastName, email, lastFourOfSsn },
      } = emp;

      const fullName = `${firstName} ${lastName}`;
      const groupName = emp.group.name;
      const id = emp.id;

      const { status, undoButton } = this._getEmployeeStatus(id);

      return {
        groupName,
        employeeName: fullName,
        lastFourOfSsn,
        email,
        status,
        id,
        undoButton,
        emp,
      };
    });
  };

  _buildReviewExistingColumns = () => {
    const columns = [
      {
        label: "Employee Name",
        key: "employeeName",
      },
      {
        label: "Employee Email",
        key: "email",
      },
      {
        label: "Last 4 SSN",
        key: "lastFourOfSsn",
      },
      {
        label: "Status",
        key: "status",
      },
      {
        customComponent: (props) => {
          const { undoButton } = this._getEmployeeStatus(props.id);

          return <div className="undo-button">{undoButton}</div>;
        },
      },
    ];
    return columns;
  };

  _selectAll = (checked) => {
    if (!checked) {
      this.setState({
        selectAll: false,
        selectedLinkRequests: {},
      });
      return;
    }

    const linkRequests = this.props.pendingLinkRequests;

    this.setState({
      selectAll: true,
      selectedLinkRequests: transform(
        linkRequests,
        (acc, req) => {
          acc[req.id] = checked;
          return acc;
        },
        {}
      ),
    });
  };

  _selectPending = (linkRequestId, checked) => {
    this.setState((state) => {
      const selectAll = this.state.selectAll && checked;
      return {
        selectedLinkRequests: {
          ...state.selectedLinkRequests,
          [linkRequestId]: checked,
        },
        selectAll,
      };
    });
  };

  render() {
    const columns = this._buildReviewExistingColumns();
    const data = this._getReviewExistingData();

    const pending = this.props.pendingLinkRequests;
    const pendingEmployees = size(pending);

    const noCheckedRow = every(
      this.state.selectedLinkRequests,
      (rowChecked) => !rowChecked
    );
    const disableActions = noCheckedRow;

    const approvedUsers = isEmpty(this.props.approvedPlans);
    const rejectedUsers = isEmpty(this.props.rejectedPlans);
    const hasNotTakenAction = approvedUsers && rejectedUsers;

    return (
      <>
        <Card>
          <>
            <div className="widget-header">
              <span>
                <Card.Title>
                  {this.props.group.name}:
                  <p className="invite-number">
                    Pending Employees (
                    <p className="number">{pendingEmployees}</p>)
                  </p>
                </Card.Title>
              </span>
              <div>
                <div className="button-row">
                  <Button
                    onClick={this._addToRejected}
                    color="red"
                    btnLabel="Reject Selected"
                    size="sm"
                    name="action"
                    disabled={disableActions}
                  />
                  <Button
                    onClick={this._addToApproved}
                    color="action"
                    name="action"
                    size="sm"
                    btnLabel="Approve Selected"
                    disabled={disableActions}
                  />
                </div>
              </div>
            </div>
            <IconTable
              columns={columns}
              data={data}
              hasSelectableRows
              uniqueRowIdentifier="id"
              onSelectAll={(e) => this._selectAll(e.target.checked)}
              hasSelectedAll={this.state.selectAll}
              onRowSelect={(id, checked) => {
                this._selectPending(id, checked);
              }}
              selectedRows={this.state.selectedLinkRequests}
            />
            <div className="close-btn">
              <Button
                onClick={() => this.props.push("/dashboard/company/group")}
                btnLabel="Cancel"
                name="cancel"
                color="cancel"
              />
              <span style={{ paddingLeft: "10px" }}>
                <Button
                  btnLabel="Next"
                  type="button"
                  name="action"
                  disabled={hasNotTakenAction}
                  onClick={() => this.props.goToNamedStep("approvals")}
                />
              </span>
            </div>
          </>
        </Card>
      </>
    );
  }
}

const actions = [
  employerConstants.REJECT_PENDING_LINKED_EMPLOYEE,
  employerConstants.APPROVE_PENDING_LINKED_EMPLOYEE,
];

const isLoadingSelector = createLoadingSelector(actions);
const isErrorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    isFetching: isLoadingSelector(state),
    error: isErrorSelector(state),
  };
};

const mapDispatchToProps = { push };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ReviewEmployeeLinkRequest));
