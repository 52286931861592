import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Alert from "components/Alert";
import { createErrorSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import {
  getEmployeeInvitations,
  resendAdministratorInvitation,
} from "actions/employerActions";
import { rolesToEnglish } from "statics/roleToEnglish";
import {
  adminStatus,
  adminStatusToEnglish,
} from "statics/statusToEnglishMapping";

import "./AdministratorTable.scss";
import IconTable from "../../../../components/IconTable";
import StatusBox from "../../../../components/StatusBox";
import AdministratorButtons from "./AdministratorButtons";
import InfoTooltip from "components/InfoTooltip";

class AdministratorTable extends React.Component {
  static propTypes = {
    administrators: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.string,
    client: PropTypes.object,
    currentUserId: PropTypes.string,
    resendAdministratorInvitation: PropTypes.func,
    link: PropTypes.shape({
      role: PropTypes.string,
      locked: PropTypes.bool,
      invitationExpiresAt: PropTypes.string,
      status: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  resendInvitation = (administrator) => {
    this.props
      .resendAdministratorInvitation(this.props.client, {
        agentId: administrator.user.id,
      })
      .then(() => {
        if (!this.props.error) {
          toast.success("Successfully resent invitation.");
        } else {
          toast.error(this.props.error);
        }
      });
  };

  _getColumns = () => {
    const pendingInvites = this.props.administrators.filter(
      (administrator) => administrator.link.status === "PENDING"
    );
    const hasPendingInvites = pendingInvites.length > 0;
    const lockedAndPendingInvites = this.props.administrators.filter(
      (administrator) =>
        administrator.link.status === "PENDING" && administrator.link.locked
    );
    const pendingInvitesAreAllLocked =
      pendingInvites.length === lockedAndPendingInvites.length;

    const columns = [
      {
        key: "user.email",
        label: "User Email",
      },
      {
        key: "link.role",
        label: "Role",
        customComponent: (props) => {
          const roleType = rolesToEnglish[props.link.role];
          const owner = roleType === "Owner";

          if (owner) {
            return (
              <>
                {roleType}
                <InfoTooltip
                  tooltipBody="Owner: has super admin capabilities (can invite other admins)
                   Administrator: can administrate the plan but not invite other admins"
                />
              </>
            );
          }
          return <>{roleType}</>;
        },
      },
      {
        key: "link.status",
        label: "Status",
        customComponent: (props) => {
          const { invitationExpiresAt, status, locked } = props.link;
          const expiresAt = moment(invitationExpiresAt);
          const isExpired = expiresAt.diff(moment.utc(), "days") < 0;
          if (locked) {
            return (
              <StatusBox
                status={adminStatusToEnglish["LOCKED"]}
                type={"dark"}
              />
            );
          }
          if (isExpired && status === "PENDING") {
            return (
              <StatusBox
                status={adminStatusToEnglish["EXPIRED"]}
                type={"danger"}
              />
            );
          }
          return (
            <StatusBox
              status={adminStatusToEnglish[status]}
              type={adminStatus[status]}
            />
          );
        },
      },

      ...(!pendingInvitesAreAllLocked && hasPendingInvites
        ? [
            {
              label: "Invite",
              customComponent: (props) => {
                const shouldShowAction =
                  hasPendingInvites &&
                  (props.link.status === "PENDING" ||
                    props.link.status === "EXPIRED") &&
                  !props.link.locked;
                const action = shouldShowAction ? (
                  <div style={{ position: "relative" }}>
                    <a
                      className="icon-link"
                      onClick={() => this.resendInvitation(props)}
                    >
                      Re-invite Admin
                    </a>
                  </div>
                ) : (
                  <div style={{ position: "relative" }} />
                );
                return action;
              },
            },
          ]
        : []),
      {
        label: "Action",
        customComponent: AdministratorButtons,
      },
    ];

    return columns;
  };

  render() {
    const columns = this._getColumns();
    return (
      <>
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        <IconTable columns={columns} data={this.props.administrators} />
      </>
    );
  }
}

const errorSelector = createErrorSelector([
  employerConstants.GET_EMPLOYEE_INVITES,
  employerConstants.RESEND_INVITE_ADMINISTRATOR,
  employerConstants.CHANGE_ADMIN_ROLE,
  employerConstants.LOCK_ADMINISTRATOR,
  employerConstants.UNLOCK_ADMINISTRATOR,
]);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    currentUserId: state.user.id,
  };
};

const mapDispatchToProps = {
  getEmployeeInvitations,
  resendAdministratorInvitation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AdministratorTable));
