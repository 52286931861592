// TODO figure out what we want our chart colors to be for branding/accessibility
export const chartColors = [
  "#2696A6",
  "#ED6F40",
  "#402A7F",
  "#DB4163",
  "#C3C9AD",
  "#4AA5A8",
  "#37324E",
  "#8EA1AF",
];

export const performanceChartColors = [
  "#2E3D55",
  "#2F5B74",
  "#B2D4E9",
  "#8E9BA6",
  "#D4DBDE",
];

export const categoryToColorMapping = {
  "International Equity": "#1B93A2",
  "International Fixed Income": "#2C7E96",
  "Domestic Equity": "#356084",
  "Domestic Fixed Income": "#394675",
  Cash: "#3A3369",
};

export const categoryToColorMappingParticipationFunnel = {
  notStarted: "#009deb",
  opening: "#1a59e6",
  enrolled: "#002c59",
};
