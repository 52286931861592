import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { FiLink2 } from "react-icons/fi";
import { isFunction } from "lodash";

import "./ClipboardCopy.scss";

function ClipboardCopy({ copyText, onClick }) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        if (isFunction(onClick)) {
          onClick();
        }
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="link-box" value={copyText}>
      <span className="link" onClick={handleCopyClick}>
        <FiLink2 className="icon" color="white" stroke="#60A4BF" size={16} />
        <p className="link-text">{isCopied ? "Copied!" : "Copy Link"}</p>
      </span>
    </div>
  );
}

ClipboardCopy.propTypes = {
  copyText: PropTypes.string,
  onClick: PropTypes.func,
};

export default ClipboardCopy;
