import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { isEmpty, size } from "lodash";
import { Card } from "react-bootstrap";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { FiUserPlus } from "react-icons/fi";
import { toast } from "react-toastify";
import { inviteAdministrator } from "actions/employerActions";

import Button from "components/Button";
import IconSpinner from "components/IconSpinner";
import AdministratorTable from "./AdministratorTable";
import AdministratorForm from "./AdministratorForm";
import IconEmptyState from "components/IconEmptyState";

class Administrators extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    isInviting: PropTypes.bool,
    administrators: PropTypes.arrayOf(PropTypes.object),
    inviteAdministrator: PropTypes.func,
    onCancel: PropTypes.func,
    invitationError: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      showAddAdminForm: false,
    };
  }

  inviteAdministrator = (values) => {
    this.props.inviteAdministrator(this.props.client, values).then(() => {
      if (!this.props.error) {
        toast.success("Successfully invited administrator.");
        this.setState({ showAddAdminForm: false });
      }
    });
  };

  _adminHeader() {
    const acceptedInvite = this.props.administrators.filter(
      (administrator) => administrator.link.status === "ACCEPTED"
    );

    const numOfActiveAdmins = size(acceptedInvite);

    return (
      <div>
        <div className="widget-header">
          <span>
            <Card.Title>
              Administrators:
              <p className="invite-number">
                Active (<p className="number">{numOfActiveAdmins}</p>)
              </p>
            </Card.Title>
          </span>

          <Button
            icon={{
              icon: <FiUserPlus size={14} color={"#fffff"} />,
              position: "left",
            }}
            size="sm"
            name="action"
            onClick={() => this.setState({ showAddAdminForm: true })}
            btnLabel="Add Admin"
          />
        </div>
      </div>
    );
  }

  _getTable() {
    if (this.props.isFetching) {
      return <IconSpinner centered />;
    }

    if (isEmpty(this.props.administrators)) {
      return (
        <>
          <div style={{ borderTop: "1px solid #f2f4f7" }}>
            <IconEmptyState
              header="Add Administrator"
              subheader="It looks like you haven't invited any administrators yet."
              icon={<FiUserPlus color="white" stroke="#60A4BF" size={16} />}
            />
          </div>
        </>
      );
    }
    return (
      <div className="tab-pane" role="tabpanel" aria-labelledby="account_tab">
        <AdministratorTable administrators={this.props.administrators} />
      </div>
    );
  }

  _displayContent = () => {
    if (this.state.showAddAdminForm) {
      return (
        <div className="add-employee-form">
          <div className="widget-header">
            <Card.Title>Add Administrators</Card.Title>
          </div>
          <Card.Body>
            <AdministratorForm
              isInviting={this.props.isInviting}
              onSubmit={this.inviteAdministrator}
              error={this.props.invitationError}
              administrators={this.props.administrators}
              onCancel={() =>
                this.setState({
                  showAddAdminForm: false,
                })
              }
            />
          </Card.Body>
        </div>
      );
    }
    return (
      <>
        {this._adminHeader()}
        {this._getTable()}
      </>
    );
  };

  render() {
    const content = this._displayContent();
    return (
      <div
        className="tab-pane"
        id="group"
        role="tabpanel"
        aria-labelledby="group_tab"
      >
        <Card className="groups-table">{content}</Card>
      </div>
    );
  }
}

const actions = [employerConstants.GET_ADMINISTRATORS];
const isFetchingSelector = createLoadingSelector(actions);
const isInvitingSelector = createLoadingSelector(
  employerConstants.INVITE_ADMINISTRATOR
);

const isInvitingErrorSelector = createErrorSelector(
  employerConstants.INVITE_ADMINISTRATOR
);

const errorSelector = createErrorSelector(actions);
const mapStateToProps = (state) => {
  return {
    isFetching: isFetchingSelector(state),
    isInviting: isInvitingSelector(state),
    invitationError: isInvitingErrorSelector(state),
    administrators: state.employer.administrators,
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  inviteAdministrator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(withApollo(Administrators));
