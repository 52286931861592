import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import { connect } from "react-redux";
import { map, isEmpty, get, filter, flatten, camelCase } from "lodash";
import { Card, Form } from "react-bootstrap";
import { withApollo } from "@apollo/client/react/hoc";
import { positionsType } from "statics/propTypes";
import { formatCurrency, formatPercentage } from "utils/number";
import {
  getUserPositions,
  getUserPortfolio,
  getUserPerformance,
} from "actions/portfolioActions";
import { portfolioMetadataMap } from "utils/individualPortfolioHelper";
import PieChart from "components/highcharts/Pie";
import { portfolioType, categoryPerformanceType } from "statics/propTypes";
import { createLoadingSelector } from "store/selectors";
import { portfolioConstants } from "actions/types";
import { categoryToColorMapping } from "utils/highcharts/config";
import IconSpinner from "components/IconSpinner";
import { getIraAccountIdSelector } from "store/selectors/user";

const currentYear = moment.utc().format("YYYY");
const previousYear = moment.utc().subtract(1, "years").format("YYYY");
const twoYearsBeforeNow = moment.utc().subtract(2, "years").format("YYYY");
const threeYearsBeforeNow = moment.utc().subtract(3, "years").format("YYYY");

const timeOptions = [
  { value: "ALL_TIME", label: "All Time" },
  { value: "PAST_90_DAYS", label: "Past 90 Days" },
  { value: "PAST_30_DAYS", label: "Past 30 Days" },
  { value: "PAST_7_DAYS", label: "Past 7 Days" },
  { value: "CURRENT_DAY", label: "Current Day" },
  { value: currentYear, label: currentYear },
  { value: previousYear, label: previousYear },
  { value: twoYearsBeforeNow, label: twoYearsBeforeNow },
  { value: threeYearsBeforeNow, label: threeYearsBeforeNow },
];
class PerformanceTable extends React.PureComponent {
  static propTypes = {
    positions: PropTypes.arrayOf(positionsType),
    getUserPositions: PropTypes.func,
    getUserPortfolio: PropTypes.func,
    getUserPerformance: PropTypes.func,
    performance: PropTypes.arrayOf(categoryPerformanceType),
    userPortfolio: portfolioType,
    portfolios: PropTypes.func,
    client: PropTypes.object,
    iraAccountId: PropTypes.string,
    positionsUpdatedAt: PropTypes.string,
    portfolioAssets: PropTypes.string,
    categoryPerformances: PropTypes.arrayOf(categoryPerformanceType),
    performanceUpdatedAt: PropTypes.string,
    endTime: PropTypes.string,
    startTime: PropTypes.string,
    isFetching: PropTypes.bool,
    createdAt: PropTypes.string,
    portfolioName: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      activeSeriesCategory: "",
      selectedTimePeriod: "CURRENT_DAY",
    };
  }

  componentDidMount() {
    this.props.getUserPositions(this.props.client, this.props.iraAccountId);
    this.props.getUserPortfolio(this.props.client);
    this._getPerformance();
  }
  _getPerformance = () => {
    const { startTime, endTime } = this._timeSlicedReturns();
    return this.props.getUserPerformance(
      this.props.client,
      this.props.iraAccountId,
      startTime.toISOString(),
      endTime.toISOString()
    );
  };
  _getPortfolioChartConfig = () => {
    const reactInstance = this;
    return {
      tooltip: {
        formatter() {
          return `<div> ${map(
            this.point.assetPerformances,
            (p) =>
              `<div> <span style="color:blue; font-weight: 600;  " > ${formatPercentage(
                p.categoryAllocation / 100
              )} : </span> <span style=" font-weight: 500; color: #202546;  "> ${
                p.assetId.symbol
              } </span> ${p.assetId.name}  </div>`
          ).join()} </div>`;
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          showInLegend: false,
          innerSize: "55%",
          width: 200,
          height: 200,
          point: {
            events: {
              mouseOver() {
                reactInstance.setState({ activeSeriesCategory: this.name });
              },
              mouseOut() {
                reactInstance.setState({ activeSeriesCategory: "" });
              },
            },
          },
          dataLabels: {
            enabled: false,
            distance: -14,
            color: "white",
            style: {
              fontweight: "bold",
              fontFamily:
                '"Graphik", "Open Sans", "Helvetica Neue", Arial, sans-serif',
            },
          },
        },
      },
    };
  };

  _buildRows = () => {
    const tableRows = map(this.props.categoryPerformances, (category) => {
      const isActiveSeries =
        this.state.activeSeriesCategory === category.category;
      if (category.category === "Total") {
        return (
          <tr
            key={category.category}
            className={classnames({
              "is-selected": isActiveSeries,
            })}
          >
            <td className="category-total">{category.category}</td>
            <td className="category-total-subclass">
              {formatCurrency(category.marketValue)}
            </td>
            <td className="category-total-subclass">
              {formatCurrency(category.returns.returnAmount)}
            </td>
            <td className="category-total-subclass">
              {formatPercentage(category.returns.returnPercent / 100)}
            </td>
          </tr>
        );
      } else {
        return map(category.assetPerformances, (asset) => {
          return (
            <tr
              key={get(asset, "assetId.id")}
              className={classnames({
                "is-selected": isActiveSeries,
              })}
            >
              <td>
                <div className="asset">
                  <span
                    style={{
                      backgroundColor:
                        categoryToColorMapping[category.category],
                    }}
                    className="asset-box"
                  ></span>
                  <p className="asset-name"> {category.category}</p>
                </div>
                <div className="asset-symbol">
                  <p className="symbol">{asset.assetId.symbol}</p>
                </div>
              </td>
              <td>
                <div>{formatCurrency(asset.marketValue)}</div>
              </td>
              <td>{formatCurrency(asset.returns.returnAmount)}</td>
              <td>{formatPercentage(asset.returns.returnPercent / 100)}</td>
            </tr>
          );
        });
      }
    });
    return flatten(tableRows);
  };

  _buildChartData() {
    const categoriesWithoutTotal = filter(
      this.props.categoryPerformances,
      (category) => category.category !== "Total"
    );
    return [
      {
        colorByPoint: true,
        size: 275,
        data: map(categoriesWithoutTotal, (data) => {
          return {
            name: data.category,
            y: data.allocation,
            assetPerformances: data.assetPerformances,
            color: categoryToColorMapping[data.category],
          };
        }),
      },
    ];
  }

  _timeSlicedReturns() {
    let startTime;
    const endTime = moment.utc();

    //Default Statement for time sliced returns will be "CURRENT_DAY"

    switch (this.state.selectedTimePeriod) {
      case "ALL_TIME":
        startTime = moment.utc(this.props.createdAt);
        break;
      case "PAST_90_DAYS":
        startTime = moment.utc().subtract(90, "days");
        break;
      case "PAST_30_DAYS":
        startTime = moment.utc().subtract(30, "days");
        break;
      case "PAST_7_DAYS":
        startTime = moment.utc().subtract(7, "days");
        break;
      case "CURRENT_DAY":
        startTime = moment.utc().startOf("day");
        break;
      default:
        startTime = moment.utc(`${this.state.selectedTimePeriod}-01-01`);
    }
    return { startTime, endTime };
  }

  render() {
    const positionRows = this._buildRows();

    const { startTime, endTime } = this._timeSlicedReturns();

    if (isEmpty(positionRows)) return null;
    const updatedAt = moment(this.props.performanceUpdatedAt.endTime).format(
      "MMMM DD, YYYY h:mm A "
    );

    const isFetching = this.props.isFetching;
    return (
      <>
        {/* <div>
          <h2 className="current-portfolio">Investments</h2>
        </div> */}
        <Card className="dashboard-widget performance-table">
          {isFetching && <IconSpinner centered />}
          {!isFetching && (
            <>
              <div className="current-portfolio-header">
                <p className="user-portfolio">
                  Current Portfolio:{" "}
                  {get(
                    portfolioMetadataMap,
                    `${this.props.portfolioName}.portfolio`
                  )}
                </p>
                <div className="time-slice">
                  <span className="time-slice-dates">
                    <p className="dates">
                      {startTime.format("MMMM DD, YYYY")} -{" "}
                      {endTime.format("MMMM DD, YYYY")}
                    </p>
                  </span>

                  <span>
                    <Form.Control
                      className="time-slice-btn"
                      as="select"
                      id="inlineFormCustomSelect"
                      value={this.state.selectedTimePeriod}
                      onChange={(e) => {
                        this.setState(
                          { selectedTimePeriod: e.target.value },
                          () => this._getPerformance()
                        );
                      }}
                    >
                      {map(timeOptions, (opt) => (
                        <option value={opt.value} key={opt.value}>
                          {opt.label}
                        </option>
                      ))}
                    </Form.Control>
                  </span>
                </div>
              </div>

              <div className="preformance-content">
                <div className="pie-chart">
                  <PieChart
                    chartData={this._buildChartData()}
                    extendedConfig={this._getPortfolioChartConfig()}
                  />
                </div>

                <table className="positions-table">
                  <thead>
                    <tr>
                      <th>Holdings</th>
                      <th>Current Value</th>
                      <th colSpan="2">Total Change</th>
                    </tr>
                  </thead>
                  <tbody>{positionRows}</tbody>
                </table>
              </div>

              <div className="time-stamp">
                <span className="circle"></span>
                <p className="as-of">Data current as of {updatedAt}</p>
              </div>
            </>
          )}
        </Card>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  portfolioConstants.GET_USER_PORTFOLIO,
  portfolioConstants.GET_POSITIONS,
  portfolioConstants.GET_PERFORMANCE,
]);

const mapStateToProps = (state) => {
  const portfolioName = camelCase(
    get(state, "portfolios.activePortfolio.description")
  );
  return {
    positions: state.portfolios.positions,
    userPortfolio: state.portfolios.activePortfolio,
    positionsUpdatedAt: state.portfolios.positionsUpdatedAt,
    categoryPerformances: state.portfolios.categoryPerformances,
    performanceUpdatedAt: state.portfolios.performanceUpdatedAt,
    iraAccountId: getIraAccountIdSelector(state),
    isFetching: loadingSelector(state),
    createdAt: get(state.accounts, "iraAccounts[0].createdAt", {}),
    portfolioName,
  };
};

const mapDispatchToProps = {
  getUserPositions,
  getUserPortfolio,
  getUserPerformance,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(PerformanceTable));
