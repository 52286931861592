import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { isEmpty, get, find, filter, includes } from "lodash";
import { accountConstants, userConstants } from "actions/types";
import { scrollToTop } from "utils/dom";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { getUserState, initializeRollover } from "actions/userActions";
import { getAllAccounts, setAccountSession } from "actions/accountActions";
import {
  activeRolloverAccountSameAsCurrent,
  activeRolloverIsMixed,
} from "store/selectors/user";
import { accountTypesToEnglishMapping } from "statics/accountTypes";

import SelectBox from "./SelectBox";
import Button from "components/Button";
import Alert from "components/Alert";

const rolloverAccountTypes = [
  {
    label: "Traditional",
    key: "TRADITIONAL_IRA",
    description: "Pre-tax dollars",
  },
  {
    label: "Roth ",
    key: "ROTH_IRA",
    description: "Post-tax dollars",
  },
  {
    label: "Both",
    key: "MIXED",
    description: "Combination of both pre and post tax dollars",
  },
];

class IndividualRolloverAccountType extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    client: PropTypes.object,
    getUserState: PropTypes.func,
    getAllAccounts: PropTypes.func,
    initializeRollover: PropTypes.func,
    isSubmitting: PropTypes.bool,
    error: PropTypes.string,
    userState: PropTypes.string,
    setAccountSession: PropTypes.func,
    isRolloverSameAsCurrent: PropTypes.bool,
    updatedRolloverAccountTypes: PropTypes.array,
    processingActiveAccountType: PropTypes.string,
    processingAccount: PropTypes.bool,
    isMixedRollover: PropTypes.bool,
  };
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      selectedAccountType: "",
      submitted: false,
    };
  }

  componentDidMount() {
    window.analytics.page("Individual Rollover Account Type Selection");
    scrollToTop();
  }

  _submitAccountType = () => {
    const accountType = this.state.selectedAccountType;

    this.props
      .initializeRollover(this.props.client, accountType)
      .then(async (response) => {
        const data = response.data;
        const id = get(data, "initializeRollover.id");
        const error = get(data, "error");

        if (id && !error) {
          await this.props.setAccountSession(
            this.props.client,
            data.initializeRollover.id
          );
          window.analytics.track("Submitted Individual Rollover Account Type");
          window.location.reload();
          this.setState({
            submitted: true,
          });
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  _setSelectedAccountType = (selectedAccountType) => {
    this.setState({
      selectedAccountType,
    });
  };

  render() {
    var articleStyle = {
      paddingBottom: 10,
    };

    const accountHeader = this.props.isMixedRollover
      ? "You currently have two rollovers in progress."
      : "What kind of 401(k) plan do you have?";
    const accountSubtext = this.props.isMixedRollover
      ? "Please wait until these rollovers have fully processed before making another one."
      : "Please choose corresponding account type. It has to match your 401k.";

    return (
      <div id="account-selection">
        <div className="mega-container">
          <div className="step-container is-active" data-circle-percent="100">
            <section className="page-title-wrap">
              <article className="text-cell">
                <p className="page-title" style={{ paddingTop: 20 }}>
                  {accountHeader}
                </p>
                <p className="page-subtext">{accountSubtext}</p>
              </article>
            </section>
            <div>
              <section className="form-sec-2col">
                <article className="col-form" style={articleStyle}>
                  <div className="account-types">
                    {!this.props.isMixedRollover && (
                      <>
                        {this.props.updatedRolloverAccountTypes.map(
                          (account) => {
                            return (
                              <SelectBox
                                label={account.label}
                                description={account.description}
                                key={account.key}
                                items={account.items}
                                ordered={account.ordered}
                                displayChevron={true}
                                onSelect={() =>
                                  this._setSelectedAccountType(account.key)
                                }
                                isSelected={
                                  this.state.selectedAccountType === account.key
                                }
                              />
                            );
                          }
                        )}
                        {this.props.processingAccount && (
                          <p
                            style={{
                              fontStyle: "italic",
                              fontSize: 14,
                              paddingTop: 15,
                              paddingLeft: 4,
                              color: "#01a3b0",
                            }}
                          >
                            Your{" "}
                            {
                              accountTypesToEnglishMapping[
                                this.props.processingActiveAccountType
                              ]
                            }{" "}
                            rollover is currently in progress.
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </article>
              </section>
              <section className="form-sec-2col">
                <article className="col-form">
                  <div className="submit-row">
                    {this.props.error && (
                      <Alert type="error" msg={this.props.error} />
                    )}
                  </div>
                  <div className="submit-row btn-row">
                    <Button
                      btnLabel={"Cancel"}
                      color={"cancel"}
                      onClick={() => this.props.push("/dashboard/support")}
                    />

                    {!this.props.isMixedRollover && (
                      <Button
                        name="submit"
                        btnLabel="Select Account"
                        withArrow={true}
                        onClick={this._submitAccountType}
                        loading={this.props.isSubmitting}
                        disabled={isEmpty(this.state.selectedAccountType)}
                      />
                    )}
                  </div>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const actions = [
  userConstants.INITIALIZE_ROLLOVER,
  accountConstants.SET_ACCOUNT_SESSION,
];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;
  const accounts = state.accounts.iraAccounts;
  const processingAccount = find(accounts, { isProcessingRollover: true });
  const processingActiveAccountType = get(processingAccount, "accountType");
  const keysToExclude = processingActiveAccountType
    ? [processingActiveAccountType, "MIXED"]
    : [];

  const filteredAccountTypes = filter(
    rolloverAccountTypes,
    (account) => !includes(keysToExclude, account.key)
  );

  const updatedRolloverAccountTypes = filteredAccountTypes;

  return {
    processingAccount,
    processingActiveAccountType,
    updatedRolloverAccountTypes,
    isSubmitting: loadingSelector(state),
    error: errorSelector(state),
    userState,
    isMixedRollover: activeRolloverIsMixed(state),
    isRolloverSameAsCurrent: activeRolloverAccountSameAsCurrent(state),
  };
};

const mapDispatchToProps = {
  push,
  getUserState,
  initializeRollover,
  getAllAccounts,
  setAccountSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualRolloverAccountType));
