import React from "react";
import PropTypes from "prop-types";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import IconCircle from "components/IconCircle";
import Button from "components/Button";

const IconEmptyState = ({
  icon,
  header,
  subheader,
  actionText,
  onClick,
  isFetching,
}) => {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <div
        style={{
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <IconCircle type="primary" icon={icon} />
      </div>
      <div>
        <IconHeader variant="cardEmptyState" headerText={header} />
        <IconSubheader subheader={subheader} />
      </div>
      <section
        style={{
          paddingTop: 10,
          paddingBottom: 20,
        }}
      >
        {onClick && (
          <div className="step-btn">
            <Button
              size="sm"
              color="action"
              name="action"
              onClick={onClick}
              loading={isFetching}
            >
              {actionText}
            </Button>
          </div>
        )}
      </section>
    </div>
  );
};

IconEmptyState.propTypes = {
  icon: PropTypes.node,
  header: PropTypes.string,
  subheader: PropTypes.string,
  secondaryActionText: PropTypes.string,
  primaryActionText: PropTypes.string,
  onClick: PropTypes.func,
  isFetching: PropTypes.bool,
  hasButton: PropTypes.func,
  actionText: PropTypes.string,
};

export default IconEmptyState;
