import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, Col, Form, Row } from "react-bootstrap";
import { FieldArray, Formik } from "formik";
import { each, get, isEmpty, last, map, reduce, size, some } from "lodash";
import { FiEdit, FiUsers, FiMinus, FiPlus } from "react-icons/fi";
import { beneficiaryType } from "statics/propTypes";
import { createLoadingSelector } from "store/selectors";
import { accountConstants, beneficiaryConstants } from "actions/types";
import {
  address2Validator,
  postalCodeValidator,
  testSsn,
} from "utils/fieldValidators";
import { states } from "statics/countriesAndStates";
import { getAllAccounts } from "actions/accountActions";
import {
  getError,
  getValue,
  isInvalid,
  isValid,
  ScrollToFieldError,
} from "utils/form";
import { getIraAccountIdSelector } from "store/selectors/user";
import {
  deleteBeneficiaries,
  getBeneficiaries,
  updateBeneficiaries,
} from "actions/beneficiaryActions";

import InputMask from "react-input-mask";
import IconTable from "components/IconTable";
import IconEmptyState from "components/IconEmptyState";
import Alert from "components/Alert";
import Button from "components/Button";
import IconSpinner from "components/IconSpinner";
import IconTableHeader from "components/IconTableHeader";

import "./Beneficiaries.scss";

const relationshipOptions = [
  "Spouse",
  "Child",
  "Mother",
  "Father",
  "Sister",
  "Brother",
  "Grandfather",
  "Grandmother",
  "Non-relative",
];

const beneficiaryTypes = [
  { label: "Primary", value: "PRIMARY" },
  { label: "Contingent", value: "CONTINGENT" },
];

const blankBeneficiary = {
  familyName: "",
  givenName: "",
  city: "",
  country: "USA",
  postalCode: "",
  state: "",
  dateOfBirth: "",
  socialSecurityNumber: "",
  relationship: "",
  beneficiaryType: "",
  sharePercent: undefined,
  address1: "",
  address2: "",
};

let yup = require("yup");

function testDob() {
  const dob = moment(this.parent.dateOfBirth);
  const today = moment();
  const isBeforeToday = dob.startOf("day").isBefore(today.startOf("day"));
  const isLessThan100YearsOld = today.diff(dob, "years") < 100;

  return isBeforeToday && isLessThan100YearsOld;
}

function testSharePercent() {
  const allBeneficiaries = get(this.options, "from.1.value.beneficiaries", []);
  let beneficiaryHasZeroSharePercent = false;
  const hasContingent = some(allBeneficiaries, {
    beneficiaryType: "CONTINGENT",
  });

  const { contingentSharePercent, primarySharePercent } = reduce(
    allBeneficiaries,
    (acc, beneficiary) => {
      let type =
        beneficiary.beneficiaryType === "CONTINGENT"
          ? "contingentSharePercent"
          : "primarySharePercent";

      let sharePercent = beneficiary.sharePercent || 0;
      acc[type] += sharePercent;
      if (sharePercent === 0) {
        beneficiaryHasZeroSharePercent = true;
      }
      return acc;
    },
    { contingentSharePercent: 0, primarySharePercent: 0 }
  );

  const sharePercentsTotal100 = hasContingent
    ? contingentSharePercent + primarySharePercent === 200
    : primarySharePercent === 100;

  // should only pass if the sum of all beneficiaries is 100 and no beneficiary is 0 for share percent
  return !beneficiaryHasZeroSharePercent && sharePercentsTotal100;
}

const schema = yup.object().shape({
  beneficiaries: yup.array().of(
    yup.object().shape({
      givenName: yup
        .string()
        .label("First Name")
        .required()
        .min(1, "Must be at least one character.")
        .max(20),
      familyName: yup
        .string()
        .label("Last Name")
        .required()
        .min(1, "Must be at least one character.")
        .max(20),
      relationship: yup.string().label("Relationship").required(),
      beneficiaryType: yup.string().label("Beneficiary Type").required(),
      sharePercent: yup
        .number()
        .label("Share Percent")
        .test(
          "beneficiaries",
          "Share Percent must be greater than 0 and must total 100.",
          testSharePercent
        ),
      dateOfBirth: yup
        .date()
        .label("Date Of Birth")
        .required()
        .test(
          "dateOfBirth",
          "${path} must be before today and beneficiary cannot be over 100 years old.",
          testDob
        ),
      socialSecurityNumber: yup
        .string()
        .required()
        .label("Social Security Number")
        .test(
          "socialSecurityNumber",
          "${path} must be of format 111-11-1111",
          testSsn
        ),
      address1: yup
        .string()
        .label("Address 1")
        .required()
        .min(2, "Must be at least two characters.")
        .max(30),
      address2: address2Validator,
      city: yup.string().label("City").required().max(50),
      country: yup.string().label("Country").required().max(50),
      state: yup.string().label("State").required().max(14),
      postalCode: postalCodeValidator,
    })
  ),
});

class Beneficiaries extends React.PureComponent {
  static propTypes = {
    beneficiaries: PropTypes.arrayOf(beneficiaryType),
    isFetching: PropTypes.bool,
    isUpdating: PropTypes.bool,
    succesfullyUpdated: PropTypes.bool,
    successfullyDeleted: PropTypes.bool,
    client: PropTypes.object,
    error: PropTypes.string,
    iraAccountId: PropTypes.string,
    getBeneficiaries: PropTypes.func,
    updateBeneficiaries: PropTypes.func,
    deleteBeneficiaries: PropTypes.func,
    getAllAccounts: PropTypes.func,
  };
  _promises = [];

  constructor(props) {
    super(props);

    this.state = {
      beneficiaries: props.beneficiaries,
      isEditMode: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (isEmpty(prevState.beneficiaries) && !isEmpty(nextProps.beneficiaries)) {
      return {
        beneficiaries: nextProps.beneficiaries,
        isEditMode: false,
      };
    }
    return {};
  }

  componentDidMount() {
    const acctPromise = this.props
      .getAllAccounts(this.props.client)
      .then(() => {
        const beneficiaryPromise = this.props.getBeneficiaries(
          this.props.client,
          this.props.iraAccountId
        );
        this._promises.push(beneficiaryPromise);
      });

    this._promises.push(acctPromise);
  }

  componentWillUnmount() {
    each(this._promises, (promise) => promise.cancel());
  }

  _deleteBeneficiaries = () => {
    this._promises.push(
      this.props.deleteBeneficiaries(this.props.client, this.props.iraAccountId)
    );
  };

  _onSubmit = (values, { resetForm }) => {
    // submit beneficiary form

    const beneficiaries = map(values.beneficiaries, (beneficiary) => {
      const { address1, address2, ...restOfProps } = beneficiary;

      return {
        ...restOfProps,
        streetAddress: [address1, address2],
      };
    });

    this._promises.push(
      this.props
        .updateBeneficiaries(this.props.client, {
          beneficiaries,
          accountId: this.props.iraAccountId,
        })
        .then(() => {
          this.setState({
            isEditMode: false,
            beneficiaries: values.beneficiaries,
          });
          resetForm({});
        })
    );
  };

  _buildBeneficiaryRows = () => {
    return map(this.props.beneficiaries, (blankBeneficiary) => {
      return (
        <tr key={blankBeneficiary.id}>
          <td>
            {blankBeneficiary.givenName} {blankBeneficiary.familyName}
          </td>
          <td>{blankBeneficiary.beneficiaryType}</td>
          <td>{blankBeneficiary.sharePercent}%</td>
        </tr>
      );
    });
  };

  _buildReviewExistingColumns = () => {
    const columns = [
      {
        label: "Recipient",
        customComponent: (props) => {
          return (
            <div>
              {props.givenName} {props.familyName}
            </div>
          );
        },
      },

      {
        label: "Beneficiary Type",
        key: "beneficiaryType",
      },

      {
        label: "Share Percent",
        customComponent: (props) => {
          return <div>{props.sharePercent}%</div>;
        },
      },
    ];
    return columns;
  };

  render() {
    const columns = this._buildReviewExistingColumns();
    const data = this.props.beneficiaries;

    return (
      <div className="mega-container dashboard-content" id="beneficiaries">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Beneficiaries</h1>
            <p className="page-subtext">Manage your account beneficiaries</p>
            <div className="main-content">
              {this.props.isFetching && <IconSpinner centered />}
              {!this.props.isFetching && !this.state.isEditMode && (
                <>
                  <div className="beneficiary-view-card">
                    <Card>
                      <div className="beneficiaries-widget">
                        <IconTableHeader
                          tableHeader="Beneficiaries"
                          tableCount={size(this.props.beneficiaries)}
                        />
                        <FiEdit
                          className="edit"
                          size={"24px"}
                          color={"#FFFFFF"}
                          fill={"#FFFFFF"}
                          stroke={"#6b7c93"}
                          strokeWidth={"1.5"}
                          btnLabel="Edit"
                          onClick={() => {
                            this.setState({ isEditMode: true });
                          }}
                        />
                      </div>

                      <IconTable columns={columns} data={data} />
                    </Card>
                  </div>
                </>
              )}
              {!this.props.isFetching && this.state.isEditMode && (
                <Row>
                  <Col>
                    <Card className="beneficiary-form-card">
                      <Card.Body>
                        <Formik
                          validateOnChange={false}
                          validationSchema={schema}
                          onSubmit={this._onSubmit}
                          enableReinitialize={true}
                          initialValues={this.state}
                        >
                          {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            setFieldValue,
                            errors,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                              <ScrollToFieldError />
                              {isEmpty(values.beneficiaries) &&
                                isEmpty(this.props.beneficiaries) && (
                                  <>
                                    <IconEmptyState
                                      header={
                                        "Would you like to add a beneficiary to your account?"
                                      }
                                      icon={
                                        <FiUsers
                                          color="white"
                                          stroke="#60A4BF"
                                          size={16}
                                        />
                                      }
                                    />
                                  </>
                                )}
                              {isEmpty(values.beneficiaries) &&
                                !isEmpty(this.props.beneficiaries) && (
                                  <Card.Title>
                                    Press confirm deletion to confirm deletion
                                    of all beneficiaries.
                                  </Card.Title>
                                )}
                              <FieldArray
                                name="beneficiaries"
                                render={(arrayHelpers) => (
                                  <div>
                                    {values.beneficiaries &&
                                    values.beneficiaries.length > 0 ? (
                                      values.beneficiaries.map(
                                        (beneficiary, index) => (
                                          <div
                                            className="form-beneficiary"
                                            key={index}
                                          >
                                            <h4 className="beneficiary-index">
                                              Beneficiary #{index + 1}
                                            </h4>
                                            <section>
                                              <article
                                                className="col-form"
                                                style={{ paddingBottom: 0 }}
                                              >
                                                <h4 className="page-title">
                                                  Information
                                                </h4>
                                                <Form.Row>
                                                  <Form.Group
                                                    as={Col}
                                                    md={6}
                                                    controlId={`formBasicbeneficiaries${index}GivenName`}
                                                  >
                                                    <Form.Label>
                                                      First Name
                                                    </Form.Label>
                                                    <Form.Control
                                                      name={`beneficiaries.${index}.givenName`}
                                                      placeholder="First Name"
                                                      value={getValue(
                                                        values,
                                                        `beneficiaries.${index}.givenName`
                                                      )}
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      isInvalid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.givenName`
                                                      )}
                                                      isValid={isValid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.givenName`
                                                      )}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                      {getError(
                                                        errors,
                                                        `beneficiaries.${index}.givenName`
                                                      )}
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                  <Form.Group
                                                    as={Col}
                                                    md={6}
                                                    controlId={`formBasicbeneficiaries${index}FamilyName`}
                                                  >
                                                    <Form.Label>
                                                      Last Name
                                                    </Form.Label>
                                                    <Form.Control
                                                      name={`beneficiaries.${index}.familyName`}
                                                      placeholder="Last Name"
                                                      value={getValue(
                                                        values,
                                                        `beneficiaries.${index}.familyName`
                                                      )}
                                                      onChange={handleChange}
                                                      isInvalid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.familyName`
                                                      )}
                                                      isValid={isValid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.familyName`
                                                      )}
                                                      onBlur={handleBlur}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                      {getError(
                                                        errors,
                                                        `beneficiaries.${index}.familyName`
                                                      )}
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                  <Form.Group
                                                    as={Col}
                                                    controlId={`formBasicbeneficiaries${index}DateOfBirth`}
                                                  >
                                                    <Form.Label>
                                                      Date of Birth
                                                    </Form.Label>
                                                    <InputMask
                                                      name={`beneficiaries.${index}.dateOfBirth`}
                                                      placeholder="MM/DD/YYYY"
                                                      mask="99/99/9999"
                                                      value={getValue(
                                                        values,
                                                        `beneficiaries.${index}.dateOfBirth`
                                                      )}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.dateOfBirth`
                                                      )}
                                                      isValid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.dateOfBirth`
                                                      )}
                                                    >
                                                      {(props) => (
                                                        <Form.Control
                                                          {...props}
                                                        />
                                                      )}
                                                    </InputMask>
                                                    <Form.Control.Feedback type="invalid">
                                                      {getError(
                                                        errors,
                                                        `beneficiaries.${index}.dateOfBirth`
                                                      )}
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                  <Form.Group
                                                    as={Col}
                                                    controlId={`formBasicbeneficiaries${index}SsnField`}
                                                  >
                                                    <Form.Label>
                                                      Social Security Number
                                                    </Form.Label>
                                                    <InputMask
                                                      name={`beneficiaries.${index}.socialSecurityNumber`}
                                                      mask="999-99-9999"
                                                      placeholder="111-11-1111"
                                                      value={getValue(
                                                        values,
                                                        `beneficiaries.${index}.socialSecurityNumber`
                                                      )}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.socialSecurityNumber`
                                                      )}
                                                      isValid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.socialSecurityNumber`
                                                      )}
                                                    >
                                                      {(props) => (
                                                        <Form.Control
                                                          {...props}
                                                        />
                                                      )}
                                                    </InputMask>
                                                    <Form.Control.Feedback type="invalid">
                                                      {getError(
                                                        errors,
                                                        `beneficiaries.${index}.socialSecurityNumber`
                                                      )}
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                  <Form.Group
                                                    as={Col}
                                                    md={4}
                                                    controlId={`formBasicbeneficiaries${index}Relationship`}
                                                  >
                                                    <Form.Label>
                                                      Relationship
                                                    </Form.Label>
                                                    <Form.Control
                                                      as="select"
                                                      name={`beneficiaries.${index}.relationship`}
                                                      value={getValue(
                                                        values,
                                                        `beneficiaries.${index}.relationship`
                                                      )}
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      isInvalid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.relationship`
                                                      )}
                                                      isValid={isValid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.relationship`
                                                      )}
                                                    >
                                                      <option value="" disabled>
                                                        Select Relationship
                                                      </option>
                                                      {map(
                                                        relationshipOptions,
                                                        (option) => (
                                                          <option
                                                            key={option}
                                                            value={option}
                                                          >
                                                            {option}
                                                          </option>
                                                        )
                                                      )}
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                      {getError(
                                                        errors,
                                                        `beneficiaries.${index}.relationship`
                                                      )}
                                                    </Form.Control.Feedback>
                                                  </Form.Group>

                                                  <Form.Group
                                                    as={Col}
                                                    md={4}
                                                    controlId={`formBasicbeneficiaries${index}BeneficiaryType`}
                                                  >
                                                    <Form.Label>
                                                      Beneficiary Type
                                                    </Form.Label>
                                                    <Form.Control
                                                      as="select"
                                                      name={`beneficiaries.${index}.beneficiaryType`}
                                                      value={getValue(
                                                        values,
                                                        `beneficiaries.${index}.beneficiaryType`
                                                      )}
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      isInvalid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.beneficiaryType`
                                                      )}
                                                      isValid={isValid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.beneficiaryType`
                                                      )}
                                                    >
                                                      <option value="" disabled>
                                                        Select Beneficiary Type
                                                      </option>
                                                      {map(
                                                        beneficiaryTypes,
                                                        (type) => (
                                                          <option
                                                            key={type.value}
                                                            value={type.value}
                                                          >
                                                            {type.label}
                                                          </option>
                                                        )
                                                      )}
                                                    </Form.Control>

                                                    <Form.Control.Feedback type="invalid">
                                                      {getError(
                                                        errors,
                                                        `beneficiaries.${index}.beneficiaryType`
                                                      )}
                                                    </Form.Control.Feedback>
                                                  </Form.Group>

                                                  <Form.Group
                                                    as={Col}
                                                    md={4}
                                                    controlId={`formBasicbeneficiaries${index}SharePercent`}
                                                  >
                                                    <Form.Label>
                                                      Share Percent
                                                    </Form.Label>
                                                    <Form.Control
                                                      name={`beneficiaries.${index}.sharePercent`}
                                                      placeholder="Share Percent"
                                                      value={getValue(
                                                        values,
                                                        `beneficiaries.${index}.sharePercent`
                                                      )}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.sharePercent`
                                                      )}
                                                      isValid={isValid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.sharePercent`
                                                      )}
                                                      type="number"
                                                      max={100}
                                                      min={0}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                      {getError(
                                                        errors,
                                                        `beneficiaries.${index}.sharePercent`
                                                      )}
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                </Form.Row>

                                                <h4 className="page-title">
                                                  Address
                                                </h4>
                                                <Form.Row>
                                                  <Form.Group
                                                    as={Col}
                                                    md={12}
                                                    controlId={`formBasicbeneficiaries${index}Adress1`}
                                                  >
                                                    <Form.Control
                                                      name={`beneficiaries.${index}.address1`}
                                                      placeholder="Address 1"
                                                      value={getValue(
                                                        values,
                                                        `beneficiaries.${index}.address1`
                                                      )}
                                                      onChange={handleChange}
                                                      isInvalid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.address1`
                                                      )}
                                                      isValid={isValid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.address1`
                                                      )}
                                                      onBlur={handleBlur}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                      {getError(
                                                        errors,
                                                        `beneficiaries.${index}.address1`
                                                      )}
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                  <Form.Group
                                                    as={Col}
                                                    md={12}
                                                    controlId={`formBasicbeneficiaries${index}Adress2`}
                                                  >
                                                    <Form.Control
                                                      name={`beneficiaries.${index}.address2`}
                                                      placeholder="Address 2"
                                                      value={getValue(
                                                        values,
                                                        `beneficiaries.${index}.address2`
                                                      )}
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      isInvalid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.address2`
                                                      )}
                                                      isValid={isValid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.address2`
                                                      )}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                      {getError(
                                                        errors,
                                                        `beneficiaries.${index}.address2`
                                                      )}
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                  <Form.Group
                                                    as={Col}
                                                    md={4}
                                                    controlId={`formBasicbeneficiaries${index}City`}
                                                  >
                                                    <Form.Control
                                                      name={`beneficiaries.${index}.city`}
                                                      placeholder="City"
                                                      value={getValue(
                                                        values,
                                                        `beneficiaries.${index}.city`
                                                      )}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.city`
                                                      )}
                                                      isValid={isValid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.city`
                                                      )}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                      {getError(
                                                        errors,
                                                        `beneficiaries.${index}.city`
                                                      )}
                                                    </Form.Control.Feedback>
                                                  </Form.Group>

                                                  <Form.Group
                                                    as={Col}
                                                    md={4}
                                                    controlId={`formBasicbeneficiaries${index}State`}
                                                  >
                                                    <Form.Control
                                                      as="select"
                                                      name={`beneficiaries.${index}.state`}
                                                      value={getValue(
                                                        values,
                                                        `beneficiaries.${index}.state`
                                                      )}
                                                      onBlur={handleBlur}
                                                      onChange={handleChange}
                                                      isInvalid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.state`
                                                      )}
                                                      isValid={isValid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.state`
                                                      )}
                                                    >
                                                      <option value="" disabled>
                                                        Select State
                                                      </option>
                                                      {map(
                                                        states,
                                                        (
                                                          stateLabel,
                                                          stateAbbrv
                                                        ) => (
                                                          <option
                                                            key={stateLabel}
                                                            value={stateAbbrv}
                                                          >
                                                            {stateLabel}
                                                          </option>
                                                        )
                                                      )}
                                                    </Form.Control>

                                                    <Form.Control.Feedback type="invalid">
                                                      {getError(
                                                        errors,
                                                        `beneficiaries.${index}.state`
                                                      )}
                                                    </Form.Control.Feedback>
                                                  </Form.Group>

                                                  <Form.Group
                                                    as={Col}
                                                    md={4}
                                                    controlId={`formBasicbeneficiaries${index}PostalCode`}
                                                  >
                                                    <Form.Control
                                                      name={`beneficiaries.${index}.postalCode`}
                                                      placeholder="Zip"
                                                      value={getValue(
                                                        values,
                                                        `beneficiaries.${index}.postalCode`
                                                      )}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={isInvalid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.postalCode`
                                                      )}
                                                      isValid={isValid(
                                                        touched,
                                                        errors,
                                                        `beneficiaries.${index}.postalCode`
                                                      )}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                      {getError(
                                                        errors,
                                                        `beneficiaries.${index}.postalCode`
                                                      )}
                                                    </Form.Control.Feedback>
                                                  </Form.Group>
                                                </Form.Row>
                                              </article>
                                            </section>
                                            <div className="plus-minus-btns">
                                              <Button
                                                btnLabel=""
                                                size="sm"
                                                name="action"
                                                type="button"
                                                color="red"
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                }
                                                icon={{
                                                  icon: (
                                                    <FiMinus
                                                      size={14}
                                                      stroke={"Gray"}
                                                    />
                                                  ),
                                                  position: "left",
                                                }}
                                              />
                                              <Button
                                                btnLabel=""
                                                name="action"
                                                icon={{
                                                  icon: (
                                                    <FiPlus
                                                      size={14}
                                                      stroke={"#FFFFFF"}
                                                    />
                                                  ),
                                                  position: "left",
                                                }}
                                                size="sm"
                                                type="button"
                                                onClick={() =>
                                                  arrayHelpers.push({
                                                    ...blankBeneficiary,
                                                  })
                                                }
                                              />
                                            </div>
                                            {last(values.beneficiaries) ===
                                              beneficiary && (
                                              <div className="submit-row">
                                                {this.props.error && (
                                                  <Alert
                                                    type="error"
                                                    msg={this.props.error}
                                                  />
                                                )}
                                                <div className="buttons">
                                                  <Button
                                                    size="sm"
                                                    className="cancel-button"
                                                    type="button"
                                                    name="cancel"
                                                    color="cancel"
                                                    btnLabel="Cancel"
                                                    onClick={() => {
                                                      isEmpty(
                                                        this.props.beneficiaries
                                                      )
                                                        ? setFieldValue(
                                                            "beneficiaries",
                                                            []
                                                          )
                                                        : this.setState({
                                                            isEditMode: false,
                                                          });
                                                    }}
                                                  />
                                                  {this.state.isEditMode &&
                                                    !this.props.isFetching && (
                                                      <div className="save-button">
                                                        <Button
                                                          size="sm"
                                                          name="submit"
                                                          withArrow={true}
                                                          btnLabel="Update Beneficiaries"
                                                          loading={
                                                            this.props
                                                              .isUpdating
                                                          }
                                                        />
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <div>
                                        {this.props.error && (
                                          <Alert
                                            type="error"
                                            msg={this.props.error}
                                          />
                                        )}
                                        {this.props.successfullyDeleted && (
                                          <Alert
                                            type="success"
                                            msg="Your beneficiaries were successfully deleted."
                                          />
                                        )}

                                        <div className="buttons">
                                          <div style={{ paddingRight: 5 }}>
                                            <Button
                                              size="sm"
                                              name="action"
                                              btnLabel="Add a beneficiary"
                                              type="button"
                                              onClick={() =>
                                                arrayHelpers.push({
                                                  ...blankBeneficiary,
                                                })
                                              }
                                            />
                                          </div>
                                          {!isEmpty(
                                            this.props.beneficiaries
                                          ) && (
                                            <Button
                                              withArrow={true}
                                              size="sm"
                                              color="red"
                                              name="action"
                                              btnLabel="Confirm Deletion"
                                              onClick={
                                                this._deleteBeneficiaries
                                              }
                                              type="button"
                                              loading={this.props.isUpdating}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              />
                            </Form>
                          )}
                        </Formik>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const isFetchingSelector = createLoadingSelector([
  beneficiaryConstants.GET_BENEFICIARIES,
  accountConstants.GET_ACCOUNTS,
]);

const isUpdatingSelector = createLoadingSelector([
  beneficiaryConstants.UPDATE_BENEFICIARIES,
  beneficiaryConstants.DELETE_BENEFICIARIES,
]);

const mapStateToProps = (state) => {
  return {
    isFetching: isFetchingSelector(state),
    isUpdating: isUpdatingSelector(state),
    beneficiaries: state.beneficiary.beneficiaries,
    succesfullyUpdated: state.beneficiary.succesfullyUpdated,
    successfullyDeleted: state.beneficiary.successfullyDeleted,
    iraAccountId: getIraAccountIdSelector(state),
  };
};

const mapdispatchToProps = {
  getBeneficiaries,
  getAllAccounts,
  updateBeneficiaries,
  deleteBeneficiaries,
};

export default connect(
  mapStateToProps,
  mapdispatchToProps
)(withApollo(Beneficiaries));
