import gql from "graphql-tag";

function _getErrorRequestContext(xhr) {
  return {
    response: xhr.responseText,
    status: xhr.status,
    statusText: xhr.statusText,
    readyState: xhr.readyState,
  };
}

function scrubFilename(filename) {
  return filename.replace(/[^\w\d_\-.]+/gi, "");
}

async function uploadFile(fileObject, callback) {
  var successResponses = [200, 201];

  var req = new XMLHttpRequest();
  req.open("PUT", fileObject.signedRequest);

  if (!req) {
    callback.onError("CORS not supported", fileObject.file);
  } else {
    req.onload = function () {
      if (successResponses.indexOf(req.status) >= 0) {
        this.onProgress(100, "Complete", fileObject.file);
        return this.onFinish(fileObject.file);
      } else {
        return this.onError(
          "Upload error: " + req.status,
          fileObject.file,
          _getErrorRequestContext(req)
        );
      }
    }.bind(callback);

    req.onerror = function () {
      return this.onError(
        "Request error",
        fileObject.file,
        _getErrorRequestContext(req)
      );
    }.bind(callback);

    req.upload.onprogress = function (e) {
      var percentLoaded;
      if (e.lengthComputable) {
        percentLoaded = Math.round((e.loaded / e.total) * 100);
        return this.onProgress(
          percentLoaded,
          percentLoaded === 100 ? "Finalizing" : "Uploading",
          fileObject.file
        );
      } else {
        console.log(e.loaded);
      }
    }.bind(callback);
  }

  var disposition = "attachment";
  var fileName = scrubFilename(fileObject.file.name);

  req.setRequestHeader(
    "Content-Disposition",
    disposition + '; filename="' + fileName + '"'
  );
  req.setRequestHeader("Content-Type", fileObject.file.type);
  req.setRequestHeader("x-amz-server-side-encryption", "aws:kms");

  this.httprequest = req;
  return req.send(fileObject.file);
}

// get a signed url for uploading a document to s3
function getUploadUrl(client, fileName, fileType) {
  return new Promise((resolve, reject) => {
    const UPLOAD_URL = gql`
      query UploadUrl($fileName: String!, $fileType: String!) {
        uploadUrl(fileName: $fileName, fileType: $fileType) {
          signedRequest
          url
        }
      }
    `;
    const result = client.query({
      query: UPLOAD_URL,
      variables: { fileName, fileType },
    });
    result.then(
      (data) => {
        resolve(data.data.uploadUrl);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function uploadDocument(client, url, fileName, fileType, contentType) {
  return new Promise((resolve, reject) => {
    const UPLOAD_DOCUMENT = gql`
      mutation UploadDocument(
        $url: String!
        $fileName: String!
        $fileType: String!
        $contentType: String!
      ) {
        uploadDocument(
          url: $url
          fileName: $fileName
          fileType: $fileType
          contentType: $contentType
        ) {
          id
          error
        }
      }
    `;
    const result = client.mutate({
      mutation: UPLOAD_DOCUMENT,
      variables: {
        url,
        fileName,
        fileType,
        contentType,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.uploadDocument);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export const fileService = {
  getUploadUrl,
  uploadFile,
  uploadDocument,
};
