import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, Form, Col } from "react-bootstrap";
import {
  debounce,
  get,
  isEmpty,
  keyBy,
  map,
  transform,
  some,
  find,
  isNil,
} from "lodash";
import { FaUndo } from "react-icons/fa";
import { push } from "connected-react-router";
import { getPaginatedEmployees } from "actions/employerActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { formatCurrency } from "utils/number";
import { FiSearch } from "react-icons/fi";

import IconEmptyState from "components/IconEmptyState";
import Button from "components/Button";
import IconSpinner from "components/IconSpinner";
import StatusBox from "components/StatusBox";
import Paginator from "components/Paginator";
import IconTable from "components/IconTable";

export class ReviewExisting extends React.PureComponent {
  static propTypes = {
    goToNamedStep: PropTypes.func,
    getPaginatedEmployees: PropTypes.func,
    push: PropTypes.func,
    setSkipped: PropTypes.func,
    setRemoved: PropTypes.func,
    isFetching: PropTypes.bool,
    client: PropTypes.object,
    skippedContributions: PropTypes.object,
    removedEmployees: PropTypes.object,
    group: PropTypes.object.isRequired,
    employeeCount: PropTypes.number,
    employees: PropTypes.array,
    payroll: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      limit: 25,
      offset: 0,
      search: "",
      checkedRows: {},
      initialFetching: true,
      page: 0,
      group: {},
      payroll: {},
    };
  }

  componentDidMount() {
    this._getPaginatedEmployees().then(() => {
      this.setState({ initialFetching: false });
    });
  }

  // _getPaginatedEmployees = () => {
  //   const groupId = this.props.group?.id;
  //   if (!groupId) {
  //     console.error("Group ID is missing");
  //     return Promise.resolve([]); // Ensure it returns a resolved Promise with an empty array
  //   }
  //   return this.props.getPaginatedEmployees(this.props.client, {
  //     groupId,
  //     limit: this.state.limit,
  //     offset: this.state.offset,
  //     search: this.state.search,
  //   });
  // };

  _getPaginatedEmployees = () => {
    return this.props.getPaginatedEmployees(this.props.client, {
      groupId: this.props.group.id,
      limit: this.state.limit,
      offset: this.state.offset,
      search: this.state.search,
    });
  };

  _removeFromSkipped = (employeeId) => {
    const newSkipped = { ...this.props.skippedContributions };
    delete newSkipped[employeeId];
    this.props.setSkipped(newSkipped);
  };
  _removeFromRemoved = (employeeId) => {
    const newRemoved = { ...this.props.removedEmployees };
    delete newRemoved[employeeId];
    this.props.setRemoved(newRemoved);
  };

  _getEmployeeStatus = (employeeId, contribution = {}) => {
    const statusAndUndo = {
      status: null,
      undoButton: null,
    };

    const changeRequests = get(contribution, "contributionChangeRequests", []);
    const hasZeroContribution = contribution.amount === 0;

    const hasPendingChange = some(
      changeRequests,
      (c) => c.status === "CHANGED"
    );

    if (this.props.skippedContributions[employeeId]) {
      statusAndUndo.status = <StatusBox status="Skipped" type="dark" />;
      statusAndUndo.undoButton = (
        <FaUndo size={16} onClick={() => this._removeFromSkipped(employeeId)} />
      );
    } else if (this.props.removedEmployees[employeeId]) {
      statusAndUndo.status = <StatusBox status="Removed" type="danger" />;
      statusAndUndo.undoButton = (
        <FaUndo size={16} onClick={() => this._removeFromRemoved(employeeId)} />
      );
    } else if (hasPendingChange) {
      statusAndUndo.status = (
        <StatusBox status="Has Pending Change" type="pending" />
      );
    } else if (hasZeroContribution) {
      statusAndUndo.status = (
        <StatusBox status="Paused Contributions" type="light" />
      );
    } else {
      statusAndUndo.status = <StatusBox status="Active" type="success" />;
    }

    return statusAndUndo;
  };

  _setChecked = (employee, isChecked) => {
    let newChecked = { ...this.state.checkedRows };
    if (!isChecked) {
      delete newChecked[employee.employeeId];
      this.setState({ checkedRows: newChecked });
      return;
    }
    this.setState({
      checkedRows: {
        ...this.state.checkedRows,
        [employee.employeeId]: employee,
      },
    });
  };

  _getReviewExisitingData = () => {
    return map(this.props.employees, (emp) => {
      const {
        userProfile: { firstName, lastName, lastFourOfSsn },
      } = emp;
      const { status, undoButton } = this._getEmployeeStatus(
        emp.employeeId,
        emp.contribution
      );
      const lastApprovedAmount = find(
        get(emp, "contribution.contributionChangeRequests", []),
        (c) => c.status === "APPROVED"
      );
      const contributionAmount = !isNil(lastApprovedAmount)
        ? formatCurrency(lastApprovedAmount.userNewContributionAmount)
        : formatCurrency(emp.contribution.amount);

      const fullName = `${firstName} ${lastName}`;

      return {
        employeeName: fullName,
        lastFourOfSsn,
        contributionAmount,
        status,
        undoButton,
        emp,
      };
    });
  };

  _buildReviewExistingColumns = () => {
    const columns = [
      {
        label: "",
        customComponent: (props) => {
          const { undoButton } = this._getEmployeeStatus(props.emp.employeeId);
          return (
            <div style={{ width: 25, textAlign: "center", paddingLeft: "8px" }}>
              {!undoButton && (
                <Form.Check
                  type="checkbox"
                  name="selectAllPending"
                  checked={this.state.checkedRows[props.emp.employeeId]}
                  onChange={(e) =>
                    this._setChecked(props.emp, e.target.checked)
                  }
                />
              )}
            </div>
          );
        },
      },
      {
        label: "Employee Name",
        key: "employeeName",
      },

      {
        label: "Last 4 SSN",
        key: "lastFourOfSsn",
      },

      {
        label: "Current Amount",
        key: "contributionAmount",
      },
      {
        label: "Status",
        key: "status",
      },
      {
        customComponent: (props) => {
          const { undoButton } = this._getEmployeeStatus(props.emp.employeeId);
          return <div className="undo-button">{undoButton}</div>;
        },
      },
    ];
    return columns;
  };

  _addToSkipped = () => {
    const newSkipped = {
      ...this.props.skippedContributions,
      ...this.state.checkedRows,
    };
    this.props.setSkipped(newSkipped);
    this.setState({ checkedRows: {} });
  };
  _addToRemoved = () => {
    const newRemoved = {
      ...this.props.removedEmployees,
      ...this.state.checkedRows,
    };
    this.props.setRemoved(newRemoved);
    this.setState({ checkedRows: {} });
  };

  _onSearchChange = (e) => {
    this.setState(
      {
        search: e.target.value,
        offset: 0,
      },
      () =>
        this._getPaginatedEmployees().then(() => {
          const empsById = keyBy(this.props.employees, "id");
          const newChecked = transform(
            this.state.checkedRows,
            (acc, val, key) => {
              if (empsById[key]) {
                acc[key] = true;
              }
              return acc;
            },
            {}
          );
          this.setState({ checkedRows: newChecked, page: 0 });
        })
    );
  };

  _debounceOnChange = debounce(this._onSearchChange, 500);

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () =>
      this._getPaginatedEmployees()
    );
  };

  _clearSearch = () => {
    this._textInput.value = "";
    this.setState(
      {
        search: "",
        offset: 0,
      },
      () => this._getPaginatedEmployees()
    );
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }
    const showBlankSlate = isEmpty(this.props.employees);

    const columns = this._buildReviewExistingColumns();
    const data = this._getReviewExisitingData();

    return (
      <div>
        <Card>
          <div className="workflow-widget">
            <div className="action-header">
              <p className="group-info">
                <span className="group-name">
                  {this.props.group.name}:{" "}
                  <span
                    className="invite-number"
                    data-testid="contributions-being-processed"
                  >
                    Contributions being processed (
                    <span className="number">{this.props.employeeCount}</span>)
                  </span>
                </span>
              </p>

              <div className="btn-row">
                <Button
                  onClick={this._addToRemoved}
                  name="action"
                  color="red"
                  btnLabel="Remove from Plan"
                  size="sm"
                  disabled={isEmpty(this.state.checkedRows)}
                />
                <Button
                  onClick={this._addToSkipped}
                  color="secondary"
                  name="action"
                  size="sm"
                  btnLabel="Skip Deduction"
                  disabled={isEmpty(this.state.checkedRows)}
                />
              </div>
            </div>
            <div style={{ paddingBottom: 5 }}>
              <Col as={Col} md={3}>
                <Form.Control
                  name="search"
                  placeholder="Search Employee"
                  data-testid="search-employee"
                  onChange={this._debounceOnChange}
                  ref={(node) => (this._textInput = node)}
                />
              </Col>
            </div>
          </div>

          {this.props.isFetching && <IconSpinner centered />}
          {showBlankSlate && (
            <IconEmptyState
              header={"There were no employees found matching your search."}
              subheader={"Would you like to clear your search?"}
              icon={<FiSearch color="white" stroke="#60A4BF" size={16} />}
              actionText={"Clear Search"}
              onClick={this._clearSearch}
            />
          )}
          {!showBlankSlate && (
            <>
              <IconTable columns={columns} data={data} />
              <div className="pagination-row">
                <Paginator
                  onChange={this._onPageChange}
                  pageCount={this.props.employeeCount / this.state.limit}
                  page={this.state.page}
                />
              </div>
            </>
          )}
        </Card>
        <div className="process-payroll-button-row">
          <Button
            onClick={() => this.props.push("/dashboard/contributions")}
            btnLabel="Cancel"
            name="cancel"
            color="cancel"
          />
          <Button
            name="action"
            onClick={() => this.props.goToNamedStep("approvals")}
          />
        </div>
      </div>
    );
  }
}

const isLoadingSelector = createLoadingSelector(
  employerConstants.GET_PAGINATED_EMPLOYEES
);
const isErrorSelector = createErrorSelector(
  employerConstants.GET_PAGINATED_EMPLOYEES
);

const mapStateToProps = (state) => {
  return {
    isFetching: isLoadingSelector(state),
    employees: state.employer.paginatedEmployees,
    employeeCount: state.employer.employeeCount,
    error: isErrorSelector(state),
  };
};

const mapDispatchToProps = {
  getPaginatedEmployees,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ReviewExisting));
