import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { FiX } from "react-icons/fi";

import Button from "components/Button";

export default class SourceActions extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        onClick: PropTypes.func,
        label: PropTypes.string,
        onSubmit: PropTypes.func,
      })
    ),
    menuTitle: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  render() {
    return (
      <div className="source-actions">
        <Button
          btnClass="actions-menu-btn"
          size="sm"
          color="action"
          name="action"
          onClick={() => this.setState({ showMenu: true })}
        >
          Manage
        </Button>

        {this.state.showMenu && (
          <ul className="source-actions-menu">
            <h4 className="source-menu-title">{this.props.menuTitle}</h4>
            <span
              className="close-icon"
              onClick={() => this.setState({ showMenu: false })}
            >
              <FiX
                size="16px"
                color="#FFFFFF"
                fill={"#FFFFFF"}
                stroke={"#a8adbb"}
                strokeWidth={"1"}
              />
            </span>
            {map(this.props.actions, (action, index) => (
              <li
                key={index}
                onClick={() => {
                  this.setState({ showMenu: false });
                  action.onClick();
                }}
                className="source-action-menu-item"
              >
                <span className="action-menu-icon" key={action.key}>
                  {action.icon}
                </span>
                {action.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
