import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { toast } from "react-toastify";
import { Card, Col, Row } from "react-bootstrap";
import { isProduction } from "utils/determineSite";
import { employerConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import Alert from "components/Alert";
import {
  FiArrowLeft,
  FiArrowRight,
  FiCopy,
  FiDownload,
  FiFile,
  FiMail,
} from "react-icons/fi";
import { updateProgressiveOnboarding } from "actions/employerActions";

import SimpleModal from "components/SimpleModal";
import Button from "components/Button";

import "./Announcement.scss";
import { hasEnabledPayrollIntegration } from "store/selectors/employer";
import { progressiveOnboardingEvents } from "statics/states";

class Announcement extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    hasPayrollIntegration: PropTypes.bool,
    updateProgressiveOnboarding: PropTypes.func,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);

    this.state = {
      showInviteSummary: false,
      textToCopy: "",
      showEmailTemplateModal: false,
      email: false,
      pdf: false,
    };
  }

  _onSubmit = () => {
    this.props
      .updateProgressiveOnboarding(
        this.props.client,
        progressiveOnboardingEvents.AnnouncePlan
      )
      .then(() => {
        if (this.props.hasPayrollIntegration) {
          return this.props.updateProgressiveOnboarding(
            this.props.client,
            progressiveOnboardingEvents.InviteUsers
          );
        }
      })
      .then(() => {
        if (!this.props.error) {
          this.props.onSuccess();
          toast.success("Successfully Announced Plan");
        }
      });
  };

  _modalHeader = () => {
    const iconLogo = <img alt="Icon" src="/assets/IconEmployerLogo.svg" />;
    const companyName = this.props.company.name;
    return (
      <div className="announcement-modal">
        <div className="logo-header">
          {iconLogo}
          <div className="sync">
            <FiArrowLeft
              color="858C9C"
              stroke="#858C9C"
              fill="white"
              size={12}
            />
            <FiArrowRight
              color="858C9C"
              stroke="#858C9C"
              fill="white"
              size={12}
            />
          </div>
          <div className="company-border">
            <p className="company-name">{companyName}</p>
          </div>
        </div>
      </div>
    );
  };

  _renderForm() {
    return (
      <div className="download-employee-template">
        <div className="main-content">
          <Row>
            <Col>
              <Card>
                <Card.Title>
                  <p className="page-header">Announce Your Icon Plan</p>
                  <p className="subtext">
                    Congrats! This launch represents an exciting milestone as an
                    employer to help your employees build a financially secure
                    future.
                  </p>
                </Card.Title>
                <Card.Body>
                  <div>
                    <div className="icon">
                      <div className="preview-box">
                        <Row className="preview">
                          <Col className="preview-intro">
                            <p className="preview-text">
                              How to build a secure financial future.
                            </p>
                            <span>
                              <img
                                className="icon-logo"
                                src="/assets/IconEmployerLogo.svg"
                              />
                            </span>
                          </Col>
                          <Col className="preview-img">
                            <img className="unicorn" src="/assets/stairs.png" />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="announcement-header">
                      <p className="header-text">Announcement Details </p>
                    </div>

                    <div className="preview-details">
                      <p className="subtext">
                        You&apos;re well on your way to getting your employees
                        up to speed on their new retirement benefit. To help
                        make it even easier for you, we&apos;ve put together the
                        documents below. All you need to do is send them to your
                        employees.
                      </p>
                    </div>
                  </div>

                  <div className="announcement-documents">
                    <p className="header-text">Documents:</p>
                    <span className="documents">
                      <span className="file">
                        <FiMail color="white" stroke="#0a2540" size={16} />
                        <p className="text">Email Template</p>
                      </span>
                      <span className="file-type">
                        <FiCopy color="white" stroke="#60A4BF" size={16} />
                        <a
                          className="text"
                          onClick={() =>
                            this.setState({ showEmailTemplateModal: true })
                          }
                        >
                          View
                        </a>
                      </span>
                    </span>

                    <span className="documents">
                      <span className="file">
                        <FiFile color="white" stroke="#0a2540" size={16} />
                        <p className="text"> Employee Onboarding Deck</p>
                      </span>
                      <span className="file-type">
                        <FiDownload color="white" stroke="#60A4BF" size={16} />

                        <a
                          className="text"
                          href={`https://iconsavingsplan-public.s3.us-west-2.amazonaws.com/${
                            isProduction() ? "production" : "staging"
                          }/documents/ICON_EMPLOYEE_ONBOARDING.pdf`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p className="text"> Please Download</p>
                        </a>
                      </span>
                    </span>
                  </div>

                  {this.state.showEmailTemplateModal && (
                    <SimpleModal
                      show={true}
                      size={"lg"}
                      onClose={() =>
                        this.setState({
                          showEmailTemplateModal: false,
                        })
                      }
                      title={this._modalHeader()}
                      buttonText="Close"
                    >
                      <p className="announcement-text">Announcement Text:</p>
                      <div className="template">
                        <p>
                          <p>Hello X,</p>
                          <p>
                            You&apos;ll be receiving an email from Icon with a
                            link that you&apos;ll use to personalize and
                            finalize your plan.
                          </p>
                          <p>Here are some of the unique features of Icon:</p>
                          <ul className="bullet-points">
                            <li>
                              Your tax-advantaged savings will be invested in a
                              portfolio tailored to your needs.
                            </li>
                            <li>
                              The investments are from leading providers like
                              Vanguard and BlackRock.
                            </li>
                            <li>
                              It&apos;s automatic, you decide how much to save,
                              and your contributions go directly into your
                              individual retirement account when you get paid.
                            </li>
                            <li>
                              It&apos;s portable, all the money in your plan
                              belongs to you and never requires a rollover.
                            </li>
                          </ul>
                          <p>
                            Saving in a tax-advantaged savings plan through Icon
                            is one of the easiest ways to build your long-term
                            wealth
                          </p>
                        </p>
                      </div>
                      <p
                        className="disclaimer"
                        style={{
                          marginTop: 8,
                          marginBottom: 8,
                          paddingLeft: 4,
                          fontSize: 14,
                          color: "#0a2540",
                        }}
                      >
                        * Feel free to modify as you see fit. We want you to
                        have a successful announcement!
                      </p>
                    </SimpleModal>
                  )}
                  {this.props.error && (
                    <Alert type="error" msg={this.props.error} />
                  )}
                  <div className="submit-row">
                    <span className="cancel-btn">
                      <Button
                        btnLabel="Cancel"
                        name="cancel"
                        color="cancel"
                        type="button"
                        onClick={this.props.onCancel}
                      />
                    </span>
                    <Button
                      btnLabel="Next"
                      name="submit"
                      withArrow={true}
                      onClick={this._onSubmit}
                      loading={this.props.isLoading}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="add-employee-form" style={{ marginBottom: 8 }}>
        {this._renderForm()}
      </div>
    );
  }
}

const actions = [
  employerConstants.GET_PROGRESSIVE_ONBOARDING,
  employerConstants.UPDATE_PROGRESSIVE_ONBOARDING,
];

const isLoading = createLoadingSelector(actions);
const error = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const company = state.employer.company;

  return {
    company,
    isLoading: isLoading(state),
    hasPayrollIntegration: hasEnabledPayrollIntegration(state),
    error: error(state),
  };
};

const mapDispatchToProps = {
  push,
  updateProgressiveOnboarding,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Announcement));
