import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { Card, Col, Row } from "react-bootstrap";
import {
  getPaginatedCustomReports,
  getReportsStatus,
} from "actions/adminActions";
import { FiFolder, FiPlus } from "react-icons/fi";

import Button from "components/Button";
import IconSpinner from "components/IconSpinner";
import IconTable from "components/IconTable";
import IconTableHeader from "components/IconTableHeader";
import IconEmptyState from "components/IconEmptyState";
import Paginator from "components/Paginator";
import { push } from "connected-react-router";
import { getReportCSV } from "services/adminService";
import moment from "moment";
import { chain, get, isEmpty, some } from "lodash";
import { downloadCSVFromString } from "utils/dom";
import EllipsisLoader from "components/EllipsisLoader";
import PromisePoller from "components/PromisePoller";

const ASYNC_STATUSES = ["REQUESTED", "PROCESSING"];

class CustomReports extends React.PureComponent {
  static propTypes = {
    getPaginatedCustomReports: PropTypes.func,
    getReportsStatus: PropTypes.func,
    push: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingStatuses: PropTypes.bool,
    client: PropTypes.object,
    reports: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    totalRows: PropTypes.number,
  };

  constructor() {
    super();

    this.state = {
      limit: 50,
      offset: 0,
      initialFetching: true,
      page: 0,
    };
  }

  componentDidMount() {
    this._getPaginatedCustomReports().then(() => {
      this.setState({
        initialFetching: false,
      });
    });
  }

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () =>
      this._getPaginatedCustomReports()
    );
  };

  _getPaginatedCustomReports = () => {
    return this.props.getPaginatedCustomReports(this.props.client, {
      limit: this.state.limit,
      offset: this.state.offset,
    });
  };

  _downloadCSV = async (report) => {
    const { id: reportId } = report;
    const reportType = get(report, "reportRequest.reportDefinition.reportType");
    const reportName = `${reportType}-${moment().format("YYYY-MM-DD")}.csv`;
    const csvString = await getReportCSV(this.props.client, reportId);
    downloadCSVFromString(csvString, reportName);
  };

  _reportColumns = () => {
    const columns = [
      {
        label: "Report Name",
        key: "reportRequest.reportName",
      },
      {
        label: "Report Type",
        key: "reportRequest.reportDefinition.description",
      },
      {
        label: "Report Status",
        key: "status",
        customComponent: (props) => {
          const status = get(props, "status", "UNKNOWN");
          return (
            <div className={"async-status-field"}>
              {status}{" "}
              {ASYNC_STATUSES.includes(status) ? <EllipsisLoader /> : null}
            </div>
          );
        },
      },
      {
        customComponent: (props) => {
          if (!props.locationUri) return null;
          return (
            <span style={{ textAlign: "center", width: 100 }}>
              <Button
                btnLabel="Download CSV"
                name="action"
                color="action"
                size="sm"
                type="button"
                onClick={() => {
                  this._downloadCSV(props);
                }}
              />
            </span>
          );
        },
      },
    ];
    return columns;
  };

  _getBlankSlate = () => {
    return (
      <Row>
        <Col>
          <IconEmptyState
            header="No Reports"
            subheader="It looks like we have not generated any reports yes"
            icon={<FiFolder color="white" stroke="#60A4BF" size={16} />}
          />
        </Col>
      </Row>
    );
  };

  getReportsStatus = () => {
    const reportIds = chain(this.props.reports)
      .filter((report) => {
        return ASYNC_STATUSES.includes(report.status);
      })
      .map("id")
      .value();

    this.props.getReportsStatus(this.props.client, reportIds);
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    const columns = this._reportColumns();
    const data = this.props.reports;
    const noReports = isEmpty(data);
    const shouldPollReports =
      !noReports &&
      some(data, (report) => {
        return ASYNC_STATUSES.includes(report.status);
      });

    return (
      <div id="admin-reports">
        <h2 style={{ color: "#0a2540" }}>Custom Reports</h2>
        <Card>
          <Row>
            <Col>
              {shouldPollReports && (
                <PromisePoller
                  interval={5000}
                  minutesToPollFor={10}
                  promiseToPoll={this.getReportsStatus}
                />
              )}
              <IconTableHeader
                tableHeader={"Admin Reports"}
                tableCountLabel="Reports"
                tableCount={this.props.totalRows}
              />
              <div
                style={{
                  float: "right",
                  margin: 8,
                }}
              >
                <Button
                  icon={{
                    icon: <FiPlus size={14} color={"#fffff"} />,
                    position: "left",
                  }}
                  size="sm"
                  name="action"
                  onClick={() =>
                    this.props.push("/dashboard/reports/custom/new")
                  }
                  btnLabel="New Report"
                />
              </div>
              {!noReports && (
                <>
                  <IconTable columns={columns} data={data} />
                  <Paginator
                    onChange={this._onPageChange}
                    pageCount={this.props.totalRows / this.state.limit}
                    page={this.state.page}
                  />
                </>
              )}
              {noReports && this._getBlankSlate()}
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const isFetching = createLoadingSelector(adminConstants.GET_CUSTOM_REPORTS);
const isFetchingStatuses = createLoadingSelector(
  adminConstants.GET_REPORTS_STATUS
);

const mapStateToProps = (state) => {
  const { results, totalRows } = state.admin.customReports;
  return {
    reports: results,
    totalRows,
    isFetching: isFetching(state),
    isFetchingStatuses: isFetchingStatuses(state),
  };
};

const mapDispatchToProps = {
  getPaginatedCustomReports,
  push,
  getReportsStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(CustomReports));
