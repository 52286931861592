import React, { PureComponent } from "react";
import { Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiAlertCircle } from "react-icons/fi";
import { Formik } from "formik";
import { omit, defaultTo } from "lodash";

import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import Button from "components/Button";
import IconHeader from "components/IconHeader";
import DatePicker from "react-datepicker";
import moment from "moment";

import "./SetupContribution.scss";
import { ScrollToFieldError } from "utils/form";
import { daysOfMonthOptions, MONTHLY, END_OF_MONTH } from "utils/timeHelper";

const yup = require("yup");
const schema = yup.object({
  frequency: yup.string().label("Payment Frequency").required(),
  dayOne: yup
    .string()
    .label("Payment Date")
    .when("frequency", {
      is: (frequency) => frequency !== MONTHLY,
      then: yup.string().nullable(),
      otherwise: yup.string().required(),
    }),
  anchorPayDate: yup
    .date()
    .label("First Payment Date")
    .when("frequency", {
      is: (frequency) => frequency === MONTHLY,
      then: yup.date().nullable(),
      otherwise: yup.date().required(),
    }),
});

export default class SchedulePicker extends PureComponent {
  static propTypes = {
    setSchedule: PropTypes.func,
    schedule: PropTypes.shape({
      frequency: PropTypes.string,
      dayOne: PropTypes.string,
      anchorPayDate: PropTypes.string,
    }),
  };
  _showDayOne = (frequency) => {
    return frequency === MONTHLY;
  };
  _renderTooltip = (props) => {
    return (
      <Tooltip className="tooltip" {...props}>
        If a transfer falls on a banking holiday or weekend, the transfer will
        process the following business day.
      </Tooltip>
    );
  };

  parseDayString = (day) => {
    return day === END_OF_MONTH ? 31 : parseInt(day);
  };

  _setSchedule = (recurringContributionValues) => {
    let newRecurringContribution = omit(
      {
        ...this.props.schedule,
        ...recurringContributionValues,
      },
      "dayOne"
    );
    if (recurringContributionValues.frequency === MONTHLY) {
      newRecurringContribution = {
        ...newRecurringContribution,
        dayOne: this.parseDayString(recurringContributionValues.dayOne),
      };
    }

    // some schedule props are not initialized with anchorPayDate - must initialize here if not set.
    return this.props.setSchedule({
      ...newRecurringContribution,
      anchorPayDate: newRecurringContribution.anchorPayDate
        ? moment(newRecurringContribution.anchorPayDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    });
  };

  render() {
    const tooltip = (
      <OverlayTrigger
        className="OverlayTrigger"
        placement="auto"
        overlay={this._renderTooltip}
        containerPadding={12}
        transition={true}
      >
        <FiAlertCircle
          className="FiAlertCircle"
          size={20}
          fill={"#FFFFFF"}
          color={"#FFFFFF"}
          stroke={"#0038FF"}
          strokeWidth={"1.5"}
        />
      </OverlayTrigger>
    );
    const startOfMonth = 1;
    return (
      <div className="schedule-picker-container">
        <IconHeader
          variant="labelHeader"
          headerText="Set contribution schedule"
        />

        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={this._setSchedule}
          enableReinitialize={true}
          initialValues={{
            ...this.props.schedule,
            dayOne: defaultTo(this.props.schedule.dayOne, startOfMonth),
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <Form.Row style={{ paddingTop: 10 }}>
                <Form.Group as={Col} md={8} controlId="formGroupFrequency">
                  <Form.Label>
                    How often do you want to contribute to your account?
                  </Form.Label>

                  <Form.Control
                    as="select"
                    name="frequency"
                    value={values.frequency}
                    onChange={handleChange}
                    isInvalid={!!errors.frequency}
                    isValid={touched.frequency && !errors.frequency}
                  >
                    <option value="">Select Frequency</option>
                    <option value="WEEKLY">Every Week</option>
                    <option value="EVERY_OTHER_WEEK">Every Two Weeks</option>
                    <option value="MONTHLY">Every Month</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.frequency}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              {this._showDayOne(values.frequency) && (
                <Form.Row>
                  <Form.Group as={Col} md={6} controlId="formGroupDayone">
                    <Form.Label>
                      Select your monthly contribution day {tooltip}
                    </Form.Label>
                    <Form.Control
                      sm={6}
                      as="select"
                      name="dayOne"
                      value={values.dayOne}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.dayOne}
                      isValid={touched.dayOne && !errors.dayOne}
                    >
                      <option value="" disabled>
                        Day of Month
                      </option>
                      {daysOfMonthOptions.map((item) => (
                        <option value={item.value} key={item.key}>
                          {item.value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
              )}
              {values.frequency && !this._showDayOne(values.frequency) && (
                <Form.Row>
                  <Form.Group as={Col} md={6} controlId="formBasicDateOfBirth">
                    <Form.Label>
                      Pick a start date for your recurring contribution{" "}
                      {tooltip}
                    </Form.Label>
                    <div>
                      <DatePicker
                        className="form-control"
                        name="anchorPayDate"
                        autoComplete="off"
                        filterDate={(date) => {
                          const day = date.getDay();
                          const sunday = 0;
                          const saturday = 6;
                          return day !== sunday && day !== saturday;
                        }}
                        minDate={moment().add(1, "days").toDate()}
                        maxDate={moment().add(90, "days").toDate()}
                        value={values.anchorPayDate}
                        dateFormat="MM/dd/yyyy"
                        selected={
                          (values.anchorPayDate &&
                            new Date(values.anchorPayDate)) ||
                          null
                        }
                        onChange={(val) => {
                          setFieldValue("anchorPayDate", moment(val));
                        }}
                        isInvalid={!!errors.anchorPayDate}
                        isValid={touched.anchorPayDate && !errors.anchorPayDate}
                      />
                    </div>
                    {errors.anchorPayDate && touched.anchorPayDate && (
                      <div
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.anchorPayDate}
                      </div>
                    )}
                  </Form.Group>
                </Form.Row>
              )}
              <div className="submit-row">
                <div className="portfolio-lg-screen">
                  <Button />
                </div>
                <div className="portfolio-sm-screen">
                  <Button size="sm" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
