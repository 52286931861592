import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

import { formatCurrency } from "utils/number";
import { DAILY } from "utils/timeHelper";

const modifierToAbbrvModifierMap = {
  [DAILY]: "daily",
  TWICE_PER_MONTH: "twice a month",
  WEEKLY: "weekly",
  EVERY_OTHER_WEEK: "every two weeks",
  MONTHLY: "monthly",
};
import "./Contributions.scss";

// if the amount is 0 here, that means that amount` was undefined/null and was type coerced to `0` in the parent components
const SelectContributionBox = ({
  onSelect,
  isSelected,
  isCustomAmount,
  isRecommended,
  amount,
  modifier,
  customAmountError,
  setCustomAmount,
  contributionLimits,
}) => {
  const modifierDisplayVal = modifierToAbbrvModifierMap[modifier];
  return (
    <div
      className={classnames("select-contribution-box shadow", {
        selected: isSelected,
        recommended: isRecommended,
        custom: isCustomAmount,
        error: !!customAmountError,
      })}
      onClick={() => onSelect(amount)}
    >
      {!isCustomAmount && (
        <div className="contribution-amount-container">
          <span className="amount">{formatCurrency(amount)}</span>
          <span className="amount-modifier">{modifierDisplayVal}</span>
        </div>
      )}
      {isRecommended && (
        <div className="recommended-container">{"Most popular"}</div>
      )}
      {isCustomAmount && (
        <div className="contribution-custom-amount-container">
          {!isSelected && (
            <span className="custom-amt-msg">Enter custom amount</span>
          )}
          {isSelected && (
            <>
              <span className="dollar">$</span>
              <Form.Control
                style={{
                  width: 105,
                  display: "inline-block",
                  marginRight: 8,
                  paddingLeft: "1rem",
                }}
                name="incomeLimitsRawValue"
                size="sm"
                placeholder={contributionLimits.min}
                value={amount === 0 ? null : amount}
                type="number"
                max={contributionLimits.max}
                min={contributionLimits.min}
                onChange={(e) => {
                  let amount = +e.target.value;
                  setCustomAmount(amount);
                }}
                isInvalid={!!customAmountError}
                isValid={amount && !customAmountError}
              />
              <span style={{ verticalAlign: "bottom" }}>
                {modifierDisplayVal}
              </span>
              <Form.Control.Feedback type="invalid">
                {customAmountError}
              </Form.Control.Feedback>
            </>
          )}
        </div>
      )}

      {isCustomAmount && isSelected && (
        <div className="description">
          <ul>
            <li>
              Minimum contribution is{" "}
              {formatCurrency(contributionLimits.min, 0)}
            </li>
            <li>
              Maximum contribution is{" "}
              {formatCurrency(contributionLimits.max, 0)}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

SelectContributionBox.propTypes = {
  onSelect: PropTypes.func,
  setCustomAmount: PropTypes.func,
  isSelected: PropTypes.bool,
  isCustomAmount: PropTypes.bool,
  isRecommended: PropTypes.bool,
  amount: PropTypes.number,
  modifier: PropTypes.string,
  payperiod: PropTypes.string,
  customAmountError: PropTypes.string,
  customAmountDisplayValue: PropTypes.string,
  contributionLimits: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
};

export default SelectContributionBox;
