import React from "react";
import { PropTypes } from "prop-types";
import { processedStatuses } from "statics/payroll";
import {
  payrollStatusToEnglish,
  payrollStatusToType,
} from "statics/statusToEnglishMapping";

import StatusBox from "components/StatusBox";

const PayrollStatus = ({ periodState, status }) => {
  let currentStatus = periodState;

  const hasProcessed = processedStatuses.includes(status);
  // currentStatus should only be set to the period state if it is "WAITING_FOR_APPROVAL", which maps it to "Current"
  if (hasProcessed) {
    currentStatus = status;
  }

  const displayCurrentStatus = payrollStatusToEnglish[currentStatus];

  return (
    <span>
      <StatusBox
        status={displayCurrentStatus}
        type={payrollStatusToType[currentStatus]}
      />
    </span>
  );
};

PayrollStatus.propTypes = {
  periodState: PropTypes.string,
  status: PropTypes.string,
};

export default PayrollStatus;
