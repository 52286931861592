import React from "react";
import * as yup from "yup";
import { PropTypes } from "prop-types";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { get, pick } from "lodash";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import {
  fetchOwners,
  getCompanyInfo,
  referCompany,
} from "actions/employerActions";

import { toast } from "react-toastify";
import Button from "components/Button";
import ClipboardCopy from "components/ClipboardCopy";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import TermsModal from "pages/signUp/TermsModal";

import "./ReferralForm.scss";
import { ScrollToFieldError } from "utils/form";

const schema = yup.object({
  email: yup
    .string()
    .label("Email")
    .email("You must enter a valid email address")
    .required(),
});

class ReferralForm extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    activeCompany: PropTypes.object,
    push: PropTypes.func,
    pathname: PropTypes.string,
    referralPlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    referCompany: PropTypes.func,
    error: PropTypes.string,
    referralId: PropTypes.string,
    isLoading: PropTypes.bool,
    onSubmit: PropTypes.func,
    getCompanyInfo: PropTypes.func,
    fetchOwners: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showTermsOfService: false,
      fetchingOwners: true,
      theController: {},
    };
  }

  _onSubmit = (values) => {
    this.props.referCompany(this.props.client, values.email).then(() => {
      if (!this.props.error) {
        toast.success("Referral Sent."), this.props.push("/dashboard");
      } else {
        toast.error("There was a problem sending referral.");
      }
    });
  };

  async componentDidMount() {
    window.analytics.page("Employer Terms");

    this.props.getCompanyInfo(this.props.client);

    const { data: owners } = await this.props.fetchOwners(this.props.client);

    this.setState({ fetchingOwners: false, owners });

    var results = owners.map((item) =>
      pick(item, [
        "role",
        "memberProfile.firstName",
        "memberProfile.lastName",
        "memberProfile.email",
      ])
    );

    const theController = results.filter((person) =>
      person.role.startsWith("ControllingOfficer")
    );

    this.setState({ theController: theController[0] });

    this._isMounted = true;
  }

  _viewTerms = () => {
    return (
      <>
        <div className="terms">
          For every new company that joins Icon using your referral link, you
          both earn a credit.
          <a
            className="terms-link"
            onClick={() => {
              this.setState({
                showTermsOfService: true,
              });
            }}
            style={{
              display: "flex",
              color: "#01a3b0",
              cursor: "pointer",
            }}
          >
            <p className="terms-link-text"> Referral Program Agreements.</p>
          </a>
        </div>
      </>
    );
  };

  render() {
    const referralUrl = `https://referrals.iconsavingsplan.com?referralCode=${this.props.referralId}`;
    const terms = this._viewTerms();

    return (
      <div className="referral-form">
        {this.state.showTermsOfService && (
          <TermsModal
            termsInfo={this.state.theController}
            type="employerPartnerProgram"
            show={this.state.showTermsOfService}
            onClose={() => this.setState({ showTermsOfService: false })}
          />
        )}

        {!this.state.showAcctAgreement && (
          <Card>
            <Card.Title className="referral-title">
              <IconHeader variant="cardHeader" headerText="Refer & Earn" />
              <IconSubheader subheader={terms} />
            </Card.Title>
            <Card.Body className="referral-body">
              <Row className="copy-and-share">
                <Col>
                  <IconHeader
                    variant="referralHeader"
                    headerText="Copy and share your link"
                  />
                  <div className="referral-copy-share">
                    <span className="referral-box">
                      <p className="referral-link">{referralUrl}</p>
                    </span>
                    <div className="copy-link">
                      <ClipboardCopy copyText={referralUrl} />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="invite-email">
                <Col>
                  <IconHeader
                    variant="referralHeader"
                    headerText="Invite by email"
                  />
                  <Formik
                    validateOnChange={false}
                    validationSchema={schema}
                    onSubmit={(values) => {
                      this._onSubmit(values);
                    }}
                    enableReinitialize={true}
                    initialValues={{
                      email: "",
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <ScrollToFieldError />
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={9}
                            controlId="formBasicEmail"
                            style={{ paddingBottom: 10 }}
                          >
                            <Form.Label
                              style={{ color: "858C9C", paddingTop: 20 }}
                            >
                              Email Address
                            </Form.Label>

                            <div className="referral-btn">
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="referafriend@gmail.com"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={touched.email && !!errors.email}
                                isValid={touched.email && !errors.email}
                              />
                              <span style={{ paddingLeft: 10, paddingTop: 3 }}>
                                <Button
                                  btnLabel="Send Invite"
                                  name="submit"
                                  withArrow={true}
                                  loading={this.props.isLoading}
                                />
                              </span>
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>

              <Row>
                <Col>
                  <IconHeader
                    variant="referralHeader"
                    headerText="Share on socials"
                  />
                  <div className="share-social">
                    Share the link on:
                    <LinkedinShareButton
                      url={referralUrl}
                      title="Join Icon Savings Plan!"
                      summary="Use this referral link to sign up for Icon Savings Plan!"
                      source={referralUrl}
                      beforeOnClick={() => {
                        window.analytics.track("Shared on LinkedIn");
                      }}
                    >
                      <LinkedinIcon size={20} round />
                    </LinkedinShareButton>
                    |
                    <TwitterShareButton
                      url={referralUrl}
                      title="Use this referral link to sign up for Icon Savings Plan!"
                      hashtags={["IconSavingsPlan"]}
                      beforeOnClick={() => {
                        window.analytics.track("Shared on Twitter");
                      }}
                    >
                      <TwitterIcon size={20} round />
                    </TwitterShareButton>
                    |
                    <FacebookShareButton
                      url={referralUrl}
                      quote="test"
                      hashtags="IconSavingsPlan"
                      beforeOnClick={() => {
                        window.analytics.track("Shared on Facebook");
                      }}
                    >
                      <FacebookIcon size={20} round />
                    </FacebookShareButton>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
      </div>
    );
  }
}

const actions = [employerConstants.REFER_COMPANY];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const referralId = state.employer.company.referralId;
  return {
    activeCompany: state.employer.company,
    pathname: get(state.router, "location.pathname"),
    referralId,

    isLoading: loadingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  referCompany,
  getCompanyInfo,
  fetchOwners,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ReferralForm));
