import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { get } from "lodash";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import ControllerForm from "pages/dashboards/adminDashboard/KYB/ControllerForm";

import { wrapError } from "utils/errorHelper";
import { registerKYBUser } from "actions/adminActions";
import { createLoadingSelector } from "store/selectors";
import { adminConstants, userConstants } from "actions/types";
import { getUserState } from "actions/userActions";
import ProgressBar from "components/ProgressBar";
import "./EmployerEnroll.scss";
import { planCodeSelector } from "store/selectors/employer";
import { ENTERPRISE } from "statics/planCodes";
import { COMPANY_INFO } from "statics/onboardingSteps";

class OwnerFullInfoForm extends React.PureComponent {
  static propTypes = {
    owner: PropTypes.shape({
      memberProfile: PropTypes.shape({}),
    }),
    companyId: PropTypes.string,
    planCode: PropTypes.string,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    isMultiAccount: PropTypes.bool,
    client: PropTypes.object,
    registerKYBUser: PropTypes.func,
    getUserState: PropTypes.func,
    push: PropTypes.func,
  };

  _onSubmit = (updatedInfo) => {
    this.props
      .registerKYBUser(this.props.client, updatedInfo)
      .then(() => {
        this.props.getUserState(this.props.client).then(() => {
          this.props.push(`/employer/terms`);
        });
      })
      .catch((err) => {
        toast.error(wrapError(err));
      });
  };

  render() {
    const owner = this.props.owner || {};
    const ownerInfo = {
      ...owner,
      ...owner.memberProfile,
      address1: "",
      address2: "",
      city: "",
      state: "",
      postalCode: "",
      ownershipStake: undefined,
    };

    const progPercent = this.props.isMultiAccount ? "25" : "75";

    return (
      <div>
        <ProgressBar
          isEmployer={true}
          activeStepId={COMPANY_INFO.id}
          progressPercent={progPercent}
          isMultiAccount={this.props.isMultiAccount}
          showBillingRoute={
            this.props.planCode && this.props.planCode !== ENTERPRISE
          }
        />
        <ControllerForm
          ownerInfo={ownerInfo}
          error={this.props.error}
          isFetching={this.props.isFetching}
          isAdminPortal={false}
          companyId={this.props.companyId}
          onSubmit={this._onSubmit}
          planCode={this.props.planCode}
        />
      </div>
    );
  }
}

const fetchingSelector = createLoadingSelector(
  adminConstants.REGISTER_KYB_USER,
  userConstants.USER_STATE
);

const mapStateToProps = (state) => {
  return {
    companyId: get(state.employer, "company.id", ""),
    isFetching: fetchingSelector(state),
    isMultiAccount: state.employer.isMultiAccount,
    planCode: planCodeSelector(state),
  };
};

const mapDispatchToProps = {
  registerKYBUser,
  push,
  getUserState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(OwnerFullInfoForm));
