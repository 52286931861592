import { adminConstants } from "actions/types";
import { filter, find, keyBy, map, transform } from "lodash";

const initialState = {
  approvalsById: {},
  complianceReports: {},
  usersById: {},
  productFeatures: [],
  companies: [],
  originations: [],
  originationsCount: 0,
  companiesCount: 0,
  payrolls: {
    results: [],
    totalRows: 0,
  },
  missedPayrolls: {
    results: [],
    totalRows: 0,
  },
  workflows: {
    results: [],
    totalRows: 0,
  },
  workflowSteps: {
    results: [],
    totalRows: 0,
  },
  foundUsers: [],
  foundUsersCount: 0,
  customReports: {
    results: [],
    totalRows: 0,
  },
  salesQuotes: {
    results: [],
    totalRows: 0,
  },
  customReportTypes: {},
};

export function admin(state = initialState, action) {
  switch (action.type) {
    case adminConstants.GET_APPROVAL_SUCCESS:
      return {
        ...state,
        approvalsById: {
          ...state.approvalsById,
          [action.data.id]: action.data,
        },
      };

    case adminConstants.SET_APPROVAL_STATUS_SUCCESS:
      return {
        ...state,
        approvalsById: {
          ...state.approvalsById,
          [action.data.id]: {
            ...state.approvalsById[action.data.id],
            ...action.data,
          },
        },
      };

    case adminConstants.GET_COMPLIANCE_REPORTS_SUCCESS: {
      const complianceReports = transform(
        action.data,
        (acc, val) => {
          acc[val.reportType] = val.reportHistory;
          return acc;
        },
        {}
      );
      return {
        ...state,
        complianceReports,
      };
    }

    case adminConstants.GET_USER_ACCOUNT_REVIEW_DOCUMENTS_SUCCESS: {
      const userById = {
        [action.data.id]: action.data,
      };
      return {
        ...state,
        usersById: {
          ...state.usersById,
          ...userById,
        },
      };
    }

    case adminConstants.GET_PENDING_APPROVALS_SUCCESS: {
      const approvalsById = action.data.reduce((accum, approval) => {
        return {
          ...accum,
          [approval.id]: approval,
        };
      }, {});
      return {
        ...state,
        approvalsById: {
          ...state.approvalsById,
          ...approvalsById,
        },
      };
    }

    case adminConstants.GET_PRODUCT_FEATURES_SUCCESS: {
      return {
        ...state,
        productFeatures: keyBy(action.data, "name"),
      };
    }

    case adminConstants.GET_PAGINATED_COMPANIES_SUCCESS: {
      return {
        ...state,
        companies: action.data.rows,
        companiesCount: action.data.count,
      };
    }

    case adminConstants.GET_ORIGINATIONS_SUCCESS: {
      return {
        ...state,
        originations: action.data.applications,
        originationsCount: action.data.total,
      };
    }

    case adminConstants.GET_PAYROLLS_SUCCESS: {
      return {
        ...state,
        payrolls: {
          ...action.data,
        },
      };
    }

    case adminConstants.GET_UNPROCESSED_PAYROLLS_SUCCESS: {
      return {
        ...state,
        missedPayrolls: {
          ...action.data,
        },
      };
    }

    case adminConstants.SEARCH_USERS_SUCCESS: {
      return {
        ...state,
        foundUsers: action.data.results,
        foundUsersCount: action.data.totalRows,
      };
    }

    case adminConstants.GET_WORKFLOWS_SUCCESS: {
      return {
        ...state,
        workflows: {
          ...action.data,
        },
      };
    }

    case adminConstants.GET_WORKFLOW_STEPS_SUCCESS: {
      return {
        ...state,
        workflowSteps: {
          ...action.data,
        },
      };
    }

    case adminConstants.GET_CUSTOM_REPORT_TYPES_SUCCESS: {
      const customReportsByType = keyBy(action.data, "reportType");
      return {
        ...state,
        customReportTypes: customReportsByType,
      };
    }

    case adminConstants.GET_CUSTOM_REPORTS_SUCCESS: {
      return {
        ...state,
        customReports: {
          totalRows: action.data.count,
          results: action.data.reports,
        },
      };
    }
    case adminConstants.GET_REPORTS_STATUS_SUCCESS: {
      const updatedResults = map(state.customReports.results, (result) => {
        const matchingReport = find(action.data, { id: result.id });

        if (!matchingReport) {
          return result;
        }

        return matchingReport;
      });
      return {
        ...state,
        customReports: {
          ...state.customReports,
          results: updatedResults,
        },
      };
    }

    case adminConstants.GET_SALES_QUOTES_SUCCESS: {
      return {
        ...state,
        salesQuotes: {
          totalRows: action.data.count,
          results: action.data.rows,
        },
      };
    }

    case adminConstants.DELETE_SALES_QUOTE_SUCCESS: {
      const salesQuotes = filter(state.salesQuotes.results, (result) => {
        return result.id !== action.data;
      });

      return {
        ...state,
        salesQuotes: {
          ...state.salesQuotes,
          results: salesQuotes,
        },
      };
    }

    default:
      return state;
  }
}
