import React from "react";
import { PropTypes } from "prop-types";

import "./IconTableHeader.scss";

const IconTableHeader = ({
  tableButton,
  tableHeader,
  tableCountLabel,
  tableCount,
}) => {
  return (
    <div className="icon-table-header">
      <span className="table-count">
        <p className="table-header">{tableHeader}:</p>
        {tableCountLabel && (
          <p className="table-count-label">{tableCountLabel}</p>
        )}
        {tableCount && <p className="count">({tableCount})</p>}
      </span>
      <span>{tableButton && <span>{tableButton}</span>}</span>
    </div>
  );
};

IconTableHeader.propTypes = {
  tableButton: PropTypes.bool,
  tableHeader: PropTypes.string,
  tableCountLabel: PropTypes.string,
  tableCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconTableHeader;
