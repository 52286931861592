import { FiChevronDown, FiUser } from "react-icons/fi";
import { toUpper } from "lodash";
import classnames from "classnames";
import React from "react";
import PropTypes from "prop-types";

export const UserIcon = ({
  userName,
  rotatedChevron,
  showNavAvatar = true,
}) => {
  return (
    <div className="user-nav">
      {showNavAvatar && (
        <div className="nav-avatar">
          <div className="circle">
            <span className="icon">
              <FiUser stroke="#FFFFFF" size={16} />
            </span>
          </div>
        </div>
      )}

      <div className="nav-details">
        <p className="nav-name">{toUpper(userName)}</p>
      </div>
      <FiChevronDown
        fill="#30374e"
        color="#30374e"
        stroke="#FFFFFF"
        size={14}
        className={classnames(`nav-chevron  ${rotatedChevron ? "rotate" : ""}`)}
      />
    </div>
  );
};

UserIcon.propTypes = {
  userName: PropTypes.string,
  rotatedChevron: PropTypes.bool,
  showNavAvatar: PropTypes.bool,
};

export default UserIcon;
