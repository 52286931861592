import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { push } from "connected-react-router";
import { get, map } from "lodash";

import UserInfo from "./UserInfo";
import UserTransactions from "./UserTransactions";
import EmployerLinks from "pages/dashboards/adminDashboard/users/EmployerLinks";
import UserAccounts from "pages/dashboards/adminDashboard/users/UserAccounts";
import ContributionAbility from "pages/dashboards/adminDashboard/users/ContributionAbility";
import EntityLimits from "../EntityLimits";
import { isSuperIconAdmin } from "store/selectors/admin";

class User extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    push: PropTypes.func,
    userId: PropTypes.string,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
    routeName: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      tab: this._calcActiveTab(props.routeName),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.routeName !== this.props.routeName) {
      this.setState({ tab: this._calcActiveTab() });
    }
  }

  _getTabs = () => {
    let allTabs = [
      {
        label: "Info",
        id: "info",
      },
      {
        label: "Transactions",
        id: "transactions",
      },
      {
        label: "Employers",
        id: "employers",
      },
      {
        label: "Accounts",
        id: "accounts",
      },
      {
        label: "Contribution Ability",
        id: "contribution_ability",
      },
    ];

    if (this.props.isSuperAdmin) {
      allTabs.push({
        label: "Limits",
        id: "limits",
      });
    }

    return allTabs;
  };

  _tabUpdate(value) {
    this.setState({ tab: value.k });
    this.props.push(`/users/${this.props.userId}/${value.k}`);
  }

  _controlledTabs() {
    const tabs = this._getTabs();
    return (
      <Tabs
        activeKey={this.state.tab}
        id="tab-user"
        onSelect={(k) => this._tabUpdate({ k })}
      >
        {map(tabs, (tab) => {
          return <Tab key={tab.id} eventKey={tab.id} title={tab.label} />;
        })}
      </Tabs>
    );
  }

  _calcActiveTab = (routeName) => {
    switch (routeName) {
      case `/users/${this.props.userId}/info`:
        return "info";
      case `/users/${this.props.userId}/transactions`:
        return "transactions";
      case `/users/${this.props.userId}/employers`:
        return "employers";
      case `/users/${this.props.userId}/accounts`:
        return "accounts";
      case `/users/${this.props.userId}/contribution_ability`:
        return "contribution_ability";
      case `/users/${this.props.userId}/limits`:
        return "limits";

      default:
        return "info";
    }
  };

  _getCardContent = () => {
    switch (this.state.tab) {
      case "info":
        return <UserInfo userId={this.props.userId} />;
      case "transactions":
        return <UserTransactions userId={this.props.userId} />;
      case "employers":
        return <EmployerLinks userId={this.props.userId} />;
      case "accounts":
        return <UserAccounts userId={this.props.userId} />;
      case "contribution_ability":
        return <ContributionAbility userId={this.props.userId} />;
      case "limits":
        return <EntityLimits entityId={this.props.userId} entityType="USER" />;
    }
  };

  render() {
    return (
      <div className="mega-container" id={"admin-user-page"}>
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">User Info</h1>

            <div className="main-content">
              <Row>
                <Col>
                  <div className="card-tab">{this._controlledTabs()}</div>
                  <Card>{this._getCardContent()}</Card>
                </Col>
              </Row>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const userId = get(ownProps.match, "params.id");
  const routeName = get(state.router, "location.pathname");

  return {
    userId,
    routeName,
    isSuperAdmin: isSuperIconAdmin(state),
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(withApollo(User));
