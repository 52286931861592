import gql from "graphql-tag";

export function submitKYBUser(client, kybUserParams) {
  return new Promise((resolve, reject) => {
    const REGISTER_KYB_USER = gql`
      mutation RegisterKYBUser(
        $employerId: String
        $id: ID
        $firstName: String!
        $lastName: String!
        $email: String!
        $role: String!
        $ownershipStake: Float!
        $address1: String!
        $address2: String!
        $city: String!
        $state: String!
        $postalCode: String!
        $phone: String!
        $dob: String!
        $ssn: String!
      ) {
        registerKYBUser(
          employerId: $employerId
          id: $id
          firstName: $firstName
          lastName: $lastName
          email: $email
          role: $role
          ownershipStake: $ownershipStake
          address1: $address1
          address2: $address2
          city: $city
          state: $state
          postalCode: $postalCode
          phone: $phone
          dob: $dob
          ssn: $ssn
        ) {
          error
        }
      }
    `;
    const result = client.mutate({
      mutation: REGISTER_KYB_USER,
      variables: {
        employerId: kybUserParams.employerId,
        firstName: kybUserParams.firstName,
        lastName: kybUserParams.lastName,
        email: kybUserParams.email,
        role: kybUserParams.role,
        ownershipStake: kybUserParams.ownershipStake,
        address1: kybUserParams.address1,
        address2: kybUserParams.address2,
        city: kybUserParams.city,
        state: kybUserParams.state,
        postalCode: kybUserParams.postalCode,
        phone: kybUserParams.phone,
        dob: kybUserParams.dob,
        ssn: kybUserParams.ssn,
        id: kybUserParams.id,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function submitKYB(client, kybEmployer) {
  return new Promise((resolve, reject) => {
    const REGISTER_KYB = gql`
      mutation StartKYB($employerId: ID!) {
        startKYB(employerId: $employerId)
      }
    `;
    const result = client.mutate({
      mutation: REGISTER_KYB,
      variables: {
        employerId: kybEmployer.employerId,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getApprovalRequest(client, approvalId) {
  return new Promise((resolve, reject) => {
    const APPROVAL = gql`
      query getApprovalRequest($approvalId: ID!) {
        getApprovalRequest(approvalId: $approvalId) {
          id
          entityIdentifier {
            id
            type
          }
          type {
            value
            urlPath
            label
          }
          correlationName
          createdAt
          updatedAt
          status
          updatedBy
          reasonComment
          allReasons
          metadataItems {
            name
            value
          }
          documents {
            id
            name
            uri
            contentType
            createdAt
            updatedAt
            type
            metadata {
              downloadUrl
              contentType
              createdAt
              updatedAt
            }
          }
        }
      }
    `;
    const result = client.query({
      query: APPROVAL,
      variables: { approvalId },
    });
    result.then(
      (data) => {
        resolve(data.data.getApprovalRequest);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getUserById(client, userId) {
  return new Promise((resolve, reject) => {
    const USER = gql`
      query getUserById($userId: ID!) {
        getUserById(userId: $userId) {
          firstName
          lastName
          email
          id
          state
          yellowPathCodes {
            rejectCodes
            indeterminateCodes
            otherCodes
          }
        }
      }
    `;
    const result = client.query({
      query: USER,
      variables: { userId },
    });
    result.then(
      (data) => {
        resolve(data.data.getUserById);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getUserByEmail(client, email) {
  return new Promise((resolve, reject) => {
    const USER = gql`
      query getUserByEmail($email: String!) {
        getUserByEmail(email: $email) {
          firstName
          lastName
          email
          id
          state
          yellowPathCodes {
            rejectCodes
            indeterminateCodes
            otherCodes
          }
        }
      }
    `;
    const result = client.query({
      query: USER,
      variables: { email },
    });
    result.then(
      (data) => {
        resolve(data.data.getUserByEmail);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getUserTransactions(client, userId) {
  return new Promise((resolve, reject) => {
    const TRANSACTIONS = gql`
      query getUserTransactions($userId: ID!) {
        getUserTransactions(userId: $userId) {
          memo
          status
          updatedAt
          id
          createdAt
        }
      }
    `;
    const result = client.query({
      query: TRANSACTIONS,
      variables: { userId },
    });
    result.then(
      (data) => {
        resolve(data.data.getUserTransactions);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function setApproval(client, approvalParams) {
  return new Promise((resolve, reject) => {
    const UPDATE_APPROVAL = gql`
      mutation setApprovalStatus($approvalParams: ApprovalParams!) {
        setApprovalStatus(approvalParams: $approvalParams) {
          id
          type {
            value
            urlPath
            label
          }
          createdAt
          updatedAt
          status
          updatedBy
          reasonComment
          allReasons
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_APPROVAL,
      variables: {
        approvalParams,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.setApprovalStatus);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function lockUser(client, userId) {
  return new Promise((resolve, reject) => {
    const LOCK_USER = gql`
      mutation lockUser($userId: ID!) {
        lockUser(userId: $userId) {
          state
        }
      }
    `;
    const result = client.mutate({
      mutation: LOCK_USER,
      variables: {
        userId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.lockUser);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function unlockUser(client, userId) {
  return new Promise((resolve, reject) => {
    const UNLOCK_USER = gql`
      mutation unlockUser($userId: ID!) {
        unlockUser(userId: $userId) {
          state
        }
      }
    `;
    const result = client.mutate({
      mutation: UNLOCK_USER,
      variables: {
        userId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.unlockUser);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getAllCompanies(client) {
  return new Promise((resolve, reject) => {
    const COMPANIES = gql`
      query companies {
        companies {
          name
          id
          deleted
          createdAt
          currentState
        }
      }
    `;
    const result = client.query({
      query: COMPANIES,
    });
    result.then(
      (data) => {
        resolve(data.data.companies);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

// get company details
export function getCompany(client, companyId) {
  return new Promise((resolve, reject) => {
    const GET_COMPANY = gql`
      query companyById($companyId: ID!) {
        companyById(companyId: $companyId) {
          id
          name
          dba
          ein
          type
          naicsCode
          size
          url
          planId
          currentState
          billingType
          payrollIntegrationStatus
          user {
            id
            email
          }
          address {
            id
            address1
            address2
            city
            state
            postalCode
            phone
            phoneExt
            fax
          }
          planTypes {
            id
            description
            name
          }
          enabledProductFeatures
          progressiveOnboardingState
        }
      }
    `;
    const result = client.query({
      query: GET_COMPANY,
      variables: { companyId },
    });
    result.then(
      (data) => {
        resolve(data.data.companyById);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getCompanyKybData(client, companyId) {
  return new Promise((resolve, reject) => {
    const GET_COMPANY = gql`
      query getCompanyKybData($companyId: ID!) {
        getCompanyKybData(companyId: $companyId) {
          employerProfile {
            id
            email
            addressAlias
            address1
            address2
            city
            state
            postalCode
            businessName
            businessType
            doingBusinessAs
            naicsBusinessClassification
            ein
            website
            phone
          }
          silaWallet {
            id
            correlationId
            routingNumber
            accountNumber
            accountType
            name
            active
          }

          businessMembers {
            id
            businessMemberHandle
            role
            ownershipStake
            state
            memberProfile {
              legalName
              firstName
              lastName
              alias
              email
              phoneNumber
              ssn
              citizenshipCountry
              birthCountry
              permanentResident
              dob
              address1
              address2
              city
              state
              postalCode
              maritalStatus
              numberOfDependents
              employeeId
              phone
            }
          }
          businessDocuments {
            id
            uri
            contentType
            name
            metadata {
              batchUploadCount
            }
            type
          }
          documents {
            id
            uri
            contentType
            metadata {
              batchUploadCount
            }
            type
          }
          businessMemberDocuments {
            memberId
            id
            correlationId
            uri
            contentType
            name
            createdAt
            metadata {
              batchUploadCount
            }
            type
          }
        }
      }
    `;
    const result = client.query({
      query: GET_COMPANY,
      variables: { companyId },
    });
    result.then(
      (data) => {
        resolve(data.data.getCompanyKybData);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

// Update company profile
export function updateCompanyKYBInfo(client, companyDetails) {
  return new Promise((resolve, reject) => {
    const UPDATE_COMPANY = gql`
      mutation updateCompanyKYBInfo(
        $id: ID!
        $name: String
        $dba: String
        $ein: String
        $type: String
        $naicsCode: String
        $size: String
        $url: String
        $addressId: ID
        $address1: String
        $address2: String
        $city: String
        $state: String
        $phone: String
        $phoneExt: String
        $postalCode: String
        $fax: String
      ) {
        updateCompanyKYBInfo(
          id: $id
          name: $name
          dba: $dba
          ein: $ein
          type: $type
          naicsCode: $naicsCode
          size: $size
          url: $url
          addressId: $addressId
          address1: $address1
          address2: $address2
          city: $city
          state: $state
          phone: $phone
          phoneExt: $phoneExt
          postalCode: $postalCode
          fax: $fax
        ) {
          company {
            id
            name
            dba
            ein
            type
            naicsCode
            size
            url
            limits {
              key
              value
            }
            address {
              id
              address1
              address2
              city
              state
              postalCode
              phone
              phoneExt
              fax
            }
          }
          error
        }
      }
    `;

    const result = client.mutate({
      mutation: UPDATE_COMPANY,
      variables: {
        id: companyDetails.id,
        name: companyDetails.name,
        dba: companyDetails.dba,
        ein: companyDetails.ein,
        type: companyDetails.type,
        naicsCode: companyDetails.naicsCode,
        size: companyDetails.size,
        url: companyDetails.url,
        addressId: companyDetails.addressId,
        address1: companyDetails.address1,
        address2: companyDetails.address2,
        city: companyDetails.city,
        state: companyDetails.state,
        phone: companyDetails.phone,
        phoneExt: companyDetails.phoneExt,
        postalCode: companyDetails.postalCode,
        fax: companyDetails.fax,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.updateCompanyKYBInfo);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function updateCompanyInfo(client, companyDetails) {
  return new Promise((resolve, reject) => {
    const UPDATE_COMPANY = gql`
      mutation updateCompanyInfo(
        $id: ID!
        $name: String
        $dba: String
        $ein: String
        $type: String
        $naicsCode: String
        $size: String
        $url: String
        $addressId: ID
        $address1: String
        $address2: String
        $city: String
        $state: String
        $phone: String
        $phoneExt: String
        $postalCode: String
        $fax: String
      ) {
        updateCompanyInfo(
          id: $id
          name: $name
          dba: $dba
          ein: $ein
          type: $type
          naicsCode: $naicsCode
          size: $size
          url: $url
          addressId: $addressId
          address1: $address1
          address2: $address2
          city: $city
          state: $state
          phone: $phone
          phoneExt: $phoneExt
          postalCode: $postalCode
          fax: $fax
        ) {
          company {
            id
            name
            dba
            ein
            type
            naicsCode
            size
            url
            limits {
              key
              value
            }
            address {
              id
              address1
              address2
              city
              state
              postalCode
              phone
              phoneExt
              fax
            }
          }
          error
        }
      }
    `;

    const result = client.mutate({
      mutation: UPDATE_COMPANY,
      variables: {
        id: companyDetails.id,
        name: companyDetails.name,
        dba: companyDetails.dba,
        ein: companyDetails.ein,
        type: companyDetails.type,
        naicsCode: companyDetails.naicsCode,
        size: companyDetails.size,
        url: companyDetails.url,
        addressId: companyDetails.addressId,
        address1: companyDetails.address1,
        address2: companyDetails.address2,
        city: companyDetails.city,
        state: companyDetails.state,
        phone: companyDetails.phone,
        phoneExt: companyDetails.phoneExt,
        postalCode: companyDetails.postalCode,
        fax: companyDetails.fax,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.updateCompanyInfo);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getComplianceReports(client) {
  return new Promise((resolve, reject) => {
    const REPORTS = gql`
      query getAdminReports {
        getAdminReports {
          reportType
          reportHistory
        }
      }
    `;
    const result = client.query({
      query: REPORTS,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.getAdminReports);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getComplianceReportLink(client, reportType, reportDate) {
  return new Promise((resolve, reject) => {
    const REPORT_LINK = gql`
      query getAdminReportLink($reportType: String!, $reportDate: String!) {
        getAdminReportLink(reportType: $reportType, reportDate: $reportDate) {
          uri
          expiresAfter
        }
      }
    `;
    const result = client.query({
      query: REPORT_LINK,
      variables: {
        reportType,
        reportDate,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.getAdminReportLink);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getApprovalRequests(client, status) {
  return new Promise((resolve, reject) => {
    const APPROVALS = gql`
      query getApprovalRequests($status: ApprovalStatus) {
        getApprovalRequests(status: $status) {
          id
          entityIdentifier {
            id
            type
          }
          type {
            value
            urlPath
            label
          }
          correlationName
          createdAt
          updatedAt
          status
          updatedBy
          reasonComment
          allReasons
          metadataItems {
            name
            value
          }
        }
      }
    `;
    client
      .query({
        query: APPROVALS,
        variables: { status },
      })
      .then(
        (data) => {
          resolve(data.data.getApprovalRequests);
        },
        (error) => {
          reject(error);
        }
      );
  });
}

export function getUserReviewDocuments(client, userId) {
  return new Promise((resolve, reject) => {
    const GET_REVIEW_DOCUMENTS = gql`
      query getDocumentsWithGetUserById($userId: ID!) {
        getUserById(userId: $userId) {
          id
          email
          username
          documents {
            id
            uri
            contentType
            metadata {
              batchUploadCount
            }
            type
          }
        }
      }
    `;
    client
      .query({
        query: GET_REVIEW_DOCUMENTS,
        variables: { userId },
      })
      .then(
        (data) => {
          resolve(data.data.getUserById);
        },
        (errors) => {
          reject(errors);
        }
      );
  });
}

export function approveYellowPathAppeal(...requestData) {
  return handleYellowPathAppeal("APPROVE", ...requestData);
}

export function approveYellowPathAttestation(...requestData) {
  return handleYellowPathAppeal("APPROVE_ATTESTATION", ...requestData);
}

export function denyYellowPathAppeal(...requestData) {
  return handleYellowPathAppeal("DENY", ...requestData);
}

function handleYellowPathAppeal(actionType, client, yellowPathAppeal) {
  const actions = {
    APPROVE: "approveYellowPathAppeal",
    APPROVE_ATTESTATION: "approveYellowPathAttestation",
    DENY: "denyYellowPathAppeal",
  };
  const mutation = getAppealReasonActionMutation(actionType);
  const responseFieldName = actions[actionType];
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation,
        variables: { yellowPathAppeal },
      })
      .then(
        (data) => {
          resolve(data.data[responseFieldName]);
        },
        (errors) => {
          reject(errors);
        }
      );
  });
}

export function retryYellowPathAppeal(client, { userId }) {
  return new Promise((resolve, reject) => {
    const mutation = gql`
      mutation retryYellowPathDocumentUpload($userId: ID!) {
        retryYellowPathDocumentUpload(userId: $userId)
      }
    `;
    client
      .mutate({
        mutation,
        variables: { userId },
      })
      .then(
        (data) => {
          resolve(data.data.retryYellowPathDocumentUpload);
        },
        (errors) => {
          reject(errors);
        }
      );
  });
}

function getAppealReasonActionMutation(actionType) {
  const mutations = {
    APPROVE: gql`
      mutation approveYellowPathAppeal($yellowPathAppeal: YellowPathAppeal!) {
        approveYellowPathAppeal(yellowPathAppeal: $yellowPathAppeal)
      }
    `,
    DENY: gql`
      mutation denyYellowPathAppeal($yellowPathAppeal: YellowPathAppeal!) {
        denyYellowPathAppeal(yellowPathAppeal: $yellowPathAppeal)
      }
    `,
    APPROVE_ATTESTATION: gql`
      mutation approveYellowPathAttestation(
        $yellowPathAppeal: YellowPathAppeal!
      ) {
        approveYellowPathAttestation(yellowPathAppeal: $yellowPathAppeal)
      }
    `,
  };
  return mutations[actionType];
}

export function requirePortfolioUpdateRequest(client, userId) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation requirePortfolioUpdate($userId: ID!) {
        requirePortfolioUpdate(userId: $userId) {
          state
        }
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        userId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.requirePortfolioUpdate);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function enableFeatureForCompany(client, { companyId, featureId }) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation enableFeatureForCompany($companyId: ID!, $featureId: ID!) {
        enableFeatureForCompany(companyId: $companyId, featureId: $featureId)
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        companyId,
        featureId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.enableFeatureForCompany);
      },
      (errors) => {
        reject(errors);
      }
    );
  });
}

export function getEmployerGroupsByCompanyIdRequest(client, companyId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getEmployerGroupsByCompanyId($companyId: ID!) {
        getEmployerGroupsByCompanyId(id: $companyId) {
          id
          name
          planType
        }
      }
    `;
    client
      .query({
        query: REQUEST,
        variables: { companyId },
      })
      .then(
        (data) => {
          resolve(data.data.getEmployerGroupsByCompanyId);
        },
        (errors) => {
          reject(errors);
        }
      );
  });
}

export function uploadEmployeesDocumentForEmployerRequest(
  client,
  groupId,
  file,
  companyId
) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation uploadEmployeeDocumentForEmployer(
        $companyId: ID!
        $groupId: ID!
        $file: DocumentInput!
      ) {
        uploadEmployeeDocumentForEmployer(
          companyId: $companyId
          groupId: $groupId
          file: $file
        ) {
          id
          documentId
          message
          status
          groupId
          filename
          group {
            planType
            name
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        companyId,
        groupId,
        file,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.uploadEmployeeDocumentForEmployer);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function enableFeaturesForCompany(client, { companyId, featureIds }) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation enableFeaturesForCompany($companyId: ID!, $featureIds: [Int!]) {
        enableFeaturesForCompany(companyId: $companyId, featureIds: $featureIds)
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        companyId,
        featureIds,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.enableFeaturesForCompany);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getProductFeatures(client) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getProductFeatures {
        getProductFeatures {
          id
          name
          description
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: {},
    });
    result.then(
      (data) => {
        resolve(data.data.getProductFeatures);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getPaginatedCompanies(
  client,
  { limit, offset, search, stateFilter }
) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getPaginatedCompanies(
        $limit: Int!
        $offset: Int!
        $search: String
        $stateFilter: String
      ) {
        getPaginatedCompanies(
          limit: $limit
          offset: $offset
          search: $search
          stateFilter: $stateFilter
        ) {
          count
          rows {
            name
            id
            deleted
            createdAt
            currentState
            dba
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: {
        limit,
        offset,
        search,
        stateFilter,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.getPaginatedCompanies);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function linkBank(
  client,
  { accountType, accountNumber, routingNumber, companyId, bankName }
) {
  return new Promise((resolve, reject) => {
    const MUTATION = gql`
      mutation createManualBankLink(
        $companyId: ID!
        $accountNumber: String!
        $routingNumber: String!
        $bankName: String!
        $accountType: BankAccountType
      ) {
        createManualBankLink(
          companyId: $companyId
          accountNumber: $accountNumber
          routingNumber: $routingNumber
          accountType: $accountType
          bankName: $bankName
        )
      }
    `;
    const result = client.mutate({
      mutation: MUTATION,
      variables: {
        accountType,
        accountNumber,
        bankName,
        routingNumber,
        companyId,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getTakeRateForCompany(client, companyId) {
  return new Promise((resolve, reject) => {
    const GET_TAKE_RATE_EMPLOYEES = gql`
      query getTakeRateForCompany($companyId: ID!) {
        getTakeRateForCompany(companyId: $companyId) {
          notStarted
          opening
          enrolled
        }
      }
    `;
    const result = client.query({
      query: GET_TAKE_RATE_EMPLOYEES,
      variables: { companyId },
      fetchPolicy: "network-only",
    });
    result.then(
      (response) => {
        resolve(response.data.getTakeRateForCompany);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getCompanyMembers(client, companyId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getMembersForCompany($companyId: ID!) {
        getMembersForCompany(companyId: $companyId) {
          id
          businessMemberHandle
          role
          ownershipStake
          state
          memberProfile {
            legalName
            firstName
            lastName
            alias
            email
            phoneNumber
            ssn
            citizenshipCountry
            birthCountry
            permanentResident
            dob
            address1
            address2
            city
            state
            postalCode
            maritalStatus
            numberOfDependents
            employeeId
            phone
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { companyId },
      fetchPolicy: "network-only",
    });
    result.then(
      (response) => {
        resolve(response.data.getMembersForCompany);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getMember(client, { companyId, memberId }) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getCompanyMember($companyId: ID!, $memberId: ID!) {
        getCompanyMember(companyId: $companyId, memberId: $memberId) {
          id
          businessMemberHandle
          role
          ownershipStake
          state
          memberProfile {
            legalName
            firstName
            lastName
            alias
            email
            phoneNumber
            ssn
            citizenshipCountry
            birthCountry
            permanentResident
            dob
            address1
            address2
            city
            state
            postalCode
            maritalStatus
            numberOfDependents
            employeeId
            phone
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { companyId, memberId },
      fetchPolicy: "network-only",
    });
    result.then(
      (response) => {
        resolve(response.data.getCompanyMember);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function removeCompanyMember(client, { companyId, memberId }) {
  return new Promise((resolve, reject) => {
    const MUTATION = gql`
      mutation removeCompanyMember($companyId: ID!, $memberId: ID!) {
        removeCompanyMember(companyId: $companyId, memberId: $memberId)
      }
    `;
    const result = client.mutate({
      mutation: MUTATION,
      variables: {
        companyId,
        memberId,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function createOrganization(client, { companyId, name }) {
  return new Promise((resolve, reject) => {
    const MUTATION = gql`
      mutation createOrganization($companyId: ID!, $name: String!) {
        createOrganization(companyId: $companyId, name: $name) {
          organization {
            id
            name
            companyLimit
            linkedCompanies {
              id
              name
              dba
              ein
              type
              size
              url
              planId
              currentState
            }
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: MUTATION,
      variables: {
        companyId,
        name,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.createOrganization);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getOrgForCompany(client, companyId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getOrganizationForCompany($companyId: ID!) {
        getOrganizationForCompany(companyId: $companyId) {
          name
          id
          companyLimit
          linkedCompanies {
            id
            name
            dba
            ein
            type
            size
            url
            planId
            currentState
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { companyId },
      fetchPolicy: "network-only",
    });
    result.then(
      (response) => {
        resolve(response.data.getOrganizationForCompany);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getCompanyAdminsAndAgents(client, companyId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getCompanyAdminsAndAgents($companyId: ID!) {
        getCompanyAdminsAndAgents(companyId: $companyId) {
          user {
            id
            email
            username
            userRoleId
          }
          link {
            id
            invitationExpiresAt
            status
            companyId
            locked
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { companyId },
      fetchPolicy: "network-only",
    });
    result.then(
      (response) => {
        resolve(response.data.getCompanyAdminsAndAgents);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function disableCompanyRequest(client, companyId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation disableCompany($companyId: ID!) {
        disableCompany(companyId: $companyId) {
          state
        }
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: { companyId },
    });
    result.then(
      (response) => {
        resolve(response.data.disableCompany);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getAllOriginations(client, { limit, offset }) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getAllOriginations($limit: Int!, $offset: Int!) {
        getAllOriginations(limit: $limit, offset: $offset) {
          count
          total
          applications {
            apexAccountStatus
            apexAccountId
            iconAccountId
            silaWalletId
            updatedAt
            userInfo {
              id
              firstName
              lastName
              lastFourOfSsn
              state
              email
            }
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { limit, offset },
      fetchPolicy: "network-only",
    });
    result.then(
      (response) => {
        resolve(response.data.getAllOriginations);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getOrigination(client, iconId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getOrigination($iconId: ID!) {
        getOrigination(iconId: $iconId) {
          apexAccountStatus
          apexAccountId
          iconAccountId
          silaWalletId
          updatedAt
          userInfo {
            id
            firstName
            lastName
            lastFourOfSsn
            state
          }

          application {
            versionId
            iraAccountDocument {
              terms {
                termsOfService
                accountAgreement
              }
              trustedContact {
                trustedContact
                firstName
                lastName
                email
              }
              employment {
                status
                employer
                position
              }
              identity {
                firstName
                lastName
                legalName
                address1
                address2
                city
                state
                postalCode
                phone
                dob
                ssn
                citizenshipCountry
                permanentResident
                birthCountry
              }
            }
          }
          silaKycReview {
            message
            verificationStatus
            verificationHistory {
              verificationId
              verificationStatus
              kycLevel
              reasons
              tags
              score
            }
          }
          apexKycReview {
            history {
              user
              stateChange
              archived
            }
            result
          }
          documents {
            id
            name
            uri
            contentType
            createdAt
            updatedAt
            type
            metadata {
              downloadUrl
              contentType
              createdAt
              updatedAt
            }
          }
          approvalRequests {
            id
            entityIdentifier {
              id
              type
            }
            correlationName
            type {
              value
              urlPath
              label
            }
            status
            updatedBy
            reasonComment
            allReasons
            metadataItems {
              name
              value
            }
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { iconId },
      fetchPolicy: "network-only",
    });
    result.then(
      (response) => {
        resolve(response.data.getOrigination);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getKybDocumentTypes(client, companyId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getKybDocumentTypes($companyId: ID!) {
        getKybDocumentTypes(companyId: $companyId) {
          id
          value
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { companyId },
    });
    result.then(
      (response) => {
        resolve(response.data.getKybDocumentTypes);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function submitKYBMemberDocuments(client, { companyId, documents }) {
  return new Promise((resolve, reject) => {
    const QUERY = gql`
      mutation submitKYBMemberDocuments(
        $companyId: ID!
        $documents: [BusinessDocumentInput!]
      ) {
        submitKYBMemberDocuments(companyId: $companyId, documents: $documents)
      }
    `;
    const result = client.mutate({
      mutation: QUERY,
      variables: {
        companyId,
        documents,
      },
    });
    result.then(
      (response) => {
        resolve(response.data.submitKYBMemberDocuments);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function submitKYBBusinessDocuments(client, { companyId, documents }) {
  return new Promise((resolve, reject) => {
    const QUERY = gql`
      mutation submitKYBBusinessDocuments(
        $companyId: ID!
        $documents: [DocumentInput!]
      ) {
        submitKYBBusinessDocuments(companyId: $companyId, documents: $documents)
      }
    `;
    const result = client.mutate({
      mutation: QUERY,
      variables: {
        companyId,
        documents,
      },
    });
    result.then(
      (response) => {
        resolve(response.data.submitKYBBusinessDocuments);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getGroupsAndBanks(client, companyId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getGroupsAndBanks($companyId: ID!) {
        getCompanyBanks(companyId: $companyId) {
          id
          bankName
          bankAlias
          verificationStatus
          accountType
          accountSubtype
          accountId
          correlationId
          state
        }
        getCompanyGroups(companyId: $companyId) {
          id
          name
          payrollFrequency
          useUniqueInviteCodes
          associatedBankAccount {
            id
            bankName
            accountId
          }
          associatedLinkRequests {
            status
            id
            employeeId
            userProfile {
              legalName
              firstName
              lastName
              email
              ssn
              lastFourOfSsn
            }
          }

          schedule {
            anchorPayDate
            displayAnchorPayDate
            displayDayOne
            displayDayTwo
          }
          planType
          companyGroupIndex
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { companyId },
    });
    result.then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getKybStatusForCompany(client, companyId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getKybStatusForCompany($companyId: ID!) {
        getCompanyKybData(companyId: $companyId) {
          employerProfile {
            id
            email
            addressAlias
            address1
            address2
            city
            state
            postalCode
            businessName
            businessType
            doingBusinessAs
            naicsBusinessClassification
            ein
            website
            phone
          }
          silaWallet {
            id
            correlationId
            routingNumber
            accountNumber
            accountType
            name
            active
          }

          businessMembers {
            id
            businessMemberHandle
            role
            ownershipStake
            state
            memberProfile {
              legalName
              firstName
              lastName
              alias
              email
              phoneNumber
              ssn
              citizenshipCountry
              birthCountry
              permanentResident
              dob
              address1
              address2
              city
              state
              postalCode
              maritalStatus
              numberOfDependents
              employeeId
              phone
            }
          }
          documents {
            id
            uri
            contentType
            metadata {
              batchUploadCount
            }
            type
          }
          businessDocuments {
            id
            uri
            contentType
            name
            metadata {
              batchUploadCount
            }
            type
          }
          businessMemberDocuments {
            memberId
            id
            correlationId
            uri
            contentType
            name
            createdAt
            metadata {
              batchUploadCount
            }
            type
          }
        }
        getKybStatusForCompany(companyId: $companyId) {
          count
          kycResponses {
            reference
            message
            status
            success
            entityType
            verificationStatus
            validKycLevels
            verificationHistory {
              verificationId
              verificationStatus
              kycLevel
              requestedAt
              updatedAt
              score
              parentVerification {
                verificationStatus
                kycLevel
                requestedAt
                updatedAt
                score
              }
              tags
              reasons
            }
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { companyId },
    });
    result.then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function searchUsers(client, { search, limit, offset }) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query searchUsers($search: String!, $limit: Int!, $offset: Int!) {
        searchUsers(search: $search, limit: $limit, offset: $offset) {
          totalRows
          results {
            userInfo {
              id
              firstName
              lastName
              lastFourOfSsn
            }
            accounts {
              accountNumber
              type
            }
            contactInfo {
              legalName
              firstName
              lastName
              address1
              address2
              city
              state
              postalCode
              phoneNumber
              email
              dob
              lastFourOfSsn
            }
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { search, limit, offset },
    });
    result.then(
      (response) => {
        resolve(response.data.searchUsers);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getUserDetails(client, userId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getUserDetails($userId: ID!) {
        getUserDetails(userId: $userId) {
          fullSsn
          user {
            firstName
            lastName
            email
            id
            state
          }
          userProfile {
            legalName
            firstName
            lastName
            address1
            address2
            city
            state
            postalCode
            phoneNumber
            email
            dob
            ssn
          }
          accounts {
            id
            routingNumber
            accountNumber
            name
            accountType
            createdAt
            active
            balances {
              withdrawalBalance
              totalAccountBalance
              cashBalance
              principal
              totalPendingContributions
              totalPendingDistributions
            }
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { userId },
    });
    result.then(
      (response) => {
        resolve(response.data.getUserDetails);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getPricingSheet(client) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getPricingSheet {
        getPricingSheet {
          subscriptions {
            id
            metadata
            unitAmount
            recurring
          }
          setupFees {
            id
            metadata
            unitAmount
          }
          perUserFees {
            id
            metadata
            unitAmount
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: {},
    });
    result.then(
      (response) => {
        resolve(response.data.getPricingSheet);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getBillingDetailsForCompany(client, companyId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getBillingDetailsForCompany($companyId: ID!) {
        getBillingDetailsForCompany(companyId: $companyId) {
          monthlyFee
          perUserFee
          nextBillingDate
          subscriptionId
          billingContactEmail
          stripeCustomerId
          metadata
          billingFrequency
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { companyId },
    });
    result.then(
      (response) => {
        resolve(response.data.getBillingDetailsForCompany);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function createInvoiceForCompany(
  client,
  {
    subscriptionId,
    setupFeeId,
    companyId,
    billingContactEmail,
    anchorBillingDate,
    perUserFeeId,
    billedInArrears,
    discountPercent,
    discountLength,
  }
) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation createInvoiceForCompany(
        $companyId: ID!
        $subscriptionId: ID
        $setupFeeId: ID
        $perUserFeeId: ID
        $billingContactEmail: String!
        $anchorBillingDate: String!
        $billedInArrears: Boolean!
        $discountPercent: Int
        $discountLength: DiscountLengths
      ) {
        createInvoiceForCompany(
          companyId: $companyId
          subscriptionId: $subscriptionId
          setupFeeId: $setupFeeId
          perUserFeeId: $perUserFeeId
          billingContactEmail: $billingContactEmail
          anchorBillingDate: $anchorBillingDate
          billedInArrears: $billedInArrears
          discountPercent: $discountPercent
          discountLength: $discountLength
        ) {
          monthlyFee
          perUserFee
          nextBillingDate
          subscriptionId
          billingContactEmail
          stripeCustomerId
          metadata
          billingFrequency
        }
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        subscriptionId,
        setupFeeId,
        companyId,
        billingContactEmail,
        anchorBillingDate,
        perUserFeeId,
        billedInArrears,
        discountPercent,
        discountLength,
      },
    });
    result.then(
      (response) => {
        resolve(response.data.createInvoiceForCompany);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getPendingAndLinkedEmployers(client, userId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getPendingAndLinkedEmployers($userId: ID!) {
        getPendingAndLinkedEmployers(userId: $userId) {
          pendingEmployers {
            id
            status
            company {
              id
              name
              planId
              enabledProductFeatures
            }
            group {
              id
              payrollFrequency
              companyGroupIndex
              useUniqueInviteCodes
              name
            }
            contribution {
              amount
              id
              contributionChangeRequests {
                userCurrentContributionAmount
                userNewContributionAmount
                status
                id
              }
            }
          }

          linkedEmployers {
            id
            deleted
            company {
              id
              name
              planId
            }
            group {
              id
              name
              payrollFrequency
              companyGroupIndex
            }
            contribution {
              amount
              id
              contributionChangeRequests {
                status
                id
                userCurrentContributionAmount
                userNewContributionAmount
              }
            }
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { userId },
    });
    result.then(
      (response) => {
        resolve(response.data.getPendingAndLinkedEmployers);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function liquidateUserAccount(client, { userId, accountId }) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation liquidateUserAccount($userId: ID!, $accountId: ID!) {
        liquidateUserAccount(userId: $userId, accountId: $accountId)
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        userId,
        accountId,
      },
    });
    result.then(
      (response) => {
        resolve(response.data.liquidateUserAccount);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function resetUserState(client, userId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation resetUserState($userId: ID!) {
        resetUserState(userId: $userId)
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        userId,
      },
    });
    result.then(
      (response) => {
        resolve(response.data.resetUserState);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function partiallyDisburseUserAccount(
  client,
  { userId, accountId, reason, amount }
) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation partiallyDisburseUserAccount(
        $userId: ID!
        $accountId: ID!
        $reason: String!
        $amount: Float!
      ) {
        partiallyDisburseUserAccount(
          userId: $userId
          accountId: $accountId
          reason: $reason
          amount: $amount
        )
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        userId,
        accountId,
        reason,
        amount,
      },
    });
    result.then(
      (response) => {
        resolve(response.data.partiallyDisburseUserAccount);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getPayrolls(
  client,
  { companyId, order, status, offset, limit }
) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getPayrolls(
        $companyId: ID
        $status: PayrollStatus
        $order: Order
        $offset: Int!
        $limit: Int!
      ) {
        getPayrolls(
          status: $status
          order: $order
          companyId: $companyId
          limit: $limit
          offset: $offset
        ) {
          totalRows
          currentOffset
          limit
          nextOffset
          pageSize
          results {
            id
            employerId
            totalAmount
            bankId
            status
            state
            createdAt
            payrollDocumentId
            period {
              start
              end
              displayStart
              displayEnd
            }
            metadata
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { companyId, order, status, offset, limit },
    });
    result.then(
      (response) => {
        resolve(response.data.getPayrolls);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function reopenUserAccount(
  client,
  { userId, accountId, reason, isAdministrativeReopen }
) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation reopenUserAccount(
        $userId: ID!
        $accountId: ID!
        $reason: String!
        $isAdministrativeReopen: Boolean!
      ) {
        reopenUserAccount(
          userId: $userId
          accountId: $accountId
          reason: $reason
          isAdministrativeReopen: $isAdministrativeReopen
        )
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        userId,
        accountId,
        reason,
        isAdministrativeReopen,
      },
    });
    result.then(
      (response) => {
        resolve(response.data.reopenUserAccount);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getContributionAbilityRequest(client, userId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getContributionAbility($userId: ID!) {
        getContributionAbility(userId: $userId) {
          userId
          currentYear {
            taxYear
            totalContributionAmount
            amountRemaining
            limit
            canContribute
          }
          priorYear {
            taxYear
            totalContributionAmount
            amountRemaining
            limit
            canContribute
          }
          isWithinPriorYearContributionWindow
          allYears {
            taxYear
            totalContributionAmount
            amountRemaining
            limit
            canContribute
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { userId },
    });
    result.then(
      (response) => {
        resolve(response.data.getContributionAbility);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getEntityLimitsRequest(client, entityType, entityId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getEntityLimits($entityType: EntityType!, $entityId: ID) {
        getEntityLimits(entityType: $entityType, entityId: $entityId) {
          key
          value
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { entityType, entityId },
    });
    result.then(
      (response) => {
        resolve(response.data.getEntityLimits);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function updateEntityLimitRequest(
  client,
  { entityType, entityId, key, value }
) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation updateEntityLimit(
        $entityType: EntityType!
        $entityId: ID
        $key: String!
        $value: String!
      ) {
        updateEntityLimit(
          entityType: $entityType
          entityId: $entityId
          key: $key
          value: $value
        ) {
          key
          value
        }
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        entityType,
        entityId,
        key,
        value,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.updateEntityLimit);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function linkEmployerToCrmRequest(
  client,
  { companyId, providerReferenceId }
) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation linkEmployerToCrm($companyId: ID!, $providerReferenceId: ID!) {
        linkEmployerToCrm(
          companyId: $companyId
          providerReferenceId: $providerReferenceId
        )
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        companyId,
        providerReferenceId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.linkEmployerToCrm);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function unlinkEmployerToCrmRequest(client, { companyId, linkId }) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation unlinkEmployerToCrm($companyId: ID!, $linkId: ID!) {
        unlinkEmployerToCrm(companyId: $companyId, linkId: $id)
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        companyId,
        linkId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.unlinkEmployerToCrm);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getEmployerCrmLinkRequest(client, { companyId }) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getEmployerCrmLink($companyId: ID!) {
        companyById(companyId: $companyId) {
          crmLink {
            id
            providerReferenceId
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { companyId },
    });
    result.then(
      (response) => {
        resolve(response.data.companyById.crmLink);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function syncCrmRequest(client, { companyId }) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation syncCrm($companyId: ID!) {
        syncCrm(companyId: $companyId)
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        companyId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.syncCrm);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function updateBillingType(client, { companyId, billingType }) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation updateBillingType($companyId: ID!, $billingType: String!) {
        updateBillingType(companyId: $companyId, billingType: $billingType)
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        companyId,
        billingType,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.updateBillingType);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getAllUnprocessedPayrollsRequest(
  client,
  { companyId, limit, offset, date }
) {
  return new Promise((resolve, reject) => {
    const request = gql`
      query unprocessedPayrolls(
        $companyId: ID
        $limit: Int!
        $offset: Int!
        $date: Date!
      ) {
        unprocessedPayrolls(
          companyId: $companyId
          limit: $limit
          offset: $offset
          date: $date
        ) {
          total
          offset
          limit
          nextOffset
          count
          results {
            payrollId
            start
            end
            payrollProcessingDate
            onOrAfterPayrollProcessingDate
            lastPayrollApprovalDate
            status
            gracePeriodEndDate
            payPeriodStart
            payPeriodEnd
            periodState
            payPeriodId
            groupId
            userCount
            payrollType
            amount
            entity {
              id
            }
          }
        }
      }
    `;
    const result = client.query({
      query: request,
      variables: {
        companyId,
        limit,
        offset,
        date,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.unprocessedPayrolls);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getWorkflows(
  client,
  { entityId, status, offset, limit, updatedAtStart, updatedAtEnd, name, id }
) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getPaginatedWorkflows(
        $entityId: ID
        $status: WorkflowStatus
        $offset: Int!
        $limit: Int!
        $name: String
        $id: ID
        $updatedAtStart: String
        $updatedAtEnd: String
      ) {
        getPaginatedWorkflows(
          status: $status
          entityId: $entityId
          limit: $limit
          offset: $offset
          updatedAtStart: $updatedAtStart
          name: $name
          id: $id
          updatedAtEnd: $updatedAtEnd
        ) {
          totalRows
          currentOffset
          limit
          nextOffset
          pageSize
          filterValues
          results {
            createdAt
            description
            entityId
            entityType
            id
            leaseExpiresAt
            name
            pausedUntil
            status
            updatedAt
            workflow {
              description
              id
              name
              params
              root {
                class
                className
                description
                id
                sibling {
                  class
                  className
                  description
                  id
                }
              }
            }
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: {
        entityId,
        status,
        offset,
        limit,
        updatedAtStart,
        updatedAtEnd,
        name,
        id,
      },
    });
    result.then(
      (response) => {
        resolve(response.data.getPaginatedWorkflows);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getLastWorkflowStep(client, workflowId) {
  return new Promise((resolve, reject) => {
    const request = gql`
      query getLastWorkflowStep($workflowId: ID!) {
        getLastWorkflowStep(workflowId: $workflowId) {
          id
          stepId
          className
          updatedAt
          result {
            status
            output
          }
        }
      }
    `;
    const result = client.query({
      query: request,
      variables: {
        workflowId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.getLastWorkflowStep);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getReportTypes(client) {
  return new Promise((resolve, reject) => {
    const request = gql`
      query getReportTypes {
        getReportTypes {
          description
          reportType
          parameters {
            name
            description
            type
            isArray
            required
          }
        }
      }
    `;
    const result = client.query({
      query: request,
    });
    result.then(
      (data) => {
        resolve(data.data.getReportTypes);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getCustomReports(client, { limit, offset }) {
  return new Promise((resolve, reject) => {
    const request = gql`
      query getCustomReports($limit: Int!, $offset: Int!) {
        getCustomReports(limit: $limit, offset: $offset) {
          reports {
            id
            locationUri
            status
            reportRequest {
              reportName
              requestedBy
              reportDefinition {
                reportType
                description
              }
            }
          }
          count
        }
      }
    `;
    const result = client.query({
      query: request,
      variables: {
        limit,
        offset,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.getCustomReports);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getReportCSV(client, reportId) {
  return new Promise((resolve, reject) => {
    const request = gql`
      query getReportCSV($reportId: ID!) {
        getReportCSV(reportId: $reportId)
      }
    `;
    const result = client.query({
      query: request,
      variables: {
        reportId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.getReportCSV);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function createCustomReport(
  client,
  {
    startDate,
    endDate,
    reportName,
    reportType,
    requestedBy,
    employerId,
    userId,
    accountId,
    accountNumber,
    entityId,
    entityType,
    employerIds,
    entityIds,
    userIds,
    accountIds,
    ids,
  }
) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation createCustomReport(
        $requestedBy: ID!
        $reportName: String!
        $reportType: String!
        $params: CustomReportInput!
      ) {
        createCustomReport(
          requestedBy: $requestedBy
          reportName: $reportName
          params: $params
          reportType: $reportType
        )
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        requestedBy,
        reportName,
        reportType,
        params: {
          startDate,
          endDate,
          employerId,
          userId,
          accountId,
          accountNumber,
          entityId,
          entityType,
          employerIds,
          entityIds,
          userIds,
          ids,
          accountIds,
        },
      },
    });
    result.then(
      (data) => {
        resolve(data.data.getWorkflowSteps);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function updateBillingContact(client, { email, companyId }) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation updateCompanyBillingContact($companyId: ID!, $email: String!) {
        updateCompanyBillingContact(companyId: $companyId, email: $email) {
          monthlyFee
          perUserFee
          nextBillingDate
          subscriptionId
          billingContactEmail
          stripeCustomerId
          metadata
        }
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        email,
        companyId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.updateCompanyBillingContact);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function approvePayrollIntegrationSetup(client, { companyId }) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation approvePayrollIntegrationSetup($companyId: ID!) {
        approvePayrollIntegrationSetup(companyId: $companyId)
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        companyId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.approvePayrollIntegrationSetup);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getKybStatus(client, companyId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getKybStatus($companyId: ID!) {
        getKybStatus(companyId: $companyId) {
          verificationStatus
          count
          kycResponses {
            rawResponse {
              reference
              message
              status
              success
              entityType
              verificationStatus
              verificationHistory {
                verificationId
                verificationStatus
                kycLevel
                requestedAt
                updatedAt
                score
                parentVerification {
                  verificationStatus
                  kycLevel
                  requestedAt
                  updatedAt
                  score
                }
                tags
                reasons
              }
            }
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { companyId },
    });
    result.then(
      (response) => {
        resolve(response.data.getKybStatus);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getReportsStatuses(client, reportIds) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getReportsStatus($reportIds: [ID!]) {
        getReportsStatus(reportIds: $reportIds) {
          id
          locationUri
          status
          reportRequest {
            reportName
            requestedBy
            reportDefinition {
              reportType
              description
            }
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { reportIds },
    });
    result.then(
      (response) => {
        resolve(response.data.getReportsStatus);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function migrateEmployeesForCompany(
  client,
  { companyId, sourceGroupId, targetGroupId, employeesForMigration }
) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation migrateEmployeesForCompany(
        $employeesForMigration: [ID!]!
        $companyId: ID!
        $sourceGroupId: ID!
        $targetGroupId: ID!
      ) {
        migrateEmployeesForCompany(
          companyId: $companyId
          sourceGroupId: $sourceGroupId
          targetGroupId: $targetGroupId
          employeesForMigration: $employeesForMigration
        ) {
          id
          name
          payrollFrequency
          useUniqueInviteCodes
          associatedBankAccount {
            id
            bankName
            accountId
          }
          associatedLinkRequests {
            status
            id
            employeeId
            userProfile {
              legalName
              firstName
              lastName
              email
              ssn
              lastFourOfSsn
            }
          }
          schedule {
            anchorPayDate
            displayAnchorPayDate
            displayDayOne
            displayDayTwo
          }
          planType
          companyGroupIndex
        }
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        companyId,
        sourceGroupId,
        targetGroupId,
        employeesForMigration,
      },
    });
    result.then(
      (response) => {
        resolve(response.data.migrateEmployeesForCompany);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function createQuote(
  client,
  { companyName, companySize, companyContact, metadata }
) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation createSalesQuote(
        $companyName: String!
        $companySize: String!
        $companyContact: String!
        $metadata: JSON!
      ) {
        createSalesQuote(
          companyName: $companyName
          companySize: $companySize
          companyContact: $companyContact
          metadata: $metadata
        ) {
          id
          quoteCode
          createdBy
          createdAt
          metadata
          companySize
          companyContact
          redeemed
        }
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: {
        companyName,
        metadata,
        companySize,
        companyContact,
      },
    });
    result.then(
      (response) => {
        resolve(response.data.createSalesQuote);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getSalesQuotes(client, { limit, offset }) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getSalesQuotes($limit: Int!, $offset: Int!) {
        getSalesQuotes(limit: $limit, offset: $offset) {
          rows {
            id
            quoteCode
            companyName
            createdBy
            metadata
            createdAt
            expiresAt
            companySize
            companyContact
            redeemed
          }
          count
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { limit, offset },
    });
    result.then(
      (response) => {
        resolve(response.data.getSalesQuotes);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function deleteQuote(client, quoteId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      mutation deleteSalesQuote($quoteId: ID!) {
        deleteSalesQuote(quoteId: $quoteId)
      }
    `;
    const result = client.mutate({
      mutation: REQUEST,
      variables: { quoteId },
    });
    result.then(
      () => {
        resolve(quoteId);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export const adminService = {
  submitKYBUser,
  updateCompanyKYBInfo,
  getApprovalRequest,
  setApproval,
  getAllCompanies,
  getComplianceReports,
  getTakeRateForCompany,
  getCompany,
  getUserReviewDocuments,
  requirePortfolioUpdateRequest,
  enableFeatureForCompany,
  getEmployerGroupsByCompanyIdRequest,
  uploadEmployeesDocumentForEmployerRequest,
  getProductFeatures,
  enableFeaturesForCompany,
  getOrgForCompany,
  removeCompanyMember,
  disableCompanyRequest,
  getOrigination,
  getCompanyKybData,
  getKybStatusForCompany,
  submitKYBBusinessDocuments,
  getPricingSheet,
  getBillingDetailsForCompany,
  createInvoiceForCompany,
  getPendingAndLinkedEmployers,
  liquidateUserAccount,
  resetUserState,
  partiallyDisburseUserAccount,
  getPayrolls,
  reopenUserAccount,
  getEntityLimitsRequest,
  updateEntityLimitRequest,
  getEmployerCrmLinkRequest,
  linkEmployerToCrmRequest,
  unlinkEmployerToCrmRequest,
  syncCrmRequest,
  getAllUnprocessedPayrollsRequest,
  updateBillingType,
  getWorkflows,
  getLastWorkflowStep,
  getReportTypes,
  getCustomReports,
  approvePayrollIntegrationSetup,
  updateBillingContact,
  getKybStatus,
  migrateEmployeesForCompany,
  getSalesQuotes,
  createQuote,
  deleteQuote,
};
