import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, Col, Form, Row } from "react-bootstrap";
import { push } from "connected-react-router";
import { debounce, find, isEmpty } from "lodash";
import { searchAllUsers } from "actions/adminActions";
import { createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { FiUsers } from "react-icons/fi";
import { Link } from "react-router-dom";

import IconEmptyState from "components/IconEmptyState";
import IconSpinner from "components/IconSpinner";
import Paginator from "components/Paginator";
import IconTable from "components/IconTable";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import { ROTH_IRA, TRADITIONAL_IRA } from "statics/accountTypes";

class Users extends React.PureComponent {
  static propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({})),
    searchAllUsers: PropTypes.func,
    client: PropTypes.object,
    push: PropTypes.func,
    isFetching: PropTypes.bool,
    usersCount: PropTypes.number,
  };

  constructor() {
    super();

    this.state = {
      limit: 50,
      offset: 0,
      search: "",
      page: 0,
    };
  }

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () => this._searchUsers());
  };

  _searchUsers = () => {
    return this.props.searchAllUsers(this.props.client, {
      limit: this.state.limit,
      offset: this.state.offset,
      search: this.state.search,
    });
  };

  _onSearchChange = (e) => {
    this.setState(
      {
        search: e.target.value,
        offset: 0,
        page: 0,
      },
      () => this._searchUsers()
    );
  };

  _debounceOnChange = debounce(this._onSearchChange, 500);

  _getUsersData = () => {
    return this.props.users.map((user) => {
      const { userInfo, contactInfo, accounts } = user;
      const apexAccount = find(accounts, (account) => {
        return account.type === TRADITIONAL_IRA || account.type === ROTH_IRA;
      });

      const fullName = `${userInfo.firstName} ${userInfo.lastName}`;
      const apex = apexAccount ? apexAccount.accountNumber : "";

      return {
        id: userInfo.id,
        fullName,
        email: contactInfo.email,
        apex,
        userInfo,
      };
    });
  };

  _buildUsersColumns = () => {
    const columns = [
      {
        label: "User Id",
        key: "id",
      },

      {
        label: "User Name",
        key: "fullName",
      },
      {
        label: "Email",
        key: "email",
      },

      {
        label: "Apex Id",
        key: "apex",
      },
      {
        customComponent: (props) => {
          return (
            <Link to={`/users/${props.userInfo.id}`} className="icon-link">
              View Info
            </Link>
          );
        },
      },
    ];
    return columns;
  };

  _clearFilters = () => {
    this._textInput.value = "";
    this.setState(
      {
        search: "",
        offset: 0,
      },
      () => this._searchUsers()
    );
  };

  render() {
    const showBlankSlate =
      !this.props.isFetching &&
      isEmpty(this.props.users) &&
      !!this.state.search;

    const hideTable =
      this.props.isFetching || showBlankSlate || !this.state.search;

    const columns = this._buildUsersColumns();
    const data = this._getUsersData();

    return (
      <div className="mega-container" id={"admin-users-page"}>
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Users</h1>

            <div className="main-content">
              <Card>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <span>
                        <IconHeader
                          variant="labelHeader"
                          headerText="Search User Info"
                        />
                        <IconSubheader
                          subheader="Search by name, id, email, apex id or any user profile
                          information"
                        />
                      </span>
                      <div className="search-bar" style={{ paddingTop: 15 }}>
                        <Form.Control
                          name="search"
                          placeholder="search"
                          onChange={this._debounceOnChange}
                          ref={(node) => (this._textInput = node)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                {this.props.isFetching && <IconSpinner centered />}

                {showBlankSlate && (
                  <IconEmptyState
                    header={
                      "There were no users found matching your search or filters."
                    }
                    subheader={"Would you like to clear your search?"}
                    icon={<FiUsers color="white" stroke="#60A4BF" size={16} />}
                    actionText={"Clear Filters"}
                    onClick={this._clearFilters}
                  />
                )}

                {!hideTable && (
                  <>
                    <IconTable columns={columns} data={data} />
                    <div
                      style={{
                        padding: 20,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Paginator
                        onChange={this._onPageChange}
                        pageCount={this.props.usersCount / this.state.limit}
                        page={this.state.page}
                      />
                    </div>
                  </>
                )}
              </Card>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const isLoadingSelector = createLoadingSelector(adminConstants.SEARCH_USERS);

const mapStateToProps = (state) => {
  return {
    isFetching: isLoadingSelector(state),
    users: state.admin.foundUsers,
    usersCount: state.admin.foundUsersCount,
  };
};

const mapDispatchToProps = {
  push,
  searchAllUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(withApollo(Users));
