import { userConstants, documentConstants } from "actions/types";

const initialState = {
  documents: [],
  statements: [],
};

export function userDocuments(state = initialState, action) {
  switch (action.type) {
    case userConstants.USER_UPLOADED_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: [action.data, ...state.documents],
      };

    case documentConstants.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        statements: action.data,
      };

    case userConstants.CLEAR_USER_UPLOADED_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: [],
      };

    default:
      return state;
  }
}
