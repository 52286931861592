import React from "react";
import PropTypes from "prop-types";

import { every, map, trim } from "lodash";
import { Formik } from "formik";
import { Col, Form } from "react-bootstrap";
import Button from "components/Button";

import Alert from "components/Alert";

import { testUsername } from "utils/fieldValidators";
import { rolesToEnglish } from "statics/roleToEnglish";
import { ScrollToFieldError } from "utils/form";

let yup = require("yup");

const roles = ["AGENT", "ADMIN"];

function testExistingUserEmail(email) {
  return every(
    this.parent.administrators,
    ({ user: admin }) => admin.email !== email
  );
}

function testExistingUsername(username) {
  return every(
    this.parent.administrators,
    ({ user: admin }) => admin.username !== username
  );
}

const schema = yup.object({
  email: yup
    .string()
    .label("Administrator Email")
    .email("You must enter a valid email address.")
    .test(
      "administrators",
      "User has already been invited.",
      testExistingUserEmail
    )
    .required(),
  username: yup
    .string()
    .label("Administrator Username")
    .required()
    .min(2)
    .max(25)
    .test("username", "User has already been invited.", testExistingUsername)
    .test("username", "Invalid username.", testUsername),
  role: yup.string().label("Role").required(),

  firstName: yup.string().label("First Name").required(),
  lastName: yup.string().label("Last Name").required(),
});

export default class AdministratorForm extends React.PureComponent {
  static propTypes = {
    isInviting: PropTypes.bool,
    onSubmit: PropTypes.func,
    error: PropTypes.string,
    fixedRole: PropTypes.string,
    administrators: PropTypes.arrayOf(PropTypes.object),
    onCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      email: "",
      username: "",
      firstName: "",
      lastName: "",
      role: "AGENT",
    };
  }

  _onSubmit = (values, skipValidation) => {
    const emailDomainMatches = this._validateEmailDomain(values.email);

    if (!emailDomainMatches && !skipValidation) {
      return this.setState({
        showEmailDomainWarning: true,
        email: values.email,
        username: values.username,
        firstName: values.firstName,
        lastName: values.lastName,
        role: values.role,
      });
    }

    this.props.onSubmit({
      ...values,
      firstName: trim(values.firstName),
      lastName: trim(values.lastName),
      username: trim(values.username),
    });
  };

  _validateEmailDomain = (email) => {
    const preexistingDomains = map(this.props.administrators, (admin) => {
      return admin.user.email.split("@")[1];
    });

    const emailDomain = email.split("@")[1];

    return preexistingDomains.includes(emailDomain);
  };

  _getEmailDomainWarning = () => {
    return (
      <div className="domain-warning">
        <p>
          Warning: The domain in the new administrators email (
          {this.state.email}) does not match existing administrator email
          domains.
        </p>
        <p>Is this correct?</p>
        <div className="btn-row">
          <Button
            btnLabel="Go Back"
            color="cancel"
            name="cancel"
            type="button"
            onClick={() => {
              this.setState({ showEmailDomainWarning: false });
            }}
          />
          <Button
            btnLabel="Continue"
            color="primary"
            name="action"
            type="button"
            onClick={() => {
              this.setState({ showEmailDomainWarning: false });
              this._onSubmit(
                {
                  email: this.state.email,
                  username: this.state.username,
                  firstName: this.state.firstName,
                  lastName: this.state.lastName,
                  role: this.state.role,
                },
                true
              );
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    const role = this.props.fixedRole || this.state.role;

    if (this.state.showEmailDomainWarning) {
      return this._getEmailDomainWarning();
    }

    return (
      <div className="employee-invite-form">
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={(values) => this._onSubmit(values)}
          enableReinitialize={true}
          initialValues={{
            ...this.state,
            role,
            administrators: this.props.administrators,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            handleBlur,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="formBasicUserEmail">
                  <Form.Label>Administrator Email</Form.Label>
                  <Form.Control
                    name="email"
                    placeholder="Administrator Email"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.email && !!errors.email}
                    isValid={touched.email && !errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="formBasicUsername">
                  <Form.Label>Administrator Username</Form.Label>
                  <Form.Control
                    name="username"
                    placeholder="Administrator Username"
                    value={values.username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.username && !!errors.username}
                    isValid={touched.username && !errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="formAdminRole">
                  <Form.Label>Administrator role</Form.Label>
                  <Form.Control
                    sm={4}
                    as="select"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!!this.props.fixedRole}
                    isInvalid={touched.role && !!errors.role}
                    isValid={touched.role && !errors.role}
                  >
                    <option value="" disabled>
                      Administrator Role
                    </option>
                    {roles.map((role) => (
                      <option value={role} key={role}>
                        {rolesToEnglish[role]}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.role}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="formBasicFirstName">
                  <Form.Label>Administrator First Name</Form.Label>
                  <Form.Control
                    name="firstName"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.firstName && !!errors.firstName}
                    isValid={touched.firstName && !errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={6} controlId="formBasicLastName">
                  <Form.Label>Administrator Last Name</Form.Label>
                  <Form.Control
                    name="lastName"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.lastName && !!errors.lastName}
                    isValid={touched.lastName && !errors.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Group as={Col}>
                {this.props.error && (
                  <Alert type="error" msg={this.props.error} />
                )}

                <div className="submit-row">
                  <span className="cancel-btn">
                    <Button
                      btnLabel="Cancel"
                      color="cancel"
                      name="cancel"
                      type="button"
                      onClick={() => {
                        this.props.onCancel();
                      }}
                    />
                  </span>
                  <Button
                    btnLabel="Invite Administrator"
                    name="submit"
                    withArrow={true}
                    loading={this.props.isInviting}
                  />
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
