import React from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import TransferWidget from "components/transferWidget/TransferWidget";

import "./ContributionModal.scss";

class ContributionModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    show: PropTypes.bool,
    depositOnly: PropTypes.bool,
    options: PropTypes.shape({
      isApplyToPriorYear: PropTypes.bool,
    }),
  };

  render() {
    if (this.props.show) {
      return (
        <Modal
          centered
          scrollable
          show={this.props.show}
          onHide={noop}
          className="contribution-modal"
        >
          <Modal.Body>
            <TransferWidget
              {...this.props.options}
              onClose={this.props.onClose}
              onSuccess={this.props.onSuccess}
              depositOnly={this.props.depositOnly}
            />
          </Modal.Body>
        </Modal>
      );
    }
    return null;
  }
}

export default ContributionModal;
