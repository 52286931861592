import React from "react";
import { Router } from "react-router-dom";
import history from "utils/history";
import { hot } from "react-hot-loader";
import { useDispatch } from "react-redux";
import { useApolloClient } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";
import queryString from "query-string";
import { get, isEmpty, isNil } from "lodash";
import { useAuthApollo } from "utils/AuthApolloInitializer";
import { useAuth0 } from "utils/react-auth0-wrapper";
import { getUserState, setInitialUserLoading } from "actions/userActions";
import {
  getLinkedCompanies,
  setEmployerSession,
} from "actions/employerActions";
import { adminSite, employerSite, individualSite } from "utils/determineSite";
import { useMediaQuery } from "react-responsive";

import AccessTokenStorage from "utils/AccessTokenStorage";
import Loading from "components/Loading";
import IndividualRoutes from "routes/IndividualRoutes";
import AdminRoutes from "routes/AdminRoutes";
import EmployerRoutes from "routes/EmployerRoutes";
import IconLayout from "./components/IconLayout";

import "./Employer.scss";
import "./Individual.scss";
import "./Admin.scss";

import "react-toastify/dist/ReactToastify.css";

import "./App.scss";
import { getPath } from "store/selectors/auth0";
import { setAccountSession } from "actions/accountActions";

const App = () => {
  const { user, isAuthenticated, logout } = useAuth0();
  const { tokenLoading } = useAuthApollo();
  const dispatch = useDispatch();
  const handleOnIdle = () => {
    // stop the idle timer for the login page -- it should only be started when the user is authenticated
    reset();
    logout({
      returnTo: `${window.location.origin}/login?redirect_to=${window.location.pathname}${window.location.search}`,
    });
  };

  const thirtyMinutes = 30 * 1000 * 60;
  const { reset, start } = useIdleTimer({
    timeout: thirtyMinutes,
    onIdle: handleOnIdle,
    startOnMount: false,
    startManually: true,
  });

  if (!tokenLoading && AccessTokenStorage.getToken()) {
    // Idle timer starts unmounted to account for the login page. Once authenticated, begin the timer
    start();
  }
  if (tokenLoading) {
    console.log("Loading...");
    return <Loading />;
  } else {
    console.log("Providers Loaded.");

    // Setup initial state, and getState if logged in
    const setupState = async () => {
      dispatch(setInitialUserLoading(true));
      try {
        // ensure that we call getState to set the app up properly in case earlier async
        // calls to it (e.g. from /login redirects) failed
        if (isAuthenticated) {
          const client = useApolloClient();
          const response = await dispatch(getUserState(client, user));
          const companyId = get(response.data, "company.id");
          if (employerSite()) {
            // This will execute during employer registration and cause a false positive error unless we check for email verification
            if (user.email_verified && !isNil(companyId)) {
              dispatch(getLinkedCompanies(client));
            }
            const targetCompanyId = get(
              queryString.parse(window.location.search),
              "targetCompany"
            );
            const companyIdsDoNotMatch =
              !isEmpty(targetCompanyId) && companyId !== targetCompanyId;
            if (companyIdsDoNotMatch) {
              const employerSessionResponse = await dispatch(
                setEmployerSession(client, targetCompanyId)
              );
              // only reload page if the session change actually worked
              if (isEmpty(employerSessionResponse.error)) {
                window.location.reload();
              } else {
                history.push({
                  pathname: window.location.pathname,
                  search: "",
                });
              }
            }
          }

          if (individualSite()) {
            const activeAccountIdPath = `${getPath()}/active_account_id`;

            const accountId = get(user, activeAccountIdPath);
            const targetAccountId = get(
              queryString.parse(window.location.search),
              "account_id"
            );

            if (!isEmpty(targetAccountId)) {
              const accountIdsDoNotMatch = accountId !== targetAccountId;
              if (accountIdsDoNotMatch) {
                const accountSessionResponse = await dispatch(
                  setAccountSession(client, targetAccountId)
                );
                if (isEmpty(accountSessionResponse.error)) {
                  window.location.reload();
                } else {
                  history.push({
                    pathname: window.location.pathname,
                    search: "",
                  });
                }
              } else {
                // remove from url
                window.history.pushState({}, "", window.location.pathname);
              }
            }
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setInitialUserLoading(false));
      }
    };

    setupState();

    let activeRoutes = null;

    if (employerSite()) {
      activeRoutes = <EmployerRoutes />;
    } else {
      if (individualSite()) {
        activeRoutes = <IndividualRoutes />;
      } else if (adminSite()) {
        activeRoutes = <AdminRoutes isAuthenticated={isAuthenticated} />;
      }
    }
    const queryObj = queryString.parse(window.location.search);
    if (queryObj.redirect_to) {
      history.push(queryObj.redirect_to);
    }

    return (
      <Router history={history}>
        <IconLayout
          routes={activeRoutes}
          isSmallScreen={useMediaQuery({ maxWidth: 767 })}
        />
        <ToastContainer position="bottom-right" />
      </Router>
    );
  }
};

export default hot(module)(App);
