import gql from "graphql-tag";

export function getAllTransactions(client, accountId) {
  return new Promise((resolve, reject) => {
    const TRANSACTIONS = gql`
      query getAllTransactions($accountId: ID!) {
        getAllTransactions(accountId: $accountId) {
          memo
          status
          updatedAt
          id
          createdAt
          category
          cancellationStatus
          subCategory
          taxYear
        }
      }
    `;
    const result = client.query({
      query: TRANSACTIONS,
      variables: { accountId },
      // making a contribution creates a new transaction on the backend but if you request
      // transactions again from client it returns cached results. For now we wont cache until
      // can solve the cache issue
      fetchPolicy: "network-only",
    });
    result.then(
      (data) => {
        resolve(data.data.getAllTransactions);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
export function getCashflowTransactions(client, accountId) {
  return new Promise((resolve, reject) => {
    const CASHFLOW_TRANSACTIONS = gql`
      query getCashflowTransactions($accountId: ID!) {
        getCashflowTransactions(accountId: $accountId) {
          memo
          status
          updatedAt
          createdAt
      }
    `;
    const result = client.query({
      query: CASHFLOW_TRANSACTIONS,
      variables: { accountId },
    });
    result.then(
      (data) => {
        resolve(data.data.getCashflowTransactions);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
export function getTradeTransactions(client, accountId) {
  return new Promise((resolve, reject) => {
    const TRADE_TRANSACTIONS = gql`
      query getTradeTransactions($accountId: ID!) {
        getTradeTransactions(accountId: $accountId) {
          memo
          status
          updatedAt
          createdAt
        }
      }
    `;
    const result = client.query({
      query: TRADE_TRANSACTIONS,
      variables: { accountId },
    });
    result.then(
      (data) => {
        resolve(data.data.getTradeTransactions);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function cancelTransaction(client, accountId, transactionId) {
  return new Promise((resolve, reject) => {
    const CANCEL_TRANSACTION = gql`
      mutation cancelTransaction($accountId: ID!, $transactionId: ID!) {
        cancelTransaction(accountId: $accountId, transactionId: $transactionId)
      }
    `;
    const result = client.mutate({
      mutation: CANCEL_TRANSACTION,
      variables: { accountId, transactionId },
    });
    result.then(
      (data) => {
        resolve(data.data.cancelTransaction);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export default {
  getAllTransactions,
  getCashflowTransactions,
  cancelTransaction,
  getTradeTransactions,
};
