import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { get, isEmpty } from "lodash";
import { push } from "connected-react-router";
import Alert from "components/Alert";
import IconSpinner from "components/IconSpinner";
import ContributionSelector from "components/ContributionSelector";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import {
  getEmployerLinkRequests,
  getUserState,
  updateRequiredDisclosures,
} from "actions/userActions";

import {
  accountConstants,
  contributionConstants,
  userConstants,
} from "actions/types";
import { scrollToTop } from "utils/dom";
import { getIraAccountIdSelector } from "store/selectors/user";
import {
  addPaycheckContributionSource,
  getContributionRecommendations,
} from "actions/contributionActions";
import { recommendedContributionType } from "statics/propTypes";

import "./SetupContribution.scss";

const payPeriodToEnglish = {
  WEEKLY: "weekly",
  EVERY_OTHER_WEEK: "bi-weekly",
  TWICE_PER_MONTH: "semi-monthly",
  MONTHLY: "monthly",
};

class SetupContribution extends React.PureComponent {
  static propTypes = {
    client: PropTypes.shape({}),
    getUserState: PropTypes.func,
    getEmployerLinkRequests: PropTypes.func,
    addPaycheckContributionSource: PropTypes.func,
    getContributionRecommendations: PropTypes.func,
    updateRequiredDisclosures: PropTypes.func,
    push: PropTypes.func,
    userState: PropTypes.string,
    error: PropTypes.string,
    accountId: PropTypes.string,
    groupId: PropTypes.string,
    scheduleFrequency: PropTypes.string,
    hasLinkRequest: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    dailyMax: PropTypes.number,
    perPayPeriodMax: PropTypes.number,
    dailyRecommendations: recommendedContributionType,
    perPayPeriodRecommendations: recommendedContributionType,
  };

  constructor() {
    super();

    this.state = {
      initialFetching: true,
    };
  }

  async componentDidMount() {
    window.analytics.page("Setup Contribution");
    scrollToTop();
    await this.props.getEmployerLinkRequests(this.props.client);

    await this.props.getContributionRecommendations(this.props.client, {
      groupId: this.props.groupId,
      isSelfEmployed: false,
    });

    this.setState({ initialFetching: false });
  }

  _submitPayCheckContribution = async (amount, isDailyCustom) => {
    try {
      await this.props.addPaycheckContributionSource(this.props.client, {
        isDailyCustom,
        groupId: this.props.groupId,
        amount: +amount,
      });
      window.analytics.track("Submitted Contribution");

      await this.props.getUserState(this.props.client);
      this.props.push("/dashboard");
    } catch (error) {
      return error;
    }
  };

  render() {
    const noErrorNotLoading = !this.props.error && !this.state.initialFetching;

    return (
      <>
        <div id="setup-contributions">
          <div className="mega-container">
            <div className="main-content">
              {this.state.initialFetching && <IconSpinner centered />}
              {this.props.error && (
                <Alert type="error" msg={this.props.error} />
              )}
              {noErrorNotLoading &&
                !isEmpty(this.props.perPayPeriodRecommendations) && (
                  <ContributionSelector
                    dailyMax={this.props.dailyMax}
                    perPayPeriodMax={this.props.perPayPeriodMax}
                    dailyRecommendations={this.props.dailyRecommendations}
                    perPayPeriodRecommendations={
                      this.props.perPayPeriodRecommendations
                    }
                    error={this.props.error}
                    onSubmit={this._submitPayCheckContribution}
                    isSubmitting={this.props.isSubmitting}
                    schedule={{ frequency: this.props.scheduleFrequency }}
                    isPaycheck={true}
                  >
                    <section className="page-title-wrap">
                      <article className="text-cell">
                        <h1 className="page-title">
                          Your employer changed the pay periods for your plan.
                          Please choose a new{" "}
                          {payPeriodToEnglish[this.props.scheduleFrequency]}{" "}
                          contribution amount.
                        </h1>
                        <p className="page-subtext">
                          Please select the amount that{"'"}s good for you or
                          enter in a custom amount. You can modify your
                          contribution amount at any time.
                        </p>
                      </article>
                    </section>
                  </ContributionSelector>
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const actions = [
  userConstants.USER_EMPLOYER_LINK_REQUESTS,
  accountConstants.GET_ACCOUNTS,
  userConstants.GET_INVESTMENT_PROFILE,
  contributionConstants.ONBOARDING_PAYCHECK_CONTRIBUTION,
  contributionConstants.GET_CONTRIBUTION_RECOMMENDATIONS,
  userConstants.USER_STATE,
];

const isSubmitting = createLoadingSelector([
  contributionConstants.ONBOARDING_PAYCHECK_CONTRIBUTION,
  userConstants.USER_STATE,
]);

const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const { dailyMax, perPayPeriodMax, recommendations } = state.contribution;
  const { scheduleFrequency } = recommendations;
  return {
    error: errorSelector(state),
    groupId: get(state.user.employerLinkRequests, "0.group.id", null),
    dailyMax,
    perPayPeriodMax,
    dailyRecommendations: recommendations.daily,
    perPayPeriodRecommendations: recommendations.perPayPeriod,
    scheduleFrequency,
    hasLinkRequest: !isEmpty(state.user.employerLinkRequests),
    accountId: getIraAccountIdSelector(state),
    isSubmitting: isSubmitting(state),
    userState: get(state, "user.userState.state", ""),
  };
};
const mapDispatchToProps = {
  push,
  getEmployerLinkRequests,
  getContributionRecommendations,
  getUserState,
  addPaycheckContributionSource,
  updateRequiredDisclosures,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(SetupContribution));
