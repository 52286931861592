import React from "react";
import PropTypes from "prop-types";
import "./PortfolioSelectBox.scss";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { portfolioMetadataMap } from "utils/individualPortfolioHelper";

const PortfolioSelectBox = (props) => {
  const { portfolioName } = props.portfolio;

  const portfolioMetadata = portfolioMetadataMap[portfolioName];
  if (isEmpty(props.portfolio) || isEmpty(portfolioMetadata)) return null;
  return (
    <div className="portfolio-selection-box">
      <div
        className="portfolio-select-box-container"
        onClick={() => {
          props.onSelect(props.portfolio);
        }}
      >
        <div
          className={classnames("portfolio-select-box shadow", {
            selected: props.isSelected,
          })}
        >
          <div className="portfolio">
            <div className="portfolio-label">
              <p className="type">{portfolioMetadata.portfolio}</p>
              {props.isRecommendedPortoflio && (
                <p className="isPreferred">{" Recommended"}</p>
              )}
            </div>

            <div className="risk">
              <p className="portfolio-risk">{portfolioMetadata.risk}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
PortfolioSelectBox.defaultProps = {
  portfolio: {},
};

PortfolioSelectBox.propTypes = {
  portfolio: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    key: PropTypes.string,
    description: PropTypes.string,
    portfolio: PropTypes.string,
    portfolioName: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  isRecommendedPortoflio: PropTypes.bool,
};

export default PortfolioSelectBox;
