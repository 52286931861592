import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { withApollo } from "@apollo/client/react/hoc";
import EmployerCard from "components/EmployerCard";

import {
  addEmployerGroup,
  getAllEmployerGroups,
} from "actions/employerActions";
import { toast } from "react-toastify";
import { employerConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { groupType } from "statics/propTypes";
import { activeBanksSelector } from "store/selectors/bank";
import {
  hasEnabledDaysBeforeDebit,
  hasExpiredInvites,
} from "store/selectors/employer";
import GroupForm from "components/GroupForm";

class AddGroup extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    addEmployerGroup: PropTypes.func,
    getAllEmployerGroups: PropTypes.func,
    client: PropTypes.shape({}),
    companyId: PropTypes.string,
    error: PropTypes.string,
    accounts: PropTypes.array,
    isLoading: PropTypes.bool,
    group: groupType,
    planTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    hasExpiredInvites: PropTypes.bool,
    enabledDaysBeforeDebit: PropTypes.bool,
  };

  submitGroup = (group) => {
    this.props
      .addEmployerGroup(this.props.client, this.props.companyId, group)
      .then(() => {
        if (!this.props.error) {
          toast.success("Successfully added group.");
          this.props.getAllEmployerGroups(this.props.client);
          this.props.onClose();
        }
      });
  };

  _cardTitle = () => {
    return "Add Group";
  };

  _cardDescription = () => {
    return "A group is the way that you can align your Icon payroll withdrawals with your actual payroll schedule per payroll group.";
  };

  render() {
    return (
      <EmployerCard
        title={this._cardTitle()}
        description={this._cardDescription()}
        content={
          <GroupForm
            onClose={this.props.onClose}
            onSubmit={this.submitGroup}
            companyId={this.props.companyId}
            error={this.props.error}
            accounts={this.props.accounts}
            isLoading={this.props.isLoading}
            planTypes={this.props.planTypes}
            hasExpiredInvites={this.props.hasExpiredInvites}
            enabledDaysBeforeDebit={this.props.enabledDaysBeforeDebit}
          />
        }
      />
    );
  }
}

const actions = [
  employerConstants.GET_EMPLOYER_GROUPS,
  employerConstants.ADD_EMPLOYER_GROUPS,
];

const isLoading = createLoadingSelector(actions);
const error = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const companyId = state.employer.company.id;

  return {
    accounts: activeBanksSelector(state),
    companyId,
    isLoading: isLoading(state),
    error: error(state),
    planTypes: state.employer.company.planTypes,
    hasExpiredInvites: hasExpiredInvites(state),
    enabledDaysBeforeDebit: hasEnabledDaysBeforeDebit(state),
  };
};

const mapDispatchToProps = {
  addEmployerGroup,
  getAllEmployerGroups,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddGroup));
