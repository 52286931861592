import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { get, groupBy } from "lodash";
import { push } from "connected-react-router";

import Employees from "./Employees";
import UploadEmployees from "./UploadEmployees";
import UploadSingle from "pages/dashboards/employerDashboard/userManagement/UploadSingle";

import {
  getAllEmployerGroups,
  getEmployeeInvitations,
} from "actions/employerActions";

class UserManagement extends React.Component {
  static propTypes = {
    navTab: PropTypes.shape({
      tabObject: PropTypes.string,
      tabValue: PropTypes.string,
    }),
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    routeName: PropTypes.string,
    push: PropTypes.func,
    getAllEmployerGroups: PropTypes.func,
    getEmployeeInvitations: PropTypes.func,
    client: PropTypes.object,
    groupWithEmployees: PropTypes.shape({}),
  };

  constructor(props) {
    super(props);

    this.state = {
      tab: this._calcActiveTab(props.routeName),
      activeStep: 1,
    };
  }

  componentDidMount() {
    // hydration calls for data needed by sub components
    this.props.getAllEmployerGroups(this.props.client);
    this.props.getEmployeeInvitations(this.props.client);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.routeName !== this.props.routeName) {
      this.setState({ tab: this._calcActiveTab() });
    }
  }

  _calcActiveTab(routeName) {
    switch (routeName) {
      case "/dashboard/users/employees":
        return "employees";
      case "/dashboard/users/employees/upload":
        return "employees/upload";
      case "/dashboard/users/employees/upload-single":
        return "employees/upload-single";
      default:
        return "employees";
    }
  }

  _employeesContent = () => {
    return (
      <section className="page-title-wrap">
        <article className="text-cell">
          <div className="dashboard-header">
            <h1 className="page-title">User Management</h1>
            <p className="page-subtext">
              Invite employees or manage existing users.
            </p>
          </div>
          <div>
            <Employees />
          </div>
        </article>
      </section>
    );
  };

  _rosterContent = () => {
    return <UploadEmployees />;
  };

  _getContent = () => {
    switch (this.state.tab) {
      case "employees":
        return <Employees />;
      case "employees/upload":
        return <UploadEmployees />;
      case "employees/upload-single":
        return <UploadSingle />;
    }
  };

  render() {
    const content = this._getContent();

    return <div className="mega-container">{content}</div>;
  }
}

const mapStateToProps = (state) => {
  const routeName = get(state.router, "location.pathname");
  let groupWithEmployees = groupBy(
    state.employer.linkedEmployees,
    "groupWithSchedule.group.id"
  );
  return { routeName, groups: state.employer.groups, groupWithEmployees };
};

const mapDispatchToProps = {
  push,
  getAllEmployerGroups,
  getEmployeeInvitations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(UserManagement));
