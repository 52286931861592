/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

class EmployerTermsAgreement extends React.Component {
  static propTypes = {
    companyName: PropTypes.string,
    companyEIN: PropTypes.string,
    info: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  _content = () => {
    const companyName = this.props.companyName;
    const signerName =
      this.props.info.firstName + " " + this.props.info.lastName;
    const companyEIN = this.props.companyEIN;
    const signerEmail = this.props.info.userEmail;
    const today = moment().format("MMMM Do YYYY");

    return (
      <div className="terms-of-service">
        <div className="terms-intro">
          <b>
            YOU MUST READ AND CONSIDER THE AGREEMENTS CAREFULLY AND CONTACT ICON
            TO ASK ANY QUESTIONS YOU MAY HAVE BEFORE ENTERING INTO THEM.
            CLICKING THAT YOU AGREE HAS THE SAME LEGAL EFFECT AS SIGNING A PAPER
            VERSION OF EACH OF THE AGREEMENTS.
          </b>
        </div>

        <div>
          <p>
            <b>PLATFORM SERVICES AGREEMENT</b>
            <span>
              (“<b>Agreement</b>”) is effective as of the date all parties have
              signed the agreement (the “Effective Date”), by and between{" "}
              {companyName}
              <span> (“User”),</span> and Icon Savings Plan (“Icon”).
            </span>
          </p>
          <p>
            <b>
              <span>WHEREAS,</span>
            </b>
            <span>
              Icon is a provider of a web-based platform [known as the Icon
              Employer Portal] (the “Platform”);
            </span>
          </p>
          <p>
            <b>
              <span>WHEREAS, </span>
            </b>
            <span>
              User desires to access the Platform for use of the Services
              (defined below) by User’s employees who are authorized by User to
              access and use the Services under the rights granted to User (the
              “Authorized User(s)”) pursuant to this Agreement; and
            </span>
          </p>
          <p>
            <b>
              <span>WHEREAS, </span>
            </b>
            <span>
              Icon and User desire to enter into this Agreement defining their
              respective rights and responsibilities and memorializing the terms
              and conditions pursuant to which Icon will provide to User
              services.
            </span>
          </p>
          <p>
            <b>
              <span>NOW, THEREFORE, </span>
            </b>
            <span>
              in consideration of the mutual promises, terms, and conditions set
              forth herein, User and Icon hereby agree as follows:
            </span>
          </p>

          <div>
            <p className="terms-number">1. Services and Use</p>
            <ol type="a">
              <li>
                Subject to the terms and conditions of this Agreement, Icon
                hereby grants to User, a limited, non-exclusive,
                non-transferable, non-sub licensable and non-assignable license
                to access and to use the Platform and associated services
                (together the "Services"). Icon shall provide to User the
                necessary passwords and network links or connections to allow
                User to access the Services. User will access the Platform
                through the internet using equipment and internet connectivity
                provided by User.
              </li>
              <li>
                Services shall also include Plan Administration functionality
                (Approval of Plan Join Requests, Approval of Deferral Amounts
                and Changes, Plan Invites, ACH Transfer for Plan Contributions
                and Bank Linking). [Services do not and shall not include any
                required support SLA of any kind to Users.]
              </li>
              <li>
                User shall not use the Services for any purposes beyond the
                scope of the access granted in this Agreement. User shall not at
                any time, directly or indirectly, and shall not permit any
                Authorized Users to: (i) copy, modify, or create derivative
                works of the Services, in whole or in part; (ii) rent, lease,
                lend, sell, license, sublicense, assign, distribute, publish,
                transfer, or otherwise make available the Services; (iii)
                reverse engineer, disassemble, decompile, decode, adapt, or
                otherwise attempt to derive or gain access to any software
                component of the Services, in whole or in part; (iv) remove any
                proprietary notices from the Services; or (v) use the Services
                in any manner or for any purpose that infringes,
                misappropriates, or otherwise violates any intellectual property
                right or other right of any person, or that violates any
                applicable law.
              </li>
              <li>
                Icon reserves all rights not expressly granted to User in this
                Agreement. Except for the limited rights and licenses expressly
                granted under this Agreement, nothing in this Agreement grants,
                by implication, waiver, estoppel, or otherwise, to User or any
                third party any intellectual property rights or other right,
                title, or interest in or to the Icon IP.
              </li>
              <li>
                Icon agrees to host and maintain the Platform and shall provide
                access thereto in accordance with the terms and conditions of
                this Agreement and the Service agreed to by the parties.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">2. Intellectual Property</p>
            <ol type="a">
              <li>
                For purposes of this Agreement, “Platform Technology” means the
                Platform, the underlying software and any materials developed or
                created in whole or in part by Icon in any reports,
                presentations, charts, graphs, documents or other materials from
                the Platform Technology. Icon shall retain all right title and
                interest in all aspects of the Platform Technology, including
                any intellectual property rights therein (including, without
                limitation, copyrights, trademarks, trade names, patents, trade
                secrets, applications for any of the foregoing, etc.).
                Notwithstanding anything to the contrary herein, Icon reserves
                all rights to use and/or license the Platform Technology,
                including the right to modify and license the Platform and any
                derivative works thereof, and User agrees that other than the
                access and use license granted pursuant to this Agreement, User
                has no other right, title, or interest in the Platform
                Technology.
              </li>
              <li>
                Icon reserves the right to terminate access to any third party
                content provided as part of the Platform, with no penalty to
                Icon, if the owner of such third party content discontinues
                offering such content or restricts Icon’s or User’s access,
                provided, however, that if such termination occurs, Icon will
                use commercially reasonable efforts to promptly replace the
                discontinued services with replacement content.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">3. Updates and Customizations</p>
            <ol type="a">
              <li>
                Icon will provide or make available updates to the User’s user
                iteration of the Platform as they are made generally available
                by Icon (hereafter “Update” or “Updates”). As used herein,
                Updates are any change, correction, modification, improvement,
                enhancement, extension, update, upgrade, new version, new
                release, or other change to the Platform released by Icon.
              </li>
              <li>
                If User requests to add a feature or functionality that is not
                an Update or, in the alternative, to suppress a feature or
                functionality of an Update, Icon will provide such customization
                on a schedule and cost under a separate agreement to be
                determined by the parties.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">4. Platform Warranties</p>
            <ol type="a">
              <li>
                During the term, Icon represents and warrants that (i) it has
                legal power to enter into this Agreement and (ii) the Platform
                shall materially comply with terms outlined in this Agreement.
                User’s sole and exclusive remedy and Icon's sole and exclusive
                liability for breach of the foregoing warranty shall be for Icon
                to repair or replace the defective element of the Platform or,
                if repair or replacement cannot be provided within a reasonable
                time, terminate this Agreement and refund any prepaid fees, if
                any.
              </li>
              <li>
                Icon has the authority to license or sublicense all components
                of the Platform. If at any time the Platform fails to satisfy
                the warranties in this Agreement, Icon will promptly (i) replace
                the component with a compatible, functionally equivalent and
                non-infringing software product or service; (ii) modify the
                Platform so as to provide User with a functionally-equivalent,
                compatible and non-infringing product or service; or (iii)
                obtain a license for User to continue use of the non-compliant
                component of the Platform for the remainder of the Term.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">5. Platform User and IDs</p>
            <ol type="a">
              <li>
                User may designate licenses for Authorized Users as limited in
                this Agreement. The Platform is intended for access and use
                solely by User and its Authorized Users. A user ID and password
                will be issued to User for use by an Authorized User for access
                and use of the Platform. User is solely responsible for: (i)
                authorizing, monitoring, controlling access to and maintaining
                the strict confidentiality of any user IDs, passwords and codes
                (collectively, “IDs”) assigned to an Authorized User; (ii)
                instructing Authorized Users to not allow another person to use
                their IDs; (iii) any charges or damages that may be incurred as
                a result of an Authorized User’s failure to maintain the strict
                confidentiality of their IDs; and (iv) promptly informing Icon
                in writing of any need to deactivate an ID due to security
                concerns. Icon is not liable for any harm related to the theft
                of IDs, an Authorized User’s disclosure of IDs, or an Authorized
                User allowing another person or entity to access and use the
                Platform using the Authorized User’s IDs. User shall immediately
                notify Icon, in writing, of any unauthorized use of IDs.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">6. Fees</p>
            <ol type="a">
              <li>
                All fees for the Services are detailed in Exhibit A – Order
                Form. A fully executed Exhibit A is required to use the
                Services.
              </li>
              <li>
                If applicable, User shall pay all sales, use and excise taxes,
                and any other tax-like fees and/or assessments now or hereafter
                imposed by any governmental authority with respect to the
                Services.
              </li>
              <li>
                The Parties agree that in the event of conflicting terms between
                Exhibit A – Order Form and this Agreement, the conflicting terms
                of the most recently executed Exhibit A supersede those set
                forth in this Agreement.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">
              7. Representations, Warranties and Covenants of User
            </p>
            <p className="terms-list">
              User represents, warrants and covenants to Icon as follows:
            </p>
            <ol type="a">
              <li>
                User is solely responsible for complying with all federal and
                state laws and rules that are applicable to User, its affiliates
                and agents, by virtue of the activities contemplated by this
                Agreement. User its affiliates and agents, shall be solely
                responsible for its compliance with all applicable foreign,
                federal, state and local laws, rules and regulations, and the
                rules and regulations of any self-regulatory organization of
                which User is a member (collectively, "Applicable Law"),
                including but not limited to the Investment Advisers Act of 1940
                (“Advisers Act”), as amended, with respect to User’s use of the
                Platform.
              </li>
              <li>
                User understand and acknowledges, and shall instruct all
                Authorized Users, that access to the Platform and the Services
                is provided on the strict understanding that the Icon is not
                acting as an “investment advisor” as such term is defined in the
                U.S. Investment Advisers Act of 1940 or any other applicable
                state law, or as a “fiduciary” under the Employee Retirement
                Income Security Act of 1974. User further understands and
                acknowledges that the Icon is not engaged in rendering legal,
                accounting, fiduciary, or other professional services. User
                agrees to consult with their attorneys, compliance officers,
                and/or other professional advisors prior to executing this
                Agreement. User is solely responsible for any investment advice
                rendered to its clients or any content or communications that
                User delivers to its clients or prospective clients.
              </li>
              <li>
                User has the full power and authority to execute and perform
                this Agreement, and the execution and performance of this
                Agreement by User will not violate any law or agreement to which
                User is a party or by which it is otherwise bound. When executed
                by a duly authorized officer, this Agreement will be enforceable
                against User in accordance with its terms.
              </li>
              <li>
                User agrees that all information provided to Icon from User to
                enable it to perform the Services shall be true, correct, and
                complete in all material respects. User agrees to promptly
                notify Icon in writing of any material change in the information
                provided from User to Icon and to promptly provide any such
                additional information as may be reasonably requested.
              </li>
              <li>
                User shall cause all Authorized Users to be bound by the
                applicable terms and conditions of this Agreement (e.g. Platform
                use, access and Confidentiality). User acknowledges that the
                User and an Authorized User of the Platform are required to
                accept the Terms of Service and Privacy Policy (“TOS”). The
                Parties agree that in the event of conflicting terms between the
                TOS and this Agreement, the terms of this Agreement supersede
                those set forth in the TOS. User further agrees that from time
                to time, Icon may update its TOS without notice to User. The
                most recent copy of the TOS is available at
                www.iconsavingsplan.com/terms
              </li>
              <li>
                User understands that the technical processing and transmission
                of any transfer of signs, signals, text, images, sounds, data or
                intelligence of any nature transmitted in whole or part
                electronically to or from the Platform (“Electronic
                Communications”) is fundamentally necessary to User’s use of the
                Platform. User expressly consents to Icon’s interception and
                storage of Electronic Communications and/or all data, files,
                including hypertext markup language files, documents, audio and
                visual information, graphics, scripts, programs, applets or
                servlets that User creates, installs, uploads to or transfers in
                or through the Platform or provides in the course of using the
                Platform, excluding identification and other information
                provided by User relative to User and Authorized Users (“User
                Data”). User further acknowledges and understands that User’s
                Electronic Communications will involve transmission over the
                internet, and over various networks, only part of which may be
                owned and/or operated by Icon. User acknowledges and understands
                that changes to User’s Electronic Communications may occur in
                order to conform and adapt such data to the technical
                requirements of connecting networks or devices. User further
                understands that Electronic Communications may be accessed by
                unauthorized parties when communicated across the internet,
                network communications facilities, telephone, or other
                electronic means. User agrees that Icon is not responsible for
                any Electronic Communications and/or User Data which are lost,
                altered, intercepted or stored without authorizations during the
                transmission of any data whatsoever across networks not owned
                and/or operated by Icon.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">
              8. Representations Warranties and Covenants of Icon
            </p>
            <p className="terms-list">
              Icon represents, warrants and covenants to User as follows:
            </p>
            <ol type="a">
              <li>
                Icon will comply with all of the federal and state laws and
                rules that are applicable to Icon by virtue of the activities
                contemplated by this Agreement.
              </li>
              <li>
                Icon has the full power and authority to execute and perform
                this Agreement, and the execution and performance of this
                Agreement by Icon will not violate any law or agreement to which
                Icon is a party or by which it is otherwise bound. When executed
                by a duly authorized officer, this Agreement will be enforceable
                against Icon in accordance with its terms.
              </li>
              <li>
                Icon shall use commercially reasonable efforts consistent with
                high industry standards to ensure that the Platform and Services
                shall be true and accurate to the best of Icon’s knowledge;
                however, Icon provides no guarantee of accuracy or warranty for
                third-party materials reproduced on the Platform (including but
                not limited to direct data feeds).
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">
              9. Right to Engage in Other Activities
            </p>
            <ol type="a">
              <li>
                The parties acknowledge and agree that each may act and continue
                to provide any and all related services for other clients as it
                may determine. Nothing herein will be deemed to restrict the
                right of Icon to perform services for any other party, and the
                performance of such services for others shall not be deemed to
                violate or give rise to any duty or obligation to the other
                party.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">
              10. Confidential Information and Disclosure of Information
            </p>

            <ol type="a">
              <li>
                “Confidential Information” includes, without limitation,
                proprietary business, technical and financial and other
                non-public information (including, but not limited to, User
                information, software and systems, technical drawings,
                algorithms, know-how, formulas, tools, methodologies, processes,
                ideas, inventions (whether patentable or not), schematics,
                product development plans, forecasts, technical, marketing and
                business strategies and initiatives) of either party and its
                business partners.
              </li>
              <li>
                User and Icon each agree that with respect to any Confidential
                Information that is furnished by the other party hereto, such
                information shall be kept in strict confidence and shall not be
                used, directly or indirectly, for any purpose other than that
                for which it was furnished. The foregoing notwithstanding, such
                confidential information shall not include any information that:
                (i) was known to the recipient at the time it received the
                information; (ii) was or became publicly known through no
                wrongful act of the recipient; (iii) was received from a third
                party without similar restrictions and without breach of this
                agreement; (iv) was developed independently by the recipient; or
                (v) was approved for release by written authorization of a party
                authorized to disclose such information.
              </li>
              <li>
                The parties shall establish and maintain safeguards against the
                unauthorized access, destruction, loss, or alteration of such
                confidential information in their control which are no less
                rigorous than those maintained by a party for its own
                information of a similar nature.
              </li>
              <li>
                The foregoing notwithstanding, either party may use or disclose
                the other party’s confidential information to the extent such
                use or disclosure is required by applicable law, regulation, or
                court order; provided, however, that, prior to any such
                disclosure, the disclosing party shall notify the other party
                and provide the other party an opportunity, if possible, to
                prevent disclosure, which may include seeking a protective
                order. The disclosing party will cooperate in any such
                proceeding.
              </li>
              <li>
                Subject to Icon’s compliance with the other applicable
                provisions of this Agreement, Icon may compile, distribute and
                otherwise exploit Aggregated Data (defined below) derived from
                the Platform and the Services. “Aggregated Data” shall mean: (i)
                data aggregated with similar data of Icon’s other clients that
                is compiled as a result of use of the Platform which is of a
                general, non-identifiable nature.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">11. Use of Name and Publicity</p>
            <ol type="a">
              <li>
                Icon will not use the name, logo, trademark, trade name, or
                other marks of User or make any public announcement or press
                release regarding this Agreement or any activities performed
                hereunder without the prior written consent of User.
                Notwithstanding the foregoing, this Agreement shall serve as
                User’s consent to Icon listing User on a representative list of
                clients/partners along with the applicable logo. User will not
                use Icon’s name/logo in press release or marketing collateral
                without Icon’s prior approval.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">12. Independent Contractor</p>
            <ol type="a">
              <li>
                Icon is an independent contractor of User, and this Agreement
                will not be construed as creating a relationship of employment,
                agency, partnership, joint venture, or any other form of legal
                association. Neither party has any power to bind the other party
                or to assume or to create any obligation or responsibility on
                behalf of the other party or in the other party’s name. Icon
                personnel are not eligible for, nor may they participate in, any
                employee benefit plans of User, and User will not insure Icon
                for workers’ compensation coverage or for unemployment
                insurance. Icon is solely responsible for, and agrees to comply
                with all federal and state laws and regulations with respect to:
                (i) salaries and compensation (including unemployment
                compensation); (ii) deductions and withholdings; and (iii)
                payment of any and all contributions, taxes and assessments,
                with respect to all Icon personnel who provide services to User
                hereunder.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">13. Term and Termination</p>
            <ol type="a">
              <li>
                Term. This Agreement will commence on the Effective Date and
                continue until terminated as set forth in this Section 13(c)
                below.
              </li>
              <li>
                Suspension of Services. User agrees that Icon may, upon notice
                to User, suspend User’s access to the Services if Icon
                reasonably concludes that User’s use of the Services is causing
                immediate and ongoing harm to Icon or others. Icon will use
                commercially reasonable efforts to resolve the issues causing
                the suspension of Service. User agrees that Icon will not be
                liable to User or to any third party for any suspension of the
                Service under such circumstances as described in this Section.
              </li>
              <li>
                Termination. Either party may terminate this Agreement without
                charge or penalty upon thirty (30) days prior written notice to
                the other party.
              </li>
              <li>
                Effect of Termination. Upon any termination of this Agreement:
                (i) all rights and licenses granted to User under this Agreement
                will immediately terminate; (ii) if applicable, User will
                immediately pay to Icon all amounts due and payable up to the
                effective date of termination of this Agreement; and (iii) each
                party will promptly return to the other party all Confidential
                Information of such other party then in its possession or
                destroy all copies of Confidential Information of such other
                party, at such other party's sole discretion and direction. User
                will immediately confirm, in writing, that it has complied with
                Section 13(d) at Icon’s request.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">14. DISCLAIMER OF WARRANTIES</p>
            <ol type="a">
              <li>
                EXCEPT AS EXPRESSLY PROVIDED HEREIN, THE PLATFORM IS PROVIDED ON
                AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS. EXCEPT AS
                EXPRESSLY PROVIDED HEREIN, ICON DISCLAIMS ALL WARRANTIES,
                EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF
                MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR
                COMPATIBILITY. ICON MAKES NO REPRESENTATION NOR WARRANTY THAT
                INFORMATION IS ACCURATE, COMPLETE, APPROPRIATE, RELIABLE OR
                TIMELY. ICON MAKES NO REPRESENTATIONS OR WARRANTIES THAT ACCESS
                TO AND USE OF THE INTERNET WILL BE UNINTERRUPTED OR ERROR-FREE,
                OR FREE OF VIRUSES, UNAUTHORIZED CODE OR OTHER HARMFUL
                COMPONENTS. TO THE FULLEST EXTENT PROVIDED BY LAW, IFSICON
                HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
                ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS
                FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY
                WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE
                LAW.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">15. LIMITATIONS OF LIABILITIES</p>

            <ol type="a">
              <li>
                IN NO EVENT SHALL ICON ITS AFFILIATES, OR THEIR LICENSORS,
                SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE
                LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING,
                WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS,
                ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
                PERFORMANCE OF THE PLATFORM OR THE RELATED SERVICES, WITH THE
                DELAY OR INABILITY TO ACCESS OR USE THE PLATFORM OR RELATED
                SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR
                FOR ANY CONTENT, SOFTWARE, PRODUCTS AND SERVICES MADE AVAILABLE
                OR OBTAINED THROUGH THE SITE, THE APPLICATION OR THEIR
                RESPECTIVE CONTENT, OR OTHERWISE ARISING OUT OF THE USE OR
                ACCESS OF PLATFORM OR USE OF THE SERVICES, WHETHER BASED ON
                CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN
                IF ICON HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. ANY
                SERVICES OR CONTENT MADE AVAILABLE OR OBTAINED THROUGH THE USE
                OF PLATFORM OR THE RELATED SERVICES, AND ALL OTHER USE OF THE
                PLATFORM OR THE RELATED SERVICES, IS DONE AT YOUR OWN DISCRETION
                AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
                YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS THEREFROM. IF
                YOU ARE DISSATISFIED WITH ANY PORTION OF THE PLATFORM, OR WITH
                ANY OF THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS
                TO DISCONTINUE USING THE PLATFORM.
              </li>
              <li>
                THE MAXIMUM AGGREGATE LIABILITY OF ICON TO SUBSCIBER OR ANY
                THIRD PARTY FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE
                PLATFORM OR ICON SERVICES, REGARDLESS OF THE FORM OF SUCH CLAIM,
                SHALL BE THE AMOUNT PAID TO ICON UNDER THIS AGREEMENT DURING THE
                SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING SUCH CLAIM.
              </li>
              <li>
                THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">16. Indemnification</p>
            <ol type="a">
              <li>
                User agrees to indemnify, defend, and hold harmless Icon and
                each of its current or future subsidiaries or affiliates, and
                their shareholders, directors, officers, employees, agents or
                other representatives, and hold each of them harmless from and
                against any and all claims, losses, expenses, liabilities,
                demands, obligations, costs, attorneys’ fees or damages of every
                kind and character without limitation arising out of or
                connected with any breach of User’s representations, warranties
                or covenants under this Agreement, any act or omission by User
                (including Authorized Users) which is a violation of applicable
                statutes, laws or regulation or arising from User’s gross
                negligence or willful misconduct, or the misuse of the Platform.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">17. Notices</p>
            <ol type="a">
              <li>
                Any notices and other communications hereunder shall be in
                writing, delivered to the addresses set forth below, and shall
                be deemed to have been duly given: (i) if sent via email listed
                below, then upon the date and time of actual receipt; (ii) if
                mailed first-class, registered or certified mail, return receipt
                requested, postage prepaid, then upon the date and time return
                receipt delivery is attempted by the U. S. Postal Service; (iii)
                if delivered by courier for hand-delivery, then upon the date
                and time of actual delivery; or (iv) if delivered by overnight
                U.S. or private mail service, then upon the date and time of
                actual delivery (with or without recipient signature) by the
                U.S. Postal Service or private delivery service company, as
                applicable; or (v) if updated electronically via the Platform
                services at the date and time of actual receipt.
                <div className="terms-user-details">
                  <span>
                    <p className="terms-label">To User:</p>
                    <p>
                      ADDRESS: Address on file from Company Information
                      onboarding documents.
                    </p>
                    <p>
                      EMAIL: Email on file from Company Information and
                      Controller Verification onboarding documents.
                    </p>
                  </span>
                  <span>
                    <p className="terms-label">To Icon:</p>
                    <p>
                      ADDRESS: Icon Savings Plan 1631 NE Broadway St. #815
                      Portland, OR 97232
                    </p>
                    <p>EMAIL: legal@iconsavingsplan.com</p>
                  </span>
                </div>
                Either party may, by notice to the other, change its address for
                receiving such notices by giving notice to the other party in
                the manner provided by this Section.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">18. Governing Law</p>
            <ol type="a">
              <li>
                This Agreement and the interpretation and application of the
                provisions hereof shall be governed and construed in accordance
                with the laws of the state of Delaware, without giving effect to
                its choice of law provisions.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">19. Arbitration Agreement</p>
            <ol type="a">
              <li>
                To the extent enforceable and except with respect to class
                actions or other representative actions, any controversy or
                claim arising out of this Agreement, or any breach thereof, or
                arising out of the Services provided by Icon and its employees
                or representatives hereunder, shall be settled by arbitration in
                a location designated by Icon in accordance with the Commercial
                Rules of the American Arbitration Association, and judgment upon
                the award rendered by the arbitrator shall be final and may be
                entered in any court having jurisdiction thereof. User and Icon
                hereby irrevocably submit to the jurisdiction of any such court
                for the purpose of such arbitration and the entering of such
                judgment. User agrees that it will not claim that any such
                proceeding elected pursuant to this Section 19 has been brought
                in an inconvenient forum. If any provision of this Agreement is
                held by any court or in any arbitration to be invalid, void, or
                unenforceable, the remaining provisions shall nevertheless
                continue in full force and effect. User is in no way waiving
                their rights provided under state or federal securities laws to
                pursue a remedy by other means. In entering into this Agreement,
                User is aware that: (i) arbitration is generally final and
                binding on the parties; a party’s right to have a court reverse
                or modify an arbitration award is very limited; (ii) the parties
                are waiving their right to seek remedies in court, including the
                right to jury trial, except to the extent that such a waiver
                would violate applicable law; (iii) pre-arbitration discovery is
                generally more limited than and potentially different in form
                and scope from court proceedings; (iv) the arbitration award is
                not required to include factual findings or legal reasoning; (v)
                the panel of arbitrators will typically include a minority of
                arbitrators who were or are affiliated with the securities
                industry; (vi) the rules of some arbitration forums may impose
                time limits for bringing a claim in arbitration; in some cases,
                a claim that is ineligible for arbitration may be brought in
                court; (vii) the rules of the arbitration forum in which the
                claim is filed, and any amendments thereto, shall be
                incorporated into this Agreement. The award rendered by the
                arbitrator shall include costs of arbitration, reasonable
                attorneys’ fees and reasonable costs for expert and other
                witnesses subject to the Limitation of Liability provision
                above. Nothing in this Section shall prevent either party from
                seeking injunctive or other equitable relief from the courts for
                matters related to data security, intellectual property or
                unauthorized access to the Services.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">20. Entire Agreement; Amendment</p>

            <ol type="a">
              <li>
                This Agreement, including the attached Exhibits, if applicable,
                constitutes the entire understanding between the parties
                relating to the subject matter contained herein and merges and
                supersedes all prior discussions and writings between them.
              </li>
              <li>
                The Agreement may be modified, including without limitation the
                Services to be provided by Icon or the fees charged by Icon (i)
                by mutual written agreement or (ii) by Icon providing notice to
                Client and Client failing within 30 calendar days to object in
                writing or terminate this Agreement, at which time the amendment
                will be effective.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">21. Severability and Interpretation</p>
            <ol type="a">
              <li>
                If any provision of this Agreement is held to be invalid, void
                or unenforceable by reason of any law, rule, administrative
                order or judicial decision, that determination shall not affect
                the validity of the remaining provisions of this Agreement.
              </li>
              <li>
                This Agreement shall be construed in accordance with its fair
                meaning as if prepared by all parties hereto and shall not be
                interpreted against either Party on the basis that it was
                prepared by one party or the other. The captions, headings, and
                subheadings used in this Agreement are for convenience only and
                do not in any way affect, limit, amplify or modify the terms and
                provisions thereof. Words used herein in the singular shall
                include the plural, and words used in the plural shall include
                the singular, wherever the context so reasonably requires.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">22. Assignment</p>
            <ol type="a">
              <li>
                This Agreement may not be assigned by either party without the
                consent of the other party, which consent shall not be
                unreasonably withheld. Notwithstanding the foregoing, either
                party may assign this Agreement and its rights and obligations
                hereunder (i) in connection with the disposition of
                substantially all of the business and assets of the assigning
                party to which this Agreement relates or (ii) in connection with
                the merger or corporate reorganization involving the assigning
                party.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">23. Force Majeure</p>
            <ol type="a">
              <li>
                None of the parties shall be held responsible for any delay or
                failure to perform any part of this Agreement to the extent such
                delay or failure results from any cause beyond its control and
                without the fault or negligence of the party claiming excusable
                delay, such as acts of God, pandemics, acts of war or terrorism,
                extraordinary acts of the United States of America or any state,
                territory or political subdivision thereof, fires, storms,
                floods, epidemics, riots, work stoppages, strikes (work
                stoppages and/or strikes of any of the parties to this agreement
                are specifically excluded from the language of this section),
                embargoes, computer viruses, unauthorized access, systems
                failure, failure or technical difficulties with software,
                hardware or other equipment, downtime for hardware and software
                maintenance, unusual volumes of traffic, failure of
                communications lines, telephone or other interconnect problems,
                theft, government restrictions, exchange or market rulings,
                extreme market volumes or volatility, suspension of trading
                (whether declared or undeclared), failure of utility services,
                adverse weather or events of nature.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">24. Waiver; Consent</p>
            <ol type="a">
              <li>
                Failure by either party to exercise or enforce any right under
                this Agreement, no matter how long the same may continue, shall
                not be deemed a waiver of such right by such party. No waiver of
                any provision of, or consent to any breach of, this Agreement
                shall be deemed a waiver of any other provision of, or consent
                to any subsequent breach of, this Agreement. A party’s consent
                to or approval of an act or omission on any one occasion shall
                not be deemed a consent to or approval of said act or omission
                on any subsequent occasion, or a consent to or approval of any
                other acts or omission on the same or any subsequent occasion.
                To be effective, a party’s waiver of any right or remedy must be
                documented in a writing signed by the waiving party.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">25. Counterparts</p>
            <ol type="a">
              <li>
                This Agreement may be executed in one or more counterparts, each
                of which shall be deemed and original and all of which together
                constitute a single agreement.
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-number">26. Electronic Receipt of Documents</p>
            <ol type="a">
              <li>
                User agrees to provide Icon with User’s e-mail address(es), and
                to accept emails (or other Electronic Communications) from Icon
                at the e-mail address User specifies. Notwithstanding any
                provision in the Agreement to the contrary, acknowledgement by
                an officer of User is not required with respect to e-mail
                communications pertaining to the User’s routine use of the
                Services, including without limitation communications relating
                to the support, maintenance, or the updating of the Services.
                User further agrees the Icon may provide any and all required
                notices including legal notices to User through either e-mail
                (or other electronic transmission), or by mail or express
                delivery service in accordance with Section 17. By signing
                below, User is able to receive electronic communications and
                agrees that such electronic communications (unless e-mail is
                returned as undeliverable) shall constitute delivery. User
                agrees to promptly notify Icon of any need to update the e-mail
                provided below and until User has updated its e-mail address,
                Icon may utilize and rely on the contact information provided
                for the delivery of such communications reports and other
                documents.
              </li>
            </ol>
          </div>

          <div className="terms-footer">
            <div>
              <p>
                <b>IN WITNESS WHEREOF</b>, the parties, by their duly authorized
                representatives, have executed this Agreement as of {today}.
              </p>
            </div>

            <div className="terms-user-details">
              <span>
                <p className="terms-label">User</p>
                <p>Name: {signerName}</p>
                <p>Email: {signerEmail} </p>
                <p>
                  Company: {companyName} (Tax ID: {companyEIN})
                </p>
              </span>
              <span>
                <p className="terms-label">Icon</p>
                <p>Email: legal@iconsavingsplan.com</p>
                <p>
                  Company: The Icon Savings Corporation (Tax ID: 82-3955531)
                </p>
              </span>
            </div>

            <div>
              <b>
                A separate and fully executed Exhibit A - Order Form is required
                to use the Services.
              </b>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const content = this._content();
    return <div>{content}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const companyName = state.employer.company.name;
  const companyEIN = state.employer.company.ein;
  const { info } = ownProps;

  return {
    companyName,
    companyEIN,
    ownProps,
    info,
  };
};

export default connect(mapStateToProps)(EmployerTermsAgreement);
