import React from "react";
import { Formik } from "formik";
import { Col, Form } from "react-bootstrap";
import { ScrollToFieldError } from "utils/form";
import Alert from "components/Alert";
import Button from "components/Button";
import { trim } from "lodash";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { userConstants } from "actions/types";
import { updateUserProfile } from "actions/userActions";

let yup = require("yup");

const schema = yup.object({
  firstName: yup.string().label("First Name").required(),
  lastName: yup.string().label("Last Name").required(),
});

class EmployerAdminNameForm extends React.PureComponent {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    updateUserProfile: PropTypes.func.isRequired,
    error: PropTypes.string,
    isUpdating: PropTypes.bool,
    client: PropTypes.object,
  };

  _onSubmit = async (values) => {
    await this.props.updateUserProfile(this.props.client, {
      firstName: trim(values.firstName),
      lastName: trim(values.lastName),
    });

    if (this.props.onSuccess) {
      this.props.onSuccess();
    }
  };

  render() {
    return (
      <div className="employee-invite-form">
        <p className="page-subtitle">
          Please provide your first and last name to continue
        </p>
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={this._onSubmit}
          enableReinitialize={true}
          initialValues={{
            firstName: "",
            lastName: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            handleBlur,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="formBasicFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name="firstName"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.firstName && !!errors.firstName}
                    isValid={touched.firstName && !errors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="formBasicLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="lastName"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.lastName && !!errors.lastName}
                    isValid={touched.lastName && !errors.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Group as={Col}>
                {this.props.error && (
                  <Alert type="error" msg={this.props.error} />
                )}

                <div className="submit-row">
                  <Button
                    name="submit"
                    btnLabel="Submit"
                    withArrow={true}
                    loading={this.props.isUpdating}
                  />
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const actions = [userConstants.USER_PROFILE_UPDATE];
const errorSelector = createErrorSelector(actions);
const loadingSelector = createLoadingSelector(actions);

const mapStateToProps = (state) => {
  return {
    isUpdating: loadingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  updateUserProfile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerAdminNameForm));
