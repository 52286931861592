import React from "react";
import PropTypes from "prop-types";
import JSONPretty from "react-json-pretty";
import Collapsible from "react-collapsible";
import Button from "components/Button";

import { isEmpty, map } from "lodash";
import SimpleModal from "components/SimpleModal";
import IconTableHeader from "components/IconTableHeader";
import IconTable from "components/IconTable";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { getKycStatus } from "actions/adminActions";
import IconSpinner from "components/IconSpinner";
import Alert from "components/Alert";
import { createErrorSelector } from "store/selectors";
import { adminConstants } from "actions/types";

class KycJsonViewer extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    error: PropTypes.string,
    companyId: PropTypes.string,
    getKycStatus: PropTypes.func,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      kycResponses: [],
    };
  }

  async componentDidMount() {
    const { data: kycStatus } = await this.props.getKycStatus(
      this.props.client,
      this.props.companyId
    );

    this.setState({
      isFetching: false,
      kycResponses: kycStatus.kycResponses,
    });
  }

  buildMemberTrigger = (data) => {
    const columns = [
      {
        label: "Entity Type",
        key: "rawResponse.entityType",
      },
      {
        label: "Status",
        key: "rawResponse.status",
      },
      {
        label: "Message",
        key: "rawResponse.message",
      },
      {
        label: "Metadata",
        customComponent: () => {
          return (
            <div className="employerInfo-action">
              <Button name="action" size="sm" btnLabel="View Results" />
            </div>
          );
        },
      },
    ];
    return (
      <>
        <IconTableHeader tableHeader="Status" />
        <IconTable columns={columns} data={[data]} />
      </>
    );
  };

  buildJsonView = () => {
    if (this.state.isFetching) {
      return <IconSpinner centered />;
    }

    if (this.props.error) {
      return <Alert type="error" msg={this.props.error} />;
    }

    if (isEmpty(this.state.kycResponses)) {
      return <div>No KYB/KYC status available</div>;
    }

    return map(this.state.kycResponses, (item, index) => {
      return (
        <div style={{ marginBottom: 24 }}>
          <Collapsible key={index} trigger={this.buildMemberTrigger(item)}>
            <JSONPretty
              data={item}
              themeClassName="custom-json-pretty"
              style={{ fontSize: "14px", margin: 15 }}
              mainStyle="background:#F7F7F7; line-height:1.3; padding: 20px; ;overflow:auto; color:#999;"
              keyStyle="color:#905;"
              stringStyle="color:#690;"
              valueStyle="color:#905;"
              booleanStyle="color:#905;"
            ></JSONPretty>
          </Collapsible>
        </div>
      );
    });
  };

  render() {
    return (
      <SimpleModal
        show={true}
        size={"lg"}
        onClose={() => this.props.onClose()}
        title="KYB/KYC Details"
        buttonText="close"
      >
        {this.buildJsonView()}
      </SimpleModal>
    );
  }
}

const errorSelector = createErrorSelector(adminConstants.GET_KYC_STATUS);

const mapStateToProps = (state) => ({
  error: errorSelector(state),
});

const mapDispatchToProps = {
  getKycStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(KycJsonViewer));
