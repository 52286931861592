import gql from "graphql-tag";

export function getSessionParam(
  client,
  { sessionToken, sessionScope, identityHash }
) {
  return new Promise((resolve, reject) => {
    const QUERY = gql`
      query getSessionParam(
        $sessionToken: ID!
        $sessionScope: ScopeTypes!
        $identityHash: String!
      ) {
        getSessionParam(
          sessionToken: $sessionToken
          sessionScope: $sessionScope
          identityHash: $identityHash
        ) {
          success
          error {
            message
            errorCode
          }
          sessionParams {
            expiresOn
            payload
          }
        }
      }
    `;

    const result = client.query({
      query: QUERY,
      variables: {
        sessionToken,
        sessionScope,
        identityHash,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.getSessionParam);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

export default {
  getSessionParam,
};
