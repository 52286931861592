import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

export const ProgressItem = ({
  isComplete,
  isActive,
  stepNum,
  stepLabel,
  stepTime,
}) => {
  const itemClass = classnames({
    ["is-complete"]: isComplete,
    ["is-active"]: isActive,
  });
  return (
    <li className={itemClass}>
      <a>
        <p className="step-num">
          <span className="num-text both-align">{stepNum}</span>
          <span className="checkmark">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 16.6 16.6"
            />
            <svg color="black">
              <g>
                <polyline
                  color="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="10"
                  points="14.8,1.8 5.5,14.8 1.8,11.1"
                />
              </g>
            </svg>
          </span>
        </p>
        <p className="step-name">{stepLabel}</p>
        {stepTime && <p className="step-time">{stepTime} min</p>}
      </a>
    </li>
  );
};

ProgressItem.propTypes = {
  isComplete: PropTypes.bool,
  isActive: PropTypes.bool,
  stepNum: PropTypes.number,
  stepLabel: PropTypes.string,
  stepTime: PropTypes.number,
};

export default ProgressItem;
