import {
  shape,
  string,
  bool,
  array,
  number,
  arrayOf,
  PropTypes,
} from "prop-types";

/* there will be many times that we are passing the same shaped object to many components so we should centralize 
   those here so we can reuse the proptypes
*/

export const userType = shape({
  authenticated: bool,
  profile: shape({
    legalName: string,
    firstName: string,
    lastName: string,
    address1: string,
    address2: string,
    city: string,
    state: string,
    postalCode: string,
    phoneNumber: string,
    email: string,
  }),
  userState: shape({
    state: string,
  }),
  confirmEmailSuccess: bool,
  error: string,
  initialUserLoading: bool,
  id: string,
  userRoleId: string,
  username: string,
  emailConfirmed: bool,
  auth0User: shape({}),
  neededYellowPathDocs: array,
});

export const portfolioType = shape({
  assets: arrayOf(
    shape({
      symbol: string,
      id: string,
      allocation: number,
    })
  ),
  id: string,
  name: string,
  label: string,
  description: string,
  createdAt: string,
  updatedAt: string,
});

export const transactionType = shape({
  memo: string,
  createdAt: string,
  updatedAt: string,
  status: string,
  subCategory: string,
  category: string,
  cancellationStatus: string,
});

export const bankAccountType = shape({
  id: string,
  bankName: string,
  bankAlias: string,
  verificationStatus: string,
  accountType: string,
  accountId: string,
  correlationId: string,
});

export const balancesType = shape({
  withdrawalBalance: number,
  totalAccountBalance: number,
  cashBalance: number,
});

export const statementType = shape({
  name: string,
  id: string,
  correlationId: string,
  uri: string,
  contentType: string,
  createdAt: string,
  updatedAt: string,
  type: string,
});

export const returnsType = shape({
  returnAmount: number,
  returnPercent: number,
  startTime: string,
  endTime: string,
});

export const positionsType = shape({
  symbol: string,
  type: string,
  description: string,
  dateTime: string,
  quantity: number,
  marketValue: number,
});

export const categoryPerformanceType = shape({
  category: string,
  returns: returnsType,
  assetPerformances: arrayOf(
    shape({
      returns: returnsType,
      assetId: shape({
        id: string,
        name: string,
        description: string,
        type: string,
        symbol: string,
      }),
      marketValue: number,
      totalAllocation: number,
      categoryAllocation: number,
    })
  ),
  marketValue: number,
  allocation: number,
});

export const approvalType = shape({
  id: string,
  type: shape({}),
  createdAt: string,
  userId: string,
  username: string,
  userEmail: string,
  updatedAt: string,
  status: string,
  updatedBy: string,
  reasonComment: string,
  allReasons: arrayOf(string),
  metadataItems: arrayOf(
    shape({
      name: string,
      value: string,
    })
  ),
});

export const beneficiaryType = shape({
  familyName: string,
  givenName: string,
  city: string,
  country: string,
  postalCode: string,
  state: string,
  dateOfBirth: string,
  socialSecurityNumber: string,
  relationship: string,
  beneficiaryType: string,
  sharePercent: number,
  streetAddress: arrayOf(string),
});

export const employeeInvitationType = shape({
  id: string,
  invitationCode: string,
  companyId: string,
  userEmail: string,
  groupId: string,
  expiresAt: string,
  status: string,
  firstName: string,
});

export const companyType = shape({
  id: string,
  name: string,
  dba: string,
  ein: string,
  type: string,
  naicsCode: string,
  size: string,
  url: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  postalCode: string,
  phone: string,
  phoneExt: string,
  fax: string,
  userId: string,
  planId: string,
  groups: arrayOf(groupType),
});

export const employerLinkRequestType = shape({
  id: string,
  company: companyType,
  group: groupType,
});

export const recommendedContributionType = shape({
  recommended: number,
  other: arrayOf(number),
  isMaxContributionOption: bool,
});

export const groupType = shape({
  id: string,
  name: string,
  payrollFrequency: string,
  payrollProcessingOffset: number,
  associatedLinkRequests: arrayOf(employerLinkRequestType),
  useUniqueInviteCodes: bool,
});

// Testing payrolls
export const payrollTypes = shape({
  id: string,
  scheduleId: string,
  from: string,
  to: string,
  payrollProcessingDate: string,
  onOrAfterPayrollProcessingDate: bool,
  status: string,
  gracePeriodEndDate: string,
  payPeriodStart: string,
  payPeriodEnd: string,
});

export const estimatesType = shape({
  estimates: string,
  currentMonthAmount: string,
  currentYearAmount: string,
});

export const payrollDirectoryType = shape({
  employeeId: string,
  matches: arrayOf(
    shape({
      firstName: string,
      lastName: string,
      id: string,
      dob: string,
      emails: arrayOf(string),
      phoneNumbers: arrayOf(string),
    })
  ),
});

export const userProfileSchema = PropTypes.objectOf(
  PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    lastFourOfSsn: PropTypes.string,
    state: PropTypes.string,
  })
);
