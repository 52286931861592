import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { get } from "lodash";
import { toast } from "react-toastify";
import { Card, Col, Row } from "react-bootstrap";
import { employerConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { updateProgressiveOnboarding } from "actions/employerActions";
import { progressiveOnboardingEvents } from "statics/states";
import {
  hasAddedGroups,
  hasAddedDeductionCode,
  hasInvitedEmployees,
  hasAnnouncedIcon,
} from "store/selectors/employer";

import Button from "components/Button";
import Alert from "components/Alert";
import AddDeductionCodeInstructions from "./AddDeductionCodeInstructions";

class AddDeductionCode extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    payrollProvider: PropTypes.object,

    updateProgressiveOnboarding: PropTypes.func,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    company: PropTypes.shape({
      name: PropTypes.string,
    }),

    hasAddedGroups: PropTypes.bool,
    hasAddedDeductionCode: PropTypes.bool,
    hasInvitedEmployees: PropTypes.bool,
    hasAnnouncedIcon: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  _onSubmit = () => {
    this.props
      .updateProgressiveOnboarding(
        this.props.client,
        progressiveOnboardingEvents.AddDeductionCode
      )
      .then(() => {
        if (!this.props.error) {
          this.props.onSuccess();
          toast.success("Successfully Added Deduction Code");
        }
      });
  };

  _renderForm = () => {
    const isGusto = this.props.payrollProvider === "gusto";

    return (
      <div className="download-employee-template">
        <div className="main-content">
          <Row>
            <Col lg="9">
              <Card>
                <Card.Title>
                  <p className="page-header">Adding Benefit Deductions</p>
                </Card.Title>
                <Card.Body>
                  <div>
                    <div className="announcement-header">
                      <p
                        style={{
                          fontWeight: 500,
                          color: "#0a2540",
                          fontSize: 14,
                        }}
                      >
                        Steps to Managing Your Benefit Deductions in Your
                        Payroll
                      </p>
                    </div>

                    <div>
                      {isGusto && (
                        <p style={{ color: "#858c9c", fontSize: 14 }}>
                          You will need to setup a benefit deduction code for
                          Icon in Gusto. This generic after-tax deduction will
                          be where you record payroll contributions for
                          individuals who join the plan. You should set up a new
                          deduction for Icon — don
                          {"'"}t use an existing retirement deduction type.
                        </p>
                      )}

                      {!isGusto && (
                        <p style={{ color: "#858c9c", fontSize: 14 }}>
                          You will need to setup a benefit deduction code for
                          Icon in your payroll system. This generic after-tax
                          deduction will be where you record payroll
                          contributions for individuals who join the plan. You
                          should set up a new deduction for Icon — don{"'"}t use
                          an existing retirement deduction type.
                        </p>
                      )}
                    </div>

                    <div style={{ paddingTop: 10 }}>
                      <AddDeductionCodeInstructions />
                    </div>
                  </div>

                  {this.props.error && (
                    <Alert type="error" msg={this.props.error} />
                  )}
                  <div className="submit-row">
                    <span className="cancel-btn">
                      <Button
                        btnLabel="Cancel"
                        color="cancel"
                        type="button"
                        onClick={this.props.onClose}
                      />
                    </span>
                    <Button
                      btnLabel="Done"
                      withArrow={true}
                      onClick={this._onSubmit}
                      loading={this.props.isLoading}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="add-employee-form" style={{ marginBottom: 8 }}>
        {this._renderForm()}
      </div>
    );
  }
}

const actions = [
  employerConstants.GET_PROGRESSIVE_ONBOARDING,
  employerConstants.UPDATE_PROGRESSIVE_ONBOARDING,
];

const isLoading = createLoadingSelector(actions);
const error = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const { user } = state;
  const company = state.employer.company;

  const payrollProvider = get(state.employer.company, "payrollProvider");

  return {
    user,
    company,
    payrollProvider,
    isLoading: isLoading(state),
    error: error(state),
    hasAddedGroups: hasAddedGroups(state),
    hasAddedDeductionCode: hasAddedDeductionCode(state),
    hasAnnouncedIcon: hasAnnouncedIcon(state),
    hasInvitedEmployees: hasInvitedEmployees(state),
  };
};

const mapDispatchToProps = {
  push,
  updateProgressiveOnboarding,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddDeductionCode));
