import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { groupType } from "statics/propTypes";
import { BsTrash } from "react-icons/bs";
import { toast } from "react-toastify";
import Alert from "components/Alert";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import {
  hasEnabledFinchFeature,
  hasEnabledPayrollIntegration,
  hasFullyOnboarded,
  hasStatePlanSelector,
} from "store/selectors/employer";
import { employerConstants } from "actions/types";
import {
  debounce,
  every,
  filter,
  get,
  isEmpty,
  isEqual,
  keyBy,
  keys,
  map,
  mapValues,
  pickBy,
  size,
  some,
  transform,
} from "lodash";
import { FiAlertTriangle, FiPlus, FiUpload, FiUser } from "react-icons/fi";
import {
  Card,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Table,
} from "react-bootstrap";

import {
  getAllEmployerGroups,
  getPaginatedInvites,
  resendInvitation,
  resendInvitations,
  revokeInvitation,
  updateProgressiveOnboarding,
} from "actions/employerActions";
import {
  inviteStatusToEnglish,
  inviteStatusToType,
} from "statics/statusToEnglishMapping";

import IconSpinner from "components/IconSpinner";
import StatusBox from "components/StatusBox";
import Paginator from "components/Paginator";
import Button from "components/Button";
import AddEmployeeForm from "./AddEmployeeForm";
import IconEmptyState from "components/IconEmptyState";

import "./EmployeeInvitationsTable.scss";
import { progressiveOnboardingEvents } from "statics/states";

const allGroups = {
  id: "ALL_GROUPS",
  value: "ALL_GROUPS",
  name: "All Groups",
};

export class EmployeeInvitationsTable extends React.PureComponent {
  static propTypes = {
    groupsById: PropTypes.objectOf(groupType),
    groups: PropTypes.arrayOf(groupType),
    resendInvitation: PropTypes.func.isRequired,
    revokeInvitation: PropTypes.func.isRequired,
    error: PropTypes.string,
    client: PropTypes.object,
    isResending: PropTypes.bool,
    isResendingBulk: PropTypes.bool,
    hasPayrollIntegration: PropTypes.bool,
    resendInvitations: PropTypes.func,
    push: PropTypes.func,
    getPaginatedInvites: PropTypes.func,
    paginatedEmployeeInvitations: PropTypes.arrayOf(PropTypes.shape({})),
    paginatedEmployeeInvitationsCount: PropTypes.number,
    getAllEmployerGroups: PropTypes.func.isRequired,
    hasStatePlan: PropTypes.bool,
    isFetching: PropTypes.bool,
    isRevoking: PropTypes.bool,
    hasFullyOnboarded: PropTypes.bool,
    updateProgressiveOnboarding: PropTypes.func,
    hasEnabledFinch: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      showConfirmationModal: false,
      pendingCancellationId: null,
      selectedInvitations: {},
      selectAllInvitations: {},
      limit: 10,
      offset: 0,
      search: "",
      page: 0,
      selectedGroup: "ALL_GROUPS",
      showForm: false,
      selectEveryInvitation: false,
    };
  }

  componentDidMount() {
    this._getPaginatedInvites().then(() => {
      this.setState({ initialFetching: false });
    });
  }

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () =>
      this._getPaginatedInvites()
    );
  };

  _getPaginatedInvites = () => {
    return this.props.getPaginatedInvites(this.props.client, {
      limit: this.state.limit,
      offset: this.state.offset,
      search: this.state.search,
      groupId: this.state.groupId,
    });
  };

  _onSearchChange = (e) => {
    this.setState(
      {
        search: e.target.value,
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedInvites()
    );
  };

  _debounceOnChange = debounce(this._onSearchChange, 500);

  _resendInvitation = (invitation) => {
    this.props.resendInvitation(this.props.client, invitation).then(() => {
      if (!this.props.error) {
        toast.success("Successfully resent invitation.");
      }
    });
  };

  _revokeInvitation = (invitationId) => {
    this.props.revokeInvitation(this.props.client, invitationId).then(() => {
      // close popover
      document.body.click();
      if (!this.props.error) {
        toast.success("Successfully removed invitation.");
      }

      return this._getPaginatedInvites();
    });
  };

  _handleStateFilterChange = () => {
    this.setState(
      {
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedInvites()
    );
  };

  _clearFilters = () => {
    this._textInput.value = "";
    this.setState(
      {
        search: "",
        offset: 0,
      },
      () => this._getPaginatedInvites()
    );
  };

  _createGroupFilter = () => {
    const groups = this.props.groups.map((group) => {
      return { id: group.id, value: group.id, name: group.name };
    });

    return [allGroups, ...groups];
  };

  _emptyState = () => {
    const showEmptySearch = this.state.search;

    if (showEmptySearch) {
      return (
        <>
          <IconEmptyState
            header={
              "There were no employees found matching your search or filters."
            }
            subheader="Would you like to clear filters?"
            icon={<FiAlertTriangle color="white" stroke="#60A4BF" size={16} />}
            actionText={"Clear Filters"}
            onClick={this._clearFilters}
          />
        </>
      );
    } else if (this.props.hasPayrollIntegration || this.props.hasEnabledFinch) {
      return (
        <IconEmptyState
          header={"Waiting on roster to sync."}
          icon={<FiUser color="white" stroke="#60A4BF" size={16} />}
        />
      );
    } else {
      return (
        <IconEmptyState
          header={"Get started by inviting an employee!"}
          icon={<FiUser color="white" stroke="#60A4BF" size={16} />}
        />
      );
    }
  };

  buildRows = (showUniqueColumn) => {
    return this.props.paginatedEmployeeInvitations.map((invite) => {
      let status = invite.status;

      const inviteAccepted = status === "ACCEPTED";
      const invitedRevoked = status === "REVOKED";

      const commonDateFormat = "MMM Do YYYY";
      const group = get(this.props.groupsById, invite.groupId, {});

      const groupHasUniqueCodes = group.useUniqueInviteCodes;

      const everyInviteActive = every(
        this.state.selectEveryInvitation,
        (everyInvite) => everyInvite
      );

      const hasDeselectedInvite = every(
        this.state.selectedInvitations,
        (selected) => selected
      );

      const selectedAllInvitations = every(
        this.state.selectAllInvitations,
        (checked) => checked
      );

      const clearAll =
        this.state.selectEveryInvitation &&
        everyInviteActive &&
        selectedAllInvitations &&
        hasDeselectedInvite;

      let expirationDate;

      if (!inviteAccepted && groupHasUniqueCodes) {
        const invitationExpiresAt = invite.expiresAt;

        const expiresAt = moment(invitationExpiresAt);
        expirationDate = expiresAt;
        if (!invitedRevoked && expiresAt.diff(moment().utc(), "days") < 0) {
          status = "EXPIRED";
        }
      }

      return (
        <tr key={invite.id}>
          <td>
            {!inviteAccepted &&
              !this.props.hasPayrollIntegration &&
              this.props.hasFullyOnboarded && (
                <Form.Check
                  id={invite.id}
                  type="checkbox"
                  name="selectUser"
                  checked={
                    this.state.selectedInvitations[invite.id] ||
                    this.state.selectEveryInvitation
                  }
                  onChange={(event) => {
                    if (clearAll) {
                      return this.setState({
                        selectEveryInvitation: false,
                        selectedInvitations: {},
                        selectAllInvitations: {},
                      });
                    } else {
                      this.setState({
                        selectedInvitations: {
                          ...this.state.selectedInvitations,
                          [invite.id]: event.target.checked,
                        },
                        selectEveryInvitation: false,
                      });
                    }
                  }}
                />
              )}
          </td>
          <td>{group.name}</td>
          <td>{invite.userEmail}</td>
          <td>{moment(invite.createdAt).format(commonDateFormat)}</td>
          {showUniqueColumn && (
            <td>
              {!inviteAccepted && groupHasUniqueCodes
                ? expirationDate.format(commonDateFormat)
                : null}
            </td>
          )}
          <td>
            <StatusBox
              status={inviteStatusToEnglish[status]}
              type={inviteStatusToType[status]}
            />
          </td>

          <td>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="bottom"
              overlay={
                <Popover className="action-popover">
                  <Popover.Content>
                    <p>Are you sure you want to remove this invitation?</p>
                    <div>
                      <Button
                        type="button"
                        color="cancel"
                        name="cancel"
                        btnLabel="Cancel"
                        onClick={() => document.body.click()}
                        size="sm"
                      />
                      <Button
                        type="button"
                        color="red"
                        name="submit"
                        btnLabel="Remove"
                        withArrow={true}
                        loading={this.props.isRevoking}
                        onClick={() => this._revokeInvitation(invite.id)}
                        size="sm"
                      />
                    </div>
                  </Popover.Content>
                </Popover>
              }
            >
              <span className="cancel-action">
                <BsTrash size={18} color="#B12121" />
              </span>
            </OverlayTrigger>
          </td>
        </tr>
      );
    });
  };

  _handleOnClick = () => {
    let invitations, groupIds;
    const everyInvitation = this.state.selectEveryInvitation;

    if (everyInvitation) {
      groupIds =
        this.state.selectedGroup === "ALL_GROUPS"
          ? map(this.props.groups, "id")
          : [this.state.selectedGroup];
    } else {
      invitations = keys(
        pickBy(this.state.selectedInvitations, (value) => value)
      );
    }

    this.props
      .resendInvitations(this.props.client, { invitations, groupIds })
      .then(() => {
        if (!this.props.hasFullyOnboarded) {
          this.props.updateProgressiveOnboarding(
            this.props.client,
            progressiveOnboardingEvents.LaunchPlan
          );
        }
        return this._getPaginatedInvites();
      })
      .then(() => {
        toast.success("Successfully sent invitations.");
        this.setState({
          selectAllInvitations: {},
          selectedInvitations: {},
          selectEveryInvitation: false,
        });
      });
  };

  _clearChecked = () => {
    const everyInviteActive = every(
      this.state.selectEveryInvitation,
      (everyInvite) => everyInvite
    );
    const invitations = this.props.paginatedEmployeeInvitations;
    const selectedInvites = this.state.selectedInvitations;
    const paginatedEmployeeInvitationsCount =
      this.props.paginatedEmployeeInvitationsCount;

    const numberOfSelectedInvites = size(
      filter(selectedInvites, (checked) => checked)
    );
    const totalNumbersAreEqual = isEqual(
      paginatedEmployeeInvitationsCount,
      numberOfSelectedInvites
    );

    if (
      (this.state.selectEveryInvitation && everyInviteActive) ||
      totalNumbersAreEqual
    ) {
      return this.setState({
        selectEveryInvitation: false,
        selectedInvitations: {},
        selectAllInvitations: {},
      });
    } else if (this.state.selectAllInvitations[this.state.page]) {
      const selectedInvitations = transform(
        invitations,
        (acc, invite) => {
          if (invite.status !== "ACCEPTED") {
            acc[invite.id] = false;
          }
          return acc;
        },
        {}
      );
      return this.setState({
        selectedInvitations: {
          ...this.state.selectedInvitations,
          ...selectedInvitations,
        },
        selectAllInvitations: {
          ...this.state.selectAllInvitations,
          [this.state.page]: false,
        },
      });
    } else {
      return this.setState({
        selectedInvitations: {},
        selectAllInvitations: {},
      });
    }
  };

  _selectedInvitesText = () => {
    const selectedInvites = this.state.selectedInvitations;
    const numberOfSelectedInvites = size(
      filter(selectedInvites, (checked) => checked)
    );

    const paginatedEmployeeInvitationsCount =
      this.props.paginatedEmployeeInvitationsCount;
    const pageSize = numberOfSelectedInvites > this.state.limit;

    const totalNumbersAreEqual = isEqual(
      paginatedEmployeeInvitationsCount,
      numberOfSelectedInvites
    );

    if (pageSize) {
      return (
        <div className="popover-box">
          <p className="selected-count">
            You have selected<p className="count">{numberOfSelectedInvites}</p>{" "}
            employee invitations.
          </p>
          {!totalNumbersAreEqual && (
            <a
              className="selectedAll-count"
              onClick={() => {
                this.setState({
                  selectEveryInvitation: true,
                  selectedInvitations: mapValues(
                    this.state.selectedInvitations,
                    () => {
                      return true;
                    }
                  ),
                });
              }}
            >
              Select all{" "}
              <p className="all-count">{paginatedEmployeeInvitationsCount} </p>
              employee invitations.
            </a>
          )}
          {totalNumbersAreEqual && (
            <a className="selectedAll-count" onClick={this._clearChecked}>
              Clear Selection
            </a>
          )}
        </div>
      );
    } else {
      return (
        <div className="popover-box">
          <p className="selected-count">
            You have selected<p className="count">{numberOfSelectedInvites}</p>{" "}
            employee invitations.
          </p>
          <a
            className="selectedAll-count"
            onClick={() => {
              this.setState({
                selectEveryInvitation: true,
                selectedInvitations: mapValues(
                  this.state.selectedInvitations,
                  () => {
                    return true;
                  }
                ),
              });
            }}
          >
            Select all{" "}
            <p className="all-count">{paginatedEmployeeInvitationsCount} </p>
            employee invitations.
          </a>
        </div>
      );
    }
  };

  _allInvitesSelected = () => {
    const numberOfInvites = this.props.paginatedEmployeeInvitationsCount;
    const pageSize = numberOfInvites > this.state.limit;
    const nothingSelected = isEmpty(this.state.selectAllInvitations);
    const allCheckedActive = every(
      this.state.selectAllInvitations,
      (checked) => checked
    );

    const hasAllSelectedInvite = every(
      this.state.selectedInvitations,
      (selected) => selected
    );

    const selectedCheck = allCheckedActive || !hasAllSelectedInvite;

    if (!nothingSelected && selectedCheck && pageSize) {
      return (
        <div>
          {!this.state.selectEveryInvitation && hasAllSelectedInvite && (
            <>{this._selectedInvitesText()}</>
          )}
          {this.state.selectEveryInvitation && (
            <div className="popover-box">
              <p className="selected-count">
                All <p className="count">{numberOfInvites}</p> invitations in
                table are selected.
              </p>
              <a className="selectedAll-count" onClick={this._clearChecked}>
                Clear Selection
              </a>
            </div>
          )}
        </div>
      );
    }
  };

  _buildGroupTables = () => {
    const showBlankSlate =
      isEmpty(this.props.paginatedEmployeeInvitations) &&
      !this.props.isFetching;

    const invitations = this.props.paginatedEmployeeInvitations;
    const group = this.props.groupsById;

    const groupHasUniqueCodes = some(
      group,
      (group) => group.useUniqueInviteCodes
    );

    const invitationRows = this.buildRows(groupHasUniqueCodes);

    const groupContainsUnacceptedInvite = some(
      invitations,
      (invite) => invite.status !== "ACCEPTED"
    );

    return (
      <>
        {showBlankSlate && (
          <div className="clear-search">{this._emptyState()}</div>
        )}
        {!showBlankSlate && (
          <>
            <div>{this._allInvitesSelected()}</div>
            <Table
              striped
              responsive
              style={{ marginTop: 12, marginBottom: 12 }}
            >
              <thead>
                <tr>
                  <th className="custom-checkbox">
                    {groupContainsUnacceptedInvite &&
                      !this.props.hasPayrollIntegration &&
                      this.props.hasFullyOnboarded && (
                        <Form.Check
                          type="checkbox"
                          name="selectUsers"
                          checked={
                            this.state.selectAllInvitations[this.state.page] ||
                            this.state.selectEveryInvitation
                          }
                          onChange={(event) => {
                            const checked = event.target.checked;
                            if (!checked) {
                              return this._clearChecked();
                            }
                            const selectedInvitations = transform(
                              invitations,
                              (acc, invite) => {
                                if (invite.status !== "ACCEPTED") {
                                  acc[invite.id] = checked;
                                }
                                return acc;
                              },
                              {}
                            );

                            this.setState({
                              selectedInvitations: {
                                ...this.state.selectedInvitations,
                                ...selectedInvitations,
                              },
                              selectAllInvitations: {
                                ...this.state.selectAllInvitations,
                                [this.state.page]: checked,
                              },
                            });
                          }}
                        />
                      )}
                  </th>
                  <th>Group</th>
                  <th>User Email</th>
                  <th>Date Sent</th>
                  {groupHasUniqueCodes && <th>Date of Expiration</th>}
                  <th>Status</th>
                  <th />
                </tr>
              </thead>
              <tbody>{invitationRows}</tbody>
            </Table>
            <div className="paginator-box">
              <Paginator
                onChange={this._onPageChange}
                pageCount={
                  this.props.paginatedEmployeeInvitationsCount /
                  this.state.limit
                }
                page={this.state.page}
              />
            </div>
          </>
        )}
      </>
    );
  };

  _actionBtnToggle = () => {
    if (this.props.hasPayrollIntegration) return null;
    const showBlankSlate =
      isEmpty(this.props.paginatedEmployeeInvitations) &&
      !this.props.isFetching;

    if (!this.props.hasStatePlan) {
      return (
        <span style={{ paddingLeft: 5 }}>
          <Button
            icon={{
              icon: <FiUpload size={12} color={"#fffff"} />,
              position: "left",
            }}
            btnLabel="Add Employees"
            size="sm"
            name="action"
            onClick={() => this.setState({ showForm: true })}
          >
            Add Employee
          </Button>
        </span>
      );
    } else if (this.props.hasStatePlan) {
      const uploadButton = (
        <span style={{ paddingLeft: 5 }}>
          <Button
            icon={{
              icon: <FiUpload size={12} color={"#fffff"} />,
              position: "left",
            }}
            color="action"
            name="action"
            size="sm"
            onClick={() => {
              this.props.push("/dashboard/users/employees/upload");
            }}
            btnLabel="Upload File"
          />
        </span>
      );
      const singleUploadButton = (
        <span style={{ paddingLeft: 5 }}>
          <Button
            color="secondary"
            name="action"
            size="sm"
            onClick={() => {
              this.props.push("/dashboard/users/employees/upload-single");
            }}
            btnLabel="Add Single Employee"
          />
        </span>
      );
      return (
        <>
          {singleUploadButton}
          {showBlankSlate && uploadButton}
        </>
      );
    } else {
      return null;
    }
  };

  _handleChange = (e) => {
    const groupId = e.target.value;
    const showAllgroups = groupId === "ALL_GROUPS";
    const allGroups = showAllgroups ? undefined : groupId;
    this._clearChecked();

    this.setState(
      {
        selectedGroup: groupId,
        groupId: allGroups,
      },
      () => {
        this._getPaginatedInvites();
      }
    );
  };

  _renderEmployeeTable() {
    const allUsersAccepted = every(
      this.props.paginatedEmployeeInvitations,
      (invite) => invite.status === "ACCEPTED"
    );

    const shouldShowBulkInviteButton = !allUsersAccepted;

    const numberOfInvites = this.props.paginatedEmployeeInvitationsCount;

    const inviteTable = this._buildGroupTables();

    const buttonsDisabled = every(
      this.state.selectedInvitations,
      (checked) => !checked
    );

    return (
      <>
        {this.props.error && <Alert type="error" msg={this.props.error} />}{" "}
        <div className="group-table">
          <Card className="employee_-nvitations-table">
            <div className="widget-header">
              <span>
                <Card.Title>
                  Employees:
                  <p className="invite-number">
                    Invited (<span className="number">{numberOfInvites}</span>)
                  </p>
                </Card.Title>
                <Card.Text>
                  <Row className="filters-column">
                    <Col>
                      <div className="Group-filter">
                        <Form.Control
                          className="group-filter"
                          as="select"
                          id="inlineFormCustomSelect"
                          value={this.state.selectedGroup}
                          onChange={this._handleChange}
                        >
                          {map(this._createGroupFilter(), (group) => (
                            <option id={group.id} value={group.value}>
                              {group.name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="search-bar">
                        <Form.Control
                          name="search"
                          as="input"
                          placeholder="Search"
                          onChange={this._debounceOnChange}
                          ref={(node) => (this._textInput = node)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card.Text>
              </span>
              <div className="btn-row">
                {!this.props.hasFullyOnboarded && numberOfInvites > 0 && (
                  <Button
                    btnLabel="Launch Plan"
                    size="sm"
                    name="action"
                    onClick={() =>
                      this.props.push("/dashboard?showLaunchPlan=true")
                    }
                  />
                )}
                <span className="invite-btn">
                  {shouldShowBulkInviteButton &&
                    !this.props.hasPayrollIntegration &&
                    this.props.hasFullyOnboarded && (
                      <Button
                        icon={{
                          icon: <FiPlus size={14} color={"#fffff"} />,
                          position: "left",
                        }}
                        size="sm"
                        color="action"
                        name="submit"
                        disabled={buttonsDisabled}
                        withArrow={true}
                        loading={this.props.isResendingBulk}
                        onClick={this._handleOnClick}
                        btnLabel="Invite Selected"
                      />
                    )}
                </span>
                {this._actionBtnToggle()}
              </div>
            </div>
            {this.props.isFetching && <IconSpinner centered />}
            {inviteTable}
          </Card>
        </div>
      </>
    );
  }

  render() {
    const content = this.state.showForm ? (
      <AddEmployeeForm onClose={() => this.setState({ showForm: false })} />
    ) : (
      this._renderEmployeeTable()
    );
    return <div className="add-employee-form">{content}</div>;
  }
}

const errorSelector = createErrorSelector([
  employerConstants.GET_EMPLOYEE_INVITES,
  employerConstants.RESEND_INVITE_EMPLOYEE,
  employerConstants.REVOKE_INVITE_EMPLOYEE,
  employerConstants.RESEND_INVITES_EMPLOYEE,
  employerConstants.GET_PAGINATED_INVITES,
]);

const isLoadingSelector = createLoadingSelector(
  employerConstants.GET_PAGINATED_INVITES
);

const isResendingSelector = createLoadingSelector([
  employerConstants.RESEND_INVITE_EMPLOYEE,
]);

const isResendingBulkSelector = createLoadingSelector([
  employerConstants.RESEND_INVITES_EMPLOYEE,
]);

const isRevokingSelector = createLoadingSelector(
  employerConstants.REVOKE_INVITE_EMPLOYEE
);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isResending: isResendingSelector(state),
    isResendingBulk: isResendingBulkSelector(state),
    groupsById: keyBy(state.employer.groups, "id"),
    paginatedEmployeeInvitations: state.employer.paginatedEmployeeInvitations,
    paginatedEmployeeInvitationsCount:
      state.employer.paginatedEmployeeInvitationsCount,
    groups: state.employer.groups,
    hasStatePlan: hasStatePlanSelector(state),
    isFetching: isLoadingSelector(state),
    isRevoking: isRevokingSelector(state),
    hasFullyOnboarded: hasFullyOnboarded(state),
    hasEnabledFinch: hasEnabledFinchFeature(state),
    hasPayrollIntegration: hasEnabledPayrollIntegration(state),
  };
};

const mapDispatchToProps = {
  push,
  resendInvitation,
  revokeInvitation,
  resendInvitations,
  getPaginatedInvites,
  getAllEmployerGroups,
  updateProgressiveOnboarding,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployeeInvitationsTable));
