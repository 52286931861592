import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { withApollo } from "@apollo/client/react/hoc";

import {
  approveRoster,
  getEmployeeRoster,
  getFinchStatus,
  resyncEmployeeRoster,
  updateProgressiveOnboarding,
} from "actions/employerActions";
import { employerConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import IconSpinner from "components/IconSpinner";
import IconTable from "components/IconTable";
import { Card, Form } from "react-bootstrap";
import { isEmpty, keys, map, size } from "lodash";
import Paginator from "components/Paginator";
import Button from "components/Button";
import StatusBox, { statusBoxStatuses } from "components/StatusBox";
import { FaUndo } from "react-icons/fa";
import { toast } from "react-toastify";

const linkStatuses = {
  UNKNOWN: "UNKNOWN",
  PENDING: "PENDING",
  ACTIVE: "ACTIVE",
  REJECTED: "REJECTED",
  ADMIN_REVIEW_REQUIRED: "ADMIN_REVIEW_REQUIRED",
  DEACTIVATED_BY_EMPLOYER: "DEACTIVATED_BY_EMPLOYER",
};

function linkStatusToStatusBox(linkStatus) {
  let status, type;
  switch (linkStatus) {
    case linkStatuses.UNKNOWN: {
      status = "NEW";
      type = statusBoxStatuses.primary;
      break;
    }
    case linkStatuses.PENDING: {
      status = "PENDING";
      type = statusBoxStatuses.success;
      break;
    }
    case linkStatuses.ACTIVE: {
      status = "ACTIVE";
      type = statusBoxStatuses.success;
      break;
    }
    case linkStatuses.REJECTED: {
      status = "REJECTED";
      type = statusBoxStatuses.danger;
      break;
    }
    case linkStatuses.DEACTIVATED_BY_EMPLOYER: {
      status = "DEACTIVATED";
      type = statusBoxStatuses.danger;
      break;
    }
    default: {
      status = "NEW";
      type = statusBoxStatuses.primary;
      break;
    }
  }

  return <StatusBox status={status} type={type} />;
}

class RosterApprovalTable extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    updateProgressiveOnboarding: PropTypes.func,
    resyncEmployeeRoster: PropTypes.func,
    getFinchStatus: PropTypes.func,
    approveRoster: PropTypes.func,
    getEmployeeRoster: PropTypes.func,
    client: PropTypes.shape({}),
    error: PropTypes.string,
    employeeRoster: PropTypes.array,
    employeeRosterCount: PropTypes.number,
    isApproving: PropTypes.bool,
    isResyncing: PropTypes.bool,
    isFullyOnboarded: PropTypes.bool,
    isGettingRoster: PropTypes.bool,
    employeeRosterFilterValues: PropTypes.shape({
      state: PropTypes.arrayOf(PropTypes.string),
      city: PropTypes.arrayOf(PropTypes.string),
    }),
  };

  static defaultProps = {
    isFullyOnboarded: false,
  };

  constructor() {
    super();

    this.state = {
      initialFetching: true,
      limit: 50,
      offset: 0,
      page: 0,
      selectedEmployees: {},
      rejectedEmployees: {},
    };
  }

  async componentDidMount() {
    await this._getPaginatedRoster();
    this.setState({
      initialFetching: false,
    });
  }

  _getPaginatedRoster = () => {
    const filter =
      this.state.stateFilter && this.state.stateFilter !== "all"
        ? `state|eq|${this.state.stateFilter}`
        : "";

    const params = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter,
    };
    return this.props.getEmployeeRoster(this.props.client, params);
  };

  _approveRoster = () => {
    const rejectedEmployeeIds = keys(this.state.rejectedEmployees);
    this.props
      .approveRoster(this.props.client, rejectedEmployeeIds)
      .then(() => {
        if (this.props.error) {
          toast.error("Something went wrong during roster approval.");
          return;
        }
        return this.props.getFinchStatus(this.props.client).then(() => {
          toast.success("Successfully approved roster.");
          this.props.onClose();
        });
      });
  };

  _resyncRoster = async () => {
    await this.props.resyncEmployeeRoster(this.props.client);
    this.props.onClose();
  };

  _handleReject = () => {
    this.setState({
      selectedEmployees: {},
      rejectedEmployees: {
        ...this.state.selectedEmployees,
        ...this.state.rejectedEmployees,
      },
    });
  };

  _removeFromRejected = (employeeId) => {
    const rejectedEmployees = { ...this.state.rejectedEmployees };

    delete rejectedEmployees[employeeId];
    this.setState({
      rejectedEmployees,
    });
  };

  _getContent = () => {
    const tableData = map(this.props.employeeRoster, (emp) => ({
      ...emp,
      unselectable:
        emp.linkStatus !== linkStatuses.UNKNOWN ||
        this.state.rejectedEmployees[emp.employeeId],
    }));
    const rosterColumns = [
      {
        key: "firstName",
        label: "First Name",
      },
      {
        key: "lastName",
        label: "Last Name",
      },
      {
        key: "dob",
        label: "DOB",
      },
      {
        key: "email",
        label: "Email",
      },
      {
        label: "status",
        customComponent: ({ linkStatus, employeeId }) => {
          if (this.state.rejectedEmployees[employeeId]) {
            return linkStatusToStatusBox(linkStatuses.REJECTED);
          }
          return linkStatusToStatusBox(linkStatus);
        },
      },
      {
        customComponent: ({ employeeId }) => {
          if (this.state.rejectedEmployees[employeeId]) {
            return (
              <div className="undo-button">
                <FaUndo
                  size={16}
                  onClick={() => this._removeFromRejected(employeeId)}
                />
              </div>
            );
          }

          return null;
        },
      },
    ];
    return (
      <>
        <div className="filters-and-buttons">
          <div className="table-filters">
            <span>
              <Form.Control
                className="table-filter"
                as="select"
                id="inlineFormCustomSelect"
                value={this.state.stateFilter}
                onChange={(e) => {
                  this.setState(
                    {
                      stateFilter: e.target.value,
                    },
                    () => {
                      this._getPaginatedRoster();
                    }
                  );
                }}
              >
                <option value="all">All States</option>
                {map(this.props.employeeRosterFilterValues.state, (state) => (
                  <option id={state} value={state} key={state}>
                    {state}
                  </option>
                ))}
              </Form.Control>
            </span>
          </div>

          <div className="reject-btn-container">
            <Button
              disabled={isEmpty(this.state.selectedEmployees)}
              color={"red"}
              name="action"
              onClick={this._handleReject}
            >
              Reject Selected
            </Button>
          </div>
        </div>
        {this.props.isGettingRoster && <IconSpinner centered />}
        <IconTable
          columns={rosterColumns}
          data={tableData}
          hasSelectableRows
          uniqueRowIdentifier="employeeId"
          selectedRows={this.state.selectedEmployees}
          onRowSelect={(id, checked) => {
            const selectedEmployees = { ...this.state.selectedEmployees };

            if (!checked) {
              delete selectedEmployees[id];
            } else {
              selectedEmployees[id] = checked;
            }
            this.setState({
              selectedEmployees,
            });
          }}
        />
        <Paginator
          onChange={this._onPageChange}
          pageCount={this.props.employeeRosterCount / this.state.limit}
          page={this.state.page}
        />
        <div className="submit-row">
          <span className="cancel-btn">
            <Button
              btnLabel="Re-Sync Roster"
              color="cancel"
              name="cancel"
              type="button"
              withArrow={true}
              loading={this.props.isResyncing}
              onClick={this._resyncRoster}
            />
          </span>
          <Button
            btnLabel="Approve Roster"
            name="submit"
            withArrow={true}
            loading={this.props.isApproving}
            onClick={this._approveRoster}
          />
        </div>
      </>
    );
  };

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () => this._getPaginatedRoster());
  };

  render() {
    const rosterSize = size(this.props.employeeRoster);

    return (
      <Card className={"table-container"}>
        <div className="widget-header">
          <span>
            <Card.Title>
              Employee Roster:
              <p className="number-context">
                Pending (<p className="number">{rosterSize}</p>)
              </p>
            </Card.Title>
          </span>
        </div>
        <Card.Body>
          {this.state.initialFetching && <IconSpinner centered />}
          {!this.state.initialFetching && this._getContent()}
        </Card.Body>
      </Card>
    );
  }
}

const isApproving = createLoadingSelector([
  employerConstants.UPDATE_PROGRESSIVE_ONBOARDING,
  employerConstants.APPROVE_ROSTER,
  employerConstants.GET_FINCH_STATUS,
]);
const isResyncing = createLoadingSelector(employerConstants.RESYNC_ROSTER);

const isGettingRoster = createLoadingSelector(
  employerConstants.GET_EMPLOYEE_ROSTER
);

const error = createErrorSelector([
  employerConstants.UPDATE_PROGRESSIVE_ONBOARDING,
  employerConstants.RESYNC_ROSTER,
  employerConstants.APPROVE_ROSTER,
  employerConstants.GET_FINCH_STATUS,
]);

const mapStateToProps = (state) => {
  return {
    isApproving: isApproving(state),
    isResyncing: isResyncing(state),
    error: error(state),
    employeeRoster: state.employer.paginatedEmployeeRoster,
    employeeRosterCount: state.employer.paginatedEmployeeRosterCount,
    employeeRosterFilterValues: state.employer.employeeRosterFilterValues,
    isGettingRoster: isGettingRoster(state),
  };
};

const mapDispatchToProps = {
  updateProgressiveOnboarding,
  getEmployeeRoster,
  resyncEmployeeRoster,
  approveRoster,
  getFinchStatus,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(RosterApprovalTable));
