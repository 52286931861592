import React from "react";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { get, isEmpty } from "lodash";
import { PropTypes } from "prop-types";
import { CSVLink } from "react-csv";
import { FiDownload } from "react-icons/fi";

import { userProfilesSelector } from "store/selectors/user";

import "./PayrollStatementDownloader.scss";
export class PayrollStatementDownloader extends React.PureComponent {
  static propTypes = {
    group: PropTypes.object.isRequired,
    userProfiles: PropTypes.object.isRequired,
    bankAccountNumber: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    adminEmail: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    payrollType: PropTypes.string.isRequired,
    payrollInput: PropTypes.shape({
      contributionsToProcess: PropTypes.arrayOf(PropTypes.object).isRequired,
      skippedContributions: PropTypes.arrayOf(PropTypes.object).isRequired,
    }),
    reason: PropTypes.string,
    userProfilesHasMetadata: PropTypes.bool,
    text: PropTypes.string,
  };

  _buildCsvData() {
    const { payrollInput } = this.props;
    const approved = payrollInput.contributionsToProcess.map((contribution) => {
      const userProfile = get(
        this.props.userProfiles,
        contribution.employeeId,
        {}
      );
      if (this.props.userProfilesHasMetadata) {
        return [
          userProfile.firstName,
          userProfile.lastName,
          userProfile.lastFourOfSsn,
          get(userProfile, "directoryInfo.location", ""),
          get(userProfile, "directoryInfo.department", ""),
          contribution.amount,
        ];
      }
      return [
        userProfile.firstName,
        userProfile.lastName,
        userProfile.lastFourOfSsn,
        contribution.amount,
      ];
    });
    const withMetadataColumns = [
      "First Name",
      "Last Name",
      "SSN",
      "Location",
      "Department",
      "Amount",
    ];
    const withoutMetadataColumns = ["First Name", "Last Name", "SSN", "Amount"];
    const headers = [
      [
        "Group Name",
        "Bank Account Number",
        "Start",
        "End",
        "Executed By",
        "Type",
        "Reason",
        "Total Amount",
      ],
      [
        this.props.group.name,
        `${this.props.bankAccountNumber}`,
        this.props.startDate,
        this.props.endDate,
        this.props.adminEmail,
        this.props.payrollType,
        this.props.reason || "",
        this.props.totalAmount,
      ],
      [], // new line
    ];
    const approvedContributions = [
      ...headers,
      this.props.userProfilesHasMetadata
        ? withMetadataColumns
        : withoutMetadataColumns,
      ...approved,
    ];
    const hasSkippedContributions = !isEmpty(payrollInput.skippedContributions);
    if (!hasSkippedContributions) {
      return approvedContributions;
    }
    const skippedContributions = payrollInput.skippedContributions.map(
      (contribution) => {
        const userProfile = get(
          this.props.userProfiles,
          contribution.employeeId,
          {}
        );
        if (this.props.userProfilesHasMetadata) {
          return [
            userProfile.firstName,
            userProfile.lastName,
            userProfile.lastFourOfSsn,
            get(userProfile, "directoryInfo.location", ""),
            get(userProfile, "directoryInfo.department", ""),
            "Skipped",
            contribution.skippedAmount,
            contribution.amount,
          ];
        }
        return [
          userProfile.firstName,
          userProfile.lastName,
          userProfile.lastFourOfSsn,
          "Skipped",
          contribution.skippedAmount,
          contribution.amount,
        ];
      }
    );
    const withMetadataSkippedColumns = [
      "First Name",
      "Last Name",
      "SSN",
      "Location",
      "Department",
      "Status",
      "Skipped Amount",
      "Amount",
    ];
    const withoutMetadataSkippedColumn = [
      "First Name",
      "Last Name",
      "SSN",
      "Status",
      "Skipped Amount",
      "Amount",
    ];
    if (isEmpty(approved)) {
      return [
        ...headers,
        this.props.userProfilesHasMetadata
          ? withMetadataSkippedColumns
          : withoutMetadataSkippedColumn,
        ...skippedContributions,
      ];
    }
    return [
      ...approvedContributions,
      [], // new line
      this.props.userProfilesHasMetadata
        ? withMetadataSkippedColumns
        : withoutMetadataSkippedColumn,
      ...skippedContributions,
    ];
  }

  _buildFilename() {
    return `${this.props.group.name}-${this.props.startDate}-${this.props.endDate}`;
  }

  render() {
    return (
      <span className="csv-downloader">
        <CSVLink data={this._buildCsvData()} filename={this._buildFilename()}>
          <div className="downloader-description">
            <FiDownload
              fill={"#FFFFFF"}
              color={"#FFFFFF"}
              stroke={"#29b0c2"}
              size={"16px"}
            />
            <p className="downloader-text"> {this.props.text}</p>
          </div>
        </CSVLink>
      </span>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminEmail: get(state, "user.auth0User.email"),
    userProfiles: userProfilesSelector(state),
    userProfilesHasMetadata: get(
      state,
      "employer.userProfilesHasMetadata",
      false
    ),
  };
};

export default connect(mapStateToProps)(withApollo(PayrollStatementDownloader));
