import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

function LottieAnimation() {
  const container = useRef(null);

  useEffect(() => {
    if (container.current) {
      const animation = lottie.loadAnimation({
        container: container.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: require("/public/assets/transferingFiles.json"),
      });

      return () => {
        if (animation) {
          animation.destroy();
        }
      };
    }
  }, []);

  return (
    <div
      className="container"
      style={{ width: 300, height: 300 }}
      ref={container}
    ></div>
  );
}

export default LottieAnimation;
