import React from "react";
import { ErrorBoundary as ErrorBoundaryWrapper } from "react-error-boundary";
import Alert from "components/Alert";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

// component displayed when child component has exception occur
const ErrorFallback = () => {
  return (
    <Alert msg="Something went wrong. Please try again later." type="error" />
  );
};

const ErrorBoundary = ({ children, fallbackComponent = ErrorFallback }) => {
  return (
    <ErrorBoundaryWrapper
      FallbackComponent={fallbackComponent}
      // can remove this probably unless there is a way to leverage this with sentry for better telemetry
      onError={(err) => {
        Sentry.captureException(err);
        console.error(err);
      }}
    >
      {children}
    </ErrorBoundaryWrapper>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  fallbackComponent: PropTypes.node,
};

export default ErrorBoundary;
