import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, Col, Form, Row } from "react-bootstrap";
import { push } from "connected-react-router";
import { debounce, isEmpty, map } from "lodash";
import { getPaginatedCompanies } from "actions/adminActions";
import { createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { getStatusLabel } from "statics/states";
import { FiUsers } from "react-icons/fi";
import { Link } from "react-router-dom";

import IconTableHeader from "components/IconTableHeader";
import IconEmptyState from "components/IconEmptyState";
import IconSpinner from "components/IconSpinner";
import IconTable from "components/IconTable";
import Paginator from "components/Paginator";

const stateFilters = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
  { value: "PENDING_KYB", label: "Pending KYB" },
  { value: "PENDING_KYB_APPROVAL", label: "Pending Approval" },
  { value: "KYB_YELLOW_PATH", label: "Pending Yellowpath Docs" },
  { value: "DENIED", label: "Denied" },
];

class Employers extends React.PureComponent {
  static propTypes = {
    companies: PropTypes.arrayOf(PropTypes.shape({})),
    getPaginatedCompanies: PropTypes.func,
    client: PropTypes.object,
    push: PropTypes.func,
    isFetching: PropTypes.bool,
    companiesCount: PropTypes.number,
  };

  constructor() {
    super();

    this.state = {
      limit: 50,
      offset: 0,
      search: "",
      initialFetching: true,
      page: 0,
      stateFilter: "",
    };
  }

  componentDidMount() {
    this._getPaginatedCompanies().then(() => {
      this.setState({ initialFetching: false });
    });
  }

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () =>
      this._getPaginatedCompanies()
    );
  };

  _getPaginatedCompanies = () => {
    return this.props.getPaginatedCompanies(this.props.client, {
      limit: this.state.limit,
      offset: this.state.offset,
      search: this.state.search,
      stateFilter: this.state.stateFilter,
    });
  };

  _onSearchChange = (e) => {
    this.setState(
      {
        search: e.target.value,
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedCompanies()
    );
  };

  _debounceOnChange = debounce(this._onSearchChange, 500);

  _getEmployersData = () => {
    return this.props.companies.map((company) => {
      const status = getStatusLabel(company.currentState);
      const commonDateFormat = "MMM Do YYYY";
      return {
        companyId: company.id,
        companyName: company.name,
        companyDBA: company.dba,
        currentStatus: status,
        createdAt: moment(company.createdAt).format(commonDateFormat),
      };
    });
  };

  _buildEmployersColumns = () => {
    const columns = [
      {
        label: "Company Name",
        key: "companyName",
      },

      {
        label: "Company DBA",
        key: "companyDBA",
      },
      {
        label: "Company ID",
        key: "companyId",
      },
      {
        label: "Company State",
        key: "currentStatus",
      },

      {
        label: "Created Date",
        key: "createdAt",
      },

      {
        customComponent: (props) => {
          return (
            <div>
              <Link to={`/employers/${props.companyId}`} className="icon-link">
                View Info
              </Link>
            </div>
          );
        },
      },
    ];
    return columns;
  };

  _handleStateFilterChange = (e) => {
    this.setState(
      {
        stateFilter: e.target.value,
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedCompanies()
    );
  };

  _clearFilters = () => {
    this._textInput.value = "";
    this.setState(
      {
        search: "",
        stateFilter: "",
        offset: 0,
      },
      () => this._getPaginatedCompanies()
    );
  };

  render() {
    const columns = this._buildEmployersColumns();
    const data = this._getEmployersData();

    if (this.state.initialFetching) {
      return <IconSpinner />;
    }

    const showBlankSlate = isEmpty(this.props.companies);
    return (
      <div className="mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Employers</h1>

            <div className="main-content">
              <Card>
                {this.props.isFetching && <IconSpinner centered />}

                <Row>
                  <Col md={6}>
                    <div
                      style={{ paddingLeft: 15, paddingTop: 15 }}
                      className="search-bar"
                    >
                      <Form.Label>Search by name or id</Form.Label>

                      <Form.Control
                        name="search"
                        placeholder="search"
                        onChange={this._debounceOnChange}
                        ref={(node) => (this._textInput = node)}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <div
                      style={{ paddingLeft: 15, paddingBottom: 15 }}
                      className="state-filter"
                    >
                      <Form.Label>Filter by state</Form.Label>
                      <Form.Control
                        as="select"
                        name="stateFilter"
                        value={this.state.stateFilter}
                        onChange={this._handleStateFilterChange}
                      >
                        <option value="">None</option>
                        {map(stateFilters, (filter) => (
                          <option key={filter.label} value={filter.value}>
                            {filter.label}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </Col>
                </Row>
                {showBlankSlate && (
                  <div style={{ marginTop: 12 }}>
                    <IconEmptyState
                      header={
                        "There were no companies found matching your search or filters"
                      }
                      subheader="Would you like to clear filters?"
                      icon={
                        <FiUsers color="white" stroke="#60A4BF" size={16} />
                      }
                      actionText={"Clear Filters"}
                      onClick={this._clearFilters}
                    />
                  </div>
                )}

                {!showBlankSlate && (
                  <>
                    <IconTableHeader
                      tableHeader="Employers"
                      tableCount={this.props.companiesCount}
                    />
                    <IconTable columns={columns} data={data} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: 15,
                      }}
                    >
                      <Paginator
                        onChange={this._onPageChange}
                        pageCount={this.props.companiesCount / this.state.limit}
                        page={this.state.page}
                      />
                    </div>
                  </>
                )}
              </Card>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const isLoadingSelector = createLoadingSelector(
  adminConstants.GET_PAGINATED_COMPANIES
);

const mapStateToProps = (state) => {
  return {
    isFetching: isLoadingSelector(state),
    companies: state.admin.companies,
    companiesCount: state.admin.companiesCount,
  };
};

const mapDispatchToProps = {
  push,
  getPaginatedCompanies,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Employers));
