import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import ProfileInfoCard from "./ProfileInfoCard";

class ProfileInfo extends React.Component {
  static propTypes = {
    company: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      postalCode: PropTypes.string,
      phone: PropTypes.string,
      phoneExt: PropTypes.string,
      fax: PropTypes.string,
    }),
    client: PropTypes.object,
    setTab: PropTypes.func,
    push: PropTypes.func,
  };
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div
        className="tab-pane"
        id="account"
        role="tabpanel"
        aria-labelledby="account_tab"
      >
        <ProfileInfoCard companyInfo={this.props.company} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const company = state.employer.company;

  return { company, ownProps };
};

const mapDispatchToProps = { push };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ProfileInfo));
