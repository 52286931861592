import env from "@beam-australia/react-env";
import { camelCase, get, map, some } from "lodash";

export const superAdminRole = camelCase("Icon Super Admin");
export const regularAdminRole = camelCase("Icon Admin");
export const employerAdminRole = camelCase("Icon Employer Admin");
export const salesAgentRole = camelCase("Icon Sales Agent");

export const getAdminRoles = (state) => {
  const roleProperty = `https://${env("AUTH0_DOMAIN")}/roles`;
  const roles = get(state, `user.auth0User["${roleProperty}"]`, []);
  return map(roles, camelCase);
};

export const isSuperIconAdmin = (state) => {
  const roles = getAdminRoles(state);
  return some(roles, (role) => role === superAdminRole);
};
export const isIconAdmin = (state) => {
  const roles = getAdminRoles(state);
  return some(roles, (role) => role === regularAdminRole);
};
export const isIconEmployerAdmin = (state) => {
  const roles = getAdminRoles(state);
  return some(roles, (role) => role === employerAdminRole);
};
export const isIconSalesAgent = (state) => {
  const roles = getAdminRoles(state);
  return some(roles, (role) => role === salesAgentRole);
};
