import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

function SessionlessAnimation() {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("/public/assets/sessionlessLoading.json"),
    });
  }, []);

  return (
    <div
      className="container"
      style={{ width: 300, height: 300 }}
      ref={container}
    ></div>
  );
}
export default SessionlessAnimation;
