import gql from "graphql-tag";

export function getDocuments(client, accountId) {
  return new Promise((resolve, reject) => {
    const DOCUMENTS = gql`
      query getAllDocuments($accountId: String!) {
        getAllDocuments(accountId: $accountId) {
          name
          id
          correlationId
          uri
          contentType
          createdAt
          updatedAt
          type
        }
      }
    `;
    const result = client.query({
      query: DOCUMENTS,
      variables: { accountId },
    });
    result.then(
      (data) => {
        resolve(data.data.getAllDocuments);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getUrlForDocument(client, accountId, documentId) {
  return new Promise((resolve, reject) => {
    const DOCUMENT_URL = gql`
      query getDocUrl($accountId: String!, $documentId: String!) {
        getDocUrl(accountId: $accountId, documentId: $documentId) {
          validUntil
          url
        }
      }
    `;
    const result = client.query({
      query: DOCUMENT_URL,
      variables: { accountId, documentId },
    });
    result.then(
      (data) => {
        resolve(data.data.getDocUrl);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getDownloadUrl(client, userId, documentId) {
  return new Promise((resolve, reject) => {
    const DOWNLOAD_URL = gql`
      query getDocumentDownloadUrl($userId: ID!, $documentId: ID!) {
        getDocumentDownloadUrl(userId: $userId, documentId: $documentId) {
          validUntil
          url
        }
      }
    `;
    const result = client.query({
      query: DOWNLOAD_URL,
      variables: { userId, documentId },
      errorPolicy: "none",
    });
    result.then(
      (data) => {
        resolve(data.data.getDocumentDownloadUrl);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getEmployerDownloadUrl(client, { documentId, companyId }) {
  return new Promise((resolve, reject) => {
    const URL = gql`
      query getEmployerDocumentDownloadUrl($documentId: ID!, $companyId: ID) {
        getEmployerDocumentDownloadUrl(
          documentId: $documentId
          companyId: $companyId
        ) {
          validUntil
          url
        }
      }
    `;
    const result = client.query({
      query: URL,
      variables: { documentId, companyId },
      errorPolicy: "none",
    });
    result.then(
      (data) => {
        resolve(data.data.getEmployerDocumentDownloadUrl);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getEmployerTakeRateDownloadUrl(
  client,
  { groupId, status, timeframe, companyId }
) {
  return new Promise((resolve, reject) => {
    const URL = gql`
      query getEmployerTakeRateDocumentDownloadUrl(
        $groupId: ID
        $status: String!
        $timeframe: String!
        $companyId: ID
      ) {
        getEmployerTakeRateDocumentDownloadUrl(
          groupId: $groupId
          status: $status
          timeframe: $timeframe
          companyId: $companyId
        ) {
          validUntil
          url
        }
      }
    `;
    const result = client.query({
      query: URL,
      variables: { groupId, status, timeframe, companyId },
      errorPolicy: "none",
    });
    result.then(
      (data) => {
        resolve(data.data.getEmployerTakeRateDocumentDownloadUrl);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export default {
  getDocuments,
  getUrlForDocument,
  getDownloadUrl,
  getEmployerTakeRateDownloadUrl,
};
