import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";
import { get, includes } from "lodash";
import { formatCurrency, formatPercentage } from "utils/number";
import "./AnnualContributionsWidget.scss";
import Button from "components/Button";
import ContributionModal from "pages/dashboards/individualDashboard/dashboard/ContributionModal";
import { getContributionAbilityAndEstimates } from "actions/contributionActions";
import { contributionAbilitySelector } from "store/selectors/contribution";
import { bankSelector } from "store/selectors/bank";
import { bankAccountType } from "statics/propTypes";
import { Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { getIraAccountIdSelector } from "store/selectors/user";
import {
  FiActivity,
  FiAward,
  FiBell,
  FiCalendar,
  FiTarget,
} from "react-icons/fi";

function bankIsVerified(bankState) {
  const activeBankStates = ["BankAccountPendingAchLink", "BankAccountActive"];
  return includes(activeBankStates, bankState);
}

class AnnualContributionsWidget extends PureComponent {
  static propTypes = {
    client: PropTypes.object,
    priorYearContributionAbility: PropTypes.object,
    currentContributionAbility: PropTypes.object,

    contributionAbility: PropTypes.object,
    getContributionAbilityAndEstimates: PropTypes.func,
    estimatesType: PropTypes.object,
    bank: bankAccountType,
  };

  constructor(props) {
    super(props);

    this.state = {
      initialFetching: true,
      showContributionModal: false,
    };
  }

  componentDidMount() {
    window.analytics.page("Annual Contributions Widget");
    this.props
      .getContributionAbilityAndEstimates(this.props.client)
      .then(() => {
        this.setState({
          initialFetching: false,
        });
      });
  }

  _onContinueClick = () => {
    this.setState({
      showContributionModal: true,
    });
  };

  _shouldShowPriorYearContributionCard = () => {
    const isWithinPriorYearContributionWindow = get(
      this.props,
      "contributionAbility.isWithinPriorYearContributionWindow",
      false
    );
    return isWithinPriorYearContributionWindow;
  };

  _maxedOutPriorYearIra = () => {
    const priorYearTotalContributionAmount = get(
      this.props,
      "contributionAbility.priorYear.totalContributionAmount"
    );
    const priorYearLimit = get(
      this.props,
      "contributionAbility.priorYear.limit"
    );
    return priorYearTotalContributionAmount >= priorYearLimit;
  };

  render() {
    const totalContributionAmount = get(
      this.props,
      "contributionAbility.currentYear.totalContributionAmount"
    );

    const currentYearLimit = get(
      this.props,
      "contributionAbility.currentYear.limit"
    );

    const amountRemaining = get(
      this.props,
      "contributionAbility.currentYear.amountRemaining"
    );
    const currentTaxYear = get(
      this.props,
      "contributionAbility.currentYear.taxYear"
    );

    const priorYearTotalContributionAmount = get(
      this.props,
      "contributionAbility.priorYear.totalContributionAmount"
    );

    const priorYearLimit = get(
      this.props,
      "contributionAbility.priorYear.limit"
    );

    const priorYearAmountRemaining = get(
      this.props,
      "contributionAbility.priorYear.amountRemaining"
    );

    const priorTaxYear = get(
      this.props,
      "contributionAbility.priorYear.taxYear"
    );

    const monthlyTotal = get(
      this.props,
      "contributionEstimates.currentMonthAmount"
    );

    const yearlyEstimatedTotal = get(
      this.props,
      "contributionEstimates.currentYearAmount"
    );

    const showOneTimeContributionButton =
      this.props.bank && bankIsVerified(this.props.bank.state);

    return (
      <div className="annual-contribution-widget">
        <h4 className="annual-contribution-title">
          Annual Contribution Progress
        </h4>
        <Tabs defaultActiveKey={currentTaxYear} id="uncontrolled-tab-example">
          <Tab eventKey={currentTaxYear} title={currentTaxYear}>
            <Card>
              <Card.Body>
                <Container className="portfolio-lg-screen">
                  <Row>
                    <Col>
                      <Card.Title className="left">
                        Summary of your progress
                      </Card.Title>
                      <p className="title-subtext"></p>
                    </Col>
                    <Col>
                      <div className="contribution">
                        <FiCalendar
                          size={"18px"}
                          color={"#FFFFFF"}
                          fill={"#FFFFFF"}
                          stroke={"#6772E5"}
                          strokeWidth={"2"}
                        />
                        <div className="contribution-title">
                          <p className="contribution-type">Monthly recurring</p>
                          <p className="contribution-type-subtext">
                            Your contribution amount for this month is{" "}
                            {formatCurrency(monthlyTotal)}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="total-contributed-title">
                        <div className="total-contributed">
                          <p className="total">Total contributed</p>
                          <p className="amount">
                            {formatCurrency(totalContributionAmount)}
                          </p>
                        </div>
                        <div className="contribution-bar">
                          <div
                            className="contribution-fill-current-year"
                            style={{
                              width: formatPercentage(
                                totalContributionAmount / currentYearLimit
                              ),
                            }}
                          ></div>
                        </div>
                        <p className="annual-maximum">Max</p>
                      </div>
                    </Col>
                    <Col>
                      <div className="current-year-contribution">
                        <FiActivity
                          size={"18px"}
                          color={"#FFFFFF"}
                          fill={"#FFFFFF"}
                          stroke={"#6772E5"}
                          strokeWidth={"2"}
                        />
                        <div className="contribution-title">
                          <p className="contribution-type">
                            Total amount contributed
                          </p>
                          <p className="contribution-type-subtext">
                            You have reached{" "}
                            {formatPercentage(
                              totalContributionAmount / currentYearLimit,
                              0
                            )}{" "}
                            of your annual maximum. This means you have{" "}
                            {formatCurrency(amountRemaining)} left to contribute
                            to your IRA.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="total-contributed-title">
                        <div className="total-contributed">
                          <p className="total">Estimated annual contribution</p>
                          <p className="amount">
                            {formatCurrency(yearlyEstimatedTotal)}
                          </p>
                        </div>
                        <div className="contribution-bar">
                          <div
                            className="contribution-fill"
                            style={{
                              width: formatPercentage(
                                yearlyEstimatedTotal / currentYearLimit
                              ),
                            }}
                          ></div>
                        </div>
                        <p className="annual-maximum">Max</p>
                      </div>
                    </Col>
                    <Col>
                      <div className="current-year-contribution">
                        <FiTarget
                          size={"18px"}
                          color={"#FFFFFF"}
                          fill={"#FFFFFF"}
                          stroke={"#6772E5"}
                          strokeWidth={"2"}
                        />
                        <div className="contribution-title">
                          <p className="contribution-type">
                            Estimated annual contribution
                          </p>
                          <p className="contribution-type-subtext">
                            Based on your monthly contributions you will reach{" "}
                            {formatPercentage(
                              yearlyEstimatedTotal / currentYearLimit,
                              0
                            )}{" "}
                            of your annual IRA maximum.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <Container className="portfolio-sm-screen ">
                  <Row>
                    <Col>
                      <Card.Title className="left">
                        Summary of your progress
                      </Card.Title>
                      <p className="title-subtext"></p>
                      <div className="contribution">
                        <FiCalendar
                          size={"18px"}
                          color={"#FFFFFF"}
                          fill={"#FFFFFF"}
                          stroke={"#6772E5"}
                          strokeWidth={"2"}
                        />
                        <div className="contribution-title">
                          <p className="contribution-type">Monthly recurring</p>
                          <p className="contribution-type-subtext">
                            Your contribution amount for this month is{" "}
                            {formatCurrency(monthlyTotal)}.
                          </p>
                        </div>
                      </div>
                      <div className="current-year-contribution">
                        <FiActivity
                          size={"18px"}
                          color={"#FFFFFF"}
                          fill={"#FFFFFF"}
                          stroke={"#6772E5"}
                          strokeWidth={"2"}
                        />
                        <div className="contribution-title">
                          <p className="contribution-type">
                            Total amount contributed
                          </p>
                          <p className="contribution-type-subtext">
                            You have reached{" "}
                            {formatPercentage(
                              totalContributionAmount / currentYearLimit,
                              0
                            )}{" "}
                            of your annual maximum.
                          </p>
                        </div>
                      </div>
                      <div className="total-contributed-title">
                        <div className="total-contributed">
                          <p className="total">Total contributed</p>
                          <p className="amount">
                            {formatCurrency(totalContributionAmount)}
                          </p>
                        </div>
                        <div className="contribution-bar">
                          <div
                            className="contribution-fill-current-year"
                            style={{
                              width: formatPercentage(
                                totalContributionAmount / currentYearLimit
                              ),
                            }}
                          ></div>
                        </div>
                        <p className="annual-maximum">Max</p>
                      </div>
                      <div className="current-year-contribution">
                        <FiTarget
                          size={"18px"}
                          color={"#FFFFFF"}
                          fill={"#FFFFFF"}
                          stroke={"#6772E5"}
                          strokeWidth={"2"}
                        />
                        <div className="contribution-title">
                          <p className="contribution-type">
                            Estimated annual contribution
                          </p>
                          <p className="contribution-type-subtext">
                            Based on your monthly contributions you will reach
                            {formatPercentage(
                              yearlyEstimatedTotal / currentYearLimit,
                              0
                            )}{" "}
                            of your annual IRA maximum.
                          </p>
                        </div>
                      </div>
                      <div className="total-contributed-title">
                        <div className="total-contributed">
                          <p className="total">Estimated annual contribution</p>
                          <p className="amount">
                            {" "}
                            {formatCurrency(yearlyEstimatedTotal)}
                          </p>
                        </div>
                        <div className="contribution-bar">
                          <div
                            className="contribution-fill"
                            style={{
                              width: formatPercentage(
                                yearlyEstimatedTotal / currentYearLimit
                              ),
                            }}
                          ></div>
                        </div>
                        <p className="annual-maximum">Max</p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Tab>
          {this._shouldShowPriorYearContributionCard() && (
            <Tab
              eventKey="profile"
              title={
                <span className="tax-year-tab">
                  <span className="prior-year-tab"> {priorTaxYear}</span>
                  {!this._maxedOutPriorYearIra() && (
                    <span className="bell">
                      <FiBell
                        className="notification"
                        size={"14px"}
                        color={"#FFFFFF"}
                        fill={"#FFFFFF"}
                        stroke={"#6772E5"}
                        strokeWidth={"2"}
                      />{" "}
                    </span>
                  )}
                </span>
              }
              priorYearContributionAbility={get(
                this.props,
                "contributionAbility.priorYear"
              )}
            >
              <Card>
                <Card.Body>
                  {!this._maxedOutPriorYearIra() && (
                    <Container>
                      <Row>
                        <Col md={6}>
                          <Card.Title className="left">
                            Summary of {priorTaxYear} progress{" "}
                          </Card.Title>
                          <p className="title-subtext">
                            You haven{"'"}t reached your annual maximum just
                            yet. You are still able to contribute to your{" "}
                            {priorTaxYear} IRA! You have{" "}
                            {formatCurrency(priorYearAmountRemaining)} left to
                            max out your annual maximum.
                          </p>
                          <Card.Text>
                            <span className="portfolio-lg-screen">
                              {!showOneTimeContributionButton && (
                                <p className="title-subtext">
                                  Link your bank account to start making
                                  contributions.
                                </p>
                              )}
                              {showOneTimeContributionButton && (
                                <div>
                                  <p className="contribution-action-text">
                                    Would you like to contribute now?
                                  </p>{" "}
                                  <Button
                                    size="sm"
                                    name="action"
                                    className="contribute-btn-2020"
                                    btnLabel="Contribute"
                                    onClick={this._onContinueClick}
                                    withArrow={true}
                                  />
                                </div>
                              )}
                            </span>
                            <ContributionModal
                              show={this.state.showContributionModal}
                              options={{ isApplyToPriorYear: true }}
                              onSuccess={() => {
                                this.setState({
                                  showContributionModal: false,
                                });
                              }}
                              onClose={() => {
                                this.setState({
                                  showContributionModal: false,
                                });
                              }}
                            />
                          </Card.Text>
                        </Col>
                        <Col md={6}>
                          <div className="contribution">
                            <FiActivity
                              size={"18px"}
                              color={"#FFFFFF"}
                              fill={"#FFFFFF"}
                              stroke={"#6772E5"}
                              strokeWidth={"2"}
                            />
                            <div className="contribution-title">
                              <p className="contribution-type">
                                Total amount contributed
                              </p>
                              <p className="contribution-type-subtext">
                                You have reached{" "}
                                {formatPercentage(
                                  priorYearTotalContributionAmount /
                                    priorYearLimit,
                                  0
                                )}{" "}
                                of your annual maximum.
                              </p>
                            </div>
                          </div>
                          <div className="prior-year-total-contributed-title ">
                            <div className="total-contributed">
                              <p className="total">Total contributed</p>
                              <p className="amount">
                                {formatCurrency(
                                  priorYearTotalContributionAmount
                                )}
                              </p>
                            </div>
                            <div className="contribution-bar">
                              <div
                                className="contribution-fill-current-year"
                                style={{
                                  width: formatPercentage(
                                    priorYearTotalContributionAmount /
                                      priorYearLimit
                                  ),
                                }}
                              ></div>
                            </div>
                            <p className="annual-maximum">Max</p>
                          </div>
                          <span className="portfolio-sm-screen-btn">
                            {!showOneTimeContributionButton && (
                              <p className="title-subtext">
                                Link your bank account to start making
                                contributions.
                              </p>
                            )}
                            {showOneTimeContributionButton && (
                              <Button
                                className="contribute-btn-2020"
                                btnLabel="Contribute"
                                name="action"
                                onClick={this._onContinueClick}
                                withArrow={true}
                              />
                            )}
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  )}
                  {this._maxedOutPriorYearIra() && (
                    <Container>
                      <Row>
                        <Col md={6}>
                          <Card.Title className="left">
                            You’ve reached your IRA contribution Limit!{" "}
                            <FiAward
                              size={"18px"}
                              fill={"#FFFFFF"}
                              color={"#FFFFFF"}
                              stroke={"#6772E5"}
                              strokeWidth={"2"}
                            />
                          </Card.Title>
                          <p className="title-subtext">
                            Congratulations! Thanks to your automatic deposit
                            plan you’ve reached your{" "}
                            {formatCurrency(priorYearLimit, 0)} IRA contribution
                            limit for the year. In January we will restart your
                            IRA automatic deposits so you can max out next
                            year’s contributions.
                          </p>
                          <Card.Text></Card.Text>
                        </Col>
                        <Col md={6}>
                          <div className="contribution">
                            <FiActivity
                              size={"18px"}
                              color={"#FFFFFF"}
                              fill={"#FFFFFF"}
                              stroke={"#6772E5"}
                              strokeWidth={"2"}
                            />
                            <div className="contribution-title">
                              <p className="contribution-type">
                                Total amount contributed
                              </p>
                              <p className="contribution-type-subtext">
                                You have reached{" "}
                                {formatPercentage(
                                  priorYearTotalContributionAmount /
                                    priorYearLimit,
                                  0
                                )}{" "}
                                of your annual maximum.
                              </p>
                            </div>
                          </div>
                          <div className="maxed-total-contributed-title">
                            <div className="total-contributed">
                              <p className="total">Total contributed</p>
                              <p className="amount">
                                {formatCurrency(
                                  priorYearTotalContributionAmount
                                )}
                              </p>
                            </div>
                            <div className="contribution-bar">
                              <div
                                className="contribution-fill"
                                style={{
                                  width: formatPercentage(
                                    priorYearTotalContributionAmount /
                                      priorYearLimit
                                  ),
                                }}
                              ></div>
                            </div>
                            <p className="annual-maximum">Max</p>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  )}
                </Card.Body>
              </Card>
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const routeName = get(state.router, "location.pathname");
  const accountId = getIraAccountIdSelector(state);

  return {
    accountId,
    routeName,
    contributionAbility: contributionAbilitySelector(state),
    contributionEstimates: state.contribution.contributionEstimates,
    bank: bankSelector(state),
  };
};

const mapDispatchToProps = {
  getContributionAbilityAndEstimates,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AnnualContributionsWidget));
