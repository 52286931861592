import React from "react";
import PropTypes from "prop-types";
import { each, every, isEmpty, pick, values } from "lodash";
import { Col, Modal, Row } from "react-bootstrap";

import Button from "./Button";
import SelectAddressCard from "./SelectAddressCard";
import classnames from "classnames";

import { isPoBox } from "utils/fieldValidators";
import "./AddressValidator.css";

const customerInput = "customerAddress";
const uspsPreferred = "uspsPreferred";

export default class AddressValidator extends React.PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func,
    validatedAddress: PropTypes.object,
    values: PropTypes.object,
    isSelected: PropTypes.bool,
    allowPoBox: PropTypes.bool,
  };

  static defaultProps = {
    validatedAddress: {},
    allowPoBox: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedAddress: this.isUspsInvalid() ? customerInput : uspsPreferred,
    };
  }

  isUspsInvalid = () => {
    const validatedAddress = pick(this.props.validatedAddress, [
      "address1",
      "address2",
      "city",
      "state",
      "postalCode",
    ]);
    return every(values(validatedAddress), isEmpty);
  };

  _setSelected = (selectedAddress) => {
    this.setState({
      selectedAddress,
    });
  };

  _onSubmit = () => {
    // if they are using the USPS preferred we will need to update formik values and resubmit
    if (this.state.selectedAddress === uspsPreferred) {
      each(this.props.validatedAddress, (val, key) => {
        this.props.setFieldValue && this.props.setFieldValue(key, val);
      });

      const newValues = {
        ...this.props.values,
        ...this.props.validatedAddress,
      };
      this.props.onSubmit(newValues, true);
      this.props.onClose();

      return;
    }

    // otherwise just resubmit with values passed in which includes user inputted address
    this.props.onSubmit(this.props.values, true);
    this.props.onClose();
    window.analytics.track("Validating Address");
  };

  _isPoBox = () => {
    return (
      isPoBox(this.props.validatedAddress.address1) ||
      isPoBox(this.props.validatedAddress.address2)
    );
  };

  _getBody = () => {
    if (!this.props.allowPoBox && this._isPoBox()) {
      return (
        <h5>
          Address cannot be a P.O. Box, please use a residential or business
          address.
        </h5>
      );
    }

    const customerAddress = pick(this.props.values, [
      "address1",
      "address2",
      "city",
      "state",
      "postalCode",
    ]);
    const userName =
      this.props.values.businessName ||
      `${this.props.values.firstName} ${this.props.values.lastName}`;

    const uspsAddressIsInvalid = this.isUspsInvalid();

    return (
      <Row>
        <Col md={6} sm={12} style={{ marginBottom: 8 }}>
          <p
            className={classnames("address-info ", {
              selected: this.props.isSelected,
              invalid: uspsAddressIsInvalid,
            })}
          >
            We Suggest:
          </p>
          <SelectAddressCard
            isInvalid={uspsAddressIsInvalid}
            userName={userName}
            onSelect={() => {
              this._setSelected(uspsPreferred);
              window.analytics.track(
                "Selected Verified USPS Preferred Address"
              );
            }}
            isSelected={this.state.selectedAddress === uspsPreferred}
            address={this.props.validatedAddress}
          />
        </Col>
        <Col md={6} sm={12}>
          <p
            className={classnames("address-info", {
              selected: this.props.isSelected,
            })}
          >
            You Entered:
          </p>
          <SelectAddressCard
            userName={userName}
            onSelect={() => {
              this._setSelected(customerInput);
              window.analytics.track(
                "Selected Verified Customer Input Address"
              );
            }}
            isSelected={this.state.selectedAddress === customerInput}
            address={customerAddress}
          />
        </Col>
      </Row>
    );
  };

  render() {
    const showSubmit = this.props.allowPoBox || !this._isPoBox();
    return (
      <Modal
        show={this.props.show}
        size="lg"
        centered
        onHide={this.props.onClose}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title id="contained-modal-title-vcenter">
            Address Verification
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>{this._getBody()}</Modal.Body>
        <Modal.Footer>
          <Button
            btnLabel="Edit Address"
            color="cancel"
            name="cancel"
            onClick={() => {
              const shouldShowEditMode = true;
              this.props.onClose(shouldShowEditMode);
              window.analytics.track("Clicked Edit Address Button");
            }}
          />
          {showSubmit && (
            <Button name="submit" withArrow onClick={this._onSubmit} />
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}
