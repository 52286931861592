import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { PropTypes } from "prop-types";
import Button from "components/Button";
import { Modal } from "react-bootstrap";
function PdfViewer({ documentUrl, height = 400, width = 400, onClose }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Modal show={true} onClose={onClose} onHide={onClose}>
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="pdf-viewer-container">
          <Document file={documentUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              height={height}
              width={width}
              renderAnnotationLayer={false}
            />
          </Document>
          <p>
            Page {pageNumber} of {numPages}
          </p>
          <div className="btn-row">
            <Button
              onClick={() => setPageNumber(pageNumber - 1)}
              size="sm"
              name="action"
              btnLabel="Prev Page"
              disabled={pageNumber === 1}
            />
            <Button
              onClick={() => setPageNumber(pageNumber + 1)}
              size="sm"
              name="action"
              btnLabel="Next Page"
              disabled={pageNumber === numPages}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

PdfViewer.propTypes = {
  documentUrl: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  onClose: PropTypes.func,
};

export default PdfViewer;
