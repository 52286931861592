import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { get, isEmpty, isEqual } from "lodash";
import IconSpinner from "components/IconSpinner";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { getEmployeeDocumentUploadJobs } from "actions/employerActions";
import AddEmployeeUploadFormToggle from "./AddEmployeeUploadFormToggle";
import MissingEmployerGroup from "./MissingEmployerGroup";
import EmployeeUploadJobTable from "./EmployeeUploadJobTable";

import "./Employees.scss";

class UploadEmployees extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    groups: PropTypes.arrayOf(PropTypes.shape({})),
    employeeUploadJobs: PropTypes.arrayOf(PropTypes.shape({})),
    getAllEmployerGroups: PropTypes.func,
    getEmployeeDocumentUploadJobs: PropTypes.func,
  };

  componentDidMount() {
    window.analytics.page("Employer Employee Upload Page");
    this.props.getEmployeeDocumentUploadJobs(this.props.client);
  }

  shouldComponentUpdate(nextProps) {
    // Required so that when we upload an employee doc for the first time the `employeeUploadJobs` prop changes which
    // causes the entire component to render. We want to prevent that so it shows the correct "uploading" component
    // eslint-disable-next-line no-unused-vars
    const { employeeUploadJobs: ignore, ...nextUpcomingProps } = nextProps;
    // eslint-disable-next-line no-unused-vars
    const { employeeUploadJobs: ignore2, ...currentProps } = this.props;
    return !isEqual(nextUpcomingProps, currentProps);
  }

  render() {
    if (this.props.isFetching) {
      return <IconSpinner centered />;
    } else if (isEmpty(this.props.groups)) {
      return <MissingEmployerGroup />;
    } else if (this.props.employeeUploadJobs) {
      return (
        <>
          <AddEmployeeUploadFormToggle
            onUploadSuccess={() => {
              this.props.push("/dashboard/users/employees");
            }}
          />
        </>
      );
    }

    return (
      <div id="account employees-page">
        <EmployeeUploadJobTable />
      </div>
    );
  }
}

const actions = [employerConstants.GET_EMPLOYER_GROUPS];
const isFetchingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    isFetching: isFetchingSelector(state),
    groups: state.employer.groups,
    employeeUploadJobs: isEmpty(get(state, "employer.employeeUploadJobs", [])),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  getEmployeeDocumentUploadJobs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(UploadEmployees));
