import React from "react";
import PropTypes from "prop-types";
import { each, find } from "lodash";
import { push } from "connected-react-router";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getInvestmentProfileAndSuitability,
  startInvestmentProfileUpdate,
} from "actions/userActions";
import { getAllAccounts } from "actions/accountActions";
import { withApollo } from "@apollo/client/react/hoc";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { userConstants } from "actions/types";
import { scrollToTop } from "utils/dom";
import {
  experienceValues,
  timeHorizonLiquidityValues,
  riskToleranceValues,
  incomeLabel,
  wealthLabel,
  hideLiquidInvestment,
} from "utils/investProfileHelper";
import { getIraAccountIdSelector } from "store/selectors/user";

import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import Button from "components/Button";
import Alert from "components/Alert";
import IconSpinner from "components/IconSpinner";

import "./SuitabilityProfile.scss";
class SuitabilityProfile extends React.PureComponent {
  static propTypes = {
    client: PropTypes.shape({}),
    push: PropTypes.func,
    getInvestmentProfileAndSuitability: PropTypes.func,
    getAllAccounts: PropTypes.func,
    startInvestmentProfileUpdate: PropTypes.func,
    error: PropTypes.string,
    accountId: PropTypes.string,
    isUpdating: PropTypes.bool,
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      investmentProfile: {
        objective: "",
        experience: "",
        annualIncome: "",
        liquidNetWorth: "None",
        totalNetWorth: "",
        riskTolerance: "",
      },
      suitability: {
        timeHorizon: "",
        liquidityNeeds: "",
        timeHorizonLiquidity: "",
      },
      submitting: false,
      loading: true,
    };
  }

  _promises = [];

  componentDidMount() {
    const usPromise = this.props
      .getInvestmentProfileAndSuitability(this.props.client)
      .then((data) => {
        const investmentProfileInfo = data && data.getInvestmentProfile;
        const suitability = data && data.getSuitability;
        let investmentProfile = {
          ...investmentProfileInfo,
          liquidNetWorth: wealthLabel(investmentProfileInfo.liquidNetWorth),
          totalNetWorth: wealthLabel(investmentProfileInfo.totalNetWorth),
          riskTolerance: investmentProfileInfo.riskToleranceRawValue,
        };
        this.setState({
          investmentProfile,
          suitability,
          loading: false,
        });
      });
    this._promises.push(usPromise);
    scrollToTop();
  }

  componentWillUnmount() {
    each(this._promises, (promise) => promise.cancel());
  }

  updateInvestmentProfile = async () => {
    this.setState({ submitting: true });
    await this.props
      .startInvestmentProfileUpdate(this.props.client)
      .then(() =>
        this.setState(
          { submitting: false },
          this.props.push("/individual/investment")
        )
      )
      .catch(() => this.setState({ submitting: false }));
  };

  render() {
    if (this.state.loading) {
      return <IconSpinner centered />;
    }

    return this.renderForm();
  }

  renderForm = () => {
    const { investmentProfile, suitability } = this.state;

    return (
      <div className="investment-profile-container">
        <Row>
          <Col>
            <div>
              <div className="header-section">
                <IconHeader
                  variant="cardHeader"
                  headerText="Investment Profile"
                />
                <IconSubheader
                  subheader="Let us know if anything changes by updating your profile and
                  we can make sure your portfolio is in tip-top shape together!"
                />
              </div>
            </div>

            <div className="information-section">
              <p className="title">What is your pre-tax income this year?</p>
              <p className="subtext">
                {incomeLabel(investmentProfile.annualIncome)}
              </p>
            </div>

            <div className="information-section">
              <p className="title">
                Please estimate the value of your cash, investments, and
                retirement accounts.
              </p>
              <p className="subtext">{investmentProfile.totalNetWorth}</p>
            </div>
            {!hideLiquidInvestment(investmentProfile.totalNetWorth) && (
              <div className="information-section">
                <p className="title">
                  How much of your cash holdings are being held in a checking or
                  savings account?
                </p>
                <p className="subtext">{investmentProfile.liquidNetWorth}</p>
              </div>
            )}
            <div className="information-section">
              <p className="title">
                When you think about how your money is invested, which one of
                the following statements describes your investment goal?
              </p>
              <p className="subtext">
                {
                  find(
                    riskToleranceValues,
                    (item) => item.value === investmentProfile.riskTolerance
                  ).description
                }
              </p>
            </div>
            <div className="information-section">
              <p className="title">
                What is your current level of investment experience?
              </p>
              <p className="subtext">
                {
                  find(
                    experienceValues,
                    (item) => item.value === investmentProfile.experience
                  ).description
                }
              </p>
            </div>
            <div className="information-section">
              <p className="title">
                When do you anticipate needing the money in your investment
                account?
              </p>
              <p className="subtext">
                {
                  find(
                    timeHorizonLiquidityValues,
                    (item) => item.value === suitability.timeHorizonLiquidity
                  ).description
                }
              </p>
            </div>
            <div className="submit-row">
              {this.props.error && (
                <Alert type="error" msg={this.props.error} />
              )}
              <Button
                size="sm"
                withArrow={true}
                name="submit"
                btnLabel="Update Profile"
                loading={this.props.isUpdating || this.state.submitting}
                onClick={this.updateInvestmentProfile}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  };
}
const actions = [userConstants.USER_INVESTMENT_PROFILE_UPDATE];

const updatingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  isUpdating: updatingSelector(state),
  accountId: getIraAccountIdSelector(state),
});

const mapDispatchToProps = {
  push,
  startInvestmentProfileUpdate,
  getInvestmentProfileAndSuitability,
  getAllAccounts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(SuitabilityProfile));
