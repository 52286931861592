import gql from "graphql-tag";
import { get } from "lodash";

// link bank with Icon
export function addContribution({
  client,
  iraAccountId,
  amount,
  bankId,
  applyToPriorYear = false,
}) {
  return new Promise((resolve, reject) => {
    const ADD_CONTRIBUTION = gql`
      mutation addContribution(
        $contributionType: String
        $amount: Float!
        $bankId: ID!
        $iraAccountId: ID!
        $applyToPriorYear: Boolean
      ) {
        addContribution(
          contributionType: $contributionType
          amount: $amount
          bankId: $bankId
          iraAccountId: $iraAccountId
          applyToPriorYear: $applyToPriorYear
        )
      }
    `;

    const result = client.mutate({
      mutation: ADD_CONTRIBUTION,
      variables: {
        contributionType: "REGULAR",
        amount,
        bankId,
        iraAccountId,
        applyToPriorYear,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function addPaycheckContribution({
  client,
  amount,
  groupId,
  isDailyCustom,
}) {
  return new Promise((resolve, reject) => {
    const ADD_CONTRIBUTION = gql`
      mutation addPayCheckContribution(
        $contributionType: String
        $amount: Float!
        $groupId: ID!
        $isDailyCustom: Boolean!
      ) {
        addPayCheckContribution(
          contributionType: $contributionType
          amount: $amount
          groupId: $groupId
          isDailyCustom: $isDailyCustom
        ) {
          id
          status
          company {
            id
            name
            planId
          }
          group {
            id
            payrollFrequency
            companyGroupIndex
          }
          schedule {
            start
            end
            payrollProcessingDate
            onOrAfterPayrollProcessingDate
            status
          }
          contribution {
            amount
            id
            contributionChangeRequests {
              userCurrentContributionAmount
              userNewContributionAmount
              status
              id
            }
          }
        }
      }
    `;

    const result = client.mutate({
      mutation: ADD_CONTRIBUTION,
      variables: {
        contributionType: "EMPLOYER",
        groupId,
        isDailyCustom,
        amount,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.addPayCheckContribution);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

export function editPaycheckContribution(
  client,
  { amount, contributionId, isDailyCustom }
) {
  return new Promise((resolve, reject) => {
    const ADD_CONTRIBUTION = gql`
      mutation editPayCheckContribution(
        $id: ID!
        $amount: Float!
        $isDailyCustom: Boolean!
      ) {
        editPayCheckContribution(
          id: $id
          amount: $amount
          isDailyCustom: $isDailyCustom
        ) {
          id
          company {
            id
            name
            planId
          }
          group {
            id
            payrollFrequency
            companyGroupIndex
          }
          groupWithSchedule {
            group {
              id
              name
              payrollFrequency
            }
          }
          schedule {
            start
            end
            payrollProcessingDate
            onOrAfterPayrollProcessingDate
            status
          }
          contribution {
            amount
            id
          }
        }
      }
    `;

    const result = client.mutate({
      mutation: ADD_CONTRIBUTION,
      variables: {
        id: contributionId,
        amount,
        isDailyCustom,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.editPayCheckContribution);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

export function submitOnboardingPayrollContribution(
  client,
  { isDailyCustom, amount, groupId }
) {
  return new Promise((resolve, reject) => {
    const SUBMIT_ONBOARDING_CONTRIBUTION = gql`
      mutation addOnboardingPayCheckContribution(
        $groupId: ID!
        $amount: Float!
        $isDailyCustom: Boolean!
      ) {
        addOnboardingPayCheckContribution(
          groupId: $groupId
          amount: $amount
          isDailyCustom: $isDailyCustom
        )
      }
    `;

    const result = client.mutate({
      mutation: SUBMIT_ONBOARDING_CONTRIBUTION,
      variables: {
        isDailyCustom,
        amount,
        groupId,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.addOnboardingPayCheckContribution);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

export function addOnboardingRecurringContribution(
  client,
  { accountId, amount, bankId, dayOne, frequency, anchorPayDate, isDailyCustom }
) {
  return new Promise((resolve, reject) => {
    const SUBMIT_ONBOARDING_CONTRIBUTION = gql`
      mutation addOnboardingRecurringContribution(
        $amount: Float!
        $accountId: ID!
        $bankId: ID!
        $dayOne: Int
        $frequency: String!
        $anchorPayDate: Date!
        $isDailyCustom: Boolean
      ) {
        addOnboardingRecurringContribution(
          amount: $amount
          accountId: $accountId
          bankId: $bankId
          dayOne: $dayOne
          frequency: $frequency
          anchorPayDate: $anchorPayDate
          isDailyCustom: $isDailyCustom
        )
      }
    `;

    const result = client.mutate({
      mutation: SUBMIT_ONBOARDING_CONTRIBUTION,
      variables: {
        amount,
        accountId,
        bankId,
        dayOne,
        frequency,
        anchorPayDate,
        isDailyCustom,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.addOnboardingRecurringContribution);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}
export function addRecurringContribution(
  client,
  {
    accountId,
    amount,
    bankId,
    dayOne,
    frequency,
    anchorPayDate,
    isDailyCustom,
    scheduleId,
  }
) {
  return new Promise((resolve, reject) => {
    const SUBMIT_ONBOARDING_CONTRIBUTION = gql`
      mutation addRecurringContribution(
        $amount: Float!
        $accountId: ID!
        $bankId: ID!
        $dayOne: Int
        $frequency: String!
        $anchorPayDate: Date!
        $isDailyCustom: Boolean
        $scheduleId: ID
      ) {
        addRecurringContribution(
          amount: $amount
          accountId: $accountId
          bankId: $bankId
          dayOne: $dayOne
          frequency: $frequency
          anchorPayDate: $anchorPayDate
          isDailyCustom: $isDailyCustom
          scheduleId: $scheduleId
        ) {
          frequency
          anchorPayDate
          amount
          dayOne
          dayTwo
          sourceBankAccountId
          targetIraAccountId
          nextScheduledDate
          scheduleId
        }
      }
    `;

    const result = client.mutate({
      mutation: SUBMIT_ONBOARDING_CONTRIBUTION,
      variables: {
        amount,
        accountId,
        bankId,
        dayOne,
        frequency,
        anchorPayDate,
        isDailyCustom,
        scheduleId,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.addRecurringContribution);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}
export function getRecommendedContributionAmounts(
  client,
  { groupId, isSelfEmployed }
) {
  return new Promise((resolve, reject) => {
    const GET_RECOMMENDATIONS = gql`
      query getRecommendedContributionAmounts(
        $groupId: ID
        $isSelfEmployed: Boolean
      ) {
        getRecommendedContributionAmounts(
          groupId: $groupId
          isSelfEmployed: $isSelfEmployed
        ) {
          daily {
            recommended
            other
            isMaxContributionOption
          }
          weekly {
            recommended
            other
            isMaxContributionOption
          }
          everyOtherWeek {
            recommended
            other
            isMaxContributionOption
          }
          monthly {
            recommended
            other
            isMaxContributionOption
          }
          perPayPeriod {
            recommended
            other
            isMaxContributionOption
          }
          dailyMax
          weeklyMax
          everyOtherWeekMax
          monthlyMax
          perPayPeriodMax
          scheduleFrequency
        }
      }
    `;
    const result = client.query({
      query: GET_RECOMMENDATIONS,
      variables: { groupId, isSelfEmployed },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.getRecommendedContributionAmounts);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getContributionAbilityRequest(client) {
  return new Promise((resolve, reject) => {
    const query = gql`
      query getContributionAbility {
        getContributionAbility {
          currentYear {
            taxYear
            totalContributionAmount
            amountRemaining
            limit
            canContribute
          }
          priorYear {
            taxYear
            totalContributionAmount
            amountRemaining
            limit
            canContribute
          }
          isWithinPriorYearContributionWindow
          allYears {
            taxYear
            totalContributionAmount
            amountRemaining
            limit
            canContribute
          }
        }
      }
    `;
    const result = client.query({ query });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.getContributionAbility);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}
export function getContributionAbilityAndEstimatesRequest(client) {
  return new Promise((resolve, reject) => {
    const query = gql`
      query getContributionAbilityAndEstimates($rid: String!) {
        getContributionAbility {
          currentYear {
            taxYear
            totalContributionAmount
            amountRemaining
            limit
            canContribute
          }
          priorYear {
            taxYear
            totalContributionAmount
            amountRemaining
            limit
            canContribute
          }
          isWithinPriorYearContributionWindow
          allYears {
            taxYear
            totalContributionAmount
            amountRemaining
            limit
            canContribute
          }
        }
        getState(rid: $rid) {
          user {
            contributionInfo {
              estimates {
                currentMonthAmount
                currentYearAmount
                currentMonthWithPendingAmount
                currentYearWithPendingAmount
              }
            }
          }
        }
      }
    `;
    const result = client.query({
      query,
      variables: { rid: "id-" + Math.random() },
    });
    result.then(
      (response) => {
        if (response.data) {
          const estimates = get(
            response.data,
            "getState.user.contributionInfo.estimates"
          );
          const contributionAbility = get(
            response.data,
            "getContributionAbility"
          );
          resolve({ estimates, contributionAbility });
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getDailyToRecurringAmount(client, { frequency, amount }) {
  return new Promise((resolve, reject) => {
    const query = gql`
      query getDailyToRecurringAmount($frequency: String!, $amount: Float!) {
        getDailyToRecurringAmount(frequency: $frequency, amount: $amount)
      }
    `;
    const result = client.query({
      query,
      variables: {
        amount,
        frequency,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.getDailyToRecurringAmount);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}
export function getRecurringContributions(client) {
  return new Promise((resolve, reject) => {
    const query = gql`
      query getRecurringContributions {
        getRecurringContributions {
          id
          employeeId
          contributionType
          amount
          bankId
          schedule {
            frequency
            anchorPayDate
            amount
            dayOne
            dayTwo
            sourceBankAccountId
            targetIraAccountId
            nextScheduledDate
          }
        }
      }
    `;
    const result = client.query({
      query,
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.getRecurringContributions);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export default {
  addContribution,
  addPaycheckContribution,
  editPaycheckContribution,
  getRecommendedContributionAmounts,
  getContributionAbilityRequest,
  getContributionAbilityAndEstimatesRequest,
  getDailyToRecurringAmount,
  addRecurringContribution,
};
