import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { each, get, isEmpty } from "lodash";

import { getApproval, setApprovalStatus } from "actions/adminActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { approvalType } from "statics/propTypes";
import ApprovalItem from "pages/admin/ApprovalItem";
import ApprovalDocumentItem from "pages/admin/ApprovalDocumentItem";
import IconSpinner from "components/IconSpinner";

import "./Approval.scss";

class Approval extends React.PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
    approvalId: PropTypes.string,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    approval: approvalType,
    client: PropTypes.object,
    getApproval: PropTypes.func,
    setApprovalStatus: PropTypes.func,
  };
  _promises = [];

  constructor() {
    super();

    this.state = {
      approvalStatus: "",
      comments: "",
      reason: "NONE",
    };
  }

  componentDidMount() {
    this._promises.push(
      this.props.getApproval(this.props.client, this.props.approvalId)
    );
  }

  componentWillUnmount() {
    each(this._promises, (promise) => promise.cancel);
  }

  _onSubmit = (values) => {
    const approvalParams = {
      approvalId: this.props.approvalId,
      status: values.approvalStatus,
      reason: values.reason,
      comment: values.comments,
      metadata: values.metadata,
    };
    this._promises.push(
      this.props.setApprovalStatus(this.props.client, approvalParams)
    );
  };

  render() {
    if (this.props.isFetching) {
      return <IconSpinner centered />;
    }

    if (!isEmpty(this.props.approval.documents)) {
      return <ApprovalDocumentItem {...this.props} onSubmit={this._onSubmit} />;
    }

    return <ApprovalItem {...this.props} onSubmit={this._onSubmit} />;
  }
}

const isLoadingSelector = createLoadingSelector(adminConstants.GET_APPROVAL);
const isSubmittingSelector = createLoadingSelector(
  adminConstants.SET_APPROVAL_STATUS
);

const errorSelector = createErrorSelector([
  adminConstants.GET_APPROVAL,
  adminConstants.SET_APPROVAL_STATUS,
]);

const mapStateToProps = (state, ownProps) => {
  const approvalId = get(ownProps.match, "params.id");
  const approval = state.admin.approvalsById[approvalId];
  return {
    approval: approval || {},
    isFetching: isLoadingSelector(state) || !approval,
    isSubmitting: isSubmittingSelector(state),
    approvalId,
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  getApproval,
  setApprovalStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Approval));
