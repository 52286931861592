import { accountConstants } from "./types";
import { accountService } from "services/accountService";
import ActionCreator from "utils/actionHandler";

export const getAllAccounts = (client, userId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(accountConstants.GET_ACCOUNTS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      accountService.getUserAccounts(client, userId)
    );
  };
};

export const getIraAccountBalance = (client, iraAccountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(accountConstants.GET_IRA_BALANCE);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      accountService.getIraAccountBalanceRequest(client, iraAccountId)
    );
  };
};

export const setAccountSession = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      accountConstants.SET_ACCOUNT_SESSION
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      accountService.setAccountSession(client, accountId)
    );
  };
};
