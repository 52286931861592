import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { Card } from "react-bootstrap";

import IconSpinner from "components/IconSpinner";
import { getContributionAbilityRequest } from "services/adminService";
import InfoTable from "components/InfoTable";

class ContributionAbility extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    userId: PropTypes.string,
    push: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      contributionAbility: {},
      isFetching: true,
    };
  }

  async componentDidMount() {
    getContributionAbilityRequest(this.props.client, this.props.userId).then(
      (contributionAbility) => {
        return this.setState({ isFetching: false, contributionAbility });
      }
    );
  }

  _getTableMetadata = () => {
    return [
      {
        key: "totalContributionAmount",
        label: "Total Contribution Amount",
      },
      {
        key: "limit",
        label: "Contribution Limit",
      },
      {
        key: "amountRemaining",
        label: "Amount Remaining",
      },
    ];
  };

  _buildContributionYearTables = () => {
    const allYears = this.state.contributionAbility.allYears;

    return allYears.map((ability) => {
      return (
        <>
          <h1 className="page-title">Tax Year {ability.taxYear}</h1>
          <InfoTable metadata={this._getTableMetadata()} data={ability} />
          <br />
        </>
      );
    });
  };

  render() {
    if (this.state.isFetching) {
      return <IconSpinner centered />;
    }

    return <Card.Body>{this._buildContributionYearTables()}</Card.Body>;
  }
}

export default withApollo(ContributionAbility);
