export const TRADITIONAL_IRA = "TRADITIONAL_IRA";
export const ROTH_IRA = "ROTH_IRA";
export const OVERVIEW = "OVERVIEW";
export const MIXED = "MIXED";

export const accountTypesToEnglishMapping = {
  [TRADITIONAL_IRA]: "Traditional IRA",
  [ROTH_IRA]: "Roth IRA",
  [OVERVIEW]: "Overview",
  [MIXED]: "Both",
};
