/* eslint-disable react/no-unescaped-entities */
import React from "react";

class IconTerms extends React.Component {
  _content = () => {
    return (
      <div className="terms-of-service">
        <div>
          <p>
            YOU MUST READ AND CONSIDER THE AGREEMENTS CAREFULLY AND CONTACT ICON
            FINANCIAL SERVICES LLC TO ASK ANY QUESTIONS YOU MAY HAVE BEFORE
            ENTERING INTO THEM. CLICKING THAT YOU AGREE HAS THE SAME LEGAL
            EFFECT AS SIGNING A PAPER VERSION OF EACH OF THE AGREEMENTS.
          </p>
          <p>
            This Discretionary Investment Advisory Agreement (the “Agreement”)
            is made and entered into by and between Icon Financial Services LLC
            (“IFS”), a registered investment adviser under Investment Advisers
            Act of 1940 (the "Advisers Act") and you (“Client”). This agreement
            sets out the terms and conditions under which IFS will provide
            ongoing discretionary advisory services with respect to the assets
            in your IFS account (the “Account”) through the IFS Wrap Fee Program
            (the “Program”), an automated investment platform that provides,
            algorithm-based portfolio management advice. This Agreement is
            effective as of the first day an Individual Retirement Account is
            opened in connection with this Agreement.
          </p>
          <p>
            In order to become a client of IFS and have a portfolio managed on
            an ongoing basis, you will need to open a brokerage account and
            complete an account agreement with Apex Clearing Corporation (“Apex”
            or “Custodian”) and will be subject to the terms and conditions of
            that agreement.
          </p>
        </div>

        <div>
          <ol type="a">
            <li>
              <p className="terms-label">Advisory Services</p>
              <p>
                Client appoints IFS as a discretionary investment adviser for
                the Accounts and grants to IFS full discretion as to all
                investment decisions regarding the Account, including, but not
                limited to, authority to investigate, purchase, and sell on
                behalf of Client, various securities and investments. IFS is
                authorized to execute purchases and sales of securities,
                including but not limited to stocks, exchange traded funds
                (“ETFs”), mutual funds (including without limitation money
                market mutual funds as separate investments), and/or similarly
                traded instruments (collectively “Securities”), on Client’s
                behalf without consulting Client regarding each sale or purchase
                and to supervise and direct the investment and reinvestment of
                assets in the Client Accounts, with full authority and
                discretion (without consultation with the Client), on the
                Client’s behalf and at the Client’s risk, and in accordance with
                the Client’s Investment Profile (defined below). IFS is further
                authorized to manage cash balances within the Client Accounts.
                Without in any way limiting the foregoing and for the avoidance
                of doubt, Client cannot issue individual trading instructions to
                IFS to purchase and/or sell specific Securities.
              </p>
              <p>
                IFS shall manage the Account in accordance with an investment
                plan recommended by IFS to Client from time to time based on
                profile information provided by Client (“Investment Profile”).
                IFS shall supervise and direct the investments of the Account in
                accordance with the investment objectives of Client and will
                monitor and review investments of the Account.
              </p>
            </li>

            <li>
              <p className="terms-label"> Power of Attorney</p>
              <p>
                To enable IFS to exercise fully its discretion and authority as
                provided in Section 1, Client hereby appoints IFS as Client’s
                agent and attorney-in-fact with full power and authority for
                Client and on Client’s behalf to buy, sell and otherwise deal in
                Securities and contracts relating to same for the Account.
                Client further grants to IFS as Client’s agent and
                attorney-in-fact full power and authority to do and perform
                every act necessary and proper to be done in the exercise of the
                foregoing powers as fully as Client might or could do
                personally. This power of attorney shall terminate only on
                termination of this Agreement by either party or on receipt by
                IFS of written notice of death, incapacity or dissolution of
                Client.
              </p>
            </li>

            <li>
              <p className="terms-label"> Client Accounts</p>
              <p>
                The assets shall be held by an independent custodian meeting the
                requirements of a “qualified custodian” under Rule 206(4)-2 of
                the Investment Advisers Act (the “Custody Rule”). IFS has
                appointed Apex Clearing Corporation as its broker-dealer and
                custodian pursuant to a separate “Customer Brokerage and Custody
                Agreement,” for assets in the Program. IFS shall not be liable
                to Client for any act, conduct or omission by the custodian
                and/or the clearing broker in its capacity as broker or
                custodian. Due to the nature of the Program, IFS may be deemed
                to have Custody of Client assets, IFS will maintain Client
                assets with a qualified custodian and comply with the Custody
                rule, for any Client assets that are subject to the Custody
                Rule.
              </p>
              <p>
                IFS is authorized to give instructions to the Custodian with
                respect to all investment decisions regarding the Assets and the
                Custodian is hereby authorized and directed to effect
                transactions, as are customarily performed with respect to
                securities brokerage accounts, and otherwise take such actions
                as IFS shall direct in connection with the performance of IFS’
                obligations with respect of the Account. If applicable, the fees
                charged to Client by the Custodian are exclusive of, and in
                addition to, IFS’ Compensation as defined in Section 4 below,
                and other charges discussed herein. IFS does not share in any
                portion of the fees assessed by any third party.
              </p>
              <p>
                The Custodian will also act as general administrator of Program
                Accounts, which will include the charging and collection of
                account fees and the processing, pursuant to IFS instructions,
                of deposits to and withdrawals from Client Account. Any
                custodian that maintains Client assets will forward trade
                confirmation and/or monthly statements to Client.
              </p>
            </li>
            <li>
              <p className="terms-label"> Compensation</p>
              <p>
                The compensation of IFS for its advisory services rendered
                hereunder shall be calculated in accordance with the Schedule of
                Fees found on the Clients account page on the website and within
                the IFS Form ADV Part 2A Wrap Fee Brochure. IFS will promptly
                notify Client of any increase or decrease in the Advisory Fee.
                An increase in the Advisory Fee will be effective for the
                Account starting in the next month that begins at least 30 days
                after IFS sends or posts such notice. A reduction in the
                Advisory Fee will be effective for the Account starting in the
                next month following its reduction.
              </p>
              <p>
                Client authorizes the Custodian to charge the Account for the
                amount of IFS’ fee and to remit such fee to IFS in accordance
                with required regulatory procedures. In addition to the advisory
                fee, unaffiliated third parties may impose certain charges.
                These charges may include, but are not limited to, fees charged
                by independent managers, custodial fees, brokerage commissions,
                transaction fees, charges imposed directly by a mutual, hedge,
                index or exchange traded fund, fees imposed by variable annuity
                providers, certain deferred sales charges, odd-lot
                differentials, transfer taxes, and wire transfer and electronic
                fund fees.
              </p>
              <p>
                IFS does not charge any performance-based fees (that is, fees
                based upon a share of capital gains on or capital appreciation
                of the assets of a client)
              </p>
            </li>

            <li>
              <p className="terms-label"> Termination</p>
              <p>
                This Agreement will continue in effect until terminated by
                either party by providing written notice to the other party.
                Client may terminate the Agreement within five (5) business days
                of signing the Agreement, without penalty or fee. Termination of
                this Agreement will not affect: (i) the validity of any action
                previously taken by IFS under this Agreement; (ii) liabilities
                or obligations of the parties from transactions initiated before
                termination of this Agreement; or (iii) Client’s obligation to
                pay advisory fees (prorated through the date of termination).
                Upon the termination of this Agreement, IFS will have no
                obligation to recommend or take any action with regard to the
                Securities, cash or other investments in the Account. Upon
                termination of this Agreement IFS will refund the balance of any
                unearned fees.
              </p>
            </li>

            <li>
              <p className="terms-label">
                Representations and Acknowledgements
              </p>
              <p>Client makes the following representations and warranties:</p>
              <ol type="a">
                <li>
                  Client has the requisite legal capacity, authority and power
                  to execute, deliver and perform his or her obligations under
                  this Agreement. This Agreement has been duly authorized,
                  executed and delivered by Client and is the legal, valid and
                  binding agreement of Client, enforceable against Client in
                  accordance with its terms. Client’s execution of this
                  Agreement and the performance of his or her obligations
                  hereunder do not conflict with or violate any obligations by
                  which Client is bound, whether arising by contract, operation
                  of law or otherwise.
                </li>
                <li>
                  To the extent that the Account is for an Individual Retirement
                  Account (“IRA”), the person signing on behalf of the IRA (a)
                  has the authority to execute this Agreement on behalf of the
                  IRA and (b) understands that IFS is not responsible for
                  maintaining the Account in compliance with laws and
                  regulations that are applicable to IRAs.
                </li>
                <li>
                  Client is the owner or co-owner of all cash and Securities in
                  the Account, and there are no restrictions on the pledge,
                  hypothecation, transfer, sale or public distribution of such
                  cash or Securities.
                </li>
                <li>
                  Client will provide IFS with complete and accurate information
                  about Client’s identity, background, net worth, investing
                  timeframe, other risk considerations, and Securities from
                  which Client may be or become legally restricted from buying
                  or selling, as requested, in the Investment Profile and will
                  promptly update that information as Client’s circumstances
                  change.
                </li>
                <li>
                  Client agrees to provide information and/or documentation
                  requested by IFS in furtherance of this Agreement as it
                  pertains to Client’s investment objectives, needs and goals,
                  and to keep IFS duly informed of any changes regarding same.
                  Client acknowledges that IFS cannot adequately perform its
                  advisory services for Client unless Client diligently performs
                  Client's responsibilities under this Agreement. IFS shall not
                  be required to verify any information obtained from Client,
                  Client’s attorney, accountant or other professionals, and is
                  expressly authorized to rely thereon.
                </li>
                <li>
                  Client agrees that IFS shall not have any duty or obligation
                  to advise or take any action on behalf of Client in any legal
                  proceedings, including bankruptcies or class actions,
                  involving Securities held in or formerly held in the Account
                  or the issuers of Securities.
                </li>
              </ol>
            </li>
            <li>
              <p className="terms-label">Representations by IFS</p>
              <p>Client makes the following representations and warranties:</p>
              <ol type="a">
                <li>
                  This Agreement has been duly authorized and executed and
                  constitutes the legal, valid and binding Agreement of IFS,
                  enforceable in accordance with its terms IFS has the power and
                  authority to enter into and perform this Agreement, and there
                  are no authorizations, permits, certifications, licenses,
                  filings, registrations, approvals or consents which must be
                  obtained by it from any third party, including any
                  governmental authority, in connection with this Agreement.
                </li>
                <li>
                  By execution of this Agreement, IFS represents and confirms
                  that it is registered as an investment adviser and will
                  maintain that registration through the term of this Agreement.
                  All personnel assigned by IFS to provide the advisory services
                  will be licensed as required by law.
                </li>
                <li>
                  IFS agrees to comply with applicable federal and state privacy
                  and information security laws governing the use, disclosure
                  and safeguarding of nonpublic personal information.
                </li>
              </ol>
            </li>
            <li>
              <p className="terms-label"> Limitation of Liability</p>
              <p>
                Except as otherwise provided by federal or state securities
                laws, IFS, nor any (a) of its officers, partners or directors
                (or persons performing similar functions); (b) its employees and
                representatives; or (c) persons directly or indirectly
                controlling IFS or controlled by IFS (as defined in the Advisers
                Act) (together, our “Affiliates”); or (d) any of IFS’ agents,
                including any service providers, (together with our Affiliates,
                the “Indemnified Persons”) will be liable for (i) any
                obligations, costs, fees, losses, liabilities, claims,
                judgments, actions, damages and expenses, including but not
                limited to attorneys’ fees, expenses and court costs (“Losses”)
                paid, suffered, incurred or arising from any investment decision
                made or other action taken or omitted in good faith by IFS with
                the degree of care, skill, prudence; and diligence that a person
                acting in a fiduciary capacity would use under the
                circumstances; (ii) any losses arising from adhering to your
                instructions; or (iii) any act or failure to act by Apex or any
                other third party; or (iv) any failures arising out of the use
                of the Program website or any of the investment tools or
                calculators on the Program website.
              </p>
              <p>
                If IFS is not responsible for (a) any of Client’s assets that
                are not managed under this Agreement; or (b) diversifying all of
                Client’s assets. Nothing in this Agreement will waive or limit
                any rights that you may have under federal and state securities
                laws for the advisory services you receive under this Agreement.
              </p>
              <p>
                Client will defend, indemnify and hold all of the Indemnified
                Persons harmless from any and all Losses sustained by the
                indemnified party arising out of or in connection with any
                misrepresentations or omissions made by Client in this
                Agreement, any inaccuracies in the information that you provide
                to IFS or any other indemnified party, or any instructions that
                you provide to IFS or any other party in connection with
                Client’s Account
              </p>
            </li>
            <li>
              <p className="terms-label">
                Non-Exclusive Services; Relationship of Parties
              </p>
              <p>
                It is understood that IFS may perform investment advisory
                services for various clients and that the services provided by
                IFS are rendered on a non‑exclusive basis. Client agrees that
                IFS may give advice and take action in the performance of its
                duties with respect to any of its other clients which may differ
                with the advice given or action taken with respect to the
                Account. Nothing in this Agreement shall be deemed to confer
                upon IFS any obligation to acquire for the Account a position in
                any security which IFS, its principals, or its employees may
                acquire for its or their own accounts or for the account of any
                other client, if in the sole and absolute discretion of IFS it
                is not for any reason practical or desirable to acquire a
                position in such security for the Account.
              </p>
            </li>
            <li>
              <p className="terms-label">Inside Information</p>
              <p>
                IFS shall have no obligation to seek to obtain any material
                nonpublic (“inside”) information about any issuer of securities
                and shall not purchase, sell, or recommend for the Account the
                securities of any issuer on the basis of any such information as
                may come into its possession.
              </p>
            </li>

            <li>
              <p className="terms-label"> Proxies</p>
              <p>
                IFS will not ask for, nor accept voting authority for client
                securities. Clients will receive proxies directly from the
                issuer of the security or the custodian. Clients should direct
                all proxy questions to the issuer of the security.
              </p>
            </li>

            <li>
              <p className="terms-label"> Valuation</p>
              <p>
                In computing the market value of any investment of the Account,
                the securities in the Account listed on a national securities
                exchange or otherwise subject to current last‑sale reporting
                shall be valued at the amount reported on the statement that
                Client receives from the Custodian. Such securities which are
                not traded nor subject to last‑sale reporting shall be valued at
                the latest available bid price reflected by quotations furnished
                to IFS by such sources as it may deem appropriate. Any other
                security shall be valued in such manner as shall be determined
                in good faith by IFS and Client to reflect its fair market
                value.
              </p>
            </li>

            <li>
              <p className="terms-label"> Assignment; Modification</p>
              <p>
                This Agreement may not be assigned (within the meaning of the
                Advisers Act) by either Client or Adviser without the prior
                consent of the other party. Client acknowledges and agrees that
                transactions that do not result in a change of actual control or
                management of IFS shall not be considered an assignment pursuant
                to Rule 202(a)(1)-1 under the Investment Advisers Act, and/or
                relevant state law.
              </p>
              <p>
                The Agreement may be modified, including without limitation the
                advisory services to be provided by IFS or the fees charged by
                IFS (i) by mutual written agreement or (ii) by IFS providing
                notice to Client and Client failing within 30 calendar days to
                object in writing or terminate this Agreement, at which time the
                amendment will be effective. Notwithstanding the above, IFS may
                update information contained in Section 4 at any time in any
                reasonable manner and may rely upon new information provided by
                Client.
              </p>
            </li>

            <li>
              <p className="terms-label">Notices</p>
              <p>
                All notices to be given under this Agreement shall be in writing
                and shall be (i) delivered in person, (ii) sent by U.S. Postal
                Service certified mail (return receipt requested) or reputable
                overnight courier or (iii) transmitted electronically (including
                via facsimile or email), in each case addressed: 1) to Client at
                the address appearing below; 2) to IFS at the address set forth
                on the cover page of this Agreement to the attention of its
                Chief Compliance Officer; or 3) to such other address as each
                may hereafter specify to the other in writing in accordance with
                this section. Notices shall be deemed to have been delivered on
                the date of delivery if delivered personally. Notices sent
                either by certified mail, return-receipt-requested or overnight
                courier shall be deemed to have been given upon the receipt or
                rejection of delivery thereof by the party to whom the notice
                was sent. Notices sent electronically (including via facsimile
                or email) shall be deemed to have been given on the date sent
                with confirmation of the successful transmission of such
                electronic notice (including, with respect to emails, a
                date/time sent stamp) to the party to whom the notice was sent,
                otherwise upon receipt of delivery thereof by the party to whom
                the notice was sent.
              </p>
            </li>
            <li>
              <p className="terms-label"> Consent to Electronic Delivery</p>
              <p>
                Client hereby consent to electronic delivery by IFS of all
                documents (other than any that Client specifically request be
                delivered in paper form), including, without limitation, all
                current and future notices, disclosures, regulatory
                communications (e.g., IFS’ Privacy Policy and brochure and
                brochure supplement prepared pursuant to Part 2A and Part 2B of
                Form ADV, including any amendments or supplements thereto) and
                other information, documents, data and records regarding the
                advisory services provided to Client under this Agreement.
                Client agrees that all information provided to Client via email
                notification will be deemed to have been good and effective
                delivery to Client when sent or posted by IFS, regardless of
                whether Client actually or timely receive or access the email
                notification. IFS will send all emails to the email address that
                Client provide to IFS. If an email notification is
                undeliverable, we will provide the delivery via an alternative
                electronic medium or to your postal mail address of record.
              </p>
              <p>
                Client may revoke or restrict its consent to electronic delivery
                of such documents at any time by notifying IFS in writing of
                your intention to do so. Client has the right to request paper
                delivery of any documents that IFS is legally required to
                provide in paper form and Client acknowledge that IFS may charge
                a reasonable service fee for the delivery of documents that
                would otherwise be delivered electronically. Neither the
                revocation or restriction of consent or the request for paper
                delivery, nor our delivery of paper copies of documents, will
                affect the legal effectiveness or validity of any electronic
                communication provided while the consent is in effect.
              </p>
              <p>
                This consent will be effective immediately and will remain in
                effect unless and until either Client or IFS revokes it. Client
                understand that it may take up to three (3) business days to
                process a revocation of consent to electronic delivery and
                Client may receive electronic documents in the interim.
              </p>
              <p>
                Client agree to notify IFS immediately if Client changes email
                addresses or wish to have IFS deliver documents to a different
                or additional email addresses.
              </p>
            </li>

            <li>
              <p className="terms-label"> Governing Law</p>
              <p>
                The validity of this Agreement and the rights and liabilities of
                the parties hereunder shall be determined in accordance with the
                laws of the state Delaware, except to the extent preempted by
                ERISA or other federal or state laws or regulations.
              </p>
            </li>

            <li>
              <p className="terms-label"> Arbitration</p>
              <p>
                To the extent enforceable and except with respect to class
                actions or other representative actions, any controversy or
                claim arising out of this Agreement, or any breach thereof, or
                arising out of the advisory services provided by IFS and its
                employees or representatives hereunder, shall be settled by
                arbitration in a location designated by IFS in accordance with
                the Commercial Rules of the American Arbitration Association,
                and judgment upon the award rendered by the arbitrator shall be
                final and may be entered in any court having jurisdiction
                thereof. Client and IFS hereby irrevocably submit to the
                jurisdiction of any such court for the purpose of such
                arbitration and the entering of such judgment. Client agrees
                that it will not claim that any such proceeding elected pursuant
                to this Section 18 has been brought in an inconvenient forum. If
                any provision of this Agreement is held by any court or in any
                arbitration to be invalid, void, or unenforceable, the remaining
                provisions shall nevertheless continue in full force and effect.
                Client is in no way waiving their rights provided under state or
                federal securities laws to pursue a remedy by other means.
              </p>
              <p>
                In entering into this Agreement, Client is aware that: (i)
                arbitration is generally final and binding on the parties; a
                party’s right to have a court reverse or modify an arbitration
                award is very limited; (ii) the parties are waiving their right
                to seek remedies in court, including the right to jury trial,
                except to the extent that such a waiver would violate applicable
                law; (iii) pre-arbitration discovery is generally more limited
                than and potentially different in form and scope from court
                proceedings; (iv) the arbitration award is not required to
                include factual findings or legal reasoning; (v) the panel of
                arbitrators will typically include a minority of arbitrators who
                were or are affiliated with the securities industry; (vi) the
                rules of some arbitration forums may impose time limits for
                bringing a claim in arbitration; in some cases, a claim that is
                ineligible for arbitration may be brought in court; (vii) the
                rules of the arbitration forum in which the claim is filed, and
                any amendments thereto, shall be incorporated into this
                Agreement; and (viii) Client acknowledges and agrees that in the
                specific event of non-payment of any portion of Compensation
                pursuant to Section 4 of this Agreement, IFS, in addition to the
                aforementioned arbitration remedy, shall be free to pursue all
                other legal remedies available to it under law, and shall be
                entitled to reimbursement of reasonable attorneys fees and other
                costs of collection.
              </p>
            </li>

            <li>
              <p className="terms-label"> Confidential Relationship</p>
              <p>
                All information and advice furnished by either party to the
                other shall be treated as confidential and shall not be
                disclosed to third parties except as required by law and as
                described in IFS’ Privacy Policy Statement.
              </p>
            </li>
            <li>
              <p className="terms-label"> Death or Disability</p>
              <p>
                If Client is a natural person, then Client’s death, incapacity,
                disability, or incompetence will not terminate or change the
                terms of this Agreement. However, Client’s guardian, executor,
                attorney-in-fact, or other authorized representative may
                terminate this Agreement by giving IFS written notice in
                accordance with the termination provisions of this Agreement.
              </p>
            </li>

            <li>
              <p className="terms-label"> Interpretation</p>
              <p>
                This Agreement will be interpreted by its fair meaning as if
                prepared by IFS and Client, and will not be interpreted against
                either party on the basis that it was prepared by one party or
                the other. The captions, headings, and subheadings used in this
                Agreement are for convenience only and do not in any way affect,
                limit, amplify or modify the terms and provisions of this
                Agreement. Words used in the masculine gender include the neuter
                and feminine gender, words used in the neuter gender include the
                masculine and feminine, words used in the singular include the
                plural, and words used in the plural include the singular,
                wherever the context reasonably requires.
              </p>
            </li>
            <li>
              <p className="terms-label"> Entire Agreement; Counterparts</p>
              <p>
                This Agreement constitutes the entire agreement between Client
                and IFS with respect to the matters described, and each party
                acknowledges and agrees that no representations, warranties,
                inducements, promises or agreements other than those written in
                this Agreement have been made by any party to the other. This
                Agreement may be executed in counterparts, each of which will be
                deemed to be an original.
              </p>
            </li>
          </ol>
        </div>

        <p className="end">END OF AGREEMENT</p>

        <div>
          <p>Icon Financial Services, LLC</p>
          <p>1631 NE Broadway St. #815</p>
          <p>Portland, Oregon 97232</p>
          <p>June 23, 2020</p>
        </div>
      </div>
    );
  };

  render() {
    const content = this._content();
    return <div>{content}</div>;
  }
}

export default IconTerms;
