import { navigationConstants } from "actions/types";

const initialState = {
  tab: {
    tabObject: "",
    tabValue: "",
    topicId: "",
  },
  company: {
    companyId: "",
    companyName: "",
  },
};

export function nav(state = initialState, action) {
  switch (action.type) {
    case navigationConstants.NAVIGATION_SET_TAB_FAILURE:
      return {
        ...state,
        tab: {
          tabObject: "",
          tabValue: "",
          topicId: "",
        },
      };
    case navigationConstants.NAVIGATION_SET_TAB_SUCCESS:
      return {
        ...state,
        tab: {
          tabObject: action.data.tabObject,
          tabValue: action.data.tabValue,
          topicId: action.data.topicId,
        },
      };
    case navigationConstants.NAVIGATION_SET_COMPANY_FAILURE:
      return {
        ...state,
        company: {
          companyId: "",
          companyName: "",
        },
      };
    case navigationConstants.NAVIGATION_SET_COMPANY_SUCCESS:
      return {
        ...state,
        company: {
          companyId: action.data.companyId,
          companyName: action.data.companyName,
        },
      };
    default:
      return state;
  }
}
