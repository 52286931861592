import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import ReactPolling from "react-polling";

function defaultRender() {
  return null;
}

export default class PromisePoller extends React.PureComponent {
  static propTypes = {
    interval: PropTypes.number.isRequired,
    promiseToPoll: PropTypes.func.isRequired,
    renderFunction: PropTypes.func,
    minutesToPollFor: PropTypes.number,
  };

  static defaultProps = {
    renderFunction: defaultRender,
  };

  constructor(props) {
    super(props);

    this.state = {
      startTime: moment(),
    };
  }

  _shouldContinuePolling = () => {
    if (!this.props.minutesToPollFor) {
      return true;
    }

    const currentTime = moment();
    const timePassedInMinutes = currentTime.diff(
      this.state.startTime,
      "minutes"
    );

    const minutesToPollForHasPassed =
      timePassedInMinutes >= this.props.minutesToPollFor;

    return !minutesToPollForHasPassed;
  };

  render() {
    return (
      <ReactPolling
        url={" "} // url empty string since we will always be polling against our graphql queries/action dispatchers
        interval={this.props.interval}
        onSuccess={() => {
          return this._shouldContinuePolling();
        }}
        onFailure={() => false}
        promise={this.props.promiseToPoll}
        render={this.props.renderFunction}
      />
    );
  }
}
