import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { isNil, get } from "lodash";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";

import PlaidLinkTokenStorage from "../utils/PlaidLinkTokenStorage";
import IconSpinner from "components/IconSpinner";
import {
  linkBankIndividual,
  linkBank,
  oAuthPlaidLinkError,
} from "actions/bankActions";
import { isEmployerSelector } from "store/selectors/user";

class OauthPlaidLink extends React.Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,

    userId: PropTypes.string.isRequired,
    linkBank: PropTypes.func.isRequired,
    linkBankIndividual: PropTypes.func.isRequired,
    isEmployer: PropTypes.bool.isRequired,
    oAuthPlaidLinkError: PropTypes.func.isRequired,
    userLegalName: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const isOAuthRedirect = window.location.href.includes("?oauth_state_id=");

    if (isNil(this.props.userId) || !isOAuthRedirect) {
      this.props.push("/dashboard");
    }

    let tokenContext;
    try {
      tokenContext = PlaidLinkTokenStorage.getTokenContext(this.props.userId);
    } catch (ex) {
      this.props.oAuthPlaidLinkError("Failed to link bank account");
      this.props.push("/dashboard");
    }
    if (isNil(tokenContext) && !isOAuthRedirect) {
      this.props.push("/dashboard");
    } else {
      const previousPath = get(tokenContext, "currentPath");
      var redirectPath = previousPath.includes("link-bank")
        ? "/dashboard"
        : previousPath;
      const token = get(tokenContext, "token");
      const plaidConfig = {
        token,
        onExit: () => this.props.push(redirectPath),
        onSuccess: async (token, metadata) =>
          await this._handleOnSuccess({ token, metadata, redirectPath }),
        receivedRedirectUri: window.location.href,
      };

      const plaidLink = window.Plaid.create(plaidConfig);
      plaidLink.open();
    }
  }

  _handleOnSuccess = async ({ token, metadata, redirectPath }) => {
    if (this.props.isEmployer) {
      await this.props.linkBank(this.props.client, token, metadata);
    } else {
      await this.props.linkBankIndividual(
        this.props.client,
        token,
        metadata,
        this.props.userLegalName
      );
    }
    PlaidLinkTokenStorage.clearTokenContext(this.props.userId);
    this.props.push(redirectPath);
  };

  render() {
    return (
      <div>
        <IconSpinner centered />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userLegalName = `${state.user.profile.firstName} ${state.user.profile.lastName}`;
  return {
    userId: state.user.id,
    isEmployer: isEmployerSelector(state),
    userLegalName,
  };
};

const mapDispatchToProps = {
  push,
  linkBankIndividual,
  linkBank,
  oAuthPlaidLinkError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(OauthPlaidLink));
