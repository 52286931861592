import React from "react";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Formik } from "formik";
import { Form, Col, Row } from "react-bootstrap";
import { PropTypes } from "prop-types";

import SimpleModal from "components/SimpleModal";

import { createLoadingSelector } from "store/selectors";
import { createErrorSelector } from "store/selectors";
import { linkCompanyBank } from "actions/adminActions";
import { adminConstants } from "actions/types";
import { toast } from "react-toastify";

const yup = require("yup");

const schema = yup.object({
  accountNumber: yup.string().required().matches(/^\d+$/),
  confirmAccountNumber: yup
    .string()
    .required()
    .label("Confirm account number")
    .test(
      "account-numbers-match",
      "Account numbers must match",
      function (value) {
        return this.parent.accountNumber === value;
      }
    ),
  routingNumber: yup.string().required().matches(/^\d+$/),
  confirmRoutingNumber: yup
    .string()
    .required()
    .label("Confirm routing number")
    .test(
      "routing-numbers-match",
      "Routing numbers must match",
      function (value) {
        return this.parent.routingNumber === value;
      }
    ),
  bankName: yup.string().required(),
  accountType: yup.string().required(),
});
class BankLinkModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    linkCompanyBank: PropTypes.func,
    companyId: PropTypes.string,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    client: PropTypes.object,
  };

  _linkBank = ({ accountNumber, routingNumber, accountType, bankName }) => {
    const bankRequest = {
      accountNumber,
      routingNumber,
      accountType,
      companyId: this.props.companyId,
      bankName,
    };

    this.props.linkCompanyBank(this.props.client, bankRequest).then(() => {
      if (!this.props.error) {
        toast.success("Succesfully linked bank.");
        this.props.onClose();
      }
    });
  };

  render() {
    return (
      <Formik
        validateOnChange={false}
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={(values) => {
          this._linkBank(values);
        }}
        initialValues={{
          accountNumber: "",
          routingNumber: "",
          bankName: "",
          accountType: "CHECKING",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <SimpleModal
            show={true}
            onClose={this.props.onClose}
            onSubmit={handleSubmit}
            title="Setup manual bank link"
            buttonText="Link"
            closeButtonText="Cancel"
            isXhrRequest={true}
            error={this.props.error}
            isFetching={this.props.isFetching}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="formBankName">
                <Form.Control
                  name="bankName"
                  placeholder="Bank Name"
                  value={values.bankName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.bankName && !!errors.bankName}
                  isValid={touched.bankName && !errors.bankName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.bankName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formAccountNumber">
                <Form.Control
                  name="accountNumber"
                  placeholder="Account Number"
                  value={values.accountNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.accountNumber && !!errors.accountNumber}
                  isValid={touched.accountNumber && !errors.accountNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.accountNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formConfirmAccountNumber">
                <Form.Control
                  name="confirmAccountNumber"
                  placeholder="Confirm Account Number"
                  value={values.confirmAccountNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.confirmAccountNumber &&
                    !!errors.confirmAccountNumber
                  }
                  isValid={
                    touched.confirmAccountNumber && !errors.confirmAccountNumber
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmAccountNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formRoutingNumber">
                <Form.Control
                  name="routingNumber"
                  placeholder="Routing Number"
                  value={values.routingNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.routingNumber && !!errors.routingNumber}
                  isValid={touched.routingNumber && !errors.routingNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.routingNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formConfirmRoutingNumber">
                <Form.Control
                  name="confirmRoutingNumber"
                  placeholder="Confirm Routing Number"
                  value={values.confirmRoutingNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.confirmRoutingNumber &&
                    !!errors.confirmRoutingNumber
                  }
                  isValid={
                    touched.confirmRoutingNumber && !errors.confirmRoutingNumber
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmAccountNumber}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formAccountType" as={Row}>
                <Col md={3}>
                  <Form.Check
                    type="radio"
                    name="accountType"
                    id="CHECKING"
                    label="Checking"
                    value="CHECKING"
                    onChange={handleChange}
                    checked={values.accountType === "CHECKING"}
                  />
                </Col>

                <Col md={3}>
                  <Form.Check
                    type="radio"
                    name="accountType"
                    label="Savings"
                    value="SAVINGS"
                    id="SAVINGS"
                    onChange={handleChange}
                    checked={values.accountType === "SAVINGS"}
                  />
                </Col>
              </Form.Group>
            </Form>
          </SimpleModal>
        )}
      </Formik>
    );
  }
}

const isFetchingSelector = createLoadingSelector(adminConstants.LINK_BANK);
const errorSelector = createErrorSelector(adminConstants.LINK_BANK);

const mapStateToProps = (state) => {
  return {
    isFetching: isFetchingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  linkCompanyBank,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(BankLinkModal));
