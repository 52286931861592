export const selectHeader = "selectHeader";
export const uploadFile = "uploadFile";
export const matchColumns = "matchColumns";
export const validateRows = "validateRows";

export const rosterUploaderSteps = [
  {
    index: 0,
    key: uploadFile,
    label: "Upload Roster",
  },
  {
    index: 1,
    key: selectHeader,
    label: "Select Header Row",
  },
  {
    index: 2,
    key: matchColumns,
    label: "Map Columns",
  },
  {
    index: 3,
    key: validateRows,
    label: "Submit",
  },
];
