import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Card } from "react-bootstrap";

import IconSpinner from "components/IconSpinner";

import "./EmployerCard.scss";
export const EmployerCard = ({ title, description, loading, content }) => {
  return (
    <div className="download-employee-template">
      <Row>
        <Col>
          <Card>
            <Card.Title>
              <p className="page-header">{title}</p>
              <p className="page-subtext">{description}</p>
            </Card.Title>
            <Card.Body>
              {loading && (
                <div className="action-card-action action-card-loading">
                  <IconSpinner />
                </div>
              )}
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

EmployerCard.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.object,
  actionText: PropTypes.string,
  completedAction: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default EmployerCard;
