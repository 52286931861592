import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { get, map } from "lodash";

import Button from "components/Button";
import Alert from "components/Alert";
import IconHeader from "components/IconHeader";
import IconSpinner from "components/IconSpinner";
import IconTable from "components/IconTable";

import { approvalType } from "statics/propTypes";
import "./Approval.scss";
import { ScrollToFieldError } from "utils/form";

const yup = require("yup");

const schema = yup.object({
  approvalStatus: yup.string().required(),
  reason: yup.string(),
  comments: yup.string(),
});

const approvalOptions = [
  { value: "APPROVED", label: "Approved" },
  { value: "DENIED", label: "Denied" },
];

class ApprovalItem extends React.PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    approval: approvalType,
    client: PropTypes.object,
    onSubmit: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      approvalStatus: "",
      comments: "",
      reason: "NONE",
    };
  }

  _buildMetaDataColumns = () => {
    const columns = [
      {
        label: "Name",
        key: "name",
      },

      {
        label: "Value",
        key: "value",
      },
    ];
    return columns;
  };

  render() {
    const isDenied = this.props.approval.status === "DENIED";
    const isApproved = this.props.approval.status === "APPROVED";
    const hasMetadata = this.props.approval.metadataItems.length > 0;

    const columns = this._buildMetaDataColumns();
    const cardTitle = get(this.props.approval, "type.label", "Approval Item");

    const entity = get(this.props.approval, "entityIdentifier");
    let label;
    let info = "";
    if (entity.type === "Employer") {
      label = `Company Id: ${entity.id}`;
      info = `Company Name: ${this.props.approval.correlationName}`;
    } else if (entity.type === "User") {
      label = `User Id: ${entity.id}`;
      info = `User Email: ${this.props.approval.correlationName}`;
    } else {
      label = `System`;
    }

    return (
      <Card className="admin-approval-card">
        <Card.Body>
          {this.props.isFetching && <IconSpinner centered />}
          {!this.props.isFetching && (
            <>
              <div className="action-box">
                <div className="action-header">
                  <span>
                    <IconHeader variant="cardHeader" headerText={cardTitle} />

                    <div className="approval-details">
                      <p className="approval">{label}</p>
                      <p className="approval">{info}</p>
                    </div>
                  </span>
                </div>
                <div>
                  {hasMetadata && (
                    <>
                      <IconTable
                        columns={columns}
                        data={this.props.approval.metadataItems}
                      />
                    </>
                  )}
                </div>
              </div>

              <Row>
                <Col as={Col} sm={6}>
                  <div className="action-box">
                    <div className="action-header">
                      <span>
                        <IconHeader
                          variant="cardHeader"
                          headerText="Employer Information"
                        />
                      </span>
                    </div>
                    <div className="action-body">
                      <Formik
                        validateOnChange={false}
                        validationSchema={schema}
                        onSubmit={(values) => {
                          this.props.onSubmit({
                            ...values,
                            approvalId: this.props.approval.id,
                          });
                        }}
                        enableReinitialize={true}
                        initialValues={this.state}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          values,
                          touched,
                          errors,
                          handleBlur,
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <ScrollToFieldError />
                            <Form.Group controlId="formBasicApprovalStatus">
                              <Form.Label>Approval Status</Form.Label>
                              <Form.Control
                                as="select"
                                name="approvalStatus"
                                value={values.approvalStatus}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={
                                  touched.approvalStatus &&
                                  !!errors.approvalStatus
                                }
                                isValid={
                                  touched.approvalStatus &&
                                  !errors.approvalStatus
                                }
                              >
                                <option value="" disabled>
                                  Select Approval Status
                                </option>
                                {map(approvalOptions, (opt) => (
                                  <option key={opt.value} value={opt.value}>
                                    {opt.label}
                                  </option>
                                ))}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.approvalStatus}
                              </Form.Control.Feedback>
                            </Form.Group>
                            {values.approvalStatus === "DENIED" && (
                              <Form.Group controlId="formBasicReason">
                                <Form.Label>Rejection Reason</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="reason"
                                  value={values.reason}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  isInvalid={touched.reason && !!errors.reason}
                                  isValid={touched.reason && !errors.reason}
                                >
                                  <option value="" disabled>
                                    Select Reason
                                  </option>
                                  {map(
                                    this.props.approval.allReasons,
                                    (reason) => (
                                      <option key={reason} value={reason}>
                                        {reason}
                                      </option>
                                    )
                                  )}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {errors.reason}
                                </Form.Control.Feedback>
                              </Form.Group>
                            )}
                            <Form.Group controlId="formBasicComments">
                              <Form.Label>Additional Comments</Form.Label>
                              <Form.Control
                                as="textarea"
                                name="comments"
                                value={values.comments}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                isInvalid={
                                  touched.comments && !!errors.comments
                                }
                                isValid={touched.comments && !errors.comments}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.approvalStatus}
                              </Form.Control.Feedback>
                              {isDenied && (
                                <div style={{ paddingTop: 6 }}>
                                  <Alert
                                    type="warning"
                                    msg={`Request was denied by ${this.props.approval.updatedBy}`}
                                  />
                                </div>
                              )}
                              {isApproved && (
                                <div style={{ paddingTop: 6 }}>
                                  <Alert
                                    type="success"
                                    msg={`Request was approved by ${this.props.approval.updatedBy}`}
                                  />
                                </div>
                              )}
                            </Form.Group>
                            <section className="form-sec-2col">
                              <div className="submit-row">
                                {this.props.error && (
                                  <Alert type="error" msg={this.props.error} />
                                )}
                                <Button
                                  name="submit"
                                  withArrow={true}
                                  btnLabel="Submit"
                                  loading={this.props.isSubmitting}
                                />
                              </div>
                            </section>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default ApprovalItem;
