import { transactionConstants } from "actions/types";

const initialState = {
  transactions: [],
};

export function transactions(state = initialState, action) {
  switch (action.type) {
    case transactionConstants.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.data,
      };

    default:
      return state;
  }
}
