import React from "react";
import classnames from "classnames";
import { Card } from "react-bootstrap";
import SupportEmailLink from "components/SupportEmailLink";

const NoState = () => {
  return (
    <Card className={classnames("text-center")}>
      <Card.Body>
        <Card.Title>Something went wrong</Card.Title>
        <Card.Text>
          Please try refreshing the page, if you continue to have issues please
          reach out to <SupportEmailLink />.
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default NoState;
