import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FiChevronRight } from "react-icons/fi";

import "./SelectBox.scss";

const SelectBox = (props) => {
  const {
    items,
    ordered,
    defaultSelected,
    onSelect,
    isSelected,
    label,
    description,
    displayChevron,
  } = props;
  const ListType = ordered ? "ol" : "ul";

  return (
    <div className="select-box-container" onClick={onSelect}>
      <div
        className={classnames("select-box shadow", {
          selected: isSelected || defaultSelected ? "selected" : "",
        })}
      >
        <div className="description-box">
          <p className="label">{label}</p>
          <p className="description">{description}</p>
          {items && items.length > 0 && (
            <ListType className="list">
              {items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ListType>
          )}
        </div>
        {displayChevron && (
          <div style={{ paddingRight: 5 }}>
            <FiChevronRight stroke={"#5E6B75"} size={14} />
          </div>
        )}
      </div>
    </div>
  );
};

SelectBox.propTypes = {
  letter: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  items: PropTypes.array,
  ordered: PropTypes.bool,
  displayChevron: PropTypes.bool,
  defaultSelected: PropTypes.bool,
};

export default SelectBox;
