import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import EmployerGroups from "./EmployerGroups";
import BankAccounts from "./BankAccounts";
import ProfileInfo from "./ProfileInfo";
import IconSpinner from "components/IconSpinner";

import { createErrorSelector } from "store/selectors";
import { isEmployerAdminSelector } from "store/selectors/user";
import { employerConstants } from "actions/types";
import {
  getAllEmployerGroups,
  getAllEmployerAccounts,
} from "actions/employerActions";
import Administrators from "../profile/Administrators";
import { getAdministrators } from "actions/employerActions";

class CompanyProfile extends React.Component {
  static propTypes = {
    navTab: PropTypes.shape({
      tabObject: PropTypes.string,
      tabValue: PropTypes.string,
    }),
    routeName: PropTypes.string,
    setTab: PropTypes.func,
    push: PropTypes.func,
    getAllEmployerGroups: PropTypes.func,
    getAllEmployerAccounts: PropTypes.func,
    getEmployeeInvitations: PropTypes.func,
    getAdministrators: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    isEmployerAdmin: PropTypes.bool,
    hasVirtualBank: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      tab: this._calcActiveTab(props.routeName),
      initialFetching: true,
    };
  }

  componentDidMount() {
    window.analytics.page("Employer Company Profile");
    // hydration calls for data needed by sub components
    const requests = [
      this.props.getAllEmployerGroups(this.props.client),
      this.props.getAllEmployerAccounts(this.props.client),
    ];
    if (this.props.isEmployerAdmin) {
      requests.push(this.props.getAdministrators(this.props.client));
    }
    Promise.all([requests]).then(() =>
      this.setState({ initialFetching: false })
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.routeName !== this.props.routeName) {
      this.setState({ tab: this._calcActiveTab() });
    }
  }

  _calcActiveTab = (routeName) => {
    if (
      this.props.isEmployerAdmin &&
      routeName === "/dashboard/company/administrators"
    ) {
      return "administrators";
    }

    switch (routeName) {
      case "/dashboard/company/bank":
        return "bank";
      case "/dashboard/company/info":
        return "info";
      case "/dashboard/company/group":
        return "group";
      default:
        return "info";
    }
  };

  tabUpdate(value) {
    this.setState({ tab: value.k });
    this.props.push("/dashboard/company/" + value.k);
  }

  _getCardContent = () => {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }
    switch (this.state.tab) {
      case "bank":
        return <BankAccounts />;
      case "info":
        return <ProfileInfo />;
      case "group":
        return <EmployerGroups />;
      case "administrators":
        return <Administrators />;
    }
  };

  render() {
    const content = this._getCardContent();

    return (
      <div className="mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Company Profile</h1>
            <p className="page-subtext">
              Manage all aspects of your company and plan setup.
            </p>
            <div className="main-content">{content}</div>
          </article>
        </section>
      </div>
    );
  }
}
const actions = [
  employerConstants.GET_EMPLOYER_ACCOUNTS,
  employerConstants.GET_EMPLOYER_GROUPS,
];
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const routeName = get(state.router, "location.pathname");
  return {
    routeName,
    error: errorSelector(state),
    isEmployerAdmin: isEmployerAdminSelector(state),
    hasVirtualBank: get(state.employer, "hasVirtualBank"),
  };
};

const mapDispatchToProps = {
  push,
  getAllEmployerGroups,
  getAllEmployerAccounts,
  getAdministrators,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(CompanyProfile));
