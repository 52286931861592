import moment from "moment";
import { contributionConstants, userConstants } from "actions/types";
import env from "@beam-australia/react-env";
import { filter, get, map, pick } from "lodash";

import { kellyPlanId } from "statics/enterprisePlanIds";

const initialState = {
  authenticated: false,
  profile: {},
  userState: {},
  companyState: {},
  companies: [],
  confirmEmailSuccess: false,
  initialUserLoading: false,
  id: null,
  userRoleId: null,
  username: null,
  emailConfirmed: false,
  auth0User: {},
  primaryNeededYellowPathDocs: [],
  secondaryNeededYellowPathDocs: [],
  tags: [],
  pendingEmailChangeRequest: {},
  employerLinkRequests: [],
  linkedEmployers: [],
  investmentProfile: {},
  contributionAbility: {},
  requiredUpdates: {},
  featureFlags: [],
  rolloverInfo: {},
};

const profileFields = [
  "legalName",
  "firstName",
  "lastName",
  "address1",
  "address2",
  "city",
  "state",
  "postalCode",
  "phoneNumber",
  "email",
  "dob",
];

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.USER_STATE_FAILURE:
      return {
        ...state,
        authenticated: false,
        userState: {},
      };
    case userConstants.USER_STATE_SUCCESS:
      return {
        ...state,
        authenticated: true,
        userState: action.data.userState,
        companyState: action.data.companyState,
        companies: action.data.companies,
        featureFlags: action.data.featureFlags,
        ...action.data.user,
      };
    case userConstants.CONFIRM_EMAIL_SUCCESS:
      return { ...state, confirmEmailSuccess: true };

    case userConstants.USER_KYC_COMPLETED_SUCCESS:
      return {
        ...state,
        userState: action.data,
      };

    case userConstants.USER_NEEDED_YELLOWPATH_DOCS_SUCCESS:
      return {
        ...state,
        primaryNeededYellowPathDocs: action.data.primaryNeededDocuments,
        secondaryNeededYellowPathDocs: action.data.secondaryNeededDocuments,
        tags: action.data.tags,
      };

    case userConstants.USER_LOGOUT_SUCCESS:
      return { ...state, ...initialState };
    case userConstants.SET_INITIAL_USER_LOADING:
      return {
        ...state,
        initialUserLoading: action.isLoading,
      };

    case userConstants.USER_PROFILE_SUCCESS: {
      const profile = pick(action.data, profileFields);
      profile.userIsFiftyOrOlder = profile.dob
        ? moment().diff(moment(profile.dob), "years") >= 50
        : false;

      return {
        ...state,
        profile,
      };
    }

    case userConstants.USER_PROFILE_UPDATE_SUCCESS: {
      const profile = pick(action.data, profileFields);
      profile.userIsFiftyOrOlder = profile.dob
        ? moment().diff(moment(profile.dob), "years") >= 50
        : false;

      return {
        ...state,
        profile,
      };
    }

    case userConstants.EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        auth0User: {
          ...state.auth0User,
          ["https://" + env("AUTH0_DOMAIN") + "/change_email"]:
            action.data.toEmail,
        },
        pendingEmailChangeRequest: action.data,
      };

    case userConstants.GET_EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        pendingEmailChangeRequest: action.data,
      };

    case userConstants.DELETE_EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        pendingEmailChangeRequest: {},
        auth0User: {
          ...state.auth0User,
          ["https://" + env("AUTH0_DOMAIN") + "/change_email"]: null,
        },
      };
    case userConstants.USER_EMPLOYER_LINK_REQUESTS_SUCCESS: {
      const linkRequests = map(action.data, (linkRequest) => {
        const planId = get(linkRequest, "company.planId");
        const isKelly = planId === kellyPlanId;

        return { ...linkRequest, isKelly };
      });
      return {
        ...state,
        employerLinkRequests: linkRequests,
      };
    }
    case userConstants.USER_ACCEPT_INVITATION_SUCCESS: {
      const planId = get(action.data, "company.planId");
      const isKelly = planId === kellyPlanId;

      return {
        ...state,
        employerLinkRequests: [
          ...state.employerLinkRequests,
          { ...action.data, isKelly },
        ],
      };
    }

    case userConstants.USER_EMPLOYER_LINK_SUCCESS:
      return {
        ...state,
        linkedEmployers: map(action.data, (data) => ({
          ...data,
          group: data.groupWithSchedule.group,
          schedule: data.schedule,
        })),
      };

    case userConstants.DELETE_USER_EMPLOYER_LINK_SUCCESS:
      return {
        ...state,
        linkedEmployers: filter(
          state.linkedEmployers,
          (link) => link.id !== action.data
        ),
      };

    case userConstants.GET_INVESTMENT_PROFILE_SUCCESS:
      return {
        ...state,
        investmentProfile: action.data,
      };

    case userConstants.DELETE_USER_EMPLOYER_LINK_REQ_SUCCESS:
      return {
        ...state,
        employerLinkRequests: action.data,
      };

    case contributionConstants.GET_CONTRIBUTION_ABILITY_SUCCESS:
      return {
        ...state,
        contributionAbility: action.data,
      };

    case userConstants.GET_USER_REQUIRED_STEPS_SUCCESS:
      return {
        ...state,
        requiredUpdates: action.data,
      };
    case userConstants.GET_ROLLOVER_INFO_SUCCESS:
      return {
        ...state,
        rolloverInfo: action.data,
      };

    default:
      return state;
  }
}
