import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import { get, isEmpty, map } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";

import Button from "components/Button";
import Alert from "components/Alert";
import IconSpinner from "components/IconSpinner";

import { approvalType } from "statics/propTypes";
import { capitalCase } from "change-case";
import {
  approveYellowPathAppeal,
  denyYellowPathAppeal,
  retryYellowPathAppeal,
} from "services/adminService";

import Viewer from "react-viewer";
import PdfViewer from "components/PdfViewer";
import "./Approval.scss";
import { ScrollToFieldError } from "utils/form";

let yup = require("yup");
const schema = yup.object({
  approvalStatus: yup.string().required(),
  reason: yup.string(),
  comments: yup.string(),
  appealReason: yup.string().when("approvalStatus", {
    is: (status) => status === "RETRY",
    then: yup.string(),
    otherwise: yup.string().required("An appeal reason is required"),
  }),
});

const approvalOptions = [
  { value: "APPROVED", label: "Approved" },
  { value: "DENIED", label: "Denied" },
  { value: "RETRY", label: "Request New Documents" },
];

class ApprovalDocumentItem extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    onSubmit: PropTypes.func,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    approval: approvalType,
    user: PropTypes.object,
  };

  constructor() {
    super();

    this.state = {
      approvalStatus: "",
      comments: "",
      reason: "NONE",
      appealReason: "",
      documentToView: {},
    };
  }

  _buildFileViewLinks() {
    return map(this.props.approval.documents, (document) => {
      return (
        <li key={document.uri}>
          {this._parseFileName(document)} -{" "}
          <a
            className="link"
            style={{
              paddingLeft: 0,
            }}
            onClick={() => this.setState({ documentToView: document })}
          >
            View Document
          </a>{" "}
        </li>
      );
    });
  }

  _parseFileName(file) {
    const uriParts = file.uri.split("/");
    return `${capitalCase(file.type)} - ${uriParts[uriParts.length - 1]}`;
  }

  _onFileClose = () => {
    this.setState({
      documentToView: {},
    });
  };

  _onSubmit = async (values) => {
    const handleAppeal = {
      APPROVED: approveYellowPathAppeal,
      DENIED: denyYellowPathAppeal,
      RETRY: retryYellowPathAppeal,
    };
    const yellowPathAppeal = this._buildYellowPathAppealPayload(values);
    const approvalValues = {
      ...values,
      approvalStatus:
        values.approvalStatus === "RETRY" ? "DENIED" : values.approvalStatus,
    };
    const metadata = {
      appealReason: values.appealReason,
    };
    this.props.onSubmit({ ...approvalValues, metadata });
    await handleAppeal[values.approvalStatus](
      this.props.client,
      yellowPathAppeal
    );
  };

  _buildYellowPathAppealPayload(values) {
    const userId = get(this.props.approval, "entityIdentifier.id");
    const documentIds = this.props.approval.documents.map((d) => d.id);
    let yellowPathAppeal = {
      userId,
      documentIds,
    };
    if (values.approvalStatus !== "RETRY") {
      yellowPathAppeal = {
        ...yellowPathAppeal,
        appealReason: values.appealReason,
      };
    }
    return yellowPathAppeal;
  }

  _getDocumentViewer = () => {
    if (isEmpty(this.state.documentToView)) {
      return null;
    }

    const uri = get(this.state.documentToView, "metadata.downloadUrl");

    if (this.state.documentToView.contentType === "application/pdf") {
      return (
        <PdfViewer
          documentUrl={uri}
          onClose={this._onFileClose}
          isPdf={false}
        />
      );
    } else {
      return (
        <Viewer
          images={[{ src: uri }]}
          visible={true}
          onClose={this._onFileClose}
          noClose={false}
          onMaskClick={this._onFileClose}
          zIndex={100000}
        />
      );
    }
  };

  render() {
    const isDenied = this.props.approval.status === "DENIED";
    const isApproved = this.props.approval.status === "APPROVED";
    return (
      <Card className="admin-approval-card">
        <Card.Body>
          {this.props.isFetching && <IconSpinner centered />}
          {!this.props.isFetching && (
            <>
              <Card.Title>
                {get(this.props.approval, "type.label", "Approval Item")}
              </Card.Title>
              <p>
                <strong>User Id:</strong>{" "}
                {get(this.props.approval, "entityIdentifier.id", "N/A")}
              </p>
              <p>
                <strong>User Email:</strong>{" "}
                {get(this.props.approval, "correlationName", "")}
              </p>
              <p>
                <strong>{"Number of Upload Retries: "}</strong>
                {get(
                  this.props,
                  "user.documents[0].metadata.batchUploadCount",
                  "N/A"
                )}
              </p>
              <div>
                <strong>{"Documents: "}</strong>
                <ul className="file-links">{this._buildFileViewLinks()}</ul>
              </div>
              <br />
              <Formik
                validateOnChange={false}
                validationSchema={schema}
                onSubmit={this._onSubmit}
                enableReinitialize={true}
                initialValues={this.state}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  touched,
                  errors,
                  handleBlur,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <ScrollToFieldError />
                    <Form.Group
                      as={Col}
                      sm={6}
                      controlId="formBasicApprovalStatus"
                    >
                      <Form.Label>Approval Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="approvalStatus"
                        value={values.approvalStatus}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={
                          touched.approvalStatus && !!errors.approvalStatus
                        }
                        isValid={
                          touched.approvalStatus && !errors.approvalStatus
                        }
                      >
                        <option value="" disabled>
                          Select Approval Status
                        </option>
                        {map(approvalOptions, (opt) => (
                          <option key={opt.value} value={opt.value}>
                            {opt.label}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.approvalStatus}
                      </Form.Control.Feedback>
                    </Form.Group>
                    {(values.approvalStatus === "APPROVED" ||
                      values.approvalStatus === "DENIED") && (
                      <Form.Group
                        as={Col}
                        sm={6}
                        controlId="formBasicAppealReason"
                      >
                        <Form.Label>Appeal Reason</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="appealReason"
                          value={values.appealReason}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={
                            touched.appealReason && !!errors.appealReason
                          }
                          isValid={touched.appealReason && !errors.appealReason}
                        />
                      </Form.Group>
                    )}
                    {values.approvalStatus === "RETRY" && (
                      <Form.Group as={Col} sm={6} controlId="formBasicReason">
                        <Form.Label>Retry Reason</Form.Label>
                        <Form.Control
                          as="select"
                          name="reason"
                          value={values.reason}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          isInvalid={touched.reason && !!errors.reason}
                          isValid={touched.reason && !errors.reason}
                        >
                          <option value="" disabled>
                            Select Reason
                          </option>
                          {map(this.props.approval.allReasons, (reason) => (
                            <option key={reason} value={reason}>
                              {reason}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.reason}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    <Form.Group as={Col} sm={6} controlId="formBasicComments">
                      <Form.Label>Additional Comments</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="comments"
                        value={values.comments}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={touched.comments && !!errors.comments}
                        isValid={touched.comments && !errors.comments}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.approvalStatus}
                      </Form.Control.Feedback>
                      {isDenied && (
                        <div style={{ paddingTop: 6 }}>
                          <Alert
                            type="warning"
                            msg={`Request was denied by ${this.props.approval.updatedBy}`}
                          />
                        </div>
                      )}
                      {isApproved && (
                        <div style={{ paddingTop: 6 }}>
                          <Alert
                            type="success"
                            msg={`Request was approved by ${this.props.approval.updatedBy}`}
                          />
                        </div>
                      )}
                    </Form.Group>
                    {this._getDocumentViewer()}

                    <section className="form-sec-2col">
                      <div className="submit-row">
                        {this.props.error && (
                          <Alert type="error" msg={this.props.error} />
                        )}
                        <Button
                          name="submit"
                          withArrow={true}
                          btnLabel="Submit"
                          loading={this.props.isSubmitting}
                        />
                      </div>
                    </section>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default withApollo(ApprovalDocumentItem);
