import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { get, isEmpty, map, transform, upperCase } from "lodash";
import { FiDollarSign, FiDownload } from "react-icons/fi";

import { getPaginatedPayrolls } from "actions/adminActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { formatCurrency } from "utils/number";
import Alert from "components/Alert";
import Button from "components/Button";
import Paginator from "components/Paginator";
import SimpleModal from "components/SimpleModal";
import InfoTable from "components/InfoTable";
import IconTable from "components/IconTable";
import IconSpinner from "components/IconSpinner";
import IconEmptyState from "components/IconEmptyState";
import IconTableHeader from "components/IconTableHeader";
import { Col, Form, Row } from "react-bootstrap";
import { adminConstants } from "actions/types";
import { getEmployerDownloadUrl } from "services/documentService";
import { simulateLinkClickNewTab } from "utils/dom";
import { wrapError } from "utils/errorHelper";

const PENDING_DOCUMENT_ID = "-1";

class Payrolls extends React.PureComponent {
  static propTypes = {
    getPaginatedPayrolls: PropTypes.func,
    client: PropTypes.object,
    isFetching: PropTypes.bool,
    payrollsCount: PropTypes.number,
    payrolls: PropTypes.arrayOf(PropTypes.shape({})),
    companyId: PropTypes.string,
    error: PropTypes.string,
  };

  constructor(props) {
    super(props);

    let filters = {
      status: null,
      order: "DESC",
    };
    if (!isEmpty(props.companyId)) {
      filters = {
        ...filters,
        companyId: props.companyId,
      };
    }

    this.state = {
      initialFetching: true,
      metadata: {},
      page: 0,
      limit: 25,
      offset: 0,
      totalRows: 0,
      payrolls: [],
      filters,
    };
  }

  componentDidMount() {
    this._getPaginatedPayrolls();
  }

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () =>
      this._getPaginatedPayrolls()
    );
  };

  _getPaginatedPayrolls = () => {
    this.setState({ fetching: true }, () => {
      this.props
        .getPaginatedPayrolls(this.props.client, {
          limit: this.state.limit,
          offset: this.state.offset,
          ...this.state.filters,
        })
        .then(() => {
          this.setState({
            initialFetching: false,
          });
        })
        .catch((ex) => {
          this.setState({
            fetching: false,
            initialFetching: false,
            error: ex.message,
          });
        });
    });
  };

  _buildPayrollColumns = () => {
    const columns = [
      {
        label: "Payroll ID",
        key: "id",
      },
      {
        label: "Employer Id",
        key: "employerId",
      },
      {
        label: "Payroll State",
        key: "state",
      },
      {
        label: "Payroll Status",
        key: "status",
      },
      {
        label: "Period",
        customComponent: (props) => {
          return (
            <div>
              {props.period.start} - {props.period.end}
            </div>
          );
        },
      },

      {
        label: "Payroll Amount",
        customComponent: (props) => {
          return <div>{formatCurrency(props.totalAmount)}</div>;
        },
      },

      {
        label: "Metadata",
        customComponent: (props) => {
          return (
            <div>
              <Button
                color="action"
                name="action"
                btnLabel="View Metadata"
                onClick={() => {
                  this.setState({
                    showMetadataModal: true,
                    metadata: props.metadata,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        label: "Action",
        customComponent: (props) => (
          <>
            {props.payrollDocumentId === PENDING_DOCUMENT_ID ? null : (
              <a
                className="link"
                target="_blank"
                onClick={() =>
                  this._downloadFile(props.payrollDocumentId, props.employerId)
                }
              >
                <FiDownload
                  fill={"#FFFFFF"}
                  color={"#FFFFFF"}
                  stroke={"#29b0c2"}
                  size={"20px"}
                />
              </a>
            )}
          </>
        ),
      },
    ];
    return columns;
  };

  _downloadFile(documentId, companyId) {
    getEmployerDownloadUrl(this.props.client, {
      documentId,
      companyId,
    })
      .then((response) => {
        simulateLinkClickNewTab(response.url);
      })
      .catch((error) => {
        this.setState({ error: wrapError(error) });
      });
  }

  _handleStatusFilterChange = (e) => {
    let status = e.target.value;
    if (status === "ALL") {
      status = null;
    }
    this.setState(
      {
        filters: { ...this.state.filters, status },
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedPayrolls()
    );
  };

  _handleOrderFilterChange = (e) => {
    this.setState(
      {
        filters: { ...this.state.filters, order: e.target.value },
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedPayrolls()
    );
  };

  _buildPayrollStatuses = () => {
    return [
      // null value returns all payrolls
      {
        label: "All",
        value: "ALL",
      },
      {
        label: "Complete",
        value: "COMPLETE",
      },
      {
        label: "Processing",
        value: "PROCESSING",
      },
      {
        label: "Error",
        value: "ERROR",
      },
      {
        label: "Canceled",
        value: "CANCELED",
      },
      {
        label: "Approved",
        value: "APPROVED",
      },
      {
        label: "Waiting for Action",
        value: "WAITING_FOR_ACTION",
      },
    ];
  };

  _buildPayrollOrder = () => {
    return [
      { label: "Desc", value: "DESC" },
      { label: "Asc", value: "ASC" },
    ];
  };

  _buildMetadataTable = () => {
    const metadata = transform(
      this.state.metadata,
      (acc, val, key) => {
        const labelObject = {
          key,
          label: upperCase(key),
        };
        acc.push(labelObject);
        return acc;
      },
      []
    );
    return <InfoTable metadata={metadata} data={this.state.metadata} />;
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    if (this.props.error) {
      return <Alert type={"error"} msg={this.props.error} />;
    }

    const columns = this._buildPayrollColumns();
    const data = this.props.payrolls.results;
    const showBlankSlate = isEmpty(data);

    return (
      <div className="company-payrolls">
        <Row>
          <Col md={4}>
            <div
              style={{ paddingLeft: 15, paddingBottom: 15 }}
              className="state-filter"
            >
              <Form.Label>Filter by status</Form.Label>
              <Form.Control
                as="select"
                name="statusFilter"
                value={this.state.filters.status}
                onChange={this._handleStatusFilterChange}
              >
                {map(this._buildPayrollStatuses(), (filter) => (
                  <option key={filter.label} value={filter.value}>
                    {filter.label}
                  </option>
                ))}
              </Form.Control>
            </div>
          </Col>
          <Col md={4}>
            <div
              style={{ paddingLeft: 15, paddingBottom: 15 }}
              className="state-filter"
            >
              <Form.Label>Order</Form.Label>
              <Form.Control
                as="select"
                name="orderFilter"
                value={this.state.filters.order}
                onChange={this._handleOrderFilterChange}
              >
                {map(this._buildPayrollOrder(), (filter) => (
                  <option key={filter.label} value={filter.value}>
                    {filter.label}
                  </option>
                ))}
              </Form.Control>
            </div>
          </Col>
        </Row>

        {showBlankSlate && (
          <div>
            <IconEmptyState
              header={"No Payrolls"}
              subheader="There are currently no payrolls to display."
              icon={<FiDollarSign color="white" stroke="#60A4BF" size={16} />}
            />
          </div>
        )}
        {this.props.isFetching && <IconSpinner centered />}
        {!showBlankSlate && (
          <>
            {this.state.showMetadataModal && (
              <SimpleModal
                show={true}
                size={"lg"}
                onClose={() =>
                  this.setState({ showMetadataModal: false, metadata: {} })
                }
                title="Payroll Metadata"
                buttonText="close"
              >
                {this._buildMetadataTable()}
              </SimpleModal>
            )}

            <div className="action-box">
              <IconTableHeader
                tableHeader="Payrolls"
                tableCount={this.props.payrolls.totalRows}
              />
              <IconTable columns={columns} data={data} />
              <Paginator
                onChange={this._onPageChange}
                pageCount={this.props.payrolls.totalRows / this.state.limit}
                page={this.state.page}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

const isLoadingSelector = createLoadingSelector(adminConstants.GET_PAYROLLS);
const errorSelector = createErrorSelector(adminConstants.GET_PAYROLLS);

const mapStateToProps = (state) => {
  const routeName = get(state.router, "location.pathname");

  return {
    routeName,
    isFetching: isLoadingSelector(state),
    payrolls: state.admin.payrolls,
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  getPaginatedPayrolls,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Payrolls));
