import React from "react";
import StepWizard from "react-step-wizard";
import queryString from "query-string";
import moment from "moment";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { chain, filter, find, get, keyBy, map, transform } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { PropTypes } from "prop-types";
import { AiOutlineCalendar } from "react-icons/ai";
import { lockedUserProfilesSelector } from "store/selectors/user";
import { payrollTypes } from "statics/propTypes";
import {
  createTopOffContributions,
  getPayrollLineItems,
} from "actions/payrollActions";
import {
  currentPayrolls,
  getAllEmployerAccounts,
  getEmployerGroup,
  getLinkedEmployees,
  getUsersForGroup,
} from "actions/employerActions";

import ReviewExisting from "./ReviewExisting";
import ReviewApprovals from "./ReviewApprovals";
import Summary from "./Summary";
import StepInfo from "components/StepInfo";
import IconSpinner from "components/IconSpinner";
import "./payrollWorkflow.scss";

const stepToLabels = {
  1: {
    subheader:
      "Have any of these people left the company, gone on leave, or have no hours for the period?",
  },
  2: {
    subheader: "These are all the system changes you need to review.",
  },
  3: {
    subheader: "Here's the summary.",
  },
};

const steps = [
  {
    index: 1,
    label: "Review Existing",
  },
  {
    index: 2,
    label: "Approve Changes",
  },
  {
    index: 3,
    label: "Submit",
  },
];

function buildUsersWhoLeft(users) {
  return map(users, (user) => {
    return {
      isUserWhoLeft: true,
      employeeId: user.employeeId,
      userProfile: user.userProfile,
      userNewContributionAmount: 0,
      userCurrentContributionAmount: user.contribution.amount,
    };
  });
}

export class PayrollWorkflow extends React.PureComponent {
  static propTypes = {
    groupId: PropTypes.string,
    client: PropTypes.object,
    pendingContributionChanges: PropTypes.object,
    contributionsWithNoChange: PropTypes.object,
    usersWhoLeftPlan: PropTypes.array,
    linkedEmployees: PropTypes.array,
    lockedUserProfiles: PropTypes.object,
    getLinkedEmployees: PropTypes.func,
    getEmployerGroup: PropTypes.func,
    getUsersForGroup: PropTypes.func,
    push: PropTypes.func,
    getAllEmployerAccounts: PropTypes.func,
    createTopOffContributions: PropTypes.func,
    getPayrollLineItems: PropTypes.func,
    currentPayrolls: PropTypes.func,
    payroll: PropTypes.arrayOf(payrollTypes),
  };

  constructor(props) {
    super(props);

    const queryParams = queryString.parse(window.location.search);
    const payPeriodId = get(queryParams, "payPeriodId", "");

    this.state = {
      skippedContributions: {},
      approvedContributions: {},
      keepPrior: {},
      removedEmployees: {},
      activeStep: "1",
      isLoading: true,
      group: {},
      payroll: {},
      payPeriodId,
    };
  }

  async componentDidMount() {
    if (!this.state.payPeriodId) {
      this.props.push("/dashboard/contributions");
    }

    await Promise.all([
      this.props.getLinkedEmployees(this.props.client),
      this.props
        .getEmployerGroup(this.props.client, this.props.groupId)
        .then(({ data: group }) => {
          this.setState({ group });
        }),
      this.props.getUsersForGroup(this.props.client, this.props.groupId),
      this.props.getAllEmployerAccounts(this.props.client),
      this.props
        .createTopOffContributions(
          this.props.client,
          this.props.groupId,
          this.state.payPeriodId
        )
        .then(() =>
          this.props.getPayrollLineItems(
            this.props.client,
            this.props.groupId,
            this.state.payPeriodId
          )
        ),
      this.props.currentPayrolls(this.props.client),
    ]);

    this.setState({
      isLoading: false,
    });
  }

  _onStepChange = ({ activeStep }) => {
    this.setState({ activeStep });
  };

  _setSkipped = (skippedIds) => {
    this.setState({ skippedContributions: skippedIds });
  };

  _setRemoved = (removedIds) => {
    this.setState({ removedEmployees: removedIds });
  };

  _setKeepPrior = (keptIds) => {
    this.setState({ keepPrior: keptIds });
  };

  _setApproved = (approvedIds) => {
    this.setState({ approvedContributions: approvedIds });
  };

  _getDateSection = () => {
    const payPeriodId = this.state.payPeriodId;

    const commonDateFormat = "MMM Do";

    const payPeriod = find(
      this.props.payroll,
      (payroll) => payroll.payPeriodId === payPeriodId
    );

    const start = get(payPeriod, "payPeriodStart");
    const end = get(payPeriod, "payPeriodEnd");

    const period = `${moment(start).format(commonDateFormat)} - ${moment(
      end
    ).format(commonDateFormat)}`;

    return (
      <div className="payroll-calendar">
        <AiOutlineCalendar />
        <span className="payroll-period">Period: {period}</span>
      </div>
    );
  };

  _getUsersToExcludeFromSummary = () => {
    const userIdsToExclude = transform(
      this.props.linkedEmployees,
      (acc, emp) => {
        const empId = emp.employeeId;

        if (
          !this.state.removedEmployees[empId] &&
          !this.state.skippedContributions[empId] &&
          !this.state.approvedContributions[empId] &&
          !this.state.keepPrior[empId]
        ) {
          acc.push(empId);
        }
        return acc;
      },
      []
    );

    return userIdsToExclude;
  };

  _getLockedContributions = () => {
    const linkedEmployeesInCurrentGroup = filter(
      this.props.linkedEmployees,
      (employee) => {
        const employeeGroupId = get(employee, "groupWithSchedule.group.id");
        return employeeGroupId === this.props.groupId;
      }
    );

    const linkedEmployeeIdsMap = keyBy(
      linkedEmployeesInCurrentGroup,
      "employeeId"
    );

    return chain(this.props.lockedUserProfiles)
      .filter((user) => linkedEmployeeIdsMap[user.id])
      .map((user) => {
        const pendingContribution = find(
          this.props.pendingContributionChanges,
          {
            employeeId: user.id,
          }
        );
        const existingContribution = find(
          this.props.contributionsWithNoChange,
          {
            employeeId: user.id,
          }
        );
        if (existingContribution) {
          return {
            userProfile: { ...user },
            isLockedUser: true,
            userNewContributionAmount: 0,
            userCurrentContributionAmount: existingContribution.amount,
          };
        } else if (pendingContribution) {
          return {
            userProfile: { ...user },
            isLockedUser: true,
            userNewContributionAmount: 0,
            userCurrentContributionAmount:
              pendingContribution.userCurrentContributionAmount,
          };
        }
      })
      .value();
  };

  render() {
    if (this.state.isLoading) {
      return <IconSpinner centered />;
    }

    const lockedUserContributions = this._getLockedContributions();
    const usersToExclude = this._getUsersToExcludeFromSummary();
    const activeSectionLabels = stepToLabels[this.state.activeStep];
    return (
      <div className="mega-container" id="payroll-workflow">
        <section className="page-title-wrap">
          <article className="text-cell">
            <div className="subtext-and-calendar">
              <p className="page-subtext">{activeSectionLabels.subheader}</p>
              {this._getDateSection()}
            </div>
            <StepInfo steps={steps} activeStepNumber={this.state.activeStep} />
          </article>
        </section>
        <div className="main-content">
          <StepWizard
            onStepChange={this._onStepChange}
            isLazyMount={true}
            transitions={{
              enterRight: "",
              enterLeft: "",
              exitRight: "",
              exitLeft: "",
            }}
          >
            <ReviewExisting
              skippedContributions={this.state.skippedContributions}
              removedEmployees={this.state.removedEmployees}
              stepName="existing"
              group={this.state.group}
              setSkipped={this._setSkipped}
              setRemoved={this._setRemoved}
            />
            <ReviewApprovals
              stepName="approvals"
              skippedContributions={this.state.skippedContributions}
              removedEmployees={this.state.removedEmployees}
              group={this.state.group}
              setKeepPrior={this._setKeepPrior}
              setApproved={this._setApproved}
              keptPriorContributions={this.state.keepPrior}
              approvedContributions={this.state.approvedContributions}
              usersWhoLeftPlan={this.props.usersWhoLeftPlan}
              lockedUserContributions={lockedUserContributions}
              lockedEmployeeIds={map(lockedUserContributions, "userProfile.id")}
              payPeriodId={this.state.payPeriodId}
            />
            <Summary
              skippedContributions={this.state.skippedContributions}
              removedEmployees={this.state.removedEmployees}
              keptPriorContributions={this.state.keepPrior}
              approvedContributions={this.state.approvedContributions}
              stepName="summary"
              group={this.state.group}
              payPeriodId={this.state.payPeriodId}
              usersWhoLeftPlan={this.props.usersWhoLeftPlan}
              usersToExclude={usersToExclude}
              lockedUserContributions={lockedUserContributions}
            />
          </StepWizard>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const groupId = get(ownProps.match, "params.id");

  let { usersWhoLeftPlan } = state.payroll;

  return {
    groupId,
    usersWhoLeftPlan: buildUsersWhoLeft(usersWhoLeftPlan),
    linkedEmployees: state.employer.linkedEmployees,
    lockedUserProfiles: lockedUserProfilesSelector(state),
    pendingContributionChanges: state.payroll.pendingContributionChanges,
    contributionsWithNoChange: state.payroll.contributionsWithNoChange,
    payroll: state.employer.currentPayrolls,
  };
};

const mapDispatchToProps = {
  getLinkedEmployees,
  getEmployerGroup,
  getUsersForGroup,
  getAllEmployerAccounts,
  createTopOffContributions,
  getPayrollLineItems,
  currentPayrolls,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(PayrollWorkflow));
