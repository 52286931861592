import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export const UpdateGroupCard = ({ numOfInvalidBanks }) => {
  return (
    <Card className="center">
      <Card.Body>
        <Card.Title>Groups With Outdated Banks</Card.Title>
        <p className="card-total">{numOfInvalidBanks}</p>
        <div className="card-link">
          <Link className="icon-link" to="/dashboard/company/group">
            Update Groups
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

UpdateGroupCard.propTypes = {
  numOfInvalidBanks: PropTypes.number,
};

export default UpdateGroupCard;
