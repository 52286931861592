import React from "react";
import PropTypes from "prop-types";
import { get, includes, isEqual, isNil, pick, some } from "lodash";
import { groupType } from "statics/propTypes";
import { daysOfMonthOptions, END_OF_MONTH } from "utils/timeHelper";
import { ScrollToFieldError } from "utils/form";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";

import DatePicker from "react-datepicker";
import moment from "moment";
import Button from "components/Button";
import InfoTooltip from "components/InfoTooltip";
import Alert from "components/Alert";

const yup = require("yup");

const schema = yup.object({
  name: yup
    .string()
    .label("Group Name")
    .required()
    .min(2, "Must be at least two characters.")
    .max(100),
  associatedBankAccount: yup
    .string()
    .label("Associated Bank Account")
    .required(),
  useUniqueInviteCodes: yup.boolean(),
});

export default class EditGroupForm extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    error: PropTypes.string,
    accounts: PropTypes.array,
    isLoading: PropTypes.bool,
    group: groupType,
    hasExpiredInvites: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const {
      schedule: {
        displayAnchorPayDate,
        displayDayOne,
        displayDayTwo,
        anchorPayDate,
      },
    } = props.group;

    const bankAccountId = props.group.associatedBankAccount.id;

    const groupHasActiveBankAccount = some(this.props.accounts, {
      id: bankAccountId,
    });

    const defaultAccount = !groupHasActiveBankAccount ? "" : bankAccountId;

    this.state = {
      ...props.group,
      associatedBankAccount: defaultAccount,
      useUniqueInviteCodes: props.group.useUniqueInviteCodes,
      useDisplayDates: displayAnchorPayDate || displayDayOne || displayDayTwo,
      displayAnchorPayDate,
      displayDayOne: displayDayOne === 31 ? END_OF_MONTH : displayDayOne,
      displayDayTwo: displayDayTwo === 31 ? END_OF_MONTH : displayDayTwo,
      anchorPayDate,
    };
  }

  submitGroup = (values) => {
    let group = {
      id: this.state.id,
      name: values.name,
      associatedBankAccount: values.associatedBankAccount,
      useUniqueInviteCodes: values.useUniqueInviteCodes,
    };
    if (values.useDisplayDates) {
      group = this._addDisplayValues(
        group,
        values,
        this.props.group.payrollFrequency
      );
    }
    this.props.onSubmit(group);
  };

  _addDisplayValues = (groupVals, values, payrollFrequency) => {
    if (payrollFrequency === "TWICE_PER_MONTH") {
      return {
        ...groupVals,
        displayDayOne: this.parseDayString(values.displayDayOne),
        displayDayTwo: this.parseDayString(values.displayDayTwo),
      };
    } else if (payrollFrequency === "MONTHLY") {
      return {
        ...groupVals,
        displayDayOne: this.parseDayString(values.displayDayOne),
      };
    }
    return {
      ...groupVals,
      displayAnchorPayDate: moment(values.displayAnchorPayDate).format(
        "YYYY-MM-DD"
      ),
    };
  };

  _hasBeenUpdated = (values) => {
    const newGroup = {
      id: this.state.id,
      name: values.name,
      associatedBankAccount: values.associatedBankAccount,
      useUniqueInviteCodes: values.useUniqueInviteCodes,
      displayAnchorPayDate: values.displayAnchorPayDate,
      displayDayOne: this.parseDayString(values.displayDayOne),
      displayDayTwo: this.parseDayString(values.displayDayTwo),
    };
    const associatedBankAccount = get(
      this.props,
      "group.associatedBankAccount.id"
    );
    const {
      schedule: { displayAnchorPayDate, displayDayOne, displayDayTwo },
    } = this.props.group;
    const oldGroup = {
      ...pick(this.props.group, ["id", "name", "useUniqueInviteCodes"]),
      associatedBankAccount,
      displayAnchorPayDate,
      displayDayOne,
      displayDayTwo,
    };

    return (
      !isEqual(newGroup, oldGroup) ||
      this.state.useDisplayDates !== values.useDisplayDates
    );
  };

  _parseDate = (val) => {
    if (val) {
      return moment(val).toDate();
    }
    return val;
  };

  parseDayString = (day) => {
    if (isNil(day)) {
      return day;
    }
    return day === END_OF_MONTH ? 31 : parseInt(day);
  };

  _showDayOne = (frequency) => {
    return includes(["MONTHLY", "TWICE_PER_MONTH"], frequency);
  };

  _showDayTwo = (frequency) => {
    return frequency === "TWICE_PER_MONTH";
  };

  render() {
    const accounts = this.props.accounts.map((accounts, index) => {
      return (
        <option value={accounts.id} key={index}>
          {accounts.bankName} - {accounts.bankAlias} {accounts.accountId}
        </option>
      );
    });

    const endCurrentPayPeriod = get(
      this.props.group,
      "associatedSchedule.payPeriodEnd",
      ""
    );
    return (
      <Formik
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={(values) => {
          this.submitGroup(values);
        }}
        enableReinitialize={true}
        initialValues={this.state}
      >
        {({
          handleSubmit,
          setFieldValue,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <ScrollToFieldError />
            <Form.Row>
              <Form.Group as={Col} controlId="formGroupName">
                <Form.Label>Group Name</Form.Label>
                <Form.Control
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                  isValid={touched.name && !errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGroupBank">
                <Form.Label>Associated Bank Account</Form.Label>
                <Form.Control
                  as="select"
                  name="associatedBankAccount"
                  value={values.associatedBankAccount}
                  onChange={handleChange}
                  isInvalid={!!errors.associatedBankAccount}
                  isValid={
                    touched.associatedBankAccount &&
                    !errors.associatedBankAccount
                  }
                >
                  <option value="" disabled>
                    Associated Bank Account
                  </option>
                  {accounts}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.associatedBankAccount}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            {this.props.hasExpiredInvites && (
              <Form.Row>
                <Form.Group as={Col} sm={12} controlId="formBasicUniqueCode">
                  <Form.Row className="align-items-center">
                    <Form.Check
                      className="useUniqueInviteCodes"
                      type="checkbox"
                      name="useUniqueInviteCodes"
                      checked={values.useUniqueInviteCodes}
                      value={values.useUniqueInviteCodes}
                      onBlur={handleBlur}
                      label="Employee invites should expire"
                      onChange={(e) => {
                        setFieldValue("useUniqueInviteCodes", e.target.checked);
                      }}
                    />
                    <InfoTooltip
                      tooltipBody="If checked, we will generate a unique invite code for each employee that will expire after 60 days."
                      placement="right"
                    />
                  </Form.Row>
                </Form.Group>
              </Form.Row>
            )}
            <Form.Row>
              <Form.Group as={Col} sm={12} controlId="useDisplayDates">
                <Form.Row className="align-items-center">
                  <Form.Check
                    className="useDisplayDates"
                    type="checkbox"
                    name="useDisplayDates"
                    checked={values.useDisplayDates}
                    value={values.useDisplayDates}
                    onBlur={handleBlur}
                    label="Pay period ends before payday"
                    onChange={(e) => {
                      setFieldValue("useDisplayDates", e.target.checked);
                    }}
                  />
                  <InfoTooltip
                    tooltipBody="If checked, you can configure the pay period end date."
                    placement="right"
                  />
                </Form.Row>
              </Form.Group>
            </Form.Row>
            {values.useDisplayDates &&
              this._showDayOne(values.payrollFrequency) && (
                <Form.Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    controlId="formGroupDisplayDayOne"
                  >
                    <Form.Label>
                      {values.payrollFrequency === "MONTHLY"
                        ? "What day of the month is the end of the pay period?"
                        : "What day of the month is the first end of the pay period?"}
                    </Form.Label>
                    <Form.Control
                      sm={6}
                      as="select"
                      name="displayDayOne"
                      value={
                        isNil(values.displayDayOne)
                          ? values.dayOne
                          : values.displayDayOne
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.displayDayOne}
                      isValid={touched.displayDayOne && !errors.displayDayOne}
                    >
                      <option value="" disabled>
                        Day of Month
                      </option>
                      {daysOfMonthOptions.map((item) => (
                        <option value={item.value} key={item.key}>
                          {item.value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
              )}
            {values.useDisplayDates &&
              this._showDayTwo(values.payrollFrequency) && (
                <Form.Row>
                  <Form.Group as={Col} md={6} controlId="formGroupDayTwo">
                    <Form.Label>
                      What day of the month is the second end of the pay period?
                    </Form.Label>
                    <Form.Control
                      sm={6}
                      as="select"
                      name="displayDayTwo"
                      value={
                        isNil(values.displayDayTwo)
                          ? values.dayTwo
                          : values.displayDayTwo
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.displayDayTwo}
                      isValid={touched.displayDayTwo && !errors.displayDayTwo}
                    >
                      <option value="" disabled>
                        Day of Month
                      </option>
                      {daysOfMonthOptions.map((item) => (
                        <option value={item.value} key={item.key}>
                          {item.value}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
              )}
            {values.useDisplayDates &&
              !this._showDayTwo(values.payrollFrequency) &&
              !this._showDayOne(values.payrollFrequency) && (
                <Form.Row>
                  <Form.Group as={Col} controlId="displayAnchorPayDate">
                    <Form.Label>
                      What is the last day of your current pay period?
                    </Form.Label>
                    <div>
                      <DatePicker
                        className="form-control"
                        name="displayAnchorPayDate"
                        value={
                          isNil(values.displayAnchorPayDate)
                            ? this._parseDate(values.anchorPayDate)
                            : this._parseDate(values.displayAnchorPayDate)
                        }
                        dateFormat="MM/dd/yyyy"
                        selected={
                          (values.displayAnchorPayDate &&
                            moment(values.displayAnchorPayDate).toDate()) ||
                          null
                        }
                        onChange={(val) => {
                          setFieldValue(
                            "displayAnchorPayDate",
                            moment(val).toDate()
                          );
                        }}
                        isInvalid={!!errors.displayAnchorPayDate}
                        isValid={
                          touched.displayAnchorPayDate &&
                          !errors.displayAnchorPayDate
                        }
                        maxDate={this._parseDate(endCurrentPayPeriod)}
                      />
                    </div>
                    {errors.displayAnchorPayDate &&
                      touched.displayAnchorPayDate && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.displayAnchorPayDate}
                        </div>
                      )}
                  </Form.Group>
                </Form.Row>
              )}
            {this.props.error && <Alert type="error" msg={this.props.error} />}
            <div className="submit-row">
              <span className="cancel-btn">
                <Button
                  btnLabel="Cancel"
                  color="cancel"
                  name="cancel"
                  type="button"
                  onClick={this.props.onClose}
                />
              </span>
              <Button
                name="submit"
                btnLabel="Update Group"
                withArrow={true}
                loading={this.props.isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
