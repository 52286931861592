import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { map, get } from "lodash";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { push } from "connected-react-router";

import Payrolls from "../employers/Payrolls";
import MissedPayrolls from "../employers/MissedPayrolls";

class AdminTransactions extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    error: PropTypes.string,
    routeName: PropTypes.string,
    push: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      tab: this._calcActiveTab(props.routeName),
    };
  }

  _tabUpdate(value) {
    this.setState({ tab: value.k });
    this.props.push(`/transactions/${value.k}`);
  }

  _getTabs = () => {
    let allTabs = [
      {
        label: "Payrolls",
        id: "payrolls",
      },
      {
        label: "Missed Payrolls",
        id: "missed_payrolls",
      },
    ];

    return allTabs;
  };

  _controlledTabs() {
    const tabs = this._getTabs();
    return (
      <Tabs
        activeKey={this.state.tab}
        id="tab-transaction"
        onSelect={(k) => this._tabUpdate({ k })}
      >
        {map(tabs, (tab) => {
          return <Tab key={tab.id} eventKey={tab.id} title={tab.label} />;
        })}
      </Tabs>
    );
  }

  _calcActiveTab = (routeName) => {
    switch (routeName) {
      case "/transactions/missed_payrolls":
        return "missed_payrolls";
      default:
        return "payrolls";
    }
  };

  _getCardContent = () => {
    switch (this.state.tab) {
      case "payrolls":
        return <Payrolls />;
      case "missed_payrolls":
        return <MissedPayrolls />;
    }
  };

  render() {
    return (
      <div className="mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Transactions</h1>
            <div className="main-content">
              <Row>
                <Col>
                  <div className="card-tab">{this._controlledTabs()}</div>
                  <Card>
                    <Card.Body>{this._getCardContent()}</Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const routeName = get(state.router, "location.pathname");

  return { routeName };
};

const mapDispatchToProps = { push };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AdminTransactions));
