import React from "react";
import PropTypes from "prop-types";
import { get, map } from "lodash";
import { formatValue } from "utils/number";
import "./InfoTable.scss";

const InfoTable = ({ data, metadata }) => {
  return (
    <div className="info-table">
      {map(metadata, (meta, idx) => {
        return (
          <div className="info-table-row" key={idx}>
            <div className="property">{meta.label}:</div>
            <div className="value">
              {formatValue(meta, get(data, meta.key))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

InfoTable.propTypes = {
  metadata: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
    })
  ),
  data: PropTypes.shape({}),
};

export default InfoTable;
