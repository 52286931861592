import { sidebarConstants } from "actions/types";

export const sidebar = (state = { showSidebar: false }, action) => {
  switch (action.type) {
    case sidebarConstants.SIDEBAR_SHOW:
      return { ...state, showSidebar: true };
    case sidebarConstants.SIDEBAR_HIDE:
      return { ...state, showSidebar: false };
    default:
      return state;
  }
};
