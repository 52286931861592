import { payrollConstants } from "actions/types";
import { keyBy } from "lodash";
const initialState = {
  pendingContributionChanges: {},
  usersWhoLeftPlan: {},
  contributionsWithNoChange: {},
  totalAmounts: {},
  paginatedPendingContributionChangesCount: 0,
  paginatedContributionsCount: 0,
  paginatedPendingContributionChanges: [],
};

export function payroll(state = initialState, action) {
  switch (action.type) {
    case payrollConstants.GET_PAYROLL_LINE_ITEMS_SUCCESS: {
      return {
        ...state,
        pendingContributionChanges: keyBy(
          action.data.pendingContributionChanges,
          "id"
        ),
        usersWhoLeftPlan: keyBy(action.data.usersWhoLeftPlan, "id"),
        contributionsWithNoChange: keyBy(
          action.data.contributionsWithNoChange,
          "id"
        ),
      };
    }
    case payrollConstants.GET_PAGINATED_CHANGE_REQUESTS_SUCCESS: {
      return {
        ...state,
        paginatedPendingContributionChanges: action.data.rows,
        paginatedPendingContributionChangesCount: action.data.count,
      };
    }
    case payrollConstants.GET_PAGINATED_CONTRIBUTIONS_SUCCESS: {
      return {
        ...state,
        paginatedContributions: action.data.rows,
        paginatedContributionsCount: action.data.count,
      };
    }
    case payrollConstants.GET_TOTAL_AMOUNT_SAVED_SUCCESS: {
      return {
        ...state,
        totalAmounts: {
          ...state.totalAmounts,
          totalAmount: action.data.totalAmount,
        },
      };
    }

    case payrollConstants.DISMISS_PAYROLL_SUCCESS: {
      return {
        ...state,
        dismissPayroll: action.data,
      };
    }

    default:
      return state;
  }
}
