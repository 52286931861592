import React from "react";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { getUserState } from "actions/userActions";
import PropTypes from "prop-types";
import PageHeading from "components/PageHeading";
import AddGroup from "pages/dashboards/employerDashboard/dashboard/AddGroup";
import RosterApprovalTable from "pages/dashboards/employerDashboard/dashboard/RosterApprovalTable";
import {
  ANNOUNCED_ICON,
  INVITED_EMPLOYEES,
  LAUNCHED_PLAN,
} from "statics/onboardingSteps";
import Announcement from "pages/dashboards/employerDashboard/dashboard/Announcement";
import AddEmployeeUploadFormToggle from "pages/dashboards/employerDashboard/userManagement/AddEmployeeUploadFormToggle";
import AddEmployeeForm from "pages/dashboards/employerDashboard/userManagement/AddEmployeeForm";
import LaunchPlan from "pages/dashboards/employerDashboard/dashboard/LaunchPlan";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import AddEmployerBankCard from "pages/dashboards/employerDashboard/dashboard/AddEmployerBankCard";
import AddGroupCard from "pages/dashboards/employerDashboard/dashboard/AddGroupCard";
import FinchConnect from "pages/dashboards/employerDashboard/dashboard/FinchConnect";
import AddUsersCard from "pages/dashboards/employerDashboard/dashboard/AddUsersCard";
import AddDeductionCodeCard from "./AddDeductionCodeCard";
import AddDeductionCode from "./AddDeductionCode";
import {
  hasAddedBank,
  hasAddedDeductionCode,
  hasAddedGroups,
  hasAnnouncedIcon,
  hasEnabled360PayrollIntegration,
  hasEnabledFinchFeature,
  hasFinchConnectionAuthError,
  hasInvitedAUserSelector,
  hasInvitedEmployees,
  hasLaunchedPlan,
  hasStatePlanSelector,
  hasSuccessfullyConnectedFinch,
} from "store/selectors/employer";
import {
  groupCreationHide,
  groupCreationShow,
} from "actions/groupCreationActions";

import { updateProgressiveOnboarding } from "actions/employerActions";
import { progressiveOnboardingEvents } from "statics/states";
import queryString from "query-string";
import PayrollConnect from "pages/dashboards/employerDashboard/dashboard/PayrollConnect";

class ProgressiveOnboardingDashboard extends React.PureComponent {
  static propTypes = {
    getUserState: PropTypes.func.isRequired,
    groupCreationShow: PropTypes.func.isRequired,
    groupCreationHide: PropTypes.func.isRequired,

    updateProgressiveOnboarding: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
    hasBank: PropTypes.bool,
    hasGroup: PropTypes.bool,
    hasDeductionCode: PropTypes.bool,
    hasInvitedEmployees: PropTypes.bool,
    hasLaunchedPlan: PropTypes.bool,
    hasInvitedAUser: PropTypes.bool,

    hasStatePlan: PropTypes.bool,
    hasEnabledFinch: PropTypes.bool,
    hasSuccessfullyConnectedFinch: PropTypes.bool,
    hasAnnouncedIcon: PropTypes.bool,
    hasConnectionAuthError: PropTypes.bool,
    showGroupCreation: PropTypes.bool,
    has360PayrollIntegration: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const queryParams = queryString.parse(window.location.search);
    const shouldShowLaunchPlan =
      queryParams.showLaunchPlan === "true" &&
      this.props.hasInvitedEmployees &&
      this.props.hasDeductionCode &&
      !this.props.hasLaunchedPlan;

    this.state = {
      initialFetching: true,
      showGroupForm: false,
      activeOnboardingStep: 1,
      showAddUsersCard: !shouldShowLaunchPlan,
    };
  }

  _getActiveStep = () => {
    const EMPTY = 1;
    if (
      this.props.hasGroup &&
      this.props.hasDeductionCode &&
      !this.props.hasAnnouncedIcon
    ) {
      return ANNOUNCED_ICON;
    } else if (
      this.props.hasDeductionCode &&
      this.props.hasAnnouncedIcon &&
      !this.props.hasInvitedEmployees
    ) {
      return INVITED_EMPLOYEES;
    } else if (
      this.props.hasInvitedEmployees &&
      this.props.hasDeductionCode &&
      !this.props.hasLaunchedPlan
    ) {
      return LAUNCHED_PLAN;
    } else {
      return EMPTY;
    }
  };

  _getContent = () => {
    if (this.props.showGroupCreation) {
      return <AddGroup onClose={this.props.groupCreationHide} />;
    }

    if (this.state.showRosterTable) {
      return (
        <RosterApprovalTable
          onClose={() => this.setState({ showRosterTable: false })}
        />
      );
    }

    if (this.state.showDeductionCode) {
      return (
        <AddDeductionCode
          onClose={() => this.setState({ showDeductionCode: false })}
          onSuccess={() => this.setState({ showDeductionCode: false })}
        />
      );
    }

    if (!this.state.showAddUsersCard) {
      switch (this._getActiveStep()) {
        case ANNOUNCED_ICON:
          return (
            <Announcement
              hasDeductionCode={this.props.hasDeductionCode}
              activeOnboardingStep={this._getActiveStep()}
              onCancel={() =>
                this.setState({
                  showAddUsersCard: true,
                })
              }
              onSuccess={() => {
                this.setState({
                  activeOnboardingStep: this._getActiveStep(),
                  showAddUsersCard: true,
                });
              }}
            />
          );

        case INVITED_EMPLOYEES:
          {
            if (this.props.hasStatePlan) {
              return (
                <AddEmployeeUploadFormToggle
                  hasDeductionCode={this.props.hasDeductionCode}
                  hasAnnouncedIcon={this.props.hasAnnouncedIcon}
                  activeOnboardingStep={this._getActiveStep()}
                  onCancel={() =>
                    this.setState({
                      showAddUsersCard: true,
                      activeOnboardingStep: false,
                    })
                  }
                  onUploadSuccess={() => {
                    this.setState({
                      activeOnboardingStep: this._getActiveStep(),
                      showAddUsersCard: true,
                    });
                  }}
                />
              );
            } else if (!this.props.hasStatePlan) {
              return (
                <AddEmployeeForm
                  hasDeductionCode={this.props.hasDeductionCode}
                  hasAnnouncedIcon={this.props.hasAnnouncedIcon}
                  activeOnboardingStep={this._getActiveStep()}
                  onCancel={() =>
                    this.setState({
                      showAddUsersCard: true,
                    })
                  }
                  onSuccess={() => {
                    this.setState({
                      activeOnboardingStep: this._getActiveStep(),
                      showAddUsersCard: true,
                    });
                  }}
                />
              );
            }
          }
          return;
        case LAUNCHED_PLAN:
          return (
            <LaunchPlan
              hasInvitedEmployees={
                this.props.hasInvitedEmployees && this.props.hasDeductionCode
              }
              activeOnboardingStep={this._getActiveStep()}
              onCancel={() =>
                this.setState({
                  showAddUsersCard: true,
                })
              }
              onSuccess={() => {
                this.setState({
                  activeOnboardingStep: this._getActiveStep(),
                  showAddUsersCard: true,
                });
              }}
            />
          );
      }
    }

    return (
      <>
        {this.state.showAddUsersCard && (
          <div className="accordian">
            <div className="accordian-intro">
              <IconHeader variant="cardHeader" headerText="Getting Started" />
              <IconSubheader subheader="Complete these steps..." />
            </div>
            <AddEmployerBankCard
              onSuccess={() => {
                this.props.updateProgressiveOnboarding(
                  this.props.client,
                  progressiveOnboardingEvents.AddBank
                );
              }}
            />
            <AddGroupCard hasBank={this.props.hasBank} />
            {this.props.hasEnabledFinch && (
              <FinchConnect
                showRosterTable={() => this.setState({ showRosterTable: true })}
              />
            )}
            {this.props.has360PayrollIntegration && <PayrollConnect />}
            {!this.props.has360PayrollIntegration && (
              <AddDeductionCodeCard
                hasGroup={this.props.hasGroup}
                hasInvitedEmployees={this.props.hasInvitedEmployees}
                showDeductionCode={() =>
                  this.setState({ showDeductionCode: true })
                }
              />
            )}
            <AddUsersCard
              hasDeductionCode={this.props.hasDeductionCode}
              onStepChange={this._setStep}
              activeStepNumber={this._getActiveStep()}
            />
          </div>
        )}
      </>
    );
  };

  _onStepChange = () => {
    this.setState({
      showAddUsersCard: false,
    });
  };

  _setStep = () => {
    if (
      this.props.hasGroup &&
      this.props.hasDeductionCode &&
      !this.props.hasAnnouncedIcon
    ) {
      this._onStepChange(ANNOUNCED_ICON);
    } else if (
      this.props.hasGroup &&
      this.props.hasDeductionCode &&
      this.props.hasAnnouncedIcon &&
      !this.props.hasInvitedEmployees
    ) {
      this._onStepChange(INVITED_EMPLOYEES);
    } else if (
      this.props.hasInvitedEmployees &&
      this.props.hasDeductionCode &&
      !this.props.hasLaunchedPlan
    ) {
      this._onStepChange(LAUNCHED_PLAN);
    }
  };

  render() {
    const content = this._getContent();

    return (
      <>
        <PageHeading
          title={"Welcome to Icon!"}
          subtext={
            "We’re here to help you get things rolling. Let's link your bank account, setup your plan, and invite additional users."
          }
        />
        <div className="main-content">{content}</div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hasBank: hasAddedBank(state),
    hasGroup: hasAddedGroups(state),
    hasDeductionCode: hasAddedDeductionCode(state),
    hasStatePlan: hasStatePlanSelector(state),
    hasInvitedAUser: hasInvitedAUserSelector(state),
    hasAnnouncedIcon: hasAnnouncedIcon(state),
    hasInvitedEmployees: hasInvitedEmployees(state),
    hasLaunchedPlan: hasLaunchedPlan(state),
    hasEnabledFinch: hasEnabledFinchFeature(state),
    hasSuccessfullyConnectedFinch: hasSuccessfullyConnectedFinch(state),
    hasConnectionAuthError: hasFinchConnectionAuthError(state),
    has360PayrollIntegration: hasEnabled360PayrollIntegration(state),
    showGroupCreation: state.groupCreation.groupCreation,
  };
};
const mapDispatchToProps = {
  getUserState,
  groupCreationShow,
  groupCreationHide,
  updateProgressiveOnboarding,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ProgressiveOnboardingDashboard));
