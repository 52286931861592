import React from "react";
import { connect } from "react-redux";
import { isEmpty, find, includes, get } from "lodash";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import moment from "moment";

import AcceptInvite from "pages/dashboards/individualDashboard/contributions/AcceptInvite";
import AddContribution from "./AddContribution";

import { flushErrors } from "actions/errorActions";
import { einVerificationId } from "statics/featureIds";
import EinVerification from "./EinVerification";
import { kellyPlanId } from "statics/enterprisePlanIds";

class AddEmployerModal extends React.PureComponent {
  static propTypes = {
    flushErrors: PropTypes.func,
    onClose: PropTypes.func,
    show: PropTypes.bool,
    isEditing: PropTypes.bool,
    isLinkRequest: PropTypes.bool,
    employerLink: PropTypes.object,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};
    if (isEmpty(prevState.employerLink) && !isEmpty(nextProps.employerLink)) {
      newState.employerLink = nextProps.employerLink;
    }

    return newState;
  }
  constructor() {
    super();

    this.state = {
      employerLink: null,
      hasSubmittedEin: false,
    };
  }

  _setEmployerLink = (employerLink) => {
    this.setState({ employerLink });
  };

  _getContributionAmount() {
    const employer = this.state.employerLink;
    if (!employer.contribution) {
      return 0;
    }

    const pendingContribution = find(
      employer.contribution.contributionChangeRequests,
      (req) => req.status === "CHANGED"
    );

    const contributionAmount = pendingContribution
      ? pendingContribution.userNewContributionAmount
      : employer.contribution.amount;

    return contributionAmount;
  }

  _onClose = () => {
    this.props.flushErrors();
    this.props.onClose();
  };

  _shouldShowContributionDisclaimer = () => {
    const endDate = moment(
      get(this.state.employerLink, "schedule.end", "")
    ).startOf("day");
    const now = moment().startOf("day");
    const cutOffDays = 5;

    return endDate.diff(now, "days") <= cutOffDays;
  };

  _modalContent = () => {
    if (this.state.employerLink) {
      const requireEin =
        isEmpty(this.state.employerLink.contribution) &&
        includes(
          get(this.state.employerLink, "company.enabledProductFeatures", []),
          einVerificationId
        );

      if (requireEin && !this.state.hasSubmittedEin) {
        const companyId = get(this.state.employerLink, "company.id");
        const companyPlanId = get(this.state.employerLink, "company.planId");

        const isKelly = kellyPlanId === companyPlanId;

        return (
          <EinVerification
            companyId={companyId}
            setHasSubmittedEin={() => this.setState({ hasSubmittedEin: true })}
            isKelly={isKelly}
          />
        );
      }

      const showContributionDisclaimer =
        this._shouldShowContributionDisclaimer();

      return (
        <AddContribution
          employerLink={this.state.employerLink}
          onClose={this._onClose}
          isEditing={this.props.isEditing}
          showContributionDisclaimer={showContributionDisclaimer}
          currentContributionAmount={this._getContributionAmount()}
        />
      );
    }

    return <AcceptInvite setEmployerLink={this._setEmployerLink} />;
  };

  render() {
    return (
      <Modal
        centered
        scrollable
        show={this.props.show}
        onHide={this._onClose}
        className="icon-modal"
      >
        {this._modalContent()}
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  flushErrors,
};

export default connect(null, mapDispatchToProps)(AddEmployerModal);
