import React from "react";
import { useFinchConnect } from "react-finch-connect";
import env from "@beam-australia/react-env";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { AiOutlineFileSync } from "react-icons/ai";

import { userType } from "statics/propTypes";
import {
  getFinchStatus,
  linkFinch,
  reconnectFinch,
} from "actions/employerActions";

import Accordian from "./Accordian";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import {
  awaitingFinchSync,
  awaitingInitialRosterProcessing,
  finchHasSynced,
  hasAddedGroups,
  hasSuccessfullyConnectedFinch,
  isValidatingFinchToken,
} from "store/selectors/employer";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import PromisePoller from "components/PromisePoller";
import { isProduction } from "utils/determineSite";

const onboardingSteps = [
  {
    index: 1,
    label: "Connect with your payroll provider.",
  },

  {
    index: 2,
    label: "Waiting for first payroll roster sync",
    isAsync: true,
  },
  {
    index: 3,
    label: "Approve initial roster sync",
  },
  {
    index: 4,
    label: "Validating approved roster",
    isAsync: true,
  },
];

const reAuthSteps = [
  {
    index: 1,
    label: "Reconnect with your payroll provider.",
  },
  {
    index: 2,
    label: "Validating authentication",
    isAsync: true,
  },
];

const FinchConnect = (props) => {
  const onSuccess = ({ code }) => {
    if (props.isReauthFlow) {
      return props.reconnectFinch(props.client, code).then(() => {
        if (!props.error) {
          toast.success("Successfully reconnected with payroll provider.");
        }
      });
    } else {
      return props.linkFinch(props.client, code);
    }
  };
  const onError = ({ errorMessage }) => {
    Sentry.captureMessage(`Finch connect client error: ${errorMessage}`, {
      level: "error",
    });
    toast.error("Something went wrong, please try again.");
  };

  const { open } = useFinchConnect({
    clientId: env("FINCH_CLIENT_ID"),
    products: [
      "company",
      "directory",
      "individual",
      "employment",
      "payment",
      "pay_statement",
      "ssn",
    ],
    sandbox: !isProduction(),
    manual: true,
    onSuccess,
    onError,
  });

  let activeStepNumber = 1;
  let onClick = open;
  let actionText = "Connect With Payroll";
  let shouldPollStatus = false;

  if (!props.isReauthFlow) {
    if (props.awaitingFinchSync) {
      activeStepNumber = 2;
      onClick = null;
      shouldPollStatus = true;
    } else if (props.finchHasSynced) {
      activeStepNumber = 3;
      onClick = props.showRosterTable;
      actionText = "Review Roster";
      shouldPollStatus = false;
    } else if (props.awaitingInitialRosterProcessing) {
      activeStepNumber = 4;
      onClick = null;
      shouldPollStatus = true;
    }
  } else {
    if (props.isValidatingFinchToken) {
      onClick = null;
      shouldPollStatus = true;
      activeStepNumber = 2;
    } else {
      shouldPollStatus = true;
      actionText = "Reconnect With Payroll";
    }
  }

  const description = props.isReauthFlow
    ? "Re-authenticate with payroll provider so we can continue to sync employee roster"
    : "Integrate with your payroll provider to sync employee roster";

  return (
    <>
      <Accordian
        onClick={onClick}
        icon={<AiOutlineFileSync color="#60A4BF" stroke="#60A4BF" size={16} />}
        title={"Integrate Payroll"}
        description={description}
        actionText={actionText}
        disabled={!props.hasAddedGroups}
        completedAction={props.hasSuccessfullyConnectedFinch}
        isFetching={props.isConnectingFinch}
        steps={props.isReauthFlow ? reAuthSteps : onboardingSteps}
        activeStepNumber={activeStepNumber}
      />
      {shouldPollStatus && !props.hasSuccessfullyConnectedFinch && (
        <PromisePoller
          interval={4000}
          minutesToPollFor={10}
          promiseToPoll={() => props.getFinchStatus(props.client)}
        />
      )}
    </>
  );
};

FinchConnect.propTypes = {
  push: PropTypes.func,
  client: PropTypes.object,
  hasAddedGroups: PropTypes.bool,
  hasSuccessfullyConnectedFinch: PropTypes.bool,
  isConnectingFinch: PropTypes.bool,
  finchHasSynced: PropTypes.bool,
  isReauthFlow: PropTypes.bool,
  isValidatingFinchToken: PropTypes.bool,
  awaitingInitialRosterProcessing: PropTypes.bool,
  awaitingFinchSync: PropTypes.bool,
  user: userType,
  linkFinch: PropTypes.func,
  showRosterTable: PropTypes.func,
  getFinchStatus: PropTypes.func,
  reconnectFinch: PropTypes.func,
  error: PropTypes.string,
};

const isLoadingSelector = createLoadingSelector([
  employerConstants.CONNECT_FINCH,
  employerConstants.RECONNECT_FINCH,
]);

const errorSelector = createErrorSelector(employerConstants.RECONNECT_FINCH);

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    user,
    isConnectingFinch: isLoadingSelector(state),
    hasSuccessfullyConnectedFinch: hasSuccessfullyConnectedFinch(state),
    finchHasSynced: finchHasSynced(state),
    isValidatingToken: isValidatingFinchToken(state),
    error: errorSelector(state),
    awaitingFinchSync: awaitingFinchSync(state),
    hasAddedGroups: hasAddedGroups(state),
    awaitingInitialRosterProcessing: awaitingInitialRosterProcessing(state),
  };
};

const mapDispatchToProps = {
  push,
  linkFinch,
  getFinchStatus,
  reconnectFinch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(FinchConnect));
