import { contributionConstants } from "actions/types";

const initialState = {
  perPayPeriodMax: null,
  dailyMax: null,
  recommendations: {},
  recurringContributions: [],
  contributionEstimates: {},
};

export function contribution(state = initialState, action) {
  switch (action.type) {
    case contributionConstants.GET_CONTRIBUTION_RECOMMENDATIONS_SUCCESS: {
      const {
        dailyMax,
        perPayPeriodMax,
        weeklyMax,
        monthlyMax,
        everyOtherWeekMax,
        ...restOfRecs
      } = action.data;
      return {
        ...state,
        dailyMax,
        perPayPeriodMax,
        weeklyMax,
        monthlyMax,
        everyOtherWeekMax,
        recommendations: restOfRecs,
      };
    }

    case contributionConstants.GET_RECURRING_CONTRIBUTIONS_SUCCESS: {
      return {
        ...state,
        recurringContributions: action.data,
      };
    }
    case contributionConstants.GET_CONTRIBUTION_ESTIMATES_SUCCESS: {
      const {
        currentMonthWithPendingAmount,
        currentYearWithPendingAmount,
        currentMonthAmount,
        currentYearAmount,
      } = action.data;

      // if there is pending amount use that for the current month and year otherwise just use current month/year
      return {
        ...state,
        contributionEstimates: {
          ...action.data,
          currentMonthAmount: currentMonthWithPendingAmount
            ? currentMonthWithPendingAmount
            : currentMonthAmount,
          currentYearAmount: currentYearWithPendingAmount
            ? currentYearWithPendingAmount
            : currentYearAmount,
        },
      };
    }
    default:
      return state;
  }
}
