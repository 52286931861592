import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import "./ProfileInfoCard.scss";
import { FiHome, FiPhone } from "react-icons/fi";

function ProfileInfoCard({ companyInfo }) {
  return (
    <div className="profile-card-containter">
      <Row>
        <Col>
          <h3 className="first-last-name">{companyInfo.name}</h3>
        </Col>
      </Row>
      <div className="icon-info-sec">
        <Row>
          <div>
            <FiHome
              size={"24px"}
              fill={"#FFFFFF"}
              color={"#FFFFFF"}
              stroke={"#808080"}
              strokeWidth={"1.5"}
            />
          </div>
          <Col xs={12} md={8} className="address-column">
            <p className="address">Company Address</p>
            <p>
              {companyInfo.address1} {companyInfo.address2}
            </p>
            <p>
              {companyInfo.city}, {companyInfo.state} {companyInfo.postalCode}
            </p>
          </Col>
        </Row>
        <Row>
          <div>
            <FiPhone
              size={"24px"}
              fill={"#FFFFFF"}
              color={"#FFFFFF"}
              stroke={"#808080"}
              strokeWidth={"1.5"}
            />
          </div>
          <Col xs={12} md={8} className="phone-column">
            <p className="phone">Phone Number</p>
            <p>
              {companyInfo.phone}{" "}
              {companyInfo.phoneExt && `ext: ${companyInfo.phoneExt}`}
            </p>
            {companyInfo.fax && <p>{companyInfo.fax}</p>}
          </Col>
        </Row>
      </div>
    </div>
  );
}

ProfileInfoCard.propTypes = {
  companyInfo: PropTypes.object,
};

ProfileInfoCard.defaultProps = {
  companyInfo: {},
};

export default ProfileInfoCard;
