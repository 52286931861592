import React from "react";
import PropTypes from "prop-types";
import { Col, Form } from "react-bootstrap";
import FileUploader from "components/FileUploader";

import { ID_DOCUMENT } from "statics/docTypes";
import Button from "components/Button";
import { intersection, map, noop, size } from "lodash";
import { docTypes } from "pages/signUp/individual/IndividualDocUploader";

const acceptableNameChangeDocs = [
  docTypes.DRIVERS_LICENSE.name,
  docTypes.DRIVERS_PERMIT.name,
  docTypes.STATE_ID_CARD.name,
  docTypes.PASSPORT.name,
  docTypes.PASSPORT_CARD.name,
  docTypes.SSN_CARD.name,
  docTypes.MILITARY_ID.name,
  docTypes.MARRIAGE_CERTIFICATE.name,
  docTypes.DIVORCE_DECREE.name,
  docTypes.NAME_CHANGE_DOC.name,
  docTypes.TAX_W2.name,
];

const acceptableAddressChangeDocs = [
  docTypes.DRIVERS_LICENSE.name,
  docTypes.DRIVERS_PERMIT.name,
  docTypes.STATE_ID_CARD.name,
  docTypes.MILITARY_ID.name,
  docTypes.UTILITY_BILL.name,
  docTypes.LEASE.name,
  docTypes.MORTGAGE_AGREEMENT.name,
  docTypes.NYC_ID_CARD.name,
  docTypes.TUITION_STATEMENT.name,
];

const sharedDocs = intersection(
  acceptableNameChangeDocs,
  acceptableAddressChangeDocs
);

export default class UploadDocumentation extends React.Component {
  static propTypes = {
    idDocType: PropTypes.string,
    onSuccess: PropTypes.func,
    isFrontUploader: PropTypes.bool,
    nameWasChanged: PropTypes.bool,
    addressWasChanged: PropTypes.bool,
    isBackUploader: PropTypes.bool,
    documents: PropTypes.array,
    goToNamedStep: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      selectedDocType: "",
    };
  }

  render() {
    const successMessage = "Your file was successfully uploaded.";
    let neededDocs;

    if (this.props.nameWasChanged && this.props.addressWasChanged) {
      neededDocs = sharedDocs;
    } else if (this.props.nameWasChanged) {
      neededDocs = acceptableNameChangeDocs;
    } else if (this.props.addressWasChanged) {
      neededDocs = acceptableAddressChangeDocs;
    }

    const buttonDisabled =
      !this.state.selectedDocType ||
      size(this.props.documents) < docTypes[this.state.selectedDocType].numDocs;

    return (
      <article className="col-form" style={{ paddingBottom: 0 }}>
        <Col
          as={Col}
          sm={8}
          style={{ paddingLeft: 0, paddingTop: 10, marginBottom: 12 }}
        >
          <article>
            <Form.Label>Document Requirements</Form.Label>
            <div className="processing-steps">
              <ul className="steps">
                <li>
                  Clear photos and PDFs are fine; we cannot accept screenshots
                  or scans.
                </li>
                <li>
                  All documents must be current. IDs cannot be expired, and
                  monthly bills or statements should have been issued within the
                  past 60 days.
                </li>
                <li>
                  We need to see all four corners of the document, and the
                  information we need to verify must be clearly visible.
                </li>
                <li>
                  If the document has a signature requirement, then it must be
                  hand-signed. We cannot accept electronic signatures (with the
                  exception of leases, where electronic signatures are
                  acceptable).
                </li>
                <li>
                  Government-issued documents (such as a state-issued ID or
                  passports) must be in color. We can accept black-and-white
                  photos or PDFs of other documents, such as utility bills.
                </li>
              </ul>
            </div>
          </article>
        </Col>
        <Form.Row>
          <Form.Group
            as={Col}
            sm={6}
            controlId="formBasicSize"
            style={{ paddingLeft: 0, paddingBottom: 20 }}
          >
            <Form.Control
              as="select"
              name="documentType"
              defaultValue={""}
              value={this.state.selectedDocType}
              onChange={(e) =>
                this.setState({ selectedDocType: e.target.value })
              }
            >
              <option value="" disabled>
                Document Type
              </option>
              {map(neededDocs, (doc) => {
                return <option value={doc}>{docTypes[doc].description}</option>;
              })}
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          {this.state.selectedDocType && (
            <Form.Group as={Col} md={6} className="upload-card">
              <Form.Label className={"document-side"}>
                Front of Document:
              </Form.Label>
              <FileUploader
                fileLimit={1}
                docType={ID_DOCUMENT}
                subDocType={this.state.selectedDocType}
                onSuccess={noop}
                requireSubDocType={true}
                fileUploadedMsg={successMessage}
                hideUploadedFiles={true}
              />
            </Form.Group>
          )}
          {this.state.selectedDocType &&
            docTypes[this.state.selectedDocType].numDocs === 2 && (
              <Form.Group as={Col} md={6} className="upload-card">
                <Form.Label className={"document-side"}>
                  Back of Document:
                </Form.Label>
                <FileUploader
                  fileLimit={1}
                  docType={ID_DOCUMENT}
                  subDocType={this.state.selectedDocType}
                  onSuccess={noop}
                  requireSubDocType={true}
                  fileUploadedMsg={successMessage}
                  hideUploadedFiles={true}
                />
              </Form.Group>
            )}
        </Form.Row>
        <Form.Row>
          <div className="btn-row">
            <Button
              size="sm"
              className="cancel-button"
              type="button"
              color="cancel"
              name="cancel"
              btnLabel="Go Back"
              onClick={() => {
                this.props.goToNamedStep("updateInfo");
              }}
            />
            <Button
              size="sm"
              withArrow={true}
              disabled={buttonDisabled}
              name="submit"
              btnLabel="Submit"
              onClick={this.props.onSubmit}
            />
          </div>
        </Form.Row>
      </article>
    );
  }
}
