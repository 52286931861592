import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Button from "components/Button";
import EmployerTermsAgreement from "pages/signUp/employer/EmployerTermsAgreement";
import EmployerTermsOrder from "pages/signUp/employer/EmployerTermsOrder";
import EmployerPartnerProgramAgreement from "./employer/EmployerPartnerProgramAgreement";
import IconTerms from "../../components/terms/IconTerms";
import ApexTerms from "../../components/terms/ApexTerms";
import IRATerms from "../../components/terms/IRATerms";
import EmployerIsolvedTermsOrder from "pages/signUp/employer/EmployerIsolvedTermsOrder";
import ApexTermsRoth from "components/terms/ApexTermsRoth";
import RolloverTerms from "components/terms/RolloverTerms";
import EmployerTermsOrderV2 from "pages/signUp/employer/EmployerTermsOrderV2";
import EvolveTerms from "components/terms/EvolveTerms";

const TermsModal = ({ show, onClose, type, termsInfo }) => {
  var content;
  var title;
  switch (type) {
    case "termsOfService":
      content = <IconTerms />;
      title = (
        <div>
          ICON FINANCIAL SERVICES LLC DISCRETIONARY INVESTMENT ADVISORY CONTRACT
        </div>
      );
      break;
    case "accountAgreement":
      content = <ApexTerms info={termsInfo} />;
      title = (
        <div className="agreements-title">
          <p className="agreements-modal-header">
            Traditional, Rollover, Beneficiary Traditional, or SEP IRA Agreement
          </p>
          <p className="agreements-modal-subheader">
            Select exactly one type of IRA: Traditional IRA
          </p>
        </div>
      );
      break;
    case "accountAgreementRoth":
      content = <ApexTermsRoth info={termsInfo} />;
      title = (
        <div className="agreements-title">
          <p className="agreements-modal-header">Roth IRA Account Agreement</p>
          <p className="agreements-modal-subheader">
            Select exactly one type of IRA: Roth IRA
          </p>
        </div>
      );
      break;
    case "rolloverTerms":
      content = <RolloverTerms info={termsInfo} />;
      title = (
        <div className="agreements-title">
          <p className="agreements-modal-header">Rollover Account Agreement</p>
          <p className="agreements-modal-subheader">
            Please read important information about your rollover.
          </p>
        </div>
      );
      break;
    case "evolveAgreement":
      content = <EvolveTerms info={termsInfo} />;
      title = (
        <div className="agreements-title">
          <p className="agreements-modal-header">EVOLVE BANK & TRUST</p>
          <p className="agreements-modal-subheader">
            CUSTOMER ACCOUNT AGREEMENT
          </p>
        </div>
      );
      break;
    case "employerAgreement":
      content = <EmployerTermsAgreement info={termsInfo} />;
      title = <div>PLATFORM SERVICES AGREEMENT</div>;
      break;
    case "employerOrder":
      content = <EmployerTermsOrder info={termsInfo} />;
      title = <div>EXHIBIT A - ORDER FORM</div>;
      break;
    case "employerOrderV2":
      content = <EmployerTermsOrderV2 info={termsInfo} />;
      title = <div>EXHIBIT A - ORDER FORM</div>;
      break;
    case "isolvedOrder":
      content = <EmployerIsolvedTermsOrder info={termsInfo} />;
      title = <div>EXHIBIT A - ORDER FORM</div>;
      break;
    case "employerPartnerProgram":
      content = <EmployerPartnerProgramAgreement info={termsInfo} />;
      title = <div>EMPLOYER REFERRAL PROGRAM AGREEMENT</div>;
      break;
    case "iraAgreement":
      content = <IRATerms info={termsInfo} />;
      title = <div>New Account Application & Agreement</div>;
      break;
    default:
      content = "";
      break;
  }

  return (
    <Modal
      centered
      scrollable
      show={show}
      onHide={onClose}
      size="lg"
      className="terms-modal"
    >
      <Modal.Header style={{ alignItems: "center" }}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button name="cancel" btnLabel="Close" onClick={onClose} />
      </Modal.Footer>
    </Modal>
  );
};

TermsModal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  termsInfo: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};

export default TermsModal;
