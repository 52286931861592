import { transactionConstants } from "./types";
import {
  getAllTransactions,
  cancelTransaction,
} from "services/transactionService";
import ActionCreator from "utils/actionHandler";

export const getTransactions = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      transactionConstants.GET_TRANSACTIONS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getAllTransactions(client, accountId)
    );
  };
};

export const cancelPendingTransaction = (client, accountId, transactionId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      transactionConstants.CANCEL_TRANSACTION
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      cancelTransaction(client, accountId, transactionId)
    );
  };
};
