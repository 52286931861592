import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";

import TransactionsTable from "./TransactionsTable";
import "./Transactions.scss";
import IconSpinner from "components/IconSpinner";

import { getTransactions } from "actions/transactionActions";
import { transactionType } from "statics/propTypes";
import { transactionConstants } from "actions/types";
import { createErrorSelector } from "store/selectors";
import { getIraAccountIdSelector } from "store/selectors/user";
import { getAllAccounts } from "actions/accountActions";
class Transactions extends React.Component {
  static propTypes = {
    getTransactions: PropTypes.func,
    getAllAccounts: PropTypes.func,
    transactions: PropTypes.arrayOf(transactionType),
    client: PropTypes.object,
    iraAccountId: PropTypes.string,
    error: PropTypes.string,
  };

  constructor() {
    super();

    this.state = { initialFetching: true };
  }

  componentDidMount() {
    this.props.getAllAccounts(this.props.client).then(() => {
      this.props
        .getTransactions(this.props.client, this.props.iraAccountId)
        .then(() => {
          this.setState({ initialFetching: false });
        });
    });
  }

  render() {
    const isFetching = this.state.initialFetching;
    return (
      <div className="transactions-mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <div>
              <h1 className="page-title">Transactions</h1>
              <p className="page-subtext">
                View transaction history or modify pending transactions.
              </p>
              <div className="transaction-card">
                {isFetching && <IconSpinner centered />}
                {!isFetching && <TransactionsTable />}
              </div>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const actions = [transactionConstants.GET_TRANSACTIONS];
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    iraAccountId: getIraAccountIdSelector(state),
    transactions: state.transactions.transactions,
    error: errorSelector(state),
  };
};
const mapDispatchToProps = {
  push,
  getTransactions,
  getAllAccounts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Transactions));
