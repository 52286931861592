import gql from "graphql-tag";
// get users financial accounts
function getUserAccounts(client) {
  return new Promise((resolve, reject) => {
    const GET_ALL_ACCOUNTS = gql`
      query userAccountList {
        userAccountList {
          bankAccounts {
            id
            bankName
            bankAlias
            verificationStatus
            accountType
            accountSubtype
            accountId
            correlationId
            state
            recurringContribution {
              frequency
              anchorPayDate
              amount
              dayOne
              dayTwo
              sourceBankAccountId
              targetIraAccountId
              nextScheduledDate
              scheduleId
            }
          }
          iraAccounts {
            id
            routingNumber
            accountNumber
            name
            accountType
            isRollover
            state
            createdAt
            active
            lifecycleState
            isProcessingRollover
            balances {
              withdrawalBalance
              totalAccountBalance
              cashBalance
              principal
              totalPendingContributions
              totalPendingDistributions
            }
          }
        }
      }
    `;
    const result = client.query({
      query: GET_ALL_ACCOUNTS,
      variables: {},
      fetchPolicy: "network-only",
    });
    result.then(
      (data) => {
        resolve(data.data.userAccountList);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getIraAccountBalanceRequest(client, iraAccountId) {
  return new Promise((resolve, reject) => {
    const query = gql`
      query userAccountList {
        userAccountList {
          iraAccounts {
            id
            balances {
              withdrawalBalance
              totalAccountBalance
              cashBalance
              principal
              totalPendingContributions
              totalPendingDistributions
            }
          }
        }
      }
    `;
    const result = client.query({
      query,
      variables: { iraAccountId },
      fetchPolicy: "network-only",
    });
    result.then(
      (data) => {
        resolve(data.data.userAccountList);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function setAccountSession(client, accountId) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation setAccountSession($accountId: ID!) {
        setAccountSession(accountId: $accountId)
      }
    `;

    const result = client.mutate({
      mutation: request,
      variables: {
        accountId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.setAccountSession);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export const accountService = {
  getUserAccounts,
  getIraAccountBalanceRequest,
  setAccountSession,
};
