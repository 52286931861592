// code contract - contribution selector represents either per paycehck/daily or per recurring schedule/daily, we should just have this be a display based component and receive the above as props.
import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { Col, Row } from "react-bootstrap";
import {
  camelCase,
  filter,
  findIndex,
  get,
  isEmpty,
  isNil,
  lowerCase,
  max,
  min,
} from "lodash";
import classnames from "classnames";
import moment from "moment";

import Button from "components/Button";
import Alert from "components/Alert";
import SelectContributionBox from "./SelectContributionBox";

import { formatAmount, formatCurrency } from "utils/number";
import {
  DAILY,
  MONTHLY,
  payrollPeriodsToEnglishMapping,
  PER_PAY_PERIOD,
} from "utils/timeHelper";
import { recommendedContributionType } from "statics/propTypes";
import { getDailyToRecurringAmount } from "services/contributionService";
import "./Contributions.scss";

const Modifier = ({ isSelected, name, label, onSelect }) => {
  return (
    <div
      className={classnames("modifier", {
        selected: isSelected,
      })}
      onClick={() => onSelect(name)}
    >
      {label || name}
    </div>
  );
};

Modifier.propTypes = {
  onSelect: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
};

function getDefaultOption(currentAmount, recommendations) {
  const isRecommended =
    currentAmount === recommendations.recommended || !currentAmount;

  if (isRecommended) {
    return "recommended";
  }

  const matchingSubOptionIdx = findIndex(
    recommendations.other,
    (other) => other === currentAmount
  );

  if (matchingSubOptionIdx >= 0) {
    return `other.${matchingSubOptionIdx}`;
  }

  return null;
}

const PAYCHECK_LIMITS = {
  MONTHLY: { max: 1000, min: 25 },
  TWICE_PER_MONTH: { max: 500, min: 13 },
  EVERY_OTHER_WEEK: { max: 500, min: 13 },
  WEEKLY: { max: 250, min: 6 },
  DAILY: { max: 30, min: 1 },
};

class ContributionSelector extends React.PureComponent {
  static propTypes = {
    isSubmitting: PropTypes.bool,
    schedule: PropTypes.shape({
      frequency: PropTypes.string,
      dayOne: PropTypes.string,
      anchorPayDate: PropTypes.string,
    }),
    error: PropTypes.string,
    bankAccountNumber: PropTypes.string,
    dailyMax: PropTypes.number,
    weeklyMax: PropTypes.number,
    monthlyMax: PropTypes.number,
    everyOtherWeekMax: PropTypes.number,
    perPayPeriodMax: PropTypes.number,
    currentContributionAmount: PropTypes.number,
    dailyRecommendations: recommendedContributionType,
    weeklyRecommendations: recommendedContributionType,
    everyOtherWeekRecommendations: recommendedContributionType,
    monthlyRecommendations: recommendedContributionType,
    perPayPeriodRecommendations: recommendedContributionType,
    onSubmit: PropTypes.func,
    client: PropTypes.object,
    children: PropTypes.node,
    isPaycheck: PropTypes.bool,
    isEditing: PropTypes.bool,
  };

  static defaultProps = {
    isPaycheck: false,
  };

  constructor(props) {
    super(props);

    const selectedOption = getDefaultOption(
      props.currentContributionAmount,
      this.props.isPaycheck
        ? props.perPayPeriodRecommendations
        : props[`${camelCase(this.props.schedule.frequency)}Recommendations`]
    );

    const defaultModifier = selectedOption
      ? DAILY
      : this.props.schedule.frequency;

    this.state = {
      selectedOption,
      selectedCustom: !selectedOption,
      showCustomBox: !selectedOption,
      modifier: props.isPaycheck ? PER_PAY_PERIOD : defaultModifier,
      // Uses an empty string to clear the input between modifier screens
      customAmount: !selectedOption ? this.props.currentContributionAmount : "",
      customAmountError: "",
      isFetchingCustomAmount: false,
      calculatedCustomAmount: null,
      showConfirmation: false,
    };
  }

  _getAllRecommendations = () => {
    const isDaily = this.state.modifier === DAILY;

    const frequencyString = isDaily
      ? "daily"
      : camelCase(this.props.schedule.frequency);

    const recs =
      this.props.isPaycheck && !isDaily
        ? this.props.perPayPeriodRecommendations
        : this.props[`${frequencyString}Recommendations`];

    const contributionLimits = this._getContributionLimits();
    const recsWithinLimits = filter(
      [recs.recommended, ...recs.other],
      (rec) => {
        return rec >= contributionLimits.min;
      }
    );

    return recsWithinLimits;
  };

  _getCustomPositionToRecommended = () => {
    const recs = this._getAllRecommendations();
    const minRec = min(recs);
    const maxRec = max(recs);

    const { customAmount } = this.state;
    if (customAmount < minRec) return "less_than_recommended";
    if (customAmount > maxRec) return "greater_than_recommended";
    return "within_recommended";
  };

  _handleSubmit = () => {
    const isDaily = this.state.modifier === DAILY;
    const baseTrackingObject = {
      isDaily,
      payrollFrequency: this.props.schedule.frequency,
    };

    if (this.state.selectedCustom) {
      const contributionLimits = this._getContributionLimits();
      const customIsValid =
        this.state.customAmount >= contributionLimits.min &&
        !this.state.customAmountError;

      if (customIsValid) {
        window.analytics.track("User selected custom deduction amount", {
          ...baseTrackingObject,
          positionComparedToRecommended: this._getCustomPositionToRecommended(),
          contributionAmount: +this.state.customAmount,
        });
        this.props.onSubmit(+this.state.customAmount, isDaily);
        return;
      } else {
        return;
      }
    }

    const recs = this.props.isPaycheck
      ? this.props.perPayPeriodRecommendations
      : this.props[
          `${camelCase(this.props.schedule.frequency)}Recommendations`
        ];
    const contributionAmount = +get(recs, this.state.selectedOption);

    window.analytics.track("User selected preset deduction amount", {
      ...baseTrackingObject,
      selectedOption: this.state.selectedOption,
      contributionAmount,
    });

    this.props.onSubmit(contributionAmount, false);
  };

  _updateModifier = (modifier) => {
    this.setState({ modifier, customAmount: "", customAmountError: "" });
  };

  _getRecommendedContributions = () => {
    if (this.state.modifier === DAILY) {
      return this.props.dailyRecommendations;
    }
    if (this.props.isPaycheck) {
      return this.props["perPayPeriodRecommendations"];
    }
    return this.props[
      `${camelCase(this.props.schedule.frequency)}Recommendations`
    ];
  };

  _getModifier = () => {
    if (this.state.modifier === DAILY) {
      return this.state.modifier;
    }
    if (this.props.isPaycheck) {
      return PER_PAY_PERIOD;
    }
    return this.props.schedule.frequency;
  };

  _getSelectBoxes = () => {
    const recommendedContributions = this._getRecommendedContributions();
    const contributionLimits = this._getContributionLimits();

    const selectedRec = get(
      recommendedContributions,
      this.state.selectedOption,
      null
    );

    const modifier = this._getModifier();

    const preferredSelectBox = (
      <SelectContributionBox
        key="preferred"
        amount={+recommendedContributions.recommended}
        isRecommended={true}
        showAsMax={recommendedContributions.isMaxContributionOption}
        modifier={modifier}
        isSelected={
          !this.state.selectedCustom &&
          selectedRec === recommendedContributions.recommended
        }
        onSelect={() => this._setSelectedContribution("recommended")}
      />
    );

    const otherOptions = recommendedContributions.other
      .filter((val) => val >= contributionLimits.min)
      .map((val, idx) => (
        <SelectContributionBox
          key={val}
          amount={+val}
          modifier={modifier}
          isSelected={!this.state.selectedCustom && selectedRec === val}
          onSelect={() => this._setSelectedContribution(`other.${idx}`)}
        />
      ));

    const customInputBox = this.state.showCustomBox ? (
      <SelectContributionBox
        key="custom"
        amount={+this.state.customAmount}
        customAmountError={this.state.customAmountError}
        modifier={modifier}
        isCustomAmount={true}
        isSelected={this.state.selectedCustom}
        onSelect={() => this.setState({ selectedCustom: true })}
        setCustomAmount={this._setCustomAmount}
        contributionLimits={contributionLimits}
      />
    ) : null;

    return [preferredSelectBox, ...otherOptions, customInputBox];
  };

  _getContributionLimits = () => {
    const scheduleLimit = PAYCHECK_LIMITS[this.props.schedule.frequency];
    let min;

    if (this.props.isEditing) {
      min = 0;
    } else {
      min =
        this.state.modifier === DAILY
          ? PAYCHECK_LIMITS.DAILY.min
          : scheduleLimit.min;
    }

    return {
      min,
      max:
        this.state.modifier === DAILY
          ? PAYCHECK_LIMITS.DAILY.max
          : scheduleLimit.max,
    };
  };

  _setCustomAmount = (amt) => {
    let customAmountError = "";
    const contributionLimits = this._getContributionLimits();

    if (amt < contributionLimits.min) {
      customAmountError = `Custom amount must be greater than or equal to ${formatCurrency(
        contributionLimits.min
      )}`;
    } else if (amt > contributionLimits.max) {
      customAmountError = `Custom amount must be less than or equal to ${formatCurrency(
        contributionLimits.max
      )}`;
    }
    this.setState({
      customAmount: formatAmount(amt),
      customAmountError,
    });
  };

  _setSelectedContribution = (selectedOption) => {
    this.setState({
      selectedCustom: false,
      selectedOption,
      customAmount: "",
      customAmountError: "",
    });
  };

  _buildConfirmationSentence = () => {
    const { frequency, anchorPayDate } = this.props.schedule;
    let startSentence;

    let amount;

    if (this.state.selectedCustom) {
      amount =
        this.state.modifier === DAILY
          ? this.state.calculatedCustomAmount
          : this.state.customAmount;
    } else {
      amount = get(
        this.props[`${camelCase(frequency)}Recommendations`],
        this.state.selectedOption
      );
    }

    let firstPaymentDate = anchorPayDate;
    if (frequency === MONTHLY) {
      const dayOne =
        this.props.schedule.dayOne === "End of Month"
          ? 31
          : this.props.schedule.dayOne;

      /* The monthly scheduling logic takes a bit to calculate, basically we ask the user for the day of month they want to process
         so to figure out next processing date we have to figure out if that day already passed this month if not just use current month as processing date
         otherwise we have to figure out which date it will happen next month. The logic also accounts for variable end of month dates using moment in a way that 
         ensures it will always default to last day of month if day number is outside of that months range.
      */
      let anchorDate = moment(anchorPayDate);
      // this format is important because it ensures that if we are in February that it will convert Feb 31 into Feb 28 vs March 3rd
      const currentMonthProcessingDate = moment()
        .date(dayOne)
        .month(anchorDate.month())
        .year(anchorDate.year());
      const willProcessThisMonth = anchorDate.isBefore(
        currentMonthProcessingDate.startOf("day")
      );

      let nextProcessingDate = currentMonthProcessingDate;

      if (!willProcessThisMonth) {
        const nextMonth = currentMonthProcessingDate.add(1, "months");
        nextProcessingDate = moment()
          .date(dayOne)
          .month(nextMonth.month())
          .year(nextMonth.year());
      }

      firstPaymentDate = nextProcessingDate.format("YYYY-MM-DD");
    }
    startSentence = `starting ${firstPaymentDate}`;
    return (
      <p className="modal-text">
        By clicking continue, I authorize Icon Financial Services to debit my
        account ending in {this.props.bankAccountNumber} the amount of{" "}
        {formatCurrency(amount)}{" "}
        {lowerCase(payrollPeriodsToEnglishMapping[frequency])} {startSentence}.
        Scheduled payments may be cancelled up to 1 day in advance.
      </p>
    );
  };

  _getConfirmationMessage = () => {
    return (
      <div>
        <p>Confirm recurring contribution</p>
        {this._buildConfirmationSentence()}
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        <div className="submit-row">
          <Button
            btnLabel="go back"
            color="cancel"
            name="cancel"
            onClick={() => this.setState({ showConfirmation: false })}
          />
          <Button
            btnLabel="submit"
            name="submit"
            withArrow
            loading={this.props.isSubmitting}
            onClick={this._handleSubmit}
          />
        </div>
      </div>
    );
  };

  _getRecurringAmount = async () => {
    this.setState({ isFetchingCustomAmount: true });
    const customAmount = await getDailyToRecurringAmount(this.props.client, {
      frequency: this.props.schedule.frequency,
      amount: +this.state.customAmount,
    });
    this.setState({
      isFetchingCustomAmount: false,
      calculatedCustomAmount: customAmount,
    });

    this.setState({ showConfirmation: true });
  };

  _getContent = () => {
    if (this.state.showConfirmation) {
      return this._getConfirmationMessage();
    }

    const name = this.props.isPaycheck
      ? PER_PAY_PERIOD
      : this.props.schedule.frequency;
    const label = this.props.isPaycheck
      ? "PER PAY PERIOD"
      : payrollPeriodsToEnglishMapping[this.props.schedule.frequency];
    const isSelected =
      this.state.modifier === this.props.schedule.frequency ||
      this.state.modifier === PER_PAY_PERIOD;
    const frequencyModifier = (
      <Modifier
        name={name}
        label={label}
        onSelect={this._updateModifier}
        isSelected={isSelected}
      />
    );

    let handleSubmit;
    const isDailyCustom =
      this.state.modifier === DAILY && this.state.selectedCustom;

    if (this.props.isPaycheck) {
      handleSubmit = this._handleSubmit;
    } else if (isDailyCustom) {
      handleSubmit = this._getRecurringAmount;
    } else {
      handleSubmit = () => this.setState({ showConfirmation: true });
    }

    return (
      <>
        {this.props.children}
        <div>
          <div className="modifier-picker">
            <Modifier
              name={DAILY}
              onSelect={this._updateModifier}
              isSelected={this.state.modifier === DAILY}
            />

            {frequencyModifier}
          </div>
        </div>
        <div className="select-contributions-container">
          <Row>
            <Col> {this._getSelectBoxes()}</Col>
          </Row>
        </div>

        <div className="submit-row">
          {this.props.error && <Alert type="error" msg={this.props.error} />}

          {!this.state.showCustomBox && (
            <span
              onClick={() =>
                this.setState({ showCustomBox: true, selectedCustom: true })
              }
              className="icon-link"
            >
              Enter Custom Amount
            </span>
          )}
          <Button
            onClick={handleSubmit}
            btnLabel="Submit"
            name="submit"
            withArrow={true}
            loading={
              this.props.isSubmitting || this.state.isFetchingCustomAmount
            }
            disabled={
              this.state.selectedCustom &&
              (!isEmpty(this.state.customAmountError) ||
                isNil(this.state.customAmount) ||
                this.state.customAmount === "")
            }
          />
        </div>
      </>
    );
  };

  render() {
    return (
      <div className="contribution-setup-wrapper">{this._getContent()}</div>
    );
  }
}

export default withApollo(ContributionSelector);
