import React from "react";
import PropTypes from "prop-types";
import { map, includes, filter, isEmpty, find } from "lodash";
import { Row, Col } from "react-bootstrap";
import { BsBriefcase } from "react-icons/bs";

import ContributionSource from "./ContributionSource";
import ActionCard from "components/ActionCard";
import AddEmployerModal from "./AddEmployerModal";

class Employers extends React.PureComponent {
  static propTypes = {
    linkedEmployers: PropTypes.arrayOf(PropTypes.shape({})),
    pendingEmployerLinks: PropTypes.arrayOf(PropTypes.shape({})),
  };

  constructor() {
    super();

    this.state = {
      showAddEmployerModal: false,
    };
  }
  _getPendingAndLinkedEmployers = () => {
    const linkedCompanies = map(this.props.linkedEmployers, "company.id");
    const pendingEmployerRequests = filter(
      this.props.pendingEmployerLinks,
      (link) => !includes(linkedCompanies, link.company.id)
    );

    return [...pendingEmployerRequests, ...this.props.linkedEmployers];
  };

  _getEmployerRows = () => {
    const employers = this._getPendingAndLinkedEmployers();

    return map(employers, (employer) => {
      const contributionSource = {
        name: employer.company.name,
        planId: `${employer.company.planId}-${employer.group.companyGroupIndex}`,
        id: employer.id,
        isLinkRequest: !!employer.status, // only link requests have a status
      };

      let contributionInfo;

      if (!employer.contribution) {
        contributionInfo = {};
      } else {
        const pendingContribution = find(
          employer.contribution.contributionChangeRequests,
          (req) => req.status === "CHANGED"
        );

        const contributionAmount = pendingContribution
          ? pendingContribution.userNewContributionAmount
          : employer.contribution.amount;

        contributionInfo = {
          schedule: "Per paycheck",
          end: employer.schedule.end,
          amount: contributionAmount,
        };
      }

      return (
        <Col md={12} key={employer.id}>
          <Row>
            <ContributionSource
              isEmployer={true}
              contributionSource={contributionSource}
              contributionInfo={contributionInfo}
              employerLink={employer}
            />
          </Row>
        </Col>
      );
    });
  };
  render() {
    const employerRows = this._getEmployerRows();
    const canAddEmployer = isEmpty(employerRows);

    return (
      <div className="employers-container">
        <h1 className="section-title">Employer Plan</h1>
        {this.state.showAddEmployerModal && (
          <AddEmployerModal
            show={this.state.showAddEmployerModal}
            onClose={() => this.setState({ showAddEmployerModal: false })}
          />
        )}

        {employerRows}
        {canAddEmployer && (
          <Col md={6}>
            <Row>
              <ActionCard
                onClick={() => this.setState({ showAddEmployerModal: true })}
                icon={<BsBriefcase size={12} color={"#FF5330"} />}
                title={"Link employer"}
                description={
                  "Link your employer account to set aside a portion of each paycheck"
                }
                actionText={"Link Employer Plan"}
              />
            </Row>
          </Col>
        )}
      </div>
    );
  }
}

export default Employers;
