import React from "react";
import StepWizard from "react-step-wizard";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { PropTypes } from "prop-types";
import { find } from "lodash";
import StepInfo from "components/StepInfo";
import GroupSelection from "components/groupMigrationWorkflow/GroupSelection";
import EmployeeSelection from "components/groupMigrationWorkflow/EmployeeSelection";
import Summary from "components/groupMigrationWorkflow/Summary";
import { migrateEmployees } from "actions/adminActions";
import { adminConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { toast } from "react-toastify";

const stepToLabels = {
  1: {
    subheader:
      "Choose which group you are migrating from and which group you want to migrate to",
  },
  2: {
    subheader: "Select which employees are being migrated to the new group",
  },
  3: {
    subheader: "Here's the summary.",
  },
};

const steps = [
  {
    index: 1,
    label: "Select groups for migration",
  },
  {
    index: 2,
    label: "Select employees",
  },
  {
    index: 3,
    label: "Submit",
  },
];

export class GroupMigrationWorkflow extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    getLinkedEmployees: PropTypes.func,
    groups: PropTypes.array,
    onCancel: PropTypes.func,
    migrateEmployees: PropTypes.func,
    push: PropTypes.func,
    companyId: PropTypes.string,
    error: PropTypes.string,
    onSuccess: PropTypes.func,
    isUpdating: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      sourceGroupId: "",
      targetGroupId: "",
      employeesForMigration: [],
      activeStep: "1",
    };
  }

  _onStepChange = ({ activeStep }) => {
    this.setState({ activeStep });
  };

  _setTargetGroupId = (targetGroupId) => {
    this.setState({ targetGroupId });
  };

  _setSourceGroupId = (sourceGroupId) => {
    this.setState({ sourceGroupId });
  };

  _setEmployeesForMigration = (employeesForMigration) => {
    this.setState({ employeesForMigration });
  };

  _submitGroupMigration = () => {
    const { sourceGroupId, targetGroupId, employeesForMigration } = this.state;
    this.props
      .migrateEmployees(this.props.client, {
        sourceGroupId,
        targetGroupId,
        employeesForMigration,
        companyId: this.props.companyId,
      })
      .then(({ data }) => {
        if (!this.props.error) {
          toast.success("Employees migrated successfully");
          this.props.onSuccess(data);
        } else {
          toast.error("Error migrating employees");
        }
      });
  };

  render() {
    const activeSectionLabels = stepToLabels[this.state.activeStep];

    const sourceGroup = find(this.props.groups, {
      id: this.state.sourceGroupId,
    });
    const targetGroup = find(this.props.groups, {
      id: this.state.targetGroupId,
    });
    return (
      <div className="mega-container" id="payroll-workflow">
        <section className="page-title-wrap">
          <article className="text-cell">
            <div className="subtext-and-calendar">
              <p className="page-subtext">{activeSectionLabels.subheader}</p>
            </div>
            <StepInfo steps={steps} activeStepNumber={this.state.activeStep} />
          </article>
        </section>
        <div className="main-content">
          <StepWizard
            onStepChange={this._onStepChange}
            isLazyMount={true}
            transitions={{
              enterRight: "",
              enterLeft: "",
              exitRight: "",
              exitLeft: "",
            }}
          >
            <GroupSelection
              groups={this.props.groups}
              onCancel={this.props.onCancel}
              stepName="selectGroups"
              setSourceGroupId={this._setSourceGroupId}
              setTargetGroupId={this._setTargetGroupId}
              sourceGroupId={this.state.sourceGroupId}
              targetGroupId={this.state.targetGroupId}
            />
            <EmployeeSelection
              sourceGroup={sourceGroup}
              stepName="employeeSelection"
              setEmployeesForMigration={this._setEmployeesForMigration}
              employeesForMigration={this.state.employeesForMigration}
            />
            <Summary
              stepName={"summary"}
              sourceGroup={sourceGroup}
              targetGroup={targetGroup}
              handleMigration={this._submitGroupMigration}
              isUpdating={this.props.isUpdating}
              employeesForMigration={this.state.employeesForMigration}
            />
          </StepWizard>
        </div>
      </div>
    );
  }
}

const actions = [adminConstants.ADMIN_MIGRATE_EMPLOYEES];

const isUpdatingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isUpdating: isUpdatingSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  migrateEmployees,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(GroupMigrationWorkflow));
