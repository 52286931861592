import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { startCase, lowerCase, size, truncate } from "lodash";
import { FiEdit3 } from "react-icons/fi";
import { connect } from "react-redux";
import { Form, Card } from "react-bootstrap";

import IconSpinner from "components/IconSpinner";
import { getEntityLimits, updateEntityLimit } from "actions/adminActions";
import IconTable from "components/IconTable";
import IconTableHeader from "components/IconTableHeader";
import SimpleModal from "components/SimpleModal";
import { createLoadingSelector } from "store/selectors";

import "./EntityLimits.scss";
import { adminConstants } from "../../../actions/types";
class EntityLimits extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    push: PropTypes.func,
    getEntityLimits: PropTypes.func,
    updateEntityLimit: PropTypes.func,
    isFetching: PropTypes.bool,

    entityType: PropTypes.oneOf(["EMPLOYER", "USER", "SYSTEM"]).isRequired,
    entityId: PropTypes.string,
  };

  static defaultProps = {
    entityType: "SYSTEM",
  };

  constructor() {
    super();

    this.state = {
      limits: [],
      showEditModal: false,
      editLimit: {},
    };
  }

  async componentDidMount() {
    this.loadLimits();
  }

  loadLimits = () => {
    this.props
      .getEntityLimits(
        this.props.client,
        this.props.entityType,
        this.props.entityId
      )
      .then(({ data }) => {
        return this.setState({
          isFetching: false,
          limits: data.map((limit) => ({
            ...limit,
            originalValue: limit.value,
            hasBeenModified: false,
            limitKey: limit.key,
          })),
        });
      });
  };

  _getTableMetadata = () => {
    return [
      {
        key: "key",
        label: "Limit",
      },
      {
        key: "value",
        label: "Value",
        format: (value) => {
          return truncate(value, { length: 60, separator: "..." });
        },
      },
      {
        label: "Action",
        customComponent: (props) => {
          return (
            <span
              key={props.key}
              className="action-icon"
              onClick={() => {
                this.setState({
                  showEditModal: true,
                  editLimit: props,
                });
              }}
            >
              <FiEdit3 size={18} stroke="#96AAC4" fill="white" />
            </span>
          );
        },
      },
    ];
  };

  submitLimitUpdate = () => {
    this.setState({ isUpdating: true });
    this.props
      .updateEntityLimit(this.props.client, {
        entityId: this.props.entityId,
        entityType: this.props.entityType,
        key: this.state.editLimit.limitKey,
        value: this.state.editLimit.value,
      })
      .then(() => {
        this.loadLimits();
      })
      .finally(() => {
        this.setState({
          isUpdating: false,
          showEditModal: false,
          editLimit: {},
        });
      });
  };

  render() {
    if (this.props.isFetching) {
      return <IconSpinner centered />;
    }

    return (
      <>
        <h1 className="page-title">
          {`${startCase(lowerCase(this.props.entityType))} Limits`}
        </h1>
        <SimpleModal
          show={this.state.showEditModal}
          onClose={() => this.setState({ showEditModal: false })}
          onSubmit={this.submitLimitUpdate}
          title="Update Limit"
          buttonText="Update"
          closeButtonText="Cancel"
          isXhrRequest={true}
          btnDisabled={false}
          isFetching={this.state.isUpdating}
        >
          <Form.Control
            autoFocus
            value={this.state.editLimit.value}
            onChange={(event) => {
              this.setState({
                editLimit: {
                  ...this.state.editLimit,
                  value: event.target.value,
                },
              });
            }}
          />
        </SimpleModal>
        <Card>
          {" "}
          <IconTableHeader
            tableCount={size(this.state.limits)}
            tableHeader="Limits"
          />
          <IconTable
            columns={this._getTableMetadata()}
            data={this.state.limits}
          />
        </Card>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  adminConstants.GET_ENTITY_LIMITS,
]);

const mapStateToProps = (state) => {
  return {
    isFetching: loadingSelector(state),
  };
};

const mapDispatchToProps = {
  getEntityLimits,
  updateEntityLimit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EntityLimits));
