import React from "react";
import PropTypes from "prop-types";
import { Col, Form } from "react-bootstrap";
import FileUploader from "components/FileUploader";

import { ID_DOCUMENT } from "statics/docTypes";

import "./IndividualDocUploader.scss";

export const docTypes = {
  DRIVERS_LICENSE: {
    description: "Driver's License",
    name: "DRIVERS_LICENSE",
    numDocs: 2,
  },
  DRIVERS_PERMIT: {
    description: "Driver's Permit",
    name: "DRIVERS_PERMIT",
    numDocs: 2,
  },
  STATE_ID_CARD: {
    description: "State ID Card",
    name: "STATE_ID_CARD",
    numDocs: 2,
  },
  SSN_CARD: {
    description: "Social Security Card",
    name: "SSN_CARD",
    numDocs: 1,
  },
  MILITARY_ID: {
    description: "Military ID Card",
    name: "MILITARY_ID",
    numDocs: 2,
  },
  MILITARY_DEPENDENT: {
    description: "Military Dependent's ID Card",
    name: "MILITARY_DEPENDENT",
    numDocs: 1,
  },
  PASSPORT: {
    description: "Passport",
    name: "PASSPORT",
    numDocs: 1,
  },
  PASSPORT_CARD: {
    description: "US Passport Card",
    name: "PASSPORT_CARD",
    numDocs: 2,
  },
  SSA_LETTER: {
    description: "Social Security Administration Letter",
    name: "SSA_LETTER",
    numDocs: 1,
  },
  IRS_ITIN_LETTER: {
    description: "IRS ITIN Letter",
    name: "IRS_ITIN_LETTER",
    numDocs: 1,
  },
  TAX_1040: {
    description: "1040 Tax Return",
    name: "TAX_1040",
    numDocs: 1,
  },
  BIRTH_CERTIFICATE: {
    description: "Birth Certificate",
    name: "BIRTH_CERTIFICATE",
    numDocs: 1,
  },
  NAME_CHANGE_DOC: {
    description: "Name Change Doc",
    name: "NAME_CHANGE_DOC",
  },
  DIVORCE_DECREE: {
    description: "Divorce Decree",
    name: "DIVORCE_DECREE",
    numDocs: 1,
  },
  TAX_W2: {
    description: "IRS form W2",
    name: "TAX_W2",
    numDocs: 1,
  },
  TAX_W4: {
    description: "W4 Withholding Allowance Certificate",
    name: "TAX_W4",
    numDocs: 1,
  },
  LEASE: {
    description: "Lease Agreement",
    name: "LEASE",
    numDocs: 1,
  },
  MARRIAGE_CERTIFICATE: {
    description: "Marriage Certificate",
    name: "MARRIAGE_CERTIFICATE",
    numDocs: 1,
  },
  MORTGAGE_AGREEMENT: {
    description: "Mortgage Agreement",
    name: "MORTGAGE_AGREEMENT",
    numDocs: 1,
  },
  NYC_ID_CARD: {
    description: "NYC ID Card",
    name: "NYC_ID_CARD",
    numDocs: 2,
  },
  PAYSTUB: {
    description: "Pay Stub Documentation",
    name: "PAYSTUB",
  },
  GREEN_CARD: {
    description: "Permanent Resident or Green Card",
    name: "GREEN_CARD",
    numDocs: 1,
  },
  TAX_1095: {
    description: "Tax Form 1095",
    name: "TAX_1095",
    numDocs: 1,
  },
  TAX_1099: {
    description: "Tax Form 1099",
    name: "TAX_1099",
    numDocs: 1,
  },
  TUITION_STATEMENT: {
    description: "Tuition Statement",
    name: "TUITION_STATEMENT",
    numDocs: 1,
  },
  UNEMPLOYMENT_LETTER: {
    description: "Unemployment Benefits Letter",
    name: "UNEMPLOYMENT_LETTER",
    numDocs: 1,
  },
  UTILITY_BILL: {
    description: "Utility Bill",
    name: "UTILITY_BILL",
    numDocs: 1,
  },
  SELFIE: {
    description: "Selfie with ID",
    name: "SELFIE",
    numDocs: 1,
  },
};

const IndividualDocUploader = ({
  idDocType,
  onSuccess,
  isFrontUploader,
  isBackUploader,
}) => {
  const successMessage = "Your file was successfully uploaded.";
  const isFrontOrBackUploader = isFrontUploader || isBackUploader;

  return (
    <Form.Group as={Col} md={6} className="upload-card">
      {isFrontOrBackUploader && (
        <Form.Label className={"document-side"}>
          {isFrontUploader && "Front of Document:"}
          {isBackUploader && "Back of Document:"}
        </Form.Label>
      )}
      <FileUploader
        fileLimit={1}
        docType={ID_DOCUMENT}
        subDocType={idDocType}
        onSuccess={onSuccess}
        requireSubDocType={true}
        fileUploadedMsg={successMessage}
        hideUploadedFiles={true}
      />
    </Form.Group>
  );
};

IndividualDocUploader.propTypes = {
  idDocType: PropTypes.string,
  onSuccess: PropTypes.func,
  isFrontUploader: PropTypes.bool,
  isBackUploader: PropTypes.bool,
};

export default IndividualDocUploader;
