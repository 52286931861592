import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import IconSpinner from "components/IconSpinner";

import {
  addPaycheckContributionSource,
  editPaycheckContributionSource,
  getContributionAbilityAndEstimates,
  getContributionRecommendations,
} from "actions/contributionActions";
import { contributionConstants, userConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import {
  employerLinkRequestType,
  recommendedContributionType,
} from "statics/propTypes";
import ContributionSelector from "components/ContributionSelector";
import { getIraAccountIdSelector } from "store/selectors/user";
import {
  getEmployerLinkRequests,
  getLinkedEmployers,
} from "actions/userActions";

class AddContribution extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    isFetching: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    addPaycheckContributionSource: PropTypes.func,
    editPaycheckContributionSource: PropTypes.func,

    getContributionAbilityAndEstimates: PropTypes.func,
    onClose: PropTypes.func,
    getContributionRecommendations: PropTypes.func,
    onCancel: PropTypes.func,
    accountId: PropTypes.string,
    payrollPeriod: PropTypes.string,
    error: PropTypes.string,
    dailyMax: PropTypes.number,
    perPayPeriodMax: PropTypes.number,
    dailyRecommendations: recommendedContributionType,
    perPayPeriodRecommendations: recommendedContributionType,
    employerLink: employerLinkRequestType,
    isEditing: PropTypes.bool,
    getLinkedEmployers: PropTypes.func,
    getEmployerLinkRequests: PropTypes.func,
    currentContributionAmount: PropTypes.number,
    showContributionDisclaimer: PropTypes.bool,
    scheduleFrequency: PropTypes.string,
  };

  componentDidMount() {
    this.props.getContributionRecommendations(this.props.client, {
      groupId: this.props.employerLink.group.id,
    });
  }

  _submitContribution = (amount, isDailyCustom) => {
    // if contribution is empty they aren't editing an existing contribution
    const isEditing =
      this.props.isEditing && !isEmpty(this.props.employerLink.contribution);
    if (isEditing) {
      this.props
        .editPaycheckContributionSource(this.props.client, {
          amount,
          contributionId: this.props.employerLink.contribution.id,
          isDailyCustom,
        })
        .then(async () => {
          await Promise.all([
            this.props.getLinkedEmployers(this.props.client),
            this.props.getEmployerLinkRequests(this.props.client),

            this.props.getContributionAbilityAndEstimates(this.props.client),
          ]);
          if (!this.props.error) {
            this.props.onClose();
            toast.success("Successfully updated contribution");
          }
        });
      return;
    }
    this.props
      .addPaycheckContributionSource(this.props.client, {
        amount,
        isDailyCustom,
        groupId: this.props.employerLink.group.id,
      })
      .then(() => {
        if (!this.props.error) {
          this.props.getContributionAbilityAndEstimates(this.props.client);
          this.props.onClose();
          toast.success("Successfully added contribution");
        }
      });
  };

  render() {
    const isFetching =
      this.props.isFetching || isEmpty(this.props.perPayPeriodRecommendations);

    return (
      <>
        <Modal.Header closeButton>
          <h3 className="modal-title">
            How much do you want to invest to your IRA from your employer
            account?
          </h3>
          <p className="modal-subtitle">
            This is a long-term investment – you can put a little bit in each
            time you’re paid so that your money can start working for you. You
            can always change this at any time.
          </p>
        </Modal.Header>
        <Modal.Body>
          {isFetching && <IconSpinner centered />}
          {!isFetching && (
            <div className="contribution-selection">
              <ContributionSelector
                dailyMax={this.props.dailyMax}
                perPayPeriodMax={this.props.perPayPeriodMax}
                dailyRecommendations={this.props.dailyRecommendations}
                perPayPeriodRecommendations={
                  this.props.perPayPeriodRecommendations
                }
                error={this.props.error}
                onSubmit={this._submitContribution}
                isSubmitting={this.props.isSubmitting}
                onCancel={this.props.onCancel}
                currentContributionAmount={this.props.currentContributionAmount}
                schedule={{ frequency: this.props.scheduleFrequency }}
                isPaycheck={true}
                isEditing={
                  this.props.isEditing &&
                  !isEmpty(this.props.employerLink.contribution)
                }
              />
              {this.props.showContributionDisclaimer && (
                <div className="disclaimer">
                  * Contribution change request may not go into effect until the
                  next payroll is processed, current payroll may process for
                  current amount.
                </div>
              )}
            </div>
          )}
        </Modal.Body>
      </>
    );
  }
}

const actions = [
  contributionConstants.ONBOARDING_PAYCHECK_CONTRIBUTION,
  contributionConstants.GET_CONTRIBUTION_RECOMMENDATIONS,
  contributionConstants.PAYCHECK_CONTRIBUTION,
  contributionConstants.EDIT_PAYCHECK_CONTRIBUTION,
  userConstants.USER_STATE,
  userConstants.USER_EMPLOYER_LINK_REQUESTS,
  userConstants.USER_EMPLOYER_LINK,
];
const errorSelector = createErrorSelector(actions);
const loadingSelector = createLoadingSelector(
  contributionConstants.GET_CONTRIBUTION_RECOMMENDATIONS
);
const isSubmitting = createLoadingSelector(actions);

const mapStateToProps = (state) => {
  const { dailyMax, perPayPeriodMax, recommendations } = state.contribution;
  const { scheduleFrequency } = recommendations;

  return {
    dailyMax,
    perPayPeriodMax,
    dailyRecommendations: recommendations.daily,
    perPayPeriodRecommendations: recommendations.perPayPeriod,
    error: errorSelector(state),
    isFetching: loadingSelector(state),
    isSubmitting: isSubmitting(state),
    accountId: getIraAccountIdSelector(state),
    scheduleFrequency,
  };
};

const mapDispatchToProps = {
  getContributionRecommendations,
  editPaycheckContributionSource,
  getLinkedEmployers,
  getEmployerLinkRequests,
  addPaycheckContributionSource,

  getContributionAbilityAndEstimates,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddContribution));
