import { payrollConstants } from "./types";
import payrollService from "services/payrollService";
import ActionCreator from "utils/actionHandler";

export const getPayrollLineItems = (client, groupId, payPeriodId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      payrollConstants.GET_PAYROLL_LINE_ITEMS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      payrollService.getPayrollLineItems(client, groupId, payPeriodId),
      { id: groupId }
    );
  };
};

export const approvePayroll = (client, payload) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(payrollConstants.APPROVE_PAYROLL);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      payrollService.approvePayroll(client, payload),
      { id: payload.groupId }
    );
  };
};

export const getPayrollHistory = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      payrollConstants.GET_PAYROLL_HISTORY
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      payrollService.getPayrollHistoryRequest(client)
    );
  };
};

export const cancelPayrollsByBankId = (client, bankId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(payrollConstants.CANCEL_PAYROLLS);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      payrollService.cancelPayrollsByBankIdRequest(client, bankId)
    );
  };
};

export const getTotalAmountSaved = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      payrollConstants.GET_TOTAL_AMOUNT_SAVED
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      payrollService.getTotalAmountSavedRequest(client)
    );
  };
};

export const createTopOffContributions = (client, groupId, payPeriodId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      payrollConstants.CREATE_TOP_OFF_CONTRIBUTIONS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      payrollService.createTopOffContributionsRequest(
        client,
        groupId,
        payPeriodId
      )
    );
  };
};
export const getPaginatedChangeRequests = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      payrollConstants.GET_PAGINATED_CHANGE_REQUESTS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      payrollService.getPaginatedChangeRequests(client, params)
    );
  };
};

export const getPaginatedContributions = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      payrollConstants.GET_PAGINATED_CONTRIBUTIONS
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      payrollService.getPaginatedContributions(client, params)
    );
  };
};

export const cancelPayroll = (client, groupId, payrollId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(payrollConstants.CANCEL_PAYROLL);
    dispatch(actionCreator.request());
    return payrollService
      .cancelPayrollRequest(client, groupId, payrollId)
      .then((schedules) =>
        dispatch(actionCreator.success({ groupId, schedules }))
      )
      .catch((error) => dispatch(actionCreator.failure(error)));
  };
};

export const recheckPayrollBalance = (client, payrollId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      payrollConstants.RECHECK_PAYROLL_BALANCE
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      payrollService.recheckPayrollBalanceRequest(client, payrollId)
    );
  };
};

export const dismissPayroll = (client, payPeriodId, reason) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(payrollConstants.DISMISS_PAYROLL);
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      payrollService.dismissPayroll(client, payPeriodId, reason)
    );
  };
};

export const approveSessionTokenPayroll = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      payrollConstants.APPROVE_SESSION_TOKEN_PAYROLL
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      payrollService.approveSessionTokenPayroll(client, params)
    );
  };
};
