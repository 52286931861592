import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { isEmpty, map, filter, get, find } from "lodash";
import { FiAnchor, FiUmbrella } from "react-icons/fi";
import { accountConstants, userConstants } from "actions/types";
import { scrollToTop } from "utils/dom";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { cancelRollover } from "actions/userActions";
import { setAccountSession } from "actions/accountActions";
import { Row, Col, Card } from "react-bootstrap";
import { getAllAccounts } from "actions/accountActions";
import {
  accountTypesToEnglishMapping,
  TRADITIONAL_IRA,
} from "statics/accountTypes";

import Button from "components/Button";
import Alert from "components/Alert";
import "../../dashboards/individualDashboard/dashboard/IndividualOverview.scss";

class IndividualCancelRollover extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    getAllAccounts: PropTypes.func,
    client: PropTypes.object,
    cancelRollover: PropTypes.func,
    setAccountSession: PropTypes.func,
    isSubmitting: PropTypes.bool,
    error: PropTypes.string,
    isProcessingRollover: PropTypes.object,
    activeAccountId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedAccount: "",
      showDeletedComponent: false,
      submitted: false,
    };
  }

  componentDidMount() {
    window.analytics.page("Individual Cancelling Rollover");
    scrollToTop();
  }

  _cancelRolloverAccount = () => {
    const accountId = this.state.selectedAccount;
    this.props
      .cancelRollover(this.props.client, accountId)
      .then(async () => {
        if (!this.props.error) {
          await this.props.getAllAccounts(this.props.client);
          await this.props.setAccountSession(
            this.props.client,
            this.props.activeAccountId
          );

          toast.success("You have successfully canceled your rollover.");

          window.location.search = `?redirect_to=/dashboard`;
          window.analytics.track("Cancelled Individual Rollover");

          this.setState({
            submitted: true,
          });
        } else {
          console.error("Error found:", this.props.error);
        }
      })
      .catch((error) => {
        toast.error("There was a problem canceling your rollover.");
        console.error("An error occurred:", error);
      });
  };

  _setSelectedAccount = (selectedAccount) => {
    this.setState({
      selectedAccount,
    });
  };

  _closeAccount = () => {
    var articleStyle = {
      paddingBottom: 0,
    };

    return (
      <div id="account-selection">
        <div className="mega-container">
          <div className="step-container is-active" data-circle-percent="100">
            <section className="page-title-wrap">
              <article className="text-cell">
                <p className="page-title">Confirm rollover cancellation</p>
                <p className="page-subtext">
                  Please review the details below to confirm you want to cancel
                  your rollover. This action cannot be undone.
                </p>
              </article>
            </section>
            <div>
              <section className="form-sec-2col">
                <article className="col-form" style={articleStyle}>
                  <div className="account-types">
                    {map(this.props.isProcessingRollover, (account) => {
                      return (
                        <Card
                          className="dashboard-widget balance-kpi "
                          style={{ marginTop: 30 }}
                        >
                          <Row
                            key={account.id}
                            className={classnames("account-container", {
                              ["is-selected"]:
                                this.state.selectedAccount === account.id,
                            })}
                            onClick={() => this._setSelectedAccount(account.id)}
                          >
                            <Col>
                              <div className="account-item">
                                <div>
                                  <div className="account-circle">
                                    <span className="account-icon">
                                      {account.accountType ===
                                      TRADITIONAL_IRA ? (
                                        <FiAnchor
                                          color="white"
                                          stroke="#60A4BF"
                                          size={16}
                                        />
                                      ) : (
                                        <FiUmbrella
                                          color="white"
                                          stroke="#60A4BF"
                                          size={16}
                                        />
                                      )}
                                    </span>
                                  </div>
                                </div>

                                <div className="item-description">
                                  <p className="title">
                                    {
                                      accountTypesToEnglishMapping[
                                        account.accountType
                                      ]
                                    }
                                  </p>
                                  {account.isProcessingRollover ? (
                                    <p className="description">
                                      Rollover in progress
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      );
                    })}
                  </div>
                </article>
              </section>
              <section className="form-sec-2col">
                <article className="col-form">
                  <div className="submit-row">
                    {this.props.error && (
                      <Alert type="error" msg={this.props.error} />
                    )}
                  </div>
                  <div className="submit-row btn-row">
                    <Button
                      btnLabel={"Close"}
                      color={"cancel"}
                      onClick={() => this.props.push("/dashboard/support")}
                    />
                    <Button
                      name="submit"
                      btnLabel="Cancel Rollover"
                      withArrow={true}
                      onClick={this._cancelRolloverAccount}
                      loading={this.props.isSubmitting}
                      disabled={isEmpty(this.state.selectedAccount)}
                    />
                  </div>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const content = this._closeAccount();

    return <>{content}</>;
  }
}

const actions = [userConstants.CANCEL_ROLLOVER, accountConstants.GET_ACCOUNTS];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const accounts = state.accounts.iraAccounts;
  const isProcessingRollover = filter(accounts, { isProcessingRollover: true });
  const activeAccountId = get(
    find(accounts, { state: "AccountActive" }),
    "id",
    null
  );

  return {
    activeAccountId,
    isProcessingRollover,
    isSubmitting: loadingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  cancelRollover,
  getAllAccounts,
  setAccountSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualCancelRollover));
