import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { AiOutlineFileSync } from "react-icons/ai";
import { getCompanyInfo } from "actions/employerActions";

import Accordian from "./Accordian";
import {
  getPayrollIntegrationStatus,
  hasAddedGroups,
  hasSuccessfullyConnectedPayroll,
} from "store/selectors/employer";
import PromisePoller from "components/PromisePoller";
import { get, includes } from "lodash";
import {
  ConnectionInitialized,
  InitialRosterSyncError,
  PendingAdminApproval,
  PendingInitialRosterSync,
} from "statics/payrollIntegrationStates";

const onboardingSteps = [
  {
    index: 1,
    label: "Waiting to connect with your Payroll provider.",
  },
  {
    index: 2,
    label: "Waiting for first payroll roster sync",
    isAsync: true,
  },
  {
    index: 3,
    label: "Awaiting manual payroll sync verification by customer success",
  },
];

const PayrollConnect = (props) => {
  let activeStepNumber = 1;
  const onClick = null;
  const pendingSync = includes(
    [ConnectionInitialized, PendingInitialRosterSync, InitialRosterSyncError],
    props.payrollIntegrationStatus
  );

  if (pendingSync) {
    activeStepNumber = 2;
  }

  const pendingApproval =
    props.payrollIntegrationStatus === PendingAdminApproval;

  if (pendingApproval) {
    activeStepNumber = 3;
  }

  const shouldPoll = get(
    onboardingSteps[activeStepNumber - 1],
    "isAsync",
    false
  );

  return (
    <>
      <Accordian
        onClick={onClick}
        icon={<AiOutlineFileSync color="#60A4BF" stroke="#60A4BF" size={16} />}
        title={"Payroll Integration"}
        description={"Integrating with your payroll provider"}
        actionText={null}
        disabled={!props.hasAddedGroups}
        completedAction={props.hasSuccessfullyConnectedPayroll}
        steps={onboardingSteps}
        activeStepNumber={activeStepNumber}
      />
      {shouldPoll && !props.hasSuccessfullyConnectedPayroll && (
        <PromisePoller
          interval={5000}
          promiseToPoll={() => {
            return props.getCompanyInfo(props.client);
          }}
          minutesToPollFor={5}
        />
      )}
    </>
  );
};

PayrollConnect.propTypes = {
  push: PropTypes.func,
  getCompanyInfo: PropTypes.func,
  hasSuccessfullyConnectedPayroll: PropTypes.bool,
  client: PropTypes.object,
  hasAddedGroups: PropTypes.bool,
  payrollIntegrationStatus: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    hasAddedGroups: hasAddedGroups(state),
    payrollIntegrationStatus: getPayrollIntegrationStatus(state),
    hasSuccessfullyConnectedPayroll: hasSuccessfullyConnectedPayroll(state),
  };
};

const mapDispatchToProps = {
  push,
  getCompanyInfo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(PayrollConnect));
