import React from "react";
import PropTypes from "prop-types";

const PageHeading = ({ title, subtext }) => {
  return (
    <section className="page-title-wrap">
      <article className="text-cell">
        <h1 className="page-title">{title}</h1>
        {subtext && <p className="page-subtext">{subtext}</p>}
      </article>
    </section>
  );
};

PageHeading.propTypes = {
  title: PropTypes.string,
  subtext: PropTypes.string,
};

export default PageHeading;
