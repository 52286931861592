import React from "react";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import PropTypes from "prop-types";
import "./RosterUploader.scss";
import moment from "moment";
import {
  formatErrorString,
  IndividualAddress1Validator,
  individualAddress2Validator,
  NAME_LENGTH_ERROR,
  NAME_MESSAGE,
  noWhitespaceRegex,
  PRINTABLE_ASCII_MESSAGE,
  testFirstLastName,
  testName,
  testPhoneNumber,
  testPrintableAsciiCharacters,
  testSsn,
  whiteSpaceError,
} from "utils/fieldValidators";
import { matchColumns, selectHeader, validateRows } from "statics/rosterSteps";
import { each, map, mapValues, size, trim } from "lodash";

const yup = require("yup");
const DOB_FORMAT = "YYYY-MM-DD";

const schema = yup.object({
  firstName: yup
    .string()
    .label("First")
    .matches(noWhitespaceRegex, whiteSpaceError)
    .required()
    .test("is-valid-name", NAME_MESSAGE, testName)
    .min(1, "Must be at least one character.")
    .max(20)
    .test("first-last-name-length", NAME_LENGTH_ERROR, testFirstLastName),
  lastName: yup
    .string()
    .label("Last")
    .matches(noWhitespaceRegex, whiteSpaceError)
    .required()
    .test("is-valid-name", NAME_MESSAGE, testName)
    .min(1, "Must be at least one character.")
    .max(20, "Must be less than 20 characters.")
    .test("first-last-name-length", NAME_LENGTH_ERROR, testFirstLastName),
  email: yup.string().email().required(),
  dob: yup
    .string()
    .label("Date Of Birth")
    .required()
    .test("dob", "dob must be a valid date string YYYY-MM-DD.", (value) => {
      return moment(value, DOB_FORMAT, true).isValid();
    })
    .test(
      "dob",
      "Employee must be at least 18 to create an account.",
      (value) => {
        return moment().diff(moment(value), "years") >= 18;
      }
    )
    .test(
      "dob",
      "Employee must be under 100 years old to create an account.",
      (value) => {
        return moment().diff(moment(value), "years") < 100;
      }
    ),
  ssn: yup
    .string()
    .trim()
    .required()
    .label("Social Security Number")
    .test("ssn", "${path} must be of format 111-11-1111.", (value) =>
      testSsn(value, true)
    ),
  address1: IndividualAddress1Validator,
  address2: individualAddress2Validator,
  city: yup
    .string()
    .trim()
    .label("City")
    .required()
    .test("city", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .max(50),
  state: yup
    .string()
    .label("State")
    .required()
    .test("state", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .max(14),
  postalCode: yup
    .string()
    .trim()
    .label("Zip")
    .required()
    .test("postalCode", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .test(
      "postalCode",
      "Postal Code must be five digits",
      (val) => size(val) === 5
    ),

  phoneNumber: yup
    .string()
    .trim()
    .label("Phone")
    .required()
    .test("phone", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .test(
      "us-phone",
      "${path} is not a valid phone number. Ex: (123) 456-7890 or 1234567890",
      testPhoneNumber
    )
    .max(15),
});

// email	ssn	firstName	lastName	address1	address2	city	state	postalCode	phoneNumber	dob	location	department

export default class RosterUploader extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    setActiveStep: PropTypes.func,
    isOpen: PropTypes.bool,
  };

  _onSubmit = (data) => {
    this.props.onSubmit(data.validData);
  };

  _rowValidator = (data, addError) => {
    const newDataObj = mapValues(data, trim);
    let dob = moment(newDataObj.dob);
    // if dob is a valid date string we should auto-format it into the format we expect
    if (dob.isValid()) {
      newDataObj.dob = dob.format(DOB_FORMAT);
    }

    try {
      schema.validateSync(newDataObj, {
        abortEarly: false,
      });
      return newDataObj;
    } catch (err) {
      each(err.inner, (pathErrors) => {
        const errors = map(pathErrors.errors, (error) =>
          formatErrorString(error)
        );

        const joinedErrors = errors.join(" ");
        addError(pathErrors.path, {
          message: joinedErrors,
          level: "error",
        });
      });
      return newDataObj;
    }
  };

  render() {
    // 502 to account for header row and potentially description row
    const maxRows = 502;
    return (
      <ReactSpreadsheetImport
        isOpen={true}
        maxRecords={maxRows}
        onSubmit={this._onSubmit}
        onClose={this.props.onClose}
        allowInvalidSubmit={false}
        rowHook={this._rowValidator}
        uploadStepHook={(data) => {
          this.props.setActiveStep(selectHeader);
          return data;
        }}
        selectHeaderStepHook={(headerValues, data) => {
          this.props.setActiveStep(matchColumns);
          return { data, headerValues };
        }}
        matchColumnsStepHook={(values) => {
          this.props.setActiveStep(validateRows);

          return values;
        }}
        translations={{
          alerts: {
            unmatchedRequiredFields: {
              bodyText:
                "There are required columns that are not matched, please match all columns.",
            },
          },
        }}
        customTheme={{
          components: {
            UploadStep: {
              baseStyle: {
                dropzoneButton: {
                  bg: "#29b0c2",
                  _hover: {
                    bg: "#29b0c2",
                  },
                },
              },
            },
          },
        }}
        fields={[
          {
            fieldType: {
              type: "input",
            },
            label: "Email",
            key: "email",
            example: "user@company.com",
            alternateMatches: ["emailaddress", "personalemail", "homeemail"],
            validations: [
              {
                rule: "unique",
                errorMessage: "Email must be unique.",
                level: "error",
              },
              {
                rule: "regex",
                value:
                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                errorMessage: "Must be a valid email address.",
                level: "error",
              },
              {
                rule: "required",
                errorMessage: "email is required",
                level: "error",
              },
            ],
          },
          {
            fieldType: {
              type: "input",
            },
            label: "SSN",
            key: "ssn",
            alternateMatches: [
              "social security number",
              "social",
              "socialSecurityNumber",
              "socialsecuritynumber",
              "socialsecurity#",
            ],
            example: "111-11-1111",
            validations: [
              {
                rule: "unique",
                errorMessage: "ssn must be unique.",
                level: "error",
              },
              {
                rule: "required",
                errorMessage: "ssn is required",
                level: "error",
              },
            ],
          },
          {
            fieldType: {
              type: "input",
            },
            label: "First Name",
            key: "firstName",
            alternateMatches: ["first"],
            example: "John",
            validations: [
              {
                rule: "required",
                errorMessage: "firstName is required",
                level: "error",
              },
            ],
          },
          {
            fieldType: {
              type: "input",
            },
            label: "Last Name",
            key: "lastName",
            alternateMatches: ["last"],
            example: "Doe",
            validations: [
              {
                rule: "required",
                errorMessage: "lastName is required",
                level: "error",
              },
            ],
          },
          {
            fieldType: {
              type: "input",
            },
            label: "Address 1",
            key: "address1",
            alternateMatches: [
              "street1",
              "line1",
              "addressline1",
              "streetaddress",
            ],
            example: "123 main st",
            validations: [
              {
                rule: "required",
                errorMessage: "address1 is required",
                level: "error",
              },
            ],
          },
          {
            fieldType: {
              type: "input",
            },
            label: "Address 2",
            key: "address2",
            alternateMatches: [
              "street2",
              "line2",
              "addressline2",
              "streetaddress2",
            ],
            example: "apt. 1",
          },
          {
            fieldType: {
              type: "input",
            },
            label: "City",
            key: "city",
            example: "Portland",
            validations: [
              {
                rule: "required",
                errorMessage: "city is required",
                level: "error",
              },
            ],
          },
          {
            fieldType: {
              type: "input",
            },
            label: "State",
            key: "state",
            example: "OR",
            validations: [
              {
                rule: "required",
                errorMessage: "state is required",
                level: "error",
              },
            ],
          },
          {
            fieldType: {
              type: "input",
            },
            label: "Postal Code",
            alternateMatches: ["zipcode", "zip", "postalcode"],
            key: "postalCode",
            example: "97209",
            validations: [
              {
                rule: "required",
                errorMessage: "postalCode is required",
                level: "error",
              },
            ],
          },
          {
            fieldType: {
              type: "input",
            },
            label: "Phone Number",
            key: "phoneNumber",

            alternateMatches: [
              "phone",
              "mobile",
              "personalmobile",
              "cellphone",
              "homephone",
            ],
            example: "555-555-5555",
            validations: [
              {
                rule: "required",
                errorMessage: "phoneNumber is required",
                level: "error",
              },
            ],
          },
          {
            fieldType: {
              type: "input",
            },
            label: "Date of Birth",
            key: "dob",
            alternateMatches: ["dateofbirth", "birthdate"],
            example: "1950-12-31",
            validations: [
              {
                rule: "required",
                errorMessage: "dob is required",
                level: "error",
              },
            ],
          },
          {
            fieldType: {
              type: "input",
            },
            label: "Location",
            key: "location",
            example: "Denver Region",
          },
          {
            fieldType: {
              type: "input",
            },
            label: "Department",
            key: "department",
            example: "Engineering",
          },
        ]}
      />
    );
  }
}
