import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { BsBriefcase } from "react-icons/bs";
import { FiDollarSign, FiTrash2, FiEdit3, FiRepeat } from "react-icons/fi";

import { isEmpty } from "lodash";
import moment from "moment";
import { formatCurrency } from "utils/number";

import Button from "components/Button";
import AddEmployerModal from "./AddEmployerModal";
import RemoveSourceModal from "./RemoveSourceModal";
import SourceActions from "./SourceActions";
import RecurringContributionModal from "./RecurringContributionModal";
import ContributionModal from "pages/dashboards/individualDashboard/dashboard/ContributionModal";

class ContributionSource extends React.PureComponent {
  static propTypes = {
    isEmployer: PropTypes.bool,
    isPendingVerification: PropTypes.bool,
    sourceId: PropTypes.string,
    contributionSource: PropTypes.shape({
      name: PropTypes.string,
      planId: PropTypes.string,
      bankName: PropTypes.string,
      accountSubtype: PropTypes.string,
      accountType: PropTypes.string,
      accountId: PropTypes.string,
      id: PropTypes.string,
      isLinkRequest: PropTypes.bool,
    }),
    contributionInfo: PropTypes.shape({
      amount: PropTypes.number,
      end: PropTypes.string,
      schedule: PropTypes.string,
      scheduleId: PropTypes.string,
      frequency: PropTypes.string,
    }),
    employerLink: PropTypes.object,
  };

  constructor() {
    super();
    this.state = {
      showContributionModal: false,
      showOneTimeContributionModal: false,
      showDeleteModal: false,
      showContributonActions: false,
    };
  }

  _getTitle() {
    if (this.props.isEmployer) {
      return (
        <>
          <p className="company-name">{this.props.contributionSource.name}</p>
          <p className="plan-id">
            Plan ID: {this.props.contributionSource.planId}
          </p>
        </>
      );
    }

    return (
      <div>
        <p className="bank-name">{this.props.contributionSource.bankName}</p>
      </div>
    );
  }

  _getSourceFooter() {
    if (this.props.isEmployer) {
      return <BsBriefcase size={"22px"} stroke={"0.5"} />;
    }

    const { accountId } = this.props.contributionSource;

    return (
      <>
        <div className="bank-design">
          <div className="card-chip">
            <p className="account-type">
              {this.props.contributionSource.accountSubtype}
              <span style={{ display: "block" }} className="bank-number">
                **** **** {accountId}
              </span>
            </p>
          </div>
        </div>
      </>
    );
  }

  _getActionMenuActions = () => {
    if (this.props.isEmployer) {
      return [
        {
          label: "Change Contribution Amount",
          onClick: () => this.setState({ showContributionModal: true }),
          icon: (
            <FiEdit3
              size="16px"
              color="#FFFFFF"
              fill={"#FFFFFF"}
              stroke={"#1D99A9"}
              strokeWidth={"1.5"}
            />
          ),
        },
        {
          label: "Remove Employer",
          onClick: () => this.setState({ isDeleting: true }),
          icon: (
            <FiTrash2
              size="16px"
              color="#FFFFFF"
              fill={"#FFFFFF"}
              stroke={"#B12121"}
              strokeWidth={"1.5"}
            />
          ),
        },
      ];
    }

    return [
      {
        label: "Change Contribution Amount",
        onClick: () => this.setState({ showContributionModal: true }),
        icon: (
          <FiEdit3
            size="16px"
            color="#FFFFFF"
            fill={"#FFFFFF"}
            stroke={"#1D99A9"}
            strokeWidth={"1.5"}
          />
        ),
      },
      {
        label: "Make one-time contribution",
        onClick: () => this.setState({ showOneTimeContributionModal: true }),
        icon: (
          <FiDollarSign
            size="16px"
            color="#FFFFFF"
            fill={"#FFFFFF"}
            stroke={"#1D99A9"}
            strokeWidth={"1.5"}
          />
        ),
      },
      {
        label: "Remove Bank",
        onClick: () => this.setState({ isDeleting: true }),
        icon: (
          <FiTrash2
            size="16px"
            color="#FFFFFF"
            fill={"#FFFFFF"}
            stroke={"#B12121"}
            strokeWidth={"1.5"}
          />
        ),
      },
    ];
  };

  _getContributionScourceActions = () => {
    if (isEmpty(this.props.contributionInfo)) {
      if (this.props.contributionSource.isLinkRequest) {
        return [
          {
            label: "Setup Contribution",
            onClick: () => this.setState({ showContributionModal: true }),
            icon: (
              <FiRepeat
                size="16px"
                color="#FFFFFF"
                fill={"#1D99A9"}
                stroke={"#1D99A9"}
                strokeWidth={"1.5"}
              />
            ),
          },
          {
            label: "Remove Employer",
            onClick: () => this.setState({ isDeleting: true }),
            icon: (
              <FiTrash2
                size="16px"
                color="#FFFFFF"
                fill={"#FFFFFF"}
                stroke={"#B12121"}
                strokeWidth={"1.5"}
              />
            ),
          },
        ];
      }
      return [
        {
          label: "Make one-time contribution",
          onClick: () => this.setState({ showOneTimeContributionModal: true }),
          icon: (
            <FiDollarSign
              size="16px"
              color="#FFFFFF"
              fill={"#FFFFFF"}
              stroke={"#1D99A9"}
              strokeWidth={"1.5"}
            />
          ),
        },
        {
          label: "Setup Recurring Contributions",
          onClick: () => this.setState({ showContributionModal: true }),
          icon: (
            <FiRepeat
              size="16px"
              color="#FFFFFF"
              fill={"#1D99A9"}
              stroke={"#1D99A9"}
              strokeWidth={"1.5"}
            />
          ),
        },
        {
          label: "Remove Bank",
          onClick: () => this.setState({ isDeleting: true }),
          icon: (
            <FiTrash2
              size="16px"
              color="#FFFFFF"
              fill={"#FFFFFF"}
              stroke={"#B12121"}
              strokeWidth={"1.5"}
            />
          ),
        },
      ];
    }
  };

  _getContributionSection() {
    if (isEmpty(this.props.contributionInfo)) {
      if (this.props.isPendingVerification) {
        return (
          <div className="pending-bank">
            <p className="pending-bank-message">Verifying Account </p>
            <p className="no-contribution-subtext">
              Bank account is pending verification, once verified you can setup
              a recurring contribution.
            </p>

            <span className="remove-bank">
              <Button
                btnClass="actions-menu-btn"
                size="sm"
                color="red"
                name="action"
                onClick={() => this.setState({ isDeleting: true })}
              >
                Remove Bank
              </Button>
            </span>
          </div>
        );
      }
      if (this.props.contributionSource.isLinkRequest) {
        return (
          <div className="no-contribution">
            <div className="no-contribution-content">
              <p className="no-contribution-message">Finish Setup</p>
              <p className="no-contribution-subtext">
                Finish setting up your employer account by adding your
                contribution amount.
              </p>
              <span className="add-contrbution-actions">
                <SourceActions
                  actions={this._getContributionScourceActions()}
                  menuTitle={"CONTRIBUTION ACTIONS"}
                />
              </span>
            </div>
          </div>
        );
      }
      return (
        <div className="no-contribution">
          <div className="no-contribution-content">
            <p className="no-contribution-message">Add a contribution</p>
            <p className="no-contribution-subtext">
              With a linked bank account you can set up reccuring and or make a
              one-time contribution.
            </p>
            <span className="add-contrbution-actions">
              <SourceActions
                actions={this._getContributionScourceActions()}
                menuTitle={"CONTRIBUTION ACTIONS"}
              />
            </span>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className="contribution-heading">
          <h3 className="contribution">Contribution</h3>
          <div className="contribution-schedule">
            <span>
              <FiRepeat
                size={12}
                color={"#ffffff"}
                fill={"#ffffff"}
                stroke={"#6b7c93"}
                strokeWidth={"1.5"}
              />
            </span>
            <span className="schedule">
              {this.props.contributionInfo.schedule}
            </span>
          </div>
        </div>
        <div className="contribution-amount-and-actions">
          <span className="contribution-amount">
            {formatCurrency(this.props.contributionInfo.amount)}
          </span>
        </div>
        <div className="flex-break" />
        <div className="menu-and-date">
          <div className="next-run-date">
            <span className="dot" />
            <span>
              Next{" "}
              {this.props.isEmployer ? "payroll date: " : "contribution date: "}
              {moment(this.props.contributionInfo.end).format("MMMM Do, YYYY")}
            </span>
          </div>

          <div className="menu-btn">
            <SourceActions
              actions={this._getActionMenuActions()}
              menuTitle={
                this.props.isEmployer ? "EMPLOYER ACCOUNT" : "BANK ACCOUNT"
              }
            />
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div
        className={classnames("contribution-source-container", {
          "bank-source": !this.props.isEmployer,
          "employer-source": this.props.isEmployer,
        })}
      >
        {this.state.showContributionModal && this.props.isEmployer && (
          <AddEmployerModal
            show={this.state.showContributionModal}
            employerLink={this.props.employerLink}
            isLinkRequest={this.props.contributionSource.isLinkRequest}
            isEditing={true}
            onClose={() => this.setState({ showContributionModal: false })}
          />
        )}

        {this.state.showContributionModal && !this.props.isEmployer && (
          <RecurringContributionModal
            show={true}
            scheduleId={this.props.contributionInfo.scheduleId}
            bankId={this.props.contributionSource.id}
            onClose={() => this.setState({ showContributionModal: false })}
            recurringContribution={this.props.contributionInfo}
            bankAccountNumber={this.props.contributionSource.accountId}
            currentContributionAmount={this.props.contributionInfo.amount}
          />
        )}
        {this.state.showOneTimeContributionModal && (
          <ContributionModal
            show={this.state.showOneTimeContributionModal}
            onSuccess={() => {
              this.setState({ showOneTimeContributionModal: false });
            }}
            onClose={() => {
              this.setState({ showOneTimeContributionModal: false });
            }}
            depositOnly={true}
          />
        )}
        <RemoveSourceModal
          show={this.state.isDeleting}
          isEmployer={this.props.isEmployer}
          isLinkRequest={this.props.contributionSource.isLinkRequest}
          onClose={() => {
            this.setState({ isDeleting: false });
          }}
          sourceId={this.props.contributionSource.id}
        />
        <div className="source-info">
          <div className="source-title">{this._getTitle()}</div>
          <div className="source-footer">{this._getSourceFooter()}</div>
        </div>
        <div
          className={classnames("contribution-info", {
            ["no-contribution"]: isEmpty(this.props.contributionInfo),
          })}
        >
          {this._getContributionSection()}
        </div>
      </div>
    );
  }
}
export default ContributionSource;
