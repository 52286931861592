import React from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from "components/PrivateRoute";
import PublicRoute from "components/PublicRoute";
import EmployerSignup from "pages/signUp/employer/EmployerSignup";
import EmployerEnroll from "pages/signUp/employer/EmployerEnroll";
import EmployerTerms from "pages/signUp/employer/EmployerTerms";
import EmployerBilling from "pages/signUp/employer/EmployerBilling";
import EmployerSelfServeController from "pages/signUp/employer/EmployerSelfServeController";
import DashboardLayout from "pages/dashboards/DashboardLayout";
import VerifyEmail from "pages/signUp/VerifyEmail";
import Home from "pages/Home";
import PageNotFound from "pages/PageNotFound";
import { useMediaQuery } from "react-responsive";
import OauthPlaidLink from "../pages/OauthPlaidLink";
import AuthlessPayrollApproval from "pages/dashboards/employerDashboard/processContributions/authlessPayrollApproval/AuthlessPayrollApproval";
import EmployerRequiredUpdates from "pages/dashboards/employerDashboard/requiredUpdates/EmployerRequiredUpdates";

const EmployerRoutes = () => {
  return (
    <Switch>
      <PublicRoute
        path="/"
        exact
        component={(props) => <EmployerSignup {...props} />}
      />
      <PublicRoute
        path="/session/employer-paycheck-auto-approval"
        exact
        component={(props) => <AuthlessPayrollApproval {...props} />}
      />
      <PrivateRoute
        path="/employer/enroll"
        exact
        component={(props) => <EmployerEnroll {...props} />}
      />
      <PrivateRoute
        path="/employer/verify"
        exact
        component={(props) => <VerifyEmail {...props} />}
      />
      <PrivateRoute
        path="/employer/terms"
        exact
        component={(props) => <EmployerTerms {...props} />}
      />
      <PrivateRoute
        path="/employer/billing"
        exact
        component={(props) => <EmployerBilling {...props} />}
      />
      <PrivateRoute
        path="/employer/info"
        exact
        component={(props) => <EmployerSelfServeController {...props} />}
      />
      <PrivateRoute
        path="/required-updates"
        exact
        component={(props) => <EmployerRequiredUpdates {...props} />}
      />

      <PrivateRoute
        path="/dashboard"
        component={(props) => (
          <DashboardLayout
            {...props}
            isEmployerDashboard={true}
            isSmallScreen={useMediaQuery({ maxWidth: 767 })}
          />
        )}
      />
      <PrivateRoute
        path="/login"
        exact
        component={(props) => <Home {...props} />}
      />
      <PrivateRoute
        path="/link-bank"
        exact
        component={(props) => <OauthPlaidLink {...props} />}
      />
      <PrivateRoute component={PageNotFound} />
    </Switch>
  );
};

export default EmployerRoutes;
