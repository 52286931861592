import React from "react";
import PropTypes from "prop-types";
import IconTable from "components/IconTable";
import Button from "components/Button";
import StatusBox from "components/StatusBox";

import { Card } from "react-bootstrap";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { toast } from "react-toastify";
import { flushErrors } from "actions/errorActions";
import { capitalize, isEmpty, map, size, some, transform } from "lodash";
import {
  approveEmployeeLinks,
  getAllEmployerGroups,
  rejectEmployeeLinks,
} from "actions/employerActions";

class ApproveEmployeeLinkRequest extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    group: PropTypes.object,
    linkedEmployees: PropTypes.array,
    getAllEmployerGroups: PropTypes.func,
    rejectEmployeeLinks: PropTypes.func,
    approveEmployeeLinks: PropTypes.func,
    goToNamedStep: PropTypes.func,
    push: PropTypes.func,

    isUpdating: PropTypes.bool,
    isApproving: PropTypes.bool,
    isRejecting: PropTypes.bool,

    approvedPlans: PropTypes.object,
    rejectedPlans: PropTypes.object,

    error: PropTypes.string,
    pendingLinkRequests: PropTypes.array,
    planType: PropTypes.string,
  };

  _getApprovalData = () => {
    const { existing, approved, rejected } = transform(
      [...this.props.linkedEmployees, ...this.props.pendingLinkRequests],
      (acc, val) => {
        const {
          userProfile: { firstName, lastName, email, lastFourOfSsn },
        } = val;

        const fullName = `${firstName} ${lastName}`;
        const id = val.id;

        const isExisting = some(this.props.linkedEmployees, { id: val.id });
        const isNewApproved = !!this.props.approvedPlans[val.id];
        const isNewRejected = !!this.props.rejectedPlans[val.id];
        const rowData = {
          id,
          employeeName: fullName,
          lastFourOfSsn,
          email,
          status,
        };
        let status;
        if (isExisting || isNewApproved) {
          rowData.status = "Approved";
          isExisting ? acc.existing.push(rowData) : acc.approved.push(rowData);
        } else if (isNewRejected) {
          rowData.status = "Rejected";
          acc.rejected.push(rowData);
        }
      },
      {
        existing: [],
        approved: [],
        rejected: [],
      }
    );

    return [...rejected, ...approved, ...existing];
  };

  _buildPlanColumns = () => {
    const columns = [
      {
        label: "Employee Name",
        key: "employeeName",
      },

      {
        label: "Employee Email",
        key: "email",
      },
      {
        label: "Last 4 SSN",
        key: "lastFourOfSsn",
      },
      {
        customComponent: (props) => {
          let status;

          if (this.props.approvedPlans[props.id]) {
            status = <StatusBox status="Approved" type="success" />;
          } else if (this.props.rejectedPlans[props.id]) {
            status = <StatusBox status="Rejected" type="danger" />;
          } else {
            status = <StatusBox status="Existing" type="complete" />;
          }
          return <div>{status}</div>;
        },
      },
    ];
    return columns;
  };

  _submitPlanRequest = () => {
    const acceptedIds = map(this.props.approvedPlans, (val, id) => id);
    const rejectedIds = map(this.props.rejectedPlans, (val, id) => id);

    const promises = [];

    if (!isEmpty(this.props.approvedPlans)) {
      promises.push(
        this.props.approveEmployeeLinks(this.props.client, acceptedIds)
      );
    }

    if (!isEmpty(this.props.rejectedPlans)) {
      promises.push(
        this.props.rejectEmployeeLinks(this.props.client, rejectedIds)
      );
    }

    Promise.all(promises).then(() => {
      if (!this.props.error) {
        toast.success("Successfully reviewed requests.");
        this.props.push("/dashboard/company/group");
      }
    });
  };

  render() {
    const columns = this._buildPlanColumns();
    const data = this._getApprovalData();

    const pending = this.props.pendingLinkRequests;
    const numberOfEmployees = size(pending);

    return (
      <>
        <Card>
          <div className="widget-header">
            <span>
              <Card.Title>
                {this.props.group.name}:
                <p className="invite-number">
                  Pending Employees (
                  <p className="number">{numberOfEmployees}</p>)
                </p>
              </Card.Title>
              <p className="detail">
                <span className="badge">
                  <span className="badge-style-plan-type">
                    <p className="type"> {capitalize(this.props.planType)}</p>
                  </span>
                </span>
              </p>
            </span>
          </div>

          <div className="plan-approval-table">
            <IconTable columns={columns} data={data} />
            <div className="close-btn">
              <Button
                onClick={() => this.props.goToNamedStep("existing")}
                btnLabel="Go Back"
                name="cancel"
                color="cancel"
              />
              <span style={{ paddingLeft: "10px" }}>
                <Button
                  btnLabel="Submit"
                  name="submit"
                  onClick={this._submitPlanRequest}
                  withArrow
                  loading={this.props.isUpdating}
                />
              </span>
            </div>
          </div>
        </Card>
      </>
    );
  }
}

const actions = [
  employerConstants.REJECT_PENDING_LINKED_EMPLOYEE,
  employerConstants.APPROVE_PENDING_LINKED_EMPLOYEE,
  employerConstants.GET_EMPLOYER_GROUPS,
];

const updatingSelector = createLoadingSelector(actions);

const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isUpdating: updatingSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  approveEmployeeLinks,
  rejectEmployeeLinks,
  getAllEmployerGroups,
  flushErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ApproveEmployeeLinkRequest));
