import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Col, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";

import Alert from "components/Alert";
import Button from "components/Button";

import { flushErrors } from "actions/errorActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { updateEntityLimit } from "actions/adminActions";
import { toast } from "react-toastify";
import { adminConstants } from "actions/types";
import { withApollo } from "@apollo/client/react/hoc";

const yup = require("yup");

const schema = yup.object({
  companyLimit: yup.number().label("Organization Company Limit").required(),
});

class ChangeOrgLimitModal extends React.PureComponent {
  static propTypes = {
    flushErrors: PropTypes.func,
    onClose: PropTypes.func,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    companyId: PropTypes.string,
    updateEntityLimit: PropTypes.func,
    client: PropTypes.object,
    onSuccess: PropTypes.func,
    companyLimit: PropTypes.number,
    organizationId: PropTypes.string,
  };

  _onClose = () => {
    this.props.flushErrors();
    this.props.onClose();
  };

  _submitNewOrgLimit = async ({ companyLimit }) => {
    await this.props.updateEntityLimit(this.props.client, {
      entityId: this.props.organizationId,
      entityType: "ORGANIZATION",
      key: "OrganizationCompanyLimit",
      value: companyLimit.toString(),
    });

    if (!this.props.error) {
      toast.success("Successfully updated company limit.");
      this.props.onSuccess(companyLimit);
    }
  };

  render() {
    return (
      <Formik
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={(values) => {
          this._submitNewOrgLimit(values);
        }}
        enableReinitialize={true}
        initialValues={{
          companyLimit: this.props.companyLimit,
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal
              centered
              scrollable
              show={true}
              onHide={this._onClose}
              className="icon-modal"
            >
              <Modal.Header closeButton>
                Organization Company Limits
              </Modal.Header>

              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGroupName">
                    <Form.Label>
                      Max number of companies for Organization
                    </Form.Label>
                    <Form.Control
                      name="companyLimit"
                      type={"number"}
                      placeholder="Organization Company Limit"
                      value={values.companyLimit}
                      onChange={handleChange}
                      isInvalid={!!errors.companyLimit}
                      isValid={touched.companyLimit && !errors.companyLimit}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyLimit}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {this.props.error && (
                  <Alert type="error" msg={this.props.error} />
                )}
              </Modal.Body>
              <Modal.Footer>
                <div className="submit-row">
                  <Button
                    btnLabel="Update Company Limit"
                    name="submit"
                    withArrow={true}
                    loading={this.props.isFetching}
                    onClick={() => handleSubmit(values)}
                  />
                  <Button
                    btnLabel="Cancel"
                    name="cancel"
                    color="cancel"
                    type="button"
                    onClick={this.props.onClose}
                  />
                </div>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    );
  }
}

const orgEvent = adminConstants.UPDATE_ENTITY_LIMIT;

const loadingSelector = createLoadingSelector(orgEvent);
const errorSelector = createErrorSelector(orgEvent);

const mapStateToProps = (state) => {
  return {
    isFetching: loadingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  flushErrors,
  updateEntityLimit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ChangeOrgLimitModal));
