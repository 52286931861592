import React from "react";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { PropTypes } from "prop-types";

import SimpleModal from "components/SimpleModal";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { disableCompany } from "actions/adminActions";
import { adminConstants } from "actions/types";
import { toast } from "react-toastify";
import { get } from "lodash";

class DisableCompanyModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    disableCompany: PropTypes.func.isRequired,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    client: PropTypes.object.isRequired,
  };

  _disableCompany = () => {
    this.props
      .disableCompany(this.props.client, this.props.company.id)
      .then((response) => {
        if (!this.props.error) {
          const state = get(response, "data.state");
          this.props.onClose(state);
          toast.success("Successfully disabled company.");
        }
      });
  };

  render() {
    return (
      <SimpleModal
        show={true}
        onClose={this.props.onClose}
        onSubmit={this._disableCompany}
        title="Disable Company"
        buttonText="Disable"
        closeButtonText="Cancel"
        isXhrRequest={true}
        error={this.props.error}
        isFetching={this.props.isFetching}
      >
        <p>
          {"Are you sure you want to disable "}
          <b>{this.props.company.name}</b>
          {"? This action is not reversible."}
        </p>
      </SimpleModal>
    );
  }
}

const isFetchingSelector = createLoadingSelector(
  adminConstants.DISABLE_COMPANY
);
const errorSelector = createErrorSelector(adminConstants.DISABLE_COMPANY);

const mapStateToProps = (state) => {
  return {
    isFetching: isFetchingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  disableCompany,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(DisableCompanyModal));
