import React from "react";
import PropTypes from "prop-types";
import IconSpinner from "components/IconSpinner";
import IconEmptyState from "components/IconEmptyState";
import AddEmployeeUploadFormToggle from "pages/dashboards/employerDashboard/userManagement/AddEmployeeUploadFormToggle";

import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { get, isEmpty } from "lodash";
import { FiFileText } from "react-icons/fi";
import { push } from "connected-react-router";
import { EmployeeUploadJobTable } from "pages/dashboards/employerDashboard/userManagement/EmployeeUploadJobTable";
import { getEmployeeDocumentUploadJobs } from "actions/employerActions";
import { employerConstants } from "actions/types";
import { createErrorSelector } from "store/selectors";

class RosterUploads extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    companyId: PropTypes.string,
    client: PropTypes.object,
    getEmployeeDocumentUploadJobs: PropTypes.func,
    employeeUploadJobs: PropTypes.array,
    isIconAdmin: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      jobsForEmployer: {},
      initialFetching: true,
      showForm: false,
    };
  }

  async componentDidMount() {
    await this.props.getEmployeeDocumentUploadJobs(
      this.props.client,
      this.props.companyId
    );

    this.setState({ initialFetching: false });
  }

  _showForm = () => {
    this.setState({ showForm: true });
  };

  render() {
    let content;

    if (this.state.initialFetching) {
      content = <IconSpinner centered />;
    } else if (!this.state.showForm && isEmpty(this.state.employeeUploadJobs)) {
      content = (
        <IconEmptyState
          header={"Empty Roster"}
          subheader="Employer has not uploaded a roster yet."
          icon={<FiFileText color="white" stroke="#60A4BF" size={16} />}
          actionText={"Upload File"}
          onClick={this._showForm}
        />
      );
    } else if (this.state.showForm) {
      content = (
        <AddEmployeeUploadFormToggle
          onClose={() => this.setState({ showForm: false })}
          isIconAdmin={this.props.isIconAdmin}
          companyId={this.props.companyId}
          onUploadSuccess={() => {
            this.props.push("/dashboard/users/employees");
          }}
        />
      );
    } else {
      <EmployeeUploadJobTable
        isIconAdmin={true}
        employeeUploadJobs={this.props.employeeUploadJobs}
        companyId={this.props.companyId}
      />;
    }

    return (
      <div id="admin-roster-page" className="roster-uploads">
        {content}
      </div>
    );
  }
}

const actions = [employerConstants.GET_EMPLOYEE_DOCUMENT_UPLOAD_JOBS];

const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    employeeUploadJobs: get(state, "employer.employeeUploadJobs", []),
  };
};

const mapDispatchToProps = {
  push,
  getEmployeeDocumentUploadJobs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(RosterUploads));
