import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { filter, get } from "lodash";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { withApollo } from "@apollo/client/react/hoc";
import { FiAlertCircle } from "react-icons/fi";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { balancesType, transactionType } from "statics/propTypes";
import { formatCurrency } from "utils/number";
import {
  getIraAccountIdSelector,
  isIndividualAccountOverview,
} from "store/selectors/user";

class BalanceKpi extends React.PureComponent {
  static propTypes = {
    iraBalances: balancesType,
    iraAccountId: PropTypes.string,
    createdAt: PropTypes.string,
    client: PropTypes.object,
    hasBankAccount: PropTypes.bool,
    userInfo: PropTypes.object,
    transactions: PropTypes.arrayOf(transactionType),
    isAccountOverview: PropTypes.bool,
    iraPendingDistributions: PropTypes.string,
    iraPendingContribution: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {};
  }

  _renderTooltip = (props) => (
    <Tooltip className="tooltip" {...props}>
      This balance does not include pending transactions.
    </Tooltip>
  );

  _numberOfPendingAch = () => {
    return filter(this.props.transactions, (transaction) => {
      return (
        transaction.category === "IRA_CONTRIBUTION" &&
        (transaction.status === "PENDING" || transaction.status === "HOLD")
      );
    }).length;
  };

  _numberOfPendingAchWithDrawls = () => {
    return filter(this.props.transactions, (transaction) => {
      return (
        transaction.category === "IRA_WITHDRAWAL" &&
        (transaction.status === "PENDING" || transaction.status === "HOLD")
      );
    }).length;
  };

  render() {
    const numberOfTrans = this._numberOfPendingAch();
    const numberOfwidthrwals = this._numberOfPendingAchWithDrawls();
    const pendingContributions = formatCurrency(
      this.props.iraPendingContribution
    );

    const pendingDistributions = formatCurrency(
      this.props.iraPendingDistributions
    );

    return (
      <div className="mega-container" id="user-contributions">
        <div className="portfolio-sm-screen">
          {this.props.isAccountOverview && (
            <div className="user-greeting">
              <p className="user-name">
                Hello, {this.props.userInfo.firstName}.
              </p>
              <p className="account-status">
                Here is what is happening in your account today!
              </p>
            </div>
          )}
        </div>
        <div className="portfolio-lg-screen"></div>
        <Card className="dashboard-widget balance-kpi">
          <div className="portfolio-lg-screen">
            <div className="dashboard-greeting">
              {!this.props.isAccountOverview && (
                <div className="welcome-user">
                  <h2 className="user-name">
                    Hello, {this.props.userInfo.firstName}.
                  </h2>
                  <p className="user-status">
                    Here is what is happening in your account today!
                  </p>
                </div>
              )}
              <div className="account-details">
                <p className="account-balance">
                  {formatCurrency(this.props.iraBalances.totalAccountBalance)}
                </p>
                <p className="account-text">
                  Account Balance{"  "}
                  <OverlayTrigger
                    className="OverlayTrigger"
                    placement="right"
                    delay={{ show: 250, hide: 200 }}
                    overlay={this._renderTooltip}
                    containerPadding={20}
                    transition={true}
                  >
                    <FiAlertCircle
                      className="FiAlertCircle"
                      size={"14px"}
                      fill={"#FFFFFF"}
                      color={"#FFFFFF"}
                      stroke={"#635bff"}
                      strokeWidth={"1.5"}
                    />
                  </OverlayTrigger>
                </p>

                <div className="account-pending">
                  <p className="deposit">
                    <BsArrowRight className="pending-arrow" />
                    {numberOfTrans} pending deposits for{"  "}
                    <b className="b">{pendingContributions}</b>
                    total.
                  </p>
                  <p className="deposit">
                    <BsArrowLeft className="pending-arrow" />
                    {numberOfwidthrwals} pending withdrawals for{"  "}
                    <b className="b">{pendingDistributions}</b>
                    total.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="portfolio-sm-screen">
            <div>
              <h2 className="account-balance">
                {formatCurrency(this.props.iraBalances.totalAccountBalance)}
              </h2>
              <p className="account-text">
                Account Balance{"  "}
                <OverlayTrigger
                  className="OverlayTrigger"
                  placement="right"
                  delay={{ show: 250, hide: 200 }}
                  overlay={this._renderTooltip}
                  containerPadding={20}
                  transition={true}
                >
                  <FiAlertCircle
                    className="FiAlertCircle"
                    size={"14px"}
                    fill={"#FFFFFF"}
                    color={"#FFFFFF"}
                    stroke={"#6772e5"}
                    strokeWidth={"1.5"}
                  />
                </OverlayTrigger>
              </p>
            </div>
            <div className="account-pending">
              <p className="deposit">
                <BsArrowRight className="pending-arrow" />
                {numberOfTrans} pending deposits for{"  "}
                <b className="b">{pendingContributions}</b>
                total.
              </p>
              <p className="deposit">
                <BsArrowLeft className="pending-arrow" />
                {numberOfwidthrwals} pending withdrawals for{"  "}
                <b className="b">{pendingDistributions}</b>
                total.
              </p>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const accounts = state.accounts.iraAccounts;
  const iraAccountId = getIraAccountIdSelector(state);
  const iraAccount = accounts.find((account) => account.id === iraAccountId);
  const iraBalances = get(iraAccount, "balances");
  const createdAt = get(iraAccount, "createdAt");
  const iraPendingContribution = get(iraBalances, "totalPendingContributions");
  const iraPendingDistributions = get(iraBalances, "totalPendingDistributions");

  const transactions = state.transactions.transactions;
  const isAccountOverview = isIndividualAccountOverview(state);
  const { profile: userInfo } = state.user;
  return {
    iraBalances,
    iraAccountId,
    createdAt,
    transactions,
    userInfo,
    isAccountOverview,
    iraPendingContribution,
    iraPendingDistributions,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(BalanceKpi));
