import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { includes } from "lodash";
import Alert from "components/Alert";
import Button from "components/Button";
import {
  registerUserRequest,
  updateIraAccountEmploymentStatus,
} from "actions/userActions";
import ProgressBar from "components/ProgressBar";
import CircularProgressBar from "components/CircularProgressBar";
import "./IndividualEnroll.scss";

import { userConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { withApollo } from "@apollo/client/react/hoc";
import { scrollToTop } from "utils/dom";
import queryString from "query-string";
import { kellyPlanId } from "statics/enterprisePlanIds";

import {
  noWhitespaceRegex,
  testUsername,
  whiteSpaceError,
} from "utils/fieldValidators";
import * as yup from "yup";
import { CREATE_ACCOUNT } from "statics/onboardingSteps";
import { ScrollToFieldError } from "utils/form";
import { invitationCodeParser } from "../../../utils/fieldValidators";

const schema = yup.object({
  username: yup
    .string()
    .label("Username")
    .required()
    .min(3, "Must be at least three characters")
    .max(25)
    .matches(noWhitespaceRegex, whiteSpaceError)
    .test("username", "Invalid username.", testUsername),
  email: yup
    .string()
    .label("Email")
    .email("You must enter a valid email address")
    .matches(noWhitespaceRegex, whiteSpaceError)
    .max(40)
    .required(),
  password: yup
    .string()
    .label("Password")
    .required()
    .min(8, "Seems a bit short...")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Must be at least 8 characters long with one uppercase, one lowercase, one number and one special character(e.g. #?!@$%^&*- )"
    ),
  confirmPassword: yup
    .string()
    .required()
    .label("Confirm password")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  invitationCode: yup.string().label("Invitation Code").notRequired(),
});

class IndividualSignup extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    registerUserRequest: PropTypes.func,
    error: PropTypes.string,
    userId: PropTypes.string,
    isFetching: PropTypes.bool,
    updateIraAccountEmploymentStatus: PropTypes.func,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    const invitationCode = queryString.parse(
      window.location.search
    ).invitationCode;

    const inviteId = queryString.parse(window.location.search).inviteId;

    this.state = {
      termsOfService: "",
      user: {
        username: "",
        email: "",
        role: "",
      },
      submitted: false,
      urlHadInviteCode: !!invitationCode,
      invitationCode: invitationCode || "",
      inviteId: inviteId || "",
      isKelly: includes(invitationCode, kellyPlanId),
    };
  }

  componentDidMount() {
    this._isMounted = true;
    scrollToTop();

    window.analytics.page("Individual Signup");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  submitUser = (values) => {
    let user = {
      username: values.username,
      email: values.email,
      password: values.password,
      role: "Individual",
      invitationCode: values.invitationCode,
      inviteId: this.state.inviteId,
    };

    this.props.registerUserRequest(this.props.client, user);

    if (this._isMounted) {
      this.setState({
        submitted: true,
      });
    }
  };

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    const disablePlanID = this.state.invitationCode;

    const title = this.state.isKelly
      ? "Welcome to your Kelly personal retirement plan"
      : "Welcome to Icon";

    return (
      <div className="individualSignup">
        <ProgressBar
          isEmployer={false}
          activeStepId={CREATE_ACCOUNT.id}
          progressPercent={"10"}
        />

        <Formik
          validateOnChange={false}
          validationSchema={schema}
          enableReinitialize={true}
          onSubmit={(values) => {
            this.submitUser(values);
          }}
          initialValues={{
            email: "",
            password: "",
            confirmPassword: "",
            username: "",
            invitationCode: this.state.invitationCode,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <div className="mega-container">
                <div
                  className="step-container is-active"
                  data-circle-percent="10"
                >
                  <section className="page-title-wrap">
                    <article className="text-cell">
                      <h1 className="page-title">{title}</h1>
                      <p className="page-subtext">
                        Let&#39;s start by creating your account login.
                      </p>
                    </article>
                    <article className="progress-cell">
                      <ul className="circular-progress-wrap">
                        <CircularProgressBar
                          strokeWidth="8"
                          sqSize="75"
                          percentage="10"
                        />
                      </ul>
                    </article>
                  </section>
                  <div id="form-employer-profile">
                    <section className="form-sec-2col">
                      <article className="col-form" style={articleStyle}>
                        <Form.Group controlId="formBasicUsername">
                          <Form.Control
                            name="username"
                            placeholder="Username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.username && !!errors.username}
                            isValid={touched.username && !errors.username}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Personal Email Address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.email && !!errors.email}
                            isValid={touched.email && !errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.password && !!errors.password}
                            isValid={touched.password && !errors.password}
                          />
                          <Form.Control.Feedback className="valid-account-creation">
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicPasswordConfirm">
                          <Form.Control
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.confirmPassword &&
                              !!errors.confirmPassword
                            }
                            isValid={
                              touched.confirmPassword && !errors.confirmPassword
                            }
                          />
                          <Form.Control.Feedback className="valid-account-creation">
                            Passwords match!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group sm={4} controlId="formBasicInvitationCode">
                          <Form.Control
                            disabled={disablePlanID}
                            name="invitationCode"
                            value={values.invitationCode}
                            placeholder="Plan ID"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              const value = invitationCodeParser(
                                event,
                                values.invitationCode
                              );
                              setFieldValue("invitationCode", value);
                            }}
                            isInvalid={
                              touched.invitationCode && !!errors.invitationCode
                            }
                            // isValid={!errors.invitationCode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.invitationCode}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </article>
                    </section>

                    <section className="form-sec-2col">
                      <article className="col-form">
                        <p
                          style={{
                            fontStyle: "italic",
                            fontSize: 14,
                            paddingTop: 10,
                            paddingLeft: 4,
                            color: "#0a2540",
                          }}
                        >
                          By pressing the Continue button to create your login
                          you are certifying that you have read and agree to our{" "}
                          <a
                            href="https://iconsavingsplan.com/terms"
                            className="modal-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#01a3b0",
                            }}
                          >
                            Terms of Service
                          </a>
                          .
                        </p>
                        {this.props.error && (
                          <Alert type="error" msg={this.props.error} />
                        )}
                        <div className="submit-row">
                          <Button
                            name="submit"
                            withArrow={true}
                            loading={this.props.isFetching}
                          />
                        </div>
                      </article>
                    </section>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([
  userConstants.REGISTER_USER,
  userConstants.USER_ON_BOARDING_EMPLOYMENT_STATUS_UPDATE,
]);
const errorSelector = createErrorSelector([
  userConstants.REGISTER_USER,
  userConstants.USER_ON_BOARDING_EMPLOYMENT_STATUS_UPDATE,
]);

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = {
  registerUserRequest,
  updateIraAccountEmploymentStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualSignup));
