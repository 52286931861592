import React from "react";
import PropTypes from "prop-types";
import { findIndex, map } from "lodash";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import {
  activeRolloverAccountSameAsCurrent,
  newActiveRolloverAccount,
  activeRolloverIsMixed,
} from "store/selectors/user";
import {
  adminSteps,
  employerMultiAccountSteps,
  employerMultiWithBillingSteps,
  employerOnboardingSteps,
  employerOnboardingWithBillingSteps,
  individualOnboardingSteps,
  IndividualRolloverSameAsCurrent,
  IndividualMultiAccountSteps,
} from "statics/onboardingSteps";

import CircularProgressBar from "./CircularProgressBar";
import ProgressItem from "components/ProgressItem";

class ProgressBar extends React.Component {
  static propTypes = {
    isEmployer: PropTypes.bool,
    isAdministrator: PropTypes.bool,
    isMultiAccount: PropTypes.bool,
    isRolloverAccount: PropTypes.bool,
    showBillingRoute: PropTypes.bool,
    isRolloverSameAsCurrent: PropTypes.bool,
    activeStepId: PropTypes.string,
    progressPercent: PropTypes.string,
    isRolloverIsMixed: PropTypes.bool,
  };

  _buildStepsSection(steps) {
    const { activeStepId } = this.props;
    const stepItems = map(steps, (step, idx) => {
      const activeStepIdx = findIndex(steps, { id: activeStepId });
      const currentStepIdx = findIndex(steps, { id: step.id });
      const isActive = step.id === activeStepId;
      const isComplete = currentStepIdx < activeStepIdx;

      /*
       individual has a few states where user has done all steps and is in a waiting period/additional work
       so want to show previous states were completed and not show the transitory step in the progress bar
       */

      if (step.hidden) {
        return null;
      }

      return (
        <ProgressItem
          key={step.id}
          isComplete={isComplete}
          isActive={isActive}
          stepNum={idx + 1}
          stepLabel={step.label}
          stepTime={step.stepTime}
          hidden={step.hidden}
        />
      );
    });

    return (
      <section className="steps-bar-module">
        <article className="steps-cell">
          <ul>{stepItems}</ul>
        </article>
        <article className="progress-cell">
          <CircularProgressBar
            strokeWidth="8"
            sqSize="100"
            percentage={this.props.progressPercent}
          />
        </article>
      </section>
    );
  }

  render() {
    if (this.props.isAdministrator) {
      return this._buildStepsSection(adminSteps);
    } else if (this.props.isEmployer) {
      if (this.props.isMultiAccount) {
        return this._buildStepsSection(
          this.props.showBillingRoute
            ? employerMultiWithBillingSteps
            : employerMultiAccountSteps
        );
      } else {
        return this._buildStepsSection(
          this.props.showBillingRoute
            ? employerOnboardingWithBillingSteps
            : employerOnboardingSteps
        );
      }
    } else {
      if (this.props.isRolloverAccount || this.props.isRolloverIsMixed) {
        return this._buildStepsSection(IndividualMultiAccountSteps);
      } else if (this.props.isRolloverSameAsCurrent) {
        return this._buildStepsSection(IndividualRolloverSameAsCurrent);
      } else {
        return this._buildStepsSection(individualOnboardingSteps);
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isRolloverAccount: newActiveRolloverAccount(state),
    isRolloverSameAsCurrent: activeRolloverAccountSameAsCurrent(state),
    isRolloverIsMixed: activeRolloverIsMixed(state),
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ProgressBar));
