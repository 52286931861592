import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withApollo } from "@apollo/client/react/hoc";
import { get } from "lodash";
import { connect } from "react-redux";
import { EmployerApplicationApproved, lifecycleStates } from "statics/states";
import { adminSite, employerSite, individualSite } from "utils/determineSite";
import NavBar from "components/navbar/NavBar";

class IconLayout extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    companyState: PropTypes.string,
    userState: PropTypes.string,
    routes: PropTypes.func,
    isSmallScreen: PropTypes.bool,
    isIndividualSubNav: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  styleSheetId = () => {
    if (employerSite()) {
      return "employer-portal";
    } else if (individualSite()) {
      return "individual-portal";
    } else if (adminSite()) {
      return "admin-portal";
    }
  };

  render() {
    const isEmployerDashboard = [EmployerApplicationApproved].includes(
      this.props.companyState
    );

    return (
      <div id="icon-layout">
        <main role="main" id={this.styleSheetId()}>
          <div className="max-container">
            <NavBar />
          </div>
          <div className="max-container main-body">
            <div
              className={classnames({
                container: !adminSite(),
              })}
            >
              <div
                className={classnames("padding", {
                  ["remove-padding"]: isEmployerDashboard,
                })}
              >
                <div
                  className={classnames("body-content-wrap", {
                    ["subnav-body-content-wrap"]:
                      this.props.isIndividualSubNav &&
                      !this.props.isSmallScreen,
                  })}
                >
                  {this.props.routes}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const userState = get(state.user, "userState.state", "");
  return {
    ownProps,
    companyState: get(state.user, "companyState.state"),
    isIndividualSubNav: lifecycleStates.includes(userState) && individualSite(),
    userState,
  };
};

export default connect(mapStateToProps)(withApollo(IconLayout));
