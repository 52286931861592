import { sidebarConstants } from "./types";

export const sidebarShow = () => {
  return (dispatch) => {
    dispatch({ type: sidebarConstants.SIDEBAR_SHOW });
  };
};

export const sidebarHide = () => {
  return (dispatch) => {
    dispatch({ type: sidebarConstants.SIDEBAR_HIDE });
  };
};
