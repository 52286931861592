import React from "react";
import PropTypes from "prop-types";
import {
  map,
  each,
  filter,
  includes,
  orderBy,
  isEmpty,
  size,
  startCase,
} from "lodash";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { getReports } from "actions/adminActions";
import { createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { Form, Card, Row, Col } from "react-bootstrap";
import { simulateLinkClickNewTab } from "utils/dom";
import { getComplianceReportLink } from "services/adminService";
import { toast } from "react-toastify";
import { FiFolder } from "react-icons/fi";

import Button from "components/Button";
import moment from "moment";
import IconSpinner from "components/IconSpinner";
import IconTable from "components/IconTable";
import IconTableHeader from "components/IconTableHeader";
import IconEmptyState from "components/IconEmptyState";
import IconHeader from "components/IconHeader";

function buildDateSelectOptions(reportDates) {
  const seenMonthYears = {};
  const options = [];
  const sortedReportDates = orderBy(reportDates, (date) => date, "desc");
  each(sortedReportDates, (date) => {
    const monthYearString = moment(date).format("YYYY-MM");
    if (!seenMonthYears[monthYearString]) {
      options.push(
        <option value={monthYearString} key={monthYearString}>
          {monthYearString}
        </option>
      );
      seenMonthYears[monthYearString] = true;
    }
  });
  return options;
}

class AdminReports extends React.PureComponent {
  static propTypes = {
    getReports: PropTypes.func,
    isFetching: PropTypes.bool,
    client: PropTypes.object,
    reports: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  };

  constructor() {
    super();

    this.state = {
      selectedReportType: "",
      selectedYearMonth: "",
    };
  }

  componentDidMount() {
    this.props.getReports(this.props.client);
  }

  _downloadCSV = (date) => {
    getComplianceReportLink(
      this.props.client,
      this.state.selectedReportType,
      date
    )
      .then((result) => {
        simulateLinkClickNewTab(result.uri);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  _reportData = () => {
    const reportHistory = filter(
      this.props.reports[this.state.selectedReportType],
      (date) => includes(date, this.state.selectedYearMonth)
    );
    return map(
      orderBy(reportHistory, (date) => date, "desc"),
      (date) => {
        return {
          date,
        };
      }
    );
  };

  _reportColumns = () => {
    const columns = [
      {
        label: "Report Date",
        key: "date",
        customComponent: (props) => {
          const commonDateFormat = "MMM Do YYYY";
          return <> {moment(props.date).format(commonDateFormat)}</>;
        },
      },
      {
        customComponent: (props) => {
          return (
            <span style={{ textAlign: "center", width: 100 }}>
              <Button
                btnLabel="Download CSV"
                name="action"
                color="action"
                size="sm"
                type="button"
                onClick={() => {
                  this._downloadCSV(props.date);
                }}
              />
            </span>
          );
        },
      },
    ];
    return columns;
  };

  render() {
    if (this.props.isFetching) {
      return <IconSpinner centered />;
    }
    const selectedReportTypeHasData = !isEmpty(
      this.props.reports[this.state.selectedReportType]
    );

    const columns = this._reportColumns();
    const data = this._reportData();

    const historyLabel = startCase(this.state.selectedReportType);

    return (
      <div id="admin-reports">
        <h2 style={{ color: "#0a2540" }}>Compliance Reports</h2>
        <Card>
          <Row style={{ paddingLeft: 15, paddingTop: 15 }}>
            <Form.Group as={Col} md={6}>
              <IconHeader
                variant="labelHeader"
                headerText="Select Report Type"
              />
              <Form.Control
                sm={4}
                as="select"
                name="reportType"
                value={this.state.selectedReportType}
                onChange={(e) =>
                  this.setState({
                    selectedReportType: e.target.value,
                    selectedYearMonth: "",
                  })
                }
              >
                <option value="" disabled>
                  Select Report Type
                </option>
                {map(this.props.reports, (item, reportType) => (
                  <option value={reportType} key={reportType}>
                    {reportType}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Row>
          {this.state.selectedReportType && selectedReportTypeHasData && (
            <Row style={{ paddingLeft: 15, paddingTop: 15 }}>
              <Form.Group as={Col} md={6}>
                <IconHeader
                  variant="labelHeader"
                  headerText="Select Report Month"
                />
                <Form.Control
                  sm={4}
                  as="select"
                  name="selectedYearMonth"
                  value={this.state.selectedYearMonth}
                  onChange={(e) =>
                    this.setState({ selectedYearMonth: e.target.value })
                  }
                >
                  <option value="" disabled>
                    Select Report Month
                  </option>
                  {buildDateSelectOptions(
                    this.props.reports[this.state.selectedReportType]
                  )}
                </Form.Control>
              </Form.Group>
            </Row>
          )}
          {!selectedReportTypeHasData && this.state.selectedReportType !== "" && (
            <Row>
              <Col>
                <IconEmptyState
                  header="Empty Report"
                  subheader="It looks like we have not generated any reports for the above
                report type"
                  icon={<FiFolder color="white" stroke="#60A4BF" size={16} />}
                />
              </Col>
            </Row>
          )}
          {this.state.selectedReportType && this.state.selectedYearMonth && (
            <Row>
              <Col>
                <IconTableHeader
                  tableHeader={historyLabel}
                  tableCountLabel="Reports"
                  tableCount={size(this.state.selectedReportType)}
                />

                <IconTable columns={columns} data={data} />
              </Col>
            </Row>
          )}
        </Card>
      </div>
    );
  }
}

const isFetching = createLoadingSelector(adminConstants.GET_COMPLIANCE_REPORTS);

const mapStateToProps = (state) => {
  return {
    reports: state.admin.complianceReports,
    isFetching: isFetching(state),
  };
};

const mapDispatchToProps = {
  getReports,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AdminReports));
