import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { FiX } from "react-icons/fi";
import { withApollo } from "@apollo/client/react/hoc";
import { Formik } from "formik";
import { Card, Col, Form, Row } from "react-bootstrap";
import { filter, get, isEmpty, map, slice } from "lodash";
import { toast } from "react-toastify";
import Button from "components/Button";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import Alert from "components/Alert";
import EmployeeInvitationsTable from "./EmployeeInvitationsTable";
import {
  getEmployeeInvitations,
  inviteEmployees,
  stageEmployeeInvites,
  updateProgressiveOnboarding,
} from "actions/employerActions";

import { hasFullyOnboarded } from "store/selectors/employer";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { employeeInvitationType } from "statics/propTypes";

import "./AddEmployeeForm.scss";
import { progressiveOnboardingEvents } from "statics/states";
import { ScrollToFieldError } from "utils/form";

const maxEmailInput = 250;

let yup = require("yup");

const schema = yup.object({
  userEmails: yup.array(yup.string()).label("User Emails").required(),
  groupId: yup.string().required(),
});

class AddEmployeeForm extends React.PureComponent {
  static propTypes = {
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    push: PropTypes.func,
    isSubmitting: PropTypes.bool,
    inviteEmployees: PropTypes.func,
    stageEmployeeInvites: PropTypes.func,
    getEmployeeInvitations: PropTypes.func,
    client: PropTypes.object,
    employeeInvitations: PropTypes.objectOf(employeeInvitationType),
    error: PropTypes.string,
    isInviting: PropTypes.bool,
    inviteEmployeesHide: PropTypes.func,
    hasFullyOnboarded: PropTypes.bool,
    getEmployerGroupsByCompanyId: PropTypes.func,
    updateProgressiveOnboarding: PropTypes.func,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      userEmails: [],
      groupId: props.groups[0].id,
      showAcceptedError: false,
      showInviteSummary: false,
    };
  }

  async componentDidMount() {
    this.setState({
      initialFetching: false,
    });
  }

  _inviteUsers = (values) => {
    const uniqEmails = filter(values.userEmails, (email) => {
      const previousInvite = get(this.props.employeeInvitations, email);
      if (!previousInvite) return true;

      // if previous invite wasn't accepted then we can just resend safely
      return previousInvite.status !== "ACCEPTED";
    });

    if (isEmpty(uniqEmails)) {
      this.setState({ showAcceptedError: true });
      return;
    }
    if (this.state.showAcceptedError) {
      this.setState({ showAcceptedError: false });
    }

    if (this.props.hasFullyOnboarded) {
      this.props
        .inviteEmployees(this.props.client, {
          ...values,
          userEmails: uniqEmails,
        })
        .then(() => {
          if (!this.props.error) {
            toast.success("Successfully invited users.");
            this.setState({ showForm: true });
          }
        });
    } else if (!this.props.hasFullyOnboarded) {
      this.props
        .stageEmployeeInvites(this.props.client, {
          groupId: values.groupId,
          userEmails: uniqEmails,
        })
        .then(() => {
          return this.props.updateProgressiveOnboarding(
            this.props.client,
            progressiveOnboardingEvents.InviteUsers
          );
        })
        .then(() => {
          if (!this.props.error) {
            if (this.props.onSuccess) {
              this.props.onSuccess();
            } else {
              this.setState({ showForm: true });
            }
          }
        });
    }
  };

  _renderForm() {
    return (
      <div className="download-employee-template">
        <div className="main-content">
          <Row>
            <Col>
              <Card>
                <Card.Title>
                  <p className="page-header">Add Employees</p>
                  <p className="page-subtext">
                    Select a group and add employees who are ready to join Icon.
                  </p>
                </Card.Title>
                <Formik
                  validateOnChange={false}
                  validationSchema={schema}
                  onSubmit={this._inviteUsers}
                  enableReinitialize={true}
                  initialValues={{
                    ...this.state,
                    employeeInvitations: this.props.employeeInvitations,
                    groupId: this.state.groupId,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    handleBlur,
                    setFieldValue,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <ScrollToFieldError />
                      <Card.Body>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={6}
                            controlId="formBasicGroupId"
                          >
                            <Form.Label>Payroll Group</Form.Label>
                            <Form.Control
                              as="select"
                              name="groupId"
                              value={values.groupId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.groupId && !!errors.groupId}
                              isValid={touched.groupId && !errors.groupId}
                            >
                              {map(this.props.groups, (group) => (
                                <option key={group.id} value={group.id}>
                                  {group.name}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.groupId}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row className="employee-emails">
                          <Form.Group
                            as={Col}
                            md={6}
                            controlId="formBasicUserEmail"
                          >
                            <Form.Label>Employee Emails:</Form.Label>
                            <ReactMultiEmail
                              placeholder="employee1@company.com,"
                              emails={values.userEmails}
                              onChange={(userEmails) => {
                                const limitedEmails = slice(
                                  userEmails,
                                  0,
                                  maxEmailInput
                                );
                                setFieldValue("userEmails", limitedEmails);
                              }}
                              validateEmail={(email) => {
                                return isEmail(email);
                              }}
                              getLabel={(email, index) => {
                                return (
                                  <div data-tag key={index}>
                                    {email}
                                    <span
                                      data-tag-handle
                                      onClick={() => {
                                        const newEmailsVal = filter(
                                          values.userEmails,
                                          (val, idx) => idx !== index
                                        );
                                        setFieldValue(
                                          "userEmails",
                                          newEmailsVal
                                        );
                                      }}
                                    >
                                      <FiX
                                        style={{ marginRight: 2 }}
                                        size={14}
                                        color={"#858C9C"}
                                      />
                                    </span>
                                  </div>
                                );
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.userEmails}
                            </Form.Control.Feedback>
                            <p
                              className="disclaimer"
                              style={{
                                marginTop: 8,
                                marginBottom: 8,
                                paddingLeft: 4,
                              }}
                            >
                              * You can invite up to {maxEmailInput} employees
                              at a time.
                            </p>
                          </Form.Group>
                        </Form.Row>
                      </Card.Body>

                      <Form.Group as={Col} className="invite">
                        {this.props.error && (
                          <Alert type="error" msg={this.props.error} />
                        )}
                        {this.state.showAcceptedError && (
                          <Alert
                            type="error"
                            msg={
                              "Employee has already been invited and started enrollment. Check the Dashboard Enrolling list. "
                            }
                          />
                        )}

                        <div className="submit-row">
                          <span className="cancel-btn">
                            {!this.props.hasFullyOnboarded && (
                              <Button
                                btnLabel="Cancel"
                                name="cancel"
                                color="secondary"
                                type="button"
                                onClick={this.props.onCancel}
                              />
                            )}
                          </span>

                          {!this.props.hasFullyOnboarded && (
                            <Button
                              btnLabel="Create Invites"
                              name="submit"
                              withArrow={true}
                              loading={this.props.isSubmitting}
                            />
                          )}
                          {this.props.hasFullyOnboarded && (
                            <Button
                              btnLabel="Invite Employees"
                              name="submit"
                              withArrow={true}
                              loading={this.props.isInviting}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    const content = this.state.showForm ? (
      <EmployeeInvitationsTable
        onClose={() => this.setState({ showForm: false })}
      />
    ) : (
      this._renderForm()
    );
    return (
      <div className="add-employee-form" style={{ marginBottom: 8 }}>
        {content}
      </div>
    );
  }
}

const isInvitingSelector = createLoadingSelector(
  employerConstants.INVITE_EMPLOYEE
);

const errorSelector = createErrorSelector(
  employerConstants.INVITE_EMPLOYEE,
  employerConstants.STAGE_EMPLOYEE_INVITE
);

const isSubmittingSelector = createLoadingSelector([
  employerConstants.STAGE_EMPLOYEE_INVITE,
]);

const mapStateToProps = (state) => {
  return {
    groups: state.employer.groups,
    error: errorSelector(state),
    isInviting: isInvitingSelector(state),
    employeeInvitations: state.employer.employeeInvitations,
    //OnBoardingSelectors
    hasFullyOnboarded: hasFullyOnboarded(state),
    isSubmitting: isSubmittingSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  inviteEmployees,
  stageEmployeeInvites,
  getEmployeeInvitations,
  updateProgressiveOnboarding,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddEmployeeForm));
