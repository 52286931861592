import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Modal } from "react-bootstrap";

import Button from "components/Button";
import Alert from "components/Alert";
import "./IndividualPortfolioSelection.scss";

import { portfolioConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";

class PortfolioConfirmationModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    isSubmitting: PropTypes.func,
    error: PropTypes.string,
    client: PropTypes.object,
    onSubmit: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedPortfolio: {},
      showConfirmationModal: false,
    };
  }

  render() {
    return (
      <Modal
        centered
        scrollable
        show={true}
        onHide={this.props.onClose}
        className="contribution-modal"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title className="title" id="contained-modal-title-vcenter">
            Confirm Investment Portfolio.
            <p className="confirmation-text">
              The investment portfolio you selected was not recommended by Icon.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-text">
            By selecting “I Agree” you acknowledge that you are independently
            choosing to elect a portfolio that is not recommended by Icon. The
            portfolio you selected will be managed by Icon according to the
            portfolios’ stated investment methodology which may not be suitable
            or appropriate for your financial situation, investment experience,
            investment objectives and risk tolerances. You further acknowledge
            your investment decision is made in contravention of the investment
            advice provided by Icon.
          </p>
          {this.props.error && <Alert type="error" msg={this.props.error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            btnLabel="Cancel"
            name="cancel"
            color="cancel"
            onClick={this.props.onClose}
          />
          <Button
            btnLabel="I agree"
            name="submit"
            withArrow
            loading={this.props.isSubmitting}
            onClick={this.props.onSubmit}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const action = portfolioConstants.UPDATE_USER_PORTFOLIO;
const loadingSelector = createLoadingSelector(action);
const errorSelector = createErrorSelector(action);

const mapStateToProps = (state) => {
  return {
    isSubmitting: loadingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(PortfolioConfirmationModal));
