import React from "react";
import PropTypes from "prop-types";
import "./PortfolioDetailCard.scss";
import { map, isEmpty } from "lodash";
import { portfolioMetadataMap } from "utils/individualPortfolioHelper";
import { formatPercentage } from "utils/number";

import { Card, ListGroup, ListGroupItem } from "react-bootstrap";

const PortfolioDetailCard = (props) => {
  const { assets, portfolioName } = props.portfolio;

  const portfolioMetadata = portfolioMetadataMap[portfolioName];

  if (isEmpty(props.portfolio) || isEmpty(portfolioMetadata)) return null;

  return (
    <div className="portfolio-card">
      <Card className="portfolio-detail">
        <Card.Body>
          <Card.Title className="card-title">
            <p className="card-label"> {portfolioMetadata.portfolio}</p>
            <p className="portfolio">Portfolio</p>
          </Card.Title>
          <div className="card-description">
            <p className="stock-bond-allocation">
              {portfolioMetadata.stock}
              {portfolioMetadata.stock && portfolioMetadata.bond && (
                <span className="divider">|</span>
              )}
              {portfolioMetadata.bond}
            </p>
            <p className="card-text">{portfolioMetadata.description}</p>
          </div>
        </Card.Body>
        <Card.Body className="asset-breakdown">Breakdown of assets</Card.Body>
        <ListGroup className="list-group-flush">
          {map(assets, (asset) => (
            <ListGroupItem key={asset.id}>
              <div className="asset-details">
                <div className="stock-symbol">
                  <p className="sym">{asset.symbol}</p>
                </div>
                {/* <p className="asset-name">Domestic equity</p> */}
                <p className="asset-percentage">
                  {formatPercentage(asset.allocation, 0)}
                </p>
              </div>
              <div className="allocation-bar">
                <div
                  className="allocation-fill"
                  style={{ width: formatPercentage(asset.allocation, 0) }}
                ></div>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Card>
    </div>
  );
};

PortfolioDetailCard.defaultProps = {
  portfolio: {},
};

PortfolioDetailCard.propTypes = {
  portfolio: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    portfolioName: PropTypes.string,
    assets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        symbol: PropTypes.string,
        allocation: PropTypes.number,
        assets: PropTypes.string,
      })
    ),
  }),
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default PortfolioDetailCard;
