import React from "react";
import { PropTypes } from "prop-types";
import { Card } from "react-bootstrap";
import { keyBy, map, size } from "lodash";
import { FiChevronLeft } from "react-icons/fi";
import Button from "components/Button";
import Alert from "components/Alert";
import IconSpinner from "components/IconSpinner";
import Kpi from "components/Kpi";
import IconTable from "components/IconTable";

class Summary extends React.PureComponent {
  static propTypes = {
    goToNamedStep: PropTypes.func,
    push: PropTypes.func,
    isFetching: PropTypes.bool,
    isUpdating: PropTypes.bool,
    client: PropTypes.object,
    targetGroup: PropTypes.object,
    sourceGroup: PropTypes.object,
    error: PropTypes.string,
    employeesForMigration: PropTypes.array,
    handleMigration: PropTypes.func,
  };

  _getSummaryData = () => {
    const linkRequestsByEmployeeId = keyBy(
      this.props.sourceGroup.associatedLinkRequests,
      "employeeId"
    );
    return map(this.props.employeesForMigration, (linkId) => {
      const linkRequest = linkRequestsByEmployeeId[linkId];
      return linkRequest;
    });
  };

  _buildSummaryColumns = () => {
    const columns = [
      {
        label: "First Name",
        key: "userProfile.firstName",
      },
      {
        label: "Last Name",
        key: "userProfile.lastName",
      },
      {
        label: "Email",
        key: "userProfile.email",
      },

      {
        label: "Last 4 SSN",
        key: "userProfile.lastFourOfSsn",
      },
    ];
    return columns;
  };

  _getSummarySection = () => {
    const payrollFrequencyMatch =
      this.props.sourceGroup.payrollFrequency ===
      this.props.targetGroup.payrollFrequency;

    return (
      <div className="summary-info-wrapper">
        <p className="summary-title">Just a reminder</p>
        <p className="summary-body">
          {payrollFrequencyMatch && (
            <>
              Employees with existing contributions will need to be re-approved
              on the next payroll run in the target group
            </>
          )}
          {!payrollFrequencyMatch && (
            <>
              Employees with existing contributions will need to select a new
              contribution amount due to the payroll frequency changing
            </>
          )}
        </p>
      </div>
    );
  };

  _getKpiSection = () => {
    const totalEmployeesMigrating = size(this.props.employeesForMigration);
    const previousSourceEmployeeCount = size(
      this.props.sourceGroup.associatedLinkRequests
    );
    const previousTargetEmployeeCount = size(
      this.props.targetGroup.associatedLinkRequests
    );

    return (
      <div className="kpis">
        <Kpi
          label="Source group updated employee count"
          value={previousSourceEmployeeCount - totalEmployeesMigrating}
        />
        <Kpi
          label="Target group updated employee count"
          value={previousTargetEmployeeCount + totalEmployeesMigrating}
        />
      </div>
    );
  };

  render() {
    const columns = this._buildSummaryColumns();
    const data = this._getSummaryData();

    return (
      <div>
        {this._getSummarySection()}
        {this._getKpiSection()}
        <Card>
          <div className="workflow-widget">
            <div className="action-header">
              <span className="group-info">
                <span className="group-name">
                  {this.props.targetGroup.name}:{" "}
                  <span className="invite-number">
                    Total employees migrating
                    <span className="number">
                      ({size(this.props.employeesForMigration)})
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>

          {this.props.isFetching && <IconSpinner centered />}
          <IconTable columns={columns} data={data} />
        </Card>
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        <div className="process-payroll-button-row">
          <Button
            icon={{
              icon: <FiChevronLeft size={14} stroke={"#0cb7c4"} />,
              position: "left",
            }}
            onClick={() => this.props.goToNamedStep("employeeSelection")}
            btnLabel="Go Back"
            name="cancel"
            color="cancel"
          />
          <Button
            name="submit"
            btnLabel={"Migrate Employees"}
            onClick={this.props.handleMigration}
            withArrow
            loading={this.props.isUpdating}
          />
        </div>
      </div>
    );
  }
}

export default Summary;
