import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";

import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";

export const InstantHelp = ({ title, description, lists }) => {
  return (
    <Card style={{ height: "80%" }}>
      <Card.Title style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
        <IconHeader variant="labelHeader" headerText={title} />
      </Card.Title>
      <Card.Body style={{ paddingTop: 0 }}>
        {description && <IconSubheader subheader={description} />}
        <div>
          {lists.map(
            (list, index) =>
              !list.hide && (
                <div key={index}>
                  {list.customComponent && list.customComponent({ ...list })}
                </div>
              )
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

InstantHelp.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      hide: PropTypes.bool,
      customComponent: PropTypes.func,
    })
  ),
};

export default InstantHelp;
