import React from "react";
import SimpleModal from "components/SimpleModal";
import JSONPretty from "react-json-pretty";
import PropTypes from "prop-types";

const WorkflowParamsModal = ({ workflowParams, onClose }) => {
  return (
    <SimpleModal
      show={true}
      size={"xl"}
      onClose={() => onClose()}
      title="Workflow Params"
      buttonText="close"
    >
      <JSONPretty
        data={workflowParams}
        themeClassName="custom-json-pretty"
        style={{ fontSize: "14px", margin: 15 }}
        mainStyle="background:#F7F7F7; line-height:1.3; padding: 20px; ;overflow:auto; color:#999;"
        keyStyle="color:#905;"
        stringStyle="color:#690;"
        valueStyle="color:#905;"
        booleanStyle="color:#905;"
      />
    </SimpleModal>
  );
};

WorkflowParamsModal.propTypes = {
  workflowParams: PropTypes.object,
  onClose: PropTypes.func,
};

export default WorkflowParamsModal;
