import queryString from "query-string";
import React from "react";
import { compact, get, has, keyBy, map, transform } from "lodash";
import PropTypes from "prop-types";
import { Col, Form } from "react-bootstrap";
import Button from "components/Button";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiCalendar } from "react-icons/fi";
import Alert from "components/Alert";

import { ScrollToFieldError } from "utils/form";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { createCustomReport, getCustomReportTypes } from "actions/adminActions";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import IconSpinner from "components/IconSpinner";
import { push } from "connected-react-router";
import { toast } from "react-toastify";

const yup = require("yup");

const parseDate = (val) => {
  if (val) {
    return Date.parse(val);
  }
  return val;
};

function generateDateField({
  handleBlur,
  values,
  touched,
  errors,
  setFieldValue,
  inputParams,
}) {
  return (
    <>
      <Form.Label>
        {inputParams.description} {inputParams.required ? "(Required)" : ""}
      </Form.Label>

      <div className="date-picker">
        <span className="calendar">
          <FiCalendar
            size={"18px"}
            color={"#FFFFFF"}
            fill={"#FFFFFF"}
            stroke={"#AAB1BF"}
            strokeWidth={"2"}
          />
        </span>
        <DatePicker
          className="form-control"
          name={inputParams.name}
          value={parseDate(values[inputParams.name])}
          dateFormat="MM/dd/yyyy"
          selected={
            (values[inputParams.name] && new Date(values[inputParams.name])) ||
            null
          }
          onChange={(val) => {
            setFieldValue(inputParams.name, new Date(val));
          }}
          onBlur={handleBlur}
          isInvalid={touched[inputParams.name] && !!errors[inputParams.name]}
          isValid={touched[inputParams.name] && !errors[inputParams.name]}
        />
      </div>
      {errors[inputParams.name] && touched[inputParams.name] && (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {errors[inputParams.name]}
        </div>
      )}
    </>
  );
}

function generateStringInputField({
  handleChange,
  handleBlur,
  values,
  touched,
  errors,
  inputParams,
}) {
  return (
    <>
      <Form.Label>
        {inputParams.description} {inputParams.required ? "(Required)" : ""}
      </Form.Label>
      <Form.Control
        name={inputParams.name}
        as={inputParams.isArray ? "textarea" : "input"}
        value={values[inputParams.name]}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={touched[inputParams.name] && !!errors[inputParams.name]}
        isValid={touched[inputParams.name] && !errors[inputParams.name]}
      />
      <Form.Control.Feedback type="invalid">
        {errors[inputParams.name]}
      </Form.Control.Feedback>
    </>
  );
}

function cleanAndSplitIds(idsString) {
  if (idsString.includes(",")) {
    idsString = idsString.replace(/[\s"']|,$/g, "");

    return idsString.split(",");
  } else if (idsString.includes("\n")) {
    return compact(idsString.split("\n"));
  }

  return idsString;
}

class CustomReportForm extends React.PureComponent {
  static propTypes = {
    error: PropTypes.string,
    userId: PropTypes.string,
    isLoading: PropTypes.bool,
    getCustomReportTypes: PropTypes.func,
    push: PropTypes.func,
    createCustomReport: PropTypes.func,
    customReportTypes: PropTypes.object,
    client: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const queryParams = queryString.parse(window.location.search);
    const isEmployer = has(queryParams, "employerId");
    const isUser = has(queryParams, "userId");
    let selectedReportType = "";
    if (isEmployer) {
      selectedReportType = "EmployerContributionReport";
    } else if (isUser) {
      selectedReportType = "IndividualContributionReport";
    }

    this.state = {
      initialFetching: true,
      selectedReportType,
    };
  }

  async componentDidMount() {
    await this.props.getCustomReportTypes(this.props.client);

    this.setState({ initialFetching: false });
  }

  formatReportValues = (values) => {
    const selectedReportType =
      this.props.customReportTypes[this.state.selectedReportType];
    const inputParametersByName = keyBy(selectedReportType.parameters, "name");

    return transform(
      values,
      (acc, val, key) => {
        const inputParam = inputParametersByName[key];
        // some fields are not in the input parameters, so we just pass them through
        if (!inputParam) {
          acc[key] = val;
          return acc;
        }

        // the UX input is a comma separated string, but the API expects an array
        if (inputParam.type === "Str" && inputParam.isArray) {
          acc[key] = cleanAndSplitIds(val);
          return acc;
        }

        acc[key] = val;
      },
      {}
    );
  };

  submitReport = (values) => {
    const updatedValues = this.formatReportValues(values);
    const params = {
      requestedBy: this.props.userId,
      reportType: this.state.selectedReportType,
      ...updatedValues,
    };
    this.props.createCustomReport(this.props.client, params).then(() => {
      if (!this.props.error) {
        toast.success("Report created successfully");
        this.props.push("/dashboard/reports/custom");
      }
    });
  };

  _generateSchema = (parameters) => {
    return yup.object({
      reportName: yup.string().label("Report Name").required(),
      ...transform(
        parameters,
        (acc, param) => {
          if (param.type === "Date") {
            acc[param.name] = yup.date().label(param.description);
            if (param.required) {
              acc[param.name] = acc[param.name].required();
            }
          } else if (param.type === "Str") {
            acc[param.name] = yup.string().label(param.description);
            if (param.required) {
              acc[param.name] = acc[param.name].required();
            }
          }

          return acc;
        },
        {}
      ),
    });
  };

  _buildReportTypeForm = () => {
    const selectedReportType =
      this.props.customReportTypes[this.state.selectedReportType];
    const intialValues = transform(
      selectedReportType.parameters,
      (acc, param) => {
        const queryParams = queryString.parse(window.location.search);
        const defaultValue = get(queryParams, param.name, undefined);
        acc[param.name] = defaultValue;
        return acc;
      },
      {}
    );

    const schema = this._generateSchema(selectedReportType.parameters);
    return (
      <Formik
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={(values) => {
          this.submitReport(values);
        }}
        enableReinitialize={true}
        initialValues={{
          ...intialValues,
          reportName: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="group-creation">
            <ScrollToFieldError />
            <Form.Row>
              <Form.Group as={Col} md={6} key={"reportName"}>
                <Form.Label>Report Name (Required)</Form.Label>
                <Form.Control
                  name={"reportName"}
                  value={values.reportName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.reportName && !!errors.reportName}
                  isValid={touched.reportName && !errors.reportName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.reportName}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            {map(selectedReportType.parameters, (inputParams) => {
              let formInput = null;
              if (inputParams.type === "Date") {
                formInput = generateDateField({
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue,
                  inputParams,
                });
              } else if (inputParams.type === "Str") {
                formInput = generateStringInputField({
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  inputParams,
                });
              }

              return (
                <Form.Row>
                  <Form.Group
                    as={Col}
                    md={6}
                    key={inputParams.name}
                    controlId={inputParams.name}
                  >
                    {formInput}
                  </Form.Group>
                </Form.Row>
              );
            })}
            {this.props.error && <Alert type="error" msg={this.props.error} />}
            <div className="submit-row">
              <span className="cancel-btn">
                <Button
                  btnLabel="Cancel"
                  name="cancel"
                  color="cancel"
                  type="button"
                  onClick={() => this.props.push("/dashboard/reports/custom")}
                />
              </span>
              <Button
                btnLabel="Create Report"
                name="submit"
                withArrow={true}
                loading={this.props.isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  render() {
    // first input is the report type, then we

    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    return (
      <div className="mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Custom Report Generator</h1>

            <div className="main-content">
              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Select custom report type</Form.Label>
                  <Form.Control
                    as="select"
                    name="reportType"
                    value={this.state.selectedReportType}
                    onChange={(e) => {
                      this.setState({ selectedReportType: e.target.value });
                    }}
                  >
                    <option value="" disabled>
                      Select Report Type
                    </option>
                    {map(this.props.customReportTypes, (item) => (
                      <option value={item.reportType} key={item.reportType}>
                        {item.description}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              {this.state.selectedReportType && this._buildReportTypeForm()}
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const actions = [
  adminConstants.GET_CUSTOM_REPORT_TYPES,
  adminConstants.CREATE_CUSTOM_REPORT,
];
const isLoading = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    reports: state.admin.complianceReports,
    isLoading: isLoading(state),
    error: errorSelector(state),
    customReportTypes: state.admin.customReportTypes,
    userId: state.user.id,
  };
};

const mapDispatchToProps = {
  getCustomReportTypes,
  createCustomReport,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(CustomReportForm));
