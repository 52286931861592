import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";
import { isEmpty } from "lodash";
import { Row, Col } from "react-bootstrap";
import { FiAlertTriangle } from "react-icons/fi";
import {
  expiringIndividualBankSelector,
  bankSelector,
  isBankVerifiedSelector,
} from "store/selectors/bank";
import { bankAccountType } from "statics/propTypes";
import { payrollPeriodsToEnglishMapping } from "utils/timeHelper";
import { getTransactions } from "actions/transactionActions";
import { transactionType } from "statics/propTypes";
import { transactionConstants, bankConstants } from "actions/types";
import { createLoadingSelector } from "store/selectors";
import { getIraAccountIdSelector } from "store/selectors/user";

import PlaidLinkUpdateCard from "../../../../components/PlaidLinkUpdateCard";
import ReAuthPlaidLink from "components/ReAuthPlaidLink";
import ContributionSource from "./ContributionSource";
import AddBankCard from "pages/dashboards/AddBankCard";
import ContributionModal from "pages/dashboards/individualDashboard/dashboard/ContributionModal";
import OneTimeContributionCard from "./OneTimeContributionCard";

class Banks extends React.PureComponent {
  static propTypes = {
    bank: bankAccountType,
    bankIsVerified: PropTypes.bool,
    getTransactions: PropTypes.func,
    transactions: PropTypes.arrayOf(transactionType),
    iraAccountId: PropTypes.string,
    client: PropTypes.object,
    isFetching: PropTypes.bool,
    individualReAuthRequired: PropTypes.bool,
    expiringIndividualBanks: PropTypes.array,
    onSuccess: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      showOneTimeContributionModal: false,
      initialFetching: true,
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.getTransactions(this.props.client, this.props.iraAccountId),
    ]).then(() => this.setState({ initialFetching: false }));
  }

  _getBankRow = () => {
    const { bank } = this.props;
    if (isEmpty(bank)) return null;

    const contributionSource = {
      bankName: bank.bankName,
      accountSubtype: bank.accountSubtype,
      accountId: bank.accountId,
      id: bank.id,
    };

    let contributionInfo;

    const { recurringContribution } = bank;

    if (!recurringContribution) {
      contributionInfo = {};
    } else {
      contributionInfo = {
        ...recurringContribution,
        schedule:
          payrollPeriodsToEnglishMapping[recurringContribution.frequency],
        end: recurringContribution.nextScheduledDate,
        anchorPayDate: recurringContribution.nextScheduledDate,
      };
    }

    const bankIsPendingFinalVerification = bank.state === "BankAccountVerified";

    if (!this.props.bankIsVerified) return null;

    return (
      <Col md={12}>
        <Row>
          <ContributionSource
            isEmployer={false}
            contributionSource={contributionSource}
            contributionInfo={contributionInfo}
            isPendingVerification={bankIsPendingFinalVerification}
          />
        </Row>

        <Row>
          <OneTimeContributionCard />
        </Row>
      </Col>
    );
  };

  _handleOnSuccess = (token, metadata, userLegalName) => {
    if (this.props.onSuccess) {
      this.props.onSuccess(token, metadata, userLegalName);
      toast.success("Bank account successfully re-authenticated.");
    }
  };

  _getPlaidLinkUpdateCard = (bank) => {
    return <ReAuthPlaidLink bank={bank} onSuccess={this._handleOnSuccess} />;
  };

  render() {
    const bankRow = this._getBankRow();
    const showAddBankCard = isEmpty(bankRow) || !this.props.bankIsVerified;

    const { bank } = this.props;

    const bankAccountName = bank.bankName;
    const lastFourDigits = bank.accountId;

    const description = `Re-authenticate your ${bankAccountName} bank account ending in ${lastFourDigits} to resume payroll deductions.`;

    return (
      <div className="banks-container">
        {this.state.showOneTimeContributionModal && (
          <ContributionModal
            show={this.state.showOneTimeContributionModal}
            onSuccess={() => {
              this.setState({ showOneTimeContributionModal: false });
            }}
            onClose={() => {
              this.setState({ showOneTimeContributionModal: false });
            }}
            depositOnly={true}
          />
        )}
        <div className="bank-row-header">
          <h1 className="section-title">Bank Account</h1>
        </div>

        {showAddBankCard && (
          <Row>
            <Col md={6}>
              <AddBankCard />
            </Col>
          </Row>
        )}

        {this.props.individualReAuthRequired ? (
          <Row>
            <Col md={6}>
              <PlaidLinkUpdateCard
                icon={
                  <FiAlertTriangle color="white" stroke="#B12121" size={16} />
                }
                title="Bank Re-Authentication Required!"
                description={description}
                color="red"
                withArrow={true}
                onClick={this._getPlaidLinkUpdateCard(bank)}
              />
            </Col>
          </Row>
        ) : (
          <> {bankRow}</>
        )}
      </div>
    );
  }
}
const loadingSelector = createLoadingSelector([
  transactionConstants.GET_TRANSACTIONS,
  bankConstants.OAUTH_PLAID_LINK_TOKEN,
]);

const mapStateToProps = (state) => {
  return {
    bank: bankSelector(state),
    bankIsVerified: isBankVerifiedSelector(state),
    isFetching: loadingSelector(state),
    iraAccountId: getIraAccountIdSelector(state),
    transactions: state.transactions.transactions,
    individualReAuthRequired: !isEmpty(expiringIndividualBankSelector(state)),
    expiringIndividualBanks: expiringIndividualBankSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  getTransactions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withApollo(Banks));
