import React from "react";
import { size } from "lodash";
import { Card, Col, Row } from "react-bootstrap";
import { FiDownload, FiEye } from "react-icons/fi";
import { isProduction } from "utils/determineSite";

import IconTableHeader from "components/IconTableHeader";
import IconTable from "components/IconTable";
import { withApollo } from "@apollo/client/react/hoc";
import { employerConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { connect } from "react-redux";
import { getCompanyTermsSubmissionDate } from "actions/employerActions";
import moment from "moment";
import IconSpinner from "components/IconSpinner";
import TermsModal from "pages/signUp/TermsModal";
import PropTypes from "prop-types";

const iconDocuments = [
  {
    name: "Icon's current W9",
    id: "icon-current-W9",
    url: `https://iconsavingsplan-public.s3.us-west-2.amazonaws.com/${
      isProduction() ? "production" : "staging"
    }/documents/ICON_FORM_W9.pdf`,
    docType: "download",
  },
  {
    name: "Employee onboarding PDF",
    id: "employee-onboarding-PDF",
    url: `https://iconsavingsplan-public.s3.us-west-2.amazonaws.com/${
      isProduction() ? "production" : "staging"
    }/documents/ICON_EMPLOYEE_ONBOARDING.pdf`,
    docType: "download",
  },
  {
    name: "Plan announcement email template",
    id: "icon-announcement-template-PDF",
    url: `https://iconsavingsplan-public.s3.us-west-2.amazonaws.com/${
      isProduction() ? "production" : "staging"
    }/documents/ICON_ANNOUNCEMENT_TEMPLATE.pdf`,
    docType: "download",
  },
  {
    name: "Icon email template",
    id: "icon-email-template-PDF",
    url: `https://iconsavingsplan-public.s3.us-west-2.amazonaws.com/${
      isProduction() ? "production" : "staging"
    }/documents/ICON_EMAIL_TEMPLATE.pdf`,
    docType: "download",
  },
  {
    name: "Icon email template - State Plan",
    id: "icon-email-template-state-plan-PDF",
    url: `https://iconsavingsplan-public.s3.us-west-2.amazonaws.com/${
      isProduction() ? "production" : "staging"
    }/documents/ICON_EMAIL_TEMPLATE_STATE_PLAN.pdf`,
    docType: "download",
  },
];

const iconVideos = [
  {
    name: "Introduction to Icon",
    url: `https://www.youtube.com/shorts/2sp_1Q_t2K8`,
    docType: "video",
  },
  {
    name: "Getting Started with Your Icon Retirement Account",
    url: `https://www.youtube.com/shorts/tTCOgM0QjQc`,
    docType: "video",
  },
  {
    name: "Making Contributions through Your Paycheck",
    url: `https://www.youtube.com/shorts/f_bhv6DLq6s`,
    docType: "video",
  },
  {
    name: "Your Icon Account is Portable",
    id: "icon-announcement-template-PDF",
    url: `https://www.youtube.com/shorts/t7dv44T0-hc`,
    docType: "video",
  },
  {
    name: "Savings Vehicle",
    url: `https://www.youtube.com/shorts/9l8B2QLZL8M`,
    docType: "video",
  },
];

export class EmployerDocuments extends React.PureComponent {
  static propTypes = {
    getCompanyTermsSubmissionDate: PropTypes.func,
    isFetching: PropTypes.bool,
    client: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      termsAcceptanceDate: null,
      showEvolveTerms: false,
    };
  }

  componentDidMount() {
    this.props
      .getCompanyTermsSubmissionDate(this.props.client)
      .then(({ data }) => {
        this.setState({
          termsAcceptanceDate: moment(data.createdAt).format("MMMM D, YYYY"),
        });
      });
  }

  _buildDocumentsColumns = () => {
    const columns = [
      {
        label: "Document Name",
        key: "name",
      },

      {
        customComponent: (props) => {
          return (
            <a
              className="link"
              href={props.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FiDownload
                size={"20px"}
                color={"#FFFFFF"}
                fill={"#FFFFFF"}
                stroke={"#635bff"}
                strokeWidth={"1.5"}
                className="icon"
              />
            </a>
          );
        },
      },
    ];
    return columns;
  };

  _buildVideosColumns = () => {
    const columns = [
      {
        label: "Video Title",
        key: "name",
      },

      {
        customComponent: (props) => {
          return (
            <a
              className="terms-link"
              href={props.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#01a3b0",
                cursor: "pointer",
              }}
            >
              <p
                className="terms-text"
                style={{
                  fontStyle: "italic",
                  fontSize: 14,
                  margin: 0,
                }}
              >
                View Video
              </p>
            </a>
          );
        },
      },
    ];
    return columns;
  };

  _buildAgreementsColumns = () => {
    const columns = [
      {
        label: "Statement Date",
        key: "createdAt",
      },

      {
        label: "Name",
        key: "name",
      },

      {
        customComponent: (props) => {
          return (
            <a className="link" onClick={props.onClick}>
              <FiEye
                size={"20px"}
                color={"#FFFFFF"}
                fill={"#FFFFFF"}
                stroke={"#635bff"}
                strokeWidth={"1.5"}
                className="icon"
              />
            </a>
          );
        },
      },
    ];
    return columns;
  };

  render() {
    const agreements = [
      {
        createdAt: this.state.termsAcceptanceDate,
        name: "Evolve Bank & Trust Customer Account Agreement",
        id: "evolve-terms",
        onClick: () => {
          this.setState({ showEvolveTerms: true });
        },
      },
    ];

    return (
      <div className="mega-container dashboard-content" id="documents">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Icon Account Documents</h1>
            <p className="page-subtext">See and download all your documents.</p>
            <div className="main-content">
              {this.state.showEvolveTerms && (
                <TermsModal
                  termsInfo={{ effectiveDate: this.state.termsAcceptanceDate }}
                  type={"evolveAgreement"}
                  show={true}
                  onClose={() => this.setState({ showEvolveTerms: false })}
                />
              )}
              <Row>
                <Col>
                  <Card className="dashboard-widget statements-table">
                    <>
                      <div className="business-member-documents">
                        <IconTableHeader
                          tableHeader="Documents"
                          tableCount={size(iconDocuments)}
                        />
                        <IconTable
                          columns={this._buildDocumentsColumns()}
                          data={iconDocuments}
                        />
                      </div>
                    </>
                  </Card>

                  <Card className="dashboard-widget statements-table">
                    <>
                      <div className="business-member-documents">
                        <IconTableHeader
                          tableHeader="Employee Video Links"
                          tableCount={size(iconVideos)}
                        />
                        <IconTable
                          columns={this._buildVideosColumns()}
                          data={iconVideos}
                        />
                      </div>
                    </>
                  </Card>

                  <Card className="dashboard-widget statements-table">
                    {!this.props.isFetching && (
                      <>
                        <div className="business-member-documents">
                          <IconTableHeader
                            tableHeader="Agreements"
                            tableCount={size(agreements)}
                          />
                          <IconTable
                            columns={this._buildAgreementsColumns()}
                            data={agreements}
                          />
                        </div>
                      </>
                    )}
                    {this.props.isFetching && <IconSpinner centered />}
                  </Card>
                </Col>
              </Row>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const actions = [employerConstants.GET_EMPLOYER_TERMS_DATE];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    isFetching: loadingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  getCompanyTermsSubmissionDate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerDocuments));
