import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { FiCode } from "react-icons/fi";
import { withApollo } from "@apollo/client/react/hoc";
import {
  hasAddedGroups,
  hasAnnouncedIcon,
  hasAddedDeductionCode,
  hasLaunchedPlan,
  hasEnabledFinchFeature,
  hasSuccessfullyConnectedFinch,
} from "store/selectors/employer";

import Accordian from "./Accordian";

class AddDeductionCodeCard extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    client: PropTypes.object,
    activeStepNumber: PropTypes.number,
    hasAddedGroups: PropTypes.bool,
    hasAddedDeductionCode: PropTypes.bool,
    hasAnnouncedIcon: PropTypes.bool,
    showDeductionCode: PropTypes.func,
    showDeductionCodeFinal: PropTypes.func,
    hasInvitedEmployees: PropTypes.bool,
    hasLaunchedPlan: PropTypes.bool,
    hasEnabledFinch: PropTypes.bool,
    hasSuccessfullyConnectedFinch: PropTypes.bool,
  };

  render() {
    let activeStepNumber = 1;

    let onClick = this.props.showDeductionCode;

    const steps = [{ index: 1, label: "Add a deduction code" }];

    const waitingForRosterApproval =
      this.props.hasEnabledFinch && !this.props.hasSuccessfullyConnectedFinch;

    return (
      <Accordian
        onClick={onClick}
        icon={<FiCode color="white" stroke="#60A4BF" size={16} />}
        title={"Payroll Deduction Setup"}
        description={
          "Add a generic after-tax deduction for an exact dollar amount, named Icon."
        }
        actionText={"Setup Deduction"}
        disabled={!this.props.hasAddedGroups || waitingForRosterApproval}
        completedAction={this.props.hasAddedDeductionCode}
        activeStepNumber={activeStepNumber}
        steps={steps}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    user,
    hasAddedGroups: hasAddedGroups(state),
    hasAddedDeductionCode: hasAddedDeductionCode(state),
    hasAnnouncedIcon: hasAnnouncedIcon(state),
    hasLaunchedPlan: hasLaunchedPlan(state),
    hasEnabledFinch: hasEnabledFinchFeature(state),
    hasSuccessfullyConnectedFinch: hasSuccessfullyConnectedFinch(state),
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddDeductionCodeCard));
