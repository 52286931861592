import gql from "graphql-tag";

// get users financial accounts
export function getPayrollLineItems(client, groupId, payPeriodId) {
  return new Promise((resolve, reject) => {
    const GET_LINE_ITEMS = gql`
      query getPayrollLineItems($groupId: ID!, $payPeriodId: ID) {
        getPayrollLineItems(groupId: $groupId, payPeriodId: $payPeriodId) {
          pendingContributionChanges {
            employeeId
            id
            userCurrentContributionAmount
            userNewContributionAmount
            isTopOffContribution
          }
          usersWhoLeftPlan {
            id
            employeeId
            userProfile {
              firstName
              lastName
              email
              lastFourOfSsn
              dob
            }
            contribution {
              id
              employeeId
              amount
            }
          }

          contributionsWithNoChange {
            id
            employeeId
            amount
          }
        }
      }
    `;
    const result = client.query({
      query: GET_LINE_ITEMS,
      variables: {
        groupId,
        payPeriodId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.getPayrollLineItems);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getPaginatedChangeRequests(
  client,
  { limit, offset, groupId, excludedIds, payPeriodId }
) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getPaginatedChangeRequests(
        $limit: Int!
        $offset: Int!
        $groupId: ID!
        $excludedIds: [ID]
        $payPeriodId: ID!
      ) {
        getPaginatedChangeRequests(
          limit: $limit
          offset: $offset
          groupId: $groupId
          excludedIds: $excludedIds
          payPeriodId: $payPeriodId
        ) {
          count
          rows {
            employeeId
            id
            userCurrentContributionAmount
            userNewContributionAmount
            isTopOffContribution
            userProfile {
              firstName
              lastName
              email
              lastFourOfSsn
              dob
            }
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { limit, offset, groupId, excludedIds, payPeriodId },
    });
    result.then(
      (data) => {
        resolve(data.data.getPaginatedChangeRequests);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getPaginatedContributions(
  client,
  { limit, offset, groupId, excludedIds, payPeriodId }
) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getPaginatedContributions(
        $limit: Int!
        $offset: Int!
        $groupId: ID!
        $excludedIds: [ID]
        $payPeriodId: ID!
      ) {
        getPaginatedContributions(
          limit: $limit
          offset: $offset
          groupId: $groupId
          excludedIds: $excludedIds
          payPeriodId: $payPeriodId
        ) {
          count
          rows {
            ... on Contribution {
              employeeId
              id
              amount
              userProfile {
                firstName
                lastName
                email
                lastFourOfSsn
                dob
              }
            }

            ... on ContributionChangeRequest {
              employeeId
              id
              userCurrentContributionAmount
              userNewContributionAmount
              isTopOffContribution
              userProfile {
                firstName
                lastName
                email
                lastFourOfSsn
                dob
              }
            }
          }
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { limit, offset, groupId, excludedIds, payPeriodId },
    });
    result.then(
      (data) => {
        resolve(data.data.getPaginatedContributions);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function approvePayroll(client, payrollInput) {
  return new Promise((resolve, reject) => {
    const PROCESS_PAYROLL = gql`
      mutation approvePayroll($payrollInput: ScheduledPayrollInput!) {
        approvePayroll(input: $payrollInput) {
          start
          end
          payrollProcessingDate
          onOrAfterPayrollProcessingDate
        }
      }
    `;

    const result = client.mutate({
      mutation: PROCESS_PAYROLL,
      variables: {
        payrollInput,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.approvePayroll);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getPayrollHistoryRequest(client) {
  return new Promise((resolve, reject) => {
    const request = gql`
      query getPayrollHistory {
        company {
          payrollHistory {
            id
            period {
              start
              end
              displayStart
              displayEnd
            }
            totalAmount
            status
            bankId
            isBankLinked
            payrollDocumentId
            groupName
          }
        }
      }
    `;
    const result = client.query({
      query: request,
    });
    result.then(
      (data) => {
        if (data.data.company) {
          resolve(data.data.company);
        } else {
          reject(data);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function cancelPayrollsByBankIdRequest(client, bankId) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation cancelPayrollsByBankId($bankId: ID!) {
        cancelPayrollsByBankId(bankId: $bankId)
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        bankId,
      },
    });
    result.then(
      (data) => {
        if (data.data.cancelPayrollsByBankId) {
          resolve(data.data.cancelPayrollsByBankId);
        } else {
          reject(data);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function getTotalAmountSavedRequest(client) {
  return new Promise((resolve, reject) => {
    const request = gql`
      query getTotalAmountSaved {
        getTotalAmountSaved {
          totalAmount
        }
      }
    `;
    const result = client.query({
      query: request,
    });
    result.then(
      (data) => {
        if (data.data.getTotalAmountSaved) {
          resolve(data.data.getTotalAmountSaved);
        } else {
          reject(data);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function createTopOffContributionsRequest(client, groupId, payPeriodId) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation createTopOffContributions($groupId: ID!, $payPeriodId: ID!) {
        createTopOffContributions(groupId: $groupId, payPeriodId: $payPeriodId)
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        groupId,
        payPeriodId,
      },
    });
    result.then(
      (data) => {
        if (data.data.createTopOffContributions) {
          resolve(data.data.createTopOffContributions);
        } else {
          reject(data);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function cancelPayrollRequest(client, groupId, payrollId) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation cancelPayroll($groupId: ID!, $payrollId: ID!) {
        cancelPayroll(groupId: $groupId, payrollId: $payrollId) {
          start
          end
          payrollProcessingDate
          onOrAfterPayrollProcessingDate
          status
          gracePeriodEndDate
          payPeriodStart
          payPeriodEnd
          payrollId
        }
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        groupId,
        payrollId,
      },
    });
    result.then(
      (data) => {
        if (data.data.cancelPayroll) {
          resolve(data.data.cancelPayroll);
        } else {
          reject(data);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function recheckPayrollBalanceRequest(client, payrollId) {
  return new Promise((resolve, reject) => {
    const request = gql`
      mutation recheckPayrollBalance($payrollId: ID!) {
        recheckPayrollBalance(payrollId: $payrollId)
      }
    `;
    const result = client.mutate({
      mutation: request,
      variables: {
        payrollId,
      },
    });
    result.then(
      (data) => {
        if (data.data.recheckPayrollBalance) {
          resolve(data.data.recheckPayrollBalance);
        } else {
          reject(data);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function dismissPayroll(client, payPeriodId, reason) {
  return new Promise((resolve, reject) => {
    const DISMISS_PAYROLL = gql`
      mutation dismissPayroll($payPeriodId: ID!, $reason: String!) {
        dismissPayroll(payPeriodId: $payPeriodId, reason: $reason)
      }
    `;

    const result = client.mutate({
      mutation: DISMISS_PAYROLL,
      variables: {
        payPeriodId,
        reason,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.dismissPayroll);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function approveSessionTokenPayroll(client, { sessionToken, identityHash }) {
  return new Promise((resolve, reject) => {
    const MUTATION = gql`
      mutation approveSessionTokenPayroll(
        $sessionToken: ID!
        $identityHash: String!
      ) {
        approveSessionTokenPayroll(
          sessionToken: $sessionToken
          identityHash: $identityHash
        )
      }
    `;

    const result = client.mutate({
      mutation: MUTATION,
      variables: {
        sessionToken,
        identityHash,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.approveSessionTokenPayroll);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export default {
  getPayrollLineItems,
  approvePayroll,
  getPayrollHistoryRequest,
  cancelPayrollsByBankIdRequest,
  getTotalAmountSavedRequest,
  createTopOffContributionsRequest,
  getPaginatedChangeRequests,
  getPaginatedContributions,
  cancelPayrollRequest,
  recheckPayrollBalanceRequest,
  dismissPayroll,
  approveSessionTokenPayroll,
};
