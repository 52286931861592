import React, { Component } from "react";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import classnames from "classnames";
import { push } from "connected-react-router";
import { employerSite, individualSite } from "utils/determineSite";
import PropTypes from "prop-types";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { get, includes } from "lodash";
import { lifecycleStates } from "statics/states";
import { sidebarHide } from "actions/sidebarActions";
import { Auth0Context } from "utils/react-auth0-wrapper";
import { FiCreditCard, FiLogOut, FiFileText } from "react-icons/fi";
import {
  requiresBillingMicroDepositVerification,
  requiresBillingPaymentUpdate,
} from "store/selectors/employer";

import "./SideBar.scss";

export class SideBar extends Component {
  static contextType = Auth0Context;

  static propTypes = {
    routes: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.object,
    color: PropTypes.string,
    image: PropTypes.string,
    isEmployer: PropTypes.bool,
    sidebarHide: PropTypes.func,
    showSidebar: PropTypes.bool,
    isSmallScreen: PropTypes.bool,
    hasVirtualBank: PropTypes.bool,
    billingPortalURL: PropTypes.string,
    push: PropTypes.func,
    isIndividualDashboard: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.getCollapseStates(props.routes),
    };
  }

  // this creates the initial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      const hideVirtual =
        prop.path === "/dashboard/company/bank" && this.props.hasVirtualBank;
      if (
        prop.redirect ||
        prop.layout === "/workflow" ||
        prop.hidden ||
        hideVirtual
      ) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              <div className="sidebar-routes">
                <span className="sidebar-icon"> {prop.icon}</span>
                <p className="sidebar-name"> {prop.name}</p>

                <b
                  className={
                    this.state[prop.state] ? "caret rotate-180" : "caret"
                  }
                />
              </div>
            </a>
            <Collapse in={this.state[prop.state]}>
              <ul className="sidebar-nav collapsing-nav">
                {this.createLinks(prop.views)}
              </ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.path)} key={key}>
          <NavLink
            to={prop.path}
            className="icon-nav-link"
            activeClassName="active"
            onClick={() => {
              this.props.sidebarHide();
            }}
          >
            <>
              <div className="sidebar-routes">
                <span className="sidebar-icon"> {prop.icon}</span>
                <p className="sidebar-name"> {prop.name}</p>
              </div>
            </>
          </NavLink>
        </li>
      );
    });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    if (routeName === this.props.location.pathname) {
      return this.props.location.pathname.indexOf(routeName) > -1
        ? "active"
        : "";
    } else {
      if (
        this.props.location.pathname === "/dashboard/company/bank" &&
        routeName === "/dashboard/company"
      ) {
        return "active";
      }
      if (
        this.props.location.pathname === "/dashboard/company/group" &&
        routeName === "/dashboard/company"
      ) {
        return "active";
      }
      if (
        this.props.location.pathname === "/dashboard/company/info" &&
        routeName === "/dashboard/company"
      ) {
        return "active";
      }
      if (
        includes(this.props.location.pathname, "approvals") &&
        includes(routeName, "approvals")
      ) {
        return "active";
      }
      if (
        includes(this.props.location.pathname, "one-time") &&
        includes(routeName, "one-time")
      ) {
        return "active";
      }
      if (
        includes(this.props.location.pathname, "employers") &&
        includes(routeName, "employers")
      ) {
        return "active";
      }
      if (
        includes(this.props.location.pathname, "documents") &&
        includes(routeName, "documents")
      ) {
        return "active";
      }
      if (
        includes(this.props.location.pathname, "/dashboard/support") &&
        includes(routeName, "/dashboard/support")
      ) {
        return "active";
      }

      return 0;
    }
  };

  logout = () => {
    window.analytics.track("Logged Out");
    window.analytics.reset();

    this.context.logout({
      returnTo: window.location.origin + "/login",
    });
  };

  render() {
    return (
      <div
        className={classnames("sidebar", {
          ["show-sidebar"]: this.props.showSidebar,
        })}
      >
        <div
          className="sidebar-wrapper"
          ref={(ref) => (this.sidebarWrapper = ref)}
        >
          <ul className="sidebar-nav">
            {/*
              here we render the links in the sidebar if the link is simple,
              we make a simple link, if not, we have to create a collapsible group,
              with the speciffic parent button and with it's children which are the links
            */}
            {this.createLinks(this.props.routes)}

            {this.props.isSmallScreen && (
              <li>
                {this.props.billingPortalURL && (
                  <a
                    eventKey="4.3"
                    href={this.props.billingPortalURL}
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <div className="sidebar-routes">
                      <span className="sidebar-icon">
                        <FiCreditCard />
                      </span>
                      <p className="sidebar-name">Billing</p>
                    </div>
                  </a>
                )}
                {employerSite() && (
                  <div
                    className="icon-nav-link"
                    activeClassName="active"
                    onClick={() => this.props.push("/dashboard/documents")}
                  >
                    <div className="sidebar-logout">
                      <div className="sidebar-routes">
                        <span className="sidebar-icon">
                          <FiFileText />
                        </span>
                        <p className="sidebar-name"> Documents</p>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className="icon-nav-link"
                  activeClassName="active"
                  onClick={this.logout}
                >
                  <div className="sidebar-logout">
                    <div className="sidebar-routes">
                      <span className="sidebar-icon">
                        {!this.props.isIndividualDashboard && <FiLogOut />}
                      </span>
                      <p className="sidebar-name"> Logout</p>
                    </div>
                  </div>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userState = get(state.user, "userState.state", "");
  const billingPortalURL =
    requiresBillingMicroDepositVerification(state) ||
    requiresBillingPaymentUpdate(state)
      ? null
      : get(state.employer, "billingPortalURL", null);

  return {
    showSidebar: state.sidebar.showSidebar,
    hasVirtualBank: get(state.employer, "hasVirtualBank"),
    billingPortalURL,
    isIndividualDashboard:
      lifecycleStates.includes(userState) && individualSite(),
  };
};

const mapDispatchToprops = {
  push,
  sidebarHide,
};

export default connect(
  mapStateToProps,
  mapDispatchToprops
)(withApollo(SideBar));
