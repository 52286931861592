import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import moment from "moment";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { chain, get, isEmpty, map } from "lodash";

import Alert from "components/Alert";
import Button from "components/Button";
import IconSpinner from "components/IconSpinner";

import { createInvoiceForCustomer, getPricingData } from "actions/adminActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { formatCurrency } from "utils/number";
import { ScrollToFieldError, zeroToNull } from "utils/form";

function validateHasSubscriptionOrPerUserFee() {
  const hasPerUserFee =
    !isEmpty(this.parent.selectedPerUserFeeId) &&
    this.parent.selectedPerUserFeeId !== "0";
  const hasSelectedSubscription =
    !isEmpty(this.parent.selectedSubscriptionId) &&
    this.parent.selectedSubscriptionId !== "0";

  return hasPerUserFee || hasSelectedSubscription;
}

const EARLIEST_INVOICE_DATE = moment();
const LATEST_INVOICE_DATE_MONTHLY = moment().add(1, "months").toDate();
const LATEST_INVOICE_DATE_YEARLY = moment().add(1, "years").toDate();
const yup = require("yup");
const schema = yup.object({
  billingContactEmail: yup
    .string()
    .email("You must enter a valid email address")
    .label("Billing Contact Email")
    .required(),

  selectedSubscriptionId: yup
    .string()
    .label("Monthly Pricing")
    .test(
      "selectedSubscriptionId or selectedPerUserFeeId",
      "selectedSubscriptionId or selectedPerUserFeeId is required",
      validateHasSubscriptionOrPerUserFee
    ),
  selectedPerUserFeeId: yup
    .string()
    .label("Per User Fee")
    .test(
      "selectedSubscriptionId or selectedPerUserFeeId",
      "selectedSubscriptionId or selectedPerUserFeeId is required",
      validateHasSubscriptionOrPerUserFee
    ),
  selectedSetupFeeId: yup.string().label("Setup Fee").required(),
  anchorBillingDate: yup.date().required(),
  discountPercent: yup
    .number()
    .label("Discount Percent")
    .max(100, "Discount Percent must be less than or equal to 100"),
  discountLength: yup
    .string()
    .label("Discount Length")
    .when("discountPercent", {
      is: (discountPercent) => discountPercent > 0,
      then: yup.string().required(),
    }),
});

const noFee = {
  id: 0,
  unitAmount: 0,
};

const YEAR = "year";
const MONTH = "month";

const DISCOUNT_LENGTHS = [
  {
    value: "ONE_YEAR",
    label: "One Year",
  },
  {
    value: "TWO_YEARS",
    label: "Two Years",
  },
  {
    value: "FOREVER",
    label: "Forever",
  },
];

const DISCOUNT_PERCENTAGES = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 5,
    label: "5%",
  },
  {
    value: 10,
    label: "10%",
  },
];

class BillingForm extends React.PureComponent {
  static propTypes = {
    getPricingData: PropTypes.func,
    createInvoiceForCustomer: PropTypes.func,
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
    client: PropTypes.object,
    billingContactEmail: PropTypes.string,
    companyId: PropTypes.string,
    error: PropTypes.string,
    isSubmitting: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      initialFetching: true,
      setupFees: [],
      subscriptions: [],
      perUserFees: [],
    };
  }

  async componentDidMount() {
    const {
      data: { setupFees, subscriptions, perUserFees },
    } = await this.props.getPricingData(this.props.client);

    this.setState({
      setupFees: [...setupFees, noFee],
      perUserFees: [...perUserFees, noFee],
      subscriptions: [...subscriptions, noFee],
      initialFetching: false,
      billingFrequency: YEAR,
    });
  }

  _onSubmit = (vals) => {
    const anchorBillingDate = moment(vals.anchorBillingDate).format(
      "YYYY-MM-DD"
    );

    // in the case where we aren't applying a setup fee, just pass in null
    const setupFeeId = zeroToNull(vals.selectedSetupFeeId);
    const perUserFeeId = zeroToNull(vals.selectedPerUserFeeId);
    const subscriptionId = zeroToNull(vals.selectedSubscriptionId);
    const discountPercent = zeroToNull(vals.discountPercent);

    const payload = {
      billingContactEmail: vals.billingContactEmail,
      billedInArrears: vals.billedInArrears,
      companyId: this.props.companyId,
      subscriptionId,
      setupFeeId,
      perUserFeeId,
      anchorBillingDate,
      discountPercent,
      discountLength: discountPercent ? vals.discountLength : null,
    };

    this.props
      .createInvoiceForCustomer(this.props.client, payload)
      .then(({ data }) => {
        if (!this.props.error) {
          toast.success("Successfully created billing subscription.");
          this.props.onSuccess(data);
        }
      });
  };

  subscriptionOptions = () => {
    return chain(this.state.subscriptions)
      .filter((subscription) => {
        const subInterval = get(subscription, "recurring.interval");
        return subInterval === this.state.billingFrequency;
      })
      .map((subscription) => {
        return (
          <option value={subscription.id} key={subscription.id}>
            {formatCurrency(subscription.unitAmount)}
          </option>
        );
      })
      .value();
  };

  discountLengthOptions = () => {
    return map(DISCOUNT_LENGTHS, (discountLength) => {
      return (
        <option value={discountLength.value} key={discountLength.value}>
          {discountLength.label}
        </option>
      );
    });
  };

  discountOptions = () => {
    return map(DISCOUNT_PERCENTAGES, (discountLength) => {
      return (
        <option value={discountLength.value} key={discountLength.value}>
          {discountLength.label}
        </option>
      );
    });
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }
    return (
      <div className="billing-form">
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={this._onSubmit}
          enableReinitialize={true}
          initialValues={{
            selectedSubscriptionId: "",
            selectedSetupFeeId: "",
            selectedPerUserFeeId: "",
            billingContactEmail: this.props.billingContactEmail,
            anchorBillingDate: EARLIEST_INVOICE_DATE.clone()
              .add("1", "days")
              .toDate(),
            billedInArrears: false,
            discountPercent: 0,
            discountLength: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            handleBlur,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <Form.Row>
                <Col md={12}>
                  <Form.Label>Billing Frequency</Form.Label>
                </Col>
                <Form.Group
                  controlId="formAccountType"
                  as={Row}
                  style={{ paddingLeft: 24 }}
                >
                  <Col md={3}>
                    <Form.Check
                      type="radio"
                      name="billingFrequency"
                      id="yearly"
                      label="Annual"
                      value={YEAR}
                      onChange={(e) => {
                        this.setState({ billingFrequency: e.target.value });
                      }}
                      checked={this.state.billingFrequency === YEAR}
                    />
                  </Col>
                  <Col md={2} />
                  <Col md={3}>
                    <Form.Check
                      type="radio"
                      name="billingFrequency"
                      id="monthly"
                      label="Monthly"
                      value={MONTH}
                      onChange={(e) => {
                        this.setState({ billingFrequency: e.target.value });
                      }}
                      checked={this.state.billingFrequency === MONTH}
                    />
                  </Col>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={4} sm={12} controlId="formBillingDate">
                  <Form.Label>First Due Date</Form.Label>
                  <DatePicker
                    className="form-control"
                    name="anchorBillingDate"
                    selected={
                      values.anchorBillingDate &&
                      new Date(values.anchorBillingDate)
                    }
                    dateFormat="MM/dd/yyyy"
                    onChange={(val) => {
                      setFieldValue("anchorBillingDate", val);
                    }}
                    onBlur={handleBlur}
                    includeDateIntervals={[
                      {
                        start: EARLIEST_INVOICE_DATE.toDate(),
                        end:
                          this.state.billingFrequency === MONTH
                            ? LATEST_INVOICE_DATE_MONTHLY
                            : LATEST_INVOICE_DATE_YEARLY,
                      },
                    ]}
                  />

                  <Form.Control.Feedback>{errors.dob}</Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="formSubscription">
                  <Form.Label>
                    {this.state.billingFrequency === MONTH
                      ? "Monthly"
                      : "Yearly"}{" "}
                    Pricing
                  </Form.Label>
                  <Form.Control
                    sm={4}
                    as="select"
                    name="selectedSubscriptionId"
                    value={values.selectedSubscriptionId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.selectedSubscriptionId &&
                      !!errors.selectedSubscriptionId
                    }
                    isValid={
                      touched.selectedSubscriptionId &&
                      !errors.selectedSubscriptionId
                    }
                  >
                    <option value="" disabled>
                      Select Subscription
                    </option>
                    {this.subscriptionOptions()}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.selectedSubscriptionId}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="formPerUser">
                  <Form.Label>Per User Pricing</Form.Label>
                  <Form.Control
                    sm={4}
                    as="select"
                    name="selectedPerUserFeeId"
                    value={values.selectedPerUserFeeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.selectedPerUserFeeId &&
                      !!errors.selectedPerUserFeeId
                    }
                    isValid={
                      touched.selectedPerUserFeeId &&
                      !errors.selectedPerUserFeeId
                    }
                  >
                    <option value="" disabled>
                      Select Per User Fee
                    </option>
                    {this.state.perUserFees.map((fee) => (
                      <option value={fee.id} key={fee.id}>
                        {formatCurrency(fee.unitAmount)}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.selectedPerUserFeeId}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="formSetupFee">
                  <Form.Label>Setup Fee</Form.Label>
                  <Form.Control
                    sm={4}
                    as="select"
                    name="selectedSetupFeeId"
                    value={values.selectedSetupFeeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.selectedSetupFeeId && !!errors.selectedSetupFeeId
                    }
                    isValid={
                      touched.selectedSetupFeeId && !errors.selectedSetupFeeId
                    }
                  >
                    <option value="" disabled>
                      Select One-Time Fee
                    </option>
                    {this.state.setupFees.map((fee) => (
                      <option value={fee.id} key={fee.id}>
                        {formatCurrency(fee.unitAmount)}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.selectedSetupFeeId}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={4} controlId={"discountPercent"}>
                  <Form.Label>Discount Percent</Form.Label>
                  <Form.Control
                    sm={4}
                    as="select"
                    name="discountPercentage"
                    value={values.discountPercent}
                    onChange={(e) => {
                      setFieldValue("discountPercent", +e.target.value);
                    }}
                    onBlur={handleBlur}
                    isInvalid={
                      touched.discountPercent && !!errors.discountPercent
                    }
                    isValid={touched.discountPercent && !errors.discountPercent}
                  >
                    <option value="" disabled>
                      Select Discount Percentage
                    </option>
                    {this.discountOptions()}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.discountPercent}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              {values.discountPercent > 0 && (
                <Form.Row>
                  <Form.Group as={Col} sm={6} controlId="discountLength">
                    <Form.Label>Discount Length</Form.Label>
                    <Form.Control
                      sm={4}
                      as="select"
                      name="discountLength"
                      value={values.discountLength}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.discountLength && !!errors.discountLength
                      }
                      isValid={touched.discountLength && !errors.discountLength}
                    >
                      <option value="" disabled>
                        Select Discount Length
                      </option>
                      {this.discountLengthOptions()}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.discountLength}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              )}
              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="formBasicUserEmail">
                  <Form.Label>Billing Contact Email</Form.Label>
                  <Form.Control
                    name="billingContactEmail"
                    placeholder="Billing Contact Email"
                    value={values.billingContactEmail}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={
                      touched.billingContactEmail &&
                      !!errors.billingContactEmail
                    }
                    isValid={
                      touched.billingContactEmail && !errors.billingContactEmail
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.billingContactEmail}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group
                  as={Col}
                  sm={6}
                  controlId="formBasicBilledInArrears"
                >
                  <Form.Check
                    type="checkbox"
                    id={"billedInArrears"}
                    name={"billedInArrears"}
                    checked={values.billedInArrears}
                    disabled={false}
                    label={"Billed in arrears"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Group>
                {this.props.error && (
                  <Alert type="error" msg={this.props.error} />
                )}
                <div className="btn-row">
                  <Button
                    onClick={this.props.onClose}
                    btnLabel={"Cancel"}
                    color={"cancel"}
                    name="cancel"
                  />
                  <Button
                    btnLabel="Send Invoice"
                    name="submit"
                    withArrow={true}
                    loading={this.props.isSubmitting}
                  />
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const errorSelector = createErrorSelector([
  adminConstants.GET_PRICING_SHEET,
  adminConstants.CREATE_BILLING_INVOICE,
]);
const loadingSelector = createLoadingSelector(
  adminConstants.CREATE_BILLING_INVOICE
);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isSubmitting: loadingSelector(state),
  };
};

const mapDispatchToProps = {
  getPricingData,
  createInvoiceForCustomer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(BillingForm));
