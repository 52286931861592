import React from "react";
import classnames from "classnames";
import { map } from "lodash";

import "./StepInfo.scss";
import { PropTypes } from "prop-types";

const Step = ({ isActive, label, index }) => {
  return (
    <div
      className={classnames("step", {
        ["is-active"]: isActive,
      })}
    >
      <span className="step-line" />
      <p className="step-label">
        {index}. {label}
      </p>
    </div>
  );
};

Step.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string,
  index: PropTypes.number,
};

const StepInfo = ({ activeStepNumber, steps }) => {
  return (
    <div className="step-wrapper">
      {map(steps, (step, idx) => {
        return (
          <Step
            isActive={activeStepNumber >= step.index}
            key={step.index}
            label={step.label}
            index={idx + 1}
          />
        );
      })}
    </div>
  );
};

StepInfo.propTypes = {
  activeStepNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  steps: PropTypes.array,
};

export default StepInfo;
