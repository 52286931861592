import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

import AddBankCard from "pages/dashboards/AddBankCard";
import SchedulePicker from "./SchedulePicker";
import SelfEmployedContributionSelection from "./SelfEmployedContributionSelection";

import { getAllAccounts } from "actions/accountActions";
import { getUserProfile } from "actions/userActions";
import { getContributionAbility } from "actions/contributionActions";
import { createLoadingSelector } from "store/selectors";
import { accountConstants } from "actions/types";
import { isBankVerifiedSelector } from "store/selectors/bank";
import "./SetupContribution.scss";
import AddEmployerCard from "pages/signUp/individual/setupContribution/AddEmployerCard";

class SelfEmployedFlow extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    getAllAccounts: PropTypes.func,
    getUserProfile: PropTypes.func,
    client: PropTypes.object,
    isFetching: PropTypes.bool,
    hasVerifiedBankAccount: PropTypes.bool,
    getContributionAbility: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      hasConfiguredSchedule: false,
      schedule: {
        frequency: "",
        dayOne: "",
        anchorPayDate: "",
      },
      modifier: "",
      contributionAmount: "",
    };
  }

  componentDidMount() {
    this.props.getAllAccounts(this.props.client);
    this.props.getUserProfile(this.props.client);
    this.props.getContributionAbility(this.props.client);
  }

  _setSchedule = (schedule) => {
    this.setState({ schedule, hasConfiguredSchedule: true });
  };

  _editSchedule = () => {
    this.setState({
      hasConfiguredSchedule: false,
      schedule: {
        ...this.state.schedule,
        anchorPayDate: moment(this.state.schedule.anchorPayDate),
      },
    });
  };

  _getPageTitle() {
    if (this.state.hasConfiguredSchedule) {
      return (
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">
              With Icon, you have the freedom to choose how much of your pay you
              set aside to your retirement account.
            </h1>
            <p className="page-subtext">
              Please select the amount that{"'"}s good for you or enter in a
              custom amount. You can modify your contribution amount at any
              time.
            </p>
          </article>
        </section>
      );
    } else {
      return (
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">
              Let{"'"}s get started with setting up your contributions!
            </h1>
            <p className="page-subtext">
              First you will link your bank account or employer account and then
              setup a recurring contribution into your retirement account.
            </p>
          </article>
        </section>
      );
    }
  }

  render() {
    let content;

    if (!this.props.hasVerifiedBankAccount) {
      content = (
        <Row className="justify-content-md-center">
          <Col md={6}>
            <AddBankCard />
          </Col>
          <Col md={6}>
            <AddEmployerCard />
          </Col>
        </Row>
      );
    } else if (!this.state.hasConfiguredSchedule) {
      content = (
        <SchedulePicker
          schedule={this.state.schedule}
          setSchedule={this._setSchedule}
        />
      );
    } else {
      content = (
        <SelfEmployedContributionSelection
          editSchedule={this._editSchedule}
          schedule={this.state.schedule}
        />
      );
    }
    return (
      <Row className="setup-contributions-row">
        <Col md={12} style={{ textAlign: "center" }}>
          <div className="contribution-setup-wrapper">
            {this._getPageTitle()}
            {content}
          </div>
        </Col>
      </Row>
    );
  }
}

const loadingSelector = createLoadingSelector(accountConstants.GET_ACCOUNTS);

const mapStateToProps = (state) => {
  return {
    hasVerifiedBankAccount: isBankVerifiedSelector(state),
    isFetching: loadingSelector(state),
  };
};
const mapDispatchToProps = {
  push,
  getAllAccounts,
  getUserProfile,
  getContributionAbility,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(SelfEmployedFlow));
