import AccountSwitcher from "components/AccountSwitcher";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiMessageSquare } from "react-icons/fi";
import { get, includes, some, find } from "lodash";
import {
  IndividualContributionPending,
  IndividualInvestmentProfileUpdate,
  IndividualPortfolioPending,
  IndividualPortfolioUpdate,
  IndividualRequiredUpdatesPending,
  IndividualRolloverInfoPending,
  IndividualApplicationInfoComplete,
  IndividualDisclosuresComplete,
  IndividualInvestmentInfoComplete,
  IndividualTermsPending,
} from "statics/states";
import { activeAccountState } from "store/selectors/user";
import React from "react";
import { push } from "connected-react-router";
import { exitInvestmentProfileUpdate } from "actions/userActions";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { setAccountSession } from "actions/accountActions";
import PropTypes from "prop-types";

const IndividualNavLeft = ({
  userInRequiredUpdates,
  push,
  userInFinalOnboarding,
  userUpdatingPortfolio,
  userState,
  pathname,
  exitInvestmentProfileUpdate,
  userInRolloverOnboarding,
  activeRolloverAccount,
  activeAccountId,
  setAccountSession,
  client,
}) => {
  const shouldHideSupportLink =
    userInFinalOnboarding ||
    userUpdatingPortfolio ||
    userInRequiredUpdates ||
    userInRolloverOnboarding;

  const shouldShowFinishRollover =
    activeRolloverAccount && includes(pathname, "/dashboard");

  let link;
  switch (userState) {
    case IndividualContributionPending:
      link = "/individual/setup_contribution";
      break;
    case IndividualPortfolioPending:
    case IndividualPortfolioUpdate:
      link = "/individual/portfolio_selection";
      break;
    case IndividualInvestmentProfileUpdate:
      link = "/individual/investment";
      break;
    default:
      link = "/dashboard";
  }

  return (
    <div className="individual-account-switcher">
      <AccountSwitcher />

      {!shouldHideSupportLink && (
        <Nav className="support-link">
          <Link
            to="/dashboard/support"
            className="support-link-target-box"
            onClick={() => push("/dashboard/support")}
          >
            <p className="support-link">
              Support <FiMessageSquare />
            </p>
          </Link>
        </Nav>
      )}

      {(userInFinalOnboarding || userUpdatingPortfolio) &&
        !includes(pathname, "/individual") && (
          <Nav className="support-link">
            <Link
              style={{ color: "white" }}
              className="support-link-target-box"
              to={link}
            >
              <p className="support-link">Finish Setup</p>
            </Link>
          </Nav>
        )}

      {shouldShowFinishRollover && (
        <Nav className="support-link">
          <Link
            style={{ color: "white" }}
            className="support-link-target-box"
            onClick={async () => {
              await setAccountSession(client, activeAccountId);
              window.location.search = `?redirect_to=/dashboard`;
            }}
          >
            <p className="support-link">Resume Rollover Application</p>
          </Link>
        </Nav>
      )}
      {userState === IndividualInvestmentProfileUpdate &&
        !includes(pathname, "/dashboard") && (
          <Nav className="support-link">
            <Link
              to="/dashboard/profile/investment-profile"
              className="support-link-target-box"
              onClick={async (e) => {
                // using push instead of the 'to' props because
                // exitInvestmentProfileUpdate needs to be called before route transition
                e.preventDefault();
                exitInvestmentProfileUpdate(client).then(() =>
                  push("/dashboard/profile/investment-profile")
                );
              }}
            >
              <p className="support-link">Exit Profile Update</p>
            </Link>
          </Nav>
        )}
    </div>
  );
};

IndividualNavLeft.propTypes = {
  userInFinalOnboarding: PropTypes.bool,
  userUpdatingPortfolio: PropTypes.bool,
  userInRequiredUpdates: PropTypes.bool,
  userInRolloverOnboarding: PropTypes.bool,
  activeRolloverAccount: PropTypes.bool,
  activeAccountId: PropTypes.string,
  push: PropTypes.func,
  setAccountSession: PropTypes.func,
  userState: PropTypes.string,
  pathname: PropTypes.string,
  exitInvestmentProfileUpdate: PropTypes.func,
  client: PropTypes.object,
};

const mapStateToProps = (state) => {
  const userState = get(state.user, "userState.state", "");
  const pathname = get(state.router, "location.pathname");
  const accountState = activeAccountState(state);
  const accounts = state.accounts.iraAccounts;
  const rolloverOnboardingStates = [
    IndividualRolloverInfoPending,
    IndividualApplicationInfoComplete,
    IndividualDisclosuresComplete,
    IndividualInvestmentInfoComplete,
    IndividualTermsPending,
  ];
  const userInFinalOnboarding = [
    IndividualPortfolioPending,
    IndividualContributionPending,
  ].includes(userState);
  const userUpdatingPortfolio = [
    IndividualInvestmentProfileUpdate,
    IndividualPortfolioUpdate,
  ].includes(userState);
  const userInRequiredUpdates = [IndividualRequiredUpdatesPending].includes(
    userState
  );

  const userInRolloverOnboarding = [
    IndividualRolloverInfoPending,
    IndividualApplicationInfoComplete,
    IndividualDisclosuresComplete,
    IndividualInvestmentInfoComplete,
    IndividualTermsPending,
  ].includes(accountState);

  const activeRolloverAccount = some(
    accounts,
    (account) =>
      account.isRollover && includes(rolloverOnboardingStates, account.state)
  );

  const activeAccount = find(
    accounts,
    (account) =>
      account.isRollover && includes(rolloverOnboardingStates, account.state)
  );

  const activeAccountId = activeAccount ? activeAccount.id : null;

  return {
    pathname,
    accountState,
    activeAccountId,
    userState: get(state.user, "userState.state", ""),
    activeRolloverAccount,
    userInFinalOnboarding,
    userUpdatingPortfolio,
    userInRequiredUpdates,
    userInRolloverOnboarding,
  };
};

const mapDispatchToProps = {
  push,
  exitInvestmentProfileUpdate,
  setAccountSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualNavLeft));
