import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { isEmpty } from "lodash";
import { userConstants } from "actions/types";
import { scrollToTop } from "utils/dom";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { getUserState, updateSelectedAccountType } from "actions/userActions";
import { getIraAccountIdSelector } from "store/selectors/user";
import { INVESTMENT_PROFILE } from "statics/onboardingSteps";

import classnames from "classnames";
import ProgressBar from "components/ProgressBar";
import SelectBox from "./SelectBox";
import Button from "components/Button";
import Alert from "components/Alert";
import CircularProgressBar from "components/CircularProgressBar";

const traditionalIRA = {
  label: "Traditional IRA",
  description:
    "A Traditional IRA offers tax-deductible contributions with taxable withdrawals in retirement.",
  key: "TRADITIONAL_IRA",
};

const accountTypes = [
  {
    label: "Traditional IRA",
    key: "TRADITIONAL_IRA",
    items: [
      "Contributions are often tax-deductible.",
      "Withdrawals are taxed as ordinary income.",
      "Required Minimum Distributions (RMDs) start at age 72.",
      "No income limits for contributions.",
    ],
    ordered: false,
  },
  {
    label: "Roth IRA",
    key: "ROTH_IRA",
    items: [
      "Contributions are not tax-deductible.",
      "Qualified withdrawals, including earnings, are tax-free.",
      "No RMDs during the account owner's lifetime.",
      "Income limits for contributions.",
    ],
    ordered: false,
  },
];

class IndividualAccountType extends React.Component {
  static propTypes = {
    client: PropTypes.object,
    getUserState: PropTypes.func,
    updateSelectedAccountType: PropTypes.func,
    isSubmitting: PropTypes.bool,
    error: PropTypes.string,
    userState: PropTypes.string,
    accountId: PropTypes.string,
    accounts: PropTypes.arrayOf(PropTypes.object),
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      isRothIRASelected: false,
      selectedAccountType: "TRADITIONAL_IRA",
      submitted: false,
    };
  }

  componentDidMount() {
    window.analytics.page("Individual Account Type Selection");
    scrollToTop();
  }

  _submitAccountType = () => {
    const accountType = this.state.selectedAccountType;

    this.props
      .updateSelectedAccountType(this.props.client, accountType)
      .then(() => {
        this.props.getUserState(this.props.client);
      });
    window.analytics.track("Submitted Individual Account Type", {
      userState: this.props.userState,
    });

    this.setState({
      submitted: true,
    });
  };

  _setSelectedAccountType = (selectedAccountType) => {
    this.setState({
      selectedAccountType,
    });
  };

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    const getTraditionalIRA = [traditionalIRA];
    const accountSelection = this.state.isRothIRASelected
      ? accountTypes
      : getTraditionalIRA;

    const accountSubtext = this.state.isRothIRASelected
      ? "Here are a few differences to consider:"
      : "Traditional IRAs are known for their potential advantages in long-term financial planning.";

    const showChevron = this.state.isRothIRASelected ? false : true;

    return (
      <div id="account-selection">
        <ProgressBar
          isEmployer={false}
          activeStepId={INVESTMENT_PROFILE.id}
          progressPercent={"90"}
        />

        <div className="mega-container">
          <div className="step-container is-active" data-circle-percent="100">
            <section className="page-title-wrap">
              <article className="text-cell">
                <p className="page-title">
                  What type of account are you interested in?
                </p>
                <p className="page-subtext">{accountSubtext}</p>
              </article>
              <article className="progress-cell">
                <ul className="circular-progress-wrap">
                  <CircularProgressBar
                    strokeWidth="8"
                    sqSize="75"
                    percentage="90"
                  />
                </ul>
              </article>
            </section>
            <div>
              <section className="form-sec-2col">
                <article className="col-form" style={articleStyle}>
                  <div
                    className={classnames("account-types", {
                      ["display-both"]: this.state.isRothIRASelected,
                    })}
                  >
                    {accountSelection.map((account) => (
                      <SelectBox
                        label={account.label}
                        description={account.description}
                        key={account.key}
                        items={account.items}
                        ordered={account.ordered}
                        displayChevron={showChevron}
                        onSelect={() =>
                          this._setSelectedAccountType(account.key)
                        }
                        isSelected={
                          this.state.selectedAccountType === account.key
                        }
                      />
                    ))}
                  </div>
                  <div className="need-info">
                    {!this.state.isRothIRASelected && (
                      <>
                        <a
                          className="terms-link"
                          onClick={() => {
                            this.setState({
                              isRothIRASelected: true,
                            });
                          }}
                          style={{
                            display: "flex",
                            color: "#01a3b0",
                            cursor: "pointer",
                          }}
                        >
                          <p
                            className="terms-text"
                            style={{
                              fontStyle: "italic",
                              fontSize: 14,
                            }}
                          >
                            Looking for a Roth IRA?
                          </p>
                        </a>
                      </>
                    )}
                    {/* {this.state.isRothIRASelected && (
                      <a
                        className="terms-link"
                        href="https://support.iconsavingsplan.com/en/articles/9006281-ira-account-types"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: "flex",
                          color: "#01a3b0",
                          cursor: "pointer",
                        }}
                      >
                        <p
                          className="terms-text"
                          style={{
                            fontStyle: "italic",
                            fontSize: 14,
                          }}
                        >
                          Need more information?
                        </p>
                      </a>
                    )} */}
                  </div>
                </article>
              </section>
              <section className="form-sec-2col">
                <article className="col-form">
                  <div className="submit-row">
                    {this.props.error && (
                      <Alert type="error" msg={this.props.error} />
                    )}
                  </div>
                  <div>
                    <Button
                      name="submit"
                      btnLabel="Select Account"
                      withArrow={true}
                      onClick={this._submitAccountType}
                      loading={this.props.isSubmitting}
                      disabled={isEmpty(this.state.selectedAccountType)}
                    />
                  </div>
                </article>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const actions = [userConstants.UPDATE_SELECTED_ACCOUNT_TYPE];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector([actions]);

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;
  const accounts = state.accounts.iraAccounts;
  return {
    isSubmitting: loadingSelector(state),
    error: errorSelector(state),
    userState,
    accountId: getIraAccountIdSelector(state),
    accounts,
  };
};

const mapDispatchToProps = {
  getUserState,
  updateSelectedAccountType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualAccountType));
