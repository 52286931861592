import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { Auth0Provider } from "./utils/react-auth0-wrapper";
import { AuthApolloProvider } from "./utils/AuthApolloProvider";
import App from "./App.js";
import configureStore from "./store/configureStore";
import history from "./utils/history";
import { ConnectedRouter, push } from "connected-react-router";
//import env from "@beam-australia/react-env";
import env from "@icon-savings/react-env-heroku";
import "regenerator-runtime/runtime.js";
import { isProduction } from "utils/determineSite";

window.Promise = require("bluebird");
// enable Promise cancellation bluebird feature
Promise.config({
  cancellation: true,
  warnings: {
    wForgottenReturn: false, // there are many places where we create a promise without necessarily meaning to return anything
  },
});

const store = configureStore();

Sentry.init({
  dsn: env("SENTRY_DSN"),
  environment: isProduction ? "production" : "staging",
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration({
      maskAllText: true,
    }),
  ],

  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  if (appState) {
    // There is a specific target URL that the Auth0 request wants to send them to, let's go there
    store.dispatch(push(appState.targetUrl));
  } else {
    // User just logged in and there was no specific appState or destination URL
    // Let's send them to our login routing object that will get them to the right place
    store.dispatch(push(`/login/${window.location.search}`));
  }
};

ReactDOM.render(
  <Auth0Provider
    domain={env("AUTH0_DOMAIN")}
    client_id={env("AUTH0_CLIENTID")}
    audience={`https://${env("AUTH0_AUDIENCE")}`}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens={true}
  >
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AuthApolloProvider>
          <App />
        </AuthApolloProvider>
      </ConnectedRouter>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);
