class AccessTokenStorage {
  constructor(token) {
    this.token = token;
  }

  setToken = (token) => {
    this.token = token;
  };

  getToken = () => {
    return this.token;
  };
}

export default new AccessTokenStorage();
