import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { get, isEmpty, map, size } from "lodash";
import { createErrorSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { getUser } from "actions/adminActions";
import { formatCurrency } from "utils/number";
import { FiUsers } from "react-icons/fi";
import { Card } from "react-bootstrap";
import IconTable from "components/IconTable";
import IconTableHeader from "components/IconTableHeader";
import IconEmptyState from "components/IconEmptyState";
import IconSpinner from "components/IconSpinner";
import Alert from "components/Alert";
import Button from "components/Button";
import LiquidationForm from "pages/dashboards/adminDashboard/users/LiquidationForm";
import { ROTH_IRA, TRADITIONAL_IRA } from "statics/accountTypes";

class EmployerLinks extends React.PureComponent {
  static propTypes = {
    error: PropTypes.string,
    userId: PropTypes.string,
    client: PropTypes.object,
    getUser: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      user: {},
      isFetching: true,
      showLiquidationForm: false,
    };
  }

  async componentDidMount() {
    await this._getAndSetUser();
    this.setState({
      isFetching: false,
    });
  }

  _getAndSetUser = async () => {
    const { data: user } = await this.props.getUser(
      this.props.client,
      this.props.userId
    );
    this.setState({ user });
  };

  _getUserAccountsData = () => {
    const accounts = get(this.state.user, "accounts", []);
    return map(accounts, (account) => {
      const totalBalance = get(account, "balances.totalAccountBalance", 0);
      const cashBalance = get(account, "balances.cashBalance", 0);
      const withdrawalBalance = get(account, "balances.withdrawalBalance", 0);
      const assetBalance = totalBalance - cashBalance;
      const pendingContributions = get(
        account,
        "balances.totalPendingContributions",
        0
      );
      const isIra =
        account.accountType === TRADITIONAL_IRA ||
        account.accountType === ROTH_IRA;

      return {
        id: account.id,
        accountType: account.accountType,
        accountNumber: account.accountNumber,
        accountName: account.name,
        cashBalance,
        cashBalanceFormatted: formatCurrency(cashBalance),
        withdrawalBalance,
        withdrawalBalanceFormatted: formatCurrency(withdrawalBalance),
        assetBalance,
        assetBalanceFormatted: formatCurrency(assetBalance),
        pendingContributions: formatCurrency(pendingContributions),
        isIra,
      };
    });
  };

  _buildUserAccountsColumns = () => {
    const columns = [
      {
        label: "Type",
        key: "accountType",
      },

      {
        label: "ID",
        key: "id",
      },
      {
        label: "Account Number",
        key: "accountNumber",
      },
      {
        label: "Account Name",
        key: "accountName",
      },

      {
        label: "Asset Balance",
        key: "assetBalanceFormatted",
      },
      {
        label: "Cash Balance",
        key: "cashBalanceFormatted",
      },
      {
        label: "Withdrawal Balance",
        key: "withdrawalBalanceFormatted",
      },
      {
        label: "Pending Contributions",
        key: "pendingContributions",
      },
      {
        customComponent: (props) => {
          if (!props.isIra) return null;
          return (
            <div>
              <Button
                color="action"
                name="action"
                btnLabel={"Liquidate"}
                onClick={() =>
                  this.setState({
                    showLiquidationForm: true,
                    accountForLiquidation: props,
                  })
                }
                size={"sm"}
              />
            </div>
          );
        },
      },
    ];
    return columns;
  };

  render() {
    const columns = this._buildUserAccountsColumns();
    const data = this._getUserAccountsData();

    const userAccounts = get(this.state.user, "accounts", []);
    const userAccountsCount = size(userAccounts);

    const showBlankSlate = isEmpty(userAccounts);

    if (this.state.isFetching) {
      return <IconSpinner centered />;
    } else if (showBlankSlate) {
      return (
        <div>
          <IconEmptyState
            header={"User Accounts"}
            subheader="No user accounts exisit for this employer."
            icon={<FiUsers color="white" stroke="#60A4BF" size={16} />}
          />
        </div>
      );
    }

    return (
      <Card.Body id={"admin-user-accounts"}>
        {this.props.error && <Alert type={"error"} msg={this.props.error} />}
        {!this.state.showLiquidationForm && (
          <>
            <IconTableHeader
              tableHeader="Accounts"
              tableCount={userAccountsCount}
            />
            <IconTable columns={columns} data={data} hasTruncatedRows />{" "}
          </>
        )}
        {this.state.showLiquidationForm && (
          <LiquidationForm
            onCancel={() =>
              this.setState({
                showLiquidationForm: false,
                accountForLiquidation: {},
              })
            }
            account={this.state.accountForLiquidation}
            onSuccess={() => {
              this.setState({
                showLiquidationForm: false,
                accountForLiquidation: {},
              });
            }}
            userId={this.props.userId}
          />
        )}
      </Card.Body>
    );
  }
}

const errorSelector = createErrorSelector(adminConstants.LIQUIDATE_ACCOUNT);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  getUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerLinks));
