import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { user } from "./userReducer";
import { employer } from "./employerReducer";
import { nav } from "./navigationReducer";
import { loading } from "./loadingReducer";
import { accounts } from "./accountReducer";
import { contribution } from "./contributionReducer";
import { userDocuments } from "./userDocumentsReducer";
import { withdrawals } from "./withdrawalReducer";
import { portfolios } from "./portfolioReducer";
import { sidebar } from "./sidebarReducer";
import { transactions } from "./transactionReducer";
import { beneficiary } from "./beneficiaryReducer";
import { admin } from "./adminReducer";
import { error } from "./errorReducer";
import { payroll } from "./payrollReducer";
import { groupCreation } from "./groupCreationReducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    employer,
    nav,
    loading,
    accounts,
    contribution,
    userDocuments,
    withdrawals,
    portfolios,
    sidebar,
    transactions,
    beneficiary,
    admin,
    error,
    payroll,
    groupCreation,
  });
