import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { adminConstants } from "actions/types";
import { createErrorSelector } from "store/selectors";
import Alert from "components/Alert";
import SimpleModal from "components/SimpleModal";
import IconSpinner from "components/IconSpinner";
import { getLastWorkflowStepForWorkflow } from "actions/adminActions";
import JSONPretty from "react-json-pretty";

class WorkflowStepModal extends React.PureComponent {
  static propTypes = {
    workflowId: PropTypes.string,
    client: PropTypes.object,
    error: PropTypes.string,
    onClose: PropTypes.func,
    getLastWorkflowStepForWorkflow: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      workflowStep: {},
    };
  }

  async componentDidMount() {
    const { data: workflowStep } =
      await this.props.getLastWorkflowStepForWorkflow(
        this.props.client,
        this.props.workflowId
      );
    this.setState({
      isLoading: false,
      workflowStep,
    });
  }

  _buildMetadataTable = () => {
    if (this.state.isLoading) {
      return <IconSpinner centered />;
    }

    return (
      <JSONPretty
        data={this.state.workflowStep}
        themeClassName="custom-json-pretty"
        style={{ fontSize: "14px", margin: 15 }}
        mainStyle="background:#F7F7F7; line-height:1.3; padding: 20px; ;overflow:auto; color:#999;"
        keyStyle="color:#905;"
        stringStyle="color:#690;"
        valueStyle="color:#905;"
        booleanStyle="color:#905;"
      />
    );
  };

  render() {
    if (this.props.error) {
      return <Alert type={"error"} msg={this.props.error} />;
    }

    return (
      <div>
        <SimpleModal
          show={true}
          size={"xl"}
          onClose={() => this.props.onClose()}
          title="Workflow Step Metadata"
          buttonText="close"
        >
          {this._buildMetadataTable()}
        </SimpleModal>
      </div>
    );
  }
}

const errorSelector = createErrorSelector(adminConstants.GET_WORKFLOW_STEPS);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  getLastWorkflowStepForWorkflow,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(WorkflowStepModal));
