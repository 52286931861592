import { errorConstants } from "actions/types";

export const error = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // remove all error objects from state
  if (type === errorConstants.FLUSH_ERRORS) {
    return {};
  }
  // ignore any action that isn't relevant to api calls being made
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // if request action came back as FAILURE we know an error is present, set error, otherwise empty string for error
    [requestName]: requestState === "FAILURE" ? action.error : "",
  };
};
