import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";

import Button from "components/Button";

const PageNotFound = ({ push }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>Page not found</Card.Title>
        <p>
          Uh oh, We can{"'t"} find the page you are looking for. Try going back
          to the previous page or returning to the home page.
        </p>
        <Button
          name="action"
          onClick={() => push("/")}
          btnLabel="Back to Home"
        />
      </Card.Body>
      <div className="page-not-found-container"></div>
    </Card>
  );
};

PageNotFound.propTypes = {
  push: PropTypes.func,
};

const mapDispatchToProps = {
  push,
};

export default connect(null, mapDispatchToProps)(PageNotFound);
