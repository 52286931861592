import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "components/PrivateRoute";
import PublicRoute from "components/PublicRoute";
import IndividualEnroll from "pages/signUp/individual/IndividualEnroll";
import IndividualDisclosure from "pages/signUp/individual/IndividualDisclosure";
import IndividualInvestmentProfile from "pages/signUp/individual/IndividualInvestmentProfile";
import IndividualTerms from "pages/signUp/individual/IndividualTerms";
import IndividualThanks from "pages/signUp/individual/IndividualThanks";
import IndividualPortfolioSelection from "pages/signUp/individual/IndividualPortfolioSelection";
import IndividualAccountType from "../pages/signUp/individual/IndividualAccountType";
import IndividualDocsRequired from "pages/signUp/individual/IndividualDocsRequired";
import IndividualDenied from "pages/signUp/individual/IndividualDenied";
import IndividualSignup from "pages/signUp/individual/IndividualSignup";
import IndividualOptOut from "pages/IndividualOptOut";
import VerifyEmail from "pages/signUp/VerifyEmail";
import DashboardLayout from "pages/dashboards/DashboardLayout";
import EmailVerification from "pages/verification/EmailVerification";
import EmailVerificationRedirect from "pages/verification/EmailVerificationRedirect";
import Home from "pages/Home";
import SetupContribution from "pages/signUp/individual/setupContribution/SetupContribution";
import PageNotFound from "pages/PageNotFound";
import { useMediaQuery } from "react-responsive";
import OauthPlaidLink from "../pages/OauthPlaidLink";
import IndividualRequiredUpdates from "../pages/dashboards/individualDashboard/requiredUpdates/IndividualRequiredUpdates";
import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

const IndividualRoutes = () => {
  return (
    <Switch>
      <PublicRoute
        path="/"
        exact
        component={(props) => <IndividualSignup {...props} />}
      />
      <PublicRoute
        path="/individual/opt-out"
        exact
        component={(props) => <IndividualOptOut {...props} />}
      />
      <PrivateRoute
        path="/individual/verify"
        exact
        component={(props) => <VerifyEmail {...props} />}
      />
      <PrivateRoute
        path="/individual/info"
        exact
        component={(props) => <IndividualEnroll {...props} />}
      />
      <PrivateRoute
        path="/individual/investment"
        exact
        component={(props) => <IndividualInvestmentProfile {...props} />}
      />
      <PrivateRoute
        path="/required-updates"
        exact
        component={(props) => <IndividualRequiredUpdates {...props} />}
      />
      <PrivateRoute
        path="/individual/disclosure"
        exact
        component={(props) => <IndividualDisclosure {...props} />}
      />
      <PrivateRoute
        path="/individual/terms"
        exact
        component={(props) => <IndividualTerms {...props} />}
      />
      <PrivateRoute
        path="/individual/thanks"
        exact
        component={(props) => <IndividualThanks {...props} />}
      />
      <PrivateRoute
        path="/individual/portfolio_selection"
        exact
        component={(props) => <IndividualPortfolioSelection {...props} />}
      />
      <PrivateRoute
        path="/individual/account_type"
        exact
        component={(props) => <IndividualAccountType {...props} />}
      />
      <PrivateRoute
        path="/individual/documentation"
        exact
        component={(props) => <IndividualDocsRequired {...props} />}
      />
      <PrivateRoute
        path="/individual/status"
        exact
        component={(props) => <IndividualDenied {...props} />}
      />
      <PrivateRoute
        path="/individual/change-email"
        exact
        component={(props) => <IndividualDenied {...props} />}
      />
      <PrivateRoute
        path="/individual/attention"
        exact
        component={(props) => <IndividualDocsRequired {...props} />}
      />

      {/* Apex has our documents url as /docs so just redirect to right route */}
      <SentryRoute path="/dashboard/docs" exact>
        <Redirect to="/dashboard/documents" />
      </SentryRoute>
      <SentryRoute path="/docs" exact>
        <Redirect to="/dashboard/documents" />
      </SentryRoute>
      <PrivateRoute
        path="/dashboard"
        component={(props) => (
          <DashboardLayout
            {...props}
            isEmployerDashboard={false}
            isSmallScreen={useMediaQuery({ maxWidth: 767 })}
          />
        )}
      />
      <PrivateRoute
        path="/verify-email"
        component={(props) => <EmailVerification {...props} />}
      />
      <PrivateRoute
        path="/verify-email-redirect"
        component={(props) => <EmailVerificationRedirect {...props} />}
      />
      <PrivateRoute
        path="/login"
        exact
        component={(props) => <Home {...props} />}
      />
      <PrivateRoute
        path="/individual/setup_contribution"
        exact
        component={(props) => <SetupContribution {...props} />}
      />
      <PrivateRoute
        path="/link-bank"
        exact
        component={(props) => <OauthPlaidLink {...props} />}
      />
      <PrivateRoute component={PageNotFound} />
    </Switch>
  );
};

export default IndividualRoutes;
