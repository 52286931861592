import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { accountTypesToEnglishMapping } from "statics/accountTypes";

import "./AccountBox.css";

const AccountBox = ({
  isFrom,
  balance,
  accountName,
  accountType,
  lastFourOfAccount,
  isBank,
}) => {
  const acctBoxClass = classnames("transfer-acct-box", {
    from: isFrom,
    ["has-balance"]: !!balance,
  });
  return (
    <div className={acctBoxClass}>
      <span className="acct-src">{isFrom ? "FROM" : "TO"}</span>
      {isFrom ? (
        <GiPayMoney size={32} color="#01a3b0" />
      ) : (
        <GiReceiveMoney size={32} color="#01a3b0" />
      )}
      <div className="account-name-and-bal">
        <p className="acct-name semi-bold">
          {accountName || accountTypesToEnglishMapping[accountType]}
          {isBank && <span className="last-four"> {lastFourOfAccount}</span>}
        </p>
        {balance && <p className="acct-bal">{balance} balance</p>}
      </div>
    </div>
  );
};

AccountBox.propTypes = {
  isFrom: PropTypes.bool,
  balance: PropTypes.number,
  accountName: PropTypes.string,
  accountType: PropTypes.string,
  lastFourOfAccount: PropTypes.string,
  isBank: PropTypes.bool,
};

export default AccountBox;
