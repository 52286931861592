/* Helper functions/statics for suitability/investment profile form */

import { isNumber } from "lodash";

const yup = require("yup");
import { formatCurrency } from "utils/number";

export const schema = yup.object({
  experience: yup.string().label("Experience").required(),
  annualIncome: yup.string().label("Annual Income").required(),
  liquidNetWorth: yup
    .string()
    .when("totalNetWorth", {
      is: (val) => {
        return !hideLiquidInvestment(val);
      },
      then: yup.string().required(),
      otherwise: yup.string(),
    })
    .label("Liquid Net Worth"),
  totalNetWorth: yup.string().label("Total Net Worth").required(),
  riskTolerance: yup.string().label("Risk Tolerance").required(),
  timeHorizonLiquidity: yup.string().label("Time Horizon Liquidity").required(),
});

export const incomeLimits = [
  { label: "$25,000 or Lower", min: 0, max: 25000, key: "1" },
  { label: "$25,001 to $50,000", min: 25001, max: 50000, key: "2" },
  { label: "$50,001 to $100,000", min: 50001, max: 100000, key: "3" },
  { label: "$100,001 to $200,000", min: 100001, max: 200000, key: "4" },
  { label: "$200,001 to $300,000", min: 200001, max: 300000, key: "5" },
  { label: "$300,001 to $500,000", min: 300001, max: 500000, key: "6" },
  { label: "Over $500,000", min: 500001, max: 1200000, key: "7" },
];

export const wealthLimits = [
  { label: "$50,000 or Lower", min: 0, max: 50000, key: "1" },
  { label: "$50,001 to $100,000", min: 50001, max: 100000, key: "2" },
  { label: "$100,001 to $200,000", min: 100001, max: 200000, key: "3" },
  { label: "$200,001 to $500,000", min: 200001, max: 500000, key: "4" },
  { label: "$500,001 to $1,000,000", min: 500001, max: 1000000, key: "5" },
  { label: "Over $1,000,000", min: 1000001, max: 5000000, key: "6" },
];

export const experienceValues = [
  {
    value: "NONE",
    description: "I have no investing experience.",
    key: "1",
    label: "A",
  },
  {
    value: "LIMITED",
    description: "I have limited experience managing my investments.",
    key: "2",
    label: "B",
  },
  {
    value: "GOOD",
    description: "I have some experience managing my investments.",
    key: "3",
    label: "C",
  },
  {
    value: "EXTENSIVE",
    description: "I have extensive experience managing my investments.",
    key: "4",
    label: "D",
  },
];

export const oldTimeHorizonLiquidityValues = {
  LONGEST_NOT_IMP_30_PLUS: {
    value: "LONGEST_NOT_IMP_30_PLUS",
    description:
      "Not for 30+ years,  I don't plan on touching my investments for at least 30 years.",
    key: "1",
    timeHorizon: "LONGEST",
    liquidityNeeds: "NOT_IMPORTANT",
  },
  LONGEST_NOT_IMP_30: {
    value: "LONGEST_NOT_IMP_30",
    description: "21-30 years",
    key: "2",
    timeHorizon: "LONGEST",
    liquidityNeeds: "NOT_IMPORTANT",
  },
};

export const timeHorizonLiquidityValues = {
  LONGEST_NOT_IMP_25_PLUS: {
    value: "LONGEST_NOT_IMP_25_PLUS",
    description:
      "Not for 25+ years, I don't plan on touching my investments for at least 25 years.",
    key: "1",
    timeHorizon: "LONGEST",
    liquidityNeeds: "NOT_IMPORTANT",
    label: "A",
  },
  LONGEST_NOT_IMP_25: {
    value: "LONGEST_NOT_IMP_25",
    description: "21-25 years",
    key: "2",
    timeHorizon: "LONGEST",
    liquidityNeeds: "NOT_IMPORTANT",
    label: "B",
  },
  LONGEST_NOT_IMP_20: {
    value: "LONGEST_NOT_IMP_20",
    description: "16-20 years",
    key: "3",
    timeHorizon: "LONGEST",
    liquidityNeeds: "NOT_IMPORTANT",
    label: "C",
  },
  LONGEST_NOT_IMP_10: {
    value: "LONGEST_NOT_IMP_10",
    description: "11-15 years ",
    key: "4",
    timeHorizon: "LONGEST",
    liquidityNeeds: "NOT_IMPORTANT",
    label: "D",
  },
  AVERAGE_NOT_IMP: {
    value: "AVERAGE_NOT_IMP",
    description: "6-10 years ",
    key: "5",
    timeHorizon: "AVERAGE",
    liquidityNeeds: "NOT_IMPORTANT",
    label: "E",
  },
  SHORT_SOME_IMP: {
    value: "SHORT_SOME_IMP",
    description:
      "Under 5 years, I need to be able to sell my investments within 5 years.",
    key: "6",
    timeHorizon: "SHORT",
    liquidityNeeds: "SOMEWHAT_IMPORTANT",
    label: "F",
  },
};

export const riskToleranceValues = [
  {
    value: "LOW_CAP",
    description:
      "I want stability more than I want growth, I have a hard time with market ups and downs.",
    key: "1",
    label: "A",
  },
  {
    value: "LOW_GROWTH",
    description:
      "I want conservative growth with less exposure to ups and downs in the market.",
    key: "2",
    label: "B",
  },
  {
    value: "MEDIUM_GROWTH_1",
    description:
      "I want some growth potential, I can handle moderate ups and downs in the market.",
    key: "3",
    label: "C",
  },
  {
    value: "MEDIUM_GROWTH_2",
    description:
      "I want moderate growth potential and I can handle ups and downs in the market.",
    key: "4",
    label: "D",
  },
  {
    value: "HIGH_GROWTH",
    description:
      "I want high growth potential and I'm willing to accept substantial risk in the market.",
    key: "5",
    label: "E",
  },
];

export const incomeLabel = (val) => {
  if (val.rawValue) {
    return `${formatCurrency(val.rawValue)}`;
  }
  if (val && isNumber(val.min)) {
    for (let i in incomeLimits) {
      if (incomeLimits[i].min === val.min) {
        return incomeLimits[i].label;
      }
    }
  }
  return "";
};

export const wealthLabel = (val) => {
  if (val && isNumber(val.min)) {
    for (let i in incomeLimits) {
      if (wealthLimits[i].min === val.min) {
        return wealthLimits[i].label;
      }
    }
  }
  return "";
};

export const incomeRange = (val) => {
  for (let i in incomeLimits) {
    if (incomeLimits[i].label === val) {
      return { min: incomeLimits[i].min, max: incomeLimits[i].max };
    }
  }
  return { min: incomeLimits[0].min, max: incomeLimits[0].max };
};

export const wealthRange = (val) => {
  for (let i in wealthLimits) {
    if (wealthLimits[i].label === val) {
      return { min: wealthLimits[i].min, max: wealthLimits[i].max };
    }
  }
  return { min: wealthLimits[0].min, max: wealthLimits[0].max };
};

export function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getUTCFullYear() - birthDate.getUTCFullYear();
  var month = today.getUTCMonth() - birthDate.getUTCMonth();
  if (
    month < 0 ||
    (month === 0 && today.getUTCDate() < birthDate.getUTCDate())
  ) {
    age--;
  }
  return age;
}

export function hideLiquidInvestment(totalNetWorth = "") {
  return totalNetWorth === "$50,000 or Lower" || totalNetWorth == "";
}
