import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import {
  getLinkedEmployees,
  getPendingLinkedEmployees,
} from "actions/employerActions";

import ProcessGroupsTable from "./ProcessGroupsTable";
import IconSpinner from "components/IconSpinner";
import {
  getAllEmployerAccounts,
  getAllEmployerGroups,
} from "actions/employerActions";

class ProcessContributions extends React.Component {
  static propTypes = {
    getLinkedEmployees: PropTypes.func,
    getPendingLinkedEmployees: PropTypes.func,
    getAllEmployerAccounts: PropTypes.func,
    getAllEmployerGroups: PropTypes.func,
    client: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      initialFetching: true,
    };
  }

  async componentDidMount() {
    window.analytics.page("Employer Process Contributions");
    await Promise.all([
      this.props.getAllEmployerGroups(this.props.client),
      this.props.getLinkedEmployees(this.props.client),
      this.props.getPendingLinkedEmployees(this.props.client),
      this.props.getAllEmployerAccounts(this.props.client),
    ]);
    this.setState({ initialFetching: false });
  }

  render() {
    let content;
    if (this.state.initialFetching) {
      content = <IconSpinner centered />;
    } else {
      content = <ProcessGroupsTable />;
    }
    return (
      <div className="mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Process Contributions</h1>
            <p className="page-subtext">
              Process a scheduled payroll contribution for the current pay
              period.
            </p>
          </article>
        </section>
        <div className="main-content">{content}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getLinkedEmployees,
  getPendingLinkedEmployees,
  getAllEmployerAccounts,
  getAllEmployerGroups,
};

export default connect(
  null,
  mapDispatchToProps
)(withApollo(ProcessContributions));
