/* Helper functions/statics for portfolio selection*/

export const portfolioMetadataMap = {
  aggressive: {
    label: "Aggressive",
    risk: "Higher risk, larger growth potential",
    portfolio: "Determined",
    stock: "100% stocks",
    description:
      "This portfolio prioritizes long-term growth above all other considerations and may experience very large market fluctuations. It’s built for an investor who is willing to accept a high level of risk. This portfolio has an aggressive risk profile.",
    key: "1",
  },
  moderatelyAggressive: {
    label: "Moderately Aggressive",
    risk: "Moderately aggresive risk, large growth potential",
    portfolio: "Focused",
    stock: "80% stocks",
    bond: "20% bonds",
    description:
      "This portfolio prioritizes long-term growth and may experience large market fluctuations. It’s built is for an investor willing to accept some risk with their investments. This portfolio has a moderately aggressive risk profile",
    key: "2",
  },
  moderate: {
    label: "Moderate",
    risk: "Moderate risk, standard growth potential",
    portfolio: "Balanced",
    stock: "60% stocks",
    bond: "40% bonds",
    description:
      " This portfolio balances moderate market fluctuations while still maintaining the potential for moderate account growth. It’s built for people that don’t want a large amount of risk with their investments. This portfolio has a moderate risk profile.",
    key: "3",
  },
  moderatelyConservative: {
    label: "Moderately Conservative",
    risk: "Moderately conservative risk, medium growth potential",
    portfolio: "Deliberate",
    stock: "40% stocks",
    bond: "60% bonds",
    description:
      "This portfolio favors low market fluctuations while still maintaining the potential for a conservative amount of growth. It’s built for people who want low risk investing. This portfolio has a moderately conservative risk profile.",
    key: "4",
  },
  conservative: {
    label: "Conservative",
    risk: "Conservative risk, small growth potential",
    portfolio: "Steady",
    bond: "100% bonds",
    description:
      "This portfolio favors keeping market fluctuations to a minimum and maintaining account stability over all other considerations. It’s built for people who wants very little risk in their investments. This portfolio has a conservative risk profile.",
    key: "5",
  },
};
