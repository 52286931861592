import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { NavLink } from "react-router-dom";
import { withApollo } from "@apollo/client/react/hoc";
import { Auth0Context } from "utils/react-auth0-wrapper";
import { isIndividualAccountOverview } from "store/selectors/user";

import "components/navbar/SubNav.scss";

export class SubNav extends Component {
  static contextType = Auth0Context;

  static propTypes = {
    push: PropTypes.func,
    isAccountOverview: PropTypes.bool,
    location: PropTypes.object,
    routes: PropTypes.arrayOf(PropTypes.object),
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  // this function creates the links
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect || prop.hidden) {
        return null;
      } else {
        return (
          <li className={this.activeRoute(prop.path)} key={key}>
            <NavLink
              to={prop.path}
              className="icon-nav-link"
              activeClassName="active"
            >
              <>
                <div className="subnav-routes">
                  <p className="subnav-name"> {prop.name}</p>
                </div>
              </>
            </NavLink>
          </li>
        );
      }
    });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    if (routeName === this.props.location.pathname) {
      return this.props.location.pathname.indexOf(routeName) > -1
        ? "active"
        : "";
    } else {
      return 0;
    }
  };

  render() {
    return (
      <div
        className={classnames("subnav", {
          ["hide-subnav"]: this.props.isAccountOverview,
        })}
      >
        <div
          className="subnav-wrapper"
          ref={(ref) => (this.subnavWrapper = ref)}
        >
          <ul className="subnav-nav">{this.createLinks(this.props.routes)}</ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAccountOverview: isIndividualAccountOverview(state),
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(withApollo(SubNav));
