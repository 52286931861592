import React from "react";
import PropTypes from "prop-types";
import { get, some } from "lodash";
import { Col, Form } from "react-bootstrap";
import {
  inviteStatusToType,
  inviteStatusToEnglish,
} from "statics/statusToEnglishMapping";
import IconTable from "../../../../components/IconTable";
import StatusBox from "components/StatusBox";

import "./UploadFailed.scss";

export class UploadFailed extends React.PureComponent {
  static propTypes = {
    failedJob: PropTypes.arrayOf(PropTypes.object),

    errors: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        format: PropTypes.string,
        resaon: PropTypes.string,
      })
    ),
  };

  _groupName = () => {
    const groupName = get(this.props.failedJob, "group.name");
    const { id } = this.props.failedJob;

    return (
      <div className="group-select" key={id}>
        <Form.Row>
          <Form.Group as={Col} sm={6} controlId="formBasicGroupId">
            <Form.Label>Group</Form.Label>
            <Form.Control placeholder={groupName} disabled />
          </Form.Group>
        </Form.Row>
      </div>
    );
  };

  _buildColumns = () => {
    const { filename, status } = this.props.failedJob;

    const columns = [
      {
        label: "File Name",
        customComponent: () => {
          return (
            <div>
              <div className="uploaded-file">
                <div className="file-type">
                  <img className="png" src="/assets/file.png"></img>
                </div>
                <p className="file"> {filename}</p>
              </div>
            </div>
          );
        },
      },

      {
        label: "Reason",
        key: "reason",
      },
      {
        label: "Format",
        key: "format",
        customComponent: () => {
          const [{ format }] = this.props.failedJob.errors;
          const hasFormat = some(format, (error) => error.format !== null);
          const showFormat = hasFormat ? format : " None ";
          return showFormat;
        },
      },
      {
        label: "Status",
        customComponent: () => {
          return (
            <StatusBox
              status={inviteStatusToEnglish[status]}
              type={inviteStatusToType[status]}
            />
          );
        },
      },
    ];
    return columns;
  };

  render() {
    const groupName = this._groupName();
    const columns = this._buildColumns();

    return (
      <div className="add-employee-form" style={{ marginBottom: 8 }}>
        <div>{groupName}</div>
        <Form.Label>Error Details</Form.Label>
        <IconTable columns={columns} data={this.props.failedJob.errors} />
      </div>
    );
  }
}

export default UploadFailed;
