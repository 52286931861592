import { Col, Row } from "react-bootstrap";
import React from "react";
import PropTypes from "prop-types";

export const NavLoginHeaderText = ({ onClick }) => (
  <span className="header-cta-text">
    <Col className="login">
      <Row>
        &nbsp;Already a user?
        <hr className="line" />
        <a
          className="link"
          onClick={() =>
            onClick({
              redirect_uri:
                window.location.origin + "/" + window.location.search,
            })
          }
        >
          Sign In
        </a>
      </Row>
    </Col>
  </span>
);

NavLoginHeaderText.propTypes = {
  onClick: PropTypes.func,
};

export default NavLoginHeaderText;
