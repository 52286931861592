import React, { useState } from "react";
import classnames from "classnames";
import { BsBriefcase } from "react-icons/bs";

import AddEmployerModal from "pages/dashboards/individualDashboard/contributions/AddEmployerModal";
import ActionCard from "components/ActionCard";

const AddEmployerCard = () => {
  const cardWrapperClass = classnames("add-bank-card", "action-card");
  const [showAddEmployerModal, setShowAddEmployerModal] = useState(false);

  return (
    <div className={cardWrapperClass}>
      <ActionCard
        onClick={() => {
          setShowAddEmployerModal(true);
        }}
        icon={<BsBriefcase color="#60A4BF" stroke="#60A4BF" size={16} />}
        title={"Link Your Employer"}
        description={"Link your employer to setup payroll contributions."}
        actionText={"Link Employer"}
        completedAction={false}
        loading={false}
      />
      <AddEmployerModal
        show={showAddEmployerModal}
        onClose={() => setShowAddEmployerModal(false)}
      />
    </div>
  );
};

export default AddEmployerCard;
