import React from "react";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { PropTypes } from "prop-types";

import SimpleModal from "components/SimpleModal";

import { inviteAdministrator } from "actions/employerActions";
import { createLoadingSelector, createErrorSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { toast } from "react-toastify";
import AdministratorForm from "pages/dashboards/employerDashboard/profile/AdministratorForm.js";

class InvitationModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    inviteAdministrator: PropTypes.func,
    error: PropTypes.string,
    isInviting: PropTypes.bool,
    client: PropTypes.object,
    isAdminForm: PropTypes.bool,
    administrators: PropTypes.array,
    companyId: PropTypes.string,
  };

  _inviteAdmin = (values) => {
    this.props
      .inviteAdministrator(this.props.client, {
        ...values,
        companyId: this.props.companyId,
      })
      .then(() => {
        if (!this.props.error) {
          this.props.onSuccess();
          toast.success("Successfully invited administrator.");
        }
      });
  };

  render() {
    const role = this.props.isAdminForm ? "Administrator" : "Agent";
    return (
      <SimpleModal
        show={true}
        onClose={this.props.onClose}
        title={`Invite ${role}`}
        showCloseButton={true}
        hideActions={true}
      >
        <AdministratorForm
          error={this.props.error}
          administrators={this.props.administrators}
          onSubmit={this._inviteAdmin}
          isInviting={this.props.isInviting}
          fixedRole={this.props.isAdminForm ? "ADMIN" : "AGENT"}
        />
      </SimpleModal>
    );
  }
}

const isInvitingSelector = createLoadingSelector(
  employerConstants.INVITE_ADMINISTRATOR
);

const errorSelector = createErrorSelector(
  employerConstants.INVITE_ADMINISTRATOR
);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isInviting: isInvitingSelector(state),
  };
};

const mapDispatchToProps = {
  inviteAdministrator,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(InvitationModal));
