import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { withApollo } from "@apollo/client/react/hoc";
import queryString from "query-string";
import { get, some, toLower, trim } from "lodash";

import { registerUserRequest } from "actions/userActions";
import { userConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { testUsername } from "utils/fieldValidators";
import ProgressBar from "components/ProgressBar";
import CircularProgressBar from "components/CircularProgressBar";
import Button from "components/Button";
import Alert from "components/Alert";

import "./EmployerEnroll.scss";
import PlanCard from "./PlanCard";
import { CREATE_PROFILE } from "statics/onboardingSteps";
import { ScrollToFieldError } from "utils/form";
import { wholesaleBillingReferralCodes } from "statics/payrollProviders";
import ExpiredQuoteCard from "pages/signUp/employer/ExpiredQuoteCard";

let yup = require("yup");

const schema = yup.object({
  username: yup
    .string()
    .label("Username")
    .required()
    .min(3, "Must be at least three characters")
    .max(25)
    .test("username", "Invalid username.", testUsername),
  email: yup
    .string()
    .label("Email")
    .email("You must enter a valid email address")
    .max(40)
    .required(),
  firstName: yup.string().label("First Name").required(),
  lastName: yup.string().label("Last Name").required(),
  password: yup
    .string()
    .label("Password")
    .required()
    .min(8, "Seems a bit short...")
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      "Must be at least 8 characters long with one uppercase, one lowercase, one number and one special character (e.g. #?!@$%^&*- )"
    ),
  confirmPassword: yup
    .string()
    .required()
    .label("Confirm password")
    .test("passwords-match", "Passwords must match", function (value) {
      return this.parent.password === value;
    }),
  referralCode: yup.string().label("Referral Code").max(30),
});

class EmployerSignup extends Component {
  static propTypes = {
    client: PropTypes.shape({}),
    registerUserRequest: PropTypes.func,
    error: PropTypes.string,
    userId: PropTypes.string,
    isFetching: PropTypes.bool,
    hasExpiredSalesQuote: PropTypes.bool,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    const queryParams = queryString.parse(window.location.search);
    const referralCode = get(queryParams, "referralCode", "");
    let planCode = get(queryParams, "planCode");
    const isWholesaleBillingReferral = some(
      wholesaleBillingReferralCodes,
      (code) => {
        return toLower(code) === toLower(referralCode);
      }
    );
    if (isWholesaleBillingReferral) {
      planCode = "";
    }

    this.state = {
      user: {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        role: "",
        referralCode,
        planCode,
      },
      submitted: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.analytics.page("Employer Signup");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  submitUser = (values) => {
    let user = {
      username: values.username,
      email: values.email,
      password: values.password,
      firstName: trim(values.firstName),
      lastName: trim(values.lastName),
      role: "Employer Admin",
      referralCode: values.referralCode || "",
      planCode: this.state.user.planCode,
    };
    this.props.registerUserRequest(this.props.client, user);

    if (this._isMounted) {
      this.setState({
        submitted: true,
      });
    }
  };

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    if (this.props.hasExpiredSalesQuote) {
      return (
        <div className="mega-container">
          <ExpiredQuoteCard />
        </div>
      );
    }

    return (
      <div>
        <ProgressBar
          isEmployer={true}
          showBillingRoute={!!this.state.user.planCode}
          activeStepId={CREATE_PROFILE.id}
          progressPercent={"25"}
        />
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={(values) => {
            this.submitUser(values);
          }}
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            username: "",
            referralCode: this.state.user.referralCode,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <div className="mega-container">
                <div
                  className="step-container is-active"
                  data-circle-percent="25"
                >
                  <section className="page-title-wrap">
                    <article className="text-cell">
                      <p className="page-title">Create Your Company Account</p>
                      <p className="page-subtext">
                        Let&#39;s start by creating your company administrator
                        login.
                      </p>
                    </article>
                    <article className="progress-cell">
                      <ul className="circular-progress-wrap">
                        <CircularProgressBar
                          strokeWidth="8"
                          sqSize="75"
                          percentage="25"
                        />
                      </ul>
                    </article>
                  </section>
                  <div id="form-employer-profile">
                    <section className="form-sec-2col">
                      <article className="col-form" style={articleStyle}>
                        <Form.Group controlId="formBasicUsername">
                          <Form.Control
                            name="username"
                            placeholder="Username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.username && !!errors.username}
                            isValid={touched.username && !errors.username}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.email && !!errors.email}
                            isValid={touched.email && !errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicFirstName">
                          <Form.Control
                            name="firstName"
                            placeholder="First Name"
                            value={values.firstName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.firstName && !!errors.firstName}
                            isValid={touched.firstName && !errors.firstName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicLastName">
                          <Form.Control
                            name="lastName"
                            placeholder="Last Name"
                            value={values.lastName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.lastName && !!errors.lastName}
                            isValid={touched.lastName && !errors.lastName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.password && !!errors.password}
                            isValid={touched.password && !errors.password}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicPasswordConfirm">
                          <Form.Control
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.confirmPassword &&
                              !!errors.confirmPassword
                            }
                            isValid={
                              touched.confirmPassword && !errors.confirmPassword
                            }
                          />
                          <Form.Control.Feedback>
                            Passwords match!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group sm={4} controlId="formBasicReferralCode">
                          <div className="referralCode">
                            <Form.Control
                              name="referralCode"
                              placeholder="Referral Code (Optional)"
                              value={values.referralCode}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              disabled={!!this.state.user.referralCode}
                              isInvalid={
                                touched.referralCode && !!errors.referralCode
                              }
                              isValid={
                                touched.referralCode && !errors.referralCode
                              }
                            />
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {errors.referralCode}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </article>
                      <PlanCard planCode={this.state.user.planCode} />
                    </section>
                    <section className="form-sec-2col">
                      <article className="col-form">
                        <p
                          style={{
                            fontStyle: "italic",
                            fontSize: 14,
                            paddingTop: 10,
                            paddingLeft: 4,
                          }}
                        >
                          By pressing the Continue button to create your account
                          you are certifying that you have read and agree to our{" "}
                          <a
                            href="https://iconsavingsplan.com/terms"
                            className="modal-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#01a3b0",
                            }}
                          >
                            Terms of Service
                          </a>{" "}
                          and{" "}
                          <a
                            href="https://iconsavingsplan.com/privacy"
                            className="modal-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#01a3b0",
                            }}
                          >
                            Privacy Policy
                          </a>
                          .
                        </p>
                        {this.props.error && (
                          <Alert type="error" msg={this.props.error} />
                        )}
                        <div className="submit-row">
                          <Button
                            name="submit"
                            withArrow={true}
                            loading={this.props.isFetching}
                          />
                        </div>
                      </article>
                    </section>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const actions = [userConstants.REGISTER_USER];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const hasExpiredSalesQuote = get(
    state,
    "employer.currentSalesQuote.isExpired",
    false
  );
  return {
    error: errorSelector(state),
    isFetching: loadingSelector(state),
    hasExpiredSalesQuote,
  };
};

const mapDispatchToProps = {
  registerUserRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerSignup));
