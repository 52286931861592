import React from "react";
import PropTypes from "prop-types";

import { Card, Col, Row } from "react-bootstrap";
import Button from "components/Button";
import Errors from "./Errors";

import "./UploadFailed.scss";

export class UploadFailed extends React.PureComponent {
  static propTypes = {
    failedJob: PropTypes.arrayOf(PropTypes.object),
    isIconAdmin: PropTypes.bool,
    onClose: PropTypes.func,
    showForm: PropTypes.func,

    errors: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        format: PropTypes.string,
        resaon: PropTypes.string,
      })
    ),
  };

  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
    };
  }

  _renderFailedJobs = () => {
    return (
      <div className="download-employee-template">
        <div className="main-content">
          <Row>
            <Col>
              <Card>
                <Card.Title>
                  <p className="page-header">Errors processing file... </p>
                  <p className="page-subtext">
                    Some records failed to import to the system, please check
                    them, and upload a revised file.
                  </p>
                </Card.Title>
                <Card.Body>
                  <Errors failedJob={this.props.failedJob} />
                </Card.Body>
                <div className="submit btn-row">
                  {this.props.isIconAdmin && (
                    <Button
                      btnLabel="Go Back"
                      name="cancel"
                      color="cancel"
                      onClick={this.props.onClose}
                    />
                  )}
                  <Button
                    btnLabel="Upload File"
                    name="action"
                    onClick={this.props.showForm}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="add-employee-form" style={{ marginBottom: 8 }}>
        {this._renderFailedJobs()}
      </div>
    );
  }
}

export default UploadFailed;
