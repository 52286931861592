import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { accountConstants, userConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { cancelRollover } from "actions/userActions";
import { setAccountSession } from "actions/accountActions";
import { auth0AccountSessionSelector } from "store/selectors/auth0";
import { get, find } from "lodash";
import { Modal } from "react-bootstrap";
import { getAllAccounts } from "actions/accountActions";
import {
  newActiveRolloverAccount,
  activeRolloverAccountSameAsCurrent,
  activeRolloverIsMixed,
} from "store/selectors/user";

import Button from "components/Button";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";

import "./RolloverInstructionsModal.scss";

class CancelRolloverModal extends React.PureComponent {
  static propTypes = {
    getAllAccounts: PropTypes.func,
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    push: PropTypes.func,
    client: PropTypes.object,
    cancelRollover: PropTypes.func,
    setAccountSession: PropTypes.func,
    isSubmitting: PropTypes.bool,
    error: PropTypes.string,
    activeAccountId: PropTypes.string,
    accountId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedAccount: "",
      showDeletedComponent: false,
      submitted: false,
    };
  }

  _cancelRolloverAccount = () => {
    this.props
      .cancelRollover(this.props.client, this.props.activeAccountId)
      .then(async () => {
        if (!this.props.error) {
          await this.props.getAllAccounts(this.props.client);
          await this.props.setAccountSession(
            this.props.client,
            this.props.accountId
          );
          toast.success("You have successfully canceled your rollover.");

          window.location.search = `?redirect_to=/dashboard`;
          window.analytics.track("Cancelled Individual Rollover");

          this.setState({
            submitted: true,
          });
        } else {
          console.error("Error found:", this.props.error);
        }
      })
      .catch((error) => {
        toast.error("There was a problem canceling your rollover.");
        console.error("An error occurred:", error);
      });
  };

  render() {
    if (this.props.show) {
      return (
        <Modal
          centered
          scrollable
          show={this.props.show}
          className="rollover-modal"
        >
          <Modal.Header className="modal-header">
            <IconHeader
              variant="cardHeader"
              headerText="Are you sure you want to cancel your rollover?"
            />
            <IconSubheader subheader="This will terminate your active rollover." />
          </Modal.Header>

          <Modal.Footer>
            <Button
              color="dismiss"
              btnLabel="Close"
              type="button"
              name="cancel"
              onClick={this.props.onClose}
            />
            <Button
              color="red"
              name="cancel"
              withArrow={true}
              loading={this.state.submitted}
              btnLabel="Cancel Rollover"
              onClick={() => this._cancelRolloverAccount()}
            />
          </Modal.Footer>
        </Modal>
      );
    }
    return null;
  }
}

const actions = [userConstants.CANCEL_ROLLOVER, accountConstants.GET_ACCOUNTS];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);
const mapStateToProps = (state) => {
  const accounts = state.accounts.iraAccounts;
  const noneRolloverAccountId = find(accounts, { isRollover: false })?.id;
  const firstAccountId = get(accounts, "[0].id", null);

  let accountId = null;

  if (newActiveRolloverAccount(state)) {
    accountId = noneRolloverAccountId;
  } else if (activeRolloverAccountSameAsCurrent(state)) {
    accountId = auth0AccountSessionSelector(state);
  } else if (activeRolloverIsMixed(state)) {
    accountId = firstAccountId;
  }

  return {
    accountId,
    isSubmitting: loadingSelector(state),
    error: errorSelector(state),
    activeAccountId: auth0AccountSessionSelector(state),
    isRolloverAccountNew: newActiveRolloverAccount(state),
    isRolloverSameAsCurrent: activeRolloverAccountSameAsCurrent(state),
    isRolloverIsMixed: activeRolloverIsMixed(state),
  };
};

const mapDispatchToProps = {
  push,
  cancelRollover,
  getAllAccounts,
  setAccountSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(CancelRolloverModal));
