import React from "react";
import PropTypes from "prop-types";
import { Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Formik } from "formik";
import * as yup from "yup";

import { validateEin } from "actions/userActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { userConstants } from "actions/types";

import Button from "components/Button";
import Alert from "components/Alert";
import { ScrollToFieldError } from "utils/form";

class EinVerification extends React.PureComponent {
  static propTypes = {
    setHasSubmittedEin: PropTypes.func,
    validateEin: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    companyId: PropTypes.string,
    isFetching: PropTypes.bool,
    isKelly: PropTypes.bool,
  };

  _validateEin = (values) => {
    const params = {
      ein: values.ein,
      companyId: this.props.companyId,
    };
    this.props.validateEin(this.props.client, params).then(() => {
      if (!this.props.error) {
        this.props.setHasSubmittedEin(true);
      }
    });
  };

  render() {
    const isKelly = this.props.isKelly;
    const idName = isKelly ? "ALT ID" : "Employee Identification Number";

    const schema = yup.object({
      ein: yup.string().label(idName).min(1).max(32).required(),
      confirmEin: yup
        .string()
        .required()
        .label(`Confirm ${idName}`)
        .max(32)
        .min(1)
        .test("eins-match", `${idName} must match`, function (value) {
          return this.parent.ein === value;
        }),
    });

    return (
      <Formik
        validateOnChange={false}
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={(values) => {
          this._validateEin(values);
        }}
        initialValues={{
          confirmEin: "",
          ein: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <ScrollToFieldError />
            <Modal.Header closeButton>
              <h3 className="modal-title">Employer Plan</h3>
              <p className="modal-subtitle">
                In order to link with your employer please provide your {idName}{" "}
                given to you by your employer.{" "}
                {isKelly &&
                  `Your ALT ID is a unique
                identifying ID number, located in the box to the right of your
                name and address on your paystub or on your ePaystub.`}
              </p>
            </Modal.Header>
            <Modal.Body>
              <div className="invitation-code-wrapper">
                <h5>Employee</h5>

                <Form.Group controlId="formEin">
                  <Form.Label>{idName}</Form.Label>

                  <Form.Control
                    name="ein"
                    placeholder={isKelly ? "ALT ID" : "EIN"}
                    value={values.ein}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.ein && !!errors.ein}
                    isValid={touched.ein && !errors.ein}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.ein}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formConfirmEin">
                  <Form.Label>Confirm {idName}</Form.Label>

                  <Form.Control
                    name="confirmEin"
                    placeholder={isKelly ? "Confirm ALT ID" : "Confirm EIN"}
                    value={values.confirmEin}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.confirmEin && !!errors.confirmEin}
                    isValid={touched.confirmEin && !errors.confirmEin}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.confirmEin}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              {this.props.error && (
                <Alert type="error" msg={this.props.error} />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                name="submit"
                withArrow={true}
                btnLabel="Continue"
                loading={this.props.isFetching}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    );
  }
}

const actions = [userConstants.VALIDATE_EIN];

const isFetchingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    isFetching: isFetchingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  validateEin,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EinVerification));
