// import { withdrawalConstants } from "actions/types";

const initialState = {};

export function withdrawals(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
