import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { get, size } from "lodash";
import { Card } from "react-bootstrap";
import { FiUpload, FiAlertTriangle } from "react-icons/fi";
import {
  inviteStatusToEnglish,
  inviteStatusToType,
} from "statics/statusToEnglishMapping";

import moment from "moment";
import Button from "components/Button";
import StatusBox from "components/StatusBox";
import SimpleModal from "components/SimpleModal";
import AddEmployeeUploadFormToggle from "./AddEmployeeUploadFormToggle";
import Errors from "./Errors";
import IconTable from "components/IconTable";

import "./EmployeeInvitationsTable.scss";

export class EmployeeUploadJobTable extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    employeeUploadJobs: PropTypes.arrayOf(PropTypes.object),
    isIconAdmin: PropTypes.bool,
    companyId: PropTypes.string,

    errors: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        format: PropTypes.string,
        resaon: PropTypes.string,
      })
    ),
  };

  constructor(props) {
    super(props);

    this.state = {
      initialFetching: true,
      showForm: false,
      showErrorsModal: false,
      failedJobData: null,
    };
  }

  _buildColumns = () => {
    const commonDateFormat = "MMM Do YYYY";
    const columns = [
      {
        label: "Group Name",
        key: "group.name",
      },

      {
        label: "File Name",
        key: "filename",
      },
      {
        label: "Processing Date",
        customComponent: (props) => (
          <>{moment(props.createdAt).format(commonDateFormat)}</>
        ),
      },
      {
        label: "Status",
        customComponent: (props) => (
          <StatusBox
            status={inviteStatusToEnglish[props.status]}
            type={inviteStatusToType[props.status]}
          />
        ),
      },
      {
        label: "Errors",
        customComponent: (props) => {
          const errors = props.errors;
          const errorSize = size(errors);
          const hideErrors = props.status === "PROCESSING" || errorSize === 0;

          const viewErrors = `View Errors ${errorSize}`;

          return (
            <div className="errors">
              {!hideErrors && (
                <Button
                  btnLabel={viewErrors}
                  name="action"
                  color="red"
                  size="sm"
                  icon={{
                    icon: <FiAlertTriangle size={14} color={"#fffff"} />,
                    position: "left",
                  }}
                  onClick={() =>
                    this.setState({
                      showErrorsModal: true,
                      failedJobData: props,
                    })
                  }
                />
              )}
            </div>
          );
        },
      },
    ];
    return columns;
  };

  _renderUploadsTable() {
    const columns = this._buildColumns();
    const processedFiles = size(this.props.employeeUploadJobs);

    return (
      <div className="group-table">
        {this.state.showErrorsModal && (
          <SimpleModal
            className="errors-modal"
            show={true}
            size={"lg"}
            onClose={() =>
              this.setState({ showErrorsModal: false, failedJobData: null })
            }
            title="Error Details"
            buttonText="close"
          >
            <Errors failedJob={this.state.failedJobData} />
          </SimpleModal>
        )}
        <Card className="employee_-nvitations-table">
          <div className="widget-header">
            <span>
              <Card.Title>
                Files:
                <p className="invite-number">
                  Processed (<p className="number">{processedFiles}</p>)
                </p>
              </Card.Title>
            </span>
            <div className="action-btns">
              <span className="add-btn">
                <Button
                  icon={{
                    icon: <FiUpload size={14} color={"#fffff"} />,
                    position: "left",
                  }}
                  size="sm"
                  name="action"
                  onClick={() => this.setState({ showForm: true })}
                  btnLabel="Upload File"
                />
              </span>
            </div>
          </div>
          <IconTable columns={columns} data={this.props.employeeUploadJobs} />
        </Card>
      </div>
    );
  }

  render() {
    let content;

    if (this.state.showForm) {
      content = (
        <AddEmployeeUploadFormToggle
          onClose={() => this.setState({ showForm: false })}
          isIconAdmin={this.props.isIconAdmin}
          companyId={this.props.companyId}
          onUploadSuccess={() => {
            this.props.push("/dashboard/users/employees");
          }}
        />
      );
    } else {
      content = this._renderUploadsTable();
    }
    return (
      <div className="add-employee-form" style={{ marginBottom: 8 }}>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employeeUploadJobs: get(state, "employer.employeeUploadJobs", []),
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployeeUploadJobTable));
