import React from "react";
import PropTypes from "prop-types";
import Viewer from "react-viewer";
import JSONPretty from "react-json-pretty";
import Collapsible from "react-collapsible";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import IconSpinner from "components/IconSpinner";
import Button from "components/Button";
import InfoTable from "components/InfoTable";
import PdfViewer from "components/PdfViewer";
import Alert from "components/Alert";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { setApprovalStatus } from "actions/adminActions";
import { capitalCase } from "change-case";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, Col, Form, Row } from "react-bootstrap";
import { find, get, isEmpty, map, size } from "lodash";
import { Formik } from "formik";
import { FiDollarSign, FiList } from "react-icons/fi";
import {
  approveYellowPathAppeal,
  denyYellowPathAppeal,
  getOrigination,
  retryYellowPathAppeal,
} from "services/adminService";

import "./Origination.scss";
import { ScrollToFieldError } from "utils/form";

const yup = require("yup");

const schema = yup.object({
  approvalStatus: yup.string().required(),
  reason: yup.string(),
  comments: yup.string().required("Comment is required"),
  appealReason: yup.string().when("approvalStatus", {
    is: (status) => status === "RETRY",
    then: yup.string(),
    otherwise: yup.string().required("An appeal reason is required"),
  }),
});

const approvalOptions = [
  { value: "APPROVED", label: "Approved" },
  { value: "DENIED", label: "Denied" },
  { value: "RETRY", label: "Request New Documents" },
];

const metadata = [
  {
    key: "userInfo.id",
    label: "Icon User Id",
  },
  {
    key: "userInfo",
    label: "Name",
    format(userInfo) {
      return `${userInfo.firstName} ${userInfo.lastName}`;
    },
  },
  {
    key: "application.iraAccountDocument.identity.dob",
    label: "Date of Birth",
  },
  {
    key: "application.iraAccountDocument.identity.citizenshipCountry",
    label: "Citizenship",
  },
  {
    key: "application.iraAccountDocument.identity",
    label: "Address",
    format(info) {
      return `${info.address1}${info.address2 && ", " + info.address2}, ${
        info.city
      }, ${info.state} ${info.postalCode} `;
    },
  },
  {
    key: "application.iraAccountDocument.identity.ssn",
    label: "SSN",
  },
  {
    key: "application.iraAccountDocument.identity.phone",
    label: "Phone",
  },
  {
    key: "iconAccountId",
    label: "Icon Account ID",
  },
  {
    key: "silaWalletId",
    label: "Sila Wallet ID",
  },
  {
    key: "apexAccountId",
    label: "Apex Account ID",
  },
  {
    key: "apexAccountStatus",
    label: "Apex Account Status",
  },
];

class Origination extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    iconId: PropTypes.string,
    error: PropTypes.string,
    isSubmitting: PropTypes.bool,
    setApprovalStatus: PropTypes.func,
    disabled: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      origination: {},
      initialFetching: true,
      approvalStatus: "",
      comments: "",
      reason: "NONE",
      appealReason: "",
      documentToView: {},
    };
  }

  async componentDidMount() {
    const origination = await getOrigination(
      this.props.client,
      this.props.iconId
    );

    this.setState({
      initialFetching: false,
      origination,
    });
  }

  _buildYellowPathAppealPayload(approval, values) {
    const userId = this.state.origination.userInfo.id;
    const documentIds = this.state.origination.documents.map((d) => d.id);
    let yellowPathAppeal = {
      userId,
      documentIds,
    };
    if (values.approvalStatus !== "RETRY") {
      yellowPathAppeal = {
        ...yellowPathAppeal,
        appealReason: values.appealReason,
      };
    }
    return yellowPathAppeal;
  }

  _onSubmit = async (values) => {
    const approvals = get(this.state.origination, "approvalRequests");
    const approval = find(
      approvals,
      (a) => a.type.value === "DocumentAttestationReview"
    );
    const handleAppeal = {
      APPROVED: approveYellowPathAppeal,
      DENIED: denyYellowPathAppeal,
      RETRY: retryYellowPathAppeal,
    };
    const yellowPathAppeal = this._buildYellowPathAppealPayload(
      approval,
      values
    );
    const approvalValues = {
      ...values,
      approvalStatus:
        values.approvalStatus === "RETRY" ? "DENIED" : values.approvalStatus,
    };
    const promises = [];
    promises.push(
      this.props.setApprovalStatus(this.props.client, {
        approvalId: approval.id,
        status: approvalValues.approvalStatus,
        reason: approvalValues.reason,
        comment: approvalValues.comments,
        metadata: {
          appealReason: values.appealReason,
        },
      })
    );
    promises.push(
      handleAppeal[values.approvalStatus](this.props.client, yellowPathAppeal)
    );
    Promise.all(promises).then(() => {
      if (!this.props.error) {
        toast.success("Successfully submitted.");
      }
    });
  };
  _getApprovalsSection = () => {
    const approvals = get(this.state.origination, "approvalRequests");
    const approval = find(
      approvals,
      (a) => a.type.value === "DocumentAttestationReview"
    );
    const isDenied = approval.status === "DENIED";
    const isApproved = approval.status === "APPROVED";

    return (
      <Formik
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={this._onSubmit}
        enableReinitialize={true}
        initialValues={this.state}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          handleBlur,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <ScrollToFieldError />
            <Form.Group controlId="formBasicApprovalStatus">
              <Form.Label>Approval Status</Form.Label>
              <Form.Control
                as="select"
                name="approvalStatus"
                value={values.approvalStatus}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={touched.approvalStatus && !!errors.approvalStatus}
                isValid={touched.approvalStatus && !errors.approvalStatus}
              >
                <option value="" disabled>
                  Select Approval Status
                </option>
                {map(approvalOptions, (opt) => (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.approvalStatus}
              </Form.Control.Feedback>
            </Form.Group>
            {(values.approvalStatus === "APPROVED" ||
              values.approvalStatus === "DENIED") && (
              <Form.Group controlId="formBasicAppealReason">
                <Form.Label>Appeal Reason</Form.Label>
                <Form.Control
                  as="textarea"
                  name="appealReason"
                  value={values.appealReason}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.appealReason && !!errors.appealReason}
                  isValid={touched.appealReason && !errors.appealReason}
                />
              </Form.Group>
            )}
            {values.approvalStatus === "RETRY" && (
              <Form.Group controlId="formBasicReason">
                <Form.Label>Retry Reason</Form.Label>
                <Form.Control
                  as="select"
                  name="reason"
                  value={values.reason}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.reason && !!errors.reason}
                  isValid={touched.reason && !errors.reason}
                >
                  <option value="" disabled>
                    Select Reason
                  </option>
                  {map(approval.allReasons, (reason) => (
                    <option key={reason} value={reason}>
                      {reason}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.reason}
                </Form.Control.Feedback>
              </Form.Group>
            )}
            <Form.Group controlId="formBasicComments">
              <Form.Label>Additional Comments</Form.Label>
              <Form.Control
                as="textarea"
                name="comments"
                value={values.comments}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={touched.comments && !!errors.comments}
                isValid={touched.comments && !errors.comments}
              />
              <Form.Control.Feedback type="invalid">
                {errors.comments}
              </Form.Control.Feedback>
              {isDenied && (
                <div style={{ paddingTop: 6 }}>
                  <Alert
                    type="warning"
                    msg={`Request was denied by ${approval.updatedBy}`}
                  />
                </div>
              )}
              {isApproved && (
                <div style={{ paddingTop: 6 }}>
                  <Alert
                    type="success"
                    msg={`Request was approved by ${approval.updatedBy}`}
                  />
                </div>
              )}
            </Form.Group>

            <div className="submit-row">
              {this.props.error && (
                <Alert type="error" msg={this.props.error} />
              )}
              <Button
                withArrow={true}
                name="submit"
                btnLabel="Submit"
                loading={this.props.isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  _onFileClose = () => {
    this.setState({
      documentToView: null,
    });
  };

  _getDocumentViewer = () => {
    if (isEmpty(this.state.documentToView)) {
      return null;
    }

    const uri = get(this.state.documentToView, "metadata.downloadUrl");

    if (this.state.documentToView.contentType === "application/pdf") {
      return (
        <PdfViewer
          documentUrl={uri}
          onClose={this._onFileClose}
          isPdf={false}
        />
      );
    } else {
      return (
        <Viewer
          images={[{ src: uri }]}
          visible={true}
          onClose={this._onFileClose}
          noClose={false}
          onMaskClick={this._onFileClose}
          zIndex={100000}
        />
      );
    }
  };

  _parseFileName(file) {
    const uriParts = file.uri.split("/");
    return `${capitalCase(file.type)} - ${uriParts[uriParts.length - 1]}`;
  }

  _buildFileViewLinks(documents) {
    return map(documents, (document) => {
      return (
        <li key={document.uri}>
          {this._parseFileName(document)} -{" "}
          <a
            className="link"
            style={{
              paddingLeft: 0,
            }}
            onClick={() => this.setState({ documentToView: document })}
          >
            View Document
          </a>{" "}
        </li>
      );
    });
  }

  _showApexResults = () => {
    return (
      <>
        <div className="employerInfo">
          <div className="employerInfo-body">
            <div>
              <div className="circle">
                <span className="icon">
                  <FiList color="white" stroke="#60A4BF" size={16} />
                </span>
              </div>
            </div>
            <div className="employerInfo-intro">
              <IconHeader variant="labelHeader" headerText="Apex Results" />
              <IconSubheader subheader="Click to view Apex JSON" />
            </div>
          </div>
          <div className="employerInfo-action">
            <Button name="action" size="sm" btnLabel="View Results" />
          </div>
        </div>
      </>
    );
  };

  _showSilaResults = () => {
    return (
      <>
        <div className="employerInfo">
          <div className="employerInfo-body">
            <div>
              <div className="circle">
                <span className="icon">
                  <FiDollarSign color="white" stroke="#60A4BF" size={16} />
                </span>
              </div>
            </div>
            <div className="employerInfo-intro">
              <IconHeader variant="labelHeader" headerText="Sila Results" />
              <IconSubheader subheader="Click to view Sila JSON" />
            </div>
          </div>
          <div className="employerInfo-action">
            <Button name="action" size="sm" btnLabel="View Results" />
          </div>
        </div>
      </>
    );
  };

  render() {
    if (this.state.initialFetching || isEmpty(this.state.origination)) {
      return <IconSpinner />;
    }
    const approvals = get(this.state.origination, "approvalRequests");
    const hasApprovals = size(approvals) > 0;
    const documents = this.state.origination.documents;
    const hasDocuments = size(documents) > 0;

    const silaReasons = this.state.origination.silaKycReview;
    const apexReasons = {
      ...this.state.origination.apexKycReview,
      result: JSON.parse(this.state.origination.apexKycReview.result),
    };

    return (
      <div className="mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Origination Info</h1>

            <div className="main-content">
              <Card>
                <Card.Body>
                  <div>
                    <div className="action-box">
                      <div className="action-header">
                        <span>
                          <IconHeader
                            variant="cardHeader"
                            headerText="User Info"
                          />
                        </span>
                      </div>
                      <div className="action-body">
                        <InfoTable
                          metadata={metadata}
                          data={this.state.origination}
                        />
                      </div>
                    </div>

                    <div className="action-box">
                      <Collapsible
                        style={{ border: "1px Solid gray" }}
                        trigger={this._showApexResults()}
                      >
                        <JSONPretty
                          data={apexReasons}
                          themeClassName="custom-json-pretty"
                          style={{ fontSize: "14px", margin: 15 }}
                          mainStyle="background:#F7F7F7; line-height:1.3; padding: 20px; ;overflow:auto; color:#999;"
                          keyStyle="color:#905;"
                          stringStyle="color:#690;"
                          valueStyle="color:#905;"
                          booleanStyle="color:#905;"
                        ></JSONPretty>
                      </Collapsible>
                    </div>
                    <div className="action-box">
                      <Collapsible
                        style={{ border: "1px Solid gray" }}
                        trigger={this._showSilaResults()}
                      >
                        <JSONPretty
                          data={silaReasons}
                          themeClassName="custom-json-pretty"
                          style={{ fontSize: "14px", margin: 15 }}
                          mainStyle="background:#F7F7F7; line-height:1.3; padding: 20px; ;overflow:auto; color:#999;"
                          keyStyle="color:#905;"
                          stringStyle="color:#690;"
                          valueStyle="color:#905;"
                          booleanStyle="color:#905;"
                        ></JSONPretty>
                      </Collapsible>
                    </div>
                  </div>
                  {hasDocuments && (
                    <>
                      <div className="action-box">
                        <div className="action-header">
                          <span>
                            <IconHeader
                              variant="cardHeader"
                              headerText="Documents"
                            />
                          </span>
                        </div>
                        <div className="action-body">
                          {this._buildFileViewLinks(documents)}
                        </div>
                      </div>
                    </>
                  )}
                  {hasApprovals && (
                    <Row>
                      <Col as={Col} sm={8}>
                        <div className="action-box">
                          <div className="action-header">
                            <span>
                              <IconHeader
                                variant="cardHeader"
                                headerText="Approvals"
                              />
                            </span>
                          </div>
                          <div className="action-body">
                            {this._getApprovalsSection()}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {this._getDocumentViewer()}
                </Card.Body>
              </Card>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const errorSelector = createErrorSelector([adminConstants.SET_APPROVAL_STATUS]);
const isSubmittingSelector = createLoadingSelector(
  adminConstants.SET_APPROVAL_STATUS
);

const mapStateToProps = (state, ownProps) => {
  const iconId = get(ownProps.match, "params.id");

  return {
    iconId,
    error: errorSelector(state),
    isSubmitting: isSubmittingSelector(state),
  };
};

const mapDispatchToProps = {
  setApprovalStatus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Origination));
