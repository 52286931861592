import React from "react";
import PropTypes from "prop-types";
import Slider from "react-rangeslider";
import { Formik } from "formik";
import { connect } from "react-redux";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { find, get, isNil, last, map, replace } from "lodash";
import { INVESTMENT_PROFILE } from "statics/onboardingSteps";
import { getAllAccounts } from "actions/accountActions";
import { withApollo } from "@apollo/client/react/hoc";
import { Carousel, Col, Form } from "react-bootstrap";
import { userService } from "services/userService";
import { ScrollToFieldError } from "utils/form";
import { formatCurrency } from "utils/number";
import { userConstants, accountConstants } from "actions/types";
import { scrollToTop } from "utils/dom";

import {
  getIraAccountIdSelector,
  isRolloverSelector,
} from "store/selectors/user";
import {
  getInvestmentProfileAndSuitability,
  getRequiredUpdates,
  getUserState,
  startPortfolioUpdate,
  updateInvestmentProfileAndSuitability,
  updateRolloverAccountTermsAndSubmit,
  updateIraAccountInvestmentProfile,
  updateIraAccountSuitability,
  updateRolloverInvestmentProfileAndSuitability,
} from "actions/userActions";
import {
  experienceValues,
  hideLiquidInvestment,
  incomeLabel,
  incomeLimits,
  oldTimeHorizonLiquidityValues,
  riskToleranceValues,
  timeHorizonLiquidityValues,
  wealthLabel,
  wealthLimits,
} from "utils/investProfileHelper";
import {
  IndividualInvestmentProfileUpdate,
  IndividualRequiredUpdatesPending,
} from "statics/states";

import ProgressBar from "components/ProgressBar";
import CircularProgressBar from "components/CircularProgressBar";
import SimpleModal from "components/SimpleModal";
import IconSpinner from "components/IconSpinner";
import Button from "components/Button";
import Alert from "components/Alert";
import SelectBox from "./SelectBox";
import IconHeader from "components/IconHeader";
import CancelRolloverModal from "../../dashboards/individualDashboard/dashboard/CancelRolloverModal";

import "react-datepicker/dist/react-datepicker.css";
import "react-rangeslider/lib/index.css";

import "./IndividualInvestmentProfile.scss";
import { accountTypesToEnglishMapping } from "statics/accountTypes";

const suitabilityQuestions = [
  "incomeLimitsRawValue",
  "wealthLimitsSelectedIndex",
  "riskTolerance",
  "experience",
  "timeHorizonLiquidity",
];

const suitabilityQuestionswithLiquid = [
  "incomeLimitsRawValue",
  "wealthLimitsSelectedIndex",
  "liquidNetWorthLimitsSelectedIndex",
  "riskTolerance",
  "experience",
  "timeHorizonLiquidity",
];
const maxIncomeLimit = 1000000000;
const yup = require("yup");

const schema = yup.object({
  experience: yup.string().label("Experience").required(),
  incomeLimitsRawValue: yup
    .number()
    .label("Annual Income")
    .required()
    .min(0)
    .max(maxIncomeLimit),
  liquidNetWorthLimitsSelectedIndex: yup
    .string()
    .when("totalNetWorth", {
      is: (val) => {
        return !hideLiquidInvestment(val);
      },
      then: yup.string().required(),
      otherwise: yup.string(),
    })
    .label("Liquid Net Worth"),
  wealthLimitsSelectedIndex: yup.string().label("Total Net Worth").required(),
  riskTolerance: yup.string().label("Risk Tolerance").required(),
  timeHorizonLiquidity: yup.string().label("Time Horizon Liquidity").required(),
});

class IndividualInvestmentProfile extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    updateIraAccountInvestmentProfile: PropTypes.func,
    updateIraAccountSuitability: PropTypes.func,
    updateRolloverInvestmentProfileAndSuitability: PropTypes.func,
    getUserState: PropTypes.func,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    userState: PropTypes.string,
    updateInvestmentProfileAndSuitability: PropTypes.func,
    updateRolloverAccountTermsAndSubmit: PropTypes.func,
    startPortfolioUpdate: PropTypes.func,
    getAllAccounts: PropTypes.func,
    accountId: PropTypes.string,
    fallbackAccountId: PropTypes.string,
    isUpdatingInvestmentProfile: PropTypes.bool.isRequired,
    isInRequiredStepFlow: PropTypes.bool.isRequired,
    getRequiredUpdates: PropTypes.func.isRequired,
    getInvestmentProfileAndSuitability: PropTypes.func.isRequired,
    isRolloverAccount: PropTypes.bool,
    updatingAccountId: PropTypes.string,
    accountType: PropTypes.string,
    multiAccountRequiredUpdates: PropTypes.bool,
    requiredUpdates: PropTypes.array,
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      showCancelRolloverModal: false,
      currentQuestionIndex: 0,
      investmentProfile: {
        objective: "",
        experience: "",
        incomeLimitsRawValue: null,
        wealthLimitsSelectedIndex: 0,
        liquidNetWorthLimitsSelectedIndex: 0,
        riskTolerance: "",
      },
      suitability: {
        timeHorizon: "",
        liquidityNeeds: "",
        timeHorizonLiquidity: "",
      },
      loading: get(props, "isUpdatingInvestmentProfile", false),
      submitting: false,
      submitted: false,
      showTextModal: false,
      currentInvestmentProfile: {},
      currentSuitability: {},
    };
  }

  getEffectiveAccountId = () => {
    if (this.props.isInRequiredStepFlow && this.props.updatingAccountId) {
      return this.props.updatingAccountId;
    }
    return this.props.fallbackAccountId;
  };

  componentDidMount() {
    this._isMounted = true;
    window.analytics.page("Individual Investment Profile");
    scrollToTop();
    if (
      this.props.isUpdatingInvestmentProfile ||
      this.props.isInRequiredStepFlow
    ) {
      this.props
        .getAllAccounts(this.props.client)
        .then(() =>
          this.props.getInvestmentProfileAndSuitability(
            this.props.client,
            this.props.updatingAccountId
          )
        )
        .then((data) => {
          const investmentProfileInfo = data && data.getInvestmentProfile;
          const currentSuitability = data && data.getSuitability;
          let currentInvestmentProfile = {
            ...investmentProfileInfo,
            annualIncome: incomeLabel(investmentProfileInfo.annualIncome),
            liquidNetWorth: wealthLabel(investmentProfileInfo.liquidNetWorth),
            totalNetWorth: wealthLabel(investmentProfileInfo.totalNetWorth),
            riskTolerance: investmentProfileInfo.riskToleranceRawValue,
          };
          this.setState({
            currentInvestmentProfile,
            currentSuitability,
            showTextModal:
              this.props.userState === IndividualRequiredUpdatesPending,
            loading: false,
          });
        });
      return;
    }
    userService.getIraAccountForm(this.props.client).then(
      (data) => {
        let investmentProfile = {
          objective: data.investmentProfile.objective,
          experience: data.investmentProfile.experience,
          incomeLimitsRawValue: null,
          wealthLimitsSelectedIndex: 0,
          liquidNetWorthLimitsSelectedIndex: 0,
          riskTolerance: "",
        };

        let suitability = {
          timeHorizon: data.suitability.timeHorizon,
          liquidityNeeds: data.suitability.liquidityNeeds,
          timeHorizonLiquidity: data.suitability.timeHorizonLiquidity,
        };

        if (this._isMounted) {
          this.setState({
            submitted: false,
            investmentProfile,
            suitability,
            loading: false,
          });
        }
      },
      () => {
        if (this._isMounted) {
          this.setState({
            submitted: false,
            loading: false,
          });
        }
      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  submitInvestmentProfile = (values) => {
    this.setState({
      submitting: true,
    });
    let metaData = {
      riskToleranceObjectSelection: riskToleranceValues.find(
        (item) => item.value === values.riskTolerance
      ),
      timeHorizonLiquiditySelection:
        timeHorizonLiquidityValues[values.timeHorizonLiquidity],
      experienceSelection: experienceValues.find(
        (item) => item.value === values.experience
      ),
    };

    const maxIncomeRange = last(incomeLimits).max;
    let selectedIncomeLimits;

    if (values.incomeLimitsRawValue > maxIncomeRange) {
      selectedIncomeLimits = last(incomeLimits);
    } else {
      selectedIncomeLimits = find(
        incomeLimits,
        (limit) =>
          limit.min <= values.incomeLimitsRawValue &&
          values.incomeLimitsRawValue <= limit.max
      );
    }

    const selectedWealthLimits = wealthLimits[values.wealthLimitsSelectedIndex];
    const selectedliquidNetWorthLimits =
      wealthLimits[values.liquidNetWorthLimitsSelectedIndex];

    let investmentProfile = {
      objective: "",
      experience: values.experience,
      annualIncome: {
        min: selectedIncomeLimits.min,
        max: selectedIncomeLimits.max,
        rawValue: +values.incomeLimitsRawValue,
      },
      liquidNetWorth: {
        min: selectedliquidNetWorthLimits.min,
        max: selectedliquidNetWorthLimits.max,
      },
      totalNetWorth: {
        min: selectedWealthLimits.min,
        max: selectedWealthLimits.max,
      },
      riskTolerance: values.riskTolerance,
      riskToleranceRawValue: values.riskTolerance,
      formMetadata: JSON.stringify(metaData),
    };

    const timeHorizonLiquidityNeeds =
      timeHorizonLiquidityValues[values.timeHorizonLiquidity];

    let suitability = {
      timeHorizon: timeHorizonLiquidityNeeds.timeHorizon,
      liquidityNeeds: timeHorizonLiquidityNeeds.liquidityNeeds,
      timeHorizonLiquidity: timeHorizonLiquidityNeeds.value,
    };

    // Risk Tolerance values also set Objective
    switch (values.riskTolerance) {
      case "LOW_CAP":
        investmentProfile.riskTolerance = "LOW";
        investmentProfile.objective = "CAPITAL_PRESERVATION";
        break;
      case "LOW_GROWTH":
        investmentProfile.riskTolerance = "LOW";
        investmentProfile.objective = "GROWTH";
        break;
      case "MEDIUM_GROWTH_1":
        investmentProfile.riskTolerance = "MEDIUM";
        investmentProfile.objective = "GROWTH";
        break;
      case "MEDIUM_GROWTH_2":
        investmentProfile.riskTolerance = "MEDIUM";
        investmentProfile.objective = "GROWTH";
        break;
      case "HIGH_GROWTH":
        investmentProfile.riskTolerance = "HIGH";
        investmentProfile.objective = "GROWTH";
        break;
    }

    if (this.props.isRolloverAccount) {
      this.props
        .updateRolloverInvestmentProfileAndSuitability(
          this.props.client,
          investmentProfile,
          suitability
        )
        .then(() => {
          if (this.props.isUpdatingInvestmentProfile) {
            return this.props.startPortfolioUpdate(this.props.client);
          } else if (this.props.isInRequiredStepFlow) {
            this.props.getRequiredUpdates(this.props.client);
          }
        })
        .then(() => {
          if (!this.props.isUpdatingInvestmentProfile) {
            this.setState({ submitting: false });
          }
        });

      window.analytics.track(
        "Submitted Rollover Account Investment Profile",
        {}
      );
    } else {
      this.props
        .updateInvestmentProfileAndSuitability(
          this.props.client,
          this.getEffectiveAccountId(),
          investmentProfile,
          suitability,
          !!this.props.updatingAccountId
        )
        .then(() => {
          if (this.props.isUpdatingInvestmentProfile) {
            return this.props.startPortfolioUpdate(this.props.client);
          } else if (this.props.isInRequiredStepFlow) {
            this.props.getRequiredUpdates(this.props.client);
          }
          return this.props.getUserState(this.props.client);
        })
        .then(() => {
          if (!this.props.isUpdatingInvestmentProfile) {
            this.setState({ submitting: false });
          }
        });

      window.analytics.track("Submitted Account Investment Profile", {
        userState: this.props.userState,
      });
    }

    if (this._isMounted) {
      this.setState({
        submitted: true,
      });
    }
  };

  _setSelectedQuestion = (currentQuestionIndex) => {
    this.setState({ currentQuestionIndex });
  };

  _advanceCarousel = () => {
    this.setState({
      currentQuestionIndex: this.state.currentQuestionIndex + 1,
    });
  };

  _isLastQuestion = (isWithLiquid) => {
    const setOfQuestions = isWithLiquid
      ? suitabilityQuestionswithLiquid
      : suitabilityQuestions;
    const indexIsLast =
      this.state.currentQuestionIndex === setOfQuestions.length - 1;
    return indexIsLast;
  };

  calcContinueBtnDisabled = (formValues, isWithLiquid, formErrors) => {
    const setOfQuestions = isWithLiquid
      ? suitabilityQuestionswithLiquid
      : suitabilityQuestions;

    const currentQuestion = setOfQuestions[this.state.currentQuestionIndex];
    const questionValue = formValues[currentQuestion];
    // we only validate errors on the incomeLimitsRawValue since the rest are predetermined options

    return (
      (!questionValue && questionValue !== 0) ||
      (currentQuestion === "incomeLimitsRawValue" &&
        formErrors[currentQuestion])
    );
  };

  _onCancel = () => {
    this.setState({
      showCancelRolloverModal: true,
    });
  };

  _onModalClose = () => {
    this.setState({
      showTextModal: false,
    });
  };

  _displayPriorAnswer = (value, condition = true) => {
    if (
      (this.props.isUpdatingInvestmentProfile ||
        this.props.isInRequiredStepFlow) &&
      condition
    )
      return (
        <Form.Label className={"investment-form-label-currency"}>
          Your previous answer: {value}
        </Form.Label>
      );
  };

  displayRequiredUpdatesModal = () => {
    return (
      <div>
        <IconHeader variant="cardEmptyState" headerText="Accounts to update:" />

        {map(this.props.requiredUpdates, (update) => {
          return (
            <div
              style={{
                display: "flex",
                paddingTop: "10px",
              }}
            >
              <Form.Label>
                {accountTypesToEnglishMapping[update.accountType]}
              </Form.Label>
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    const {
      investmentProfile,
      suitability,
      currentInvestmentProfile,
      currentSuitability,
      loading,
    } = this.state;
    if (loading) {
      return <IconSpinner centered />;
    }

    const progPercent = this.props.isRolloverAccount ? "60" : "80";
    const formMetadata = get(currentInvestmentProfile, "formMetadata");
    const isFirstQuestion = this.state.currentQuestionIndex === 0;

    const requiredUpdatesTitle = "Required Investment Profile Update";

    const requiredUpdatesSubtext = this.props.multiAccountRequiredUpdates
      ? "Please update your investment profile for each of your IRA accounts. This helps us better manage your goals. Once updated, you can return to your dashboard."
      : "Every so often we'll ask you to update your investment profile and portfolio to help satisfy your investment goals. After you finish, you can return to your dashboard to invest!";

    const format = (value) => value;
    const accountTypeText = this.props.accountType
      ? `Your ${
          accountTypesToEnglishMapping[this.props.accountType]
        } investment profile`
      : "Your investment profile";

    return (
      <div className="investment-profile">
        <SimpleModal
          show={this.state.showTextModal}
          onClose={this._onModalClose}
          title={requiredUpdatesTitle}
          subtext={requiredUpdatesSubtext}
          buttonText="Continue"
        >
          <p>{this.displayRequiredUpdatesModal()}</p>
        </SimpleModal>
        {this.state.showCancelRolloverModal && (
          <CancelRolloverModal
            show={this.state.showCancelRolloverModal}
            onClose={() =>
              this.setState({
                showCancelRolloverModal: false,
              })
            }
            onSuccess={() =>
              this.setState({
                showCancelRolloverModal: false,
              })
            }
          />
        )}
        {!(
          this.props.isUpdatingInvestmentProfile ||
          this.props.isInRequiredStepFlow
        ) && (
          <ProgressBar
            isEmployer={false}
            activeStepId={INVESTMENT_PROFILE.id}
            progressPercent={progPercent}
          />
        )}
        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={schema}
          onSubmit={(values) => {
            this.submitInvestmentProfile(values);
          }}
          enableReinitialize={true}
          initialValues={{
            ...investmentProfile,
            ...suitability,
            incomeLimitsRawValue: null,
            wealthLimitsSelectedIndex: 0,
            liquidNetWorthLimitsSelectedIndex: 0,
            incomeLimitsDisplayValue: "",
          }}
        >
          {({
            handleSubmit,
            handleBlur,
            setFieldValue,
            touched,
            values,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <div className="mega-container">
                <div
                  className="step-container is-active"
                  data-circle-percent="55"
                >
                  <section className="page-title-wrap">
                    <article className="text-cell">
                      <p className="page-title">{accountTypeText}</p>
                      {isFirstQuestion && (
                        <p className="page-subtext-investment-profile">
                          Your savings will be invested in a diversified
                          portfolio with low-cost index funds. We automatically
                          rebalance your investments to help you stay on track.
                          To tailor your portfolio to your needs, we need to ask
                          you a few questions.
                        </p>
                      )}
                    </article>
                    <article className="progress-cell">
                      <ul className="circular-progress-wrap">
                        <CircularProgressBar
                          strokeWidth="8"
                          sqSize="75"
                          percentage="55"
                        />
                      </ul>
                    </article>
                  </section>
                  <Carousel
                    className="suitability-carousel"
                    interval={null}
                    controls={null}
                    touch={false} // we don't want the questions to change on swipe left/right
                    activeIndex={this.state.currentQuestionIndex}
                    onSelect={this._setSelectedQuestion}
                  >
                    <Carousel.Item>
                      <Form.Row id="questions">
                        <Form.Group
                          as={Col}
                          sm={8}
                          controlId="formBasicAnnualIncome"
                        >
                          <Form.Label className="investment-form-label-currency">
                            What is your pre-tax income this year?
                          </Form.Label>
                          {this._displayPriorAnswer(
                            currentInvestmentProfile.annualIncome
                          )}
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          sm={8}
                          controlId="formBasicAnnualIncomeField"
                        >
                          <Form.Control
                            style={{ width: 250 }}
                            name="incomeLimitsRawValue"
                            size="lg"
                            placeholder="$1000"
                            value={values.incomeLimitsDisplayValue}
                            onChange={(e) => {
                              /* We want input to display user friendly amt ($1,000,000) so we need to track
                                the raw numeric value and the display value as two separate entities to ensure formatCurrency 
                                works its magic and we are able to store the raw value that will be represented as INT vs string.
                              */
                              const rawValueFromInputString = +replace(
                                e.target.value,
                                /\D/g,
                                ""
                              );
                              setFieldValue(
                                "incomeLimitsDisplayValue",
                                formatCurrency(rawValueFromInputString, 0)
                              );
                              setFieldValue(
                                "incomeLimitsRawValue",
                                rawValueFromInputString
                              );
                            }}
                            isInvalid={
                              touched.incomeLimitsRawValue &&
                              !!errors.incomeLimitsRawValue
                            }
                            isValid={
                              touched.incomeLimitsRawValue &&
                              !errors.incomeLimitsRawValue
                            }
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.incomeLimitsRawValue}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Form.Row id="questions">
                        <Form.Group
                          as={Col}
                          sm={8}
                          controlId="formBasicTotalNetWorth"
                        >
                          <Form.Label className="investment-form-label-currency">
                            Please estimate the value of your cash, investments,
                            and retirement accounts.
                            <div className="value">
                              <p className="currancy-label">
                                {
                                  wealthLimits[values.wealthLimitsSelectedIndex]
                                    .label
                                }
                              </p>
                            </div>
                          </Form.Label>
                          {this._displayPriorAnswer(
                            currentInvestmentProfile.totalNetWorth
                          )}
                          <div className="slider-horizontal">
                            <Slider
                              type="range"
                              min={0}
                              max={wealthLimits.length - 1}
                              tooltip={false}
                              value={values.wealthLimitsSelectedIndex}
                              format={format}
                              labels={{
                                0: "|",
                                1: "|",
                                2: "|",
                                3: "|",
                                4: "|",
                                5: "|",
                                6: "|",
                              }}
                              onChange={(value) => {
                                setFieldValue(
                                  "wealthLimitsSelectedIndex",
                                  value
                                );
                              }}
                            />
                          </div>
                          <div className="slider-labels">
                            <p className="description">$50k or Lower</p>{" "}
                            <p className="description">Over $1M</p>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {errors.totalNetWorth}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Carousel.Item>
                    {!hideLiquidInvestment(
                      wealthLimits[values.wealthLimitsSelectedIndex].label
                    ) && (
                      <Carousel.Item>
                        <Form.Row id="questions">
                          <Form.Group
                            as={Col}
                            sm={8}
                            controlId="formBasicLiquidNetWorth"
                          >
                            <Form.Label className="investment-form-label-currency">
                              How much of your cash holdings are being held in a
                              checking or savings account?
                              <div className="value">
                                <p className="currancy-label">
                                  {
                                    wealthLimits[
                                      values.liquidNetWorthLimitsSelectedIndex
                                    ].label
                                  }
                                </p>
                              </div>
                              {this._displayPriorAnswer(
                                currentInvestmentProfile.liquidNetWorth,
                                !isNil(currentInvestmentProfile.liquidNetWorth)
                              )}
                            </Form.Label>
                            <div className="slider-horizontal">
                              <Slider
                                type="range"
                                min={0}
                                max={wealthLimits.length - 1}
                                tooltip={false}
                                value={values.liquidNetWorthLimitsSelectedIndex}
                                format={format}
                                labels={{
                                  0: "|",
                                  1: "|",
                                  2: "|",
                                  3: "|",
                                  4: "|",
                                  5: "|",
                                  6: "|",
                                }}
                                onChange={(value) => {
                                  setFieldValue(
                                    "liquidNetWorthLimitsSelectedIndex",
                                    value
                                  );
                                }}
                              />
                            </div>
                            <div className="slider-labels">
                              <p className="description">$50k or Lower</p>{" "}
                              <p className="description">Over $1M</p>
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors.liquidNetWorth}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </Carousel.Item>
                    )}
                    <Carousel.Item>
                      <Form.Row id="questions">
                        <Form.Group
                          as={Col}
                          sm={8}
                          controlId="formBasicRiskTolerance"
                        >
                          <Form.Label className="investment-form-label">
                            When you think about how your money is invested,
                            which one of the following statements describes your
                            investment goal?
                          </Form.Label>
                          {this._displayPriorAnswer(
                            !isNil(formMetadata) &&
                              get(
                                JSON.parse(formMetadata),
                                "riskToleranceObjectSelection.description"
                              )
                          )}
                          {riskToleranceValues.map((item) => (
                            <SelectBox
                              description={item.description}
                              key={item.key}
                              onBlur={handleBlur}
                              onSelect={() => {
                                setFieldValue("riskTolerance", item.value);
                              }}
                              isSelected={values.riskTolerance === item.value}
                            />
                          ))}
                          <Form.Control.Feedback type="invalid">
                            {errors.riskTolerance}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Form.Row id="questions">
                        <Form.Group
                          as={Col}
                          sm={8}
                          controlId="formBasicExperience"
                        >
                          <Form.Label className="investment-form-label-currency">
                            What is your current level of investment experience?
                          </Form.Label>
                          {this._displayPriorAnswer(
                            get(
                              experienceValues.filter(
                                (v) =>
                                  v.value ===
                                  currentInvestmentProfile.experience
                              ),
                              "[0].description"
                            )
                          )}
                          {experienceValues.map((item) => (
                            <SelectBox
                              description={item.description}
                              key={item.key}
                              onBlur={handleBlur}
                              onSelect={() => {
                                setFieldValue("experience", item.value);
                              }}
                              isSelected={values.experience === item.value}
                            />
                          ))}
                          <Form.Control.Feedback type="invalid">
                            {errors.experience}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Form.Row id="questions">
                        <Form.Group
                          as={Col}
                          sm={8}
                          controlId="formBasictimeHorizonLiquidity"
                        >
                          <Form.Label className="investment-form-label-currency">
                            When do you anticipate needing the money in your
                            investment account?
                          </Form.Label>
                          {this._displayPriorAnswer(
                            get(
                              {
                                ...oldTimeHorizonLiquidityValues,
                                ...timeHorizonLiquidityValues,
                              },
                              `${currentSuitability.timeHorizonLiquidity}.description`
                            )
                          )}
                          {map(timeHorizonLiquidityValues, (value) => (
                            <SelectBox
                              description={value.description}
                              key={value.key}
                              onBlur={handleBlur}
                              onSelect={() => {
                                setFieldValue(
                                  "timeHorizonLiquidity",
                                  value.value
                                );
                              }}
                              isSelected={
                                values.timeHorizonLiquidity === value.value
                              }
                            />
                          ))}
                          <Form.Control.Feedback type="invalid">
                            {errors.timeHorizonLiquidity}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </Carousel.Item>
                  </Carousel>
                  <div id="form-employer-company-profile">
                    <article className="col-form">
                      <div className="profile-buttons">
                        {this.props.error && (
                          <Alert type="error" msg={this.props.error} />
                        )}
                        <div className="submit-row btn-row">
                          {this.props.isRolloverAccount && (
                            <Button
                              type="button"
                              btnLabel={"Cancel"}
                              color={"cancel"}
                              name="cancel"
                              onClick={this._onCancel}
                            />
                          )}

                          {this._isLastQuestion(
                            !hideLiquidInvestment(
                              wealthLimits[values.wealthLimitsSelectedIndex]
                                .label
                            )
                          ) && (
                            <Button
                              disabled={this.calcContinueBtnDisabled(
                                values,
                                !hideLiquidInvestment(
                                  wealthLimits[values.wealthLimitsSelectedIndex]
                                    .label
                                ),
                                errors
                              )}
                              name="submit"
                              withArrow={true}
                              btnLabel="Continue"
                              loading={this.state.submitting}
                            />
                          )}
                          {!this._isLastQuestion(
                            !hideLiquidInvestment(
                              wealthLimits[values.wealthLimitsSelectedIndex]
                                .label
                            )
                          ) && (
                            <Button
                              disabled={this.calcContinueBtnDisabled(
                                values,
                                !hideLiquidInvestment(
                                  wealthLimits[values.wealthLimitsSelectedIndex]
                                    .label
                                ),
                                errors
                              )}
                              withArrow={false}
                              name="submit"
                              type={"button"}
                              btnLabel="Continue"
                              onClick={this._advanceCarousel}
                            />
                          )}
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const actions = [
  userConstants.USER_ON_BOARDING_INVESTMENT_SUITABILITY_UPDATE,
  userConstants.USER_ON_BOARDING_INVESTMENT_PROFILE_UPDATE,
  userConstants.USER_ROLLOVER_INVESTMENT_PROFILE_UPDATE,
  userConstants.USER_INVESTMENT_PROFILE_UPDATE,
  userConstants.GET_USER_REQUIRED_STEPS,
  accountConstants.GET_ACCOUNTS,
];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;

  const requiredUpdates = get(state, "user.requiredUpdates.updates", []);

  // const multiAccountRequiredUpdates = requiredUpdates.length > 1;

  const accounts = state.accounts.iraAccounts;

  const multiAccountRequiredUpdates = accounts.length > 1;

  console.log("requiredUpdates", requiredUpdates);

  console.log("multiAccountRequiredUpdates", multiAccountRequiredUpdates);
  return {
    userState,
    error: errorSelector(state),
    isFetching: loadingSelector(state),
    fallbackAccountId: getIraAccountIdSelector(state),
    isUpdatingInvestmentProfile:
      IndividualInvestmentProfileUpdate === userState,
    isInRequiredStepFlow: IndividualRequiredUpdatesPending === userState,
    isRolloverAccount: isRolloverSelector(state),
    requiredUpdates,
    multiAccountRequiredUpdates,
  };
};

const mapDispatchToProps = {
  updateIraAccountInvestmentProfile,
  updateIraAccountSuitability,
  getUserState,
  updateInvestmentProfileAndSuitability,
  updateRolloverAccountTermsAndSubmit,
  startPortfolioUpdate,
  getAllAccounts,
  getInvestmentProfileAndSuitability,
  getRequiredUpdates,
  updateRolloverInvestmentProfileAndSuitability,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualInvestmentProfile));
