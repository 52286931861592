import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";

import { FiTrendingUp } from "react-icons/fi";

import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import Button from "components/Button";
import ContributionModal from "./ContributionModal";
import AddBankModal from "./AddBankModal";
import "./PriorYearContributionCard.scss";

class PriorYearContributionCard extends React.Component {
  static propTypes = {
    client: PropTypes.object,
    priorYearContributionAbility: PropTypes.object,
    hasBankAccount: PropTypes.bool,
    bankAccount: PropTypes.object,
    isSmallScreen: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      showContributionModal: false,
      showBankModal: false,
    };
  }

  _onContinueClick = () => {
    if (!this.props.hasBankAccount) {
      this.setState({
        showBankModal: true,
      });
      return;
    }
    this.setState({
      showContributionModal: true,
    });
  };

  _onFinishBankLinking = () => {
    this.setState({
      showBankModal: false,
      showContributionModal: true,
    });
  };

  _header = () => {
    const { taxYear } = this.props.priorYearContributionAbility;
    return <>Would you like to contribute to your {taxYear} IRA?</>;
  };

  _subHeader = () => {
    const { taxYear } = this.props.priorYearContributionAbility;
    return (
      <>
        You haven{"'"}t maxed out your contributions for your {taxYear} IRA yet.
        Make a contribution now to start investing!
      </>
    );
  };

  render() {
    const header = this._header();
    const subHeader = this._subHeader();

    return (
      <>
        <div className="contribution-banner">
          <ContributionModal
            show={this.state.showContributionModal}
            options={{ isApplyToPriorYear: true }}
            onSuccess={() => {
              this.setState({ showContributionModal: false });
            }}
            onClose={() => {
              this.setState({ showContributionModal: false });
            }}
          />
          <AddBankModal
            show={this.state.showBankModal}
            onSuccess={this._onFinishBankLinking}
            onClose={() => {
              this.setState({ showBankModal: false });
            }}
          />
          <div className="action-box">
            <div className="employerInfo">
              <div className="employerInfo-body">
                <div>
                  <div className="circle">
                    <span className="icon">
                      <FiTrendingUp color="white" stroke="#60A4BF" size={16} />
                    </span>
                  </div>
                </div>
                <div className="employerInfo-intro">
                  <IconHeader variant="labelHeader" headerText={header} />
                  <IconSubheader subheader={subHeader} />
                </div>
              </div>
              <div className="employerInfo-action">
                <Button
                  size="sm"
                  name="action"
                  btnLabel="Continue"
                  onClick={this._onContinueClick}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(PriorYearContributionCard));
