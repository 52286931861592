import React from "react";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { deleteBank } from "actions/bankActions";
import { createLoadingSelector, createErrorSelector } from "store/selectors";
import { userConstants, bankConstants } from "actions/types";
import { toast } from "react-toastify";
import { FiAlertTriangle } from "react-icons/fi";
import { getContributionAbilityAndEstimates } from "actions/contributionActions";
import {
  deleteEmployerLink,
  deleteEmployerLinkRequest,
} from "actions/userActions";

import IconCircle from "components/IconCircle";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import Button from "components/Button";

class RemoveSourceModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    deleteEmployerLink: PropTypes.func,
    deleteEmployerLinkRequest: PropTypes.func,
    getContributionAbilityAndEstimates: PropTypes.func,
    deleteBank: PropTypes.func,
    show: PropTypes.bool,
    isEmployer: PropTypes.bool,
    isLinkRequest: PropTypes.bool,
    isRemoving: PropTypes.bool,
    sourceId: PropTypes.string,
    error: PropTypes.string,
    client: PropTypes.object,
  };

  _setEmployerLink = (employerLink) => {
    this.setState({ employerLink });
  };

  _removeAccount = () => {
    let deleteFunction;

    if (this.props.isEmployer) {
      deleteFunction = this.props.isLinkRequest
        ? this.props.deleteEmployerLinkRequest
        : this.props.deleteEmployerLink;
    } else {
      deleteFunction = this.props.deleteBank;
    }

    deleteFunction(this.props.client, this.props.sourceId).then(() => {
      if (!this.props.error) {
        this.props.getContributionAbilityAndEstimates(this.props.client);
        toast.success("Successfully removed account");
        this.props.onClose();
      }
    });
  };

  render() {
    const contributionType = this.props.isEmployer ? "payroll" : "recurring";
    const header = "Are you sure you want to remove this account?";

    const subheader = `Any ${contributionType} contribution associated with this account will be removed upon deletion.`;

    return (
      <Modal
        closeButton
        centered
        scrollable
        show={this.props.show}
        onHide={this.props.onClose}
        className="icon-modal"
      >
        <Modal.Body>
          <IconCircle
            type="error"
            icon={<FiAlertTriangle color="white" stroke="#B12121" size={16} />}
          />
          <IconHeader variant="centeredText" headerText={header} />
          <IconSubheader
            variant="centered"
            subheader={subheader}
            style={{ display: "flex", textAlign: "center" }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="red"
            name="submit"
            btnLabel="Remove Account"
            onClick={this._removeAccount}
            loading={this.props.isRemoving}
            withArrow
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

const actions = [
  userConstants.DELETE_USER_EMPLOYER_LINK,
  userConstants.DELETE_USER_EMPLOYER_LINK_REQ,
  bankConstants.DELETE_BANK,
];

const isRemovingSelector = createLoadingSelector(actions);

const errorSelector = createErrorSelector(actions);
const mapStateToProps = (state) => {
  return {
    isRemoving: isRemovingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  deleteEmployerLink,
  deleteBank,
  deleteEmployerLinkRequest,
  getContributionAbilityAndEstimates,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(RemoveSourceModal));
