import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Alert from "components/Alert";
import PayrollStatus from "components/PayrollStatus";
import Button from "components/Button";
import IconTable from "components/IconTable";

import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { get, keyBy, map } from "lodash";
import { Card, Form } from "react-bootstrap";
import { FiSettings } from "react-icons/fi";
import { groupType, payrollTypes } from "statics/propTypes";
import { currentPayrolls, getAllEmployerGroups } from "actions/employerActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { getPeriodState } from "store/selectors/payrolls";
import { expiringBankIdSelector } from "store/selectors/bank";

import "./EmployerDashboard.scss";

const allGroups = {
  id: "ALL_GROUPS",
  value: "ALL_GROUPS",
  name: "All Groups",
};

const commonDateFormat = "MMM Do YYYY";

class CurrentPayrollTable extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    getAllEmployerGroups: PropTypes.func,
    hasGroup: PropTypes.bool,
    client: PropTypes.object,
    groups: PropTypes.arrayOf(groupType),
    error: PropTypes.string,
    isUpdating: PropTypes.bool,
    groupsById: PropTypes.objectOf(groupType),
    hasLatePayroll: PropTypes.arrayOf(PropTypes.object),
    payroll: PropTypes.arrayOf(payrollTypes),
    currentPayrolls: PropTypes.func,
    periodState: PropTypes.string,
    expiringBanks: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      initialFetching: true,
      selectedGroupFilter: "ALL_GROUPS",
    };
  }

  componentDidMount() {
    this.props.currentPayrolls(this.props.client);
  }

  _createGroupFilter = () => {
    const groups = this.props.groups.map((group) => {
      return { value: group.name, name: group.name };
    });
    return [allGroups, ...groups];
  };

  _filteredGroups = () => {
    const allGroups = this.props.payroll;
    if (this.state.selectedGroupFilter === "ALL_GROUPS") return allGroups;

    return allGroups.filter((payroll) => {
      const groupName = get(this.props.groupsById[payroll.groupId], "name");
      return groupName === this.state.selectedGroupFilter;
    });
  };

  _buildColumns = () => {
    const columns = [
      {
        label: "Group Name",
        customComponent: (props) => {
          const groupName = get(this.props.groupsById[props.groupId], "name");
          return groupName;
        },
      },

      {
        label: "Payroll Period",
        customComponent: (props) => {
          const start = props.payPeriodStart;
          const end = props.payPeriodEnd;
          const period = `${moment(start).format(commonDateFormat)} - ${moment(
            end
          ).format(commonDateFormat)}`;
          return period;
        },
      },
      {
        label: "Processing Date",
        customComponent: (props) => {
          const payrollProcessingDate = props.payrollProcessingDate;
          return moment(payrollProcessingDate).format(commonDateFormat);
        },
      },

      {
        label: "Status",
        customComponent: (props) => {
          const groupId = props.groupId;
          const group = this.props.groupsById[groupId];
          const associatedBankAccountId = get(
            group,
            "associatedBankAccount.id"
          );
          const shouldReAuthBank = this.props.expiringBanks.some(
            (expiredBank) =>
              expiredBank.bankAccountId === associatedBankAccountId
          );
          let periodState;
          if (shouldReAuthBank) {
            periodState = "RE_AUTH_REQUIRED";
          } else {
            periodState = props.periodState;
          }
          return (
            <PayrollStatus periodState={periodState} status={props.status} />
          );
        },
      },
    ];
    return columns;
  };

  render() {
    const title = "Payroll Contributions";

    const columns = this._buildColumns();
    const data = this._filteredGroups();

    return (
      <Card className="process-contributions">
        <div className="widget-header">
          <span>
            <Card.Title>{title}</Card.Title>
            <Card.Text>
              <span>
                <Form.Control
                  className="group-filter"
                  as="select"
                  id="inlineFormCustomSelect"
                  value={this.state.selectedGroupFilter}
                  onChange={(e) => {
                    this.setState({
                      selectedGroupFilter: e.target.value,
                    });
                  }}
                >
                  {map(this._createGroupFilter(), (group) => (
                    <option value={group.value} key={group.id}>
                      {group.name}
                    </option>
                  ))}
                </Form.Control>
              </span>
            </Card.Text>
          </span>
          <Button
            size="sm"
            name="action"
            color="action"
            icon={{
              icon: <FiSettings size={14} stroke={"#29b0c2"} />,
              position: "left",
            }}
            onClick={() => {
              this.props.push(`/dashboard/contributions`);
            }}
            btnLabel="Manage Payroll"
          />
        </div>
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        <IconTable columns={columns} data={data} />
      </Card>
    );
  }
}

const updatingActions = [employerConstants.GET_EMPLOYER_GROUPS];

const errorSelector = createErrorSelector(updatingActions);
const isUpdating = createLoadingSelector(updatingActions);

const mapStateToProps = (state) => {
  return {
    periodState: getPeriodState(state),
    groupsById: keyBy(state.employer.groups, "id"),
    payroll: state.employer.currentPayrolls,
    groups: state.employer.groups,
    isUpdating: isUpdating(state),
    error: errorSelector(state),
    expiringBanks: expiringBankIdSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  getAllEmployerGroups,
  currentPayrolls,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(CurrentPayrollTable));
