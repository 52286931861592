import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerAdminUserRequest } from "actions/userActions";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import Alert from "components/Alert";
import Button from "components/Button";
import { userConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { withApollo } from "@apollo/client/react/hoc";
import { isSuperIconAdmin } from "store/selectors/admin";
import { ScrollToFieldError } from "utils/form";

let yup = require("yup");

const schema = yup.object({
  username: yup
    .string()
    .label("Username")
    .required()
    .min(3, "Must be at least three characters")
    .max(25)
    .test("not-email", "Username cannot be an email address", function (value) {
      let localSchema = yup.string().email();
      return !localSchema.isValidSync(value);
    }),
  email: yup
    .string()
    .label("Email")
    .email("You must enter a valid email address")
    .required(),
  providerId: yup
    .string()
    .label("Provider ID")
    .min(3, "Must be at least three characters")
    .max(50)
    .required(),
});

class AdminSignup extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    registerAdminUserRequest: PropTypes.func,
    error: PropTypes.string,
    userId: PropTypes.string,
    isFetching: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      user: {
        username: "",
        email: "",
      },
      submitted: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.analytics.page("Admin Signup");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  submitUser = (values) => {
    let user = {
      username: values.username,
      email: values.email,
      providerId: values.providerId,
    };

    this.props.registerAdminUserRequest(this.props.client, user);

    if (this._isMounted) {
      this.setState({
        submitted: true,
      });
    }
  };

  render() {
    var articleStyle = {
      paddingBottom: 0,
    };

    if (!this.props.isSuperAdmin) {
      return <h1>Welcome to the Icon Admin Portal</h1>;
    }

    return (
      <div>
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={(values) => {
            this.submitUser(values);
          }}
          initialValues={{
            email: "",
            providerId: "",
            username: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <div className="mega-container">
                <div
                  className="step-container is-active"
                  data-circle-percent="30"
                >
                  <section className="page-title-wrap">
                    <article className="text-cell">
                      <h1 className="page-title">Create Admin Account.</h1>
                      <p className="page-subtext">
                        A ProviderID and the related account must already exist
                        before you submit this request. Contact your manager if
                        you are unsure if this has been completed.
                      </p>
                    </article>
                  </section>
                  <div id="form-admin-profile" style={{ paddingleft: 10 }}>
                    <section className="form-sec-2col">
                      <article className="col-form" style={articleStyle}>
                        <Form.Group controlId="formBasicUsername">
                          <Form.Control
                            name="username"
                            placeholder="Username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.username && !!errors.username}
                            isValid={touched.username && !errors.username}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.email && !!errors.email}
                            isValid={touched.email && !errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formBasicProviderId">
                          <Form.Control
                            name="providerId"
                            placeholder="Provider ID"
                            value={values.providerId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.providerId && !!errors.providerId
                            }
                            isValid={touched.providerId && !errors.providerId}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {errors.providerId}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </article>
                    </section>
                    <section className="form-sec-2col">
                      <article className="col-form">
                        <div className="submit-row">
                          {this.props.error && (
                            <Alert type="error" msg={this.props.error} />
                          )}
                          <Button
                            name="submit"
                            withArrow={true}
                            btnLabel="Submit"
                            loading={this.props.isFetching}
                          />
                        </div>
                      </article>
                    </section>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const actions = [userConstants.REGISTER_ADMIN_USER];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  isFetching: loadingSelector(state),
  isSuperAdmin: isSuperIconAdmin(state),
});

const mapDispatchToProps = {
  registerAdminUserRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AdminSignup));
