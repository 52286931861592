import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./IconCircle.scss";

const IconCircle = ({ icon, type }) => {
  return (
    <>
      <div className={classnames(`alert-circle ${type}-cirlce`)}>
        <div className={classnames(`overlay ${type}-overlay`)}>
          <span className="inner-circle">{icon}</span>
        </div>
      </div>
    </>
  );
};

IconCircle.propTypes = {
  icon: PropTypes.node,
  type: PropTypes.string,
};

export default IconCircle;
