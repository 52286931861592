import React from "react";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Col, Row } from "react-bootstrap";
import PromisePoller from "components/PromisePoller";
import KybCard from "pages/dashboards/employerDashboard/dashboard/KybCard";
import { getUserState } from "actions/userActions";
import { getSetupIntent } from "actions/employerActions";

import PropTypes from "prop-types";
import {
  requiresBillingMicroDepositVerification,
  requiresBillingPaymentUpdate,
} from "store/selectors/employer";
import VerifyBillingCard from "pages/dashboards/employerDashboard/dashboard/VerifyBillingCard";

class KybDashboard extends React.PureComponent {
  static propTypes = {
    getUserState: PropTypes.func.isRequired,
    verifyBilling: PropTypes.func.isRequired,
    getSetupIntent: PropTypes.func.isRequired,
    requiresBillingMicroDepositVerification: PropTypes.bool,
    requiresPaymentMethodUpdate: PropTypes.bool,
    client: PropTypes.object.isRequired,
  };

  render() {
    const showBillingDetails =
      this.props.requiresBillingMicroDepositVerification ||
      this.props.requiresPaymentMethodUpdate;
    return (
      <Row>
        {showBillingDetails && (
          <Col md={12}>
            {/* We don't want to poll for the setup intent if the payment update is required since that
              potentially could dismount the payment form component before it completes its onsuccess */}
            {!this.props.requiresPaymentMethodUpdate && (
              <PromisePoller
                interval={5000}
                promiseToPoll={() => {
                  return this.props.getSetupIntent(this.props.client);
                }}
                minutesToPollFor={5}
              />
            )}
            <VerifyBillingCard />
          </Col>
        )}
        <Col md={12}>
          <PromisePoller
            interval={5000}
            promiseToPoll={() => {
              return this.props.getUserState(this.props.client);
            }}
            minutesToPollFor={5}
          />
          <KybCard isEmployerDashboard={true} />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requiresBillingMicroDepositVerification:
      requiresBillingMicroDepositVerification(state),
    requiresPaymentMethodUpdate: requiresBillingPaymentUpdate(state),
  };
};
const mapDispatchToProps = {
  getUserState,
  getSetupIntent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(KybDashboard));
