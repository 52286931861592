import React from "react";

const SupportEmailLink = () => {
  return (
    <a
      className="icon-link"
      href="mailto:support@iconsavingsplan.com"
      onClick={() => {
        window.analytics.track("Clicked Support Link");
      }}
    >
      support@iconsavingsplan.com
    </a>
  );
};

export default SupportEmailLink;
