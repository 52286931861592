import React from "react";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { toast } from "react-toastify";

import Alert from "components/Alert";
import Button from "components/Button";

import { updateBillingEmail } from "actions/adminActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { ScrollToFieldError } from "utils/form";

const yup = require("yup");
const schema = yup.object({
  billingContactEmail: yup
    .string()
    .email("You must enter a valid email address")
    .label("Billing Contact Email")
    .required(),
});

class BillingForm extends React.PureComponent {
  static propTypes = {
    updateBillingEmail: PropTypes.func,
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
    client: PropTypes.object,
    billingContactEmail: PropTypes.string,
    companyId: PropTypes.string,
    error: PropTypes.string,
    isSubmitting: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      setupFees: [],
      subscriptions: [],
      perUserFees: [],
    };
  }

  _onSubmit = (vals) => {
    const payload = {
      email: vals.billingContactEmail,
      companyId: this.props.companyId,
    };
    this.props
      .updateBillingEmail(this.props.client, payload)
      .then(({ data }) => {
        if (!this.props.error) {
          toast.success("Successfully updated billing contact email.");
          this.props.onSuccess(data);
        }
      });
  };

  render() {
    return (
      <div className="billing-form">
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={this._onSubmit}
          enableReinitialize={true}
          initialValues={{
            billingContactEmail: this.props.billingContactEmail,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            handleBlur,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />

              <Form.Row>
                <Form.Group as={Col} sm={6} controlId="formBasicUserEmail">
                  <Form.Label>Billing Contact Email</Form.Label>
                  <Form.Control
                    name="billingContactEmail"
                    placeholder="Billing Contact Email"
                    value={values.billingContactEmail}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={
                      touched.billingContactEmail &&
                      !!errors.billingContactEmail
                    }
                    isValid={
                      touched.billingContactEmail && !errors.billingContactEmail
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.billingContactEmail}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Group>
                {this.props.error && (
                  <Alert type="error" msg={this.props.error} />
                )}
                <div className="btn-row">
                  <Button
                    onClick={this.props.onClose}
                    btnLabel={"Cancel"}
                    color={"cancel"}
                    name="cancel"
                  />
                  <Button
                    btnLabel="Update Contact"
                    name="submit"
                    withArrow={true}
                    loading={this.props.isSubmitting}
                  />
                </div>
              </Form.Group>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const errorSelector = createErrorSelector([
  adminConstants.UPDATE_BILLING_CONTACT,
]);
const loadingSelector = createLoadingSelector(
  adminConstants.UPDATE_BILLING_CONTACT
);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isSubmitting: loadingSelector(state),
  };
};

const mapDispatchToProps = {
  updateBillingEmail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(BillingForm));
