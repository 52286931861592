import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty, map, noop } from "lodash";
import { push } from "connected-react-router";
import { FiChevronDown } from "react-icons/fi";
import { withApollo } from "@apollo/client/react/hoc";
import { Col, Row } from "react-bootstrap";
import { isBankVerifiedSelector } from "store/selectors/bank";

import Button from "components/Button";
import classnames from "classnames";
import Collapsible from "react-collapsible";
import Step from "./Step";

import "./Accordian.scss";

class Accordian extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    actionText: PropTypes.string,
    completedAction: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    img: PropTypes.func,
    isFetching: PropTypes.bool,
    hasBank: PropTypes.bool,
    hasGroup: PropTypes.bool,
    isActive: PropTypes.bool,

    activeStepNumber: PropTypes.number,
    steps: PropTypes.array,
  };

  constructor() {
    super();
    this.state = {};
  }

  verificationCheckMark = () => {
    return (
      <svg
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    );
  };

  _accordianActionBtn = () => {
    const disabled = this.props.disabled;
    const color = disabled ? "#858c9c" : "#0a2540";

    const doesNotHaveSteps = isEmpty(this.props.steps);

    if (!doesNotHaveSteps && !this.props.hasGroup) {
      return (
        <div
          className={classnames("chevron", {
            disabled,
          })}
        >
          <FiChevronDown color="white" stroke={color} size={16} />
        </div>
      );
    } else if (
      this.props.hasBank &&
      this.props.hasGroup &&
      this.props.completedAction
    ) {
      return (
        <div
          className={classnames("chevron", {
            disabled,
          })}
        >
          <FiChevronDown color="white" stroke={color} size={16} />
        </div>
      );
    } else if (isEmpty(this.props.steps)) {
      return (
        <div
          className={classnames("accordian-button", {
            disabled,
          })}
        >
          <Button
            size="sm"
            name="action"
            onClick={disabled ? noop : this.props.onClick}
            loading={this.props.isFetching}
          >
            {this.props.actionText}
          </Button>
        </div>
      );
    }
  };

  _accordianItem = () => {
    const { disabled, completedAction } = this.props;

    return (
      <>
        <div
          className={classnames("accordian-item", {
            disabled,
            completedAction,
          })}
        >
          <div className="item">
            <div className="circle">
              {this.props.icon && (
                <span className="icon">{this.props.icon}</span>
              )}
            </div>

            <div className="item-description">
              <p className="title">{this.props.title}</p>
              {this.props.description && (
                <p className="description">{this.props.description}</p>
              )}
            </div>
          </div>

          {this.props.completedAction && (
            <div className="completed-action">
              <div>{this.verificationCheckMark()}</div>
            </div>
          )}
          {!this.props.completedAction && (
            <div>{this._accordianActionBtn()}</div>
          )}
        </div>
      </>
    );
  };

  render() {
    const { disabled, completedAction } = this.props;
    const isOpen = !disabled && !completedAction;
    const doesNotHaveSteps = isEmpty(this.props.steps);

    return (
      <>
        <Collapsible
          trigger={this._accordianItem()}
          triggerDisabled={disabled || completedAction || isOpen}
          open={isOpen}
        >
          {!doesNotHaveSteps && (
            <div className="accordian-body">
              <Row>
                <Col className="step-col">
                  {map(this.props.steps, (step, idx) => {
                    return (
                      <div className="active-step">
                        <Step
                          isActive={this.props.activeStepNumber == step.index}
                          key={step.index}
                          label={step.label}
                          subtext={step.subtext}
                          index={idx + 1}
                          isAsync={step.isAsync}
                          completedStep={
                            this.props.activeStepNumber > step.index
                          }
                        />

                        {this.props.activeStepNumber == step.index &&
                          this.props.onClick && (
                            <div className="step-btn">
                              <Button
                                size="sm"
                                name="action"
                                onClick={disabled ? noop : this.props.onClick}
                                loading={this.props.isFetching}
                              >
                                {this.props.actionText}
                              </Button>
                            </div>
                          )}
                        {this.props.activeStepNumber == step.index &&
                          step.actions && (
                            <>
                              <div
                                style={{
                                  flexBasis: "100%",
                                  height: 0,
                                }}
                              ></div>
                              <div className="step-actions">
                                {map(step.actions, (action) => action)}
                              </div>
                            </>
                          )}
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </div>
          )}
        </Collapsible>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userHasGroup: !isEmpty(state.employer.groups),
    hasGroup: !isEmpty(state.employer.groups),
    hasBank: isBankVerifiedSelector(state),
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Accordian));
