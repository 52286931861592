import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserState } from "actions/userActions";
import { useAuth0 } from "utils/react-auth0-wrapper";
import { useApolloClient } from "@apollo/client";

const Home = () => {
  const { user, isAuthenticated, loading } = useAuth0();
  const dispatch = useDispatch();
  const client = useApolloClient();

  if (isAuthenticated && !loading) {
    // You can't get here without going through Auth0, now get their state
    // State updates will navigate them to the right spot for their account/session
    useEffect(() => {
      dispatch(getUserState(client, user));
    }, []);
  }
  return <div></div>;
};

export default Home;
