import moment from "moment";

// TODO use moment to calculate how many periods remain given current date and frequency
export function calcPayPeriodsPerYear(frequency) {
  switch (frequency) {
    case "TWICE_PER_MONTH":
      return 24;
    case "WEEKLY":
      return 52;
    case "EVERY_OTHER_WEEK":
      return 26;
    case "MONTHLY":
      return 12;

    default:
      throw new Error("No matching frequency found");
  }
}

export const DAILY = "DAILY";
export const MONTHLY = "MONTHLY";
export const PER_PAY_PERIOD = "PER_PAY_PERIOD";
export const END_OF_MONTH = "End of Month";

export const payrollPeriodsToEnglishMapping = {
  TWICE_PER_MONTH: "Twice a Month",
  WEEKLY: "Every Week",
  EVERY_OTHER_WEEK: "Every Two Weeks",
  MONTHLY: "Every Month",
};

const daysOfMonth = Array.from({ length: 31 }, (_, i) => i + 1);
export const daysOfMonthOptions = [
  ...daysOfMonth.map((d) => {
    return {
      key: d,
      value: d,
    };
  }),
  {
    key: 32,
    value: END_OF_MONTH,
  },
];

export function createDateFilterRange(daysBeforeNow) {
  const endDate = moment().endOf("day");
  const startDate = moment().subtract(daysBeforeNow, "days").startOf("day");
  return {
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
  };
}
