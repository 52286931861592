import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get, isEmpty, some } from "lodash";
import { Card, Col, Form } from "react-bootstrap";
import { GoSync } from "react-icons/go";
import { Formik } from "formik";
import { ScrollToFieldError } from "utils/form";
import { withApollo } from "@apollo/client/react/hoc";
import { createLoadingSelector } from "store/selectors";
import { activeBankSelector } from "store/selectors/bank";
import { accountConstants } from "actions/types";
import { formatAmount, formatCurrency } from "utils/number";
import { getIraAccountIdSelector } from "store/selectors/user";
import { contributionAbilitySelector } from "store/selectors/contribution";

import IconSpinner from "components/IconSpinner";
import Button from "components/Button";
import AccountBox from "./AccountBox";
import ConfirmTransfer from "./ConfirmTransfer";
import WithdrawalReasonSelect from "./WithdrawalReasonSelect";
import Alert from "components/Alert";

import "./TransferWidget.scss";

let yup = require("yup");

function getLastFourOfAccount(accountId) {
  if (!accountId) {
    return "";
  }
  return accountId.substring(accountId.length - 4, accountId.length);
}

class TransferWidget extends React.PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool,
    values: PropTypes.object,
    bankAccount: PropTypes.object,
    iraAccount: PropTypes.object,
    userId: PropTypes.string,
    bankId: PropTypes.string,
    iraAccountId: PropTypes.string,
    error: PropTypes.string,
    client: PropTypes.object,
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
    isApplyToPriorYear: PropTypes.bool,
    depositOnly: PropTypes.bool,
    contributionAbility: PropTypes.object,
  };

  static defaultProps = {
    isApplyToPriorYear: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {};
    if (isEmpty(prevState.fromAccount) && !isEmpty(nextProps.bankAccount)) {
      newState.fromAccount = nextProps.bankAccount;
    }

    if (isEmpty(prevState.toAccount) && !isEmpty(nextProps.iraAccount)) {
      newState.toAccount = nextProps.iraAccount;
    }

    return newState;
  }

  constructor(props) {
    super(props);

    this.state = {
      fromAccount: {},
      toAccount: {},
      amount: undefined,
      frequency: "Just this once",
      transferDate: "Today",
      showConfirmation: false,
      reason: "",
      isFullDisbursement: false,
    };
  }

  _getTaxYear = () => {
    if (this.props.isApplyToPriorYear) {
      return get(this.props, "contributionAbility.priorYear.taxYear");
    }
    return get(this.props, "contributionAbility.currentYear.taxYear");
  };

  _getAmountRemaining = () => {
    if (this.props.isApplyToPriorYear) {
      return get(this.props, "contributionAbility.priorYear.amountRemaining");
    }
    return get(this.props, "contributionAbility.currentYear.amountRemaining");
  };

  _getContributionLimits = () => {
    if (this.props.isApplyToPriorYear) {
      return get(this.props, "contributionAbility.priorYear.limit");
    }
    return get(this.props, "contributionAbility.currentYear.limit");
  };

  _reverseAccounts = () => {
    const toAccount = { ...this.state.fromAccount };
    const fromAccount = { ...this.state.toAccount };

    this.setState({
      fromAccount,
      toAccount,
    });
  };

  _setModalState = (showModal) => {
    this.setState({
      showConfirmation: showModal,
    });
  };

  onSubmit = (values) => {
    this.setState({ ...values });
    this._setModalState(true);
  };

  _onAmountChange = (event) => {
    const amount = +event.target.value;
    this.setState({
      amount: formatAmount(amount),
    });
  };

  renderContent() {
    const isIraWithdrawal = this.state.fromAccount.accountType === "IRA";

    const schema = yup.object({
      amount: yup
        .number()
        .when("isFullDisbursement", {
          is: true,
          then: yup.number(),
          otherwise: yup
            .number()
            .required()
            .min(1, "Amount must be greater than 0.")
            .max(
              this._getAmountRemaining(),
              `Amount must be less than or equal to ${formatCurrency(
                this._getAmountRemaining()
              )}.`
            ),
        })
        .label("Amount"),
      transferDate: yup.string().label("Transfer Date").required(),
      frequency: yup.string().label("Frequency").required(),
      isFullDisbursement: yup.boolean().label("Withdrawal all funds"),
      fromAccount: yup.object().shape({
        accountType: yup.string(),
      }),
      reason: yup.string().when("fromAccount", {
        is: (acct) => acct.accountType === "IRA",
        then: yup.string().required(),
        otherwise: yup.string(),
      }),
    });
    return (
      <Formik
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={(values) => {
          this.onSubmit(values);
        }}
        enableReinitialize={true}
        initialValues={{
          ...this.state,
          taxYear: this._getTaxYear(),
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          handleBlur,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <ScrollToFieldError />
            <div className="accounts-and-flip-icon">
              {!this.props.depositOnly && (
                <div className="flip-icon" onClick={this._reverseAccounts}>
                  <GoSync size={24} color="#3e2a67" />
                </div>
              )}
              <AccountBox
                isFrom
                isBank={this.state.fromAccount.accountType !== "IRA"}
                accountType={this.state.fromAccount.accountType}
                accountName={this.state.fromAccount.bankAlias}
                balance={this.state.fromAccount.balance}
                lastFourOfAccount={getLastFourOfAccount(
                  this.state.fromAccount.accountId
                )}
              />
              <AccountBox
                isBank={this.state.toAccount.accountType !== "IRA"}
                accountType={this.state.toAccount.accountType}
                accountName={this.state.toAccount.bankAlias}
                balance={this.state.toAccount.balance}
                lastFourOfAccount={getLastFourOfAccount(
                  this.state.toAccount.accountId
                )}
              />
            </div>
            {isIraWithdrawal && (
              <Form.Row>
                <Form.Group as={Col} sm={12} controlId="isFullDisbursement">
                  <Form.Check
                    type="checkbox"
                    id="isFullDisbursement"
                    name="isFullDisbursement"
                    label="Withdraw all available funds"
                    value={this.state.accountAgreement}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </Form.Row>
            )}
            <Form.Group as={Col} controlId="amount">
              <Form.Row>
                <Form.Label>Amount</Form.Label>
              </Form.Row>

              <Form.Row>
                <span className="currency-sign">$</span>
                <Form.Control
                  name="amount"
                  disabled={isIraWithdrawal && values.isFullDisbursement}
                  placeholder="0.00"
                  value={values.amount}
                  onChange={this._onAmountChange}
                  onBlur={handleBlur}
                  type="number"
                  isInvalid={touched.amount && !!errors.amount}
                  isValid={touched.amount && !errors.amount}
                />
                {!isIraWithdrawal && (
                  <Form.Text muted>
                    You can contribute{" "}
                    <span className="amount-remaining">
                      {formatCurrency(this._getAmountRemaining())}{" "}
                    </span>
                    before reaching your maximum.
                  </Form.Text>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.amount}
                </Form.Control.Feedback>
              </Form.Row>
            </Form.Group>

            {!isIraWithdrawal && (
              <Form.Group as={Col} controlId="tax-year">
                <Form.Row>
                  <Form.Label>Tax Year</Form.Label>
                </Form.Row>

                <Form.Row>
                  <Form.Control
                    name="taxYear"
                    disabled
                    value={values.taxYear}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.taxYear && !!errors.taxYear}
                    isValid={touched.taxYear && !errors.taxYear}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.taxYear}
                  </Form.Control.Feedback>
                </Form.Row>
              </Form.Group>
            )}
            {isIraWithdrawal && (
              <Form.Group as={Col} sm={4} controlId="formBasicReason">
                <Form.Label>Withdrawal Reason</Form.Label>
                <WithdrawalReasonSelect
                  value={values.reason}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.reason}
                </Form.Control.Feedback>
              </Form.Group>
            )}
            {!isIraWithdrawal && (
              <>
                <Form.Group as={Col} controlId="transferDate">
                  <Form.Row>
                    <Form.Label>Transfer Date</Form.Label>
                  </Form.Row>

                  <Form.Row>
                    <Form.Control
                      name="transferDate"
                      disabled
                      value={values.transferDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.transferDate && !!errors.transferDate}
                      isValid={touched.transferDate && !errors.transferDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.transferDate}
                    </Form.Control.Feedback>
                  </Form.Row>
                </Form.Group>
                <Form.Group as={Col} controlId="frequency">
                  <Form.Row>
                    <Form.Label>Frequency</Form.Label>
                  </Form.Row>

                  <Form.Row>
                    <Form.Control
                      name="frequency"
                      disabled
                      value={values.frequency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.frequency && !!errors.frequency}
                      isValid={touched.frequency && !errors.frequency}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.frequency}
                    </Form.Control.Feedback>
                  </Form.Row>
                </Form.Group>
              </>
            )}
            <section className="form-sec-2col">
              <div className="submit-row">
                {this.props.error && (
                  <Alert type="error" msg={this.props.error} />
                )}
                <Button
                  name="cancel"
                  color="cancel"
                  btnLabel="Close"
                  type="button"
                  onClick={this.props.onClose}
                />
                <Button
                  name="submit"
                  withArrow={true}
                  btnLabel="Submit Transfer"
                />
              </div>
            </section>
          </Form>
        )}
      </Formik>
    );
  }

  render() {
    let content;
    const isIraWithdrawal = this.state.fromAccount.accountType === "IRA";

    if (this.props.isFetching) {
      content = <IconSpinner centered />;
    } else if (some([this.props.iraAccount, this.props.bankAccount], isEmpty)) {
      return null;
    } else if (this.state.showConfirmation) {
      return (
        <ConfirmTransfer
          isWithdrawal={isIraWithdrawal}
          values={this.state}
          show={this.state.showConfirmation}
          iraAccountId={this.props.iraAccount.id}
          bankId={this.props.bankAccount.id}
          userId={this.props.userId}
          onClose={() => {
            this._setModalState(false);
          }}
          onSuccess={this.props.onSuccess}
          isApplyToPriorYear={this.props.isApplyToPriorYear}
        />
      );
    } else {
      content = this.renderContent();
    }

    return (
      <Card className="transfer-card">
        <Card.Body>
          <Card.Title>Transfer Funds</Card.Title>
          {content}
        </Card.Body>
      </Card>
    );
  }
}

const loadingSelector = createLoadingSelector(accountConstants.GET_ACCOUNTS);

const mapStateToProps = (state) => {
  const userId = state.user.id;
  const bankAccount = activeBankSelector(state) || {};

  const accounts = state.accounts.iraAccounts;
  const iraAccountId = getIraAccountIdSelector(state);
  const iraAccount = accounts.find((account) => account.id === iraAccountId);
  return {
    iraAccountId,
    bankAccount,
    iraAccount,
    userId,
    isFetching: loadingSelector(state),
    contributionAbility: contributionAbilitySelector(state),
  };
};

export default connect(mapStateToProps, null)(withApollo(TransferWidget));
