import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import Button from "components/Button";
import "./IndividualEnroll.scss";

/* eslint-disable react/no-children-prop */

class IndividualVerifyInfo extends React.Component {
  static propTypes = {
    submitIndividual: PropTypes.func,
    identity: PropTypes.object,
    onEditAddress: PropTypes.func,
    isFetching: PropTypes.bool,
  };

  render() {
    return (
      <div>
        <div className="individualEnroll-info-card">
          <Row>
            <Col>
              <div>
                <article className="text-cell">
                  <p className="page-title">
                    Please confirm this information is correct
                  </p>
                  <p className="page-subtext">
                    We have found the following information for you through your
                    employer.
                  </p>
                </article>
              </div>

              <div className="information-section">
                <p className="info-title">Legal Name</p>
                <p className="info-subtext">
                  {this.props.identity.firstName} {this.props.identity.lastName}
                </p>
              </div>

              <div className="information-section">
                <p className="info-title">Social Security Number</p>
                <p className="info-subtext">{this.props.identity.ssn}</p>
              </div>

              <div className="information-section">
                <p className="info-title">Date of Birth </p>
                <p className="info-subtext">{this.props.identity.dob}</p>
              </div>

              <div className="information-section">
                <p className="info-title">Legal Address</p>

                <p className="info-subtext">{this.props.identity.address1}</p>

                <p className="info-subtext">
                  {this.props.identity.city} {this.props.identity.state}{" "}
                  {this.props.identity.postalCode}
                </p>
              </div>

              <div className="information-section">
                <p className="info-title">Phone Number</p>

                <p className="info-subtext">{this.props.identity.phone}</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="edit-button">
          <div className="edit">
            <Button
              type="button"
              color="cancel"
              name="action"
              btnLabel="Edit"
              onClick={() => {
                this.props.onEditAddress();
              }}
            />
          </div>
          <Button
            name="submit"
            withArrow={true}
            loading={this.props.isFetching}
            onClick={() => {
              this.props.submitIndividual(this.props.identity);
            }}
          />
        </div>
      </div>
    );
  }
}

export default IndividualVerifyInfo;
