import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Col, Form } from "react-bootstrap";
import Button from "components/Button";
import { ScrollToFieldError } from "utils/form";
import AddressValidator from "components/AddressValidator";
import StateSelect from "components/StateSelect";
import {
  address2Validator,
  IndividualAddress1Validator,
  NAME_MESSAGE,
  postalCodeValidator,
  PRINTABLE_ASCII_MESSAGE,
  testName,
  testPhoneNumber,
  testPrintableAsciiCharacters,
  validateUsersAddress,
} from "utils/fieldValidators";
import { userService } from "services/userService";
import { isEqual, pick } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";

let yup = require("yup");

const schema = yup.object({
  firstName: yup
    .string()
    .label("First Name")
    .required()
    .test("is-valid-name", NAME_MESSAGE, testName)
    .min(1, "Must be at least one character.")
    .max(20),
  lastName: yup
    .string()
    .label("Last Name")
    .required()
    .test("is-valid-name", NAME_MESSAGE, testName)
    .min(1, "Must be at least one character.")
    .max(20),
  address1: IndividualAddress1Validator,
  address2: address2Validator,
  city: yup
    .string()
    .label("City")
    .required()
    .test("city", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .max(50),
  state: yup
    .string()
    .label("State")
    .required()
    .test("state", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .max(14),
  postalCode: postalCodeValidator,
  phoneNumber: yup
    .string()
    .label("Phone number")
    .required()
    .test(
      "us-phone",
      "${path} is not a valid phone number. Ex: (123) 456-7890 or 1234567890",
      testPhoneNumber
    )
    .max(15),
});

class UserProfileForm extends React.Component {
  static propTypes = {
    userInfo: PropTypes.object,
    client: PropTypes.object,
    updateUserInfo: PropTypes.func,
    onCancel: PropTypes.func,
    goToNamedStep: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showAddressModal: false,
      fetchingAddress: false,
      validatedAddress: {},
    };
  }

  _closeModal = () => {
    this.setState({ showAddressModal: false });
  };

  _updateUserInfo = async (values, skipAddressValidation) => {
    // call the update action once it exists
    const addressesAreEqual = this._newAddressEqualsOldAddress(values);
    if (!skipAddressValidation && !addressesAreEqual) {
      try {
        this.setState({ fetchingAddress: true });

        const userAddress = {
          address1: values.address1,
          address2: values.address2,
          state: values.state,
          postalCode: values.postalCode,
          city: values.city,
        };

        const validatedAddress = await userService.getValidatedAddress(
          this.props.client,
          userAddress
        );
        this.setState({ fetchingAddress: false });

        const userAddressIsValid = validateUsersAddress(
          userAddress,
          validatedAddress
        );

        if (!userAddressIsValid) {
          this.setState({
            showAddressModal: true,
            validatedAddress,
          });

          return;
        }
      } catch (error) {
        // if the validation endpoint fails we dont want to prevent user from saving so continue on and log error
        console.log(error);
      }
    }
    const updatedProfile = {
      ...this.props.userInfo,
      ...values,
    };
    const payload = pick(updatedProfile, [
      "legalName",
      "firstName",
      "lastName",
      "address1",
      "address2",
      "city",
      "state",
      "postalCode",
      "phoneNumber",
      "email",
    ]);

    this.props.updateUserInfo(payload);
    this.props.goToNamedStep("uploadDocuments");
  };

  _newAddressEqualsOldAddress = (userInfo) => {
    const addressFields = [
      "address1",
      "address2",
      "state",
      "city",
      "postalCode",
    ];

    const currentAddress = pick(this.props.userInfo, addressFields);
    const newAddress = pick(userInfo, addressFields);
    return isEqual(currentAddress, newAddress);
  };

  calcSubmitDisabled = (values) => {
    const currentProfile = pick(this.props.userInfo, [
      "legalName",
      "firstName",
      "lastName",
      "address1",
      "address2",
      "city",
      "state",
      "postalCode",
      "phoneNumber",
      "email",
    ]);

    const formProfile = pick(values, [
      "legalName",
      "firstName",
      "lastName",
      "address1",
      "address2",
      "city",
      "state",
      "postalCode",
      "phoneNumber",
      "email",
    ]);

    return isEqual(currentProfile, formProfile);
  };

  render() {
    return (
      <Formik
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={(values) => {
          this._updateUserInfo(values);
        }}
        enableReinitialize={true}
        initialValues={this.props.userInfo}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <ScrollToFieldError />
            {this.state.showAddressModal && (
              <AddressValidator
                show={true}
                onSubmit={this._updateUserInfo}
                setFieldValue={setFieldValue}
                validatedAddress={this.state.validatedAddress}
                onClose={this._closeModal}
                values={values}
              />
            )}
            <div className="mega-container">
              <div
                className="step-container is-active"
                data-circle-percent="30"
              >
                <div id="form-individual-info">
                  <section>
                    <article className="col-form" style={{ paddingBottom: 0 }}>
                      <Form.Label>Legal Name</Form.Label>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md={6}
                          controlId="formBasicFirstName"
                        >
                          <Form.Control
                            name="firstName"
                            placeholder="First Name"
                            value={values.firstName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.firstName && !!errors.firstName}
                            isValid={touched.firstName && !errors.firstName}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md={6}
                          controlId="formBasicLastName"
                        >
                          <Form.Control
                            name="lastName"
                            placeholder="Last Name"
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={touched.lastName && !!errors.lastName}
                            isValid={touched.lastName && !errors.lastName}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Label>U.S. Address</Form.Label>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md={12}
                          controlId="formBasicaddress1"
                        >
                          <Form.Control
                            name="address1"
                            placeholder="Address 1"
                            value={values.address1}
                            onChange={handleChange}
                            isInvalid={touched.address1 && !!errors.address1}
                            isValid={touched.address1 && !errors.address1}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address1}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group
                          as={Col}
                          md={12}
                          controlId="formBasicaddress2"
                        >
                          <Form.Control
                            name="address2"
                            placeholder="Address 2"
                            value={values.address2}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.address2 && !!errors.address2}
                            isValid={touched.address2 && !errors.address2}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address2}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md={4} controlId="formBasicCity">
                          <Form.Control
                            name="city"
                            placeholder="City"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.city && !!errors.city}
                            isValid={touched.city && !errors.city}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.city}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md={4} controlId="formBasicState">
                          <StateSelect
                            value={values.state}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.state}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md={4}
                          controlId="formBasicPostalCode"
                        >
                          <Form.Control
                            name="postalCode"
                            placeholder="Zip"
                            value={values.postalCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.postalCode && !!errors.postalCode
                            }
                            isValid={touched.postalCode && !errors.postalCode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.postalCode}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} sm={5} controlId="formBasicPhone">
                          <Form.Control
                            name="phoneNumber"
                            placeholder="Phone"
                            value={values.phoneNumber}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={
                              touched.phoneNumber && !!errors.phoneNumber
                            }
                            isValid={touched.phoneNumber && !errors.phoneNumber}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.phoneNumber}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>
                    </article>
                  </section>
                  <section className="form-sec-2col">
                    <article className="col-form">
                      <div className="submit-row">
                        <div className="btn-row">
                          <Button
                            size="sm"
                            className="cancel-button"
                            type="button"
                            color="cancel"
                            name="cancel"
                            btnLabel="Cancel"
                            onClick={() => {
                              this.props.onCancel();
                            }}
                          />
                          <div className="save-button">
                            <Button
                              size="sm"
                              withArrow={true}
                              disabled={this.calcSubmitDisabled(values)}
                              name="submit"
                              btnLabel="Continue"
                              loading={this.state.fetchingAddress}
                            />
                          </div>
                        </div>
                      </div>
                    </article>
                  </section>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withApollo(UserProfileForm);
