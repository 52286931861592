import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { accountTypesToEnglishMapping } from "statics/accountTypes";

import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";

import "./ProfileInfoCard.scss";

function ProfileInfoCard(props) {
  return (
    <div className="profile-card-containter">
      <Row>
        <Col>
          <IconHeader variant="cardHeader" headerText="Profile Information" />
          <IconSubheader subheader="You can edit your profile at any time." />

          <div className="information-section">
            <p className="title">Legal Name</p>
            <p className="subtext">
              {props.userInfo.firstName} {props.userInfo.lastName}
            </p>
          </div>

          <div className="information-section">
            <p className="title">Address</p>

            <p className="subtext">
              {props.userInfo.address1} {props.userInfo.address2}
            </p>

            <p className="subtext">
              {props.userInfo.city} {props.userInfo.state}{" "}
              {props.userInfo.postalCode}
            </p>
          </div>

          <div className="information-section">
            <p className="title">Phone Number</p>
            <p className="subtext">{props.userInfo.phoneNumber}</p>
          </div>

          <div className="information-section">
            <p className="title">Active Accounts and Account Numbers</p>
            {props.iraAccounts.map((account) => (
              <p className="subtext" key={account.key}>
                {accountTypesToEnglishMapping[account.accountType]}:{" "}
                {account.accountNumber}
              </p>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}

ProfileInfoCard.propTypes = {
  userInfo: PropTypes.object,
  iraAccounts: PropTypes.array,
};

ProfileInfoCard.defaultProps = {
  userInfo: {},
};

export default ProfileInfoCard;
