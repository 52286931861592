import React from "react";
import { Switch } from "react-router-dom";
import PropTypes from "prop-types";

import PrivateRoute from "components/PrivateRoute";
import Home from "pages/Home";
import DashboardLayout from "pages/dashboards/DashboardLayout";
import PageNotFound from "pages/PageNotFound";
import { useMediaQuery } from "react-responsive";
import "./Admin.scss";
const AdminRoutes = ({ isAuthenticated }) => {
  return (
    <>
      {!isAuthenticated && (
        <div className="mega-container">
          <section className="page-title-wrap">
            <article className="text-cell">
              <h1 className="page-title">
                Please contact your manager to create an Admin account.
              </h1>
            </article>
          </section>
        </div>
      )}
      <Switch>
        <PrivateRoute
          path="/"
          component={(props) =>
            isAuthenticated && (
              <DashboardLayout
                {...props}
                isAdminDashboard={true}
                isSmallScreen={useMediaQuery({ maxWidth: 767 })}
                color="silver"
              />
            )
          }
        />
        <PrivateRoute
          path="/login"
          exact
          component={(props) => <Home {...props} />}
        />
        <PrivateRoute component={PageNotFound} />
      </Switch>
    </>
  );
};

AdminRoutes.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default AdminRoutes;
