import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { push } from "connected-react-router";
import { FiGrid } from "react-icons/fi";
import { withApollo } from "@apollo/client/react/hoc";
import { groupCreationShow } from "actions/groupCreationActions";
import { getAllPortfolios } from "actions/portfolioActions";

import Accordian from "./Accordian";
import { hasAddedGroups } from "store/selectors/employer";
import { updateProgressiveOnboarding } from "actions/employerActions";
import Button from "components/Button";
import { createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { progressiveOnboardingEvents } from "statics/states";

class AddGroupCard extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    client: PropTypes.object,
    userHasGroup: PropTypes.bool,
    hasBank: PropTypes.bool,
    hasEnabledFinch: PropTypes.bool,
    isLoading: PropTypes.bool,
    hasAddedGroups: PropTypes.bool,
    groupCreationShow: PropTypes.func,
    updateProgressiveOnboarding: PropTypes.func,
  };

  _finishAddingGroups = () => {
    return this.props.updateProgressiveOnboarding(
      this.props.client,
      progressiveOnboardingEvents.AddGroups
    );
  };

  render() {
    const { hasBank, userHasGroup } = this.props;

    let activeStepNumber = 1;
    let onClick = this.props.groupCreationShow;

    if (userHasGroup) {
      activeStepNumber = 2;
      onClick = null;
    }

    const steps = [
      { index: 1, label: "Add initial payroll group" },
      {
        index: 2,
        label: "Add additional payroll groups",
        actions: [
          <Button
            key={"group-create"}
            size="sm"
            color="action"
            name="action"
            onClick={this.props.groupCreationShow}
          >
            Add Another Group
          </Button>,
          <Button
            key={"group-finished"}
            size="sm"
            name="action"
            onClick={this._finishAddingGroups}
            loading={this.props.isLoading}
          >
            Done
          </Button>,
        ],
      },
    ];

    return (
      <>
        <Accordian
          onClick={onClick}
          icon={<FiGrid color="white" stroke="#60A4BF" size={16} />}
          title={"Group Setup"}
          description={
            "Configure employee groups, classifications, and pay periods."
          }
          actionText={"Add Group"}
          disabled={!hasBank}
          completedAction={this.props.hasAddedGroups}
          activeStepNumber={activeStepNumber}
          steps={steps}
        />
      </>
    );
  }
}

const isLoadingSelector = createLoadingSelector(
  employerConstants.UPDATE_PROGRESSIVE_ONBOARDING
);

const mapStateToProps = (state) => {
  return {
    userHasGroup: !isEmpty(state.employer.groups),
    hasAddedGroups: hasAddedGroups(state),
    isLoading: isLoadingSelector(state),
  };
};

const mapDispatchToProps = {
  getAllPortfolios,
  groupCreationShow,
  updateProgressiveOnboarding,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddGroupCard));
