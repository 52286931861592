import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { get, isEmpty, map, orderBy, size } from "lodash";
import { createErrorSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { getPendingLinkedEmployers } from "actions/adminActions";
import { formatCurrency } from "utils/number";
import { FiUsers } from "react-icons/fi";

import IconTable from "components/IconTable";
import IconTableHeader from "components/IconTableHeader";
import IconEmptyState from "components/IconEmptyState";
import IconSpinner from "components/IconSpinner";
import Button from "components/Button";
import Alert from "components/Alert";

class EmployerLinks extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    error: PropTypes.string,
    userId: PropTypes.string,
    client: PropTypes.object,
    getPendingLinkedEmployers: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      isFetching: true,
      pendingEmployers: [],
      linkedEmployers: [],
    };
  }

  async componentDidMount() {
    const { data: pendingAndLinkedEmployers } =
      await this.props.getPendingLinkedEmployers(
        this.props.client,
        this.props.userId
      );

    this.setState({
      pendingEmployers: pendingAndLinkedEmployers.pendingEmployers,
      linkedEmployers: orderBy(pendingAndLinkedEmployers.linkedEmployers, [
        "deleted",
        "asc",
      ]),
      isFetching: false,
    });
  }

  _getEmployerLinksData = () => {
    const linkedRows = map(this.state.linkedEmployers, (linkedEmployer) => {
      const { company, group, contribution, deleted } = linkedEmployer;
      const status = deleted ? "INACTIVE" : "ACTIVE";
      const contributionAmount = get(contribution, "amount", 0);

      return {
        id: linkedEmployer.id,
        companyName: company.name,
        companyId: company.id,
        groupName: group.name,
        groupId: group.id,
        employerStatus: status,
        contributionAmount: formatCurrency(contributionAmount),
      };
    });
    const linkRequestRows = map(
      this.state.pendingEmployers,
      (pendingEmployer) => {
        const { company, group, contribution, status } = pendingEmployer;

        const contributionAmount = get(
          contribution,
          "contributionChangeRequests.0.userNewContributionAmount",
          0
        );

        return {
          id: pendingEmployer.id,
          companyName: company.name,
          companyId: company.id,
          groupName: group.name,
          groupId: group.id,
          employerStatus: status,
          contributionAmount: formatCurrency(contributionAmount),
        };
      }
    );

    return [...linkedRows, ...linkRequestRows];
  };

  _buildEmployerLinksColumns = () => {
    const columns = [
      {
        label: "Company Name",
        key: "companyName",
      },

      {
        label: "Company ID",
        key: "companyId",
      },
      {
        label: "GroupName",
        key: "groupName",
      },
      {
        label: "Group Id",
        key: "groupId",
      },

      {
        label: "Status",
        key: "employerStatus",
      },
      {
        label: "Contribution Amount",
        key: "contributionAmount",
      },
      {
        customComponent: (props) => {
          return (
            <div>
              <Button
                color="action"
                name="action"
                onClick={() => this.props.push(`/employers/${props.companyId}`)}
                btnLabel="View Employer"
                size={"sm"}
              />
            </div>
          );
        },
      },
    ];
    return columns;
  };

  render() {
    const columns = this._buildEmployerLinksColumns();
    const data = this._getEmployerLinksData();

    if (this.state.isFetching) {
      return <IconSpinner centered />;
    }

    if (this.props.error) {
      return <Alert type={"error"} msg={this.props.error} />;
    }
    const { pendingEmployers, linkedEmployers } = this.state;
    const noEmployers = isEmpty(pendingEmployers) && isEmpty(linkedEmployers);

    if (noEmployers) {
      return (
        <IconEmptyState
          header={"Linked Employers"}
          subheader="User has no linked employers."
          icon={<FiUsers color="white" stroke="#60A4BF" size={16} />}
        />
      );
    }

    return (
      <div id={"admin-user-employers"}>
        <IconTableHeader
          tableHeader="Linked Employers"
          tableCount={size(this.state.linkedEmployers)}
        />
        <IconTable columns={columns} data={data} />
      </div>
    );
  }
}

const errorSelector = createErrorSelector(
  adminConstants.GET_PENDING_LINKED_EMPLOYERS
);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  getPendingLinkedEmployers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerLinks));
