export const payrollStatuses = {
  WAITING_FOR_APPROVAL: "WAITING_FOR_APPROVAL",
  APPROVED: "APPROVED",
  PROCESSING: "PROCESSING",
  ERROR: "ERROR",
  COMPLETE: "COMPLETE",
  CANCELED: "CANCELED",
};

export const processedStatuses = [
  "ERROR",
  "COMPLETE",
  "PROCESSING",
  "APPROVED",
  "WAITING_FOR_ACTION",
  "DISMISSED",
];
