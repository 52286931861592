import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import CircularProgressBar from "components/CircularProgressBar";
import { userConstants } from "actions/types";
import Button from "components/Button";
import Alert from "components/Alert";
import TypeUtils from "utils/typeUtils";
import { getUserState, updateRequiredDisclosures } from "actions/userActions";
import IconSpinner from "components/IconSpinner";
import "pages/signUp/individual/IndividualDisclosure.scss";
import { ScrollToFieldError } from "utils/form";

const yup = require("yup");

const schema = yup.object({
  noneApply: yup.boolean().required().label("None Apply"),
  isCustodianEmployee: yup.boolean().label("Politically Exposed").required(),
});

class CustodianDisclosure extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    userState: PropTypes.string.isRequired,
    updateRequiredDisclosures: PropTypes.func.isRequired,
    getUserState: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      disclosure: {
        noneApply: true,
        isCustodianEmployee: false,
      },
    };
  }

  submitDisclosure = (values) => {
    this.props
      .updateRequiredDisclosures(this.props.client, {
        isCustodianEmployee: TypeUtils.booleanValue(values.isCustodianEmployee),
      })
      .then(() => {
        this.props.getUserState(this.props.client);
      });
  };

  render() {
    if (this.state.loading) {
      return <IconSpinner centered />;
    }
    const { disclosure } = this.state;

    return (
      <div>
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={(values) => {
            this.submitDisclosure(values);
          }}
          enableReinitialize={true}
          initialValues={{ ...disclosure }}
        >
          {({ handleSubmit, handleBlur, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit} className="disclosure">
              <ScrollToFieldError />
              <div className="mega-container">
                <div
                  className="step-container is-active"
                  data-circle-percent="70"
                >
                  <section className="page-title-wrap">
                    <article className="text-cell">
                      <p className="page-title">Your Disclosures</p>
                      <p className="page-subtext">
                        We need some additional disclosures.
                      </p>
                    </article>
                    <article className="progress-cell">
                      <ul className="circular-progress-wrap">
                        <CircularProgressBar
                          strokeWidth="8"
                          sqSize="75"
                          percentage="70"
                        />
                      </ul>
                    </article>
                  </section>
                  <div id="form-employer-company">
                    <section className="form-sec-2col-disclosure">
                      <article
                        className="col-form"
                        style={{
                          paddingBottom: 0,
                        }}
                      >
                        <Form.Row className="control-checkbox-noneApply">
                          <Form.Check
                            type="checkbox"
                            name="noneApply"
                            checked={values.noneApply}
                            defaultChecked={true}
                            onChange={(e) => {
                              setFieldValue("noneApply", e.target.checked);
                              if (e.target.checked) {
                                setFieldValue("isCustodianEmployee", false);
                              }

                              window.analytics.track("Clicked None Apply");
                            }}
                          />
                          <Form.Label>
                            <b className="bold">None of these apply to me.</b>
                          </Form.Label>
                        </Form.Row>

                        <Form.Row
                          className="disclosure-control-checkbox"
                          controlId="formCustodianEmployee"
                        >
                          <Form.Check
                            type="checkbox"
                            name="isCustodianEmployee"
                            checked={values.isCustodianEmployee}
                            value={values.isCustodianEmployee}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue(
                                "isCustodianEmployee",
                                e.target.checked
                              );
                              setFieldValue("noneApply", false);
                              window.analytics.track(
                                "Clicked Custodian Employee"
                              );
                            }}
                          />
                          <Form.Label>
                            <b className="bold">Custodian Employee:</b> Are you
                            an employee of, or affiliated with Apex Fintech
                            Solutions?
                          </Form.Label>
                        </Form.Row>
                      </article>
                    </section>
                    <section className="form-sec-2col">
                      <article className="col-form">
                        <div className="disclosure-submit-row">
                          {this.props.error && (
                            <Alert type="error" msg={this.props.error} />
                          )}
                          <Button
                            name="submit"
                            withArrow={true}
                            loading={this.props.isFetching}
                            disabled={
                              !(values.noneApply || values.isCustodianEmployee)
                            }
                          />
                        </div>
                      </article>
                    </section>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const actions = [userConstants.UPDATE_REQUIRED_DISCLOSURES];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => ({
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = {
  updateRequiredDisclosures,
  getUserState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(CustodianDisclosure));
