import { contributionConstants } from "./types";
import {
  addContribution,
  addPaycheckContribution,
  editPaycheckContribution,
  submitOnboardingPayrollContribution,
  getRecommendedContributionAmounts,
  getContributionAbilityRequest,
  addOnboardingRecurringContribution,
  getRecurringContributions,
  addRecurringContribution,
  getContributionAbilityAndEstimatesRequest,
} from "services/contributionService";
import { userConstants, bankConstants } from "actions/types";
import ActionCreator from "utils/actionHandler";
import { getTransactions } from "./transactionActions";
import { getIraAccountBalance } from "./accountActions";

export const addContributionSource = (
  client,
  { iraAccountId, amount, bankId, applyToPriorYear }
) => {
  return async (dispatch) => {
    const actionCreator = new ActionCreator(contributionConstants.CONTRIBUTION);
    // Any new transaction/contribution ability endpoints should be called after contribution is made
    await actionCreator.dispatchFullLifecycle(
      dispatch,
      addContribution({
        client,
        iraAccountId,
        amount,
        bankId,
        applyToPriorYear,
      })
    );
    dispatch(getIraAccountBalance(client));
    dispatch(getTransactions(client, iraAccountId));
    return getContributionAbility(client)(dispatch);
  };
};

export const addPaycheckContributionSource = (
  client,
  { amount, groupId, isDailyCustom }
) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      contributionConstants.PAYCHECK_CONTRIBUTION
    );
    dispatch(actionCreator.request());
    return addPaycheckContribution({
      client,
      amount,
      groupId,
      isDailyCustom,
    }).then(
      (data) => {
        if (data) {
          dispatch(actionCreator.success(data));
          dispatch({
            type: userConstants.USER_EMPLOYER_LINK_REQUESTS_SUCCESS,
            data,
          });
        }
      },
      (error) => {
        console.log("addPaycheckContribution Error: " + error);
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const editPaycheckContributionSource = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      contributionConstants.EDIT_PAYCHECK_CONTRIBUTION
    );
    dispatch(actionCreator.request());

    return editPaycheckContribution(client, params).then(
      (data) => {
        if (data) {
          dispatch(actionCreator.success(data));
          dispatch({ type: userConstants.USER_EMPLOYER_LINK_SUCCESS, data });
        }
      },
      (error) => {
        console.log("editPaycheckContributionSource Error: " + error);
        dispatch(actionCreator.failure(error));
      }
    );
  };
};

export const makeOnboardingPayrollContribution = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      contributionConstants.ONBOARDING_PAYCHECK_CONTRIBUTION
    );

    return actionCreator.dispatchFullLifecycle(
      dispatch,
      submitOnboardingPayrollContribution(client, params)
    );
  };
};

export const getContributionRecommendations = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      contributionConstants.GET_CONTRIBUTION_RECOMMENDATIONS
    );

    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getRecommendedContributionAmounts(client, params)
    );
  };
};

export const getContributionAbility = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      contributionConstants.GET_CONTRIBUTION_ABILITY
    );

    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getContributionAbilityRequest(client)
    );
  };
};

export const getContributionAbilityAndEstimates = (client) => {
  return (dispatch) => {
    const estimateAction = new ActionCreator(
      contributionConstants.GET_CONTRIBUTION_ESTIMATES
    );
    const contributionAbilityAction = new ActionCreator(
      contributionConstants.GET_CONTRIBUTION_ABILITY
    );

    dispatch(estimateAction.request());
    dispatch(contributionAbilityAction.request());

    return getContributionAbilityAndEstimatesRequest(client)
      .then((data) => {
        if (data) {
          dispatch(estimateAction.success(data.estimates));
          dispatch(contributionAbilityAction.success(data.contributionAbility));
        }
      })
      .catch((err) => {
        console.log("getContributionAbilityAndEstimates Error: " + err);
        dispatch(estimateAction.failure(err));
        dispatch(contributionAbilityAction.failure(err));
      });
  };
};

export const makeOnboardingRecurringContribution = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      contributionConstants.ONBOARDING_RECURRING_CONTRIBUTION
    );

    return actionCreator.dispatchFullLifecycle(
      dispatch,
      addOnboardingRecurringContribution(client, params)
    );
  };
};

export const createRecurringContribution = (client, params) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      contributionConstants.ADD_RECURRING_CONTRIBUTION
    );

    return actionCreator.dispatchFullLifecycle(
      dispatch,
      addRecurringContribution(client, params),
      {},
      (data) => {
        return dispatch({
          type: bankConstants.SET_RECURRING_SCHEDULE,
          data: { recurringContribution: data, bankId: params.bankId },
        });
      }
    );
  };
};

export const getUserRecurringContributions = (client) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      contributionConstants.GET_RECURRING_CONTRIBUTIONS
    );

    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getRecurringContributions(client)
    );
  };
};
