import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import { get, keyBy } from "lodash";
import { flushErrors } from "actions/errorActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { toast } from "react-toastify";
import { payrollConstants } from "actions/types";
import { groupType, payrollTypes } from "statics/propTypes";
import { withApollo } from "@apollo/client/react/hoc";

import { dismissPayroll } from "actions/payrollActions";
import {
  payrollStatusToEnglish,
  payrollStatusToType,
} from "statics/statusToEnglishMapping";

import Alert from "components/Alert";
import Button from "components/Button";
import IconSubheader from "components/IconSubheader";
import IconHeader from "components/IconHeader";
import StatusBox from "components/StatusBox";

import "./DismissPayrollModal.scss";
import { ScrollToFieldError } from "utils/form";

const commonDateFormat = "MMM Do YYYY";

const yup = require("yup");
const schema = yup.object({
  reason: yup.string().required(),
});

class DismissPayrollModal extends React.PureComponent {
  static propTypes = {
    flushErrors: PropTypes.func,
    onClose: PropTypes.func,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    onSubmit: PropTypes.func,
    client: PropTypes.object,
    onSuccess: PropTypes.func,
    dismissPayroll: PropTypes.func,
    payroll: PropTypes.arrayOf(payrollTypes),
    groupsById: PropTypes.objectOf(groupType),
    payrollData: PropTypes.arrayOf(PropTypes.object),
  };

  constructor() {
    super();

    this.state = {
      reason: "",
    };
  }

  _onClose = () => {
    this.props.flushErrors();
    this.props.onClose();
  };

  _dismissPayroll = (values) => {
    const payPeriodId = get(this.props.payrollData, "payPeriodId");

    this.props
      .dismissPayroll(this.props.client, payPeriodId, values.reason)
      .then(() => {
        if (!this.props.error) {
          this.props.onSuccess();
          toast.success("Successfully dismissed payroll."),
            this.props.onClose();
        } else {
          toast.error("There was a problem dismissing payroll.");
        }
      });
  };

  render() {
    const groupId = get(this.props.payrollData, "groupId");
    const status = get(this.props.payrollData, "periodState");
    const groupName = get(this.props.groupsById[groupId], "name");
    const start = get(this.props.payrollData, "payPeriodStart");
    const end = get(this.props.payrollData, "payPeriodEnd");
    const period = `${moment(start).format(commonDateFormat)} - ${moment(
      end
    ).format(commonDateFormat)}`;

    return (
      <div>
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={(values) => {
            this._dismissPayroll(values);
          }}
          enableReinitialize={true}
          initialValues={{
            reason: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <Modal
                centered
                scrollable
                show={true}
                onHide={this._onClose}
                className="dismissed-payroll"
              >
                <Modal.Header className="dismissed-payroll-header">
                  <div className="payroll-header">
                    <IconHeader
                      variant="cardHeader"
                      headerText={"Dismiss Payroll"}
                    />
                    <IconSubheader subheader="Are you sure you want to dismiss the following payroll?" />
                  </div>
                </Modal.Header>

                <Modal.Body>
                  <Form.Row>
                    <Form.Group>
                      <div className="dismiss-payroll-info" key={groupId}>
                        <span className="payroll-label">
                          <p>Group</p>
                          <p>Payroll Period</p>
                          <p>Status</p>
                        </span>
                        <span>
                          <p>{groupName}</p>
                          <p>{period}</p>
                          <span>
                            <StatusBox
                              status={payrollStatusToEnglish[status]}
                              type={payrollStatusToType[status]}
                            />
                          </span>
                        </span>
                      </div>
                    </Form.Group>
                  </Form.Row>
                  <div className="break" />
                  <Form.Row>
                    <Form.Group as={Col} md={9}>
                      <Form.Label>Reason for dismissal</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="reason"
                        value={values.reason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.reason && !!errors.reason}
                        isValid={touched.amount && !errors.amount}
                      />
                      <Form.Control.Feedback type="invalid">
                        Reason is a required field.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  {this.props.error && (
                    <Alert type="error" msg={this.props.error} />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <div className="dismiss-actions">
                    <span>
                      <Button
                        className="table-btn"
                        color="dismiss"
                        size="sm"
                        btnLabel="Cancel"
                        type="button"
                        name="cancel"
                        onClick={this.props.onClose}
                      />
                    </span>
                    <span style={{ paddingLeft: 3 }}>
                      <Button
                        className="table-btn"
                        color="red"
                        size="sm"
                        btnLabel="Dismiss"
                        name="submit"
                        withArrow={true}
                        loading={this.props.isFetching}
                        onClick={() => handleSubmit(values)}
                      />
                    </span>
                  </div>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const actions = [payrollConstants.DISMISS_PAYROLL];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const groupsById = keyBy(state.employer.groups, "id");

  return {
    groupsById,
    isFetching: loadingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  dismissPayroll,
  flushErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(DismissPayrollModal));
