import UserIcon from "components/navbar/UserIcon";
import { NavDropdown } from "react-bootstrap";
import { FiLogOut } from "react-icons/fi";
import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export const IconNavDropdown = ({
  userName,
  children,
  onLogout,
  showNavAvatar = true,
  shouldHideSideBar = false,
}) => {
  const [rotateChevron, setRotateChevron] = useState(false);
  const handleRotate = () => setRotateChevron(!rotateChevron);

  return (
    <div
      className={classnames("navigation ", {
        ["portfolio-lg-screen"]: !shouldHideSideBar,
      })}
    >
      <NavDropdown
        title={
          <UserIcon
            userName={userName}
            rotatedChevron={rotateChevron}
            showNavAvatar={showNavAvatar}
          />
        }
        id="nav-dropdown"
        bsPrefix={"icon"}
        onClick={handleRotate}
      >
        {children}
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="4.4" onClick={() => onLogout()}>
          <FiLogOut
            color={"#FFFFFF"}
            fill={"#FFFFFF"}
            stroke={"#32325d"}
            className="dropdown-icons"
            size={14}
          />
          Log Out
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
};

IconNavDropdown.propTypes = {
  userName: PropTypes.string,
  children: PropTypes.node,
  onLogout: PropTypes.func,
  shouldHideSideBar: PropTypes.bool,
  showNavAvatar: PropTypes.bool,
};

export default IconNavDropdown;
