import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { camelCase, get, isEmpty, isNil, map } from "lodash";
import { Col, Container, Row } from "react-bootstrap";
import { portfolioConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { portfolioMetadataMap } from "utils/individualPortfolioHelper";
import { INVESTMENT_PROFILE } from "statics/onboardingSteps";
import { portfolioType } from "statics/propTypes";
import { isRolloverSelector, activeAccountState } from "store/selectors/user";
import { accountConstants } from "actions/types";

import {
  IndividualInvestmentInfoComplete,
  IndividualPortfolioUpdate,
  IndividualRequiredUpdatesPending,
} from "statics/states";
import {
  getAllPortfolios,
  getUserPortfolio,
  selectUserPortfolio,
  rolloverAccountPortfolio,
} from "actions/portfolioActions";
import {
  getEmployerLinkRequests,
  getUserState,
  getRequiredUpdates,
} from "actions/userActions";

import ProgressBar from "components/ProgressBar";
import PortfolioSelectBox from "./PortfolioSelectBox";
import PortfolioDetailCard from "./PortfolioDetailCard";
import RecommendedPortfolio from "./RecommendedPortfolio";
import PortfolioConfirmationModal from "./PortfolioConfirmationModal";
import Button from "components/Button";
import Alert from "components/Alert";
import IconSpinner from "components/IconSpinner";
import CancelRolloverModal from "../../dashboards/individualDashboard/dashboard/CancelRolloverModal";

import "./IndividualPortfolioSelection.scss";

class IndividualPortfolioSelection extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    isFetching: PropTypes.bool,
    isUpdating: PropTypes.bool,
    getUserState: PropTypes.func,
    selectUserPortfolio: PropTypes.func,
    rolloverAccountPortfolio: PropTypes.func,
    getAllPortfolios: PropTypes.func,
    otherPortfolios: PropTypes.arrayOf(portfolioType),
    recommendedPortfolio: PropTypes.object,
    client: PropTypes.object,
    error: PropTypes.string,
    onClose: PropTypes.func,
    getUserPortfolio: PropTypes.func,
    userState: PropTypes.string,
    accountState: PropTypes.string,
    portfolioName: PropTypes.string,
    getEmployerLinkRequests: PropTypes.func,
    getRequiredUpdates: PropTypes.func.isRequired,
    isInRequiredStepFlow: PropTypes.bool.isRequired,
    isRolloverAccount: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedPortfolio: {},
      showAllPortfolios: false,
      showConfirmationModal: false,
      isUpdating: false,
      hasFetchedPortfolios: false,
      showCancelRolloverModal: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.hasFetchedPortfolios &&
      isEmpty(prevState.selectedPortfolio) &&
      !isEmpty(nextProps.recommendedPortfolio)
    ) {
      return {
        selectedPortfolio: nextProps.recommendedPortfolio,
      };
    }
    return null;
  }

  async componentDidMount() {
    window.analytics.page("Individual Portfolio Selection");
    await this.props.getAllPortfolios(this.props.client);
    if (
      this.props.userState === IndividualPortfolioUpdate ||
      this.props.isInRequiredStepFlow
    ) {
      await this.props.getUserPortfolio(this.props.client);
    }

    this.setState({ hasFetchedPortfolios: true });
  }

  _showAllPortfolios = () => {
    this.setState({ showAllPortfolios: true });
  };

  _showConfirmationModal = () => {
    this.setState({ showConfirmationModal: true });
  };

  _closeConfirmationModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  _setSelectedPortfolio = (selectedPortfolio) => {
    this.setState({
      selectedPortfolio,
    });
  };

  _onCancel = () => {
    this.setState({
      showCancelRolloverModal: true,
    });
  };

  _submitPortfoilio = () => {
    if (this.state.selectedPortfolio !== this.props.recommendedPortfolio) {
      this.setState({
        showConfirmationModal: true,
      });
    } else {
      this._setUserPortfolio();
    }
    window.analytics.track("Selected User Portfolio", {
      userState: this.props.userState,
    });
  };
  _setUserPortfolio = () => {
    this.setState({ isUpdating: true });
    if (this.props.isRolloverAccount) {
      this.props
        .rolloverAccountPortfolio(
          this.props.client,
          this.state.selectedPortfolio.id
        )
        .then(() => {
          this.setState({
            isUpdating: false,
          });
        });
    } else {
      this.props
        .selectUserPortfolio(this.props.client, this.state.selectedPortfolio.id)
        .then(() => {
          // hydrate state before `SetupContribution` on the next page of sign up
          if (this.props.userState === IndividualInvestmentInfoComplete) {
            return this.props.getEmployerLinkRequests(this.props.client);
          } else if (this.props.isInRequiredStepFlow) {
            return this.props.getRequiredUpdates(this.props.client);
          }
          return Promise.resolve();
        })
        .then(() => this.props.getUserState(this.props.client))
        .finally(() => this.setState({ isUpdating: false }));
    }
  };

  _portfolioContent = () => {
    const mappedPortfolios = map(this.props.otherPortfolios, (portfolio) => (
      <PortfolioSelectBox
        key={portfolio.label}
        portfolio={portfolio}
        onSelect={this._setSelectedPortfolio}
        isSelected={portfolio === this.state.selectedPortfolio}
      />
    ));
    return (
      <>
        {this.state.showCancelRolloverModal && (
          <CancelRolloverModal
            show={this.state.showCancelRolloverModal}
            onClose={() =>
              this.setState({
                showCancelRolloverModal: false,
              })
            }
            onSuccess={() =>
              this.setState({
                showCancelRolloverModal: false,
              })
            }
          />
        )}
        {this.props.recommendedPortfolio && !this.state.showAllPortfolios && (
          <Container fluid>
            <Col md={8}>
              <RecommendedPortfolio
                portfolio={this.props.recommendedPortfolio}
                onSelect={this._setSelectedPortfolio}
                isRecommendedPortoflio={true}
                isSelected={
                  this.state.selectedPortfolio ===
                  this.props.recommendedPortfolio
                }
              />
              <Row
                className={classnames("submit-btn-row portfolio-lg-screen ", {
                  ["cancel-rollover-button"]: this.props.isRolloverAccount,
                })}
              >
                <div className="rec-button-row">
                  {!this.state.showAllPortfolios && (
                    <div className="other-portfolios">
                      <Button
                        name="action"
                        btnLabel="See Other Portfolios"
                        onClick={this._showAllPortfolios}
                        color="action"
                      />
                    </div>
                  )}
                  <Button
                    name="submit"
                    btnLabel="Select Portfolio"
                    withArrow={true}
                    onClick={this._submitPortfoilio}
                    loading={this.state.isUpdating}
                  />
                </div>
                <div>
                  {this.props.isRolloverAccount && (
                    <Button
                      type="button"
                      btnLabel={"Cancel"}
                      color={"cancel"}
                      name="cancel"
                      onClick={this._onCancel}
                    />
                  )}
                </div>
              </Row>
            </Col>
          </Container>
        )}
        {this.state.showAllPortfolios && (
          <Container>
            <Row className="portfolio-lg-screen">
              <Col md={6}>
                <PortfolioSelectBox
                  portfolio={this.props.recommendedPortfolio}
                  onSelect={this._setSelectedPortfolio}
                  isRecommendedPortoflio={true}
                  isSelected={
                    this.state.selectedPortfolio ===
                    this.props.recommendedPortfolio
                  }
                />
                {mappedPortfolios}
                <div
                  className={classnames("submit-btn-row portfolio-lg-screen", {
                    ["cancel-rollover-button"]: this.props.isRolloverAccount,
                  })}
                >
                  <div>
                    {!this.state.showAllPortfolios && (
                      <Button
                        name="action"
                        btnLabel="See Other Portfolios"
                        onClick={this._showAllPortfolios}
                        color="action"
                      />
                    )}
                    <Button
                      name="action"
                      btnLabel="Select Portfolio"
                      onClick={this._submitPortfoilio}
                      loading={this.state.isUpdating}
                      disabled={isEmpty(this.state.selectedPortfolio)}
                    />
                  </div>
                  <div>
                    {this.props.isRolloverAccount && (
                      <Button
                        type="button"
                        btnLabel={"Cancel"}
                        color={"cancel"}
                        name="cancel"
                        onClick={this._onCancel}
                      />
                    )}
                  </div>
                </div>
              </Col>
              <Col md={6} className="detail-col">
                <PortfolioDetailCard portfolio={this.state.selectedPortfolio} />
              </Col>
            </Row>

            <Row className="portfolio-sm-screen">
              <Col md={6}>
                <PortfolioDetailCard portfolio={this.state.selectedPortfolio} />
              </Col>
              <Col md={6}>
                <div className="scrollbar">
                  <PortfolioSelectBox
                    portfolio={this.props.recommendedPortfolio}
                    onSelect={this._setSelectedPortfolio}
                    isRecommendedPortoflio={true}
                    isSelected={
                      this.state.selectedPortfolio ===
                      this.props.recommendedPortfolio
                    }
                  />
                  {mappedPortfolios}
                </div>
                <div className="submit-btn-row portfolio-sm-screen">
                  <div className="button-row">
                    {!this.state.showAllPortfolios && (
                      <Button
                        btnLabel="See Other Portfolios"
                        onClick={this._showAllPortfolios}
                        name="action"
                        color="action"
                      />
                    )}
                    <Button
                      name="action"
                      btnLabel="Select Portfolio"
                      onClick={this._submitPortfoilio}
                      loading={this.state.isUpdating}
                      disabled={isEmpty(this.state.selectedPortfolio)}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  };

  _loadingContent = () => {
    return <IconSpinner centered />;
  };

  render() {
    let content;

    if (this.props.isFetching) {
      content = this._loadingContent();
    } else {
      content = this._portfolioContent();
    }
    const shouldDisplayCurrentPortfolio =
      (this.props.userState === IndividualPortfolioUpdate ||
        this.props.isInRequiredStepFlow) &&
      !isNil(this.props.portfolioName) &&
      !isEmpty(this.props.portfolioName);

    const individualStates =
      this.props.userState === IndividualInvestmentInfoComplete ||
      this.props.accountState === IndividualInvestmentInfoComplete;

    return (
      <>
        {individualStates && (
          <ProgressBar
            isEmployer={false}
            activeStepId={INVESTMENT_PROFILE.id}
            progressPercent={"85"}
          />
        )}

        <div className="individual-portfolio-selection">
          <div className="mega-container">
            <section className="page-title-wrap">
              <article className="text-cell">
                <p className="page-title">
                  Based on your answers, we recommend the following investment
                  strategy for you.{" "}
                </p>
                <p className="page-subtext-portfolio">
                  Each portfolio represents a different level of investment risk
                  and potential growth.
                </p>
                {shouldDisplayCurrentPortfolio && (
                  <p className="your-portfolio">
                    Your current portfolio is{" "}
                    {get(
                      portfolioMetadataMap,
                      `${this.props.portfolioName}.portfolio`
                    )}
                    .
                  </p>
                )}
              </article>
            </section>
            <>
              {this.state.showConfirmationModal && (
                <PortfolioConfirmationModal
                  show={this.state.showConfirmationModal}
                  onClose={() => {
                    this.setState({ showConfirmationModal: false });
                  }}
                  onSubmit={this._setUserPortfolio}
                />
              )}
              <div>
                {content}
                {this.props.error && (
                  <Alert type="error" msg={this.props.error} />
                )}
              </div>
            </>
          </div>
        </div>
      </>
    );
  }
}

const loadingPortfolios = createLoadingSelector(
  portfolioConstants.GET_PORTFOLIOS,
  portfolioConstants.GET_USER_PORTFOLIO
);

const errorSelector = createErrorSelector([
  portfolioConstants.GET_PORTFOLIOS,
  portfolioConstants.UPDATE_USER_PORTFOLIO,
  portfolioConstants.SELECT_USER_ROLLOVER_PORTFOLIO,
  portfolioConstants.GET_USER_PORTFOLIO,
  accountConstants.GET_ACCOUNTS,
]);

const mapStateToProps = (state) => {
  const portfolioName = camelCase(
    get(state, "portfolios.activePortfolio.description")
  );
  const userState = state.user.userState.state;
  return {
    portfolios: state.portfolios.allPortfolios,
    otherPortfolios: state.portfolios.otherPortfolios,
    recommendedPortfolio: state.portfolios.recommendedPortfolio,
    isFetching: loadingPortfolios(state),
    error: errorSelector(state),
    userState,
    portfolioName,
    isInRequiredStepFlow: IndividualRequiredUpdatesPending === userState,
    isRolloverAccount: isRolloverSelector(state),
    accountState: activeAccountState(state),
  };
};

const mapDispatchToProps = {
  selectUserPortfolio,
  rolloverAccountPortfolio,
  getUserState,
  getAllPortfolios,
  getUserPortfolio,
  getEmployerLinkRequests,
  getRequiredUpdates,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualPortfolioSelection));
