import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, Form } from "react-bootstrap";
import { isEmpty, keyBy, keys, map, size, some } from "lodash";
import { FaUndo } from "react-icons/fa";
import { FiChevronLeft } from "react-icons/fi";

import { getPaginatedChangeRequests } from "actions/payrollActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { payrollConstants } from "actions/types";
import Button from "components/Button";
import IconSpinner from "components/IconSpinner";
import StatusBox from "components/StatusBox";
import Paginator from "components/Paginator";
import { formatCurrency } from "utils/number";
import InfoTooltip from "components/InfoTooltip";
import IconTable from "components/IconTable";

export class ReviewApprovals extends React.PureComponent {
  static propTypes = {
    goToNamedStep: PropTypes.func,
    getPaginatedChangeRequests: PropTypes.func,
    setKeepPrior: PropTypes.func,
    setApproved: PropTypes.func,
    isFetching: PropTypes.bool,
    client: PropTypes.object,
    keptPriorContributions: PropTypes.object,
    lockedEmployeeIds: PropTypes.arrayOf(PropTypes.string),
    approvedContributions: PropTypes.object,
    skippedContributions: PropTypes.object,
    removedEmployees: PropTypes.object,
    group: PropTypes.object.isRequired,
    pendingChangesCount: PropTypes.number,
    pendingChanges: PropTypes.array,
    usersWhoLeftPlan: PropTypes.array,
    lockedUserContributions: PropTypes.array,
    payPeriodId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      limit: 25,
      offset: 0,
      checkedRows: {},
      initialFetching: true,
      checkAll: false,
      page: 0,
    };
  }

  componentDidMount() {
    this._getPaginatedChangeRequests().then(() => {
      this.setState({
        initialFetching: false,
        checkAll: false,
      });
    });
  }

  _getPaginatedChangeRequests = () => {
    const excludedIds = [
      ...keys(this.props.skippedContributions),
      ...keys(this.props.removedEmployees),
      ...this.props.lockedEmployeeIds,
    ];
    return this.props.getPaginatedChangeRequests(this.props.client, {
      groupId: this.props.group.id,
      limit: this.state.limit,
      offset: this.state.offset,
      excludedIds,
      payPeriodId: this.props.payPeriodId,
    });
  };

  _removeFromKeepPrior = (contributionId) => {
    const newKeepPrior = { ...this.props.keptPriorContributions };
    delete newKeepPrior[contributionId];
    this.props.setKeepPrior(newKeepPrior);
  };
  _removeFromApproved = (contributionId) => {
    const newApproved = { ...this.props.approvedContributions };
    delete newApproved[contributionId];
    this.props.setApproved(newApproved);
  };

  _getStatus = (contributionId, isUserWhoLeft, isLockedUser) => {
    const statusAndUndo = {
      status: null,
      undoButton: null,
    };
    if (isUserWhoLeft) {
      statusAndUndo.status = <StatusBox status="Removed" type="danger" />;
      statusAndUndo.undoButton = null;
    } else if (isLockedUser) {
      statusAndUndo.status = <StatusBox status="Paused" type="warning" />;
      statusAndUndo.undoButton = null;
    } else if (this.props.keptPriorContributions[contributionId]) {
      statusAndUndo.status = (
        <StatusBox status="Using Prior Amount" type="dark" />
      );
      statusAndUndo.undoButton = (
        <FaUndo
          size={16}
          onClick={() => this._removeFromKeepPrior(contributionId)}
        />
      );
    } else if (this.props.approvedContributions[contributionId]) {
      statusAndUndo.status = <StatusBox status="Approved" type="success" />;
      statusAndUndo.undoButton = (
        <FaUndo
          size={16}
          onClick={() => this._removeFromApproved(contributionId)}
        />
      );
    }

    return statusAndUndo;
  };

  _setChecked = (changeReq, isChecked) => {
    let newChecked = { ...this.state.checkedRows };
    if (!isChecked) {
      delete newChecked[changeReq.employeeId];
      this.setState({ checkedRows: newChecked });
      return;
    }
    this.setState({
      checkedRows: {
        ...this.state.checkedRows,
        [changeReq.employeeId]: changeReq,
      },
    });
  };

  _addToKeepPrior = () => {
    const newKeepPrior = {
      ...this.props.keptPriorContributions,
      ...this.state.checkedRows,
    };

    this.props.setKeepPrior(newKeepPrior);
    this.setState({ checkedRows: {}, checkAll: false });
  };
  _addToApproved = () => {
    const newApproved = {
      ...this.props.approvedContributions,
      ...this.state.checkedRows,
    };

    this.props.setApproved(newApproved);
    this.setState({ checkedRows: {}, checkAll: false });
  };

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () =>
      this._getPaginatedChangeRequests()
    );
  };

  _blankSlate = () => {
    return (
      <p className="blank-slate-msg">
        There are no contribution changes for this pay period.
      </p>
    );
  };

  _setCheckedAll = (checked) => {
    if (checked) {
      const checkedRows = keyBy(this.props.pendingChanges, "employeeId");
      this.setState({ checkedRows, checkAll: true });
    } else {
      this.setState({ checkedRows: {}, checkAll: false });
    }
  };

  _getApprovalData = () => {
    return map(
      [
        ...this.props.usersWhoLeftPlan,
        ...this.props.lockedUserContributions,
        ...this.props.pendingChanges,
      ],
      (change) => {
        const {
          userProfile: { firstName, lastName, lastFourOfSsn },
        } = change;
        const { status, undoButton } = this._getStatus(
          change.employeeId,
          change.isUserWhoLeft,
          change.isLockedUser
        );
        const contributionAmount = formatCurrency(
          change.userNewContributionAmount
        );
        const prevContributionAmount = formatCurrency(
          change.userCurrentContributionAmount
        );

        const { isTopOffContribution } = change;

        const fullName = `${firstName} ${lastName}`;

        return {
          employeeName: fullName,
          lastFourOfSsn,
          prevContributionAmount,
          status,
          undoButton,
          contributionAmount,
          isTopOffContribution,
          change,
        };
      }
    );
  };

  _buildReviewApprovalsColumns = () => {
    const columns = [
      {
        label: "",
        customComponent: (props) => {
          const { status } = this._getStatus(
            props.change.employeeId,
            props.change.isUserWhoLeft,
            props.change.isLockedUser
          );
          return (
            <div style={{ width: 25, textAlign: "center", paddingLeft: "8px" }}>
              {!status && (
                <Form.Check
                  type="checkbox"
                  name="selectAllPending"
                  checked={this.state.checkedRows[props.change.employeeId]}
                  onChange={(e) =>
                    this._setChecked(props.change, e.target.checked)
                  }
                />
              )}
            </div>
          );
        },
      },
      {
        label: "Employee Name",
        key: "employeeName",
      },

      {
        label: "Last 4 SSN",
        key: "lastFourOfSsn",
      },
      {
        label: "Previous Amount	",
        key: "prevContributionAmount",
      },
      {
        label: "Current Amount",
        key: "amount",
        customComponent: (props) => {
          return (
            <div
              style={{ fontWeight: props.isTopOffContribution ? "600" : "400" }}
            >
              {props.contributionAmount}
              {props.isTopOffContribution && (
                <InfoTooltip
                  placement="right"
                  tooltipBody="This pending contribution is adjusted to reach the user's contribution limit for the tax year. Cannot use prior amount as it would cause an overcontribution."
                />
              )}
            </div>
          );
        },
      },
      {
        label: "Status",
        key: "status",
      },
      {
        customComponent: (props) => {
          const { undoButton } = this._getStatus(
            props.change.employeeId,
            props.change.isUserWhoLeft,
            props.change.isLockedUser
          );
          return <div className="undo-button">{undoButton}</div>;
        },
      },
    ];
    return columns;
  };

  _getContent = () => {
    const columns = this._buildReviewApprovalsColumns();
    const data = this._getApprovalData();
    return (
      <>
        {this.props.isFetching && <IconSpinner centered />}
        <IconTable columns={columns} data={data} />
        <div className="pagination-row">
          <Paginator
            onChange={this._onPageChange}
            pageCount={this.props.pendingChangesCount / this.state.limit}
            page={this.state.page}
          />
        </div>
      </>
    );
  };

  _hasTopOffSelected = () => {
    const topOffPending = some(this.props.pendingChanges, (change) => {
      if (!change.isTopOffContribution) return false;
      if (this.state.checkedRows[change.employeeId]) return true;
    });

    return topOffPending;
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    const allActioned =
      size(this.props.approvedContributions) +
      size(this.props.keptPriorContributions);

    const totalActioned = this.props.pendingChangesCount - allActioned;
    const disableContinue = totalActioned !== 0;
    // only want to show blankslate if nothing has changed (even things that don't require approval but are still changes)
    const noApprovals =
      this.props.pendingChangesCount === 0 &&
      isEmpty(this.props.lockedUserContributions) &&
      isEmpty(this.props.usersWhoLeftPlan);

    const content = noApprovals ? this._blankSlate() : this._getContent();
    const topOffContributionIsSelected = this._hasTopOffSelected();

    return (
      <div>
        <Card>
          <div className="workflow-widget">
            <div className="action-header">
              <p className="group-info">
                <span className="group-name">
                  {this.props.group.name}:{" "}
                  <span className="invite-number">
                    Contributions that need action (
                    <span className="number">
                      {totalActioned}/{this.props.pendingChangesCount}
                    </span>
                    )
                  </span>
                </span>
              </p>

              {!noApprovals && (
                <div className="btn-row">
                  <Button
                    onClick={this._addToKeepPrior}
                    name="action"
                    color="secondary"
                    size="sm"
                    btnLabel="Keep prior amount"
                    disabled={
                      isEmpty(this.state.checkedRows) ||
                      topOffContributionIsSelected
                    }
                  />
                  <Button
                    onClick={this._addToApproved}
                    name="action"
                    btnLabel="Approve"
                    size="sm"
                    disabled={isEmpty(this.state.checkedRows)}
                  />
                </div>
              )}
            </div>
          </div>
          {content}
        </Card>
        <div className="process-payroll-button-row">
          <Button
            icon={{
              icon: <FiChevronLeft size={14} stroke={"#0cb7c4"} />,
              position: "left",
            }}
            onClick={() => this.props.goToNamedStep("existing")}
            name="cancel"
            btnLabel="Go Back"
            color="cancel"
          />
          <Button
            name="action"
            onClick={() => this.props.goToNamedStep("summary")}
            disabled={disableContinue}
          />
        </div>
      </div>
    );
  }
}

const isLoadingSelector = createLoadingSelector(
  payrollConstants.GET_PAGINATED_CHANGE_REQUESTS
);
const isErrorSelector = createErrorSelector(
  payrollConstants.GET_PAGINATED_CHANGE_REQUESTS
);

const mapStateToProps = (state) => {
  return {
    isFetching: isLoadingSelector(state),
    pendingChanges: state.payroll.paginatedPendingContributionChanges,
    pendingChangesCount: state.payroll.paginatedPendingContributionChangesCount,
    error: isErrorSelector(state),
  };
};

const mapDispatchToProps = {
  getPaginatedChangeRequests,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ReviewApprovals));
