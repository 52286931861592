export const EmployerAccountPending = "EmployerAccountPending";
export const EmployerApplicationApproved = "EmployerApplicationApproved";
export const EmployerApplicationDenied = "EmployerApplicationDenied";
export const EmployerApplicationAdditionalDocumentationSubmitted =
  "EmployerApplicationAdditionalDocumentationSubmitted";
export const EmployerApplicationRequiresAdditionalDocumentation =
  "EmployerApplicationRequiresAdditionalDocumentation";
export const EmployerApplicationSubmittedKYB =
  "EmployerApplicationSubmittedKYB";
export const EmployerRegistrationComplete = "EmployerRegistrationComplete";
export const EmployerSelfServeRegistrationComplete =
  "EmployerSelfServeRegistrationComplete";
export const EmployerSelfServeControllerComplete =
  "EmployerSelfServeControllerComplete";
export const EmployerSelfServeTermsComplete = "EmployerSelfServeTermsComplete";
export const EmployerCreated = "EmployerCreated";
export const EmployerTermsComplete = "EmployerTermsComplete";
export const EmployerBillingVerificationPending =
  "EmployerBillingVerificationPending";

export const IndividualSubmitSsnPending = "IndividualSubmitSsnPending";
export const IndividualEmailVerificationPending =
  "IndividualEmailVerificationPending";

export const IndividualLoginCreated = "IndividualLoginCreated";
export const IndividualRolloverInfoPending = "IndividualRolloverInfoPending";
export const IndividualEmailVerificationComplete =
  "IndividualEmailVerificationComplete";
export const IndividualApplicationInfoComplete =
  "IndividualApplicationInfoComplete";
export const IndividualInvestmentInfoComplete =
  "IndividualInvestmentInfoComplete";
export const IndividualInvestmentInfo = "IndividualInvestmentInfo";
export const IndividualDisclosures = "IndividualDisclosures";
export const IndividualDisclosuresComplete = "IndividualDisclosuresComplete";
export const IndividualTermsAccepted = "IndividualTermsAccepted";
export const IndividualKYC1Green = "IndividualKYC1Green";
export const IndividualKYC2Yellow = "IndividualKYC2Yellow";
export const IndividualApplicationRestartedKYC2 =
  "IndividualApplicationRestartedKYC2";
export const IndividualKYC1Red = "IndividualKYC1Red";
export const IndividualKYC2Red = "IndividualKYC2Red";
export const IndividualApplicationSubmittedKYC1 =
  "IndividualApplicationSubmittedKYC1";
export const IndividualApplicationSubmittedKYC2 =
  "IndividualApplicationSubmittedKYC2";
export const IndividualApplicationApproved = "IndividualApplicationApproved";
export const IndividualApplicationRequiresAdditionalDocumentation =
  "IndividualApplicationRequiresAdditionalDocumentation";
export const IndividualApplicationAdditionalDocumentationSubmitted =
  "IndividualApplicationAdditionalDocumentationSubmitted";
export const IndividualApplicationDenied = "IndividualApplicationDenied";
export const EmployerAdminActive = "EmployerAdminActive";
export const EmployerAdminLoginCreated = "EmployerAdminLoginCreated";
export const IndividualReopened = "IndividualReopened";
export const IndividualClosed = "IndividualClosed";
export const IndividualLocked = "IndividualLocked";
export const IndividualDormant = "IndividualDormant";
export const IndividualActive = "IndividualActive";
export const IndividualPortfolioPending = "IndividualPortfolioPending";
export const IndividualClosePending = "IndividualClosePending";
export const IndividualContributionPending = "IndividualContributionPending";
export const IndividualApplicationAppealSubmitted =
  "IndividualApplicationAppealSubmitted";
export const IndividualApplicationAppealDocumentationAdminReviewPending =
  "IndividualApplicationAppealDocumentationAdminReviewPending";
export const IndividualApplicationRequiresAdditionalDocumentationForAppeal =
  "IndividualApplicationRequiresAdditionalDocumentationForAppeal";
export const IndividualInvestmentProfileUpdate =
  "IndividualInvestmentProfileUpdate";
export const IndividualPortfolioUpdate = "IndividualPortfolioUpdate";
export const IndividualRequiredUpdatesPending =
  "IndividualRequiredUpdatesPending";
export const EmployerRequiredUpdatesPending = "EmployerRequiredUpdatesPending";
export const IndividualTermsPending = "IndividualTermsPending";
export const IndividualPayrollContributionPending =
  "IndividualPayrollContributionPending";
export const IndividualAccountTypeSelectionPending =
  "IndividualAccountTypeSelectionPending";
export const IndividualSelectedAccountType = "IndividualSelectedAccountType";
export const IndividualApplicationAdditionalDocumentationSubmittedKYC1 =
  "IndividualApplicationAdditionalDocumentationSubmittedKYC1";
export const IndividualApplicationRequiresAdditionalDocumentationKYC1 =
  "IndividualApplicationRequiresAdditionalDocumentationKYC1";
export const IndividualApplicationRequiresAdditionalDocumentationForAppealKYC1 =
  "IndividualApplicationRequiresAdditionalDocumentationForAppealKYC1";
export const IndividualApplicationAppealSubmittedKYC1 =
  "IndividualApplicationAppealSubmittedKYC1";
export const IndividualApplicationAppealDocumentationAdminReviewPendingKYC1 =
  "IndividualApplicationAppealDocumentationAdminReviewPendingKYC1";
export const IndividualApplicationAttestationSubmitted =
  "IndividualApplicationAttestationSubmitted";
export const IndividualCompletedForms = "IndividualCompletedForms";
export const EmployerPendingKYBSubmission = "EmployerPendingKYBSubmission";
export const EmployerClosed = "EmployerClosed";
export const EmployerClosePending = "EmployerClosePending";
export const EmployerSelfServeControllerRegistrationPending =
  "EmployerSelfServeControllerRegistrationPending";
export const EmployerBillingDetailsPending = "EmployerBillingDetailsPending";
export const EmployerSelfServeBillingDetailsPending =
  "EmployerSelfServeBillingDetailsPending";
export const EmployerBillingDetailsComplete = "EmployerBillingDetailsComplete";
export const EmployerSelfServeBillingDetailsComplete =
  "EmployerSelfServeBillingDetailsComplete";

//Rollover States
export const AccountActive = "AccountActive";
export const AccountClosePending = "AccountClosePending";
export const AccountClosed = "AccountClosed";
export const AccountLocked = "AccountLocked";

export const AccountApplicationSubmitted = "AccountApplicationSubmitted";
export const AccountApplicationApproved = "AccountApplicationApproved";
export const AccountApplicationDenied = "AccountApplicationDenied";

export const AccountApplicationApexAutoAppeal =
  "AccountApplicationApexAutoAppeal";
export const AccountApplicationAdditionalApexDocumentationSubmitted =
  "AccountApplicationAdditionalApexDocumentationSubmitted";
export const AccountApplicationApexDocumentsRequired =
  "AccountApplicationApexDocumentsRequired";

export const employerOnboardingStates = [
  EmployerAccountPending,
  EmployerApplicationDenied,
  EmployerApplicationAdditionalDocumentationSubmitted,
  EmployerApplicationRequiresAdditionalDocumentation,
  EmployerApplicationSubmittedKYB,
  EmployerRegistrationComplete,
  EmployerCreated,
  EmployerTermsComplete,
  EmployerPendingKYBSubmission,
  EmployerClosed,
  EmployerSelfServeRegistrationComplete,
  EmployerSelfServeTermsComplete,
  EmployerSelfServeControllerComplete,
  EmployerSelfServeBillingDetailsComplete,
  EmployerBillingDetailsComplete,
  EmployerBillingDetailsPending,
  EmployerSelfServeBillingDetailsPending,
  EmployerSelfServeControllerRegistrationPending,
  EmployerBillingVerificationPending,
];

export const employerYellowPathStates = [
  EmployerApplicationAdditionalDocumentationSubmitted,
  EmployerApplicationRequiresAdditionalDocumentation,
];

// Add the list of all the signup fixed-path states
export const onboardingStates = [
  IndividualSubmitSsnPending,
  IndividualEmailVerificationPending,
  IndividualLoginCreated,
  IndividualRolloverInfoPending,
  IndividualEmailVerificationComplete,
  IndividualApplicationInfoComplete,
  IndividualInvestmentInfoComplete,
  IndividualDisclosuresComplete,
  IndividualPayrollContributionPending,
  IndividualAccountTypeSelectionPending,
  IndividualSelectedAccountType,
  IndividualTermsPending,
  IndividualTermsAccepted,
  IndividualCompletedForms,
  IndividualKYC1Green,
  IndividualKYC2Yellow,
  IndividualKYC1Red,
  IndividualKYC2Red,
  IndividualApplicationSubmittedKYC1,
  IndividualApplicationSubmittedKYC2,
  IndividualApplicationApproved,
  IndividualApplicationRequiresAdditionalDocumentation,
  IndividualApplicationAdditionalDocumentationSubmitted,
  IndividualApplicationDenied,
  IndividualApplicationAppealDocumentationAdminReviewPending,
  IndividualApplicationRequiresAdditionalDocumentationForAppeal,
  IndividualApplicationAppealSubmitted,
  IndividualApplicationAdditionalDocumentationSubmittedKYC1,
  IndividualApplicationRequiresAdditionalDocumentationKYC1,
  IndividualApplicationRequiresAdditionalDocumentationForAppealKYC1,
  IndividualApplicationAppealSubmittedKYC1,
  IndividualApplicationAppealDocumentationAdminReviewPendingKYC1,
  IndividualApplicationRestartedKYC2,
  IndividualApplicationAttestationSubmitted,

  AccountApplicationSubmitted,
  AccountApplicationApproved,
  AccountApplicationDenied,
];

export const individualPreKycSubmissionStates = [
  IndividualEmailVerificationComplete,
  IndividualApplicationInfoComplete,
  IndividualInvestmentInfoComplete,
  IndividualDisclosuresComplete,
  IndividualPayrollContributionPending,
  IndividualAccountTypeSelectionPending,
  IndividualRolloverInfoPending,
  IndividualTermsPending,
];

export const employerAdminStates = [
  EmployerAdminActive,
  EmployerAdminLoginCreated,
];

export const lifecycleStates = [
  IndividualReopened,
  IndividualClosed,
  IndividualLocked,
  IndividualDormant,
  IndividualActive,
  IndividualClosePending,
  IndividualInvestmentProfileUpdate,
  IndividualPortfolioUpdate,
  IndividualRequiredUpdatesPending,
  IndividualContributionPending,
  IndividualPortfolioPending,

  AccountActive,
  AccountClosePending,
  AccountClosed,
  AccountLocked,
];

export function getStatusLabel(state) {
  switch (state) {
    case EmployerPendingKYBSubmission:
    case EmployerRegistrationComplete:
    case EmployerSelfServeRegistrationComplete:
    case EmployerSelfServeControllerRegistrationPending:
    case EmployerSelfServeControllerComplete:
    case EmployerSelfServeTermsComplete:
    case EmployerTermsComplete:
    case EmployerCreated:
    case EmployerAccountPending:
    case null:
      return "Pending KYB";
    case EmployerApplicationSubmittedKYB:
      return "Pending Approval";
    case EmployerApplicationAdditionalDocumentationSubmitted:
    case EmployerApplicationRequiresAdditionalDocumentation:
      return "Pending Yellowpath Docs";
    case EmployerApplicationDenied:
      return "Denied";
    case EmployerApplicationApproved:
      return "Active";
    case EmployerClosePending:
    case EmployerClosed:
      return "Inactive";

    default:
      return "Active";
  }
}

export const progressiveOnboardingStates = {
  ProgressiveOnboardingStarted: "ProgressiveOnboardingStarted",
  AddedBankAccount: "AddedBankAccount",
  AddedGroups: "AddedGroups",
  AddedDeductionCode: "AddedDeductionCode",
  InvitedEmployees: "InvitedEmployees",
  AnnouncedIcon: "AnnouncedIcon",
  LaunchedPlan: "LaunchedPlan",
  DeductionCodeAfterInviteEmployees: "DeductionCodeAfterInviteEmployees",
  DeductionCodeAfterLaunchPlan: "DeductionCodeAfterLaunchPlan",
};

export const progressiveOnboardingEvents = {
  Create: "Create",
  AddBank: "AddBank",
  AddGroups: "AddGroups",
  AddDeductionCode: "AddDeductionCode",
  InviteUsers: "InviteUsers",
  AnnouncePlan: "AnnouncePlan",
  LaunchPlan: "LaunchPlan",
  DeductionCodeAfterInviteEmployees: "DeductionCodeAfterInviteEmployees",
  DeductionCodeAfterLaunchPlan: "DeductionCodeAfterLaunchPlan",
  Reset: "Reset",
};

export const BusinessMemberKYCRequested = "BusinessMemberKYCRequested";
export const BusinessMemberKYCFailed = "BusinessMemberKYCFailed";

export const rolloverStates = {
  AccountActive: "AccountActive",
  AccountClosePending: "AccountClosePending",
  AccountClosed: "AccountClosed",
  AccountLocked: "AccountLocked",
  AccountApplicationSubmitted: "AccountApplicationSubmitted",
  AccountApplicationApproved: "AccountApplicationApproved",
  AccountApplicationDenied: "AccountApplicationDenied",
  AccountApplicationApexAutoAppeal: "AccountApplicationApexAutoAppeal",
  AccountApplicationAdditionalApexDocumentationSubmitted:
    "AccountApplicationAdditionalApexDocumentationSubmitted",
  AccountApplicationApexDocumentsRequired:
    "AccountApplicationApexDocumentsRequired",
};
