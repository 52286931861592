import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { getLinkedEmployees } from "actions/employerActions";
import { createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";

import ProcessOneTimeGroupsTable from "./ProcessOneTimeGroupsTable";
import IconSpinner from "components/IconSpinner";
import {
  getAllEmployerAccounts,
  getAllEmployerGroups,
} from "actions/employerActions";

class ProcessOneTimeContributions extends React.Component {
  static propTypes = {
    getLinkedEmployees: PropTypes.func,
    getPendingLinkedEmployees: PropTypes.func,
    getAllEmployerAccounts: PropTypes.func,
    getAllEmployerGroups: PropTypes.func,
    client: PropTypes.object,
    isFetching: PropTypes.bool,
  };

  componentDidMount() {
    window.analytics.page("Employer Process One-Time Contributions");
    this.props.getLinkedEmployees(this.props.client);
    this.props.getAllEmployerAccounts(this.props.client);
    this.props.getAllEmployerGroups(this.props.client);
  }

  render() {
    let content;
    if (this.props.isFetching) {
      content = <IconSpinner centered />;
    } else {
      content = <ProcessOneTimeGroupsTable />;
    }
    return (
      <div className="mega-container">
        <section className="page-title-wrap">
          <article className="text-cell">
            <h1 className="page-title">Process One-Time Contributions</h1>
            <p className="page-subtext">
              Process a one-time payroll contribution for any pay period.
            </p>
          </article>
        </section>
        <div className="main-content">{content}</div>
      </div>
    );
  }
}

const isFetchingSelector = createLoadingSelector([
  employerConstants.GET_LINKED_EMPLOYEES,
  employerConstants.GET_EMPLOYER_ACCOUNTS,
  employerConstants.GET_EMPLOYER_GROUPS,
]);

const mapStateToProps = (state) => {
  return {
    isFetching: isFetchingSelector(state),
  };
};

const mapDispatchToProps = {
  getLinkedEmployees,
  getAllEmployerAccounts,
  getAllEmployerGroups,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ProcessOneTimeContributions));
