import React from "react";
import AdminNavBar from "components/navbar/AdminNavBar";
import { determineSite } from "utils/determineSite";
import IndividualNavBar from "components/navbar/IndividualNavBar";
import EmployerNavBar from "components/navbar/EmployerNavBar";

const NavBar = () => {
  switch (determineSite()) {
    case "individual":
      return <IndividualNavBar />;
    case "employer":
      return <EmployerNavBar />;
    case "admin":
      return <AdminNavBar />;
    default:
      return null;
  }
};

export default NavBar;
