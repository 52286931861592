import React from "react";

import { Card } from "react-bootstrap";
import LoginInfo from "pages/dashboards/individualDashboard/profile/LoginInfo";

const IndividualChangeEmail = () => {
  return (
    <Card>
      <Card.Body>
        <LoginInfo isOnboarding={true} />
      </Card.Body>
    </Card>
  );
};

export default IndividualChangeEmail;
