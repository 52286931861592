import React from "react";
import PropTypes from "prop-types";
import "./PortfolioDetailCard.scss";
import { map, isEmpty } from "lodash";
import { portfolioMetadataMap } from "utils/individualPortfolioHelper";
import { formatPercentage } from "utils/number";
import "./PortfolioDetailCard.scss";

import { Card, Col, Row } from "react-bootstrap";

const RecommendedPortfolio = (props) => {
  const { assets, portfolioName } = props.portfolio;

  const portfolioMetadata = portfolioMetadataMap[portfolioName];

  if (isEmpty(props.portfolio) || isEmpty(portfolioMetadata)) return null;

  return (
    <div className="portfolio-card">
      <Card className="portfolio-detail">
        <Col>
          <Row>
            <Card.Body>
              <Card.Title className="card-title-horizontal">
                <div className="portfolio-type">
                  <p className="card-label"> {portfolioMetadata.portfolio}</p>
                  <p className="portfolio">Portfolio</p>
                </div>

                {props.isRecommendedPortoflio && (
                  <div className="recommended">
                    <p className="isPreferred">{" Recommended"}</p>
                  </div>
                )}
              </Card.Title>
              <div className="card-description">
                <p className="stock-bond-allocation">
                  {portfolioMetadata.stock}
                  {portfolioMetadata.stock && portfolioMetadata.bond && (
                    <span className="divider">|</span>
                  )}
                  {portfolioMetadata.bond}
                </p>
                <p className="card-text">{portfolioMetadata.description}</p>
              </div>
            </Card.Body>
          </Row>
          <div className="asset-breakdown-horizontal">Breakdown of assets</div>
          <Row className="horizontal-card">
            {map(assets, (asset) => (
              <Col md={6} key={asset.id}>
                <div className="asset-details-horizontal">
                  <div className="stock-symbol">
                    <p className="sym">{asset.symbol}</p>
                  </div>
                  <p className="asset-percentage">
                    {formatPercentage(asset.allocation, 0)}
                  </p>
                </div>
                <div className="allocation-bar">
                  <div
                    className="allocation-fill"
                    style={{
                      width: formatPercentage(asset.allocation, 0),
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Card>
    </div>
  );
};

RecommendedPortfolio.defaultProps = {
  portfolio: {},
};

RecommendedPortfolio.propTypes = {
  portfolio: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    portfolioName: PropTypes.string,
    assets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        symbol: PropTypes.string,
        allocation: PropTypes.number,
        assets: PropTypes.string,
      })
    ),
  }),
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  isRecommendedPortoflio: PropTypes.bool,
};

export default RecommendedPortfolio;
