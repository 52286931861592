import React from "react";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  chain,
  find,
  get,
  has,
  isEmpty,
  map,
  pick,
  reduce,
  size,
} from "lodash";
import { PropTypes } from "prop-types";
import { toast } from "react-toastify";
import { push } from "connected-react-router";

import { approvePayroll } from "actions/payrollActions";
import {
  approveContributionChanges,
  deleteEmployerLinks,
  processPayrollForGroup,
  rejectContributionChanges,
} from "actions/employerActions";
import { groupType } from "statics/propTypes";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { activeBankSelector } from "store/selectors/bank";
import { employerConstants, payrollConstants } from "actions/types";
import { formatCurrency } from "utils/number";
import Button from "components/Button";
import InfoTable from "components/InfoTable";
import Alert from "components/Alert";
import {
  userProfilesSelector,
  usersWithNoContributionChangeSelector,
  usersWithPendingContributionsSelector,
} from "store/selectors/user";
import { userProfileSchema } from "statics/propTypes";
import { flushErrors } from "actions/errorActions";
import { formatAmount } from "utils/number";
import moment from "moment";
import IconTable from "components/IconTable";

import EmployerCard from "components/EmployerCard";

import "./PayrollSummary.scss";
import PayrollStatementDownloader from "./PayrollStatementDownloader";

const baseSummaryTable = [
  {
    key: "payPeriod",
    label: "Pay Period",
  },
  {
    key: "newlyApprovedAmt",
    label: "Total Amount",
    format: "currency",
  },
  {
    key: "displayReason",
    label: "Reason",
  },
];

const summaryTable = [
  {
    key: "numberOfUsersLeaving",
    label: "Users Leaving Plan",
  },
  {
    key: "numberOfSkippedContributions",
    label: "Skipped Contribution Changes",
  },
  ...baseSummaryTable,
];

const oneTimeSummaryTable = [...baseSummaryTable];

export class PayrollSummary extends React.PureComponent {
  static propTypes = {
    //redux props
    client: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
    error: PropTypes.string,
    isUpdating: PropTypes.bool,
    bankAccountNumber: PropTypes.string.isRequired,
    processingDate: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    userProfiles: userProfileSchema.isRequired,
    linkedEmployees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    groups: PropTypes.arrayOf(groupType),

    // Action Creators
    approvePayroll: PropTypes.func.isRequired,
    approveContributionChanges: PropTypes.func.isRequired,
    rejectContributionChanges: PropTypes.func.isRequired,
    deleteEmployerLinks: PropTypes.func.isRequired,
    processPayrollForGroup: PropTypes.func.isRequired,
    flushErrors: PropTypes.func.isRequired,

    // component props
    allApproved: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isOneTimePayrollContribution: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    usersLeavingPlan: PropTypes.object,
    newSkipped: PropTypes.object,
    newRemovedFromPlan: PropTypes.object,
    newRejected: PropTypes.object,
    newApproved: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    reason: PropTypes.string,
    isNextPayPeriod: PropTypes.bool,
    payPeriodId: PropTypes.string,
    taxYear: PropTypes.number,
  };

  static defaultProps = {
    isOneTimePayrollContribution: false,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  _handleOnContinueClick = () => {
    this._updateUsersAndProcessPayroll();
  };

  _updateUsersAndProcessPayroll = () => {
    if (this.props.isOneTimePayrollContribution) {
      const payrollInput = {
        reason: this.props.reason,
        users: this.props.allApproved.map((c) =>
          pick(c, ["employeeId", "amount"])
        ),
        totalAmount: this._calculateTotal(),
        payPeriod: {
          start: this.props.startDate,
          end: this.props.endDate,
        },
        groupId: this.props.groupId,
        payPeriodId: this.props.payPeriodId,
        taxYear: this.props.taxYear,
      };

      this.props
        .processPayrollForGroup(this.props.client, payrollInput)
        .then(() => {
          if (!this.props.error) {
            toast.success("Successfully processed payroll.");
            this.props.push("/dashboard/contributions/one-time");
          }
        });
      return;
    }

    const promises = [];
    const payrollInput = this._buildPayrollInput();

    if (!isEmpty(this.props.newApproved)) {
      promises.push(
        this.props.approveContributionChanges(
          this.props.client,
          payrollInput.approvedContributions,
          this.props.groupId
        )
      );
    }
    if (!isEmpty(this.props.newRejected)) {
      promises.push(
        this.props.rejectContributionChanges(
          this.props.client,
          payrollInput.rejectedContributions,
          this.props.groupId
        )
      );
    }
    if (!isEmpty(this.props.newRemovedFromPlan)) {
      promises.push(
        this.props.deleteEmployerLinks(
          this.props.client,
          payrollInput.usersRemovedFromPlan
        )
      );
    }

    Promise.all(promises).then(() => {
      if (!this.props.error) {
        this.props.approvePayroll(this.props.client, payrollInput).then(() => {
          if (!this.props.error) {
            toast.success("Successfully processed payroll.");
            this.props.push("/dashboard/contributions");
          }
        });
      }
    });
  };

  _buildPayrollInput() {
    const allApproved = this.props.allApproved.map((approved) => {
      return pick(
        {
          ...approved,
          amount: this._getApprovedContributionAmount(approved),
        },
        ["employeeId", "amount"]
      );
    });
    const newSkipped = map(this.props.newSkipped, (skipped) => {
      return pick(
        {
          ...skipped,
          amount: skipped.userCurrentContributionAmount,
          skippedAmount: skipped.userNewContributionAmount,
        },
        ["employeeId", "amount", "skippedAmount"]
      );
    });
    const linkIds = map(this.props.newRemovedFromPlan, (removedUser) => {
      return (
        find(this.props.linkedEmployees, { employeeId: removedUser.id }) || {}
      ).id;
    });

    return {
      contributionsToProcess: allApproved,
      approvedContributions: map(this.props.newApproved, "id"),
      rejectedContributions: map(this.props.newRejected, (rej) => ({
        id: rej.id,
        comment: rej.comment,
      })),
      skippedContributions: newSkipped,
      usersRemovedFromPlan: linkIds,
      totalAmount: this._calculateTotal(),
      groupId: this.props.groupId,
      isNextPayPeriod: this.props.isNextPayPeriod,
      payPeriodId: this.props.payPeriodId,
      taxYear: this.props.taxYear,
    };
  }

  _commonDateFormat = () => {
    const commonDateFormat = "MMM Do YYYY";
    return commonDateFormat;
  };

  _getSummaryTable(newlyApprovedAmt) {
    const period = `${moment(this.props.startDate).format(
      this._commonDateFormat()
    )} to ${moment(this.props.endDate).format(this._commonDateFormat())}`;

    const reason = this.props.reason;

    return {
      newlyApprovedAmt,
      payPeriod: period,
      numberOfUsersLeaving: size(this.props.usersLeavingPlan),
      numberOfSkippedContributions: size(this.props.newSkipped),
      displayReason: reason,
    };
  }

  _getApprovedContributionAmount(approvedContribution) {
    return has(approvedContribution, "userNewContributionAmount")
      ? approvedContribution.userNewContributionAmount
      : approvedContribution.amount;
  }

  _getApprovedData = () => {
    return chain(this.props.allApproved)
      .map((approvedContribution) => {
        const matchingUserProfile =
          this.props.userProfiles[approvedContribution.employeeId] || {};
        // check skipped contribution amount
        const amount =
          this._getApprovedContributionAmount(approvedContribution);

        const first = matchingUserProfile.firstName;
        const last = matchingUserProfile.lastName;
        const fullName = first + " " + last;

        return {
          employeeName: fullName,
          lastFourOfSsn: matchingUserProfile.lastFourOfSsn,
          amount: formatCurrency(amount),
          id: approvedContribution.id,
        };
      })
      .value();
  };

  _buildApprovedColumns = () => {
    const columns = [
      {
        label: "Employee Name",
        key: "employeeName",
      },

      {
        label: "Last 4 SSN",
        key: "lastFourOfSsn",
      },
      {
        label: "Contribution Amount",
        key: "amount",
      },
    ];
    return columns;
  };

  _getApprovedTable() {
    const group =
      find(this.props.groups, (group) => group.id === this.props.groupId) || {};

    const columns = this._buildApprovedColumns();
    const data = this._getApprovedData();
    return (
      <>
        <div className="summary-table">
          <span className="summary-header">
            <p className="title">
              Contributions Being Processed:{" "}
              <p className="invite-number">({size(this.props.allApproved)})</p>
            </p>
          </span>

          <div>
            <PayrollStatementDownloader
              endDate={this.props.endDate}
              startDate={this.props.startDate}
              bankAccountNumber={this.props.bankAccountNumber}
              group={group}
              payrollInput={this._buildPayrollInput()}
              payrollType={"ADHOC"}
              totalAmount={this._calculateTotal()}
              reason={this.props.reason}
              text={"Download Contributions"}
            />
          </div>
        </div>

        <div className="contribution-table">
          <IconTable className="table" columns={columns} data={data} />
        </div>
      </>
    );
  }

  _calculateTotal() {
    const total = reduce(
      this.props.allApproved,
      (acc, contrib) => {
        const newAmount = has(contrib, "userNewContributionAmount")
          ? contrib.userNewContributionAmount
          : contrib.amount;

        return (acc += newAmount);
      },
      0
    );
    return formatAmount(total);
  }
  _subheader() {
    let newlyApprovedAmt = this._calculateTotal();
    return (
      <p>
        By clicking submit, you authorize Icon Financial Services to debit your
        account ending in {this.props.bankAccountNumber} the amount of{" "}
        {formatCurrency(newlyApprovedAmt)} on {this.props.processingDate}. If
        the processing date falls on a weekend, US federal holidays, or after
        5:30 PM Pacific, requests will be processed on the next business day.
      </p>
    );
  }

  _summary() {
    let newlyApprovedAmt = this._calculateTotal();

    return (
      <Container fluid className="summary-body">
        <Row>
          <Col>
            <Card.Subtitle className="summary-subtitle">Summary</Card.Subtitle>

            <InfoTable
              metadata={
                this.props.isOneTimePayrollContribution
                  ? oneTimeSummaryTable
                  : summaryTable
              }
              data={this._getSummaryTable(newlyApprovedAmt)}
            />
          </Col>
        </Row>

        <Row>
          <Col>{this._getApprovedTable()}</Col>
        </Row>
        <Row>
          <Col>
            {this.props.error && <Alert type="error" msg={this.props.error} />}
            <div className="button-row">
              <Button
                btnLabel="Go Back"
                name="cancel"
                color="cancel"
                onClick={() => {
                  this.props.flushErrors();
                  this.props.onCancel();
                }}
              />
              <Button
                btnLabel="Submit for Processing"
                name="submit"
                withArrow={true}
                loading={this.props.isUpdating}
                onClick={this._handleOnContinueClick}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const subheader = this._subheader();

    const content = this._summary();
    return (
      <>
        <EmployerCard
          title={"Payroll Summary"}
          description={subheader}
          content={content}
        />
      </>
    );
  }
}

const updatingActions = [
  employerConstants.APPROVE_CONTRIBUTION_CHANGES,
  employerConstants.REJECT_CONTRIBUTION_CHANGES,
  employerConstants.DELETE_EMPLOYER_LINKS,
  payrollConstants.APPROVE_PAYROLL,
  employerConstants.PROCESS_PAYROLL,
  employerConstants.GET_EMPLOYER_GROUPS,
];

const errorSelector = createErrorSelector(updatingActions);
const isUpdating = createLoadingSelector(updatingActions);

const mapStateToProps = (state) => {
  const { usersWhoLeftPlan } = state.payroll;

  return {
    groups: state.employer.groups,
    error: errorSelector(state),
    pendingContributionChanges: usersWithPendingContributionsSelector(state),
    usersWhoLeftPlan,
    contributionsWithNoChange: usersWithNoContributionChangeSelector(state),
    linkedEmployees: state.employer.linkedEmployees,
    isUpdating: isUpdating(state),
    userProfiles: userProfilesSelector(state),
    bankAccountNumber: get(activeBankSelector(state), "accountId", ""),
  };
};

const mapDispatchToProps = {
  push,
  approvePayroll,
  approveContributionChanges,
  rejectContributionChanges,
  deleteEmployerLinks,
  processPayrollForGroup,
  flushErrors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(PayrollSummary));
