import React from "react";
import PropTypes from "prop-types";

import { Formik } from "formik";
import { get, some } from "lodash";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import { userConstants } from "actions/types";
import { ScrollToFieldError } from "utils/form";
import { Card, Col, Form } from "react-bootstrap";
import { withApollo } from "@apollo/client/react/hoc";
import { createSupportRequest } from "actions/userActions";
import { isProcessingRolloverSelector } from "store/selectors/user";
import { createLoadingSelector, createErrorSelector } from "store/selectors";
import { accountTypesToEnglishMapping } from "statics/accountTypes";

import Alert from "components/Alert";
import Button from "components/Button";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import IndividualRolloverAccountType from "../../../signUp/individual/IndividualRolloverAccountType";
import InstantHelp from "components/InstantHelp";
import IndividualCancelRollover from "../../../signUp/individual/IndividualCancelRollover";

let yup = require("yup");

const schema = yup.object({
  subject: yup
    .string()
    .label("Subject")
    .required()
    .min(2, "Must be at least two characters."),
  comment: yup
    .string()
    .label("comment")
    .required()
    .min(2, "Must be at least two characters."),
});

class ZendeskSupportPage extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    createSupportRequest: PropTypes.func,
    name: PropTypes.string,
    email: PropTypes.string,
    error: PropTypes.string,
    pathname: PropTypes.string,
    userState: PropTypes.string,

    isSubmitting: PropTypes.bool,
    client: PropTypes.object,
    accounts: PropTypes.array,
    userHasTwoAccounts: PropTypes.bool,
    rolloverSupportFlag: PropTypes.bool,
    isProcessingRollover: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      showRolloverAccountTypeSelection: false,
      showCloseRolloverAccount: false,
    };
  }

  _submitRequest = (vals, { resetForm }) => {
    const requestParams = {
      comment: vals.comment,
      subject: vals.subject,
      name: this.props.name,
      email: this.props.email,
    };

    this.props
      .createSupportRequest(this.props.client, requestParams)
      .then(() => {
        if (!this.props.error) {
          toast.success("Support request submitted. You'll get an email.");
          resetForm();
        }
      });
  };

  _supportTicket = () => {
    var articleStyle = {
      paddingBottom: 0,
    };

    const list = [
      {
        index: 1,
        customComponent: () => (
          <p
            className="support-list"
            onClick={() => {
              this.setState({
                showRolloverAccountTypeSelection: true,
              });
            }}
          >
            Are you considering rolling over an account?
          </p>
        ),
      },
      {
        index: 2,
        hide: !this.props.isProcessingRollover,
        customComponent: () => (
          <p
            className="support-list"
            onClick={() => {
              this.setState({
                showCloseRolloverAccount: true,
              });
            }}
          >
            How do I cancel my rollover?
          </p>
        ),
      },
    ];

    return (
      <div className="transactions-mega-container">
        <section className="form-sec-2col">
          <article className="col-form" style={articleStyle}>
            <div>
              <Card>
                <Card.Title style={{ padding: 20, margin: 0 }}>
                  <IconHeader
                    variant="cardHeader"
                    headerText="How can we help you?"
                  />
                  <IconSubheader
                    subheader="Let us know how we can help and we will get back to you via
                email as soon as we can."
                  />
                </Card.Title>

                <Card.Body>
                  <Formik
                    validateOnChange={false}
                    validationSchema={schema}
                    onSubmit={this._submitRequest}
                    enableReinitialize={true}
                    initialValues={{ subject: "", comment: "" }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <ScrollToFieldError />

                        {this.props.accounts.map((account) => (
                          <Form.Row key={account.key}>
                            <Form.Group as={Col} controlId="formGroupComment">
                              <Form.Label>
                                {
                                  accountTypesToEnglishMapping[
                                    account.accountType
                                  ]
                                }
                                : {account.accountNumber}
                              </Form.Label>
                            </Form.Group>
                          </Form.Row>
                        ))}

                        <Form.Row>
                          <Form.Group as={Col} controlId="formGroupSubject">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                              name="subject"
                              value={values.subject}
                              onChange={handleChange}
                              isInvalid={!!errors.subject}
                              isValid={touched.subject && !errors.subject}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.subject}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formGroupComment">
                            <Form.Label>Message</Form.Label>

                            <Form.Control
                              name="comment"
                              as="textarea"
                              placeholder="Please include your account number in the request and any other information you think will be helpful."
                              value={values.comment}
                              onChange={handleChange}
                              isInvalid={!!errors.comment}
                              style={{ minHeight: "150px" }}
                              isValid={touched.comment && !errors.comment}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.comment}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        {this.props.error && (
                          <Alert type="error" msg={this.props.error} />
                        )}
                        <div className="submit-row btn-row">
                          <Button
                            btnLabel={"Cancel"}
                            color={"cancel"}
                            onClick={() => this.props.push("/dashboard")}
                          />
                          <Button
                            name="submit"
                            btnLabel="Submit"
                            withArrow={true}
                            loading={this.props.isSubmitting}
                          />
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </div>
          </article>
          {this.props.rolloverSupportFlag && (
            <InstantHelp title="Get instant help:" lists={list} />
          )}
        </section>
      </div>
    );
  };

  render() {
    let content;

    if (this.state.showRolloverAccountTypeSelection) {
      content = <IndividualRolloverAccountType />;
    } else if (this.state.showCloseRolloverAccount) {
      content = <IndividualCancelRollover />;
    } else {
      content = this._supportTicket();
    }

    return <>{content}</>;
  }
}

const actions = [userConstants.CREATE_REQUEST];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector([actions]);

const mapStateToProps = (state) => {
  const { firstName, lastName, email } = get(state, "user.profile", {});

  const accounts = state.accounts.iraAccounts;

  const userState = state.user.userState.state;
  const pathname = get(state.router, "location.pathname");
  const featureFlags = get(state.user, "featureFlags", []);
  const rolloverSupportFlag = some(featureFlags, {
    key: "RolloverSupport",
    value: "true",
  });

  return {
    email,
    name: `${firstName} ${lastName}`,

    userState,
    pathname,
    isProcessingRollover: isProcessingRolloverSelector(state),
    error: errorSelector(state),
    isSubmitting: loadingSelector(state),
    rolloverSupportFlag,
    accounts,
  };
};

const mapDispatchToProps = {
  push,
  createSupportRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ZendeskSupportPage));
