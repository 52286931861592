import { isNil } from "lodash";

class PlaidLinkTokenStorage {
  #getStorageKey(userId) {
    return `${userId}/plaid/token`;
  }

  setTokenContext = (userId, currentPath, token) => {
    const context = {
      currentPath,
      token,
    };
    localStorage.setItem(this.#getStorageKey(userId), JSON.stringify(context));
  };

  getTokenContext = (userId) => {
    const context = localStorage.getItem(this.#getStorageKey(userId));
    if (!isNil(context)) {
      return JSON.parse(context);
    }
    return context;
  };

  clearTokenContext = (userId) => {
    localStorage.removeItem(this.#getStorageKey(userId));
  };
}

export default new PlaidLinkTokenStorage();
