import React from "react";
import PropTypes from "prop-types";
import { Form, Col, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import * as yup from "yup";
import { isEmpty } from "lodash";

import { acceptInvitation } from "actions/userActions";
import { createLoadingSelector, createErrorSelector } from "store/selectors";
import { userConstants } from "actions/types";

import Button from "components/Button";
import Alert from "components/Alert";
import {
  invitationCodeError,
  invitationCodeParser,
  noWhitespaceRegex,
  validIconInvite,
  whiteSpaceError,
} from "../../../../utils/fieldValidators";

const schema = yup.object({
  invitationCode: yup
    .string()
    .label("Invitation code")
    .required()
    .min(7)
    .max(10)
    .matches(noWhitespaceRegex, whiteSpaceError)
    .matches(validIconInvite, invitationCodeError),
});

class AcceptInvite extends React.PureComponent {
  static propTypes = {
    acceptInvitation: PropTypes.func,
    setEmployerLink: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      invitationCode: "",
      invitationCodeError: "",
    };
  }

  _linkEmployer = () => {
    this.props
      .acceptInvitation(this.props.client, this.state.invitationCode)
      .then((res) => {
        if (!this.props.error) {
          this.props.setEmployerLink(res);
        }
      });
  };

  render() {
    return (
      <>
        <Modal.Header closeButton>
          <h3 className="modal-title">Employer Plan</h3>
          <p className="modal-subtitle">
            In order to link with your employer please provide your Plan ID or
            invitation code you should have received in an email from your
            employer.
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="invitation-code-wrapper">
            <h5>Employee</h5>

            <Form onSubmit={(e) => e.preventDefault()}>
              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label> Plan ID</Form.Label>
                  <Form.Control
                    name="invitationCode"
                    placeholder=""
                    value={this.state.invitationCode}
                    isInvalid={!isEmpty(this.state.invitationCodeError)}
                    isValid={isEmpty(this.state.invitationCodeError)}
                    onBlur={(e) => {
                      try {
                        schema.validateSync({
                          invitationCode: e.target.value,
                        });
                      } catch (ex) {
                        this.setState({
                          invitationCodeError: ex.message,
                        });
                      }
                    }}
                    onChange={(e) => {
                      const invitationCode = invitationCodeParser(e);
                      this.setState({
                        invitationCode,
                        invitationCodeError: "",
                      });
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {this.state.invitationCodeError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
            </Form>
          </div>
          {this.props.error && <Alert type="error" msg={this.props.error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            name="submit"
            withArrow={true}
            btnLabel="Continue"
            disabled={!isEmpty(this.state.invitationCodeError)}
            onClick={this._linkEmployer}
            loading={this.props.isFetching}
          />
        </Modal.Footer>
      </>
    );
  }
}

const actions = [
  userConstants.USER_EMPLOYER_LINK,
  userConstants.USER_ACCEPT_INVITATION,
];

const isFetchingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    isFetching: isFetchingSelector(state),
    error: errorSelector(state),
    linkedEmployers: state.user.linkedEmployers,
  };
};

const mapDispatchToProps = {
  acceptInvitation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AcceptInvite));
