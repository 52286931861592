import env from "@beam-australia/react-env";

export const isProduction = () => {
  const domain = env("AUTH0_DOMAIN");

  switch (domain) {
    case "employer-login.iconsavingsplan.com":
    case "login.iconsavingsplan.com":
    case "admin-login.iconsavingsplan.com":
    case "login-pre-production.iconsavingsplan.com":
      return true;
    default:
      return false;
  }
};

export const determineSite = () => {
  const domain = env("AUTH0_DOMAIN");
  const audience = env("AUTH0_AUDIENCE");
  // Pre prod shares a single tenant for all three apps, so need to check audience for target app
  if (domain === "login-pre-production.iconsavingsplan.com") {
    switch (audience) {
      case "private-admin-pre.herokuapp.com/":
        return "admin";
      case "private-frontend-emp-pre.herokuapp.com/":
        return "employer";
      case "private-frontend-pre.herokuapp.com/":
        return "individual";
    }
  }

  switch (domain) {
    case "employer-login-staging.iconsavingsplan.com":
    case "employer-login.iconsavingsplan.com":
      return "employer";
    case "admin-login-staging.iconsavingsplan.com":
    case "admin-login.iconsavingsplan.com":
      return "admin";
    case "login-staging.iconsavingsplan.com":
    case "login.iconsavingsplan.com":
      return "individual";
  }
};

export const individualSite = () => {
  return determineSite() === "individual";
};

export const employerSite = () => {
  return determineSite() === "employer";
};

export const adminSite = () => {
  return determineSite() === "admin";
};

export const productionEmployerOrigin = "https://employer.iconsavingsplan.com";
export const stagingEmployerOrigin =
  "https://private-frontend-emp-stage.herokuapp.com/";
