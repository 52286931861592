export const TRADITIONAL_IRA = "TRADITIONAL_IRA";
export const ROTH_IRA = "ROTH_IRA";
export const OVERVIEW = "OVERVIEW";
export const MIXED = "MIXED";

export const accountTypesToEnglishMapping = {
  [TRADITIONAL_IRA]: "Traditional IRA",
  [ROTH_IRA]: "Roth IRA",
  [OVERVIEW]: "Overview",
  [MIXED]: "Both",
};

export const PREMATURE = "PREMATURE";
export const NORMAL = "NORMAL";
export const TRANSFER_TO_IDENTICAL_IRA = "TRANSFER_TO_IDENTICAL_IRA";
export const DIRECT_ROLLOVER_TO_EMPLOYER_PLAN =
  "DIRECT_ROLLOVER_TO_EMPLOYER_PLAN";
export const OTHER = "OTHER";

export const distributionTypesToEnglishMapping = {
  [PREMATURE]: "Premature Withdrawal",
  [NORMAL]: "Normal Withdrawal",
  [TRANSFER_TO_IDENTICAL_IRA]: "Transfer to Identical IRA",
  [DIRECT_ROLLOVER_TO_EMPLOYER_PLAN]: "Direct Rollover to Employer Plan",
  [OTHER]:
    "Other or I am not sure (Revocation, Excess Contribution, or similar)",
};
