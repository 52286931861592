import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Col, Modal } from "react-bootstrap";
import { Formik } from "formik";
import { get } from "lodash";

import Alert from "components/Alert";
import Button from "components/Button";

import { flushErrors } from "actions/errorActions";
import { createLoadingSelector } from "store/selectors";
import { createErrorSelector } from "store/selectors";
import { createOrganizationForCompany } from "actions/adminActions";
import { toast } from "react-toastify";
import { adminConstants } from "actions/types";
import { withApollo } from "@apollo/client/react/hoc";
const yup = require("yup");

const schema = yup.object({
  name: yup
    .string()
    .label("Organization Name")
    .required()
    .min(2, "Must be at least two characters.")
    .max(100),
});

class AddOrgModal extends React.PureComponent {
  static propTypes = {
    flushErrors: PropTypes.func,
    onClose: PropTypes.func,
    isFetching: PropTypes.bool,
    error: PropTypes.string,
    companyId: PropTypes.string,
    createOrganizationForCompany: PropTypes.func,
    client: PropTypes.object,
    onSuccess: PropTypes.func,
  };

  _onClose = () => {
    this.props.flushErrors();
    this.props.onClose();
  };

  _submitOrganization = async ({ name }) => {
    const res = await this.props.createOrganizationForCompany(
      this.props.client,
      {
        companyId: this.props.companyId,
        name,
      }
    );
    if (!this.props.error) {
      const organization = get(res, "data.organization");
      toast.success("Successfully created organization.");
      this.props.onSuccess(organization);
    }
  };

  render() {
    return (
      <Formik
        validateOnChange={false}
        validationSchema={schema}
        onSubmit={(values) => {
          this._submitOrganization(values);
        }}
        enableReinitialize={true}
        initialValues={{
          name: "",
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal
              centered
              scrollable
              show={true}
              onHide={this._onClose}
              className="icon-modal"
            >
              <Modal.Header closeButton>Create Organization</Modal.Header>

              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGroupName">
                    <Form.Label>
                      What name do you want to give the organization?
                    </Form.Label>
                    <Form.Control
                      name="name"
                      placeholder="Organization Name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                      isValid={touched.name && !errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {this.props.error && (
                  <Alert type="error" msg={this.props.error} />
                )}
              </Modal.Body>
              <Modal.Footer>
                <div className="submit-row">
                  <Button
                    btnLabel="Create Organization"
                    name="submit"
                    withArrow={true}
                    loading={this.props.isFetching}
                    onClick={() => handleSubmit(values)}
                  />
                  <Button
                    btnLabel="Cancel"
                    color="cancel"
                    name="cancel"
                    type="button"
                    onClick={this.props.onClose}
                  />
                </div>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    );
  }
}

const orgEvent = adminConstants.CREATE_ORGANIZATION;

const loadingSelector = createLoadingSelector(orgEvent);
const errorSelector = createErrorSelector(orgEvent);

const mapStateToProps = (state) => {
  return {
    isFetching: loadingSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  flushErrors,
  createOrganizationForCompany,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddOrgModal));
