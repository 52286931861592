import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";

export const NavBrand = () => {
  return (
    <div className="nav-left">
      <Navbar.Brand>
        <Link to="/dashboard">
          <img alt="Icon" src="/assets/logo.svg" />
        </Link>
      </Navbar.Brand>
    </div>
  );
};

export default NavBrand;
