import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { get, isEmpty, isNil, noop } from "lodash";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { FiDollarSign } from "react-icons/fi";
import { createLoadingSelector } from "store/selectors";
import { bankAccountType } from "statics/propTypes";
import { toast } from "react-toastify";
import { isEmployerSelector } from "store/selectors/user";
import {
  linkBank,
  linkBankIndividual,
  verifyBankAccount,
} from "actions/bankActions";
import {
  accountConstants,
  bankConstants,
  employerConstants,
} from "actions/types";
import {
  activeBanksSelector,
  bankSelector,
  isPendingAutoBankVerification,
  isPendingManualBankVerification,
} from "store/selectors/bank";

import ActionCard from "components/ActionCard";
import PlaidLink from "components/PlaidLink";

import "./AddBankCard.scss";

const AddBankCardWithPlaid = ({
  plaidInstance = {},
  hasBankAccount = false,
  isAddingBank = false,
  isEmployer = false,
  isPendingAutoBankVerification = false,
  isPendingManualBankVerification = false,
}) => {
  const cardWrapperClass = classnames("add-bank-card", {
    "action-card": !hasBankAccount && !isAddingBank,
    "completed-card": hasBankAccount,
  });

  const cardTitle = () => {
    if (isPendingAutoBankVerification || isPendingManualBankVerification) {
      return "Bank Account is Being Verified";
    }
    return isEmployer ? "Link Company Bank Account" : "Link Your Bank Account";
  };

  const cardDescription = () => {
    if (isPendingManualBankVerification) {
      return "Verify the two small deposits in your account after one to two business days.";
    } else if (isPendingAutoBankVerification) {
      return "Your bank account will be automatically verified in one to two business days.";
    }
    return isEmployer
      ? "Link your company bank account to fund plan payroll contributions."
      : "Link your bank account to start making contributions.";
  };

  const cardAction = () => {
    if (isPendingManualBankVerification) {
      return "Verify";
    }
    return "Link Bank";
  };

  return (
    <div className={cardWrapperClass}>
      <ActionCard
        onClick={plaidInstance.handleOnClick || noop}
        icon={<FiDollarSign color="white" stroke="#60A4BF" size={16} />}
        title={cardTitle()}
        description={cardDescription()}
        actionText={cardAction()}
        completedAction={hasBankAccount || isPendingAutoBankVerification}
        loading={isAddingBank}
      />
    </div>
  );
};

AddBankCardWithPlaid.propTypes = {
  plaidInstance: PropTypes.object,
  hasBankAccount: PropTypes.bool,
  isAddingBank: PropTypes.bool,
  isEmployer: PropTypes.bool,
  isPendingAutoBankVerification: PropTypes.bool,
  isPendingManualBankVerification: PropTypes.bool,
};

class AddBankCard extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    companyId: PropTypes.string,
    linkBankIndividual: PropTypes.func,
    linkBank: PropTypes.func,
    bankAccount: bankAccountType,
    isAddingBank: PropTypes.bool,
    isEmployer: PropTypes.bool,
    hasVerifiedBankAccount: PropTypes.bool,
    verifyBankAccount: PropTypes.func,
    onSuccess: PropTypes.func,
    insertOnlyMode: PropTypes.bool,
  };

  static defaultProps = {
    insertOnlyMode: false,
    onSuccess: noop,
  };

  _handleOnSuccess = (token, metadata, ownerName) => {
    if (isPendingManualBankVerification(this.props.bankAccount)) {
      this.props.verifyBankAccount(
        this.props.client,
        this.props.bankAccount.id
      );
      return;
    }
    if (this.props.isEmployer) {
      this.props.linkBank(this.props.client, token, metadata).then((data) => {
        if (data && isNil(data.error)) {
          toast.success("Successfully linked bank account.");
          this.props.onSuccess(data);
        }
      });
    } else {
      this.props
        .linkBankIndividual(this.props.client, token, metadata, ownerName)
        .then((data) => {
          if (data && isNil(data.error)) {
            toast.success("Successfully linked bank account.");
            this.props.onSuccess(data);
          }
        });
    }
  };

  _noLinkedBankRender = (plaidInstance) => {
    return (
      <AddBankCardWithPlaid
        plaidInstance={plaidInstance}
        isAddingBank={this.props.isAddingBank}
        isEmployer={this.props.isEmployer}
        isPendingManualBankVerification={isPendingManualBankVerification(
          this.props.bankAccount
        )}
      />
    );
  };

  render() {
    if (
      isEmpty(this.props.bankAccount) ||
      isPendingManualBankVerification(this.props.bankAccount)
    ) {
      return (
        <PlaidLink
          isAddingBank={this.props.isAddingBank}
          onSuccess={this._handleOnSuccess}
          render={this._noLinkedBankRender}
          bank={this.props.bankAccount}
        />
      );
    }

    return (
      <AddBankCardWithPlaid
        hasBankAccount={this.props.hasVerifiedBankAccount}
        isPendingAutoBankVerification={isPendingAutoBankVerification(
          this.props.bankAccount
        )}
        isAddingBank={this.props.isAddingBank}
        isEmployer={this.props.isEmployer}
      />
    );
  }
}

const loadingSelector = createLoadingSelector([
  bankConstants.LINK_BANK_WITH_ICON,
  bankConstants.GET_PLAID_LINK_TOKEN,
  accountConstants.GET_ACCOUNTS,
  employerConstants.GET_EMPLOYER_ACCOUNTS,
]);

const mapStateToProps = (state, ownProps) => {
  const bankAccount = getBankAccount(state, ownProps);
  return {
    isEmployer: isEmployerSelector(state),
    hasVerifiedBankAccount: activeBanksSelector(state).length > 0,
    isAddingBank: loadingSelector(state),
    companyId: get(state.employer, "company.id", null),
    bankAccount,
  };
};

const getBankAccount = (state, props) => {
  /*
    This is necessary for multi-bank support for employers. Since we are displaying multiple add bank cards now,
    we need to pass in bank account from a list as a prop so we can render the correct state. This also maintains
    backwards compatibility for individuals by fetching whatever bank account exists in redux if none is passed as a prop
  */
  if (props.insertOnlyMode) {
    return {};
  }
  if (isEmpty(props.bankAccount)) {
    return bankSelector(state);
  }
  return props.bankAccount;
};

const mapDispatchToProps = {
  linkBankIndividual,
  linkBank,
  verifyBankAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddBankCard));
