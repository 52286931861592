import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Modal } from "react-bootstrap";

import IconSpinner from "components/IconSpinner";
import { keyBy, get, some } from "lodash";
import { flushErrors } from "actions/errorActions";
import { employerConstants } from "actions/types";
import { createLoadingSelector } from "store/selectors";
import { createErrorSelector } from "store/selectors";
import { toast } from "react-toastify";
import { withApollo } from "@apollo/client/react/hoc";
import {
  deleteEmployerGroup,
  getAllEmployerGroups,
  currentPayrolls,
} from "actions/employerActions";

import Alert from "components/Alert";
import Button from "components/Button";
import IconSubheader from "components/IconSubheader";
import IconHeader from "components/IconHeader";

class RemoveGroup extends React.PureComponent {
  static propTypes = {
    flushErrors: PropTypes.func,
    onClose: PropTypes.func,
    isFetching: PropTypes.bool,
    onSubmit: PropTypes.func,
    client: PropTypes.object,
    onSuccess: PropTypes.func,
    groupData: PropTypes.arrayOf(PropTypes.object),
    payrolls: PropTypes.arrayOf(PropTypes.object),
    deleteEmployerGroup: PropTypes.func,
    getAllEmployerGroups: PropTypes.func,
    currentPayrolls: PropTypes.func,
    loading: PropTypes.bool,
    isDeleting: PropTypes.bool,
    error: PropTypes.string,
  };

  constructor() {
    super();

    this.state = {
      groupHasProcessingPayrolls: false,
      groupHasApprovedPayrolls: false,
    };
  }

  async componentDidMount() {
    await this.props.currentPayrolls(this.props.client);
    const approved = this.props.payrolls.filter(
      (payroll) => payroll.status === "APPROVED"
    );
    const processing = this.props.payrolls.filter(
      (payroll) => payroll.status === "PROCESSING"
    );
    const groupId = get(this.props.groupData, "id");
    this.setState({
      groupHasProcessingPayrolls: some(processing, { groupId }),
      groupHasApprovedPayrolls: some(approved, { groupId }),
    });
  }

  _onClose = () => {
    this.props.flushErrors();
    this.props.onClose();
  };

  _removeGroup = async () => {
    const id = get(this.props.groupData, "id");
    await this.props.deleteEmployerGroup(this.props.client, id);
    if (!this.props.error) {
      this.props.onSuccess();
      toast.success("Successfully removed group.");
      this.props.onClose();
    } else {
      toast.error("There was a problem removing the group.");
    }
  };

  render() {
    const id = get(this.props.groupData, "id");
    const groupName = get(this.props.groupData, "name");
    if (this.props.isFetching) {
      return <IconSpinner centered />;
    }

    return (
      <div>
        <Modal
          centered
          scrollable
          show={true}
          onHide={this._onClose}
          className="dismissed-payroll"
        >
          <Modal.Header className="dismissed-payroll-header">
            <div className="payroll-header">
              <IconHeader variant="cardHeader" headerText={"Remove Group"} />
              {this.state.groupHasApprovedPayrolls &&
                !this.state.groupHasProcessingPayrolls && (
                  <IconSubheader subheader="Are you sure you want to remove the following group?" />
                )}
            </div>
          </Modal.Header>

          <Modal.Body>
            <Form.Row>
              <Form.Group>
                {!this.state.groupHasApprovedPayrolls &&
                  !this.state.groupHasProcessingPayrolls && (
                    <div className="dismiss-payroll-info" key={id}>
                      <span className="payroll-label">
                        <p>Group</p>
                      </span>
                      <span>
                        <p>{groupName}</p>
                      </span>
                    </div>
                  )}
                {this.state.groupHasProcessingPayrolls && (
                  <div className="dismiss-payroll-info">
                    <span>
                      <p>
                        {"There are currently payroll contributions processing and you are unable to remove the group." +
                          " Come back in 1 business day, after contributions have processed, to remove the group."}
                      </p>
                    </span>
                  </div>
                )}
                {this.state.groupHasApprovedPayrolls &&
                  !this.state.groupHasProcessingPayrolls && (
                    <div className="dismiss-payroll-info">
                      <span>
                        <p>
                          {"There are currently payroll contributions approved for processing. By continuing and removing this group," +
                            " the payroll contributions will be un-approved. Would you like to continue?"}
                        </p>
                      </span>
                    </div>
                  )}
              </Form.Group>
            </Form.Row>

            {this.props.error && <Alert type="error" msg={this.props.error} />}
          </Modal.Body>
          <Modal.Footer>
            <div className="dismiss-actions">
              <span>
                <Button
                  className="table-btn"
                  name="cancel"
                  color="dismiss"
                  size="sm"
                  btnLabel="Cancel"
                  type="button"
                  onClick={this.props.onClose}
                />
              </span>
              {!this.state.groupHasProcessingPayrolls && (
                <span style={{ paddingLeft: 3 }}>
                  <Button
                    className="table-btn"
                    color="red"
                    size="sm"
                    btnLabel="Remove"
                    name="submit"
                    withArrow={true}
                    loading={this.props.isDeleting}
                    disabled={this.props.isDeleting}
                    onClick={() => this._removeGroup(id)}
                  />
                </span>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const actions = [
  employerConstants.DELETE_EMPLOYER_GROUPS,
  employerConstants.GET_EMPLOYER_GROUPS,
  employerConstants.CURRENT_PAYROLLS,
];

const errorSelector = createErrorSelector(actions);
const isDeleting = createLoadingSelector(actions);
const isLoading = createLoadingSelector(employerConstants.CURRENT_PAYROLLS);

const mapStateToProps = (state) => {
  const groups = state.employer.groups;
  const groupsById = keyBy(state.employer.groups, "id");

  return {
    groupsById,
    groups,
    isDeleting: isDeleting(state),
    error: errorSelector(state),
    isFetching: isLoading(state),
    payrolls: state.employer.currentPayrolls,
  };
};

const mapDispatchToProps = {
  getAllEmployerGroups,
  deleteEmployerGroup,
  flushErrors,
  currentPayrolls,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(RemoveGroup));
