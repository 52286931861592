import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { OverlayTrigger, Popover } from "react-bootstrap";
import "./ActionsMenu.scss";

function buildPopover(actions) {
  return (
    <Popover className="action-list-popover">
      <Popover.Content>
        <ul className="actions-list">
          {map(actions, (action) => {
            return (
              <li
                key={action.label}
                className="action-item"
                onClick={() => {
                  action.onClick();
                  // close overlay manually
                  document.body.click();
                }}
              >
                {action.icon && (
                  <span className="action-icon">{action.icon}</span>
                )}
                <p>{action.label}</p>
              </li>
            );
          })}
        </ul>
      </Popover.Content>
    </Popover>
  );
}

const ActionsMenu = ({ actions = [], children }) => {
  const popover = buildPopover(actions);
  return (
    <div className="actions-menu">
      <OverlayTrigger
        rootClose
        trigger="click"
        placement="bottom"
        overlay={popover}
      >
        {children}
      </OverlayTrigger>
    </div>
  );
};

ActionsMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.node,
    })
  ),
  children: PropTypes.node,
};

export default ActionsMenu;
