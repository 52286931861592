import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { isEmpty } from "lodash";
import { FiGlobe, FiGrid } from "react-icons/fi";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import IconTable from "components/IconTable";
import AddOrgModal from "./AddOrgModal";
import Button from "components/Button";
import { getOrgForCompany } from "services/adminService";
import { Link } from "react-router-dom";
import IconSpinner from "components/IconSpinner";
import ChangeOrgLimitModal from "pages/dashboards/adminDashboard/employers/ChangeOrgLimitModal";

class Organization extends React.PureComponent {
  static propTypes = {
    companyId: PropTypes.string,
    client: PropTypes.object,
  };

  constructor() {
    super();

    this.state = {
      showOrgModal: false,
      initialFetching: true,
      showChangeLimitModal: false,
    };
  }

  async componentDidMount() {
    const organization = await getOrgForCompany(
      this.props.client,
      this.props.companyId
    );

    this.setState({
      initialFetching: false,
      organization,
    });
  }

  _buildCompanyColumns = () => {
    const columns = [
      {
        label: "Company Name",
        key: "name",
      },
      {
        label: "Company ID",
        key: "id",
      },
      {
        label: "Company DBA",
        key: "dba",
      },
      {
        label: "Company EIN",
        key: "ein",
      },
      {
        label: "Company State",
        key: "currentState",
      },
      {
        customComponent: (props) => {
          if (props.id === this.props.companyId) {
            return null;
          }
          return (
            <div>
              <Link to={`/employers/${props.id}`} className="icon-link">
                View Company
              </Link>
            </div>
          );
        },
      },
    ];
    return columns;
  };

  _getOrganizationContent = () => {
    return (
      <>
        <div className="plan-table">
          <div className="plan-header">
            <div className="plan-avatar">
              <p className="plan-initials">
                <FiGrid
                  color="white"
                  stroke="#60A4BF"
                  stroke-width="1.5"
                  size={26}
                />
              </p>
            </div>
            <div className="plan-details">
              <p className="plan-name">{this.state.organization.name}</p>
              <p className="detail">
                Organization Id: {this.state.organization.id}
              </p>
              <p className="detail">
                Company Limit: {this.state.organization.companyLimit}
                <span
                  className="icon-link"
                  style={{
                    marginLeft: 8,
                  }}
                  onClick={() => this.setState({ showChangeLimitModal: true })}
                >
                  change limit
                </span>
              </p>
            </div>
          </div>
        </div>
        <IconTable
          columns={this._buildCompanyColumns()}
          data={this.state.organization.linkedCompanies}
        />
      </>
    );
  };

  _getContent = () => {
    const data = this.state.organization;

    if (!isEmpty(data)) {
      return this._getOrganizationContent();
    }

    return (
      <div className="action-box">
        <div className="employerInfo">
          <div className="employerInfo-body">
            <div>
              <div className="circle">
                <span className="icon">
                  <FiGlobe color="white" stroke="#60A4BF" size={16} />
                </span>
              </div>
            </div>
            <div className="employerInfo-intro">
              <IconHeader variant="labelHeader" headerText="Organization" />
              <IconSubheader subheader="Create a new organization" />
            </div>
          </div>
          <div className="employerInfo-action">
            <Button
              size="sm"
              name="action"
              btnLabel="Create Organization"
              onClick={() => this.setState({ showOrgModal: true })}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const content = this._getContent();

    if (this.state.initialFetching) return <IconSpinner centered />;

    return (
      <div className="organization-container">
        {this.state.showOrgModal && (
          <AddOrgModal
            onClose={() => this.setState({ showOrgModal: false })}
            companyId={this.props.companyId}
            onSuccess={(org) => {
              this.setState({ showOrgModal: false, organization: org });
            }}
          />
        )}
        {this.state.showChangeLimitModal && (
          <ChangeOrgLimitModal
            onClose={() => this.setState({ showChangeLimitModal: false })}
            organizationId={this.state.organization.id}
            companyLimit={this.state.organization.companyLimit}
            onSuccess={(companyLimit) => {
              this.setState({
                showChangeLimitModal: false,
                organization: {
                  ...this.state.organization,
                  companyLimit,
                },
              });
            }}
          />
        )}
        {content}
      </div>
    );
  }
}

export default connect()(withApollo(Organization));
