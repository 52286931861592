import { groupCreationConstants } from "./types";

export const groupCreationShow = () => {
  return (dispatch) => {
    dispatch({ type: groupCreationConstants.GROUP_CREATION_SHOW });
  };
};

export const groupCreationHide = () => {
  return (dispatch) => {
    dispatch({ type: groupCreationConstants.GROUP_CREATION_HIDE });
  };
};
