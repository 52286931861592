import { FiMenu, FiX } from "react-icons/fi";
import React from "react";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { sidebarHide, sidebarShow } from "actions/sidebarActions";
import PropTypes from "prop-types";

const SideBarToggleButton = ({ showSidebar, sidebarHide, sidebarShow }) => {
  return (
    <div className="portfolio-sm-screen">
      <div className="responsive-menu">
        {!showSidebar && (
          <span className="menu-icon">
            <FiMenu
              stroke={"#FFFFFF"}
              size={24}
              strokeWidth={"1"}
              onClick={() => {
                sidebarShow();
              }}
            />
          </span>
        )}

        {showSidebar && (
          <span
            className="close-x-icon"
            onClick={() => {
              sidebarHide();
            }}
          >
            <FiX size={24} strokeWidth={"1.5"} />
          </span>
        )}
      </div>
    </div>
  );
};

SideBarToggleButton.propTypes = {
  showSidebar: PropTypes.bool,
  sidebarHide: PropTypes.func,
  sidebarShow: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    showSidebar: state.sidebar.showSidebar,
  };
};

const mapDispatchToProps = {
  sidebarShow,
  sidebarHide,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(SideBarToggleButton));
