import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { map, orderBy, some, isEmpty, get, size } from "lodash";
import { Card } from "react-bootstrap";
import { transactionType } from "statics/propTypes";
import { paymentStatusToEnglish } from "statics/statusToEnglishMapping";
import { FiList } from "react-icons/fi";

import moment from "moment";
import IconTable from "components/IconTable";
import IconEmptyState from "components/IconEmptyState";
import Button from "components/Button";
import ConfirmAchCancellationModal from "../dashboard/ConfirmAchCancellationModal";
import IconTableHeader from "components/IconTableHeader";

import "./Transactions.scss";

const achDepositCategory = "IRA_CONTRIBUTION_ADHOC";

export class TransactionsTable extends React.PureComponent {
  static propTypes = {
    transactions: PropTypes.arrayOf(transactionType),
  };

  constructor(props) {
    super(props);

    this.state = {
      showConfirmationModal: false,
      pendingCancellationId: null,
    };
  }
  _getTransactionData = (containsPendingAch) => {
    return map(this.props.transactions, (transaction) => {
      const createdAt = moment(transaction.createdAt).format("MMM Do YYYY");
      let action = null;
      const cancellationPending =
        transaction.cancellationStatus === "SUBMITTED";
      if (
        containsPendingAch &&
        transaction.status === "PENDING" &&
        !cancellationPending &&
        transaction.subCategory === achDepositCategory
      ) {
        action = (
          <>
            <Button
              btnLabel="cancel"
              name="cancel"
              color="red"
              type="button"
              onClick={() => this._openConfirmModal(transaction.id)}
            />
          </>
        );
      }
      let status =
        paymentStatusToEnglish[transaction.status] || transaction.status;
      status = cancellationPending ? "Cancellation pending" : status;

      const taxYear = get(transaction, "taxYear");

      const pendingActionACH = containsPendingAch ? action : null;

      return {
        createdAt,
        status,
        taxYear,
        pendingActionACH,
        memo: transaction.memo,
      };
    });
  };

  _buildTransactionColumns = () => {
    const containsPendingAch = this._transactionsContainPendingAchDeposit();
    const columns = [
      {
        label: "Transaction Date",
        key: "createdAt",
      },

      {
        label: "Description",
        key: "memo",
      },
      {
        label: "Status",
        key: "status",
      },
      {
        label: "Tax Year",
        key: "taxYear",
      },
      {
        customComponent: (props) => {
          return (
            <>
              {containsPendingAch && (
                <span style={{ width: 125 }}>{props.pendingActionACH}</span>
              )}
            </>
          );
        },
      },
    ];
    return columns;
  };

  _openConfirmModal = (pendingCancellationId) => {
    this.setState({
      pendingCancellationId,
      showConfirmationModal: true,
    });
  };

  _closeConfirmModal = () => {
    this.setState({
      showConfirmationModal: false,
      pendingCancellationId: null,
    });
  };

  _transactionsContainPendingAchDeposit = () => {
    return some(
      this.props.transactions,
      (t) =>
        t.subCategory === achDepositCategory &&
        t.status === "PENDING" &&
        t.cancellationStatus !== "SUBMITTED"
    );
  };

  _blankSlateBody() {
    return (
      <IconEmptyState
        header="There are no transactions at this time."
        subheader="This usually means you have
          not made a contribution yet through your employer or bank account."
        icon={<FiList color="white" stroke="#60A4BF" size={16} />}
      />
    );
  }

  render() {
    const containsPendingAch = this._transactionsContainPendingAchDeposit();

    const columns = this._buildTransactionColumns();
    const data = this._getTransactionData(containsPendingAch);

    let body;

    if (isEmpty(data)) {
      body = this._blankSlateBody();
    } else {
      body = (
        <>
          {this.state.showConfirmationModal && (
            <ConfirmAchCancellationModal
              onClose={this._closeConfirmModal}
              transactionId={this.state.pendingCancellationId}
            />
          )}
          <IconTableHeader
            tableHeader="Recent Transactions"
            tableCount={size(this.props.transactions)}
          />
          <IconTable columns={columns} data={data} />
        </>
      );
    }

    return <Card className="transaction-table">{body}</Card>;
  }
}

const mapStateToProps = (state) => {
  const descendingTransactions = orderBy(
    state.transactions.transactions,
    "createdAt",
    "desc"
  );
  return {
    transactions: descendingTransactions,
  };
};

export default connect(mapStateToProps, null)(TransactionsTable);
