import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { FiUsers } from "react-icons/fi";
import { withApollo } from "@apollo/client/react/hoc";
import { get } from "lodash";
import { userType } from "statics/propTypes";
import {
  hasAddedDeductionCode,
  hasAddedGroups,
  hasAnnouncedIcon,
  hasEnabled360PayrollIntegration,
  hasEnabledFinchFeature,
  hasEnabledPayrollIntegration,
  hasInvitedEmployees,
  hasLaunchedPlan,
  hasStatePlanSelector,
  hasSuccessfullyConnectedPayroll,
} from "store/selectors/employer";

import Accordian from "./Accordian";

const nonPayrollIntegrationSteps = [
  {
    index: 1,
    label: "Announce Plan",
  },
  {
    index: 2,
    label: "Invite Employees",
  },
  {
    index: 3,
    label: "Launch Plan",
  },
];

const payrollIntegrationSteps = [
  {
    index: 1,
    label: "Announce Plan",
  },

  {
    index: 3,
    label: "Launch Plan",
  },
];

class AddUsersCard extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    client: PropTypes.object,
    hasInvitedAUser: PropTypes.bool,
    hasStatePlan: PropTypes.bool,
    hasEnabledFinch: PropTypes.bool,
    hasPayrollIntegration: PropTypes.bool,
    user: userType,
    hasAnnouncedIcon: PropTypes.bool,
    hasInvitedEmployees: PropTypes.bool,
    has360PayrollIntegration: PropTypes.bool,
    finchHasSynced: PropTypes.bool,
    hasLaunchedPlan: PropTypes.bool,
    img: PropTypes.func,
    steps: PropTypes.string,
    onStepChange: PropTypes.func,
    activeStepNumber: PropTypes.number,
    hasAddedGroups: PropTypes.bool,
    hasAddedDeductionCode: PropTypes.bool,
    hasSuccessfullyConnectedPayroll: PropTypes.bool,
  };

  _label = () => {
    let label;
    if (
      this.props.hasAddedGroups &&
      this.props.hasAddedDeductionCode &&
      !this.props.hasAnnouncedIcon
    ) {
      label = "Announce Plan";
    } else if (
      this.props.hasAddedGroups &&
      this.props.hasAddedDeductionCode &&
      this.props.hasAnnouncedIcon &&
      !this.props.hasInvitedEmployees
    ) {
      label = "Create List";
    } else if (
      this.props.hasInvitedEmployees &&
      this.props.hasAddedDeductionCode &&
      !this.props.hasLaunchedPlan
    ) {
      label = "Launch Plan";
    }
    return label;
  };

  render() {
    const label = this._label();

    const steps =
      this.props.hasPayrollIntegration || this.props.hasEnabledFinch
        ? payrollIntegrationSteps
        : nonPayrollIntegrationSteps;

    const waitingForRosterApproval =
      this.props.has360PayrollIntegration &&
      !this.props.hasSuccessfullyConnectedPayroll;
    return (
      <Accordian
        onClick={this.props.onStepChange}
        icon={<FiUsers color="white" stroke="#60A4BF" size={16} />}
        title={"Invite Employees"}
        description={"Add employee emails and invite them to enroll."}
        actionText={label}
        disabled={
          !this.props.hasAddedDeductionCode ||
          !this.props.hasAddedGroups ||
          waitingForRosterApproval
        }
        completedAction={this.props.hasLaunchedPlan}
        steps={steps}
        activeStepNumber={this.props.activeStepNumber}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    user,
    hasStatePlan: hasStatePlanSelector(state),
    hasAnnouncedIcon: hasAnnouncedIcon(state),
    hasInvitedEmployees: hasInvitedEmployees(state),
    hasLaunchedPlan: hasLaunchedPlan(state),
    hasEnabledFinch: hasEnabledFinchFeature(state),
    hasPayrollIntegration: hasEnabledPayrollIntegration(state),
    hasSuccessfullyConnectedPayroll: hasSuccessfullyConnectedPayroll(state),
    has360PayrollIntegration: hasEnabled360PayrollIntegration(state),
    hasAddedGroups: hasAddedGroups(state),
    hasAddedDeductionCode: hasAddedDeductionCode(state),
    employer: get(state.employer.progressiveOnboarding, "hasLaunchedPlan"),
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddUsersCard));
