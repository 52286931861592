import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get, head, isEmpty } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import IconSpinner from "components/IconSpinner";
import {
  pendingEmployerRequiredUpdates,
  requiresAdministrativeUpdates,
} from "store/selectors/employer";
import { employerConstants } from "actions/types";
import OrderFormAgreements from "pages/dashboards/employerDashboard/requiredUpdates/OrderFormAgreements";
import { getEmployerRequiredUpdates } from "actions/employerActions";

class EmployerRequiredUpdates extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),

    error: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    getEmployerRequiredUpdates: PropTypes.func.isRequired,
    pendingEmployerRequiredUpdates: PropTypes.arrayOf(PropTypes.string)
      .isRequired,
    push: PropTypes.func.isRequired,
    requiresAdministrativeUpdates: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: get(props, "isFetching", true),
    };
  }

  async componentDidMount() {
    await this.props
      .getEmployerRequiredUpdates(this.props.client)
      .then(() => {
        if (!this.props.requiresAdministrativeUpdates)
          this.props.push("/dashboard");
      })
      .then(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return <IconSpinner centered />;
    }
    const firstPendingStep = head(this.props.pendingEmployerRequiredUpdates);
    const components = {
      ICON_ORDER_FORM_AGREEMENT: (
        <OrderFormAgreements orderForm={"ICON_ORDER_FORM_AGREEMENT"} />
      ),
      ISOLVED_ORDER_FORM_AGREEMENT: (
        <OrderFormAgreements orderForm={"ISOLVED_ORDER_FORM_AGREEMENT"} />
      ),
    };
    const component = get(components, firstPendingStep);

    return <>{!isEmpty(component) ? component : <div />}</>;
  }
}

const actions = [employerConstants.GET_EMPLOYER_REQUIRED_STEPS];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isFetching: loadingSelector(state),
    pendingEmployerRequiredUpdates: pendingEmployerRequiredUpdates(state),
    requiresAdministrativeUpdates: requiresAdministrativeUpdates(state),
  };
};

const mapDispatchToProps = {
  getEmployerRequiredUpdates,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerRequiredUpdates));
