import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { push } from "connected-react-router";
import { filter, groupBy, size, isEmpty } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { Card } from "react-bootstrap";
import { FiMail, FiList } from "react-icons/fi";
import { groupType } from "statics/propTypes";
import { createErrorSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import {
  getLinkedEmployees,
  getAllEmployerGroups,
} from "actions/employerActions";

import Alert from "components/Alert";
import PayrollTable from "./PayrollTable";
import Button from "components/Button";
import IconEmptyState from "components/IconEmptyState";

import "./ProcessGroupsTable.scss";

export class ProcessGroupsTable extends React.PureComponent {
  static propTypes = {
    // Redux props
    push: PropTypes.func,
    client: PropTypes.object,
    linkedEmployeesByGroup: PropTypes.object,
    linkedEmployees: PropTypes.object,
    error: PropTypes.string,
    groups: PropTypes.arrayOf(groupType),

    // Action Creators
    getLinkedEmployees: PropTypes.func.isRequired,
    getAllEmployerGroups: PropTypes.func,
    employerLinkRequestsByGroup: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      initialFetching: true,
    };
  }
  render() {
    const employees = size(this.props.linkedEmployees);
    const inviteEmployees = employees === 0;

    const employerLinkRequestsByGroup = this.props.employerLinkRequestsByGroup;
    const pendingEmployees = !isEmpty(employerLinkRequestsByGroup);

    return (
      <>
        {inviteEmployees && (
          <Card className="process-contributions">
            <div className="widget-header">
              <span>
                <Card.Title>Process Payroll</Card.Title>
              </span>

              <div className="action-btn">
                {!pendingEmployees && (
                  <Button
                    className="table-btn"
                    color="action"
                    name="action"
                    size="sm"
                    btnLabel="Invite Employees"
                    icon={{
                      icon: <FiMail color={"#29b0c2"} stroke={"#29b0c2"} />,
                      position: "left",
                    }}
                    onClick={() =>
                      this.props.push("/dashboard/users/employees")
                    }
                  />
                )}
              </div>
            </div>
            {this.props.error && <Alert type="error" msg={this.props.error} />}{" "}
            <div style={{ borderTop: "1px solid #f2f4f7" }}>
              {!pendingEmployees && (
                <IconEmptyState
                  header={"No linked payrolls"}
                  subheader="Please invite employees to join a group to see linked payrolls."
                  icon={<FiList color="white" stroke="#60A4BF" size={16} />}
                />
              )}

              {pendingEmployees && (
                <IconEmptyState
                  header={"You have pending employees!"}
                  subheader="Please review and approve employees so you can see the linked payrolls."
                  icon={<FiList color="white" stroke="#60A4BF" size={16} />}
                  actionText={"Review pending Employees"}
                  onClick={() => this.props.push("/dashboard/company/group")}
                />
              )}
            </div>
          </Card>
        )}

        {!inviteEmployees && <PayrollTable />}
      </>
    );
  }
}

const errorSelector = createErrorSelector([
  employerConstants.PROCESS_PAYROLL,
  employerConstants.GET_LINKED_EMPLOYEES,
  employerConstants.GET_EMPLOYER_GROUPS,
]);

const mapStateToProps = (state) => {
  const linkedEmployees = state.employer.linkedEmployees;

  const linkedEmployeesByGroup = groupBy(
    linkedEmployees,
    "groupWithSchedule.group.id"
  );

  const employerLinkRequests = filter(
    state.employer.employerLinkRequests,
    (request) => request.status === "REQUEST_ACCESS"
  );

  const employerLinkRequestsByGroup = groupBy(employerLinkRequests, "group.id");

  return {
    groups: state.employer.groups,
    linkedEmployeesByGroup,
    error: errorSelector(state),
    linkedEmployees,
    employerLinkRequestsByGroup,
  };
};

const mapDispatchToProps = {
  push,
  getLinkedEmployees,
  getAllEmployerGroups,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ProcessGroupsTable));
