import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

import "./Paginator.scss";
const Paginator = ({ onChange, pageCount, page }) => {
  return (
    <div className="paginator">
      <ReactPaginate
        previousLabel={"Prev"}
        nextLabel={"Next"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onChange}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
        forcePage={page}
      />
    </div>
  );
};

Paginator.propTypes = {
  onChange: PropTypes.func,
  pageCount: PropTypes.number,
  page: PropTypes.number,
};

export default Paginator;
