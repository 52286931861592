import React from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { Card, Modal } from "react-bootstrap";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";

import Button from "components/Button";
import Alert from "components/Alert";
import PlaidLink from "components/PlaidLink";
import { linkBankIndividual, verifyBankAccount } from "actions/bankActions";
import { createErrorSelector } from "store/selectors";
import { bankConstants } from "actions/types";
import {
  bankSelector,
  isPendingManualBankVerificationSelector,
  isPendingAutoBankVerificationSelector,
  isBankVerifiedSelector,
} from "store/selectors/bank";
import "./AddBankModal.scss";

class AddBankModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    show: PropTypes.bool,
    linkBankIndividual: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    isPendingManualBankVerification: PropTypes.bool,
    isPendingAutoBankVerification: PropTypes.bool,
    verifyBankAccount: PropTypes.func,
    bankAccount: PropTypes.object,
    isBankVerified: PropTypes.bool,
  };

  _handleOnSuccess = (token, metadata, ownerName) => {
    if (this.props.isPendingManualBankVerification) {
      this.props.verifyBankAccount(
        this.props.client,
        this.props.bankAccount.id
      );
      return;
    }
    this.props.linkBankIndividual(
      this.props.client,
      token,
      metadata,
      ownerName
    );
  };

  _getDescription = () => {
    if (this.props.isPendingManualBankVerification) {
      return (
        <p>
          Your bank account is being verified! Verify the two small deposits in
          your account after one to two business days.
          {this._getBankInfo()}
        </p>
      );
    } else if (this.props.isPendingAutoBankVerification) {
      return (
        <p>
          Your bank account is being verified! Your bank account will be
          automatically verified in one to two business days.
          {this._getBankInfo()}
        </p>
      );
    } else if (this.props.isBankVerified) {
      return (
        <p>
          Your bank account has been successfully added!
          {this._getBankInfo()}
        </p>
      );
    }
    return (
      <p>
        It looks like you haven{"'"}t added a bank account yet. Securely connect
        your personal bank account to Icon so you can fund your account.
      </p>
    );
  };

  _getBankInfo = () => {
    return (
      <>
        <br />
        <br />
        <strong>Account Type: </strong>{" "}
        {this.props.bankAccount.accountSubtype.toUpperCase()}
        <br />
        <strong>Account Last 4: </strong> {this.props.bankAccount.accountId}
      </>
    );
  };

  render() {
    const shouldShowBankLink = !(
      this.props.isPendingManualBankVerification ||
      this.props.isPendingAutoBankVerification ||
      this.props.isBankVerified
    );

    if (this.props.show) {
      return (
        <Modal
          centered
          scrollable
          show={this.props.show}
          onHide={noop}
          className="add-bank-modal"
        >
          <Modal.Body>
            <Card>
              <Card.Body>
                <Card.Title>Add Bank Account</Card.Title>
                {this._getDescription()}
                {this.props.error && (
                  <Alert type="error" msg={this.props.error} />
                )}
              </Card.Body>
              <Card.Footer>
                <Button
                  color="cancel"
                  name="cancel"
                  onClick={this.props.onClose}
                  btnLabel="Cancel"
                />
                {this.props.isPendingManualBankVerification && (
                  <PlaidLink
                    onSuccess={this._handleOnSuccess}
                    label="Verify"
                    bank={this.props.bankAccount}
                  />
                )}
                {shouldShowBankLink && (
                  <PlaidLink
                    onSuccess={this._handleOnSuccess}
                    bank={this.props.bankAccount}
                  />
                )}
                {this.props.isBankVerified && (
                  <Button
                    onClick={this.props.onSuccess}
                    btnLabel="Continue"
                    name="submit"
                    withArrow
                  />
                )}
              </Card.Footer>
            </Card>
          </Modal.Body>
        </Modal>
      );
    }
    return null;
  }
}

const errorSelector = createErrorSelector([bankConstants.LINK_BANK_WITH_ICON]);
const mapStateToProps = (state) => {
  return {
    bankAccount: bankSelector(state),
    isPendingManualBankVerification:
      isPendingManualBankVerificationSelector(state),
    isPendingAutoBankVerification: isPendingAutoBankVerificationSelector(state),
    isBankVerified: isBankVerifiedSelector(state),
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  linkBankIndividual,
  verifyBankAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddBankModal));
