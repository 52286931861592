import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Card } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";

import IconSpinner from "components/IconSpinner";

import "./ActionCard.scss";

const PlaidLinkUpdateCard = ({
  onClick,
  icon,
  color,
  title,
  description,
  completedAction,
  loading,
  disabled,
}) => (
  <Card>
    <Card.Body>
      <div>
        <div className="action-card-details">
          {icon && (
            <div className="action-icon-container">
              <div
                className={`action-icon-circle ${
                  color === "red" ? "red-color" : "primary-color"
                }`}
              >
                <div className="action-icon">{icon}</div>
              </div>
            </div>
          )}
          <h5 className="action-card-title">{title}</h5>
          <p className="action-card-description">{description}</p>
        </div>
        {!completedAction && !loading && (
          <div className={classnames("action-card-action", { disabled })}>
            {onClick}
          </div>
        )}
        {completedAction && (
          <div className="action-card-action completed-action">
            <div className="success-icon">
              <FaCheckCircle size={24} color="#4BB543" />
            </div>
          </div>
        )}
        {loading && (
          <div className="action-card-action action-card-loading">
            <IconSpinner />
          </div>
        )}
      </div>
    </Card.Body>
  </Card>
);

PlaidLinkUpdateCard.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  actionText: PropTypes.string,
  completedAction: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

export default PlaidLinkUpdateCard;
