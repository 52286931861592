import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { useAuth0 } from "utils/react-auth0-wrapper";
import AccessTokenStorage from "utils/AccessTokenStorage";
import AuthApolloInitializer from "utils/AuthApolloInitializer";

const AuthApolloProviderComponent = ({ children }) => {
  const { loading, getTokenSilently, loginWithRedirect, logout } = useAuth0();
  const [tokenLoading, setTokenLoading] = useState(true);
  const [timeoutId, setTimeoutId] = useState();
  const dispatch = useDispatch();
  const initializerProps = {
    dispatch,
    tokenLoading,
    loading,
    getTokenSilently,
    loginWithRedirect,
    logout,
  };

  const getTokenExpiration = (token) => {
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    return new Date(decodedToken.exp * 1000);
  };

  // If Auth0 is done initializing, the begin out provider init, otherwise just provide tokenLoading state
  if (loading) {
    return (
      <AuthApolloInitializer {...initializerProps}>
        {children}
      </AuthApolloInitializer>
    );
  }

  const getAccessToken = async () => {
    try {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      // Since AUTH_TOKEN relies on a 3rd party service, it might be slow to show up
      const tokenResult = await getTokenSilently();
      AccessTokenStorage.setToken(tokenResult);
      const now = new Date();
      const tokenExpiration = getTokenExpiration(tokenResult);
      const tenSeconds = 10 * 1000;
      const timeUntilRefresh =
        tokenExpiration.getTime() - now.getTime() - tenSeconds;
      const currentTimeoutId = setTimeout(async () => {
        // refreshes the token after `timeUntilRefresh` has elapsed
        await getAccessToken();
      }, timeUntilRefresh);
      setTimeoutId(currentTimeoutId);
    } catch (e) {
      console.log(e);
    } finally {
      // We have an Auth Token, can continue loading the app
      setTokenLoading(false);
    }
  };

  // Only request the Auth Token if we don't have it yet
  if (tokenLoading && !AccessTokenStorage.getToken()) {
    getAccessToken();
  }

  if (tokenLoading) {
    return (
      <AuthApolloInitializer {...initializerProps}>
        {children}
      </AuthApolloInitializer>
    );
  }

  return (
    <AuthApolloInitializer {...initializerProps}>
      {children}
    </AuthApolloInitializer>
  );
};

AuthApolloProviderComponent.propTypes = {
  children: PropTypes.node,
};
export const AuthApolloProvider = AuthApolloProviderComponent;
