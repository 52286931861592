import gql from "graphql-tag";

export function getAllBeneficiaries(client, accountId) {
  return new Promise((resolve, reject) => {
    const BENEFICIARIES = gql`
      query getBeneficiaries($accountId: ID!) {
        getBeneficiaries(accountId: $accountId) {
          familyName
          givenName
          city
          country
          postalCode
          state
          dateOfBirth
          socialSecurityNumber
          relationship
          beneficiaryType
          sharePercent
          streetAddress
        }
      }
    `;
    const result = client.query({
      query: BENEFICIARIES,
      variables: {
        accountId,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.getBeneficiaries);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function updateBeneficiaries(client, beneficiaryInput) {
  return new Promise((resolve, reject) => {
    const UPDATE_BENEFICIARIES = gql`
      mutation updateBeneficiaries($beneficiaryInput: BeneficiaryInput!) {
        updateBeneficiaries(beneficiaryInput: $beneficiaryInput) {
          familyName
          givenName
          city
          country
          postalCode
          state
          dateOfBirth
          socialSecurityNumber
          relationship
          beneficiaryType
          sharePercent
          streetAddress
        }
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_BENEFICIARIES,
      variables: {
        beneficiaryInput,
      },
    });
    result.then(
      (data) => {
        resolve(data.data.updateBeneficiaries);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export function deleteAllBeneficiaries(client, accountId) {
  return new Promise((resolve, reject) => {
    const DELETE_BENEFICIARIES = gql`
      mutation deleteBeneficiaries($accountId: ID!) {
        deleteBeneficiaries(accountId: $accountId)
      }
    `;
    const result = client.mutate({
      mutation: DELETE_BENEFICIARIES,
      variables: { accountId },
    });
    result.then(
      (data) => {
        resolve(data.data.deleteBeneficiaries);
      },
      (errors) => {
        reject(errors);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export default {
  getAllBeneficiaries,
  updateBeneficiaries,
  deleteAllBeneficiaries,
};
