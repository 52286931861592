import React, { Component } from "react";
import { isEqual } from "lodash";
import { merge } from "lodash";
import PropTypes from "prop-types";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import { chartColors } from "utils/highcharts/config";

class PieChart extends Component {
  static propTypes = {
    extendedConfig: PropTypes.shape({}),
    chartData: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    extendedConfig: {},
  };

  shouldComponentUpdate(nextProps) {
    const configChanged = !isEqual(
      nextProps.extendedConfig,
      this.props.extendedConfig
    );
    const chartDataChanged = !isEqual(
      nextProps.chartData,
      this.props.chartData
    );
    return configChanged || chartDataChanged;
  }

  _buildChartConfig() {
    return merge(
      {},
      {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          style: {
            fontFamily:
              '"Graphik", "Open Sans", "Helvetica Neue", Arial, sans-serif',
          },
        },
        colors: chartColors,
        title: {
          text: "",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        series: this.props.chartData,
        credits: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "{point.name}: {point.percentage:.1f} %",
            },
          },
        },
      },
      this.props.extendedConfig
    );
  }

  render() {
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={this._buildChartConfig()}
        />
      </div>
    );
  }
}

export default PieChart;
