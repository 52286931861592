import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";

class EmployerTermsOrderV2 extends React.Component {
  static propTypes = {
    companyName: PropTypes.string,
    companyEIN: PropTypes.string,
    info: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  _content = () => {
    const companyName = this.props.companyName;
    const signerName =
      this.props.info.firstName + " " + this.props.info.lastName;
    const companyEIN = this.props.companyEIN;
    const signerEmail = this.props.info.userEmail;
    const today = moment().format("MMMM Do YYYY");
    return (
      <>
        <div className="terms-of-service">
          {/* // Fees */}
          <div>
            <p className="terms-number">1. Fees</p>
            <ol type="a">
              <li>
                This Exhibit A is governed by the terms of the Icon Platform
                Services Agreement. User must have a Platform Services Agreement
                in place, executed by Icon and User.
              </li>
              <li>
                Upon signature by User and submission to Icon, this Exhibit A
                shall become legally binding upon User, unless this Exhibit A is
                rejected by Icon. Icon reserves the right to reject Exhibit A
                for any reason, which includes but is not limited to:
                <ol type="1">
                  <li>
                    The signatory below does not have the authority to bind User
                    to this Exhibit A
                  </li>
                  <li>
                    Changes have been made to this Exhibit A (other than
                    completion of the signature block)
                  </li>
                  <li>
                    The signature block is incomplete or does not match our
                    records or the rest of this Exhibit A
                  </li>
                  <li>
                    The User does not have a Platform Services Agreement in
                    place.
                  </li>
                </ol>
              </li>
              <li>
                <div>
                  <p className="terms-list">
                    <b>Setup fee </b>for the Services and access to the
                    Platform.
                  </p>
                  <div className="terms-table">
                    <span className="table-col">
                      <p className="terms-number">Company Size</p>
                      <span>
                        <p>1 to 10</p>
                        <p>11 to 25</p>
                        <p>26 to 50</p>
                        <p>51 to 100</p>
                      </span>
                    </span>
                    <span className="divider"></span>
                    <span className="table-col">
                      <p className="terms-number">One-time setup fee</p>
                      <span>
                        <p>$80</p>
                        <p>$160</p>
                        <p>$280</p>
                        <p>$400</p>
                      </span>
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <p className="terms-list">
                    <b>Recurring monthly fee </b> for the Services and access to
                    the Platform.
                  </p>
                  <div className="terms-table">
                    <span className="table-col">
                      <p className="terms-number">Company Size</p>
                      <span>
                        <p>1 to 10</p>
                        <p>11 to 25</p>
                        <p>26 to 50</p>
                        <p>51 to 100</p>
                      </span>
                    </span>
                    <span className="divider"></span>
                    <span className="table-col">
                      <p className="terms-number">Monthly Platform Fee</p>
                      <span>
                        <p>$40</p>
                        <p>$80</p>
                        <p>$140</p>
                        <p>$200</p>
                      </span>
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <p className="terms-list">
                  <b>Support fee</b> for the Services.
                  <span style={{ marginTop: 20, paddingLeft: 24 }}>
                    <p>Employer support: Included</p>
                    <p>Employee support: Included</p>
                  </span>
                </p>
              </li>
            </ol>
          </div>
          {/* // Product Terms*/}
          <div>
            <p className="terms-number">2. Product Terms</p>
            <ol type="a">
              <li>
                Participating Employee is defined as any employee enrolled in
                Icon that has a linked employer plan to the User’s account on
                the Icon Platform for any amount of time in a calendar month.
              </li>
              <li>
                Company Size is determined during business verification and
                reflects the total number of W2 and 1099 workers at the User’s
                company. For purposes of determining the Monthly Platform Fee
                the Company Size will remain static for the term of the Platform
                Services Agreement associated with this Exhibit A unless updated
                by a payroll system automatically. When Company Size is updated
                by a payroll system automatically the count will be determined
                by the number of unique active W2 and 1099 employee records
                returned by the payroll data source for a given calendar month,
                and the corresponding Monthly Platform Fee tier will be billed
                for that month. If Company Size is greater than the largest tier
                specified in section 1(d) for a given calendar month, then the
                largest tier will be the effective tier for the month unless a
                new Order Form is established.
              </li>
              <li>
                Employer Support includes Email support during normal business
                hours (9am to 5pm Pacific, M-F, excluding Federal Holidays). A
                dedicated Customer Success agent with online and video
                conference support for initial plan setup and administration
                during the first 60 days of contract term is also included.
              </li>
              <li>
                Employee Support includes Email and online support during normal
                business hours (9am to 5pm Pacific, M-F, excluding Federal
                Holidays).
              </li>
            </ol>
          </div>

          {/* // Invoicing and Payment */}
          <div>
            <p className="terms-number">3. Invoicing and Payment </p>
            <ol type="a">
              <li>
                Invoiced fees are due net 30 days from the invoice date. User is
                responsible for providing complete and accurate billing and
                contact information to Icon and notifying Icon of any changes to
                such information. Payment must be made digitally via credit card
                or ACH. Initial payment will be invoiced and collected upon the
                successful completion of Icon’s business verification of the
                User.
              </li>
            </ol>
          </div>

          {/* // Suspension of Service and Acceleration*/}
          <div>
            <p className="terms-number">
              4. Suspension of Service and Acceleration
            </p>
            <ol type="a">
              <li>
                If any charge owing by User under this or any other agreement
                for services is 30 days or more overdue, (or 10 or more days
                overdue in the case of amounts User has authorized Icon to
                charge to User’s credit card), Icon may, without limiting its
                other rights and remedies, accelerate User’s unpaid fee
                obligations under such agreements so that all such obligations
                become immediately due and payable, and suspend Services until
                such amounts are paid in full, provided that, other than for
                customers paying by credit card or direct debit whose payment
                has been declined, Icon will give User at least 10 days’ prior
                notice that its account is overdue.
              </li>
            </ol>
          </div>

          <div className="terms-footer">
            <div>
              <p>
                <b>IN WITNESS WHEREOF</b>, the parties, by their duly authorized
                representatives, have executed this Platform Services Agreement
                Exhibit A Order Form as of {today} (the “Effective Date”).
              </p>
            </div>

            <div className="terms-user-details">
              <span>
                <p className="terms-label">User</p>
                <p>Name: {signerName}</p>
                <p>Email: {signerEmail} </p>
                <p>
                  Company: {companyName} (Tax ID: {companyEIN})
                </p>
              </span>
              <span>
                <p className="terms-label">Icon</p>
                <p>Email: legal@iconsavingsplan.com</p>
                <p>
                  Company: The Icon Savings Corporation (Tax ID: 82-3955531)
                </p>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const content = this._content();
    return <div>{content}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const companyName = state.employer.company.name;
  const companyEIN = state.employer.company.ein;
  const { info } = ownProps;

  return {
    companyName,
    companyEIN,
    ownProps,
    info,
  };
};

export default connect(mapStateToProps)(EmployerTermsOrderV2);
