import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth0 } from "utils/react-auth0-wrapper";
import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

const PublicRoute = ({ ...props }) => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    // Even though they went to a public route, they have an active session
    // Let's redirect them to our Home route that will get them to the right spot
    return <Redirect to={`/login/${window.location.search}`} />;
  } else {
    return <SentryRoute {...props} />;
  }
};

export default PublicRoute;
