import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";
import { Card } from "react-bootstrap";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import { FiAlertTriangle } from "react-icons/fi";
import IconCircle from "components/IconCircle";

import Button from "components/Button";

class IconAuthlessErrorState extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    push: PropTypes.func,
    header: PropTypes.string,
    subheader: PropTypes.string,
    onClick: PropTypes.func,
    isFetching: PropTypes.bool,
    actionText: PropTypes.string,
  };

  _login = () => {};

  render() {
    return (
      <>
        <Card>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <div
              style={{
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              <IconCircle
                type="error"
                icon={
                  <FiAlertTriangle color="white" stroke="#B12121" size={16} />
                }
              />
            </div>
            <div>
              <IconHeader
                variant="referralHeader"
                headerText={this.props.header}
                style={{
                  color: "#B12121",
                }}
              />
              <IconSubheader subheader={this.props.subheader} />
            </div>
            <section
              style={{
                paddingTop: 10,
                paddingBottom: 20,
              }}
            >
              {this.props.onClick && (
                <div className="step-btn">
                  <Button
                    size="sm"
                    color="action"
                    name="action"
                    onClick={this.props.onClick}
                    loading={this.props.isFetching}
                  >
                    {this.props.actionText}
                  </Button>
                </div>
              )}
            </section>
          </div>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    referralPlans: state.employer.referralPlans,
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IconAuthlessErrorState));
