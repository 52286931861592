/* eslint-disable react/no-unescaped-entities */

import React from "react";

class ApexTermsRoth extends React.Component {
  _content = () => {
    return (
      <>
        <div className="terms-of-service">
          <p className="terms-label">Depositor Authorization</p>
          <div>
            <p>
              I understand that I have the right to direct the investment and
              reinvestment of contributions to my account and hereby appoint the
              following brokerage firm as my agent to execute my directions, as
              Broker under the terms of Custodial Agreement.
            </p>
          </div>
          <div>
            <ul>
              <li>Brokerage Firm: Apex Clearing</li>
              <br />
            </ul>
          </div>
          <div>
            <p className="terms-label">
              ROTH INDIVIDUAL RETIREMENT CUSTODIAL ACCOUNT AGREEMENT
            </p>
          </div>
          <div>
            <p>
              Form 5305-RA under section 408(a) of the InternalRevenue Code.
              FORM (Rev. April 2017)
            </p>
          </div>
          <div>
            <p>
              The Depositor named on the Application is establishing a Roth
              Individual Retirement Account (Roth IRA) under section 408A to
              provide for his or her retirement and for the support of his or
              her beneficiaries after death.
            </p>
          </div>
          <div>
            <p>
              The Custodian named on the Application has given the Depositor the
              disclosure statement required by Regulations section 1.408-6.
            </p>
          </div>
          <div>
            <p>
              The Depositor has assigned the custodial account the sum indicated
              on the Application.
            </p>
          </div>
          <div>
            <p>The Depositor and the Custodian make the following agreement:</p>
          </div>
          <div>
            <p className="terms-label">ARTICLE 1</p>
          </div>
          <div>
            <p>
              Except in the case of a qualified rollover contribution described
              in section 408A(e) or a recharacterized contribution described in
              section 408A(d)(6), the Custodian will accept only cash
              contributions up to $5,500 per year for 2013 through 2017. For
              individuals who have reached the age of 50 before the close of the
              tax year, the contribution limit is increased to $6,500 per year
              for tax years 2013 through 2017. For years after 2017, these
              limits will be increased to reflect a cost-of-living adjustment,
              if any.
            </p>
          </div>
          <div>
            <p className="terms-label">ARTICLE II</p>
          </div>
          <div>
            <ol>
              <li>
                <p>
                  The annual contribution limit described in Article I is
                  gradually reduced to $0 for higher income levels. For a
                  depositor who is single or treated as a single, the annual
                  contribution is phased out between adjusted gross income (AGI)
                  of $118,000 and $133,000; for a married Depositor filing
                  jointly, between AGI of $186,000 and $196,000; and for a
                  married Depositor filing separately, between AGI of $0 and
                  $10,000. These phase-out ranges are for 2017. For years after
                  2017, the phase-out ranges, except for the $0 to $10,000
                  range, will be increased to reflect a cost-of-living
                  adjustment, if any. Adjusted gross income is defined in
                  section 408A©(3).
                </p>
              </li>
              <li>
                <p>
                  In the case of a joint return, the AGI limits in the preceding
                  paragraph apply to the combined AGI of the Depositor and his
                  or her spouse.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <p className="terms-label">ARTICLE III</p>
          </div>
          <div>
            <p>
              The Depositor’s interest in the balance in the custodial account
              is nonforfeitable.
            </p>
          </div>
          <div>
            <p className="terms-label">ARTICLE IV</p>
          </div>
          <div>
            <ol>
              <li>
                <p>
                  No part of the custodial account funds may be invested in life
                  insurance contracts, nor may the assets of the custodial
                  account be commingled with other property except in a common
                  trust fund or common investment fund (within the meaning of
                  section 408(a)(5)).
                </p>
              </li>
              <li>
                <p>
                  No part of the custodial account funds may be invested in
                  collectibles (within the meaning of section 408(m)) except as
                  otherwise permitted by section 408(m)(3), which provides an
                  exception for certain gold, silver, and platinum coins, coins
                  issued under the laws of any state, and certain bullion.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <p className="terms-label">ARTICLE V</p>
          </div>
          <div>
            <ol>
              <li>
                <p>
                  If the Depositor dies before his or her entire interest is
                  distributed to him or her and the Depositor’s surviving spouse
                  is not the designated beneficiary, the remaining interest will
                  be distributed in accordance with paragraph (a) below or, if
                  elected or there is no designated beneficiary, in accordance
                  with paragraph (b) below:
                </p>
                <ol type="a">
                  <li>
                    The remaining interest will be distributed, starting by the
                    end of the calendar year following the year of the
                    Depositor’s death, over the designated beneficiary’s
                    remaining life expectancy as determined in the year
                    following the death of the Depositor.
                  </li>
                  <li>
                    The remaining interest will be distributed by the end of the
                    calendar year containing the fifth anniversary of the
                    Depositor’s death.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  The minimum amount that must be distributed each year under
                  paragraph 1(a) above is the account value at the close of
                  business on December 31 of the preceding year divided by the
                  life expectancy (in the single life table in Regulations
                  section 1.401(a)(9)-9) of the designated beneficiary using the
                  attained age of the beneficiary in the year following the year
                  of the Depositor’s death and subtracting one from the divisor
                  for each subsequent year.
                </p>
              </li>
              <li>
                <p>
                  If the Depositor’s surviving spouse is the designated
                  beneficiary, such spouse will then be treated as the
                  Depositor.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <p className="terms-label">ARTICLE VI</p>
          </div>
          <div>
            <ol>
              <li>
                <p>
                  The Depositor agrees to provide the Custodian with all
                  information necessary to prepare any reports required by
                  sections 408(i) and 408A(d)(3)(E), Regulations sections
                  1.408-5 and 1.408-6, or other guidance published by the
                  Internal Revenue Service (IRS).
                </p>
              </li>
              <li>
                <p>
                  The Custodian agrees to submit to the IRS and Depositor the
                  reports prescribed by the IRS.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <p className="terms-label">ARTICLE VII</p>
          </div>
          <div>
            <p>
              Notwithstanding any other articles which may be added or
              incorporated, the provisions of Articles I through IV and this
              sentence will be controlling. Any additional articles inconsistent
              with section 408A, the related regulations, and other published
              guidance will be invalid.
            </p>
          </div>
          <div>
            <p className="terms-label">ARTICLE VIII</p>
          </div>
          <div>
            <p>
              This Agreement will be amended as necessary to comply with the
              provisions of the Code, the related Regulations, and other
              published guidance. Other amendments may be made with the consent
              of the persons whose signatures appear on the Application.
            </p>
          </div>
          <div>
            <p className="terms-label">ARTICLE IX</p>
          </div>
          <div>
            <ol>
              <li>
                <p>
                  <strong>Definitions</strong> – In this part of this Agreement
                  (Article IX), the words “you” and “your” mean the Depositor,
                  the words “we,” “us” and “our” mean the Custodian, “Code”
                  means the Internal Revenue Code, and “Regulations” means the
                  Treasury Regulations.
                </p>
              </li>
              <li>
                <p>
                  <strong>Notices and Change of Address</strong> – Any required
                  notice regarding this Roth IRA will be considered effective
                  when we send it to the intended recipient at the last address
                  which we have in our records. Any notice to be given to us
                  will be considered effective when we actually receive it. You,
                  or the intended recipient, must notify us of any change of
                  address.
                </p>
              </li>
              <li>
                <p>
                  <strong>Representations and Responsibilities</strong> – You
                  represent and warrant to us that any information you have
                  given or will give us with respect to this Agreement is
                  complete and accurate. Further, you agree that any directions
                  you give us, or action you take will be proper under this
                  Agreement, and that we are entitled to rely upon any such
                  information or directions. If we fail to receive directions
                  from you regarding any transaction, if we receive ambiguous
                  directions regarding any transaction, or we, in good faith,
                  believe that any transaction requested is in dispute, we
                  reserve the right to take no action until further
                  clarification acceptable to us is received from you or the
                  appropriate government or judicial authority. We shall not be
                  responsible for losses of any kind that may result from your
                  directions to us or your actions or failures to act, and you
                  agree to reimburse us for any loss we may incur as a result of
                  such directions, actions or failures to act. We shall not be
                  responsible for any penalties, taxes, judgments, or expenses
                  you incur in connection with your Roth IRA. We have no duty to
                  determine whether your contributions or distributions comply
                  with the Code, Regulations, rulings, or this Agreement.
                </p>
                <p>
                  We may permit you to appoint, through written notice
                  acceptable to us, an authorized agent to act on your behalf
                  with respect to this Agreement (e.g., attorney-in-fact,
                  executor, administrator, investment manager), however, we have
                  no duty to determine the validity of such appointment or any
                  instrument appointing such authorized agent. Any such
                  delegation of investment responsibility shall be to an
                  investment advisor under the Investment Advisors Act of 1940.
                  If the assets of your Roth IRA are to be directed by an
                  investment advisor, you shall deliver to us, if we request, a
                  copy of the instruments appointing the investment advisor
                  evidencing the investment advisor’s acceptance of such
                  appointment, an acknowledgment by the investment advisor that
                  it is a fiduciary, and a certificate evidencing the investment
                  advisor’s current registration under said Act. We shall be
                  fully protected in relying upon such instruments and
                  certificate until otherwise notified in writing by you. We
                  shall not be responsible for losses of any kind that may
                  result from directions, actions or failures to act by your
                  authorized agent, and you agree to reimburse us for any loss
                  we may incur as a result of such directions, actions, or
                  failures to act by your authorized agent.
                </p>
                <p>
                  The investment advisor at any time may issue orders for the
                  purchase or sale of securities directly to a broker; and in
                  order to facilitate such transaction, upon request we shall
                  execute and deliver appropriate trading authorizations.
                  Written notification of the issuance of each order shall be
                  confirmed by written advice via confirms or otherwise to us by
                  the investment advisor.
                  <br />
                  You hereby agree to fully indemnify us and hold us harmless
                  from and against any claim or liability which may be asserted
                  against us by reason of our acting or not acting pursuant to
                  any direction from the investment advisor or failing to act in
                  the absence of any such direction.
                </p>
                <p>
                  You will have sixty (60) days after you receive any documents,
                  statements, or other information from us to notify us in
                  writing of any errors or inaccuracies reflected in these
                  documents, statements, or other information. If you do not
                  notify us within 60 days, the documents, statements, or other
                  information shall be deemed correct and accurate, and we shall
                  have no further liability or obligation for such documents,
                  statements, other information, or the transactions described
                  therein.
                </p>
                <p>
                  By performing services under this Agreement we are acting as
                  your agent. You acknowledge and agree that nothing in this
                  Agreement shall be construed as conferring fiduciary status
                  upon us. We shall not be required to perform any additional
                  services unless specifically agreed to under the terms and
                  conditions of this Agreement, or as required under the Code
                  and the Regulations promulgated thereunder with respect to
                  Roth IRAs. You agree to indemnify and hold us harmless for any
                  and all claims, actions, proceedings, damages, judgments,
                  liabilities, costs and expenses, including attorney’s fees,
                  arising from, or in connection with this Agreement.
                </p>
                <p>
                  To the extent written instructions or notices are required
                  under this Agreement, we may accept or provide such
                  information in any other form permitted by the Code or
                  applicable regulations including, but not limited to,
                  electronic communication.
                </p>
              </li>
              <li>
                <p>
                  <strong>Disclosure of Account Information</strong> – We may
                  use agents and/or subcontractors to assist in administering
                  your Roth IRA. We may release nonpublic personal information
                  regarding your Roth IRA to such providers as necessary to
                  provide the products and services made available under this
                  agreement, and to evaluate our business operations and analyze
                  potential product, service, or process improvements.
                </p>
              </li>
              <li>
                <p>
                  <strong>Service Fees</strong> – We have the right to charge an
                  annual service fee and other designated fees (e.g., a
                  transfer, rollover, or termination fee) for maintaining your
                  Roth IRA. In addition, we have the right to be reimbursed for
                  all reasonable expenses, including legal expenses, we incur in
                  connection with the administration of your Roth IRA. We may
                  charge you separately for any fees or expenses, or we may
                  deduct the amount of the fees or expenses from the assets in
                  your Roth IRA at our discretion. The full annual service fee
                  attributable to the year in which you terminate your Roth IRA,
                  along with the termination fee, shall be due and payable upon
                  termination of your Roth IRA regardless of the date during the
                  year in which you terminate your Roth IRA. We reserve the
                  right to charge any additional fee upon 30 days’ notice to you
                  that the fee will be effective. Fees such as subtransfer agent
                  fees or commissions may be paid to us by third parties for
                  assistance in performing certain transactions with respect to
                  this Roth IRA.
                </p>
                <p>
                  Any brokerage commissions attributable to the assets in your
                  Roth IRA will be charged to your Roth IRA. You cannot
                  reimburse your Roth IRA for those commissions.
                </p>
              </li>
              <li>
                <p>
                  <strong>Investment of Amounts in the Roth IRA</strong> – You
                  have exclusive responsibility for and control over the
                  investment of the assets of your Roth IRA. All transactions
                  shall be subject to any and all restrictions or limitations,
                  direct or indirect, which are imposed by our charter, articles
                  of incorporation, or bylaws; any and all applicable federal
                  and state laws and regulations; the rules, regulations,
                  customs and usages of any exchange, market or clearing house
                  where the transaction is executed; our policies and practices;
                  and this Agreement. After your death, your beneficiary(ies)
                  shall have the right to direct the investment of your Roth IRA
                  assets, subject to the same conditions that applied to you
                  during your lifetime under this Agreement (including, without
                  limitation, Section 9.03 of this article). The right to direct
                  investment of assets may be restricted, however, as provided
                  in Section 9.06. We shall have no discretion to direct any
                  investment in your Roth IRA. We assume no responsibility for
                  rendering investment advice with respect to your Roth IRA, nor
                  will we offer any opinion or judgment to you on matters
                  concerning the value or suitability of any investment or
                  proposed investment for your Roth IRA. In the absence of
                  instructions from you, or if your instructions are not in a
                  form acceptable to us, we shall have the right to hold any
                  uninvested amounts in cash, and we shall have no
                  responsibility to invest uninvested cash unless and until
                  directed by you. We will not exercise the voting rights and
                  other shareholder rights with respect to investments in your
                  Roth IRA unless you or your agent provide timely written
                  directions acceptable to us.
                </p>
                <p>
                  You will select the type of investment for your Roth IRA
                  assets, provided, however, that your selection of investments
                  shall be limited to any investment vehicle obtainable by us,
                  that we are authorized by our charter, articles of
                  incorporation, or bylaws to offer and do in fact, in our sole
                  discretion offer for investment in Roth IRAs. For example,
                  investments may include but shall not be limited to common
                  stocks, government and corporate bonds, mutual funds, the
                  purchase of put options on existing positions and writing of
                  covered listed call options and such other options strategies
                  that we may, from time to time, in our sole discretion make
                  available for Roth IRAs and which strategies are approved for
                  your account by your broker and/or investment advisor.
                  Investments not generating confirmations must be accompanied
                  by additional written instructions and such other
                  documentation as we may, in our sole discretion, require. We
                  shall act as a stockbroker or dealer whenever such services
                  are required. We may, in our sole discretion, make available
                  to you, additional investment offerings, which shall be
                  limited to publicly traded securities, mutual funds, money
                  market instruments, and other investments that are obtainable
                  by us and that we, in our sole discretion, determine that we
                  are capable of holding in the ordinary course of our business.
                  <br />
                  We shall have the power and authority in the administration of
                  this Agreement to do all acts, including by way of
                  illustration but not in limitation of the powers conferred by
                  law, the following:
                </p>
                <ol type="A">
                  <li>
                    Pursuant to your or your agent’s direction, to invest and
                    reinvest all or any part of the assets in securities
                    obtainable through us and to invest in any lawful investment
                    which is administratively acceptable to us without any duty
                    to diversify and without regard to whether such property is
                    authorized by the laws of any jurisdiction for investment by
                    us;
                  </li>
                  <li>
                    Pursuant to your or your agent’s direction, to hold part or
                    all of the uninvested assets or to place the same in a
                    savings account approved by you or purchase a Certificate of
                    Deposit with an institution approved by you;
                  </li>
                  <li>
                    To employ suitable agents and counsel and to pay them
                    reasonable expenses and compensation;
                  </li>
                  <li>
                    Pursuant to your or your agent’s direction, to vote in
                    person or by proxy with respect to securities held by us and
                    to delegate our discretionary power;
                  </li>
                  <li>
                    Pursuant to your or your agent’s direction (and subject to
                    approval of a custodial account for option trading
                    privileges), to write covered listed call options against
                    existing positions, to liquidate or close such option
                    contracts, and to purchase put options on existing long
                    positions (the same securities cannot be used to
                    simultaneously cover more than one position);
                  </li>
                  <li>
                    Pursuant to your or your agent’s direction, to consent to or
                    participate in dissolutions, reorganizations,
                    consolidations, mergers, sales, leases, mortgages, and
                    transfers or other changes affecting securities held by us;
                  </li>
                  <li>
                    To leave any securities or cash for safekeeping or on
                    deposit, with or without interest, with such banks, brokers
                    and other custodians as we may select, and to hold any
                    securities in bearer form or in the name of these banks,
                    brokers and any other custodians or in the name of the
                    custodian without qualification or description or in the
                    name of any nominee; and
                  </li>
                  <li>
                    Prior to the entry of any orders to purchase or sell
                    securities in your account, you or your agent shall approve
                    beforehand all such orders and direct us to implement such
                    instructions. Selling short and executing purchases in an
                    amount greater than available cash are prohibited. All
                    investments outside of the cash account shall be accompanied
                    by additional written instructions.
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  <strong>Beneficiary(ies)</strong> – If you die before you
                  receive all of the amounts in your Roth IRA, payments from
                  your Roth IRA will be made to your beneficiary(ies). We have
                  no obligation to pay to your beneficiaries until such time we
                  are notified of your death by receiving a valid death
                  certificate.
                  <br />
                  You may designate one or more persons or entities as
                  beneficiary(ies) of your Roth IRA. This designation can only
                  be made on a form provided by or acceptable to us, and it will
                  only be effective when it is filed with us during your
                  lifetime. Each beneficiary designation you file with us will
                  cancel all previous designations. The consent of a
                  beneficiary(ies) shall not be required for you to revoke a
                  beneficiary designation. If you have designated both primary
                  and contingent beneficiaries and no primary beneficiary(ies)
                  survives you, the contingent beneficiary(ies) shall acquire
                  the designated share of your Roth IRA. If you do not designate
                  a beneficiary, or if all of your primary and contingent
                  beneficiary(ies) predecease you, your spouse will be the
                  beneficiary, or if there is no spouse living at the time of
                  your death your estate shall be the beneficiary.
                </p>
                <p>
                  If your surviving spouse is the designated beneficiary, your
                  spouse may elect to treat your Roth IRA as his or her own Roth
                  IRA, and would not be subject to the required minimum
                  distribution rules. Your surviving spouse will also be
                  entitled to such additional beneficiary payment options as are
                  granted under the Code or applicable Regulations.
                </p>
                <p>
                  If the beneficiary designated to receive payments hereunder is
                  a minor or person of unsound mind, whether so formally
                  adjudicated or not, we may, at our discretion, make such
                  payments to such person as may be acting as parent, guardian,
                  committee, conservator, trustee or legal representative of
                  such minor or incompetent and the receipt by any such person
                  as selected by us shall be a full and complete discharge of us
                  for any sums so paid.
                </p>
                <p>
                  We reserve the right to, at our discretion, deposit funds in a
                  special savings account established in our name as Custodian
                  for a beneficiary when within six months after any payment is
                  due because we cannot ascertain the whereabouts or the
                  identity of the beneficiary by mailing to the last known
                  address shown on our records, and such beneficiary has not
                  submitted a written claim for such payment before the
                  expiration of said six-month period.
                </p>
                <p>
                  We may allow, if permitted by state law, an original Roth IRA
                  beneficiary(ies) (the beneficiary(ies) who is entitled to
                  receive distribution(s) from an inherited Roth IRA at the time
                  of your death) to name a successor beneficiary(ies) for the
                  inherited Roth IRA. This designation can only be made on a
                  form provided by or acceptable to us, and it will only be
                  effective when it is filed with us during the original Roth
                  IRA beneficiary’s(ies’) lifetime. Each beneficiary designation
                  form that the original Roth IRA beneficiary(ies) files with us
                  will cancel all previous designations. The consent of a
                  successor beneficiary(ies) shall not be required for the
                  original Roth IRA beneficiary(ies) to revoke a successor
                  beneficiary(ies) designation. If the original Roth IRA
                  beneficiary(ies) does not designate a successor
                  beneficiary(ies), his or her estate will be the successor
                  beneficiary. In no event shall the successor beneficiary(ies)
                  be able to extend the distribution period beyond that required
                  for the original Roth IRA beneficiary.
                </p>
                <p>
                  If we so choose, for any reason (e.g., due to limitations of
                  our charter or bylaws), we may require that a beneficiary of a
                  deceased Roth IRA owner take total distribution of all Roth
                  IRA assets by December 31 of the year following the year of
                  death.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Termination of Agreement, Resignation, or Removal of
                    Custodian
                  </strong>
                  – Either party may terminate this Agreement at any time by
                  giving written notice to the other or in another manner and
                  form acceptable to us. We can resign as custodian at any time
                  effective 30 days after we send written notice of our
                  resignation to you. Upon receipt of that notice, you must make
                  arrangements to transfer your Roth IRA to another financial
                  organization. If you do not complete a transfer of your Roth
                  IRA within 30 days from the date we send the notice to you, we
                  may, but shall not be required to, appoint a successor
                  custodian that we choose in our sole discretion. Upon any such
                  successor’s acceptance of appointment, we shall have the right
                  to transfer your Roth IRA assets to such successor trustee or
                  custodian, or we may distribute your Roth IRA assets to you in
                  a single sum. We shall not be liable for any actions or
                  failures to act on the part of any successor trustee or
                  custodian, nor for any tax consequences you may incur that
                  result from the transfer or distribution of your assets
                  pursuant to this section, nor for any losses arising out of
                  such.
                </p>
                <p>
                  If this Agreement is terminated, we may charge to your Roth
                  IRA a reasonable amount of money that we believe is necessary
                  to cover any associated costs, including but not limited to
                  one or more of the following:
                </p>
                <ul>
                  <li>
                    Any fees, expenses, or taxes chargeable against your Roth
                    IRA
                  </li>
                  <li>
                    Any penalties or surrender charges associated with the early
                    withdrawal of any savings instrument or other investment in
                    your Roth IRA
                  </li>
                </ul>
                <p>
                  If we are required to comply with Regulations section
                  1.408-2(e), and we fail to do so, or we are not keeping the
                  records, making the returns, or sending the statements as are
                  required by forms or regulations, the IRS may require us to
                  substitute another trustee or custodian.
                </p>
                <p>
                  We may establish a policy requiring distribution of the entire
                  balance of your Roth IRA to you in cash or property if the
                  balance of your Roth IRA drops below the minimum balance
                  required under the applicable investment or policy
                  established.
                </p>
              </li>
              <li>
                <b>Successor Custodian</b> - If our organization changes its
                name, reorganizes, merges with another organization (or comes
                under the control of any federal or state agency), or if our
                entire organization (or any portion which includes your Roth
                IRA) is bought by another organization, that organization (or
                agency) shall automatically become the trustee or custodian of
                your Roth IRA, but only if it is the type of organization
                authorized to serve as a Roth IRA trustee or custodian.
              </li>
              <li>
                <b>Amendments</b> - We have the right to amend this Agreement at
                any time. Any amendment we make to comply with the Code and
                related regulations does not require your consent. You will be
                deemed to have consented to any other amendment unless, within
                30 days from the date we send the amendment, you notify us in
                writing that you do not consent.
              </li>
              <li>
                <p>
                  <b>Withdrawals or Transfers</b> - All requests for withdrawal
                  or transfer shall be in writing on a form provided by or
                  acceptable to us. The method of distribution must be specified
                  in writing or in any other method acceptable to us. The tax
                  identification number of the recipient must be provided to us
                  before we are obligated to make a distribution. Withdrawals
                  shall be subject to all applicable tax and other laws and
                  regulations, including but not limited to possible early
                  withdrawal penalties or surrender charges, and withholding
                  requirements.
                </p>
                <p>
                  You are not required to take a distribution from your Roth IRA
                  at age 70½. At your death, however, your beneficiary(ies) must
                  begin taking distributions in accordance with Article V and
                  Section 9.07 of this Agreement. We will make no distributions
                  to you from your Roth IRA until you provide us with a written
                  request for a distribution on a form provided by or acceptable
                  to us.
                </p>
              </li>
              <li>
                <b>Transfers from Other Plans</b> - We can, but are not
                obligated to, receive amounts transferred to this Roth IRA from
                the custodian or trustee of another Roth IRA as permitted by the
                Code. In addition, we can, but are not obligated to, accept
                rollovers of eligible rollover distributions from
                employer-sponsored retirement plans as permitted by the Code. We
                reserve the right not to accept any transfer.
              </li>
              <li>
                <b>Liquidation of Assets</b> - We have the right to liquidate
                assets in your Roth IRA if necessary to make distributions or to
                pay fees, expenses, taxes, penalties, or surrender charges
                properly chargeable against your Roth IRA. If you fail to direct
                us as to which assets to liquidate, we will decide, in our
                complete and sole discretion, and you agree not to hold us
                liable for any adverse consequences that result from our
                decision.
              </li>
              <li>
                <b>Restrictions on the Fund</b> - Neither you nor any
                beneficiary may sell, transfer, or pledge any interest in your
                Roth IRA in any manner whatsoever, except as provided by law or
                this Agreement. The assets in your Roth IRA shall not be
                responsible for the debts, contracts, or torts of any person
                entitled to distributions under this Agreement.
              </li>
              <li>
                <p>
                  <strong>What Law Applies</strong> – This Agreement is subject
                  to all applicable federal and state laws and regulations. If
                  it is necessary to apply any state law to interpret and
                  administer this Agreement, the laws of the State of Texas
                  shall govern.
                </p>
                <p>Any court accounting shall be in the courts of Texas.</p>
                <p>
                  If any part of this Agreement is held to be illegal or
                  invalid, the remaining parts shall not be affected. Neither
                  your nor our failure to enforce at any time or for any period
                  of time any of the provisions of this Agreement shall be
                  construed as a waiver of such provisions, or your right or our
                  right thereafter to enforce each and every such provision.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Arbitration – This agreement contains a Predispute
                    Arbitration Clause. By Signing an Arbitration Agreement the
                    Parties agree as follows:
                  </strong>
                </p>
                <p>
                  <strong>
                    a) All parties to this agreement are giving up the right to
                    sue each other in court, including the right to a trial by
                    jury except as provided by the rules of the Arbitration form
                    in which a claim is filed;
                  </strong>
                </p>
                <p>
                  <strong>
                    b) Arbitration awards are generally final and binding; a
                    party’s ability to have a court reverse or modify an
                    arbitration award is very limited.
                  </strong>
                </p>
                <p>
                  <strong>
                    c) The liability of the parties to obtain documents, witness
                    statements and other discovery is generally more limited in
                    Arbitration than in court proceedings;
                  </strong>
                </p>
                <p>
                  <strong>
                    d) The Arbitrators do not have to explain the reason(s) for
                    their award unless in an eligible case, a joint request for
                    an explained decision has been submitted by all parties to
                    the panel at least 20 days prior to the first scheduled
                    hearing date.
                  </strong>
                </p>
                <p>
                  <strong>
                    e) The panel of Arbitrators will typically include a
                    minority of Arbitrators who were or are affiliated with the
                    securities industry.
                  </strong>
                </p>
                <p>
                  <strong>
                    f) The rules of some Arbitration forums may impose time
                    limits for bringing a claim in Arbitration. In some cases, a
                    claim that is ineligible for Arbitration may be brought in
                    court.
                  </strong>
                </p>
                <p>
                  <strong>
                    g) The rules of the Arbitration forum in which the claim is
                    filed, and any amendments thereto, shall be incorporated
                    into this agreement.
                  </strong>
                </p>
                <p>
                  <strong>
                    The following Arbitration Agreement should be read in
                    conjunction with the disclosures above. Any and all
                    controversies, disputes or claims between the Customer and
                    You, or the Introducing Broker and/or Registered Investment
                    Advisor, or the Agents, Representatives, Employees,
                    Directors, Officers, or Control Persons of You or The
                    Introducing Broker and/or Registered Investment Advisor,
                    Arising out of, in connection with, from or with respect to:
                  </strong>
                </p>
                <p>
                  <strong>
                    (a) Any provisions of or the validity of this agreement or
                    any related agreements,
                    <br />
                    (b) The relationship of the parties hereto , or
                    <br />
                    (c) Any controversy arising out of your business, the
                    Introducing Broker and/or Registered Investment Advisor’s
                    business or the Customer’s accounts, shall be conducted
                    pursuant to the code of Arbitration procedure of the
                    Financial Industry Regulatory Authority (“FINRA”).
                    Arbitration must be commenced by service of a written demand
                    for Arbitration or a written Notice of Intention to
                    Arbitrate. The decision and award of the Arbitrator(s) shall
                    be conclusive and binding upon all parties, and any judgment
                    upon any award rendered may be entered in a court having
                    jurisdiction thereof, and neither party shall oppose such
                    entry.
                  </strong>
                </p>
                <p>
                  <strong>
                    No person shall bring a putative or certified class action
                    to arbitration, nor seek to enforce any pre-dispute
                    arbitration agreement against any person who has initiated
                    in court a putative class action; or who is a member of a
                    putative class who has not opted out of the class with
                    respect to any claims encompassed by the putative class
                    action until: (i) the class certification is denied; or (ii)
                    the class is de-certified; or (iii) the customer is excluded
                    from the class by the court. Such forbearance to enforce an
                    agreement to arbitrate shall not constitute a waiver of any
                    rights under this agreement except to the extent stated
                    herein.
                  </strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>Assignability</strong> – This Agreement shall inure to
                  the benefit of our successors and assigns, shall be binding on
                  you, your heirs, executors, administrators and assigns, and
                  shall be governed by the laws of the State of Texas.
                </p>
              </li>
              <li>
                <p>
                  <strong>Payment for Order Flow/Order Routing</strong> –
                  “Payment for order flow” is a common and widespread industry
                  practice whereby a brokerage firm receives monetary or
                  non-monetary remuneration in return for the routing of
                  customer orders to a designated exchange, market maker, dealer
                  or market center for execution. Apex Clearing receives payment
                  for order flow on certain transactions in the form of rebates,
                  monetary compensation or an inter-company transfer of funds.
                  Payment for order flow is considered to be compensation to
                  Apex Clearing. Your broker and/or the introducing firm that
                  clears its trades through Apex Clearing may or may not be
                  compensated for such orders. The source and nature of any
                  compensation received in connection with a specific
                  transaction will be furnished upon written request of the
                  customer.
                </p>
                <p>
                  Absent specific instructions from customers, Apex Clearing
                  automatically routes orders in over-the-counter (“OTC”)
                  securities to selected OTC market makers. Selected
                  exchange-traded securities may be routed to affiliated
                  specialists, regional exchanges or designated third-market
                  dealers. All orders are routed to an exchange, market-maker,
                  dealer or market center that matches or improves upon the
                  displayed national best bid or offer for the particular
                  security at the time the order is processed. Price improvement
                  opportunities, or execution at prices superior to the
                  displayed national best bid or offer, may be available for
                  certain transactions in NASDAQ and listed securities from
                  execution destinations to which orders are routed.
                </p>
              </li>
              <li>
                <p>
                  <strong>Accounting</strong> – Within 90 days from the close of
                  each custodial account year, We shall render an accounting
                  (valuing the assets fair market value) to you, which
                  accounting may consist of copies of regularly issued
                  broker-dealer statements to you. In the absence of the filing
                  in writing with us of exceptions or objections to any such
                  accounting, within 30 days after the mailing of such
                  accounting, you shall be deemed to have approved such
                  accounting. In such case, or upon your written approval, we
                  shall be released, relieved and discharged with respect to all
                  matters and things set forth in such accounting as though such
                  accounting had been settled by the decree of a court of
                  competent jurisdiction. No person other than you may require
                  an accounting or bring any action against us with respect to
                  this agreement or our actions as Custodian.
                </p>
                <p>
                  We reserve the right to apply to a court of competent
                  jurisdiction for judicial settlement of our accounts, for
                  determination of any questions of construction which may arise
                  or for instructions. You shall be the only necessary party
                  defendant to such action except we may, if we so elect, bring
                  in as a party defendant any other person or persons.
                </p>
              </li>
            </ol>
          </div>

          <div></div>
          <div></div>
          <div className="terms-label">GENERAL INSTRUCTIONS</div>
          <div>
            <p>
              <em>
                Section References are to the Internal Revenue Code unless
                otherwise noted.
              </em>
            </p>
          </div>
          <div>
            <p className="terms-label">PURPOSE OF FORM</p>
          </div>
          <div>
            <p>
              Form 5305-RA is a model custodial account agreement that meets the
              requirements of section 408A. However, only Articles I through
              VIII have been reviewed by the IRS. A Roth Individual Retirement
              Account (Roth IRA) is established after the form is fully executed
              by both the individual (Depositor) and the Custodian. This account
              must be created in the United States for the exclusive benefit of
              the Depositor and his or her beneficiaries.
            </p>
          </div>
          <div>
            <p>
              Do not file Form 5305-RA with the IRS. Instead, keep it with your
              records.
            </p>
          </div>
          <div>
            <p>
              Unlike contributions to Traditional individual retirement
              arrangements, contributions to a Roth IRA are not deductible from
              the Depositor’s gross income; and distributions after five years
              that are made when the Depositor is 59½ years of age or older or
              on account of death, disability, or the purchase of a home by a
              first- time homebuyer (limited to $10,000), are not includible in
              gross income. For more information on Roth IRAs, including the
              required disclosures the Custodian must give the Depositor, see
              Pub. 590-A, Contributions to Individual Retirement Arrangements
              (IRAs), and Pub. 590-B, Distributions from Individual Retirement
              Arrangements (IRAs).
            </p>
          </div>
          <div>
            <p className="terms-label">DEFINITIONS</p>
          </div>
          <div>
            <p>
              <strong>Custodian</strong> – The custodian must be a bank or
              savings and loan association, as defined in section 408(n), or any
              person who has the approval of the IRS to act as custodian.
              <br />
              <strong>Depositor</strong> – The Depositor is the person who
              establishes the custodial account.
            </p>
          </div>
          <div>
            <p className="terms-label">SPECIFIC INSTRUCTIONS</p>
          </div>
          <div>
            <p>
              <strong>Article I</strong> – The Depositor may be subject to a 6%
              tax on excess contributions if (1) contributions to other
              individual retirement arrangements of the Depositor have been made
              for the same tax year, (2) the Depositor’s adjusted gross income
              exceeds the applicable limits in Article II for the tax year, or
              (3) the Depositor’s and spouse’s compensation is less than the
              amount contributed by or on behalf of them for the tax year.
            </p>
          </div>
          <div>
            <p>
              <strong>Article V</strong> – This article describes how
              distributions will be made from the Roth IRA after the Depositor’s
              death. Elections made pursuant to this article should be reviewed
              periodically to ensure they correspond to the Depositor’s intent.
              Under paragraph 3 of Article V, the Depositor’s spouse is treated
              as the owner of the Roth IRA upon the death of the Depositor,
              rather than as the beneficiary. If the spouse is to be treated as
              the beneficiary, and not the owner, an overriding provision should
              be added to Article IX.
            </p>
          </div>
          <div>
            <p>
              <strong>Article IX</strong> – Article IX and any that follow it
              may incorporate additional provisions that are agreed to by the
              Depositor and Custodian to complete the agreement. They may
              include, for example, definitions, investment powers, voting
              rights, exculpatory provisions, amendment and termination, removal
              of the Custodian, Custodian’s fees, state law requirements,
              beginning date of distributions, accepting only cash, treatment of
              excess contributions, prohibited transactions with the Depositor,
              etc. Attach additional pages if necessary.
            </p>
          </div>
          <div>
            <p className="terms-label">DISCLOSURE STATEMENT</p>
          </div>
          <div>
            <p className="terms-label">RIGHT TO REVOKE YOUR IRA</p>
          </div>
          <div>
            <p>
              You have the right to revoke your Roth IRA within seven (7) days
              of the receipt of the Disclosure Statement. If revoked, you are
              entitled to a full return of the contribution you made to your
              Roth IRA. The amount returned to you would not include an
              adjustment for such items as sales commissions, administrative
              expenses, or fluctuation in market value. You may make this
              revocation only by mailing or delivering a written notice to the
              IRA Administrator, Apex Clearing Corporation, 350 N. St. Paul
              Street 1300, Dallas, TX, 75201.
            </p>
          </div>
          <div>
            <p>
              <strong>
                If you send your notice by first class mail, your revocation
                will be deemed mailed as of the date of the postmark, or if sent
                by certified or registered mail, it shall be deemed to be mailed
                as of the date of certification or registration. If mailed, the
                written notice of revocation shall be mailed in the United
                States in an envelope, or other appropriate wrapper, first-class
                mail with the postage prepaid.
              </strong>
            </p>
          </div>
          <div>
            <p>
              <strong>
                If you have any questions about the procedure for revoking your
                Roth IRA, please call the Custodian at the telephone number
                listed on the Application.
              </strong>
            </p>
          </div>
          <div>
            <p className="terms-label">REQUIREMENTS OF A ROTH IRA</p>
          </div>
          <div>
            <p>
              A. <strong>Cash Contributions</strong> – Your contribution must be
              in cash, unless it is a rollover or conversion contribution.
            </p>
          </div>
          <div>
            <p>
              B. <strong>Maximum Contribution</strong> – The total amount you
              may contribute to a Roth IRA for any taxable year cannot exceed
              the lesser of 100 percent of your compensation or $5,500 for 2017
              and 2018, with possible cost-of-living adjustments thereafter. If
              you also maintain a Traditional IRA (i.e., an IRA subject to the
              limits of Internal Revenue Code (Code) sections 408(a) or 408(b)),
              the maximum contribution to your Roth IRAs is reduced by any
              contributions you make to your Traditional IRAs. Your total annual
              contribution to all Traditional IRAs and Roth IRAs cannot exceed
              the lesser of the dollar amounts described above or 100 percent of
              your compensation.
            </p>
          </div>
          <div>
            <p>
              Your Roth IRA contribution is further limited if your modified
              adjusted gross income (MAGI) equals or exceeds $186,000 (for 2017)
              or $189,000 (for 2018) if you are a married individual filing a
              joint income tax return, or equals or exceeds $118,000 (for 2017)
              or $120,000 (for 2018) if you are a single individual. Married
              individuals filing a joint income tax return with MAGI equaling or
              exceeding $196,000 (for 2017) or $199,000 (for 2018) may not fund
              a Roth IRA. Married individuals filing a separate income tax
              return with MAGI equaling or exceeding $10,000 may not fund a Roth
              IRA. The MAGI limits described above are subject to cost-of-living
              increases for tax years beginning after 2018.
            </p>
          </div>
          <div>
            <p>
              If you are married filing a joint income tax return and your MAGI
              is between the applicable MAGI phase-out range for the year, your
              maximum Roth IRA contribution is determined as follows:
            </p>
            <ol type="1">
              <li>
                Begin with the appropriate MAGI phase- out maximum for the
                applicable year and subtract your MAGI from it;
              </li>
              <li>
                Divide the result by the difference between the phase-out range
                maximum and minimum; and
              </li>

              <li>
                Multiply this number by the maximum allowable contribution for
                the year, including catch-up contributions if you are age 50 or
                older. For example, if you are age 30 and your MAGI is $191,000,
                your maximum Roth IRA contribution for 2017 is $2,750 ([$196,000
                minus $191,000] divided by $10,000 and multiplied by $5,500).
              </li>
            </ol>
          </div>
          <div>
            <p>
              If you are single and your MAGI is between the applicable MAGI
              phase-out for the year, your maximum Roth IRA contribution is
              determined as follows: (1) Begin with the appropriate MAGI
              phase-out maximum for the applicable year and subtract your MAGI
              from it; (2) divide the result by the difference between the
              phase-out range maximum and minimum; and (3) multiply this number
              by the maximum allowable contribution for the year, including
              catch-up contributions if you are age 50 or older. For example, if
              you are age 30 and your MAGI is $121,000, your maximum Roth IRA
              contribution for 2017 is $4,400 ([$133,000 minus $121,000] divided
              by $15,000 and multiplied by $5,500).
            </p>
          </div>
          <div>
            <p>
              C. <strong>Contribution Eligibility</strong> – You are eligible to
              make a regular contribution to your Roth IRA, regardless of your
              age, if you have compensation and your MAGI is below the maximum
              threshold. Your Roth IRA contribution is not limited by your
              participation in an employer-sponsored retirement plan, other than
              a Traditional IRA.
            </p>
          </div>
          <div>
            <p>
              D. <strong>Catch-Up Contribution</strong> – If you are age 50 or
              older by the close of the taxable year, you may make an additional
              contribution to your Roth IRA. The maximum additional contribution
              is $1,000 per year.
            </p>
          </div>
          <div>
            <p>
              E. <strong>Nonforfeitability</strong> – Your interest in your Roth
              IRA is nonforfeitable.
            </p>
          </div>
          <div>
            <p>
              F. <strong>Eligible Custodians</strong> – The Custodian of your
              Roth IRA must be a bank, savings and loan association, credit
              union, or a person or entity approved by the Secretary of the
              Treasury.
            </p>
          </div>
          <div>
            <p>
              G. <strong>Commingling Assets</strong> – The assets of your Roth
              IRA cannot be commingled with other property except in a common
              trust fund or common investment fund.
            </p>
          </div>
          <div>
            <p>
              H. <strong>Life Insurance</strong> – No portion of your Roth IRA
              may be invested in life insurance contracts.
            </p>
          </div>
          <div>
            <p>
              I. <strong>Collectibles</strong> – You may not invest the assets
              of your Roth IRA in collectibles (within the meaning of Code
              section 408(m)). A collectible is defined as any work of art, rug
              or antique, metal or gem, stamp or coin, alcoholic beverage, or
              other tangible personal property specified by the Internal Revenue
              Service (IRS). However, specially minted United States gold and
              silver coins, and certain state- issued coins are permissible
              investments. Platinum coins and certain gold, silver, platinum or
              palladium bullion (as described in Code section 408(m)(3)) are
              also permitted as Roth IRAinvestments.
            </p>
          </div>
          <div>
            <p>
              J. <strong>Beneficiary Payouts</strong> – Your designated
              beneficiary is determined based on the beneficiary(ies) designated
              as of the date of your death who remains your beneficiary(ies) as
              of September 30 of the year following the year of your death. The
              entire amount remaining in your account will, at the election of
              your beneficiary(ies), either
            </p>
            <ol type="1">
              <li>
                Be distributed by December 31 of the year containing the fifth
                anniversary of your death, or
              </li>
              <li>
                Be distributed over the remaining life expectancy of your
                designated beneficiary(ies).
              </li>
            </ol>
          </div>
          <div>
            <p>
              If your spouse is your sole designated beneficiary, he or she must
              elect either option (1) or (2) by the earlier of December 31 of
              the year containing the fifth anniversary of your death, or
              December 31 of the year you would have attained age 70½. Your
              designated beneficiary(ies), other than a spouse who is the sole
              designated beneficiary, must elect either option (1) or (2) by
              December 31 of the year following the year of your death. If no
              election is made, distribution will be calculated in accordance
              with option (2). In the case of distributions under option (2),
              distributions must commence by December 31 of the year following
              the year of your death. Generally if your spouse is the designated
              beneficiary, distributions need not commence until December 31 of
              the year you would have attained age 70½, if later. If a
              beneficiary(ies) other than an individual or qualified trust as
              defined in the Regulations is named, you will be treated as having
              no designated beneficiary(ies) of your Roth IRA for purposes of
              determining the distribution period. If there is no designated
              beneficiary of your Roth IRA, the entire Roth IRA must be
              distributed by December 31 of the year containing the fifth
              anniversary of your death.
            </p>
          </div>
          <div>
            <p>
              A spouse who is the sole designated beneficiary of your entire
              Roth IRA will be deemed to elect to treat your Roth IRA as his or
              her own by either (1) making contributions to your Roth IRA or (2)
              failing to timely remove a required minimum distribution from your
              Roth IRA. Regardless of whether or not the spouse is the sole
              designated beneficiary of your Roth IRA, a spouse beneficiary may
              roll over his or her share of the assets to his or her own Roth
              IRA.
            </p>
          </div>
          <div>
            <p>
              If we so choose, for any reason (e.g., due to limitations of our
              charter or bylaws), we may require that a beneficiary of a
              deceased Roth IRA owner take total distribution of all Roth IRA
              assets by December 31 of the year following the year of death.
            </p>
          </div>
          <div>
            <p>
              If your beneficiary fails to remove a required minimum
              distribution after your death, an additional penalty tax of 50
              percent is imposed on the amount of the required minimum
              distribution that should have been taken but was not. Your
              beneficiary must file IRS Form 5329 along with his or her income
              tax return to report and remit any additional taxes to the IRS.
            </p>
          </div>
          <div>
            <p className="terms-label">
              INCOME TAX CONSEQUENCES OF ESTABLISHING A ROTH IRA
            </p>
          </div>
          <div>
            <p>
              A. <strong>Contributions Not Deducted</strong> – No deduction is
              allowed for Roth IRA contributions, including transfers, rollovers
              and conversion contributions.
            </p>
          </div>
          <div>
            <p>
              B. <strong>Contribution Deadline</strong> – The deadline for
              making a Roth IRA contribution is your tax return due date (not
              including extensions). You may designate a contribution as a
              contribution for the preceding taxable year in a manner acceptable
              to us. For example, if you are a calendar year taxpayer, and you
              make your Roth IRA contribution on or before April 15, your
              contribution is considered to have been made for the previous tax
              year if you designate it as such.
            </p>
          </div>
          <div>
            <p>
              If you are a member of the Armed Forces serving in a combat zone,
              hazardous duty area, or contingency operation, you may have an
              extended contribution deadline of 180 days after the last day
              served in the area. In addition, your contribution deadline for a
              particular tax year is also extended by the number of days that
              remained to file that year’s tax return as of the date you entered
              the combat zone. This additional extension to make your Roth IRA
              contribution cannot exceed the number of days between January 1
              and your tax filing deadline, not including extensions.
            </p>
          </div>
          <div>
            <p>
              C. <strong>Tax Credit for Contributions</strong> – You may be
              eligible to receive a tax credit for your Roth IRA contributions.
              This credit may not exceed $1,000 in a given year. You may be
              eligible for this tax credit if you are:
            </p>
            <ul>
              <li>age 18 or older as of the close of the taxable year,</li>
              <li>not a dependent of another taxpayer, and</li>
              <li>not a full-time student.</li>
            </ul>
          </div>
          <div>
            <p>
              The credit is based upon your income (see chart below), and will
              range from 0 to 50 percent of eligible contributions. In order to
              determine the amount of your contributions, add all of the
              contributions made to your Roth IRA and reduce these contributions
              by any distributions that you have taken during the testing
              period. The testing period begins two years prior to the year for
              which the credit is sought and ends on the tax return due date
              (including extensions) for the year for which the credit is
              sought. In order to determine your tax credit, multiply the
              applicable percentage from the chart below by the amount of your
              contributions that do not exceed $2,000.
            </p>
          </div>
          <div>
            <p className="terms-label">2019 Adjusted Gross Income*</p>
          </div>
          <div>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Joint Return</th>
                    <th>Head of a Household</th>
                    <th>All Other Cases</th>
                    <th>Applicable Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>$1–38,500</td>
                    <td>$1–28,875</td>
                    <td>$1–19,250</td>
                    <td>50%</td>
                  </tr>
                  <tr>
                    <td>$38,501–41,500</td>
                    <td>$28,876–31,125</td>
                    <td>$19,251–20,750</td>
                    <td>20%</td>
                  </tr>
                  <tr>
                    <td>$41,501–64,000</td>
                    <td>$31,126–48,000</td>
                    <td>$20,751–32,000</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>Over $64,000</td>
                    <td>Over $48,000</td>
                    <td>Over $32,000</td>
                    <td>0%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <p className="terms-label">2020 Adjusted Gross Income*</p>
          </div>
          <div>
            <div className="table-wrapper">
              <table>
                <thead>
                  <tr>
                    <th>Joint Return</th>
                    <th>Head of a Household</th>
                    <th>All Other Cases</th>
                    <th>Applicable Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>$1–38,500</td>
                    <td>$1–28,875</td>
                    <td>$1–19,250</td>
                    <td>50%</td>
                  </tr>
                  <tr>
                    <td>$38,501–41,500</td>
                    <td>$28,876–31,125</td>
                    <td>$19,251–20,750</td>
                    <td>20%</td>
                  </tr>
                  <tr>
                    <td>$41,501–64,000</td>
                    <td>$31,126–48,000</td>
                    <td>$20,751–32,000</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>Over $64,000</td>
                    <td>Over $48,000</td>
                    <td>Over $32,000</td>
                    <td>0%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <p>
              <strong>
                Adjusted gross income (AGI) includes foreign earned income and
                income from Guam, America Samoa, North Mariana Islands, and
                Puerto Rico. AGI limits are subject to cost-of-living
                adjustments each year.
              </strong>
            </p>
          </div>
          <div>
            <p>
              D. <strong>Excess Contributions</strong> – An excess contribution
              is any amount that is contributed to your Roth IRA that exceeds
              the amount that you are eligible to contribute. If the excess is
              not corrected timely, an additional penalty tax of six percent
              will be imposed upon the excess amount. The procedure for
              correcting an excess is determined by the timeliness of the
              correction as identified below.
            </p>

            <ol type={"1"}>
              <li>
                Removal Before Your Tax Filing Deadline. An excess contribution
                may be corrected by withdrawing the excess amount, along with
                the earnings attributable to the excess, before your tax filing
                deadline, including extensions, for the year for which the
                excess contribution was made. An excess withdrawn under this
                method is not taxable to you, but you must include the earnings
                attributable to the excess in your taxable income in the year in
                which the contribution was made. The six percent excess
                contribution penalty tax will be avoided.
              </li>
              <li>
                Removal After Your Tax Filing Deadline. If you are correcting an
                excess contribution after your tax filing deadline, including
                extensions, remove only the amount of the excess contribution.
                The six percent excess contribution penalty tax will be imposed
                on the excess contribution for each year it remains in the Roth
                IRA. An excess withdrawal under this method is not taxable to
                you.
              </li>
              <li>
                Carry Forward to a Subsequent Year. If you do not withdraw the
                excess contribution, you may carry forward the contribution for
                a subsequent tax year. To do so, you under-contribute for that
                tax year and carry the excess contribution amount forward to
                that year on your tax return. The six percent excess
                contribution penalty tax will be imposed on the excess amount
                for each year that it remains as an excess contribution at the
                end of the year.
              </li>
            </ol>
          </div>
          <div>
            <p>
              You must file IRS Form 5329 along with your income tax return to
              report and remit any additional taxes to the IRS.
            </p>
          </div>
          <div>
            <p>
              E. <strong>Tax-Deferred Earnings</strong> – The investment
              earnings of your Roth IRA are not subject to federal income tax as
              they accumulate in your Roth IRA. In addition, distributions of
              your Roth IRA earnings will be free from federal income tax if you
              take a qualified distribution, as described below.
            </p>
          </div>
          <div>
            <p>
              F. <strong>Taxation of Distributions</strong> – The taxation of
              Roth IRA distributions depends on whether the distribution is a
              qualified distribution or a nonqualified distribution.
            </p>

            <ol type={"1"}>
              <li>
                Qualified Distributions. Qualified distributions from your Roth
                IRA (both the contributions and earnings) are not included in
                your income. A qualified distribution is a distribution that is
                made after the expiration of the five-year period beginning
                January 1 of the first year for which you made a contribution to
                any Roth IRA (including a conversion from a Traditional IRA),
                and is made on account of one of the following events.
                <ul>
                  <li>Attainment of age 59½</li>
                  <li>Disability</li>
                  <li>First-time homebuyer purchase</li>
                  <li>Death</li>
                </ul>
                For example, if you made a contribution to your Roth IRA for
                2007, the five-year period for determining whether a
                distribution is a qualified distribution is satisfied as of
                January 1, 2012.
              </li>
              <li>
                Nonqualified Distributions. If you do not meet the requirements
                for a qualified distribution, any earnings you withdraw from
                your Roth IRA will be included in your gross income and, if you
                are under age 59½, may be subject to an early distribution
                penalty tax. However, when you take a distribution, the amounts
                you contributed annually to any Roth IRA and any military death
                gratuity or Service members’ Group Life Insurance (SGLI)
                payments that you rolled over to a Roth IRA, will be deemed to
                be removed first, followed by conversion and employer-sponsored
                retirement plan rollover contributions made to any Roth IRA on a
                first-in, first-out basis. Therefore, your nonqualified
                distributions will not be taxable to you until your withdrawals
                exceed the amount of your annual contributions, rollovers of
                your military death gratuity or SGLI payments, and your
                conversions and employer-sponsored retirement plan rollovers.
              </li>
            </ol>
          </div>
          <div>
            <p>
              G. <strong>Income Tax Withholding</strong> – Any nonqualified
              withdrawal of earnings from your Roth IRA may be subject to
              federal income tax withholding. You may, however, elect not to
              have withholding apply to your Roth IRA withdrawal. If withholding
              is applied to your withdrawal, not less than 10 percent of the
              amount withdrawn must be withheld.
            </p>
          </div>
          <div>
            <p>
              H. <strong>Early Distribution Penalty Tax</strong> – If you are
              under age 59½ and receive a nonqualified Roth IRA distribution, an
              additional early distribution penalty tax of 10 percent generally
              will apply to the amount includible in income in the year of the
              distribution. If you are under age 59½ and receive a distribution
              of conversion amounts or employer-sponsored retirement plan
              rollover amounts within the five-year period beginning with the
              year in which the conversion or employer-sponsored retirement plan
              rollover occurred, an additional early distribution penalty tax of
              10 percent generally will apply to the amount of the distribution.
              The additional early distribution penalty tax of 10 percent
              generally will not apply if one of the following exceptions apply.
            </p>

            <ol type={"1"}>
              <li>
                Death. After your death, payments made to your beneficiary are
                not subject to the 10 percent early distribution penalty tax.
              </li>
              <li>
                Disability. If you are disabled at the time of distribution, you
                are not subject to the additional 10 percent early distribution
                penalty tax. In order to be disabled, a physician must determine
                that your impairment can be expected to result in death or to be
                of long, continued, and indefinite duration.
              </li>
              <li>
                Substantially Equal Periodic Payments. You are not subject to
                the additional 10 percent early distribution penalty tax if you
                are taking a series of substantially equal periodic payments (at
                least annual payments) over your life expectancy or the joint
                life expectancy of you and your beneficiary. You must continue
                these payments for the longer of five years or until you reach
                age 59½.
              </li>
              <li>
                Unreimbursed Medical Expenses. If you take payments to pay for
                unreimbursed medical expenses that exceed a specified percentage
                of your adjusted gross income, you will not be subject to the 10
                percent early distribution penalty tax. For further detailed
                information and effective dates you may obtain IRS Publication
                590-B, Distributions from Individual Retirement Arrangements
                (IRAs), from the IRS. The medical expenses may be for you, your
                spouse, or any dependent listed on your tax return.
              </li>
              <li>
                Health Insurance Premiums. If you are unemployed and have
                received unemployment compensation for 12 consecutive weeks
                under a federal or state program, you may take payments from
                your Roth IRA to pay for health insurance premiums without
                incurring the 10 percent early distribution penalty tax.
              </li>
              <li>
                Higher Education Expenses. Payments taken for certain qualified
                higher education expenses for you, your spouse, or the children
                or grandchildren of you or your spouse, will not be subject to
                the 10 percent early distribution penalty tax.
              </li>
              <li>
                First-Time Homebuyer. You may take payments from your Roth IRA
                to use toward qualified acquisition costs of buying or building
                a principle residence. The amount you may take for this reason
                may not exceed a lifetime maximum of $10,000. The payment must
                be used for qualified acquisition costs within 120 days of
                receiving the distribution.
              </li>
              <li>
                IRS Levy. Payments from your Roth IRA made to the U.S.
                government in response to a federal tax levy are not subject to
                the 10 percent early distribution penalty tax.
              </li>
              <li>
                Qualified Reservist Distributions. If you are a qualified
                reservist member called to active duty for more than 179 days or
                an indefinite period, the payments you take from your Roth IRA
                during the active duty period are not subject to the 10 percent
                early distribution penalty tax.
              </li>
            </ol>
          </div>
          <div>
            <p>
              You must file IRS Form 5329 along with your income tax return to
              the IRS to report and remit any additional taxes or to claim a
              penalty tax exception.
            </p>
          </div>
          <div>
            <p>
              I. <strong>Required Minimum Distributions.</strong> – You are not
              required to take distributions from your Roth IRA at age 70½ (as
              required for Traditional and savings incentive match plan for
              employees of small employers (SIMPLE) IRAs). However, your
              beneficiaries generally are required to take distributions from
              your Roth IRA after your death. See the section titled Beneficiary
              Payouts in this disclosure statement regarding beneficiaries’
              required minimum distributions.
            </p>
          </div>
          <div>
            <p>
              J. <strong>Rollovers and Conversions</strong> – Your Roth IRA may
              be rolled over to another Roth IRA of yours, may receive rollover
              contributions, or may receive conversion contributions, provided
              that all of the applicable rollover or conversion rules are
              followed. Rollover is a term used to describe a movement of cash
              or other property to your Roth IRA from another Roth IRA, or from
              your employer’s qualified retirement plan, 403(a) annuity, 403(b)
              tax-sheltered annuity, 457(b) eligible governmental deferred
              compensation plan, or federal Thrift Savings Plan. Conversion is a
              term used to describe the movement of Traditional IRA or SIMPLE
              IRA assets to a Roth IRA. A conversion generally is a taxable
              event. The general rollover and conversion rules are summarized
              below. These transactions are often complex. If you have any
              questions regarding a rollover or conversion, please see a
              competent taxadvisor.
            </p>

            <ol type={"1"}>
              <li>
                <strong>Roth IRA-to-Roth IRA Rollovers.</strong>
                Assets distributed from your Roth IRA may be rolled over to the
                same Roth IRA or another Roth IRA of yours if the requirements
                of IRC Sec. 408(d)(3) are met. A proper Roth IRA-to-Roth IRA
                rollover is completed if all or part of the distribution is
                rolled over not later than 60 days after the distribution is
                received. In the case of a distribution for a first-time
                homebuyer where there was a delay or cancellation of the
                purchase, the 60-day rollover period may be extended to 120
                days. Roth IRA assets may not be rolled over to other types of
                IRAs (e.g., Traditional IRA, SIMPLE IRA), or employer- sponsored
                retirement plans.You are permitted to roll over only one
                distribution from an IRA (Traditional, Roth, or SIMPLE) in a
                12-month period, regardless of the number of IRAs you own. A
                distribution may be rolled over to the same IRA or to another
                IRA that is eligible to receive the rollover. For more
                information on rollover limitations, you may wish to obtain IRS
                Publication 590-B, Distributions from Individual Retirement
                Arrangements (IRAs), from the IRS or refer to the IRS website at
                <a href="http://www.irs.gov">www.irs.gov</a>.
              </li>
              <li>
                <strong>Traditional IRA-to-Roth IRA Conversions.</strong> If you
                convert to a Roth IRA, the amount of the conversion from your
                Traditional IRA to your Roth IRA will be treated as a
                distribution for income tax purposes, and is includible in your
                gross income (except for any nondeductible contributions).
                Although the conversion amount generally is included in income,
                the 10 percent early distribution penalty tax will not apply to
                conversions from a Traditional IRA to a Roth IRA, regardless of
                whether you qualify for any exceptions to the 10 percent early
                distribution penalty tax. If you are age 70½ or older, you must
                remove your required minimum distribution before converting your
                Traditional IRA.
              </li>
              <li>
                <strong>Simple IRA-to-Roth IRA Conversions.</strong> You are
                eligible to convert all or any portion of your existing SIMPLE
                IRA into your Roth IRA, provided two years have passed since you
                first participated in a SIMPLE IRA plan sponsored by your
                employer. The amount of the conversion from your SIMPLE IRA to
                your Roth IRA will be treated as a distribution for income tax
                purposes and is includible in your gross income. Although the
                conversion amount generally is included in income, the 10
                percent early distribution penalty tax will not apply to
                conversions from a SIMPLE IRA to a Roth IRA, regardless of
                whether you qualify for any exceptions to the 10 percent early
                distribution penalty tax. If you are age 70½ or older you must
                remove your required minimum distribution before converting your
                SIMPLE IRA.
              </li>
              <li>
                <strong>Rollovers of Roth Elective Deferrals.</strong>
                Roth elective deferrals distributed from a 401(k) cash or
                deferred arrangement, 403(b) tax- sheltered annuity, 457(b)
                eligible governmental deferred compensation plan, or federal
                Thrift Savings Plan, may be rolled into your Roth IRA.
              </li>
              <li>
                <strong>
                  Employer-Sponsored Retirement Plan-to-Roth IRA Rollovers.
                </strong>
                You may roll over, directly or indirectly, any eligible rollover
                distribution from an eligible employer-sponsored retirement plan
                to your Roth IRA. An eligible rollover distribution is defined
                generally as any distribution from a qualified retirement plan,
                403(a) annuity, 403(b) tax-sheltered annuity, 457(b) eligible
                governmental deferred compensation plan, or federal Thrift
                Savings Plan unless it is a required minimum distribution,
                hardship distribution, part of a certain series of substantially
                equal periodic payments, corrective distributions of excess
                contributions, excess deferrals, excess annual additions and any
                income allocable to the excess, deemed loan distribution,
                dividends on employer securities, or the cost of life insurance
                coverage. If you are a spouse, nonspouse, or qualified trust
                beneficiary who has inherited a qualified retirement plan,
                403(a) annuity, 403(b) tax-sheltered annuity, or 457(b) eligible
                governmental deferred compensation plan, you may be eligible to
                directly roll over the assets to an inherited Roth IRA. The
                inherited Roth IRA is subject to the beneficiary distribution
                requirements.
                <br />
                If you are conducting an indirect rollover, your eligible
                rollover distribution generally must be rolled over to your Roth
                IRA not later than 60 days after you receive the distribution.
                In the case of a plan loan offset due to plan termination or
                severance from employment, the deadline for completing the
                rollover is your tax return due date (including extensions) for
                the year in which the offset occurs.
                <br />
                Although the rollover amount generally is included in income,
                the 10 percent early distribution penalty tax will not apply to
                rollovers from eligible employer-sponsored retirement plans to a
                Roth IRA or inherited Roth IRA, regardless of whether you
                qualify for any exceptions to the 10 percent early distribution
                penalty tax.
              </li>
              <li>
                <strong>
                  Beneficiary Rollovers From 401(k), 403(b), or 457(b) Eligible
                  Governmental Plans Containing Roth Elective Deferrals.
                </strong>
                If you are a spouse, nonspouse, or qualified trust beneficiary
                of a deceased 401(k), 403(b), or 457(b) eligible governmental
                deferred compensation plan participant who had made Roth
                elective deferrals to the plan, you may directly roll over the
                Roth elective deferrals and their earnings to an inherited Roth
                IRA. The Roth IRA must be maintained as an inherited Roth IRA,
                subject to the beneficiary distribution requirements
              </li>
              <li>
                <strong>Rollovers of Military Death Benefits</strong>
                If you receive or have received a military death gratuity or a
                payment from the SGLI program, you may be able to roll over the
                proceeds to your Roth IRA. The rollover contribution amount is
                limited to the sum of the death benefits or SGLI payment
                received, less any such amount that was rolled over to a
                Coverdell education savings account. Proceeds must be rolled
                over within one year of receipt of the gratuity or SGLI payment
                for deaths occurring on or after June 17, 2008. Any amount that
                is rolled over under this provision is considered nontaxable
                basis in your Roth IRA.
              </li>
              <li>
                <strong>Qualified HSA Funding Distribution.</strong>
                If you are eligible to contribute to a health savings account
                (HSA), you may be eligible to take a one- time tax-free
                qualified HSA funding distribution from your Roth IRA and
                directly deposit it to your HSA. The amount of the qualified HSA
                funding distribution may not exceed the maximum HSA contribution
                limit in effect for the type of high deductible health plan
                coverage (i.e., single or family coverage) that you have at the
                time of the deposit, and counts toward your HSA contribution
                limit for that year. For further detailed information, you may
                wish to obtain IRS Publication 969, Health Savings Accounts and
                Other Tax-Favored Health Plans.
              </li>
              <li>
                <strong>
                  Rollovers of Settlement Payments From Bankrupt Airlines.
                </strong>
                If you are a qualified airline employee who has received a
                qualified airline settlement payment from a commercial airline
                carrier under the approval of an order of a federal bankruptcy
                court in a case filed after September 11, 2001, and before
                January 1, 2007, you are allowed to roll over any portion of the
                proceeds into your Roth IRA within 180 days after receipt of
                such amount, or by a later date if extended by federal law. For
                further detailed information and effective dates you may obtain
                IRS Publication 590-A, Contributions to Individual Retirement
                Arrangements (IRAs), from the IRS or refer to the IRS website at
                www.irs.gov.
              </li>
              <li>
                <strong>Rollover of Exxon Valdez Settlement Payments.</strong>
                If you receive a qualified settlement payment from Exxon Valdez
                litigation, you may roll over the amount of the settlement, up
                to $100,000, reduced by the amount of any qualified Exxon Valdez
                settlement income previously contributed to a Traditional or
                Roth IRA or eligible retirement plan in prior taxable years. You
                will have until your tax return due date (not including
                extensions) for the year in which the qualified settlement
                income is received to make the rollover contribution. To obtain
                more information on this type of rollover, you may wish to visit
                the IRS website at www.irs.gov.
              </li>
              <li>
                <strong>Rollover of IRS Levy.</strong>
                If you receive a refund of eligible retirement plan assets that
                had been wrongfully levied, you may roll over the amount
                returned up until your tax return due date (not including
                extensions) for the year in which the money was returned.
              </li>
              <li>
                <strong>Written Election.</strong>
                At the time you make a rollover or conversion to a Roth IRA, you
                must designate in writing to the custodian your election to
                treat that contribution as a rollover or conversion. Once made,
                the election is irrevocable.
              </li>
            </ol>
          </div>
          <div>
            <p>
              K. <strong>Transfer Due to Divorce</strong> – If all or any part
              of your Roth IRA is awarded to your spouse or former spouse in a
              divorce or legal separation proceeding, the amount so awarded will
              be treated as the spouse’s Roth IRA (and may be transferred
              pursuant to a court-approved divorce decree or written legal
              separation agreement to another Roth IRA of your spouse), and will
              not be considered a taxable distribution to you. A transfer is a
              tax-free direct movement of cash and/or property from one Roth IRA
              to another.
            </p>
          </div>
          <div>
            <p>
              L. <strong>Recharacterizations</strong> – If you make a
              contribution to a Traditional IRA and later recharacterize either
              all or a portion of the original contribution to a Roth IRA along
              with net income attributable, you may elect to treat the original
              contribution as having been made to the Roth IRA. The same
              methodology applies when recharacterizing a contribution from a
              Roth IRA to a Traditional IRA. For tax years beginning before
              January 1, 2018, if you have converted from a Traditional IRA to a
              Roth IRA, or rolled over an eligible employer-sponsored retirement
              plan to a Roth IRA, you may recharacterize the conversion or
              rollover along with the net income attributable to a Traditional
              IRA. The deadline for completing a recharacterization is your tax
              filing deadline (including any extensions) for the year for which
              the original contribution was made or conversion or rollover
              completed. However, effective for tax years beginning after
              December 31, 2017, you may not recharacterize a Roth IRA
              conversion or an employer-sponsored retirement planrollover.
            </p>
          </div>
          <div>
            <p className="terms-label">LIMITATIONS AND RESTRICTIONS</p>
          </div>
          <div>
            <p>
              A. <strong>Spousal Roth IRA</strong> – If you are married and have
              compensation, you may contribute to a Roth IRA established for the
              benefit of your spouse, regardless of whether or not your spouse
              has compensation. You must file a joint income tax return for the
              year for which the contribution is made.
              <br />
              The amount you may contribute to your Roth IRA and your spouse’s
              Roth IRA is the lesser of 100 percent of your combined eligible
              compensation or $11,000 for 2017 and 2018. This amount may be
              increased with cost-of-living adjustments each year. However, you
              may not contribute more than the individual contribution limit to
              each Roth IRA. Your contribution may be further limited if your
              MAGI falls within the minimum and maximum thresholds.
            </p>
          </div>
          <div>
            <p>
              If your spouse is age 50 or older by the close of the taxable
              year, and is otherwise eligible, you may make an additional
              contribution to your spouse’s Roth IRA. The maximum additional
              contribution is $1,000 per year.
            </p>
          </div>
          <div>
            <p>
              B. <strong>Gift Tax</strong> – Transfers of your Roth IRA assets
              to a beneficiary made during your life and at your request may be
              subject to federal gift tax under IRC Sec. 2501.
            </p>
          </div>
          <div>
            <p>
              C. <strong>Special Tax Treatment</strong> – Capital gains
              treatment and 10-year income averaging authorized by IRC Sec. 402
              do not apply to Roth IRA distributions.
            </p>
          </div>
          <div>
            <p>
              D. <strong>Prohibited Transactions</strong> – If you or your
              beneficiary(ies) engage in a prohibited transaction with your Roth
              IRA, as described in IRC Sec. 4975, your Roth IRA will lose its
              tax-deferred or tax-exempt status, and you generally must include
              the value of the earnings in your account in your gross income for
              that taxable year. The following transactions are examples of
              prohibited transactions with your Roth IRA. (1) Taking a loan from
              your Roth IRA (2) Buying property for personal use (present or
              future) with Roth IRA assets (3) Receiving certain bonuses or
              premiums because of your Roth IRA.
            </p>
          </div>
          <div>
            <p>
              E. <strong>Pledging</strong> – If you pledge any portion of your
              Roth IRA as collateral for a loan, the amount so pledged will be
              treated as a distribution and may be included in your gross income
              for that year.
            </p>
          </div>
          <div>
            <p className="terms-label">OTHER</p>
          </div>
          <div>
            <p>
              A. <strong>IRS Plan Approval</strong> – Articles I through VIII of
              the agreement used to establish this Roth IRA have been approved
              by the IRS. The IRS approval is a determination only as to form.
              It is not an endorsement of the plan in operation or of the
              investments offered.
            </p>
          </div>
          <div>
            <p>
              B. <strong>Additional Information</strong> – For further
              information on Roth IRAs, you may wish to obtain IRS Publication
              590-A, Contributions to Individual Retirement Arrangements (IRAs),
              or Publication 590-B, Distributions from Individual Retirement
              Arrangements (IRAs), by calling 800-TAX-FORM, or by visiting
              <a href="http://www.irs.gov">www.irs.gov</a> on the Internet.
            </p>
          </div>
          <div>
            <p>
              C.
              <strong>
                Important Information About Procedures for Opening a New Account
              </strong>
              – To help the government fight the funding of terrorism and money
              laundering activities, federal law requires all financial
              organizations to obtain, verify, and record information that
              identifies each person who opens an account. Therefore, when you
              open a Roth IRA, you are required to provide your name,
              residential address, date of birth, and identification number. We
              may require other information that will allow us to identify you.
            </p>
          </div>
          <div>
            <p>
              D. <strong>Qualified Reservist Distributions</strong> – If you are
              an eligible qualified reservist who has taken penalty-free
              qualified reservist distributions from your Roth IRA or retirement
              plan, you may recontribute those amounts to a Roth IRA generally
              within a two-year period from your date of return.
            </p>
          </div>
          <div>
            <p>
              E. <strong>Qualified Charitable Distributions</strong> – If you
              are age 70½ or older, you may take tax-free Roth IRA distributions
              of up to $100,000 per year and have these distributions paid
              directly to certain charitable organizations. Special tax rules
              may apply. For further detailed information and effective dates
              you may obtain IRS Publication 590-B, Distributions from
              Individual Retirement Arrangements (IRAs), from the IRS or refer
              to the IRS website at
              <a href="http://www.irs.gov">www.irs.gov</a>.
            </p>
          </div>
          <div>
            <p>
              F. <strong>Disaster Related Relief</strong> – If you qualify (for
              example, you sustained an economic loss due to, or are otherwise
              considered affected by, certain IRS designated disasters), you may
              be eligible for favorable tax treatment on distributions,
              rollovers, and other transactions involving your Roth IRA.
              Qualified disaster relief may include penalty-tax free early
              distributions made during specified timeframes for each disaster,
              the ability to include distributions in your gross income ratably
              over multiple years, the ability to roll over distributions to an
              eligible retirement plan without regard to the 60- day rollover
              rule, and more. For additional information on specific disasters,
              including a complete listing of disaster areas, qualification
              requirements for relief, and allowable disaster-related Roth IRA
              transactions, you may wish to obtain IRS Publication 590-B,
              Distributions from Individual Retirement Arrangements (IRAs), from
              the IRS or refer to the IRS website at{" "}
              <a href="http://www.irs.gov">www.irs.gov</a>.
            </p>
          </div>
          <div>
            <p className="terms-label">FINANCIAL DISCLOSURE</p>
          </div>
          <div>
            <p>
              <em>
                Apex Clearing Corporation may charge your broker and/or
                investment advisor a fee. Please contact your broker and/or
                investment advisor for information regarding these charges.
              </em>
            </p>
          </div>
          <div>
            <p>
              <strong>Service Fees</strong>: We have the right to charge an
              annual service fee and other designated fees (e.g., a transfer,
              rollover or termination fee) in conjunction with your Roth IRA. In
              addition, we have the right to be reimbursed for all reasonable
              expenses, including legal expenses, we incur in connection with
              the administration of your Roth IRA. We may charge you separately
              for any fees or expenses, or we may deduct the amount of the fees
              or expenses from the assets in your Roth IRA at our discretion.
              The full annual service fee attributable to the year in which you
              terminate your Roth IRA, along with the termination fee, shall be
              due and payable upon termination of your Roth IRA regardless of
              the date during the year in which you terminate your IRA. We
              reserve the right to charge any additional fee upon 30 days notice
              to you that the fee will be effective. Fees such as sub transfer
              agent fees or commissions may be paid to us by third parties for
              assistance in performing certain transactions with respect to this
              Roth IRA.
            </p>
          </div>
          <div>
            <p>
              Any brokerage commissions attributable to the assets in your Roth
              IRA will be charged to your Roth IRA. You cannot reimburse your
              Roth IRA for those commissions.
            </p>
          </div>
          <div>
            <p>
              The value of your Roth IRA will be solely dependent upon the
              performance of any investment instrument chosen by you to fund
              your Roth IRA. Therefore, no projection of the growth of your Roth
              IRA can be reasonably shown or guaranteed. There are certain fees
              and charges associated with the investments you may select for
              your Roth IRA.
            </p>
          </div>
          <div>
            <p>
              In the event this agreement is terminated or you transfer out of
              your existing Roth IRA, a fee will apply. Additionally, brokerage
              commissions may apply according to your selection of investments.
              Questions relative to fees and brokerage commission(s) should be
              discussed with your broker and/or investment advisor prior to
              executing any orders or you may refer to the prospectus which will
              describe the terms of the investment you choose.
            </p>
          </div>
          <div>
            <p>
              You will select the type of investment for your Roth IRA assets,
              provided, however, that your selection of investments shall be
              limited to any investment vehicle obtainable by us, that we are
              authorized by our charter, articles of incorporation, or bylaws to
              offer and do in fact, in our sole discretion offer for investment
              in Roth IRAs… For example, investments may include but shall not
              be limited to common stocks, government and corporate bonds,
              mutual funds, the purchase of put options on existing positions
              and writing of covered listed call options and such other options
              strategies that we may, from time to time, in our sole discretion
              make available for Roth IRAs and which strategies are approved for
              your account by your broker and/or investment advisor. Investments
              not generating confirmations must be accompanied by additional
              written instructions and such other documentation as we may, in
              our sole discretion, require. We shall act as a stockbroker or
              dealer whenever such services are required. We may, in our sole
              discretion, make available to you, additional investment
              offerings, which shall be limited to publicly traded securities,
              mutual funds, money market instruments and other investments that
              are obtainable by us and that we, in our sole discretion,
              determine that we are capable of holding in the ordinary course of
              our business.
            </p>
          </div>
          <div>
            <p className="terms-label">TRUSTED CONTACT</p>
          </div>
          <div>
            <p>
              “Under FINRA Rule 4512 Apex Clearing Corporation is required to
              disclose to you (the customer) that Apex Clearing Corporation or
              an associated person of Apex Clearing Corporation is authorized to
              contact the trusted contact person and disclose information about
              the customer’s account to address possible financial exploitation,
              to confirm the specifics of the customer’s current contact
              information, health status, or the identity of any legal guardian,
              executor, trustee or holder of a power of attorney, or as
              otherwise permitted by FINRA Rule 2165.”
            </p>
          </div>
          <div>
            <p className="terms-label">ACH AGREEMENT</p>
          </div>
          <div>
            <p>
              If I request Automated Clearinghouse (“ACH”) transactions from my
              Account at Clearing Firm, I authorize Clearing Firm to originate
              or facilitate transfer credits/debits to/from my eligible bank
              account. Transactions sent through the NACHA network will be
              subject to all applicable rules of NACHA and all rules set forth
              in Federal Reserve Operating circulars or other applicable laws
              and regulations. ACH deposits to my brokerage account are
              provisional. If the beneficiary bank does not receive final and
              complete payment for a payment order transferred through ACH, the
              beneficiary bank is entitled to recover from the beneficiary any
              provisional credit and Clearing Firm may charge my account for the
              transaction amount. I understand Clearing Firm or my Broker may
              not notify me of any returned or rejected ACH transfers. I agree
              to hold Clearing Firm and Clearing Firm’s agents free of liability
              for compliance with these instructions. I hereby agree to hold
              harmless Clearing Firm and each of its affiliates, offices,
              directors, employees, and agents against, any claims, judgments,
              expenses, liabilities or costs of defense or settlement relating
              to: (a) any refusal or failure to initiate or honor any credit or
              debit request, by Clearing Firm or my Broker, whether (i) due to a
              lack of funds necessary to credit my account; (ii) due to
              inadvertence, error caused by similarity of account holder names
              or (iii) otherwise provided Clearing Firm has not acted in bad
              faith; (b) if the routing number is incorrect or the routing
              number or other information changes at another U.S. financial
              institution or © any loss, damage, liability or claim arising,
              directly or indirectly, from any error, delay or failure which is
              caused by circumstances beyond Clearing Firm’s direct control. To
              the extent permitted by applicable law or regulation, Clearing
              Firm hereby disclaims all warranties, express or implied, and in
              no event shall Clearing Firm be liable for any special indirect,
              incidental, or consequential damages whatsoever resulting from the
              ACH electronic service or any ACH transactions. Nothing in this
              herein shall constitute a commitment or undertaking by Clearing
              Firm or my Broker to effect any ACH transaction or otherwise act
              upon my instructions or those of my Broker with respect to any
              account at Clearing Firm. This authorization shall remain in full
              force and effect until I revoke authorization by written
              notification to my Broker that is forwarded to Clearing Firm. I
              understand that Clearing Firm has the right to terminate or
              suspend the ACH agreement at any time and without notice.
            </p>
          </div>
          <div>
            <p className="terms-label">PRIVACY POLICY</p>
          </div>
          <div>
            <p>
              Apex Clearing Corporation (“Apex”) carries your account as a
              clearing broker by arrangement with your broker-dealer or
              registered investment advisor as Apex’s introducing client. At
              Apex, we understand that privacy is an important issue for
              customers of our introducing firms. It is our policy to respect
              the privacy of all accounts that we maintain as clearing broker
              and to protect the security and confidentiality of non-public
              personal information relating to those accounts. Please note that
              this policy generally applies to former customers of Apex as well
              as current customers.
            </p>
          </div>
          <div>
            <p className="terms-label">Personal Information Collected</p>
          </div>
          <div>
            <p>
              In order to service your account as a clearing broker, information
              is provided to Apex by your introducing firm who collects
              information from you in order to provide the financial services
              that you have requested. The information collected by your
              introducing firm and provided to Apex or otherwise obtained by
              Apex may come from the following sources and is not limited to:
            </p>
          </div>
          <div>
            <p>
              • Information included in your applications or forms, such as your
              name, address, telephone number, social security number,
              occupation, and income;
              <br />
              • Information relating to your transactions, including account
              balances, positions, and activity;
              <br />
              • Information which may be received from consumer reporting
              agencies, such as credit bureau reports;
              <br />
              • Information relating to your creditworthiness;
              <br />• Information which may be received from other sources with
              your consent or with the consent of your introducing firm.
            </p>
          </div>
          <div>
            <p>
              In addition to servicing your account, Apex may make use of your
              personal information for analysis purposes, for example, to draw
              conclusions, detect patterns or determine preferences.
            </p>
          </div>
          <div>
            <p>
              <strong>Sharing of Non-public Personal Information</strong>
              <br />
              Apex does not disclose non-public personal information relating to
              current or former customers of introducing firms to any third
              parties, except as required or permitted by law, including but not
              limited to any obligations of Apex under the USA PATRIOT Act, and
              in order to facilitate the clearing of customer transactions in
              the ordinary course of business.
            </p>
          </div>
          <div>
            <p>
              Apex has multiple affiliates and relationships with third party
              companies. Examples of these companies include financial and
              non-financial companies that perform services such as data
              processing and companies that perform securities executions on
              your behalf. We may share information among our affiliates and
              third parties, as permitted by law, in order to better service
              your financial needs and to pursue legitimate business interests,
              including to carry out, monitor and analyze our business, systems
              and operations.
            </p>
          </div>
          <div>
            <p>
              <strong>Security</strong>
              <br />
              Apex strives to ensure that our systems are secure and that they
              meet industry standards. We seek to protect non- public personal
              information that is provided to Apex by your introducing firm or
              otherwise obtained by Apex by implementing physical and electronic
              safeguards. Where we believe appropriate, we employ firewalls,
              encryption technology, user authentication systems (i.e. passwords
              and personal identification numbers) and access control mechanisms
              to control access to systems and data. Apex endeavors to ensure
              that third party service providers who may have access to
              non-public personal information are following appropriate
              standards of security and confidentiality. Further, we instruct
              our employees to use strict standards of care in handling the
              personal financial information of customers. As a general policy,
              our staff will not discuss or disclose information regarding an
              account except:
            </p>
            <ol type={"1"}>
              <li>with authorized personnel of your introducing firm,</li>
              <li>as required by law or pursuant to regulatory request, or</li>
              <li>
                as authorized by Apex to a third party or affiliate providing
                services to your account or pursuing Apex’s legitimate business
                interests.
              </li>
            </ol>
          </div>
          <div>
            <p>
              <strong>Access to Your Information</strong>
              <br />
              You may access your account information through a variety of media
              offered by your introducing firm and Apex (i.e. statements or
              online services). Please contact your introducing firm if you
              require any additional information. Apex may use “cookies” in
              order to provide better service, to facilitate its customers’ use
              of the website, to track usage of the website, and to address
              security hazards. A cookie is a small piece of information that a
              website stores on a personal computer, and which it can later
              retrieve.
            </p>
          </div>
          <div>
            <p>
              <strong>Changes to Apex’s Privacy Policy</strong>
              <br />
              Apex reserves the right to make changes to this policy.
            </p>
          </div>
          <div>
            <p>
              <strong>
                How to Get in Touch with Apex about this Privacy Policy
              </strong>
              <br />
              For reference, this Privacy Policy is available on our website at
              <a href="http://www.apexclearing.com">www.apexclearing.com</a>.
              For more information relating to Apex’s Privacy Policy or to limit
              our sharing of your personal information, please contact:
            </p>
          </div>
          <div>
            <p>
              Apex Clearing Corporation
              <br />
              Attn: Compliance Department
              <br />
              350 N. St. Paul St., Suite 1300
              <br />
              Dallas, Texas 75201
              <br />
              214-765-1055
            </p>
          </div>
        </div>
      </>
    );
  };

  render() {
    const content = this._content();
    return <div>{content}</div>;
  }
}

export default ApexTermsRoth;
