import React from "react";
import PropTypes from "prop-types";
import { find, get, includes, isEmpty } from "lodash";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, OverlayTrigger, Popover } from "react-bootstrap";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import {
  getUser,
  lockTargetUser,
  requirePortfolioUpdate,
  resetUserOnboardingState,
  unlockTargetUser,
} from "actions/adminActions";
import {
  userIsClosingAccountSelector,
  userIsLockedSelector,
} from "store/selectors/user";
import {
  IndividualPortfolioUpdate,
  IndividualRequiredUpdatesPending,
  onboardingStates,
} from "statics/states";

import InfoTable from "components/InfoTable";
import Button from "components/Button";
import Alert from "components/Alert";
import IconSpinner from "components/IconSpinner";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import { ROTH_IRA, TRADITIONAL_IRA } from "statics/accountTypes";
import { Link } from "react-router-dom";

class UserInfo extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    getUser: PropTypes.func,
    userId: PropTypes.string,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    isRequiringPortfolio: PropTypes.bool,
    isResetingUser: PropTypes.bool,
    isTogglingLockStatus: PropTypes.func,
    lockTargetUser: PropTypes.func,
    unlockTargetUser: PropTypes.func,
    requirePortfolioUpdate: PropTypes.func,
    resetUserOnboardingState: PropTypes.func,
    push: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      user: {},
      isFetching: true,
      showFullSsn: false,
    };
  }

  async componentDidMount() {
    await this._getAndSetUser();
    this.setState({
      isFetching: false,
    });
  }

  _getAndSetUser = async () => {
    const { data: user } = await this.props.getUser(
      this.props.client,
      this.props.userId
    );
    this.setState({ user });
  };

  _resetUserState = async () => {
    await this.props.resetUserOnboardingState(
      this.props.client,
      this.props.userId
    );
    document.body.click();
    if (!this.props.error) {
      toast.success("Successfully reset user state.");
      this.props.push("/users");
    }
  };

  _unlockUser = async () => {
    const { data } = await this.props.unlockTargetUser(
      this.props.client,
      this.props.userId
    );
    if (!this.props.error) {
      this._setUserStateFromAction(data.state);
    }
  };

  _lockUser = async () => {
    const { data } = await this.props.lockTargetUser(
      this.props.client,
      this.props.userId
    );
    if (!this.props.error) {
      this._setUserStateFromAction(data.state);
    }
  };

  _setUserStateFromAction = (state) => {
    this.setState({
      user: {
        ...this.state.user,
        user: {
          ...this.state.user.user,
          state,
        },
      },
    });
  };

  _getLockAction = () => {
    const state = get(this.state.user, "user.state");
    const wrappedState = { user: { userState: { state } } };
    const userIsLocked = userIsLockedSelector(wrappedState);
    const userIsClosingAccount = userIsClosingAccountSelector(wrappedState);

    if (userIsClosingAccount) {
      return null;
    }

    if (userIsLocked) {
      return (
        <Button
          type="button"
          name="submit"
          btnLabel="Unlock User"
          loading={this.props.isTogglingLockStatus}
          onClick={this._unlockUser}
          size={"sm"}
          withArrow
        />
      );
    }
    return (
      <Button
        type="button"
        btnLabel="Lock User"
        name="submit"
        color="red"
        loading={this.props.isTogglingLockStatus}
        onClick={this._lockUser}
        size={"sm"}
        withArrow
      />
    );
  };

  _getResetAction = () => {
    const state = get(this.state.user, "user.state");
    const userIsOnboarding = includes(onboardingStates, state);

    if (!userIsOnboarding) {
      return null;
    }

    return (
      <OverlayTrigger
        rootClose
        trigger="click"
        placement="bottom"
        overlay={
          <Popover className="action-popover">
            <Popover.Content>
              <p>
                Are you sure you want to reset this user&apos;s state? This
                action will erase all of their application data.
              </p>
              <div>
                <Button
                  type="button"
                  color="cancel"
                  name="cancel"
                  btnLabel="Cancel"
                  onClick={() => document.body.click()}
                  size="sm"
                />
                <Button
                  type="button"
                  color="red"
                  name="submit"
                  btnLabel="Reset"
                  withArrow={true}
                  loading={this.props.isResetingUser}
                  onClick={this._resetUserState}
                  size="sm"
                />
              </div>
            </Popover.Content>
          </Popover>
        }
      >
        <Button
          type="button"
          name="action"
          color="red"
          btnLabel="Reset User"
          size={"sm"}
        />
      </OverlayTrigger>
    );
  };

  _requirePortfolioUpdate = async () => {
    const { data } = await this.props.requirePortfolioUpdate(
      this.props.client,
      this.props.userId
    );

    if (!this.props.error) {
      this._setUserStateFromAction(data.state);
    }
  };

  _getTableMetadata = () => {
    return [
      {
        key: "user.firstName",
        label: "First Name",
      },
      {
        key: "user.lastName",
        label: "Last Name",
      },
      {
        key: this.state.showFullSsn ? "fullSsn" : "userProfile.ssn",
        label: "SSN",
        format: (ssn) => {
          const actionText = this.state.showFullSsn ? "Hide" : "Show";
          return (
            <p style={{ marginBottom: 0 }}>
              <span
                style={{
                  fontSize: 12,
                  color: "#0cb7c4",
                }}
                onClick={() =>
                  this.setState({ showFullSsn: !this.state.showFullSsn })
                }
              >
                ({actionText} Full SSN)
              </span>{" "}
              {ssn}
            </p>
          );
        },
      },
      {
        key: "userProfile.dob",
        label: "DOB",
      },
      {
        key: "userProfile",
        label: "Address",
        format(userProfile = {}) {
          if (isEmpty(userProfile)) {
            return "";
          }
          return `${userProfile.address1}${
            userProfile.address2 && ", " + userProfile.address2
          }, ${userProfile.city}, ${userProfile.state} ${
            userProfile.postalCode
          } `;
        },
      },
      {
        key: "userProfile.email",
        label: "Email",
      },
      {
        key: "userProfile.phoneNumber",
        label: "Phone #",
      },

      {
        key: "user.id",
        label: "ID",
      },
      {
        key: "accounts",
        label: "Apex ID",
        format(accounts) {
          const iraAccount = find(accounts, (account) => {
            return (
              account.accountType === TRADITIONAL_IRA ||
              account.accountType === ROTH_IRA
            );
          });
          if (!iraAccount) return "";
          return iraAccount.accountNumber;
        },
      },
      {
        key: "accounts",
        label: "Sila Wallet ID",
        format(accounts) {
          const walletAccount = find(accounts, { accountType: "WALLET" });
          if (!walletAccount) return "";
          return walletAccount.accountNumber;
        },
      },
      {
        key: "user.state",
        label: "State",
      },
    ];
  };

  render() {
    const userState = get(this.state.user, "user.state");

    if (this.state.isFetching) {
      return <IconSpinner centered />;
    }

    return (
      <>
        <Card.Body>
          <InfoTable
            metadata={this._getTableMetadata()}
            data={this.state.user}
          />
          {this.props.error && <Alert type={"error"} msg={this.props.error} />}
          <div className="btn-row" style={{ marginTop: 12 }}>
            {this._getLockAction()}
            {this._getResetAction()}
            <Button
              type="button"
              name="submit"
              btnLabel="Require Portfolio Update"
              loading={this.props.isRequiringPortfolio}
              onClick={this._requirePortfolioUpdate}
              disabled={[
                IndividualRequiredUpdatesPending,
                IndividualPortfolioUpdate,
              ].includes(userState)}
              withArrow
              size={"sm"}
            />
            <Link
              to={`/workflows?entityId=${this.props.userId}`}
              className="icon-link"
            >
              View Workflows
            </Link>
          </div>
        </Card.Body>
      </>
    );
  }
}

const errorSelector = createErrorSelector([
  adminConstants.LOCK_USER,
  adminConstants.UNLOCK_USER,
  adminConstants.GET_USER,
  adminConstants.REQUIRE_PORTFOLIO_UPDATE,
  adminConstants.RESET_USER_STATE,
]);

const lockingSelector = createLoadingSelector([
  adminConstants.LOCK_USER,
  adminConstants.UNLOCK_USER,
]);

const requiringPortfolioSelector = createLoadingSelector(
  adminConstants.REQUIRE_PORTFOLIO_UPDATE
);

const resetingSelector = createLoadingSelector([
  adminConstants.RESET_USER_STATE,
  adminConstants.GET_USER,
]);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isTogglingLockStatus: lockingSelector(state),
    isRequiringPortfolio: requiringPortfolioSelector(state),
    isResetingUser: resetingSelector(state),
  };
};

const mapDispatchToProps = {
  getUser,
  lockTargetUser,
  unlockTargetUser,
  requirePortfolioUpdate,
  resetUserOnboardingState,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(UserInfo));
