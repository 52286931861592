import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get, head, isEmpty } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { getAllAccounts } from "actions/accountActions";
import { userConstants } from "actions/types";
import { getRequiredUpdates } from "actions/userActions";
import IconSpinner from "components/IconSpinner";
import { pendingRequiredUpdates } from "store/selectors/user";
import IndividualInvestmentProfile from "pages/signUp/individual/IndividualInvestmentProfile";
import IndividualPortfolioSelection from "pages/signUp/individual/IndividualPortfolioSelection";
import CustodianDisclosure from "./CustodianDisclosure";
import { IndividualRequiredUpdatesPending } from "statics/states";
import RequiredUpdatesContributionSelection from "pages/signUp/individual/setupContribution/RequiredUpdatesContributionSelection";

class IndividualRequiredUpdates extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),

    error: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    userState: PropTypes.string.isRequired,
    getRequiredUpdates: PropTypes.func.isRequired,
    pendingRequiredUpdates: PropTypes.arrayOf(PropTypes.string).isRequired,
    getAllAccounts: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: get(props, "isFetching", true),
    };
  }

  async componentDidMount() {
    await this.props
      .getRequiredUpdates(this.props.client)
      .then(() => {
        if (this.props.userState !== IndividualRequiredUpdatesPending)
          this.props.push("/dashboard");
      })
      .then(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return <IconSpinner centered />;
    }
    const firstPendingStep = head(this.props.pendingRequiredUpdates);
    const components = {
      INVESTMENT_PROFILE_AND_SUITABILITY: IndividualInvestmentProfile,
      PORTFOLIO_UPDATE: IndividualPortfolioSelection,
      CUSTODIAN_DISCLOSURE_QUESTION: CustodianDisclosure,
      PAYROLL_CONTRIBUTION_UPDATE: RequiredUpdatesContributionSelection,
    };
    const ActiveComponent = get(components, firstPendingStep);

    return <>{!isEmpty(ActiveComponent) ? <ActiveComponent /> : <div />}</>;
  }
}

const actions = [userConstants.GET_USER_REQUIRED_STEPS];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;
  return {
    userState,
    error: errorSelector(state),
    isFetching: loadingSelector(state),
    pendingRequiredUpdates: pendingRequiredUpdates(state),
  };
};

const mapDispatchToProps = {
  getRequiredUpdates,
  getAllAccounts,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualRequiredUpdates));
