import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import queryString from "query-string";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { registerKYBUser } from "actions/adminActions";
import { getMember } from "services/adminService";
import { wrapError } from "utils/errorHelper";

import IconSpinner from "components/IconSpinner";
import ControllerForm from "./ControllerForm";

class Controller extends React.Component {
  static propTypes = {
    memberId: PropTypes.string,
    companyId: PropTypes.string,
    push: PropTypes.func,
    client: PropTypes.shape({}),
    registerKYBUser: PropTypes.func,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      identity: {
        firstName: "",
        lastName: "",
        email: "",
        dob: "",
        ssn: "",
        role: "",
        ownershipStake: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        postalCode: "",
        phone: "",
      },
      submitted: false,
      loading: false,
      fetching: true,
      showAddressModal: false,
      fetchingAddress: false,
      validatedAddress: {},
      member: null,
    };
  }

  async componentDidMount() {
    const queryObj = queryString.parse(window.location.search);

    if (queryObj.memberId) {
      this.setState({
        fetching: true,
      });

      const member = await getMember(this.props.client, {
        companyId: this.props.companyId,
        memberId: queryObj.memberId,
      });

      this.setState({
        member,
        identity: {
          ...member,
          ...member.memberProfile,
          dob: member.memberProfile.dob,
        },
        fetching: false,
      });
    } else {
      this.setState({ fetching: false });
    }
  }

  _submitUser = (updatedInfo) => {
    this.props
      .registerKYBUser(this.props.client, updatedInfo)
      .then(() => {
        const isEditing = !!this.state.member;
        const term = isEditing ? "edited" : "registered";
        toast.success(`Successfully ${term} controller/owner.`);
        this.props.push(`/employers/${this.props.companyId}/kyb/summary`);
      })
      .catch((err) => {
        toast.error(wrapError(err));
      });
  };

  render() {
    const { identity } = this.state;

    if (this.state.fetching) {
      return <IconSpinner centered />;
    }

    return (
      <div
        className="tab-pane"
        id="controller"
        role="tabpanel"
        aria-labelledby="controller_tab"
      >
        <div>
          <ControllerForm
            isAdminPortal={true}
            ownerInfo={identity}
            error={this.props.error}
            isFetching={this.props.isFetching}
            companyId={this.props.companyId}
            onSubmit={this._submitUser}
          />
        </div>
      </div>
    );
  }
}

const actions = [adminConstants.REGISTER_KYB_USER];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state, ownProps) => ({
  memberId: get(ownProps.match, "query.memberId"),
  error: errorSelector(state),
  isFetching: loadingSelector(state),
});

const mapDispatchToProps = {
  registerKYBUser,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Controller));
