import numbro from "numbro";
import { round, isFunction } from "lodash";

export function formatCurrency(amount, mantissa = 2) {
  return numbro(+amount).formatCurrency({
    mantissa,
    thousandSeparated: true,
  });
}

export function formatCurrencyPrefix(amount, mantissa = 2) {
  return numbro(+amount).formatCurrency({
    mantissa,
    average: true,
    optionalMantissa: true,
    currencyPosition: "prefix",
  });
}

export function formatPercentage(number, mantissa = 2) {
  return numbro(+number).format({
    mantissa,
    output: "percent",
  });
}

export function formatAmount(amount) {
  return round(+amount, 2);
}

export const boolToEnglish = (val) => {
  if (val) {
    return "Yes";
  }
  return "No";
};

export function formatValue(meta, data) {
  if (!meta.format) return data;

  if (isFunction(meta.format)) {
    return meta.format(data);
  }

  switch (meta.format) {
    case "currency":
      return formatCurrency(data);
    case "percentage":
      return formatPercentage(data);
    case "boolean":
      return boolToEnglish(data);

    default:
      return data;
  }
}
