import React from "react";
import PropTypes from "prop-types";
import { get, isFunction, isObject, map } from "lodash";
import { formatValue } from "utils/number";
import { Form } from "react-bootstrap";
import Table from "react-bootstrap/Table";

const IconTable = ({
  data,
  columns,
  hasSelectableRows,
  onSelectAll,
  onRowSelect,
  hasSelectedAll,
  selectedRows,
  uniqueRowIdentifier,
  disable,
}) => {
  return (
    <div className="icon-table">
      <Table responsive striped>
        <thead>
          <tr>
            {hasSelectableRows && (
              <th style={{ width: 25, textAlign: "center", borderTop: "none" }}>
                {isFunction(onSelectAll) && (
                  <Form.Check
                    id="selectAllRows"
                    type="checkbox"
                    name="selectAllRows"
                    checked={hasSelectedAll}
                    onChange={onSelectAll}
                  />
                )}
              </th>
            )}
            {columns.map((item, index) => {
              return <th key={index}>{item.label}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {map(data, (rowData, idx) => {
            const rowId = rowData[uniqueRowIdentifier];
            return (
              <tr key={`table-row-${idx}`}>
                {hasSelectableRows && (
                  <th
                    key={`selectable-row-${idx}`}
                    style={{
                      width: 25,
                      textAlign: "center",
                      borderTop: "none",
                    }}
                  >
                    {!rowData.unselectable && (
                      <Form.Check
                        id={rowId}
                        type="checkbox"
                        name={rowId}
                        checked={hasSelectedAll || get(selectedRows, rowId)}
                        onChange={(e) =>
                          onRowSelect(e.target.name, e.target.checked)
                        }
                        disabled={disable}
                      />
                    )}
                  </th>
                )}

                {columns.map((columnItem, index) => {
                  if (columnItem.customComponent) {
                    const CustomComponent = columnItem.customComponent;
                    const customComponentProps = {
                      ...rowData,
                      ...(isObject(columnItem.metadata) && columnItem.metadata),
                    };
                    return (
                      <td key={`custom-component-${index}`}>
                        <CustomComponent {...customComponentProps} />
                      </td>
                    );
                  }

                  return (
                    <>
                      <td key={`column-${index}`}>
                        {formatValue(columnItem, get(rowData, columnItem.key))}
                      </td>
                    </>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

IconTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
      customComponent: PropTypes.func,
    })
  ),
  data: PropTypes.arrayOf(PropTypes.object),
  hasSelectableRows: PropTypes.bool,
  onSelectAll: PropTypes.func,
  onRowSelect: PropTypes.func,
  selectedRows: PropTypes.objectOf(PropTypes.bool),
  hasSelectedAll: PropTypes.bool,
  uniqueRowIdentifier: PropTypes.string,

  disable: PropTypes.bool,
};

export default IconTable;
