import {
  endsWith,
  every,
  isArray,
  isObject,
  isString,
  map,
  mapValues,
  size,
  trim,
} from "lodash";

let yup = require("yup");

const usPhoneRegEx = [
  /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}\s*$/,
  /^(\([0-9]{3}\))\s*[0-9]{3}-[0-9]{4}\s*$/,
  /^(\([0-9]{3}\))\s*[0-9]{3}\s*[0-9]{4}\s*$/,
  /^[0-9]{3}\s+[0-9]{3}\s+[0-9]{4}\s*$/,
  /^[0-9]{3}\s*[0-9]{7}\s*$/,
];

const domainRegex = /^(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
const pathRegex = /^\/?[\w\-.+~!$'()*,;=:@]*$/;

export const PRINTABLE_ASCII_MESSAGE =
  "${path} can only contain letters, numbers, special characters, or spaces.";
export const NAME_MESSAGE =
  "${path} may only contain letters, periods, apostrophes, hyphens, slashes, or spaces.";
export const NAME_LENGTH_ERROR =
  "Combined first and last name must be less than 30 characters.";

export const noWhitespaceRegex = /^$|^[^\s]+(\s+[^\s]+)*$/;

export const whiteSpaceError = "value contains trailing or leading whitespace";

const poBoxRegex =
  /^(((p[\s.]?[o\s][.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/gim;

const einRegEx = [/^\d{2}-?\d{7}$/];

export const usernameRegex = /^([a-zA-Z0-9+\-_.!#$'^`~@]{2,25})$/;

export function testEIN(value) {
  for (let i = 0; i < einRegEx.length; i++) {
    if (einRegEx[i].test(value)) {
      return true;
    }
  }
  return false;
}

export function isPoBox(address) {
  const uspsValidPoBoxRegex = /po\sbox|p.o.\sbox/i;
  return uspsValidPoBoxRegex.test(address);
}

export function testPoBoxAddress(address) {
  return !poBoxRegex.test(address);
}

export function testPrintableAsciiCharacters(value) {
  // matches characters from range "SPACE" to ~ (Char range 32-126)
  const addressCharRegex = /^([ -~]*)$/;
  return addressCharRegex.test(value);
}

export function testPhoneNumber(value) {
  for (let i = 0; i < usPhoneRegEx.length; i++) {
    if (usPhoneRegEx[i].test(value)) {
      return true;
    }
  }
  return false;
}

export function testSsn(value, allowNoDash) {
  const matchesWithDashes = /^\d{3}-\d{2}-\d{4}$/.test(value);

  if (matchesWithDashes) return true;

  if (allowNoDash) {
    return /\d{9}/.test(value);
  }

  return false;
}

export function booleanValue(val) {
  if (val) {
    return val === true || val.toString().toLowerCase() === "true";
  }
  return false;
}

export function validateUsersAddress(userAddress, validatedAddress) {
  const propertiesToCheck = [
    "address1",
    "address2",
    "city",
    "state",
    "postalCode",
  ];

  // if user has put in a po box want to make sure we don't consider valid
  if (
    isPoBox(validatedAddress.address1) ||
    isPoBox(validatedAddress.address2)
  ) {
    return false;
  }

  // if what user passed in matches USPS minus casing return true
  return every(
    propertiesToCheck,
    (property) => userAddress[property] == validatedAddress[property]
  );
}

export function testUsername(username) {
  let localSchema = yup.string().email();
  const isEmail = localSchema.isValidSync(username);

  if (isEmail) {
    return this.createError({
      message: "Username cannot be an email address.",
    });
  }

  if (!usernameRegex.test(username)) {
    return this.createError({
      message:
        "Username can only contain alphanumeric characters and the following characters: '_', '+', '-', '.', '!', '#', '$', ''', '^', '`', '~' and '@'.",
    });
  }

  return true;
}

export function testName(name) {
  const nameRegex = /^([a-zA-Z.'\-/\s]*)$/;
  return nameRegex.test(name);
}

export function testFirstLastName() {
  const firstName = this.parent.firstName;
  const lastName = this.parent.lastName;
  return size(firstName) + size(lastName) < 30;
}

export const postalCodeValidator = yup
  .string()
  .trim()
  .label("Zip")
  .required()
  .test("postal-code", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
  .max(5)
  .min(5)
  .matches(noWhitespaceRegex, whiteSpaceError);

export const formatErrorString = (errMsg) => {
  const message = endsWith(errMsg, ".") ? errMsg : errMsg + ".";
  return message;
};

export const urlValidator = (url) => {
  if (!url) return true;
  const [domain, path] = url
    .replace("http://", "")
    .replace("https://", "")
    .split("/");

  const domainIsValid = domainRegex.test(domain);
  const pathIsValid = pathRegex.test(path);
  return domainIsValid && pathIsValid;
};

export const IndividualAddress1Validator = yup
  .string()
  .label("Address 1")
  .required()
  .matches(noWhitespaceRegex, whiteSpaceError)
  .min(3, "Must be at least three characters.")
  .max(40)
  .test(
    "po-box-address",
    "${path} cannot be a P.O. Box, please use a residential or business address.",
    testPoBoxAddress
  )
  .test("address1", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters);

export const address2Validator = yup
  .string()
  .label("Address 2")
  .test("address2", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
  .trim()
  .matches(noWhitespaceRegex, whiteSpaceError)
  .max(30)
  .min(3, "Must be at least three characters.")
  .transform((value) => (value ? value : null))
  .nullable();

export const individualAddress2Validator = yup
  .string()
  .label("Address 2")
  .test("address2", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
  .test(
    "po-box-address",
    "${path} cannot be a P.O. Box, please use a residential or business address.",
    testPoBoxAddress
  )
  .trim()
  .matches(noWhitespaceRegex, whiteSpaceError)
  .max(30)
  .min(3, "Must be at least three characters.")
  .transform((value) => (value ? value : null))
  .nullable();

export const companyInviteCodeRegex = /^[a-zA-Z0-9]{7}-\d{1,2}$/;
export const uniqueInviteCodeRegex = /^[a-zA-Z0-9]{7}$/;
// The two regexes above are unused, but help to illustrate the difference between the two in the regex below.
export const validIconInvite = /^[a-zA-Z0-9]{7}-\d{1,2}$|^[a-zA-Z0-9]{7}$/;
// Takes a regex that allows a-z, A-Z, 0-9, -, and spaces then negates it.
export const unallowedInvitationCodeCharacters = /[^a-zA-Z0-9-]*/g;
export const invitationCodeParser = (event) => {
  const newValue = event.target.value;
  return trim(newValue).replace(unallowedInvitationCodeCharacters, "");
};
export const invitationCodeError =
  "Invalid invitation code, please make sure there are no leading or trailing special characters.";

export const whiteSpaceFieldTrimmer = (value) => {
  if (isString(value)) {
    return value.trim();
  } else if (isArray(value)) {
    return map(value, whiteSpaceFieldTrimmer);
  } else if (isObject(value)) {
    return mapValues(value, whiteSpaceFieldTrimmer);
  }
  return value;
};
