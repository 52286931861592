import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { get, isEmpty } from "lodash";
import { sidebarHide, sidebarShow } from "actions/sidebarActions";
import { Navbar } from "react-bootstrap";
import { useAuth0 } from "utils/react-auth0-wrapper";
import "components/navbar/NavBar.scss";
import NavBrand from "components/navbar/NavBrand";
import IconNavDropdown from "components/navbar/IconNavDropdown";
import SideBarToggleButton from "components/navbar/SideBarToggleButton";
import NavLoginHeaderText from "components/navbar/NavLoginHeaderText";

const AdminNavBar = ({ userState }) => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const logoutWithRedirect = async () => {
    window.analytics.track("Logged Out");
    window.analytics.reset();

    logout({
      returnTo: window.location.origin + "/login",
    });
  };

  return (
    <div>
      <header id="header" className={"icon-header-admin"}>
        <Navbar>
          <div className="nav-left">
            <NavBrand />
            <Navbar.Text>
              <p className="portal">Admin Portal</p>
            </Navbar.Text>
          </div>

          <div className="nav-right">
            {!isAuthenticated && (
              <NavLoginHeaderText onClick={loginWithRedirect} />
            )}

            {isAuthenticated && !isEmpty(userState) && (
              <>
                <IconNavDropdown
                  userName={user.nickname}
                  onLogout={logoutWithRedirect}
                />
                <SideBarToggleButton />
              </>
            )}
          </div>
        </Navbar>
      </header>
    </div>
  );
};

AdminNavBar.propTypes = {
  userState: PropTypes.string,
  client: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    userState: get(state.user, "userState.state", ""),
    showSidebar: state.sidebar.showSidebar,
  };
};

const mapDispatchToProps = {
  sidebarShow,
  sidebarHide,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AdminNavBar));
