import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";

class EmployerIsolvedTermsOrder extends React.Component {
  static propTypes = {
    companyName: PropTypes.string,
    companyEIN: PropTypes.string,
    info: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  _content = () => {
    const companyName = this.props.companyName;
    const signerName =
      this.props.info.firstName + " " + this.props.info.lastName;
    const companyEIN = this.props.companyEIN;
    const signerEmail = this.props.info.userEmail;
    const today = moment().format("MMMM Do YYYY");
    return (
      <>
        <div className="terms-of-service">
          {/* // Fees */}
          <div>
            <p className="terms-number">1. Fees</p>
            <ol type="a">
              <li>
                This Exhibit A is governed by the terms of the Icon Platform
                Services Agreement. User must have a Platform Services Agreement
                in place, executed by Icon and User. User must also have a fully
                executed isolved order form in place, executed by isolved and
                User for this Exhibit A to be in effect.
              </li>
              <li>
                Upon signature by User and submission to Icon, this Exhibit A
                shall become legally binding upon User, unless this Exhibit A is
                rejected by Icon. Icon reserves the right to reject Exhibit A
                for any reason, which includes but is not limited to:
                <ol type="1">
                  <li>
                    the signatory below does not have the authority to bind User
                    to this Exhibit A
                  </li>
                  <li>
                    Changes have been made to this Exhibit A (other than
                    completion of the signature block)
                  </li>
                  <li>
                    the signature block is incomplete or does not match our
                    records or the rest of this Exhibit A
                  </li>
                  <li>
                    the User does not have a Platform Services Agreement in
                    place
                  </li>
                  <li>the User does not have a isolved Order Form in place</li>
                </ol>
              </li>
              <li>
                <div>
                  <p className="terms-list">
                    <b>Setup fee </b>for the Services and access to the Platform
                    are provided in the isolved Order Form.
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p className="terms-list">
                    <b>Recurring fees </b>for the Services and access to the
                    Platform are provided in the isolved Order Form.
                  </p>
                </div>
              </li>
              <li>
                <p className="terms-list">
                  <b>Support fee</b> for the Services.
                  <span style={{ marginTop: 20, paddingLeft: 24 }}>
                    <p>Employer support: Included</p>
                    <p>Employee support: Included</p>
                  </span>
                </p>
              </li>
            </ol>
          </div>
          {/* // Product Terms*/}
          <div>
            <p className="terms-number">2. Product Terms</p>
            <ol type="a">
              <li>
                <b>Participating Employee</b> is defined as any employee
                enrolled in Icon that has a linked employer plan to the User’s
                account on the Icon Platform for any amount of time in a
                calendar month.
              </li>
              <li>
                <b>Company Size</b> is determined during business verification
                and reflects the total number of W2 and 1099 workers at the
                User’s company. For purposes of determining any fees the Company
                Size will remain static for the term of the Platform Services
                Agreement associated with this Exhibit A unless updated by a
                payroll system automatically. When Company Size is updated by a
                payroll system automatically the count will be determined by the
                number of unique active W2 and 1099 employee records returned by
                the payroll data source for a given calendar month.
              </li>
              <li>
                <b>Automatic Payroll Integration</b> with isolved is included
                and automatically keeps the Platform in sync with the payroll
                data source.
              </li>
              <li>
                Employer Support includes Email support during normal business
                hours (9am to 5pm Pacific, M-F, excluding Federal Holidays). A
                dedicated Customer Success agent with online and video
                conference support for initial plan setup and administration
                during the first 60 days of contract term is also included.
              </li>
              <li>
                Employee Support includes Email and online support during normal
                business hours (9am to 5pm Pacific, M-F, excluding Federal
                Holidays).
              </li>
            </ol>
          </div>

          {/* // Invoicing and Payment */}
          <div>
            <p className="terms-number">3. Invoicing and Payment </p>
            <ol type="a">
              <li>
                All invoicing and payments are handled between User and isolved.
              </li>
            </ol>
          </div>

          {/* // Suspension of Service and Acceleration*/}
          <div>
            <p className="terms-number">
              4. Suspension of Service and Acceleration
            </p>
            <ol type="a">
              <li>
                If any charge owed by User under this or any other agreement for
                services with isolved is unfulfilled per the terms of the
                isolved Order Form, Icon may, without limiting its other rights
                and remedies, suspend Services until such amounts are paid in
                full, provided that Icon will give User at least 10 days’ prior
                notice that its account is overdue.
              </li>
            </ol>
          </div>

          {/* // User Separation From isolved */}
          <div>
            <p className="terms-number">5. User Separation From isolved</p>
            <ol type="a">
              <li>
                If User’s isolved Order Form is terminated or invalidated for
                any reason, this Exhibit A will also become invalid (Section
                1(b)(5)). User must establish a new Icon Exhibit A within 30
                calendar days of termination date.
              </li>
            </ol>
          </div>

          <div className="terms-footer">
            <div>
              <p>
                <b>IN WITNESS WHEREOF</b>, the parties, by their duly authorized
                representatives, have executed this Platform Services Agreement
                Exhibit A Order Form as of {today} (the “Effective Date”).
              </p>
            </div>

            <div className="terms-user-details">
              <span>
                <p className="terms-label">User</p>
                <p>Name: {signerName}</p>
                <p>Email: {signerEmail} </p>
                <p>
                  Company: {companyName} (Tax ID: {companyEIN})
                </p>
              </span>
              <span>
                <p className="terms-label">Icon</p>
                <p>Email: legal@iconsavingsplan.com</p>
                <p>
                  Company: The Icon Savings Corporation (Tax ID: 82-3955531)
                </p>
              </span>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const content = this._content();
    return <div>{content}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const companyName = state.employer.company.name;
  const companyEIN = state.employer.company.ein;
  const { info } = ownProps;

  return {
    companyName,
    companyEIN,
    ownProps,
    info,
  };
};

export default connect(mapStateToProps)(EmployerIsolvedTermsOrder);
