import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { isEmpty } from "lodash";
import { Form } from "react-bootstrap";
import { FiEdit3 } from "react-icons/fi";

import {
  linkEmployerToCrm,
  unlinkEmployerToCrm,
  getEmployerCrmLink,
  syncCrm,
} from "actions/adminActions";
import { adminConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import IconSpinner from "components/IconSpinner";
import Alert from "components/Alert";
import IconTable from "components/IconTable";
import IconTableHeader from "components/IconTableHeader";
import SimpleModal from "components/SimpleModal";
import Button from "components/Button";

class CrmLink extends React.PureComponent {
  static propTypes = {
    companyId: PropTypes.string,
    crmLink: PropTypes.object,

    error: PropTypes.string,
    isFetching: PropTypes.bool,
    client: PropTypes.object,

    getEmployerCrmLink: PropTypes.func,
    unlinkEmployerToCrm: PropTypes.func,
    linkEmployerToCrm: PropTypes.func,
    syncCrm: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      initialFetching: true,
      isUpdating: false,

      showEditModal: false,
      currentCrmLink: {
        ...props.crmLink,
      },
    };
  }

  async componentDidMount() {
    this.props
      .getEmployerCrmLink(this.props.client, {
        companyId: this.props.companyId,
      })
      .then(() => {
        this.setState({
          initialFetching: false,
        });
      });
  }

  _getTableMetadata = () => {
    return [
      {
        key: "providerReferenceId",
        label: "HubSpot Company Id",
      },
      {
        label: "Action",
        customComponent: (props) => {
          return (
            <span
              key={props.key}
              className="action-icon"
              onClick={() => {
                this.setState({
                  showEditModal: true,
                  currentCrmLink: props,
                });
              }}
            >
              <FiEdit3 size={18} stroke="#96AAC4" fill="white" />
            </span>
          );
        },
      },
    ];
  };

  saveHubSpotId = () => {
    this.setState({ isUpdating: true });
    this.props
      .linkEmployerToCrm(this.props.client, {
        companyId: this.props.companyId,
        providerReferenceId: this.state.currentCrmLink.providerReferenceId,
      })
      .then(() => {
        return this.props.getEmployerCrmLink(this.props.client, {
          companyId: this.props.companyId,
        });
      })
      .then(() => {
        this.setState({ showEditModal: false, isUpdating: false });
      });
  };

  render() {
    if (this.state.initialFetching) return <IconSpinner centered />;
    return (
      <div>
        <SimpleModal
          show={this.state.showEditModal}
          onClose={() => {
            this.setState({ showEditModal: false });
          }}
          onSubmit={this.saveHubSpotId}
          title="Update HubSpot Company Id"
          buttonText="Update"
          closeButtonText="Cancel"
          btnDisabled={isEmpty(this.state.currentCrmLink.providerReferenceId)}
          isFetching={this.state.isUpdating}
          isXhrRequest
          showCloseButton
        >
          <Form.Control
            autoFocus
            value={this.state.currentCrmLink.providerReferenceId}
            onChange={(event) => {
              this.setState({
                currentCrmLink: {
                  ...this.state.currentCrmLink,
                  providerReferenceId: event.target.value,
                },
              });
            }}
          />
        </SimpleModal>
        <IconTableHeader tableHeader="CRM Links" />
        <IconTable
          columns={this._getTableMetadata()}
          data={[this.props.crmLink]}
        />
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        <Button
          btnLabel={"Sync"}
          name="submit"
          disabled={isEmpty(this.props.crmLink.providerReferenceId)}
          withArrow={true}
          loading={this.props.isFetching}
          onClick={() =>
            this.props.syncCrm(this.props.client, {
              companyId: this.props.companyId,
            })
          }
        />
      </div>
    );
  }
}

const errorSelector = createErrorSelector(
  adminConstants.GET_COMPANY_CRM_LINK,
  adminConstants.LINK_COMPANY_TO_CRM,
  adminConstants.UNLINK_COMPANY_TO_CRM,
  adminConstants.SYNC_CRM_DATA
);

const loadingSelector = createLoadingSelector(adminConstants.SYNC_CRM_DATA);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isFetching: loadingSelector(state),
    crmLink: state.employer.crmLink,
  };
};

const mapDispatchToProps = {
  getEmployerCrmLink,
  linkEmployerToCrm,
  unlinkEmployerToCrm,
  syncCrm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(CrmLink));
