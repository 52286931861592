import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import { routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import rootReducer from "reducers";
import history from "utils/history";
import { isProduction } from "utils/determineSite";

const loggerMiddleware = createLogger();
export default function configureStore(initialState = {}) {
  // add redux dev tools if we are running in development
  const composeEnhancers =
    !isProduction() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        thunkMiddleware,
        loggerMiddleware
      )
    )
  );

  if (!isProduction()) {
    store.subscribe(() => {
      localStorage.setItem("reduxState", JSON.stringify(store.getState()));
    });
  }

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("reducers", () => {
      const nextRootReducer = require("reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
