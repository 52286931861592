import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Card, Col, Row } from "react-bootstrap";
import { isProduction } from "utils/determineSite";
import { findIndex, get, head } from "lodash";
import { getEmployeeDocumentUploadJobs } from "actions/employerActions";
import { hasFullyOnboarded } from "store/selectors/employer";
import { FiChevronRight } from "react-icons/fi";
import {
  matchColumns,
  rosterUploaderSteps,
  selectHeader,
  uploadFile,
  validateRows,
} from "statics/rosterSteps";

import UploadProcessing from "./UploadProcessing";
import UploadFailed from "./UploadFailed";
import AddEmployeeUploadForm from "./AddEmployeeUploadForm";
import Button from "components/Button";
import StepInfo from "components/StepInfo";

import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";

import "./Employees.scss";

const headingAndSubheaderMap = {
  [uploadFile]: {
    heading: "Upload Roster",
    subheading:
      "Let's import your employee roster to a group. Once imported you can send invites.",
  },
  [selectHeader]: {
    heading: "Select Row Header",
    subheading: "Select column headings row.",
  },
  [matchColumns]: {
    heading: "Match Columns",
    subheading: "Match your spreadsheet columns to the values Icon expects.",
  },
  [validateRows]: {
    heading: "Validate Rows",
    subheading:
      "Validate data is in expected format and submit if everything looks good.",
  },
};

class AddEmployeeUploadFormToggle extends React.Component {
  static propTypes = {
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    push: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    companyId: PropTypes.string,
    isFetching: PropTypes.bool,
    uploadEmployeesDocument: PropTypes.func,
    document: PropTypes.object,
    isIconAdmin: PropTypes.bool,
    onClose: PropTypes.func,
    isFetchingEmployerGroups: PropTypes.bool,
    employeeUploadJob: PropTypes.object,
    getEmployeeDocumentUploadJobs: PropTypes.func,
    inviteEmployeesHide: PropTypes.func,
    onCancel: PropTypes.func,
    hasFullyOnboarded: PropTypes.bool,
    onUploadSuccess: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      hasUploadedFile: false,
      inviteEmployeesHide: false,
      activeStep: uploadFile,
    };
  }

  _setActiveStep = (activeStep) => {
    this.setState({ activeStep });
  };

  _getJobState = () => {
    const status = get(this.props.employeeUploadJob, "status");
    const isFailed = status === "ERROR";
    const isProcessing = status === "PROCESSING";
    const isComplete = status === "PROCESSED";
    if (isFailed) {
      return (
        <UploadFailed
          failedJob={this.props.employeeUploadJob}
          isIconAdmin={this.props.isIconAdmin}
          onClose={this.props.onClose}
          showForm={() =>
            this.setState({ showForm: true, hasUploadedFile: false })
          }
        />
      );
    } else if (isComplete) {
      if (this.props.isIconAdmin) {
        return this.props.onClose();
      }
      this.props.onUploadSuccess();
    } else if (isProcessing) {
      return <UploadProcessing companyId={this.props.companyId} />;
    }
  };

  _renderPlaceholder = () => {
    return (
      <div className="download-employee-template">
        <div className="main-content">
          <Row>
            <Col as={Col} sm={8}>
              <Card>
                <Card.Title>
                  <IconHeader
                    variant="cardHeader"
                    headerText="Download a template"
                  />
                  <IconSubheader subheader="We made it easy for you by prefilling expected column headers. " />
                </Card.Title>
                <Card.Body>
                  <p className="choose-template">Choose File Type</p>
                  <Row>
                    <Col>
                      <a
                        className="link"
                        href={`https://iconsavingsplan-public.s3-us-west-2.amazonaws.com/${
                          isProduction() ? "production" : "staging"
                        }/documents/ICON_EMPLOYEE_UPLOAD_EXAMPLE_CSV.csv`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="select-box">
                          <img className="png" src="/assets/csv.svg"></img>
                        </div>
                      </a>
                    </Col>
                    <Col>
                      <a
                        className="link"
                        href={`https://iconsavingsplan-public.s3-us-west-2.amazonaws.com/${
                          isProduction() ? "production" : "staging"
                        }/documents/ICON_EMPLOYEE_UPLOAD_EXAMPLE.xlsx`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="select-box">
                          <img className="png" src="/assets/excel.svg"></img>
                        </div>
                      </a>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="processing-steps">To properly process:</p>
                      <ul className="steps">
                        <li>Download a template (CSV or Excel format).</li>
                        <li>
                          Enter the roster data in the appropriate columns.
                        </li>
                        <li>
                          Match the data formats used in the example rows.
                        </li>
                        <li>Do not modify row one (header values).</li>
                        <li>Save file with appropriate name.</li>
                      </ul>
                    </Col>
                  </Row>
                </Card.Body>
                <div className="submit btn-row">
                  {this.props.isIconAdmin && (
                    <Button
                      btnLabel="Go Back"
                      name="cancel"
                      color="cancel"
                      onClick={this.props.onClose}
                    />
                  )}

                  {!this.props.hasFullyOnboarded && (
                    <Button
                      btnLabel="Cancel"
                      color="cancel"
                      name="cancel"
                      onClick={this.props.onCancel}
                    />
                  )}

                  <Button
                    btnLabel="Next"
                    name="action"
                    icon={{
                      icon: <FiChevronRight size={8} color={"#FFFFF"} />,
                      position: "right",
                    }}
                    onClick={() => this.setState({ showForm: true })}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  render() {
    let content;

    if (this.state.showForm && !this.state.hasUploadedFile) {
      content = (
        <AddEmployeeUploadForm
          isIconAdmin={this.props.isIconAdmin}
          companyId={this.props.companyId}
          hideForm={() => this.setState({ showForm: false })}
          onStepChange={this._setActiveStep}
          onClose={() =>
            this.setState({ showForm: false, hasUploadedFile: true })
          }
        />
      );
    } else if (!this.state.hasUploadedFile) {
      content = this._renderPlaceholder();
    } else if (this.state.hasUploadedFile) {
      content = this._getJobState();
    } else {
      content = this._renderPlaceholder();
    }

    const activeStepNumber = findIndex(rosterUploaderSteps, {
      key: this.state.activeStep,
    });
    const { heading, subheading } =
      headingAndSubheaderMap[this.state.activeStep];

    return (
      <div className="add-employee-form" style={{ marginBottom: 8 }}>
        <section className="page-title-wrap">
          <article className="text-cell">
            <div className="dashboard-header"></div>
            <StepInfo
              steps={rosterUploaderSteps}
              activeStepNumber={activeStepNumber}
            />
            <div className="step-heading">
              <h1 className="page-title">{heading}</h1>
              <p className="page-subtext">{subheading}</p>
              <div>{content}</div>
            </div>
          </article>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groups: state.employer.groups,
    employeeUploadJob: head(get(state, "employer.employeeUploadJobs", [])),
    //OnBoardingSelectors
    hasFullyOnboarded: hasFullyOnboarded(state),
  };
};
const mapDispatchToProps = {
  push,
  getEmployeeDocumentUploadJobs,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddEmployeeUploadFormToggle));
