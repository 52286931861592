import { map, filter } from "lodash";

export const getPeriodState = (state) => {
  const periodState = map(state.employer.currentPayrolls, "periodState");
  return periodState;
};

//Late Payrolls
export const latePayrolls = (state) => {
  const periodState = getPeriodState(state);
  return filter(periodState, (key) => key === "MISSED");
};
