import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { map, orderBy, some, isEmpty, filter, slice } from "lodash";

import { transactionType } from "statics/propTypes";
import classnames from "classnames";

import Button from "components/Button";
import ConfirmAchCancellationModal from "pages/dashboards/individualDashboard/dashboard/ConfirmAchCancellationModal";
import "./IndividualContributions.scss";
import { paymentStatusToEnglish } from "statics/statusToEnglishMapping";

const achDepositCategory = "IRA_CONTRIBUTION_ADHOC";

export class OneTimeContributionCard extends React.PureComponent {
  static propTypes = {
    transactions: PropTypes.arrayOf(transactionType),
    isEmployer: PropTypes.bool,
    contributionInfo: PropTypes.shape({
      amount: PropTypes.number,
      end: PropTypes.string,
      schedule: PropTypes.string,
      scheduleId: PropTypes.string,
      frequency: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);

    this.state = {
      showConfirmationModal: false,
      pendingCancellationId: null,
    };
  }

  _buildTransactionRow = () => {
    return map(this.props.transactions, (transaction) => {
      const createdAt = moment(transaction.createdAt).format("MMM Do YYYY");
      let action = null;
      const cancellationPending =
        transaction.cancellationStatus === "SUBMITTED";
      if (
        transaction.status === "PENDING" &&
        !cancellationPending &&
        transaction.subCategory === achDepositCategory
      ) {
        action = (
          <>
            <span className="remove-bank">
              <Button
                btnLabel="cancel"
                name="cancel"
                color="red"
                size="sm"
                onClick={() => this._openConfirmModal(transaction.id)}
              />
            </span>
          </>
        );
      }

      let status =
        paymentStatusToEnglish[transaction.status] || transaction.status;
      status = cancellationPending ? "Cancellation pending" : status;

      const oneTimeContributionAmount = slice(transaction.memo, 30);
      return (
        <div className="one-time-contribution-container" key={transaction.id}>
          <div
            className={classnames("contribution-source-container", {
              "bank-source": !this.props.isEmployer,
            })}
          >
            {this.state.showConfirmationModal && (
              <ConfirmAchCancellationModal
                onClose={this._closeConfirmModal}
                transactionId={this.state.pendingCancellationId}
              />
            )}
            <div
              className={classnames("contribution-info", {
                ["no-contribution"]: isEmpty(this.props.contributionInfo),
              })}
            >
              <div className="contribution-heading">
                <h3 className="contribution">One-Time Contribution</h3>
                <div className="contribution-schedule">
                  <span className="contribution-status">Status:</span>
                  {this.state.pendingCancellationId}
                  <span className="contribution-status"> {status}</span>
                </div>
              </div>
              <div className="contribution-amount-and-actions">
                <span className="contribution-amount">
                  {oneTimeContributionAmount}
                </span>
              </div>
              <div className="flex-break" />
              <div className="menu-and-date">
                <div className="next-run-date">
                  <span className="dot" />
                  <span>Contribution Date: {createdAt}</span>
                </div>

                <div className="menu-btn">
                  <div>{action}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  _openConfirmModal = (pendingCancellationId) => {
    this.setState({
      pendingCancellationId,
      showConfirmationModal: true,
    });
  };

  _closeConfirmModal = () => {
    this.setState({
      showConfirmationModal: false,
      pendingCancellationId: null,
    });
  };

  _transactionsContainPendingAchDeposit = () => {
    return some(
      this.props.transactions,
      (t) =>
        t.subCategory === achDepositCategory &&
        t.status === "PENDING" &&
        t.cancellationStatus !== "SUBMITTED"
    );
  };

  render() {
    const containsPendingAch = this._transactionsContainPendingAchDeposit();
    const transactionRow = this._buildTransactionRow(containsPendingAch);

    return (
      <div className="help">
        <div>{containsPendingAch}</div>
        <div>{transactionRow}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const pendingAchTransactions = filter(
    state.transactions.transactions,
    (t) =>
      t.subCategory === achDepositCategory &&
      (t.status === "PENDING" || t.status === "HOLD")
  );
  const descendingTransactions = orderBy(
    pendingAchTransactions,
    "createdAt",
    "desc"
  );
  return {
    transactions: descendingTransactions,
  };
};

export default connect(mapStateToProps, null)(OneTimeContributionCard);
