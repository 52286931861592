import React from "react";
import PropTypes from "prop-types";
import { get, groupBy, map, transform } from "lodash";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { AGENT_ROLE_ID } from "statics/roles";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { enableProductFeatures } from "actions/adminActions";
import {
  EmployerApplicationApproved,
  EmployerPendingKYBSubmission,
  EmployerRegistrationComplete,
  EmployerTermsComplete,
  getStatusLabel,
} from "statics/states";
import {
  getCompany,
  getCompanyAdminsAndAgents,
  getCompanyKybData,
  getCompanyMembers,
  getTakeRateForCompany,
} from "services/adminService";

import IconHeader from "components/IconHeader";
import InfoTable from "components/InfoTable";
import Button from "components/Button";
import ProductFeatures from "./ProductFeatures";
import BankLinkModal from "./BankLinkModal";
import DisableCompanyModal from "./DisableCompanyModal";
import IconSpinner from "components/IconSpinner";
import AdminsAndAgents from "./AdminsAndAgents";
import Company from "pages/dashboards/adminDashboard/KYB/Company";

import "./EmployerInfo.scss";
import { getEmployerTakeRateDownloadUrl } from "services/documentService";
import { simulateLinkClickNewTab } from "utils/dom";
import { wrapError } from "utils/errorHelper";
import { FiDownload } from "react-icons/fi";
import Alert from "components/Alert";
import { Link } from "react-router-dom";
import PayrollIntegrationStatusField from "pages/dashboards/adminDashboard/employers/PayrollIntegrationStatusField";

function buildProductFeaturesObject(features) {
  return transform(
    features,
    (acc, val) => {
      acc[val] = true;
      return acc;
    },
    {}
  );
}

class EmployerInfo extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    push: PropTypes.func,
    enableProductFeatures: PropTypes.func,
    companyId: PropTypes.string,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      company: {},
      takeRate: {},
      showLinkModal: false,
      showEditForm: false,
      isFetching: true,
      organization: {},
      members: [],
      downloadError: null,
    };
  }

  async componentDidMount() {
    const {
      company,
      takeRate = {},
      administrators,
      members = [],
      kybInfo,
    } = await Promise.props({
      company: getCompany(this.props.client, this.props.companyId),
      members: getCompanyMembers(this.props.client, this.props.companyId),
      takeRate: getTakeRateForCompany(this.props.client, this.props.companyId),
      kybInfo: getCompanyKybData(this.props.client, this.props.companyId),
      administrators: getCompanyAdminsAndAgents(
        this.props.client,
        this.props.companyId
      ),
    });

    this.setState({
      company: {
        ...company,
        ...takeRate,
        silaWalletId: get(kybInfo, "silaWallet.accountNumber", ""),
        enabledProductFeatures: buildProductFeaturesObject(
          company.enabledProductFeatures
        ),
      },
      isFetching: false,
      administrators,
      showDisableModal: false,
      members,
    });
  }

  _onOrgCreation = (organization) => {
    this.setState({ organization });
  };

  _getKybButton = () => {
    const companyState = this.state.company.currentState;

    const baseUrl = `/employers/${this.props.companyId}/kyb/`;
    switch (companyState) {
      case EmployerTermsComplete:
        return (
          <Button
            size="sm"
            name="action"
            onClick={() => this.props.push(baseUrl + "summary")}
            btnLabel="KYB Summary"
          />
        );
      case EmployerRegistrationComplete:
        return (
          <Button
            size="sm"
            name="action"
            onClick={() => this.props.push(baseUrl + "summary")}
            btnLabel="KYB Summary"
          />
        );
      case EmployerPendingKYBSubmission:
        return (
          <Button
            size="sm"
            name="action"
            onClick={() => this.props.push(baseUrl + "summary")}
            btnLabel="KYB Summary"
          />
        );
      case EmployerApplicationApproved:
        return (
          <Button
            size="sm"
            name="action"
            onClick={() => this.setState({ showEditForm: true })}
            btnLabel="Edit Info"
            color="secondary"
          />
        );
      default:
        return null;
    }
  };

  _closeLinkModal = () => {
    this.setState({ showLinkModal: false });
  };

  _showLinkModal = () => {
    this.setState({ showLinkModal: true });
  };

  _showDisableModal = () => {
    this.setState({ showDisableModal: true });
  };
  _closeDisableEmployerModal = (state) => {
    this.setState({
      showDisableModal: false,
      company: { ...this.state.company, currentState: state },
    });
  };

  _refetchAdminsAndAgents = async () => {
    this.setState({
      fetchingAdmins: true,
    });
    const administrators = await getCompanyAdminsAndAgents(
      this.props.client,
      this.props.companyId
    );

    this.setState({
      administrators,
      fetchingAdmins: false,
    });
  };

  _updateProductFeatures = (enabledFeatures) => {
    this.props
      .enableProductFeatures(this.props.client, {
        companyId: this.props.companyId,
        featureIds: enabledFeatures,
      })
      .then(() => {
        if (!this.props.error) {
          toast.success("Successfully updated enabled product features.");
        }
      });
  };

  _downloadFile(status) {
    getEmployerTakeRateDownloadUrl(this.props.client, {
      groupId: null,
      status,
      companyId: this.props.companyId,
      timeframe: "ALL_TIME",
    })
      .then((response) => {
        simulateLinkClickNewTab(response.url);
      })
      .catch((error) => {
        this.setState({ downloadError: wrapError(error) });
      });
  }

  _buildTakeRateItems = () => {
    const takeRateItems = [
      {
        key: "notStarted",
        apiKey: "NOT_STARTED",
        label: "Not Enrolled",
      },
      {
        key: "opening",
        apiKey: "OPENING",
        label: "Enrolling",
      },
      {
        key: "enrolled",
        apiKey: "ENROLLED",
        label: "Enrolled",
      },
    ];

    return map(takeRateItems, (item) => {
      return {
        ...item,
        format: (val) => {
          return (
            <div>
              <span>{val}</span>
              <a
                className="link"
                target="_blank"
                onClick={() => this._downloadFile(item.apiKey)}
              >
                <FiDownload
                  fill={"#FFFFFF"}
                  color={"#FFFFFF"}
                  stroke={"#29b0c2"}
                  strokeWidth={"1.5"}
                  size={"16px"}
                />
              </a>
            </div>
          );
        },
      };
    });
  };

  onPayrollIntegrationSuccess = (status) => {
    this.setState({
      company: {
        ...this.state.company,
        payrollIntegrationStatus: status,
      },
    });
  };

  getPayrollIntegrationStatusField = () => {
    const payrollIntegrationStatus = get(
      this.state.company,
      "payrollIntegrationStatus",
      null
    );

    if (!payrollIntegrationStatus) {
      return null;
    }

    return {
      key: "payrollIntegrationStatus",
      label: "Payroll Integration Status",
      format: (status) => {
        return (
          <PayrollIntegrationStatusField
            status={status}
            companyId={this.props.companyId}
            onSuccess={this.onPayrollIntegrationSuccess}
          />
        );
      },
    };
  };

  _getMetadata = () => {
    const membersSection = map(this.state.members, (member, idx) => {
      return {
        key: `members.${idx}`,
        label: `Member #${idx + 1}`,
        format(val) {
          const { firstName, lastName } = val.memberProfile;
          return `${firstName} ${lastName} - ${val.businessMemberHandle}`;
        },
      };
    });
    const payrollIntegrationStatusField =
      this.getPayrollIntegrationStatusField();
    return [
      {
        key: "name",
        label: "Company Name",
      },
      {
        key: "dba",
        label: "Company DBA",
      },
      {
        key: "id",
        label: "Company ID",
      },

      {
        key: "user.id",
        label: "Company Admin Id",
      },
      {
        key: "user.email",
        label: "Company Admin Email",
      },

      {
        key: "currentState",
        label: "State",
        format(state) {
          const friendlyLabel = getStatusLabel(state);
          const stateLabel = state ? state : "None";
          return `${friendlyLabel}(${stateLabel})`;
        },
      },
      {
        key: "progressiveOnboardingState",
        label: "Progressive Onboarding State",
      },
      ...(payrollIntegrationStatusField ? [payrollIntegrationStatusField] : []),
      {
        key: "planId",
        label: "Plan ID",
      },
      {
        key: "payrollProvider",
        label: "Payroll Provider",
      },
      {
        key: "billingType",
        label: "Billing Type",
      },
      ...this._buildTakeRateItems(),

      {
        key: "silaWalletId",
        label: "Sila Wallet ID",
      },
      ...membersSection,
    ];
  };

  render() {
    if (this.state.isFetching) {
      return <IconSpinner centered />;
    }

    const metadata = this._getMetadata();

    const { agents, administrators } = groupBy(
      this.state.administrators,
      (admin) => {
        if (admin.user.userRoleId === AGENT_ROLE_ID) {
          return "agents";
        }
        return "administrators";
      }
    );

    if (this.state.showEditForm) {
      return (
        <Company
          companyId={this.state.company.id}
          hasPassedKyb={true}
          onUpdate={async () => {
            this.setState({ showEditForm: false });
            const company = await getCompany(
              this.props.client,
              this.props.companyId
            );
            this.setState({
              company: {
                ...this.state.company,
                ...company,
              },
            });
          }}
          onBack={() => {
            this.setState({ showEditForm: false });
          }}
        />
      );
    }

    return (
      <>
        {this.state.showLinkModal && (
          <BankLinkModal
            companyId={this.props.companyId}
            onClose={this._closeLinkModal}
          />
        )}
        {this.state.showDisableModal && (
          <DisableCompanyModal
            company={this.state.company}
            onClose={this._closeDisableEmployerModal}
          />
        )}

        <div className="action-box">
          <div className="action-header">
            <span>
              <IconHeader
                variant="cardHeader"
                headerText="Employer Information"
              />
            </span>

            <div>
              <Button
                size="sm"
                name="action"
                loading={this.state.disablingCompany}
                onClick={this._showDisableModal}
                disabled={
                  this.state.company.currentState !==
                  EmployerApplicationApproved
                }
                btnLabel="Disable Company"
                color="red"
              />
              <span> {this._getKybButton()}</span>

              <span style={{ paddingLeft: 5 }}>
                <Button
                  size="sm"
                  name="action"
                  onClick={this._showLinkModal}
                  disabled={
                    this.state.company.currentState !==
                    EmployerApplicationApproved
                  }
                  btnLabel="Link Bank"
                />
              </span>
            </div>
          </div>
          <div className="action-body">
            <InfoTable
              metadata={metadata}
              data={{ ...this.state.company, members: this.state.members }}
            />
            {this.state.downloadError && (
              <Alert msg={this.state.downloadError} type={"error"} />
            )}
            <Link
              to={`/dashboard/reports/custom/new?employerId=${this.props.companyId}`}
              className="link"
              style={{ paddingLeft: 0, marginTop: 8, display: "inline-block" }}
            >
              Create Contribution Report
            </Link>
            <div style={{ textAlign: "right" }}>
              <Link
                to={`/workflows?entityId=${this.props.companyId}`}
                className="icon-link"
              >
                View Workflows
              </Link>
            </div>
          </div>
        </div>
        <ProductFeatures
          companyId={this.props.companyId}
          error={this.props.error}
          isFetching={this.props.isFetching}
          onChange={this._updateProductFeatures}
          enabledProductFeatures={this.state.company.enabledProductFeatures}
        />
        <AdminsAndAgents
          agents={agents}
          administrators={administrators}
          companyId={this.props.companyId}
          fetchAdmins={this._refetchAdminsAndAgents}
        />
      </>
    );
  }
}

const errorSelector = createErrorSelector(
  adminConstants.ENABLE_PRODUCT_FEATURES
);
const loadingSelector = createLoadingSelector(
  adminConstants.ENABLE_PRODUCT_FEATURES
);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isFetching: loadingSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  enableProductFeatures,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerInfo));
