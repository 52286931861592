import { portfolioConstants } from "actions/types";
import { camelCase, map } from "lodash";

const initialState = {
  allPortfolios: [],
  otherPortfolios: [],
  recommendedPortfolio: {},
  activePortfolio: {},
  positions: [],
  positionsUpdatedAt: "",
  categoryPerformances: [],
  performanceUpdatedAt: "",
};

function formatPortfolio(portfolio) {
  return {
    ...portfolio,
    portfolioName: camelCase(portfolio.label),
  };
}
export function portfolios(state = initialState, action) {
  switch (action.type) {
    case portfolioConstants.GET_PORTFOLIOS_SUCCESS:
      return {
        ...state,
        otherPortfolios: map(action.data.other, formatPortfolio),
        recommendedPortfolio: formatPortfolio(action.data.recommended),
      };

    case portfolioConstants.GET_USER_PORTFOLIO_SUCCESS:
    case portfolioConstants.UPDATE_USER_PORTFOLIO_SUCCESS:
      return { ...state, activePortfolio: action.data };

    case portfolioConstants.GET_POSITIONS_SUCCESS:
      return {
        ...state,
        positions: action.data && action.data.positions,
        positionsUpdatedAt: action.data && action.data.dateTime,
      };
    case portfolioConstants.GET_PERFORMANCE_SUCCESS:
      return {
        ...state,
        categoryPerformances: action.data && action.data.categoryPerformances,
        performanceUpdatedAt: action.data && action.data.updatedAt,
      };
    default:
      return state;
  }
}
