import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import Alert from "components/Alert";

import Button from "components/Button";
import queryString from "query-string";
import { scrollToTop } from "utils/dom";

import { optOut } from "actions/userActions";
import { createErrorSelector } from "store/selectors";
import { userConstants } from "actions/types";
import { withApollo } from "@apollo/client/react/hoc";

class IndividualOptOut extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    optOut: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
  };

  _isMounted = false;

  constructor(props) {
    super(props);

    const invitationCode = queryString.parse(
      window.location.search
    ).invitationCode;

    const inviteId = queryString.parse(window.location.search).inviteId;

    this.state = {
      urlHadInviteId: !!inviteId,
      invitationCode: invitationCode || "",
      inviteId: inviteId || "",
      optedOut: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    scrollToTop();
    if (!this.state.optedOut && this.state.urlHadInviteId) {
      this.props
        .optOut(this.props.client, this.state.inviteId, true)
        .then(() => {
          if (!this.props.error) {
            this.setState({
              optedOut: true,
            });
          }
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <Card className="opt-out-container">
        <Card.Body>
          <img className="door-img" src="/assets/door.png" />
          <Card.Title>
            You{"'ve"} successfully opted out of the Icon Savings retirement
            plan.
          </Card.Title>
          <p className="sub-text">
            You can opt back in at anytime. If you would like to do so now,
            click the button below and create an account.
          </p>
          <Button
            name="action"
            onClick={() => {
              this.props
                .optOut(this.props.client, this.state.inviteId, false)
                .then(() => {
                  this.setState({
                    optedOut: false,
                  });
                });
              this.props.push(
                `/?inviteId=${this.state.inviteId}&invitationCode=${this.state.invitationCode}`
              );
            }}
            btnLabel="Opt In?"
          />
          {this.props.error && <Alert type="error" msg={this.props.error} />}
        </Card.Body>
        <div></div>
      </Card>
    );
  }
}

const actions = [userConstants.OPT_OUT];

const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  push,
  optOut,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualOptOut));
