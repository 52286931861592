/* eslint-disable react/no-unescaped-entities */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class EmployerPartnerProgramAgreement extends React.Component {
  static propTypes = {
    companyName: PropTypes.string,
  };

  _content = () => {
    const companyName = this.props.companyName;

    return (
      <div className="terms-of-service">
        <div>
          <p>
            This <b>Referral Program Agreement</b>, in combination with other
            Agreements relating to your use of the Icon Savings Plan Services,
            is made between {companyName} ("you", "your") and The Icon Savings
            Corporation ("we," "our", "us", “Icon”, “Icon Savings Plan”) and
            governs your participation in the Icon Savings Plan Employer
            Referral Program (the "Referral Program").
          </p>
        </div>

        <div>
          <p>
            For purposes of this Referral Program Agreement, references to
            Company include any Authorized Users authorized to participate in
            the Referral Program on Company's behalf. Company shall be fully
            responsible for the acts or omissions of its Authorized Users. By
            requesting or sending a Referral Link (defined below), you will be
            deemed to have accepted and be bound by this Agreement.
          </p>
        </div>

        <div>
          <p>
            The Icon Terms of Service located at
            (https://iconsavingsplan.com/terms), as amended from time to time,
            are incorporated into and form part of this Referral Program
            Agreement and shall apply to your participation in the Referral
            Program. In the event of any inconsistency between any of the
            provisions of the Icon Terms of Service and this Referral Program
            Agreement, this Referral Program Agreement will control. Any terms
            that are not defined in this Referral Program Agreement shall have
            the meaning provided in the Icon Terms of Service. We may modify
            this Referral Program Agreement at any time by posting an updated
            version on our website. By continuing to participate in the Referral
            Program after the posting of such modifications, you shall be deemed
            to accept such modifications.
          </p>
        </div>

        <div>
          <p className="terms-label">PURPOSE AND REFERRAL PROCESS</p>
          <p>
            <b>Purpose.</b> Icon Savings Plan and Company wish to enter into an
            agreement for Company to refer to Icon Savings Plan employer
            customers interested in the services and products of Icon Savings
            Plan (the “Services”) in exchange for Referral Compensation, as
            further described in this Agreement.
          </p>
          <p>
            <b>Eligibility.</b> In order to participate in the Icon Referral
            Program, you must (1) be an Icon Customer in good standing; (2) have
            completed at least one payroll contribution run; and (3) ensure that
            all Authorized Users distributing Referral Links are natural persons
            of at least 18 years of age.
          </p>
          <p>
            <b> Definitions.</b>“Referral” means a third party referred by
            Company that may be interested in Icon Savings Plan’s services
          </p>
          <p>
            “Referred customer” means a third party referred by Company who
            enters into a subscription agreement with Icon Savings Plan via the
            online purchasing page.
          </p>

          <p>
            <b> Referral Submission and Qualification.</b> Icon will provide you
            with a unique URL that you may provide to your contacts as
            prospective Icon Savings Plan customers (the "Referral Link").
            Referred customers must be businesses engaging in commercial
            activity for the sale of goods and/or services. Referral
            Compensation will only be awarded for Qualifying Referrals. A
            "Qualifying Referral" must meet all of the following conditions:
            <div className="terms-list">
              <ul>
                <li>
                  The referred customer is not registered, and was not
                  previously registered, with Icon under any email address or
                  alias;
                </li>
                <li>
                  The referred customer is approved to use Icon’s Services;
                </li>
                <li>
                  The referred customer completes the registration process with
                  Icon using the referrer's Referral Link. If a referred
                  customer registers with Icon using any other link or method,
                  the registration will not count as a Qualifying Referral;
                </li>
                <li>
                  The referred customer signs a contract for Icon’s Services;
                  and
                </li>
                <li>
                  The referred customer successfully completes at least one
                  payroll contribution run.
                </li>
              </ul>
            </div>
            <p>
              The Referral Link should only be provided directly to your
              Authorized Users' personal acquaintances via direct one-to-one
              communication (for example, an email sent solely to the referred
              customer contact or a direct LinkedIn message), or via posting to
              a social media account associated with the Company or it’s
              Authorized Users (for example Twitter or Facebook).
            </p>
            <p>
              Additionally, you and your Authorized Users may not use any paid
              advertisement to promote the Referral Program, including but not
              limited to, paid search marketing, advertisements, affiliate
              networks, paid email campaigns, paid social campaigns, or other
              similar methods.
            </p>
            <p>
              You may not use a Referral Link to sign up for Icon’s Services for
              Company, its affiliated entities, or yourself. By participating in
              the Referral Program, you represent and warrant that (i) each of
              your Authorized Users has a personal relationship with his/her
              referral or otherwise has the necessary consents to submit their
              information to the Referral Program; and (ii) each of your
              Authorized Users will comply with all applicable Icon policies and
              terms, and any and all applicable laws or regulations.
            </p>
          </p>

          <p>
            <b>Acceptance of Referrals.</b> Icon may decline a Referral if, as
            of the date that complete referral information was provided by
            Company:
            <div className="terms-list">
              <ul>
                <li>
                  The Referral is in possession of a current proposal from Icon
                  Savings Plan;
                </li>
                <li>
                  The Referral has had one or more face-to-face meetings or
                  substantial communications with Icon Savings Plan
                  representatives during the six months preceding the date of
                  receipt of the referral submission;
                </li>
                <li>
                  Icon Savings Plan has already received within the last six
                  months a referral submission for that Referral from a third
                  party; or
                </li>
                <li>
                  Icon Savings Plan has already established a business
                  relationship with the Referral or an affiliate of the Referral
                  under a current agreement between Icon Savings Plan and such
                  Referral or affiliate.
                </li>
              </ul>
            </div>
          </p>
        </div>

        <div>
          <p className="terms-label">REFERRAL COMPENSATION</p>
          <p>
            <b>Referral Compensation.</b> You may be eligible to earn Referral
            Compensation for each qualified referral that you refer. For each
            Referred Customer from Company, Icon Savings Plan will credit to
            Company a flat referral fee based on the employee count of the
            Referred Customer at the time of Referred Customer’s first payroll
            contribution run. Referral compensation amount will be subject to
            applicable taxes and will be credited to Company’s Icon billing
            portal within 60 days post the first payroll run by Referred
            Customer. Referred customer compensation amount will be subject to
            applicable taxes and will be credited to Referred Customer’s Icon
            billing portal within 60 days post the first payroll run by Referred
            Customer.
          </p>
          <p className="terms-number">
            All compensation amounts are outlined below:
          </p>
          <div className="terms-table">
            <span className="table-col">
              <span>
                <p>Referred customer employee count </p>
                <p>1 - 4</p>
                <p>5 - 25</p>
                <p>26 - 50</p>
                <p>51+</p>
              </span>
            </span>

            <span className="divider"></span>

            <span className="table-col">
              <p>Referral Compensation amount</p>
              <span>
                <p>$100</p>
                <p>$150</p>
                <p>$200</p>
                <p>$250</p>
              </span>
            </span>

            <span className="divider"></span>
            <span className="table-col">
              <p>Referred customer compensation amount</p>
              <span>
                <p>$50</p>
                <p>$100</p>
                <p>$180</p>
                <p>$280</p>
              </span>
            </span>
          </div>
        </div>

        <div>
          <p className="terms-label">COMPANY’S OBLIGATIONS</p>
          <p>
            <b> Business Practices.</b> When seeking customer referrals and
            otherwise performing under this Agreement, Company will not engage
            in any deceptive, misleading, illegal, or unethical practices; not
            make any representations or warranties concerning the Service,
            except as set forth in printed marketing collateral or documentation
            furnished by Icon Savings Plan; and comply with all applicable
            federal, state, and local laws and regulations. Company will not
            have the authority, express or implied, to make any commitment or
            incur any obligations on behalf of Icon Savings Plan other than
            making Referrals as set forth in this Agreement.
          </p>
        </div>

        <div>
          <p className="terms-label">TERM AND TERMINATION</p>
          <p>
            Icon reserves the right to suspend or terminate the Referral Program
            at any time or to change this Referral Program Agreement at any time
            and for any reason in our sole discretion upon Notice to you or by
            deactivation of the Referral Link. Your eligibility for the Referral
            Program and the eligibility of any referrals is determined in the
            sole discretion of Icon.
          </p>
          <p>
            Icon reserves the right to withhold, deny or cancel any Referral
            Compensation and/or terminate your access to the Icon Services
            (without any obligation to refund any prepaid fees for unused
            Services) if we, in our sole discretion, deem the award of any
            Referral Bonus as fraudulent, abusive, unethical, suspicious or
            otherwise inconsistent with these Referral Program Terms, the
            Platform Services Agreement or any other applicable laws or
            regulations.
          </p>
        </div>

        <div>
          <p className="terms-label">INTELLECTUAL PROPERTY</p>
          <p>
            Icon is the owner of all intellectual property related to the
            Referral Link, including and without limitation the Services and
            related technology; Icon Confidential Information; and copyrights,
            patents, trade secrets, trade or service marks, brands, logos, and
            other intellectual property incorporated into each of the foregoing
            (the "Icon Property"). Icon grants you a limited, nonexclusive,
            revocable, and nontransferable license to use Icon Property solely
            as permitted by this Referral Program Agreement.
          </p>
        </div>

        <div>
          <p className="terms-header">PRIVACY</p>
          <p>
            Any information obtained in connection with the Referral Program
            will be collected, processed and used in accordance with Icon
            Savings Plan’s Privacy Policy located at
            (https://iconsavingsplan.com/privacy).
          </p>
        </div>

        <div>
          <p className="terms-header">ENTIRE AGREEMENT</p>
          <p>
            This Referral Program Agreement constitutes the entire understanding
            between Company and Icon for the subject matter described and no
            other agreements, representations, or warranties other than those
            provided in this Referral Program Agreement will be binding unless
            in writing and signed by you and Icon.
          </p>
        </div>

        <div>
          <p className="terms-header">SEVERABILITY</p>
          <p>
            If any provision of this Agreement shall be held to be invalid or
            unenforceable for any reason, the remaining provisions shall
            continue to be valid and enforceable.
          </p>
          <p>
            If a court finds that any provision of this Agreement is invalid or
            unenforceable, but that by limiting such provision it would become
            valid and enforceable, then such provision shall be deemed to be
            written, construed, and enforced as so limited.
          </p>
        </div>

        <div>
          <p className="terms-header">WAIVER OF CONTRACTUAL RIGHTS</p>
          <p>
            The failure of either Party to enforce any provision of this
            Agreement shall not be construed as a waiver or limitation of that
            Party's right to subsequently enforce and compel strict compliance
            with every provision of this Agreement.
          </p>
        </div>

        <div>
          <p className="terms-header">APPLICABLE LAW</p>
          <p>
            This Agreement shall be governed by the laws of the State of
            California.
          </p>
        </div>
      </div>
    );
  };

  render() {
    const content = this._content();
    return <div>{content}</div>;
  }
}
const mapStateToProps = (state, ownProps) => {
  const companyName = state.employer.company.name;

  return {
    companyName,
    ownProps,
  };
};

export default connect(mapStateToProps)(EmployerPartnerProgramAgreement);
