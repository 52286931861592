import React from "react";
import { formatCurrency, formatPercentage } from "utils/number";
import { PropTypes } from "prop-types";

function formatValue(value, format) {
  if (!format) return value;

  switch (format) {
    case "currency":
      return formatCurrency(value);
    case "percentage":
      return formatPercentage(value);
    default:
      return value;
  }
}
import "./Kpi.scss";
const Kpi = ({ label, value, format }) => {
  return (
    <div className="kpi-container">
      <p className="kpi-label">{label}</p>
      <p className="kpi-value">{formatValue(value, format)}</p>
    </div>
  );
};

Kpi.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
};

export default Kpi;
