import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { isEmpty, transform, upperCase } from "lodash";
import { FiDollarSign } from "react-icons/fi";
import moment from "moment";

import { getPaginatedMissedPayrolls } from "actions/adminActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import Alert from "components/Alert";
import Paginator from "components/Paginator";
import SimpleModal from "components/SimpleModal";
import InfoTable from "components/InfoTable";
import IconTable from "components/IconTable";
import IconSpinner from "components/IconSpinner";
import IconEmptyState from "components/IconEmptyState";
import IconTableHeader from "components/IconTableHeader";
import { Col, Form, Row } from "react-bootstrap";
import { adminConstants } from "actions/types";
import DatePicker from "react-datepicker";

class Payrolls extends React.PureComponent {
  static propTypes = {
    getPaginatedMissedPayrolls: PropTypes.func,
    client: PropTypes.object,
    isFetching: PropTypes.bool,
    payrollsCount: PropTypes.number,
    missedPayrolls: PropTypes.arrayOf(PropTypes.shape({})),
    companyId: PropTypes.string,
    error: PropTypes.string,
  };

  constructor(props) {
    super(props);

    let filters = {
      date: moment().utc().format("YYYY-MM-DD"),
    };
    if (!isEmpty(props.companyId)) {
      filters = {
        ...filters,
        companyId: props.companyId,
      };
    }

    this.state = {
      initialFetching: true,
      metadata: {},
      page: 0,
      limit: 25,
      offset: 0,
      totalRows: 0,
      missedPayrolls: [],
      filters,
    };
  }

  componentDidMount() {
    this._getPaginatedMissedPayrolls();
  }

  _onPageChange = ({ selected }) => {
    const offset = selected * this.state.limit;

    this.setState({ page: selected, offset }, () =>
      this._getPaginatedMissedPayrolls()
    );
  };

  _getPaginatedMissedPayrolls = () => {
    this.setState({ fetching: true }, () => {
      this.props
        .getPaginatedMissedPayrolls(this.props.client, {
          limit: this.state.limit,
          offset: this.state.offset,
          ...this.state.filters,
        })
        .then(() => {
          this.setState({
            initialFetching: false,
          });
        })
        .catch((ex) => {
          this.setState({
            fetching: false,
            initialFetching: false,
            error: ex.message,
          });
        });
    });
  };

  _buildPayrollColumns = () => {
    const columns = [
      {
        label: "Pay Period Id",
        key: "payPeriodId",
      },
      {
        label: "Employer Id",
        key: "entity.id",
      },
      {
        label: "Pay Period State",
        key: "periodState",
      },
      {
        label: "Schedule Period",
        customComponent: (props) => {
          return (
            <div>
              {props.start} - {props.end}
            </div>
          );
        },
      },
      {
        label: "Pay Period",
        customComponent: (props) => {
          return (
            <div>
              {props.payPeriodStart} - {props.payPeriodEnd}
            </div>
          );
        },
      },
    ];
    return columns;
  };

  _handleCompanyIdChange = (e) => {
    this.setState(
      {
        filters: { ...this.state.filters, companyId: e.target.value },
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedMissedPayrolls()
    );
  };

  _handleDateChange = (dateValue) => {
    const date = isEmpty(dateValue) ? moment() : moment(dateValue);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          date: date.utc().format("YYYY-MM-DD"),
        },
        offset: 0,
        page: 0,
      },
      () => this._getPaginatedMissedPayrolls()
    );
  };

  _buildMetadataTable = () => {
    const metadata = transform(
      this.state.metadata,
      (acc, val, key) => {
        const labelObject = {
          key,
          label: upperCase(key),
        };
        acc.push(labelObject);
        return acc;
      },
      []
    );
    return <InfoTable metadata={metadata} data={this.state.metadata} />;
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    if (this.props.error) {
      return <Alert type={"error"} msg={this.props.error} />;
    }

    const columns = this._buildPayrollColumns();
    const data = this.props.missedPayrolls.results;
    const showBlankSlate = isEmpty(data);

    return (
      <div className="company-payrolls">
        <Row>
          {isEmpty(this.props.companyId) && (
            <Col md={4}>
              <div
                style={{ paddingLeft: 15, paddingBottom: 15 }}
                className="company-id-filter"
              >
                <Form.Label>Company Id</Form.Label>
                <Form.Control
                  as="input"
                  name="companyIdFilter"
                  value={this.state.filters.companyId}
                  onChange={this._handleCompanyIdChange}
                />
              </div>
            </Col>
          )}
          <Col md={4}>
            <div
              style={{ paddingLeft: 15, paddingBottom: 15 }}
              className="date-filter"
            >
              <Form.Label>Schedule End Date</Form.Label>
              <DatePicker
                className="form-control"
                name="anchorPayDate"
                value={moment(this.state.filters.date).toDate()}
                dateFormat="MM/dd/yyyy"
                selected={moment(this.state.filters.date).toDate()}
                onChange={(val) => {
                  this._handleDateChange(val);
                }}
              />
            </div>
          </Col>
        </Row>

        {showBlankSlate && (
          <div>
            <IconEmptyState
              header={"No Missing Payrolls"}
              subheader="There are currently no missing pay periods to display."
              icon={<FiDollarSign color="white" stroke="#60A4BF" size={16} />}
            />
          </div>
        )}
        {this.props.isFetching && <IconSpinner centered />}
        {!showBlankSlate && (
          <>
            {this.state.showMetadataModal && (
              <SimpleModal
                show={true}
                size={"lg"}
                onClose={() =>
                  this.setState({ showMetadataModal: false, metadata: {} })
                }
                title="Payroll Metadata"
                buttonText="close"
              >
                {this._buildMetadataTable()}
              </SimpleModal>
            )}

            <div className="action-box">
              <IconTableHeader
                tableHeader="Payrolls"
                tableCount={this.props.missedPayrolls.total}
              />
              <IconTable columns={columns} data={data} />
              <Paginator
                onChange={this._onPageChange}
                pageCount={this.props.missedPayrolls.total / this.state.limit}
                page={this.state.page}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

const isLoadingSelector = createLoadingSelector(
  adminConstants.GET_UNPROCESSED_PAYROLLS
);
const errorSelector = createErrorSelector(
  adminConstants.GET_UNPROCESSED_PAYROLLS
);

const mapStateToProps = (state) => {
  return {
    isFetching: isLoadingSelector(state),
    missedPayrolls: state.admin.missedPayrolls,
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  getPaginatedMissedPayrolls,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(Payrolls));
