import gql from "graphql-tag";

// link bank with Icon
function linkBank(client, token, metadata) {
  return new Promise((resolve, reject) => {
    const LINK_BANK = gql`
      mutation linkBank($public_token: String!, $metadata: MetaData!) {
        linkBank(public_token: $public_token, metadata: $metadata) {
          status
          data
          userBank {
            id
            bankName
            bankAlias
            verificationStatus
            accountType
            accountSubtype
            accountId
            correlationId
            state
          }
        }
      }
    `;
    const result = client.mutate({
      mutation: LINK_BANK,
      variables: {
        public_token: token,
        metadata,
      },
    });
    result.then(
      (response) => {
        console.log("Plaid Link Submit");
        console.log(response);
        if (response.data) {
          resolve(response.data.linkBank);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

// link bank with Icon
function linkBankIndividual(client, token, metadata, ownerName) {
  return new Promise((resolve, reject) => {
    const LINK_BANK = gql`
      mutation linkBankIndividual(
        $public_token: String!
        $metadata: MetaData!
        $ownerName: String!
      ) {
        linkBankIndividual(
          public_token: $public_token
          metadata: $metadata
          ownerName: $ownerName
        ) {
          status
          data
          userBank {
            id
            bankName
            bankAlias
            verificationStatus
            accountType
            accountSubtype
            accountId
            correlationId
            state
          }
        }
      }
    `;
    console.log(metadata);
    const result = client.mutate({
      mutation: LINK_BANK,
      variables: {
        public_token: token,
        metadata,
        ownerName,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.linkBankIndividual);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function deleteBankIndividual(client, accountId) {
  return new Promise((resolve, reject) => {
    const DELETE_BANK = gql`
      mutation deleteBankIndividual($accountId: ID!) {
        deleteBankIndividual(accountId: $accountId)
      }
    `;
    const result = client.mutate({
      mutation: DELETE_BANK,
      variables: {
        accountId,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function deleteBankEmployer(client, accountId) {
  return new Promise((resolve, reject) => {
    const DELETE_BANK = gql`
      mutation deleteBankEmployer($accountId: ID!) {
        deleteBankEmployer(accountId: $accountId)
      }
    `;
    const result = client.mutate({
      mutation: DELETE_BANK,
      variables: {
        accountId,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function getPlaidLinkToken(client, accountId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getPlaidLinkToken($accountId: String) {
        getPlaidLinkToken(accountId: $accountId) {
          linkToken
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { accountId },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.getPlaidLinkToken);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function verifyBankAccountRequest(client, accountId) {
  return new Promise((resolve, reject) => {
    const MUTATION = gql`
      mutation verifyBankAccount($accountId: ID!) {
        verifyBankAccount(accountId: $accountId) {
          id
          bankName
          bankAlias
          verificationStatus
          accountType
          accountSubtype
          accountId
          correlationId
          state
        }
      }
    `;
    const result = client.mutate({
      mutation: MUTATION,
      variables: {
        accountId,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.verifyBankAccount);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getReauthorizationLinkToken(client, accountId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getReauthorizationLinkToken($accountId: String!) {
        getReauthorizationLinkToken(accountId: $accountId) {
          linkToken
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { accountId },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.getReauthorizationLinkToken);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getExpiringEmployerBanks(client) {
  return new Promise((resolve, reject) => {
    const GET_EXPIRING_EMPLOYER_BANKS = gql`
      query getExpiringEmployerBanks {
        getExpiringEmployerBanks {
          iconAccountId
          bankAccountType
          expirationDate
          bankAccountId
          bankCorrelationId
          entityIdentifier {
            id
            type
          }
        }
      }
    `;
    const result = client.query({
      query: GET_EXPIRING_EMPLOYER_BANKS,
      variables: {},
      fetchPolicy: "network-only",
    });
    result.then(
      (data) => {
        resolve(data.data.getExpiringEmployerBanks);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function updateLinkToken(client, accountId) {
  return new Promise((resolve, reject) => {
    const UPDATE_LINK_TOKEN = gql`
      mutation updateLinkToken($accountId: ID!) {
        updateLinkToken(accountId: $accountId)
      }
    `;
    const result = client.mutate({
      mutation: UPDATE_LINK_TOKEN,
      variables: {
        accountId,
      },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          reject(response);
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
}

function getIndividualReauthorizationLinkToken(client, accountId) {
  return new Promise((resolve, reject) => {
    const REQUEST = gql`
      query getIndividualReauthorizationLinkToken($accountId: String!) {
        getIndividualReauthorizationLinkToken(accountId: $accountId) {
          linkToken
        }
      }
    `;
    const result = client.query({
      query: REQUEST,
      variables: { accountId },
    });
    result.then(
      (response) => {
        if (response.data) {
          resolve(response.data.getIndividualReauthorizationLinkToken);
        } else {
          reject(response);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function getExpiringIndividualBanks(client) {
  return new Promise((resolve, reject) => {
    const GET_EXPIRING_INDIVIDUAL_BANKS = gql`
      query getExpiringIndividualBanks {
        getExpiringIndividualBanks {
          iconAccountId
          bankAccountType
          expirationDate
          bankAccountId
          bankCorrelationId
          entityIdentifier {
            id
            type
          }
        }
      }
    `;
    const result = client.query({
      query: GET_EXPIRING_INDIVIDUAL_BANKS,
      variables: {},
      fetchPolicy: "network-only",
    });
    result.then(
      (data) => {
        resolve(data.data.getExpiringIndividualBanks);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export default {
  linkBank,
  linkBankIndividual,
  deleteBankIndividual,
  deleteBankEmployer,
  getPlaidLinkToken,
  verifyBankAccountRequest,
  getReauthorizationLinkToken,
  getExpiringEmployerBanks,
  updateLinkToken,
  getIndividualReauthorizationLinkToken,
  getExpiringIndividualBanks,
};
