import React from "react";
import { Card } from "react-bootstrap";
import { FiAlertTriangle } from "react-icons/fi";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";

const ExpiredQuoteCard = () => {
  return (
    <Card
      className={"expired-quote-card"}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="icon-container"
        style={{
          marginTop: 12,
        }}
      >
        <FiAlertTriangle size={32} color={"#f2a766"} />
      </div>
      <Card.Body style={{ textAlign: "center", paddingTop: 8 }}>
        <IconHeader variant="labelHeader" headerText={"Expired Quote"} />
        <IconSubheader>
          This price quote is no longer valid. Please contact your Icon Sales
          Representative for a new one, or you can email
          <a className={"link"} href="mailto:sales@iconsavingsplan.com">
            sales@iconsavingsplan.com
          </a>
          .
        </IconSubheader>
      </Card.Body>

      {/*<Card.Title>Your sales quote has expired</Card.Title>*/}
      {/*<Card.Body*/}
      {/*  style={{*/}
      {/*    paddingTop: 0,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  This price quote is no longer valid. Please contact your Icon Sales*/}
      {/*  Representative for a new one, or you can email*/}
      {/*  <a className={"link"} href="mailto:sales@iconsavingsplan.com">*/}
      {/*    sales@iconsavingsplan.com*/}
      {/*  </a>*/}
      {/*  .*/}
      {/*</Card.Body>*/}
    </Card>
  );
};

export default ExpiredQuoteCard;
