import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import * as Sentry from "@sentry/react";
import classnames from "classnames";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { each, find, isEmpty, isNil, map, omit, slice, some } from "lodash";
import { EMPLOYEES_UPLOAD_DOCUMENT } from "statics/docTypes";
import { FiFolderPlus } from "react-icons/fi";

import { fileService } from "services/uploaderService";
import { persistDocument } from "actions/userActions";

import Button from "components/Button";
import Alert from "components/Alert";
import IconSpinner from "components/IconSpinner";

import "./FileUploader.scss";

class FileUploader extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    metadata: PropTypes.object,
    persistDocument: PropTypes.func,
    onSuccess: PropTypes.func,
    fileLimit: PropTypes.number,
    docType: PropTypes.string,
    subDocType: PropTypes.string,
    persist: PropTypes.bool,
    requireSubDocType: PropTypes.bool,
    hideUploadedFiles: PropTypes.bool,
    fileUploadedMsg: PropTypes.string,
  };

  static defaultProps = {
    fileLimit: 1,
    docType: "DOCUMENT",
    persist: false,
    requireSubDocType: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      uploadedFiles: [],
      uploadProgress: {},
      complete: false,
      errors: {},
    };
  }

  async getSignedUrl(files) {
    const filesToUpload = slice(files, 0, this.props.fileLimit);
    // get max number of files
    // upload files
    //
    each(filesToUpload, (file) => {
      if (this.validateSize(file)) {
        if (!this.duplicateFile(file)) {
          fileService
            .getUploadUrl(this.props.client, file.name, file.type)
            .then(
              (data) => {
                var newFile = {
                  fileName: file.name,
                  fileUrl: data.url,
                  file,
                  signedRequest: data.signedRequest,
                };

                const newProgress = { ...this.state.uploadProgress };
                newProgress[file.name] = {
                  status: "Pending",
                  percent: 1,
                };

                this.setState({
                  uploadedFiles: this.state.uploadedFiles.concat(newFile),
                  uploadProgress: newProgress,
                  errors: {
                    ...this.state.errors,
                    [file.name]: null,
                  },
                });

                fileService.uploadFile(newFile, this);
              },
              () => {
                // Cleanup here isn't needed, file and objects were never created
                this.setState({
                  errors: {
                    ...this.state.errors,
                    [file.name]: "Upload failed.",
                  },
                });
              }
            );
        } else {
          this.setState({
            errors: {
              ...this.state.errors,
              [file.name]: "Duplicate file.",
            },
          });
        }
      } else {
        this.setState({
          errors: {
            ...this.state.errors,
            [file.name]: "File size cannot exceed 12mb.",
          },
        });
      }
    });
  }

  async storeUploadedDocumentData(file) {
    const theFile = this.getFileFromState(file);

    if (this.props.persist) {
      const docResponse = fileService.uploadDocument(
        this.props.client,
        theFile.fileUrl,
        theFile.fileName,
        this.props.docType,
        theFile.file.type
      );
      if (!docResponse) {
        this.errorCleanup("Upload submission failed", file);
      }
    }
    if (this.props.fileLimit <= this.state.uploadedFiles.length) {
      // We're done, set state and update forms
      this.setState({ complete: true });
    }

    // Call Action/Reducer to add this to global state
    this.props.persistDocument({
      file: theFile,
      docType: this.props.docType,
      subDocType: this.props.subDocType,
      metadata: this.props.metadata,
    });
    if (this.props.onSuccess) {
      this.props.onSuccess();
    }
  }

  onFinish = function (file) {
    this.storeUploadedDocumentData(file);
  };

  onProgress = function (percent, status, file) {
    const copy = { ...this.state.uploadProgress };
    copy[file.name] = {
      status,
      percent,
    };
    this.setState({ uploadProgress: copy });
  };

  onError = function (status, file) {
    this.errorCleanup(status, file);
  };

  errorCleanup = function (message, file) {
    // Cleanup here involves removing the uploadedFiles object, and uploadProgress object
    const copy = { ...this.state.uploadProgress };
    let newCopy = omit(copy, file.name);
    Sentry.captureMessage(
      `Document upload failed: ${message}, for file ${file.name}`,
      { level: "warning" }
    );
    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter(
        (storedFile) => storedFile.file !== file
      ),
      uploadProgress: newCopy,
      errors: {
        ...this.state.errors,
        [file.name]: message,
      },
    });
  };

  validateSize = function (file) {
    //12 000 000 == 12MB
    if (file.size <= 12000000) {
      return true;
    }
    return false;
  };

  duplicateFile = function (file) {
    if (some(this.state.uploadedFiles, { fileName: file.name })) {
      return true;
    }
    return false;
  };

  getFileFromState(file) {
    return find(this.state.uploadedFiles, { file });
  }

  verificationCheckMark = () => {
    return (
      <svg
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          className="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    );
  };

  getProcessingFile = () => {
    const uploadedFile = this.state.uploadedFiles.length > 0;

    if (!uploadedFile) {
      return (
        <div className="noUploads">
          <div className="fileupload-wrapper">
            <div className="upload-circle">
              <FiFolderPlus
                fill={"# f2f4f8"}
                color={"#f2f4f8"}
                stroke={"#7F97B7"}
                fontSize={18}
                strokeWidth={"1.5"}
              />
            </div>
          </div>
          <span className="files">
            <p className="drag-drop">
              Drag & Drop {this.props.fileLimit === 1 ? "File" : "Files"},
            </p>
            <p className="or">or</p>
          </span>
        </div>
      );
    } else if (uploadedFile) {
      return (
        <div className="complete">
          <span className="check">
            <span>{this.verificationCheckMark()}</span>
          </span>
          <span>
            <p className="file-header">Your file was uploaded! </p>
            <p className="subtext">{this.props.fileUploadedMsg}</p>
          </span>
        </div>
      );
    }
    return <div></div>;
  };

  renderFile(fileName) {
    return (
      <div className="file-type">
        <img className="png" src="/assets/file.png"></img>
        <div className="file-name">{fileName}</div>
      </div>
    );
  }

  renderProgress(progress) {
    const displayStatus = progress.status != "Complete";

    return progress ? (
      <div className="file-progress">
        <span className="status">
          <div className="status-box">
            {progress.percent}% - {progress.status}
          </div>
          {displayStatus && <IconSpinner size="sm" />}
        </span>
      </div>
    ) : null;
  }

  render() {
    const uploadedFile = this.state.uploadedFiles.length > 0;
    const hasNotUploadedAllFiles =
      this.props.fileLimit > this.state.uploadedFiles.length;
    const hasNoSubType =
      this.props.requireSubDocType &&
      (isNil(this.props.subDocType) || isEmpty(this.props.subDocType));
    const csvContentType = "text/csv";
    const excelContentTypes =
      "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const acceptedFileTypes =
      this.props.docType === EMPLOYEES_UPLOAD_DOCUMENT
        ? { [csvContentType]: [], [excelContentTypes]: [] }
        : { "image/jpeg": [], "image/png": [], "application/pdf": [] };

    const filesUploaded = this.state.uploadedFiles.length;

    const showFileUploadedText = filesUploaded !== 0;

    let btnLabel;
    if (hasNotUploadedAllFiles && filesUploaded !== 0) {
      btnLabel = "Add another file";
    } else if (hasNotUploadedAllFiles) {
      btnLabel = "Browse Files";
    }

    return (
      <div className="file-drop-zone">
        <Dropzone
          noKeyboard={true}
          accept={acceptedFileTypes}
          onDrop={(acceptedFiles) => this.getSignedUrl(acceptedFiles)}
          noClick={!hasNotUploadedAllFiles}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="container">
              <div
                {...getRootProps()}
                className={classnames("file-uploader", {
                  ["complete-file-upload"]: uploadedFile,
                })}
              >
                <div> {this.getProcessingFile()}</div>
                <input data-testid="file-input" {...getInputProps()} />

                {hasNotUploadedAllFiles &&
                  this.state.uploadedFiles.length > 0 && <br />}
                {hasNotUploadedAllFiles && (
                  <Button
                    type="button"
                    name="submit"
                    size="sm"
                    withArrow={false}
                    disabled={hasNoSubType}
                  >
                    {btnLabel}
                  </Button>
                )}
              </div>
            </div>
          )}
        </Dropzone>
        <div>
          {!this.props.hideUploadedFiles && showFileUploadedText && (
            <p className="uploaded">Uploaded Files</p>
          )}
          <span>
            {!this.props.hideUploadedFiles &&
            this.state.uploadedFiles.length > 0 ? (
              <div className="uploaded-files">
                <div className="file-box">
                  {this.state.uploadedFiles.map((uploadedFile, index) => {
                    return (
                      <span className="file" key={index}>
                        <span>{this.renderFile(uploadedFile.fileName)}</span>
                        <span>
                          {this.renderProgress(
                            this.state.uploadProgress[uploadedFile.fileName]
                          )}
                        </span>
                      </span>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </span>
          <span>
            {map(this.state.errors, (err, fileName) => {
              if (!err) return null;
              const msg = `Error uploading ${fileName} - ${err}`;
              return (
                <div>
                  <p className="uploaded">Uploaded Files</p>
                  <Alert type="error" msg={msg} />
                </div>
              );
            })}
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  persistDocument,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(FileUploader));
