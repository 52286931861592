import React from "react";
import PropTypes from "prop-types";
import StepWizard from "react-step-wizard";

import { connect } from "react-redux";
import { filter, find, get } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";
import { hasAutoApprovalLinkRequestsFeature } from "store/selectors/employer";
import {
  getAllEmployerGroups,
  getLinkedEmployees,
  getPendingLinkedEmployees,
} from "actions/employerActions";

import IconSpinner from "components/IconSpinner";
import StepInfo from "components/StepInfo";
import ReviewEmployeeLinkRequest from "./ReviewEmployeeLinkRequest";
import ApproveEmployeeLinkRequest from "./ApproveEmployeeLinkRequest";

const stepToLabels = {
  1: {
    subheader: "Here is a list of all employees requiring a review.",
  },
  2: {
    subheader:
      "Outlined below is a summary encompassing all current approvals, rejections, and existing employees associated with this plan.",
  },
};

const steps = [
  {
    index: 1,
    label: "Review Existing",
  },
  {
    index: 2,
    label: "Approve Changes",
  },
];

class EmployeeLinkRequestWorkflow extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    getAllEmployerGroups: PropTypes.func,
    getPendingLinkedEmployees: PropTypes.func,
    getLinkedEmployees: PropTypes.func,
    getUsersForGroup: PropTypes.func,
    isFetching: PropTypes.bool,
    groupId: PropTypes.string,
    employerLinkRequests: PropTypes.array,

    client: PropTypes.shape({}),
    linkedEmployees: PropTypes.array,
    group: PropTypes.object,
  };
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      initialFetching: true,
      rejectedPlans: {},
      approvedPlans: {},
      activeStep: "1",
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.props.getPendingLinkedEmployees(this.props.client),
      this.props.getLinkedEmployees(this.props.client),
      this.props.getAllEmployerGroups(this.props.client),
    ]);
    this.setState({ initialFetching: false });
  }

  _onStepChange = ({ activeStep }) => {
    this.setState({ activeStep });
  };

  _setApproved = (approvedIds) => {
    this.setState({ approvedPlans: approvedIds });
  };

  _setRejected = (rejectedIds) => {
    this.setState({ rejectedPlans: rejectedIds });
  };

  render() {
    const activeSectionLabels = stepToLabels[this.state.activeStep];

    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    return (
      <>
        <p className="steps-header">{activeSectionLabels.subheader}</p>
        <StepInfo steps={steps} activeStepNumber={this.state.activeStep} />
        <StepWizard
          onStepChange={this._onStepChange}
          isLazyMount={true}
          transitions={{
            enterRight: "",
            enterLeft: "",
            exitRight: "",
            exitLeft: "",
          }}
        >
          <ReviewEmployeeLinkRequest
            group={this.props.group}
            stepName="existing"
            pendingLinkRequests={this.props.employerLinkRequests}
            rejectedPlans={this.state.rejectedPlans}
            approvedPlans={this.state.approvedPlans}
            setApproved={this._setApproved}
            setRejected={this._setRejected}
          />
          <ApproveEmployeeLinkRequest
            group={this.props.group}
            stepName="approvals"
            planType={this.props.group.planType}
            linkedEmployees={this.props.linkedEmployees}
            pendingLinkRequests={this.props.employerLinkRequests}
            rejectedPlans={this.state.rejectedPlans}
            approvedPlans={this.state.approvedPlans}
            groupId={this.props.groupId}
          />
        </StepWizard>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const groupId = get(ownProps.match, "params.id");

  const linkedEmployees = filter(state.employer.linkedEmployees, (employee) => {
    const employeeGroup = get(employee, "groupWithSchedule.group.id", "");
    return employeeGroup === groupId;
  });

  const hasAutoApprovalFeature = hasAutoApprovalLinkRequestsFeature(state);

  const pendingLinks = hasAutoApprovalFeature
    ? []
    : filter(state.employer.employerLinkRequests, (request) => {
        const requestGroupId = get(request, "group.id", "");
        return (
          request.status === "REQUEST_ACCESS" && requestGroupId === groupId
        );
      });

  const group = find(state.employer.groups, { id: groupId });

  return {
    planId: state.employer.company.planId,
    employerLinkRequests: pendingLinks,
    linkedEmployees,
    group,
    groupId,
  };
};

const mapDispatchToProps = {
  push,
  getAllEmployerGroups,
  getPendingLinkedEmployees,
  getLinkedEmployees,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployeeLinkRequestWorkflow));
