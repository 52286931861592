import React from "react";
import PropTypes from "prop-types";
import { isEmpty, filter } from "lodash";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Col, Row, Card } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { getIraAccountIdSelector } from "store/selectors/user";
import { createLoadingSelector, createErrorSelector } from "store/selectors";
import { addContributionSource } from "actions/contributionActions";
import { makeIraWithdrawal } from "actions/withdrawalActions";
import { accountTypesToEnglishMapping } from "statics/accountTypes";
import {
  contributionConstants,
  withdrawalConstants,
  transactionConstants,
  accountConstants,
} from "actions/types";

import Button from "components/Button";
import Alert from "components/Alert";
import InfoTable from "components/InfoTable";

const accountType = (accountType) => {
  return accountTypesToEnglishMapping[accountType] || accountType;
};

const commonProperties = [
  {
    key: "fromAccount.bankAlias",
    label: "FROM",
  },
  {
    key: "toAccount.accountType",
    label: "TO",
    format: accountType,
  },
  {
    key: "amount",
    format: "currency",
    label: "AMOUNT",
  },
];

const contributionTableProperties = [
  ...commonProperties,
  {
    key: "transferDate",
    label: "TRANSFER DATE",
  },
  {
    key: "frequency",
    label: "FREQUENCY",
  },
  {
    key: "taxYear",
    label: "TAX YEAR",
  },
];

const iraWithdrawTableProperties = [
  ...commonProperties,
  {
    key: "isFullDisbursement",
    format: "boolean",
    label: "FULL DISBURSEMENT",
  },
];

class ConfirmTransfer extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isWithdrawal: PropTypes.bool,
    addContributionSource: PropTypes.func.isRequired,
    makeIraWithdrawal: PropTypes.func.isRequired,
    show: PropTypes.bool,
    isFetching: PropTypes.bool,
    values: PropTypes.object,
    bankId: PropTypes.string,
    iraAccountId: PropTypes.string,
    error: PropTypes.string,
    client: PropTypes.object,
    onSuccess: PropTypes.func,
    isApplyToPriorYear: PropTypes.bool,
  };
  constructor() {
    super();

    this.state = {
      submittedContribution: false,
    };
  }

  _onSubmit = () => {
    if (!this.props.isWithdrawal) {
      const requestObj = {
        amount: +this.props.values.amount,
        iraAccountId: this.props.iraAccountId,
        applyToPriorYear: this.props.isApplyToPriorYear,
        bankId: this.props.bankId,
      };

      this.props
        .addContributionSource(this.props.client, requestObj)
        .then(() => {
          this.setState({ submittedContribution: true });
        });
    } else {
      // user is making a withdrawal request
      let { isFullDisbursement, amount, reason } = this.props.values;
      amount = isFullDisbursement ? 0 : +amount;

      const requestObj = {
        amount,
        isFullDisbursement,
        reason,
        iraAccountId: this.props.iraAccountId,
      };
      this.props.makeIraWithdrawal(this.props.client, requestObj).then(() => {
        this.setState({ submittedContribution: true });
      });
    }
  };

  _successContent = () => {
    return (
      <>
        <Row>
          <Col>
            <p>Your transfer was submitted successfully.</p>
          </Col>
        </Row>
        <section className="form-sec-2col">
          <div className="submit-row">
            <Button
              name="cancel"
              btnLabel="Close"
              onClick={() => {
                this.props.onSuccess();
              }}
            />
          </div>
        </section>
      </>
    );
  };

  _infoContent = () => {
    let tablePropsToUse = this.props.isWithdrawal
      ? iraWithdrawTableProperties
      : contributionTableProperties;

    if (this.props.isWithdrawal && this.props.values.isFullDisbursement) {
      tablePropsToUse = filter(
        tablePropsToUse,
        (prop) => prop.key !== "amount"
      );
    }

    return (
      <>
        <Row>
          <Col>
            <InfoTable metadata={tablePropsToUse} data={this.props.values} />
          </Col>
        </Row>
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        <section className="form-sec-2col">
          <div className="submit-row">
            <Button
              btnLabel="Cancel"
              color="cancel"
              name="cancel"
              onClick={this.props.onClose}
            />
            <Button
              withArrow
              name="submit"
              btnLabel="Transfer"
              onClick={this._onSubmit}
              loading={this.props.isFetching}
            />
          </div>
        </section>
      </>
    );
  };

  render() {
    const successfullyContributed =
      this.state.submittedContribution && isEmpty(this.props.error);
    const content = successfullyContributed
      ? this._successContent()
      : this._infoContent();
    const title = successfullyContributed
      ? "Transfer Initiated"
      : "Verify Transfer";

    return (
      <Card className="transfer-card">
        <Card.Body>
          <Card.Title>
            {title}
            {successfullyContributed && (
              <div className="success-icon">
                <FaCheckCircle size={18} color="#4BB543" />
              </div>
            )}
          </Card.Title>
          {content}
        </Card.Body>
      </Card>
    );
  }
}

const actions = [
  contributionConstants.CONTRIBUTION,
  contributionConstants.GET_CONTRIBUTION_ABILITY,
  accountConstants.GET_IRA_BALANCE,
  transactionConstants.GET_TRANSACTIONS,
  withdrawalConstants.WITHDRAWAL,
];

const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => ({
  isFetching: loadingSelector(state),
  error: errorSelector(state),
  iraAccountId: getIraAccountIdSelector(state),
});

const mapDispatchToProps = {
  addContributionSource,
  makeIraWithdrawal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ConfirmTransfer));
