/* eslint-disable react/no-unescaped-entities */

import React from "react";

class ApexTerms extends React.Component {
  _content = () => {
    return (
      <>
        <div className="terms-of-service">
          <div>
            <p className="terms-label">Depositor Authorization</p>
            <p>
              I understand that I have the right to direct the investment and
              reinvestment of contributions to my account and hereby appoint the
              following brokerage firm as my agent to execute my directions, as
              Broker under the terms of Custodial Agreement. Brokerage Firm:
              Apex Clearing
            </p>
          </div>

          <div>
            <p className="terms-label">
              Individual Retirement Custodial Account Agreement
            </p>
            <p>
              <p>
                Form 5305-A under section 408(a) of the Internal Revenue Code.
                FORM (Rev. April 2017) The Depositor named on the application is
                establishing a Traditional individual retirement account under
                section 408(a) to provide for his or her retirement and for the
                support of his or her beneficiaries after death. The custodian
                named on the application has given the Depositor the disclosure
                statement required by Regulations section 1.408-6. The Depositor
                has assigned the custodial account the sum indicated on the
                application. The Depositor and the custodian make the following
                agreement:
              </p>
              <ul className="listNone">
                <li>
                  <p className="terms-label">ARTICLE I </p>
                  <p>
                    Except in the case of a rollover contribution described in
                    section 402(c), 403(a)(4), 403(b)(8), 408(d)(3), or
                    457(e)(16), an employer contribution to a simplified
                    employee pension plan as described in section 408(k), or a
                    recharacterized contribution described in section
                    408A(d)(6), the custodian will accept only cash
                    contributions up to $5500 per year for tax years 2013
                    through 2017. For individuals who have reached the age of 50
                    by the end of the year, the contribution limit is increased
                    to $6500 per year for tax years 2013 through 2017. For years
                    after 2017, these limits will be increased to reflect a
                    cost-of-living adjustment, if any.
                  </p>
                </li>
                <li>
                  <p className="terms-label">ARTICLE II</p>
                  <p>
                    The Depositor’s interest in the balance in the custodial
                    account is nonforfeitable.
                  </p>
                </li>
                <li>
                  <p className="terms-label">ARTICLE III</p>
                  <p>
                    <ol type="1">
                      <li>
                        No part of the custodial account funds may be invested
                        in life insurance contracts, nor may the assets of the
                        custodial account be commingled with other property
                        except in a common trust fund or common investment fund
                        (within the meaning of section 408(a)(5))
                      </li>
                      <li>
                        No part of the custodial account funds may be invested
                        in collectibles (within the meaning of section 408(m))
                        except as otherwise permitted by section 408(m)(3),
                        which provides an exception for certain gold, silver,
                        and platinum coins, coins issued under the laws of any
                        state, and certain bullion.
                      </li>
                    </ol>
                  </p>
                </li>
                <li>
                  <p className="terms-label">ARTICLE IV</p>
                  <p>
                    <ol type="1">
                      <li>
                        Notwithstanding any provision of this agreement to the
                        contrary, the distribution of the Depositor’s interest
                        in the custodial account shall be made in accordance
                        with the following requirements and shall otherwise
                        comply with section 408(a)(6) and the regulations
                        thereunder, the provisions of which are herein
                        incorporated by reference.
                      </li>

                      <li>
                        The Depositor’s entire interest in the custodial account
                        must be, or begin to be, distributed not later than the
                        Depositor’s required beginning date, April 1 following
                        the calendar year in which the Depositor reaches age
                        70½. By that date, the Depositor may elect, in a manner
                        acceptable to the custodian, to have the balance in the
                        custodial account distributed in: (a) A single sum or
                        (b) Payments over a period not longer than the life of
                        the Depositor or the joint lives of the Depositor and
                        his or her designated beneficiary.
                      </li>

                      <li>
                        <p>
                          If the Depositor dies before his or her entire
                          interest is distributed to him or her, the remaining
                          interest will be distributed as follows:
                        </p>

                        <ol type="a">
                          <li>
                            If the Depositor dies on or after the required
                            beginning date and:
                            <ol type="i">
                              <li>
                                the designated beneficiary is the Depositor’s
                                surviving spouse, the remaining interest will be
                                distributed over the surviving spouse’s life
                                expectancy as determined each year until such
                                spouse’s death, or over the period in paragraph
                                (a)(iii) below if longer. Any interest remaining
                                after the spouse’s death will be distributed
                                over such spouse’s remaining life expectancy as
                                determined in the year of the spouse’s death and
                                reduced by one for each subsequent year, or, if
                                distributions are being made over the period in
                                paragraph (a)(iii) below, over such period.
                              </li>
                              <li>
                                the designated beneficiary is not the
                                Depositor’s surviving spouse, the remaining
                                interest will be distributed over the
                                beneficiary’s remaining life expectancy as
                                determined in the year following the death of
                                the Depositor and reduced by one for each
                                subsequent year, or over the period in paragraph
                                (a)(iii) below if longer
                              </li>
                              <li>
                                there is no designated beneficiary, the
                                remaining interest will be distributed over the
                                remaining life expectancy of the Depositor as
                                determined in the year of the Depositor’s death
                                and reduced by one for each subsequent year.
                              </li>
                            </ol>
                          </li>

                          <li>
                            <p>
                              If the Depositor dies before the required
                              beginning date, the remaining interest will be
                              distributed in accordance with paragraph (i) below
                              or, if elected or there is no designated
                              beneficiary, in accordance with paragraph (ii)
                              below.
                            </p>
                            <ol type="i">
                              <li>
                                The remaining interest will be distributed in
                                accordance with paragraphs (a)(i) and (a)(ii)
                                above (but not over the period in paragraph
                                (a)(iii), even if longer), starting by the end
                                of the calendar year following the year of the
                                Depositor’s death. If, however, the designated
                                beneficiary is the Depositor’s surviving spouse,
                                then this distribution is not required to begin
                                before the end of the calendar year in which the
                                Depositor would have reached age 70½. But, in
                                such case, if the Depositor’s surviving spouse
                                dies before distributions are required to begin,
                                then the remaining interest will be distributed
                                in accordance with paragraph (a)(ii) above (but
                                not over the period in paragraph (a)(iii), even
                                if longer), over such spouse’s designated
                                beneficiary’s life expectancy, or in accordance
                                with (ii) below if there is no such designated
                                beneficiary.
                              </li>
                              <li>
                                The remaining interest will be distributed by
                                the end of the calendar year containing the
                                fifth anniversary of the Depositor’s death
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </li>

                      <li>
                        If the Depositor dies before his or her entire interest
                        has been distributed and if the designated beneficiary
                        is not the Depositor’s surviving spouse, no additional
                        contributions may be accepted in the account.
                      </li>

                      <li>
                        <p>
                          The minimum amount that must be distributed each year,
                          beginning with the year containing the Depositor’s
                          required beginning date, is known as the “required
                          minimum distribution” and is determined as follows.
                        </p>

                        <ol type="a">
                          <li>
                            The required minimum distribution under paragraph
                            2(b) for any year, beginning with the year the
                            Depositor reaches age 70½, is the Depositor’s
                            account value at the close of business on December
                            31 of the preceding year divided by the distribution
                            period in the uniform lifetime table in Regulations
                            section 1.401(a)(9)-9. However, if the Depositor’s
                            designated beneficiary is his or her surviving
                            spouse, the required minimum distribution for a year
                            shall not be more than the Depositor’s account value
                            at the close of business on December 31 of the
                            preceding year divided by the number in the joint
                            and last survivor table in Regulations section
                            1.401(a)(9)-9. The required minimum distribution for
                            a year under this paragraph (a) is determined using
                            the Depositor’s (or, if applicable, the Depositor
                            and spouse’s) attained age (or ages) in the year.
                          </li>
                          <li>
                            The required minimum distribution under paragraphs
                            3(a) and 3(b)(i) for a year, beginning with the year
                            following the year of the Depositor’s death (or the
                            year the Depositor would have reached age 70½, if
                            applicable under paragraph 3(b)(i)) is the account
                            value at the close of business on December 31 of the
                            preceding year divided by the life expectancy (in
                            the single life table in Regulations section
                            1.401(a)(9)-9) of the individual specified in such
                            paragraphs 3(a) and 3(b)(i).
                          </li>
                          <li>
                            The required minimum distribution for the year the
                            Depositor reaches age 70½ can be made as late as
                            April 1 of the following year. The required minimum
                            distribution for any other year must be made by the
                            end of such year.
                          </li>
                        </ol>
                      </li>

                      <li>
                        The owner of two or more Traditional IRAs may satisfy
                        the minimum distribution requirements described above by
                        taking from one Traditional IRA the amount required to
                        satisfy the requirement for another in accordance with
                        the regulations under section 408(a)(6).
                      </li>
                    </ol>
                  </p>
                </li>

                <p className="terms-label">ARTICLE V</p>
                <li>
                  <ol type="1">
                    <li>
                      The Depositor agrees to provide the custodian with all
                      information necessary to prepare any reports required by
                      section 408(i) and Regulations sections 1.408-5 and
                      1.408-6.
                    </li>
                    <li>
                      The custodian agrees to submit to the Internal Revenue
                      Service (IRS) and Depositor the reports prescribed by the
                      IRS.
                    </li>
                  </ol>
                </li>

                <p className="terms-label">ARTICLE VI</p>
                <li>
                  <p>
                    Notwithstanding any other articles which may be added or
                    incorporated, the provisions of Articles I through III and
                    this sentence will be controlling. Any additional articles
                    inconsistent with section 408(a) and the related regulations
                    will be invalid.
                  </p>
                </li>

                <p className="terms-label">ARTICLE VII</p>
                <li>
                  <p>
                    This agreement will be amended as necessary to comply with
                    the provisions of the Code and the related regulations.
                    Other amendments may be made with the consent of the persons
                    whose signatures appear on the application.
                  </p>
                </li>

                <p className="terms-label">ARTICLE VIII</p>
                <li>
                  <ol type="1">
                    <li>
                      <b>Definitions</b> – In this part of this agreement
                      (Article VIII), the words “you” and “your” mean the
                      Depositor. The words “we,” “us,” and “our” mean the
                      custodian. The word “Code” means the Internal Revenue
                      Code, and “regulations” means the Treasury regulations.
                    </li>

                    <li>
                      <b> Notices and Change of Address</b> – Any required
                      notice regarding this IRA will be considered effective
                      when we send it to the intended recipient at the last
                      address that we have in our records. Any notice to be
                      given to us will be considered effective when we actually
                      receive it. You, or the intended recipient, must notify us
                      of any change of address.
                    </li>

                    <li>
                      <p>
                        <b> Representations and Responsibilities</b> – You
                        represent and warrant to us that any information you
                        have given or will give us with respect to this
                        agreement is complete and accurate. Further, you agree
                        that any directions you give us or action you take will
                        be proper under this agreement, and that we are entitled
                        to rely upon any such information or directions. If we
                        fail to receive directions from you regarding any
                        transaction, if we receive ambiguous directions
                        regarding any transaction, or if we, in good faith,
                        believe that any transaction requested is in dispute, we
                        reserve the right to take no action until further
                        clarification acceptable to us is received from you or
                        the appropriate government or judicial authority. We
                        will not be responsible for losses of any kind that may
                        result from your directions to us or your actions or
                        failures to act, and you agree to reimburse us for any
                        loss we may incur as a result of such directions,
                        actions, or failures to act. We will not be responsible
                        for any penalties, taxes, judgments, or expenses you
                        incur in connection with your IRA. We have no duty to
                        determine whether your contributions or distributions
                        comply with the Code, regulations, rulings, or this
                        agreement.
                      </p>
                      <p>
                        We may permit you to appoint, through written notice
                        acceptable to us, an authorized agent to act on your
                        behalf with respect to this agreement (e.g.,
                        attorney-in-fact, executor, administrator, investment
                        manager), but we have no duty to determine the validity
                        of such appointment or any instrument appointing such
                        authorized agent. We will not be responsible for losses
                        of any kind that may result from directions, actions, or
                        failures to act by your authorized agent, and you agree
                        to reimburse us for any loss we may incur as a result of
                        such directions, actions, or failures to act by your
                        authorized agent.
                      </p>
                      <p>
                        You will have 60 days after you receive any documents,
                        statements, or other information from us to notify us in
                        writing of any errors or inaccuracies reflected in these
                        documents, statements, or other information. If you do
                        not notify us within 60 days, the documents, statements,
                        or other information will be deemed correct and
                        accurate, and we will have no further liability or
                        obligation for such documents, statements, other
                        information, or the transactions described therein.
                      </p>
                      <p>
                        By performing services under this agreement we are
                        acting as your agent. You acknowledge and agree that
                        nothing in this agreement will be construed as
                        conferring fiduciary status upon us. We will not be
                        required to perform any additional services unless
                        specifically agreed to under the terms and conditions of
                        this agreement, or as required under the Code and the
                        regulations promulgated thereunder with respect to IRAs.
                        You agree to indemnify and hold us harmless for any and
                        all claims, actions, proceedings, damages, judgments,
                        liabilities, costs, and expenses, including attorney’s
                        fees arising from or in connection with this agreement.
                      </p>
                      <p>
                        To the extent written instructions or notices are
                        required under this agreement, we may accept or provide
                        such information in any other form permitted by the Code
                        or applicable regulations including, but not limited to,
                        electronic communication.
                      </p>
                    </li>

                    <li>
                      <b>Disclosure of Account Information</b> – We may use
                      agents and or subcontractors to assist in administering
                      your IRA. We may release non-public personal information
                      regarding your IRA to such providers as necessary to
                      provide the products and services made available under
                      this agreement, and to evaluate our business operations
                      and analyze potential product, service, or process
                      improvements.
                    </li>

                    <li>
                      <b>Service Fees</b> – We may use agents and or
                      subcontractors to assist in administering your IRA. We may
                      release non-public personal information regarding your IRA
                      to such providers as necessary to provide the products and
                      services made available under this agreement, and to
                      evaluate our business operations and analyze potential
                      product, service, or process improvements.
                    </li>

                    <li>
                      <p>
                        <b>Investment of Amounts in the IRA</b>– You have
                        exclusive responsibility for and control over the
                        investment of the assets of your IRA. All transactions
                        will be subject to any and all restrictions or
                        limitations, direct or indirect, that are imposed by our
                        charter, articles of incorporation, or bylaws; any and
                        all applicable federal and state laws and regulations;
                        the rules, regulations, customs and usages of any
                        exchange, market or clearing house where the transaction
                        is executed; our policies and practices; and this
                        agreement. After your death, your beneficiaries will
                        have the right to direct the investment of your IRA
                        assets, subject to the same conditions that applied to
                        you during your lifetime under this agreement
                        (including, without limitation, Section 8.03 of this
                        article). The right to direct investment of assets may
                        be restricted, however, as provided herein. We will have
                        no discretion to direct any investment in your IRA. We
                        assume no responsibility for rendering investment advice
                        with respect to your IRA, nor will we offer any opinion
                        or judgment to you on matters concerning the value or
                        suitability of any investment or proposed investment for
                        your IRA. In the absence of instructions from you, or if
                        your instructions are not in a form acceptable to us, we
                        will have the right to hold any uninvested amounts in
                        cash, and we will have no responsibility to invest
                        uninvested cash unless and until directed by you. We
                        will not exercise the voting rights and other
                        shareholder rights with respect to investments in your
                        IRA unless you provide timely written directions
                        acceptable to us.
                      </p>

                      <p>
                        You will select the investment for your IRA assets,
                        provided, however, that your selection of investments
                        shall be limited to any investment vehicle obtainable by
                        us, that we are authorized by our charter, articles of
                        incorporation, or bylaws to offer and do in fact, in our
                        sole discretion offer for IRAs For example, investments
                        may include but shall not be limited to common stocks,
                        government and corporate bonds, mutual funds, the
                        purchase of put options on existing positions and
                        writing of covered listed call options and such other
                        options strategies that we may, from time to time, in
                        our sole discretion make available for IRAs and which
                        strategies are approved for your account by your broker
                        and/or investment advisor. Investments not generating
                        confirmations must be accompanied by additional written
                        instructions and such other documentation as we may, in
                        our sole discretion, require. We shall act as a
                        stockbroker or dealer whenever such services are
                        required. We may in our sole discretion make available
                        to you additional investment offerings, which will be
                        limited to publicly traded securities, mutual funds,
                        money market instruments, and other investments that are
                        obtainable by us and that we, in our sole discretion,
                        determine that we are capable of holding in the ordinary
                        course of our business.
                      </p>
                      <p>
                        We shall have the power and authority in the
                        administration of this Agreement to do all acts,
                        including by way of illustration but not in limitation
                        of the powers conferred by law, the following:
                      </p>

                      <ol type="a">
                        <li>
                          Pursuant to your or your agent’s direction, to invest
                          and reinvest all or any part of the assets in
                          securities obtainable through us and to invest in any
                          lawful investment which is administratively acceptable
                          to us without any duty to diversify and without regard
                          to whether such property is authorized by the laws of
                          any jurisdiction for investment by us;
                        </li>
                        <li>
                          Pursuant to your or your agent’s direction, to hold
                          part or all of the uninvested assets or to place the
                          same in a savings account approved by you or purchase
                          a Certificate of Deposit with an institution approved
                          by you;
                        </li>
                        <li>
                          To employ suitable agents and counsel and to pay them
                          reasonable expenses and compensation;
                        </li>
                        <li>
                          Pursuant to your or your agent’s direction, to vote in
                          person or by proxy with respect to securities held by
                          us and to delegate our discretionary power;
                        </li>
                        <li>
                          Pursuant to your or your agent’s direction (and
                          subject to approval of a custodial account for option
                          trading privileges), to write covered listed call
                          options against existing positions, to liquidate or
                          close such option contracts, and to purchase put
                          options on existing long positions (the same
                          securities cannot be used to simultaneously cover more
                          than one position);
                        </li>
                        <li>
                          Pursuant to your or your agent’s direction, to consent
                          to or participate in dissolutions, reorganizations,
                          consolidations, mergers, sales, leases, mortgages, and
                          transfers or other changes affecting securities held
                          by us;
                        </li>
                        <li>
                          To leave any securities or cash for safekeeping or on
                          deposit, with or without interest, with such banks,
                          brokers and other custodians as we may select, and to
                          hold any securities in bearer form or in the name of
                          these banks, brokers and any other custodians or in
                          the name of the custodian without qualification or
                          description or in the name of any nominee; and
                        </li>
                        <li>
                          Prior to the entry of any orders to purchase or sell
                          securities in your account, you or your agent shall
                          approve beforehand all such orders and direct us to
                          implement such instructions. Selling short and
                          executing purchases in an amount greater than
                          available cash are prohibited. All investments outside
                          of the cash account shall be accompanied by additional
                          written instructions.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <p>
                        <b>Beneficiaries</b>– If you die before you receive all
                        of the amounts in your IRA, payments from your IRA will
                        be made to your beneficiary(ies). We have no obligation
                        to pay to your beneficiaries until such time we are
                        notified of your death by receiving a valid death
                        certificate.
                      </p>
                      <p>
                        You may designate one or more persons or entities as
                        beneficiary of your IRA. This designation can only be
                        made on a form provided by or acceptable to us, and it
                        will only be effective when it is filed with us during
                        your lifetime. Unless otherwise specified each
                        beneficiary designation you file with us will cancel all
                        previous designations. The consent of your
                        beneficiary(ies) will not be required for you to revoke
                        a beneficiary designation. If you have designated both
                        primary and contingent beneficiaries and no primary
                        beneficiary(ies) survives you, the contingent
                        beneficiary(ies) will acquire the designated share of
                        your IRA. If you do not designate a beneficiary or if
                        all of your primary and contingent beneficiaries
                        predecease you, your spouse will be the beneficiary, or
                        if there is no spouse living at the time of your death,
                        your estate will be the beneficiary.
                      </p>
                      <p>
                        A spouse beneficiary will have all rights as granted
                        under the Code or applicable regulations to treat your
                        IRA as his or her own.
                      </p>
                      <p>
                        If the beneficiary designated to receive payments
                        hereunder is a minor or person of unsound mind, whether
                        so formally adjudicated or not, we may, at our
                        discretion, make such payments to such person as may be
                        acting as parent, guardian, committee, conservator,
                        trustee or legal representative of such minor or
                        incompetent and the receipt by any such person as
                        selected by us shall be a full and complete discharge of
                        us for any sums so paid.
                      </p>
                      <p>
                        We reserve the right to, at our discretion, deposit
                        funds in a special savings account established in our
                        name as Custodian for a beneficiary when within six
                        months after any payment is due because we cannot
                        ascertain the whereabouts on our records, and such
                        beneficiary has not submitted a written claim for such
                        payment before the expiration of said six-month period.
                      </p>
                      <p>
                        We may allow, if permitted by state law, an original IRA
                        beneficiary(ies) (the beneficiary(ies) who is entitled
                        to receive distributions from an inherited IRA at the
                        time of your death) to name successor beneficiary(ies)
                        for the inherited IRA. This designation can only be made
                        on a form provided by or acceptable to us, and it will
                        only be effective when it is filed with us during the
                        original IRA beneficiary’s(ies’) lifetime. Unless it is
                        otherwise specified, each beneficiary designation form
                        that the original IRA beneficiary(ies) files with us
                        will cancel all previous ones. The consent of a
                        successor beneficiary(ies) will not be required for the
                        original IRA beneficiary(ies) to revoke a successor
                        beneficiary(ies) designation. If the original IRA
                        beneficiary(ies) does not designate a successor
                        beneficiary(ies), his or her estate will be the
                        successor beneficiary. In no event will the successor
                        beneficiary(ies) be able to extend the distribution
                        period beyond that required for the original IRA
                        beneficiary.
                      </p>
                      <p>
                        If we so choose, for any reason (e.g., due to
                        limitations of our charter or bylaws), we may require
                        that a beneficiary of a deceased IRA owner take total
                        distribution of all IRA assets by December 31 of the
                        year following the year of death.
                      </p>
                    </li>

                    <li>
                      <p>
                        <b>Required Minimum Distributions</b> – Your required
                        minimum distribution is calculated using the uniform
                        lifetime table in Regulations section 1.401(a)(9)-9.
                        However, if your spouse is your sole designated
                        beneficiary and is more than 10 years younger than you,
                        your required minimum distribution is calculated each
                        year using the joint and last survivor table in
                        Regulations section 1.401(a)(9)-9.
                      </p>
                      <p>
                        If you fail to request your required minimum
                        distribution by your required beginning date, we can, at
                        our complete and sole discretion, do any one of the
                        following.
                      </p>
                      <ol type="a">
                        <li>
                          Make no distribution until you give us a proper
                          withdrawal request
                        </li>
                        <li>
                          Distribute your entire IRA to you in a single sum
                          payment
                        </li>
                        <li>
                          Determine your required minimum distribution from your
                          IRA each year based on your life expectancy,
                          calculated using the uniform lifetime table in
                          Regulations section 1.401(a)(9)-9, and pay those
                          distributions to you until you direct otherwise
                        </li>
                      </ol>
                      <p>
                        We will not be liable for any penalties or taxes related
                        to your failure to take a required minimum distribution.
                      </p>
                    </li>

                    <li>
                      <p>
                        <b>Resignation or Removal of Custodian</b> – We may
                        resign as Custodian at any time upon 30 days written
                        notice to the Participant. Upon resignation, we may, but
                        shall not be required to, appoint a successor custodian
                        under this Agreement; provided that any successor
                        custodian shall satisfy the requirements of Code section
                        408(a)(2). Upon any such successor’s acceptance of
                        appointment, we shall transfer the assets of the
                        custodial account, together with copies of relevant
                        books and records, to such successor custodian;
                        provided, however, that we are authorized to reserve
                        such sum of money or property as we may deem advisable
                        for payment of any liabilities constituting a charge on
                        or against the assets of the custodial account, or on or
                        against us. We shall not be liable for the acts or
                        omissions of any successor custodian. If no successor
                        custodian is appointed by us, the custodial account
                        shall be terminated, and the assets of the Account,
                        reduced by the amount of any unpaid fees or expenses,
                        will be distributed to you.
                      </p>
                      <p>
                        If we are required to comply with Regulations section
                        1.408–2(e), and we fail to do so, or we are not keeping
                        the records, making the returns or sending the
                        statements as are required by forms or Regulations, the
                        IRS may, after notifying you, require you to substitute
                        another trustee or custodian.
                      </p>
                      <p>
                        We may establish a policy requiring distribution of the
                        entire balance of your IRA to you in cash or property if
                        the balance of your IRA drops below the minimum balance
                        required under the applicable investment or policy
                        established.
                      </p>
                    </li>

                    <li>
                      <p>
                        <b>Termination of Custodial Account</b> – You may
                        terminate this Agreement at any time upon notice to us
                        in a manner and form acceptable to us. Upon such
                        termination, we shall transfer the assets of the
                        custodial account, reduced by the amount of any unpaid
                        fees or expenses, to the custodian or trustee of another
                        individual retirement account (within the meaning of
                        Code section 408) or other retirement plan designated by
                        you. We shall not be liable for losses arising from the
                        acts, omissions, delays or other inaction of any such
                        transferee custodian or trustee. If we receive notice of
                        your intention to terminate the custodial account and
                        you have not designated a transferee custodian or
                        trustee for the assets in the custodial account, the
                        custodial account reduced by any unpaid fees or
                        expenses, will be distributed to you.
                      </p>
                    </li>

                    <li>
                      <b>Successor Custodian</b> – If our organization changes
                      its name, reorganizes, merges with another organization
                      (or comes under the control of any federal or state
                      agency), or if our entire organization (or any portion
                      that includes your IRA) is bought by another organization,
                      that organization (or agency) will automatically become
                      the trustee or custodian of your IRA, but only if it is
                      the type of organization authorized to serve as an IRA
                      trustee or custodian.
                    </li>

                    <li>
                      <b>Amendments and Termination of the Plan</b> – We have
                      the right to amend or terminate this agreement at any time
                      consistent with the provisions of applicable law without
                      obtaining your consent, or the consent of your spouse or
                      your beneficiary(ies). You will be deemed to have
                      consented to any other amendment unless, within 30 days
                      from the date we send the amendment, you notify us in
                      writing that you do not consent.
                    </li>

                    <li>
                      <b>Withdrawals or Transfers</b> – All requests for
                      withdrawal or transfer will be in writing on a form
                      provided by or acceptable to us. The method of
                      distribution must be specified in writing or in any other
                      method acceptable to us. The tax identification number of
                      the recipient must be provided to us before we are
                      obligated to make a distribution. Withdrawals will be
                      subject to all applicable tax and other laws and
                      regulations, including but not limited to possible early
                      distribution penalty taxes, surrender charges, and
                      withholding requirements.
                    </li>

                    <li>
                      <b>Transfers From Other Plans</b> – We can receive amounts
                      transferred to this IRA from the trustee or custodian of
                      another IRA. In addition, we can accept direct rollovers
                      of eligible rollover distributions from employer-sponsored
                      retirement plans as permitted by the Code. We reserve the
                      right not to accept any transfer or direct rollover.
                    </li>

                    <li>
                      <b>Liquidation of Assets</b> – We have the right to
                      liquidate assets in your IRA if necessary to make
                      distributions or to pay fees, expenses, taxes, penalties,
                      or surrender charges properly chargeable against your IRA.
                      If you fail to direct us as to which assets to liquidate,
                      we will decide, in our complete and sole discretion, and
                      you agree to not hold us liable for any adverse
                      consequences that result from our decision.
                    </li>

                    <li>
                      <p>
                        <b>Restrictions on the Fund</b> – Neither you nor any
                        beneficiary may sell, transfer, or pledge any interest
                        in your IRA in any manner whatsoever, except as provided
                        by law or this agreement.
                      </p>
                      <p>
                        The assets in your IRA will not be responsible for the
                        debts, contracts, or torts of any person entitled to
                        distributions under this agreement.
                      </p>
                    </li>

                    <li>
                      <p>
                        <b>What Law Applies</b> – This agreement is subject to
                        all applicable federal and state laws and regulations.
                        If it is necessary to apply any state law to interpret
                        and administer this agreement, the laws of the State of
                        Texas shall govern.
                      </p>

                      <p>
                        If any part of this agreement is held to be illegal or
                        invalid, the remaining parts will not be affected.
                        Neither your nor our failure to enforce at any time or
                        for any period of time any of the provisions of this
                        agreement will be construed as a waiver of such
                        provisions, or your right or our right thereafter to
                        enforce each and every such provision.
                      </p>
                    </li>

                    <li>
                      <p>
                        <b>Arbitration</b> – This agreement contains a
                        Predispute Arbitration Clause.
                      </p>
                      <p>
                        By Signing an Arbitration Agreement the Parties agree as
                        follows:
                      </p>
                      <ol type="a">
                        <li>
                          All parties to this agreement are giving up the right
                          to sue each other in court, including the right to a
                          trial by jury except as provided by the rules of the
                          Arbitration form in which a claim is filed;
                        </li>
                        <li>
                          Arbitration awards are generally final and binding; a
                          party’s ability to have a court reverse or modify an
                          arbitration award is very limited.
                        </li>
                        <li>
                          The liability of the parties to obtain documents,
                          witness statements and other discovery is generally
                          more limited in Arbitration than in court proceedings;
                        </li>
                        <li>
                          The Arbitrators do not have to explain the reason(s)
                          for their award unless in an eligible case, a joint
                          request for an explained decision has been submitted
                          by all parties to the panel at least 20 days prior to
                          the first scheduled hearing date.
                        </li>
                        <li>
                          The panel of Arbitrators will typically include a
                          minority of Arbitrators who were or are affiliated
                          with the securities industry.
                        </li>
                        <li>
                          The rules of some Arbitration forums may impose time
                          limits for bringing a claim in Arbitration. In some
                          cases, a claim that is ineligible for Arbitration may
                          be brought in court.
                        </li>
                        <li>
                          The rules of the Arbitration forum in which the claim
                          is filed, and any amendments thereto, shall be
                          incorporated into this agreement
                        </li>
                      </ol>
                      <p>
                        The following Arbitration Agreement should be read in
                        conjunction with the disclosures above. Any and all
                        controversies , disputes or claims between the Customer
                        and You, or the Introducing Broker and/or Investment
                        Advisor, or the Agents, Representatives, Employees,
                        Directors, Officers, or Control Persons of You or The
                        Introducing Broker and/or Investment Advisor, Arising
                        out of, in connection with, from or with respect to (a)
                        Any provisions of or the validity of this agreement or
                        any related agreements, (b) The relationship of the
                        parties hereto, or (c) Any controversy arising out of
                        your business, the Introducing Broker and/or Investment
                        Advisor’s business or the Customer’s accounts, Shall be
                        conducted pursuant to the code of Arbitration procedure
                        of the Financial Industry Regulatory Authority
                        (“FINRA”). Arbitration must be commenced by service of a
                        written demand for Arbitration or a written Notice of
                        Intention to Arbitrate. The decision and award of the
                        Arbitrator(s) shall be conclusive and binding upon all
                        parties and any judgment upon any award rendered may be
                        entered in a court having jurisdiction thereof, and
                        neither party shall oppose such entry.
                      </p>

                      <p>
                        No person shall bring a putative or certified class
                        action to arbitration, nor seek to enforce any
                        pre-dispute arbitration agreement against any person who
                        has initiated in court a putative class action; or who
                        is a member of a putative class who has not opted out of
                        the class with respect to any claims encompassed by the
                        putative class action until: (i) the class certification
                        is denied; or (ii) the class is de-certified; or (iii)
                        the customer is excluded from the class by the court.
                        Such forbearance to enforce an agreement to arbitrate
                        shall not constitute a waiver of any rights under this
                        agreement except to the extent stated herein.
                      </p>
                    </li>

                    <li>
                      <p>
                        <b>Payment For Order Flow/Order Routing</b> – “Payment
                        for order flow” is a common and widespread industry
                        practice whereby a brokerage firm receives monetary or
                        non-monetary remuneration in return for the routing of
                        customer orders to a designated exchange, market maker,
                        dealer, or market center for execution. Apex Clearing
                        receives payment for order flow on certain transactions
                        in the form of rebates, monetary compensation or an
                        inter-company transfer of funds. Payment for order flow
                        is considered to be compensation to Apex Clearing. Your
                        broker and/or the introducing firm that clears its
                        trades through Apex Clearing, may or may not be
                        compensated for such orders. The source and nature of
                        any compensation received in connection with a specific
                        transaction will be furnished upon written request of
                        the customer.
                      </p>
                      <p>
                        Absent specific instructions from customers, Apex
                        Clearing automatically routes orders in over-the-counter
                        (“OTC”) securities to selected OTC market makers.
                        Selected exchange traded securities may be routed to
                        affiliated specialists, regional exchanges or designated
                        third- market dealers. All orders are routed to an
                        exchange, market maker, dealer or market center that
                        matches or improves upon the displayed national best bid
                        or offer for the particular security at the time the
                        order is processed. Price improvement opportunities, or
                        execution at prices superior to the displayed national
                        best bid or offer, may be available for certain
                        transactions in NASDAQ and listed securities from
                        execution destinations to which orders are routed.
                      </p>
                    </li>

                    <li>
                      <b>Assignability</b> – This Agreement shall inure to the
                      benefit of our successors and assigns, shall be binding on
                      you, your heirs, executors, administrators and assigns,
                      and shall be governed by the laws of the State of Texas.
                    </li>

                    <li>
                      <p>
                        <b>Accounting</b> – Within 90 days from the close of
                        each custodial account year, we shall render an
                        accounting (valuing the assets fair market value) to
                        you, which accounting may consist of copies of regularly
                        issued broker-dealer statements to you. In the absence
                        of the filing in writing with us of exceptions or
                        objections to any such accounting, within 30 days after
                        the mailing of such accounting, you shall be deemed to
                        have approved such accounting. In such case, or upon
                        your written approval, we shall be released, relieved
                        and discharged with respect to all matters and things
                        set forth in such accounting as though such accounting
                        had been settled by the decree of a court of competent
                        jurisdiction. No person other than you may require an
                        accounting or bring any action against us with respect
                        to this agreement or our actions as Custodian.
                      </p>
                      <p>
                        We reserve the right to apply to a court of competent
                        jurisdiction for judicial settlement of our accounts,
                        for determination of any questions of construction which
                        may arise or for instructions. You shall be the only
                        necessary party defendant to such action except we may,
                        if we so elect, bring in as a party defendant any other
                        person or persons.
                      </p>
                    </li>
                  </ol>
                </li>
              </ul>
            </p>
          </div>

          <div>
            <div>
              <p className="terms-header">GENERAL INSTRUCTIONS</p>
              <p>
                Section References are to the Internal Revenue Code unless
                otherwise noted.
              </p>
            </div>

            <div>
              <p className="terms-label">PURPOSE OF FORM</p>
              <p>
                Form 5305-A is a model custodial account agreement that meets
                the requirements of section 408(a). However, only Articles I
                through VII have been reviewed by the IRS. A Traditional
                individual retirement account (Traditional IRA) is established
                after the form is fully executed by both the individual
                (Depositor) and the custodian. To make a regular contribution to
                a Traditional IRA for a year, the IRA must be established no
                later than the due date of the individual’s income tax return
                for the tax year (excluding extensions). This account must be
                created in the United States for the exclusive benefit of the
                Depositor and his or her beneficiaries. Do not file Form 5305-A
                with the IRS. Instead, keep it with your records. For more
                information on IRAs, including the required disclosures the
                Custodian must give the Depositor, see Pub. 590-A, Contributions
                to Individual Retirement Arrangements (IRAs), and Pub. 590-B,
                Distributions from Individual Retirement Arrangements (IRAs).
              </p>
            </div>

            <div>
              <p className="terms-label"> DEFINITIONS</p>
              <p>
                <b>Custodian </b>– The custodian must be a bank or savings and
                loan association, as defined in section 408(n), or any person
                who has the approval of the IRS to act as custodian.
              </p>
              <p>
                <b>Depositor</b> – The Depositor is the person who establishes
                the custodial account.
              </p>
            </div>

            <div>
              <p className="terms-label">
                TRADITIONAL IRA FOR NONWORKING SPOUSE
              </p>
              <p>
                <b>Custodian </b>– The custodian must be a bank or savings and
                loan association, as defined in section 408(n), or any person
                who has the approval of the IRS to act as custodian.
              </p>
              <p>
                <b>Depositor</b> – The Depositor is the person who establishes
                the custodial account.
              </p>
            </div>
          </div>

          <div>
            <p className="terms-header">SPECIFIC INSTRUCTIONS</p>
            <p>
              <b>Article IV</b> – Distributions made under this article may be
              made in a single sum, periodic payment, or a combination of both.
              The distribution option should be reviewed in the year the
              Depositor reaches age 70½ to ensure that the requirements of
              section 408(a)(6) have been met.
            </p>
            <p>
              <b>Article VIII – Article VIII</b> and any that follow it may
              incorporate additional provisions that are agreed to by the
              Depositor and custodian to complete the agreement. They may
              include, for example, definitions, investment powers, voting
              rights, exculpatory provisions, amendment and termination, removal
              of the custodian, custodian’s fees, state law requirements,
              beginning date of distributions, accepting only cash, treatment of
              excess contributions, prohibited transactions with the Depositor,
              etc. Attach additional pages if necessary.
            </p>
          </div>

          <div>
            <p className="terms-header">DISCLOSURE STATEMENT</p>
            <p className="terms-label">RIGHT TO REVOKE YOUR IRA</p>
            <p>
              You have the right to revoke your IRA within seven (7) days of the
              receipt of the Disclosure Statement. If revoked, you are entitled
              to a full return of the contribution you made to your IRA. The
              amount returned to you would not include an adjustment for such
              items as sales commissions, administrative expenses, or
              fluctuation in market value. You may make this revocation only by
              mailing or delivering a written notice to the IRA Administrator,
              Apex Clearing Corporation, 350 N. St. Paul Street 1300, Dallas, TX
              75201.
            </p>
            <p>
              If you send your notice by first class mail, your revocation will
              be deemed mailed as of the date of the postmark, or if sent by
              certified or registered mail, it shall be deemed to be mailed as
              of the date of certification or registration. If mailed, the
              written notice of revocation shall be mailed in the United States
              in an envelope, or other appropriate wrapper, first-class mail
              with the postage prepaid.
            </p>
            <p>
              If you have any questions about the procedure for revoking your
              IRA, please call the Custodian at the telephone number listed on
              the attached Application.
            </p>
          </div>

          <div>
            <p className="terms-label">REQUIREMENTS OF AN IRA</p>
            <ol type="a">
              <li>
                <b>Cash Contributions</b> – Your contribution must be in cash,
                unless it is a rollover contribution.
              </li>
              <li>
                <b>Maximum Contribution</b> – The total amount you may
                contribute to an IRA for any taxable year cannot exceed the
                lesser of 100 percent of your compensation or $5,500 for 2017
                and 2018, with possible cost-of-living adjustments each year
                thereafter. If you also maintain a Roth IRA (i.e., an IRA
                subject to the limits of Internal Revenue Code Section (IRC
                Sec.) 408A), the maximum contribution to your Traditional IRAs
                is reduced by any contributions you make to your Roth IRAs. Your
                total annual contribution to all Traditional IRAs and Roth IRAs
                cannot exceed the lesser of the dollar amounts described above
                or 100 percent of your compensation. Deposits received by us
                without an IRA Contribution Form will be deposited and reported
                to the IRS in accordance with the following policy:
                <ol type="1">
                  <li>
                    Any deposit we receive below the IRS Annual Contribution
                    Limit for your account will be deposited and reported to the
                    IRS as Current Year Contributions (CYC). We will not
                    aggregate deposits, and multiple deposits below the IRS
                    limit will all be reported to the IRS as CYC and could
                    result in an over-contribution in your account.
                  </li>
                  <li>
                    Deposits we receive above the IRS Annual Contribution Limit
                    for your account will be deposited and reported to the IRS
                    as Rollover Contributions.
                  </li>
                </ol>
              </li>

              <li>
                <b>Contribution Eligibility</b> – You are eligible to make a
                regular contribution to your IRA if you have compensation and
                have not attained age 70½ by the end of the taxable year for
                which the contribution is made.
              </li>
              <li>
                <b>Contribution Eligibility</b> – You are eligible to make a
                regular contribution to your IRA if you have compensation and
                have not attained age 70½ by the end of the taxable year for
                which the contribution is made.
              </li>
              <li>
                <b>Catch-Up Contributions </b> – If you are age 50 or older by
                the close of the taxable year, you may make an additional
                contribution to your IRA. The maximum additional contribution is
                $1,000 per year.
              </li>
              <li>
                <b>Nonforfeitability</b> – Your interest in your IRA is
                nonforfeitable.
              </li>
              <li>
                <b>Eligible Custodians</b> – The custodian of your IRA must be a
                bank, savings and loan association, credit union, or a person or
                entity approved by the Secretary of the Treasury.
              </li>
              <li>
                <b>Commingling Assets</b> – The assets of your IRA cannot be
                commingled with other property except in a common trust fund or
                common investment fund.
              </li>
              <li>
                <b>Life Insurance</b> – No portion of your IRA may be invested
                in life insurance contracts.
              </li>
              <li>
                <b>Collectibles</b> – You may not invest the assets of your IRA
                in collectibles (within the meaning of IRC Sec. 408(m)). A
                collectible is defined as any work of art, rug or antique, metal
                or gem, stamp or coin, alcoholic beverage, or other tangible
                personal property specified by the Internal Revenue Service
                (IRS). However, specially minted United States gold and silver
                coins, and certain state-issued coins are permissible
                investments. Platinum coins and certain gold, silver, platinum,
                or palladium bullion (as described in IRC Sec. 408(m)(3)) are
                also permitted as IRA investments.
              </li>

              <li>
                <b>Required Minimum Distributions</b> – You are required to take
                minimum distributions from your IRA at certain times in
                accordance with Treasury Regulation 1.408-8. Below is a summary
                of the IRA distribution rules.
                <ol type="1">
                  <li>
                    You are required to take a minimum distribution from your
                    IRA for the year in which you reach age 70½ and for each
                    year thereafter. You must take your first distribution by
                    your required beginning date, which is April 1 of the year
                    following the year you attain age 70½. The minimum
                    distribution for any taxable year is equal to the amount
                    obtained by dividing the account balance at the end of the
                    prior year by the applicable divisor.
                  </li>

                  <li>
                    <p>
                      The applicable divisor generally is determined using the
                      Uniform Lifetime Table provided by the IRS. If your spouse
                      is your sole designated beneficiary for the entire
                      calendar year, and is more than 10 years younger than you,
                      the required minimum distribution is determined each year
                      using the actual joint life expectancy of you and your
                      spouse obtained from the Joint Life Expectancy Table
                      provided by the IRS, rather than the life expectancy
                      divisor from the Uniform Lifetime Table.
                    </p>
                    <p>
                      We reserve the right to do any one of the following by
                      April 1 of the year following the year in which you turn
                      age 70½.
                    </p>
                    <ol type="a">
                      <li>
                        Make no distribution until you give us a proper
                        withdrawal request
                      </li>
                      <li>
                        Distribute your entire IRA to you in a single sum
                        payment
                      </li>
                      <li>
                        Determine your required minimum distribution each year
                        based on your life expectancy calculated using the
                        Uniform Lifetime Table, and pay those distributions to
                        you until you direct otherwise
                      </li>
                    </ol>
                    <p>
                      If you fail to remove a required minimum distribution, an
                      additional penalty tax of 50 percent is imposed on the
                      amount of the required minimum distribution that should
                      have been taken but was not. You must file IRS Form 5329
                      along with your income tax return to report and remit any
                      additional taxes to the IRS.
                    </p>
                  </li>

                  <li>
                    <p>
                      Your designated beneficiary is determined based on the
                      beneficiary(ies) designated as of the date of your death,
                      who remain your beneficiary(ies) as of September 30 of the
                      year following the year of your death.
                    </p>
                    <p>
                      If you die on or after your required beginning date,
                      distributions must be made to your beneficiary(ies) over
                      the longer of the single life expectancy of your
                      designated beneficiary(ies), or your remaining life
                      expectancy. If a beneficiary other than an individual or
                      qualified trust as defined in the Treasury Regulations is
                      named, you will be treated as having no designated
                      beneficiary of your IRA for purposes of determining the
                      distribution period. If there is no designated beneficiary
                      of your IRA, distributions will commence using your single
                      life expectancy, reduced by one in each subsequent year.
                    </p>
                    <p>
                      If you die before your required beginning date, the entire
                      amount remaining in your account will, at the election of
                      your designated beneficiary(ies), either
                    </p>
                    <ol type="a">
                      <li>
                        be distributed by December 31 of the year containing the
                        fifth anniversary of your death, or
                      </li>
                      <li>
                        be distributed over the remaining life expectancy of
                        your designated beneficiary(ies).
                      </li>
                    </ol>
                    <p>
                      If your spouse is your sole designated beneficiary, he or
                      she must elect either option (a) or (b) by the earlier of
                      December 31 of the year containing the fifth anniversary
                      of your death, or December 31 of the year life expectancy
                      payments would be required to begin. Your designated
                      beneficiary(ies), other than a spouse who is the sole
                      designated beneficiary, must elect either option (a) or
                      (b) by December 31 of the year following the year of your
                      death. If no election is made, distribution will be
                      calculated in accordance with option (b). In the case of
                      distributions under option (b), distributions must
                      commence by December 31 of the year following the year of
                      your death. Generally, if your spouse is the designated
                      beneficiary, distributions need not commence until
                      December 31 of the year you would have attained age 70½,
                      if later. If a beneficiary other than an individual or
                      qualified trust as defined in the Treasury Regulations is
                      named, you will be treated as having no designated
                      beneficiary(ies) of your IRA for purposes of determining
                      the distribution period. If there is no designated
                      beneficiary of your IRA, the entire IRA must be
                      distributed by December 31 of the year containing the
                      fifth anniversary of your death.
                    </p>
                    <p>
                      A spouse who is the sole designated beneficiary of your
                      entire IRA will be deemed to elect to treat your IRA as
                      his or her own by either (1) making contributions to your
                      IRA or (2) failing to timely remove a required minimum
                      distribution from your IRA. Regardless of whether or not
                      the spouse is the sole designated beneficiary of your IRA,
                      a spouse beneficiary may roll over his or her share of the
                      assets to his or her own IRA.
                    </p>
                    <p>
                      If your beneficiary fails to remove a required minimum
                      distribution after your death, an additional penalty tax
                      of 50 percent is imposed on the amount of the required
                      minimum distribution that should have been taken but was
                      not. Your beneficiary must file IRS Form 5329 along with
                      his or her income tax return to report and remit any
                      additional taxes to the IRS.
                    </p>
                  </li>
                </ol>
                <li>
                  <p>
                    <b>Qualifying Longevity Annuity Contracts and RMDs</b>– A
                    qualifying longevity annuity contract (QLAC) is a deferred
                    annuity contract that, among other requirements, must
                    guarantee lifetime income starting no later than age 85. The
                    total premiums paid to QLACs in your IRAs must not exceed 25
                    percent (up to $125,000) of the combined value of your IRAs
                    (excluding Roth IRAs). The $125,000 limit is subject to
                    cost‐of‐living adjustments each year.
                  </p>
                  <p>
                    When calculating your RMD, you may reduce the prior year end
                    account value by the value of QLACs that your IRA holds as
                    investments.
                  </p>
                  <p>
                    For more information on QLACs, you may wish to refer to the
                    IRS website at www.irs.gov.
                  </p>
                </li>
              </li>
            </ol>
          </div>

          <div>
            <p className="terms-label">
              INCOME TAX CONSEQUENCES OF ESTABLISHING AN IRA
            </p>
            <ol type="a">
              <li>
                <p>
                  <b> IRA Deductibility</b> – If you are eligible to contribute
                  to your IRA, the amount of the contribution for which you may
                  take a tax deduction will depend upon whether you (or, in some
                  cases, your spouse) are an active participant in an
                  employer-sponsored retirement plan. If you (and your spouse,
                  if married) are not an active participant, your entire IRA
                  contribution will be deductible. If you are an active
                  participant (or are married to an active participant), the
                  deductibility of your IRA contribution will depend on your
                  modified adjusted gross income (MAGI) and your tax filing
                  status for the tax year for which the contribution was made.
                  MAGI is determined on your income tax return using your
                  adjusted gross income but disregarding any deductible IRA
                  contribution and certain other deductions and exclusions.
                </p>
                <p>
                  Definition of Active Participant. Generally, you will be an
                  active participant if you are covered by one or more of the
                  following employer-sponsored retirement plans.
                </p>
                <ol type="1">
                  <li>
                    Qualified pension, profit sharing, 401(k), or stock bonus
                    plan
                  </li>
                  <li>Qualified annuity plan of an employer</li>
                  <li>Simplified employee pension (SEP) plan</li>
                  <li>
                    Retirement plan established by the federal government, a
                    state, or a political subdivision (except certain unfunded
                    deferred compensation plans under IRC Sec. 457)
                  </li>
                  <li>
                    Tax-sheltered annuity for employees of certain tax-exempt
                    organizations or public schools
                  </li>
                  <li>Plan meeting the requirements of IRC Sec. 501(c)(18)</li>
                  <li>
                    Savings incentive match plan for employees of small
                    employers (SIMPLE) IRA plan or a SIMPLE 401(k) plan
                  </li>
                </ol>
                <p>
                  If you do not know whether your employer maintains one of
                  these plans or whether you are an active participant in a
                  plan, check with your employer or your tax advisor. Also, the
                  IRS Form W-2, Wage and Tax Statement that you receive at the
                  end of the year from your employer will indicate whether you
                  are an active participant.
                </p>
                <p>
                  If you are an active participant, are single, and have MAGI
                  within the applicable phase-out range listed below, the
                  deductible amount of your contribution is determined as
                  follows. (1) Begin with the appropriate phase-out range
                  maximum for the applicable year (specified below) and subtract
                  your MAGI; (2) divide this total by the difference between the
                  phase-out maximum and minimum; and (3) multiply this number by
                  the maximum allowable contribution for the applicable year,
                  including catch-up contributions if you are age 50 or older.
                  The resulting figure will be the maximum IRA deduction you may
                  take. For example, if you are age 30 with MAGI of $63,000 in
                  2017, your maximum deductible contribution is $4,950 (the 2017
                  phase-out range maximum of $72,000 minus your MAGI of $63,000,
                  divided by the difference between the maximum and minimum
                  phase-out range limits of $10,000, and multiplied by the
                  contribution limit of $5,500).
                </p>
                <p>
                  If you are an active participant, are married to an active
                  participant and you file a joint income tax return, and have
                  MAGI within the applicable phase-out range listed below, the
                  deductible amount of your contribution is determined as
                  follows. (1) Begin with the appropriate phase-out maximum for
                  the applicable year (specified below) and subtract your MAGI;
                  (2) divide this total by the difference between the phase-out
                  range maximum and minimum; and (3) multiply this number by the
                  maximum allowable contribution for the applicable year,
                  including catch-up contributions if you are age 50 or older.
                  The resulting figure will be the maximum IRA deduction you may
                  take. For example, if you are age 30 with MAGI of $103,000 in
                  2017, your maximum deductible contribution is $4,400 (the 2017
                  phase-out maximum of $119,000 minus your MAGI of $103,000,
                  divided by the difference between the maximum and minimum
                  phase-out limits of $20,000, and multiplied by the
                  contribution limit of $5,500).
                </p>
                <p>
                  If you are an active participant, are married and you file a
                  separate income tax return, your MAGI phase-out range is
                  generally $0–$10,000. However, if you lived apart for the
                  entire tax year, you are treated as a single filer.
                </p>
                <p className="terms-label">Tax Year 2011:</p>
                <ul>
                  <li>Joint Filers Phase-Out Range: $90,000 – $110,000</li>
                  <li>Single Filers Phase-Out Range: $56,000 – $66,000</li>
                </ul>
                <p className="terms-label">Tax Year 2012:</p>
                <ul>
                  <li>Joint Filers Phase-Out Range: $92,000 – $112,000</li>
                  <li>Single Filers Phase-Out Range: $58,000 – $68,000</li>
                </ul>
                <p className="terms-label">Tax Year 2013:</p>
                <ul>
                  <li>Joint Filers Phase-Out Range: $95,000 – $115,000</li>
                  <li>Single Filers Phase-Out Range: $59,000 – $69,000</li>
                </ul>
                <p className="terms-label">Tax Year 2014:</p>
                <ul>
                  <li>Joint Filers Phase-Out Range: $96,000 – $116,000</li>
                  <li>Single Filers Phase-Out Range: $60,000 – $70,000</li>
                </ul>
                <p className="terms-label">Tax Year 2015:</p>
                <ul>
                  <li>Joint Filers Phase-Out Range: $98,000 – $118,000</li>
                  <li>Single Filers Phase-Out Range: $61,000 – $71,000</li>
                </ul>
                <p className="terms-label">Tax Year 2016:</p>
                <ul>
                  <li>Joint Filers Phase-Out Range: $98,000 – $118,000</li>
                  <li>Single Filers Phase-Out Range: $61,000 – $71,000</li>
                </ul>
                <p className="terms-label">Tax Year 2017:</p>
                <ul>
                  <li>Joint Filers Phase-Out Range: $99,000 – $119,000</li>
                  <li>Single Filers Phase-Out Range: $62,000 – $72,000</li>
                </ul>
                <p className="terms-label">Tax Year 2018:</p>
                <ul>
                  <li>Joint Filers Phase-Out Range: $101,000 – $121,000</li>
                  <li>Single Filers Phase-Out Range: $63,000 – $73,000</li>
                </ul>

                <p>
                  MAGI limits are subject to cost-of-living adjustments each
                  year.
                </p>
                <p>
                  The MAGI phase-out range for an individual that is not an
                  active participant, but is married to an active participant,
                  is $186,000–$196,000 (for 2017) and $189,000–$199,000 (for
                  2018). This limit is also subject to cost-of-living increases
                  for tax years after 2018. If you are not an active participant
                  in an employer- sponsored retirement plan, are married to
                  someone who is an active participant, and you file a joint
                  income tax return with MAGI between the applicable phase-out
                  range for the year, your maximum deductible contribution is
                  determined as follows.
                </p>
                <p>
                  <ol type="1">
                    <li>
                      Begin with the appropriate MAGI phase-out maximum for the
                      year and subtract your MAGI;
                    </li>
                    <li>
                      Divide this total by the difference between the phase-out
                      range maximum and minimum;
                    </li>
                    <li>
                      Multiply this number by the maximum allowable contribution
                      for the applicable year, including catch-up contributions
                      if you are age 50 or older.
                    </li>
                  </ol>
                </p>
                <p>
                  The resulting figure will be the maximum IRA deduction you may
                  take.
                </p>
                <p>
                  You must round the resulting deduction to the next highest $10
                  if the number is not a multiple of 10. If your resulting
                  deduction is between $0 and $200, you may round up to $200.
                </p>
              </li>

              <li>
                <p>
                  <b> Contribution Deadline</b> – The deadline for making an IRA
                  contribution is your tax return due date (not including
                  extensions). You may designate a contribution as a
                  contribution for the preceding taxable year in a manner
                  acceptable to us. For example, if you are a calendar-year
                  taxpayer and you make your IRA contribution on or before your
                  tax filing deadline, your contribution is considered to have
                  been made for the previous tax year if you designate it as
                  such.
                </p>
                <p>
                  If you are a member of the Armed Forces serving in a combat
                  zone, hazardous duty area, or contingency operation, you may
                  have an extended contribution deadline of 180 days after the
                  last day served in the area. In addition, your contribution
                  deadline for a particular tax year is also extended by the
                  number of days that remained to file that year’s tax return as
                  of the date you entered the combat zone. This additional
                  extension to make your IRA contribution cannot exceed the
                  number of days between January 1 and your tax filing deadline,
                  not including extensions.
                </p>
              </li>

              <li>
                <p>
                  <b> Tax Credit for Contributions</b> – You may be eligible to
                  receive a tax credit for your Traditional IRA contributions.
                  This credit will be allowed in addition to any tax deduction
                  that may apply, and may not exceed $1,000 in a given year. You
                  may be eligible for this tax credit if you are
                </p>
                <ul>
                  <li>age 18 or older as of the close of the taxable year,</li>
                  <li>not a dependent of another taxpayer, and</li>
                  <li>not a full-time student.</li>
                </ul>
                <p>
                  The credit is based upon your income (see chart below), and
                  will range from 0 to 50 percent of eligible contributions. In
                  order to determine the amount of your contributions, add all
                  of the contributions made to your Traditional IRA and reduce
                  these contributions by any distributions that you have taken
                  during the testing period. The testing period begins two years
                  prior to the year for which the credit is sought and ends on
                  the tax return due date (including extensions) for the year
                  for which the credit is sought. In order to determine your tax
                  credit, multiply the applicable percentage from the chart
                  below by the amount of your contributions that do not exceed
                  $2,000.
                </p>
                <p className="terms-label">2017 Adjusted Gross Income</p>
                <span>
                  <p className="terms-percentage">
                    Applicable Percentage of 50%:
                  </p>
                  <ul>
                    <li>Joint Return: $1 - 37,000</li>
                    <li>Heads of a Household: $1 - 27,750</li>
                    <li>All Other Cases: $1 - 18,500</li>
                  </ul>
                </span>

                <span>
                  <p className="terms-percentage">
                    Applicable Percentage of 20%:
                  </p>
                  <ul>
                    <li>Joint Return: $37,001 - 40,000</li>
                    <li>Heads of a Household: $27,751 - 30,000</li>
                    <li>All Other Cases: $18,501 - 20,000</li>
                  </ul>
                </span>

                <span>
                  <p className="terms-percentage">
                    Applicable Percentage of 10%:
                  </p>
                  <ul>
                    <li>Joint Return: $40,001 - 62,000</li>
                    <li>Heads of a Household: $30,001 - 46,500</li>
                    <li>All Other Cases: $20,001 - 31,000</li>
                  </ul>
                </span>

                <span>
                  <p className="terms-percentage">
                    Applicable Percentage of 0%:
                  </p>
                  <ul>
                    <li>Joint Return: Over $62,000</li>
                    <li>Heads of a Household: Over $46,500</li>
                    <li>All Other Cases: Over $31,000</li>
                  </ul>
                </span>

                <p className="terms-label">2018 Adjusted Gross Income</p>
                <span>
                  <p className="terms-percentage">
                    Applicable Percentage of 50%:
                  </p>
                  <ul>
                    <li>Joint Return: $1 - 38,000</li>
                    <li>Heads of a Household: $1 - 28,500</li>
                    <li>All Other Cases: $1 – $19,000</li>
                  </ul>
                </span>

                <span>
                  <p className="terms-percentage">
                    Applicable Percentage of 20%:
                  </p>
                  <ul>
                    <li>Joint Return: $38,001 - 41,000</li>
                    <li>Heads of a Household: $28,501 - 30,750</li>
                    <li>All Other Cases: $19,001 – $20,500</li>
                  </ul>
                </span>

                <span>
                  <p className="terms-percentage">
                    Applicable Percentage of 10%:
                  </p>
                  <ul>
                    <li>Joint Return: $41,001 - 63,000</li>
                    <li>Heads of a Household: $30,751 - 47,250</li>
                    <li>All Other Cases: $20,501 – $31,500</li>
                  </ul>
                </span>

                <span>
                  <p className="terms-percentage">
                    Applicable Percentage of 0%:
                  </p>
                  <ul>
                    <li>Joint Return: Over $63,000</li>
                    <li>Heads of a Household: Over $47,250</li>
                    <li>All Other Cases: Over $31,500</li>
                  </ul>
                </span>

                <p>
                  Adjusted gross income (AGI) includes foreign earned income and
                  income from Guam, America Samoa, North Mariana Islands, and
                  Puerto Rico. AGI limits are subject to cost-of-living
                  adjustments each year.
                </p>
              </li>

              <li>
                <p>
                  <b>Excess Contributions</b> – An excess contribution is any
                  amount that is contributed to your IRA that exceeds the amount
                  that you are eligible to contribute. If the excess is not
                  corrected timely, an additional penalty tax of six percent
                  will be imposed upon the excess amount. The procedure for
                  correcting an excess is determined by the timeliness of the
                  correction as identified below.
                  <ol type="1">
                    <li>
                      Removal Before Your Tax Filing Deadline. An excess
                      contribution may be corrected by withdrawing the excess
                      amount, along with the earnings attributable to the
                      excess, before your tax filing deadline, including
                      extensions, for the year during which the excess
                      contribution was made. An excess withdrawn under this
                      method is not taxable to you, but you must include the
                      earnings attributable to the excess in your taxable income
                      in the year in which the contribution was made. The six
                      percent excess contribution penalty tax will be avoided.
                    </li>
                    <li>
                      Removal After Your Tax Filing Deadline. If you are
                      correcting an excess contribution after your tax filing
                      deadline, including extensions, remove only the amount of
                      the excess contribution. The six percent excess
                      contribution penalty tax will be imposed on the excess
                      contribution for each year it remains in the IRA. An
                      excess withdrawal under this method will only be taxable
                      to you if the total contributions made in the year of the
                      excess exceed the annual applicable contribution limit.
                    </li>
                    <li>
                      Carry Forward to a Subsequent Year. If you do not withdraw
                      the excess contribution, you may carry forward the
                      contribution for a subsequent tax year. To do so, you
                      under-contribute for that tax year and carry the excess
                      contribution amount forward to that year on your tax
                      return. The six percent excess contribution penalty tax
                      will be imposed on the excess amount for each year that it
                      remains as an excess contribution at the end of the year.
                      <p>
                        You must file IRS Form 5329 along with your income tax
                        return to report and remit any additional taxes to the
                        IRS.
                      </p>
                    </li>
                  </ol>
                </p>
              </li>
              <li>
                <b> Tax-Deferred Earnings</b> – The investment earnings of your
                IRA are not subject to federal income tax until distributions
                are made (or, in certain instances, when distributions are
                deemed to be made).
              </li>
              <li>
                <p>
                  <b>Nondeductible Contributions</b> – You may make
                  nondeductible contributions to your IRA to the extent that
                  deductible contributions are not allowed. The sum of your
                  deductible and nondeductible IRA contributions cannot exceed
                  your contribution limit (the lesser of the allowable
                  contribution limit described previously, or 100 percent of
                  compensation). You may elect to treat deductible IRA
                  contributions as nondeductible contributions.
                </p>
                <p>
                  If you make nondeductible contributions for a particular tax
                  year, you must report the amount of the nondeductible
                  contribution along with your income tax return using IRS Form
                  8606. Failure to file IRS Form 8606 will result in a $50 per
                  failure penalty.
                </p>
                <p>
                  If you overstate the amount of designated nondeductible
                  contributions for any taxable year, you are subject to a $100
                  penalty unless reasonable cause for the overstatement can be
                  shown.
                </p>
              </li>
              <li>
                <p>
                  <b>Taxation of Distributions</b> – The taxation of IRA
                  distributions depends on whether or not you have ever made
                  nondeductible IRA contributions. If you have only made
                  deductible contributions, all IRA distribution amounts will be
                  included in income.
                </p>
                <p>
                  If you have ever made nondeductible contributions to any IRA,
                  the following formula must be used to determine the amount of
                  any IRA distribution excluded from income
                </p>
                <p>
                  (Aggregate Nondeductible Contributions X Amount Withdrawn) /
                  Aggregate IRA Balance = Amount Excluded From Income
                </p>
                <p>
                  <b> NOTE:</b> Aggregate nondeductible contributions include
                  all nondeductible contributions made by you through the end of
                  the year of the distribution that have not previously been
                  withdrawn and excluded from income. Also note that the
                  aggregate IRA balance includes the total balance of all of
                  your Traditional and SIMPLE IRAs as of the end of the year of
                  distribution and any distributions occurring during the year.
                </p>
              </li>
              <li>
                <b>Income Tax Withholding</b> – Any withdrawal from your IRA is
                subject to federal income tax withholding. You may, however,
                elect not to have withholding apply to your IRA withdrawal. If
                withholding is applied to your withdrawal, not less than 10
                percent of the amount withdrawn must be withheld.
              </li>
              <li>
                <p>
                  <b>Early Distribution Penalty Tax</b> – If you receive an IRA
                  distribution before you attain age 59½, an additional early
                  distribution penalty tax of 10 percent will apply to the
                  taxable amount of the distribution unless one of the following
                  exceptions apply.
                </p>
                <ol type="1">
                  <li>
                    Death. After your death, payments made to your beneficiary
                    are not subject to the 10 percent early distribution penalty
                    tax.
                  </li>
                  <li>
                    Disability. If you are disabled at the time of distribution,
                    you are not subject to the additional 10 percent early
                    distribution penalty tax. In order to be disabled, a
                    physician must determine that your impairment can be
                    expected to result in death or to be of long, continued, and
                    indefinite duration.
                  </li>
                  <li>
                    Substantially equal periodic payments. You are not subject
                    to the additional 10 percent early distribution penalty tax
                    if you are taking a series of substantially equal periodic
                    payments (at least annual payments) over your life
                    expectancy or the joint life expectancy of you and your
                    beneficiary. You must continue these payments for the longer
                    of five years or until you reach age 59½.
                  </li>
                  <li>
                    Unreimbursed medical expenses. If you take payments to pay
                    for unreimbursed medical expenses that exceed a specified
                    percentage of your adjusted gross income, you will not be
                    subject to the 10 percent early distribution penalty tax.
                    For further detailed information and effective dates you may
                    obtain IRS Publication 590-B, Distributions from Individual
                    Retirement Arrangements (IRAs), from the IRS. The medical
                    expenses may be for you, your spouse, or any dependent
                    listed on your tax return.
                  </li>
                  <li>
                    Health insurance premiums. If you are unemployed and have
                    received unemployment compensation for 12 consecutive weeks
                    under a federal or state program, you may take payments from
                    your IRA to pay for health insurance premiums without
                    incurring the 10 percent early distribution penalty tax.
                  </li>
                  <li>
                    Higher education expenses. Payments taken for certain
                    qualified higher education expenses for you, your spouse, or
                    the children or grandchildren of you or your spouse, will
                    not be subject to the 10 percent early distribution penalty
                    tax.
                  </li>
                  <li>
                    First- time homebuyer. You may take payments from your IRA
                    to use toward qualified acquisition costs of buying or
                    building a principal residence. The amount you may take for
                    this reason may not exceed a lifetime maximum of $10,000.
                    The payment must be used for qualified acquisition costs
                    within 120 days of receiving the distribution.
                  </li>
                  <li>
                    IRS levy. Payments from your IRA made to the U.S. government
                    in response to a federal tax levy are not subject to the 10
                    percent early distribution penalty tax.
                  </li>
                  <li>
                    <p>
                      Qualified reservist distributions. If you are a qualified
                      reservist member called to active duty for more than 179
                      days or an indefinite period, the payments you take from
                      your IRA during the active duty period are not subject to
                      the 10 percent early distribution penalty tax.
                    </p>
                    <p>
                      You must file IRS Form 5329 along with your income tax
                      return to the IRS to report and remit any additional taxes
                      or to claim a penalty tax exception.
                    </p>
                  </li>
                </ol>
              </li>

              <li>
                <p>
                  <b> Rollovers and Conversions</b> – Your IRA may be rolled
                  over to another IRA of yours, may receive rollover
                  contributions, or may be converted to a Roth IRA, provided
                  that all of the applicable rollover and conversion rules are
                  followed. Rollover is a term used to describe a movement of
                  cash or other property to your IRA from another IRA, or from
                  your employer’s qualified retirement plan, 403(a) annuity,
                  403(b) tax-sheltered annuity, 457(b) eligible governmental
                  deferred compensation plan, or federal Thrift Savings Plan.
                  The amount rolled over is not subject to taxation or the
                  additional 10 percent early distribution penalty tax.
                  Conversion is a term used to describe the movement of
                  Traditional IRA assets to a Roth IRA. A conversion generally
                  is a taxable event. The general rollover and conversion rules
                  are summarized below. These transactions are often complex. If
                  you have any questions regarding a rollover or conversion,
                  please see a competent tax advisor.
                </p>
                <ol type="1">
                  <li>
                    <p>
                      Traditional IRA to Traditional IRA Rollovers. Assets
                      distributed from your Traditional IRA may be rolled over
                      to the same Traditional IRA or another Traditional IRA of
                      yours if the requirements of IRC Sec. 408(d)(3) are met. A
                      proper IRA‐to‐IRA rollover is completed if all or part of
                      the distribution is rolled over not later than 60 days
                      after the distribution is received. In the case of a
                      distribution for a first‐time homebuyer where there was a
                      delay or cancellation of the purchase, the 60‐day rollover
                      period may be extended to 120 days.
                    </p>
                    <p>
                      Effective for distributions taken on or after January 1,
                      2015, you are permitted to roll over only one distribution
                      from an IRA (Traditional, Roth, or SIMPLE) in a 12‐month
                      period, regardless of the number of IRAs you own. A
                      distribution may be rolled over to the same IRA or to
                      another IRA that is eligible to receive the rollover. For
                      more information on rollover limitations, you may wish to
                      obtain IRS Publication 590, Individual Retirement
                      Arrangements (IRAs), from the IRS or refer to the IRS
                      website at www.irs.gov.
                    </p>
                  </li>
                  <li>
                    <p>
                      SIMPLE IRA to Traditional IRA Rollovers. Assets
                      distributed from your SIMPLE IRA may be rolled over to
                      your Traditional IRA without IRS penalty tax provided two
                      years have passed since you first participated in a SIMPLE
                      IRA plan sponsored by your employer. As with Traditional
                      IRA to Traditional IRA rollovers, the requirements of IRC
                      Sec. 408(d)(3) must be met. A proper SIMPLE IRA to IRA
                      rollover is completed if all or part of the distribution
                      is rolled over not later than 60 days after the
                      distribution is received.
                    </p>
                    <p>
                      Effective for distributions taken on or after January 1,
                      2015, you are permitted to roll over only one distribution
                      from an IRA (Traditional, Roth, or SIMPLE) in a 12‐month
                      period, regardless of the number of IRAs you own. A
                      distribution may be rolled over to the same IRA or to
                      another IRA that is eligible to receive the rollover. For
                      more information on rollover limitations, you may wish to
                      obtain IRS Publication 590, Individual Retirement
                      Arrangements (IRAs), from the IRS or refer to the IRS
                      website at www.irs.gov.
                    </p>
                  </li>
                  <li>
                    <p>
                      Employer-Sponsored Retirement Plan to Traditional IRA
                      Rollovers. You may roll over, directly or indirectly, any
                      eligible rollover distribution from an eligible
                      employer-sponsored retirement plan. An eligible rollover
                      distribution is defined generally as any distribution from
                      a qualified retirement plan, 403(a) annuity, 403(b)
                      tax-sheltered annuity, 457(b) eligible governmental
                      deferred compensation plan (other than distributions to
                      non-spouse beneficiaries), or federal Thrift Savings Plan
                      unless it is part of a certain series of substantially
                      equal periodic payments, a required minimum distribution,
                      a hardship distribution, or a distribution of Roth
                      elective deferrals from a 401(k), 403(b), governmental
                      457(b), or federal Thrift Savings Plan.
                    </p>
                    <p>
                      If you elect to receive your rollover distribution prior
                      to placing it in an IRA, thereby conducting an indirect
                      rollover, your plan administrator generally will be
                      required to withhold 20 percent of your distribution as a
                      payment of income taxes. When completing the rollover, you
                      may make up out of pocket the amount withheld, and roll
                      over the full amount distributed from your
                      employer-sponsored retirement plan.
                    </p>
                    <p>
                      To qualify as a rollover, your eligible rollover
                      distribution generally must be rolled over to your IRA not
                      later than 60 days after you receive the distribution. In
                      the case of a plan loan offset due to plan termination or
                      severance from employment, the deadline for completing the
                      rollover is your tax return due date (including
                      extensions) for the year in which the offset occurs.
                      Alternatively, you may claim the withheld amount as
                      income, and pay the applicable income tax, and if you are
                      under age 59½, the 10 percent early distribution penalty
                      tax (unless an exception to the penalty applies).
                    </p>
                    <li>
                      As an alternative to the indirect rollover, your employer
                      generally must give you the option to directly roll over
                      your employer-sponsored retirement plan balance to an IRA.
                      If you elect the direct rollover option, your eligible
                      rollover distribution will be paid directly to the IRA (or
                      other eligible employer- sponsored retirement plan) that
                      you designate. The 20 percent withholding requirements do
                      not apply to direct rollovers.
                    </li>
                    <li>
                      Beneficiary Rollovers From Employer-Sponsored Retirement
                      Plans. If you are a spouse, non-spouse, or qualified trust
                      beneficiary of a deceased employer- sponsored retirement
                      plan participant, you may directly roll over inherited
                      assets from a qualified retirement plan, 403(a) annuity,
                      403(b) tax-sheltered annuity, or 457(b) eligible
                      governmental deferred compensation plan to an inherited
                      IRA. The IRA must be maintained as an inherited IRA,
                      subject to the beneficiary distribution requirements.
                    </li>
                    <li>
                      Traditional IRA-to-SIMPLE IRA Rollovers. Assets
                      distributed from your Traditional IRA may be rolled over
                      to a SIMPLE IRA if the requirements of IRC Sec. 408(d)(3)
                      are met and two years have passed since you first
                      participated in a SIMPLE IRA plan sponsored by your
                      employer. A proper Traditional IRA‐to‐ SIMPLE IRA rollover
                      is completed if all or part of the distribution is rolled
                      over not later than 60 days after the distribution is
                      received. In the case of a distribution for a first‐time
                      homebuyer where there was a delay or cancellation of the
                      purchase, the 60‐day rollover period may be extended to
                      120 days. You are permitted to roll over only one
                      distribution from an IRA (Traditional, Roth, or SIMPLE) in
                      a 12‐month period, regardless of the number of IRAs you
                      own. A distribution may be rolled over to the same IRA or
                      to another IRA that is eligible to receive the rollover.
                      For more information on rollover limitations, you may
                      obtain IRS Publication 590-B, Distributions from
                      Individual Retirement Arrangements (IRAs), from the IRS or
                      refer to the IRS website at www.irs.gov.
                    </li>
                    <li>
                      Traditional IRA to Employer-Sponsored Retirement Plan
                      Rollovers. You may roll over, directly or indirectly, any
                      taxable eligible rollover distribution from an IRA to your
                      qualified retirement plan, 403(a) annuity, 403(b) tax-
                      sheltered annuity, or 457(b) eligible governmental
                      deferred compensation plan as long as the
                      employer-sponsored retirement plan accepts such rollover
                      contributions.
                    </li>
                    <li>
                      Traditional IRA to Roth IRA Conversions. If you convert to
                      a Roth IRA, the amount of the conversion from your
                      Traditional IRA to your Roth IRA will be treated as a
                      distribution for income tax purposes, and is includible in
                      your gross income (except for any nondeductible
                      contributions). Although the conversion amount generally
                      is included in income, the 10 percent early distribution
                      penalty tax will not apply to conversions from a
                      Traditional IRA to a Roth IRA, regardless of whether you
                      qualify for any exceptions to the 10 percent penalty tax.
                      If you are age 70½ or older you must remove your required
                      minimum distribution before converting your Traditional
                      IRA.
                    </li>
                    <li>
                      Qualified HSA Funding Distribution. If you are eligible to
                      contribute to a health savings account (HSA), you may be
                      eligible to take a one-time tax-free qualified HSA funding
                      distribution from your IRA and directly deposit it to your
                      HSA. The amount of the qualified HSA funding distribution
                      may not exceed the maximum HSA contribution limit in
                      effect for the type of high deductible health plan
                      coverage (i.e., single or family coverage) that you have
                      at the time of the deposit, and counts toward your HSA
                      contribution limit for that year. For further detailed
                      information, you may wish to obtain IRS Publication 969,
                      Health Savings Accounts and Other Tax Favored Health
                      Plans.
                    </li>
                    <li>
                      Rollovers of Settlement Payments From Bankrupt Airlines.
                      If you are a qualified airline employee who has received
                      an airline settlement payment from a commercial airline
                      carrier under the approval of an order of a federal
                      bankruptcy court, you are allowed to roll over up to 90
                      percent of the proceeds into your Traditional IRA within
                      180 days after receipt of such amount, or by a later date
                      if extended by federal law. If you make such a rollover
                      contribution, you may exclude the amount rolled over from
                      your gross income in the taxable year in which the airline
                      settlement payment was paid to you. For further detailed
                      information and effective dates you may obtain IRS
                      Publication 590-A, Contributions to Individual Retirement
                      Arrangements (IRAs), from the IRS or refer to the IRS
                      website at www.irs.gov.
                    </li>
                    <li>
                      Rollovers of Exxon Valdez Settlement Payments. If you
                      receive a qualified settlement payment from Exxon Valdez
                      litigation, you may roll over the amount of the
                      settlement, up to $100,000, reduced by the amount of any
                      qualified Exxon Valdez settlement income previously
                      contributed to a Traditional or Roth IRA or eligible
                      retirement plan in prior taxable years. You will have
                      until your tax return due date (not including extensions)
                      for the year in which the qualified settlement income is
                      received to make the rollover contribution. To obtain more
                      information on this type of rollover, you may wish to
                      visit the IRS website at www.irs.gov.
                    </li>
                    <li>
                      Rollover of IRS Levy. If you receive a refund of eligible
                      retirement plan assets that had been wrongfully levied,
                      you may roll over the amount returned up until your tax
                      return due date (not including extensions) for the year in
                      which the money was returned.
                    </li>
                    <li>
                      Written Election. At the time you make a proper rollover
                      to an IRA, you must designate in writing to the custodian,
                      Apex Clearing, your election to treat that contribution as
                      a rollover. Once made, the rollover election is
                      irrevocable. Deposits in excess of the annual contribution
                      limits that are received without IRA Contribution Forms
                      will be deposited and reported to the IRS as Rollover
                      contributions. Please note that due to the IRS mandated
                      correction filing deadline of July 31, requests to make
                      changes to contribution coding must be received by the
                      close of business on the 2nd Friday in July and
                      accompanied by a completed IRA Contribution Form. Any
                      correction that needs to be made after this reporting
                      deadline will be the responsibility of the IRA Owner and
                      must be handled directly with the IRS.
                    </li>
                  </li>
                  <li>
                    <b> Transfer Due to Divorce</b> – If all or any part of your
                    IRA is awarded to your spouse or former spouse in a divorce
                    or legal separation proceeding, the amount so awarded will
                    be treated as the spouse’s IRA (and may be transferred
                    pursuant to a court-approved divorce decree or written legal
                    separation agreement to another IRA of your spouse), and
                    will not be considered a taxable distribution to you. A
                    transfer is a tax-free direct movement of cash and/or
                    property from one Traditional IRA to another.
                  </li>
                  <li>
                    <b>Recharacterizations</b> – If you make a contribution to a
                    Traditional IRA and later recharacterize either all or a
                    portion of the original contribution to a Roth IRA along
                    with net income attributable, you may elect to treat the
                    original contribution as having been made to the Roth IRA.
                    The same methodology applies when recharacterizing a
                    contribution from a Roth IRA to a Traditional IRA. For tax
                    years beginning before January 1, 2018, if you have
                    converted from a Traditional IRA to a Roth IRA you may
                    recharacterize the conversion along with net income
                    attributable back to a Traditional IRA. The deadline for
                    completing a recharacterization is your tax filing deadline
                    (including any extensions) for the year for which the
                    original contribution was made or conversion completed.
                    However, effective for tax years beginning after December
                    31, 2017, you may not recharacterize a Roth IRA conversion.
                  </li>
                </ol>
              </li>
            </ol>

            <div>
              <p className="terms-label">LIMITATIONS AND RESTRICTIONS</p>
              <ol type="a">
                <li>
                  <p>
                    <b> SEP Plans</b> – Under a simplified employee pension
                    (SEP) plan that meets the requirements of IRC Sec. 408(k),
                    your employer may make contributions to your IRA. Your
                    employer is required to provide you with information that
                    describes the terms of your employer’s SEP plan.
                  </p>
                </li>

                <li>
                  <p>
                    <b>Spousal IRA</b> – If you are married and have
                    compensation, you may contribute to an IRA established for
                    the benefit of your spouse for any year prior to the year
                    your spouse turns age 70½, regardless of whether or not your
                    spouse has compensation. You may make these spousal
                    contributions even if you are age 70½ or older. You must
                    file a joint income tax return for the year for which the
                    contribution is made.
                  </p>
                  <p>
                    The amount you may contribute to your IRA and your spouse’s
                    IRA is the lesser of 100 percent of your combined eligible
                    compensation or $11,000 for 2017 and 2018. This amount may
                    be increased with cost-of-living adjustments each year.
                    However, you may not contribute more than the individual
                    contribution limit to each IRA.
                  </p>
                  <p>
                    If your spouse is age 50 or older by the close of the
                    taxable year, and is otherwise eligible, you may make an
                    additional contribution to your spouse’s IRA. The maximum
                    additional contribution is $1,000 per year.
                  </p>
                </li>

                <li>
                  <b>Deduction of Rollovers and Transfers</b> – A deduction is
                  not allowed for rollover or transfer contributions.
                </li>

                <li>
                  <b>Gift Tax</b> – Transfers of your IRA assets to a
                  beneficiary made during your life and at your request may be
                  subject to federal gift tax under IRC Sec. 2501.
                </li>

                <li>
                  <b>Special Tax Treatment</b> – Capital gains treatment and
                  10-year income averaging authorized by IRC Sec. 402 do not
                  apply to IRA distributions.
                </li>

                <li>
                  <b>Prohibited Transactions </b>– If you or your beneficiary
                  engage in a prohibited transaction with your IRA, as described
                  in IRC Sec. 4975, your IRA will lose its tax-deferred status,
                  and you must include the value of your account in your gross
                  income for that taxable year. The following transactions are
                  examples of prohibited transactions with your IRA. (1) Taking
                  a loan from your IRA (2) Buying property for personal use
                  (present or future) with IRA assets (3) Receiving certain
                  bonuses or premiums because of your IRA.
                </li>

                <li>
                  <b>Pledging</b> – If you pledge any portion of your IRA as
                  collateral for a loan, the amount so pledged will be treated
                  as a distribution and will be included in your gross income
                  for that year.
                </li>
              </ol>
            </div>

            <div>
              <p className="terms-label">OTHER</p>
              <ol type="a">
                <li>
                  <b> IRS Plan Approval</b> – Articles I through VII of the
                  agreement used to establish this IRA have been approved by the
                  IRS. The IRS approval is a determination only as to form. It
                  is not an endorsement of the plan in operation or of the
                  investments offered.
                </li>
                <li>
                  <b> Additional Information</b> – For further information on
                  IRAs, you may wish to obtain IRS Publication 590-A,
                  Contributions to Individual Retirement Arrangements (IRAs), or
                  Publication 590-B, Distributions from Individual Retirement
                  Arrangements (IRAs), by calling 800-TAX-FORM, or by visiting
                  www.irs.gov on the Internet.
                </li>
                <li>
                  <b>
                    Important Information About Procedures for Opening a New
                    Account
                  </b>
                  – To help the government fight the funding of terrorism and
                  money laundering activities, federal law requires all
                  financial organizations to obtain, verify, and record
                  information that identifies each person who opens an account.
                  Therefore, when you open an IRA, you are required to provide
                  your name, residential address, date of birth, and
                  identification number. We may require other information that
                  will allow us to identify you.
                </li>
                <li>
                  <b>Qualified Reservist Distributions </b>– If you are an
                  eligible qualified reservist who has taken penalty-free
                  qualified reservist distributions from your IRA or retirement
                  plan, you may recontribute those amounts to an IRA generally
                  within a two-year period from your date of return.
                </li>
                <li>
                  <b> Qualified Charitable Distributions</b> – If you are age
                  70½ or older, you may take tax-free IRA distributions of up to
                  $100,000 per year and have these distributions paid directly
                  to certain charitable organizations. Special tax rules may
                  apply. This provision applies to distributions during tax
                  years 2012 and 2013 and may apply to subsequent years if
                  extended by Congress. For further detailed information and
                  effective dates you may wish to obtain IRS Publication 590,
                  Individual Retirement Arrangements (IRAs), from the IRS or
                  refer to the IRS website at www.irs.gov.
                </li>
                <li>
                  <b>Disaster Related Relief</b> – If you qualify (for example,
                  you sustained an economic loss due to, or are otherwise
                  considered affected by, certain IRS designated disasters), you
                  may be eligible for favorable tax treatment on distributions,
                  rollovers, and other transactions involving your IRA.
                  Qualified disaster relief may include penalty-tax free early
                  distributions made during specified timeframes for each
                  disaster, the ability to include distributions in your gross
                  income ratably over multiple years, the ability to roll over
                  distributions to an eligible retirement plan without regard to
                  the 60-day rollover rule, and more. For additional information
                  on specific disasters, including a complete listing of
                  disaster areas, qualification requirements for relief, and
                  allowable disaster-related IRA transactions, you may wish to
                  obtain IRS Publication 590, Individual Retirement Arrangements
                  (IRAs), from the IRS or refer to the IRS website at
                  www.irs.gov.
                </li>
              </ol>
            </div>

            <div>
              <p className="terms-label">FINANCIAL DISCLOSURE</p>
              <p>
                Apex Clearing Corporation may charge your broker and/or
                investment advisor a fee. Please contact your broker and/or
                investment advisor for information regarding these charges.
              </p>
              <p>
                <b>Service Fees:</b> We have the right to charge an annual
                service fee and other designated fees (e.g., a transfer,
                rollover or termination fee) in conjunction with your IRA. In
                addition, we have the right to be reimbursed for all reasonable
                expenses, including legal expenses, we incur in connection with
                the administration of your IRA. We may charge you separately for
                any fees or expenses, or we may deduct the amount of the fees or
                expenses from the assets in your IRA at our discretion. The full
                annual service fee attributable to the year in which you
                terminate your IRA, along with the termination fee, shall be due
                and payable upon termination of your IRA regardless of the date
                during the year in which you terminate your IRA. We reserve the
                right to charge any additional fee upon 30 days’ notice to you
                that the fee will be effective. Fees such as sub transfer agent
                fees or commissions may be paid to us by third parties for
                assistance in performing certain transactions with respect to
                this IRA.
              </p>
              <p>
                Any brokerage commissions attributable to the assets in your IRA
                will be charged to your IRA. You cannot reimburse your IRA for
                those commissions.
              </p>
              <p>
                The value of your IRA will be solely dependent upon the
                performance of any investment instrument chosen by you to fund
                your IRA. Therefore, no projection of the growth of your IRA can
                be reasonably shown or guaranteed. There are certain fees and
                charges associated with the investments you may select for your
                IRA. In the event this agreement is terminated or you transfer
                out of your existing IRA, a fee will apply. Additionally,
                brokerage commissions may apply according to your selection of
                investments. Questions relative to brokerage commission(s)
                should be discussed with your broker and/or investment advisor
                prior to executing any orders or you may refer to the prospectus
                which will describe the terms of the investment you choose.
              </p>
              <p>
                You will select the type of investment for your IRA assets,
                provided, however, that your selection of investments shall be
                limited to any investment vehicle obtainable by us, that we are
                authorized by our charter, articles of incorporation, or bylaws
                to offer and do in fact, in our sole discretion offer for
                investment in IRAs. For example, investments may include but
                shall not be limited to common stocks, government and corporate
                bonds, mutual funds, the purchase of put options on existing
                positions and writing of covered listed call options and such
                other options strategies that we may, from time to time, in our
                sole discretion make available for IRAs and which strategies are
                approved for your account by your broker and/or investment
                advisor. Investments not generating confirmations must be
                accompanied by additional written instructions and such other
                documentation as we may, in our sole discretion, require. We
                shall act as a stockbroker or dealer whenever such services are
                required. We may, in our sole discretion, make available to you,
                additional investment offerings, which shall be limited to
                publicly traded securities, mutual funds, money market
                instruments and other investments that are obtainable by us and
                that we, in our sole discretion, determine that we are capable
                of holding in the ordinary course of our business.
              </p>
            </div>

            <div>
              <p className="terms-label">TRUSTED CONTACT</p>
              <p>
                Under FINRA Rule 4512 Apex Clearing Corporation is required to
                disclose to you (the customer) that Apex Clearing Corporation or
                an associated person of Apex Clearing Corporation is authorized
                to contact the trusted contact person and disclose information
                about the customer’s account to address possible financial
                exploitation, to confirm the specifics of the customer’s current
                contact information, health status, or the identity of any legal
                guardian, executor, trustee or holder of a power of attorney, or
                as otherwise permitted by FINRA Rule 2165.
              </p>
            </div>

            <div>
              <p className="terms-label">ACH AGREEMENT</p>
              <p>
                If the customer (the “Customer”) opening a brokerage account
                with Apex Clearing Corporation (“Apex”) requests Automated
                Clearinghouse (“ACH”) transactions from Customer’s Account at
                Apex, Customer authorizes Apex to originate or facilitate
                transfer credits/debits to/from Customer’s eligible bank
                account. Transactions sent through the NACHA network will be
                subject to all applicable rules of NACHA and all rules set forth
                in Federal Reserve Operating circulars or other applicable laws
                and regulations. ACH deposits to Customer’s brokerage account
                are provisional. If the beneficiary bank does not receive final
                and complete payment for a payment order transferred through
                ACH, the beneficiary bank is entitled to recover from the
                beneficiary any provisional credit and Apex may charge
                Customer’s account for the transaction amount. Customer
                understands Apex or Customer’s broker or investment advisor may
                not notify Customer of any returned or rejected ACH transfers.
                Customer agrees to hold Apex and Apex’s agents free of liability
                for compliance with these instructions. Customer hereby agrees
                to hold harmless Apex and each of its affiliates, offices,
                directors, employees, and agents against, any claims, judgments,
                expenses, liabilities or costs of defense or settlement relating
                to: (a) any refusal or failure to initiate or honor any credit
                or debit request, by Apex or Customer’s broker or investment
                advisor, whether (i) due to a lack of funds necessary to credit
                Customer’s account; (ii) due to inadvertence, error caused by
                similarity of account holder names or (iii) otherwise provided
                Apex has not acted in bad faith; (b) if the routing number is
                incorrect or the routing number or other information changes at
                another U.S. financial institution or (c) any loss, damage,
                liability or claim arising, directly or indirectly, from any
                error, delay or failure which is caused by circumstances beyond
                Apex’s direct control. To the extent permitted by applicable law
                or regulation, Apex hereby disclaims all warranties, express or
                implied, and in no event shall Apex be liable for any special
                indirect, incidental, or consequential damages whatsoever
                resulting from the ACH electronic service or any ACH
                transactions. Nothing in this herein shall constitute a
                commitment or undertaking by Apex or Customer’s broker or
                investment advisor to effect any ACH transaction or otherwise
                act upon the instructions of Customer or Customer’s broker or
                investment advisor with respect to any account at Apex. This
                authorization shall remain in full force and effect until
                Customer revokes authorization by written notification to
                Customer’s broker or investment advisor that is forwarded to
                Apex. Customer understands that Apex has the right to terminate
                or suspend the ACH agreement at any time and without notice.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const content = this._content();
    return <div>{content}</div>;
  }
}

export default ApexTerms;
