import { beneficiaryConstants } from "./types";
import {
  getAllBeneficiaries,
  updateBeneficiaries as updateBeneficiariesByAccount,
  deleteAllBeneficiaries,
} from "services/beneficiaryService";
import ActionCreator from "utils/actionHandler";

export const getBeneficiaries = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      beneficiaryConstants.GET_BENEFICIARIES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      getAllBeneficiaries(client, accountId)
    );
  };
};

export const updateBeneficiaries = (client, beneficiariesInput) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      beneficiaryConstants.UPDATE_BENEFICIARIES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      updateBeneficiariesByAccount(client, beneficiariesInput)
    );
  };
};

export const deleteBeneficiaries = (client, accountId) => {
  return (dispatch) => {
    const actionCreator = new ActionCreator(
      beneficiaryConstants.DELETE_BENEFICIARIES
    );
    return actionCreator.dispatchFullLifecycle(
      dispatch,
      deleteAllBeneficiaries(client, accountId)
    );
  };
};

export default {
  getBeneficiaries,
  updateBeneficiaries,
  deleteBeneficiaries,
};
