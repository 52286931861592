import React from "react";
import classnames from "classnames";
import { PropTypes } from "prop-types";

import "./StatusBox.scss";

const StatusBox = ({ type, status }) => {
  return (
    <span className={classnames(`status-box ${type}-status-box`)}>
      {status}
    </span>
  );
};

StatusBox.propTypes = {
  type: PropTypes.string,
  status: PropTypes.string,
};

export const statusBoxStatuses = {
  primary: "primary",
  secondary: "secondary",
  success: "success",
  danger: "danger",
  warning: "warning",
  info: "info",
  light: "light",
  dark: "dark",
  complete: "complete",
  pending: "pending",
};

export default StatusBox;
