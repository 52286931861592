import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiAlertCircle } from "react-icons/fi";

import "./InfoTooltip.scss";
const InfoTooltip = ({ tooltipBody, placement = "auto", iconSize = 14 }) => {
  function renderTooltip(props) {
    return (
      <Tooltip className="tooltip" {...props}>
        {tooltipBody}
      </Tooltip>
    );
  }

  return (
    <OverlayTrigger
      className="info-tooltip"
      placement={placement}
      delay={{ show: 250, hide: 200 }}
      overlay={renderTooltip}
      containerPadding={20}
      transition={true}
    >
      <span className="info-icon">
        <FiAlertCircle
          size={iconSize}
          fill={"#FFFFFF"}
          color={"#FFFFFF"}
          stroke={"#0038FF"}
          strokeWidth={"1.5"}
        />
      </span>
    </OverlayTrigger>
  );
};

InfoTooltip.propTypes = {
  tooltipBody: PropTypes.node,
  placement: PropTypes.string,
  iconSize: PropTypes.number,
};

export default InfoTooltip;
