import { wrapError } from "utils/errorHelper";

export default class ActionCreator {
  constructor(type) {
    this.type = type;
  }

  request(data) {
    return {
      type: `${this.type}_REQUEST`,
      data,
    };
  }

  success(data) {
    return {
      type: `${this.type}_SUCCESS`,
      data,
    };
  }

  failure(error) {
    return {
      type: `${this.type}_FAILURE`,
      error: wrapError(error),
    };
  }

  /**
   * Dispatches every action around the service response (promise).
   * @param dispatch
   * @param promise
   * @returns {*}
   */
  dispatchFullLifecycle(dispatch, promise, requestData, callback) {
    dispatch(this.request(requestData));
    return promise
      .then(
        (data) => {
          if (callback) {
            callback(data);
          }
          return dispatch(this.success(data));
        },
        (error) => dispatch(this.failure(error))
      )
      .catch((error) => dispatch(this.failure(error)));
  }
}
