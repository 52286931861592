export const VERIFY_EMAIL = {
  id: "verifyEmail",
  label: "Verify Email",
  stepTime: 1,
};

export const COMPANY_INFO = {
  id: "companyInfo",
  label: "Company Info",
  stepTime: 2,
};

export const BILLING_INFO = {
  id: "billingInfo",
  label: "Billing Info",
  stepTime: 1,
};

export const AGREEMENTS = {
  id: "agreements",
  label: "Agreements",
  stepTime: 2,
};

export const CREATE_PROFILE = {
  id: "createProfile",
  label: "Create Profile",
  stepTime: 2,
};
export const CREATE_ACCOUNT = {
  id: "createAccount",
  label: "Create Account",
  stepTime: 2,
};

export const PERSONAL_INFO = {
  id: "personalInfo",
  label: "Personal Info",
  stepTime: 5,
};

export const DISCLOSURES = {
  id: "disclosures",
  label: "Disclosures",
  stepTime: 3,
};

export const INVESTMENT_PROFILE = {
  id: "investmentProfile",
  label: "Investment Profile",
  stepTime: 5,
};

export const INDIVIDUAL_WAITING_APPROVAL = {
  id: "individualWaitingApproval",
  hidden: true,
};

export const adminSteps = [VERIFY_EMAIL];
export const employerMultiAccountSteps = [COMPANY_INFO, AGREEMENTS];
export const employerMultiWithBillingSteps = [
  COMPANY_INFO,
  BILLING_INFO,
  AGREEMENTS,
];
export const employerOnboardingSteps = [
  CREATE_PROFILE,
  VERIFY_EMAIL,
  COMPANY_INFO,
  AGREEMENTS,
];
export const employerOnboardingWithBillingSteps = [
  CREATE_PROFILE,
  VERIFY_EMAIL,
  COMPANY_INFO,
  BILLING_INFO,
  AGREEMENTS,
];
export const individualOnboardingSteps = [
  CREATE_ACCOUNT,
  VERIFY_EMAIL,
  PERSONAL_INFO,
  DISCLOSURES,
  INVESTMENT_PROFILE,
  AGREEMENTS,
  INDIVIDUAL_WAITING_APPROVAL,
];

export const IndividualMultiAccountSteps = [
  DISCLOSURES,
  INVESTMENT_PROFILE,
  AGREEMENTS,
];

export const IndividualRolloverSameAsCurrent = [AGREEMENTS];

export const ANNOUNCED_ICON = 1;
export const INVITED_EMPLOYEES = 2;
export const LAUNCHED_PLAN = 3;

export const onboardingTask = {
  EMAIL: "Email",
  DECK: "Deck",
};
