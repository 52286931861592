import React from "react";
import PropTypes from "prop-types";
import { map, get, isEmpty, camelCase, lowerCase, isNil } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";
import classnames from "classnames";
import { Modal, Col, Row } from "react-bootstrap";

import Button from "components/Button";
import Alert from "components/Alert";
import SelectContributionBox from "./SelectContributionBox";
import IconSpinner from "components/IconSpinner";

import { formatCurrency } from "utils/number";
import { makeOnboardingRecurringContribution } from "actions/contributionActions";
import { contributionConstants, userConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { getUserState } from "actions/userActions";
import {
  DAILY,
  MONTHLY,
  END_OF_MONTH,
  payrollPeriodsToEnglishMapping,
} from "utils/timeHelper";
import { recommendedContributionType } from "statics/propTypes";
import { getIraAccountIdSelector } from "store/selectors/user";
import { activeBankSelector } from "store/selectors/bank";
import { getDailyToRecurringAmount } from "services/contributionService";
import { formatAmount } from "utils/number";

const Modifier = ({ isSelected, name, label, onSelect }) => {
  return (
    <div
      className={classnames("modifier", {
        selected: isSelected,
      })}
      onClick={() => onSelect(name)}
    >
      {label || name}
    </div>
  );
};

Modifier.propTypes = {
  onSelect: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
};

class SelfEmployedContributionSelection extends React.PureComponent {
  static propTypes = {
    client: PropTypes.object,
    isSubmitting: PropTypes.bool,
    isFetchingCustomAmount: PropTypes.bool,
    makeOnboardingRecurringContribution: PropTypes.func,
    push: PropTypes.func,
    getUserState: PropTypes.func,
    accountId: PropTypes.string,
    error: PropTypes.string,
    bankId: PropTypes.string,
    bankAccountNumber: PropTypes.string,
    dailyMax: PropTypes.number,
    dailyRecommendations: recommendedContributionType,
    schedule: PropTypes.shape({
      frequency: PropTypes.string,
      dayOne: PropTypes.string,
      anchorPayDate: PropTypes.string,
    }),
    editSchedule: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const defaultModifier = DAILY;

    this.state = {
      selectedOption: "recommended",
      selectedCustom: false,
      showCustomBox: false,
      modifier: defaultModifier,
      // Uses an empty string to clear the input between modifier screens
      customAmount: "",
      customAmountError: "",
      isFetchingCustomAmount: false,
      calculatedCustomAmount: null,
    };
  }

  _submitContribution = async (amount, isDailyCustom) => {
    try {
      await this.props.makeOnboardingRecurringContribution(this.props.client, {
        accountId: this.props.accountId,
        dayOne:
          this.props.schedule.dayOne === END_OF_MONTH
            ? 31
            : +this.props.schedule.dayOne,
        frequency: this.props.schedule.frequency,
        anchorPayDate: this.props.schedule.anchorPayDate,
        bankId: this.props.bankId,
        isDailyCustom,
        amount: +amount,
      });
      await this.props.getUserState(this.props.client);
      window.analytics.track("Submitted Self Employed Contribution");
      this.props.push("/dashboard");
    } catch (error) {
      return error;
    }
  };

  _handleSubmit = () => {
    if (this.state.selectedCustom) {
      const customIsValid =
        this.state.customAmount > 0 && !this.state.customAmountError;

      if (customIsValid) {
        const isDailyCustom = this.state.modifier === DAILY;
        this._submitContribution(this.state.customAmount, isDailyCustom);
        return;
      } else {
        return;
      }
    }

    this._submitContribution(
      get(
        this.props[
          `${camelCase(this.props.schedule.frequency)}Recommendations`
        ],
        this.state.selectedOption
      ),
      false
    );
  };

  _updateModifier = (modifier) => {
    this.setState({ modifier, customAmount: "", customAmountError: "" });
  };

  _getSelectBoxes = () => {
    const contributionLimits = this._getContributionLimits();

    const recommendedContributions =
      this.state.modifier === DAILY
        ? this.props.dailyRecommendations
        : this.props[
            `${camelCase(this.props.schedule.frequency)}Recommendations`
          ];

    const selectedRec = get(
      recommendedContributions,
      this.state.selectedOption,
      null
    );

    const modifier =
      this.state.modifier === DAILY
        ? this.state.modifier
        : this.props.schedule.frequency;

    const preferredSelectBox = (
      <SelectContributionBox
        key="preferred"
        amount={recommendedContributions.recommended}
        isRecommended={true}
        showAsMax={recommendedContributions.isMaxContributionOption}
        modifier={modifier}
        isSelected={
          !this.state.selectedCustom &&
          selectedRec === recommendedContributions.recommended
        }
        onSelect={() => this._setSelectedContribution("recommended")}
      />
    );

    const otherOptions = map(recommendedContributions.other, (val, idx) => (
      <SelectContributionBox
        key={val}
        amount={val}
        modifier={modifier}
        isSelected={!this.state.selectedCustom && selectedRec === val}
        onSelect={() => this._setSelectedContribution(`other.${idx}`)}
      />
    ));

    const customInputbox = this.state.showCustomBox ? (
      <SelectContributionBox
        key="custom"
        amount={this.state.customAmount}
        customAmountError={this.state.customAmountError}
        modifier={modifier}
        isCustomAmount={true}
        isSelected={this.state.selectedCustom}
        onSelect={() => this.setState({ selectedCustom: true })}
        setCustomAmount={this._setCustomAmount}
        contributionLimits={contributionLimits}
      />
    ) : null;

    return [preferredSelectBox, ...otherOptions, customInputbox];
  };

  _getContributionLimits = () => {
    const dailyMin = 1;
    const perPayPeriodMin = 25;

    return {
      min: this.state.modifier === DAILY ? dailyMin : perPayPeriodMin,
      max:
        this.state.modifier === DAILY
          ? this.props.dailyMax
          : this.props[`${camelCase(this.props.schedule.frequency)}Max`],
    };
  };

  _setCustomAmount = (amt) => {
    let customAmountError = "";
    const contributionLimits = this._getContributionLimits();

    if (amt < contributionLimits.min) {
      customAmountError = `Custom amount must be greater than or equal to ${formatCurrency(
        contributionLimits.min
      )}`;
    } else if (amt > contributionLimits.max) {
      customAmountError = `Custom amount must be less than or equal to ${formatCurrency(
        contributionLimits.max
      )}`;
    }
    this.setState({
      customAmount: formatAmount(amt),
      customAmountError,
    });
  };

  _setSelectedContribution = (selectedOption) => {
    this.setState({
      selectedCustom: false,
      selectedOption,
      customAmount: "",
      customAmountError: "",
    });
  };

  _toggleConfirmationModal = () => {
    this.setState((state) => {
      return {
        showConfirmationModal: !state.showConfirmationModal,
      };
    });
  };

  _buildConfirmationSentence = () => {
    const { frequency, anchorPayDate } = this.props.schedule;
    let startSentence;

    let amount;

    if (this.state.selectedCustom) {
      amount =
        this.state.modifier === DAILY
          ? this.state.calculatedCustomAmount
          : this.state.customAmount;
    } else {
      amount = get(
        this.props[`${camelCase(frequency)}Recommendations`],
        this.state.selectedOption
      );
    }

    let firstPaymentDate = anchorPayDate;
    if (frequency === MONTHLY) {
      const dayOne =
        this.props.schedule.dayOne === "End of Month"
          ? 31
          : this.props.schedule.dayOne;

      /* The monthly scheduling logic takes a bit to calculate, basically we ask the user for the day of month they want to process
         so to figure out next processing date we have to figure out if that day already passed this month if not just use current month as processing date
         otherwise we have to figure out which date it will happen next month. The logic also accounts for variable end of month dates using moment in a way that 
         ensures it will always default to last day of month if day number is outside of that months range.
      */
      let anchorDate = moment(anchorPayDate);
      // this format is important because it ensures that if we are in February that it will convert Feb 31 into Feb 28 vs March 3rd
      const currentMonthProcessingDate = moment()
        .date(dayOne)
        .month(anchorDate.month())
        .year(anchorDate.year());
      const willProcessThisMonth = anchorDate.isBefore(
        currentMonthProcessingDate.startOf("day")
      );

      let nextProcessingDate = currentMonthProcessingDate;

      if (!willProcessThisMonth) {
        const nextMonth = currentMonthProcessingDate.add(1, "months");
        nextProcessingDate = moment()
          .date(dayOne)
          .month(nextMonth.month())
          .year(nextMonth.year());
      }

      firstPaymentDate = nextProcessingDate.format("YYYY-MM-DD");
    }
    startSentence = `starting ${firstPaymentDate}`;
    return (
      <p className="modal-text">
        By clicking continue, I authorize Icon Financial Services to debit my
        account ending in {this.props.bankAccountNumber} the amount of{" "}
        {formatCurrency(amount)}{" "}
        {lowerCase(payrollPeriodsToEnglishMapping[frequency])} {startSentence}.
        Scheduled payments may be cancelled up to 1 day in advance.
      </p>
    );
  };

  _getConfirmationModal = () => {
    return (
      <Modal
        centered
        show={true}
        onHide={this._toggleConfirmationModal}
        className="contribution-modal"
      >
        <Modal.Header className="header" closeButton={false}>
          <Modal.Title className="title" id="contained-modal-title-vcenter">
            Confirm recurring contribution
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this._buildConfirmationSentence()}
          {this.props.error && <Alert type="error" msg={this.props.error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            btnLabel="Cancel"
            name="cancel"
            color="cancel"
            onClick={this._toggleConfirmationModal}
          />
          <Button
            btnLabel="submit"
            name="submit"
            withArrow
            loading={this.props.isSubmitting}
            onClick={this._handleSubmit}
          />
        </Modal.Footer>
      </Modal>
    );
  };

  _getRecurringAmount = async () => {
    this.setState({ isFetchingCustomAmount: true });
    const customAmount = await getDailyToRecurringAmount(this.props.client, {
      frequency: this.props.schedule.frequency,
      amount: +this.state.customAmount,
    });
    this.setState({
      isFetchingCustomAmount: false,
      calculatedCustomAmount: customAmount,
    });

    this._toggleConfirmationModal();
  };

  render() {
    if (
      isEmpty(
        this.props[`${camelCase(this.props.schedule.frequency)}Recommendations`]
      )
    ) {
      return <IconSpinner centered />;
    }

    const isDailyCustom =
      this.state.modifier === DAILY && this.state.selectedCustom;
    const onButtonClick = isDailyCustom
      ? this._getRecurringAmount
      : this._toggleConfirmationModal;
    return (
      <div>
        {this.state.showConfirmationModal && this._getConfirmationModal()}
        <div>
          <div className="modifier-picker">
            <Modifier
              name={DAILY}
              onSelect={this._updateModifier}
              isSelected={this.state.modifier === DAILY}
            />
            <Modifier
              name={this.props.schedule.frequency}
              label={
                payrollPeriodsToEnglishMapping[this.props.schedule.frequency]
              }
              onSelect={this._updateModifier}
              isSelected={this.state.modifier === this.props.schedule.frequency}
            />
          </div>
        </div>
        <div className="select-contributions-container">
          <Row>
            <Col> {this._getSelectBoxes()}</Col>
          </Row>
        </div>

        <div className="submit-row">
          {!this.state.showCustomBox && (
            <span
              onClick={() =>
                this.setState({ showCustomBox: true, selectedCustom: true })
              }
              className="icon-link"
            >
              Enter Custom Amount
            </span>
          )}
          <Button
            size="sm"
            color="cancel"
            name="cancel"
            onClick={this.props.editSchedule}
            btnLabel="back"
          />
          <Button
            size="sm"
            name="submit"
            onClick={onButtonClick}
            btnLabel="Confirm"
            withArrow={isDailyCustom}
            loading={this.state.isFetchingCustomAmount}
            disabled={
              this.state.selectedCustom &&
              (!isEmpty(this.state.customAmountError) ||
                isNil(this.state.customAmount) ||
                this.state.customAmount === "")
            }
          />
        </div>
      </div>
    );
  }
}

const actions = [
  contributionConstants.ONBOARDING_RECURRING_CONTRIBUTION,
  contributionConstants.GET_CONTRIBUTION_RECOMMENDATIONS,
  userConstants.USER_STATE,
];
const errorSelector = createErrorSelector(actions);
const isSubmitting = createLoadingSelector(actions);

const mapStateToProps = (state) => {
  const {
    dailyMax,
    weeklyMax,
    monthlyMax,
    everyOtherWeekMax,
    recommendations,
  } = state.contribution;
  const bankAccount = activeBankSelector(state);

  return {
    dailyMax,
    weeklyMax,
    monthlyMax,
    everyOtherWeekMax,
    dailyRecommendations: recommendations.daily,
    weeklyRecommendations: recommendations.weekly,
    everyOtherWeekRecommendations: recommendations.everyOtherWeek,
    monthlyRecommendations: recommendations.monthly,
    error: errorSelector(state),
    isSubmitting: isSubmitting(state),
    accountId: getIraAccountIdSelector(state),
    bankId: get(bankAccount, "id"),
    bankAccountNumber: get(bankAccount, "accountId"),
  };
};

const mapDispatchToProps = {
  makeOnboardingRecurringContribution,
  getUserState,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(SelfEmployedContributionSelection));
