import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import queryString from "query-string";
import { FaExclamationTriangle } from "react-icons/fa";

import { createLoadingSelector, createErrorSelector } from "store/selectors";
import { userConstants } from "actions/types";
import { verifyEmailChangeRequest } from "actions/userActions";

import SupportEmailLink from "components/SupportEmailLink";
import IconSpinner from "components/IconSpinner";

class EmailVerification extends React.PureComponent {
  static propTypes = {
    isVerifying: PropTypes.bool,
    verifyEmailChangeRequest: PropTypes.func,
    error: PropTypes.string,
    verificationId: PropTypes.string,
    client: PropTypes.object,
  };

  componentDidMount() {
    this.props.verifyEmailChangeRequest(
      this.props.client,
      this.props.verificationId
    );
    window.analytics.page("Individual Email Verification");
  }

  _getErrorContent = () => {
    return (
      <>
        <Card.Title>Email verification failed.</Card.Title>
        <Card.Text>
          We were unable to verify your email. If you believe this message to be
          an error please reach out to <SupportEmailLink />.
        </Card.Text>
      </>
    );
  };

  _getSuccessContent = () => {
    return (
      <>
        <Card.Title>Email verification complete.</Card.Title>
        <Card.Text>
          Your email was successfully verified and may now be used to login as
          well as receive communications from Icon.{" "}
          <a href="/dashboard" className="link">
            Please login
          </a>
          .
        </Card.Text>
      </>
    );
  };
  render() {
    let content;
    if (this.props.isVerifying) {
      content = <IconSpinner centered />;
    } else if (this.props.error) {
      content = this._getErrorContent();
    } else {
      content = this._getSuccessContent();
    }
    return (
      <Card className="text-center attention-card">
        {this.props.error && (
          <div className="alert-icon-container">
            <FaExclamationTriangle color="#DB4163" size={42} />
          </div>
        )}
        <Card.Body>{content}</Card.Body>
      </Card>
    );
  }
}

const actions = [userConstants.VERIFY_EMAIL_CHANGE];

const isVerifying = createLoadingSelector(actions);
const getErrors = createErrorSelector(actions);
const mapStateToProps = (state, ownProps) => {
  return {
    isVerifying: isVerifying(state),
    error: getErrors(state),
    verificationId: queryString.parse(ownProps.location.search).verificationId,
  };
};

const mapDispatchToProps = {
  verifyEmailChangeRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmailVerification));
