import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Card, Col, Form, Row } from "react-bootstrap";
import { filter, get, groupBy, keyBy, map, size } from "lodash";
import { FiAlertCircle, FiGrid } from "react-icons/fi";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import {
  employeeInvitationType,
  groupType,
  payrollTypes,
} from "statics/propTypes";
import { toast } from "react-toastify";
import { payrollPeriodsToEnglishMapping } from "utils/timeHelper";
import {
  hasEnabled360PayrollIntegration,
  hasEnabledFinchFeature,
} from "store/selectors/employer";

import {
  currentPayrolls,
  getAllEmployerGroups,
  getEmployeeInvitations,
  getEmployeeRoster,
  inviteEmployees,
  processRoster,
  resendInvitations,
  stageEmployeeInvites,
  updateProgressiveOnboarding,
} from "actions/employerActions";
import Button from "components/Button";
import Alert from "components/Alert";
import StatusBox from "components/StatusBox";
import moment from "moment";

import "./LaunchPlan.scss";
import { progressiveOnboardingEvents } from "statics/states";

class LaunchPlan extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    groups: PropTypes.arrayOf(groupType),
    groupIds: PropTypes.string,
    inviteEmployees: PropTypes.func,
    getEmployeeInvitations: PropTypes.func,
    stageEmployeeInvites: PropTypes.bool,
    employeeInvitations: PropTypes.objectOf(employeeInvitationType),
    getAllEmployerGroups: PropTypes.func,
    currentPayrolls: PropTypes.func,
    payrollsById: PropTypes.objectOf(payrollTypes),
    client: PropTypes.object,

    error: PropTypes.string,
    isInviting: PropTypes.bool,
    values: PropTypes.object,
    resendInvitations: PropTypes.func,
    updateProgressiveOnboarding: PropTypes.func,
    getEmployeeRoster: PropTypes.func,
    onCancel: PropTypes.func,
    hasEnabledPayrollIntegration: PropTypes.bool,
    has360PayrollIntegration: PropTypes.bool,
    processRoster: PropTypes.func,
    onSuccess: PropTypes.func,
    employeeRosterCount: PropTypes.number,
    revokedInvitationIds: PropTypes.arrayOf(PropTypes.string),

    group: groupType,
  };

  static defaultProps = {
    revokedInvitationIds: [],
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.currentPayrolls(this.props.client);
    this.props.getEmployeeInvitations(this.props.client);
    if (this.props.hasEnabledPayrollIntegration) {
      this.props.getEmployeeRoster(this.props.client, { offset: 0, limit: 0 });
    }
  }

  _handleOnClick = () => {
    const groupIds = map(this.props.groups, "id");

    if (this.props.hasEnabledPayrollIntegration) {
      return this.props
        .updateProgressiveOnboarding(
          this.props.client,
          progressiveOnboardingEvents.LaunchPlan
        )
        .then(() => {
          return this.props.processRoster(this.props.client);
        })
        .then(() => {
          if (!this.props.error) {
            this.props.onSuccess();
            toast.success("Successfully launched plan.");
          }
        });
    }

    this.props
      .resendInvitations(this.props.client, {
        groupIds,
        excludeInvitationIds: this.props.revokedInvitationIds,
      })
      .then(() => {
        if (!this.props.error) {
          return this.props.updateProgressiveOnboarding(
            this.props.client,
            progressiveOnboardingEvents.LaunchPlan
          );
        }
      })
      .then(() => {
        if (!this.props.error) {
          this.props.onSuccess();
          toast.success("Successfully launched plan.");
        }
      });
  };

  _getGroupInfo = () => {
    const commonDateFormat = "MMM Do YYYY";

    const employeeInvitationsByGroupId = groupBy(
      this.props.employeeInvitations,
      "groupId"
    );

    return map(this.props.groups, (group) => {
      const groupName = group.name;
      const planType = group.planType;
      const payrollFrequency = group.payrollFrequency;
      const groupId = group.id;

      const payrollFrequencyLabel =
        payrollPeriodsToEnglishMapping[payrollFrequency];

      const numOfInvites = size(employeeInvitationsByGroupId[groupId]);

      const processingDate = get(
        this.props.payrollsById[groupId],
        "payrollProcessingDate"
      );

      return (
        <>
          <div className="invite-box">
            <div className="invite-group">
              <div className="launch-circle">
                <span className="launch-icon">
                  <FiGrid color="white" stroke="#60A4BF" size={16} />
                </span>
              </div>

              <div className="group">
                <div className="group-details">
                  <p className="group-name">{groupName} </p>
                </div>
              </div>
            </div>

            <span className="type">
              <StatusBox type="plan-type" status={planType} />
            </span>
          </div>

          <span className="scheudle">
            <span className="info">
              <p className="scheudle-info">Frequency:</p>
              <p className="scheudle-info">{payrollFrequencyLabel}</p>
            </span>

            <span className="info">
              <p className="scheudle-info">Processing Date:</p>
              <p className="scheudle-info">
                {moment(processingDate).format(commonDateFormat)}
              </p>
            </span>
            <span className="info">
              <p className="scheudle-info">Employee Invitations: </p>

              <p className="scheudle-info">
                {this.props.hasEnabledPayrollIntegration
                  ? this.props.employeeRosterCount
                  : numOfInvites}
              </p>
            </span>
          </span>
        </>
      );
    });
  };

  _renderForm() {
    return (
      <div className="launch-plan">
        <div className="download-employee-template">
          <div className="main-content">
            <Row>
              <Col>
                <Card>
                  <Card.Title>
                    <div className="launch">
                      <p className="launch-header">
                        Invite your team{" "}
                        <img
                          alt="Icon"
                          height={"20"}
                          src="/assets/congrats.svg"
                        />
                      </p>
                      <p className="launch-subtext">
                        You have created a new invite list. Invite your team to
                        start helping them save for retirement.
                      </p>
                    </div>
                  </Card.Title>
                  <Card.Body>{this._getGroupInfo()}</Card.Body>
                  <Form.Group as={Col} className="send-invite">
                    {this.props.error && (
                      <Alert type="error" msg={this.props.error} />
                    )}
                    {this.state.showAcceptedError && (
                      <Alert
                        type="error"
                        msg={
                          "Employee has already been invited and started enrollment. Check the Dashboard Enrolling list. "
                        }
                      />
                    )}

                    <div className="submit-row">
                      <span className="cancel-btn">
                        <Button
                          btnLabel="Cancel"
                          name="cancel"
                          color="cancel"
                          onClick={this.props.onCancel}
                        />
                      </span>
                      <Button
                        btnLabel="Launch Plan"
                        name="submit"
                        withArrow={true}
                        onClick={this._handleOnClick}
                        loading={this.props.isInviting}
                      />
                    </div>
                  </Form.Group>
                  {this.props.has360PayrollIntegration && (
                    <Form.Group as={Col}>
                      <div className="info-box">
                        <span className="info-icon-container">
                          <FiAlertCircle
                            size={16}
                            fill={"#FFFFFF"}
                            color={"#FFFFFF"}
                            stroke={"#01a3b0"}
                            strokeWidth={"1.5"}
                          />
                        </span>
                        <span className="info-text">
                          After launching your plan, invitations will be sent
                          out within the next hour.
                        </span>
                      </div>
                    </Form.Group>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const content = this._renderForm();

    return (
      <div className="add-employee-form" style={{ marginBottom: 8 }}>
        {content}
      </div>
    );
  }
}

const actions = [
  employerConstants.GET_EMPLOYER_GROUPS,
  employerConstants.STAGE_EMPLOYEE_INVITE,
  employerConstants.PROCESS_ROSTER,
  employerConstants.GET_EMPLOYEE_ROSTER,
  employerConstants.RESEND_INVITES_EMPLOYEE,
];

const isInvitingSelector = createLoadingSelector(actions);

const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const revokedInvitations = filter(state.employer.employeeInvitations, {
    status: "REVOKED",
  });
  const revokedInvitationIds = map(revokedInvitations, "id");

  const newRosterInvites = filter(
    state.employer.paginatedEmployeeRoster,
    (emp) => emp.linkStatus === "UNKNOWN"
  );

  return {
    groups: state.employer.groups,
    error: errorSelector(state),
    isInviting: isInvitingSelector(state),
    hasEnabledFinch: hasEnabledFinchFeature(state),
    employeeRosterCount: size(newRosterInvites),
    employeeInvitations: Object.values(
      state.employer.employeeInvitations
    ).filter((invite) => invite.status === "NEW"),
    revokedInvitationIds,
    groupIds: Object.values(state.employer.employeeInvitations).filter(
      (invite) => invite.groupId
    ),

    payrollsById: keyBy(state.employer.currentPayrolls, "groupId"),
    has360PayrollIntegration: hasEnabled360PayrollIntegration(state),
    hasEnabledPayrollIntegration:
      hasEnabledFinchFeature(state) || hasEnabled360PayrollIntegration(state),
  };
};

const mapDispatchToProps = {
  push,
  inviteEmployees,
  getEmployeeInvitations,
  stageEmployeeInvites,
  resendInvitations,
  updateProgressiveOnboarding,
  getAllEmployerGroups,
  processRoster,
  getEmployeeRoster,
  currentPayrolls,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(LaunchPlan));
