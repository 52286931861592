import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { get } from "lodash";
import {
  getExpiringEmployerBanks,
  getReauthorizationLinkToken,
  getIndividualReauthorizationLinkToken,
  getExpiringIndividualBanks,
  updateLinkToken,
} from "actions/bankActions";
import { bankConstants } from "actions/types";
import { createLoadingSelector } from "store/selectors";
import { isEmployerSelector } from "store/selectors/user";

import PlaidLinkTokenStorage from "utils/PlaidLinkTokenStorage";
import Alert from "components/Alert";
import Button from "components/Button";

class ReAuthPlaidLink extends Component {
  static propTypes = {
    updateLinkToken: PropTypes.func,
    getReauthorizationLinkToken: PropTypes.func.isRequired,
    getIndividualReauthorizationLinkToken: PropTypes.func.isRequired,
    getExpiringEmployerBanks: PropTypes.func,
    getExpiringIndividualBanks: PropTypes.func,

    client: PropTypes.object.isRequired,
    label: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    render: PropTypes.func,
    onSuccess: PropTypes.func,
    onExit: PropTypes.func,
    userId: PropTypes.string,
    bank: PropTypes.object,
    userLegalName: PropTypes.string,
    isEmployer: PropTypes.bool,
  };

  static defaultProps = {
    style: {
      padding: "6px 4px",
      outline: "none",
      background: "#FFFFFF",
      border: "2px solid #F1F1F1",
      borderRadius: "4px",
    },
  };

  _isMounted = false;
  plaidHandler = null;

  constructor(props) {
    super(props);
    this.state = {
      linkLoaded: false,
      error: null,
      isOpen: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (!this.state.isOpen) {
      PlaidLinkTokenStorage.clearTokenContext(this.props.userId);
    }
  }

  onExit = async (err, metadata) => {
    if (err != null && err.error_code === "INVALID_LINK_TOKEN") {
      this.plaidHandler.destroy();
      await this.createPlaidLink();
    }

    if (err != null) {
      this.setState({ error: "Failed to initialize plaid" });
      console.error({ message: "Failed to initialize plaid", err });
    }
    PlaidLinkTokenStorage.clearTokenContext(this.props.userId);
    this.props.onExit && this.props.onExit(metadata);
  };

  createReAuthPlaidLink = async () => {
    try {
      const accountId = get(this.props.bank, "id");

      let reAuthLinkToken;

      if (this.props.isEmployer) {
        reAuthLinkToken = await this.props.getReauthorizationLinkToken(
          this.props.client,
          accountId
        );
      } else {
        reAuthLinkToken =
          await this.props.getIndividualReauthorizationLinkToken(
            this.props.client,
            accountId
          );
      }

      const token = get(reAuthLinkToken, "data.linkToken");

      if (!token) {
        throw new Error("Link token is missing.");
      }

      const plaidConfig = {
        token,
        onExit: (err, metadata) => this.onExit(err, metadata),
        onSuccess: async (token, metadata) => {
          PlaidLinkTokenStorage.clearTokenContext(this.props.userId);

          let result;
          await this.props.updateLinkToken(this.props.client, accountId);

          if (this.props.isEmployer) {
            result = await this.props.getExpiringEmployerBanks(
              this.props.client
            );
          } else {
            result = await this.props.getExpiringIndividualBanks(
              this.props.client
            );
          }

          this.props.onSuccess(token, metadata, this.props.userLegalName);

          return result;
        },
      };

      this.plaidHandler = window.Plaid.create(plaidConfig);
      if (this._isMounted) {
        this.setState({ linkLoaded: true });
      }
    } catch (error) {
      this.setState({
        error: error.message || "Failed to load bank information.",
      });
      console.error("Error in createReAuthPlaidLink:", error);
      throw error;
    }
  };

  handleOnClick = async () => {
    await this.createReAuthPlaidLink();
    if (this.state.linkLoaded) {
      this.plaidHandler.open();
      this.setState({ isOpen: true });
    }
  };

  render() {
    return (
      <>
        {(this.props.render && this.props.render(this)) || (
          <Button
            name="submit"
            color="action"
            size="sm"
            disabled={this.state.linkLoaded}
            onClick={this.handleOnClick}
            btnLabel="Authenticate Bank"
            loading={this.props.isFetching}
            withArrow
          />
        )}
        {this.state.error && <Alert msg={this.state.error} type="error" />}
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([
  bankConstants.GET_REAUTHORIZATION_LINK_TOKEN,
  bankConstants.GET_EXPIRING_EMPLOYER_BANKS,
  bankConstants.GET_INDIVIDUAL_REAUTHORIZATION_LINK_TOKEN,
  bankConstants.GET_EXPIRING_INDIVIDUAL_BANKS,
  bankConstants.UPDATE_LINK_TOKEN,
]);

const mapStateToProps = (state) => {
  const userLegalName = `${state.user.profile.firstName} ${state.user.profile.lastName}`;
  return {
    userLegalName,
    userId: state.user.id,
    isFetching: loadingSelector(state),
    isEmployer: isEmployerSelector(state),
  };
};

const mapDispatchToProps = {
  getExpiringEmployerBanks,
  getReauthorizationLinkToken,
  getIndividualReauthorizationLinkToken,
  getExpiringIndividualBanks,
  updateLinkToken,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ReAuthPlaidLink));
