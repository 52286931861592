import React from "react";
import PropTypes from "prop-types";
import { filter, get, groupBy, isEmpty, map, size } from "lodash";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Carousel, Col, Form } from "react-bootstrap";
import Button from "components/Button";
import IndividualDocUploader, { docTypes } from "./IndividualDocUploader";
import Alert from "components/Alert";
import SupportEmailLink from "components/SupportEmailLink";
import IconSpinner from "components/IconSpinner";

import {
  clearUploadedDocuments,
  getNeededYellowPathDocs,
  getUserState,
  submitYellowPathDocs,
} from "actions/userActions";
import { createLoadingSelector } from "store/selectors";
import { userConstants } from "actions/types";
import { ID_DOCUMENT } from "statics/docTypes";
import { scrollToTop } from "utils/dom";

import "./IndividualDocUploader.scss";
import StepInfo from "components/StepInfo";

const stepToLabels = {
  1: "Upload Primary document type.",
  2: "Upload Secondary document type.",
};

class IndividualDocsRequired extends React.PureComponent {
  static propTypes = {
    client: PropTypes.shape({}),
    documents: PropTypes.array,
    getUserState: PropTypes.func,
    getNeededYellowPathDocs: PropTypes.func,
    submitYellowPathDocs: PropTypes.func,
    clearUploadedDocuments: PropTypes.func,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    isGettingNeededDocs: PropTypes.bool,
    primaryNeededYellowPathDocs: PropTypes.array,
    secondaryNeededYellowPathDocs: PropTypes.array,
    tags: PropTypes.array,
    userState: PropTypes.string,
    documentType: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      primaryDocType: "",
      secondaryDocType: "",
      currentStep: 0,
    };
  }

  componentDidMount() {
    scrollToTop();
    window.analytics.page("Individual Documents Required");
    this.props.getNeededYellowPathDocs(this.props.client).then(() => {
      window.analytics.track("Individual Documents Displayed");
    });
  }

  _handlePrimaryChange = (e) => {
    this.setState({
      primaryDocType: e.target.value,
    });
  };

  _handleSecondaryChange = (e) => {
    this.setState({
      secondaryDocType: e.target.value,
    });
  };

  _onSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submitting: true,
    });

    const documents = map(this.props.documents, (doc) => {
      return {
        type: ID_DOCUMENT,
        url: doc.docUrl,
        contentType: doc.contentType,
        name: doc.name,
        metadata: { subType: doc.subDocType },
      };
    });

    this.props.submitYellowPathDocs(this.props.client, documents).then(() => {
      this.props.getUserState(this.props.client);
    });
    window.analytics.track("Submitted Individual Documents Required");
  };

  _documentRequirments = () => {
    return (
      <ul className="steps">
        <li>
          Clear photos and PDFs are fine; we cannot accept screenshots or scans.
        </li>
        <li>
          All documents must be current. IDs cannot be expired, and monthly
          bills or statements should have been issued within the past 60 days.
        </li>
        <li>
          We need to see all four corners of the document, and the information
          we need to verify must be clearly visible.
        </li>
        <li>
          If the document has a signature requirement, then it must be
          hand-signed. We cannot accept electronic signatures (with the
          exception of leases, where electronic signatures are acceptable).
        </li>
        <li>
          Government-issued documents (such as a state-issued ID or passports)
          must be in color. We can accept black-and-white photos or PDFs of
          other documents, such as utility bills.
        </li>
      </ul>
    );
  };

  _advanceCarousel = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    });
  };

  docUploader = (
    docType,
    neededDocs,
    handleChange,
    showDocTypeSelector = true,
    isFrontUploader,
    isBackUploader,
    isSecondaryUploader
  ) => {
    const label = isSecondaryUploader
      ? "Now we need a secondary supporting documentation, please select one of the following:"
      : "Please upload an image of one of the following primary supporting documents:";
    return (
      <article className="col-form" style={{ paddingBottom: 0 }}>
        <Col as={Col} sm={8} style={{ paddingLeft: 0, paddingTop: 10 }}>
          <article>
            <Form.Label>Document Requirements</Form.Label>
            <p className="processing-steps">{this._documentRequirments()}</p>
          </article>
        </Col>
        {showDocTypeSelector && (
          <>
            <article className="text-cell">
              <Form.Label>{label}</Form.Label>
            </article>
            <Form.Group
              as={Col}
              sm={6}
              controlId="formBasicSize"
              style={{ paddingLeft: 0, paddingBottom: 20 }}
            >
              <Form.Control
                as="select"
                name="documentType"
                defaultValue={""}
                value={docType}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Document Type
                </option>
                {map(neededDocs, (doc) => {
                  return (
                    <option value={doc}>{docTypes[doc].description}</option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            {docType === "SELFIE" && (
              <Form.Label className="selfie">
                The selfie should be an image of your face with the ID uploaded
                above. Please make sure the ID is legible and all four corners
                of the ID are visible.
              </Form.Label>
            )}
          </>
        )}
        <Form.Row>
          {!!docType && (
            <>
              <IndividualDocUploader
                key={docType}
                idDocType={docType}
                isFrontUploader={isFrontUploader}
                isBackUploader={isBackUploader}
              />
            </>
          )}
        </Form.Row>
      </article>
    );
  };

  _calcContinueDisabled = () => {
    const numOfSubmittedDocsByType = groupBy(
      this.props.documents,
      "subDocType"
    );

    const numberOfPrimaryDocsSubmitted = size(
      numOfSubmittedDocsByType[this.state.primaryDocType]
    );
    const numberOfSecondaryDocsSubmitted = size(
      numOfSubmittedDocsByType[this.state.secondaryDocType]
    );

    const totalDocsSubmitted =
      numberOfPrimaryDocsSubmitted + numberOfSecondaryDocsSubmitted;

    // if each step is always a single upload then we know that the continue/submit button should only be enabled if the total docs submitted is equal to the current step + 1 (since the current step is 0 indexed)
    return totalDocsSubmitted < this.state.currentStep + 1;
  };

  _calcShowSubmitButton = () => {
    const primaryDocType = this.state.primaryDocType;
    const secondaryDocType = this.state.secondaryDocType;

    const needsSecondary = !isEmpty(this.props.secondaryNeededYellowPathDocs);
    const numOfNeededPrimaryDocs = get(
      docTypes,
      [primaryDocType, "numDocs"],
      0
    );
    // we wont know the amount of secondary docs needed but we can assume at least 1 for calculating as a default value if needsSecondary is true
    const numOfNeededSecondaryDocs = get(
      docTypes,
      [secondaryDocType, "numDocs"],
      needsSecondary ? 1 : 0
    );
    return (
      numOfNeededPrimaryDocs + numOfNeededSecondaryDocs ===
      this.state.currentStep + 1
    );
  };

  _getSteps = () => {
    const requiresSecondaryDoc = !isEmpty(
      this.props.secondaryNeededYellowPathDocs
    );

    const steps = [
      {
        index: 1,
        label: "Primary Document",
      },
    ];

    if (requiresSecondaryDoc) {
      steps.push({
        index: 2,
        label: "Secondary Document",
      });
    }

    return steps;
  };

  _getActiveflowStep = () => {
    const numOfSteps = size(this._getSteps());
    const { currentStep } = this.state;
    // if only primary doc needed we just always return 1 as the active step or if current step is 0
    if (numOfSteps === 1 || currentStep === 0) {
      return 1;
    }

    const numberOfPrimaryDocsNeeded =
      docTypes[this.state.primaryDocType].numDocs;

    if (currentStep === 1 && numberOfPrimaryDocsNeeded === 2) {
      return 1;
    }

    return 2;
  };

  _resetForm = () => {
    this.setState({
      currentStep: 0,
      primaryDocType: "",
      secondaryDocType: "",
    });

    this.props.clearUploadedDocuments();
  };

  render() {
    const neededDocs = this.props.primaryNeededYellowPathDocs;
    const docType = this.state.primaryDocType;
    const secondaryDocType = this.state.secondaryDocType;
    const handleChange = this._handlePrimaryChange;
    const showSubmitButton = this._calcShowSubmitButton();
    const activeFlowStep = this._getActiveflowStep();
    const showResetButton = this.state.currentStep > 0;
    const showButtonSection =
      (activeFlowStep === 1 && !!docType) ||
      (activeFlowStep === 2 && !!secondaryDocType);

    const steps = this._getSteps();
    return (
      <div id={"individual-docs-required"}>
        {this.props.isGettingNeededDocs && <IconSpinner centered />}
        {!this.props.isGettingNeededDocs && (
          <Form noValidate>
            <div className="mega-container">
              <div
                className="step-container is-active"
                data-circle-percent="100"
              >
                <section
                  className="page-title-wrap"
                  style={{ marginBottom: 10 }}
                >
                  <article className="text-cell">
                    <h1 className="page-title">
                      {stepToLabels[activeFlowStep]}
                    </h1>
                    <p className="page-subtext">
                      In order to open your IRA we need some additional
                      documentation that allows us to verify your identity. If
                      you have any questions please feel free to reach out to{" "}
                      <SupportEmailLink />
                      .
                      <br />
                    </p>
                    {this.props.userState ===
                      "IndividualApplicationRequiresAdditionalDocumentationForAppeal" && (
                      <>
                        <br />
                        <p>
                          <strong>Note:</strong> Before uploading any documents,
                          make sure they are not blurry and show the entire
                          document.
                        </p>
                      </>
                    )}
                    <StepInfo steps={steps} activeStepNumber={activeFlowStep} />
                  </article>
                </section>
                <div>
                  <Carousel
                    interval={null}
                    controls={null}
                    touch={false} // we don't want the questions to change on swipe left/right
                    activeIndex={this.state.currentStep}
                    onSelect={(currentStep) => this.setState({ currentStep })}
                    slide={false}
                  >
                    <Carousel.Item>
                      {this.docUploader(
                        docType,
                        neededDocs,
                        handleChange,
                        true,
                        docType && docTypes[docType].numDocs === 2
                      )}
                    </Carousel.Item>
                    {docType && docTypes[docType].numDocs === 2 && (
                      <Carousel.Item>
                        {this.docUploader(
                          docType,
                          neededDocs,
                          handleChange,
                          false,
                          false,
                          true
                        )}
                      </Carousel.Item>
                    )}
                    <Carousel.Item>
                      {this.docUploader(
                        secondaryDocType,
                        this.props.secondaryNeededYellowPathDocs,
                        this._handleSecondaryChange,
                        true,
                        secondaryDocType &&
                          docTypes[secondaryDocType].numDocs === 2,
                        false,
                        true
                      )}
                    </Carousel.Item>
                    {secondaryDocType &&
                      docTypes[secondaryDocType].numDocs === 2 && (
                        <Carousel.Item>
                          {this.docUploader(
                            secondaryDocType,
                            this.props.secondaryNeededYellowPathDocs,
                            this._handleSecondaryChange,
                            false,
                            false,
                            true,
                            true
                          )}
                        </Carousel.Item>
                      )}
                  </Carousel>
                  {showButtonSection && (
                    <section className="col-md-6" style={{ paddingTop: 10 }}>
                      <article className="col-form">
                        <div className="submit-row" style={{ paddingTop: 0 }}>
                          {this.props.error && (
                            <Alert type="error" msg={this.props.error} />
                          )}
                          {showResetButton && (
                            <Button
                              type="button"
                              name="action"
                              color={"action"}
                              onClick={this._resetForm}
                              btnLabel={"Restart"}
                            />
                          )}
                          {showSubmitButton && (
                            <Button
                              name="submit"
                              withArrow={true}
                              onClick={this._onSubmit}
                              loading={this.props.isFetching}
                              disabled={this._calcContinueDisabled()}
                              btnLabel={"Submit"}
                            />
                          )}

                          {!showSubmitButton && (
                            <Button
                              name="submit"
                              disabled={this._calcContinueDisabled()}
                              withArrow={false}
                              type={"button"}
                              btnLabel="Continue"
                              onClick={this._advanceCarousel}
                            />
                          )}
                        </div>
                      </article>
                    </section>
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </div>
    );
  }
}

const loadingSelectorOnSubmit = createLoadingSelector(
  userConstants.USER_SUBMIT_YELLOWPATH_DOCS
);

const loadingSelectorForDocs = createLoadingSelector(
  userConstants.USER_NEEDED_YELLOWPATH_DOCS
);

const mapStateToProps = (state, ownProps) => {
  const userState = state.user.userState.state;

  return {
    userState,
    isFetching: loadingSelectorOnSubmit(state),
    isGettingNeededDocs: loadingSelectorForDocs(state),
    documents: filter(state.userDocuments.documents, { docType: ID_DOCUMENT }),
    primaryNeededYellowPathDocs: state.user.primaryNeededYellowPathDocs,
    secondaryNeededYellowPathDocs: state.user.secondaryNeededYellowPathDocs,
    tags: state.user.tags,
    ownProps,
  };
};
const mapDispatchToProps = {
  submitYellowPathDocs,
  getUserState,
  getNeededYellowPathDocs,
  clearUploadedDocuments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualDocsRequired));
