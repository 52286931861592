import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { get } from "lodash";
import { createErrorSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import {
  getEmployeeDocumentUploadJobs,
  updateProgressiveOnboarding,
} from "actions/employerActions";
import { Card, Col, Row } from "react-bootstrap";
import { hasFullyOnboarded } from "store/selectors/employer";
import LottieAnimation from "components/LottieAnimation";

import Alert from "components/Alert";

import "./Employees.scss";
import PromisePoller from "components/PromisePoller";
import { progressiveOnboardingEvents } from "statics/states";

class UploadProcessing extends React.Component {
  static propTypes = {
    client: PropTypes.object,
    push: PropTypes.func.isRequired,
    error: PropTypes.string,
    getEmployeeDocumentUploadJobs: PropTypes.func,
    onFinished: PropTypes.func,
    companyId: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    hasFullyOnboarded: PropTypes.bool,
    updateProgressiveOnboarding: PropTypes.func,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
  };

  static defaultProps = {
    heading: "Processing File",
    subheading: "Give us a moment while we process your file.",
  };

  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
    };
  }

  verify = async () => {
    const uploadJobs = await this.props.getEmployeeDocumentUploadJobs(
      this.props.client,
      this.props.companyId
    );

    const status = get(uploadJobs, "data.[0].status");
    const terminalStatuses = ["PROCESSED", "ERROR"];
    const isTerminalState = terminalStatuses.includes(status);
    const isProcessed = status === "PROCESSED";

    const job = get(uploadJobs, "data.[0]");
    const hasErrored = status === "ERROR";

    if (isProcessed && !this.props.hasFullyOnboarded) {
      this.props.updateProgressiveOnboarding(
        this.props.client,
        progressiveOnboardingEvents.InviteUsers
      );
      if (this.props.onFinished) {
        this.props.onFinished(job, hasErrored);
      }
    } else if (isTerminalState && this.props.onFinished) {
      this.props.onFinished(job, hasErrored);
    }
    return status;
  };

  render() {
    return (
      <div className="download-employee-template">
        <PromisePoller
          interval={4000}
          minutesToPollFor={10}
          promiseToPoll={this.verify}
        />
        <div className="main-content">
          <Row>
            <Col>
              <Card>
                <Card.Title>
                  <p className="page-header">{this.props.heading}</p>
                  <p className="page-subtext">{this.props.subheading}</p>
                </Card.Title>
                <Card.Body>
                  <LottieAnimation />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>

        {this.props.error && <Alert type="error" msg={this.props.error} />}
      </div>
    );
  }
}

const actions = [employerConstants.GET_EMPLOYEE_DOCUMENT_UPLOAD_JOBS];

const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    //OnBoardingSelectors
    hasFullyOnboarded: hasFullyOnboarded(state),
  };
};

const mapDispatchToProps = {
  push,
  getEmployeeDocumentUploadJobs,
  updateProgressiveOnboarding,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(UploadProcessing));
