export const EMPLOYER_PAYCHECK_AUTO_APPROVAL =
  "EMPLOYER_PAYCHECK_AUTO_APPROVAL";
export const EMPLOYER_KYB_MEMBER_DOCUMENT_UPLOAD =
  "EMPLOYER_KYB_MEMBER_DOCUMENT_UPLOAD";
export const EMPLOYER_DOCUMENT_UPLOAD = "EMPLOYER_DOCUMENT_UPLOAD";
export const EMPLOYER_PII_DOCUMENT_UPLOAD = "EMPLOYER_PII_DOCUMENT_UPLOAD";
export const INDIVIDUAL_KYC_DOCUMENT_UPLOAD = "INDIVIDUAL_KYC_DOCUMENT_UPLOAD";

export const ALL_SCOPES = [
  EMPLOYER_PAYCHECK_AUTO_APPROVAL,
  EMPLOYER_KYB_MEMBER_DOCUMENT_UPLOAD,
  EMPLOYER_DOCUMENT_UPLOAD,
  EMPLOYER_PII_DOCUMENT_UPLOAD,
  INDIVIDUAL_KYC_DOCUMENT_UPLOAD,
];
