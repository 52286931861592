import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { FiUsers } from "react-icons/fi";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { filter, get, isEmpty, map, size } from "lodash";
import { employerConstants } from "actions/types";
import { createErrorSelector } from "store/selectors";
import {
  changeAdministratorRole,
  lockAdministrator,
  resendAdministratorInvitation,
  unlockAdministrator,
} from "actions/employerActions";

import moment from "moment";
import IconSpinner from "components/IconSpinner";
import Button from "components/Button";
import InvitationModal from "./InvitationModal";
import IconTable from "components/IconTable";
import IconEmptyState from "components/IconEmptyState";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";

export class AdminsAndAgents extends React.PureComponent {
  static propTypes = {
    agents: PropTypes.array,
    administrators: PropTypes.array,
    changeAdministratorRole: PropTypes.func,
    resendAdministratorInvitation: PropTypes.func,
    fetchAdmins: PropTypes.func,
    lockAdministrator: PropTypes.func,
    unlockAdministrator: PropTypes.func,
    companyId: PropTypes.string,
    error: PropTypes.string,
    client: PropTypes.object,
  };

  constructor() {
    super();

    this.state = {
      showAgentForm: false,
      showAdminForm: false,
      showConfirmRemoveAgent: false,
      showConfirmRemoveAdmin: false,
      resendingInvitationIds: {},
      lockingAgents: {},
      switchingRoleAgents: {},
    };
  }

  _setLockingStatus = (agentId, isUpdating) => {
    this.setState({
      lockingAgents: {
        ...this.state.lockingAgents,
        [agentId]: isUpdating,
      },
    });
  };

  _setChangingRoleStatus = (agentId, isUpdating) => {
    this.setState({
      switchingRoleAgents: {
        ...this.state.switchingRoleAgents,
        [agentId]: isUpdating,
      },
    });
  };

  _setResendingStatus = (agentId, isResending) => {
    this.setState({
      resendingInvitationIds: {
        ...this.state.resendingInvitationIds,
        [agentId]: isResending,
      },
    });
  };

  _lockAdmin = (agentId, companyId) => {
    this._setLockingStatus(agentId, true);
    this.props
      .lockAdministrator(this.props.client, { agentId, companyId })
      .then(() => {
        if (!this.props.error) {
          return this.props.fetchAdmins().then(() => {
            toast.success("Successfully locked administrator.");
          });
        } else {
          toast.error("There was a problem locking admin.");
        }
      })
      .finally(() => {
        this._setLockingStatus(agentId, false);
      });
  };

  _unlockAdmin = (agentId, companyId) => {
    this._setLockingStatus(agentId, true);
    this.props
      .unlockAdministrator(this.props.client, { agentId, companyId })
      .then(() => {
        if (!this.props.error) {
          return this.props.fetchAdmins().then(() => {
            toast.success("Successfully unlocked administrator.");
          });
        } else {
          toast.error("There was a problem unlocking admin.");
        }
      })
      .finally(() => {
        this._setLockingStatus(agentId, false);
      });
  };

  _changeRole = (agentId, role) => {
    this._setChangingRoleStatus(agentId, true);
    this.props
      .changeAdministratorRole(this.props.client, {
        agentId,
        role,
        companyId: this.props.companyId,
      })
      .then(() => {
        if (!this.props.error) {
          return this.props.fetchAdmins().then(() => {
            toast.success("Successfully changed administrator role.");
          });
        } else {
          toast.error("There was a problem changing administrator role.");
        }
      })
      .finally(() => {
        this._setChangingRoleStatus(agentId, false);
      });
  };

  _hasMoreThanOneActiveAdmin = () => {
    const unlockedAdmins = filter(
      this.props.administrators,
      (admin) => !admin.link.locked
    );

    return size(unlockedAdmins) > 1;
  };

  _getActionsButtons = ({ isAdmin, agent, link }) => {
    const lockButton = link.locked ? (
      <Button
        name="submit"
        btnLabel={`Unlock ${isAdmin ? "Admin" : "Agent"}`}
        size="sm"
        withArrow
        loading={this.state.lockingAgents[agent.id]}
        onClick={() => this._unlockAdmin(agent.id, link.companyId)}
      />
    ) : (
      <Button
        name="submit"
        btnLabel={`Lock ${isAdmin ? "Admin" : "Agent"}`}
        size="sm"
        withArrow
        color="red"
        onClick={() => this._lockAdmin(agent.id, link.companyId)}
        disabled={isAdmin && !this._hasMoreThanOneActiveAdmin()}
        loading={this.state.lockingAgents[agent.id]}
      />
    );

    const convertButton = isAdmin ? (
      <Button
        name="submit"
        color="action"
        btnLabel="Make Agent"
        size="sm"
        disabled={!this._hasMoreThanOneActiveAdmin()}
        withArrow
        loading={this.state.switchingRoleAgents[agent.id]}
        onClick={() => this._changeRole(agent.id, "AGENT")}
      />
    ) : (
      <Button
        name="submit"
        color="action"
        btnLabel="Make Admin"
        withArrow
        size="sm"
        loading={this.state.switchingRoleAgents[agent.id]}
        onClick={() => this._changeRole(agent.id, "ADMIN")}
      />
    );

    return (
      <span style={{ display: "flex", justifyContent: "flex-end" }}>
        {lockButton}
        <span style={{ paddingLeft: 5 }}>{!link.locked && convertButton}</span>
      </span>
    );
  };

  _resendInvitation = (adminId) => {
    this._setResendingStatus(adminId, true);

    this.props
      .resendAdministratorInvitation(this.props.client, {
        agentId: adminId,
        companyId: this.props.companyId,
      })
      .then(() => {
        this._setResendingStatus(adminId, false);

        if (!this.props.error) {
          toast.success("Successfully resent invitation.");
        } else {
          toast.error("There was a problem resending invitation.");
        }
      });
  };

  _getStatusSection = (admin, link) => {
    const status = get(link, "status", "");
    const isLocked = get(link, "locked", false);
    const invitationExpiresAt = get(link, "invitationExpiresAt");
    const expiresAt = moment(invitationExpiresAt);

    const isExpired = expiresAt.diff(moment.utc(), "days") < 0;

    if (!status) {
      return null;
    }

    if (isLocked) {
      return "Locked";
    }

    if (this.state.resendingInvitationIds[admin.id]) {
      return <IconSpinner />;
    }

    if (status === "PENDING") {
      return (
        <div>
          {isExpired ? "Expired" : "Pending"} (
          <a
            className="link"
            style={{ paddingLeft: 0 }}
            onClick={() => this._resendInvitation(admin.id)}
          >
            Resend Invitation
          </a>
          )
        </div>
      );
    }

    return "Accepted";
  };

  //Admin Table
  _getAdminData = () => {
    return map(this.props.administrators, ({ user: admin, link }) => {
      const status = this._getStatusSection(admin, link);
      const actionButtons = this._getActionsButtons({
        isAdmin: true,
        agent: admin,
        link,
      });

      return {
        userName: admin.username,
        email: admin.email,
        status,
        actionButtons,
      };
    });
  };

  _buildAdminColumns = () => {
    const columns = [
      {
        label: "Username",
        key: "userName",
      },
      {
        label: "Email",
        key: "email",
      },
      {
        label: "Status",
        key: "status",
      },
      {
        customComponent: (props) => {
          return <div>{props.actionButtons}</div>;
        },
      },
    ];
    return columns;
  };

  _getAdminSection = () => {
    const columns = this._buildAdminColumns();
    const data = this._getAdminData();

    const noAdmins = isEmpty(this.props.administrators);

    if (isEmpty(this.props.administrators)) return null;
    return (
      <div>
        {!noAdmins && (
          <div className="action-box">
            <div className="action-header">
              <span className="has-count">
                <IconHeader variant="labelHeader" headerText="Administrators" />
                <p className="count">: ({size(this.props.administrators)})</p>
              </span>

              <div>
                <Button
                  name="action"
                  onClick={() => this.setState({ showAdminForm: true })}
                  size="sm"
                  btnLabel={"Add Admin"}
                />
              </div>
            </div>

            <IconTable columns={columns} data={data} />
          </div>
        )}
        {noAdmins && (
          <div className="action-box">
            <IconEmptyState
              header={"Employer has no Admin."}
              subheader="Would you like to clear your search?"
              icon={<FiUsers color="white" stroke="#60A4BF" size={16} />}
              actionText={"Add Admin"}
              onClick={() => this.setState({ showAgentForm: true })}
            />
          </div>
        )}
      </div>
    );
  };

  //Agents Table
  _getAgentsData = () => {
    return map(this.props.agents, ({ user: agent, link }) => {
      const status = this._getStatusSection(agent, link);
      const actionButtons = this._getActionsButtons({
        isAdmin: false,
        agent,
        link,
      });

      return {
        userName: agent.username,
        email: agent.email,
        status,
        actionButtons,
      };
    });
  };

  _buildAgentsColumns = () => {
    const columns = [
      {
        label: "Username",
        key: "userName",
      },

      {
        label: "Email",
        key: "email",
      },
      {
        label: "Status",
        key: "status",
      },
      {
        customComponent: (props) => {
          return <div style={{ paddingRight: 15 }}>{props.actionButtons}</div>;
        },
      },
    ];
    return columns;
  };

  _getAgentSection = () => {
    const columns = this._buildAgentsColumns();
    const data = this._getAgentsData();

    const noAgents = isEmpty(this.props.agents);
    return (
      <div>
        {!noAgents && (
          <div className="action-box">
            <div className="action-header">
              <span className="has-count">
                <IconHeader variant="labelHeader" headerText="Agents" />
                <p className="count">: ({size(this.props.agents)})</p>
              </span>

              <div>
                <Button
                  name="action"
                  onClick={() => this.setState({ showAgentForm: true })}
                  size="sm"
                  btnLabel={"Add Agent"}
                />
              </div>
            </div>

            <IconTable columns={columns} data={data} />
          </div>
        )}
        {noAgents && (
          <div className="action-box">
            <div className="employerInfo">
              <div className="employerInfo-body">
                <div>
                  <div className="circle">
                    <span className="icon">
                      <FiUsers color="white" stroke="#60A4BF" size={16} />
                    </span>
                  </div>
                </div>
                <div className="employerInfo-intro">
                  <IconHeader
                    variant="labelHeader"
                    headerText="Employer agents."
                  />
                  <IconSubheader subheader="Add or invite an agent." />
                </div>
              </div>
              <div className="employerInfo-action">
                <Button
                  size="sm"
                  name="action"
                  onClick={() => this.setState({ showAgentForm: true })}
                  btnLabel={"Add Agent"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  _onModalClose = () => {
    this.setState({
      showAdminForm: false,
      showAgentForm: false,
    });
  };

  _onInviteSuccess = () => {
    this._onModalClose();
    this.props.fetchAdmins();
  };

  render() {
    const showModal = this.state.showAdminForm || this.state.showAgentForm;
    const agents = this.props.agents || [];
    return (
      <div className="admins-and-agents">
        {this._getAdminSection()}
        {this._getAgentSection()}

        {showModal && (
          <InvitationModal
            onClose={this._onModalClose}
            administrators={[...agents, ...this.props.administrators]}
            isAdminForm={this.state.showAdminForm}
            companyId={this.props.companyId}
            onSuccess={this._onInviteSuccess}
          />
        )}
      </div>
    );
  }
}

const errorSelector = createErrorSelector([
  employerConstants.RESEND_INVITE_ADMINISTRATOR,
  employerConstants.LOCK_ADMINISTRATOR,
  employerConstants.UNLOCK_ADMINISTRATOR,
  employerConstants.CHANGE_ADMIN_ROLE,
]);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  lockAdministrator,
  unlockAdministrator,
  resendAdministratorInvitation,
  changeAdministratorRole,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AdminsAndAgents));
