import React from "react";
import cn from "classnames";
import { PropTypes } from "prop-types";

import "./Typography.scss";

const variantsMapping = {
  pageHeader: "pageHeader",
  cardHeader: "cardHeader",
  labelHeader: "labelHeader",
  cardEmptyState: "cardEmptyState",
  header: "header",
  referralHeader: "referralHeader",
  centeredText: "centeredText",
};

const IconHeader = ({ variant, children, headerText, ...props }) => {
  const Component = variant ? variantsMapping[variant] : "p";

  return (
    <div>
      <Component
        className={cn({
          [`typography--variant-${variant}`]: variant,
        })}
        {...props}
      >
        {children}
        {headerText}
      </Component>
    </div>
  );
};

IconHeader.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
  headerText: PropTypes.string,
};

export default IconHeader;
