import React from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { get, groupBy, includes, size, isEmpty } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { Card } from "react-bootstrap";
import { createErrorSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import { invalidBankIdsLinkedToGroupsSelector } from "store/selectors/bank";
import { FiList } from "react-icons/fi";
import { groupType } from "statics/propTypes";

import Alert from "components/Alert";
import IconTable from "components/IconTable";
import Button from "components/Button";
import IconEmptyState from "components/IconEmptyState";

import "./ProcessOneTimeContributions.scss";

export class ProcessGroupsTable extends React.PureComponent {
  static propTypes = {
    push: PropTypes.func,
    linkedEmployeesByGroup: PropTypes.object,
    client: PropTypes.object,
    error: PropTypes.string,
    invalidBankIdsLinkedToGroups: PropTypes.arrayOf(PropTypes.string),
    groups: PropTypes.arrayOf(groupType),
  };

  constructor(props) {
    super(props);

    this.state = {
      showConfirmationModal: false,
      pendingCancellationId: null,
    };
  }

  _buildColumns = () => {
    const columns = [
      {
        label: "Group Name",
        key: "[0]groupWithSchedule.group.name",
      },

      {
        label: "Number of Users",
        key: "length",
      },
      {
        label: "Action",
        customComponent: (props) => <>{this._getPayrollAction(props)}</>,
      },
    ];
    return columns;
  };

  _getPayrollAction = (linkedEmployees) => {
    const groupId = get(linkedEmployees, "[0]groupWithSchedule.group.id");

    const associatedBankAccountId = get(
      linkedEmployees,
      "[0]groupWithSchedule.group.associatedBankAccount.id"
    );

    const shouldUpdateBank = includes(
      this.props.invalidBankIdsLinkedToGroups,
      associatedBankAccountId
    );

    if (shouldUpdateBank) {
      return (
        <Button
          color="action"
          name="action"
          size="sm"
          btnLabel="Update Bank"
          onClick={() => {
            this.props.push("/dashboard/company/group");
          }}
        />
      );
    }
    return (
      <Button
        color="action"
        name="action"
        size="sm"
        btnLabel="Review Payroll"
        onClick={() =>
          this.props.push(`/dashboard/contributions/${groupId}/one-time`)
        }
      />
    );
  };

  render() {
    const columns = this._buildColumns();
    const numberOfGroups = size(this.props.linkedEmployeesByGroup);
    const payrollEmpty = isEmpty(this.props.groups);
    return (
      <Card className="one-time-contributions">
        <div className="widget-header">
          <Card.Title>
            Payroll groups:
            <p className="invite-number">
              Groups (<p className="number">{numberOfGroups}</p>)
            </p>
          </Card.Title>
        </div>
        {this.props.error && <Alert type="error" msg={this.props.error} />}
        {!payrollEmpty && (
          <IconTable
            columns={columns}
            data={this.props.linkedEmployeesByGroup}
          />
        )}
        {payrollEmpty && (
          <div style={{ borderTop: "1px solid #f2f4f7" }}>
            <IconEmptyState
              header={"No payrolls to process"}
              subheader="Please add a group and employees in order to process a payroll."
              icon={<FiList color="white" stroke="#60A4BF" size={16} />}
            />
          </div>
        )}
      </Card>
    );
  }
}

const errorSelector = createErrorSelector(employerConstants.PROCESS_PAYROLL);

const mapStateToProps = (state) => {
  const linkedEmployees = state.employer.linkedEmployees;
  const linkedEmployeesByGroup = groupBy(
    linkedEmployees,
    "groupWithSchedule.group.id"
  );

  return {
    linkedEmployeesByGroup,
    groups: state.employer.groups,
    error: errorSelector(state),
    invalidBankIdsLinkedToGroups: invalidBankIdsLinkedToGroupsSelector(state),
  };
};

const mapDispatchToProps = {
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ProcessGroupsTable));
