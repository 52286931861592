export const Disconnected = "Disconnected";
export const ConnectionActive = "ConnectionActive";
export const ConnectionFailure = "ConnectionFailure";
export const ConnectionSyncError = "ConnectionSyncError";
export const ConnectionInitialized = "ConnectionInitialized";
export const PendingInitialRosterSync = "PendingInitialRosterSync";
export const RosterSyncError = "RosterSyncError";
export const InitialRosterSyncError = "InitialRosterSyncError";
export const PendingRosterSync = "PendingRosterSync";
export const PendingTokenValidation = "PendingTokenValidation";
export const PendingInitialRosterApproval = "PendingInitialRosterApproval";
export const ProcessingInitialRosterApproval =
  "ProcessingInitialRosterApproval";
export const ConnectionAuthError = "ConnectionAuthError";
export const PendingAuthTokenValidation = "PendingAuthTokenValidation";
export const PendingAdminApproval = "PendingAdminApproval";

export default {
  Disconnected,
  ConnectionActive,
  ConnectionFailure,
  ConnectionSyncError,
  ConnectionInitialized,
  PendingInitialRosterSync,
  PendingRosterSync,
  PendingInitialRosterApproval,
  PendingTokenValidation,
  ConnectionAuthError,
  PendingAuthTokenValidation,
  ProcessingInitialRosterApproval,
  InitialRosterSyncError,
  PendingAdminApproval,
};
