import { isApolloError } from "@apollo/client/errors";
import { isError, replace, isString } from "lodash";

const graphqlErrorText = "GraphQL error: ";
export function wrapError(error) {
  if (isString(error)) {
    return error;
  }

  if (isApolloError(error)) {
    // Graphql likes to add its own error text before graphql errors, this doesn't help
    // the user in any way so we wil remove it
    return replace(error.message, graphqlErrorText, "");
  }

  if (isError(error)) {
    return error.toString();
  }

  return error;
}
