import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Formik } from "formik";
import { Card, Col, Form, Row } from "react-bootstrap";
import { get, isEmpty, map } from "lodash";
import { toast } from "react-toastify";
import { FiChevronLeft, FiChevronRight, FiGrid } from "react-icons/fi";
import { uploadFile } from "statics/rosterSteps";
import { uploadEmployeesDocument, uploadRoster } from "actions/employerActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants, employerConstants } from "actions/types";
import { isIconAdminSelector } from "store/selectors/user";
import {
  getEmployerGroupsByCompanyId,
  uploadEmployeesDocumentForEmployer,
} from "actions/adminActions";

import RosterUploader from "components/RosterUploader";
import LottieAnimation from "components/LottieAnimation";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import IconEmptyState from "components/IconEmptyState";
import IconSpinner from "components/IconSpinner";
import Button from "components/Button";
import Alert from "components/Alert";

import "./AddEmployeeUploadForm.scss";
import { ScrollToFieldError } from "utils/form";

const schema = yup.object({
  groupId: yup.string().required(),
});

class AddEmployeeUploadForm extends React.PureComponent {
  static propTypes = {
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    client: PropTypes.object,
    error: PropTypes.string,
    companyId: PropTypes.string,
    isLoading: PropTypes.bool,
    uploadEmployeesDocument: PropTypes.func,
    document: PropTypes.object,
    isIconAdmin: PropTypes.bool,
    getEmployerGroupsByCompanyId: PropTypes.func,
    isFetchingEmployerGroups: PropTypes.bool,
    uploadEmployeesDocumentForEmployer: PropTypes.func,
    onStepChange: PropTypes.func,
    uploadRoster: PropTypes.func,
    onClose: PropTypes.func,
    hideForm: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      submittedDocument: false,
      companies: [],
      selectedCompanyId: "",
      selectedGroupId: get(props, "groups[0].id"),
      initialFetching: props.isIconAdmin,
      showUploadToggle: false,
      showRosterUploader: false,
      groupsByCompanyId: [],
    };
  }

  async componentDidMount() {
    if (this.props.isIconAdmin) {
      await this.props.getEmployerGroupsByCompanyId(
        this.props.client,
        this.props.companyId
      );

      this.setState({
        initialFetching: false,
        selectedGroupId: get(this.props, "groups[0].id"),
      });
    }
  }

  _buildGroupInput(values, handleChange, handleBlur, touched, errors) {
    if (isEmpty(this.props.groups)) {
      return (
        <IconEmptyState
          header={"There are no groups for this employer"}
          icon={<FiGrid color="white" stroke="#60A4BF" size={16} />}
        />
      );
    }
    return (
      <div className="group-select">
        <Form.Row>
          <Form.Group as={Col} sm={6} controlId="formBasicGroupId">
            <Form.Label>Group</Form.Label>
            <Form.Control
              as="select"
              name="groupId"
              value={values.groupId}
              onChange={(event) => {
                handleChange(event);
                this.setState({ selectedGroupId: event.target.value });
              }}
              onBlur={handleBlur}
              isInvalid={touched.groupId && !!errors.groupId}
              isValid={touched.groupId && !errors.groupId}
            >
              {map(this.props.groups, (group) => (
                <option key={group.id} value={group.id}>
                  {this.props.isIconAdmin
                    ? `${group.name} - ${group.planType} - ${group.id}`
                    : group.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.groupId}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
      </div>
    );
  }

  _onRosterUpload = async (data) => {
    if (isEmpty(data)) {
      toast.error(
        "Failed to upload roster. You must upload at least one employee."
      );
      this.props.onStepChange(uploadFile);
      return;
    }
    await this.props.uploadRoster(this.props.client, {
      rows: data,
      groupId: this.state.selectedGroupId,
      companyId: this.props.companyId,
    });
    if (!this.props.error) {
      toast.success("Successfully uploaded document!");
      this.props.onClose && this.props.onClose();
    } else {
      this.props.onStepChange(uploadFile);
    }
  };

  _renderUpload = () => {
    if (this.state.isFetchingCompanies) {
      return <IconSpinner centered />;
    }

    return (
      <div className="download-employee-template">
        <div className="main-content">
          <Row>
            <Col as={Col} sm={9}>
              <Card>
                {this.props.isLoading && (
                  <>
                    <Card.Title>
                      <p className="page-header">Processing File</p>
                      <p className="page-subtext">
                        Give us a moment while we process your file.{" "}
                      </p>
                    </Card.Title>
                    <Card.Body>
                      <LottieAnimation />
                    </Card.Body>
                  </>
                )}
                {!this.state.showRosterUploader && !this.props.isLoading && (
                  <>
                    <Card.Title>
                      <IconHeader
                        variant="cardHeader"
                        headerText="Select a group"
                      />
                      <IconSubheader subheader="This is the group you will be uploading the roaster to." />
                    </Card.Title>
                    <Card.Body>
                      <Formik
                        validateOnChange={false}
                        validationSchema={schema}
                        onSubmit={this._uploadFile}
                        enableReinitialize={true}
                        initialValues={{
                          ...this.state,
                          groupId: this.state.selectedGroupId,
                        }}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          values,
                          touched,
                          errors,
                          handleBlur,
                        }) => (
                          <Form noValidate onSubmit={handleSubmit}>
                            <ScrollToFieldError />
                            {this.props.isIconAdmin &&
                            this.props.isFetchingEmployerGroups ? (
                              <IconSpinner centered />
                            ) : (
                              this._buildGroupInput(
                                values,
                                handleChange,
                                handleBlur,
                                touched,
                                errors
                              )
                            )}
                            {this.props.error && (
                              <Alert type="error" msg={this.props.error} />
                            )}
                            <div className="btn-row">
                              {!this.state.submittedDocument && (
                                <Button
                                  icon={{
                                    icon: (
                                      <FiChevronLeft
                                        size={14}
                                        stroke={"#0cb7c4"}
                                      />
                                    ),
                                    position: "left",
                                  }}
                                  btnLabel="Go Back"
                                  name="cancel"
                                  color="cancel"
                                  onClick={this.props.hideForm}
                                />
                              )}
                              <Button
                                btnLabel={"Upload Roster"}
                                name="submit"
                                onClick={() =>
                                  this.setState({ showRosterUploader: true })
                                }
                                disabled={isEmpty(this.props.groups)}
                                withArrow={this.props.isLoading}
                                loading={this.props.isLoading}
                                icon={{
                                  icon: (
                                    <FiChevronRight size={8} color={"#FFFFF"} />
                                  ),
                                  position: "right",
                                }}
                                type="button"
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </Card.Body>
                  </>
                )}
                {this.state.showRosterUploader && (
                  <Card.Body>
                    <RosterUploader
                      onSubmit={this._onRosterUpload}
                      setActiveStep={this.props.onStepChange}
                      onClose={() =>
                        this.setState({ showRosterUploader: false })
                      }
                    />
                  </Card.Body>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="add-employee-form" style={{ marginBottom: 8 }}>
        {this._renderUpload()}
      </div>
    );
  }
}

const isLoadingSelector = createLoadingSelector(
  employerConstants.UPLOAD_ROSTER
);

const isLoadingEmployerGroupsSelector = createLoadingSelector(
  adminConstants.GET_GROUPS_BY_COMPANY_ID
);

const errorSelector = createErrorSelector(employerConstants.UPLOAD_ROSTER);

const mapStateToProps = (state) => {
  return {
    groups: state.employer.groups,
    error: errorSelector(state),
    isLoading: isLoadingSelector(state),
    document: get(state, `userDocuments.documents[0]`),
    isIconAdmin: isIconAdminSelector(state),
    isFetchingEmployerGroups: isLoadingEmployerGroupsSelector(state),
  };
};

const mapDispatchToProps = {
  uploadEmployeesDocument,
  getEmployerGroupsByCompanyId,
  uploadEmployeesDocumentForEmployer,
  uploadRoster,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AddEmployeeUploadForm));
