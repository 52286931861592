import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { TransactionsTable } from "pages/dashboards/individualDashboard/transactions/TransactionsTable";
import { createErrorSelector } from "store/selectors";
import { getTransactionsForUser } from "actions/adminActions";
import { adminConstants } from "actions/types";
import { FiList } from "react-icons/fi";

import IconSpinner from "components/IconSpinner";
import Alert from "components/Alert";
import IconEmptyState from "components/IconEmptyState";

class UserTransactions extends React.PureComponent {
  static propTypes = {
    error: PropTypes.string,
    userId: PropTypes.string,
    client: PropTypes.object,
    getTransactionsForUser: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      isFetching: true,
      transactions: [],
    };
  }

  async componentDidMount() {
    const { data: transactions } = await this.props.getTransactionsForUser(
      this.props.client,
      this.props.userId
    );

    this.setState({
      transactions,
      isFetching: false,
    });
  }

  render() {
    if (this.state.isFetching) {
      return <IconSpinner centered />;
    }

    if (this.props.error) {
      return <Alert type={"error"} msg={this.props.error} />;
    }

    if (isEmpty(this.state.transactions)) {
      return (
        <div>
          <IconEmptyState
            header={"Empty Transactions"}
            subheader="No transactions exist for this user"
            icon={<FiList color="white" stroke="#60A4BF" size={16} />}
          />
        </div>
      );
    }

    return (
      <div id={"admin-user-transactions"}>
        <TransactionsTable transactions={this.state.transactions} />;
      </div>
    );
  }
}

const errorSelector = createErrorSelector(adminConstants.GET_USER_TRANSACTIONS);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
  };
};

const mapDispatchToProps = {
  getTransactionsForUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(UserTransactions));
