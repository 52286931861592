import React from "react";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import { connect } from "react-redux";
import { userService } from "services/userService";
import { filter, find, isBoolean, some } from "lodash";
import {
  addRolloverAccountDisclosure,
  getUserState,
  updateIraAccountDisclosure,
} from "actions/userActions";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { accountConstants, userConstants } from "actions/types";
import { getAllAccounts } from "actions/accountActions";
import { withApollo } from "@apollo/client/react/hoc";
import { ScrollToFieldError } from "utils/form";
import { isRolloverSelector } from "store/selectors/user";
import { scrollToTop } from "utils/dom";
import { FiMinus, FiPlus } from "react-icons/fi";
import { DISCLOSURES } from "statics/onboardingSteps";
import {
  PRINTABLE_ASCII_MESSAGE,
  testPrintableAsciiCharacters,
} from "utils/fieldValidators";

import Button from "components/Button";
import FileUploader from "components/FileUploader";
import Alert from "components/Alert";
import TypeUtils from "utils/typeUtils";
import ProgressBar from "components/ProgressBar";
import CircularProgressBar from "components/CircularProgressBar";
import CancelRolloverModal from "../../dashboards/individualDashboard/dashboard/CancelRolloverModal";

let yup = require("yup");
const schema = yup.object({
  noneApply: yup.boolean().required().label("None Apply"),
  controlPerson: yup.boolean().label("Control Person").required(),
  isCustodianEmployee: yup.boolean().label("Custodian Employee").required(),
  stockSymbols: yup
    .string()
    .when("controlPerson", {
      is: true,
      then: yup.string().required().min(2).max(4),
      otherwise: yup.string(),
    })
    .test("stock-symbol", PRINTABLE_ASCII_MESSAGE, testPrintableAsciiCharacters)
    .label("Stock Symbol"),
  exchangeOrFINRA: yup
    .boolean()
    .label("Affilated Exchange Or FINRA")
    .required(),
  firmName: yup
    .string()
    .when("exchangeOrFINRA", {
      is: true,
      then: yup
        .string()
        .required()
        .test(
          "firm-name",
          PRINTABLE_ASCII_MESSAGE,
          testPrintableAsciiCharacters
        )
        .min(2)
        .max(50),
      otherwise: yup
        .string()
        .test(
          "firm-name",
          PRINTABLE_ASCII_MESSAGE,
          testPrintableAsciiCharacters
        ),
    })
    .label("Firm Name"),
  politicallyExposed: yup.boolean().label("Politically Exposed").required(),
  organizationName: yup
    .string()
    .when("politicallyExposed", {
      is: true,
      then: yup
        .string()
        .required()
        .test(
          "organization-name",
          PRINTABLE_ASCII_MESSAGE,
          testPrintableAsciiCharacters
        )
        .min(2)
        .max(50),
      otherwise: yup
        .string()
        .test(
          "organization-name",
          PRINTABLE_ASCII_MESSAGE,
          testPrintableAsciiCharacters
        ),
    })
    .label("Organization Name"),
});

class IndividualDisclosure extends React.Component {
  static propTypes = {
    client: PropTypes.shape({}),
    documents: PropTypes.array,
    getUserState: PropTypes.func,
    getAllAccounts: PropTypes.func,
    updateIraAccountDisclosure: PropTypes.func,
    addRolloverAccountDisclosure: PropTypes.func,
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    userState: PropTypes.string,
    isRolloverAccount: PropTypes.bool,
  };
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      showCancelRolloverModal: false,
      disclosure: {
        noneApply: true,
        tradeAuthorization: false,
        controlPerson: false,
        stockSymbols: "",
        exchangeOrFINRA: false,
        firmName: "",
        politicallyExposed: false,
        organizationName: "",
        familyNames: [],
        isCustodianEmployee: false,
      },
      pepNames: [""],
      submitting: false,
      submitted: false,
      loading: true,
      documentError: "Error",
      dimensions: {
        width: -1,
        height: -1,
      },
    };
    userService.getIraAccountForm(this.props.client).then(
      (data) => {
        const anyAreTrue = some(
          filter(data.disclosure, isBoolean),
          (value) => value === true
        );
        let disclosure = {
          noneApply: !anyAreTrue,
          tradeAuthorization: data.disclosure.tradeAuthorization,
          controlPerson: data.disclosure.controlPerson,
          stockSymbols: [],
          exchangeOrFINRA: data.disclosure.exchangeOrFINRA,
          firmName: data.disclosure.firmName,
          politicallyExposed: data.disclosure.politicallyExposed,
          organizationName: data.disclosure.organizationName,
          familyNames: [],
          isCustodianEmployee: data.disclosure.isCustodianEmployee,
        };

        if (this._isMounted) {
          this.setState({
            submitted: false,
            disclosure,
            loading: false,
          });
        }
      },
      () => {
        if (this._isMounted) {
          this.setState({
            submitted: false,
            loading: false,
          });
        }
      }
    );
  }

  handlePepNameChange = (idx) => (evt) => {
    const newPepNames = this.state.pepNames.map((pepName, sidx) => {
      if (idx !== sidx) return pepName;
      return evt.target.value;
    });

    this.setState({ pepNames: newPepNames });
  };

  handleAddPepName = () => {
    this.setState({
      pepNames: this.state.pepNames.concat([""]),
    });
  };

  handleRemovePepName = (idx) => () => {
    var newNames = this.state.pepNames;
    newNames.splice(idx, 1);

    this.setState({
      pepNames: newNames,
    });
  };

  componentDidMount() {
    this._isMounted = true;
    scrollToTop();
    window.analytics.page("Individual Disclosure");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validDocuments() {
    return some(this.props.documents, { docType: "AFFILIATED_APPROVAL" });
  }

  submitDisclosure = (values) => {
    if (values.exchangeOrFINRA && values.isCustodianEmployee) {
      // Not valid to have both!
      return;
    }
    if (
      TypeUtils.booleanValue(values.exchangeOrFINRA) &&
      !this.validDocuments()
    ) {
      if (this._isMounted) {
        this.setState({
          submitting: true,
        });
      }
      return;
    }

    var docObj = find(this.props.documents, { docType: "AFFILIATED_APPROVAL" });
    let url = "";
    if (docObj !== undefined) {
      url = docObj.docUrl;
    }

    let symbols;
    if (values.stockSymbols.length !== 0) {
      symbols = [values.stockSymbols];
    } else {
      symbols = [];
    }

    let names;
    if (this.state.pepNames.length === 1 && this.state.pepNames[0] === "") {
      names = [];
    } else {
      names = this.state.pepNames;
    }
    let disclosure = {
      tradeAuthorization: false,
      controlPerson: TypeUtils.booleanValue(values.controlPerson),
      stockSymbols: symbols,
      exchangeOrFINRA: TypeUtils.booleanValue(values.exchangeOrFINRA),
      firmName: values.firmName,
      politicallyExposed: TypeUtils.booleanValue(values.politicallyExposed),
      organizationName: values.organizationName,
      familyNames: names,
      docUrl: url,
      isCustodianEmployee: TypeUtils.booleanValue(values.isCustodianEmployee),
    };

    if (this.props.isRolloverAccount) {
      this.props.addRolloverAccountDisclosure(this.props.client, disclosure);
      window.analytics.track("Submitted Rollover Account Disclosure");
    } else {
      this.props
        .updateIraAccountDisclosure(this.props.client, disclosure)
        .then(() => {
          this.props.getUserState(this.props.client);
        });
      window.analytics.track("Submitted Account Disclosure", {
        userState: this.props.userState,
      });
    }

    if (this._isMounted) {
      this.setState({
        submitted: true,
      });
    }
  };

  _onCancel = () => {
    this.setState({
      showCancelRolloverModal: true,
    });
  };

  render() {
    if (this.state.loading) {
      return <div></div>;
    }
    return this.renderForm();
  }

  renderForm = () => {
    const { disclosure } = this.state;

    var articleStyle = {
      paddingBottom: 0,
    };

    const successMessage = "Your file was successfully uploaded!";

    const progPercent = this.props.isRolloverAccount ? "40" : "55";

    return (
      <div>
        <ProgressBar
          isEmployer={false}
          activeStepId={DISCLOSURES.id}
          progressPercent={progPercent}
        />
        {this.state.showCancelRolloverModal && (
          <CancelRolloverModal
            show={this.state.showCancelRolloverModal}
            onClose={() =>
              this.setState({
                showCancelRolloverModal: false,
              })
            }
            onSuccess={() =>
              this.setState({
                showCancelRolloverModal: false,
              })
            }
          />
        )}
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={(values) => {
            this.submitDisclosure(values);
          }}
          enableReinitialize={true}
          initialValues={{ ...disclosure, stockSymbols: "" }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} className="disclosure">
              <ScrollToFieldError />
              <div className="mega-container">
                <div
                  className="step-container is-active"
                  data-circle-percent="70"
                >
                  <section className="page-title-wrap">
                    <article className="text-cell">
                      <p className="page-title">Your Disclosures</p>
                      <p className="page-subtext">
                        We need some additional disclosures.
                      </p>
                    </article>
                    <article className="progress-cell">
                      <ul className="circular-progress-wrap">
                        <CircularProgressBar
                          strokeWidth="8"
                          sqSize="75"
                          percentage="70"
                        />
                      </ul>
                    </article>
                  </section>
                  <div id="form-employer-company">
                    <section className="form-sec-2col-disclosure">
                      <article className="col-form" style={articleStyle}>
                        <Form.Row className="control-checkbox-noneApply">
                          <Form.Check
                            type="checkbox"
                            name="noneApply"
                            checked={values.noneApply}
                            defaultChecked={true}
                            onChange={(e) => {
                              setFieldValue("noneApply", e.target.checked);
                              if (e.target.checked) {
                                setFieldValue("controlPerson", false);
                                setFieldValue("exchangeOrFINRA", false);
                                setFieldValue("politicallyExposed", false);
                                setFieldValue("isCustodianEmployee", false);
                              }

                              window.analytics.track("Clicked None Apply");
                            }}
                          />
                          <Form.Label>
                            <b className="bold">None of these apply to me.</b>
                          </Form.Label>
                        </Form.Row>

                        <Form.Row
                          className="disclosure-control-checkbox"
                          controlId="formBasicControlPerson"
                        >
                          <Form.Check
                            type="checkbox"
                            checked={values.controlPerson}
                            name="controlPerson"
                            value={values.controlPerson}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue("controlPerson", e.target.checked);
                              setFieldValue("noneApply", false);
                              window.analytics.track("Clicked Control Person");
                            }}
                          />
                          <Form.Label>
                            <b className="bold">Control Person:</b> Are you a
                            control person of a publicly traded company
                            (Director, Officer, or 10% Stock Owner)?
                          </Form.Label>
                        </Form.Row>
                        {values.controlPerson === true ? (
                          <Form.Row
                            className="disclosure-context"
                            controlId="formBasicStockSymbols"
                          >
                            <Form.Group
                              as={Col}
                              controlId="formBasicStockSymbols"
                            >
                              <Form.Label>
                                Please list the stock ticker symbol:
                              </Form.Label>
                              <Form.Control
                                name="stockSymbols"
                                placeholder="Stock symbol"
                                className="stock-symbol"
                                value={values.stockSymbols}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.stockSymbols && !!errors.stockSymbols
                                }
                                isValid={
                                  touched.stockSymbols && !errors.stockSymbols
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.stockSymbols}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                        ) : (
                          <div></div>
                        )}
                        <Form.Row
                          className="disclosure-control-checkbox"
                          controlId="formBasicExchangeOrFINRA"
                        >
                          <Form.Check
                            type="checkbox"
                            name="exchangeOrFINRA"
                            checked={values.exchangeOrFINRA}
                            value={values.exchangeOrFINRA}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue(
                                "exchangeOrFINRA",
                                e.target.checked
                              );
                              setFieldValue("noneApply", false);
                              window.analytics.track(
                                "Clicked Exchange Affiliation"
                              );
                            }}
                          />
                          <Form.Label>
                            <b className="bold">Exchange Affiliation:</b> Are
                            you affiliated with, work with, or work for a member
                            firm of a Stock Exchange or FINRA?
                          </Form.Label>
                        </Form.Row>
                        {values.exchangeOrFINRA === true ? (
                          <React.Fragment>
                            <Form.Row className="disclosure-context">
                              <Form.Group
                                as={Col}
                                controlId="formBasicTradeFirm"
                              >
                                <Form.Label>
                                  Please enter the name of the firm:
                                </Form.Label>
                                <Form.Control
                                  name="firmName"
                                  placeholder="Firm name"
                                  value={values.firmName}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  isInvalid={
                                    touched.firmName && !!errors.firmName
                                  }
                                  isValid={touched.firmName && !errors.firmName}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.firmName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row className="disclosure-context">
                              <Form.Group
                                as={Col}
                                controlId="formBasicTradeFormUpload"
                              >
                                <Form.Label>
                                  Please upload an image documenting written
                                  consent of the member firm for opening your
                                  account, pursuant to FINRA incorporated NYSE
                                  Rule 3210(407):
                                </Form.Label>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                controlId="formBasicTradeFormUploadField"
                              >
                                <FileUploader
                                  fileLimit={1}
                                  docType={"AFFILIATED_APPROVAL"}
                                  persist={true}
                                  fileUploadedMsg={successMessage}
                                />
                              </Form.Group>
                            </Form.Row>
                          </React.Fragment>
                        ) : (
                          <div></div>
                        )}
                        <Form.Row
                          className="disclosure-control-checkbox"
                          controlId="formBasicPoliticallyExposed"
                        >
                          <Form.Check
                            type="checkbox"
                            name="politicallyExposed"
                            checked={values.politicallyExposed}
                            value={values.politicallyExposed}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue(
                                "politicallyExposed",
                                e.target.checked
                              );
                              setFieldValue("noneApply", false);
                              window.analytics.track(
                                "Clicked Politically Exposed"
                              );
                            }}
                          />
                          <Form.Label>
                            <b className="bold">Politically Exposed:</b> Will
                            this account be maintained for a current or former
                            Politically Exposed Person or Public Official
                            (includes U.S. and Foreign Individuals)?
                          </Form.Label>
                        </Form.Row>
                        {values.politicallyExposed === true ? (
                          <React.Fragment>
                            <Form.Row className="disclosure-context">
                              <Form.Group
                                as={Col}
                                controlId="formBasicPepOrganization"
                              >
                                <Form.Label>
                                  Please provide the name of the related
                                  political organization:{" "}
                                </Form.Label>
                                <Form.Control
                                  name="organizationName"
                                  placeholder="Organization Name"
                                  value={values.organizationName}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  isInvalid={
                                    touched.organizationName &&
                                    !!errors.organizationName
                                  }
                                  isValid={
                                    touched.organizationName &&
                                    !errors.organizationName
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.organizationName}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row className="disclosure-context">
                              <Form.Group
                                as={Col}
                                sm={12}
                                controlId="formBasicPoliticallyExposedNames"
                              >
                                <Form.Label>
                                  Please provide the names of that person{"'"}s
                                  or official{"'"}s immediate family members
                                  (including former spouses):
                                </Form.Label>
                              </Form.Group>
                            </Form.Row>
                            {this.state.pepNames.map((pepName, idx) => (
                              <Form.Row className="family-member" key={idx}>
                                <Form.Group
                                  as={Col}
                                  sm={8}
                                  controlId="formBasicPoliticallyExposedNames"
                                >
                                  <Form.Control
                                    type="text"
                                    name={`pepName.${idx}`}
                                    placeholder={`Family member #${
                                      idx + 1
                                    } Full Legal Name`}
                                    value={pepName}
                                    onChange={this.handlePepNameChange(idx)}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.pepName}
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                  as={Col}
                                  sm={2}
                                  controlId="formBasicPoliticallyExposedButton"
                                >
                                  {this.state.pepNames.length > 1 ? (
                                    <div className="minus-btn">
                                      <FiMinus
                                        type="button"
                                        size={"24px"}
                                        color={"#333333"}
                                        fill={"#333333"}
                                        stroke={"#333333"}
                                        strokeWidth={"1.5"}
                                        onClick={this.handleRemovePepName(idx)}
                                      />
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </Form.Group>
                              </Form.Row>
                            ))}
                            {this.state.pepNames.length <= 9 ? (
                              <div className="add-member-btn">
                                <FiPlus
                                  type="button"
                                  onClick={this.handleAddPepName}
                                  size={"24px"}
                                  color={"#1D99A9"}
                                  fill={"#1D99A9"}
                                  stroke={"#1D99A9"}
                                  strokeWidth={"1.5"}
                                />
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </React.Fragment>
                        ) : (
                          <div></div>
                        )}
                        <Form.Row
                          className="disclosure-control-checkbox"
                          controlId="formCustodianEmployee"
                        >
                          <Form.Check
                            type="checkbox"
                            name="isCustodianEmployee"
                            checked={values.isCustodianEmployee}
                            value={values.isCustodianEmployee}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue(
                                "isCustodianEmployee",
                                e.target.checked
                              );
                              setFieldValue("noneApply", false);
                              window.analytics.track(
                                "Clicked Custodian Employee"
                              );
                            }}
                          />
                          <Form.Label>
                            <b className="bold">Custodian Employee:</b> Are you
                            an employee of, or affiliated with Apex Fintech
                            Solutions?
                          </Form.Label>
                        </Form.Row>
                      </article>
                    </section>
                    <section className="form-sec-2col">
                      <article className="col-form">
                        <div className="disclosure-submit-row">
                          {this.props.error && (
                            <Alert type="error" msg={this.props.error} />
                          )}
                          {!this.validDocuments() &&
                          values.exchangeOrFINRA === true &&
                          this.state.submitting ? (
                            <Alert
                              type="error"
                              msg="Document upload required."
                            />
                          ) : null}
                          {values.isCustodianEmployee && (
                            <Alert
                              type="warning"
                              msg="You have selected that you are an employee of, or affiliated with Apex Fintech Solutions, this is not a common scenario. Please ensure you have selected the correct option."
                            />
                          )}
                          {values.isCustodianEmployee &&
                          values.exchangeOrFINRA ? (
                            <Alert
                              type="error"
                              msg="You cannot be a custodian employee and affiliated with a member firm of a Stock Exchange or FINRA."
                            />
                          ) : null}
                          <div className="submit-row btn-row">
                            {this.props.isRolloverAccount && (
                              <Button
                                type="button"
                                btnLabel={"Cancel"}
                                color={"cancel"}
                                name="cancel"
                                onClick={this._onCancel}
                              />
                            )}
                            <Button
                              name="submit"
                              withArrow={true}
                              loading={this.props.isFetching}
                            />
                          </div>
                        </div>
                      </article>
                    </section>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };
}

const actions = [
  userConstants.USER_ON_BOARDING_DISCLOSURE_UPDATE,
  userConstants.ADD_ROLLOVER_ACCOUNT_DISCLOSURE,
  accountConstants.GET_ACCOUNTS,
];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    userState: state.user.userState.state,
    error: errorSelector(state),
    documents: state.userDocuments.documents,
    isFetching: loadingSelector(state),
    isRolloverAccount: isRolloverSelector(state),
  };
};
const mapDispatchToProps = {
  updateIraAccountDisclosure,
  addRolloverAccountDisclosure,
  getUserState,
  getAllAccounts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualDisclosure));
