import React from "react";
import * as Sentry from "@sentry/react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { push } from "connected-react-router";
import { withApollo } from "@apollo/client/react/hoc";
import { get, isEmpty, isNil } from "lodash";
import { Navbar, NavDropdown } from "react-bootstrap";
import { useAuth0 } from "utils/react-auth0-wrapper";
import { intercomHashSelector } from "store/selectors/auth0";
import { setAccountSession } from "actions/accountActions";
import {
  employerHasFullyKybd,
  requiresBillingMicroDepositVerification,
  requiresBillingPaymentUpdate,
} from "store/selectors/employer";
import { FiCreditCard, FiFileText } from "react-icons/fi";

import queryString from "query-string";
import EmployerSwitcher from "components/EmployerSwitcher";
// import EmployerReferralLink from "components/EmployerReferralLink";
import "components/navbar/NavBar.scss";
import NavBrand from "components/navbar/NavBrand";
import IconNavDropdown from "components/navbar/IconNavDropdown";
import SideBarToggleButton from "components/navbar/SideBarToggleButton";
import NavLoginHeaderText from "components/navbar/NavLoginHeaderText";

const EmployerNavBar = ({
  push,
  intercomHash,
  userState,
  userId,
  isMultiAccount,
  billingPortalURL,
  // canReferCompany,
  employerHasKYB,
}) => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  if (!isNil(userId)) {
    window.analytics.identify(
      userId,
      {
        email: user.email,
        invitationCode: queryString.parse(window.location.search)
          .invitationCode,
      },
      {
        integrations: {
          Intercom: {
            user_hash: intercomHash,
          },
        },
      }
    );
    Sentry.setUser({ id: userId });
  }

  const logoutWithRedirect = async () => {
    window.analytics.track("Logged Out");
    window.analytics.reset();

    logout({
      returnTo: window.location.origin + "/login",
    });
  };

  return (
    <div>
      <header id="header" className={"icon-header"}>
        <Navbar>
          <div className="nav-left">
            <NavBrand />
            <Navbar.Text>
              <p className="portal">{"Employer"}</p>
            </Navbar.Text>
          </div>

          <div className="employer-switcher">
            {isMultiAccount && <EmployerSwitcher />}
          </div>

          <div className="nav-right">
            {!isAuthenticated && (
              <NavLoginHeaderText onClick={loginWithRedirect} />
            )}

            {isAuthenticated && !isEmpty(userState) && (
              <>
                <IconNavDropdown
                  userName={user.nickname}
                  onLogout={logoutWithRedirect}
                >
                  {billingPortalURL && (
                    <NavDropdown.Item
                      eventKey="4.3"
                      href={billingPortalURL}
                      onClick={() => {
                        window.analytics.track("Billing Portal Link Clicked");
                      }}
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      <FiCreditCard
                        color={"#FFFFFF"}
                        fill={"#FFFFFF"}
                        stroke={"#32325d"}
                        className="dropdown-icons"
                        size={14}
                      />
                      Billing
                    </NavDropdown.Item>
                  )}
                  {employerHasKYB && (
                    <>
                      <NavDropdown.Item
                        eventKey="4.2"
                        onClick={() => push("/dashboard/documents")}
                      >
                        <FiFileText
                          color={"#FFFFFF"}
                          fill={"#FFFFFF"}
                          stroke={"#32325d"}
                          className="dropdown-icons"
                          size={14}
                        />
                        Documents
                      </NavDropdown.Item>
                    </>
                  )}
                </IconNavDropdown>

                <SideBarToggleButton />
              </>
            )}
          </div>

          {/* <div className="employer-referral">
            {canReferCompany && <EmployerReferralLink />}
          </div> */}
        </Navbar>
      </header>
    </div>
  );
};

EmployerNavBar.propTypes = {
  userId: PropTypes.string,
  intercomHash: PropTypes.string,
  userState: PropTypes.string,
  billingPortalURL: PropTypes.string,
  pathname: PropTypes.string,
  push: PropTypes.func,
  client: PropTypes.object,
  isMultiAccount: PropTypes.bool,
  // canReferCompany: PropTypes.bool,
  showChangeEmail: PropTypes.bool,
  employerHasKYB: PropTypes.bool,
  setAccountSession: PropTypes.func,
};

const mapStateToProps = (state) => {
  const billingPortalURL =
    requiresBillingMicroDepositVerification(state) ||
    requiresBillingPaymentUpdate(state)
      ? null
      : get(state.employer, "billingPortalURL", null);

  const pathname = get(state.router, "location.pathname");

  return {
    pathname,
    userState: get(state.user, "userState.state", ""),
    userId: state.user.id,
    intercomHash: intercomHashSelector(state),
    isMultiAccount: state.employer.isMultiAccount,
    billingPortalURL,
    // canReferCompany: canReferCompany(state),
    employerHasKYB: employerHasFullyKybd(state),
  };
};

const mapDispatchToProps = {
  push,
  setAccountSession,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(EmployerNavBar));
