import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";
import "./SelectAddressCard.css";

const SelectAddressCard = ({
  address,
  isSelected,
  isInvalid,
  onSelect,
  userName,
}) => {
  const { address1, address2, state, city, postalCode } = address;

  const cardClass = classnames("select-address-card", {
    selected: isSelected,
    shadow: !isInvalid,
    invalid: isInvalid,
  });

  if (isInvalid) {
    return (
      <div className={cardClass}>
        <p>
          USPS could not your validate address. Please check that the address
          you entered is valid.
        </p>
      </div>
    );
  } else {
    return (
      <div className={cardClass} onClick={onSelect}>
        <p>{userName}</p>
        <p>{address1}</p>
        {address2 && <p>{address2}</p>}
        <p>
          {city}, {state} {postalCode}
        </p>
      </div>
    );
  }
};

SelectAddressCard.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    state: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onSelect: PropTypes.func,
  userName: PropTypes.string,
};

export default SelectAddressCard;
