import { navigationConstants } from "./types";
import ActionCreator from "utils/actionHandler";

export const setTab = (tabObject, tabValue, topicId) => {
  return (dispatch) => {
    var tab = {
      tabObject,
      tabValue,
      topicId,
    };
    const actionCreator = new ActionCreator(
      navigationConstants.NAVIGATION_SET_TAB
    );

    dispatch(actionCreator.request());

    try {
      dispatch(actionCreator.success(tab));
    } catch (error) {
      console.log("setTab Error: " + error);
      dispatch(actionCreator.failure(error));
    }
  };
};

export const setCompany = (companyId, companyName) => {
  return (dispatch) => {
    var company = {
      companyId,
      companyName,
    };
    const actionCreator = new ActionCreator(
      navigationConstants.NAVIGATION_SET_COMPANY
    );

    dispatch(actionCreator.request());

    try {
      dispatch(actionCreator.success(company));
    } catch (error) {
      console.log("setCompany Error: " + error);
      dispatch(actionCreator.failure(error));
    }
  };
};
