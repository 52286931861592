export const countryValues = [
  { value: "USA", description: "United States", key: "1" },
  { value: "AFG", description: "Afghanistan", key: "2" },
  { value: "ALA", description: "Åland Islands", key: "3" },
  { value: "ALB", description: "Albania", key: "4" },
  { value: "DZA", description: "Algeria", key: "5" },
  { value: "ASM", description: "American Samoa", key: "6" },
  { value: "AND", description: "Andorra", key: "7" },
  { value: "AGO", description: "Angola", key: "8" },
  { value: "AIA", description: "Anguilla", key: "9" },
  { value: "ATA", description: "Antarctica", key: "10" },
  { value: "ATG", description: "Antigua and Barbuda", key: "11" },
  { value: "ARG", description: "Argentina", key: "12" },
  { value: "ARM", description: "Armenia", key: "13" },
  { value: "ABW", description: "Aruba", key: "14" },
  { value: "AUS", description: "Australia", key: "15" },
  { value: "AUT", description: "Austria", key: "16" },
  { value: "AZE", description: "Azerbaijan", key: "17" },
  { value: "BHS", description: "Bahamas", key: "18" },
  { value: "BHR", description: "Bahrain", key: "19" },
  { value: "BGD", description: "Bangladesh", key: "20" },
  { value: "BRB", description: "Barbados", key: "21" },
  { value: "BLR", description: "Belarus", key: "22" },
  { value: "BEL", description: "Belgium", key: "23" },
  { value: "BLZ", description: "Belize", key: "24" },
  { value: "BEN", description: "Benin", key: "25" },
  { value: "BMU", description: "Bermuda", key: "26" },
  { value: "BTN", description: "Bhutan", key: "27" },
  { value: "BOL", description: "Bolivia, Plurinational State of", key: "28" },
  { value: "BES", description: "Bonaire, Sint Eustatius and Saba", key: "29" },
  { value: "BIH", description: "Bosnia and Herzegovina", key: "30" },
  { value: "BWA", description: "Botswana", key: "31" },
  { value: "BVT", description: "Bouvet Island", key: "32" },
  { value: "BRA", description: "Brazil", key: "33" },
  { value: "IOT", description: "British Indian Ocean Territory", key: "34" },
  { value: "BRN", description: "Brunei Darussalam", key: "35" },
  { value: "BGR", description: "Bulgaria", key: "36" },
  { value: "BFA", description: "Burkina Faso", key: "37" },
  { value: "BDI", description: "Burundi", key: "38" },
  { value: "KHM", description: "Cambodia", key: "39" },
  { value: "CMR", description: "Cameroon", key: "40" },
  { value: "CAN", description: "Canada", key: "41" },
  { value: "CPV", description: "Cape Verde", key: "42" },
  { value: "CYM", description: "Cayman Islands", key: "43" },
  { value: "CAF", description: "Central African Republic", key: "44" },
  { value: "TCD", description: "Chad", key: "45" },
  { value: "CHL", description: "Chile", key: "46" },
  { value: "CHN", description: "China", key: "47" },
  { value: "CXR", description: "Christmas Island", key: "48" },
  { value: "CCK", description: "Cocos (Keeling) Islands", key: "49" },
  { value: "COL", description: "Colombia", key: "50" },
  { value: "COM", description: "Comoros", key: "51" },
  { value: "COG", description: "Congo", key: "52" },
  {
    value: "COD",
    description: "Congo, the Democratic Republic of the",
    key: "53",
  },
  { value: "COK", description: "Cook Islands", key: "54" },
  { value: "CRI", description: "Costa Rica", key: "55" },
  { value: "CIV", description: "Côte d'Ivoire", key: "56" },
  { value: "HRV", description: "Croatia", key: "57" },
  { value: "CUB", description: "Cuba", key: "58" },
  { value: "CUW", description: "Curaçao", key: "59" },
  { value: "CYP", description: "Cyprus", key: "60" },
  { value: "CZE", description: "Czech Republic", key: "61" },
  { value: "DNK", description: "Denmark", key: "62" },
  { value: "DJI", description: "Djibouti", key: "63" },
  { value: "DMA", description: "Dominica", key: "64" },
  { value: "DOM", description: "Dominican Republic", key: "65" },
  { value: "ECU", description: "Ecuador", key: "66" },
  { value: "EGY", description: "Egypt", key: "67" },
  { value: "SLV", description: "El Salvador", key: "68" },
  { value: "GNQ", description: "Equatorial Guinea", key: "69" },
  { value: "ERI", description: "Eritrea", key: "70" },
  { value: "EST", description: "Estonia", key: "71" },
  { value: "ETH", description: "Ethiopia", key: "72" },
  { value: "FLK", description: "Falkland Islands (Malvinas)", key: "73" },
  { value: "FRO", description: "Faroe Islands", key: "74" },
  { value: "FJI", description: "Fiji", key: "75" },
  { value: "FIN", description: "Finland", key: "76" },
  { value: "FRA", description: "France", key: "77" },
  { value: "GUF", description: "French Guiana", key: "78" },
  { value: "PYF", description: "French Polynesia", key: "79" },
  { value: "ATF", description: "French Southern Territories", key: "80" },
  { value: "GAB", description: "Gabon", key: "81" },
  { value: "GMB", description: "Gambia", key: "82" },
  { value: "GEO", description: "Georgia", key: "83" },
  { value: "DEU", description: "Germany", key: "84" },
  { value: "GHA", description: "Ghana", key: "85" },
  { value: "GIB", description: "Gibraltar", key: "86" },
  { value: "GRC", description: "Greece", key: "87" },
  { value: "GRL", description: "Greenland", key: "88" },
  { value: "GRD", description: "Grenada", key: "89" },
  { value: "GLP", description: "Guadeloupe", key: "90" },
  { value: "GUM", description: "Guam", key: "91" },
  { value: "GTM", description: "Guatemala", key: "92" },
  { value: "GGY", description: "Guernsey", key: "93" },
  { value: "GIN", description: "Guinea", key: "94" },
  { value: "GNB", description: "Guinea-Bissau", key: "95" },
  { value: "GUY", description: "Guyana", key: "96" },
  { value: "HTI", description: "Haiti", key: "97" },
  { value: "HMD", description: "Heard Island and McDonald Islands", key: "98" },
  { value: "VAT", description: "Holy See (Vatican City State)", key: "99" },
  { value: "HND", description: "Honduras", key: "100" },
  { value: "HKG", description: "Hong Kong", key: "101" },
  { value: "HUN", description: "Hungary", key: "102" },
  { value: "ISL", description: "Iceland", key: "103" },
  { value: "IND", description: "India", key: "104" },
  { value: "IDN", description: "Indonesia", key: "105" },
  { value: "IRN", description: "Iran, Islamic Republic of", key: "106" },
  { value: "IRQ", description: "Iraq", key: "107" },
  { value: "IRL", description: "Ireland", key: "108" },
  { value: "IMN", description: "Isle of Man", key: "109" },
  { value: "ISR", description: "Israel", key: "110" },
  { value: "ITA", description: "Italy", key: "111" },
  { value: "JAM", description: "Jamaica", key: "112" },
  { value: "JPN", description: "Japan", key: "113" },
  { value: "JEY", description: "Jersey", key: "114" },
  { value: "JOR", description: "Jordan", key: "115" },
  { value: "KAZ", description: "Kazakhstan", key: "116" },
  { value: "KEN", description: "Kenya", key: "117" },
  { value: "KIR", description: "Kiribati", key: "118" },
  {
    value: "PRK",
    description: "Korea, Democratic People's Republic of",
    key: "119",
  },
  { value: "KOR", description: "Korea, Republic of", key: "120" },
  { value: "KWT", description: "Kuwait", key: "121" },
  { value: "KGZ", description: "Kyrgyzstan", key: "122" },
  { value: "LAO", description: "Lao People's Democratic Republic", key: "123" },
  { value: "LVA", description: "Latvia", key: "124" },
  { value: "LBN", description: "Lebanon", key: "125" },
  { value: "LSO", description: "Lesotho", key: "126" },
  { value: "LBR", description: "Liberia", key: "127" },
  { value: "LBY", description: "Libya", key: "128" },
  { value: "LIE", description: "Liechtenstein", key: "129" },
  { value: "LTU", description: "Lithuania", key: "130" },
  { value: "LUX", description: "Luxembourg", key: "131" },
  { value: "MAC", description: "Macao", key: "132" },
  {
    value: "MKD",
    description: "Macedonia, the former Yugoslav Republic of",
    key: "133",
  },
  { value: "MDG", description: "Madagascar", key: "134" },
  { value: "MWI", description: "Malawi", key: "135" },
  { value: "MYS", description: "Malaysia", key: "136" },
  { value: "MDV", description: "Maldives", key: "137" },
  { value: "MLI", description: "Mali", key: "138" },
  { value: "MLT", description: "Malta", key: "139" },
  { value: "MHL", description: "Marshall Islands", key: "140" },
  { value: "MTQ", description: "Martinique", key: "141" },
  { value: "MRT", description: "Mauritania", key: "142" },
  { value: "MUS", description: "Mauritius", key: "143" },
  { value: "MYT", description: "Mayotte", key: "144" },
  { value: "MEX", description: "Mexico", key: "145" },
  { value: "FSM", description: "Micronesia, Federated States of", key: "146" },
  { value: "MDA", description: "Moldova, Republic of", key: "147" },
  { value: "MCO", description: "Monaco", key: "148" },
  { value: "MNG", description: "Mongolia", key: "149" },
  { value: "MNE", description: "Montenegro", key: "150" },
  { value: "MSR", description: "Montserrat", key: "151" },
  { value: "MAR", description: "Morocco", key: "152" },
  { value: "MOZ", description: "Mozambique", key: "153" },
  { value: "MMR", description: "Myanmar", key: "154" },
  { value: "NAM", description: "Namibia", key: "155" },
  { value: "NRU", description: "Nauru", key: "156" },
  { value: "NPL", description: "Nepal", key: "157" },
  { value: "NLD", description: "Netherlands", key: "158" },
  { value: "NCL", description: "New Caledonia", key: "159" },
  { value: "NZL", description: "New Zealand", key: "160" },
  { value: "NIC", description: "Nicaragua", key: "161" },
  { value: "NER", description: "Niger", key: "162" },
  { value: "NGA", description: "Nigeria", key: "163" },
  { value: "NIU", description: "Niue", key: "164" },
  { value: "NFK", description: "Norfolk Island", key: "165" },
  { value: "MNP", description: "Northern Mariana Islands", key: "166" },
  { value: "NOR", description: "Norway", key: "167" },
  { value: "OMN", description: "Oman", key: "168" },
  { value: "PAK", description: "Pakistan", key: "169" },
  { value: "PLW", description: "Palau", key: "170" },
  { value: "PSE", description: "Palestinian Territory, Occupied", key: "171" },
  { value: "PAN", description: "Panama", key: "172" },
  { value: "PNG", description: "Papua New Guinea", key: "173" },
  { value: "PRY", description: "Paraguay", key: "174" },
  { value: "PER", description: "Peru", key: "175" },
  { value: "PHL", description: "Philippines", key: "176" },
  { value: "PCN", description: "Pitcairn", key: "177" },
  { value: "POL", description: "Poland", key: "178" },
  { value: "PRT", description: "Portugal", key: "179" },
  { value: "PRI", description: "Puerto Rico", key: "180" },
  { value: "QAT", description: "Qatar", key: "181" },
  { value: "REU", description: "Réunion", key: "182" },
  { value: "ROU", description: "Romania", key: "183" },
  { value: "RUS", description: "Russian Federation", key: "184" },
  { value: "RWA", description: "Rwanda", key: "185" },
  { value: "BLM", description: "Saint Barthélemy", key: "186" },
  {
    value: "SHN",
    description: "Saint Helena, Ascension and Tristan da Cunha",
    key: "187",
  },
  { value: "KNA", description: "Saint Kitts and Nevis", key: "188" },
  { value: "LCA", description: "Saint Lucia", key: "189" },
  { value: "MAF", description: "Saint Martin (French part)", key: "190" },
  { value: "SPM", description: "Saint Pierre and Miquelon", key: "191" },
  { value: "VCT", description: "Saint Vincent and the Grenadines", key: "192" },
  { value: "WSM", description: "Samoa", key: "193" },
  { value: "SMR", description: "San Marino", key: "194" },
  { value: "STP", description: "Sao Tome and Principe", key: "195" },
  { value: "SAU", description: "Saudi Arabia", key: "196" },
  { value: "SEN", description: "Senegal", key: "197" },
  { value: "SRB", description: "Serbia", key: "198" },
  { value: "SYC", description: "Seychelles", key: "199" },
  { value: "SLE", description: "Sierra Leone", key: "200" },
  { value: "SGP", description: "Singapore", key: "201" },
  { value: "SXM", description: "Sint Maarten (Dutch part)", key: "202" },
  { value: "SVK", description: "Slovakia", key: "203" },
  { value: "SVN", description: "Slovenia", key: "204" },
  { value: "SLB", description: "Solomon Islands", key: "205" },
  { value: "SOM", description: "Somalia", key: "206" },
  { value: "ZAF", description: "South Africa", key: "207" },
  {
    value: "SGS",
    description: "South Georgia and the South Sandwich Islands",
    key: "208",
  },
  { value: "SSD", description: "South Sudan", key: "209" },
  { value: "ESP", description: "Spain", key: "210" },
  { value: "LKA", description: "Sri Lanka", key: "211" },
  { value: "SDN", description: "Sudan", key: "212" },
  { value: "SUR", description: "Suriname", key: "213" },
  { value: "SJM", description: "Svalbard and Jan Mayen", key: "214" },
  { value: "SWZ", description: "Swaziland", key: "215" },
  { value: "SWE", description: "Sweden", key: "216" },
  { value: "CHE", description: "Switzerland", key: "217" },
  { value: "SYR", description: "Syrian Arab Republic", key: "218" },
  { value: "TWN", description: "Taiwan, Province of China", key: "219" },
  { value: "TJK", description: "Tajikistan", key: "220" },
  { value: "TZA", description: "Tanzania, United Republic of", key: "221" },
  { value: "THA", description: "Thailand", key: "222" },
  { value: "TLS", description: "Timor-Leste", key: "223" },
  { value: "TGO", description: "Togo", key: "224" },
  { value: "TKL", description: "Tokelau", key: "225" },
  { value: "TON", description: "Tonga", key: "226" },
  { value: "TTO", description: "Trinidad and Tobago", key: "227" },
  { value: "TUN", description: "Tunisia", key: "228" },
  { value: "TUR", description: "Turkey", key: "229" },
  { value: "TKM", description: "Turkmenistan", key: "230" },
  { value: "TCA", description: "Turks and Caicos Islands", key: "231" },
  { value: "TUV", description: "Tuvalu", key: "232" },
  { value: "UGA", description: "Uganda", key: "233" },
  { value: "UKR", description: "Ukraine", key: "234" },
  { value: "ARE", description: "United Arab Emirates", key: "235" },
  { value: "GBR", description: "United Kingdom", key: "236" },
  {
    value: "UMI",
    description: "United States Minor Outlying Islands",
    key: "237",
  },
  { value: "URY", description: "Uruguay", key: "238" },
  { value: "UZB", description: "Uzbekistan", key: "239" },
  { value: "VUT", description: "Vanuatu", key: "240" },
  {
    value: "VEN",
    description: "Venezuela, Bolivarian Republic of",
    key: "241",
  },
  { value: "VNM", description: "Viet Nam", key: "242" },
  { value: "VGB", description: "Virgin Islands, British", key: "243" },
  { value: "VIR", description: "Virgin Islands, U.S.", key: "244" },
  { value: "WLF", description: "Wallis and Futuna", key: "245" },
  { value: "ESH", description: "Western Sahara", key: "246" },
  { value: "YEM", description: "Yemen", key: "247" },
  { value: "ZMB", description: "Zambia", key: "248" },
  { value: "ZWE", description: "Zimbabwe", key: "249" },
];

export const states = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export default {
  countryValues,
  states,
};
