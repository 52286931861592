export const loading = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  // ignore any action that isn't relevant to api calls being made
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // if request is happening then loading will be true, once the success or failure action is invoked
    // it will switch to false
    [requestName]: requestState === "REQUEST",
  };
};
