import React from "react";
import { Formik } from "formik";
import { Col, Form } from "react-bootstrap";
import { isObject } from "lodash";
import InputMask from "react-input-mask";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import moment from "moment";
import { push } from "connected-react-router";

import { kybRoles } from "statics/businessCodes";
import {
  address2Validator,
  postalCodeValidator,
  testPhoneNumber,
  testSsn,
  validateUsersAddress,
} from "utils/fieldValidators";
import { userService } from "services/userService";

import StateSelect from "components/StateSelect";
import Alert from "components/Alert";
import Button from "components/Button";
import AddressValidator from "components/AddressValidator";
import { ScrollToFieldError } from "utils/form";
import PlanCard from "pages/signUp/employer/PlanCard";

let yup = require("yup");

const schema = yup.object({
  firstName: yup
    .string()
    .label("First")
    .required()
    .min(1, "Must be at least one character")
    .max(30),
  lastName: yup
    .string()
    .label("Last")
    .required()
    .min(1, "Must be at least one character")
    .max(40),
  email: yup
    .string()
    .label("Email")
    .email("You must enter a valid email address")
    .max(200)
    .required(),
  dob: yup
    .date()
    .label("Date of Birth")
    .required()
    .typeError("Invalid Date of Birth")
    .test("dob", "You must be at least 18 to create an account", (value) => {
      return moment().diff(moment(value), "years") >= 18;
    }),
  confirmDob: yup
    .string()
    .trim()
    .required()
    .label("Confirm Date of Birth")
    .test("dob-match", "Date of births must match", function (value) {
      return moment(this.parent.dob).isSame(moment(value));
    }),
  ssn: yup
    .string()
    .trim()
    .required()
    .label("Social Security Number")
    .test("ssn", "${path} must be of format 111-11-1111", testSsn),
  confirmSsn: yup
    .string()
    .trim()
    .required()
    .label("Confirm Social Security Number")
    .test("ssn-match", "Social Security Numbers must match", function (value) {
      return this.parent.ssn === value;
    }),
  role: yup.string().required().label("Please choose a Role").max(200),
  ownershipStake: yup
    .number()
    .when("role", {
      is: "ControllingOfficer",
      then: yup.number().nullable(true),
      otherwise: yup
        .number()
        .required()
        .min(
          25,
          "Must be at least 25%, if less please select Controller / Officer role"
        )
        .max(100, "Cannot be more than 100%"),
    })
    .label("Ownership Percentage"),
  address1: yup
    .string()
    .label("Address 1")
    .required()
    .min(3, "Must be at least three characters.")
    .max(40),
  address2: address2Validator,
  city: yup.string().label("City").required().max(40),
  state: yup.string().label("State").required().max(2),
  postalCode: postalCodeValidator,
  phone: yup
    .string()
    .label("Phone")
    .required()
    .test(
      "us-phone",
      "Not a valid phone number. Ex: 123-456-7890",
      testPhoneNumber
    )
    .max(30),
});

/* eslint-disable react/no-children-prop */

class ControllerForm extends React.PureComponent {
  static propTypes = {
    ownerInfo: PropTypes.object,
    error: PropTypes.string,
    isFetching: PropTypes.func,
    push: PropTypes.func,
    isAdminPortal: PropTypes.bool,
    companyId: PropTypes.string,
    planCode: PropTypes.string,
    onSubmit: PropTypes.func,
    client: PropTypes.object,
  };

  constructor() {
    super();

    this.state = {
      fetchingAddress: false,
      showAddressModal: false,
      validatedAddress: {},
    };
  }

  submitUser = async (values, skipAddressValidation = false) => {
    // verify user address, if it matches USPS just continue on
    // if USPS endpoint fails continue on
    // if there is a mismatch then open modal to allow customer to decide which to use
    if (!skipAddressValidation) {
      try {
        this.setState({ fetchingAddress: true });

        const userAddress = {
          address1: values.address1,
          address2: values.address2,
          state: values.state,
          postalCode: values.postalCode,
          city: values.city,
        };

        const validatedAddress = await userService.getValidatedAddress(
          this.props.client,
          userAddress
        );

        this.setState({ fetchingAddress: false });

        const userAddressIsValid = validateUsersAddress(
          userAddress,
          validatedAddress
        );

        if (!userAddressIsValid) {
          this.setState({
            showAddressModal: true,
            validatedAddress,
          });

          return;
        }
      } catch (error) {
        // if the validation endpoint fails we dont want to prevent user from saving so continue on and log error
        console.log(error);
      }
    }

    let stake = parseFloat(values.ownershipStake);
    if (values.role === "ControllingOfficer") {
      stake = 0;
    }

    let identity = {
      employerId: this.props.companyId,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      ssn: values.ssn,
      role: values.role,
      ownershipStake: stake,
      dob: moment(values.dob).format("YYYY/MM/DD"),
      address1: values.address1,
      address2: values.address2 || "", //graphql does not like null instead of empty string
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      phone: values.phone,
      id: this.props.ownerInfo.id || "",
    };

    this.props.onSubmit(identity);
  };

  _closeModal = () => {
    this.setState({ showAddressModal: false });
  };

  render() {
    const btnLabel = this.props.isAdminPortal ? "Submit User" : "Continue";

    var articleStyle = {
      paddingBottom: 0,
    };

    const ownerInfo = isObject(this.props.ownerInfo)
      ? this.props.ownerInfo
      : {};

    return (
      <div className={"controller-form"}>
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={(values) => {
            this.submitUser(values);
          }}
          enableReinitialize={true}
          initialValues={{
            ...ownerInfo,
            confirmSsn: ownerInfo.ssn || "",
            confirmDob: ownerInfo.dob || "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              {this.state.showAddressModal && (
                <AddressValidator
                  show={this.state.showAddressModal}
                  onSubmit={this.submitUser}
                  setFieldValue={setFieldValue}
                  validatedAddress={this.state.validatedAddress}
                  onClose={this._closeModal}
                  values={values}
                  allowPoBox={true}
                />
              )}
              <div className="mega-container">
                <div
                  className="step-container is-active"
                  data-circle-percent="100"
                >
                  <section className="page-title-wrap">
                    <article className="text-cell">
                      <p className="page-title">Contact Verification</p>
                    </article>
                  </section>

                  <div id="form-employer-profile">
                    <section className="form-sec-2col">
                      <article className="col-form" style={articleStyle}>
                        <article className="text-cell">
                          <p className="page-label">
                            {`${
                              values.role === "ControllingOfficer"
                                ? "Controller"
                                : "Owner"
                            } Personal Information`}{" "}
                          </p>
                        </article>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formBasicFirstName">
                            <Form.Control
                              name="firstName"
                              placeholder="Legal first name"
                              value={values.firstName}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              isInvalid={
                                touched.firstName && !!errors.firstName
                              }
                              isValid={touched.firstName && !errors.firstName}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.firstName}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formBasicLastName">
                            <Form.Control
                              name="lastName"
                              placeholder="Legal last name"
                              value={values.lastName}
                              onChange={handleChange}
                              isInvalid={touched.lastName && !!errors.lastName}
                              isValid={touched.lastName && !errors.lastName}
                              onBlur={handleBlur}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.lastName}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formBasicEmail">
                            <Form.Control
                              type="email"
                              name="email"
                              placeholder="Work Email Address"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.email && !!errors.email}
                              isValid={touched.email && !errors.email}
                              disabled={this.props.isAdminPortal ? false : true}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formBasicDateOfBirth">
                            <Form.Label>Date of Birth</Form.Label>
                            <InputMask
                              name="dob"
                              placeholder="MM/DD/YYYY"
                              mask="99/99/9999"
                              value={values.dob}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.dob && !!errors.dob}
                              isValid={touched.dob && !errors.dob}
                              children={(props) => <Form.Control {...props} />}
                            ></InputMask>
                            <Form.Control.Feedback type="invalid">
                              {errors.dob}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formBasicDateOfBirth">
                            <Form.Label>Confirm Date of Birth</Form.Label>
                            <InputMask
                              name="confirmDob"
                              placeholder="MM/DD/YYYY"
                              mask="99/99/9999"
                              value={values.confirmDob}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.confirmDob && !!errors.confirmDob
                              }
                              isValid={touched.confirmDob && !errors.confirmDob}
                              children={(props) => <Form.Control {...props} />}
                            ></InputMask>
                            <Form.Control.Feedback type="invalid">
                              {errors.confirmDob}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formBasicSsnField">
                            <Form.Label>Social Security Number</Form.Label>
                            <InputMask
                              name="ssn"
                              mask="999-99-9999"
                              placeholder="111-11-1111"
                              value={values.ssn}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.ssn && !!errors.ssn}
                              isValid={touched.ssn && !errors.ssn}
                              children={(props) => <Form.Control {...props} />}
                            ></InputMask>
                            <Form.Control.Feedback type="invalid">
                              {errors.ssn}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="formBasicSsnConfirmField"
                          >
                            <Form.Label>
                              Confirm Social Security Number
                            </Form.Label>
                            <InputMask
                              name="confirmSsn"
                              mask="999-99-9999"
                              placeholder="000-00-0000"
                              value={values.confirmSsn}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.confirmSsn && !!errors.confirmSsn
                              }
                              isValid={touched.confirmSsn && !errors.confirmSsn}
                              children={(props) => <Form.Control {...props} />}
                            ></InputMask>
                            <Form.Control.Feedback type="invalid">
                              {errors.confirmSsn}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} sm={9} controlId="formBasicRole">
                            <Form.Control
                              as="select"
                              name="role"
                              disabled={!this.props.isAdminPortal}
                              value={values.role}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.role && !!errors.role}
                              isValid={touched.role && !errors.role}
                            >
                              <option value="" disabled>
                                Role
                              </option>
                              {kybRoles.map((item) => (
                                <option value={item.value} key={item.value}>
                                  {item.description}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.role}
                            </Form.Control.Feedback>
                          </Form.Group>
                          {values.role === "ControllingOfficer" ||
                          values.role === "" ? (
                            <div></div>
                          ) : (
                            <Form.Group
                              as={Col}
                              sm={3}
                              controlId="formBasicOwnership"
                            >
                              <InputMask
                                name="ownershipStake"
                                mask="999"
                                placeholder="xxx%"
                                maskChar={null}
                                value={values.ownershipStake}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isInvalid={
                                  touched.ownershipStake &&
                                  !!errors.ownershipStake
                                }
                                isValid={
                                  touched.ownershipStake &&
                                  !errors.ownershipStake
                                }
                                children={(props) => (
                                  <Form.Control {...props} />
                                )}
                              ></InputMask>
                              <Form.Control.Feedback type="invalid">
                                {errors.ownershipStake}
                              </Form.Control.Feedback>
                            </Form.Group>
                          )}
                        </Form.Row>
                      </article>
                      <PlanCard planCode={this.props.planCode} />
                    </section>

                    <section className="form-sec-2col">
                      <article className="col-form">
                        <article className="text-cell-officer">
                          <p className="page-label">
                            {`${
                              values.role === "ControllingOfficer"
                                ? "Controller"
                                : "Owner"
                            } Personal Address`}{" "}
                          </p>
                        </article>
                        <Form.Group controlId="formBasicaddress1">
                          <Form.Control
                            name="address1"
                            placeholder="Address 1"
                            value={values.address1}
                            onChange={handleChange}
                            isInvalid={touched.address1 && !!errors.address1}
                            isValid={touched.address1 && !errors.address1}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address1}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formBasicaddress2">
                          <Form.Control
                            name="address2"
                            placeholder="Address 2"
                            value={values.address2}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.address2 && !!errors.address2}
                            isValid={touched.address2 && !errors.address2}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address2}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Row>
                          <Form.Group as={Col} controlId="formBasicCity">
                            <Form.Control
                              name="city"
                              placeholder="City"
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={touched.city && !!errors.city}
                              isValid={touched.city && !errors.city}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.city}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            sm={4}
                            controlId="formBasicState"
                          >
                            <StateSelect
                              value={values.state}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.state}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            sm={3}
                            controlId="formBasicPostalCode"
                          >
                            <Form.Control
                              name="postalCode"
                              placeholder="Zip"
                              value={values.postalCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={
                                touched.postalCode && !!errors.postalCode
                              }
                              isValid={touched.postalCode && !errors.postalCode}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.postalCode}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            sm={5}
                            controlId="formBasicPhone"
                          >
                            <InputMask
                              mask="999-999-9999"
                              name="phone"
                              placeholder="Personal Phone"
                              value={values.phone}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              isInvalid={touched.phone && !!errors.phone}
                              isValid={touched.phone && !errors.phone}
                              children={(props) => <Form.Control {...props} />}
                            ></InputMask>
                            <Form.Control.Feedback type="invalid">
                              {errors.phone}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                      </article>
                    </section>
                    {this.props.error && (
                      <Alert type="error" msg={this.props.error} />
                    )}
                    {this.props.isAdminPortal && (
                      <Button
                        btnLabel={"Back To Summary"}
                        name="action"
                        type={"button"}
                        color="cancel"
                        onClick={() => {
                          this.props.push(
                            `/employers/${this.props.companyId}/kyb/summary`
                          );
                        }}
                      />
                    )}
                    <Button
                      name="submit"
                      withArrow={true}
                      btnLabel={btnLabel}
                      disabled={
                        this.props.isFetching || this.state.fetchingAddress
                      }
                      loading={
                        this.props.isFetching || this.state.fetchingAddress
                      }
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapDispatchToProps = {
  push,
};

export default connect(null, mapDispatchToProps)(withApollo(ControllerForm));
