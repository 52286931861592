import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { adminSite } from "utils/determineSite";
import "./Loading.css";

let isAdminCheck = () => {
  if (adminSite()) {
    return "icon-header-admin";
  }
  return "icon-header";
};

const Loading = () => (
  <header id="loading-header" className={isAdminCheck()}>
    <div className="nav-container">
      <Navbar variant="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <img
              alt=""
              src="/assets/logo.svg"
              className="d-inline-block align-top"
            />
            {""}
          </Navbar.Brand>
          <div className="container-fluid full-height justify-content-center row">
            <Navbar.Text>
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            </Navbar.Text>
          </div>
        </Container>
      </Navbar>
    </div>
  </header>
);

export default Loading;
