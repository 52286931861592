import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { FiStar } from "react-icons/fi";
import { push } from "connected-react-router";
import { accountTypesToEnglishMapping } from "statics/accountTypes";
import { find, get } from "lodash";
import { setAccountSession } from "actions/accountActions";

import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import Button from "components/Button";

import "./PriorYearContributionCard.scss";

class IndividualRolloverFormCard extends React.Component {
  static propTypes = {
    push: PropTypes.func,
    accountType: PropTypes.string,
    setAccountSession: PropTypes.func,
    accountId: PropTypes.string,
    client: PropTypes.object,
  };
  constructor() {
    super();
  }

  render() {
    const title = `Good news! Your ${
      accountTypesToEnglishMapping[this.props.accountType]
    } account is now open.`;

    return (
      <>
        <div className="rollover-banner">
          <div className="rollover-box">
            <div className="employerInfo">
              <div className="employerInfo-body">
                <div>
                  <div className="circle">
                    <span className="icon">
                      <FiStar color="white" stroke="#60A4BF" size={16} />
                    </span>
                  </div>
                </div>
                <div className="employerInfo-intro">
                  <IconHeader variant="labelHeader" headerText={title} />
                  <IconSubheader subheader="To complete the rollover of your funds, please fill out this document and complete next steps." />
                </div>
              </div>
              <div className="employerInfo-action">
                <Button
                  size="sm"
                  color="action"
                  name="action"
                  btnLabel="View Rollover Form"
                  onClick={async () => {
                    await this.props.setAccountSession(
                      this.props.client,
                      this.props.accountId
                    );
                    window.location.search = `?redirect_to=/dashboard/rollover_form`;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const userState = state.user.userState.state;
  const accounts = state.accounts.iraAccounts;
  const rolloverAccount = find(accounts, { isRollover: true });
  const accountType = get(rolloverAccount, "accountType", null);
  const account = find(accounts, {
    isProcessingRollover: false,
    state: "AccountActive",
    isRollover: true,
  });
  const accountId = account ? account.id : null;

  return {
    accountId,
    userState,
    accounts,
    accountType,
  };
};

const mapDispatchToProps = {
  setAccountSession,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(IndividualRolloverFormCard));
