import React from "react";
import { PropTypes } from "prop-types";
import { size, transform } from "lodash";

import Button from "components/Button";
import IconSpinner from "components/IconSpinner";
import IconTable from "components/IconTable";
import { FiChevronLeft } from "react-icons/fi";

class EmployeeSelection extends React.PureComponent {
  static propTypes = {
    goToNamedStep: PropTypes.func,
    push: PropTypes.func,
    setSourceGroupId: PropTypes.func,
    setTargetGroupId: PropTypes.func,
    isFetching: PropTypes.bool,
    client: PropTypes.object,
    groups: PropTypes.object.isRequired,
    sourceGroupId: PropTypes.string,
    targetGroupId: PropTypes.string,
    onCancel: PropTypes.func,
    sourceGroup: PropTypes.obj,
    employeesForMigration: PropTypes.array,
    setEmployeesForMigration: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const selectedEmployeeRows = transform(
      props.employeesForMigration,
      (acc, val) => {
        acc[val] = true;
      },
      {}
    );
    this.state = {
      selectAllEmployees: false,
      selectedEmployeeRows,
    };
  }

  _buildEmployeeColumns = () => {
    const columns = [
      {
        label: "First Name",
        key: "userProfile.firstName",
      },
      {
        label: "Last Name",
        key: "userProfile.lastName",
      },
      {
        label: "Email",
        key: "userProfile.email",
      },

      {
        label: "Last 4 SSN",
        key: "userProfile.lastFourOfSsn",
      },
      {
        label: "Link Status",
        key: "status",
      },
    ];
    return columns;
  };

  _selectAllEmployees = (checked) => {
    const selectedEmployeeRows = {};
    this.props.sourceGroup.associatedLinkRequests.forEach((employee) => {
      selectedEmployeeRows[employee.employeeId] = checked;
    });
    this.setState({
      selectAllEmployees: checked,
      selectedEmployeeRows,
    });
  };

  _selectEmployee = (id, checked) => {
    const selectedEmployeeRows = {
      ...this.state.selectedEmployeeRows,
      [id]: checked,
    };

    const selectAllEmployees =
      !checked && this.state.selectAllEmployees
        ? false
        : this.state.selectAllEmployees;

    this.setState({
      selectedEmployeeRows,
      selectAllEmployees,
    });
  };

  render() {
    if (this.state.initialFetching) {
      return <IconSpinner centered />;
    }

    const numOfEmployees = size(this.props.sourceGroup.associatedLinkRequests);
    const columns = this._buildEmployeeColumns();
    const data = this.props.sourceGroup.associatedLinkRequests;

    return (
      <div>
        <div className="workflow-widget">
          <div className="action-header">
            <p className="group-info">
              <span className="group-name">
                {this.props.sourceGroup.name}:{" "}
                <span className="invite-number">
                  Employees in source group (
                  <span className="number">{numOfEmployees}</span>)
                </span>
              </span>
            </p>
          </div>
          <IconTable
            columns={columns}
            data={data}
            hasSelectableRows
            uniqueRowIdentifier="employeeId"
            onSelectAll={(e) => this._selectAllEmployees(e.target.checked)}
            hasSelectedAll={this.state.selectAllEmployees}
            onRowSelect={(id, checked) => {
              this._selectEmployee(id, checked);
            }}
            selectedRows={this.state.selectedEmployeeRows}
          />

          <div className="process-payroll-button-row">
            <Button
              icon={{
                icon: <FiChevronLeft size={14} stroke={"#0cb7c4"} />,
                position: "left",
              }}
              onClick={() => this.props.goToNamedStep("selectGroups")}
              name="cancel"
              btnLabel="Go Back"
              color="cancel"
            />
            <Button
              name="action"
              btnLabel={"Add Employees"}
              disabled={!size(this.state.selectedEmployeeRows)}
              onClick={() => {
                const selectedEmployees = transform(
                  this.state.selectedEmployeeRows,
                  (acc, val, key) => {
                    if (val) {
                      acc.push(key);
                    }
                  },
                  []
                );

                this.props.setEmployeesForMigration(selectedEmployees);
                this.props.goToNamedStep("summary");
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default EmployeeSelection;
