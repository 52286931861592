import { groupCreationConstants } from "actions/types";

export const groupCreation = (state = { groupCreation: false }, action) => {
  switch (action.type) {
    case groupCreationConstants.GROUP_CREATION_SHOW:
      return { ...state, groupCreation: true };
    case groupCreationConstants.GROUP_CREATION_HIDE:
      return { ...state, groupCreation: false };
    default:
      return state;
  }
};
