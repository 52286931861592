import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import moment from "moment";
import "./IndividualProfile.scss";

import Button from "components/Button";
import Alert from "components/Alert";
import IconSpinner from "components/IconSpinner";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";

import {
  changeEmail,
  deleteEmailChangeRequest,
  getEmailChangeRequest,
} from "actions/userActions";
import { userConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { Auth0Context } from "utils/react-auth0-wrapper";
import { changeEmailSelector } from "store/selectors/auth0";
import history from "utils/history";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import { ScrollToFieldError } from "utils/form";

let yup = require("yup");
const schema = yup.object({
  newEmail: yup
    .string()
    .label("Email")
    .email("You must enter a valid email address.")
    .required(),
});

class LoginInfo extends React.PureComponent {
  static propTypes = {
    changeEmail: PropTypes.func,
    push: PropTypes.func,
    getEmailChangeRequest: PropTypes.func,
    deleteEmailChangeRequest: PropTypes.func,
    client: PropTypes.object,
    error: PropTypes.string,
    email: PropTypes.string,
    pendingEmail: PropTypes.string,
    emailPending: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCancelling: PropTypes.bool,
    isFetching: PropTypes.bool,
    isOnboarding: PropTypes.bool,
    pendingEmailChangeRequest: PropTypes.shape({
      createdAt: PropTypes.string,
      status: PropTypes.string,
      id: PropTypes.string,
    }),
  };

  static contextType = Auth0Context;

  constructor() {
    super();

    this.state = {
      newEmail: "",
    };
  }

  componentDidMount() {
    if (this.props.pendingEmail) {
      this.props.getEmailChangeRequest(
        this.props.client,
        this.props.pendingEmail
      );
    }
  }

  _submitEmailChange = (values, { resetForm }) => {
    const changeEmail = this.props
      .changeEmail(this.props.client, values.newEmail)
      .then(() => {
        resetForm({});
      });

    if (this.props.isOnboarding) {
      changeEmail.then(() => {
        if (!this.props.error) {
          toast.success(
            "Successfully submitted email change request. Please check your email inbox to verify your new email address."
          );
          this.props.push("/");
        }
      });
    }
  };

  _calcIfPendingEmailExpired() {
    if (isEmpty(this.props.pendingEmailChangeRequest)) return false;
    if (this.props.pendingEmailChangeRequest.status === "EXPIRED") return true;
    const daysUntilExpiration = 3;
    const today = moment();
    const emailChangeCreatedAt = moment(
      this.props.pendingEmailChangeRequest.createdAt
    );
    return today.diff(emailChangeCreatedAt, "days") > daysUntilExpiration;
  }

  _deletePendingEmailChange = () => {
    this.props.deleteEmailChangeRequest(
      this.props.client,
      this.props.pendingEmailChangeRequest.id
    );
  };

  _getButton = (hasPendingEmail) => {
    if (hasPendingEmail) {
      return (
        <Button
          type="button"
          name="action"
          onClick={this._deletePendingEmailChange}
          color="red"
          loading={this.props.isCancelling}
          btnLabel="cancel change"
        />
      );
    }

    return (
      <Button
        size="sm"
        name="submit"
        withArrow={true}
        btnLabel="Update Email"
        loading={this.props.isUpdating}
      />
    );
  };

  _calcExpiringDate = () => {
    if (isEmpty(this.props.pendingEmailChangeRequest)) return null;

    return moment(this.props.pendingEmailChangeRequest.createdAt)
      .add(3, "days")
      .format("YYYY-MM-DD");
  };

  render() {
    if (this.props.isFetching) {
      return <IconSpinner centered />;
    }

    const hasPendingEmail =
      this.props.pendingEmail && !this._calcIfPendingEmailExpired();

    return (
      <div className="close-account">
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={this._submitEmailChange}
          enableReinitialize={true}
          initialValues={this.state}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            handleBlur,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <div className="page-header">
                <IconHeader variant="cardHeader" headerText="Login Info" />
                <IconSubheader subheader="Changing your email requires the new email to be verified." />
              </div>

              <Form.Group as={Col} md={6} controlId="formBasicNewEmail">
                <Form.Label>Current Email</Form.Label>
                <Form.Control
                  name="current email"
                  disabled={true}
                  value={this.props.email}
                />
              </Form.Group>
              {hasPendingEmail && (
                <Form.Group as={Col} md={6} controlId="formBasicNewEmail">
                  <Form.Label>Pending Email</Form.Label>
                  <Form.Control
                    name="pendingEmail"
                    disabled={true}
                    value={this.props.pendingEmail}
                  />
                  <p className="pending-email">
                    Email is pending verification, please check your email
                    inbox. You must continue to use previous email until new
                    email is verified. Change request will expire on{" "}
                    {this._calcExpiringDate()}.
                  </p>
                </Form.Group>
              )}
              {!hasPendingEmail && (
                <>
                  <Form.Group as={Col} md={6} controlId="formBasicNewEmail">
                    <Form.Label>New Email</Form.Label>
                    <Form.Control
                      name="newEmail"
                      placeholder="New Email"
                      value={values.newEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.newEmail && !!errors.newEmail}
                      isValid={touched.newEmail && !errors.newEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.newEmail}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
              <section className="form-sec-2col">
                <div className="submit-row btn-row">
                  {this.props.error && (
                    <Alert type="error" msg={this.props.error} />
                  )}
                  {this.props.isOnboarding && (
                    <Button
                      type="button"
                      onClick={() => {
                        history.push("/");
                      }}
                      color={"cancel"}
                      name="cancel"
                      btnLabel="Cancel"
                    />
                  )}

                  {this._getButton(hasPendingEmail)}
                </div>
              </section>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const isUpdating = createLoadingSelector(userConstants.EMAIL_CHANGE);
const isFetching = createLoadingSelector(userConstants.GET_EMAIL_CHANGE);
const isCancelling = createLoadingSelector(userConstants.DELETE_EMAIL_CHANGE);
const errorSelector = createErrorSelector([
  userConstants.EMAIL_CHANGE,
  userConstants.GET_EMAIL_CHANGE,
  userConstants.DELETE_EMAIL_CHANGE,
]);

const mapStateToProps = (state) => {
  return {
    email: state.user.auth0User.email,
    error: errorSelector(state),
    isFetching: isFetching(state),
    pendingEmail: changeEmailSelector(state),
    isCancelling: isCancelling(state),
    pendingEmailChangeRequest: state.user.pendingEmailChangeRequest,
    isUpdating: isUpdating(state),
  };
};

const mapDispatchToProps = {
  changeEmail,
  getEmailChangeRequest,
  deleteEmailChangeRequest,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(LoginInfo));
