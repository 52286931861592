import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ApolloProvider } from "@apollo/client/react";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import env from "@beam-australia/react-env";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { get, some } from "lodash";

import AccessTokenStorage from "utils/AccessTokenStorage";

export const AuthApolloContext = React.createContext();
export const useAuthApollo = () => useContext(AuthApolloContext);

const AuthApolloInitializerComponent = ({
  dispatch,
  tokenLoading,
  loading,
  loginWithRedirect,
  isAtDashboard,
  children,
}) => {
  // If Auth0 is done initializing, then begin our provider init, otherwise just provide tokenLoading state
  if (loading || tokenLoading) {
    return (
      <AuthApolloContext.Provider value={{ tokenLoading }}>
        {children}
      </AuthApolloContext.Provider>
    );
  }

  // Only init ApolloClient if we're done loading the Auth Token
  // Auth Token is required to do this init
  try {
    // Add token to http request context and headers
    const authLink = setContext(async (_, { headers }) => {
      const token = AccessTokenStorage.getToken();
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    // Setup apollo http link
    const httpLink = new HttpLink({
      uri: env("BACKEND_URI"),
    });

    // Init the client
    const apolloClient = new ApolloClient({
      link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
            );
            const hasLockedError = some(graphQLErrors, (error) =>
              error.message.includes("Individual is locked.")
            );
            if (hasLockedError && !isAtDashboard) {
              dispatch(push("/dashboard"));
            }
          }
          if (networkError) {
            console.log(`[Network error]: ${networkError}`);
            if (networkError.response && networkError.response.status === 401) {
              loginWithRedirect({
                redirect_uri: `${window.location.origin}?redirect_to=${window.location.pathname}${window.location.search}`,
              });
            }
          }
        }),
        authLink,
        httpLink,
      ]),
      cache: new InMemoryCache({ addTypename: false }),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "no-cache",
          errorPolicy: "ignore",
        },
        query: {
          fetchPolicy: "no-cache",
          errorPolicy: "all",
        },
      },
    });

    // Now that we have an ApolloClient, add ApolloProvider to the HOC chain
    return (
      <AuthApolloContext.Provider value={{ tokenLoading }}>
        <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
      </AuthApolloContext.Provider>
    );
  } catch (err) {
    console.error("ApolloClient Init Error: " + err);
  }
};

AuthApolloInitializerComponent.propTypes = {
  children: PropTypes.node,
  isAtDashboard: PropTypes.bool,
  dispatch: PropTypes.func,
  tokenLoading: PropTypes.bool,
  loading: PropTypes.bool,
  loginWithRedirect: PropTypes.func,
};

const mapStateToProps = (state) => {
  const pathname = get(state, "router.location.pathname");
  return {
    isAtDashboard: pathname === "/dashboard",
  };
};
export default connect(mapStateToProps)(
  React.memo(AuthApolloInitializerComponent)
);
