import React, { useEffect } from "react";
import { get, includes, isEmpty, isEqual, reduce } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { useAuth0 } from "utils/react-auth0-wrapper";
import {
  activeAccountState,
  isEmployerAdminSelector,
  isEmployerAgentSelector,
  isPreKycSubmissionState,
  isRolloverSelector,
  userIsLockedSelector,
} from "store/selectors/user";
import VerifyEmail from "pages/signUp/VerifyEmail";
import Home from "pages/Home";
import IndividualEnroll from "pages/signUp/individual/IndividualEnroll";
import IndividualInvestmentProfile from "pages/signUp/individual/IndividualInvestmentProfile";
import IndividualDisclosure from "pages/signUp/individual/IndividualDisclosure";
import IndividualTerms from "pages/signUp/individual/IndividualTerms";
import IndividualThanks from "pages/signUp/individual/IndividualThanks";
import IndividualDenied from "pages/signUp/individual/IndividualDenied";
import SetupContribution from "pages/signUp/individual/setupContribution/SetupContribution";
import IndividualDocsRequired from "pages/signUp/individual/IndividualDocsRequired";
import IndividualRequiredUpdates from "pages/dashboards/individualDashboard/requiredUpdates/IndividualRequiredUpdates";
import IndividualPortfolioSelection from "pages/signUp/individual/IndividualPortfolioSelection";
import IndividualAccountType from "../pages/signUp/individual/IndividualAccountType";
import ErrorBoundary from "components/ErrorBoundary";
import history from "utils/history";
import queryString from "query-string";
import EmployerEnroll from "pages/signUp/employer/EmployerEnroll";
import EmployerSelfServeController from "pages/signUp/employer/EmployerSelfServeController";
import EmployerTerms from "pages/signUp/employer/EmployerTerms";
import EmployerBilling from "pages/signUp/employer/EmployerBilling";
import {
  AccountApplicationAdditionalApexDocumentationSubmitted,
  AccountApplicationApexAutoAppeal,
  AccountApplicationApexDocumentsRequired,
  AccountApplicationDenied,
  AccountApplicationSubmitted,
  EmployerAdminActive,
  EmployerAdminLoginCreated,
  employerAdminStates,
  EmployerApplicationApproved,
  EmployerBillingDetailsComplete,
  EmployerBillingDetailsPending,
  EmployerCreated,
  EmployerSelfServeBillingDetailsComplete,
  EmployerSelfServeBillingDetailsPending,
  EmployerSelfServeControllerComplete,
  EmployerSelfServeControllerRegistrationPending,
  IndividualAccountTypeSelectionPending,
  IndividualApplicationAdditionalDocumentationSubmitted,
  IndividualApplicationAdditionalDocumentationSubmittedKYC1,
  IndividualApplicationAppealDocumentationAdminReviewPending,
  IndividualApplicationAppealDocumentationAdminReviewPendingKYC1,
  IndividualApplicationAppealSubmitted,
  IndividualApplicationAppealSubmittedKYC1,
  IndividualApplicationApproved,
  IndividualApplicationAttestationSubmitted,
  IndividualApplicationDenied,
  IndividualApplicationInfoComplete,
  IndividualApplicationRequiresAdditionalDocumentation,
  IndividualApplicationRequiresAdditionalDocumentationForAppeal,
  IndividualApplicationRequiresAdditionalDocumentationForAppealKYC1,
  IndividualApplicationRequiresAdditionalDocumentationKYC1,
  IndividualApplicationRestartedKYC2,
  IndividualApplicationSubmittedKYC1,
  IndividualApplicationSubmittedKYC2,
  IndividualCompletedForms,
  IndividualContributionPending,
  IndividualDisclosuresComplete,
  IndividualEmailVerificationComplete,
  IndividualInvestmentInfoComplete,
  IndividualInvestmentProfileUpdate,
  IndividualLoginCreated,
  IndividualPayrollContributionPending,
  IndividualPortfolioPending,
  IndividualPortfolioUpdate,
  IndividualRequiredUpdatesPending,
  IndividualSubmitSsnPending,
  IndividualTermsAccepted,
  IndividualTermsPending,
  lifecycleStates,
  onboardingStates,
} from "statics/states";
import NoState from "components/NoState";
import IndividualChangeEmail from "pages/signUp/individual/IndividualChangeEmail";
import EmailVerification from "pages/verification/EmailVerification";
import EmailVerificationRedirect from "pages/verification/EmailVerificationRedirect";
import { requiresAdministrativeUpdates } from "store/selectors/employer";
import EmployerRequiredUpdates from "pages/dashboards/employerDashboard/requiredUpdates/EmployerRequiredUpdates";
import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

// list of known query strings the app may use globally
const knownQueryStrings = {
  invitationCode: "invitationCode",
};

// want to make sure known global query strings stay around for route changes
// current use case is invitationCode which is used to tie users to employers
function pushAndPreserveQueryString(newRoute) {
  const queryObj = queryString.parse(window.location.search);
  const safeQueryString = reduce(
    queryObj,
    (acc, val, key) => {
      if (knownQueryStrings[key]) {
        acc ? (acc += `&${key}=${val}`) : (acc += `?${key}=${val}`);
      }
      return acc;
    },
    ""
  );

  history.push(newRoute + safeQueryString);
}

function showNewAdministratorRoutes(
  userStateObj,
  companyStateObj,
  isAdmin,
  isAgent
) {
  const userState = get(userStateObj, "state", "");
  const companyState = get(companyStateObj, "state", "");

  const isOnboardingAgent = isAgent && employerAdminStates.includes(userState);
  const companyActive = companyState === EmployerApplicationApproved;
  const isOnboardingSecondaryAdmin =
    isAdmin && employerAdminStates.includes(userState) && companyActive;

  return isOnboardingAgent || isOnboardingSecondaryAdmin;
}

/* eslint-disable react/display-name */
const PrivateRoute = ({
  component: Component,
  userState,
  accountState,
  isRollover,
  path = "",
  location,
  companyState,
  userRoleId,
  userIsLocked,
  fetchingState,
  isEmployerAdmin,
  isEmployerAgent,
  canChangeEmailOnboarding,
  requiresAdministrativeUpdates,
  ...rest
}) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: {
            targetUrl: `${location.pathname}${
              location.search ? location.search : ""
            }`,
          },
        });
      }
    };
    fn();
  }, [isAuthenticated, loginWithRedirect, path]);

  let render;
  let newPath = path;

  function buildOnboardingRoutes() {
    // Then update the render object to bind to their state's component, instead of generic passed through component
    /*
        console.log(
          "Private Route to a fixed Onboarding state: " + userState.state
        );
        */

    // Checks to see if user has a rollover. If they do trigger account state
    const stateToUse = isRollover ? accountState : userState.state;

    if (canChangeEmailOnboarding && includes(path, "individual/change-email")) {
      render = (props) => <IndividualChangeEmail {...props} />;
    } else if (
      canChangeEmailOnboarding &&
      includes(path, "/verify-email-redirect")
    ) {
      render = (props) => <EmailVerificationRedirect {...props} />;
    } else if (canChangeEmailOnboarding && includes(path, "/verify-email")) {
      render = (props) => <EmailVerification {...props} />;
    } else {
      switch (stateToUse) {
        case IndividualSubmitSsnPending:
        case IndividualLoginCreated:
          render = (props) => <VerifyEmail {...props} />;
          if (!includes(path, "/individual/verify")) {
            newPath = "/individual/verify";
            pushAndPreserveQueryString(newPath);
          }
          break;
        case IndividualEmailVerificationComplete:
          render = (props) => <IndividualEnroll {...props} />;
          if (!includes(path, "/individual/info")) {
            newPath = "/individual/info";
            pushAndPreserveQueryString(newPath);
          }
          break;
        case IndividualApplicationInfoComplete:
          render = (props) => <IndividualDisclosure {...props} />;
          if (path !== "/individual/disclosure") {
            newPath = "/individual/disclosure";
            pushAndPreserveQueryString(newPath);
          }
          break;
        case IndividualDisclosuresComplete:
          if (path === "/individual/investment") {
            render = (props) => <IndividualInvestmentProfile {...props} />;
          } else {
            newPath = "/individual/investment";
            pushAndPreserveQueryString(newPath);
          }
          break;
        case IndividualInvestmentInfoComplete:
          if (path === "/individual/investment") {
            render = (props) => <IndividualPortfolioSelection {...props} />;
          } else {
            newPath = "/individual/investment";
            pushAndPreserveQueryString(newPath);
          }
          break;
        case IndividualPayrollContributionPending:
          render = (props) => <SetupContribution {...props} />;
          if (!includes(path, "/individual/setup_contribution")) {
            newPath = "/individual/setup_contribution";
            pushAndPreserveQueryString(newPath);
          }
          break;
        case IndividualAccountTypeSelectionPending:
          render = (props) => <IndividualAccountType {...props} />;
          if (path !== "/individual/account_type") {
            newPath = "/individual/account_type";
            pushAndPreserveQueryString(newPath);
          }
          break;
        case IndividualTermsPending:
          render = (props) => <IndividualTerms {...props} />;
          if (path !== "/individual/terms") {
            newPath = "/individual/terms";
            pushAndPreserveQueryString(newPath);
          }
          break;
        // TODO: break these out into their own pages as they're created, for now fix them to THANKS page
        // TODO: add application approved to the case statements once we have new state machine working, for now
        // leave out so we can still go to the dashboard and test things
        case IndividualApplicationSubmittedKYC1:
        case IndividualApplicationSubmittedKYC2:
        case IndividualApplicationApproved:
        case IndividualTermsAccepted:
        case IndividualApplicationAppealSubmitted:
        case IndividualApplicationAppealSubmittedKYC1:
        case IndividualApplicationAdditionalDocumentationSubmittedKYC1:
        case IndividualApplicationAdditionalDocumentationSubmitted:
        case IndividualApplicationAppealDocumentationAdminReviewPending:
        case IndividualApplicationAppealDocumentationAdminReviewPendingKYC1:
        case IndividualApplicationAttestationSubmitted:
        case IndividualCompletedForms:
        case AccountApplicationSubmitted:
        case AccountApplicationApexAutoAppeal:
        case IndividualApplicationRestartedKYC2:
        case AccountApplicationAdditionalApexDocumentationSubmitted:
          render = (props) => <IndividualThanks {...props} />;
          if (path !== "/individual/thanks") {
            newPath = "/individual/thanks";
            pushAndPreserveQueryString(newPath);
          }
          break;

        case IndividualApplicationRequiresAdditionalDocumentationKYC1:
        case IndividualApplicationRequiresAdditionalDocumentation:
        case IndividualApplicationRequiresAdditionalDocumentationForAppeal:
        case IndividualApplicationRequiresAdditionalDocumentationForAppealKYC1:
        case AccountApplicationApexDocumentsRequired:
          render = (props) => <IndividualDocsRequired {...props} />;
          if (path !== "/individual/attention") {
            newPath = "/individual/attention";
            pushAndPreserveQueryString(newPath);
          }
          break;
        case IndividualApplicationDenied:
        case AccountApplicationDenied:
          render = (props) => <IndividualDenied {...props} />;
          if (path !== "/individual/status") {
            newPath = "/individual/status";
            pushAndPreserveQueryString(newPath);
          }
          break;
      }
    }
  }

  function buildAdministratorRoutes() {
    const isLoginOrSignUpRoute = path === "/" || path === "/login";
    // After the administrator is verified, keep them on the verify route until they click 'continue'
    // otherwise there is a double page reload that calls `userEmailConfirmed` twice
    if (
      userState.state === EmployerAdminActive &&
      includes(path, "/employer/verify")
    ) {
      render = (props) => <VerifyEmail {...props} />;
      //currently only an owner/admin can do administrative updates
    } else if (requiresAdministrativeUpdates && isEmployerAdmin) {
      render = (props) => <EmployerRequiredUpdates {...props} />;
      if (!includes(path, "/required-updates")) {
        newPath = "/required-updates";
        pushAndPreserveQueryString(newPath);
      }
    } else if (
      userState.state === EmployerAdminActive &&
      !includes(path, "/employer/verify") &&
      !isLoginOrSignUpRoute
    ) {
      render = (props) => <Component {...props} />;
    } else if (
      userState.state === EmployerAdminActive &&
      !includes(path, "/employer/verify") &&
      isLoginOrSignUpRoute
    ) {
      history.push("/dashboard");
      render = (props) => <Component {...props} />;
    } else if (userState.state === EmployerAdminLoginCreated) {
      render = (props) => <VerifyEmail {...props} />;
      if (!includes(path, "/employer/verify")) {
        newPath = "/employer/verify";
        history.push(newPath);
      }
    } else {
      render = (props) => <Component {...props} />;
    }
  }

  function buildEmployerAdminRoutes() {
    if (userState.state === EmployerAdminLoginCreated) {
      render = (props) => <VerifyEmail {...props} />;
      if (!includes(path, "/employer/verify")) {
        newPath = "/employer/verify";
        pushAndPreserveQueryString(newPath);
      }
      //currently only an owner/admin can do administrative updates
    } else if (requiresAdministrativeUpdates && isEmployerAdmin) {
      render = (props) => <EmployerRequiredUpdates {...props} />;
      if (!includes(path, "/required-updates")) {
        newPath = "/required-updates";
        pushAndPreserveQueryString(newPath);
      }
    } else if (
      userState.state === EmployerAdminActive &&
      isEmpty(companyState)
    ) {
      if (includes(path, "/employer/verify")) {
        render = (props) => <VerifyEmail {...props} />;
        return <SentryRoute path={newPath} render={render} {...rest} />;
      } else if (!includes(path, "/employer/enroll")) {
        newPath = "/employer/enroll";
        pushAndPreserveQueryString(newPath);
      }
      render = (props) => <EmployerEnroll {...props} />;
    } else if (
      !isEmpty(companyState) &&
      companyState.state === EmployerCreated
    ) {
      const onTermsPage = includes(path, "/employer/terms");
      render = (props) => <EmployerTerms {...props} />;

      if (!onTermsPage) {
        newPath = "/employer/terms";
        pushAndPreserveQueryString(newPath);
      }
    } else if (
      !isEmpty(companyState) &&
      companyState.state === EmployerSelfServeControllerRegistrationPending
    ) {
      const onInfoPage = includes(path, "/employer/info");
      render = (props) => <EmployerSelfServeController {...props} />;

      if (!onInfoPage) {
        newPath = "/employer/info";
        pushAndPreserveQueryString(newPath);
      }
    } else if (
      (!isEmpty(companyState) &&
        companyState.state === EmployerBillingDetailsPending) ||
      companyState.state === EmployerSelfServeBillingDetailsPending
    ) {
      render = (props) => <EmployerBilling {...props} />;
      const onBillingPage = includes(path, "/employer/billing");

      if (!onBillingPage) {
        newPath = "/employer/billing";
        pushAndPreserveQueryString(newPath);
      }
    } else if (
      (!isEmpty(companyState) &&
        companyState.state === EmployerSelfServeControllerComplete) ||
      companyState.state === EmployerBillingDetailsComplete ||
      companyState.state === EmployerSelfServeBillingDetailsComplete
    ) {
      const onTermsPage = includes(path, "/employer/terms");
      render = (props) => <EmployerTerms {...props} />;
      if (!onTermsPage) {
        newPath = "/employer/terms";
        pushAndPreserveQueryString(newPath);
      }
    } else if (path === "/" || path === "/login") {
      history.push("/dashboard");
      render = (props) => <Component {...props} />;
    } else {
      render = (props) => <Component {...props} />;
    }
  }

  function buildLifecycleRoutes() {
    // these apply to any user in the lifecycle state machine that includes users and employers.
    // if we have any need for distinguishing between the two we can either split by role id or build another function
    if (path === "/" || path === "/login") {
      history.push("/dashboard");
      render = (props) => <Component {...props} />;
      // no matter what state they are in these should be available
    } else if (location.pathname === "/dashboard/documents") {
      render = (props) => <Component {...props} />;
    } else if (IndividualRequiredUpdatesPending === userState.state) {
      render = (props) => <IndividualRequiredUpdates {...props} />;
      if (!includes(path, "/required-updates")) {
        newPath = "/required-updates";
        pushAndPreserveQueryString(newPath);
      }
    } else if (IndividualInvestmentProfileUpdate === userState.state) {
      if (path.includes("/individual/investment")) {
        render = (props) => <IndividualInvestmentProfile {...props} />;
      } else {
        newPath = "/individual/investment";
        history.push(newPath);
      }
    } else if (
      [IndividualPortfolioPending, IndividualPortfolioUpdate].includes(
        userState.state
      )
    ) {
      render = (props) => <IndividualPortfolioSelection {...props} />;
      if (!includes(path, "/individual/portfolio_selection")) {
        newPath = "/individual/portfolio_selection";
        pushAndPreserveQueryString(newPath);
      }
    } else if (userState.state === IndividualContributionPending) {
      render = (props) => <SetupContribution {...props} />;
      if (!includes(path, "/individual/setup_contribution")) {
        newPath = "/individual/setup_contribution";
        pushAndPreserveQueryString(newPath);
      }
    } else if (includes(path, "/individual")) {
      history.push("/dashboard");
      render = (props) => <Component {...props} />;
    } else {
      render = (props) => <Component {...props} />;
    }
  }

  // This whole section ensures that while we're onboarding, pages are tightly bound to user states
  // We don't want users or bots hopping around

  const stateToUse = isRollover ? accountState : userState.state;

  if (isAuthenticated !== true) {
    // TODO: This should be an Error page, not just a null
    console.log("Error displaying route. No Auth.");
    render = null;
    return <SentryRoute path={newPath} render={render} {...rest} />;
  }
  if (!fetchingState && !userState.state) {
    // if no state we should not return any route since we need to know routes user is authorized to view
    console.log("Error displaying route. No State.");
    render = () => <NoState />;
    return <SentryRoute path={newPath} render={render} {...rest} />;
  }
  if (userIsLocked && get(location, "pathname") !== "/dashboard") {
    newPath = "/dashboard";
    history.push(newPath);
    render = (props) => <Component {...props} />;
  } else if (
    path === "/link-bank" &&
    (employerAdminStates.includes(userState.state) ||
      lifecycleStates.includes(userState.state))
  ) {
    render = (props) => <Component {...props} />;
  } else if (onboardingStates.includes(stateToUse)) {
    // this method mutates the render and newPath variables
    buildOnboardingRoutes();
    // TODO add in actual reroute logic for now just render whatever component user path is pointed at
  } else if (
    showNewAdministratorRoutes(
      userState,
      companyState,
      isEmployerAdmin,
      isEmployerAgent
    )
  ) {
    buildAdministratorRoutes();
  } else if (employerAdminStates.includes(userState.state)) {
    // this method mutates the render and newPath variables
    buildEmployerAdminRoutes();
  } else if (lifecycleStates.includes(stateToUse) && userRoleId !== "4") {
    // this method mutates the render and newPath variables
    buildLifecycleRoutes();
  } else {
    if (fetchingState) {
      render = null;
    } else if (userState.state === "login" || isEmpty(userState)) {
      console.log("Route to login.");
      // There was no state object present, so default them to our login/home route
      render = (props) => <Home {...props} />;
      newPath = "/login";
      pushAndPreserveQueryString(newPath);
    } else {
      // Otherwise let it be the passed through component (it's a freely navigable one)
      render = (props) => <Component {...props} />;
    }
  }
  return (
    <ErrorBoundary>
      <SentryRoute path={newPath} render={render} {...rest} />
    </ErrorBoundary>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  userState: PropTypes.shape({
    state: PropTypes.string,
  }),
  companyState: PropTypes.shape({
    state: PropTypes.string,
  }),
  location: PropTypes.object,
  userRoleId: PropTypes.string,
  userIsLocked: PropTypes.bool,
  fetchingState: PropTypes.bool,
  isEmployerAdmin: PropTypes.bool,
  isEmployerAgent: PropTypes.bool,
  requiresAdministrativeUpdates: PropTypes.bool,
  canChangeEmailOnboarding: PropTypes.bool,
  isRollover: PropTypes.bool,
  accountState: PropTypes.shape({
    state: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  userState: state.user.userState,
  companyState: state.user.companyState,
  userRoleId: state.user.userRoleId,
  fetchingState: state.user.initialUserLoading,
  userIsLocked: userIsLockedSelector(state),
  isEmployerAdmin: isEmployerAdminSelector(state),
  isEmployerAgent: isEmployerAgentSelector(state),
  canChangeEmailOnboarding: isPreKycSubmissionState(state),
  requiresAdministrativeUpdates: requiresAdministrativeUpdates(state),
  isRollover: isRolloverSelector(state),
  accountState: activeAccountState(state),
});

/*
* PrivateRoute is both a connected component and very high in the component tree which means
* the possibility for it to unmount/remount components unnecessarily is high and can cause
* unintended side-effects. This prevents that as much as possible by only rerendering when
certain props change
*
* */
const propsEqual = (prevProps, nextProps) => {
  const statesEqual = isEqual(prevProps.userState, nextProps.userState);
  const accountStatesEqual = isEqual(
    prevProps.accountState,
    nextProps.accountState
  );
  const pathsEqual = isEqual(prevProps.path, nextProps.path);
  const locationsEqual = isEqual(prevProps.location, nextProps.location);
  const companyStateEqual = isEqual(
    prevProps.companyState,
    nextProps.companyState
  );
  const fetchingStateEqual = isEqual(
    prevProps.fetchingState,
    nextProps.fetchingState
  );
  return (
    statesEqual &&
    pathsEqual &&
    locationsEqual &&
    companyStateEqual &&
    accountStatesEqual &&
    fetchingStateEqual
  );
};

export default connect(mapStateToProps)(React.memo(PrivateRoute, propsEqual));
