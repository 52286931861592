import React, { useEffect, useState } from "react";
import { getSessionParam } from "services/sessionParamsService";
import { ALL_SCOPES } from "statics/sessionScopes";
import { find, includes, isEmpty, snakeCase, toUpper } from "lodash";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";
import { connect } from "react-redux";

import queryString from "query-string";
import PropTypes from "prop-types";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import SessionlessAnimation from "components/SessionlessAnimation";
import IconAuthlessErrorState from "./IconAuthlessErrorState";

function getSessionScopeFromUrl() {
  const path = window.location.pathname;
  const pathWithoutSession = path.replace("/session/", "");
  const snakedPath = toUpper(snakeCase(pathWithoutSession));
  const sessionScope = find(ALL_SCOPES, (scope) => includes(snakedPath, scope));
  return sessionScope;
}

export default function withSessionToken(WrappedComponent) {
  const Component = (props) => {
    const [sessionParams, setSessionParams] = useState({});
    const [isFetching, setIsFetching] = useState(true);
    const [showErrorState, setShowErrorState] = useState(false);

    useEffect(async () => {
      const sessionScope = getSessionScopeFromUrl();
      const { token, identityHash } = queryString.parse(window.location.search);
      const tokenMissing = isEmpty(token) || isEmpty(identityHash);

      if (tokenMissing) {
        setShowErrorState(true);
        setIsFetching(false);
        return;
      }

      const sessionParam = await getSessionParam(props.client, {
        sessionToken: token,
        sessionScope,
        identityHash,
      });

      if (isEmpty(sessionParam)) {
        setShowErrorState(true);
        setIsFetching(false);

        return;
      }
      setSessionParams({
        ...sessionParam.sessionParams,
        sessionScope,
        identityHash,
        sessionToken: token,
      });

      const { success, error } = sessionParam;

      const erroredSession = success === "false" || !isEmpty(error);
      if (erroredSession) {
        setShowErrorState(true);
        setIsFetching(false);

        return;
      }

      setIsFetching(false);
    }, []);

    function goToDashboard() {
      props.push("/dashboard");
    }

    if (showErrorState) {
      // dashboard will force login or show dashboard if they have a previous session
      setTimeout(goToDashboard, 5000);
      return (
        <>
          <IconAuthlessErrorState
            header={"Something went wrong validating your session token. "}
          />
        </>
      );
    }
    return !isFetching ? (
      <WrappedComponent {...props} sessionParams={sessionParams} />
    ) : (
      <>
        <SessionlessAnimation />
        <div className="authlessAnimation">
          <IconHeader variant="referralHeader" headerText="Preparing Preview" />
          <IconSubheader subheader="Please review and submit your payroll on the next screen." />
        </div>
      </>
    );
  };

  Component.propTypes = {
    client: PropTypes.object,
    sessionParams: PropTypes.object,
    push: PropTypes.func,
  };

  const mapDispatchToProps = {
    push,
  };

  return connect(null, mapDispatchToProps)(withApollo(Component));
}
