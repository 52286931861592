import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { connect } from "react-redux";
import { Col, Form, Modal } from "react-bootstrap";
import { withApollo } from "@apollo/client/react/hoc";
import { push } from "connected-react-router";
import { Formik } from "formik";
import "./IndividualProfile.scss";

import Button from "components/Button";
import Alert from "components/Alert";
import IconHeader from "components/IconHeader";
import IconSubheader from "components/IconSubheader";
import WithdrawalReasonSelect from "components/transferWidget/WithdrawalReasonSelect";

import { closeAccount, getUserState } from "actions/userActions";
import { userConstants } from "actions/types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { toast } from "react-toastify";
import { ScrollToFieldError } from "utils/form";

let yup = require("yup");
const schema = yup.object({
  reason: yup.string().required(),
});

class CloseAccount extends React.PureComponent {
  static propTypes = {
    closeAccount: PropTypes.func,
    getUserState: PropTypes.func,
    push: PropTypes.func,
    iraAccountId: PropTypes.string,
    error: PropTypes.string,
    client: PropTypes.object,
    isClosing: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      showConfirmationModal: false,
      reason: "",
    };
  }

  _hideConfirmationModal = () => {
    this.setState({
      showConfirmationModal: false,
    });
  };
  _showConfirmationModal = (values) => {
    this.setState({
      reason: values.reason,
      showConfirmationModal: true,
    });
  };

  _onCancel = () => {
    this.props
      .closeAccount(
        this.props.client,
        this.props.iraAccountId,
        this.state.reason
      )
      .then(async () => {
        if (!this.props.error) {
          toast.success("Successfully submitted account closure request.");
          this.props.push("/dashboard");
        }
      });
  };

  _confirmationModal = () => {
    return (
      <Modal
        show={this.state.showConfirmationModal}
        centered
        onHide={this._hideConfirmationModal}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm account closure
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul style={{ padding: 12 }}>
            <li>
              Once cancellation has been finalized your IRA will be fully
              disbursed.
            </li>
            <li>
              If you are under the age of 59 1/2 years old there will be a 10%
              early withdrawal fee.
            </li>
          </ul>
          {this.props.error && <Alert type="error" msg={this.props.error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            btnLabel="Nevermind"
            color="cancel"
            name="cancel"
            onClick={this._hideConfirmationModal}
          />
          <Button
            btnLabel="Cancel Account"
            name="submit"
            withArrow
            loading={this.props.isClosing}
            onClick={this._onCancel}
          />
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const confirmationModal = this.state.showConfirmationModal
      ? this._confirmationModal()
      : null;

    return (
      <div className="close-account">
        <Formik
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={(values) => {
            this._showConfirmationModal(values);
          }}
          enableReinitialize={true}
          initialValues={this.state}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            handleBlur,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ScrollToFieldError />
              {confirmationModal}
              <div className="page-header ">
                <IconHeader
                  variant="cardHeader"
                  headerText="Cancel your Icon Account"
                />
                <IconSubheader
                  subheader="Cancelling your Icon account will put your IRA into a locked
                  status. Once cancellation is finalized your IRA will be
                  disbursed to you and any applicable taxes and fees will be
                  applied."
                />
              </div>

              <Form.Group as={Col} sm={6} controlId="formBasicReason">
                <Form.Label>Disbursement Reason</Form.Label>
                <WithdrawalReasonSelect
                  value={values.reason}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.reason}
                </Form.Control.Feedback>
              </Form.Group>
              <section className="form-sec-2col">
                <div className="submit-row">
                  <Button
                    size="sm"
                    name="submit"
                    withArrow={true}
                    btnLabel="Cancel Account"
                  />
                </div>
              </section>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const actions = [userConstants.USER_CLOSE_ACCOUNT];
const loadingSelector = createLoadingSelector(actions);
const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  const iraAccountId = get(state.accounts.iraAccounts, "0.id");
  return {
    iraAccountId,
    error: errorSelector(state),
    isClosing: loadingSelector(state),
  };
};

const mapDispatchToProps = {
  closeAccount,
  getUserState,
  push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(CloseAccount));
