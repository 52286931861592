/* eslint-disable react/no-unescaped-entities */

import React from "react";

class RolloverTerms extends React.Component {
  _content = () => {
    return (
      <>
        <div className="terms-of-service">
          <div>
            <p className="terms-label">
              RULES AND CONDITIONS APPLICABLE TO TRADITIONAL IRA CONTRIBUTIONS
            </p>
            <p>
              The IRA contribution rules are often complex. The general rules
              are listed below. If you have any questions regarding a
              contribution, please consult with a competent tax professional or
              refer to IRS Publication 590-A, Contributions to Individual
              Retirement Arrangements (IRAs), or IRS Publication 560, Retirement
              Plans for Small Business (SEP, SIMPLE, and Qualified Plans) for
              more information. These publications are available on the IRS
              website at www.irs.gov or by calling 1-800-TAX-FORM.
            </p>
          </div>

          <div>
            <ul className="listNone">
              <li>
                <p className="terms-label">REGULAR</p>
                <p>
                  The total amount you may contribute to all IRAs combined
                  (including accounts held away from Apex Clearing) for any tax
                  year cannot exceed the lesser of the published annual limit or
                  100 percent of your earned income and other eligible
                  compensation. If you also maintain a Roth IRA, the maximum
                  contribution to your Traditional IRA is reduced by any
                  contributions you make to your Roth IRA.
                </p>
                <ol type="1">
                  <li>
                    You may make a contribution for the prior year up until your
                    tax filing deadline for that year, not including extensions.
                    Designating a contribution for the prior year is
                    irrevocable.
                  </li>
                  <li>
                    If you are age 50 or older by the end of the year, you may
                    be eligible to make an additional catch-up contribution to
                    an IRA for that tax year.
                  </li>
                </ol>
              </li>

              <li>
                <p className="terms-label">ROLLOVER</p>
                <p>
                  A rollover is a distribution and a subsequent tax-free
                  movement of assets from any of your Traditional IRAs
                  (including Rollover and SEP IRAs), and SIMPLE IRAs, or
                  eligible employer- sponsored retirement plans to your
                  Traditional IRA.
                </p>
                <ol type="1">
                  <li>
                    Effective for distributions taken on or after January 1,
                    2015, you are permitted to roll over only one distribution
                    from an IRA (Traditional, Roth, or SIMPLE) in a 12-month
                    period, regardless of the number of IRAs you own.
                  </li>
                  <li>
                    A rollover generally must be completed within 60 days from
                    the date you receive the assets.
                  </li>
                  <li>
                    A rollover contribution of Roth IRA assets may not be made
                    to a Traditional IRA, or SIMPLE IRA, to a Coverdell ESA or
                    to a Qualified Retirement Plan.
                  </li>
                  <li>
                    A rollover contribution of assets distributed from a SIMPLE
                    IRA within two years of the first contribution to your
                    SIMPLE IRA may not be made to a Traditional IRA.
                  </li>
                </ol>
              </li>

              <li>
                <p className="terms-label">TRANSFER</p>
                <p>
                  A transfer is a direct movement of assets to your Traditional
                  IRA from any of your other Traditional IRAs or SIMPLE IRAs.
                </p>
                <ol type="1">
                  <li>You may perform an unlimited number of transfers.</li>
                  <li>
                    A transfer contribution may not be made from a Roth IRA to a
                    Traditional IRA, SEP, or SIMPLE IRA, to a Coverdell ESA or
                    to a Qualified Retirement Plan.
                  </li>
                  <li>
                    A transfer contribution may not be made from a SIMPLE IRA
                    within two years of the first contribution to your SIMPLE
                    IRA.
                  </li>
                </ol>
              </li>

              <li>
                <p className="terms-label">SEP CONTRIBUTION</p>
                <p>
                  If you are a participant in your employer’s simplified
                  employee pension (SEP) plan, contributions may be made to your
                  Traditional IRA.
                </p>
                <ol type="1">
                  <li>
                    Your employer may make SEP contributions to your Traditional
                    IRA within the published annual limits.
                  </li>
                  <li>
                    If your employer maintains a salary deferral SEP plan, your
                    elective deferrals may not exceed the published annual
                    limit.
                  </li>
                  <li>
                    If your employer maintains a salary deferral SEP plan and
                    you are age 50 or older by the end of the calendar year, you
                    may be eligible to make additional catch-up salary deferral
                    contributions.
                  </li>
                </ol>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  };

  render() {
    const content = this._content();
    return <div>{content}</div>;
  }
}

export default RolloverTerms;
