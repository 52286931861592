import React from "react";
import PropTypes from "prop-types";
import { withApollo } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import { filter, size } from "lodash";
import { toast } from "react-toastify";

import Button from "components/Button";

import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { employerConstants } from "actions/types";
import {
  changeAdministratorRole,
  getEmployeeInvitations,
  lockAdministrator,
  unlockAdministrator,
} from "actions/employerActions";

import { AGENT_LINK_ROLE_TYPES } from "statics/roles";

import "./AdministratorTable.scss";
import { userType } from "../../../../statics/propTypes";

class AdministratorButtons extends React.Component {
  static propTypes = {
    administrators: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.string,
    client: PropTypes.object,
    isLocking: PropTypes.bool,
    isUnlocking: PropTypes.bool,
    lockAdministrator: PropTypes.func,
    unlockAdministrator: PropTypes.func,
    changeAdministratorRole: PropTypes.func,
    currentUserId: PropTypes.string,
    user: userType,
    link: PropTypes.shape({
      role: PropTypes.string,
      locked: PropTypes.bool,
    }),
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoadingByUserId: {},
      isLockingByUserId: {},
      switchingRoleAgents: {},
    };
  }

  _setChangingRoleStatus = (agentId, isUpdating) => {
    this.setState({
      switchingRoleAgents: {
        ...this.state.switchingRoleAgents,
        [agentId]: isUpdating,
      },
    });
  };

  _changeRole = (agentId, role) => {
    this._setChangingRoleStatus(agentId, true);
    this.props
      .changeAdministratorRole(this.props.client, {
        agentId,
        role,
      })
      .then(() => {
        if (!this.props.error) {
          toast.success("Successfully changed administrator role.");
        } else {
          toast.error("There was a problem changing administrator role.");
        }
      })
      .finally(() => {
        this._setChangingRoleStatus(agentId, false);
      });
  };

  buildLockingAnimationState = (userId, isLocking) => {
    return {
      ...this.state,
      isLockingByUserId: {
        ...this.state.isLockingByUserId,
        [userId]: isLocking,
      },
    };
  };

  lockAdministrator = async (userId) => {
    this.setState(this.buildLockingAnimationState(userId, true), () => {
      this.props
        .lockAdministrator(this.props.client, { agentId: userId })
        .then(() => {
          if (!this.props.error) {
            toast.success("Successfully locked administrator.");
            this.setState(this.buildLockingAnimationState(userId, false));
          }
        });
    });
  };

  unlockAdministrator = (userId) => {
    this.setState(this.buildLockingAnimationState(userId, true), () => {
      this.props
        .unlockAdministrator(this.props.client, { agentId: userId })
        .then(() => {
          if (!this.props.error) {
            toast.success("Successfully unlocked administrator.");
            this.setState(this.buildLockingAnimationState(userId, false));
          }
        });
    });
  };

  _hasMoreThanOneActiveAdmin = () => {
    const unlockedAdmins = filter(
      this.props.administrators,
      (admin) => !admin.link.locked
    );

    return size(unlockedAdmins) > 1;
  };

  _getActionsButtons = () => {
    const agent = this.props.user;
    const isCurrentAdmin = agent.id === this.props.currentUserId;

    const link = this.props.link;
    const isAdmin = link.role === AGENT_LINK_ROLE_TYPES.ADMIN;

    const lockButton = link.locked ? (
      <Button
        btnLabel={`Unlock`}
        size="sm"
        withArrow
        name="submit"
        loading={this.state.isLockingByUserId[agent.id]}
        onClick={() => this.unlockAdministrator(agent.id)}
      />
    ) : (
      <Button
        btnLabel={`Lock`}
        size="sm"
        withArrow
        name="submit"
        color="red"
        onClick={() => this.lockAdministrator(agent.id)}
        disabled={
          isAdmin && (isCurrentAdmin || !this._hasMoreThanOneActiveAdmin())
        }
        loading={this.state.isLockingByUserId[agent.id]}
      />
    );

    const convertButton = isAdmin ? (
      <Button
        style={{ padding: "6px 30px 6px 10px" }}
        btnLabel="Make Admin"
        color="action"
        name="submit"
        size="sm"
        withArrow
        disabled={isCurrentAdmin || !this._hasMoreThanOneActiveAdmin()}
        loading={this.state.switchingRoleAgents[agent.id]}
        onClick={() => this._changeRole(agent.id, AGENT_LINK_ROLE_TYPES.AGENT)}
      />
    ) : (
      <Button
        btnLabel="Make Owner"
        size="sm"
        color="action"
        name="submit"
        withArrow
        loading={this.state.switchingRoleAgents[agent.id]}
        onClick={() => this._changeRole(agent.id, AGENT_LINK_ROLE_TYPES.ADMIN)}
      />
    );

    return (
      <>
        <div style={{ display: "flex" }}>
          {!link.locked && convertButton}
          <span style={{ paddingLeft: 10 }}> {lockButton}</span>
        </div>
      </>
    );
  };

  render() {
    return this._getActionsButtons();
  }
}

const errorSelector = createErrorSelector([
  employerConstants.GET_EMPLOYEE_INVITES,
  employerConstants.RESEND_INVITE_ADMINISTRATOR,
  employerConstants.CHANGE_ADMIN_ROLE,
  employerConstants.LOCK_ADMINISTRATOR,
  employerConstants.UNLOCK_ADMINISTRATOR,
  employerConstants.GET_ADMINISTRATORS,
]);

const isLockingSelector = createLoadingSelector(
  employerConstants.LOCK_ADMINISTRATOR
);
const isUnlockingSelector = createLoadingSelector(
  employerConstants.UNLOCK_ADMINISTRATOR
);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isLocking: isLockingSelector(state),
    isUnlocking: isUnlockingSelector(state),
    currentUserId: state.user.id,
    administrators: state.employer.administrators,
  };
};

const mapDispatchToProps = {
  getEmployeeInvitations,
  lockAdministrator,
  unlockAdministrator,
  changeAdministratorRole,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(AdministratorButtons));
