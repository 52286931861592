import React from "react";
import { PropTypes } from "prop-types";
import { Col, Form } from "react-bootstrap";
import { filter, map, noop } from "lodash";

import Button from "components/Button";

class GroupSelection extends React.PureComponent {
  static propTypes = {
    goToNamedStep: PropTypes.func,
    push: PropTypes.func,
    setSourceGroupId: PropTypes.func,
    setTargetGroupId: PropTypes.func,
    isFetching: PropTypes.bool,
    client: PropTypes.object,
    groups: PropTypes.object.isRequired,
    sourceGroupId: PropTypes.string,
    targetGroupId: PropTypes.string,
    onCancel: PropTypes.func,
  };

  _getGroupsForSelect = () => {
    return map(this.props.groups, (group) => {
      return {
        value: group.id,
        label: group.name,
      };
    });
  };

  render() {
    const sourceGroupList = this._getGroupsForSelect();
    const targetGroupList = filter(sourceGroupList, (group) => {
      return group.value !== this.props.sourceGroupId;
    });

    return (
      <div>
        <div className="workflow-widget">
          <Form noValidate onSubmit={noop}>
            <Form.Group md={6} controlId="sourceGroup" as={Col}>
              <Form.Label>Source Group (Group migrating from)</Form.Label>
              <Form.Control
                as="select"
                name="sourceGroupId"
                value={this.props.sourceGroupId}
                onChange={(e) => this.props.setSourceGroupId(e.target.value)}
              >
                <option value="" disabled>
                  Select Source Group
                </option>
                {map(sourceGroupList, (opt) => (
                  <option key={opt.value} value={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {this.props.sourceGroupId && (
              <Form.Group md={6} controlId="targetGroup" as={Col}>
                <Form.Label>Target Group (Group migrating to)</Form.Label>
                <Form.Control
                  as="select"
                  name="targetGroupId"
                  value={this.props.targetGroupId}
                  onChange={(e) => this.props.setTargetGroupId(e.target.value)}
                >
                  <option value="" disabled>
                    Select Target Group
                  </option>
                  {map(targetGroupList, (opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
          </Form>
        </div>
        <div className="process-payroll-button-row">
          <Button
            onClick={() => this.props.onCancel()}
            btnLabel="Cancel"
            name="cancel"
            color="cancel"
          />
          <Button
            name="action"
            disabled={!this.props.targetGroupId || !this.props.sourceGroupId}
            onClick={() => this.props.goToNamedStep("employeeSelection")}
          />
        </div>
      </div>
    );
  }
}

export default GroupSelection;
