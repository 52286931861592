import {
  every,
  get,
  includes,
  isEmpty,
  pickBy,
  size,
  find,
  some,
  filter,
} from "lodash";
import { auth0AccountSessionSelector } from "./auth0";

import { isBankVerifiedSelector } from "./bank";
import { individualPreKycSubmissionStates } from "statics/states";

const EMPLOYER_ROLE_ID = "1";
const ADMIN_ROLE_ID = "2";
const INDIVIDUAL_ROLE_ID = "3";
const ICON_ADMIN_ROLE_ID = "4";

export const canSetupContributionSelector = (state) => {
  const hasPortfolio =
    get(state.user, "userState.state", "") !== "IndividualPortfolioPending";
  const hasBankAccount = isBankVerifiedSelector(state);
  const isSelfEmployed =
    isEmpty(state.user.employerLinkRequests) ||
    every(
      state.user.employerLinkRequests,
      (link) => link.status === "REJECTED"
    );

  if (!isSelfEmployed && !hasPortfolio) {
    return false;
  } else if (!isSelfEmployed && hasPortfolio) {
    return true;
  }
  return hasBankAccount && hasPortfolio;
};

export const userIsLockedSelector = (state) => {
  return userIsLocked(get(state, "user.userState.state", ""));
};

export const userIsClosingAccountSelector = (state) => {
  const userState = get(state, "user.userState.state");
  const lockedStates = ["IndividualClosePending", "IndividualClosed"];
  return includes(lockedStates, userState);
};

export const userProfilesSelector = (state) => {
  const userProfiles = get(state, "employer.userProfiles", {});
  return pickBy(userProfiles, (value) => !userIsLocked(value.state));
};

export const lockedUserProfilesSelector = (state) => {
  const userProfiles = get(state, "employer.userProfiles", {});
  return pickBy(userProfiles, (value) => userIsLocked(value.state));
};

export const usersWithNoContributionChangeSelector = (state) => {
  return filterOutLockedUserProfiles(
    state,
    "payroll.contributionsWithNoChange"
  );
};

export const usersWithPendingContributionsSelector = (state) => {
  return filterOutLockedUserProfiles(
    state,
    "payroll.pendingContributionChanges"
  );
};

export const isEmployerSelector = (state) => {
  return isEmployerAdminSelector(state) || isEmployerAgentSelector(state);
};

export const isEmployerAdminSelector = (state) => {
  return get(state, "user.userRoleId") === EMPLOYER_ROLE_ID;
};

export const isEmployerAgentSelector = (state) => {
  return get(state, "user.userRoleId") === ADMIN_ROLE_ID;
};

export const isIndividualSelector = (state) => {
  return get(state, "user.userRoleId") === INDIVIDUAL_ROLE_ID;
};

export const isIconAdminSelector = (state) => {
  return get(state, "user.userRoleId") === ICON_ADMIN_ROLE_ID;
};

export const pendingRequiredUpdates = (state) => {
  return get(state, "user.requiredUpdates.pendingSteps", []);
};

export const getIraAccountIdSelector = (state) => {
  const accounts = state.accounts.iraAccounts;

  if (isEmpty(accounts)) {
    return null;
  }

  const activeAccount = get(state.accounts.iraAccounts, "0");
  const activeAccountId = auth0AccountSessionSelector(state);

  const activeAccountFromId = accounts.find(
    (account) => account.id === activeAccountId
  );

  const getActiveAccountById = get(activeAccountFromId, "id");
  const accountTypeFromActiveAccount = activeAccount.id;

  const accountId = isEmpty(activeAccountId)
    ? accountTypeFromActiveAccount
    : getActiveAccountById;

  return accountId;
};

function filterOutLockedUserProfiles(state, userProfilePath) {
  const userIdToUserMap = get(state, userProfilePath);
  const lockedUserProfiles = lockedUserProfilesSelector(state);
  return pickBy(
    userIdToUserMap,
    (value) => !lockedUserProfiles[value.employeeId]
  );
}

function userIsLocked(userState) {
  const lockedStates = [
    "IndividualLocked",
    "IndividualContributionPendingLocked",
    "IndividualPortfolioPendingLocked",
    "IndividualDormantLocked",
    "IndividualInvestmentProfileUpdateLocked",
    "IndividualPortfolioUpdateLocked",
    "IndividualRequiredUpdatesPendingLocked",
  ];
  return includes(lockedStates, userState);
}

export const isPreKycSubmissionState = (state) => {
  const userState = get(state, "user.userState.state");
  return includes(individualPreKycSubmissionStates, userState);
};

export const userProfile = (state) => {
  const userProfile = get(state, "user.profile", {});
  return userProfile;
};

export const userEmail = (state) => {
  const userEmail = get(state, `user.auth0User.email`);

  return userEmail;
};

export const activeIraAccount = (state) => {
  const accounts = state.accounts.iraAccounts;
  const activeAccountId = auth0AccountSessionSelector(state);

  const activeAccount = isEmpty(activeAccountId)
    ? get(accounts, "[0]")
    : find(accounts, { id: activeAccountId });

  return activeAccount;
};

export const isIndividualAccountOverview = (state) => {
  const accounts = state.accounts.iraAccounts;
  const activeAccountId = auth0AccountSessionSelector(state);
  const accountOverview = isEmpty(activeAccountId) && size(accounts) >= 2;

  return accountOverview;
};

export const isRolloverSelector = (state) => {
  const accounts = state.accounts.iraAccounts;
  const activeAccountId = auth0AccountSessionSelector(state);
  const userHasARollover = some(accounts, {
    id: activeAccountId,
    isRollover: true,
  });

  return userHasARollover;
};

export const activeAccountState = (state) => {
  const accounts = state.accounts.iraAccounts;
  const activeAccountId = auth0AccountSessionSelector(state);
  const activeAccount = find(accounts, { id: activeAccountId });

  const accountState = activeAccount ? activeAccount.state : null;

  return accountState;
};

export const activeAccountType = (state) => {
  const accounts = state.accounts.iraAccounts;

  if (isEmpty(accounts)) {
    return null;
  }

  const activeAccount = get(state.accounts.iraAccounts, "0");
  const activeAccountId = auth0AccountSessionSelector(state);

  const activeAccountFromId = accounts.find(
    (account) => account.id === activeAccountId
  );

  const getActiveAccountById = get(activeAccountFromId, "accountType");
  const accountTypeFromActiveAccount = activeAccount.accountType;

  const accountType = isEmpty(activeAccountId)
    ? accountTypeFromActiveAccount
    : getActiveAccountById;

  return accountType;
};

export const newActiveRolloverAccount = (state) => {
  const accounts = state.accounts.iraAccounts;

  if (accounts.length !== 2) {
    return false;
  }

  const hasActiveRolloverAccount = some(accounts, {
    isRollover: true,
  });
  const hasRegularAccount = some(accounts, {
    isRollover: false,
  });

  const newRolloverAccount = hasActiveRolloverAccount && hasRegularAccount;
  return newRolloverAccount;
};

export const activeRolloverAccountSameAsCurrent = (state) => {
  const accounts = state.accounts.iraAccounts;
  const activeAccountId = auth0AccountSessionSelector(state);
  const activeAccount = filter(accounts, { id: activeAccountId });

  const rolloverAccountSameAsCurrent = some(activeAccount, {
    lifecycleState: "AccountActive",
    isRollover: true,
  });
  return rolloverAccountSameAsCurrent;
};

export const activeRolloverIsMixed = (state) => {
  const accounts = state.accounts.iraAccounts;
  const rolloverAccounts = filter(accounts, { isRollover: true });

  if (rolloverAccounts.length !== 2) {
    return false;
  }

  const hasActiveAccount = some(rolloverAccounts, {
    lifecycleState: "AccountActive",
  });

  const mixedRollover = hasActiveAccount;
  return mixedRollover;
};

export const isProcessingRolloverSelector = (state) => {
  const accounts = state.accounts.iraAccounts;
  const isProcessing = some(accounts, {
    isProcessingRollover: true,
  });

  return isProcessing;
};

export const activeIdIsProcessingRollover = (state) => {
  const accounts = state.accounts.iraAccounts;
  const activeAccountId = getIraAccountIdSelector(state);
  const activeAccount = find(accounts, { id: activeAccountId });

  if (!activeAccount) {
    return false;
  }

  const accountIsProcessing = get(activeAccount, "isProcessingRollover", false);
  return accountIsProcessing;
};
