import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { Modal } from "react-bootstrap";

import {
  cancelPendingTransaction,
  getTransactions,
} from "actions/transactionActions";

import Button from "components/Button";
import Alert from "components/Alert";

import { createLoadingSelector, createErrorSelector } from "store/selectors";
import { transactionConstants } from "actions/types";
import { getIraAccountIdSelector } from "store/selectors/user";

class ConfirmAchCancellationModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    cancelPendingTransaction: PropTypes.func,
    getTransactions: PropTypes.func,
    transactionId: PropTypes.string,
    accountId: PropTypes.string,
    error: PropTypes.string,
    isCancelling: PropTypes.bool,
    client: PropTypes.object,
  };

  _onCancel = async () => {
    try {
      await this.props.cancelPendingTransaction(
        this.props.client,
        this.props.accountId,
        this.props.transactionId
      );
      this.props.onClose();
      this.props.getTransactions(this.props.client, this.props.accountId);
    } catch (error) {
      // error should get caught by reducer logic
      return;
    }
  };

  render() {
    return (
      <Modal
        centered
        scrollable
        show={true}
        onHide={this.props.onClose}
        className="contribution-modal"
      >
        <Modal.Header className="header" closeButton={false}>
          <Modal.Title className="title" id="contained-modal-title-vcenter">
            Confirm transfer cancellation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <p style={{ padding: 44 }}>
            Once confirmed, we will submit a request to cancel the transfer.
            Cancellation can take 3-5 business days to complete.
          </p>
          {this.props.error && <Alert type="error" msg={this.props.error} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            btnLabel="Nevermind"
            name="cancel"
            color="cancel"
            onClick={this._hideConfirmationModal}
          />
          <span className="remove-bank">
            <Button
              className="cancel-transaction"
              btnLabel="Cancel Transaction"
              name="submit"
              withArrow
              loading={this.props.isCancelling}
              onClick={this._onCancel}
            />
          </span>
        </Modal.Footer>
      </Modal>
    );
  }
}

const actions = [transactionConstants.CANCEL_TRANSACTION];

const isCancelling = createLoadingSelector(actions);

const errorSelector = createErrorSelector(actions);

const mapStateToProps = (state) => {
  return {
    isCancelling: isCancelling(state),
    error: errorSelector(state),
    accountId: getIraAccountIdSelector(state),
  };
};

const mapDispatchToProps = {
  cancelPendingTransaction,
  getTransactions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ConfirmAchCancellationModal));
