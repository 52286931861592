import React from "react";
import { ISOLVED_WHOLESALE, LEGACY_NOBILL, STRIPE } from "statics/billingTypes";
import { Col, Form } from "react-bootstrap";
import { Formik } from "formik";
import { map } from "lodash";
import Button from "components/Button";
import PropTypes from "prop-types";
import { createErrorSelector, createLoadingSelector } from "store/selectors";
import { adminConstants } from "actions/types";
import { changeBillingType } from "actions/adminActions";
import { connect } from "react-redux";
import { withApollo } from "@apollo/client/react/hoc";
import { toast } from "react-toastify";
import Alert from "components/Alert";

const providers = [ISOLVED_WHOLESALE, LEGACY_NOBILL, STRIPE];

class ChangeBillingTypeForm extends React.PureComponent {
  static propTypes = {
    billingType: PropTypes.string,
    companyId: PropTypes.string,
    onCancel: PropTypes.func,
    changeBillingType: PropTypes.func,
    onSuccess: PropTypes.func,
    client: PropTypes.object,
    isSubmitting: PropTypes.bool,
    error: PropTypes.string,
  };

  _onSubmit = async (values) => {
    await this.props.changeBillingType(this.props.client, {
      companyId: this.props.companyId,
      billingType: values.billingType,
    });

    if (!this.props.error) {
      this.props.onSuccess(values.billingType);
      toast.success("Billing type changed successfully.");
    }
  };

  render() {
    return (
      <div>
        <p>
          Changing the billing type for an employer will require them to agree
          to a new order form.
        </p>
        {this.props.billingType === STRIPE && (
          <p>
            Changing the billing type for this employer will cancel their
            existing stripe subscription, if it exists.
          </p>
        )}
        <Formik
          onSubmit={this._onSubmit}
          enableReinitialize={true}
          initialValues={{
            billingType: this.props.billingType,
          }}
        >
          {({ handleSubmit, handleChange, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group as={Col} sm={6} controlId="formBasicBillingType">
                <Form.Label>Current Billing Type</Form.Label>
                <Form.Control
                  as="select"
                  name={"billingTypeCurrent"}
                  onChange={handleChange}
                  disabled
                  value={this.props.billingType}
                >
                  <option value={this.props.billingType}>
                    {this.props.billingType}
                  </option>
                </Form.Control>
              </Form.Group>
              <Form.Group as={Col} sm={6} controlId="formBasicBillingTypee">
                <Form.Label>New Billing Type</Form.Label>
                <Form.Control
                  as="select"
                  name={"billingType"}
                  onChange={handleChange}
                  value={values.billingType}
                >
                  {map(providers, (provider) => (
                    <option value={provider}>{provider}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              {this.props.error && (
                <Alert type="error" msg={this.props.error} />
              )}
              <div className="submit-row">
                <span className="cancel-btn">
                  <Button
                    btnLabel="Cancel"
                    name="cancel"
                    color="cancel"
                    type="button"
                    onClick={() => {
                      this.props.onCancel();
                    }}
                  />
                </span>
                <Button
                  btnLabel="Change Billing Type"
                  name="submit"
                  withArrow={true}
                  disabled={values.billingType === this.props.billingType}
                  loading={this.props.isSubmitting}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const errorSelector = createErrorSelector([
  adminConstants.CHANGE_BILLING_TYPES,
]);
const loadingSelector = createLoadingSelector(
  adminConstants.CHANGE_BILLING_TYPES
);

const mapStateToProps = (state) => {
  return {
    error: errorSelector(state),
    isSubmitting: loadingSelector(state),
  };
};

const mapDispatchToProps = {
  changeBillingType,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(ChangeBillingTypeForm));
